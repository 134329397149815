import { EVENTS_GENERATED } from './analytics/src/actions';
import { getPHIFilteredState } from '~shared/selectors/getPHIFilteredState';
import { type ReduxStateShared } from '~shared/store';

const ACTION_SAFELIST = {
  '@@router/LOCATION_CHANGE': true,
  [EVENTS_GENERATED]: true,
};

const sentryReduxEnhancerConfig = {
  stateTransformer: (state: ReduxStateShared) => getPHIFilteredState(state),
  actionTransformer: (action: { type: string; payload?: any; value?: any }) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
    if (ACTION_SAFELIST[action.type]) {
      return action;
    }

    if (action.payload !== undefined) {
      return { ...action, payload: 'REDACTED' };
    }

    return action;
  },
};

export default sentryReduxEnhancerConfig;
