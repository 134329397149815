// @owners { team: patients-team }
import { ActionSheetContext, Column, ListDescription } from '@alto/design-system';
import { type MedDetailsMedication } from '@alto/scriptdash/alto/medications/patients/v1/types';
import React, { useContext, useEffect } from 'react';
import { getVideosFromNdc } from '~shared/features/instructional-videos/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useProduct } from '~shared/queries';
import { RefrigerationActionSheet } from '../../medication-item';
import { AlternativeMedicationActionSheet } from './AlternativeMedicationActionSheet';
import { CompoundedMedicationActionSheet } from './CompoundedMedicationActionSheet';
import { ControlledMedicationActionSheet } from './ControlledMedicationActionSheet';
import { InjectionVideoActionSheet } from './InjectionVideoActionSheet';
import { MedicationMonographActionSheet } from './MedicationMonographActionSheet';

export const MedicationDescriptions = ({
  medication,
  providerName,
}: {
  readonly medication: MedDetailsMedication | undefined;
  readonly providerName: string;
}) => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const {
    title,
    therapeutic_alternative,
    brand_names_display,
    is_controlled,
    is_compound,
    is_refrigerated,
    product_id,
  } = medication || {};
  const { product } = useProduct({ productID: product_id });
  const videosForNDC = getVideosFromNdc(medication?.primary_prescription.ndc ?? '');

  // track when a user sees the line items (different from viewing the action sheet though)
  useEffect(() => {
    if (therapeutic_alternative) {
      trackEvent({
        event: EVENTS.MED_DETAILS__ALTERNATIVE_MEDICATION_SHOWN,
        params: {
          origin: ORIGIN_NAMES.MED_DETAILS,
        },
        additionalFields: { prescriptionId: medication?.primary_prescription.id },
      });
    }
  }, [medication?.primary_prescription.id, therapeutic_alternative, trackEvent]);

  useEffect(() => {
    if (is_controlled) {
      trackEvent({
        event: EVENTS.MED_DETAILS__CONTROLLED_MEDICATION_SHOWN,
        params: {
          origin: ORIGIN_NAMES.MED_DETAILS,
        },
        additionalFields: { prescriptionId: medication?.primary_prescription.id },
      });
    }
  }, [is_controlled, medication?.primary_prescription.id, trackEvent]);

  useEffect(() => {
    if (is_compound) {
      trackEvent({
        event: EVENTS.MED_DETAILS__COMPOUNDED_MEDICATION_SHOWN,
        params: {
          origin: ORIGIN_NAMES.MED_DETAILS,
        },
        additionalFields: { prescriptionId: medication?.primary_prescription.id },
      });
    }
  }, [is_compound, medication?.primary_prescription.id, trackEvent]);

  useEffect(() => {
    if (is_refrigerated) {
      trackEvent({
        event: EVENTS.MED_DETAILS__REFRIGERATION_REQUIRED_SHOWN,
        params: {
          origin: ORIGIN_NAMES.MED_DETAILS,
        },
        additionalFields: { prescriptionId: medication?.primary_prescription.id },
      });
    }
  }, [is_refrigerated, medication?.primary_prescription.id, trackEvent]);

  useEffect(() => {
    if (product?.monograph && product_id !== undefined) {
      trackEvent({
        event: EVENTS.MED_DETAILS__MEDICATION_MONOGRAPH_SHOWN,
        params: {
          origin: ORIGIN_NAMES.MED_DETAILS,
        },
        additionalFields: { prescriptionId: medication?.primary_prescription.id },
      });
    }
  }, [medication?.primary_prescription.id, product?.monograph, product_id, trackEvent]);

  useEffect(() => {
    if (videosForNDC?.length) {
      trackEvent({
        event: EVENTS.MED_DETAILS__HOW_TO_INJECT_SHOWN,
        params: {
          origin: ORIGIN_NAMES.MED_DETAILS,
        },
        additionalFields: { prescriptionId: medication?.primary_prescription.id },
      });
    }
  }, [medication?.primary_prescription.id, trackEvent, videosForNDC?.length]);

  // Column with gap added because of bug in RN that treats React.Fragment's children as one element
  // instead of an array of elements https://github.com/reactjs/rfcs/pull/61
  // this XXS spacing would normally be taken care of in ListItemDescriptions
  // https://github.com/scriptdash/clients/blob/d17df54f60b8b78f67d2c799538887e809ca5163/libs/design-system/src/components/lists/src/ListItem/ListItemDescriptions.tsx#L19-L19
  return (
    <Column
      gap="XXS"
      flexShrink={1}
    >
      {brand_names_display ? <ListDescription iconName="swap-small">{brand_names_display}</ListDescription> : null}
      {therapeutic_alternative ? (
        <ListDescription
          type="secondary"
          iconName="swap-small"
          onPress={() => {
            setActiveActionSheet(
              <AlternativeMedicationActionSheet
                medicationName={title}
                providerName={providerName}
              />,
            );
          }}
        >
          {`${therapeutic_alternative} ›`}
        </ListDescription>
      ) : null}
      {is_controlled ? (
        <ListDescription
          iconName="calendar-small"
          onPress={() => {
            setActiveActionSheet(<ControlledMedicationActionSheet />);
          }}
          type="secondary"
        >
          Controlled medication ›
        </ListDescription>
      ) : null}
      {is_compound ? (
        <ListDescription
          iconName="testtube-small"
          onPress={() => {
            setActiveActionSheet(<CompoundedMedicationActionSheet medicationName={title} />);
          }}
          type="secondary"
        >
          Compounded medication ›
        </ListDescription>
      ) : null}
      {is_refrigerated ? (
        <ListDescription
          iconName="snowflake-small"
          onPress={() => {
            setActiveActionSheet(<RefrigerationActionSheet medicationName={title || 'this medication'} />);
          }}
          type="secondary"
        >
          Refrigeration required ›
        </ListDescription>
      ) : null}
      {product?.monograph && product_id !== undefined ? (
        <ListDescription
          iconName="info-small"
          onPress={() => {
            setActiveActionSheet(
              <MedicationMonographActionSheet
                medicationName={title}
                productId={product_id}
              />,
            );
          }}
          type="secondary"
        >
          Uses, how to, and side effects ›
        </ListDescription>
      ) : null}
      {videosForNDC?.length ? (
        <ListDescription
          iconName="syringe-small"
          onPress={() => {
            setActiveActionSheet(
              <InjectionVideoActionSheet
                medicationName={title || 'this medication'}
                videosForNDC={videosForNDC}
              />,
            );
          }}
          type="secondary"
        >
          How to inject ›
        </ListDescription>
      ) : null}
    </Column>
  );
};
