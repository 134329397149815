import { type BACKGROUND_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';
import * as React from 'react';
import styled from 'styled-components/native';
import { Button } from '../../../buttons';
import { Column, Row } from '../../../containers';
import { AltoSpinningLoader } from '../../../loaders';
import { MdSpacing, XsSpacing, XxsSpacing } from '../../../separators';
import { Caption, H3, Text } from '../../../typography';
import { ListItemBase } from './ListItemBase';
import { ListItemDescriptions } from './ListItemDescriptions';
import { ListItemTagsWrapper } from './ListItemTagsWrapper';
import { type ListItemProps } from './ListUtils';

// prevents TitlePressableIcon from overflowing into RightContent
const TitleAndTagsWrapper = styled(Row)`
  width: 100%;
`;

const TitlePressableIconAnchor = styled(Row)`
  align-self: flex-start;
`;

type ListItemWrapperProps = {
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly onPress?: () => void;
  readonly fullBleed?: boolean;
  readonly children: React.ReactNode;
  readonly testID?: string;
};

const ListItemWrapper = ({ backgroundColor, onPress, fullBleed, children, testID }: ListItemWrapperProps) => {
  return (
    <ListItemBase
      hideTopBorder
      hideBottomBorder
      backgroundColor={backgroundColor}
      pressedBackgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST}
      onPress={onPress}
      opacity={false}
      fullBleed={fullBleed}
      testID={testID}
    >
      {children}
    </ListItemBase>
  );
};

export const ListItem = ({
  title,
  titleSize = 'default',
  byline,
  buttonProps,
  BottomButton,
  onPress,
  tags,
  loading,
  tagPlacement = 'title',
  fullBleed = false,
  LeftContent,
  RightContent,
  descriptions,
  disabled,
  titlePressableIconPlacement = 'right-aligned',
  TitlePressableIcon,
  backgroundColor = COLORS.BACKGROUND_COLORS.TRANSPARENT,
  testID,
  centerVertically = true,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: ListItemProps) => {
  const isTagsAnArray = Array.isArray(tags) && !!tags?.length;
  const hasTags = !!tags || isTagsAnArray;
  const smallTitle: boolean = titleSize === 'small';

  if (loading) {
    return (
      <ListItemWrapper
        backgroundColor={backgroundColor}
        onPress={onPress}
        fullBleed={fullBleed}
      >
        <Row centerHorizontally>
          <AltoSpinningLoader />
        </Row>
      </ListItemWrapper>
    );
  }

  return (
    <ListItemWrapper
      backgroundColor={backgroundColor}
      onPress={onPress}
      fullBleed={fullBleed}
      testID={testID}
    >
      <Row
        spaceBetween
        wrap={false}
      >
        <Row
          flexShrink={1}
          flexGrow={1}
          wrap={false}
        >
          {LeftContent ? (
            <Row flexShrink={0}>
              {LeftContent}
              <MdSpacing />
            </Row>
          ) : null}
          <Column
            flexShrink={1}
            flexGrow={1}
            centerVertically={centerVertically}
          >
            {!!byline && (
              <>
                <Text
                  textSize="mini"
                  fontFamily="semibold"
                  tight
                  color={COLORS.TEXT_COLORS.GREY}
                >
                  {byline}
                </Text>
                <XsSpacing />
              </>
            )}
            {hasTags && tagPlacement === 'top' ? (
              <ListItemTagsWrapper
                tags={tags}
                tagPlacement={tagPlacement}
              />
            ) : null}
            {!!title && (
              <Row
                flexShrink={1}
                wrap={false}
              >
                <TitleAndTagsWrapper
                  centerVertically
                  flexShrink={1}
                  wrap
                >
                  {smallTitle ? (
                    <Caption
                      fontFamily="semibold"
                      color={disabled ? COLORS.TEXT_COLORS.DISABLED : COLORS.TEXT_COLORS.PRIMARY}
                    >
                      {title}
                    </Caption>
                  ) : (
                    <H3
                      color={disabled ? COLORS.TEXT_COLORS.DISABLED : COLORS.TEXT_COLORS.PRIMARY}
                      numberOfLines={3}
                    >
                      {title}
                    </H3>
                  )}
                  {titlePressableIconPlacement === 'inline' && !!TitlePressableIcon && (
                    <>
                      <XxsSpacing />
                      {TitlePressableIcon}
                    </>
                  )}
                  {hasTags && tagPlacement === 'title' ? (
                    <ListItemTagsWrapper
                      tags={tags}
                      tagPlacement={tagPlacement}
                    />
                  ) : null}
                </TitleAndTagsWrapper>

                {titlePressableIconPlacement === 'right-aligned' && !!TitlePressableIcon && (
                  <TitlePressableIconAnchor wrap={false}>
                    <XxsSpacing />
                    {TitlePressableIcon}
                  </TitlePressableIconAnchor>
                )}
              </Row>
            )}
            {!!descriptions && (
              <ListItemDescriptions
                descriptions={descriptions}
                hasTopSpacing={!!title}
              />
            )}
            {hasTags && tagPlacement === 'bottom' ? (
              <ListItemTagsWrapper
                tags={tags}
                tagPlacement={tagPlacement}
              />
            ) : null}
            {BottomButton ? (
              <>
                <XsSpacing />
                {BottomButton}
              </>
            ) : undefined}
          </Column>
        </Row>
        {RightContent ? (
          <Row flexShrink={0}>
            <XsSpacing />
            <Column
              right
              centerVertically
            >
              {RightContent}
            </Column>
          </Row>
        ) : null}
      </Row>
      {buttonProps ? (
        <>
          <XsSpacing />
          <Button {...buttonProps} />
        </>
      ) : undefined}
    </ListItemWrapper>
  );
};
