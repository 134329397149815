// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { Button, FlexColumn, Paragraph, SimpleContainerSection, spacing } from '@alto/pocky';
import { getYear, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { API_URL } from '~shared/config';
import getDateCreated from '~shared/features/users/selectors/getDateCreated';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';

export const FSAReceiptSection = () => {
  const userJoinedAt = useSelectorShared(getDateCreated);
  const { trackEvent } = useAnalytics();

  const yearsArray = useMemo(() => {
    const thisYear = getYear(new Date());
    const inception = userJoinedAt ? getYear(parseISO(userJoinedAt)) : thisYear;

    return Array.from(
      {
        length: thisYear - inception + 1,
      },
      (_, i) => i + inception,
    ).reverse();
  }, [userJoinedAt]);
  const fireEvent = (year: number) => {
    trackEvent({ event: EVENTS.DOWNLOAD_FSA_ANNUAL_RECEIPT_CLICKED, params: { year } });
  };

  return (
    <>
      {yearsArray.map((year) => (
        <SimpleContainerSection key={`row-${year}`}>
          <FlexColumn
            key={`pdf-download-row-${year}`}
            evenlySpaceContent
            verticallyAlignContent
          >
            <FlexColumn
              spacing={spacing.smSpacing}
              verticallyAlignContent
            >
              <Paragraph>{year}</Paragraph>
            </FlexColumn>
            <a
              href={`${API_URL}/v2/receipts/fsa_receipt?year=${year}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                onClick={() => {
                  fireEvent(year);
                }}
              >
                Download PDF
              </Button>
            </a>
          </FlexColumn>
        </SimpleContainerSection>
      ))}
    </>
  );
};
