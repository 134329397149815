// @owners { team: patients-team }
import { ListDescription } from '@alto/design-system';
import React from 'react';
import { type Order } from '~shared/features/checkout/types';
import { PAYMENT_METHOD_DATA_TYPES } from '~shared/features/payment-methods/constants';
import { getPaymentMethodIconName } from '~shared/features/payment-methods/helpers';
import {
  checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder,
  checkIfPaymentMethodFailedInitialCheckoutOrAfterSaveOrderAttempt,
  findCorrespondingShipmentPaymentMethodFromPaymentMethod,
} from '~shared/features/payment-methods/helpers/checkIfPaymentMethodFailed';
import { getPaymentMethodName } from '~shared/features/payment-methods/helpers/getPaymentMethodName';
import { type APIError, type PaymentMethod } from '~shared/types';
import { PaymentMethodIcon } from '../../payment-summary';

type Props = {
  paymentMethods: PaymentMethod[];
  order: Order;
  saveOrderError: APIError | null;
  isEditingOrder: boolean;
  didAttemptOrderUpdate: boolean | null;
  isSplitPaymentEnabled: boolean;
  disabledPaymentMethodID: number | null | undefined;
  onNewPress: () => void;
};

export const usePaymentOptions = ({
  paymentMethods,
  order,
  saveOrderError,
  isEditingOrder,
  didAttemptOrderUpdate,
  isSplitPaymentEnabled,
  disabledPaymentMethodID,
  onNewPress,
}: Props) => {
  const paymentOptions = paymentMethods.map((paymentMethod) => {
    const id = paymentMethod.id.toString();
    const shipmentPaymentMethod = findCorrespondingShipmentPaymentMethodFromPaymentMethod(
      order?.shipment_payment_methods,
      paymentMethod,
    );
    const didPaymentFailBeforeSaveOrderAttemptOnExistingOrder = isEditingOrder && !didAttemptOrderUpdate;
    const didPaymentMethodFailPreauthCheck = didPaymentFailBeforeSaveOrderAttemptOnExistingOrder
      ? checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder(shipmentPaymentMethod)
      : checkIfPaymentMethodFailedInitialCheckoutOrAfterSaveOrderAttempt(paymentMethod, saveOrderError);
    const disabled =
      isSplitPaymentEnabled &&
      (paymentMethod.type === PAYMENT_METHOD_DATA_TYPES.PLAID_DATA || paymentMethod.id === disabledPaymentMethodID);
    const label = getPaymentMethodName(paymentMethod) ?? 'unknown card';
    const exp = `exp. ${paymentMethod.details?.card_expiration_month}/${paymentMethod.details?.card_expiration_year
      ?.toString()
      .substring(2)}`;
    const paymentMethodIconName = getPaymentMethodIconName(paymentMethod);
    const descriptions = [];
    if (paymentMethod.type !== PAYMENT_METHOD_DATA_TYPES.PLAID_DATA) {
      descriptions.push(<ListDescription key={exp}>{exp}</ListDescription>);
    }
    if (didPaymentMethodFailPreauthCheck) {
      descriptions.push(<ListDescription type="danger">unable to validate card</ListDescription>);
    }
    return {
      label,
      value: id,
      disabled: disabled || didPaymentMethodFailPreauthCheck,
      descriptions,
      LeftContent: (
        <PaymentMethodIcon
          paymentMethodIconName={paymentMethodIconName}
          size="default"
        />
      ),
    };
  });
  return [
    ...paymentOptions,
    {
      label: 'Add new method',
      value: 'ADD_OPTION_VALUE',
      onPress: onNewPress,
    },
  ];
};
