import { BORDERS, SPACING } from '@alto/design-library-tokens';
import React, { type ReactNode, useEffect, useState } from 'react';
import { useScreenSize } from '../../../utils';
import { Fade } from '../../animations';
import { Pressable } from '../../buttons';
import { MdPadding } from '../../containers';
import { getColors } from './AlertUtils';
import { ToastBody } from './ToastBody';

export type ToastVariant = 'error' | 'success' | 'neutral';

export type ToastProps = {
  readonly duration?: number;
  readonly delay?: number;
  readonly variant?: ToastVariant;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
  readonly children?: ReactNode;
  readonly actionLabel?: string;
  readonly actionOnPress?: () => void;
  readonly dismissible?: boolean;
  readonly fullBleed?: boolean;
  readonly onHide?: () => void;
  readonly onPress?: () => void;
};

export type ToastElement = React.ReactElement<ToastProps>;

const isTest = !!process.env.JEST_WORKER_ID;

export const Toast = ({
  duration,
  delay,
  variant = 'success',
  children,
  testID,
  actionLabel,
  onPress,
  actionOnPress,
  fullBleed,
  dismissible,
  onHide,
}: ToastProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const color = getColors(variant);
  const { isSMScreenOrBigger } = useScreenSize();
  const defaultDuration = isSMScreenOrBigger ? 4000 : 3500;
  duration = duration || defaultDuration;

  useEffect(() => {
    if (isTest) return;
    const timeout = setTimeout(() => {
      setIsVisible(false);
      onHide?.();
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [onHide, duration, setIsVisible]);

  if (!isVisible) {
    return null;
  }

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <Fade
      duration={250}
      delay={delay}
    >
      <MdPadding
        topPadding={SPACING.STATIC.NONE}
        leftPadding={fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.MD}
        rightPadding={fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.MD}
      >
        {onPress ? (
          <Pressable
            onPress={onPress}
            backgroundColor={color.background}
            testID={testID}
            borderRadius={BORDERS.RADIUS.MD}
          >
            <ToastBody
              onClose={handleClose}
              dismissible={dismissible}
              variant={variant}
              actionLabel={actionLabel}
              actionOnPress={actionOnPress}
            >
              {children}
            </ToastBody>
          </Pressable>
        ) : (
          <ToastBody
            onClose={handleClose}
            dismissible={dismissible}
            variant={variant}
            actionLabel={actionLabel}
            actionOnPress={actionOnPress}
          >
            {children}
          </ToastBody>
        )}
      </MdPadding>
    </Fade>
  );
};
