// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, H1, Modal, ModalContent, ModalFooter, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { COURIER_TIP_COPY } from '~shared/features/courier-tips/constants';

const PaddedContent = styled(Column)<{ isSMScreenOrBigger: boolean }>`
  padding: ${SPACING.STATIC.LG.rem} ${SPACING.STATIC.LG.rem} 0 ${SPACING.STATIC.LG.rem};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `padding: 0px;`}
`;

const HeaderText = styled(H1)`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
`;

const ContentText = styled(Text)`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
`;

export type Props = {
  readonly onClose: () => void;
};

const InfoModal = ({ onClose }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();

  return (
    <Modal
      dismissOnOutsideClick
      onClose={onClose}
      showDismiss
    >
      <ModalContent>
        <PaddedContent
          spacing={SPACING.STATIC.LG.rem}
          isSMScreenOrBigger={isSMScreenOrBigger}
        >
          <HeaderText>Courier tipping</HeaderText>
          <Column spacing={SPACING.STATIC.XXS.rem}>
            {COURIER_TIP_COPY.INFO_BULLETS.map((text) => (
              <ContentText key={text}>{text}</ContentText>
            ))}
          </Column>
        </PaddedContent>
      </ModalContent>
      <ModalFooter borderless>
        <Button
          full
          onClick={onClose}
        >
          {COURIER_TIP_COPY.INFO_ACTION_SHEET_BUTTON_LABEL}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InfoModal;
