// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { isPickup } from './order';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { numberOfDaysSince } from '~shared/helpers/date';

type Props = {
  date: string;
  method?: DeliveryMethod | null;
  status?: APIOrderStatus | null;
};

export function isPickupPastDue({ date, method, status }: Props) {
  if (!isPickup(method?.mode)) return false;
  if (status !== 'shipped') return false;
  return numberOfDaysSince(date) > 0;
}
