import { CHARGE_STATUS, PAYMENT_ERRORS, PAYMENT_METHOD_DATA_TYPES } from '~shared/features/payment-methods/constants';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { type PaymentMethod, type ShipmentPaymentMethod } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const checkIfPaymentMethodFailedInitialCheckoutOrAfterSaveOrderAttempt = (
  paymentMethod?: PaymentMethod | null,
  saveOrderError?: APIError | null,
): boolean => {
  return (
    paymentMethod?.type === PAYMENT_METHOD_DATA_TYPES.STRIPE_DATA &&
    paymentMethod?.most_recent_charge_status === CHARGE_STATUS.FAILED &&
    saveOrderError?.details?.code === PAYMENT_ERRORS.PREAUTH_FAILED_ERROR
  );
};

export const checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder = (
  shipmentPaymentMethod?: ShipmentPaymentMethod,
): boolean => {
  return (
    shipmentPaymentMethod?.paymentMethod?.type === PAYMENT_METHOD_DATA_TYPES.STRIPE_DATA &&
    shipmentPaymentMethod?.charge_status === CHARGE_STATUS.FAILED
  );
};

export const findCorrespondingShipmentPaymentMethodFromPaymentMethod = (
  shipmentPaymentMethods?: PaymentMethodWithAmount[] | ShipmentPaymentMethod[],
  paymentMethod?: PaymentMethod | null,
): ShipmentPaymentMethod | undefined => {
  return shipmentPaymentMethods?.find?.((spm) => spm.payment_method_id === paymentMethod?.id);
};
