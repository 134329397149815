// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Body,
  Button,
  Card,
  Column,
  H1,
  ListItem,
  MdPadding,
  MdSpacing,
  SecondaryPage,
  SmPadding,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { useSelectorShared } from '~shared/store';
import { ConfirmCashPayActionSheet } from '../../insurance';
import { FamilyMemberProgressBar } from './FamilyMemberProgressBar';

const isWeb = getPlatformOS() === 'web';

export const FamilyMemberBenefits = () => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { navigate } = useNavigation<'RouteFamilyMemberBenefits'>();
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const selectedUser = useSelectorShared(getSelectedUser);
  const familyMemberName = selectedUser?.preferred_first_name || selectedUser?.first_name || 'family member';

  useEffect(() => {
    if (!relationship) {
      navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
    }
  }, [navigate, relationship]);

  const handleSkip = () => {
    navigate('RouteFamilyMemberAdded');
  };

  const handleNoBenefitsPress = () => {
    setActiveActionSheet(
      <ConfirmCashPayActionSheet
        source="add family member"
        onConfirm={() => {
          if (!selectedUser) return;
          navigate('RouteFamilyMemberAdded');
        }}
      />,
    );
  };

  const handleTakePhotoPress = () => {
    if (!selectedUser) return;
    navigate('RouteInsurancePhotoForm', { source: 'add family member', userID: selectedUser.id });
  };

  const handleManualEntryPress = () => {
    if (!selectedUser) return;
    navigate('RouteInsuranceForm', { source: 'add family member', userID: selectedUser.id });
  };

  return (
    <SecondaryPage
      buttons={[
        <Button
          accessibilityLabel="Press to remind me later"
          key="remind-me"
          label="Remind me later"
          onPress={handleSkip}
          type="tertiary"
        />,
      ]}
      dismissIcon="chevronleft"
      onDismiss={() => {
        if (!selectedUser) return;
        // navigate to prev screen; cannot pass navigation params to goBack
        navigate('RouteFamilyMemberMedicalInfo', { userID: selectedUser.id });
      }}
      headerBackgroundColor={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      HeaderContent={
        <MdPadding topPadding={SPACING.STATIC.NONE}>
          <Column center>
            <FamilyMemberProgressBar />
            <XlSpacing />
            <H1 center>{`Complete ${getPossessiveName(familyMemberName)} profile:\nAdd benefits`}</H1>
            <XsSpacing />
            <Body center>Add all insurance and prescription savings cards so we can get you the best pricing.</Body>
            <MdSpacing />
          </Column>
        </MdPadding>
      }
      footerPlacementContext={isWeb ? 'webScreenWithoutPolicyFooter' : undefined}
    >
      <Card>
        <SmPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <ListItem
            title="Take a photo"
            LeftContent={
              <AltoIcon
                name="camera-duo"
                accessibilityLabel="Press to take photo with camera"
              />
            }
            RightContent={
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
            }
            onPress={handleTakePhotoPress}
          />
        </SmPadding>
      </Card>
      <MdSpacing />
      <Card>
        <SmPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <ListItem
            title="Enter it manually"
            LeftContent={
              <AltoIcon
                name="signpaper-duo"
                accessibilityLabel="Press to enter benefit manually"
              />
            }
            RightContent={
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
            }
            onPress={handleManualEntryPress}
          />
        </SmPadding>
      </Card>
      <MdSpacing />
      <Card>
        <SmPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <ListItem
            title="They don’t have any benefits"
            LeftContent={
              <AltoIcon
                name="shieldslash-duo"
                accessibilityLabel="Press to skip this step"
              />
            }
            RightContent={
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
            }
            onPress={handleNoBenefitsPress}
          />
        </SmPadding>
      </Card>
    </SecondaryPage>
  );
};
