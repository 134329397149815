import { type AppStateStatus } from 'react-native';

export const SET_PRESENCE = 'SET_PRESENCE';

type PresencePayload = {
  appState?: AppStateStatus;
  isConnected?: boolean | null;
};
export type ActionSetPresence = {
  type: typeof SET_PRESENCE;
  payload: PresencePayload;
};

export const setPresence = (payload: PresencePayload): ActionSetPresence => ({
  type: 'SET_PRESENCE' as const,
  payload,
});
