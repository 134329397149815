import { COLORS } from '@alto/design-library-tokens';
import { Column, Description, MdSpacing, Row, XsSpacing } from '@alto/design-system';
import React from 'react';
import formatPaymentMethod from '~shared/features/payment-methods/helpers/formatPaymentMethod';
import getPaymentMethodIconName from '~shared/features/payment-methods/helpers/getPaymentMethodIconName';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { type PaymentMethod } from '~shared/types';
import { PaymentMethodIcon } from './PaymentMethodIcon';

type Props = {
  readonly shipmentPaymentMethods: PaymentMethodWithAmount[];
  readonly showTipPaymentMethod?: boolean;
  readonly tipPaymentMethod?: PaymentMethod | null;
};

export const PaymentMethodInfo = ({ shipmentPaymentMethods, showTipPaymentMethod, tipPaymentMethod }: Props) => {
  const hasSinglePaymentMethod =
    shipmentPaymentMethods?.length === 1 &&
    tipPaymentMethod &&
    tipPaymentMethod.id === shipmentPaymentMethods[0].paymentMethod?.id;

  return (
    <Column>
      {shipmentPaymentMethods.map((shipmentPaymentMethod, idx) => {
        const { id, paymentMethod } = shipmentPaymentMethod;
        const isTipPaymentMethod = tipPaymentMethod?.id === paymentMethod?.id;
        return (
          <React.Fragment key={id}>
            <Row
              centerVertically
              spaceBetween
            >
              <Row centerHorizontally>
                <PaymentMethodIcon
                  size="small"
                  paymentMethodIconName={getPaymentMethodIconName(paymentMethod)}
                />
                <XsSpacing />
                <Description color={COLORS.TEXT_COLORS.GREY}>{formatPaymentMethod(paymentMethod)}</Description>
              </Row>
              {showTipPaymentMethod && !hasSinglePaymentMethod && isTipPaymentMethod ? (
                <>
                  <MdSpacing />
                  <Description color={COLORS.TEXT_COLORS.GREY}>Courier tip</Description>
                </>
              ) : null}
            </Row>
            {idx !== shipmentPaymentMethods.length - 1 && <XsSpacing />}
          </React.Fragment>
        );
      })}
    </Column>
  );
};
