// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Button,
  Card,
  Column,
  Description,
  H2,
  Illustration,
  LgPadding,
  LgSpacing,
  MdPadding,
  MdSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { ScrollView } from 'react-native';
import prescription from '~shared/assets/images/prescription.png';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';

type Props = {
  readonly hasPrescriptions: boolean;
  readonly updateOrder: (params: { origin: string }) => void;
  readonly loading: boolean;
};

export const EmptyDeliveriesOverview = ({ hasPrescriptions, updateOrder, loading }: Props) => {
  const { navigate } = useNavigation();

  const isNativeAndNoPrescriptions = getPlatformOS() !== 'web' && !hasPrescriptions;

  const scheduleDelivery = () => {
    updateOrder({
      origin: ORIGIN_NAMES.SCHEDULE_DELIVERY,
    });
    navigate('RouteAppTabNavigator', { screen: 'RouteTabMyMeds' });
  };

  const addMedication = () => {
    navigate('RouteSendPrescriptionOptions', {
      origin: ORIGIN_NAMES.SCHEDULE_DELIVERY,
    });
  };

  // Only show the med list send prescription options if we're on native and there are no prescriptions.
  return (
    <ScrollView>
      <MdPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        {!loading && (
          <Card data-testid="empty-orders-overview-card">
            <LgPadding>
              <Column
                center
                centerHorizontally
                centerVertically
              >
                <Illustration source={prescription} />
                <MdSpacing />
                <H2 center>{isNativeAndNoPrescriptions ? 'Add a medication' : 'Schedule your first order'}</H2>
                <XsSpacing />
                <Description center>
                  {isNativeAndNoPrescriptions
                    ? "Provide your current prescription information and we'll do the rest."
                    : 'Go to your Meds tab to add medications to your cart.'}
                </Description>
              </Column>
              <LgSpacing />
              <Button
                onPress={isNativeAndNoPrescriptions ? addMedication : scheduleDelivery}
                label="Get Started"
              />
            </LgPadding>
          </Card>
        )}
      </MdPadding>
    </ScrollView>
  );
};
