import { push } from 'react-router-redux';
import { connect } from '~shared/store';
import { type ReduxDispatchShared } from '~shared/types';
import CommsVerificationSuccess from './CommsVerificationSuccess';

type DispatchProps = {
  navigateToNextRoute: () => void;
};

type OwnProps = {
  commsChannel: string;
  authorized: boolean;
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared, ownProps: OwnProps): DispatchProps => ({
  navigateToNextRoute: () => {
    if (ownProps.authorized) {
      dispatch(
        push({
          pathname: '/prescriptions',
        }),
      );
    } else {
      // TODO: Get app store route
      dispatch(
        push({
          pathname: '/',
        }),
      );
    }
  },
});

export default connect(null, mapDispatchToProps)(CommsVerificationSuccess);
