// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import { get } from '~shared/helpers/apiHelper';
import { type ReduxDispatch } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const FETCH_MEDICATION_NAMES_LOADING = 'FETCH_MEDICATION_NAMES_LOADING';
const FETCH_MEDICATION_NAMES_SUCCEEDED = 'FETCH_MEDICATION_NAMES_SUCCEEDED';
const FETCH_MEDICATION_NAMES_FAILED = 'FETCH_MEDICATION_NAMES_FAILED';

export type FetchMedicationNamesLoading = {
  type: typeof FETCH_MEDICATION_NAMES_LOADING;
};

export type FetchMedicationNamesSucceeded = {
  type: typeof FETCH_MEDICATION_NAMES_SUCCEEDED;
};

export type FetchMedicationNamesFailed = {
  type: typeof FETCH_MEDICATION_NAMES_FAILED;
  payload: APIError;
  error: true;
};

const fetchingMedicationNames = (): FetchMedicationNamesLoading => {
  return {
    type: FETCH_MEDICATION_NAMES_LOADING,
  };
};

const fetchMedicationNamesSucceeded = (): FetchMedicationNamesSucceeded => {
  return {
    type: FETCH_MEDICATION_NAMES_SUCCEEDED,
  };
};

const fetchMedicationNamesFailed = (payload: APIError): FetchMedicationNamesFailed => {
  return {
    type: FETCH_MEDICATION_NAMES_FAILED,
    error: true,
    payload,
  };
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const fetchMedicationNames = (query: string) => {
  return (dispatch: ReduxDispatch) => {
    dispatch(fetchingMedicationNames());

    const params = {
      query,
    };
    return get('/products/medication_name_search', params).then((json) => {
      if (json.error) {
        dispatch(fetchMedicationNamesFailed(json.error));
        return { error: true };
      }

      dispatch(fetchMedicationNamesSucceeded());
      return json;
    });
  };
};
