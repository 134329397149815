import { LOCATION } from './location';
import { RADIUS } from './radius';
import { STYLE } from './style';

export const BORDERS = {
  SIZE: 1,
  RADIUS,
  STYLE,
  LOCATION,
} as const;

export type BORDERS_RADIUS_SIZE = (typeof RADIUS)[keyof typeof RADIUS];
