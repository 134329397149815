import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { type ReactNode } from 'react';
import { ScrollView, View } from 'react-native';
import styled from 'styled-components/native';

type ScreenWrapperProps = {
  refreshControl?: ReactNode;
};

type SectionProps = {
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  border?: boolean;
  height?: number;
};

export const FullView = styled(View)`
  flex: 1;
`;

export const FullWhiteView = styled(View)`
  flex: 1;
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
`;

export const WhiteBackground = styled(View)`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
`;

export const ScreenWrapper = styled(ScrollView).attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})<ScreenWrapperProps>`
  flex: 1;
`;

export const FlexColumnGroup = styled(View)`
  flex: 1;
  justify-content: space-between;
`;

export const FlexRowGroup = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

// custom styles here to account for the rerendering bug in flatlist.
export const WrappedRow = styled(FlexRowGroup)`
  flex-wrap: wrap;
  align-items: center;
`;

export const FlexAlignCenterWrapper = styled(View)`
  align-items: center;
  justify-content: center;
`;

export const Section = styled(View)<SectionProps>`
  padding-top: ${(props) => (props.noPaddingTop ? 0 : SPACING.STATIC.LG.px)};
  padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : SPACING.STATIC.LG.px)};
  padding-left: ${SPACING.STATIC.MD.px};
  padding-right: ${SPACING.STATIC.MD.px};
  border-radius: ${BORDERS.RADIUS.XS.px};
  ${(props) => (props.border ? `border: ${BORDERS.SIZE}px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};` : '')}
  ${(props) => (props.border ? `margin: ${SPACING.STATIC.MD.px}` : '')}
  ${(props) => (props.height ? `height: ${props.height};` : '')}
`;
