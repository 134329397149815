// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, H3, LgPadding, LgSpacing, XxsSpacing } from '@alto/design-system';
import React, { useContext } from 'react';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type BackorderOrigin } from '../constants';
import { useBackorderedAnalytics } from '../hooks/useBackorderedAnalytics';

type Props = {
  readonly origin: BackorderOrigin;
  readonly prescriptionId: number | undefined;
};

export const BackorderedActionSheet = ({ origin, prescriptionId }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  useBackorderedAnalytics({ origin, prescriptionId, event: EVENTS.C2_BACKORDER_FAQ_VIEWED });

  return (
    <ActionSheetV2
      onBack={closeActionSheet}
      title="Backorder FAQs"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <H3>Why can't I get my medication?</H3>
        <XxsSpacing />
        <Body>
          Alto typically stocks and dispenses this medication, but it is currently on backorder. There is a shortage
          across multiple manufacturers and suppliers.
        </Body>
        <LgSpacing />
        <H3>What can I do to get my medication?</H3>
        <XxsSpacing />
        <Body>
          Our stock availability might change based on our supplier's restock dates. We encourage you to check back with
          us and re-attempt to schedule. If your need is urgent, we recommend working with your prescriber to find a
          pharmacy that has this medication in stock. You can also reach out to our pharmacist team for help.
        </Body>
        <LgSpacing />
        <H3>Will my medication be available soon at Alto?</H3>
        <XxsSpacing />
        <Body>
          We are unable to provide a time frame as availability information provided to us are estimates. Your care is
          important to us and we will support you however we can to minimize any gaps in therapy.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
