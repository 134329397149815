import { type PriceOption } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createSelector } from 'reselect';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { getOrderPricing } from '~shared/features/pricing/selectors/getPricing';

export type PriceOptionsInOrder = Record<number, PriceOption[]>;

const getPriceOptionsInOrder = createSelector([getOrderPricing], (orderPricing) => {
  const itemPricing = orderPricing?.item_pricing || {};

  return TypescriptUtils.objectKeys(itemPricing).reduce<PriceOptionsInOrder>((result, prescriptionID) => {
    const priceOptions = itemPricing[prescriptionID]?.prices || [];
    result[prescriptionID] = priceOptions;

    return result;
  }, {});
});

export default getPriceOptionsInOrder;
