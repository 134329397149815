import { withRehydration } from './withRehydration';
import {
  ADD_TO_CART,
  type ActionAddToCart,
  type ActionClearCart,
  type ActionClearDeliveryWindows,
  type ActionClearEditOrder,
  type ActionExitEditingOrder,
  type ActionFetchDeliveryWindowsFailed,
  type ActionFetchDeliveryWindowsSucceeded,
  type ActionInitExistingOrder,
  type ActionInitSnoozeOrder,
  type ActionRemoveAutoRefills,
  type ActionRemoveFromCart,
  type ActionResetOrdersByIndex,
  type ActionSaveOrderSucceeded,
  type ActionSelectPaymentType,
  type ActionSetOrderAtIndex,
  type ActionSetOrderIndex,
  type ActionSetWasNeedlePromptShown,
  type ActionShowValidationErrors,
  type ActionToggleEditOrderWithAssistant,
  type ActionUpdateCart,
  type ActionUpdateCartAutoRefill,
  type ActionUpdateEditOrder,
  CLEAR_CART,
  CLEAR_DELIVERY_WINDOWS,
  CLEAR_EDIT_ORDER,
  EXIT_EDITING_ORDER,
  FETCH_DELIVERY_WINDOWS_FAILED,
  FETCH_DELIVERY_WINDOWS_SUCCEEDED,
  INIT_EXISTING_ORDER,
  INIT_SNOOZE_ORDER,
  REMOVE_AUTO_REFILLS,
  REMOVE_FROM_CART,
  RESET_ORDERS_BY_INDEX,
  SAVE_ORDER_SUCCEEDED,
  SELECT_PAYMENT_TYPE,
  SET_ORDER_AT_INDEX,
  SET_ORDER_INDEX,
  SET_WAS_NEEDLE_PROMPT_SHOWN,
  SHOW_VALIDATION_ERRORS,
  TOGGLE_EDIT_ORDER_WITH_ASSISTANT,
  UPDATE_CART,
  UPDATE_CART_AUTO_REFILL,
  UPDATE_EDIT_ORDER,
  type hasScheduleableDeliveryFeesInCart,
  type scheduleableSameDayFee,
} from '~shared/actions/cart';
import { itemEquals, itemNotEquals } from '~shared/features/checkout/helpers';
import { type CartAutoRefill, type Order } from '~shared/features/checkout/types';
import { type DeliveryWindow, type PricingResultPaymentType } from '~shared/types';

type Action =
  | ActionAddToCart
  | ActionClearCart
  | ActionClearDeliveryWindows
  | ActionClearEditOrder
  | ActionExitEditingOrder
  | ActionFetchDeliveryWindowsFailed
  | ActionFetchDeliveryWindowsSucceeded
  | ActionInitExistingOrder
  | ActionInitSnoozeOrder
  | ActionRemoveAutoRefills
  | ActionRemoveFromCart
  | ActionResetOrdersByIndex
  | ActionSaveOrderSucceeded
  | ActionSelectPaymentType
  | ActionSetOrderAtIndex
  | ActionSetOrderIndex
  | ActionSetWasNeedlePromptShown
  | ActionShowValidationErrors
  | ActionToggleEditOrderWithAssistant
  | ActionUpdateCart
  | ActionUpdateCartAutoRefill
  | ActionUpdateEditOrder
  | ReturnType<typeof hasScheduleableDeliveryFeesInCart>
  | ReturnType<typeof scheduleableSameDayFee>;

export type StateCart = {
  cart: Order;
  cartLastUpdatedAt: string;
  // ISO date, eg 2021-03-29T18:31:47.400Z
  editOrder: Order;
  editOrderShipmentID: number | null | undefined;
  showingValidationErrors: boolean;
  autoRefills: CartAutoRefill;
  editOrderWithAssistant: boolean;
  editOrderWithSnooze: boolean;
  deliveryWindows: DeliveryWindow[];
  hasScheduleableDeliveryFeesInCart: boolean;
  orderIndex: number;
  ordersByIndex: Record<number, Order>;
};

const initialState: StateCart = {
  cart: {
    selected_payment_types: {},
  },
  cartLastUpdatedAt: new Date().toISOString(),
  editOrder: {},
  editOrderShipmentID: null,
  showingValidationErrors: false,
  autoRefills: {},
  editOrderWithAssistant: false,
  editOrderWithSnooze: false,
  deliveryWindows: [],
  hasScheduleableDeliveryFeesInCart: false,
  orderIndex: 0,
  ordersByIndex: {},
};

function updateAutoRefills(state: StateCart, prescriptionID: number, newAutoRefillStatus: boolean): CartAutoRefill {
  const updatedAutoRefills = { ...state.autoRefills };
  updatedAutoRefills[prescriptionID] = newAutoRefillStatus;

  return updatedAutoRefills;
}

function removeAutoRefills(state: StateCart, prescriptionIDs: number[]): CartAutoRefill {
  const updatedAutoRefills = { ...state.autoRefills };
  prescriptionIDs.forEach((rxID) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete updatedAutoRefills[rxID];
  });

  return updatedAutoRefills;
}

function updatePaymentType(state: StateCart, prescriptionID: number, paymentType: PricingResultPaymentType) {
  let {
    cart: { selected_payment_types },
  } = state;
  selected_payment_types = selected_payment_types || {};
  selected_payment_types[prescriptionID] = paymentType;
  return { ...selected_payment_types };
}

const resetCartDateAndWindows = (state: StateCart, cartKey: 'cart' | 'editOrder') => ({
  ...state,
  [cartKey]: { ...state[cartKey], date: undefined, deliver_before: undefined, deliver_after: undefined },
});

const cart = (state: StateCart = initialState, action: Action): StateCart => {
  switch (action.type) {
    case TOGGLE_EDIT_ORDER_WITH_ASSISTANT: {
      return { ...state, editOrderWithAssistant: !state.editOrderWithAssistant };
    }

    case SET_WAS_NEEDLE_PROMPT_SHOWN: {
      return { ...state, cart: { ...state.cart, needle_prompt_shown: action.payload } };
    }

    case ADD_TO_CART: {
      const { payload: itemKey } = action;
      const finder = itemEquals.bind(null, itemKey);
      const items = state.cart.items || [];
      const inCart = items.find(finder) !== undefined;

      if (!inCart) {
        return {
          ...state,
          cartLastUpdatedAt: new Date().toISOString(),
          cart: { ...state.cart, items: [...items, itemKey] },
        };
      }

      return { ...state };
    }

    case REMOVE_FROM_CART: {
      const { payload: item } = action;
      const items = state.cart.items || [];
      const filteredItems = items.filter((itemInCart) => itemNotEquals(itemInCart, item));
      const prescriptionID = item.resource_id.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [Number(prescriptionID)]: _deletedSelectedPaymentType, ...filteredPaymentTypes } =
        state.cart.selected_payment_types || {};
      return {
        ...state,
        cartLastUpdatedAt: new Date().toISOString(),
        cart: { ...state.cart, items: filteredItems, selected_payment_types: filteredPaymentTypes },
      };
    }

    case CLEAR_CART:
      return {
        ...state,
        cartLastUpdatedAt: new Date().toISOString(),
        cart: {
          selected_payment_types: {},
        },
        orderIndex: 0,
        ordersByIndex: {},
      };

    case CLEAR_EDIT_ORDER:
      return { ...state, editOrder: {}, orderIndex: 0, ordersByIndex: {} };

    case UPDATE_CART: {
      const newState = {
        ...state,
        cartLastUpdatedAt: new Date().toISOString(),
        cart: { ...state.cart, ...action.payload },
      };

      // reset cart delivery windows if we've changed the address
      if (action.payload.address_id) {
        return resetCartDateAndWindows(newState, 'cart');
      }

      return newState;
    }

    case RESET_ORDERS_BY_INDEX: {
      return {
        ...state,
        ordersByIndex: {},
        orderIndex: 0,
      };
    }

    case SET_ORDER_AT_INDEX: {
      return {
        ...state,
        ordersByIndex: {
          ...state.ordersByIndex,
          [action.payload.orderIndex]: action.payload.order,
        },
      };
    }

    case SET_ORDER_INDEX: {
      return {
        ...state,
        orderIndex: action.payload,
      };
    }

    case UPDATE_EDIT_ORDER: {
      const newState = { ...state, editOrder: { ...state.editOrder, ...action.payload } };

      // reset cart delivery windows if we've changed the address
      if (action.payload.address_id) {
        return resetCartDateAndWindows(newState, 'editOrder');
      }

      return newState;
    }

    case INIT_EXISTING_ORDER:
      return {
        ...state,
        editOrder: {
          ...action.payload.order,
          // Default tip amount to 0 if it's not set for an existing order.
          tip_amount: action.payload.order.tip_amount || 0,
        },
        editOrderShipmentID: action.payload.shipmentID,
      };
    case INIT_SNOOZE_ORDER:
      return { ...state, editOrderWithSnooze: true };

    case EXIT_EDITING_ORDER:
      return {
        ...state,
        editOrder: {},
        editOrderShipmentID: null,
        showingValidationErrors: false,
        editOrderWithAssistant: false,
        editOrderWithSnooze: false,
      };

    case SAVE_ORDER_SUCCEEDED:
      return {
        ...state,
        showingValidationErrors: false,
        cartLastUpdatedAt: new Date().toISOString(),
        cart: { ...state.cart, selected_payment_types: {} },
        editOrderWithAssistant: false,
        editOrderWithSnooze: false,
      };

    case SHOW_VALIDATION_ERRORS:
      return { ...state, showingValidationErrors: true };

    case SELECT_PAYMENT_TYPE:
      return {
        ...state,
        cart: {
          ...state.cart,
          selected_payment_types: updatePaymentType(state, action.payload.prescriptionID, action.payload.paymentType),
        },
      };

    case UPDATE_CART_AUTO_REFILL: {
      const { prescriptionID, newAutoRefillStatus } = action.payload;
      const updatedAutoRefills = updateAutoRefills(state, prescriptionID, newAutoRefillStatus);
      return { ...state, autoRefills: updatedAutoRefills || {} };
    }

    case REMOVE_AUTO_REFILLS: {
      const { prescriptionIDs } = action.payload;
      const updatedAutoRefills = removeAutoRefills(state, prescriptionIDs);
      return { ...state, autoRefills: updatedAutoRefills || {} };
    }

    case FETCH_DELIVERY_WINDOWS_SUCCEEDED: {
      const stateUpdates = {
        deliveryWindows: action.payload.windows,
      };
      const cartUpdates: Order = {};
      const editOrderUpdates: Order = {};

      return {
        ...state,
        ...stateUpdates,
        cart: {
          ...state.cart,
          ...cartUpdates,
        },
        editOrder: {
          ...state.editOrder,
          ...editOrderUpdates,
        },
      };
    }
    case FETCH_DELIVERY_WINDOWS_FAILED:
      return { ...state, deliveryWindows: [] };

    case CLEAR_DELIVERY_WINDOWS:
      return { ...state, deliveryWindows: [] };

    case 'HAS_SCHEDULEABLE_DELIVERY_FEES_IN_CART': {
      const date = action.payload !== state.hasScheduleableDeliveryFeesInCart ? undefined : state.cart.date;
      return { ...state, hasScheduleableDeliveryFeesInCart: action.payload, cart: { ...state.cart, date } };
    }

    default:
      return state;
  }
};

export default withRehydration(cart, initialState);
