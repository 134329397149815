// @owners { team: patients-team }
import { useQuery } from '@tanstack/react-query';
import { staleTimes } from './constants';
import { queries } from './query-keys';

export const useProduct = ({ productID }: { productID: number | null | undefined }) => {
  const { data, isError, isPending, isSuccess } = useQuery({
    ...queries.products.product({ productID }),
    enabled: !!productID,
    staleTime: staleTimes.oneDay,
  });

  return {
    isError,
    isProductPending: isPending,
    isSuccess,
    product: data?.product,
  };
};
