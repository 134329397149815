import { COLORS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { Keyboard, type LayoutChangeEvent, type TextInputProps, View } from 'react-native';
import styled from 'styled-components/native';
import { Border } from '../../borders/src/Border';
import { XsPadding, XxsPadding } from '../../containers';
import { MdSpacing } from '../../separators';
import { Text } from '../../typography/src/Text';
import { InputText } from './InputText/InputText';
import { QuickReplyBar } from './QuickReplyBar';

const StyledBackground = styled(View)`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
`;

type InputMessageProps = {
  readonly defaultValue?: string;
  readonly inputAccessibilityLabel?: string;
  readonly buttonAccessibilityLabel?: string;
  readonly dismissKeyboardOnSubmit?: boolean;
  readonly clearOnSubmit?: boolean;
  readonly returnKeyType?: TextInputProps['returnKeyType'];
  readonly keyboardType?: TextInputProps['keyboardType'];
  readonly sending?: boolean;
  readonly placeholder?: string;
  readonly value?: string;
  readonly disabled?: boolean;
  readonly quickReplies?: string[];
  readonly messagePrompt?: string;
  readonly onPressSend: (arg0: string) => void;
  readonly onResize?: (size: { width: number; height: number }) => void;
};

export const InputMessage = ({
  defaultValue,
  inputAccessibilityLabel,
  buttonAccessibilityLabel,
  dismissKeyboardOnSubmit,
  clearOnSubmit,
  returnKeyType = 'default',
  keyboardType = 'default',
  sending,
  placeholder,
  value,
  disabled,
  quickReplies,
  messagePrompt,
  onPressSend,
  onResize,
}: InputMessageProps) => {
  const [message, setMessage] = React.useState<string>(value || defaultValue || '');

  const handleChangeText = (text: string) => {
    setMessage(text);
  };

  const handleSubmit = () => {
    onPressSend(message);

    if (dismissKeyboardOnSubmit) {
      Keyboard.dismiss();
    }

    if (clearOnSubmit) {
      setMessage('');
    }
  };

  const handleLayout = (e: LayoutChangeEvent) => {
    const { layout } = e.nativeEvent;

    if (onResize) {
      onResize({
        width: layout.width,
        height: layout.height,
      });
    }
  };

  return (
    <Border
      radius="NONE"
      hideBottom
      hideLeft
      hideRight
    >
      <StyledBackground onLayout={handleLayout}>
        <XsPadding>
          <XxsPadding
            leftPadding={SPACING.STATIC.XS}
            rightPadding={SPACING.STATIC.XS}
          >
            {!!messagePrompt && (
              <>
                <Text textSize="mini">{messagePrompt}</Text>
                <MdSpacing />
              </>
            )}
            {!!quickReplies?.length && (
              <>
                <QuickReplyBar
                  options={quickReplies}
                  onSend={onPressSend}
                />
                <MdSpacing />
              </>
            )}
            <InputText
              accessibilityLabel={inputAccessibilityLabel}
              blurOnSubmit={returnKeyType !== 'default'}
              defaultValue={defaultValue}
              disabled={disabled}
              keyboardType={keyboardType}
              onChangeText={handleChangeText}
              placeholder={placeholder}
              returnKeyType={returnKeyType}
              value={message}
              numberOfLines={1}
              rightIconProps={{
                name: sending ? 'loader' : 'paperairplane',
                buttonType: sending || !message ? 'disabled' : 'primary',
                type: 'grey',
                spin: sending,
                onPress: handleSubmit,
                accessibilityLabel: buttonAccessibilityLabel,
                position: 'bottom',
              }}
            />
          </XxsPadding>
        </XsPadding>
      </StyledBackground>
    </Border>
  );
};
