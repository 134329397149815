export function immutableUpsert<T extends Record<string, any>>({
  state,
  payload,
  idKey = 'id',
}: {
  state: T[];
  payload: T;
  idKey?: string;
}): T[] {
  const id = payload[idKey];
  const index = state.findIndex((item) => item[idKey] === id);
  const newState = [...state];

  if (index >= 0) {
    newState[index] = { ...newState[index], ...payload };
    return newState;
  }

  newState.push(payload);
  return newState;
}

export function immutableBulkUpsert<T extends Record<string, any>>({
  state,
  payload,
  idKey = 'id',
}: {
  state: T[];
  payload: T[];
  idKey?: string;
}): T[] {
  return payload.reduce(
    (out, record) => {
      const id = record[idKey];
      const index = state.findIndex((item) => id === item[idKey]);

      if (index >= 0) {
        out[index] = { ...out[index], ...record };
      } else {
        out.push(record);
      }

      return out;
    },
    [...state],
  );
}
