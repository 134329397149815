// @owners { team: patients-team }
import { Body, Column, LgPadding, LgSpacing, Row, XsSpacing } from '@alto/design-system';
import React from 'react';
import { type InsuranceSource } from '~shared/constants';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import type { Insurance as InsuranceType } from '~shared/types';
import { InsuranceButton } from './InsuranceButton';

type Props = {
  readonly hasActiveInsurances: boolean;
  readonly insurances: InsuranceType[];
  readonly onAddInsurance?: () => void; // web callback only used in onboarding flow
  readonly source: InsuranceSource;
};

const CashPayInsuranceContent = () => {
  return (
    <>
      <Body fontFamily="bold">You’re paying out of pocket</Body>
      <XsSpacing />
      <Body>
        Since you’ve elected to not use insurance, we’ll show you the out of pocket prices for your medications. We
        still apply any available coupons or discounts to get you the best price possible.
      </Body>
      <LgSpacing />
      <Body fontFamily="bold">Did your coverage change?</Body>
      <XsSpacing />
      <Body>Provide your new insurance details and moving forward we’ll bill medications to your insurance.</Body>
    </>
  );
};

export const CashPayInsurance = ({ hasActiveInsurances, insurances, onAddInsurance, source }: Props) => {
  const isWeb = getPlatformOS() === 'web';
  return (
    <Column flexGrow={1}>
      {isWeb ? (
        <CashPayInsuranceContent />
      ) : (
        <LgPadding>
          <CashPayInsuranceContent />
        </LgPadding>
      )}
      {/** in web onboarding hide add benefits button, this is accounted for in the OnboardingInsuranceForm */}
      {isWeb && source === 'profile' ? (
        <Row
          right
          testID="insurance-button-container"
        >
          <InsuranceButton
            hasActiveInsurances={hasActiveInsurances}
            insurances={insurances}
            onAddInsurance={onAddInsurance}
            source={source}
          />
        </Row>
      ) : null}
      {!isWeb ? (
        <InsuranceButton
          hasActiveInsurances={hasActiveInsurances}
          insurances={insurances}
          source={source}
        />
      ) : null}
    </Column>
  );
};
