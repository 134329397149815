// @owners { team: patients-team }
import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { type ModalType, closeModal } from '~shared/actions/modal';
import getIsWelcomeBackDisplayed from '~shared/selectors/ui/modal/getIsWelcomeBackDisplayed';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { urlFromOnboardState } from '~web/features/onboarding/helpers/urlHandler';
import getIsOnboardingRoute from '~web/features/onboarding/selectors/getIsOnboardingRoute';
import getUserOnboardState from '~web/features/onboarding/selectors/getUserOnboardState';

type Props = {
  readonly authorized?: boolean;
  readonly onboardingUrl: string | null | undefined;
  readonly pathIsOnboardingRoute: boolean;
  readonly location: Record<string, unknown>;
  readonly redirect: (arg0: string) => void;
  readonly params: Record<string, unknown>;
  readonly isWelcomeBackDisplayed: boolean;
  readonly closeModal: (name: ModalType) => void;
};

export default function requireAuthentication(Component: React.ComponentType, _: any, isUsingNextParam: boolean) {
  class AuthenticatedComponent extends React.Component<Props> {
    static defaultProps = {
      authorized: false,
      redirect: null,
    };

    UNSAFE_componentWillMount() {
      this.checkAuth();
    }

    UNSAFE_componentWillReceiveProps() {
      this.checkAuth();
    }

    checkAuth() {
      const {
        authorized,
        location: { pathname },
        redirect,
        onboardingUrl,
        pathIsOnboardingRoute,
        isWelcomeBackDisplayed,
        closeModal,
      } = this.props;

      if (!authorized) {
        if (isUsingNextParam) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          redirect(`/login?next=${pathname}`);
        } else {
          redirect('/login');
        }
      }

      if (!pathIsOnboardingRoute && onboardingUrl) {
        if (isWelcomeBackDisplayed) {
          // The onboarding resume modal is sometimes opened in middlewares/onboarding-resume.js,
          // and is intended to be shown for organic onboarding users only.
          // Close this modal when redirecting to the provider-referred onboarding flow.
          closeModal('ONBOARDING_RESUME_MODAL');
        }

        redirect(onboardingUrl);
      }
    }

    render() {
      // @ts-expect-error TS(2322): Type '{ params: Record<string, unknown>; }' is not assignable to type 'IntrinsicAttributes & { children?... (Delete me to see the full error)
      return <div>{this.props.authorized === true ? <Component params={this.props.params} /> : null}</div>;
    }
  }

  const mapStateToProps = (state: ReduxStateShared) => {
    const onboardState = getUserOnboardState(state);
    return {
      authorized: state.auth.authorized,
      onboardingUrl: urlFromOnboardState(onboardState),
      pathIsOnboardingRoute: getIsOnboardingRoute(state),
      isWelcomeBackDisplayed: getIsWelcomeBackDisplayed(state),
    };
  };

  // @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
  const mapDispatchToProps = (dispatch) => {
    return {
      // @ts-expect-error TS(7006): Parameter 'path' implicitly has an 'any' type.
      redirect: (path) => {
        dispatch(push(path));
      },
      ...bindActionCreators(
        {
          closeModal,
        },
        dispatch,
      ),
    };
  };

  // @ts-expect-error TS(2345): Argument of type 'typeof AuthenticatedComponent' is not assignable to parameter of type 'ComponentTy... (Delete me to see the full error)
  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
