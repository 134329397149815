import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Card, LgPadding, ListBase, useScreenSize } from '@alto/design-system';
import { PrescriptionStatusTracker } from '@alto/features';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type SourcePrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/source_prescription';
import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { closeModal } from '~shared/actions/modal';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { SECTION_LABELS } from '~shared/features/homescreen/constants';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared } from '~shared/store';
import { type Mutable, PrescriptionInfo, type Props as PrescriptionInfoProps } from './PrescriptionInfo';
import { type Props as PrescriptionTrackerProps } from '~web/features/homescreen/components/prescription-tracker/PrescriptionTrackerModal';
import { MedicationCTA } from '~web/features/homescreen/sections/MedicationCTA';
import pillBottle from '~web/images/pillBottle@3x.png';

const CustomCard = styled(Card)<{ isSMScreenOrBigger?: boolean }>`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && ` margin: 0 0 ${SPACING.STATIC.LG.value * 2}px;`}
  z-index: 0;
`;

type Props = {
  readonly prescriptions: (SourcePrescription | Prescription)[];
  readonly openPrescriptionTrackerModal: (modalProps: PrescriptionTrackerProps) => void;
};

export default function NewPrescriptions({ prescriptions, openPrescriptionTrackerModal }: Props) {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { isSMScreenOrBigger } = useScreenSize();

  const closeNewRxModal = () => {
    dispatch(closeModal('PRESCRIPTION_TRACKER_MODAL'));
  };

  const Rows = prescriptions.map((prescription) => {
    const { name, status } = prescription;

    const prescriptionInfoProps = {
      title: name,
      origin: ORIGIN_NAMES.HOMESCREEN,
    } as Mutable<PrescriptionInfoProps>;

    if ('id' in prescription) {
      // eslint-disable-next-line no-self-assign
      prescription = prescription;

      const { id, image_url, family_member_name, price } = prescription;

      prescriptionInfoProps.byline = family_member_name || undefined;
      prescriptionInfoProps.prescriptionID = id;
      prescriptionInfoProps.imageURL = image_url || undefined;

      if (status === PrescriptionStatusMap.READY) {
        prescriptionInfoProps.description =
          typeof price === 'number' ? formatDollarsWithPlaceholder(price, '') : 'View Price in Cart';
      }
    } else {
      // eslint-disable-next-line no-self-assign
      prescription = prescription;
    }

    const prescriptionId = prescription && 'id' in prescription && prescription?.id;

    prescriptionInfoProps.CTA = (
      <div
        role="button"
        tabIndex={0}
        onClick={(event) => {
          // Prevent clicks on CTA actions from propagating to parent
          event.stopPropagation();
        }}
        onKeyUp={(event) => {
          // Prevent clicks on CTA actions from propagating to parent
          event.stopPropagation();
        }}
      >
        <MedicationCTA
          prescription={prescription}
          openPrescriptionTrackerModal={() => {
            // PROGYNY_EARLY_ORDER_ELIGIBLE is a special case where we need to use the old prescription tracker modal
            // since there's a button that leads to a native only progyny flow
            const useNewerPrescriptionTrackerModal =
              prescription.status !== PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE;
            if (useNewerPrescriptionTrackerModal) {
              setActiveActionSheet(
                <PrescriptionStatusTracker
                  prescriptionId={prescriptionId || undefined}
                  origin="NewPrescriptions"
                  prescriptionStatus={prescription.status}
                  prescriptionName={prescription.name}
                />,
              );
            } else {
              openPrescriptionTrackerModal({
                prescription,
                onClose: closeNewRxModal,
              });
            }
          }}
        />
      </div>
    );

    prescriptionInfoProps.isCTAButton =
      PrescriptionStatusMap.MULTIPLE_PRICES === status ||
      PrescriptionStatusMap.READY === status ||
      PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE === status;

    return {
      key: prescription.name,
      component: <PrescriptionInfo {...prescriptionInfoProps} />,
    };
  });

  return (
    <CustomCard
      title={SECTION_LABELS.NEW_PRESCRIPTIONS}
      illustrationSrc={pillBottle}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.bikeCourier}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <section>
          <ListBase items={Rows} />
        </section>
      </LgPadding>
    </CustomCard>
  );
}
