// @owners { team: patients-team }
import React, { useState } from 'react';
import { withRouter } from 'react-router';
// eslint-disable-next-line import/no-deprecated
import { clearCreateSurveyResponseError, submitSurveyResponse } from '~shared/actions/surveyResponses';
import { useDispatchShared } from '~shared/store';
import { type FormValues, type Question, SurveyPage } from './SurveyPage';

const CES_QUESTIONS: Question[] = [
  {
    order: 1,
    question: 'Alto made it easy to schedule my prescription(s) for delivery.',
    required: true,
    response: null,
    type: 'numeric',
  },
  {
    order: 2,
    question: 'Alto clearly and promptly notified me of my prescription(s).',
    required: false,
    response: null,
    type: 'numeric',
  },
  {
    order: 3,
    question: 'Alto made it easy to sign up.',
    required: false,
    response: null,
    type: 'numeric',
  },
  {
    order: 4,
    question: 'Alto made it easy to find and understand the details of my prescription(s).',
    required: false,
    response: null,
    type: 'numeric',
  },
  {
    order: 5,
    question: 'Alto made the details and requirements of my delivery clear.',
    required: false,
    response: null,
    type: 'numeric',
  },
  {
    order: 6,
    question: 'What can Alto improve?',
    required: false,
    response: null,
    type: 'text',
  },
];

type SurveyResponse = {
  name: string;
  data: string;
  uuid: string;
  version: number;
};

type Props = {
  readonly uuid: string;
};

const SurveyPageContainer = ({ uuid }: Props) => {
  const dispatch = useDispatchShared();
  const [step, setStep] = useState(0);

  const changeStep = (direction?: string) => {
    setStep(direction === 'back' ? step - 1 : step + 1);
  };

  const formatData = (data: FormValues) => {
    return CES_QUESTIONS.map((question: Question, i: number) => {
      question.response = data[i];
      return question;
    });
  };

  const onSubmit = (data: FormValues) => {
    dispatch(clearCreateSurveyResponseError());

    const response: SurveyResponse = {
      name: 'ces',
      data: JSON.stringify(formatData(data)),
      uuid,
      version: 1,
    };

    // eslint-disable-next-line import/no-deprecated
    dispatch(submitSurveyResponse(response));
  };

  return (
    <SurveyPage
      changeStep={changeStep}
      onSubmit={onSubmit}
      question={CES_QUESTIONS[step]}
      step={step}
      totalQuestions={CES_QUESTIONS.length}
    />
  );
};

export default withRouter(SurveyPageContainer);
