import { COLORS } from '@alto/design-library-tokens';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { View } from 'react-native';
import { Pressable } from '../../../buttons';
import { AltoIcon } from '../../../icon';
import { ListItem } from '../../../lists';
import { type ListItemProps } from '../../../lists/src/ListItem/ListUtils';
import { type Tag } from '../../../tags';

type CheckboxState = Record<string, boolean>;

type CheckboxGroupProviderValue = {
  checkState?: CheckboxState;
  groupIsDisabled?: boolean;
  onPressCheckbox: (value: string) => void;
};

const CheckboxGroupProvider = React.createContext<CheckboxGroupProviderValue>({ onPressCheckbox: () => null });

export type InputCheckboxGroupProps = {
  readonly onValueChange: (value: CheckboxState) => void;
  readonly children: React.ReactElement<typeof InputCheckbox>[] | React.ReactElement<typeof InputCheckbox>;
  readonly disabled?: boolean;
  readonly initialState: CheckboxState;
};

const toggleCheckbox = (checkboxName: string) =>
  ({
    type: 'TOGGLE_CHECK',
    payload: checkboxName,
  }) as const;

const CheckboxReducer = (state: CheckboxState, action: ReturnType<typeof toggleCheckbox>): CheckboxState => {
  switch (action.type) {
    case 'TOGGLE_CHECK':
      return { ...state, [action.payload]: !state[action.payload] };
    default:
      return state;
  }
};

export const InputCheckboxGroup = ({ initialState, onValueChange, children, disabled }: InputCheckboxGroupProps) => {
  const [state, dispatch] = useReducer(CheckboxReducer, initialState);

  useEffect(() => {
    onValueChange(state);
  }, [state, onValueChange]);

  const onPressCheckbox = useCallback((checkboxName: string) => {
    dispatch(toggleCheckbox(checkboxName));
  }, []);

  const context = useMemo(
    () => ({ checkState: state, onPressCheckbox, groupIsDisabled: disabled }),
    [disabled, onPressCheckbox, state],
  );

  return (
    <CheckboxGroupProvider.Provider value={context}>
      <View accessibilityState={{ disabled }}>{children}</View>
    </CheckboxGroupProvider.Provider>
  );
};

export type InputCheckboxProps = {
  /**
   * Primary label for the checkbox input.
   */
  readonly label: string;
  /**
   * Corresponds to the html name attribute.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#name
   */
  readonly name: string;
  /**
   * Disables interaction with the checkbox, and colors it correspondingly.
   */
  readonly disabled?: boolean;
  /**
   * Do not use this to handle form state unless absolutely required, only use it for side-effects.
   * Prefer using InputCheckboxGroup to handle state change management instead.
   */
  readonly onPress?: () => void;
  /**
   * The value of the checkbox input (true or false).
   */
  readonly value?: boolean;
  /**
   * Tag that appears below the primary label.
   */
  readonly tag?: React.ReactElement<typeof Tag>;
  /**
   * Determines which side the check indicator is rendered on, right (default) or left.
   */
  readonly checkPlacement?: 'right' | 'left';
  /**
   * Content on the right side of the component that can be shown when the checkPosition is set to "left"
   */
  readonly RightContent?: ListItemProps['RightContent'];
  /**
   * Disables the highlighted state of the input checkbox when selected.
   */
  readonly noHighlight?: boolean;
  /**
   * Optional test ID to help on click events and e2e testing
   */
  readonly testID?: string;
} & Pick<
  ListItemProps,
  | 'LeftContent'
  | 'descriptions'
  | 'byline'
  | 'tagPlacement'
  | 'TitlePressableIcon'
  | 'titlePressableIconPlacement'
  | 'fullBleed'
>;

/*
  This component can be used in two ways:
    * (recommended): Managed via InputCheckboxGroup
    * on its own, with its parent component managing checkbox state

  When used with InputCheckboxGroup you just put an onValueChange listener in the Group component and that's it:

  ```ts
    // define this outside of your component or with useMemo
    const INITIAL_STATE = { sms: false, wundercomm: false };

    // component
    <InputCheckboxGroup onValueChange={handleChange('preferred_comms')} initialState={INITIAL_STATE}>
      <InputCheckbox name="sms" label="SMS" />
      <InputCheckbox name="wundercomm" label="Secure In-App Message" />
    </InputCheckboxGroup>
  ```

  When used on its own you must manage and supply the checkbox value yourself:

  ```ts
  const [sms, setSms] = useState(false);
  const [wundercomm, setWundercomm] = useState(false);

  const handleSmsPress = () => setSms(!sms)
  const handleWundercommPress = () => setWundercomm(!wundercomm)

  return (
    <View>
      <InputCheckbox name="sms" label="SMS" onPress={handleSmsPress} value={sms} />
      <InputCheckbox
        name="wundercomm"
        label="Secure In-App Message"
        onPress={handleWundercommPress}
        value={wundercomm}
      />
    </View>
  )
  ```

 */
export const InputCheckbox = ({
  byline,
  checkPlacement = 'right',
  descriptions,
  disabled,
  fullBleed,
  label,
  LeftContent,
  RightContent,
  name,
  onPress,
  tag,
  tagPlacement,
  TitlePressableIcon,
  titlePressableIconPlacement,
  value,
  noHighlight,
  testID = undefined,
}: InputCheckboxProps) => {
  // Web-only state to render effects on hover
  const [isHovering, setIsHovering] = useState(false);
  const checkboxContext = useContext(CheckboxGroupProvider);
  const { checkState, onPressCheckbox, groupIsDisabled } = checkboxContext || {};

  const handleCheckboxPress = useCallback(() => {
    onPressCheckbox(name);
    onPress?.();
  }, [onPressCheckbox, onPress, name]);

  const isChecked = value ?? !!checkState?.[name];
  const isDisabled = !!disabled || !!groupIsDisabled;

  const handleHoverIn = () => {
    setIsHovering(true);
  };

  const handleHoverOut = () => {
    setIsHovering(false);
  };

  const checkIndicator = useMemo(
    () => (
      <AltoIcon
        name={isChecked ? 'checkbox-duo' : 'checkbox'}
        type={isDisabled ? 'disabled' : undefined}
      />
    ),
    [isChecked, isDisabled],
  );

  const shouldShowBackground = !noHighlight && (isChecked || isHovering);
  return (
    <Pressable
      backgroundColor={shouldShowBackground ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER : 'transparent'}
      onPress={handleCheckboxPress}
      accessibilityRole="checkbox"
      accessibilityState={{ checked: isChecked, disabled: isDisabled }}
      accessibilityChecked={isChecked}
      disabled={isDisabled}
      // Web-only props to render effects on hover
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      testID={testID}
    >
      <ListItem
        title={label}
        RightContent={checkPlacement === 'right' ? checkIndicator : RightContent}
        LeftContent={checkPlacement === 'left' ? checkIndicator : LeftContent}
        disabled={isDisabled}
        tags={tag}
        tagPlacement={tagPlacement}
        descriptions={descriptions}
        byline={byline}
        TitlePressableIcon={TitlePressableIcon}
        titlePressableIconPlacement={titlePressableIconPlacement}
        backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
        fullBleed={fullBleed}
      />
    </Pressable>
  );
};
