// @owners { team: patients-team }
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { useEffect, useState } from 'react';
import { getUpdatedEssentialQuantityIsBelowWaivedDeliveryFeeMin } from '~shared/features/same-day-delivery/selectors/getUpdatedEssentialQuantityIsBelowWaivedDeliveryFeeMin';
import { getDeliveryFeeFromShipment } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getIsFertilityUser } from '~shared/features/users/selectors/getIsFertilityUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useQueryShipmentFeesAvailable, useQueryShipmentFeesConfig } from '~shared/queries';
import { useSelectorShared } from '~shared/store';

type Props = {
  essential: AddOnOtc;
  initialQuantity: number;
  quantity: number;
  quantityChanged: boolean;
  shipmentID?: number | null | undefined;
};

export const useEssentialsDeliveryWaiveFeeWarning = ({
  essential,
  initialQuantity,
  quantity,
  quantityChanged,
  shipmentID,
}: Props) => {
  const [showDeliveryWaiveFeeWarning, setShowDeliveryWaiveFeeWarning] = useState(false);
  const [message, setMessage] = useState('');
  const currentUserID = useSelectorShared(getCurrentUserID);
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID }));
  const deliveryFee = shipment && getDeliveryFeeFromShipment(shipment);
  const isFertilityUser = useSelectorShared((state) => getIsFertilityUser(state, { userID: currentUserID }));
  const { shipmentFeeAvailableHash } = useQueryShipmentFeesAvailable();
  const { shipmentFeeConfigHash } = useQueryShipmentFeesConfig();

  const feeType = deliveryFee?.fee_type;
  const availableFee = feeType ? shipmentFeeAvailableHash[feeType]?.amount : undefined;
  const hasDeliveryFeeWaived = availableFee === 0;
  const feeConfigData = feeType ? shipmentFeeConfigHash[feeType] : null;
  const minimumThresholdToWaiveFee = feeConfigData?.minimum_threshold_to_waive_fee;
  const eligibleForDeliveryWaivedFee = minimumThresholdToWaiveFee && hasDeliveryFeeWaived && isFertilityUser;
  const updatedQuantityBelowDeliveryFeeMinimum = useSelectorShared((state) =>
    eligibleForDeliveryWaivedFee && quantityChanged && Boolean(shipmentID)
      ? getUpdatedEssentialQuantityIsBelowWaivedDeliveryFeeMin(state, {
          initialQuantity,
          updatedQuantity: quantity,
          essentialPrice: essential.price,
          minimumThresholdToWaiveFee: minimumThresholdToWaiveFee.value,
        })
      : false,
  );

  useEffect(() => {
    if (updatedQuantityBelowDeliveryFeeMinimum !== showDeliveryWaiveFeeWarning) {
      setShowDeliveryWaiveFeeWarning(updatedQuantityBelowDeliveryFeeMinimum);
      setMessage(
        `This would make your subtotal under ${minimumThresholdToWaiveFee?.display_value}. As a result, a delivery fee will be added to your order.`,
      );
    }
  }, [updatedQuantityBelowDeliveryFeeMinimum, showDeliveryWaiveFeeWarning, minimumThresholdToWaiveFee?.display_value]);

  return {
    message,
    showDeliveryWaiveFeeWarning,
  };
};
