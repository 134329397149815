// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  H2,
  LgPadding,
  LgSpacing,
  XsSpacing,
} from '@alto/design-system';
import React, { useContext } from 'react';
import { updateCashPay } from '~shared/actions/users';
import { type InsuranceSource } from '~shared/constants';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getPossessiveName } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';

type Props = {
  readonly source: InsuranceSource;
  readonly hasAllInactiveInsurances?: boolean;
  readonly onConfirm?: () => void;
};

export const ConfirmCashPayActionSheet = ({ hasAllInactiveInsurances, onConfirm, source }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();

  const selectedUser = useSelectorShared(getSelectedUser);
  const currentUserID = useSelectorShared(getCurrentUserID);
  const userID = selectedUser?.id || currentUserID;
  const possessiveName =
    selectedUser && selectedUser.id !== currentUserID
      ? getPossessiveName(selectedUser.preferred_first_name ?? selectedUser.first_name)
      : 'your';

  const handlePress = async () => {
    if (!userID) return;
    const { success } = await dispatch(updateCashPay(userID, true));

    if (success) {
      trackEvent({
        event: EVENTS.CASH_PAY_ONLY_SET,
        params: {
          source,
          'new value': true,
        },
      });
    }

    if (onConfirm) onConfirm();
    closeActionSheet();
  };

  return (
    <ActionSheetV2
      title="Are you sure?"
      analyticsName="confirm cash pay"
      buttons={[
        <Button
          key="continue-without-insurance"
          width="full"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handlePress}
          label="Continue without benefits"
        />,
      ]}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {hasAllInactiveInsurances ? (
          <Body>
            We’ll remove inactive benefits from your profile, and only show you the out-of-pocket prices for your
            medications going forward. We’ll still apply any available coupons or discounts to get you the best price
            possible.
          </Body>
        ) : (
          <Body>
            Going forward we’ll only show you the out-of-pocket prices for {possessiveName} medications. We’ll still
            apply any available coupons or discounts to get you the best price possible.
          </Body>
        )}
        <LgSpacing />
        <H2>Can I change this later?</H2>
        <XsSpacing />
        <Body>
          Absolutely! You can update {possessiveName} insurance information at any time in the &quot;Account&quot; tab.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
