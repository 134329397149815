import type { Errback } from '~shared/types/websocket';

type Options = {
  endpoint: string;
  onOpen: () => void;
  onError: Errback;
  onClose: Errback;
  onMessage: (arg0: any) => void;
};
/*
 * Connection manages a single WebSocket connection,
 * with logic for re-opening unexpectedly closed connections.
 * This class should remain agnostic about the mechanics of sending
 * and receiving messages over the connection, with the exception of
 * a public `send` method that handles marshalling payloads into JSON.
 */

export default class Connection {
  options: Options;
  retryAttempt: number;
  // @ts-expect-error TS(2564): Property 'ws' has no initializer and is not definitely assigned in the constructor.
  ws: WebSocket;

  constructor(options: Options) {
    this.options = options;
    this.retryAttempt = 0;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async connect() {
    if (this.connectionStarted()) {
      return;
    }

    this.ws = new WebSocket(this.options.endpoint);

    this.ws.onopen = this.options.onOpen;
    this.ws.onerror = this.options.onError;
    this.ws.onmessage = this.options.onMessage;

    this.ws.onclose = (e: any) => {
      this.handleCloseEvent(e);
    };
  }

  handleCloseEvent(e: any) {
    this.options.onClose(e);

    if (e.wasClean) {
      // this should probably never happen, given we want to run exactly one websocket all the time.
      return;
    }

    this.reconnect();
  }

  // Deem a connection successful when we get our first acknowledgement back from the server.
  // TODO: we could extend this to mark specific channels as "connected" when we
  // receive the acknowledgement for that channel. In general we don't have a problem with this.
  handleAck() {
    this.retryAttempt = 0;
  }

  reconnect() {
    this.retryAttempt++;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(() => this.connect(), 2 ** Math.min(this.retryAttempt, 6) * 1000);
  }

  isReady(): boolean {
    return this.ws && this.ws.readyState === WebSocket.OPEN;
  }

  connectionStarted(): boolean {
    return this.ws && (this.ws.readyState === WebSocket.OPEN || this.ws.readyState === WebSocket.CONNECTING);
  }

  send(message: Record<string, any>): void {
    if (!this.isReady()) {
      return;
    }

    const payload = JSON.stringify(message);
    this.ws.send(payload);
  }
}
