import { type PreferredCommunicationMethod, type User } from '~shared/types';

export type CommunicationPreferencesFormFields = {
  preferred_communication?: PreferredCommunicationMethod | undefined;
  phi_auth_data?: {
    sms_authorized: boolean | undefined;
    email_authorized: boolean | undefined;
    push_authorized: boolean | undefined;
  };
  secondary_communication_preference?: PreferredCommunicationMethod | undefined;
};

export type UserPhiAuthValues = {
  sms_authorized?: boolean;
  push_authorized?: boolean;
  email_authorized?: boolean;
  user_prompted?: boolean;
};

export type ValidationErrors = {
  preferred_communication?: string;
  secondary_communication_preference?: string;
};

type Option = {
  value: string;
  label: string;
};

export const CALL = 'call';
const callOption: Option = {
  value: CALL,
  label: 'Call',
};

export const SECURE_MESSAGE = 'secure_message';
const secureOption: Option = {
  value: SECURE_MESSAGE,
  label: 'Secure in-app messages',
};

export const TEXT = 'text';
const textOption: Option = {
  value: TEXT,
  label: 'Text/SMS',
};

export const EMAIL = 'email';
const emailOption: Option = {
  value: EMAIL,
  label: 'Email',
};

export const PREFERRED_COMMUNICATION_OPTIONS: Option[] = [secureOption, textOption, emailOption, callOption];
export const SECONDARY_COMMUNICATION_PREFERENCE_OPTIONS: Option[] = [textOption, emailOption];

export const secondaryCommString = (
  primaryOption: User['preferred_communication'],
  phi: User['phi_auth_info'],
  secondaryCommunicationPreference: User['secondary_communication_preference'],
): string => {
  switch (primaryOption) {
    case SECURE_MESSAGE:
      return secondaryCommunicationPreference ? `(Notification via ${secondaryCommunicationPreference})` : '';

    case TEXT:
      return `(${phi?.sms_authorized ? 'Include' : 'Don’t include'} health information)`;

    case EMAIL:
      return `(${phi?.email_authorized ? 'Include' : 'Don’t include'} health information)`;

    default:
      return '';
  }
};

export const validate = (formFields: CommunicationPreferencesFormFields): ValidationErrors => {
  const { preferred_communication, secondary_communication_preference } = formFields;

  const validationErrors = {};

  if (!preferred_communication) {
    // @ts-expect-error TS(2339): Property 'preferred_communication' does not exist on type '{}'.
    validationErrors.preferred_communication = 'Please select a communication preference';
  }

  // if secure, ensure secondary comms preference
  if (preferred_communication === SECURE_MESSAGE && !secondary_communication_preference) {
    // @ts-expect-error TS(2339): Property 'secondary_communication_preference' does not exist on type '{}'.
    validationErrors.secondary_communication_preference = 'Please select a notification type';
  }

  return validationErrors;
};
