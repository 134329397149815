// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ACCOUNT_CATEGORY,
  ASSISTANT_ACCOUNT_ACTIONS,
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantAccountAction,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { MenuOption } from './MenuOption';

export const AccountActionSheet = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();

  const { saveStack, closeActionSheet } = useContext(ActionSheetContext);
  const user = useSelectorShared(getCurrentUser);

  const trackAccountActionPressed = (action: AssistantAccountAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.ACCOUNT_ACTION_CHOSEN,
        action,
        category: ASSISTANT_CATEGORIES.ACCOUNT,
      },
    });
  };

  const handleInsurancePress = () => {
    closeActionSheet();
    trackAccountActionPressed('insurance');
    if (!user) return;
    navigate('RouteUserProfile', { userID: user.id });
  };

  const handleContactInfoPress = () => {
    closeActionSheet();
    trackAccountActionPressed('contact_info');
    navigate('RouteContactInfo');
  };

  const handlePaymentMethodsPress = () => {
    closeActionSheet();
    trackAccountActionPressed('payment_methods');
    navigate('RoutePayments');
  };

  const handleReceiptsPress = () => {
    closeActionSheet();
    trackAccountActionPressed('download_receipts');
    navigate('RoutePayments');
  };

  const handleAddFamilyMemberPress = () => {
    closeActionSheet();
    trackAccountActionPressed('add_family_member');
    navigate('RouteAddFamilyMember');
  };

  const handleSomethingElsePress = () => {
    trackAccountActionPressed('something_else');
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      messagePrompt: MESSAGE_PROMPTS.HELP_WITH_ACCOUNT,
      origin: ASSISTANT_CHAT_ORIGINS.ACCOUNT,
      category: `${ACCOUNT_CATEGORY}.${ASSISTANT_ACCOUNT_ACTIONS.SOMETHING_ELSE}`,
      subject: ASSISTANT_MESSAGE_SUBJECTS.ACCOUNT,
    });
  };

  const items = [
    {
      key: 'insurance',
      component: (
        <MenuOption
          iconName="idcard-duo"
          text="View or update insurance"
          onPress={handleInsurancePress}
        />
      ),
    },
    {
      key: 'contact-info',
      component: (
        <MenuOption
          iconName="mail-duo"
          text="View or update contact info"
          onPress={handleContactInfoPress}
        />
      ),
    },
    {
      key: 'payment-methods',
      component: (
        <MenuOption
          iconName="creditcard-duo"
          text="Manage payment methods"
          onPress={handlePaymentMethodsPress}
        />
      ),
    },
    {
      key: 'receipts',
      component: (
        <MenuOption
          iconName="receipt-duo"
          text="Download receipts"
          onPress={handleReceiptsPress}
        />
      ),
    },
    {
      key: 'add-family-member',
      component: (
        <MenuOption
          iconName="people-duo"
          text="Add family member"
          onPress={handleAddFamilyMemberPress}
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          text="Something else"
          onPress={handleSomethingElsePress}
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="Account"
      analyticsName="assistant account"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
