// @owners { team: patients-team }
import {
  FacilityAlertEndpoint,
  type FacilityAlertEndpointFetchOneRequest,
  type FacilityAlertEndpointFetchOneResponse,
} from '@alto/scriptdash/alto/patient_app/facility_alert/v2/facility_alert_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';

const facilityAlertEndpoint = FacilityAlertEndpoint(apiEndpointHandler);

export const facilityAlerts = createQueryKeys('facilityAlerts', {
  fetchOne: ({ params }: { params: FacilityAlertEndpointFetchOneRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<FacilityAlertEndpointFetchOneResponse> => {
      const response = await facilityAlertEndpoint.fetchOne(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
});
