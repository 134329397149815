export const SET_WELCOME_CAROUSEL_INDEX = 'SET_WELCOME_CAROUSEL_INDEX';
export type ActionSetWelcomeCarouselIndex = {
  type: typeof SET_WELCOME_CAROUSEL_INDEX;
  payload: number;
};
export function setWelcomeCarouselIndex(index: number): ActionSetWelcomeCarouselIndex {
  return {
    type: SET_WELCOME_CAROUSEL_INDEX,
    payload: index,
  };
}
