// @owners { team: patients-team }
export const ASSISTANT_VIEWED_EVENTS = {
  ALTO_CLOSURE_INLINE_ALERT: 'alto_closure_inline_alert',
  ASSISTANT_LANDING_PAGE: 'assistant_landing_page',
  CART_PRICE_CHANGE_ALERT: 'cart_price_change_alert',
  CATEGORY_ACTION_SHEET: 'category_action_sheet',
  DELIVERY_PICKER: 'delivery_picker',
  EDIT_DELIVERY_ACTION_SHEET: 'edit_delivery_action_sheet',
  ENABLE_NOTIFICATIONS: 'enable_notifications',
  FAQ_INDEX: 'faq_index',
  FETCH_MORE_SUPPORT_CASES: 'fetch_more_support_cases',
  FETCH_MORE_NON_CASE_WUNDERCOMS: 'fetch_more_non_case_wundercoms',
  HOLIDAY_CLOSURE_FAQ: 'holiday_closure_faq',
  HOW_TO_ORDER_ACTION_SHEET: 'how_to_order_action_sheet',
  HOW_TO_EDIT_QUANTITY_ACTION_SHEET: 'how_to_edit_quantity_action_sheet',
  LEARN_MORE_FAQ_BUTTON: 'learn_more_faq_button',
  MEDICATION_PICKER: 'medication_picker',
  MEDICATIONS_ACTION_SHEET: 'medications_action_sheet',
  MESSAGE_FORM: 'message_form',
  MESSAGES: 'messages',
  MESSAGING_ACTION_SHEET: 'messaging_action_sheet',
  NEXT_AVAILABLE_DATE_ACTION_SHEET: 'next_available_date_action_sheet',
  ORDERING_ACTION_SHEET: 'ordering_action_sheet',
  PAST_CUTOFF_ACTION_SHEET: 'past_cutoff_action_sheet',
  PAST_DELIVERY_ACTION_SHEET: 'past_delivery_action_sheet',
  PAST_DELIVERY_REPORT_ISSUE_ACTION_SHEET: 'past_delivery_report_issue_action_sheet',
  PRESCRIPTION_STATUS_PAGE: 'prescription_status_page',
  PRICING_BREAKDOWN_ACTION_SHEET: 'pricing_breakdown_action_sheet',
  PRICING_PAYMENTS_ACTION_SHEET: 'pricing_payments_action_sheet',
  PRICE_CONFIRMATION_PAGE: 'price_confirmation_page',
  PRICE_CHANGE_PAGE: 'price_change_page',
  SUPPORT_CASE: 'support_case',
  SUPPORT_CASE_INBOX: 'support_case_inbox', // NOTE: "Messages" tab for support case patients
  SUPPORT_CASE_NOT_FOUND_PAGE: 'support_case_not_found_page',
  TRANSFER_ACTION_SHEET: 'transfer_action_sheet',
  UPCOMING_DELIVERY_ACTION_SHEET: 'upcoming_delivery_action_sheet',
  USER_PROFILE: 'user_profile',
  WHAT_MEDICATION_QUESTION: 'what_medication_question',
  WEB_SUPPORT_CASES_REDIRECT_PLACEHOLDER: 'web_support_Cases_redirect_placeholder',
} as const;

export type AssistantViewedEvents = (typeof ASSISTANT_VIEWED_EVENTS)[keyof typeof ASSISTANT_VIEWED_EVENTS];

export const ASSISTANT_PRESSED_EVENTS = {
  ACCOUNT_ACTION_CHOSEN: 'account_action_chosen',
  ADDRESS_CONFIRMATION_CORRECT: 'address_confirmation_correct',
  ADDRESS_CONFIRMATION_INCORRECT: 'address_confirmation_incorrect',
  ASSISTANT_BACK: 'assistant_back',
  CLOSE_STATIC_FAMILY_MEMBER_CONTACT: 'close_static_family_member_contact',
  CANCEL_DELIVERY: 'cancel_delivery',
  CATEGORY_CHOSEN: 'category_chosen',
  EDIT_DELIVERY_ACTION_CHOSEN: 'edit_delivery_action_chosen',
  EDIT_ORDER_HELP_NEEDED: 'edit_order_help_needed',
  ENABLE_NOTIFICATIONS: 'enable_notifications',
  GET_IT_SOONER_GOT_IT_BUTTON: 'get_it_sooner_got_it_button',
  GET_IT_SOONER_ASK_QUESTION_BUTTON: 'get_it_sooner_ask_question_button',
  HOW_TO_ORDER_ACTION_CHOSEN: 'how_to_order_action_chosen',
  HOMESCREEN_BELL_ICON: 'homescreen_bell_icon',
  LEARN_MORE_FAQ_BUTTON: 'learn_more_faq_button',
  MANUFACTURER_SHORTAGE_LANDING_PAGE: 'manufacturer_shortage_landing_page',
  MANUFACTURER_SHORTAGE_LEARN_MORE: 'manufacturer_shortage_learn_more',
  MANUFACTURER_SHORTAGE_STATUS_TAG: 'manufacturer_shortage_status_tag',
  MEDICATION_PICKER_BACK: 'medication_picker_back',
  MEDICATION_PICKER_CHOSEN_MED: 'medication_picker_chosen_med',
  MEDICATION_PICKER_SOMETHING_ELSE: 'medication_picker_something_else',
  MEDICATION_REMOVED: 'remove_medication',
  MEDICATIONS_ACTION_CHOSEN: 'medications_action_chosen',
  MESSAGE_WITH_SUBJECT_BACK: 'message_with_subject_back',
  NO_DELIVERY_CHOSEN: 'no_delivery_chosen',
  ORDER_IN_PROGRESS_ACTION_CHOSEN: 'order_in_progress_action_chosen',
  ORDERING_ACTION_CHOSEN: 'ordering_action_chosen',
  PAST_DELIVERY_ACTION_CHOSEN: 'past_delivery_action_chosen',
  PAST_DELIVERY_CHOSEN: 'past_delivery_chosen',
  PAST_DELIVERY_REPORT_ISSUE_ACTION_CHOSEN: 'past_delivery_report_issue_action_chosen',
  PRICING_PAYMENTS_ACTION_CHOSEN: 'pricing_payments_action_chosen',
  RECEIPTS_FAQ_DELIVERIES_LINK: 'receipts_faq_deliveries_link',
  RECEIPTS_FAQ_PAYMENTS_LINK: 'receipts_faq_payments_link',
  SAFE_PLACE_CONFIRMATION_FOUND: 'safe_place_confirmation_found',
  SAFE_PLACE_CONFIRMATION_NOT_FOUND: 'safe_place_confirmation_not_found',
  SHORTCUT: 'shortcut',
  SHOW_CONTACT_INFORMATION: 'show_contact_information',
  SUPPORT_CASE_NEW_REQUEST: 'support_case_new_request',
  SUPPORT_CASE_REPLY_BUTTON: 'support_case_reply_button',
  TRANSFER_ACTION_CHOSEN: 'transfer_action_chosen',
  UNCONFIRMED_SHIPMENT_CARD_PRICE_DETAILS: 'unconfirmed_shipment_card_price_details',
  UPCOMING_DELIVERY_ACTION_CHOSEN: 'upcoming_delivery_action_chosen',
  UPCOMING_DELIVERY_CHOSEN: 'upcoming_delivery_chosen',
  VIEW_MEDS: 'view_meds',
  WHAT_MEDICATION_QUESTION_BACK: 'what_medication_question_back',
  WEB_SUPPORT_CASES_APP_REDIRECT: 'web_support_cases_app_redirect',
} as const;

export type AssistantPressedEvent = (typeof ASSISTANT_PRESSED_EVENTS)[keyof typeof ASSISTANT_PRESSED_EVENTS];
