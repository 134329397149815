import React from 'react';
import { AltoIcon } from '../../icon';

export type StandaloneCheckboxProps = {
  readonly value: boolean;
};

export const StandaloneCheckbox = ({ value }: StandaloneCheckboxProps) => {
  return (
    <AltoIcon
      name={value ? 'checkbox-duo' : 'checkbox'}
      testID="standaloneCheckbox"
    />
  );
};
