// @owners { team: patients-team }
import { type PriceType } from '@alto/orders_api/types/v1/cart_item';
import { type Item, type ItemKey } from '~shared/features/checkout/types';
import { type Medication } from '~shared/features/my-meds/types';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { getItemPricingTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { type Pricing } from '~shared/features/pricing/types';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { isControl } from '~shared/helpers/helper';
import { type LightDelivery as Delivery, type Prescription } from '~shared/types';

type ConstructorProps = {
  delivery: Delivery;
  medication: Medication;
  prescription: Prescription;
  resource_id: number;
  pricing: Pricing;
};

export default class DeliveryItem implements Item {
  resource_type: ItemKey['resource_type'];

  resource_id: number;

  userID: number;

  delivery: Delivery;

  medication: Medication;

  prescription: Prescription;

  hasAncillaryItems: boolean;

  isRefrigerated: boolean;

  isSignatureRequired: boolean;

  isCompound: boolean;

  pricing: Pricing;

  priceType: PriceType | null | undefined;

  constructor({ delivery, medication, prescription, resource_id, pricing }: ConstructorProps) {
    this.resource_id = resource_id;
    this.resource_type = 'Delivery';

    this.delivery = delivery;
    this.medication = medication;
    this.prescription = prescription;

    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to type 'number'.
    this.userID = prescription.user_id;

    this.hasAncillaryItems = !!prescription.has_ancillary_items;
    this.isRefrigerated = !!prescription.is_refrigerated;
    this.isSignatureRequired = isControl(prescription);
    this.isCompound = !!prescription.is_compound;
    this.pricing = pricing;
    this.priceType = pricing?.prices?.find((price) => price.selected)?.type;
  }

  equals(item: Pick<Item, 'resource_type' | 'resource_id'>): boolean {
    return item.resource_type === this.resource_type && item.resource_id === this.resource_id;
  }

  displayName(): string {
    return this.prescription.medication_name || this.medication.medicationName;
  }

  key(): string {
    const { key } = this.medication;
    return `${key || ''}-${this.resource_id || ''}`;
  }

  itemKey(): ItemKey {
    return {
      resource_type: this.resource_type,
      resource_id: this.resource_id,
    };
  }

  paymentTotal() {
    return getItemPricingTotal(this.pricing);
  }

  paymentString(): string {
    const total = this.paymentTotal();

    if (total.reason === PRESCRIPTION_PRICING_LABELS.COVERED_BY_CLINIC) {
      return 'Billed to Clinic';
    }

    if (total.reason === PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE) {
      return 'Price N/A';
    }

    const valueString = formatDollarsWithPlaceholder(total.value);

    if (total.reason === PRESCRIPTION_PRICING_LABELS.PROGYNY) {
      return `${valueString}*`;
    }

    return valueString;
  }

  apiPayload() {
    return {
      id: this.delivery.id,
      prescription_id: this.prescription.id,
      patient_paid_shown: this.paymentTotal().value,
      auto_refill: this.prescription.auto_refill,
      priceType: this.priceType,
    };
  }

  // TODO: Implement `paymentBreakdown`
  paymentBreakdown(): Record<string, any> {
    return {};
  }
}

export type DeliveryItemApiPayload = ReturnType<typeof DeliveryItem.prototype.apiPayload>;
