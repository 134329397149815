// @owners { team: patients-team }
import { getItemsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import { getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { getIsUnmodifiedProgynyWizardFlow } from '~shared/selectors/checkout/progyny';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type APIError } from '~shared/types/APIError';

export const useSendCreateOrderAnalyticsEvent = () => {
  const dispatch = useDispatchShared();
  const isEditing = useSelectorShared(getIsEditingExistingOrder);
  const address = useSelectorShared(getOrderAddress);
  const scheduledWithProgynyWizard = useSelectorShared(getIsUnmodifiedProgynyWizardFlow);
  const items = useSelectorShared(getItemsInOrder__DEPRECATED);
  const sendCreateOrderAnalyticsEvent = ({
    shipmentIds,
    error,
  }: {
    shipmentIds?: number[] | null;
    error?: APIError;
  }) => {
    items.forEach((item) => {
      const placedOrderEvent = createEvent(
        error ? EVENTS.CHECKOUT__PLACE_ORDER_FAILED : EVENTS.CHECKOUT__PLACE_ORDER_SUCCEEDED,
        {
          facility_id: address?.dispensing_facility_id,
          origin: isEditing ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,

          /**
           * `scheduledWithProgynyWizard` is `true` if the patient went
           * through the progyny wizard and _didn't_ update the cart
           */
          scheduled_with_progyny_wizard: scheduledWithProgynyWizard,
          shipment_ids: shipmentIds,
        },
        { prescriptionId: item.prescription?.id },
      );
      placedOrderEvent.shipmentId = shipmentIds?.length === 1 ? shipmentIds[0] : null;
      if (item.delivery?.id) {
        placedOrderEvent.deliveryId = item.delivery?.id;
      }

      dispatch(sendAnalyticsEvent(placedOrderEvent));
    });
  };

  return { sendCreateOrderAnalyticsEvent };
};
