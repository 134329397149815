import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { Image, type ImageSourcePropType, type ImageStyle, View, useWindowDimensions } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import Modal from 'react-native-modal';
import styled from 'styled-components/native';
import { PressableAltoIcon } from '../../buttons';

export type FullScreenImageViewerProps = {
  readonly accessibilityLabel: string;
  readonly imageUrl: string;
  readonly isVisible: boolean;
  readonly onClose: () => void;
};

export const FullScreenImageViewer = ({
  accessibilityLabel,
  imageUrl,
  isVisible,
  onClose,
}: FullScreenImageViewerProps) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  return (
    <Modal
      animationIn="fadeInDown"
      animationInTiming={400}
      animationOut="fadeOutDown"
      animationOutTiming={600}
      avoidKeyboard
      backdropColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
      backdropOpacity={1}
      isVisible={isVisible}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ justifyContent: 'flex-end', margin: 0 }}
    >
      <ImageViewer
        backgroundColor={COLORS.BACKGROUND_COLORS.MODAL_BACKGROUND}
        enableSwipeDown
        imageUrls={[{ url: imageUrl }]}
        onCancel={onClose}
        saveToLocalByLongPress={false}
        useNativeDriver
        renderImage={(props: { readonly style: ImageStyle; readonly source: ImageSourcePropType }) => {
          const wrapperWidth = props.style.width ? Number(props.style.width) : 0;
          const wrapperHeigth = props.style.height ? Number(props.style.height) : 0;
          const closeIconTop = (windowHeight - wrapperHeigth) / 2 - 57;

          return (
            <Wrapper
              wrapperHeigth={wrapperHeigth}
              wrapperWidth={wrapperWidth}
            >
              <ImageContainer windowWidth={windowWidth}>
                <IconContainer closeIconTop={`-${closeIconTop}px`}>
                  <PressableAltoIcon
                    accessibilityLabel="Dismiss this screen"
                    name="close"
                    onPress={onClose}
                    type="white"
                  />
                </IconContainer>
                <StyledImage
                  accessibilityLabel={accessibilityLabel}
                  source={props.source}
                  windowWidth={windowWidth}
                />
              </ImageContainer>
            </Wrapper>
          );
        }}
      />
    </Modal>
  );
};

const Wrapper = styled(View)<{ wrapperHeigth: number; wrapperWidth: number }>`
  align-items: center;
  height: ${({ wrapperHeigth }) => wrapperHeigth}px;
  width: ${({ wrapperWidth }) => wrapperWidth}px;
`;

const ImageContainer = styled(View)<{ windowWidth: number }>`
  align-items: flex-end;
  height: 100%;
  width: ${({ windowWidth }) => windowWidth}px;
`;

const IconContainer = styled(View)<{ closeIconTop: string }>`
  position: absolute;
  right: ${SPACING.STATIC.LG.px};
  top: ${({ closeIconTop }) => closeIconTop};
  width: ${SPACING.STATIC.LG.px};
`;

const StyledImage = styled(Image)<{ windowWidth: number }>`
  height: 100%;
  width: ${({ windowWidth }) => windowWidth}px;
`;
