// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Description, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { InputBlockSelect } from '@alto/pocky';
import React from 'react';
import { type QuantityOption } from '~shared/types/clients';

export type Props = {
  readonly quantityOptions: QuantityOption[];
  readonly showWarning: boolean;
  readonly onChange: (value: number) => void;
  readonly value?: number;
};

export const ChangeQuantitySelect = ({ quantityOptions, showWarning, onChange, value }: Props) => {
  const options = quantityOptions.map((quantityOption: QuantityOption) => ({
    label: quantityOption.label,
    value: quantityOption.quantity,
  }));

  return (
    <>
      <InputBlockSelect
        input={{
          onChange,
          value,
        }}
        label="Select quantity"
        options={options}
      />
      {showWarning ? (
        <>
          <XsSpacing />
          <Description color={COLORS.TEXT_COLORS.GREY}>
            Please check with your provider before changing quantity. In order to find the best price an updated price
            may not be available immediately. This can take up to an hour, but is usually less than 10 minutes.
          </Description>
        </>
      ) : null}
    </>
  );
};
