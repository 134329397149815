// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getNextAvailableDateID } from '~shared/features/next-available-date/helpers/getNextAvailableDateID';
import { getNextAvailableDateAddressID } from '~shared/features/next-available-date/selectors/getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from '~shared/features/next-available-date/selectors/getNextAvailableDateFacilityId';
import { getNextAvailableDates } from '~shared/features/next-available-date/selectors/getNextAvailableDates';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { type NextAvailableDate, type Prescription } from '~shared/types';

export const getNextAvailableDatesByPrescriptionID = createSelector(
  [getNextAvailableDateAddressID, getNextAvailableDateFacilityID, getPrescriptions, getNextAvailableDates],
  (addressID, facilityID, prescriptions, nextAvailableDates) => {
    const result: Record<number, NextAvailableDate> = {};
    if (!(addressID || facilityID)) {
      return result;
    }
    prescriptions.forEach((prescription: Prescription) => {
      const nextAvailableDateID = getNextAvailableDateID({
        addressID,
        facilityID,
        prescriptionIDs: [prescription.id],
      });
      return (result[prescription.id] = nextAvailableDates[nextAvailableDateID]);
    });
    return result;
  },
);
