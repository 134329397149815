// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2 } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantCategory,
} from '~shared/features/alto-assistant/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { AccountActionSheet } from './AccountActionSheet';
import { MenuOption } from './MenuOption';
import { OrderingActionSheet } from './OrderingActionSheet';
import { PricingPaymentsActionSheet } from './PricingPaymentsActionSheet';
import { TransferActionSheet } from './TransferActionSheet';

export const HelpTopicsActionSheet = () => {
  const { navigate } = useNavigation();
  const { closeActionSheet, saveStack, setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();

  const trackCategoryPress = useCallback(
    (category: AssistantCategory) => {
      trackEvent({
        event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
        params: {
          name: ASSISTANT_PRESSED_EVENTS.CATEGORY_CHOSEN,
          category,
        },
      });
    },
    [trackEvent],
  );

  const handleOrderingPress = useCallback(() => {
    trackCategoryPress('ordering');
    setActiveActionSheet(<OrderingActionSheet />);
  }, [setActiveActionSheet, trackCategoryPress]);

  const handleOrderPress = useCallback(() => {
    trackCategoryPress('deliveries');
    saveStack<ActionSheetProviderStackCacheKey>('assistant-deliveries');
    closeActionSheet();
    navigate('RouteMessagesDeliverySelection', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU,
      category: 'deliveries',
    });
  }, [closeActionSheet, navigate, saveStack, trackCategoryPress]);

  const handlePricingPress = useCallback(() => {
    trackCategoryPress('pricing_payments');
    setActiveActionSheet(<PricingPaymentsActionSheet />);
  }, [setActiveActionSheet, trackCategoryPress]);

  const handleAccountPress = useCallback(() => {
    trackCategoryPress('account');
    setActiveActionSheet(<AccountActionSheet />);
  }, [setActiveActionSheet, trackCategoryPress]);

  const handleTransferPress = useCallback(() => {
    trackCategoryPress('transfer');
    setActiveActionSheet(<TransferActionSheet />);
  }, [setActiveActionSheet, trackCategoryPress]);

  const handleStockCheckPress = useCallback(() => {
    trackCategoryPress('stock_check');
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      category: 'stock_check',
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU,
      subject: 'Stock check',
    });
  }, [closeActionSheet, navigate, saveStack, trackCategoryPress]);

  const handleMedicalQuestionPress = useCallback(() => {
    trackCategoryPress('medical_question');
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      category: 'medical_question',
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU,
      subject: 'Medical question',
    });
  }, [closeActionSheet, navigate, saveStack, trackCategoryPress]);

  const handleSomethingElsePress = useCallback(() => {
    trackCategoryPress('something_else');
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      category: 'something_else',
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU,
      subject: ASSISTANT_MESSAGE_SUBJECTS.SOMETHING_ELSE,
    });
  }, [closeActionSheet, navigate, saveStack, trackCategoryPress]);

  return (
    <ActionSheetV2 title="What can we help with?">
      <MenuOption
        iconName={'cart-duo'}
        text={'Help ordering my medication(s)'}
        onPress={handleOrderingPress}
      />
      <MenuOption
        iconName={'box-duo'}
        text={'Help with my order'}
        onPress={handleOrderPress}
      />
      <MenuOption
        iconName={'creditcard-duo'}
        text={'Pricing or payment issue(s)'}
        onPress={handlePricingPress}
      />
      <MenuOption
        iconName={'usercircle-duo'}
        text={'Help with my account'}
        onPress={handleAccountPress}
      />
      <MenuOption
        iconName={'swap-duo'}
        text={'Transferring a prescription'}
        onPress={handleTransferPress}
      />
      <MenuOption
        iconName={'pills-duo'}
        text={'Check if item is in stock'}
        onPress={handleStockCheckPress}
      />
      <MenuOption
        iconName={'pillbottle-duo'}
        text={'Medical question(s)'}
        onPress={handleMedicalQuestionPress}
      />
      <MenuOption
        iconName={'questionmark-duo'}
        text={'Something else...'}
        onPress={handleSomethingElsePress}
      />
    </ActionSheetV2>
  );
};
