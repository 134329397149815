// @owners { team: patients-team }
import { Body, ExternalLink, LgPadding, MdPadding, SmSpacing } from '@alto/design-system';
import React from 'react';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_DELIVERY_ACTIONS,
  ASSISTANT_MESSAGE_SUBJECTS,
  ASSISTANT_PAYMENTS_ACTIONS,
  ASSISTANT_TRANSFER_ACTIONS,
  type AssistantCategory,
  type AssistantMessagePrompt,
  type AssistantMessageSubject,
  HOLIDAY_CLOSURE_INFO,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { FaqTextContent } from './FaqTextContent';
import { SharpsDisposalContent } from './SharpsDisposalActionSheet';
import { TransferOutContent } from './TransferOutActionSheet';

export const FAQ_KEYS = {
  ADD_MEDICATION: 'add_medication',
  HOLIDAY_CLOSURE: 'holiday_closure',
  PRICE_FSA_HSA_FUNDS: 'price_fsa_hsa_funds',
  PRICE_INVESTIGATION: 'price_investigation',
  PRICE_QUOTES: 'price_quotes',
  PRICE_UNAVAILABLE_OR_PENDING: 'price_unavailable_or_pending',
  SHARPS_CONTAINER_DISPOSAL: 'sharps_container_disposal',
  TRANSFER_OUT: 'transfer_out',
} as const;

export type FAQKey = (typeof FAQ_KEYS)[keyof typeof FAQ_KEYS];

type FAQEntry = {
  title: string;
  content: string | React.ReactNode;
  category: AssistantCategory;
  subject: AssistantMessageSubject;
  prompt?: AssistantMessagePrompt;
};

export const FAQS: Record<FAQKey, FAQEntry> = {
  [FAQ_KEYS.ADD_MEDICATION]: {
    title: 'Add medication',
    content: (
      <LgPadding>
        <Body>
          To add a medication to your order, you can schedule a separate delivery of that medication to the same address
          for the same date and time. Our pharmacy team will consolidate your order for you.
        </Body>
      </LgPadding>
    ),
    category: `${ASSISTANT_CATEGORIES.DELIVERIES}.${ASSISTANT_DELIVERY_ACTIONS.ADD_MEDICATION}`,
    subject: ASSISTANT_MESSAGE_SUBJECTS.ADD_MEDICATION,
    prompt: MESSAGE_PROMPTS.ADD_MEDICATION,
  },
  [FAQ_KEYS.HOLIDAY_CLOSURE]: {
    title: 'Holiday Closure',
    content: (
      <>
        <SmSpacing />
        {HOLIDAY_CLOSURE_INFO.map((faqElement) => {
          return (
            <MdPadding key={`faq_answer_${faqElement.title}`}>
              <FaqTextContent
                title={faqElement.title}
                body={faqElement.answer}
              />
            </MdPadding>
          );
        })}
      </>
    ),
    category: ASSISTANT_CATEGORIES.HOLIDAY_CLOSURE,
    subject: ASSISTANT_MESSAGE_SUBJECTS.HOLIDAY_CLOSURE,
  },
  [FAQ_KEYS.PRICE_FSA_HSA_FUNDS]: {
    title: 'FSA/HSA funds',
    content: (
      <LgPadding>
        <Body>
          We accept payment from most FSA/HSA cards that are eligible for use at "90% merchant" pharmacies. You can add
          your card by going to the “Payment Methods” section of your Account settings in this app.
          {'\n\n'}
          If your particular plan can only be used at IIAS registered pharmacies, you may use an alternate method of
          payment and submit your receipt to your plan to request reimbursement. Yearly FSA receipts can be accessed in
          your Account settings under Payment Methods &gt; Annual Receipts. Receipts for individual deliveries are
          available in the Deliveries tab.
          {'\n\n'}
          We recommend contacting your plan administrator to learn more about where your funds can be used and their
          reimbursement policies.
        </Body>
      </LgPadding>
    ),
    category: `${ASSISTANT_CATEGORIES.PRICING_PAYMENTS}.${ASSISTANT_PAYMENTS_ACTIONS.FSA_HSA_FUNDS}`,
    subject: ASSISTANT_MESSAGE_SUBJECTS.PRICE_FSA_HSA_FUNDS,
    prompt: MESSAGE_PROMPTS.HELP_WITH_PRICING_PAYMENTS,
  },
  [FAQ_KEYS.PRICE_INVESTIGATION]: {
    title: 'Unexpected price',
    content: (
      <LgPadding>
        <Body>
          The details of how your price was calculated can be reviewed within your cart and on the medication details
          page.
          {'\n\n'}
          If your medication appears to have been processed correctly, but the price has changed or is higher than
          expected, we recommend contacting your insurance directly as they will be best equipped to provide an
          explanation about your specific benefits.
          {'\n\n'}
          If you believe your prescription may have been processed incorrectly, you can request that our support team
          take another look.
        </Body>
      </LgPadding>
    ),
    category: `${ASSISTANT_CATEGORIES.PRICING_PAYMENTS}.${ASSISTANT_PAYMENTS_ACTIONS.INVESTIGATION_REQUEST}`,
    subject: ASSISTANT_MESSAGE_SUBJECTS.PRICE_INVESTIGATION_REQUEST,
    prompt: MESSAGE_PROMPTS.HELP_WITH_PRICING_PAYMENTS,
  },
  [FAQ_KEYS.PRICE_QUOTES]: {
    title: 'Price quotes',
    content: (
      <LgPadding>
        <Body>
          The fastest way to get an accurate price quote is to have your doctor or current pharmacy send your
          prescription directly to us. Once received, we will work with your insurance to get coverage or find a covered
          alternative and locate all coupons, copay cards and manufacturer savings programs to ensure you're getting the
          lowest price.
          {'\n\n'}
          If you know the name, strength, and quantity of your medication, our team may be able to provide a quote for
          the cash price. Please note that price may be subject to change.
          {'\n\n'}
          Inquiring about Fertility medications? Submit a price quote request here:{' '}
          <ExternalLink href="https://alto.com/fertility-affordability">
            https://alto.com/fertility-affordability
          </ExternalLink>
        </Body>
      </LgPadding>
    ),
    category: `${ASSISTANT_CATEGORIES.PRICING_PAYMENTS}.${ASSISTANT_PAYMENTS_ACTIONS.GET_A_PRICE_QUOTE}`,
    subject: ASSISTANT_MESSAGE_SUBJECTS.GET_A_PRICE_QUOTE,
    prompt: MESSAGE_PROMPTS.HELP_WITH_PRICING_PAYMENTS,
  },
  [FAQ_KEYS.PRICE_UNAVAILABLE_OR_PENDING]: {
    title: 'Price unavailable or pending',
    content: (
      <LgPadding>
        <Body>
          As every prescription has unique billing rules, a billing specialist sometimes needs to step in to guide
          complex cases to get you a final price.
          {'\n\n'}
          If your medication is in your cart, you may proceed with checkout and scheduling. Depending on the final
          price, you may be asked to review and confirm the price prior to delivery.
          {'\n\n'}
          If your new prescription is still being processed and you’re unable to add it to your cart, hang tight! Our
          team is working diligently to finalize the price and will reach out shortly to provide an update.
        </Body>
      </LgPadding>
    ),
    category: `${ASSISTANT_CATEGORIES.PRICING_PAYMENTS}.${ASSISTANT_PAYMENTS_ACTIONS.UNAVAILABLE_OR_PENDING}`,
    subject: ASSISTANT_MESSAGE_SUBJECTS.PRICE_UNAVAILABLE_OR_PENDING,
    prompt: MESSAGE_PROMPTS.HELP_WITH_PRICING_PAYMENTS,
  },
  [FAQ_KEYS.SHARPS_CONTAINER_DISPOSAL]: {
    title: 'Sharps container disposal',
    content: (
      <LgPadding>
        <SharpsDisposalContent />
      </LgPadding>
    ),
    category: ASSISTANT_CATEGORIES.SOMETHING_ELSE,
    subject: ASSISTANT_MESSAGE_SUBJECTS.SOMETHING_ELSE,
  },
  [FAQ_KEYS.TRANSFER_OUT]: {
    title: 'How do I transfer meds from Alto to another pharmacy?',
    content: <TransferOutContent origin="FAQ" />,
    category: `${ASSISTANT_CATEGORIES.TRANSFER}.${ASSISTANT_TRANSFER_ACTIONS.TRANSFER_OUT}`,
    subject: ASSISTANT_MESSAGE_SUBJECTS.TRANSFER,
  },
};
