// @owners { team: patients-team }
import { type PrescriptionPricing } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { format } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';
import { getFormattedPrice } from '~shared/helpers/pricingBreakdown';
import { type PricingRowInfo } from './PricingRow';

type Pricings = {
  hasFullPricingDetails: boolean;
  insurancePriceInfo: PricingRowInfo | undefined;
  cashPriceInfo: PricingRowInfo | undefined;
  progynyPriceInfo: PricingRowInfo | undefined;
};

export const getMedDetailsPricingInfoFromPricing = ({
  pricing,
  nextBillableDate,
}: {
  pricing: PrescriptionPricing;
  nextBillableDate: string | null | undefined;
  cashPayOnly: boolean | null | undefined;
}): Pricings => {
  const { prices, scenario, estimated } = pricing;
  let cashPriceInfo;
  let insurancePriceInfo;
  let progynyPriceInfo;
  const formattedNextBillableDate = nextBillableDate
    ? format(new Date(nextBillableDate), DATE_FORMATS.FULL_MONTH_ORDINAL_DAY)
    : undefined;

  if (scenario === 'unavailable') {
    return {
      hasFullPricingDetails: false,
      insurancePriceInfo: {
        price: 'Pending',
        title: 'Price with insurance',
        detail: formattedNextBillableDate ? `Covered after ${formattedNextBillableDate}` : 'Finalized before delivery',
      },
      progynyPriceInfo: undefined,
      cashPriceInfo: undefined,
    };
  }

  prices.map((priceOption) => {
    const { price, type } = priceOption;
    if (type === 'with_insurance' && scenario !== 'progyny') {
      // estimated === true means it's the last price with insurance
      if (estimated) {
        insurancePriceInfo = {
          price: getFormattedPrice(price),
          title: 'Last price with insurance',
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          detail: `Subject to change, ${formattedNextBillableDate ? `covered after ${formattedNextBillableDate}` : 'finalized before delivery'}`,
        };
      } else {
        insurancePriceInfo = {
          price: getFormattedPrice(price),
          title: 'Price with insurance',
          detail: undefined,
        };
      }
    }

    if (type === 'without_insurance') {
      cashPriceInfo = {
        price: getFormattedPrice(price),
        title: 'Price without insurance',
        detail: undefined,
      };
    }

    if (scenario === 'progyny') {
      progynyPriceInfo = {
        price: getFormattedPrice(price),
        title: 'Price with Alto',
        detail: 'Actual cost handled through Progyny',
      };
    }
  });

  return {
    hasFullPricingDetails: !progynyPriceInfo,
    insurancePriceInfo,
    progynyPriceInfo,
    cashPriceInfo,
  };
};
