// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Body,
  Button,
  Card,
  ExternalLink,
  H1,
  MdPadding,
  SecondaryPage,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantCategory,
  type AssistantMessagePrompt,
  type AssistantMessageSubject,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { type FAQKey, FAQS } from './Constants';

/**
 * Displays a frequently asked question and provides information on the topic
 * The patient can dismiss the action sheet or send a message to the care team.
 */
export const QuestionPage = () => {
  const { getParam, navigate } = useNavigation<'RouteFaq'>();
  const { restoreStack, saveStack } = useContext(ActionSheetContext);
  const buttonLabelDone = getParam('buttonLabelDone', 'This answered my question');
  const faqKey: FAQKey | undefined = getParam('faqKey', undefined);
  const goBack = getParam('goBack', undefined);
  const hideBottomButtons = getParam('hideBottomButtons', false);
  const onDismissFromParam = getParam('onDismiss', undefined);

  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();
  // If no onDismiss function is provided, navigate to Assistant landing page by default
  const onDismiss =
    onDismissFromParam ??
    (() => {
      restoreStack<ActionSheetProviderStackCacheKey>('assistant');
      navigateToAssistantLanding();
    });

  const handleDone = () => {
    onDismiss();
  };

  const handlePressAskQuestion = () => {
    let category: AssistantCategory;
    let subject: AssistantMessageSubject;
    let prompt: AssistantMessagePrompt | undefined;
    if (faqKey) {
      ({ category, subject, prompt } = FAQS[faqKey]);
    } else {
      category = ASSISTANT_CATEGORIES.SOMETHING_ELSE;
      subject = ASSISTANT_MESSAGE_SUBJECTS.SOMETHING_ELSE;
    }

    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    navigate('RouteAssistantMessage', {
      messagePrompt: prompt ?? MESSAGE_PROMPTS.LET_US_HELP,
      origin: ASSISTANT_CHAT_ORIGINS.FAQ,
      category,
      subject,
    });
  };

  if (!faqKey) {
    return (
      <SecondaryPage
        headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
        HeaderContent={
          <MdPadding>
            <H1>Frequently asked question</H1>
          </MdPadding>
        }
        dismissIcon="chevronleft"
        onDismiss={onDismiss}
        buttons={[
          <Button
            key="faq-button-ask-a-question"
            label="Ask a question"
            onPress={handlePressAskQuestion}
          />,
        ]}
      >
        <Card>
          <MdPadding>
            <Body>
              Sorry, we were unable to find an answer for this frequently asked question. For general help please visit{' '}
              <ExternalLink href="https://www.alto.com/how-it-works">How Alto works</ExternalLink>, or message us to ask
              your question.
            </Body>
          </MdPadding>
        </Card>
      </SecondaryPage>
    );
  }

  const { title, content } = FAQS[faqKey];

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      HeaderContent={
        <MdPadding>
          <H1>{title}</H1>
        </MdPadding>
      }
      dismissIcon="chevronleft"
      onDismiss={goBack ?? onDismiss}
      buttons={
        hideBottomButtons
          ? undefined
          : [
              <Button
                label={buttonLabelDone}
                key={buttonLabelDone}
                onPress={handleDone}
              />,
              <Button
                type="tertiary"
                label="I still have a question"
                key="I still have a question"
                onPress={handlePressAskQuestion}
              />,
            ]
      }
    >
      <Card>{content}</Card>
    </SecondaryPage>
  );
};
