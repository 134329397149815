// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, FlexRow, Modal, ModalContent, ModalFooter, Text, spacing } from '@alto/pocky';
import React from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import HeaderImage, { MODAL_SIZE } from '~web/components/HeaderImage';
import prescriptions from '~web/images/prescriptions@2x.png';

type Props = {
  readonly onClose: () => void;
};

const OnboardingPriceInfoModal = (props: Props) => {
  const { onClose } = props;
  return (
    <Modal
      onClose={onClose}
      showDismiss
    >
      <ModalContent>
        <HeaderImage
          src={prescriptions}
          alt={IMG_ALT_TEXTS.prescriptions}
          title="How did you find this?"
          imgSize={MODAL_SIZE}
        />
        <FlexRow
          spacing={spacing.mdSpacing}
          noShrink
        >
          <Text>
            We checked with your insurance to find this price for you. This price may look different from one you’ve
            seen before for a couple of different reasons like prescription quantity or manufacturer, or a deductible
            reset.
          </Text>
          <Text>
            If you have questions, don’t hesitate to contact our friendly Patient Care team for pricing details. If the
            price is too high, let us know so we can provide different options such as partnering with your provider to
            find an alternative.
          </Text>
        </FlexRow>
      </ModalContent>
      <ModalFooter>
        <ButtonWrapper horizontallyAlignContent>
          <Button onClick={onClose}>Got It</Button>
        </ButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default OnboardingPriceInfoModal;
