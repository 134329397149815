import React from 'react';
import { View } from 'react-native';
import { isSMScreenOrBigger } from '../../../utils';
import { Column, Row } from '../../containers';
import { XsSpacing } from '../../separators';
import { type ButtonsGroupProps } from '../buttonUtils';
import { type Button } from './button/Button';

export type ButtonGroupProps = {
  readonly buttons: ButtonsGroupProps;
  readonly rowReversed?: boolean;
  readonly separator?: React.ReactNode;
  readonly inline?: boolean;
  readonly justifyButtons?: 'center' | 'left' | 'right';
};

const renderButtons = (buttons: React.ReactElement<typeof Button>[], separator?: React.ReactNode, inline?: boolean) => {
  let buttonSeparator: JSX.Element | null = null;

  if (!inline) {
    buttonSeparator = <XsSpacing />;
  }

  return buttons.map((button, index) => {
    return (
      <View
        key={button.key}
        // eslint-disable-next-line react-native/no-inline-styles
        style={inline ? {} : { width: '100%' }}
      >
        {button}
        {index + 1 !== buttons.length ? separator || buttonSeparator : null}
      </View>
    );
  });
};

export const ButtonGroup = ({
  buttons,
  inline = false,
  rowReversed,
  separator,
  justifyButtons = 'center',
}: ButtonGroupProps) => {
  const smScreen = isSMScreenOrBigger();
  const reversedButtons = [...buttons].reverse();

  return smScreen || inline ? (
    <Row
      centerHorizontally={justifyButtons === 'center'}
      left={justifyButtons === 'left'}
      right={justifyButtons === 'right'}
      wrap
      gap="XS"
    >
      {renderButtons(!inline && rowReversed ? reversedButtons : buttons, separator, inline)}
    </Row>
  ) : (
    <Column>{renderButtons(rowReversed ? reversedButtons : buttons, separator)}</Column>
  );
};
