import React from 'react';
import { INTERNAL_IconWrapper, type Props as IconWrapperProps } from './INTERNAL_IconWrapper';

type Props = IconWrapperProps & {
  readonly testID?: string;
};

// Empty placeholder component for when we need a space as if an icon were rendered
export const IconPlaceholder = ({ size, allowFontScaling, testID }: Props) => (
  <INTERNAL_IconWrapper
    testID={testID}
    size={size}
    allowFontScaling={allowFontScaling}
  />
);
