import {
  CheckoutFlowEndpoint,
  type FetchEnterCheckoutRequest,
} from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';

const enterCheckoutApi = CheckoutFlowEndpoint(apiEndpointHandler);

export const enterCheckout = createQueryKeys('enterCheckout', {
  enterCheckout: (params: FetchEnterCheckoutRequest) => ({
    queryKey: [params],
    queryFn: async () => {
      const response = await enterCheckoutApi.fetchEnterCheckout(params, { version: 'v1' });

      return {
        earliestDateCompoundData: response?.data?.checkout_flow_data?.earliest_date_compound ?? undefined,
        photoIDUploadData: response?.data?.checkout_flow_data?.photo_id_upload ?? undefined,
        flowData: response?.data?.checkout_flow_data ?? undefined,
        flowTypes: response?.data?.checkout_flow_types,
        // @ts-expect-error TS(2551): Property 'error' does not exist on type 'FetchEnterCheckoutResponse'.
        error: response?.error && response?.patient_ready_message ? response.message : undefined,
      };
    },
  }),
});
