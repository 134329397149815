// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import {
  SET_ESSENTIALS_ACTIVE_CATEGORY,
  SET_ESSENTIALS_SEARCH_TERM,
  type SetActiveEssentialCategory,
  type SetEssentialsSearchTerm,
  UPDATE_ADD_TO_SHIPMENT_ERROR,
  type UpdateAddToShipmentErrorAction,
  VIEW_STORE,
  VIEW_STORE_UPSELL,
  type ViewStore,
  type ViewStoreUpSell,
} from '~shared/features/essentials/actions';

type Action =
  | ViewStore
  | ViewStoreUpSell
  | UpdateAddToShipmentErrorAction
  | SetActiveEssentialCategory
  | SetEssentialsSearchTerm;

export type EssentialsState = {
  addToShipmentError: {
    error: string | null | undefined;
    quantityAdded: number | null | undefined;
  };
  viewStoreCount: number;
  viewStoreUpsellCount: number;
  activeCategory: number;
  searchTerm: string;
};

export const initialState: EssentialsState = {
  addToShipmentError: {
    error: null,
    quantityAdded: null,
  },
  viewStoreCount: 0,
  viewStoreUpsellCount: 0,
  activeCategory: 0,
  searchTerm: '',
};

const essentials = (state: EssentialsState = initialState, action: Action): EssentialsState => {
  switch (action.type) {
    case VIEW_STORE_UPSELL: {
      return { ...state, viewStoreUpsellCount: state.viewStoreUpsellCount + 1 };
    }
    case VIEW_STORE: {
      return { ...state, viewStoreCount: state.viewStoreCount + 1 };
    }
    case UPDATE_ADD_TO_SHIPMENT_ERROR: {
      return {
        ...state,
        addToShipmentError: action.payload,
      };
    }
    case SET_ESSENTIALS_ACTIVE_CATEGORY: {
      return {
        ...state,
        activeCategory: action.payload,
      };
    }
    case SET_ESSENTIALS_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    default:
      return state;
  }
};

export default withRehydration(essentials, initialState);
