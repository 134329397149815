import { AltoIcon, Body, Row, SmSpacing } from '@alto/design-system';
import React, { type ReactElement } from 'react';

const ChecklistInfoRow = ({ infoText, children }: { readonly infoText?: string; readonly children?: ReactElement }) => (
  <Row centerVertically>
    <AltoIcon name="checkcircle-small" />
    <SmSpacing />
    {infoText ? <Body>{infoText}</Body> : children}
  </Row>
);

export default ChecklistInfoRow;
