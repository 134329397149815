import { SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import styled from 'styled-components/native';
import { Border } from '../../borders';
import { SmPadding } from '../../containers';
import { getInlineAlertColors } from './AlertUtils';
import { type ColorVariant } from './types';

const StyledSmPadding = styled(SmPadding)<{ color: string }>`
  background-color: ${(props) => props.color};
  overflow: hidden;
`;

export type InlineAlertProps = {
  readonly borderless?: boolean;
  readonly type?: ColorVariant;
  readonly children: React.ReactNode;
};

export const InlineAlert = ({ borderless = false, type = 'neutral', children }: InlineAlertProps) => {
  const colorScheme = getInlineAlertColors({ variant: type, borderless });

  return (
    <Border
      color={colorScheme.borderColor}
      radius="LG"
    >
      <StyledSmPadding
        color={colorScheme.backgroundColor}
        leftPadding={SPACING.STATIC.MD}
        rightPadding={SPACING.STATIC.MD}
      >
        {children}
      </StyledSmPadding>
    </Border>
  );
};
