// @owners { team: patients-team }
import { openOriginModal } from '~shared/actions/modal';
import { connect } from '~shared/store';
import CreditCardModal from '~web/features/payments/components/CreditCardModal';

type DispatchProps = {
  onClose: typeof openOriginModal;
};

const mapDispatchToProps: DispatchProps = {
  onClose: openOriginModal,
};

const CourierTipsCreditCardModal = connect(null, mapDispatchToProps)(CreditCardModal);

export default CourierTipsCreditCardModal;
