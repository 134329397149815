// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { AltoIcon, Row, useScreenSize } from '@alto/design-system';
import React, { type ElementRef, type RefObject, useState } from 'react';
import styled from 'styled-components';
import TransparentButtonWrapper from './TransparentButtonWrapper';

const StarWrapper = styled(TransparentButtonWrapper)<{
  isModal: boolean | null | undefined;
  isSMScreenOrBigger: boolean;
}>`
  ${({ isModal }) => `
    &:not(:last-child) > * {
      margin-right: ${isModal ? SPACING.STATIC.XS.rem : SPACING.STATIC.MD.rem};
    }
  `}
  &:not(:last-child) > * {
    margin-right: ${SPACING.STATIC.MD.rem};
  }
  /* necessary to avoid awkward overflow in small screen size layout */
  ${({ isSMScreenOrBigger, isModal }) =>
    !isSMScreenOrBigger &&
    `
      &:not(:last-child) > * {
        margin-right: ${isModal ? SPACING.STATIC.XXS.rem : SPACING.STATIC.XS.rem};
      }
  `}
`;

type Props = {
  readonly rating: number;
  readonly setRating: (rating: number) => void;
  readonly isModal: boolean | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  readonly firstFocusableElementRef: ElementRef<any> | null | undefined;
};

function getStarColor(isSelected = false, isHovered = false) {
  if (isSelected || isHovered) return 'secondary';
  return 'disabled';
}

const NUM_STARS = 5;

const Stars = ({ rating, setRating, isModal, firstFocusableElementRef }: Props) => {
  const [hoveredStarIndex, setHoveredStarIndex] = useState(-1);
  const { isSMScreenOrBigger } = useScreenSize();
  const stars = Array.from(
    {
      length: NUM_STARS,
    },
    (_, index) => {
      const isSelected = rating > index;
      const isHovered = hoveredStarIndex > index;
      const color = getStarColor(isSelected, isHovered);
      const firstStarRef = index === 0 ? firstFocusableElementRef : undefined;
      return (
        <StarWrapper
          onClick={() => {
            setRating(index + 1);
          }}
          onMouseEnter={() => {
            setHoveredStarIndex(index + 1);
          }}
          onMouseLeave={() => {
            setHoveredStarIndex(-1);
          }}
          ref={firstStarRef as RefObject<HTMLButtonElement>}
          isModal={isModal}
          key={index}
          isSMScreenOrBigger={isSMScreenOrBigger}
        >
          <AltoIcon
            name={isSelected ? 'star-duo' : 'star'}
            type={color}
          />
        </StarWrapper>
      );
    },
  );
  return <Row>{stars}</Row>;
};

export default Stars;
