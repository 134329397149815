// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Border,
  Card,
  Column,
  H1,
  ListBase,
  ListButton,
  ListDescription,
  ListItem,
  MdPadding,
  SecondaryPage,
  Separator,
  XxxlPadding,
  useScreenSize,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useContext, useEffect } from 'react';
import { Platform } from 'react-native';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses, selectAddress } from '~shared/actions/addresses';
import { getAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Address } from '~shared/types';
import { AddressFormActionSheet } from '../../address';
import { AddressListItem } from '../../address/components/AddressListItem';
import { AddAddressCard } from './AddAddressCard';

export const DeliveryAddressesPage = () => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();
  const addresses = useSelectorShared(getAddresses);
  const { navigate } = useNavigation();
  const { isMDScreenOrBigger } = useScreenSize();

  useEffect(() => {
    if (!addresses.length) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchAddresses());
    }
  }, [dispatch, addresses.length]);

  const handleAddAddress = useCallback(() => {
    setActiveActionSheet(<AddressFormActionSheet origin="profile" />);
  }, [setActiveActionSheet]);

  // the back navigation button only shows up in native - routes back to the profile/account tab
  const handleBackPress = () => {
    navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
  };

  const addressItems =
    addresses.length === 0
      ? [
          {
            key: 'no-addresses-on-file',
            component: (
              <ListItem
                descriptions={
                  <ListDescription>We don’t have an address on file for you yet. Go ahead and add one!</ListDescription>
                }
              />
            ),
          },
        ]
      : addresses.map((address: Address) => {
          return {
            key: address.id,
            component: (
              <AddressListItem
                address={address}
                onEditAddress={() => {
                  dispatch(selectAddress(address));
                  handleAddAddress();
                }}
              />
            ),
          };
        });

  const isWebLargeScreen = Platform.OS === 'web' && isMDScreenOrBigger;

  return (
    <SecondaryPage
      HeaderContent={
        isWebLargeScreen ? (
          <XxxlPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <Column centerHorizontally>
              <H1>Delivery addresses</H1>
            </Column>
          </XxxlPadding>
        ) : null
      }
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      navBarTitle={!isWebLargeScreen ? 'Delivery addresses' : undefined}
      dismissIcon="chevronleft"
      onDismiss={handleBackPress}
    >
      {addresses.length === 0 ? (
        <AddAddressCard onAddAddress={handleAddAddress} />
      ) : (
        <Card>
          <ListBase
            items={addressItems}
            separator={
              <Border
                hideLeft
                hideTop
                hideRight
              />
            }
          />
          <Separator />
          <MdPadding
            leftPadding={SPACING.STATIC.LG}
            rightPadding={SPACING.STATIC.LG}
          >
            <ListButton
              label="Add new address"
              rightIcon="plus-small"
              onPress={handleAddAddress}
            />
          </MdPadding>
        </Card>
      )}
    </SecondaryPage>
  );
};
