import { SPACING } from '@alto/design-library-tokens';
// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding, XlSpacing } from '@alto/design-system';
import React, { useContext } from 'react';
import insuranceCard from '~shared/assets/images/insurance_card.png';
import { EVENTS } from '~shared/lib/analytics/src/constants';

type KaiserActionSheetProps = {
  readonly onDismiss?: () => void;
};

export const KaiserActionSheet = ({ onDismiss }: KaiserActionSheetProps) => {
  const { goToPreviousActionSheet } = useContext(ActionSheetContext);

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss();
    } else {
      goToPreviousActionSheet();
    }
  };

  return (
    <ActionSheetV2
      illustrationSrc={insuranceCard}
      title="Insurance not covered"
      analyticsName={EVENTS.TRANSFER_KAISER_MODAL_VIEWED}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          Alto is out of network for Kaiser insurance. If you have Kaiser insurance, you will not be able to use it to
          cover any medications that you fill with Alto. Our prices are still competitive if you opt to pay without
          insurance.
        </Body>
        <XlSpacing />
        <Button
          width="full"
          onPress={handleDismiss}
          label="Got It"
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
