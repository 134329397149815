// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, Button, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import { ASSISTANT_PRESSED_EVENTS, ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantViewedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { MenuOption } from './MenuOption';

const MedsTabButton = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);

  const onPress = () => {
    closeActionSheet();
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.VIEW_MEDS,
      },
    });
    navigate('RouteAppTabNavigator', { screen: 'RouteTabMyMeds' });
  };

  return (
    <Button
      label="Take me to meds tab"
      onPress={onPress}
    />
  );
};

export const HowToOrderActionSheet = () => {
  const dispatch = useDispatchShared();
  useEffect(() => {
    dispatch(sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.HOW_TO_ORDER_ACTION_SHEET));
  }, [dispatch]);

  const items = [
    {
      key: 'go-to-meds-tab',
      component: (
        <MenuOption
          iconName="pillbottle-duo"
          description="Check to see if your medication has an 'add to cart' button."
          text="Go to your 'Meds' tab"
        />
      ),
    },
    {
      key: 'add-meds-to-cart',
      component: (
        <MenuOption
          iconName="cart-duo"
          description="Review the details of your cart to make sure everything is correct."
          text="Add medications to cart"
        />
      ),
    },
    {
      key: 'schedule-delivery',
      component: (
        <MenuOption
          iconName="truck-duo"
          description="Schedule your delivery and provide a payment method."
          text="Checkout"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="How to order"
      buttons={[<MedsTabButton key="How to Order" />]}
      analyticsName="assistant how to order"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
