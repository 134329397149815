// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
import { push } from 'react-router-redux';
import { viewNotice } from '~shared/actions/notices';
import { rateShipmentDismissed } from '~shared/actions/rateShipment';
// eslint-disable-next-line import/no-deprecated
import { type SurveyResponsePayload, submitSurveyResponse } from '~shared/actions/surveyResponses';
import { POST_DELIVERY_MODAL_NOTICE } from '~shared/features/notices/selectors/getNotices';
import { createSurveyResponseDataPayload } from '~shared/features/rate-shipment/helpers';
import getPendingRating from '~shared/features/rate-shipment/selectors/getPendingRating';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { type ANALYTICS_EVENT, EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import RateShipmentPage from './RateShipmentPage';

const mapStateToProps = (state: ReduxStateShared) => {
  const uuid = getPendingRating(state)?.uuid;
  return { uuid };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    sendAnalyticsEvent: (key: ANALYTICS_EVENT, properties: Record<string, any> | null | undefined) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(key, {
            view: 'page',
            ...properties,
          }),
        ),
      );
    },
    onSubmit: (uuid: string, rating: number, freeformReason: string) => {
      const surveyResponse: SurveyResponsePayload = {
        name: 'rate_shipment',
        data: JSON.stringify(createSurveyResponseDataPayload(rating, freeformReason)),
        survey_uuid: uuid,
        version: 2,
      };
      // eslint-disable-next-line import/no-deprecated
      dispatch(submitSurveyResponse(surveyResponse));
      dispatch(viewNotice(POST_DELIVERY_MODAL_NOTICE));
      dispatch(push('/prescriptions'));
      toaster.toast({
        kind: 'success',
        title: 'Thank you for your rating!',
      });
    },
    onDismiss: () => {
      dispatch(rateShipmentDismissed());
      dispatch(viewNotice(POST_DELIVERY_MODAL_NOTICE));
      dispatch(push('/prescriptions'));
    },
  };
};

const mergeProps = (
  { uuid }: ReturnType<typeof mapStateToProps>,
  {
    sendAnalyticsEvent,
    onSubmit: dispatchOnSubmit,
    onDismiss: dispatchOnDismiss,
  }: ReturnType<typeof mapDispatchToProps>,
) => {
  const trackRateShipmentPageViewed = () => {
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__EXPERIENCE_VIEWED, {
      view: 'page',
      uuid,
    });
  };

  const sendRatingClickedAnalyticsEvent = (rating: number) => {
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__RATING_CLICKED, {
      rating,
      uuid,
    });
  };

  const onSubmit = (rating: number, freeformReason: string) => {
    dispatchOnSubmit(uuid, rating, freeformReason);
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__SUBMIT_CLICKED, {
      uuid,
    });
  };

  const onDismiss = () => {
    dispatchOnDismiss();
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__DISMISS_CLICKED, {
      uuid,
    });
  };

  return {
    trackRateShipmentPageViewed,
    sendRatingClickedAnalyticsEvent,
    onDismiss,
    onSubmit,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RateShipmentPage);
