import { createSelector } from 'reselect';
import getPriceOptionsInOrder from './getPriceOptionsInOrder';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

export const getPriceOptionsForPrescriptionInOrder = createSelector(
  [getPriceOptionsInOrder, getPrescriptionID],
  (priceOptionsByPrescription, prescriptionID) => priceOptionsByPrescription[prescriptionID] || [],
);
