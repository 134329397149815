import { type InsuranceSource } from '~shared/constants';
import { isNative } from '~shared/helpers/isNative';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { type ReduxStateShared } from '~shared/store';

export const getInsuranceSource = (state: ReduxStateShared): InsuranceSource | null => {
  const relationship = getAddFamilyMemberRelationship(state);

  if (isNative) {
    // insurance source is passed as a prop in native components
    return null;
  }

  const currentRoute = window.location.pathname;

  if (currentRoute.startsWith('/onboard/insurance-input')) {
    return 'onboarding';
  }

  if (currentRoute === '/prescriptions') {
    return 'med details';
  }

  // insurance can be added in add family member flow
  if (relationship && currentRoute.startsWith('/me')) {
    return 'add family member';
  }

  // insurance can be added in profile for account owner and family members
  if (currentRoute.startsWith('/me')) {
    return 'profile';
  }

  return null;
};
