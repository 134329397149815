// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Body,
  Button,
  LgPadding,
  LgSpacing,
  MdSpacing,
  XxsSpacing,
} from '@alto/design-system';
import { useAddToCart } from '@alto/features';
import { type VacationSupply } from '@alto/scriptdash/alto/patient_app/vacation_supplies/types/v1/vacation_supply';
import { PriceOverrideReasonMap } from '@alto/scriptdash/alto/pricing/types/v3/price_override_reason';
import { format, parse } from 'date-fns';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { type BillingOverridePayload, autobillPrescription } from '~shared/actions/prescriptions';
import { openCart } from '~shared/actions/ui/cart';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
// eslint-disable-next-line import/no-deprecated
import { type VacationSupplyFormValues, validateVacationForm } from '~shared/features/vacation-supply/helpers';
import { DATE_FORMATS, formatDateStringForAPI } from '~shared/helpers/date';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
// eslint-disable-next-line import/no-deprecated
import { fetchLatestVacationSupply } from '~shared/queries/useFetchLatestVacationSupply';
import { useDispatchShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { VacationSupplyForm } from '~web/features/my-meds/components/modals/VacationSupply/VacationSupplyForm';

type VacationSupplyFailureActionSheetProps = {
  readonly prescription: Prescription;
  readonly vacationSupply: VacationSupply;
};

export const VacationSupplyFailureActionSheet = ({
  prescription,
  vacationSupply,
}: VacationSupplyFailureActionSheetProps) => {
  const { closeActionSheet, goToPreviousActionSheet } = useContext(ActionSheetContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useDispatchShared();
  const { handleAddToCart } = useAddToCart();
  const [latestVacationSupply, setLatestVacationSupply] = useState(vacationSupply);
  // approved_at is unused till autobill is ready
  const {
    prescription: prescription_info,
    insurance_name,
    travel_destination,
    travel_start_date,
    travel_end_date,
  } = latestVacationSupply;

  useEffect(() => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(
          EVENTS.VACATION_SUPPLY_AUTOBILL_FAIL_FORM_VIEWED,
          {},
          {
            prescriptionId: prescription.id,
          },
        ),
      ),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onVacationSupplyFormSubmit = async (values: VacationSupplyFormValues) => {
    const vacationOverrideParams: BillingOverridePayload = {
      reason: PriceOverrideReasonMap.VACATION_SUPPLY,
      data: {
        travel_destination: values.travelDestination,
        travel_start_date: formatDateStringForAPI(values.startDate) || '',
        travel_end_date: formatDateStringForAPI(values.returnDate) || '',
      },
    };

    setIsLoading(true);
    // eslint-disable-next-line import/no-deprecated
    const result = await dispatch(autobillPrescription(prescription.id, vacationOverrideParams));

    if (result.autobillSuccess) {
      const res = await handleAddToCart({
        resource_id: prescription.id,
        resource_type: 'Prescription',
        resetOrderDate: true,
      });
      setIsLoading(false);
      if (res.success) {
        dispatch(openCart());
        closeActionSheet();
      }
    } else {
      setIsLoading(false);
      // eslint-disable-next-line import/no-deprecated
      const latestVacationSupplyResponse = await fetchLatestVacationSupply({
        prescription_id: prescription.id,
        status: 'failed',
      });
      if (latestVacationSupplyResponse?.data) {
        setLatestVacationSupply(latestVacationSupplyResponse.data);
      }
    }
  };

  const { nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID: prescription.id });
  const earliestAvailableDate = nextAvailableDate?.earliest.date || '';

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      travelDestination: travel_destination,
      returnDate: format(parse(travel_end_date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0), DATE_FORMATS.DATE_INPUT_FORMAT),
      startDate: format(
        parse(travel_start_date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0),
        DATE_FORMATS.DATE_INPUT_FORMAT,
      ),
    },
    onSubmit: (values) => {
      onVacationSupplyFormSubmit(values);
    },
    // eslint-disable-next-line import/no-deprecated
    validate: (values) => validateVacationForm(earliestAvailableDate, values),
  });

  return (
    <ActionSheetV2
      title="Unable to confirm vacation supply"
      handleClose={() => {
        goToPreviousActionSheet();
      }}
      buttons={[
        <Button
          key="submit"
          onPress={formik.handleSubmit}
          loading={isLoading}
          label="Re-submit vacation supply"
        />,
      ]}
      analyticsName="vacation supply failure"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          Next steps
        </Body>
        <XxsSpacing />
        <Body>
          To get your medication you will need to contact your insurance yourself and get them to provide the override.
        </Body>
        <LgSpacing />
        <Body
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          {insurance_name}
        </Body>
        <XxsSpacing />
        <Body>Call the number on the back of your insurance card.</Body>
        <LgSpacing />
        <Body
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          {prescription_info.medication_name}
        </Body>
        <XxsSpacing />
        <Body>
          <AltoIcon name="rxchart-small" />
          &ensp; {prescription_info.rx_number}
          <br />
          <AltoIcon name="pillbottle-small" />
          &ensp; {prescription_info.quantity_with_unit}
        </Body>
        <MdSpacing />
        <VacationSupplyForm
          values={formik.values}
          errors={formik.errors}
          handleChange={formik.handleChange}
          description="Once you have received confirmation from your insurance we can process it  and deliver your medication."
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
