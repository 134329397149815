// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Card, H1, SecondaryPage, XxxlPadding, useScreenSize } from '@alto/design-system';
import { MessageWithSubject } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { goBack } from 'react-router-redux';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { type ActionSheetProviderStackCacheKey } from '~shared/features/alto-assistant/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';

export const MessageForm = () => {
  const { isMDScreenOrBigger } = useScreenSize();
  const { trackEvent } = useAnalytics();
  const { restoreStack } = useContext(ActionSheetContext);
  const { getParam } = useNavigation<'RouteAssistantMessage'>();
  const dispatch = useDispatchShared();
  const actionStackCacheKey = getParam('cacheKey') ?? 'assistant';

  // copy of onPressBack handler in MessageWithSubject form
  const handleDismiss = () => {
    dispatch(goBack());
    restoreStack<ActionSheetProviderStackCacheKey>(actionStackCacheKey);
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.MESSAGE_WITH_SUBJECT_BACK,
        type: 'button',
      },
    });
  };

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      HeaderContent={
        <XxxlPadding
          topPadding={isMDScreenOrBigger ? SPACING.STATIC.XXXL : SPACING.STATIC.MD}
          bottomPadding={isMDScreenOrBigger ? SPACING.STATIC.XXXL : SPACING.STATIC.XL}
          leftPadding={SPACING.STATIC.MD}
          rightPadding={SPACING.STATIC.MD}
        >
          <H1 center>New Message</H1>
        </XxxlPadding>
      }
      dismissIcon="chevronleft"
      onDismiss={handleDismiss}
    >
      <Card overflow="hidden">
        <MessageWithSubject />
      </Card>
    </SecondaryPage>
  );
};
