import { COLORS } from '@alto/design-library-tokens';
import styled from 'styled-components/native';
import { Card, type CardProps } from './Card';

export type FlatCardProps = CardProps;

export const FlatCard = styled(Card)<FlatCardProps>`
  box-shadow: 0 0 0 transparent;
  border: none;
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
`;
