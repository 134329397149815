// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { formatMedications } from '~shared/helpers/medication';

type MedProps = {
  readonly medicationNames: string[];
  readonly isLastRefill?: boolean;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly showAllMeds?: boolean;
  readonly newMedicationNames?: string[];
};

export const MedRow = ({
  medicationNames,
  isLastRefill,
  backgroundColor,
  showAllMeds,
  newMedicationNames,
}: MedProps) => {
  if (medicationNames.length === 0) {
    return null;
  }

  let text = showAllMeds ? medicationNames.join('\n') : formatMedications(medicationNames).allMedications;
  if (isLastRefill) {
    text += "\nLast refill! Request more so you don't run out.";
  }

  const iconName = isLastRefill ? 'alert' : 'pillbottle';

  return (
    <>
      <PresentationListItem
        testID="med-row"
        LeftContent={
          <AltoIcon
            name={iconName}
            type="grey"
          />
        }
        text={text.trim()}
        backgroundColor={isLastRefill ? COLORS.BACKGROUND_COLORS.DANGER_LIGHTEST : backgroundColor}
      />
      {
        // if a med has been newly added via Order Bundling feature,
        // it should be highlighted in light green
        newMedicationNames && newMedicationNames.length > 0 ? (
          <PresentationListItem
            testID="med-row-bundled-meds"
            backgroundColor={COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTER}
            type="grey"
            text={newMedicationNames.join('\n')}
          />
        ) : null
      }
    </>
  );
};
