import React from 'react';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { useSelectorShared } from '~shared/store';
import { DeliverySummary } from './DeliverySummary';
import { OrderBundlingCheckoutDeliverySummary } from '~web/features/checkout/components/OrderBundlingCheckoutDeliverySummary';

export const CheckoutDeliverySummary = () => {
  const isOrderBundling = useSelectorShared(getIsOrderBundling);

  return isOrderBundling ? <OrderBundlingCheckoutDeliverySummary /> : <DeliverySummary />;
};
