// @owners { team: patients-team }
import {
  ActionSheetContext,
  Body,
  Button,
  Card,
  LgPadding,
  Link,
  LoadingEmptyState,
  SecondaryPage,
  XlPadding,
} from '@alto/design-system';
import { TransferLearnMoreActionSheet, useCreateTransfer, useQueryTransferConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect, useRef } from 'react';
import { push } from 'react-router-redux';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { type TransferCreatePayload } from '~shared/features/transfers/types';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type User } from '~shared/types';
import { FAQs } from '~web/features/onboarding/components/redesign/FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { previousUrl as getOnboardingPreviousUrl } from '~web/features/onboarding/helpers/urlHandler';
import TransferForm from '~web/features/transfers/components/TransferForm';

export type Props = {
  readonly isLoading: boolean;
  readonly onContinue: () => void;
  readonly hasFamily: boolean;
  readonly users: User[];
  readonly fetchMedicationNames: (query: string) => void;
  readonly isPatientsOtherScriptsOn: boolean | null | undefined;
};

const OnboardingTransfer = ({ onContinue, ...otherProps }: Props) => {
  const formRef = useRef(null);
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackPageView, trackEvent } = useAnalytics();
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const onboardingPreviousUrl = useSelectorShared((state) => getOnboardingPreviousUrl(state, currentRoute));
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryTransferConfigs();
  const { createTransferMutate } = useCreateTransfer();

  const onBack = () => {
    if (onboardingPreviousUrl) {
      dispatch(push(onboardingPreviousUrl));
    }
  };

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__TRANSFER_FORM_VIEWED,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLearnMorePress = () => {
    setActiveActionSheet(<TransferLearnMoreActionSheet />);
  };

  const onSubmit = (createTransferPayload: TransferCreatePayload) => {
    createTransferMutate({
      medications: createTransferPayload.medications,
      user_id: createTransferPayload.user_id,
      pharmacy: createTransferPayload.pharmacy,
      origin: ORIGIN_NAMES.ONBOARDING,
      onSuccess: onContinue,
    });
  };

  const onSkip = () => {
    trackEvent({ event: EVENTS.ONBOARDING__TRANSFER_SKIPPED });
    onContinue();
  };

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      withoutWebFramingElementHeights
      onDismiss={onBack}
      dismissIcon="chevronleft"
      buttons={[
        <Button
          type="tertiary"
          key="skip"
          label="Skip for now"
          onPress={onSkip}
        />,
        <Button
          type="primary"
          key="transfer"
          label="Transfer"
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          onPress={() => formRef.current.handleFormikSubmit()}
        />,
      ]}
      footerPlacementContext="webScreenWithoutPolicyFooter"
      headerBackgroundColor={headerBackgroundColor}
      HeaderContent={
        <OnboardingHeader
          title="We can transfer your other medications to Alto, too."
          subtitle={
            <Body center>
              If you’d like us to transfer your other prescriptions over to Alto, please provide us with your current
              pharmacy’s information.
            </Body>
          }
          link={
            <Link
              onPress={handleLearnMorePress}
              center
            >
              How do transfers work?
            </Link>
          }
        />
      }
      NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
      NavBarRightPressable={<FAQs />}
    >
      <Card>
        <LgPadding>
          <TransferForm
            ref={formRef}
            submitButtonProps={{
              onClick: onSubmit,
            }}
            showLearnMoreLink={false}
            {...otherProps}
          />
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};

export default OnboardingTransfer;
