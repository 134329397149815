// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getOnboarding } from './getOnboarding';
import { VERIFICATION_COMMS_CHANNEL, type VerificationCommsChannel } from '~shared/actions/verifications';
import { obscureEmail, obscurePhone } from '~shared/helpers/obscure';

export const getOnboardingVerification = createSelector(getOnboarding, (onboarding) => onboarding?.verification);

export const getOnboardingVerificationChannel = createSelector(
  getOnboarding,
  (onboarding) => onboarding?.verification?.channel,
);

export const getOnboardingVerificationRequired = createSelector(
  getOnboarding,
  (onboarding) => onboarding?.verification?.required,
);

export const getOnboardingVerificationUser = createSelector(
  getOnboarding,
  (onboarding) => onboarding?.verification?.user,
);

export const getOnboardingVerificationUuid = createSelector(
  getOnboarding,
  (onboarding) => onboarding?.verification?.uuid,
);

export const getOnboardingVerificationMethods = createSelector(
  getOnboarding,
  (onboarding) => onboarding?.verification?.verification_methods,
);

export type Option = {
  channel: VerificationCommsChannel;
  contact: string;
};

export const getOnboardingVerificationOptions = createSelector(
  [getOnboardingVerificationMethods, getOnboardingVerificationUser],
  (methods, user) => {
    if (!methods || !user) {
      return [];
    }

    return methods.map<Option>((method) => {
      let contact = '';

      switch (method) {
        case VERIFICATION_COMMS_CHANNEL.email:
          contact = obscureEmail(user.email || '');
          break;

        case VERIFICATION_COMMS_CHANNEL.sms:
        case VERIFICATION_COMMS_CHANNEL.call:
          contact = obscurePhone(user.phone || '');
          break;

        default:
      }

      return {
        channel: method,
        contact,
      };
    });
  },
);

export const getOnboardingVerificationContact = createSelector(
  [getOnboardingVerificationUser, getOnboardingVerificationChannel],
  (user, channel) => {
    switch (channel) {
      case VERIFICATION_COMMS_CHANNEL.email:
        return obscureEmail(user?.email || '');

      case VERIFICATION_COMMS_CHANNEL.sms:
      case VERIFICATION_COMMS_CHANNEL.call:
        return obscurePhone(user?.phone || '');

      default:
        return '';
    }
  },
);
