import { isBefore, isEqual } from 'date-fns';
import { createSelector } from 'reselect';
import { getPrescriptionsInOrder__DEPRECATED } from './getItems';
import {
  hasEligibleNextRefillDate,
  hasMultiplePriceOptions,
  isInCart,
  sortByRefillDate,
} from '~shared/features/checkout/hooks/useCrossSellPrescriptions';
import { getScheduleablePrescriptions } from '~shared/features/my-meds/selectors/getScheduleablePrescriptions';
import {
  getDeliveryMethodForOrder__DEPRECATED,
  getEarliestAvailableDateForOrder__DEPRECATED,
} from '~shared/features/next-available-date/selectors/getNextAvailableDateForOrder';
import { getNextAvailableDates } from '~shared/features/next-available-date/selectors/getNextAvailableDates';
import getScheduleablePrescriptionsByNextAvailableDateID, {
  type ScheduleablePrescriptionsByNextAvailableDateID,
} from '~shared/features/next-available-date/selectors/getScheduleablePrescriptionsByNextAvailableDateId';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { type NextAvailableDateMapping } from '~shared/reducers/nextAvailableDates';

// TODO: When we are able to remove the cart.ts middleware, and subsequently getCrossSellPrescriptions we can remove this function
const hasEligibleNextAvailableDate__DEPRECATED = (
  prescriptionID: number,
  nextAvailableDatesByID: NextAvailableDateMapping,
  prescriptionsByNextAvailableDateID: ScheduleablePrescriptionsByNextAvailableDateID,
  currentOrderEarliestAvailableDate: string,
  currentOrderDeliveryMethod: string,
) => {
  const nextAvailableDateIDForPrescription = prescriptionsByNextAvailableDateID[prescriptionID]?.nextAvailableDateID;
  const nextAvailableDateForPrescription = nextAvailableDatesByID[nextAvailableDateIDForPrescription];

  if (
    !nextAvailableDateForPrescription ||
    currentOrderDeliveryMethod !== nextAvailableDateForPrescription.deliveryMethod
  ) {
    return false;
  }

  const prescriptionNextAvailableDate = nextAvailableDateForPrescription.earliest.date;
  const prescriptionDate = new Date(prescriptionNextAvailableDate);
  const orderDate = new Date(currentOrderEarliestAvailableDate);
  return isEqual(prescriptionDate, orderDate) || isBefore(prescriptionDate, orderDate);
};

// @deprecated - use libs/shared/features/checkout/hooks/useCrossSellPrescriptions hook
const getCrossSellPrescriptions__DEPRECATED = createSelector(
  [
    getScheduleablePrescriptions,
    getEarliestAvailableDateForOrder__DEPRECATED,
    getDeliveryMethodForOrder__DEPRECATED,
    getNextAvailableDates,
    getScheduleablePrescriptionsByNextAvailableDateID,
    getPrescriptionsInOrder__DEPRECATED,
    getPrescriptionPricing,
  ],
  (
    prescriptions,
    currentOrderEarliestAvailableDate,
    currentOrderDeliveryMethod,
    nextAvailableDatesByID,
    prescriptionsByNextAvailableDateID,
    prescriptionsInOrder,
    prescriptionPricing,
  ) => {
    return prescriptions
      .filter(
        (prescription) =>
          !prescription.is_add_on_otc &&
          hasEligibleNextRefillDate(prescription.next_refill_date) &&
          currentOrderEarliestAvailableDate &&
          hasEligibleNextAvailableDate__DEPRECATED(
            prescription.id,
            nextAvailableDatesByID,
            prescriptionsByNextAvailableDateID,
            currentOrderEarliestAvailableDate,
            currentOrderDeliveryMethod,
          ) &&
          !isInCart(prescription.id, prescriptionsInOrder) &&
          !hasMultiplePriceOptions(prescription.id, prescriptionPricing),
      )
      .sort(sortByRefillDate);
  },
);

export default getCrossSellPrescriptions__DEPRECATED;
