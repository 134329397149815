// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Button, LgPadding, ListButton, XlSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { type InsuranceSource } from '~shared/constants';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getUserCashPayOnly } from '~shared/features/users/selectors/getUserCashPayOnly';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useSelectorShared } from '~shared/store';
import type { Insurance } from '~shared/types';
import { ConfirmCashPayActionSheet } from './ConfirmCashPayActionSheet';

type Props = {
  readonly hasActiveInsurances: boolean;
  readonly insurances: Insurance[];
  readonly onAddInsurance?: () => void; // web callback only used in onboarding flow
  readonly source: InsuranceSource;
};

const isWeb = getPlatformOS() === 'web';

export const InsuranceButton = ({ hasActiveInsurances, insurances, onAddInsurance, source }: Props) => {
  const { navigate } = useNavigation();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const userID = selectedUserID || currentUserID;
  const isCashPayOnly = useSelectorShared((state) =>
    getUserCashPayOnly(state, {
      userID,
    }),
  );
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const handleAddBenefit = () => {
    if (!userID) return;
    navigate('RouteInsuranceAdd', { source, userID });
  };

  const handleSelectCashPay = () => {
    setActiveActionSheet(
      <ConfirmCashPayActionSheet
        source={source}
        hasAllInactiveInsurances={!hasActiveInsurances && insurances.length > 0}
      />,
    );
  };

  return (
    <LgPadding
      topPadding={SPACING.STATIC.MD}
      bottomPadding={SPACING.STATIC.NONE}
      rightPadding={isWeb ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
      leftPadding={isWeb ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
    >
      {isWeb ? (
        <Button
          label="Add benefits"
          width="inline"
          small
          onPress={source === 'onboarding' && !!onAddInsurance ? onAddInsurance : handleAddBenefit}
        />
      ) : (
        <>
          <ListButton
            label="Add benefits"
            onPress={handleAddBenefit}
          />
          {!hasActiveInsurances && !isCashPayOnly ? (
            <>
              <XlSpacing />
              <Button
                label="I don't have any benefits"
                onPress={handleSelectCashPay}
                type="secondary"
              />
            </>
          ) : null}
        </>
      )}
    </LgPadding>
  );
};
