// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Column, FlatCard, LgPadding, LgSpacing, XxsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Link, Modal, ModalContent } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { openModal } from '~shared/actions/modal';
import { getPrescriptionsHash } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { stripNonDigits } from '~shared/helpers/string';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

export type MedicationAlternativeModalProps = {
  readonly prescriptionID: number;
  readonly medicationName: string;
};

export const MedicationAlternativeModal = ({ prescriptionID, medicationName }: MedicationAlternativeModalProps) => {
  const dispatch = useDispatchShared();
  const prescriptionHash = useSelectorShared(getPrescriptionsHash);
  const { doctor, provider_phone } = prescriptionHash[prescriptionID] || {};
  const prescriberName = `${doctor?.first_name || ''} ${doctor?.last_name || ''}`.trim() || 'Your provider';

  return (
    <StyledModal
      onClose={() => dispatch(openModal('MEDICATION_BACKORDERED_MODAL', { prescriptionID, medicationName }))}
      showing
      title="Get an alternative medication"
    >
      <ModalContent>
        <Body>
          Let your provider know that {medicationName || 'your medication'} is no longer in production and have them
          send in a prescription for an alternative medication.
        </Body>
        <LgSpacing />
        <Column flexGrow={1}>
          <FlatCard>
            <LgPadding>
              <Body
                color={COLORS.TEXT_COLORS.PRIMARY}
                fontFamily="semibold"
              >
                {prescriberName}
              </Body>
              {provider_phone ? (
                <>
                  <XxsSpacing />
                  <Body>
                    <Link href={`tel:${stripNonDigits(provider_phone)}`}>{provider_phone}</Link>
                  </Body>
                </>
              ) : null}
            </LgPadding>
          </FlatCard>
        </Column>
      </ModalContent>
    </StyledModal>
  );
};
