// @owners { team: patients-team }
import { Loader } from '@googlemaps/js-api-loader';
import { type GooglePlaceType, PLACE_DETAILS_FIELDS } from './types';

export const loader = new Loader({
  apiKey: 'AIzaSyC-LrVJa4v6FD5gx7wIPoRgKr_LQ80JNBU',
  version: 'weekly',
  libraries: ['places'],
});

/*
  Wrapper around the google places API
  See here for API details: https://developers.google.com/places/web-service/autocomplete

  There is a separate implementation for web because the CORS policy in the browser
  prevents alto clients (i.e. app.alto.com) from making requests to google maps api.

  This should eventually be broken out into @alto/google-places
 */
export class GooglePlacesService {
  autocompleteService: google.maps.places.AutocompleteService | null = null;
  placesService: google.maps.places.PlacesService | null = null;

  async init() {
    const { AutocompleteService, PlacesService } = await loader.importLibrary('places');
    this.autocompleteService = new AutocompleteService();
    // @ts-expect-error can be null element, no map on page
    this.placesService = new PlacesService(document.getElementById('map'));
  }

  async autocomplete(input: string, types: GooglePlaceType[]) {
    if (!this.autocompleteService) {
      await this.init();
    }

    const componentRestrictions = { country: ['us'] };

    return this.autocompleteService?.getPlacePredictions({ types, input, componentRestrictions });
  }

  async placeDetails(placeId = '') {
    if (!this.placesService) {
      await this.init();
    }

    return new Promise<google.maps.places.PlaceResult | null>((resolve) => {
      this.placesService?.getDetails({ placeId, fields: PLACE_DETAILS_FIELDS }, (placeResult) => {
        resolve(placeResult);
      });
    });
  }

  async getDetailsFromAutocomplete(input: string, types: GooglePlaceType[]): Promise<google.maps.places.PlaceResult[]> {
    try {
      const response = await this.autocomplete(input, types);
      if (!response) return [];

      return await this.getPlaceDetails(response.predictions);
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  async getPlaceDetails(predictions: google.maps.places.QueryAutocompletePrediction[]) {
    const placeDetailsPromises = predictions.map((predictedLocation) => {
      return this.placeDetails(predictedLocation.place_id);
    });
    const results = await Promise.all(placeDetailsPromises);
    return results.filter((result): result is google.maps.places.PlaceResult => result !== null);
  }
}

// used for testing googlePlacesDataSource.test.ts
export default GooglePlacesService;
