import { type AltoIconName, BASE_FONT_SIZE, COLORS, SPACING } from '@alto/design-library-tokens';
import React, { useEffect, useState } from 'react';
import { type ImageSourcePropType, Platform, TouchableHighlight, View } from 'react-native';
import styled from 'styled-components/native';
import { ActionSheetContext, ActionSheetV2 } from '../../action-sheet';
import { Border } from '../../borders/src/Border';
import { LgPadding, MdPadding, Row, XsPadding } from '../../containers';
import { AltoIcon, smallifyIconName } from '../../icon';
import { ListButton, type ListItemLeftContentProps } from '../../lists';
import { LgSpacing, MdSpacing, SmSpacing, XsSpacing } from '../../separators';
import { Caption, Text } from '../../typography';
import { InputRadio, type InputRadioProps } from './InputRadio/InputRadio';
import { InputRadioGroup } from './InputRadio/InputRadioGroup';
import { InputLabelRow } from './InputText/InputLabelRow';
import { InputLoader } from './shared/InputLoader';

const BackgroundContainer = styled(View)<{ disabled?: boolean }>`
  background-color: ${({ disabled }) =>
    disabled ? COLORS.BACKGROUND_COLORS.GREY_LIGHTER : COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
`;

const SelectedOptionText = styled(Text)`
  flex: 1;
  font-size: ${BASE_FONT_SIZE}px;
`;

export type Option = Pick<
  InputRadioProps,
  'label' | 'value' | 'descriptions' | 'radioPlacement' | 'RightContent' | 'tag' | 'tagPlacement'
> & {
  /**
   * The icon for the option. Use this if you can pass a simple AltoIconName string.
   */
  icon?: AltoIconName;
  /**
   * The LeftContent for the option. Use this if you have particular logic for
   * the left content icon that can not be expressed by just a simple AltoIconName.
   * Ex: When selecting a payment method, we use AltoIcon for credit card options
   * or ThirdPartyIcon for Apple Pay or Google Pay payment methods.
   */
  LeftContent?: ListItemLeftContentProps;
};

const ADD_OPTION_VALUE = 'ADD_OPTION_VALUE';

export type AddOption = Option & {
  value: typeof ADD_OPTION_VALUE;
  onPress: () => void;
};

type InputSelectActionSheetProps = {
  readonly setLocalValue: (newValue?: string) => void;
  readonly localValue?: string;
  readonly description?: string;
  readonly showSeparators?: boolean;
} & Pick<InputSelectProps, 'modalTitle' | 'options' | 'illustrationSrc' | 'onValueChange'>;

const getLeftContent = ({ LeftContent, icon }: { LeftContent?: ListItemLeftContentProps; icon?: AltoIconName }) => {
  if (LeftContent) {
    return LeftContent;
  }
  if (icon) {
    return <AltoIcon name={icon} />;
  }
  return undefined;
};

const InputSelectActionSheet = ({
  modalTitle,
  description,
  options,
  illustrationSrc,
  onValueChange,
  setLocalValue,
  localValue,
  showSeparators = false,
}: InputSelectActionSheetProps) => {
  const { goToPreviousActionSheet } = React.useContext(ActionSheetContext);
  const addOption = options.find(({ value }) => value === ADD_OPTION_VALUE) as AddOption;

  const handleCloseModal = () => {
    goToPreviousActionSheet();
  };

  const handleSelectOption = (selectedValue?: string) => {
    if (selectedValue !== undefined && onValueChange) {
      onValueChange(selectedValue);
    }
    setLocalValue(selectedValue);
    handleCloseModal();
  };

  return (
    <ActionSheetV2
      title={modalTitle}
      description={description}
      handleClose={handleCloseModal}
      illustrationSrc={illustrationSrc}
      analyticsName="input select"
    >
      <InputRadioGroup
        onValueChange={handleSelectOption}
        defaultValue={localValue}
      >
        {options.map(
          ({ label, value, descriptions, icon, LeftContent, RightContent, radioPlacement, tag, tagPlacement }) =>
            value === ADD_OPTION_VALUE ? null : (
              <React.Fragment key={`input-radio-option-${label}`}>
                <InputRadio
                  radioPlacement={radioPlacement}
                  key={value}
                  label={label}
                  descriptions={descriptions}
                  LeftContent={getLeftContent({ LeftContent, icon })}
                  RightContent={RightContent}
                  value={value}
                  tag={tag}
                  tagPlacement={tagPlacement}
                />
                {showSeparators ? (
                  <Border
                    hideLeft
                    hideRight
                    hideBottom
                  />
                ) : null}
              </React.Fragment>
            ),
        )}
      </InputRadioGroup>
      {Platform.OS === 'web' ? <LgSpacing /> : null}
      {addOption ? (
        <LgPadding topPadding={SPACING.STATIC.SM}>
          <ListButton
            onPress={addOption.onPress}
            label={addOption.label}
            rightIcon="plus-small"
          />
        </LgPadding>
      ) : null}
    </ActionSheetV2>
  );
};

export type InputSelectProps = {
  readonly label?: string;
  readonly defaultValue?: string;
  readonly value?: string;
  readonly error?: string;
  readonly required?: boolean;
  readonly onValueChange?: (value: string) => void;
  readonly options: (Option | AddOption)[];
  readonly modalTitle?: string;
  readonly description?: string;
  readonly placeholder?: string;
  readonly leftIcon?: AltoIconName;
  readonly caption?: string;
  readonly sublabel?: string;
  readonly optional?: boolean;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly showSeparators?: boolean;
  readonly illustrationSrc?: ImageSourcePropType;
  readonly testID?: string;
};

export const InputSelect = ({
  label,
  defaultValue,
  value,
  error,
  required,
  onValueChange,
  options,
  modalTitle = 'Select an option',
  description,
  placeholder = '',
  leftIcon,
  caption,
  sublabel,
  optional,
  disabled,
  loading,
  showSeparators = false,
  illustrationSrc,
  testID,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: InputSelectProps) => {
  const { setActiveActionSheet } = React.useContext(ActionSheetContext);
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    if (value) {
      setLocalValue(value);
      return;
    }
    if (defaultValue) {
      setLocalValue(defaultValue);
    }
  }, [defaultValue, value]);
  const selectedOption = options.find((option) =>
    localValue !== undefined ? option.value === localValue : option.value === defaultValue,
  );
  const initialIcon = leftIcon ?? selectedOption?.icon ?? undefined;
  const smallIconForInput = initialIcon ? smallifyIconName(initialIcon) : undefined;
  const selectedLeftContent = selectedOption?.LeftContent;

  const handleOpenModal = () => {
    setActiveActionSheet(
      <InputSelectActionSheet
        setLocalValue={setLocalValue}
        options={options}
        localValue={localValue}
        illustrationSrc={illustrationSrc}
        modalTitle={modalTitle}
        description={description}
        onValueChange={onValueChange}
        showSeparators={showSeparators}
      />,
      { shouldRetainPreviousActionSheet: true },
    );
  };

  return (
    <View>
      <TouchableHighlight
        disabled={disabled || loading}
        underlayColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
        onPress={handleOpenModal}
        testID={testID}
      >
        <View>
          {!!label && (
            <InputLabelRow
              label={label}
              sublabel={sublabel}
              required={required}
              optional={optional}
              error={error}
            />
          )}
          <Border
            radius="LG"
            color={error ? COLORS.BORDER_COLORS.DANGER : COLORS.BORDER_COLORS.LIGHT}
          >
            <BackgroundContainer disabled={disabled || loading}>
              <MdPadding>
                {loading ? (
                  <InputLoader />
                ) : (
                  <Row>
                    {selectedLeftContent ? (
                      <Row centerVertically>
                        {selectedLeftContent}
                        <XsSpacing />
                      </Row>
                    ) : null}
                    {smallIconForInput ? (
                      <Row centerVertically>
                        <AltoIcon
                          name={smallIconForInput}
                          type={error ? 'danger' : undefined}
                        />
                        <XsSpacing />
                      </Row>
                    ) : null}
                    <SelectedOptionText
                      color={selectedOption ? COLORS.TEXT_COLORS.BLACK : COLORS.TEXT_COLORS.DISABLED}
                      numberOfLines={1}
                    >
                      {selectedOption ? selectedOption.label : placeholder}
                    </SelectedOptionText>
                    <Row
                      rowEnd
                      centerVertically
                    >
                      <MdSpacing />
                      <AltoIcon
                        name="chevrondown-small"
                        type="grey"
                      />
                    </Row>
                  </Row>
                )}
              </MdPadding>
            </BackgroundContainer>
          </Border>
          {caption ? (
            <>
              <SmSpacing />
              <Caption color={error ? COLORS.TEXT_COLORS.DANGER : undefined}>{caption}</Caption>
            </>
          ) : null}
          {!!error && (
            <XsPadding leftPadding={SPACING.STATIC.NONE}>
              <Text
                textSize="mini"
                color={COLORS.TEXT_COLORS.DANGER}
              >
                {error}
              </Text>
            </XsPadding>
          )}
        </View>
      </TouchableHighlight>
    </View>
  );
};
