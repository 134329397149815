// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, FlexRow, Modal, ModalContent, ModalFooter, Text, spacing } from '@alto/pocky';
import React from 'react';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import HeaderImage, { MODAL_SIZE } from '~web/components/HeaderImage';
import insuranceCard from '~web/images/insuranceCard@2x.png';

type Props = {
  readonly onClose: () => void;
};

const InsuranceInputInfoModal = (props: Props) => {
  const { onClose } = props;
  return (
    <Modal
      onClose={onClose}
      showDismiss
    >
      <ModalContent>
        <HeaderImage
          src={insuranceCard}
          alt="Illustration of insurance card"
          title="Where can I find this?"
          imgSize={MODAL_SIZE}
        />
        <FlexRow
          spacing={spacing.mdSpacing}
          noShrink
        >
          <Text>
            Typically your prescription insurance card will have an &quot;Rx&quot; symbol, and &quot;BIN&quot; and
            &quot;PCN&quot; numbers.
          </Text>
        </FlexRow>
      </ModalContent>
      <ModalFooter>
        <ButtonWrapper horizontallyAlignContent>
          <Button onClick={onClose}>Got It</Button>
        </ButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default InsuranceInputInfoModal;
