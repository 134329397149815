import { type AltoIconName, COLORS, type ColorVariant, type TEXT_COLORS_VALUES } from '@alto/design-library-tokens';
import { AltoIcon, Description, Row, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Tooltip } from '@alto/pocky';
import React, { PureComponent } from 'react';

type ColorProps = {
  success?: boolean | null | undefined;
  warning?: boolean | null | undefined;
  danger?: boolean | null | undefined;
  link?: boolean | null | undefined;
  light?: boolean | null | undefined;
};

function getIconColor({ success, warning, danger, link, light }: ColorProps): ColorVariant {
  if (success) {
    return 'success';
  }

  if (warning) {
    return 'danger';
  }

  if (danger) {
    return 'danger';
  }

  if (link) {
    return 'secondary';
  }

  if (light) {
    return 'disabled';
  }

  return 'grey';
}

const getTextColor = ({ success, warning, danger, link, light }: ColorProps): TEXT_COLORS_VALUES => {
  if (success) {
    return COLORS.TEXT_COLORS.SUCCESS;
  }

  if (warning) {
    return COLORS.TEXT_COLORS.WARNING;
  }

  if (danger) {
    return COLORS.TEXT_COLORS.DANGER;
  }

  if (link) {
    return COLORS.TEXT_COLORS.SECONDARY;
  }

  if (light) {
    return COLORS.TEXT_COLORS.DISABLED;
  }

  return COLORS.TEXT_COLORS.GREY;
};

type Props = {
  readonly bold?: boolean;
  readonly children: string;
  readonly danger?: boolean;
  readonly icon: AltoIconName;
  readonly infoText?: string;
  readonly success?: boolean;
  readonly warning?: boolean;
  readonly link?: boolean;
  readonly lightText?: boolean;
};

export default class InfoRow extends PureComponent<Props> {
  static defaultProps = {
    bold: false,
    danger: false,
    infoText: '',
    link: false,
    success: false,
    warning: false,
  };

  render() {
    const { bold, children, danger, icon, infoText, success, warning, link, lightText } = this.props;

    if (!children) return null;

    return (
      <Row centerVertically>
        <AltoIcon
          name={icon}
          type={getIconColor({ success, warning, danger, link })}
        />
        <XsSpacing />
        <Description
          fontFamily={bold ? 'bold' : undefined}
          color={getTextColor({ danger, success, warning, link, light: lightText })}
        >
          {children}
        </Description>
        <XsSpacing />
        {infoText ? (
          <Tooltip content={infoText}>
            <AltoIcon
              name="info-small"
              type="secondary"
            />
          </Tooltip>
        ) : null}
      </Row>
    );
  }
}
