// @owners { team: patients-team }
import { compareDesc, parseISO } from 'date-fns';
import { type PaymentMethod } from '~shared/types';

export function getDefaultPaymentMethodID(paymentMethods: PaymentMethod[]): number | null | undefined {
  const numPaymentMethods = paymentMethods.length;

  if (numPaymentMethods === 0) {
    return null;
  }

  if (numPaymentMethods === 1) {
    return paymentMethods[0].id;
  }

  const primaryPaymentMethod = paymentMethods.find((pm) => pm.is_primary);

  if (primaryPaymentMethod) {
    return primaryPaymentMethod.id;
  }

  // default to most recently used payment method
  const recentPaymentMethods = paymentMethods.sort((a, b) => {
    if (!a.last_used_at) {
      return 1;
    }
    if (!b.last_used_at) {
      return -1;
    }
    return compareDesc(parseISO(a.last_used_at), parseISO(b.last_used_at));
  });
  return recentPaymentMethods[0].id;
}
