// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, AltoIcon, Pressable, XxsPadding } from '@alto/design-system';
import React, { useCallback, useContext, useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { FeeDetailsActionSheet } from './FeeDetailsActionSheet';

type Props = {
  readonly origin: 'select date' | 'line item';
};

export const DeliveryFeeToolTip = ({ origin }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.DELIVERY_FEE_TOOLTIP_SHOWN,
      params: { origin },
    });
  }, [trackPageView, origin]);

  const handleInfoPress = useCallback(() => {
    setActiveActionSheet(<FeeDetailsActionSheet />);
  }, [setActiveActionSheet]);

  return (
    <Pressable onPress={handleInfoPress}>
      <XxsPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <AltoIcon name="info-small" />
      </XxsPadding>
    </Pressable>
  );
};
