// @owners { team: patients-team }
import { Card, Column, H1, LgPadding, MdSpacing, Row } from '@alto/design-system';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import { Icon } from '~web/features/essentials/primitives/Icon';
import { View } from '~web/features/essentials/primitives/View';

type Props = {
  readonly children: ReactNode;
  readonly onClose: () => void;
  readonly title: string;
  readonly showing?: boolean;
};

const Overlay = styled(Row)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  touch-action: none;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
`;

export const Modal = ({ onClose, children, showing, title }: Props) => {
  if (!showing) {
    return null;
  }

  return (
    <Overlay center>
      <Column center>
        <Card>
          <LgPadding>
            <Row right>
              <View onClick={onClose}>
                <Icon
                  name="close"
                  label="close modal"
                  size="LG"
                  interactive
                />
              </View>
            </Row>
            <MdSpacing />
            <H1>{title}</H1>
            {children}
          </LgPadding>
        </Card>
      </Column>
    </Overlay>
  );
};
