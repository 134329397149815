import { COLORS, type TEXT_COLORS_VALUES } from '@alto/design-library-tokens';
import React, { useEffect, useRef } from 'react';
import { Animated, Easing, TouchableHighlight, View } from 'react-native';
import styled from 'styled-components/native';
import { Constants, useScreenSize } from '../../../utils';
import { Column, Row } from '../../containers';
import { SmSpacing, XsSpacing, XxsSpacing } from '../../separators';
import { Text } from '../../typography';

export type MinimalSwitchProps = {
  readonly accessibilityLabel?: string;
  readonly value?: boolean;
  readonly onValueChange: (arg0: boolean) => void;
};

export type SwitchProps = MinimalSwitchProps & {
  readonly label?: string;
  readonly labelColor?: TEXT_COLORS_VALUES;
  readonly info?: string;
  readonly disabled?: boolean;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
};

const StyledButton = styled(TouchableHighlight)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const SwitchBackground = styled(View)<{ value: boolean | undefined }>`
  border-radius: 32px;
  width: 52px;
  height: 32px;
  background-color: ${({ value }) => (value ? COLORS.PALETTE.PRIMARY.DEFAULT : COLORS.PALETTE.GREYSCALE.DEFAULT)};
`;

const SwitchThumb = styled(Animated.View)`
  border-radius: 28px;
  margin: 2px 0px 2px 2px;
  height: 28px;
  width: 28px;
  background-color: ${COLORS.PALETTE.GREYSCALE.WHITE};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
`;

const inputRange: number[] = [0, 1];
const outputRange: number[] = [0, 20];

const Label = ({ label, labelColor }: { readonly label: string; readonly labelColor?: TEXT_COLORS_VALUES }) => {
  return (
    <Row>
      <Text
        tight
        fontFamily="semibold"
        color={labelColor}
      >
        {label}
      </Text>
    </Row>
  );
};

const Info = ({ info }: { readonly info: string }) => {
  return (
    <Row>
      <Text
        tight
        color={COLORS.TEXT_COLORS.GREY}
      >
        {info}
      </Text>
    </Row>
  );
};

const SwitchText = ({
  label,
  labelColor,
  info,
}: {
  readonly label: string | null | undefined;
  readonly labelColor?: TEXT_COLORS_VALUES;
  readonly info: string | null | undefined;
}) => {
  if (!label && !info) {
    return null;
  }

  return (
    <>
      <Column wrap>
        {!!label && (
          <Label
            label={label}
            labelColor={labelColor}
          />
        )}
        {!!label && !!info && <XxsSpacing />}
        {!!info && <Info info={info} />}
      </Column>
      <XsSpacing />
    </>
  );
};

export const Switch = ({
  accessibilityLabel,
  label,
  labelColor,
  value = false,
  info,
  onValueChange,
  testID,
  disabled,
}: SwitchProps) => {
  const { isXSScreen } = useScreenSize();
  const position = useRef(new Animated.Value(value ? 1 : 0)).current;
  const translate = position.interpolate({
    inputRange,
    outputRange,
  });

  useEffect(() => {
    Animated.timing(position, {
      duration: 50,
      easing: Easing.linear,
      useNativeDriver: Constants.useNativeDriver,
      toValue: value ? 1 : 0,
    }).start();
  }, [value, position]);

  return (
    <StyledButton
      testID={testID}
      disabled={disabled}
      accessibilityRole="switch"
      accessibilityLabel={`${accessibilityLabel || label || 'switch'}: ${value ? 'on' : 'off'}`}
      underlayColor="transparent"
      onPress={() => {
        onValueChange(!value);
      }}
    >
      <Row
        centerVertically
        spaceBetween
      >
        <Row flexShrink={1}>
          <SwitchText
            label={label}
            labelColor={labelColor}
            info={info}
          />
        </Row>
        <Column>
          {isXSScreen ? <SmSpacing /> : null}
          <SwitchBackground value={value}>
            {/* eslint-disable-next-line react-native/no-inline-styles */}
            <SwitchThumb style={{ elevation: 2, transform: [{ translateX: translate }] }} />
          </SwitchBackground>
        </Column>
      </Row>
    </StyledButton>
  );
};
