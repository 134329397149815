// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { type ShipmentFeeType } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import { format, parse } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';

export const feeTypeToFormValue: Record<ShipmentFeeType, string> = {
  asap_delivery: 'asap_delivery',
  same_day_delivery: 'same-day-delivery',
  next_day_delivery: 'next-day-delivery',
  standard_delivery: 'any-day-delivery',
  on_demand_delivery: 'on-demand-delivery',
  essentials_only_delivery: 'essentials-only-delivery',
};

export const getFormattedDate = (date: string | undefined): string =>
  date ? format(parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0), DATE_FORMATS.WEEKDAY_MONTH_ORDINAL_DATE) : '';

export const getDisabledColor = (loading: boolean) =>
  loading ? COLORS.TEXT_COLORS.DISABLED : COLORS.TEXT_COLORS.PRIMARY;
