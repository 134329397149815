import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { PressableAltoIcon } from '../../buttons';
import { MdPadding } from '../../containers';
import { type NavBarLogo } from '../../images';
import { XlSpacing } from '../../separators';
import { BasePage } from './BasePage';
import { NavBar } from './NavBar';
import { PageHeaderSection } from './PageHeaderSection';
import { PageOffsetContentSection } from './PageOffsetContentSection';

type Props = {
  readonly HeaderContent?: React.ReactElement;
  readonly headerBackgroundColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  readonly onDismiss?: () => void;
  readonly children: React.ReactNode;
  readonly dismissIcon?: 'close' | 'chevronleft';
  readonly NavBarCenterContent?: string | React.ReactElement<typeof NavBarLogo>;
};

export const SectionedPage = ({
  HeaderContent,
  headerBackgroundColor = COLORS.BACKGROUND_COLORS.GREY_LIGHTEST,
  children,
  onDismiss,
  dismissIcon = 'close',
  NavBarCenterContent,
}: Props) => {
  const hasNavBar = !!NavBarCenterContent || !!onDismiss;

  return (
    <BasePage
      backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
      NavBar={
        hasNavBar ? (
          <NavBar
            CenterContent={NavBarCenterContent}
            backgroundColor={headerBackgroundColor}
            LeftPressableAltoIcon={
              onDismiss ? (
                <PressableAltoIcon
                  name={dismissIcon}
                  onPress={onDismiss}
                  accessibilityLabel={dismissIcon === 'chevronleft' ? 'Press to go back' : 'Press to close'}
                />
              ) : undefined
            }
          />
        ) : undefined
      }
    >
      <PageHeaderSection backgroundColor={headerBackgroundColor}>
        {HeaderContent || <XlSpacing />}
        <XlSpacing />
      </PageHeaderSection>
      <PageOffsetContentSection>
        <MdPadding
          flexGrow={1}
          topPadding={SPACING.STATIC.XL}
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.MD}
        >
          {children}
        </MdPadding>
      </PageOffsetContentSection>
    </BasePage>
  );
};
