// @owners { team: patients-team }
import { AddOnsEndpoint } from '@alto/scriptdash/alto/patient_app/add_ons/v1/add_ons_endpoint';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useQuery } from '~shared/react-query';

const addOnsEndpoint = AddOnsEndpoint(apiEndpointHandler);

type QueryParams = {
  addressID?: number;
  date?: string | null | undefined;
};

export const useQueryFeaturedOtcs = ({ addressID, date }: QueryParams) => {
  const query = useQuery({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['featured-otcs', { addressID, date }],
    queryFn: () => {
      if (!addressID) {
        return null;
      }

      return addOnsEndpoint.fetchFeatured({
        address_id: addressID,
        fill_date: date,
        prescription_ids: [],
      });
    },
    refetchOnMount: false,
  });

  return {
    isSuccess: query.isSuccess,
    isPending: query.isPending,
    error: query.error,
    isError: query.isError,
    products: query.data?.data?.add_on_otcs,
  };
};
