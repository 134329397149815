import { BORDERS, COLORS, SIZES } from '@alto/design-library-tokens';
import React from 'react';
import { Image, View } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';
import { useScreenSize } from '../../../../../utils';
import { getCssFromOrientation } from '../../helpers';
import { type VerticalEnabled } from '../../types';
import { CompletedCheckXml } from './CompletedCheckXml';
import { DEFAULT_CIRCLE_SIZE, VERTICAL_OFFSET } from './constants';

const verticalCss = css`
  top: ${VERTICAL_OFFSET}px;
`;

const StyledIndicator = styled(View)<{ isMDScreenOrBigger: boolean; verticalEnabled: VerticalEnabled }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  ${({ verticalEnabled, isMDScreenOrBigger }) => {
    return css`
      background-color: ${COLORS.BACKGROUND_COLORS.SUCCESS};
      width: ${DEFAULT_CIRCLE_SIZE}px;
      height: ${DEFAULT_CIRCLE_SIZE}px;
      ${getCssFromOrientation({
        isMDScreenOrBigger,
        verticalEnabled,
        verticalCss,
      })}
    `;
  }}}
`;

export const CompletedIndicator = () => {
  const { isMDScreenOrBigger } = useScreenSize();
  const { verticalEnabled } = useTheme();
  return (
    <StyledIndicator
      verticalEnabled={verticalEnabled}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      <Image
        source={{
          uri: `data:image/svg+xml;base64,${btoa(CompletedCheckXml)}`,
          width: SIZES.ICON.SM.value,
          height: SIZES.ICON.SM.value,
        }}
      />
    </StyledIndicator>
  );
};
