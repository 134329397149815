// @owners { team: patients-team }
import { memoize } from 'lodash';
import { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { type Prescription } from '~shared/types';

const getEssentials = memoize(
  (prescriptions: Prescription[]) => prescriptions.filter((prescription) => prescription.is_add_on_otc),
  (prescriptions) => prescriptions.map((p) => p.id).join(','),
);

const getNonEssentials = memoize(
  (prescriptions: Prescription[]) => prescriptions.filter((prescription) => !prescription.is_add_on_otc),
  (prescriptions) => prescriptions.map((p) => p.id).join(','),
);

export const useCartEssentials = () => {
  const { prescriptions } = usePrescriptionsInCart();

  const essentials = getEssentials(prescriptions);
  const nonEssentials = getNonEssentials(prescriptions);

  return useMemo(
    () => ({
      essentials,
      nonEssentials,
      hasEssentials: !!essentials.length,
      hasNonEssentials: !!nonEssentials.length,
    }),
    [essentials, nonEssentials],
  );
};
