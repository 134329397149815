import { type VerticalEnabled } from './types';

type GetCssFromOrientationParams = {
  isMDScreenOrBigger: boolean;
  verticalEnabled: VerticalEnabled;
  verticalCss?: any; // should be type FlattenSimpleInterpolation from styled-components
  horizontalCss?: any; // should be type FlattenSimpleInterpolation from styled-components
};
export const getCssFromOrientation = ({
  isMDScreenOrBigger,
  verticalEnabled,
  verticalCss,
  horizontalCss,
}: GetCssFromOrientationParams) => {
  switch (verticalEnabled) {
    case 'always':
      return verticalCss ?? '';
    case 'never':
      return horizontalCss ?? '';
    case 'mobile':
      if (isMDScreenOrBigger) {
        return horizontalCss ?? '';
      }
      return verticalCss ?? '';
    default:
      return '';
  }
};
