import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import React, { useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';
import styled from 'styled-components/native';
import { usePrevious } from '../../../hooks/usePrevious';
import { Row } from '../../containers';

const DEFAULT_ANIMATION_DURATION = 2000; // 2 seconds

const Step = styled(View)`
  flex-grow: 1;
  flex-direction: row;
  height: ${SPACING.STATIC.XXS.px};
  border-radius: ${BORDERS.RADIUS.DEFAULT.px};
  margin: 0 ${SPACING.STATIC.XXS.px};
`;

export type SteppedProgressBarProps = {
  readonly completedSteps: number;
  readonly totalSteps: number;
  readonly completedColor?: string;
  readonly incompleteColor?: string;
  readonly animationDuration?: number;
};

const IncompleteStep = styled(Step)<{ incompleteColor: SteppedProgressBarProps['incompleteColor'] }>`
  background-color: ${({ incompleteColor }) => incompleteColor || COLORS.PALETTE.GREYSCALE.DEFAULT};
`;

const CompletedStep = styled(Step)<{ completedColor: SteppedProgressBarProps['completedColor'] }>`
  background-color: ${({ completedColor }) => completedColor || COLORS.PALETTE.SUCCESS.DEFAULT};
`;

const isTest = !!process.env.JEST_WORKER_ID;

export const SteppedProgressBar = ({
  completedSteps,
  totalSteps,
  completedColor,
  incompleteColor,
  animationDuration = DEFAULT_ANIMATION_DURATION,
}: SteppedProgressBarProps) => {
  const animatedValue = useRef(new Animated.Value(0)).current;
  const previousCompletedSteps = usePrevious(completedSteps);
  useEffect(() => {
    if (isTest) return;

    if (previousCompletedSteps !== undefined && completedSteps <= previousCompletedSteps) {
      animatedValue.setValue(1);
    } else {
      animatedValue.setValue(0);
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: animationDuration,
        useNativeDriver: false,
      }).start();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSteps]);

  // explicitly do not want to include animatedValue in this dependency list
  const steps = [];

  for (let step = 0; step < totalSteps; step++) {
    if (step === completedSteps - 1) {
      // If this was the last completed step, animate it from incomplete to complete
      steps.push(
        <IncompleteStep
          key={`stepped-progress-bar-animated-incomplete-step-${step}`}
          incompleteColor={incompleteColor}
          testID={`animated-incomplete-step-${step}`}
        >
          <Animated.View
            style={{
              flexGrow: animatedValue,
              borderRadius: BORDERS.RADIUS.DEFAULT.value,
              backgroundColor: completedColor || COLORS.PALETTE.SUCCESS.DEFAULT,
            }}
          />
        </IncompleteStep>,
      );
    } else if (step < completedSteps - 1) {
      steps.push(
        <CompletedStep
          key={`stepped-progress-bar-completed-step-${step}`}
          completedColor={completedColor}
          testID={`completed-step-${step}`}
        />,
      );
    } else {
      steps.push(
        <IncompleteStep
          key={`stepped-progress-bar-incomplete-step-${step}`}
          incompleteColor={incompleteColor}
          testID={`incomplete-step-${step}`}
        />,
      );
    }
  }

  return (
    <Row
      spaceBetween
      flexGrow={1}
    >
      {steps}
    </Row>
  );
};
