// @owners { team: patients-team }
import { InputRadio, InputRadioGroup, ListDescription, Separator, Tag } from '@alto/design-system';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';

const OPTIONS = Object.freeze({
  ONE_TIME: 'one_time',
  AUTO_REFILL: 'auto_refill',
  NONE: 'none',
});

type Option = (typeof OPTIONS)[keyof typeof OPTIONS];

const getSelectedOption = (isAutoRefillOn: boolean | null | undefined): Option => {
  if (isAutoRefillOn === undefined || isAutoRefillOn === null) {
    return OPTIONS.NONE;
  }

  if (isAutoRefillOn) {
    return OPTIONS.AUTO_REFILL;
  }

  return OPTIONS.ONE_TIME;
};

export type AutoRefillRadioOptionsProps = {
  readonly handleOnChange: (value: boolean) => void;
  readonly autoRefillEnabled: boolean | null | undefined;
  readonly prescriptionID: number;
};

export const AutoRefillRadioOptions = ({
  handleOnChange,
  prescriptionID,
  autoRefillEnabled,
}: AutoRefillRadioOptionsProps) => {
  const { trackEvent } = useAnalytics();

  const onPressOption = (item: string) => {
    const newSelection = item as Option;

    const hasSelectedAutoRefill = newSelection === OPTIONS.AUTO_REFILL;

    trackEvent({
      event: EVENTS.AUTO_REFILL_UI_STATUS_CHANGED,
      params: {
        origin: getOrigin(),
        auto_refill_status: hasSelectedAutoRefill ? 'auto refill' : 'one-time fill',
      },
      additionalFields: {
        prescriptionId: prescriptionID,
      },
    });

    handleOnChange(hasSelectedAutoRefill);
  };

  return (
    <InputRadioGroup
      defaultValue={getSelectedOption(autoRefillEnabled)}
      onValueChange={onPressOption}
    >
      <Separator />
      <InputRadio
        testID="e2e-auto-refill-radio-option"
        tag={
          <Tag
            label="RECOMMENDED"
            type="recommended"
          />
        }
        descriptions={[
          <ListDescription key="auto-refill-description">
            Receive automatic refills and bundled deliveries when possible.
          </ListDescription>,
        ]}
        label="Auto refill"
        value={OPTIONS.AUTO_REFILL}
      />
      <Separator />
      <InputRadio
        testID="e2e-one-time-fill-radio-option"
        label="One-time fill"
        value={OPTIONS.ONE_TIME}
      />
    </InputRadioGroup>
  );
};
