// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import { get } from '~shared/helpers/apiHelper';
import { type ShipmentPaymentMethod } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const FETCH_SHIPMENT_PAYMENT_METHODS_SUCCEEDED = 'FETCH_SHIPMENT_PAYMENT_METHODS_SUCCEEDED';
export const FETCH_SHIPMENT_PAYMENT_METHODS_LOADING = 'FETCH_SHIPMENT_PAYMENT_METHODS_LOADING';

export type FetchShipmentPaymentMethodsLoading = {
  type: typeof FETCH_SHIPMENT_PAYMENT_METHODS_LOADING;
};

export type FetchShipmentPaymentMethodsSucceeded = {
  type: typeof FETCH_SHIPMENT_PAYMENT_METHODS_SUCCEEDED;
  payload: ShipmentPaymentMethod[];
};

export type FetchShipmentPaymentMethodsFailed = {
  type: typeof FETCH_SHIPMENT_PAYMENT_METHODS_FAILED;
  payload: APIError;
  error: true;
};

export type ShipmentPaymentMethodsActions =
  | FetchShipmentPaymentMethodsLoading
  | FetchShipmentPaymentMethodsFailed
  | FetchShipmentPaymentMethodsSucceeded;

export function fetchingShipmentPaymentMethods() {
  return {
    type: FETCH_SHIPMENT_PAYMENT_METHODS_LOADING,
  };
}

// @ts-expect-error TS(7006): Parameter 'shipmentPaymentMethods' implicitly has an 'any' type.
export function fetchShipmentPaymentMethodsSucceeded(shipmentPaymentMethods) {
  return {
    type: FETCH_SHIPMENT_PAYMENT_METHODS_SUCCEEDED,
    payload: shipmentPaymentMethods,
  };
}

export const FETCH_SHIPMENT_PAYMENT_METHODS_FAILED = 'FETCH_SHIPMENT_PAYMENT_METHODS_FAILED';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export function fetchShipmentPaymentMethodsFailed(error) {
  return {
    type: FETCH_SHIPMENT_PAYMENT_METHODS_FAILED,
    payload: error,
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchShipmentPaymentMethods() {
  // @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
  return (dispatch) => {
    dispatch(fetchingShipmentPaymentMethods());
    return get('/shipment_payment_methods').then((json) => {
      if (json.error) {
        dispatch(fetchShipmentPaymentMethodsFailed(json.error));
        return false;
      }

      dispatch(fetchShipmentPaymentMethodsSucceeded(json));
      return true;
    });
  };
}
