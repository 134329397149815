import { PAYMENT_METHOD_DATA_TYPES, PAYMENT_TYPES, type PaymentType } from '~shared/features/payment-methods/constants';
import { type PaymentMethod } from '~shared/types/payment_methods';

export const paymentType = ({ type, details }: PaymentMethod): PaymentType | null => {
  if (type === PAYMENT_METHOD_DATA_TYPES.STRIPE_DATA) {
    switch (details?.native_pay_type) {
      case 'apple_pay':
        return PAYMENT_TYPES.APPLE_PAY;
      case 'google_pay':
        return PAYMENT_TYPES.GOOGLE_PAY;
      default:
        return PAYMENT_TYPES.CREDIT_CARD;
    }
  } else if (type === PAYMENT_METHOD_DATA_TYPES.PLAID_DATA) {
    return PAYMENT_TYPES.ACH;
  }

  return null;
};
