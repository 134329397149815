// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Row, XxsPadding } from '@alto/design-system';
import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Dot } from '../../../../index';

export type TabProps = {
  readonly name: string;
  readonly onPress?: () => void;
  readonly isActive?: boolean;
  readonly showDotIndicator?: boolean;
};

const XxsPaddingWithBottomBorder = styled(XxsPadding)<{ isActive?: boolean }>`
  border-bottom-width: ${({ isActive }) => (isActive ? '3px' : '0px')};
  border-bottom-color: ${COLORS.TEXT_COLORS.PRIMARY};
`;

export const Tab = ({ name, onPress, isActive, showDotIndicator }: TabProps) => (
  <Pressable
    onPress={onPress}
    accessibilityLabel={name}
  >
    <XxsPaddingWithBottomBorder
      isActive={isActive}
      leftPadding={SPACING.STATIC.NONE}
      rightPadding={SPACING.STATIC.NONE}
    >
      <Row>
        <Body
          fontFamily="semibold"
          color={isActive ? COLORS.TEXT_COLORS.PRIMARY : COLORS.TEXT_COLORS.GREY}
        >
          {name}
        </Body>
        {showDotIndicator ? (
          <Dot
            accessibilityLabel="Press to view unread messages"
            rightPosition={-8}
            topPosition={-8}
            withAnimation
          />
        ) : null}
      </Row>
    </XxsPaddingWithBottomBorder>
  </Pressable>
);
