import { FONT } from './font';
import { LETTER_SPACING } from './letterSpacing';
import { LINE_HEIGHT } from './lineHeight';
import { TEXT } from './text';

export * from './icons';

export const TYPOGRAPHY = {
  FONT,
  LETTER_SPACING,
  LINE_HEIGHT,
  TEXT,
} as const;
