import React from 'react';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { useScreenSize } from '../../../../../utils';
import { getCssFromOrientation } from '../../helpers';
import { DISTANCE_FROM_TOP_TO_CENTER_OF_INDICATOR, LINE_WIDTH, VERTICAL_OFFSET } from './constants';
import { type RailSegmentProps } from './types';

const verticalCss = css`
  height: ${DISTANCE_FROM_TOP_TO_CENTER_OF_INDICATOR}px;
  width: ${LINE_WIDTH}px;
  top: 0;
  left: ${VERTICAL_OFFSET}px;
`;

const Rail = styled(View)<RailSegmentProps & { isMDScreenOrBigger: boolean }>`
  position: absolute;
  z-index: -1;
  ${({ color, verticalEnabled, isMDScreenOrBigger }) => css`
    background-color: ${color};
    ${getCssFromOrientation({
      isMDScreenOrBigger,
      verticalEnabled,
      verticalCss,
    })}
  `}
`;

export const BeforeRail = ({ verticalEnabled, color }: RailSegmentProps) => {
  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <Rail
      verticalEnabled={verticalEnabled}
      color={color}
      isMDScreenOrBigger={isMDScreenOrBigger}
    />
  );
};
