// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, H1, Link, Modal, ModalContent, Text, palette, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import { phoneSupport } from '~web/constants';

const Title = styled(H1)`
  color: ${palette.navy};
  text-align: center;
`;

const Buttons = styled(ButtonWrapper)`
  justify-content: center;
`;

type Props = {
  readonly onClose: () => void;
};

export default function VerificationBlockedModal({ onClose }: Props) {
  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Column spacing={spacing.smSpacing}>
          <Title>Give Us A Call</Title>
          <Text>
            Based on the information provided, you may already have an Alto account. For your security, please call our
            team at <Link href={`tel:${phoneSupport}`}>{phoneSupport}</Link> to complete your account setup.
          </Text>
          <Buttons>
            <Button
              kind="secondary"
              onClick={onClose}
            >
              Close
            </Button>
          </Buttons>
        </Column>
      </ModalContent>
    </Modal>
  );
}
