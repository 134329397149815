// @owners { team: patients-team }
import { isBefore, isValid, parseISO } from 'date-fns';
import { type Middleware } from 'redux';
import { openModal } from '~shared/actions/modal';
import { FETCH_USERS_SUCCEEDED } from '~shared/actions/users';
import { getIsOnboardingComplete } from '~shared/features/onboarding/selectors/getIsOnboardingComplete';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import isWelcomeBackDisplayed from '~shared/selectors/ui/modal/getIsWelcomeBackDisplayed';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import getIsOnboardingRoute from '~web/features/onboarding/selectors/getIsOnboardingRoute';

// Don't force users who signed up before we started collecting health info and
// communication preferences during onboarding to fill these out.
const launchDate = new Date(2018, 6, 2); // July 2, 2018

const onboardingResume: Middleware<unknown, ReduxStateShared, ReduxDispatchShared> =
  // eslint-disable-next-line @typescript-eslint/unbound-method
  ({ dispatch, getState }) => {
    return (next) => (action) => {
      const { type } = action;
      const res = next(action);

      const state = getState();
      const {
        auth: { onboarding: oldOnboarding },
      } = state;

      const newOnboarding = getIsOnboardingRoute(state);
      const onboarding = oldOnboarding || newOnboarding;

      if (type !== FETCH_USERS_SUCCEEDED || onboarding) {
        return res;
      }

      const user = getCurrentUser(state);
      const isOnboardingComplete = getIsOnboardingComplete(state);

      if (user) {
        const parsedCreatedAt = parseISO(user.created_at ?? '');
        if (isValid(parsedCreatedAt) && isBefore(parsedCreatedAt, launchDate)) {
          return res;
        }

        // The onboarding resume modal should only be shown for organically onboarded users with incomplete information.
        // Note: do not rely on the user's onboard_state here, since that field is unrelated to organic onboarding.
        if (!isOnboardingComplete && !isWelcomeBackDisplayed(state)) {
          dispatch(openModal('ONBOARDING_RESUME_MODAL'));
        }
      }

      return res;
    };
  };

export default onboardingResume;
