import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { Platform } from 'react-native';
import { useScreenSize } from '../../../../utils';
import { PressableAltoIcon } from '../../../buttons';
import { ListDescription, ListItem } from '../../../lists';
import { HighlightText } from './HighlightText';
import { type ApiResponseRow } from './types';

type RowProps = {
  readonly title: ApiResponseRow<string>['title'];
  readonly subtitle: ApiResponseRow<string>['subtitle'];
  readonly textToHighlight?: string;
  readonly onPress: () => void;
  readonly onRemove: () => void;
  readonly isSelected: boolean;
  readonly isHighlighted?: boolean;
};

export const AutocompleteRow = ({
  title,
  subtitle,
  onPress,
  onRemove,
  textToHighlight,
  isSelected,
  isHighlighted,
}: RowProps) => {
  const { isSMScreenOrBigger } = useScreenSize();
  const isDesktopWeb = Platform.OS === 'web' && isSMScreenOrBigger;

  const rowActionButton = isSelected ? (
    <PressableAltoIcon
      hitSlop={SPACING.STATIC.SM.value}
      name="trash"
      onPress={onRemove}
      accessibilityLabel={`remove ${title} from selected items`}
    />
  ) : (
    <PressableAltoIcon
      hitSlop={SPACING.STATIC.SM.value}
      name="pluscircle"
      onPress={onPress}
      accessibilityLabel={`add ${title} to selected items`}
    />
  );

  return (
    <ListItem
      backgroundColor={isHighlighted ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST : undefined}
      onPress={isSelected ? onRemove : onPress}
      title={
        <HighlightText
          fullText={title}
          textToHighlight={textToHighlight}
        />
      }
      descriptions={subtitle ? [<ListDescription key={subtitle}>{subtitle}</ListDescription>] : undefined}
      // We don't show the icon on desktop web
      RightContent={isDesktopWeb ? undefined : rowActionButton}
    />
  );
};
