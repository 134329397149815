import { type Transaction, type TransactionContext } from '@sentry/types';
import { Sentry } from './sentry';

/**
 * 🤝 Sentry Transaction
 *
 * https://docs.sentry.io/platforms/react-native/performance/instrumentation/custom-instrumentation/
 * https://blog.sentry.io/performance-monitoring-support-for-react-native/#transaction-hub
 *
 * This transaction hub is where all of the active transactions live.
 * We can start and finish a transaction to send it off to Sentry.
 */
export const SentryTransactionHub = {
  /**
   * Transaction operations we want to instrument
   *
   * Define the transaction operation. Give it a human-readable name and a unique op id.
   * @example loginToHomescreen: { name: 'Login -> Homescreen', op: 'perf.userAction.loginToHomescreen' }
   */
  operations: {
    /**
     * Logging in to rendering the homescreen.
     */
    loginToHomescreen: { name: 'Login -> Homescreen', op: 'perf.userAction.loginToHomescreen' },

    /**
     * Pressing the add to cart button from the homescreen, going through all
     * the interstitial action sheets, and calling `handleAddToCart`.
     */
    addToCartFromHomescreen: { name: 'Add to Cart from Homescreen', op: 'perf.userAction.addToCart' },
  } as const,

  /**
   * The list of active transactions
   */
  transactions: [] as Transaction[],

  /**
   * Start the transaction
   * @param transactionContext Must include a human-readable `name` and `op`, which uniquely identifies the operation
   */
  startTransaction(transactionContext: TransactionContext) {
    const transaction = Sentry.startTransaction(transactionContext);

    this.transactions.push(transaction);

    return transaction;
  },

  /**
   * Finishing the transaction will send it to Sentry
   * @param op The unique operation identifier
   */
  finishTransaction(transactionContext: TransactionContext) {
    // Find all the transactions for a specific transaction op
    const selectedTransactions: Transaction[] = this.transactions.filter((t) => t?.op === transactionContext.op);

    // Finish each of the transactions for a specific transaction op
    selectedTransactions.forEach((t) => {
      t.finish();
    });

    // Remove these finished transactions from the transaction hub
    this.transactions = this.transactions.filter((t) => t?.op !== transactionContext.op);

    return selectedTransactions;
  },
};
