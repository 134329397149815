import { SPACING } from '@alto/design-library-tokens';
import { Description, InlineAlert, XsPadding } from '@alto/design-system';
import React from 'react';
export const AutoRefillOrderCancel = () => {
  return (
    <InlineAlert type="warning">
      <XsPadding>
        <Description fontFamily="bold">This order contains medication on auto refill</Description>
        <XsPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <Description>
            After canceling, this prescription will no longer be on auto refill. Future orders for this prescription
            will not be automatically scheduled.
          </Description>
        </XsPadding>
      </XsPadding>
    </InlineAlert>
  );
};
