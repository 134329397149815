// @owners { team: patients-team }
type CheckboxStateByName = Record<string, boolean>;

export const markAll = (items: string[], checked: boolean) => {
  return items.reduce<CheckboxStateByName>((checkedByName, item) => {
    checkedByName[item] = checked;
    return checkedByName;
  }, {});
};

export const getNextToggleState = (checkedByName: CheckboxStateByName, name: string) => {
  const isChecked = !checkedByName[name];

  if (name === 'ALL' && isChecked) {
    // check all the checkboxes when `ALL` checkbox is checked
    return markAll(Object.keys(checkedByName), true);
  } else if (name === 'ALL' && !isChecked) {
    // uncheck all the checkboxes when `ALL` checkbox is unchecked
    return markAll(Object.keys(checkedByName), false);
  } else if (name !== 'ALL' && !isChecked) {
    // uncheck `ALL` checkbox when another checkbox is unchecked
    return {
      ...checkedByName,
      ALL: false,
      [name]: isChecked,
    };
  } else if (name !== 'ALL' && isChecked) {
    // otherwise, check or uncheck the current checkbox and determine if all options are checked
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ALL, ...remainingCheckboxes } = checkedByName;
    const areRemainingChecked = Object.values({ ...remainingCheckboxes, [name]: isChecked }).every(Boolean);
    return {
      ...checkedByName,
      ALL: areRemainingChecked,
      [name]: isChecked,
    };
  }

  return checkedByName;
};
