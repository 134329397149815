// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ButtonSelector,
  Description,
  InlineAlert,
  LgPadding,
  type OptionValue,
  XsSpacing,
  generateInitialValue,
} from '@alto/design-system';
import React, { useState } from 'react';
import { AllergenSearchField } from './AllergenSearchInput';

type Props = {
  readonly allergies: string;
  readonly error?: string;
  readonly familyMemberName?: string; // first name of family member
  readonly handleChange: (value: string) => void;
  readonly hasSubmitted?: boolean;
  readonly isTouched?: boolean;
};

export const NO_KNOWN_ALLERGIES = 'No Known Allergies';

const YES_VALUE = 'Yes';
const NO_VALUE = 'No';

const options = [
  { label: NO_VALUE, value: NO_VALUE, testID: 'allergies-no' },
  { label: YES_VALUE, value: YES_VALUE, testID: 'allergies-yes' },
];

const getDefaultValue = (hasAllergies: boolean, allergies: string | undefined) => {
  if (hasAllergies) {
    return YES_VALUE;
  }
  if (allergies === NO_KNOWN_ALLERGIES) {
    return NO_VALUE;
  }
  return undefined;
};

export const AllergiesInput = ({
  allergies,
  error,
  familyMemberName,
  handleChange,
  hasSubmitted = false,
  isTouched = false,
}: Props) => {
  const [hasAllergies, setHasAllergies] = useState<boolean>(!!allergies && allergies !== NO_KNOWN_ALLERGIES);
  const handleAllergies = (value: OptionValue) => {
    if (value === YES_VALUE) {
      setHasAllergies(true);
      handleChange('');
    } else {
      setHasAllergies(false);
      handleChange(NO_KNOWN_ALLERGIES);
    }
  };

  const defaultValue = getDefaultValue(hasAllergies, allergies);
  const title = familyMemberName ? `Does ${familyMemberName} have any allergies?` : 'Do you have any allergies?';

  return (
    <>
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.MD}
      >
        <Description
          color={COLORS.TEXT_COLORS.PRIMARY}
          fontFamily="semibold"
        >
          {title}
        </Description>
        <XsSpacing />
        <ButtonSelector
          options={options}
          onChange={handleAllergies}
          value={defaultValue}
        />
      </LgPadding>
      {hasAllergies ? (
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.MD}
        >
          <AllergenSearchField
            familyMemberName={familyMemberName}
            isTouched={isTouched}
            hasSubmitted={hasSubmitted}
            onChange={handleChange}
            error={error ? error : undefined}
            initialValues={allergies ? allergies.split(',').map(generateInitialValue) : []}
          />
        </LgPadding>
      ) : null}
      {(isTouched || hasSubmitted) && !!error && defaultValue === undefined ? (
        <LgPadding
          topPadding={SPACING.STATIC.MD}
          bottomPadding={SPACING.STATIC.MD}
        >
          <InlineAlert type="error">
            <Description>{error}</Description>
          </InlineAlert>
        </LgPadding>
      ) : null}
    </>
  );
};
