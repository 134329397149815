// @owners { team: patients-team }
import {
  FUROSCIX_WAIVED_FEE_SKUS,
  IBSRELA_WAIVED_FEE_SKU,
  NY_WAIVED_FEE_SKUS,
  SAME_DAY_DELIVERY_FEE_PILOT_FACILITY_IDS,
} from '~shared/constants';
import { getRemovingDeliveryWillAddDeliveryFee } from '~shared/features/same-day-delivery/selectors/getRemovingDeliveryWillAddDeliveryFee';
import { getDeliveryFeeFromShipment } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { getShipmentAddress, getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getIsFertilityUser } from '~shared/features/users/selectors/getIsFertilityUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getFormattedPrice } from '~shared/helpers/pricingBreakdown';
import { useQueryShipmentFeesAvailable, useQueryShipmentFeesConfig } from '~shared/queries';
import { useSelectorShared } from '~shared/store';
import { type DeliveryWithPrescription } from '~shared/types';

type GetErrorMessageProps = {
  removedPrescriptionBelowDeliveryFeeMinimum: boolean;
  isFuroscixPrescription: boolean;
  isIbsrelaPrescription: boolean;
  isCOVIDPrescription: boolean;
  isClinicPayPrescription: boolean;
  minimumThresholdToWaiveFeeDisplay: string | undefined;
  deliveryFee: number | undefined;
};

const getErrorMessage = ({
  removedPrescriptionBelowDeliveryFeeMinimum,
  isFuroscixPrescription,
  isIbsrelaPrescription,
  isCOVIDPrescription,
  isClinicPayPrescription,
  minimumThresholdToWaiveFeeDisplay,
  deliveryFee,
}: GetErrorMessageProps): string => {
  const formattedDeliveryFee = getFormattedPrice(deliveryFee);

  if (isFuroscixPrescription) {
    return `Delivery fees are waived if your shipment includes Furoscix. This change would result in a ${formattedDeliveryFee} delivery fee being added to your order.`;
  } else if (isIbsrelaPrescription) {
    return `Delivery fees are waived if your shipment includes Ibsrela. This change would result in a ${formattedDeliveryFee} delivery fee being added to your order.`;
  } else if (isCOVIDPrescription) {
    return `Delivery fees are waived if your shipment includes Paxlovid, Lagevrio, or Evusheld. This change would result in a ${formattedDeliveryFee} delivery fee being added to your order.`;
  } else if (isClinicPayPrescription) {
    return `Delivery fees are waived if your shipment includes a medication covered by clinic voucher. This change would result in a ${formattedDeliveryFee} delivery fee being added to your order.`;
  } else if (removedPrescriptionBelowDeliveryFeeMinimum && minimumThresholdToWaiveFeeDisplay) {
    return `This would make your subtotal under ${minimumThresholdToWaiveFeeDisplay}. This change would result in a ${formattedDeliveryFee} delivery fee being added to your order.`;
  }
  return `This change would result in a ${formattedDeliveryFee} delivery fee being added to your order.`;
};

type Props = {
  delivery: DeliveryWithPrescription;
};

export const usePrescriptionDeliveryWaiveFeeWarning = ({ delivery }: Props) => {
  const address = useSelectorShared((state) => getShipmentAddress(state, { shipmentID: delivery?.shipment_id }));
  const facilityId = address?.dispensing_facility_id;
  const currentUserID = useSelectorShared(getCurrentUserID);
  const isFertilityUser = useSelectorShared((state) => getIsFertilityUser(state, { userID: currentUserID }));
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: delivery?.shipment_id }));
  const deliveryFee = shipment && getDeliveryFeeFromShipment(shipment);
  const { shipmentFeeConfigHash } = useQueryShipmentFeesConfig();
  const { shipmentFeeAvailableHash } = useQueryShipmentFeesAvailable({
    delivery: {
      shipmentId: shipment?.id,
      addressId: delivery?.address_id,
      removedPrescriptionId: delivery?.prescription.id,
    },
  });
  const availableFeeAmount = deliveryFee?.fee_type && shipmentFeeAvailableHash[deliveryFee?.fee_type]?.amount;
  const removalWillIncurFee = (availableFeeAmount || 0) > 0;
  const hasDeliveryFeeWaived = deliveryFee?.amount === 0;
  const feeConfigData = deliveryFee?.fee_type && shipmentFeeConfigHash[deliveryFee?.fee_type];
  const minimumThresholdToWaiveFee = feeConfigData?.minimum_threshold_to_waive_fee;
  const isCOVIDPrescription =
    facilityId === SAME_DAY_DELIVERY_FEE_PILOT_FACILITY_IDS.NEW_YORK &&
    !!delivery?.prescription?.ndc &&
    NY_WAIVED_FEE_SKUS.includes(delivery?.prescription?.ndc);
  const isFuroscixPrescription =
    !!delivery?.prescription?.ndc && FUROSCIX_WAIVED_FEE_SKUS.includes(delivery?.prescription?.ndc);
  const isIbsrelaPrescription = !!delivery?.prescription?.ndc && IBSRELA_WAIVED_FEE_SKU === delivery?.prescription?.ndc;
  const isClinicPayPrescription = !!delivery?.prescription?.covered_by_voucher_payer;
  const removedPrescriptionBelowDeliveryFeeMinimum = useSelectorShared((state) =>
    minimumThresholdToWaiveFee && isFertilityUser
      ? getRemovingDeliveryWillAddDeliveryFee(state, {
          deliveryID: delivery?.id,
          shipmentID: delivery?.shipment_id,
          minimumThresholdToWaiveFee: minimumThresholdToWaiveFee.value,
        })
      : false,
  );
  const message = getErrorMessage({
    removedPrescriptionBelowDeliveryFeeMinimum,
    isFuroscixPrescription,
    isIbsrelaPrescription,
    isCOVIDPrescription,
    isClinicPayPrescription,
    minimumThresholdToWaiveFeeDisplay: minimumThresholdToWaiveFee?.display_value,
    deliveryFee: availableFeeAmount,
  });
  const showDeliveryWaiveFeeWarning = hasDeliveryFeeWaived && removalWillIncurFee;

  return { message, showDeliveryWaiveFeeWarning };
};
