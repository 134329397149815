// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type Order } from '~shared/features/checkout/types';
import { isCourier } from '~shared/helpers/order';

export const getCourierTip = ({
  deliveryMethods,
  ordersByIndex,
}: {
  deliveryMethods: DeliveryMethod[];
  ordersByIndex: Record<number, Order>;
}) => {
  // set the courier tip amount and tip payment method if it exists
  let tip_amount = undefined;
  let tip_payment_method_id = undefined;

  const courierIndex = deliveryMethods.findIndex((deliveryMethod) => isCourier(deliveryMethod.mode));
  if (courierIndex > -1) {
    tip_amount = ordersByIndex[courierIndex]?.tip_amount;
    tip_payment_method_id = ordersByIndex[courierIndex]?.tip_payment_method_id;
  }

  return { tip_amount, tip_payment_method_id };
};
