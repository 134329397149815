// @owners { team: patients-team }
import {
  AddOnsEndpoint,
  type AddOnsEndpointDeletePrescriptionRequest,
} from '@alto/scriptdash/alto/patient_app/add_ons/v1/add_ons_endpoint';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useMutation } from '~shared/react-query';

const AddOnsApi = AddOnsEndpoint(apiEndpointHandler);

export const ADD_ONS_DELETE_MUTATION_KEY = ['addOnsDeletePrescription'];

export const useAddOnsDeletePrescription = () => {
  return useMutation({
    mutationFn: (params: AddOnsEndpointDeletePrescriptionRequest) => AddOnsApi.deletePrescription(params),
    mutationKey: ADD_ONS_DELETE_MUTATION_KEY,
  });
};
