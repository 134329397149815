import { createSelector } from 'reselect';
import { getPrescriptionPricing } from './getPricing';
import { type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, prescriptionID: number) => prescriptionID;

const getPrescriptionPricingByID = createSelector(
  [getPrescriptionPricing, getPrescriptionID],
  (prescriptionPricing, prescriptionID) => {
    return prescriptionPricing[prescriptionID];
  },
);

export default getPrescriptionPricingByID;
