import { type ModalActions } from '~shared/reducers/modal';

export type StateUiModal = {
  isWelcomeBackDisplayed: boolean;
};

const initialState: StateUiModal = {
  isWelcomeBackDisplayed: false,
};

const uiModal = (state = initialState, action: ModalActions): StateUiModal => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isWelcomeBackDisplayed: state.isWelcomeBackDisplayed || action.payload?.type === 'ONBOARDING_RESUME_MODAL',
      };

    default:
      return state;
  }
};

export default uiModal;
