import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchUserSubscriptions, toggleSubscription, updateUserSubscriptions } from '~shared/actions/subscriptions';
import getError from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getErrorMessage } from '~shared/helpers/helper';
import { getEmail, getSubscriptions } from '~shared/selectors/getSubscriptionsState';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { type Subscription } from '~shared/types/subscription';
import SubscriptionsPage from './SubscriptionsPage';

type StateProps = {
  uuid: string;
  readonly email: string;
  readonly subscriptions: Subscription[];
  readonly subscriptionsError: APIError | null | undefined;
  readonly subscriptionsLoading: boolean;
};

type DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  readonly fetchUserSubscriptions: (uuid: string) => void;
  readonly toggleSubscription: (comms_type: string) => void;
  // eslint-disable-next-line import/no-deprecated
  readonly updateUserSubscriptions: (uuid: string, unSubAll: boolean) => any;
};

type Props = StateProps &
  DispatchProps & {
    readonly uuid: string;
  };

const SubscriptionsPageContainer = ({
  uuid,
  email,
  subscriptions,
  subscriptionsLoading,
  subscriptionsError,
  fetchUserSubscriptions,
  toggleSubscription,
  updateUserSubscriptions,
}: Props) => {
  const [isChanged, setIsChanged] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    fetchUserSubscriptions(uuid);
  }, [uuid, fetchUserSubscriptions]);

  const handleUpdate = (unSubscribeAll = false) => {
    // eslint-disable-next-line promise/catch-or-return, promise/always-return
    updateUserSubscriptions(uuid, unSubscribeAll).then(() => {
      setIsUpdated(true);
    });
  };

  const setSubscriptions = (subscription: string) => {
    toggleSubscription(subscription);
    setIsChanged(true);
  };

  return (
    <SubscriptionsPage
      email={email}
      errorMessage={getErrorMessage(subscriptionsError)}
      isUpdated={isUpdated}
      isChanged={isChanged}
      subscriptions={subscriptions}
      setSubscriptions={setSubscriptions}
      subscriptionsLoading={subscriptionsLoading}
      handleUpdate={handleUpdate}
    />
  );
};

const mapStateToProps = (state: ReduxStateShared, ownProps: any): StateProps => {
  const { fetchUserSubscriptionsLoading, updateUserSubscriptionsLoading } = getLoading(state);
  const { fetchUserSubscriptionsError, updateUserSubscriptionsError } = getError(state);

  return {
    uuid: ownProps.params.uuid,
    email: getEmail(state),
    subscriptions: getSubscriptions(state),
    subscriptionsError: fetchUserSubscriptionsError || updateUserSubscriptionsError,
    subscriptionsLoading: fetchUserSubscriptionsLoading || updateUserSubscriptionsLoading,
  };
};

const mapDispatchToProps: DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  fetchUserSubscriptions,
  toggleSubscription,
  // eslint-disable-next-line import/no-deprecated
  updateUserSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPageContainer);
