// @owners { team: patients-team }

import { useMemo } from 'react';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';

/**
 * @param shipmentID - shipment id
 * @returns boolean - does the shipment contain only auto refill rx
 */

export const useIsAutoRefillOnlyShipment = ({ shipmentID }: { shipmentID: number | undefined }) => {
  const prescriptions: Prescription[] = useSelectorShared((state) =>
    getPrescriptionsForShipment(state, { shipmentID }),
  );

  const isAutoRefillOnly = useMemo(
    () => prescriptions.every((rx: Prescription) => rx.auto_refill === true),
    [prescriptions],
  );

  // if there are no prescriptions, it is not an auto refill only shipment
  return prescriptions.length > 0 && isAutoRefillOnly;
};
