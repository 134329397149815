import { type BACKGROUND_COLORS_VALUES, COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { getHeaderHeight } from '../../../utils';
import { type Button, type PressableAltoIcon } from '../../buttons';
import { Column, Row } from '../../containers';
import { type NavBarLogo } from '../../images';
import { LgSpacing } from '../../separators';
import { TopBarText } from '../../typography';

const Bars = styled(Column).attrs({
  flexGrow: 1,
})<{ position: 'absolute' | 'relative'; statusBarHeightOffset: number }>`
  padding-right: ${SPACING.STATIC.MD.px};
  padding-left: ${SPACING.STATIC.MD.px};
  width: 100%;
  z-index: 1;
  ${({ statusBarHeightOffset, position }) => `
  height: ${getHeaderHeight() + (statusBarHeightOffset || 0)}px;
  position: ${position};
  `};
`;

const StatusBarBackground = styled(View)<{ height: number; backgroundColor: NavBarProps['backgroundColor'] }>`
  ${({ height, backgroundColor }) => `
  height: ${height || 0}px;
  background-color: ${backgroundColor};
  `}
`;

const Nav = styled(Row)`
  min-height: ${getHeaderHeight}px;
`;

type CenterComponentProps = {
  readonly CenterContent?: string | React.ReactElement<typeof NavBarLogo>;
  readonly title?: string;
};

const CenterComponent = ({ CenterContent, title }: CenterComponentProps) => {
  if (title || typeof CenterContent === 'string') {
    const copy = title ? title : CenterContent;
    return (
      <Row flexShrink={1}>
        <TopBarText>{copy}</TopBarText>
      </Row>
    );
  }
  return CenterContent ? CenterContent : <LgSpacing />;
};

export type NavBarProps = {
  readonly LeftPressableAltoIcon?: React.ReactElement<typeof PressableAltoIcon>;
  /** Content rendered in the Center of the NavBar, can be string or NavBarLogo */
  readonly CenterContent?: string | React.ReactElement<typeof NavBarLogo>;
  /**
   * @deprecated Use CenterContent?: string | React.ReactElement<typeof NavBarLogo> moving forward
   */
  readonly title?: string;
  readonly RightPressable?: React.ReactElement<typeof PressableAltoIcon | typeof Button>;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly position?: 'absolute' | 'relative';
};

export const NavBar = ({
  LeftPressableAltoIcon,
  title,
  CenterContent,
  RightPressable,
  backgroundColor = COLORS.BACKGROUND_COLORS.TRANSPARENT,
  position = 'absolute',
}: NavBarProps) => {
  const { top } = useSafeAreaInsets();
  return (
    <Bars
      backgroundColor={backgroundColor}
      position={position}
      statusBarHeightOffset={top}
    >
      <StatusBarBackground
        backgroundColor={backgroundColor}
        height={top}
      />
      <Nav
        centerVertically
        spaceBetween
        flexGrow={1}
        backgroundColor={backgroundColor}
      >
        {LeftPressableAltoIcon || <LgSpacing />}
        <CenterComponent
          CenterContent={CenterContent}
          title={title}
        />
        {RightPressable || <LgSpacing />}
      </Nav>
    </Bars>
  );
};
