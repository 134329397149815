// @owners { team: patients-team }
import { ShipmentTipsEndpoint } from '@alto/scriptdash/alto/patients/v3/courier_tips/wunderbar/v1/shipment_tips_endpoint';
import { get, post, put } from '~shared/helpers/apiHelper';

export default ShipmentTipsEndpoint(async (method, endpoint, params, options) => {
  const optionsWithVersion = {
    ...options,
    // NOTE: Setting the version here is a temporary workaround until
    // apiHelpers supports the new proto-based endpoint path definition.
    // We need a way to tell the helper to use a complete endpoint path
    // else it will try to prefix it with the version number like this:
    // /v3/v3/courier_tips/shipment_tips
    version: 'v3',
  };

  switch (method) {
    case 'GET':
      return get(endpoint, params, optionsWithVersion);

    case 'POST':
      return post(endpoint, params, optionsWithVersion);

    case 'PUT':
      return put(endpoint, params, optionsWithVersion);

    default:
      throw new Error(`unhandled request method ${method}`);
  }
});
