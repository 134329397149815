// @owners { team: patients-team }
import { MdSpacing } from '@alto/design-system';
import React, { useState } from 'react';
import { ImageUploadListButton } from '~web/components/ImageUploadListButton';

export type UploadImageProps = {
  readonly disabled?: boolean;
  readonly loading: boolean;
  readonly onFormChange: (name: 'image_url' | 'image_url_2', file: File[]) => void;
  readonly initialFrontImageUrl: string;
  readonly initialBackImageUrl: string;
};

export const InsurancePhotoUploadButtons = ({
  disabled,
  loading,
  onFormChange,
  initialFrontImageUrl,
  initialBackImageUrl,
}: UploadImageProps) => {
  const [cardFront, setCardFront] = useState<string>(initialFrontImageUrl);
  const [cardBack, setCardBack] = useState<string>(initialBackImageUrl);

  const handleCardFrontFileChange = (file: File) => {
    onFormChange('image_url', [file]);
    const imgString = URL.createObjectURL(file);
    setCardFront(imgString);
  };

  const handleCardBackFileChange = (file: File) => {
    onFormChange('image_url_2', [file]);
    const imgString = URL.createObjectURL(file);
    setCardBack(imgString);
  };

  const handleCardFrontDelete = () => {
    onFormChange('image_url', []);
    setCardFront('');
  };

  const handleCardBackDelete = () => {
    onFormChange('image_url_2', []);
    setCardBack('');
  };

  return (
    <>
      <ImageUploadListButton
        name="benefit-card-front"
        onUpload={handleCardFrontFileChange}
        onDelete={handleCardFrontDelete}
        source={cardFront}
        loading={loading}
        disabled={disabled}
      >
        {cardFront ? 'Delete' : 'Upload'} front of card
      </ImageUploadListButton>
      <MdSpacing />
      <ImageUploadListButton
        name="benefit-card-back"
        onUpload={handleCardBackFileChange}
        onDelete={handleCardBackDelete}
        source={cardBack}
        loading={loading}
        disabled={disabled}
      >
        {cardBack ? 'Delete' : 'Upload'} back of card
      </ImageUploadListButton>
    </>
  );
};
