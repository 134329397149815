import { type KeyDateType } from '@alto/operations_api/v1/types';
import { format } from 'date-fns';
import { useCartDateInfoKey } from '~shared/features/cart/hooks/useCartDateInfoKey';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import {
  DATE_INVALID_MAIL,
  DATE_LOADING,
  DATE_REFRIGERATED,
  DATE_TOO_EARLY,
  DATE_TOO_LATE,
  EXCLUDED_DATE_REASON_MESSAGES,
  NO_DATE_SELECTED,
  NO_WINDOW_SELECTED,
} from '~shared/features/checkout/constants';
import { type DateInfoKey, type ValidationError } from '~shared/features/checkout/types';
import { DATE_FORMATS, formatRelativeDate } from '~shared/helpers/date';
import { useQueryShipmentFeesAvailable } from '~shared/queries';

export const dateInfoKeysForToast = new Set<DateInfoKey | KeyDateType>([
  'date_invalid_mail',
  'date_too_early',
  'date_too_late',
  'date_refrigerated',
]);

export const getDateValidationErrors = ({
  dateInfoKeyApplied,
  earliestAvailableDate,
  lastAvailableDate,
}: {
  dateInfoKeyApplied: string | null;
  earliestAvailableDate: string;
  lastAvailableDate: string;
}) => {
  const errors: ValidationError[] = [];

  switch (dateInfoKeyApplied) {
    case DATE_LOADING: {
      errors.push({
        key: DATE_LOADING,
        message: 'Please wait while we verify the date or window.',
      });
      break;
    }

    case NO_DATE_SELECTED: {
      errors.push({
        key: NO_DATE_SELECTED,
        message: 'Please select a date to schedule your order.',
      });
      break;
    }

    case NO_WINDOW_SELECTED: {
      errors.push({
        key: NO_WINDOW_SELECTED,
        message: 'A delivery window must be selected.',
      });
      break;
    }

    case DATE_TOO_EARLY: {
      // should have a next available date for the order by this point, by just in case set fallback
      const earliest = format(new Date(), DATE_FORMATS.YEAR_MONTH_DAY_DASHED);

      errors.push({
        key: DATE_TOO_EARLY,
        message: `Delivery date must be later than ${formatRelativeDate(earliestAvailableDate || earliest, {
          includeRelative: true,
        })}.`,
      });
      break;
    }

    case DATE_TOO_LATE: {
      errors.push({
        key: DATE_TOO_LATE,
        message: `Delivery date must be on or earlier than ${formatRelativeDate(lastAvailableDate, {
          includeRelative: true,
        })}`,
      });
      break;
    }

    case DATE_INVALID_MAIL: {
      errors.push({
        key: DATE_INVALID_MAIL,
        message: EXCLUDED_DATE_REASON_MESSAGES.mail,
      });
      break;
    }

    case DATE_REFRIGERATED: {
      errors.push({
        key: DATE_REFRIGERATED,
        message: EXCLUDED_DATE_REASON_MESSAGES.refrigerated,
      });
      break;
    }

    default:
  }

  return errors;
};

export const useCheckoutDateErrors = (ignoreDeliveryFeeDateNotSelected = true) => {
  const { hasScheduleableFees } = useQueryShipmentFeesAvailable();
  const { earliestAvailableDate, lastAvailableDate } = useCartNextAvailableDate();
  const { dateInfoKey } = useCartDateInfoKey();
  // If ignoreDeliveryFeeDateNotSelected is true, then we don't want to show the error if the date is not selected.
  const ignoreNotSelected = ignoreDeliveryFeeDateNotSelected && hasScheduleableFees(earliestAvailableDate);
  const dateInfoKeyApplied = ignoreNotSelected && dateInfoKey === NO_DATE_SELECTED ? null : dateInfoKey;
  const dateValidationErrors = getDateValidationErrors({
    dateInfoKeyApplied,
    earliestAvailableDate,
    lastAvailableDate,
  });

  return { dateValidationErrors };
};
