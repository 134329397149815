import { post } from '~shared/helpers/apiHelper';
import getDeviceID from '~shared/helpers/getDeviceID';
import { getUserIdentifier } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';

export const sendEvents = async (events: Event[]) => {
  const anonymousID = getDeviceID();
  const { userIdentifier, userIdentifierType } = getUserIdentifier(events);

  return post('/onederlytics/record', {
    anonymousID,
    events,
    userIdentifier,
    userIdentifierType,
  });
};
