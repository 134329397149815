// This file enables using one selector (getShipmentsWithItems) for both web and native.
// It is meant to be a temporary file that should be removed when these additional
// fields are not needed by components (ie. DeliveryLogistics) in the native app.
import { createSelector } from 'reselect';
import { getAllAddresses } from '~shared/features/addresses/selectors/getAddresses';
import {
  getPastShipmentsWithItems,
  getUpcomingShipmentsWithItems,
} from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { type ShipmentWithItems, type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { type Address, type ReduxStateShared } from '~shared/types';

const addAddress = (shipment: ShipmentWithItems, addresses: Address[]): void => {
  const item = shipment.items.find((item) => item.delivery);
  const delivery = item?.delivery;

  if (delivery) {
    shipment.address = addresses.find((address) => address.id === delivery.address_id);
  }
};

const addHomeToSignConfirmation = (shipment: ShipmentWithItems): void => {
  const item = shipment.items.find((item) => item.delivery);
  const delivery = item?.delivery;

  if (delivery) {
    shipment.home_to_sign_confirmation = delivery.home_to_sign_confirmation;
  }
};

const addAdditionalFields = (
  shipment: ShipmentWithItems,
  addresses: Address[],
): ShipmentWithItemsAndAdditionalFields => {
  addAddress(shipment, addresses);
  addHomeToSignConfirmation(shipment);
  return shipment;
};

type ShipmentsSelector = (state: ReduxStateShared) => ShipmentWithItems[];

const makeGetShipmentsWithItemsAndAdditionalFields = (selector: ShipmentsSelector) => {
  return createSelector([getAllAddresses, selector], (addresses, shipments): ShipmentWithItemsAndAdditionalFields[] => {
    return shipments.map((shipment) => addAdditionalFields(shipment, addresses));
  });
};

export const getUpcomingShipmentsWithItemsAndAdditionalFields =
  makeGetShipmentsWithItemsAndAdditionalFields(getUpcomingShipmentsWithItems);

export const getPastShipmentsWithItemsAndAdditionalFields =
  makeGetShipmentsWithItemsAndAdditionalFields(getPastShipmentsWithItems);
