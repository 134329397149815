// @owners { team: patients-team }
import { type ApiResponseRow } from '~shared/features/transfers/types';

export const formatMedications = (
  medications: ApiResponseRow<string>[],
): {
  name: string;
  autocomplete: boolean;
}[] => {
  return medications.map((med) => ({
    name: med.value,
    autocomplete: med.isFromAutocomplete,
  }));
};

export const formatInitialMedications = (medList: string[] | null | undefined): ApiResponseRow<string>[] => {
  if (!medList) {
    return [];
  }

  return medList.map(
    (medName: string): ApiResponseRow<string> => ({
      title: medName,
      label: medName,
      key: medName,
      value: medName,
      isFromAutocomplete: true,
    }),
  );
};

export const formatSubmitValues = (value: ApiResponseRow<string>[] | null | undefined): string => {
  return value?.map(({ value }) => value).join(', ') || '';
};
