// @owners { team: patients-team }

export const SET_ANNOUNCEMENT_VIEWED = 'SET_ANNOUNCEMENT_VIEWED';

export type SetAnnouncementViewed = {
  type: typeof SET_ANNOUNCEMENT_VIEWED;
  payload: number;
};

export function setAnnouncementViewed(announcementID: number) {
  return {
    type: SET_ANNOUNCEMENT_VIEWED,
    payload: announcementID,
  };
}
