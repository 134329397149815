import { useNavigation } from '@alto/navigation';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';

export const useDeliveryDetailsViewed = () => {
  const { trackPageView } = useAnalytics();
  const { getCurrentRouteName, getParam } = useNavigation<'RouteOrderDetails'>();
  const routeName = getCurrentRouteName();
  let shipmentID: number | null;
  let origin: string | undefined;

  if (Platform.OS === 'web') {
    shipmentID = Number(routeName?.split('/').pop());
  } else {
    shipmentID = Number(getParam('shipmentID'));
    if (routeName === 'RouteTabHomescreen') {
      origin = 'homescreen';
    }
    if (routeName === 'RouteTabOrders') {
      origin = 'deliveries-list';
    }
  }

  const shipment = useSelectorShared((state) =>
    getShipmentWithID(state, {
      shipmentID,
    }),
  );

  const deliveries = useSelectorShared((state) =>
    getDeliveriesForShipmentID(state, {
      shipmentID,
    }),
  );

  useEffect(() => {
    deliveries.map((delivery) => {
      trackPageView({
        event: EVENTS.DELIVERY_DETAILS_VIEWED,
        params: {
          'shipment is editable': !!shipment && isShipmentEditable(shipment),
          origin,
        },
        additionalFields: {
          prescriptionId: delivery.prescription_id,
          deliveryId: delivery.id,
          shipmentId: Number(shipmentID),
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
