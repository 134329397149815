import * as React from 'react';
import { Column } from '../../../containers';
import { XsSpacing } from '../../../separators';
import { ListItemTags } from './ListItemTags';
import { type ListItemProps } from './ListUtils';

export const ListItemTagsWrapper = ({ tags, tagPlacement }: Pick<ListItemProps, 'tags' | 'tagPlacement'>) => {
  if (tagPlacement === 'top' || tagPlacement === 'bottom') {
    return (
      <Column>
        {tagPlacement === 'bottom' && <XsSpacing />}
        {!!tags && <ListItemTags tags={tags} />}
        {tagPlacement === 'top' && <XsSpacing />}
      </Column>
    );
  }
  return (
    <>
      <XsSpacing />
      {!!tags && <ListItemTags tags={tags} />}
    </>
  );
};
