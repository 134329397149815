import { PALETTE } from './palette';

export const BORDER_COLORS = {
  PRIMARY: PALETTE.PRIMARY.DEFAULT,
  SECONDARY: PALETTE.SECONDARY.DEFAULT,
  SUCCESS: PALETTE.SUCCESS.DEFAULT,
  WARNING: PALETTE.WARNING.DEFAULT,
  DANGER: PALETTE.DANGER.DEFAULT,
  LIGHT: PALETTE.GREYSCALE.DEFAULT,
  DARK: PALETTE.GREYSCALE.DARKER,
} as const;
