import { type Values } from './getInitialInsuranceFormValues';
import { RELATIONSHIP_OPTIONS } from '~shared/constants';
import { type CreateManualInsurancePayload } from '~shared/features/insurances/types';
import { type User } from '~shared/types/clients';

export function getInferredInsuranceFormValues(values: Values, user: User): CreateManualInsurancePayload {
  const newInsuranceValues: CreateManualInsurancePayload = {};

  // If the relationship for the insurance is self (aka, 1) then
  // we automagically fill the first and last name to be user's names
  if (values.relationship_code === '1') {
    newInsuranceValues.first_name = user.first_name;
    newInsuranceValues.last_name = user.last_name;
  }

  // We infer the person code from the relationship code
  if (values.relationship_code) {
    const selectedRelationshipOption = RELATIONSHIP_OPTIONS.find(({ value }) => values.relationship_code === value);
    newInsuranceValues.person_code = selectedRelationshipOption?.personCode;
  }

  return { ...values, ...newInsuranceValues };
}
