// @owners { team: patients-team }
import { PaymentMethodIcon } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, SimpleContainerSection, Text, spacing } from '@alto/pocky';
import React, { PureComponent } from 'react';
import { getPaymentMethodIconName } from '~shared/features/payment-methods/helpers';
import { paymentType } from '~shared/features/payment-methods/helpers/paymentType';
import { shouldShowExpiration } from '~shared/helpers/payment';
import { type PaymentMethod } from '~shared/types';
import { ConfirmLink } from '~web/components/legacy';

type Props = {
  readonly creditCardPaymentMethods: PaymentMethod[];
  readonly deletePaymentMethod: (paymentMethod: PaymentMethod) => void;
};

export default class CreditCardPayments extends PureComponent<Props> {
  render() {
    const { creditCardPaymentMethods, deletePaymentMethod } = this.props;
    if (creditCardPaymentMethods.length > 0) {
      return (
        <>
          {creditCardPaymentMethods.map((creditCardPaymentMethod) => {
            const card = creditCardPaymentMethod.details;
            const cardIdentity = `${card?.card_type || ''} ${card?.card_last_4 || ''}`;
            const paymentMethodType = paymentType(creditCardPaymentMethod);
            const showExpiration = shouldShowExpiration(paymentMethodType);
            const cardExpiration =
              (showExpiration &&
                card?.card_expiration_month &&
                card.card_expiration_year &&
                `Expires ${card.card_expiration_month}/${card.card_expiration_year}`) ||
              '';
            const paymentMethodIconName = getPaymentMethodIconName(creditCardPaymentMethod);
            return (
              <SimpleContainerSection key={`cc-${creditCardPaymentMethod.id}`}>
                <FlexColumn
                  evenlySpaceContent
                  verticallyAlignContent
                >
                  <FlexColumn
                    spacing={spacing.smSpacing}
                    verticallyAlignContent
                  >
                    <PaymentMethodIcon paymentMethodIconName={paymentMethodIconName} />
                    <Text>{cardIdentity}</Text>
                    <Text light>{cardExpiration}</Text>
                  </FlexColumn>
                  <ConfirmLink
                    confirmPrompt="Are you sure you want to delete this?"
                    onClick={() => {
                      deletePaymentMethod(creditCardPaymentMethod);
                    }}
                  >
                    Delete
                  </ConfirmLink>
                </FlexColumn>
              </SimpleContainerSection>
            );
          })}
        </>
      );
    }

    return (
      <SimpleContainerSection>
        <Text>We don’t have a credit card on file for you yet. Go ahead and add one!</Text>
      </SimpleContainerSection>
    );
  }
}
