// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
import { YoutubePlayer } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { type Video } from '~shared/features/instructional-videos/types';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import DescriptionPane from '~web/features/instructional-videos/components/DescriptionPane';

const VIDEO_MAX_WIDTH = 442;

const VideoWrapper = styled.div<{ isMDScreenOrBigger: boolean }>`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: ${SPACING.STATIC.XL.rem};
  > *:not(:last-child) {
    margin-right: ${SPACING.STATIC.LG.rem};
  }
  box-shadow:
    0 0 0 1px rgba(147, 156, 163, 0.1),
    0 3px 5px rgba(195, 207, 217, 0.3),
    0 5px 15px rgba(195, 207, 217, 0.3);

  ${({ isMDScreenOrBigger }) =>
    !isMDScreenOrBigger &&
    `
      flex-direction: column;
      > *:not(:last-child) {
        margin-right: 0;
      }
    `}
`;

const VideoPlayerWrapper = styled.div`
  width: 100%;
  max-width: ${VIDEO_MAX_WIDTH}px;
`;

const DescriptionWrapper = styled.div<{ isMDScreenOrBigger: boolean }>`
  padding-right: ${spacing.xxlSpacing};
  max-width: 50%;
  ${({ isMDScreenOrBigger }) =>
    !isMDScreenOrBigger &&
    `
      max-width: ${VIDEO_MAX_WIDTH}px;
      padding-right: 0;
    `}
`;

export type Props = {
  readonly video: Video;
};

export const ProfileInstructionalVideo = ({ video }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <VideoWrapper isMDScreenOrBigger={isMDScreenOrBigger}>
      <VideoPlayerWrapper>
        <YoutubePlayer
          videoID={video.videoID}
          origin={ORIGIN_NAMES.PROFILE}
        />
      </VideoPlayerWrapper>
      <DescriptionWrapper isMDScreenOrBigger={isMDScreenOrBigger}>
        <DescriptionPane video={video} />
      </DescriptionWrapper>
    </VideoWrapper>
  );
};
