// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Caption, Description, MdSpacing, XsSpacing } from '@alto/design-system';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { updateOrder } from '~shared/actions/cart';
import { PAYMENT_METHOD_TYPES, type PaymentMethodType } from '~shared/constants';
import { useCheckoutTipErrors } from '~shared/features/checkout/hooks/useCheckoutTipErrors';
import { getShowingValidationErrors } from '~shared/features/checkout/selectors/getCart';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getDefaultPaymentMethodID } from '~shared/features/payment-methods/helpers';
import { useGetPaymentMethods } from '~shared/queries/query-keys/paymentMethods';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useDeliveryFeeCopy } from '../../checkout/components/payment-method-card/useDeliveryFeeCopy';
import { PaymentMethodSelector } from '../../payment/components/PaymentMethodSelector';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly disabled?: boolean;
};

export const TipPaymentMethodPicker = ({ handleNativePay, disabled }: Props) => {
  const dispatch = useDispatchShared();
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const { tipValidationErrors } = useCheckoutTipErrors();
  const errors = showingValidationErrors ? tipValidationErrors : [];
  const { description } = useDeliveryFeeCopy();
  const { tip_amount, tip_payment_method_id } = useSelectorShared(getOrder);
  const { paymentMethods } = useGetPaymentMethods();
  const defaultPaymentMethodID = getDefaultPaymentMethodID(paymentMethods);

  // auto-select a tip payment method when one is not selected
  useEffect(() => {
    if (!tip_amount || tip_payment_method_id) return;

    if (tip_amount && !tip_payment_method_id && defaultPaymentMethodID) {
      dispatch(updateOrder({ tip_payment_method_id: defaultPaymentMethodID }));
    }
  }, [defaultPaymentMethodID, dispatch, tip_amount, tip_payment_method_id]);

  return (
    <View>
      <PaymentMethodSelector
        disabled={disabled}
        handleNativePay={handleNativePay}
        paymentMethodType={PAYMENT_METHOD_TYPES.PRE_TIP}
      />
      {errors?.length ? (
        <>
          <XsSpacing />
          <Description color={COLORS.TEXT_COLORS.DANGER}>{errors[0].message}</Description>
        </>
      ) : null}
      <MdSpacing />
      <Caption>{description}</Caption>
    </View>
  );
};
