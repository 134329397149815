// @owners { team: patients-team }
import React from 'react';

type Props = {
  readonly src: string;
  readonly alt: string;
  readonly width?: string;
  readonly height?: string;
  readonly style?: React.CSSProperties;
};

export const Image = ({ src, alt, width, height, style }: Props) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={style}
    />
  );
};
