// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Body,
  Button,
  Card,
  Column,
  H1,
  H2,
  Illustration,
  InputSelect,
  InputText,
  LgPadding,
  LgSpacing,
  Link,
  MdSpacing,
  SecondaryPage,
  SmSpacing,
  Toast,
  ToastContext,
  XlPadding,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { setAddFamilyMemberRelationship } from '~shared/actions/ui/addFamilyMember';
import AddFamilyMemberImage from '~shared/assets/images/add_family_member.png';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { formatDateInput, formatDateStringForAPI, isOfAge } from '~shared/helpers/date';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useAddFamilyMember } from '../../family-members';
import { FamilyAccountActionSheet, FamilyMemberConsentAlert } from './FamilyAccountActionSheet';

type AddFamilyMemberFormValues = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  relationship: string;
  otherRelationship?: string;
};

const dateRegEx = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/; // MM/DD/YYYY format
const relationshipOptions = [
  {
    value: 'child',
    label: 'Child',
  },
  {
    value: 'parent',
    label: 'Parent',
  },
  {
    value: 'partner',
    label: 'Spouse/partner',
  },
  {
    value: 'sibling',
    label: 'Sibling',
  },
  {
    value: 'pet',
    label: 'Pet',
  },
  {
    value: 'other',
    label: 'Other (please specify)',
  },
];

const isWeb = getPlatformOS() === 'web';

export const AddFamilyMember = () => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { addToast } = useContext(ToastContext);
  const { goBack, navigate } = useNavigation<'RouteAddFamilyMember'>();
  const currentUser = useSelectorShared(getCurrentUser);
  const { trackEvent, trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({ event: EVENTS.FAMILY_ACCOUNTS_ADD_FAMILY_MEMBER_FORM_VIEWED });
  }, [trackPageView]);

  const { mutateAsync: addFamilyMember } = useAddFamilyMember();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    reset,
  } = useForm<AddFamilyMemberFormValues>();

  const firstNameController = useController({
    control,
    name: 'firstName',
    rules: { required: 'First name is required' },
  });

  const lastNameController = useController({ control, name: 'lastName', rules: { required: 'Last name is required' } });

  const dateOfBirthController = useController({
    control,
    name: 'dateOfBirth',
    defaultValue: '',
    rules: {
      required: 'Date of birth is required',
      pattern: { value: dateRegEx, message: 'Enter a date in the format MM/DD/YYYY' },
    },
  });

  const relationshipController = useController({
    control,
    name: 'relationship',
    rules: { required: 'Relationship is required' },
  });

  const otherRelationshipController = useController({
    control,
    name: 'otherRelationship',
    rules: { required: getValues('relationship') === 'other' ? 'Relationship is required' : undefined },
  });

  const handleAddFamilyMember = () => {
    handleSubmit(async (values) => {
      if (!isValid) return;

      const formValues = { ...values };
      if (values.relationship === 'other' && values.otherRelationship) {
        formValues.relationship = values.otherRelationship;
      }

      const params = {
        date_of_birth: formatDateStringForAPI(formValues.dateOfBirth),
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        relationship: formValues.relationship,
      };

      const { familyMemberPatientID, hasCompletedOnboarding, supportCaseID } = await addFamilyMember({
        params,
      });
      reset(); // reset form fields

      if (familyMemberPatientID) {
        dispatch(setAddFamilyMemberRelationship(formValues.relationship));
        trackEvent({ event: EVENTS.FAMILY_ACCOUNTS_FAMILY_MEMBER_FOUND });

        if (hasCompletedOnboarding) {
          navigate('RouteFamilyMemberAdded', { hasCompletedOnboarding });
        } else {
          // otherwise, navigate to complete onboarding for family member
          navigate('RouteFamilyMemberMedicalInfo', { userID: familyMemberPatientID });
        }
      } else if (supportCaseID) {
        // when family member cannot be added, show pending request screen
        navigate('RouteFamilyMemberRequest', {
          ...formValues,
          supportCaseID,
        });
      } else {
        addToast(<Toast variant="error">There was an error adding a family member. Please try again later.</Toast>);
        const context = {
          tags: { featureOwner: 'patients-team' },
          contexts: { params },
        };
        Sentry.captureMessage('Unable to add family member through self-serve or support case creation.', context);
      }
    })();
  };

  const handleShowActionSheet = () => {
    setActiveActionSheet(<FamilyAccountActionSheet />);
  };

  const is18OrOlder = !dateOfBirthController.fieldState.invalid && isOfAge(dateOfBirthController.field.value, 18);

  return (
    <SecondaryPage
      dismissIcon="chevronleft"
      onDismiss={goBack}
      headerBackgroundColor={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      HeaderContent={
        <XlPadding topPadding={SPACING.STATIC.NONE}>
          <Column center>
            <Illustration
              source={AddFamilyMemberImage}
              accessibilityLabel="family holding hands"
              size={isWeb ? 'xl' : 'lg'}
            />
            <MdSpacing />
            <H1 center>Add a family member</H1>
            <XsSpacing />
            <Body center>Manage your family’s prescriptions—including pets—all in one place!</Body>
            <MdSpacing />
            <Link onPress={handleShowActionSheet}>How do family accounts work?</Link>
          </Column>
        </XlPadding>
      }
    >
      <Card>
        <LgPadding>
          <H2>Who would you like to add?</H2>
          <LgSpacing />
          <InputSelect
            required
            label={`Relationship to ${currentUser?.first_name ?? 'account owner'}`}
            placeholder="Select relationship"
            onValueChange={(value) => {
              relationshipController.field.onChange(value);
            }}
            value={relationshipController.field.value}
            error={errors.relationship?.message}
            options={relationshipOptions}
            modalTitle={`Relationship to ${currentUser?.first_name ?? 'account owner'}`}
          />
          {relationshipController.field.value === 'other' ? (
            <>
              <SmSpacing />
              <InputText
                required
                placeholder="Enter relationship"
                onChangeText={(value) => {
                  otherRelationshipController.field.onChange(value);
                }}
                error={errors.otherRelationship?.message}
                value={otherRelationshipController.field.value}
              />
            </>
          ) : null}
          <LgSpacing />
          <InputText
            accessibilityLabel="first name"
            required
            label="First name"
            placeholder="Enter first name"
            error={errors.firstName?.message}
            onChangeText={(value) => {
              firstNameController.field.onChange(value);
            }}
            value={firstNameController.field.value}
          />
          <LgSpacing />
          <InputText
            accessibilityLabel="last name"
            required
            label="Last name"
            placeholder="Enter last name"
            error={errors.lastName?.message}
            onChangeText={(value) => {
              lastNameController.field.onChange(value);
            }}
            value={lastNameController.field.value}
          />
          <LgSpacing />
          <InputText
            accessibilityLabel="date of birth"
            required
            label="Date of birth"
            placeholder="MM/DD/YYYY"
            error={errors.dateOfBirth?.message}
            onChangeText={(value) => {
              const formattedDate = formatDateInput(value).replace(/\s+/g, '');
              dateOfBirthController.field.onChange(formattedDate);
            }}
            value={dateOfBirthController.field.value}
          />
          <LgSpacing />
          {is18OrOlder ? (
            <>
              <FamilyMemberConsentAlert />
              <LgSpacing />
            </>
          ) : null}
          <Button
            label="Request to add family member"
            loading={isSubmitting}
            onPress={handleAddFamilyMember}
          />
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};
