// @owners { team: patients-team }
// eslint-disable-next-line import/no-deprecated
import { updatePrescription } from '~shared/actions/prescriptions';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type ReduxDispatch } from '~shared/types';
import { type Prescription } from '~shared/types/clients';

export const UPDATE_QUANTITY_LOADING = 'UPDATE_QUANTITY_LOADING';

type UpdateQuantityLoadingAction = {
  type: typeof UPDATE_QUANTITY_LOADING;
};

export function updatingQuantity(): UpdateQuantityLoadingAction {
  return {
    type: UPDATE_QUANTITY_LOADING,
  };
}

export const UPDATE_QUANTITY_SUCCEEDED = 'UPDATE_QUANTITY_SUCCEEDED';

type UpdateQuantitySucceededAction = {
  type: typeof UPDATE_QUANTITY_SUCCEEDED;
};

export function updateQuantitySucceeded(): UpdateQuantitySucceededAction {
  return {
    type: UPDATE_QUANTITY_SUCCEEDED,
  };
}

export const UPDATE_QUANTITY_FAILED = 'UPDATE_QUANTITY_FAILED';

type UpdateQuantityFailedAction = {
  type: typeof UPDATE_QUANTITY_FAILED;
  error: boolean;
};

export function updateQuantityFailed(): UpdateQuantityFailedAction {
  return {
    type: UPDATE_QUANTITY_FAILED,
    error: true,
  };
}

export const updateQuantity: (arg0: Prescription['id'], arg1: number) => (arg0: ReduxDispatch) => Promise<boolean> = (
  prescriptionID,
  newQuantity,
) => {
  return (dispatch) => {
    dispatch(updatingQuantity());

    dispatch(sendAnalyticsEvent(createEvent(EVENTS.QUANTITY_CHANGE_CONFIRMED, {}, { prescriptionId: prescriptionID })));

    return dispatch(
      // eslint-disable-next-line import/no-deprecated
      updatePrescription({
        id: prescriptionID,
        quantity: newQuantity,
      }),
    ).then((wasSuccessful) => {
      if (!wasSuccessful) {
        dispatch(updateQuantityFailed());
        return false;
      }

      dispatch(updateQuantitySucceeded());

      return true;
    });
  };
};
