import { ButtonSelector, Description, InputAutocomplete, type OptionValue, XsSpacing } from '@alto/design-system';
import { medicationsAutocompleteDataSource } from '@alto/features';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, useFormikContext } from 'formik';
import React from 'react';
import { DOES_NOT_HAVE_OTHER_SCRIPTS, HAS_OTHER_SCRIPTS } from '~shared/constants';

const RADIO_YES_VALUE = HAS_OTHER_SCRIPTS;
const RADIO_NO_VALUE = DOES_NOT_HAVE_OTHER_SCRIPTS;

const getOnboardingOtherScriptsRadioValue = (
  onboardingOtherScripts: string | null | undefined,
): typeof RADIO_YES_VALUE | typeof RADIO_NO_VALUE | null => {
  if (onboardingOtherScripts === RADIO_YES_VALUE) return RADIO_YES_VALUE;
  if (onboardingOtherScripts === RADIO_NO_VALUE) return RADIO_NO_VALUE;

  // If for some reason, onboarding_other_scripts in the database is a random other string, just don't select anything
  return null;
};

const OnboardingOtherScriptsFields = () => {
  const {
    values: { onboarding_other_scripts: onboardingOtherScripts },
  } = useFormikContext<{ onboarding_other_scripts: string | null | undefined }>();
  const onboardingOtherScriptsRadioValue = getOnboardingOtherScriptsRadioValue(onboardingOtherScripts);

  return (
    <div>
      <Field name="onboarding_other_scripts">
        {({ field, form: { setFieldValue } }: FieldProps<string>) => (
          <>
            <Description fontFamily="semibold">Actively taking any other medications?</Description>
            <XsSpacing />
            <ButtonSelector
              options={[
                { label: RADIO_NO_VALUE, value: RADIO_NO_VALUE },
                { label: RADIO_YES_VALUE, value: RADIO_YES_VALUE },
              ]}
              value={onboardingOtherScriptsRadioValue as OptionValue}
              onChange={() => {
                if (onboardingOtherScriptsRadioValue === RADIO_NO_VALUE) {
                  setFieldValue(field.name, RADIO_YES_VALUE);
                } else {
                  setFieldValue('other_scripts_list', null);
                  setFieldValue(field.name, RADIO_NO_VALUE);
                }
              }}
            />
          </>
        )}
      </Field>
      {onboardingOtherScriptsRadioValue === RADIO_YES_VALUE ? (
        <>
          <XsSpacing />
          <Field name="other_scripts_list">
            {({ form: { errors, setFieldValue, submitCount, values } }: FieldProps<string>) => (
              <InputAutocomplete
                label=""
                leftIcon="pillbottle-small"
                accessibilityLabel="List any active medications"
                shouldAllowManualInput
                selectionType="multi"
                dataSource={medicationsAutocompleteDataSource}
                initialValues={values.other_scripts_list}
                onSelectCallback={(data) => {
                  setFieldValue('other_scripts_list', data);
                }}
                placeholder="e.g. Albuterol, Amoxicillin"
                error={submitCount > 0 ? (errors.other_scripts_list as unknown as string) : null}
                required
              />
            )}
          </Field>
        </>
      ) : null}
    </div>
  );
};

export default OnboardingOtherScriptsFields;
