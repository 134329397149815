// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { AccessibilityInfo, MdPadding, Row } from '@alto/design-system';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import styledNative from 'styled-components/native';

const DEFAULT_ANIMATION_DURATION = 2000; // 2 seconds

const FORWARDS = 'forwards';
const BACKWARDS = 'backwards';

export type ProgressProps = {
  readonly completedSteps: number;
  readonly totalSteps: number;
};

const Step = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: ${SPACING.STATIC.XXS.px};
  border-radius: ${BORDERS.RADIUS.XS.px};
  margin: 0 ${SPACING.STATIC.XXS.px};
`;

const IncompleteStep = styled(Step)`
  background-color: ${COLORS.PALETTE.PRIMARY.LIGHTER};
`;

const growWidth = keyframes`
  from { width: 0%; }
  to { width: 100%; }
`;

const shrinkWidth = keyframes`
  from { width: 100%; }
  to { width: 0%; }
`;

type AnimationDirectionType = typeof FORWARDS | typeof BACKWARDS;

const GrowingWidthWrapper = styled.div<{ animationDirection: AnimationDirectionType; isReduceMotionEnabled: boolean }>`
  ${({ isReduceMotionEnabled, animationDirection }) =>
    !isReduceMotionEnabled &&
    css`
      animation-duration: ${DEFAULT_ANIMATION_DURATION}ms;
      animation-name: ${animationDirection === FORWARDS ? growWidth : shrinkWidth};
      animation-fill-mode: forwards;
    `}
`;

const CompletedStep = styled(Step)<{ noMargin?: boolean }>`
  background-color: ${COLORS.PALETTE.SUCCESS.DEFAULT};
  ${({ noMargin }) => (noMargin ? 'margin: 0;' : '')};
`;

const ProgressContainer = styledNative(Row)`
  width: 240px;
`;

const OnboardingProgress = ({ completedSteps, totalSteps }: ProgressProps) => {
  const [animationDirection, setAnimationDirection] = useState<AnimationDirectionType>(FORWARDS);
  const [isReduceMotionEnabled, setIsReduceMotionEnabled] = useState(true);
  const prevCompletedStepsRef = useRef(completedSteps);

  useEffect(() => {
    prevCompletedStepsRef.current = completedSteps;
    // eslint-disable-next-line promise/catch-or-return, promise/always-return
    AccessibilityInfo.isReduceMotionEnabled().then((enabled) => {
      setIsReduceMotionEnabled(enabled);
    });
  });
  const prevCompletedSteps = prevCompletedStepsRef.current;

  useEffect(() => {
    if (prevCompletedSteps < completedSteps) {
      setAnimationDirection(FORWARDS);
    } else if (prevCompletedSteps > completedSteps) {
      setAnimationDirection(BACKWARDS);
    } // Don't need to re-render on prevCompletedSteps change as well as completedSteps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSteps]);

  const steps = [];

  for (let step = 0; step < totalSteps; step++) {
    if (
      // If this was the last completed step, animate it from incomplete to complete
      (animationDirection === FORWARDS && step === completedSteps - 1) || // If we're going backwards to this step, animate the one after it from complete to incomplete
      (animationDirection === BACKWARDS && step === completedSteps)
    ) {
      steps.push(
        <IncompleteStep>
          <GrowingWidthWrapper
            isReduceMotionEnabled={isReduceMotionEnabled}
            animationDirection={animationDirection}
          >
            <CompletedStep noMargin />
          </GrowingWidthWrapper>
        </IncompleteStep>,
      );
    } else if ((animationDirection === BACKWARDS && step === completedSteps - 1) || step < completedSteps - 1) {
      steps.push(<CompletedStep />);
    } else {
      steps.push(<IncompleteStep />);
    }
  }

  return (
    <MdPadding>
      <ProgressContainer>{steps}</ProgressContainer>
    </MdPadding>
  );
};

export default OnboardingProgress;
