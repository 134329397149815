import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Link, Text, spacing } from '@alto/pocky';
import React, { PureComponent } from 'react';
import { Link as RRLink } from 'react-router';
import styled from 'styled-components';

type LocalCrumb = {
  text: string;
  to?: string;
};

type NodeCrumb = {
  link: React.ReactNode;
  key: string;
};

type Breadcrumb = LocalCrumb | NodeCrumb;

type Props = {
  readonly breadcrumbs: Breadcrumb[];
};
const BreadcrumbContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 ${spacing.smSpacing};
`;

const BreadcrumbLink = styled(Link)`
  font-weight: normal;
`;

const BreadcrumbAnchor = styled<any>(RRLink)`
  text-decoration: none;
  color: ${COLORS.TEXT_COLORS.SECONDARY};
`;

type BCType = {
  readonly breadcrumb: Breadcrumb;
};

const isNodeCrumb = (breadcrumb: unknown): breadcrumb is NodeCrumb => {
  return !!(breadcrumb as NodeCrumb).key;
};

const BCText = ({ breadcrumb }: BCType) => {
  if (isNodeCrumb(breadcrumb)) return <>{breadcrumb.link}</>;

  return <Text>{breadcrumb.text}</Text>;
};

const BCLink = ({ breadcrumb }: BCType) => {
  if (isNodeCrumb(breadcrumb)) return <>{breadcrumb.link}</>;
  return <BreadcrumbAnchor to={breadcrumb.to}>{breadcrumb.text}</BreadcrumbAnchor>;
};
export default class Breadcrumbs extends PureComponent<Props> {
  static defaultProps = {
    breadcrumbs: [],
  };

  render() {
    const { breadcrumbs } = this.props;
    return (
      <BreadcrumbContainer>
        {breadcrumbs.map((breadcrumb, i) => {
          if (i === breadcrumbs.length - 1) {
            return (
              <BCText
                key="final"
                breadcrumb={breadcrumb}
              />
            );
          }

          return (
            <React.Fragment key={`link-${isNodeCrumb(breadcrumb) ? breadcrumb.key : breadcrumb.to}`}>
              <BreadcrumbLink>
                <BCLink breadcrumb={breadcrumb} />
              </BreadcrumbLink>
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
              <XsSpacing />
            </React.Fragment>
          );
        })}
      </BreadcrumbContainer>
    );
  }
}
