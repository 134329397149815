// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getCurrentUser } from './getCurrentUser';
import { type ReduxStateShared, type User } from '~shared/types';

// Whether or not to show the Progyny self-service flow for the current user
// True if the user is Progyny
const getShowProgynySelfServiceForUser: (arg0: ReduxStateShared) => boolean = createSelector(
  [getCurrentUser],
  (user: User | null | undefined): boolean => {
    return !!user?.is_progyny;
  },
);

export default getShowProgynySelfServiceForUser;
