import { validatePresentWithMessage } from './validatePresentWithMessage';

export const validatePresentIf = <T, Values>(rule: (value: T, otherValues: Values) => boolean, label: string) => {
  return (value: T, otherValues: Values) => {
    if (rule(value, otherValues)) {
      return validatePresentWithMessage(label)(value);
    }

    return null;
  };
};
