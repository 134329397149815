// @owners { team: patients-team }
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '~shared/helpers/apiHelper';
import { type Product } from '~shared/types';

const isProduct = (response: Product | { error: Error }): response is Product => {
  return (response as Product).id !== undefined;
};

export const products = createQueryKeys('products', {
  product: ({ productID }) => ({
    queryKey: [{ productID }],
    queryFn: async () => {
      const response = await get(`/products/${productID}`);

      if (isProduct(response)) {
        return { product: response };
      }

      throw new Error(response?.error?.message);
    },
  }),
});
