import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Row } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';

type Props = {
  readonly children: React.ReactNode;
  readonly spacing?: string;
  readonly horizontallyAlignContent?: boolean;
  readonly padding?: string;
};

const StyledButtonWrapper = styled(Row)<{ spacing?: string; isSMScreenOrBigger: boolean }>`
  > ${Button} {
    min-width: 190px;
    width: auto;
    align-self: center;
  }

  ${({ isSMScreenOrBigger, spacing }) =>
    !isSMScreenOrBigger &&
    `
      flex-direction: column-reverse;
      > *:not(:last-child) {
        margin-right: unset;
      }
      > *:not(:first-child) {
        margin-bottom: ${spacing || 'unset'};
      }
      > ${Button} {
        width: 100%;
      }
    `}
`;

export const ButtonWrapper = ({ children, spacing, horizontallyAlignContent, padding }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();

  return (
    <StyledButtonWrapper
      spacing={spacing}
      padding={padding}
      horizontallyAlignContent={horizontallyAlignContent}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      {children}
    </StyledButtonWrapper>
  );
};
