// @owners { team: patients-team }
import { PaymentMethodIcon } from '@alto/features';
import React from 'react';
import { getPaymentMethodIconName } from '~shared/features/payment-methods/helpers';
import { checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder } from '~shared/features/payment-methods/helpers/checkIfPaymentMethodFailed';
import formatPaymentMethod from '~shared/features/payment-methods/helpers/formatPaymentMethod';
import { type PaymentMethod, type ShipmentPaymentMethod } from '~shared/types';
import StyledPaymentMethodRow from './StyledPaymentMethodRow';

type Props = {
  readonly amount?: number | null | undefined;
  readonly paymentMethod?: PaymentMethod;
  readonly splitPayment?: boolean;
  readonly shipmentIsEditable?: boolean;
  readonly editShipment?: () => void;
  readonly hasFailedSplitPayments?: boolean;
  readonly shipmentPaymentMethod?: ShipmentPaymentMethod;
};

export default function DeliveryPaymentMethodRow({
  amount,
  paymentMethod,
  splitPayment,
  shipmentIsEditable,
  editShipment,
  hasFailedSplitPayments,
  shipmentPaymentMethod,
}: Props) {
  const failedPayment =
    !!shipmentPaymentMethod && checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder(shipmentPaymentMethod);
  const paymentMethodIconName = getPaymentMethodIconName(paymentMethod);

  return (
    <StyledPaymentMethodRow
      amount={amount}
      icon={<PaymentMethodIcon paymentMethodIconName={paymentMethodIconName} />}
      paymentMethodName={formatPaymentMethod(paymentMethod)}
      failedPayment={failedPayment}
      splitPayment={splitPayment}
      shipmentIsEditable={shipmentIsEditable}
      editShipment={editShipment}
      hasFailedSplitPayments={hasFailedSplitPayments}
    />
  );
}
