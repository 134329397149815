import * as React from 'react';
import { type ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '../../buttons';
import { CenterView, LgPadding } from '../../containers';
import { SizedImage } from '../../images';
import { LgSpacing, XsSpacing } from '../../separators';
import { H1, Text } from '../../typography';

const FullWidthPadding = styled(LgPadding)`
  align-items: center;
  width: 100%;
`;

export type FullViewEmptyStateProps = {
  readonly image?: ImageSourcePropType;
  readonly title?: string;
  readonly description?: string | React.ReactElement;
  readonly ctaText?: string;
  readonly onPressCTA?: () => void;
  readonly tertiaryCtaText?: string;
  readonly onPressTertiaryCTA?: () => void;
};

export const FullViewEmptyState = ({
  image,
  title,
  description,
  ctaText,
  onPressCTA,
  tertiaryCtaText,
  onPressTertiaryCTA,
}: FullViewEmptyStateProps) => {
  const hasMainCTA = !!ctaText && !!onPressCTA;
  const hasTertiaryCTA = !!tertiaryCtaText && !!onPressTertiaryCTA;
  return (
    <CenterView>
      <FullWidthPadding>
        {!!image && (
          <SizedImage
            source={image}
            pixelSize={80}
            borderRadius={0}
          />
        )}
        {!!image && (!!title || !!description) && <LgSpacing />}
        {!!title && <H1>{title}</H1>}
        {!!title && !!description && <XsSpacing />}
        {!!description && <Text center>{description}</Text>}
        {(hasMainCTA || hasTertiaryCTA) && (!!title || !!description || !!image) ? <LgSpacing /> : null}
        {hasMainCTA && !!onPressCTA && !!ctaText ? (
          <Button
            onPress={onPressCTA}
            label={ctaText}
            width="full"
          />
        ) : null}
        {hasTertiaryCTA ? (
          <>
            {hasMainCTA ? <XsSpacing /> : null}
            {!!onPressTertiaryCTA && !!tertiaryCtaText && (
              <Button
                type="tertiary"
                onPress={onPressTertiaryCTA}
                label={tertiaryCtaText}
                width="full"
              />
            )}
          </>
        ) : null}
      </FullWidthPadding>
    </CenterView>
  );
};
