import { BORDERS, COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Column } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { COMPLETED, IN_PROGRESS, NOT_STARTED } from '~shared/features/prescription-tracker/constants';

const INDICATOR_CONTAINER_COLOR_MAP = {
  [NOT_STARTED]: COLORS.PALETTE.GREYSCALE.DEFAULT,
  [IN_PROGRESS]: COLORS.BACKGROUND_COLORS.PRIMARY,
  [COMPLETED]: COLORS.BACKGROUND_COLORS.WHITE,
};

const INDICATOR_SIZE = 13;

export type Status = typeof NOT_STARTED | typeof IN_PROGRESS | typeof COMPLETED | null;
type Props = {
  readonly status: Status;
};

const IndicatorContainer = styled(Column)<{ status: Status }>`
  background-color: ${({ status }: Props) =>
    INDICATOR_CONTAINER_COLOR_MAP[status as keyof typeof INDICATOR_CONTAINER_COLOR_MAP]};
  width: ${INDICATOR_SIZE}px;
  height: ${INDICATOR_SIZE}px;
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  margin: 0px;
`;

const TrackerIndicator = ({ status }: Props) => (
  <IndicatorContainer status={status}>{status === COMPLETED && <AltoIcon name="checkcircle" />}</IndicatorContainer>
);

export default TrackerIndicator;
