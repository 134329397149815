// @owners { team: patients-team }
import React from 'react';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getURLParams } from '~shared/helpers/helper';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { MissingAddress } from './MissingAddress';
import { StoreContent } from './StoreContents';
import { FullPage, LoadingPage } from '~web/features/essentials/components';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';

export const StoreProducts = () => {
  const { order } = getURLParams();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: Number(order) }));
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID: Number(order) }));
  const addressLoading = useSelectorShared((state) => state.addresses.lastRequestSucceededAt === 0);

  useTrackViewedEvent(EVENTS.ESSENTIALS__STORE_VIEWED, shipment?.id, ORIGINS.STORE);

  if (addressLoading) {
    return (
      <FullPage>
        <LoadingPage />
      </FullPage>
    );
  }

  if (!address) {
    return <MissingAddress shipment={shipment} />;
  }

  return (
    <FullPage>
      <StoreContent
        address={address}
        shipment={shipment}
      />
    </FullPage>
  );
};
