import type { Callback, Payload, Unsub } from '~shared/types/websocket';
/*
 * Channel provides a subscription interface for a single addressable websocket channel.
 * A single channel may have any number of subscriptions.
 */

export default class Channel {
  name: string;
  connected: boolean;
  subscriptions: Callback[];

  constructor(name: string) {
    this.name = name;
    this.connected = false;
    this.subscriptions = [];
  }

  publish(message: Payload) {
    this.subscriptions.forEach((cb) => {
      cb(message);
    });
  }

  subscribe(subscriber: Callback): Unsub {
    this.subscriptions.push(subscriber);

    return () => {
      this.unsubscribe(subscriber);
    };
  }

  unsubscribe(subscriber: Callback) {
    const idx = this.subscriptions.indexOf(subscriber);

    if (idx > -1) {
      this.subscriptions.splice(idx, 1);
    }
  }

  onConnect() {
    this.connected = true;
  }

  onDisconnect() {
    this.connected = false;
  }
}
