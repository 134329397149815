// @owners { team: patients-team }
import { InputText } from '@alto/design-system';
import React from 'react';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  value: string;
};

export const InsuranceNicknameInput = ({ error, onChange, value }: Props) => {
  return (
    <InputText
      autoCapitalize="words"
      autoCorrect
      error={error}
      id="nickname"
      keyboardType="default"
      label="Nickname"
      onChangeText={onChange}
      placeholder="My Rx Card"
      value={value}
    />
  );
};
