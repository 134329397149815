import { withRehydration } from './withRehydration';
import {
  CREATE_INSURANCE_FAILED,
  CREATE_INSURANCE_LOADING,
  CREATE_INSURANCE_SUCCEEDED,
  CREATE_PHOTO_INSURANCE_FAILED,
  CREATE_PHOTO_INSURANCE_LOADING,
  CREATE_PHOTO_INSURANCE_SUCCEEDED,
  DELETE_INSURANCE_SUCCEEDED,
  DESELECT_INSURANCE,
  FETCH_INSURANCES_FAILED,
  FETCH_INSURANCES_SUCCEEDED,
  type InsurancesActions,
  SELECT_INSURANCE,
  UPDATE_INSURANCE_FAILED,
  UPDATE_INSURANCE_LOADING,
  UPDATE_INSURANCE_SUCCEEDED,
} from '~shared/actions/insurances';
import { type Insurance } from '~shared/types';

export type InsurancesState = {
  createInsuranceSuccess: null | boolean;
  updateInsuranceSuccess: null | boolean;
  createPhotoInsuranceSuccess: null | boolean;
  insurances: Insurance[];
  selectedInsuranceID: null | number;
};

const initialState: InsurancesState = {
  createInsuranceSuccess: null,
  updateInsuranceSuccess: null,
  createPhotoInsuranceSuccess: null,
  insurances: [],
  selectedInsuranceID: null,
};

const insurances = (state: InsurancesState = initialState, action: InsurancesActions): InsurancesState => {
  switch (action.type) {
    case FETCH_INSURANCES_FAILED:
      return { ...state };

    case FETCH_INSURANCES_SUCCEEDED:
      return {
        ...state,
        insurances: action.payload,
      };

    case SELECT_INSURANCE:
      return { ...state, selectedInsuranceID: action.payload.id };

    case DESELECT_INSURANCE:
      return { ...state, selectedInsuranceID: null };

    case CREATE_INSURANCE_LOADING:
      return { ...state, createInsuranceSuccess: null, updateInsuranceSuccess: null };

    case CREATE_INSURANCE_SUCCEEDED:
      return { ...state, createInsuranceSuccess: true, insurances: [...state.insurances, action.payload] };

    case CREATE_INSURANCE_FAILED:
      return { ...state, createInsuranceSuccess: false };

    case UPDATE_INSURANCE_LOADING:
      return { ...state, createInsuranceSuccess: null, updateInsuranceSuccess: null };

    case UPDATE_INSURANCE_SUCCEEDED:
      return {
        ...state,
        insurances: state.insurances.map((insurance) => {
          if (insurance.id === action.payload.id) {
            return action.payload;
          }

          return insurance;
        }),
        updateInsuranceSuccess: true,
      };

    case UPDATE_INSURANCE_FAILED:
      return { ...state, updateInsuranceSuccess: false };

    case DELETE_INSURANCE_SUCCEEDED:
      return {
        ...state,
        insurances: state.insurances.filter((insurance) => {
          return insurance.id !== action.payload.id;
        }),
      };

    case CREATE_PHOTO_INSURANCE_LOADING:
      return { ...state, createPhotoInsuranceSuccess: null };

    case CREATE_PHOTO_INSURANCE_SUCCEEDED:
      return { ...state, createPhotoInsuranceSuccess: true };

    case CREATE_PHOTO_INSURANCE_FAILED:
      return { ...state, createPhotoInsuranceSuccess: false };

    default:
      return state;
  }
};

export default withRehydration(insurances, initialState);
