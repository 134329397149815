import { COLORS } from '@alto/design-library-tokens';
import * as React from 'react';
import { type Insets, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import type { Alignment } from '../../../utils';
import { type PlatformSpecificButtonProps } from '../../buttons/src/ButtonPlatformSpecificProps';

export type TouchableProps = PlatformSpecificButtonProps & {
  readonly accessibilityLabel?: string;
  readonly backgroundColor?: string;
  readonly alignment?: Alignment;
  readonly centerHorizontally?: boolean;
  readonly centerVertically?: boolean;
  readonly children?: React.ReactNode;
  readonly height?: string;
  readonly disabled?: boolean;
  readonly hitSlop?: Insets;
  readonly width?: string;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
  readonly borderRadius?: number;
};

type StyledTouchableOpacityProps = {
  width: TouchableProps['width'];
  height: TouchableProps['height'];
  borderRadius: TouchableProps['borderRadius'];
  alignItems: 'flex-start' | 'flex-end' | 'center' | '';
  justifyContent: 'center' | '';
  backgroundColor: TouchableProps['backgroundColor'];
};

const StyledTouchableOpacity = styled(TouchableOpacity)<StyledTouchableOpacityProps>`
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
`;

const getAlignItems = (
  alignment: TouchableProps['alignment'],
  centerHorizontally: TouchableProps['centerHorizontally'],
) => {
  if (alignment === 'left') {
    return 'flex-start';
  }

  if (alignment === 'right') {
    return 'flex-end';
  }

  if (centerHorizontally || alignment === 'center') {
    return 'center';
  }

  return '';
};

const getJustifyContent = (centerVertically: TouchableProps['centerVertically']) => {
  if (centerVertically) {
    return 'center';
  }

  return '';
};

export const Touchable = ({
  accessibilityLabel,
  backgroundColor = COLORS.PALETTE.GREYSCALE.WHITE,
  centerHorizontally,
  alignment,
  centerVertically,
  children,
  height,
  onPress,
  disabled,
  testID = 'touchable',
  width,
  borderRadius,
  hitSlop,
}: TouchableProps) => (
  <StyledTouchableOpacity
    borderRadius={borderRadius}
    accessibilityLabel={accessibilityLabel}
    accessibilityRole="button"
    alignItems={getAlignItems(alignment, centerHorizontally)}
    backgroundColor={backgroundColor}
    height={height}
    justifyContent={getJustifyContent(centerVertically)}
    onPress={onPress}
    disabled={disabled}
    width={width}
    hitSlop={hitSlop}
    testID={testID}
  >
    {children}
  </StyledTouchableOpacity>
);
