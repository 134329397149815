// @owners { team: patients-team }
import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import { Row } from '@alto/design-system';
import { FSAIndividualReceiptAlert } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, InfoBox, SimpleContainer, SimpleContainerHeader, SimpleContainerSection } from '@alto/pocky';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import styled from 'styled-components';
import { openModal } from '~shared/actions/modal';
import { clearPaymentMethodError, deletePaymentMethod } from '~shared/actions/paymentMethods';
import { queries } from '~shared/queries/query-keys';
import { useGetPaymentMethods } from '~shared/queries/query-keys/paymentMethods';
import { getPaymentBreakdowns } from '~shared/reducers/paymentMethods';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type PaymentMethod } from '~shared/types';
import { AchPaymentMethods } from './AchPaymentMethods';
import CreditCardPayments from './CreditCardPayments';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, Main, PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import { FSAReceiptSection } from '~web/features/payments/components/FSAReceiptSection';

const FormHelper = styled.div`
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  color: ${COLORS.PALETTE.PRIMARY.DEFAULT};
  letter-spacing: 0.49px;
  margin-bottom: 0;
  .errored & {
    color: ${COLORS.PALETTE.DANGER.DEFAULT};
  }
`;

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Payments and Receipts',
  },
];

export const ProfilePaymentMethods = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const loading = useSelectorShared(
    (state) => state.ui.loading.createPaymentMethodLoading || state.ui.loading.deletePaymentMethodLoading,
  );
  const { error: fetchPaymentMethodError, paymentMethods } = useGetPaymentMethods();
  const reduxPaymentMethodError = useSelectorShared((state) => {
    const { createPaymentMethodError, deletePaymentMethodError } = state.ui.errors;
    return createPaymentMethodError || deletePaymentMethodError;
  });
  const paymentMethodError = reduxPaymentMethodError || fetchPaymentMethodError;
  const { achPaymentMethods, creditCardPaymentMethods, nativePaymentMethods } = getPaymentBreakdowns(paymentMethods);

  const applePayPaymentMethods = nativePaymentMethods.filter((pm) => pm.details?.native_pay_type === 'apple_pay');
  const googlePayPaymentMethods = nativePaymentMethods.filter((pm) => pm.details?.native_pay_type === 'google_pay');
  const handleDeletePaymentMethod = async (paymentMethod: PaymentMethod) => {
    const success = await dispatch(deletePaymentMethod(paymentMethod));
    if (success) {
      queryClient.invalidateQueries({ queryKey: queries.paymentMethods.fetchAll._def });
    }
  };

  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexRow evenlySpaceContent>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <PageHeader>Payments and Receipts</PageHeader>
        </FullFlexRow>
        <Main>
          {paymentMethodError ? <InfoBox warning>{paymentMethodError.message}</InfoBox> : null}
          <SimpleContainer>
            <SimpleContainerHeader>Credit Cards</SimpleContainerHeader>
            <CreditCardPayments
              creditCardPaymentMethods={creditCardPaymentMethods}
              deletePaymentMethod={handleDeletePaymentMethod}
            />
            <SimpleContainerSection>
              <Row right>
                <Button
                  kind="secondary"
                  onClick={() => {
                    dispatch(clearPaymentMethodError());
                    dispatch(openModal('CREDIT_CARD_MODAL'));
                  }}
                >
                  Add New Card
                </Button>
              </Row>
            </SimpleContainerSection>
          </SimpleContainer>
          <SimpleContainer>
            <SimpleContainerHeader>Linked Accounts</SimpleContainerHeader>
            <AchPaymentMethods
              paymentMethods={achPaymentMethods}
              deletePaymentMethod={handleDeletePaymentMethod}
            />
            {loading ? <FormHelper>Loading&hellip;</FormHelper> : null}
          </SimpleContainer>
          {applePayPaymentMethods.length > 0 && (
            <SimpleContainer>
              <SimpleContainerHeader>Apple Pay</SimpleContainerHeader>
              <CreditCardPayments
                creditCardPaymentMethods={applePayPaymentMethods}
                deletePaymentMethod={handleDeletePaymentMethod}
              />
            </SimpleContainer>
          )}
          {googlePayPaymentMethods.length > 0 && (
            <SimpleContainer>
              <SimpleContainerHeader>Google Pay</SimpleContainerHeader>
              <CreditCardPayments
                creditCardPaymentMethods={googlePayPaymentMethods}
                deletePaymentMethod={handleDeletePaymentMethod}
              />
            </SimpleContainer>
          )}
          <SimpleContainer>
            <SimpleContainerHeader>Annual Receipts</SimpleContainerHeader>
            <SimpleContainerSection>
              Download itemized receipts for FSA/HSA claims, taxes, or other reimbursement purposes.
            </SimpleContainerSection>
            <FSAReceiptSection />
            <FSAIndividualReceiptAlert />
          </SimpleContainer>
        </Main>
      </ContentWrapper>
    </PageBackground>
  );
};
