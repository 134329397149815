// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, H1, MdSpacing, useScreenSize } from '@alto/design-system';
import { useQueryEssentialsFeeConfigs } from '@alto/features';
import React from 'react';
import styled from 'styled-components';
import { type Shipment } from '~shared/types';
import { useSidePadding } from './useSidePadding';
import { Image, View } from '~web/features/essentials/primitives';

const StyledHeaderContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${SPACING.STATIC.XL.px};
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
  border-bottom: solid ${COLORS.BORDER_COLORS.LIGHT} ${BORDERS.SIZE}px;
  ${({ isSMScreenOrBigger }) =>
    isSMScreenOrBigger &&
    `padding: ${SPACING.STATIC.XXXL.px} ${SPACING.STATIC.XXL.px} ${SPACING.STATIC.XXXL.px} ${SPACING.STATIC.LG.px}; border-bottom: none;`}
`;

type Props = {
  readonly shipment: Shipment | undefined;
  readonly children: React.ReactNode;
};

export const StoreHeaderWrapper = ({ shipment, children }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();
  const sidePadding = useSidePadding();
  const { essentialsFreeDeliveryText } = useQueryEssentialsFeeConfigs();

  return (
    <StyledHeaderContainer
      style={{
        padding: `${!isSMScreenOrBigger ? SPACING.STATIC.XL.px : SPACING.STATIC.XXXL.px} ${SPACING.STATIC.XL.px} ${
          !isSMScreenOrBigger ? SPACING.STATIC.NONE.px : SPACING.STATIC.XXXL.px
        } ${sidePadding} `,
      }}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <View alignItems="center">
        <H1>{shipment ? 'Add ' : 'Shop '}</H1>
        <Image
          src="https://assets.prod.alto.com/otc_categories/essentials_logo.svg"
          alt="add essentials to your delivery"
        />
      </View>
      {shipment ? <H1>to your delivery</H1> : null}
      {!shipment && (
        <>
          <MdSpacing />
          <Body color={COLORS.TEXT_COLORS.GREY}>{essentialsFreeDeliveryText}</Body>
        </>
      )}
      {children}
    </StyledHeaderContainer>
  );
};
