import { useNavigation } from '@alto/navigation';
import { useEffect } from 'react';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

const getOriginName = (flow?: string) => {
  if (getPlatformOS() === 'web') {
    return 'signup';
  }

  if (flow) {
    return flow === 'verify' ? 'verify' : 'signup';
  } else {
    return 'profile';
  }
};

export const useCommunicationPreferencesViewed = () => {
  const { trackPageView } = useAnalytics();
  const { getParam } = useNavigation<'RouteCommunicationPreferences'>();

  useEffect(() => {
    const origin = getOriginName(getParam('flow'));
    trackPageView({
      event: EVENTS.COMMS_PREFERENCE_VIEWED,
      params: { origin },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
