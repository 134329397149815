import { COLORS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Touchable } from '../../containers';
import { Text } from '../../typography';
import { StandaloneCheckbox } from './StandaloneCheckbox';

const StyledCheckboxRow = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: ${SPACING.STATIC.MD.px};
  justify-content: space-between;
`;

const StyledHighlightedBackground = styled(View)<{ value: boolean }>`
  background-color: ${({ value }) =>
    value ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER : COLORS.BACKGROUND_COLORS.WHITE};
`;

const StyledResponsiveText = styled(View)`
  flex: 1;
`;

type CheckboxProps = {
  readonly value: boolean;
  readonly text: string;
  readonly onPress: (value: boolean) => void;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
};

/**
 * @deprecated Use InputCheckbox with InputCheckboxGroup instead. See usage in InputCheckbox.stories.tsx
 */
export const DEPRECATED_Checkbox = ({ value, text = '', onPress, testID = 'checkbox' }: CheckboxProps) => {
  return (
    <Touchable
      onPress={() => {
        onPress(!value);
      }}
      testID={testID}
    >
      <StyledHighlightedBackground value={value}>
        <StyledCheckboxRow>
          <StyledResponsiveText>
            <Text
              tight
              fontFamily="semibold"
            >
              {text}
            </Text>
          </StyledResponsiveText>
          <StandaloneCheckbox value={value} />
        </StyledCheckboxRow>
      </StyledHighlightedBackground>
    </Touchable>
  );
};
