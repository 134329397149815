import { ButtonSelector, Description, type OptionValue, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { InputBlockTextArea } from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps } from 'formik';
import React from 'react';
import { NO_KNOWN_ALLERGIES } from '~shared/constants';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';

const RADIO_YES_VALUE = 'Yes';
const RADIO_NO_VALUE = 'No';

const getAllergiesRadioValue = (allergies: string | null): typeof RADIO_YES_VALUE | typeof RADIO_NO_VALUE | null => {
  if (allergies === NO_KNOWN_ALLERGIES) return RADIO_NO_VALUE;

  if (typeof allergies === 'string') return RADIO_YES_VALUE;

  return null;
};

const AllergiesField = () => (
  <Field name="allergies">
    {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => {
      return (
        <div>
          <Description fontFamily="semibold">Any allergies?</Description>
          <ButtonSelector
            options={[
              { label: RADIO_NO_VALUE, value: RADIO_NO_VALUE },
              { label: RADIO_YES_VALUE, value: RADIO_YES_VALUE },
            ]}
            value={getAllergiesRadioValue(field.value) as OptionValue}
            onChange={() => {
              if (getAllergiesRadioValue(field.value) === RADIO_NO_VALUE) {
                setFieldValue(field.name, '');
              } else {
                setFieldValue(field.name, NO_KNOWN_ALLERGIES);
              }
            }}
          />
          {getAllergiesRadioValue(field.value) === RADIO_YES_VALUE && (
            <>
              <XsSpacing />
              <InputBlockTextArea
                input={{
                  value: field.value,
                }}
                placeholder="e.g. Penicillin, Sulfonamides"
                small
                {...mapFormikFieldToInputProps(field, meta)}
              />
            </>
          )}
        </div>
      );
    }}
  </Field>
);

export default AllergiesField;
