import { AltoIcon, ThirdPartyIcon } from '@alto/design-system';
import React from 'react';
import { Platform } from 'react-native';
import { type PaymentMethodIconName } from '~shared/features/payment-methods/helpers/getPaymentMethodIconName';

type Props = {
  readonly paymentMethodIconName?: PaymentMethodIconName;
  readonly size?: 'default' | 'small';
};

export const PaymentMethodIcon = ({
  paymentMethodIconName,
  size = Platform.OS === 'web' ? 'default' : 'small',
}: Props) => {
  switch (paymentMethodIconName) {
    case 'apple-pay':
      return (
        <ThirdPartyIcon
          name="applepay"
          testID="apple-pay"
        />
      );
    case 'google-pay':
      return (
        <ThirdPartyIcon
          name="googlepay"
          testID="google-pay"
        />
      );
    default:
      return (
        <AltoIcon
          name={size === 'default' ? 'creditcard' : 'creditcard-small'}
          type="grey"
          testID="credit-card"
        />
      );
  }
};
