// @owners { team: patients-team }
import { PAYMENT_METHOD_DATA_TYPES } from '~shared/features/payment-methods/constants';
import { type PaymentMethod } from '~shared/types';

export const getPaymentMethodName = (paymentMethod: PaymentMethod) => {
  const { type, details } = paymentMethod || {};

  let display;
  if (type === PAYMENT_METHOD_DATA_TYPES.STRIPE_DATA) {
    const { card_last_4, card_type } = details || {};
    display = `${card_type || ''} ${card_last_4 || ''}`;
  } else if (type === PAYMENT_METHOD_DATA_TYPES.PLAID_DATA) {
    const { institution_name, account_mask } = details || {};
    display = `${institution_name || ''} ${account_mask || ''}`;
  }

  return display;
};
