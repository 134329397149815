// @owners { team: patients-team }
import styled from 'styled-components';

const TransparentButtonWrapper = styled.button`
  border-color: transparent;
  background: transparent;
  padding: 0;
  // only put focus ring around the inner item, not the wrapper
  &:focus {
    outline: none;
  }
  // don't show focus ring when clicking (inner item is focused when clicking)
  & > *:focus {
    outline: none;
  }
  // show focus ring when tabbing (outer item is focused when tabbing)
  &:focus > * {
    // default browser focus styling
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

export default TransparentButtonWrapper;
