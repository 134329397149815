import { post } from '~shared/helpers/apiHelper';
import { type APIError, type PlaidLinkToken, type ReduxDispatchShared } from '~shared/types';

export const CREATE_PLAID_LINK_TOKEN_LOADING = 'CREATE_PLAID_LINK_TOKEN_LOADING';
export type CreatingPlaidLinkTokenAction = {
  type: typeof CREATE_PLAID_LINK_TOKEN_LOADING;
};
export const creatingPlaidLinkToken = (): CreatingPlaidLinkTokenAction => ({
  type: CREATE_PLAID_LINK_TOKEN_LOADING,
});

export const CREATE_PLAID_LINK_TOKEN_SUCCEEDED = 'CREATE_PLAID_LINK_TOKEN_SUCCEEDED';
export type CreatePlaidLinkTokenSucceedAction = {
  type: typeof CREATE_PLAID_LINK_TOKEN_SUCCEEDED;
  payload: PlaidLinkToken;
};
export const createPlaidLinkTokenSucceeded = (plaidLinkToken: PlaidLinkToken): CreatePlaidLinkTokenSucceedAction => ({
  type: CREATE_PLAID_LINK_TOKEN_SUCCEEDED,
  payload: plaidLinkToken,
});

export const CREATE_PLAID_LINK_TOKEN_FAILED = 'CREATE_PLAID_LINK_TOKEN_FAILED';
export type CreatePlaidLinkTokenFailedAction = {
  type: typeof CREATE_PLAID_LINK_TOKEN_FAILED;
  payload: APIError;
  error: true;
};
export const createPlaidLinkTokenFailed = (error: APIError): CreatePlaidLinkTokenFailedAction => ({
  type: CREATE_PLAID_LINK_TOKEN_FAILED,
  payload: error,
  error: true,
});

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const createPlaidLinkToken = () => {
  return (dispatch: ReduxDispatchShared): Promise<boolean> => {
    dispatch(creatingPlaidLinkToken());
    return post('/plaid/create_link_token').then((json) => {
      if (json.error) {
        dispatch(createPlaidLinkTokenFailed(json.error));
        return false;
      }

      dispatch(createPlaidLinkTokenSucceeded(json));
      return true;
    });
  };
};
