import { type AppStateStatus } from 'react-native';
import { type loadNavigation } from '~shared/actions/navigation';
import { type setPresence } from '~shared/actions/presence';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StatePresence = {
  isConnected: boolean | null;
  appState: AppStateStatus;
  isNavigationReady: boolean;
};

export const initialState: StatePresence = {
  isConnected: null,
  appState: 'unknown',
  isNavigationReady: false,
};

type Actions = ReturnType<typeof setPresence> | ReturnType<typeof loadNavigation>;

const presence = (state: StatePresence = initialState, action: Actions): StatePresence => {
  switch (action.type) {
    case 'SET_PRESENCE':
      return { ...state, ...action.payload };

    case 'LOAD_NAVIGATION':
      return { ...state, isNavigationReady: true };

    default:
      return state;
  }
};

export default withRehydration(presence, initialState);
