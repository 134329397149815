// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoSpinningLoader,
  Body,
  Button,
  Card,
  Column,
  H1,
  Illustration,
  LgSpacing,
  ListDescription,
  ListItem,
  MdSpacing,
  PresentationListItem,
  SecondaryPage,
  SmSpacing,
  XlPadding,
  XsPadding,
  XsSpacing,
  useScreenSize,
} from '@alto/design-system';
import { MedicationImage } from '@alto/features';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { formatDateWithoutDowWithoutYear, formatRelativeDateWithDow, normalizeDateString } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
// eslint-disable-next-line import/no-deprecated
import { useFetchLatestMedSyncPlan } from '~shared/queries/useFetchLatestMedSyncPlan';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import delivery from '~web/images/deliveryNoBackground.png';
import verifiedCheckmark from '~web/images/verifiedCheckmark@3x.png';

export const MedSyncSelfServiceConfirmedPage = () => {
  const dispatch = useDispatchShared();
  const currentUserID = useSelectorShared(getCurrentUserID);
  // eslint-disable-next-line import/no-deprecated
  const { data, isLoading: isFetchLatestMedSyncPlanLoading } = useFetchLatestMedSyncPlan(currentUserID);
  const { trackPageView } = useAnalytics();

  const plan = data?.data;
  const proposedShipments = plan?.proposed_shipments;
  const nextShipment = proposedShipments?.[0];
  const normalizedDate = nextShipment?.date
    ? formatDateWithoutDowWithoutYear(normalizeDateString(nextShipment.date) || '')
    : '';
  const normalizedDateWithDow = nextShipment?.date
    ? formatRelativeDateWithDow(normalizeDateString(nextShipment.date) || '')
    : '';

  useEffect(() => {
    trackPageView({ event: EVENTS.MED_SYNC_PLAN_CONFIRMED_VIEWED });
  }, [trackPageView]);

  const handleDismiss = () => {
    dispatch(push('/home'));
  };

  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      HeaderContent={
        <XlPadding
          topPadding={SPACING.STATIC.NONE}
          leftPadding={!isMDScreenOrBigger ? SPACING.STATIC.XL : SPACING.STATIC.NONE}
          rightPadding={!isMDScreenOrBigger ? SPACING.STATIC.XL : SPACING.STATIC.NONE}
        >
          <Column centerHorizontally>
            <LgSpacing />
            <Illustration
              size="lg"
              source={verifiedCheckmark}
              accessibilityLabel={IMG_ALT_TEXTS.verifiedCheckmark}
            />
            <SmSpacing />
            <H1 center>Bundle plan confirmed!</H1>
            <XsSpacing />
            <Body center>
              We'll reach out before your first delivery on
              <Body fontFamily="semibold">{` ${normalizedDate} `}</Body>
              to confirm delivery details.
            </Body>
          </Column>
        </XlPadding>
      }
    >
      {isFetchLatestMedSyncPlanLoading ? (
        <AltoSpinningLoader />
      ) : (
        <>
          <Card
            title="Upcoming Delivery"
            illustrationSrc={delivery}
            illustrationAccessibilityLabel={IMG_ALT_TEXTS.delivery}
          >
            <PresentationListItem
              iconName="calendar"
              text={normalizedDateWithDow}
              backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
            />
            <XsPadding>
              {nextShipment?.proposed_deliveries?.map((proposedDelivery) => (
                <ListItem
                  backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
                  key={proposedDelivery.medication_name}
                  title={proposedDelivery.medication_name}
                  descriptions={[
                    <ListDescription
                      key="day supply"
                      iconName="pills-small"
                    >{`${proposedDelivery.days_supply} day supply`}</ListDescription>,
                  ]}
                  LeftContent={<MedicationImage imageUrl={proposedDelivery.product_image_url} />}
                />
              ))}
            </XsPadding>
          </Card>
          <MdSpacing />
          <Button
            onPress={handleDismiss}
            label="Return home"
          />
        </>
      )}
    </SecondaryPage>
  );
};
