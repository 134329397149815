import * as React from 'react';
import { AltoIcon } from '../../icon';
import { type Tag } from '../../tags';
import { type ListDescription } from './ListDescription';
import type { ListItemLeftContentProps } from './ListItem';
import { ListItem } from './ListItem';

export type ListNavigationProps = {
  readonly text: string;
  readonly descriptions?: React.ReactElement<typeof ListDescription> | React.ReactElement<typeof ListDescription>[];
  readonly onPress?: () => void;
  readonly tag?: React.ReactElement<typeof Tag>;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly LeftContent?: ListItemLeftContentProps;
};

export const ListNavigation = ({
  text,
  descriptions,
  onPress,
  tag,
  loading,
  disabled,
  LeftContent,
}: ListNavigationProps) => {
  return (
    <ListItem
      title={text}
      loading={loading}
      disabled={disabled}
      descriptions={descriptions}
      onPress={onPress}
      RightContent={<AltoIcon name="chevronright-small" />}
      tags={tag}
      LeftContent={LeftContent}
    />
  );
};
