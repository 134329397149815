// @owners { team: patients-team }
import { useQuery } from '~shared/react-query';
import { essentials as essentialsQueries } from './queries';

type QueryParams = {
  addressID?: number;
  shipmentID?: number | null;
  enabled?: boolean;
};

export const useQueryTherapeuticAreaUpsell = ({ addressID, shipmentID, enabled = false }: QueryParams) => {
  const query = useQuery({
    ...essentialsQueries.therapeuticAreaUpsell({ address_id: addressID, shipment_id: shipmentID }),
    refetchOnMount: false,
    enabled,
  });

  return {
    isSuccess: query.isSuccess,
    isPending: query.isPending,
    error: query.error,
    isError: query.isError,
    showUpsell: query.data?.data?.show_upsell,
    products: query.data?.data?.upsell?.add_on_otcs,
    headerImageURL: query.data?.data?.upsell?.header_image_url,
    headerTitle: query.data?.data?.upsell?.header_title,
    headerSubtitle: query.data?.data?.upsell?.header_subtitle,
    upsellType: query.data?.data?.upsell?.upsell_type,
  };
};
