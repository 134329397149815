// @owners { team: patients-team }
import { useNavigation } from '@alto/navigation';
import { Platform } from 'react-native';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useSelectorShared } from '~shared/store';

export const useShipmentID = () => {
  const routeState = useSelectorShared(getRouteState);
  const { getParam } = useNavigation();

  const editOrderShipmentID = useSelectorShared(getEditShipmentID);

  // get shipment ID from the current route or from the cart state `editOrderShipmentID`
  const shipmentID =
    Platform.OS === 'web'
      ? Number(routeState?.shipmentID)
      : // @ts-expect-error this is not a good practice since we can't verify either of these are present
        Number(getParam?.('shipmentID', editOrderShipmentID));
  return { shipmentID };
};
