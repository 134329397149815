// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Description } from '@alto/design-system';
import React from 'react';
import { getPrescriptionNameSentenceCaseByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly prescriptionIds: number[];
};

export const BackorderedWarning = ({ prescriptionIds }: Props) => {
  const prescriptionNames = useSelectorShared((state) => getPrescriptionNameSentenceCaseByIDs(state, prescriptionIds));
  if (!prescriptionIds.length) return null;
  const pronoun = prescriptionIds.length > 1 ? 'them' : 'it';
  const plural = prescriptionIds.length > 1 ? 'are' : 'is';
  const message = `${prescriptionNames} ${plural} out of stock. Please remove ${pronoun} from your cart to continue ordering.`;

  return (
    <Description
      center
      color={COLORS.TEXT_COLORS.DANGER}
    >
      {message}
    </Description>
  );
};
