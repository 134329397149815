// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getProgynyWizardEligiblePrescriptions } from './getProgynyWizardEligiblePrescriptions';
import { type EligiblePrescription } from '~shared/reducers/ui/progynyWizard';
import { type ReduxStateShared } from '~shared/types';

export const getEligiblePrescriptionIDs: (state: ReduxStateShared) => number[] = createSelector(
  [getProgynyWizardEligiblePrescriptions],
  (eligiblePrescriptions: EligiblePrescription[] | null | undefined) => {
    return eligiblePrescriptions?.map((rxMed) => rxMed.prescriptionID) || [];
  },
);
