// @owners { team: patients-team }
import { type Dispatch } from 'redux';
import { get, post } from '~shared/helpers/apiHelper';
import { type ReduxDispatchShared, type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const SET_TEMP_PASSWORD = 'SET_TEMP_PASSWORD';

export type ActionSetTempPassword = {
  type: typeof SET_TEMP_PASSWORD;
  payload: string;
};

export function setTempPassword(tempPassword: string): ActionSetTempPassword {
  return {
    type: SET_TEMP_PASSWORD,
    payload: tempPassword,
  };
}

export const SET_INSURANCE_ID = 'SET_INSURANCE_ID';

export type ActionSetInsuranceID = {
  type: typeof SET_INSURANCE_ID;
  payload: number;
};

export function setInsuranceID(insuranceID: number): ActionSetInsuranceID {
  return {
    type: SET_INSURANCE_ID,
    payload: insuranceID,
  };
}

export const SET_EDITING_INSURANCE_ID = 'SET_EDITING_INSURANCE_ID';

export type ActionSetEditingInsuranceID = {
  type: typeof SET_EDITING_INSURANCE_ID;
  payload: number | null;
};

export function setEditingInsuranceID(insuranceID: number | null): ActionSetEditingInsuranceID {
  return {
    type: SET_EDITING_INSURANCE_ID,
    payload: insuranceID,
  };
}

export const SET_INITIAL_ONBOARD_STATE = 'SET_INITIAL_ONBOARD_STATE';

export type ActionSetInitialOnboardState = {
  type: typeof SET_INITIAL_ONBOARD_STATE;
  payload: string;
};

export function setInitialOnboardState(initialOnboardState: string): ActionSetInitialOnboardState {
  return {
    type: SET_INITIAL_ONBOARD_STATE,
    payload: initialOnboardState,
  };
}

export const SEEN_INSURANCE_LOADING = 'SEEN_INSURANCE_LOADING';

export type ActionSeenInsuranceLoading = {
  type: typeof SEEN_INSURANCE_LOADING;
};

export function setInsuranceLoadingSeen(): ActionSeenInsuranceLoading {
  return {
    type: SEEN_INSURANCE_LOADING,
  };
}

export const CLEAR_ONBOARDING_STATE = 'CLEAR_ONBOARDING_STATE';

export type ActionClearOnboardingState = {
  type: typeof CLEAR_ONBOARDING_STATE;
};

export function clearOnboardingState(): ActionClearOnboardingState {
  return {
    type: CLEAR_ONBOARDING_STATE,
  };
}

export const UPDATE_ONBOARDING_USER_LOADING = 'UPDATE_ONBOARDING_USER_LOADING';

export function updatingOnboardingUser() {
  return {
    type: UPDATE_ONBOARDING_USER_LOADING,
  };
}

export const UPDATE_ONBOARDING_USER_SUCCEEDED = 'UPDATE_ONBOARDING_USER_SUCCEEDED';

export type UpdateOnboardingUserSucceeded = {
  type: typeof UPDATE_ONBOARDING_USER_SUCCEEDED;
  payload: User;
};

export function updateOnboardingUserSucceeded(user: User): UpdateOnboardingUserSucceeded {
  return {
    type: UPDATE_ONBOARDING_USER_SUCCEEDED,
    payload: user,
  };
}

export const UPDATE_ONBOARDING_USER_FAILED = 'UPDATE_ONBOARDING_USER_FAILED';

export function updateOnboardingUserFailed(error: APIError) {
  return {
    type: UPDATE_ONBOARDING_USER_FAILED,
    payload: error,
    error: true,
  };
}

export type OnboardingUserUpdate = {
  onboard_state?: string;
  email?: User['email'];
  password?: string;
  phone?: User['phone'];
  allergies?: User['allergies'];
  medical_conditions?: User['medical_conditions'];
  onboarding_other_scripts?: User['onboarding_other_scripts'];
  cash_pay_only?: User['cash_pay_only'];
  user_phi_auth?: Record<string, any>;
  other_scripts_list?: string;
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const updateOnboardingUser = (userUpdate: OnboardingUserUpdate) => {
  return (dispatch: ReduxDispatchShared) => {
    dispatch(updatingOnboardingUser());
    return post('/users/onboarding_update', {
      user: userUpdate,
    }).then((json) => {
      if (json.error) {
        dispatch(updateOnboardingUserFailed(json.error));
        return false;
      }

      dispatch(updateOnboardingUserSucceeded(json));
      return true;
    });
  };
};

export const SET_SUCCESS_MESSAGE_TYPE = 'SET_SUCCESS_MESSAGE_TYPE';

export type ActionSetSuccessMessageType = {
  type: typeof SET_SUCCESS_MESSAGE_TYPE;
  payload: string | null | undefined;
};

export function setSuccessMessageType(messageType: string | null | undefined): ActionSetSuccessMessageType {
  return {
    type: SET_SUCCESS_MESSAGE_TYPE,
    payload: messageType,
  };
}

export const FETCH_ONBOARD_STATE_LOADING = 'FETCH_ONBOARD_STATE_LOADING';

export function fetchingOnboardState() {
  return {
    type: FETCH_ONBOARD_STATE_LOADING,
  };
}

export const FETCH_ONBOARD_STATE_FAILED = 'FETCH_ONBOARD_STATE_FAILED';

export function fetchOnboardStateFailed(error: Error) {
  return {
    type: FETCH_ONBOARD_STATE_FAILED,
    payload: error,
  };
}

export const FETCH_ONBOARD_STATE_SUCCEEDED = 'FETCH_ONBOARD_STATE_SUCCEEDED';

export function fetchOnboardStateSucceeded(onboardState: string) {
  return {
    type: FETCH_ONBOARD_STATE_SUCCEEDED,
    payload: onboardState,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchOnboardState(tempPassword: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchingOnboardState());
    return get('/users/onboard_state', {
      temp_password: tempPassword,
    }).then((json) => {
      if (json.error) {
        dispatch(fetchOnboardStateFailed(json.error));
        return json;
      }

      dispatch(fetchOnboardStateSucceeded(json.onboard_state));
      return json;
    });
  };
}

export const FETCH_WELCOME_TRACKER_LOADING = 'FETCH_WELCOME_TRACKER_LOADING';

export function fetchingWelcomeTrackerState() {
  return {
    type: FETCH_WELCOME_TRACKER_LOADING,
  };
}

export const FETCH_WELCOME_TRACKER_FAILED = 'FETCH_WELCOME_TRACKER_FAILED';

export function fetchWelcomeTrackerStateFailed(error: Error) {
  return {
    type: FETCH_WELCOME_TRACKER_FAILED,
    payload: error,
  };
}

export const FETCH_WELCOME_TRACKER_SUCCEEDED = 'FETCH_WELCOME_TRACKER_SUCCEEDED';

export function fetchWelcomeTrackerStateSucceeded(providerName: string, tempPassword: string) {
  return {
    type: FETCH_WELCOME_TRACKER_SUCCEEDED,
    payload: {
      providerNameShown: !!providerName,
      tempPassword,
    },
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchWelcomeTrackerState(tempPassword: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchingWelcomeTrackerState());
    return get('/users/welcome_tracker', {
      temp_password: tempPassword,
    }).then((json) => {
      if (json.error) {
        dispatch(fetchWelcomeTrackerStateFailed(json.error));
        return json;
      }

      dispatch(fetchWelcomeTrackerStateSucceeded(json.provider_name, tempPassword));
      return json;
    });
  };
}
