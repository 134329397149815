import { Experimentation } from '@alto/experimentation';
import { isEmpty } from 'lodash';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import {
  EXPENSIVE,
  HAS_CONTROLS,
  MAIL_ONLY_ZONE,
  NO_ADDRESS_SELECTED,
  NO_SAFE_PLACE,
  OUT_OF_ZONE,
  PRESENCE_NOT_REQUIRED,
} from '~shared/features/checkout/constants';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { type AddressInfoKey } from '~shared/features/checkout/types';
import { getPrescriptionsByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { isControl, isExpensive } from '~shared/helpers/helper';
import { useSelectorShared } from '~shared/store';
import { useOrderBundlingOptionsCurrentRoute } from '../../order-bundling';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useCheckoutAddressInfoKeys = () => {
  const address = useSelectorShared(getOrderAddress);
  const { prescriptions } = usePrescriptionsInCart();
  const isOrderBundling = useSelectorShared(getIsOrderBundling);
  const { bundledPrescriptionIDs } = useOrderBundlingOptionsCurrentRoute();
  const bundledPrescriptions = useSelectorShared((state) => getPrescriptionsByIDs(state, bundledPrescriptionIDs));
  const combinedPrescriptions = isOrderBundling ? bundledPrescriptions : prescriptions;
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');

  const keys: Partial<Record<AddressInfoKey, boolean>> = {};
  // Keys specific to address
  if (!address) {
    keys[NO_ADDRESS_SELECTED] = true;
  } else {
    if (address.safe_place !== true) {
      keys[NO_SAFE_PLACE] = true;
    }

    if (!address.in_courier_zone) {
      if (address.in_mail_zone) {
        keys[MAIL_ONLY_ZONE] = true;
      } else {
        keys[OUT_OF_ZONE] = true;
      }
    }
  }

  // Keys specific to prescriptions
  if (combinedPrescriptions?.some?.(isControl)) {
    keys[HAS_CONTROLS] = true;
  }

  if (isExpensive(combinedPrescriptions, useNewThreshold)) {
    keys[EXPENSIVE] = true;
  }

  if (isEmpty(keys)) {
    keys[PRESENCE_NOT_REQUIRED] = true;
  }

  const isOutOfZone = !!keys[OUT_OF_ZONE];
  const homeToSignConfirmationRequired = keys[EXPENSIVE] || keys[HAS_CONTROLS] || false;
  const deliveryWindowRequired = !keys[OUT_OF_ZONE] && !keys[MAIL_ONLY_ZONE];

  return {
    keys,
    isOutOfZone,
    homeToSignConfirmationRequired,
    deliveryWindowRequired,
  };
};
