// @owners { team: patients-team }
import { Description, InlineAlert, MdSpacing } from '@alto/design-system';
import React from 'react';
import { getRemovingDeliveryWillAddEssentialsFee } from '~shared/features/essentials/selectors/getRemovingDeliveryWillAddEssentialsFee';
import { useSelectorShared } from '~shared/store';
import { type DeliveryWithPrescription } from '~shared/types';
import { useQueryEssentialsFeeConfigs } from '../../essentials';

export type RemovalWillAddEssentialsFeeWarningProps = {
  readonly delivery: DeliveryWithPrescription;
};

export const RemovalWillAddEssentialsFeeWarning = ({ delivery }: RemovalWillAddEssentialsFeeWarningProps) => {
  const { essentialsDeliveryFee, essentialsFreeDeliveryMinimumBasket } = useQueryEssentialsFeeConfigs();
  const minimumFreeDeliveryThreshold = essentialsFreeDeliveryMinimumBasket?.value || 0;
  const removalWillAddEssentialsFee = useSelectorShared((state) =>
    getRemovingDeliveryWillAddEssentialsFee(state, {
      deliveryID: delivery.id,
      shipmentID: delivery.shipment_id,
      minimumFreeDeliveryThreshold,
    }),
  );

  if (!removalWillAddEssentialsFee) return null;

  return (
    <>
      <InlineAlert type="warning">
        <Description>
          A {essentialsDeliveryFee?.display_value} delivery fee applies to Essentials orders with subtotals under{' '}
          {essentialsFreeDeliveryMinimumBasket?.display_value}.
        </Description>
      </InlineAlert>
      <MdSpacing />
    </>
  );
};
