// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, LgPadding, ListItem, MdSpacing, Tag, XxsSpacing } from '@alto/design-system';
import { MedicationImage } from '@alto/features';
import { type OmittedPrescription } from '@alto/scriptdash/alto/med_sync/types/patients/v1/omitted_prescription';
import React from 'react';
import { SectionHeader } from '~web/components/SectionHeader';

type Props = {
  readonly omittedPrescriptions: OmittedPrescription[];
};

export const MedSyncSelfServiceOmittedPrescriptions = ({ omittedPrescriptions }: Props) => {
  return (
    <>
      <LgPadding
        topPadding={SPACING.STATIC.MD}
        bottomPadding={SPACING.STATIC.MD}
      >
        <SectionHeader color={COLORS.TEXT_COLORS.PRIMARY}>Not included in bundle</SectionHeader>
        <XxsSpacing />
        <Body>Bundling guidelines prevented the following from being added to your plan today</Body>
      </LgPadding>
      {omittedPrescriptions.map((omittedPrescription) => (
        <ListItem
          key={omittedPrescription.medication_name}
          title={omittedPrescription.medication_name}
          descriptions={[
            <Tag
              label="AUTO-BUNDLE UNAVAILABLE"
              key="AUTO-BUNDLE UNAVAILABLE"
            />,
          ]}
          LeftContent={<MedicationImage imageUrl={omittedPrescription?.product_image_url} />}
        />
      ))}
      <MdSpacing />
    </>
  );
};
