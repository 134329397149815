import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { TouchableHighlight, View } from 'react-native';
import styled from 'styled-components/native';
import { LgPadding, Row } from '../../containers';
import { AltoIcon } from '../../icon';
import { HighlightText } from '../../inputs/src/InputAutocomplete/HighlightText';
import { type ApiResponseRow } from '../../inputs/src/InputAutocomplete/types';
import { Description } from '../../typography';

const flexStyle = {
  style: {
    flex: 1,
  },
};

const LeftIconAndTextContainer = styled(View)`
  flex-direction: column;
  max-width: 85%;
`;

const GreyTouchable = styled(TouchableHighlight).attrs({
  activeOpacity: 1,
  underlayColor: COLORS.BACKGROUND_COLORS.GREY_LIGHTER,
})``;

const Container = styled(GreyTouchable).attrs(flexStyle)<{ hasSubtitle?: boolean }>`
  justify-content: center;
`;

type RowProps = ApiResponseRow<string> & {
  readonly textToHighlight?: string;
  readonly onPress?: () => void;
  readonly onRemove?: () => void;
};

export const AutocompleteResultsRow = ({ title, subtitle, onPress, onRemove, textToHighlight }: RowProps) => {
  return (
    <LgPadding
      topPadding={SPACING.STATIC.SM}
      bottomPadding={SPACING.STATIC.SM}
    >
      <Container
        onPress={onPress}
        hasSubtitle={!!subtitle}
      >
        <Row spaceBetween>
          <LeftIconAndTextContainer>
            <Row>
              <HighlightText
                fullText={title}
                textToHighlight={textToHighlight}
              />
            </Row>
            <Row>{subtitle ? <Description>{subtitle}</Description> : null}</Row>
          </LeftIconAndTextContainer>
          {onRemove ? (
            <Container
              onPress={onRemove}
              accessibilityLabel={`remove ${title} from selected items`}
              accessibilityRole="button"
            >
              <AltoIcon name="cancel" />
            </Container>
          ) : (
            <Container
              onPress={onPress}
              accessibilityLabel={`add ${title} to selected items`}
              accessibilityRole="button"
            >
              <AltoIcon name="pluscircle" />
            </Container>
          )}
        </Row>
      </Container>
    </LgPadding>
  );
};
