// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import { Body, Description, XsSpacing } from '@alto/design-system';
import { useAddOnsContext } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { EssentialTag } from './EssentialsTag';
import { FSABadge } from './FsaBadge';
import { StoreProductTag } from './StoreProductTag';
import { Image, StyledTouchable, View } from '~web/features/essentials/primitives';

type Props = {
  readonly count: number;
  readonly product: AddOnOtc;
  readonly index: number;
  readonly categoryName: string;
  readonly searchQuery?: string;
  readonly isStoreItem?: boolean;
};

const StyledScrollViewListItem = styled.li<{ count: number; children: React.ReactNode }>`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: ${({ count }) => `0 0 calc(100% / ${count})`};
  max-width: ${({ count }) => `calc(100% / ${count})`};
  border-radius: ${BORDERS.RADIUS.LG.px};
  padding: ${SPACING.STATIC.SM.px};
  &:hover {
    background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER};
  }
  ${StyledTouchable}
`;

export const ProductItem = ({ count, product, categoryName, index, searchQuery, isStoreItem }: Props) => {
  const dispatch = useDispatchShared();
  const { shipmentID, origin } = useAddOnsContext();
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));

  const onPress = () => {
    let search = `?origin=${origin}`;
    if (shipmentID) {
      search += `&order=${shipmentID}`;
    }
    dispatch(
      push({
        pathname: `/store/${product.product_id}`,
        search,
        state: {
          analyticsProps: {
            slot: index + 1,
            sku: product.sku,
            sku_type: product.sku_type,
            otc_name: product.name,
            category: categoryName,
            query_string: searchQuery,
            shipment_id: shipmentID,
            facility_id: address?.dispensing_facility_id,
            origin,
          },
        },
      }),
    );
  };

  return (
    <StyledScrollViewListItem
      onClick={onPress}
      count={count}
      key={`product-item-for-${product.sku}`}
    >
      <View
        position="sticky"
        justifyContent="center"
      >
        <Image
          src={product.image_urls[0]}
          width={SIZES.ILLUSTRATION.LG.px}
          height={SIZES.ILLUSTRATION.LG.px}
          alt={`icon for ${product.name}`}
        />
        <StoreProductTag
          product={product}
          shipmentID={shipmentID}
        />
      </View>
      {isStoreItem ? <FSABadge addOnOtc={product} /> : <EssentialTag addOnOtc={product} />}
      <Body
        fontFamily="semibold"
        numberOfLines={3}
        color={COLORS.TEXT_COLORS.PRIMARY}
      >
        {product.name}
      </Body>
      <View flexGrow={1} />
      <XsSpacing />
      <Description color={COLORS.TEXT_COLORS.GREY}>{product.quantity_description || ''}</Description>
      <XsSpacing />
      <Description color={COLORS.TEXT_COLORS.PRIMARY}>{`$${Number(product.price).toFixed(2)}`}</Description>
    </StyledScrollViewListItem>
  );
};
