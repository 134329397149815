// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React, { useContext } from 'react';
import { getFAQDetails } from './utils';

export type FAQTopic = 'carry_medication' | 'insurance_covered' | 'medication_cost' | 'transfer' | 'delivery';

export const FAQActionSheet = ({ topic }: { readonly topic: FAQTopic }) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { title, description } = getFAQDetails(topic);

  return (
    <ActionSheetV2
      title={title}
      analyticsName={`onboarding FAQ action sheet ${topic}`}
      handleClose={closeActionSheet}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>{description}</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
