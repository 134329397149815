// @owners { team: patients-team }
import { type ShipmentFeeType } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import { type FeeConfig } from '@alto/scriptdash/alto/patient_app/scheduling/types/v1/fee_config';
import { memoize } from 'lodash';
import { staleTimes } from './constants';
import { queries } from './query-keys';
import { useQuery } from '~shared/react-query';

type GetShipmentFeeConfigHashParams = {
  shipmentFees: FeeConfig[] | null | undefined;
  dataUpdatedAt: number;
};

const getShipmentFeeConfigHash = memoize(
  ({ shipmentFees }: GetShipmentFeeConfigHashParams) =>
    (shipmentFees ?? []).reduce<Record<ShipmentFeeType, FeeConfig | null>>(
      (hash, config) => {
        hash[config.shipment_fee_type] = config;
        return hash;
      },
      {
        asap_delivery: null,
        on_demand_delivery: null,
        essentials_only_delivery: null,
        same_day_delivery: null,
        next_day_delivery: null,
        standard_delivery: null,
      },
    ),
  ({ dataUpdatedAt }) => dataUpdatedAt,
);

export const useQueryShipmentFeesConfig = () => {
  const { data, error, isError, isPending, isSuccess, dataUpdatedAt } = useQuery({
    ...queries.shipmentFees.configs(),
    refetchOnMount: false,
    staleTime: staleTimes.fifteenMinutes,
  });
  const shipmentFeeConfigHash = getShipmentFeeConfigHash({ shipmentFees: data?.data, dataUpdatedAt });

  return { error, isError, isPending, isSuccess, shipmentFeeConfigHash };
};
