// @owners { team: patients-team }
import { closeModal } from '~shared/actions/modal';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import getFacilityOptions from '~shared/selectors/getFacilityOptions';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { PharmacyInfoModal } from '~web/features/profile/components/PharmacyInfoModal';

const mapStateToProps = (state: ReduxStateShared) => {
  return {
    showPharmacyInfoModal: getIsModalShowing(state) && getModalType(state) === 'PHARMACY_INFO_MODAL',
    facilityOptions: getFacilityOptions(state),
    fetchFacilitiesLoading: getLoading(state).fetchFacilitiesLoading,
    fetchFacilitiesError: getErrors(state).fetchFacilitiesError,
  };
};

const mapDispatchToProps = {
  closePharmacyInfoModal: () => closeModal('PHARMACY_INFO_MODAL'),
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyInfoModal);
