import { TYPOGRAPHY } from '@alto/design-library-tokens';
import { Body, type BodyProps, useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';

export const SectionHeader = ({ color, children }: Pick<BodyProps, 'color' | 'children'>) => {
  const { isSMScreenOrBigger, isMDScreenOrBigger } = useScreenSize();
  return (
    <StyledSectionHeader
      color={color}
      isSMScreenOrBigger={isSMScreenOrBigger}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      {children}
    </StyledSectionHeader>
  );
};

const StyledSectionHeader = styled(Body).attrs({
  semibold: true,
})<{ isSMScreenOrBigger: boolean; isMDScreenOrBigger: boolean; children?: React.ReactNode }>`
  font-size: ${TYPOGRAPHY.TEXT.HEADER.SM.px};
  line-height: 24px;
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.SM.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `line-height: 32px;`}
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `font-size: ${TYPOGRAPHY.TEXT.HEADER.MD.px};`}
`;
