// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Description, H3, InlineAlert, KeyboardAvoidingScrollView, LgPadding } from '@alto/design-system';
import { type RouteParams, useNavigation } from '@alto/navigation';
import React from 'react';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useSelectorShared } from '~shared/store';
import { ReplyFormContent } from './ReplyFormContent';

type ReplyFormWebRouteParams = { readonly params: RouteParams['RouteAssistantSupportCaseReply'] };

const isWeb = getPlatformOS() === 'web';

export const ReplyForm = ({ params: webRouteParams }: ReplyFormWebRouteParams) => {
  const { getParam } = useNavigation<'RouteAssistantSupportCaseReply'>();
  const patientID = useSelectorShared(getCurrentUserID);
  const unparsedSupportCaseID = (isWeb ? webRouteParams.supportCaseID : getParam('supportCaseID')) ?? 0;
  const supportCaseID = Number(unparsedSupportCaseID);

  if (!patientID || supportCaseID === 0) {
    return (
      <LgPadding>
        <InlineAlert type="warning">
          <H3>Something went wrong</H3>
          <Description>There's been an error with the Reply Form. Please try again.</Description>
        </InlineAlert>
      </LgPadding>
    );
  }

  return (
    <KeyboardAvoidingScrollView backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}>
      <LgPadding>
        <ReplyFormContent supportCaseID={supportCaseID} />
      </LgPadding>
    </KeyboardAvoidingScrollView>
  );
};
