import React from 'react';
// eslint-disable-next-line @alto/no-styled-components-import
import styled, { keyframes } from 'styled-components';

type Props = {
  readonly children: React.ReactNode;
};

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const InfiniteRotate = styled.div`
  animation: ${rotate} 1s linear infinite;
`;

export const AltoSpinner = ({ children }: Props) => <InfiniteRotate role="spinbutton">{children}</InfiniteRotate>;
