import { type ActionSetWelcomeCarouselIndex, SET_WELCOME_CAROUSEL_INDEX } from '~shared/actions/ui/welcome';
import { withRehydration } from '~shared/reducers/withRehydration';

type Action = ActionSetWelcomeCarouselIndex;

export type StateUiWelcome = {
  welcomeCarouselIndex: number;
};

export const initialState: StateUiWelcome = {
  welcomeCarouselIndex: 0,
};

const welcome = (state = initialState, action: Action): StateUiWelcome => {
  switch (action.type) {
    case SET_WELCOME_CAROUSEL_INDEX: {
      const { payload } = action;

      return { ...state, welcomeCarouselIndex: payload };
    }

    default:
      return state;
  }
};

export default withRehydration(welcome, initialState);
