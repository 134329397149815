export function obscureEmail(email: string): string {
  const replaceText = '••••••••••';

  if (email.length <= 2) {
    // nothing to obscure
    return email;
  }

  const parts = email.split('@');

  if (parts.length === 1) {
    // obscure the whole thing if it's not an email i guess
    return `${email[0]}${replaceText}${email.slice(-1)}`;
  }

  if (parts[0].length <= 2) {
    // nothing to obscure
    return email;
  }

  return `${parts[0][0]}${replaceText}${parts[0].slice(-1)}@${parts.slice(1).join('@')}`;
}

export function obscurePhone(phone: string): string {
  if (phone.length <= 4) {
    return phone;
  }

  return phone.slice(-4);
}
