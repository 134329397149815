// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ListPage, Row, Separator, Tag, XlSpacing, XxsSpacing, isSMScreenOrBigger } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { type ViewToken } from 'react-native';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useFetchAllNotifications } from '../../alto-assistant';
import NotificationImage from '../images/Bell.png';
import { useReadAllCurrentlyUnread } from '../queries/useReadAllCurrentlyUnread';
import { NotificationCenterListItem } from './NotificationCenterListItem';
import { NotificationLoader } from './NotificationLoader';

const viewabilityConfig = {
  minimumViewTime: 500,
  itemVisiblePercentThreshold: 90,
};

const isDesktopWeb = getPlatformOS() === 'web' && isSMScreenOrBigger();

const NotificationCenterEmptyState = () => {
  return (
    <ListPage.Content.EmptyState
      description="Important updates about your medications will appear here."
      image={NotificationImage}
      title="No notifications yet"
    />
  );
};

export const NotificationCenter = () => {
  const { canGoBack, goBack, getParam, navigate } = useNavigation<'RouteNotificationCenter'>();
  const { trackEvent, trackPageView } = useAnalytics();
  const origin = getParam('origin') || '';
  const { readAllCurrentlyUnread } = useReadAllCurrentlyUnread();
  const { data, fetchNextPage, hasNextPage, isSuccess, isPending, isFetching, unreadNotificationsCount } =
    useFetchAllNotifications();

  const handleFetchMore = useCallback(async () => {
    if (hasNextPage && !isPending && !isFetching) {
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isPending, isFetching]);

  useEffect(() => {
    if (!isSuccess) return;

    trackPageView({
      event: EVENTS.NOTIFICATION_CENTER_VIEWED,
      params: {
        origin,
        unreadNotificationsCount,
      },
    });
    // Exclude unreadNotificationsCount because it defaults to 0 while fetching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, trackPageView, origin]);

  useEffect(() => {
    if (!unreadNotificationsCount) return;
    // marking all as read when the user navigates from notification center
    return () => {
      readAllCurrentlyUnread({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotificationsCount]);

  const onViewableItemsChanged = useMemo(
    () =>
      debounce((info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
        const unreadViewableIDs = info.viewableItems
          .filter((item) => !item.item.read_at)
          .map((item) => item.item.wundercom_message_id);
        if (unreadViewableIDs.length) {
          trackEvent({
            event: EVENTS.NOTIFICATION_VIEWED,
            params: {
              wundercom_message_ids: unreadViewableIDs,
            },
          });
        }
      }, 500),
    [trackEvent],
  );

  if (isPending) {
    return <NotificationLoader />;
  }

  return (
    <ListPage.Root>
      {getPlatformOS() === 'web' ? (
        <Row backgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}>
          <XlSpacing />
        </Row>
      ) : null}
      <ListPage.Content.FlatList
        data={data}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={NotificationCenterEmptyState}
        ListHeaderComponent={
          <ListPage.Header.Root
            backgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
            onDismiss={() => {
              if (canGoBack()) {
                goBack();
              } else {
                // navigate to the homescreen when no route available to go back
                navigate('RouteAppTabNavigator', { screen: 'RouteTabHomescreen' });
                // marking all as read when the user navigates away from notification center
                // we only need to do this because the unmounting of the component is not guaranteed
                if (unreadNotificationsCount) {
                  readAllCurrentlyUnread({});
                }
              }
            }}
            CenterContent={
              <Row center>
                {unreadNotificationsCount > 0 ? (
                  <Tag
                    label={unreadNotificationsCount.toString()}
                    type="counter"
                  />
                ) : null}
                <XxsSpacing />
                <ListPage.Header.Content.Title>Notifications</ListPage.Header.Content.Title>
              </Row>
            }
          >
            {isDesktopWeb ? <XlSpacing /> : null}
          </ListPage.Header.Root>
        }
        stickyHeaderIndices={[0]}
        renderItem={({ item }) => <NotificationCenterListItem {...item} />}
        keyExtractor={(item) => item.wundercom_message_id.toString()}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onEndReached={handleFetchMore}
        onEndReachedThreshold={0.1}
        viewabilityConfig={viewabilityConfig}
        onViewableItemsChanged={onViewableItemsChanged}
      />
    </ListPage.Root>
  );
};
