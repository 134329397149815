import { SIZES } from '@alto/design-library-tokens';
import * as React from 'react';
import { Image } from 'react-native';
import { ApplePayIcon } from './ApplePayIcon';
import { GooglePayIcon } from './GooglePayIcon';

export type ThirdPartyIconName = 'applepay' | 'googlepay';

type Size = (typeof SIZES.ICON)[keyof typeof SIZES.ICON];

export type ThirdPartyIconProps = {
  readonly name: ThirdPartyIconName;
  readonly size?: Size;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
};

const iconToXmlMap: Record<ThirdPartyIconName, string> = {
  applepay: ApplePayIcon,
  googlepay: GooglePayIcon,
};

export const ThirdPartyIcon = ({ name, size = SIZES.ICON.LG, testID }: ThirdPartyIconProps) => {
  return (
    <Image
      testID={testID}
      source={{
        uri: `data:image/svg+xml;base64,${btoa(iconToXmlMap[name])}`,
        width: size.value,
        height: size.value,
      }}
    />
  );
};
