// @owners { team: patients-team }
import { removeUnschedulablePrescriptionsFromCart } from './cart';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPaymentMethods } from './shipmentPaymentMethods';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPricing } from '~shared/actions/pricing';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { type CancelationReason } from '~shared/constants';
import { type DeliveryWithPrescription } from '~shared/features/delivery-info/types';
import { type ApiOptions } from '~shared/helpers/api';
import { get, put } from '~shared/helpers/apiHelper';
import { type LightDelivery, type ReduxDispatch, type ReduxGetStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const V3_API_VERSION = 'v3';
const v3Options: ApiOptions = {
  version: V3_API_VERSION,
};
const V5_API_VERSION = 'v5';
const v5Options: ApiOptions = {
  version: V5_API_VERSION,
};

const FETCH_ALL_FRESH_THRESHOLD = 1000 * 60 * 60; // 60 minutes

export const FETCH_DELIVERIES_LOADING = 'FETCH_DELIVERIES_LOADING';
type ActionFetchDeliveriesLoading = {
  type: typeof FETCH_DELIVERIES_LOADING;
};

export function fetchingDeliveries(): ActionFetchDeliveriesLoading {
  return {
    type: FETCH_DELIVERIES_LOADING,
  };
}

export const FETCH_DELIVERIES_SUCCEEDED = 'FETCH_DELIVERIES_SUCCEEDED';
type ActionFetchDeliveriesSucceeded = {
  type: typeof FETCH_DELIVERIES_SUCCEEDED;
  payload: LightDelivery[];
};

export function fetchDeliveriesSucceeded(deliveries: LightDelivery[]): ActionFetchDeliveriesSucceeded {
  return {
    type: FETCH_DELIVERIES_SUCCEEDED,
    payload: deliveries,
  };
}

export const FETCH_ACTIVE_DELIVERIES_LOADING = 'FETCH_ACTIVE_DELIVERIES_LOADING';
type ActionFetchActiveDeliveriesLoading = {
  type: typeof FETCH_ACTIVE_DELIVERIES_LOADING;
};

export function fetchingActiveDeliveries(): ActionFetchActiveDeliveriesLoading {
  return {
    type: FETCH_ACTIVE_DELIVERIES_LOADING,
  } as const;
}

export const FETCH_ACTIVE_DELIVERIES_SUCCEEDED = 'FETCH_ACTIVE_DELIVERIES_SUCCEEDED';
type ActionFetchActiveDeliveriesSucceeded = {
  type: typeof FETCH_ACTIVE_DELIVERIES_SUCCEEDED;
  payload: LightDelivery[];
};

function fetchActiveDeliveriesSucceeded(deliveries: LightDelivery[]): ActionFetchActiveDeliveriesSucceeded {
  return {
    type: FETCH_ACTIVE_DELIVERIES_SUCCEEDED,
    payload: deliveries,
  } as const;
}

export const FETCH_DELIVERIES_FAILED = 'FETCH_DELIVERIES_FAILED';
type ActionFetchDeliveriesFailed = {
  type: typeof FETCH_DELIVERIES_FAILED;
  payload: APIError;
  error: true;
};

export function fetchDeliveriesFailed(error: APIError): ActionFetchDeliveriesFailed {
  return {
    type: FETCH_DELIVERIES_FAILED,
    payload: error,
    error: true,
  };
}

export const CLEAR_FETCH_DELIVERIES_ERROR = 'CLEAR_FETCH_DELIVERIES_ERROR';
type ActionClearFetchDeliveriesError = {
  type: typeof CLEAR_FETCH_DELIVERIES_ERROR;
};

export function clearFetchDeliveriesError(): ActionClearFetchDeliveriesError {
  return {
    type: CLEAR_FETCH_DELIVERIES_ERROR,
  };
}

type FetchDeliveriesActions =
  | ActionFetchActiveDeliveriesLoading
  | ActionFetchActiveDeliveriesSucceeded
  | ActionFetchDeliveriesLoading
  | ActionFetchDeliveriesFailed
  | ActionFetchDeliveriesSucceeded
  | ActionClearFetchDeliveriesError;

export const CANCEL_DELIVERIES_LOADING = 'CANCEL_DELIVERIES_LOADING';
type ActionCancelDeliveriesLoading = {
  type: typeof CANCEL_DELIVERIES_LOADING;
};

export function cancelingDeliveries(): ActionCancelDeliveriesLoading {
  return {
    type: CANCEL_DELIVERIES_LOADING,
  };
}

export const CANCEL_DELIVERIES_SUCCEEDED = 'CANCEL_DELIVERIES_SUCCEEDED';
type ActionCancelDeliveriesSucceeded = {
  type: typeof CANCEL_DELIVERIES_SUCCEEDED;
  payload: DeliveryWithPrescription[];
};

export function cancelDeliveriesSucceeded(deliveries: DeliveryWithPrescription[]): ActionCancelDeliveriesSucceeded {
  return {
    type: CANCEL_DELIVERIES_SUCCEEDED,
    payload: deliveries,
  };
}

export const CANCEL_DELIVERIES_FAILED = 'CANCEL_DELIVERIES_FAILED';
type ActionCancelDeliveriesFailed = {
  type: typeof CANCEL_DELIVERIES_FAILED;
  payload: APIError;
  error: true;
};

export function cancelDeliveriesFailed(error: APIError): ActionCancelDeliveriesFailed {
  return {
    type: CANCEL_DELIVERIES_FAILED,
    payload: error,
    error: true,
  };
}

type CancelDeliveryActions =
  | ActionCancelDeliveriesLoading
  | ActionCancelDeliveriesFailed
  | ActionCancelDeliveriesSucceeded;

export const CLEAR_DELIVERY_NOTIFICATIONS = 'CLEAR_DELIVERY_NOTIFICATIONS';
type ActionClearDeliveryNotifications = {
  type: typeof CLEAR_DELIVERY_NOTIFICATIONS;
};

export function clearDeliveryNotifications(): ActionClearDeliveryNotifications {
  return {
    type: CLEAR_DELIVERY_NOTIFICATIONS,
  };
}

export const CLEAR_DELIVERIES_ERROR = 'CLEAR_DELIVERIES_ERROR';
type ActionClearDeliveryError = {
  type: typeof CLEAR_DELIVERIES_ERROR;
};

export function clearDeliveriesError(): ActionClearDeliveryError {
  return {
    type: CLEAR_DELIVERIES_ERROR,
  };
}

type ClearDeliveryActions = ActionClearDeliveryNotifications | ActionClearDeliveryError;

/**
 * Fetches only the active deliveries for the user.
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
function fetchActiveDeliveries() {
  return async (dispatch: ReduxDispatch) => {
    const params = {
      batchley: true,
      active: true,
    };
    return get('/deliveries', params, v3Options)
      .then((json) => {
        if (json.error) {
          dispatch(fetchDeliveriesFailed(json.error));
          return false;
        }

        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchShipmentPaymentMethods());
        dispatch(fetchActiveDeliveriesSucceeded(json));
        dispatch(removeUnschedulablePrescriptionsFromCart());
        return true;
      })
      .catch((err: unknown) => {
        dispatch(fetchDeliveriesFailed(err as APIError));
      });
  };
}

/**
 * Fetches all deliveries for the user. This is a very expensive call, because some users have over a thousand deliveries.
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
function fetchAllDeliveries() {
  return async (dispatch: ReduxDispatch) => {
    const params = {
      batchley: true,
    };
    return get('/deliveries', params, v3Options)
      .then((json) => {
        if (json.error) {
          dispatch(fetchDeliveriesFailed(json.error));
          return false;
        }

        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchShipmentPaymentMethods());
        dispatch(fetchDeliveriesSucceeded(json));
        dispatch(removeUnschedulablePrescriptionsFromCart());
        return true;
      })
      .catch((err: unknown) => {
        dispatch(fetchDeliveriesFailed(err as APIError));
      });
  };
}

/**
 * The fetchDeliveries call will either fetch just active deliveries or all deliveries if it's been an hour
 * since the last fetch all deliveries.
 *
 * We've strategically done this to avoid the performance hit of fetching all deliveries every time we call fetchDeliveries,
 * because in most cases, we don't need to do this.
 *
 * Ideally, the default fetchDeliveries call does not do this fetch split based off of time. We should be explicit about which
 * deliveries we care about and make targeted calls to fetch only those deliveries.
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchDeliveries() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();

    if (state.ui.loading.fetchDeliveriesLoading || state.ui.loading.fetchActiveDeliveriesLoading) {
      return Promise.resolve();
    }

    if (Date.now() - (state.deliveries?.lastFetchAllRequestSucceededAt || 0) > FETCH_ALL_FRESH_THRESHOLD) {
      dispatch(fetchingDeliveries());
      return dispatch(fetchAllDeliveries());
    }
    dispatch(fetchingActiveDeliveries());
    return dispatch(fetchActiveDeliveries());
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function cancelDeliveries(
  deliveries: DeliveryWithPrescription[],
  cancelationReason: CancelationReason,
  cancelationInfo: string,
  turnOffAutoRefill: boolean,
) {
  return async (dispatch: ReduxDispatch) => {
    const options = v5Options;

    dispatch(cancelingDeliveries());
    return put(
      '/deliveries/cancel',
      {
        deliveries,
        cancelation_reason: cancelationReason,
        cancelation_info: cancelationInfo,
        turn_off_auto_refill: turnOffAutoRefill,
      },
      options,
    ).then((json) => {
      const { error } = json;

      if (error) {
        dispatch(cancelDeliveriesFailed(error));
        return false;
      }

      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipments());
      if (deliveries[0]?.shipment_id && cancelationReason === 'remove_medication') {
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchShipmentPricing(deliveries[0]?.shipment_id));
      }
      dispatch(cancelDeliveriesSucceeded(deliveries));
      return true;
    });
  };
}

export type DeliveryActions = FetchDeliveriesActions | CancelDeliveryActions | ClearDeliveryActions;
