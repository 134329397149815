import { createSelector } from 'reselect';
import { getAddresses, getAddressesObject } from './getAddresses';

const getSelectedAddressID = createSelector([getAddressesObject], (addresses) => addresses.selectedAddressID);

// TODO refactor web address form with selectors
export const getSelectedAddress = createSelector([getAddresses, getSelectedAddressID], (addresses, addressID) =>
  addresses.find((address) => address.id === addressID),
);

export const getAddressExists = createSelector([getSelectedAddress], (address) => !!address);

export default getSelectedAddress;
