import {
  type AltoIconName,
  type BACKGROUND_COLORS_VALUES,
  type BORDER_COLORS_VALUES,
  type BaseIconName,
} from '@alto/design-library-tokens';
import React, { useState } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Border } from '../../borders';
import { Row } from '../../containers/src/Flex';
import { AltoIcon } from '../../icon';
import * as buttonUtils from '../buttonUtils';

export type AltoIconButtonProps = {
  readonly iconName: BaseIconName;
  readonly type?: 'primary' | 'secondary';
  readonly onPress: () => void;
  readonly disabled?: boolean;
  readonly accessibilityLabel: string;
  readonly small?: boolean;
};

type CircleProps = Omit<InnerCircleProps, 'opacity'> & {
  readonly borderColor?: BORDER_COLORS_VALUES | BACKGROUND_COLORS_VALUES;
  readonly children: React.ReactNode;
  readonly backgroundOpacity: number;
};

type InnerCircleProps = {
  backgroundColor?: BACKGROUND_COLORS_VALUES;
  opacity: number;
  size: number;
};

const InnerCircle = styled(Row)<InnerCircleProps>`
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  opacity: ${({ opacity }) => opacity};
`;

const Circle = ({ borderColor, backgroundColor, size, children, backgroundOpacity }: CircleProps) => {
  return (
    <Border
      radius="CIRCLE"
      color={borderColor}
    >
      <InnerCircle
        center
        opacity={backgroundOpacity}
        size={size}
        backgroundColor={backgroundColor}
      >
        {children}
      </InnerCircle>
    </Border>
  );
};

export const AltoIconButton = ({
  onPress,
  disabled,
  type,
  iconName,
  accessibilityLabel,
  small,
}: AltoIconButtonProps) => {
  const defaultCircleBackgroundOpacity = 1;
  // We're trying to match the onPressed styles of IconButton, where the default opacity change
  // on press is 0.2 for Touchable components.
  const pressedCircleBackgroundOpacity = 0.2;
  const [backgroundOpacity, setBackgroundOpacity] = useState<number>(defaultCircleBackgroundOpacity);

  const utilProps = {
    secondary: type === 'secondary',
    disabled,
  };
  const iconType = buttonUtils.getAltoIconColor(utilProps);
  const borderColor = buttonUtils.getBorderColor(utilProps);
  const backgroundColor = buttonUtils.getBackgroundColor(utilProps);
  const topAndBottomBorder = 2;
  const largeAltoIconSize = 48 - topAndBottomBorder;
  const smallAltoIconSize = 36 - topAndBottomBorder;
  const size = small ? smallAltoIconSize : largeAltoIconSize;

  const altoIconName: AltoIconName = small ? `${iconName}-small` : iconName;

  return (
    <Row>
      <Pressable
        disabled={disabled}
        onPress={onPress}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole="button"
        onPressIn={() => {
          setBackgroundOpacity(pressedCircleBackgroundOpacity);
        }}
        onPressOut={() => {
          setBackgroundOpacity(defaultCircleBackgroundOpacity);
        }}
      >
        <Circle
          size={size}
          borderColor={borderColor}
          backgroundOpacity={backgroundOpacity}
          backgroundColor={backgroundColor}
        >
          <AltoIcon
            name={altoIconName}
            type={iconType}
          />
        </Circle>
      </Pressable>
    </Row>
  );
};
