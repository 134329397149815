// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  BasePage,
  Body,
  Button,
  ButtonGroup,
  Column,
  Description,
  H3,
  InputRadio,
  InputRadioGroup,
  MdSpacing,
  NavBar,
  Toast,
  ToastContext,
  XsSpacing,
  XxxlPadding,
} from '@alto/design-system';
import { AltoLogoNavBar } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { InputTextArea } from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik } from 'formik';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import PageHeader from '~web/components/PageHeader';

export type FormValues = Record<number, string | null | undefined>;

export type Question = {
  order: number;
  question: string;
  required: boolean;
  response: string | null | undefined;
  type: 'numeric' | 'text';
};

type Props = {
  readonly changeStep: (direction?: string) => void;
  readonly onSubmit: (values: FormValues) => void;
  readonly question: Question;
  readonly step: number;
  readonly totalQuestions: number;
};

const StyledInputTextArea = styled(InputTextArea)`
  max-width: 512px;
`;

const generateInitialValues = (numValues: number): FormValues => {
  const initialValues: FormValues = {};

  for (let i = 0; i < numValues; i++) {
    initialValues[i] = null;
  }

  return initialValues;
};

const NumericQuestion = ({ onChange, value }: { onChange: any; value: string }) => {
  return (
    <InputRadioGroup
      onValueChange={onChange}
      value={value}
    >
      <InputRadio
        label="1 — Strongly disagree"
        value="1"
        radioPlacement="left"
      />
      <InputRadio
        label="2 — Disagree"
        value="2"
        radioPlacement="left"
      />
      <InputRadio
        label="3 — Neutral"
        value="3"
        radioPlacement="left"
      />
      <InputRadio
        label="4 — Agree"
        value="4"
        radioPlacement="left"
      />
      <InputRadio
        label="5 — Strongly agree"
        value="5"
        radioPlacement="left"
      />
    </InputRadioGroup>
  );
};

const TextQuestion = ({ onChange, value }: { onChange: any; value: string }) => {
  return (
    <StyledInputTextArea
      input={{
        onChange,
        value,
        placeholder: 'I think Alto could...',
      }}
    />
  );
};

export const SurveyPage = ({ changeStep, onSubmit, question, step, totalQuestions }: Props) => {
  const { addToast } = useContext(ToastContext);
  const currentQuestion = question || {};
  const initialValues = generateInitialValues(totalQuestions);
  const isRequiredQuestion = !!currentQuestion.required;
  const isNumericTypeQuestion = currentQuestion.type === 'numeric';
  const isTextTypeQuestion = currentQuestion.type === 'text';
  const buttonTitle = step === totalQuestions - 1 ? 'Finish' : 'Next';
  const completed = step === totalQuestions;

  return (
    <BasePage
      NavBar={
        <NavBar
          CenterContent={<AltoLogoNavBar />}
          backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
        />
      }
      withoutWebFramingElementHeights
      backgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTER}
      footerPlacementContext="webScreenWithoutPolicyFooter"
    >
      <XxxlPadding topPadding={SPACING.STATIC.XL}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <div>
              <Column gap="MD">
                <PageHeader underline={false}>How was your experience?</PageHeader>
                {isNumericTypeQuestion ? (
                  <Description>To what extent do you agree or disagree with the following statement:</Description>
                ) : null}
              </Column>
              <MdSpacing />
              {!completed && (
                <Column gap="XXS">
                  <Description
                    fontFamily="semibold"
                    color={COLORS.TEXT_COLORS.DISABLED}
                  >
                    QUESTION {step + 1} OF {totalQuestions}
                  </Description>
                  <H3 color={COLORS.TEXT_COLORS.BLACK}>
                    {currentQuestion.question}
                    {isRequiredQuestion ? (
                      <Body
                        fontFamily="bold"
                        color={COLORS.TEXT_COLORS.DANGER}
                      >
                        *
                      </Body>
                    ) : null}
                  </H3>
                  {isNumericTypeQuestion ? (
                    <NumericQuestion
                      onChange={handleChange(step.toString())}
                      value={values[step] ?? ''}
                    />
                  ) : null}
                  {isTextTypeQuestion ? (
                    <TextQuestion
                      onChange={handleChange(step.toString())}
                      value={values[step] ?? ''}
                    />
                  ) : null}
                </Column>
              )}
              {completed ? (
                <Column>
                  <H3 color={COLORS.TEXT_COLORS.BLACK}>Thanks for your feedback!</H3>
                  <XsSpacing />
                  <Description>
                    We really appreciate you taking the time to complete this survey and helping us improve the Alto
                    experience.
                  </Description>
                </Column>
              ) : null}
              <MdSpacing />
              {!completed && (
                <ButtonGroup
                  justifyButtons="left"
                  inline
                  buttons={[
                    step !== 0 ? (
                      <Button
                        key="survey-page-previous-button"
                        label="Previous"
                        type="secondary"
                        onPress={() => {
                          changeStep('back');
                        }}
                        width="inline"
                      />
                    ) : null,
                    <Button
                      key="survey-page-next-button"
                      label={buttonTitle}
                      onPress={() => {
                        if (isRequiredQuestion && !values[step]) {
                          addToast(<Toast variant="error">This question requires a response</Toast>);
                          return;
                        }

                        handleSubmit();
                        changeStep();
                      }}
                      width="inline"
                    />,
                  ].filter(TypescriptUtils.isPresent)}
                />
              )}
            </div>
          )}
        </Formik>
      </XxxlPadding>
    </BasePage>
  );
};
