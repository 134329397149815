import { type ErrorObject } from '@alto/core/types/v1/error_object';
import {
  type ApiOptions,
  buildHeaders,
  buildQueryString,
  generateRequestID,
  getApiUrl,
  handleBadRequest,
  request,
} from './api';
import deduplicator from './api/deduplicator';
import { batchleyAwareFetch } from './batchleyAwareFetch';
import {
  ACCOUNT_LOCKED_LOGIN_ERROR_MESSAGE,
  DEFAULT_ERROR_ALERT_MESSAGE,
  LOGIN_FAILURE_ERROR_MESSAGE,
  NETWORK_FAILURE_ERROR_MESSAGE,
  UNAUTHORIZED_ERROR_MESSAGE,
  VERIFY_FAILURE_ERROR_MESSAGE,
} from '~shared/constants';
import { type APIError } from '~shared/types';

const shouldUseBuiltInFetch = !!process.env.JEST_WORKER_ID;

const defaultAPIVersion = 'v2';

type ErrorOptions = {
  internalMessage: string;
  message?: string;
  statusType?: string;
  statusCode?: number;
  code?: string;
};

const createError = (response: Response | undefined, options: ErrorOptions) => {
  const { internalMessage, message, statusType, statusCode, code, ...rest } = options;
  const { statusText, status, url } = response || {};

  const errorText = internalMessage || 'Something went wrong.';
  const errorStatusType = statusType || statusText;
  const errorStatusCode = statusCode || status;

  let defaultErrorMessage = DEFAULT_ERROR_ALERT_MESSAGE;

  if (url) {
    if (url.includes('/validate')) {
      defaultErrorMessage = VERIFY_FAILURE_ERROR_MESSAGE;
    }

    if (url.includes('/login')) {
      defaultErrorMessage =
        internalMessage === 'Too many login attempts'
          ? ACCOUNT_LOCKED_LOGIN_ERROR_MESSAGE
          : LOGIN_FAILURE_ERROR_MESSAGE;
    }
  }

  const errorMessage = message || defaultErrorMessage;

  const error: Record<string, any> = new Error(errorText);
  error.details = {
    ...rest,
    statusType: errorStatusType,
    statusCode: errorStatusCode,
    message: errorMessage,
    internalMessage: errorText,
    code,
  };
  return error;
};

// For when .json() fails
const catchJsonError = (response: Response) => (e: unknown) => {
  console.warn(`Received '${(e as Error).name || ''}' trying to parse JSON error response`, e);
  const error = createError(response, {
    internalMessage: (e as Error).message,
  });

  // FIXME: these should be rejects
  return Promise.resolve({
    error,
    response,
  });
};

function buildLegacyError(response: Response, json: any): Error {
  const { message, error, patient_ready_message, ...rest } = json;

  if (!error && !message) {
    throw new Error('Received invalid error response');
  }

  // Special case for unauthorized responses
  if (response.status === 401 && message === 'Unauthorized') {
    // @ts-expect-error TS(2739): Type 'Record<string, any>' is missing the following properties from type 'Error': name, message
    return createError(response, {
      internalMessage: 'Not Authorized',
      message: UNAUTHORIZED_ERROR_MESSAGE,
    });
  }

  // All other errors - show default message, but keep the original server
  // message around
  let errorText = message;

  if (!errorText) {
    if (typeof error === 'object') {
      errorText = Object.keys(error)
        .map((field) => {
          return `${field}: ${error[field]}`;
        })
        .join('; ');
    } else if (typeof error === 'string') {
      errorText = error;
    } else {
      errorText = response.statusText;
    }
  }

  const options = {
    ...rest,
    internalMessage: errorText,
    // show the actual message when the patient_ready_message flag is set
    message: patient_ready_message ? message : undefined,
    // sometimes this is an object with the form field as the key and error
    // message as the value. used for displaying errors within forms
    error,
  };

  // rest can include the 'errors' key from ActiveRecord::RecordInvalid errors
  // @ts-expect-error TS(2322): Type 'Record<string, any>' is not assignable to type 'Error'.
  return createError(response, options);
}

export function buildError(responseStatus: number, errors: ErrorObject[]): APIError {
  // The server supports returning multiple errors, but the client is only concerned with the highest priority error,
  // which should be returned first
  const firstError: ErrorObject = errors[0];
  let patientFacingMessage = firstError?.detail || 'Something went wrong';

  // For now, manually override the 401 error message to something more patient-friendly
  // Ideally, the server should send a patient-friendly error message
  if (responseStatus === 401 && patientFacingMessage === 'Unauthorized') {
    patientFacingMessage = UNAUTHORIZED_ERROR_MESSAGE;
  }

  const error = new Error(patientFacingMessage);
  // @ts-expect-error add details to error object
  error.details = {
    id: firstError?.id ?? '',
    statusCode: responseStatus || (firstError?.status && parseInt(firstError.status, 10)) || 0,
    message: patientFacingMessage,
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    internalMessage: `${firstError?.title ? `${firstError.title} ` : ''}${firstError?.detail || ''}`,
    code: firstError.code,
  };

  return error as APIError;
}
type RequestPayload = {
  url: string;
  method: string;
  body?: string;
  headers?: Record<string, string>;
};

// For when fetch() returns normally, but also needs to handle non-ok responses
// @see https://developer.mozilla.org/en-US/docs/Web/API/fetch

export const fetchCallback = (request: RequestPayload) => (response: Response) => {
  if (response.ok) {
    return response.json().catch(catchJsonError(response));
  }

  handleBadRequest({ response, ...request });

  // If we don't have a valid response, we might not have valid JSON, so guard
  // against that.
  return response
    .json()
    .then((json) => {
      const { message, error, errors } = json;

      if (errors && !message && !error) {
        // FIXME: these should be rejects
        // eslint-disable-next-line promise/no-return-wrap
        return Promise.resolve({
          error: buildError(response.status, errors),
        });
      }

      // the presence of 'message' or 'error' key indicates that this is a legacy error payload
      // FIXME: these should be rejects
      // eslint-disable-next-line promise/no-return-wrap
      return Promise.resolve({
        error: buildLegacyError(response, json),
      });
    })
    .catch(catchJsonError(response));
};

// Used when fetch() rejects, only occurs when the request doesn't actually
// happen - "it will only reject on network failure or if anything prevented the
// request from completing."
function fetchProblemCallback(error: unknown) {
  const networkFailure = (error as Error)?.message?.toLowerCase() === 'network request failed';
  const message = networkFailure ? NETWORK_FAILURE_ERROR_MESSAGE : undefined;
  const newError = createError(
    undefined, // Don't have access to the response, but we don't need anything from it to construct this error
    {
      message,
      internalMessage: (error as Error)?.message,
      statusType: 'Fetch Error',
      statusCode: 598,
    },
  );

  // FIXME: these should be rejects
  return Promise.resolve({
    error: newError,
  });
}

export async function get(path: string, params: Record<string, any> = {}, options: ApiOptions = {}) {
  const requestID = generateRequestID();
  const headers = buildHeaders(requestID);
  const apiVersion = options.version || defaultAPIVersion;
  const paramsString = buildQueryString(params, options.splitArrayParams);
  const url = getApiUrl(apiVersion, path, paramsString);

  if (shouldUseBuiltInFetch) {
    return fetch(url)
      .then(fetchCallback({ url, method: 'GET', headers }))
      .catch(fetchProblemCallback);
  }

  return deduplicator(url, () => {
    return batchleyAwareFetch(url, {
      method: 'GET',
      headers,
      credentials: 'include',
      mode: 'cors',
    })
      .then(fetchCallback({ url, method: 'GET', headers }))
      .catch(fetchProblemCallback);
  });
}

export const post = async (path: string, params: Record<string, any> = {}, options: ApiOptions = {}) => {
  const url = getApiUrl(options.version || defaultAPIVersion, path);
  const body = JSON.stringify(params);
  return request(url, { method: 'POST', body })
    .then(fetchCallback({ url, method: 'POST', body }))
    .catch(fetchProblemCallback);
};
export const put = async (path: string, params: Record<string, any> = {}, options: ApiOptions = {}) => {
  const url = getApiUrl(options.version || defaultAPIVersion, path);
  const body = JSON.stringify(params);
  return request(url, { method: 'PUT', body })
    .then(fetchCallback({ url, method: 'PUT', body }))
    .catch(fetchProblemCallback);
};
export const destroy = async (path: string, options: ApiOptions = {}) => {
  const url = getApiUrl(options.version || defaultAPIVersion, path);
  return request(url, { method: 'DELETE' })
    .then(fetchCallback({ url, method: 'DELETE' }))
    .catch(fetchProblemCallback);
};
export const destroyWithParams = async (path: string, params: Record<string, any> = {}, options: ApiOptions = {}) => {
  const url = getApiUrl(options.version || defaultAPIVersion, path);
  const body = JSON.stringify(params);
  return request(url, { method: 'DELETE', body })
    .then(fetchCallback({ url, method: 'DELETE', body }))
    .catch(fetchProblemCallback);
};
