import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, Tag } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button } from '@alto/pocky';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import styledNative from 'styled-components/native';
import { selectUser } from '~shared/actions/ui/users';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import InsuranceCard from '~web/images/insuranceCardFlat@2x.png';

const CustomCard = styledNative(Card)`
  margin: 0 0 0;
  z-index: 0;
`;

type Props = {
  readonly userID: number;
  readonly name: string | null | undefined;
};

export const NeedsInsuranceCard = ({ userID, name }: Props) => {
  const tag = (
    <Tag
      label="ACTION REQUIRED"
      type="warning"
    />
  );

  const dispatch = useDispatchShared();
  const currentUserID = useSelectorShared(getCurrentUserID);

  useEffect(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.ADD_INSURANCE_VIEWED))); //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const label = 'Outdated insurance';
  const messagePerson = (userID !== currentUserID && !!name && `${name}'s `) || 'your ';
  const title = (userID !== currentUserID && !!name ? `${name}'s ` : '') + label;

  const handleUpdateInsurance = () => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.ADD_INSURANCE_CLICKED)));
    dispatch(selectUser(userID));
    dispatch(push(`me/${userID}`));
  };

  return (
    <CustomCard
      title={title}
      description={`Get the most out of your prescription benefits by updating ${messagePerson}insurance info.`}
      illustrationSrc={InsuranceCard}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.insuranceCard}
      tag={tag}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Button
          kind="primary"
          full
          onClick={handleUpdateInsurance}
        >
          Update now
        </Button>
      </LgPadding>
    </CustomCard>
  );
};
