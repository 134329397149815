// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type CartItemsByDeliveryMethod } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { type Item, type Order } from '~shared/features/checkout/types';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { getCourierTip } from './getCourierTip';
import { getItemsByDeliveryMethod } from './getItemsByDeliveryMethod';

type CreateOrderParams = {
  addressID?: number;
  compoundIDs?: Set<number>;
  ordersByIndex: Record<number, Order>;
  cartItemsByDeliveryMethod: CartItemsByDeliveryMethod;
  deliveryMethods: DeliveryMethod[];
  editOrderShipmentID?: number | null;
  items: Item[];
  scheduledWithProgynyWizard: boolean;
};

export const getItemKey = ({ resource_id, resource_type }: { resource_id: number; resource_type: string }) =>
  `${resource_type}_${resource_id}`;

export const getOrderParams = ({
  addressID,
  compoundIDs = new Set([]),
  ordersByIndex,
  cartItemsByDeliveryMethod,
  deliveryMethods,
  items,
  scheduledWithProgynyWizard,
  editOrderShipmentID,
}: CreateOrderParams) => {
  if (!addressID) {
    // useCheckoutValidationErrors should validate the address by this point
    throw new Error('Cannot create an order without an addressID');
  }
  const isSplitShipments = Object.keys(ordersByIndex).length > 1;

  const itemsByKey = items.reduce<Record<string, Item>>((hash, item) => {
    const key = editOrderShipmentID
      ? // when editing an order the redux item is an instance of a DeliveryItem instead of a PrescriptionItem
        getItemKey({ resource_id: item.prescription.id, resource_type: 'Prescription' })
      : getItemKey(item);
    hash[key] = item;
    return hash;
  }, {});

  const itemsByDeliveryMethod = getItemsByDeliveryMethod({
    cartItemsByDeliveryMethod,
    itemsByKey,
  });

  const orderItems = Object.entries(ordersByIndex).flatMap(([orderIndex, order]) => {
    const parsedOrderIndex = parseInt(orderIndex, 10);
    const deliveryMethodType = deliveryMethods[parsedOrderIndex].name;
    let items = itemsByDeliveryMethod[deliveryMethodType] ?? [];

    // handle edge case for mail/mail split shipments with same delivery method
    // the first order will contain the compound prescription
    if (isSplitShipments) {
      if (parsedOrderIndex === 0) {
        items = items.filter((item) => compoundIDs.has(item.resource_id));
      } else if (parsedOrderIndex === 1) {
        items = items.filter((item) => !compoundIDs.has(item.resource_id));
      }
    }

    return items.map((item) => {
      const apiItem = item.apiPayload();
      return {
        auto_refill: item.auto_refill ?? false,
        patient_paid_shown: apiItem.patient_paid_shown?.toString() ?? '',
        sku: item.prescription.sku || '',
        home_to_sign_confirmation: order.home_to_sign_confirmation || 'UNCONFIRMED',
        quantity: item.prescription.quantity?.toString() || '',
        payment_method_id: order.payment_method_id || 0,
        payment_amount: order.payment_method_amount || 0,
        secondary_payment_method_id: order.second_payment_method_id,
        secondary_payment_amount: order.second_payment_method_amount,
        prescription_id: item.prescription.id,
        delivery_date: order.date ?? '',
        delivery_id: 'id' in apiItem ? apiItem.id : undefined,
        deliver_after: order.deliver_after,
        deliver_before: order.deliver_before,
        delivery_method: deliveryMethodType,
        price_type: item.selectedPaymentType,
      };
    });
  });

  const { tip_amount, tip_payment_method_id } = getCourierTip({ deliveryMethods, ordersByIndex });

  // the remaining order fields are the same for split shipments so use the first order
  const order = ordersByIndex[0];
  return {
    address_id: addressID,
    applied_shipment_fees: order.applied_shipment_fees || [],
    include_sharps_container: order.include_sharps_container || false,
    needle_prompt_shown: order.needle_prompt_shown || false,
    order_items: orderItems,
    origin: editOrderShipmentID ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
    scheduled_with_progyny_wizard: scheduledWithProgynyWizard || false,
    shipment_id: editOrderShipmentID,
    tip_payment_method_id,
    tip_amount,
    // required in OrdersEndpointCreateParams and OrdersEndpointUpdateParams types
    pay_at_pickup: undefined,
  };
};
