// @owners { team: patients-team }
export const palette = {
  ivory: '#FFFAF4',
  babyBlue: '#EEF4FA',
  brandNavy: '#0B4A72',
  citrus: '#E6F2B4',
  darkCitrus: '#DDE995',
  navyTint2: '#EBF0F5',
  darkNavyTint: '#D7E0E7',
  gray: 'rgba(0, 0, 0, 0.7)',
  white: '#FFFFFF',
  disabledButtonTextGray: '#6D7278',
  disabledButtonGray: '#FAFAFA',
  disabledButtonHover: '#f5f5f5',
};

export const spacing = {
  xsSpacing: '.5rem',
  smSpacing: '1rem',
  mdSpacing: '1.5rem',
  lgSpacing: '2rem',
  mdlgSpacing: '3.5rem',
  xlSpacing: '4rem',
  xxlSpacing: '6rem',
};
