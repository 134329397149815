import { COLORS } from '@alto/design-library-tokens';

export const getLabelColor = (disabled?: boolean, error?: string) => {
  if (error) {
    return COLORS.TEXT_COLORS.DANGER;
  }

  if (disabled) {
    return COLORS.TEXT_COLORS.DISABLED;
  }

  return COLORS.TEXT_COLORS.PRIMARY;
};
