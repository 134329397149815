import { withRehydration } from './withRehydration';
import {
  FETCH_SHIPMENT_PAYMENT_METHODS_SUCCEEDED,
  type ShipmentPaymentMethodsActions,
} from '~shared/actions/shipmentPaymentMethods';
import { FETCH_SHIPMENTS_LOADING, FETCH_SHIPMENTS_SUCCEEDED, type ShipmentActions } from '~shared/actions/shipments';
import { type Shipment, type ShipmentPaymentMethod } from '~shared/types';

export type ShipmentsState = {
  lastRequestAttemptedAt: number;
  requestAttemptCount: number;
  lastRequestSucceededAt: number;
  shipments: Shipment[];
  shipmentPaymentMethods: ShipmentPaymentMethod[];
};

const initialState: ShipmentsState = {
  lastRequestAttemptedAt: 0,
  requestAttemptCount: 0,
  lastRequestSucceededAt: 0,
  shipments: [],
  shipmentPaymentMethods: [],
};

const shipments = (
  state: ShipmentsState = initialState,
  action: ShipmentActions | ShipmentPaymentMethodsActions,
): ShipmentsState => {
  switch (action.type) {
    case FETCH_SHIPMENTS_SUCCEEDED:
      return { ...state, lastRequestSucceededAt: Date.now(), requestAttemptCount: 0, shipments: action.payload };

    case FETCH_SHIPMENTS_LOADING:
      return { ...state, lastRequestAttemptedAt: Date.now(), requestAttemptCount: state.requestAttemptCount + 1 };

    case FETCH_SHIPMENT_PAYMENT_METHODS_SUCCEEDED:
      return { ...state, shipmentPaymentMethods: action.payload };

    default:
      return state;
  }
};

export default withRehydration(shipments, initialState);
