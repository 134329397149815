import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { Constants } from '../../../utils';

type Props = {
  readonly children: React.ReactNode;
  readonly delay?: number;
  readonly duration?: number;
  readonly removeAfter?: boolean;
  readonly direction?: 'in' | 'out';
};

const isTest = !!process.env.JEST_WORKER_ID;

export const Fade = ({ children, delay = 0, duration = 1500, removeAfter = true, direction = 'in' }: Props) => {
  const opacity = useRef(new Animated.Value(direction === 'in' ? 0 : 1)).current;
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (isTest) return;

    setTimeout(() => {
      if (direction === 'in') {
        Animated.timing(opacity, {
          toValue: 1,
          duration,
          easing: Easing.linear,
          useNativeDriver: Constants.useNativeDriver,
        }).start();
      } else {
        Animated.timing(opacity, {
          toValue: 0,
          duration,
          easing: Easing.linear,
          useNativeDriver: Constants.useNativeDriver,
        }).start(({ finished }) => {
          if (removeAfter && finished) {
            setShow(false);
          }
        });
      }
    }, delay);
  }, [direction, opacity, delay, duration, removeAfter]);

  return show ? (
    <Animated.View
      style={{
        opacity,
      }}
    >
      {children}
    </Animated.View>
  ) : null;
};
