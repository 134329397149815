// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type DeliveryMethodType } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method_type';
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import {
  ShipmentsEndpoint,
  type ShipmentsEndpointCalculateShipmentsResponse,
} from '@alto/scriptdash/alto/scheduling/patients/v1/shipments_endpoint';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useSelectorShared } from '~shared/store';

const shipmentsEndpoint = ShipmentsEndpoint(apiEndpointHandler);

type GetCartItemsByDeliveryMethodProps = {
  cartItems: CartItem[];
  addressID?: number;
};

export type CartItemsByDeliveryMethod = Partial<Record<DeliveryMethodType, CartItem[]>>;

const groupCartItemsByDeliveryMethod = ({
  data,
  cartItemByRxID,
}: {
  data: ShipmentsEndpointCalculateShipmentsResponse | undefined;
  cartItemByRxID: Record<number, CartItem>;
}) => {
  const cartItemsByDeliveryMethod: CartItemsByDeliveryMethod = {};
  const deliveryMethods: DeliveryMethod[] = [];
  const shipments = data?.data || [];

  shipments?.forEach((shipment) => {
    const { prescription_ids, delivery_method } = shipment;

    deliveryMethods.push(delivery_method);
    const deliveryMethodName = delivery_method.name;

    if (!cartItemsByDeliveryMethod[deliveryMethodName]) {
      cartItemsByDeliveryMethod[deliveryMethodName] = [];
    }

    prescription_ids.forEach((id) => {
      const cartItem = cartItemByRxID[id];

      if (cartItem) {
        cartItemsByDeliveryMethod[deliveryMethodName]?.push(cartItem);
      }
    });
  });

  return { cartItemsByDeliveryMethod, deliveryMethods };
};

const useCartItemsByDeliveryMethod = ({ cartItems, addressID }: GetCartItemsByDeliveryMethodProps) => {
  const editShipmentId = useSelectorShared(getEditShipmentID);
  const cartItemByRxID: Record<number, CartItem> = {};
  const prescriptionIDs = cartItems.reduce<number[]>((ids, item) => {
    if (item.resource_type === 'Prescription') {
      cartItemByRxID[item.resource_id] = item;
      ids.push(item.resource_id);
    }
    return ids;
  }, []);

  const { data } = useQuery({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['scheduling-shipments-calculateShipments', { prescriptionIDs, addressID }],
    queryFn: () =>
      shipmentsEndpoint.calculateShipments(
        { prescription_ids: prescriptionIDs, address_id: addressID ?? 0, shipment_id: editShipmentId },
        { splitArrayParams: true },
      ),
    select: (data: ShipmentsEndpointCalculateShipmentsResponse) => {
      return groupCartItemsByDeliveryMethod({ data, cartItemByRxID });
    },
    enabled: prescriptionIDs.length !== 0 && !!addressID,
  });

  return { data };
};

/**
 * fetches cart items by delivery method
 */
export const useCartDeliveryMethods = () => {
  const { cartItems } = useCartData();
  const hasFamily = useSelectorShared(getHasFamily);
  const address = useSelectorShared(getOrderAddress);
  const { data } = useCartItemsByDeliveryMethod({
    cartItems,
    addressID: address?.id,
  });

  const deliveryMethods = useMemo(() => {
    const allDeliveryMethods = data?.deliveryMethods ?? [];
    // edge-case for family accounts, each patient is a separate scheduling context which
    // creates a shipment, and all the shipments are merged to one shipment at double check.
    // Do not show split shipment checkout flow for the family account owner unless the delivery method differs
    // Filter for unique delivery methods.
    return hasFamily ? uniqBy(allDeliveryMethods, 'name') : allDeliveryMethods;
  }, [data?.deliveryMethods, hasFamily]);

  return {
    cartItemsByDeliveryMethod: data
      ? data.cartItemsByDeliveryMethod
      : deliveryMethods.reduce<CartItemsByDeliveryMethod>((cartItemsByDeliveryMethod, deliveryMethod) => {
          cartItemsByDeliveryMethod[deliveryMethod.name] = [];
          return cartItemsByDeliveryMethod;
        }, {}),
    deliveryMethods,
  };
};
