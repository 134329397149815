// @owners { team: patients-team }
import { Column, H3, MdSpacing } from '@alto/design-system';
import React, { useCallback, useRef } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { VideoPlayer } from './VideoPlayer';

export type YoutubePlayerProps = {
  readonly height?: number;
  readonly width?: number;
  readonly videoID: string;
  readonly origin: string;
  readonly title?: string;
};

export const YoutubePlayer = ({ height, width, videoID, origin, title }: YoutubePlayerProps) => {
  const hasPlayed = useRef(false);
  const { trackEvent } = useAnalytics();

  const onPlayVideo = useCallback(() => {
    // only call once so we log an analytic event for when the patient has played the video
    // For mobile https://lonelycpp.github.io/react-native-youtube-iframe/component-props#onchangestate
    // For web https://developers.google.com/youtube/iframe_api_reference#onStateChange
    if (!hasPlayed.current) {
      trackEvent({ event: EVENTS.INSTRUCTIONAL_VIDEO_PLAYED, params: { videoID, origin } });
      hasPlayed.current = true;
    }
  }, [hasPlayed, origin, trackEvent, videoID]);

  return (
    <Column>
      {title ? (
        <>
          <H3>{title}</H3>
          <MdSpacing />
        </>
      ) : null}
      <VideoPlayer
        height={height || 200}
        width={width}
        youtubeVideoID={videoID}
        onPlayVideo={onPlayVideo}
      />
    </Column>
  );
};
