// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoSpinningLoader, ListPage, Row, Separator, SmPadding, SmSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { Platform, RefreshControl } from 'react-native';
import { resetFilters } from '~shared/actions/ui/medList';
import noMedicationResultsImage from '~shared/assets/images/out_of_stock.png';
import transferPrescriptionImage from '~shared/assets/images/transfer_prescription.png';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useMedList } from '../queries/useMedList';
import { AddMedicationButton } from './AddMedicationButton';
import { MedListItem } from './MedListItem';
import { FamilyMemberFilterTag, FilterTypeTag, SortTag } from './filter-tags';

const Loader = () => (
  <Row
    centerHorizontally
    backgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
  >
    <SmPadding>
      <AltoSpinningLoader small={false} />
    </SmPadding>
  </Row>
);

const MIN_MEDS_TO_SHOW_FILTERS = 4;
const MedicationsLoader = () => {
  return (
    <ListPage.Root>
      <ListPage.Header.Root>
        <ListPage.Header.Content.Root>
          <Row
            centerVertically
            spaceBetween
            testID="loading"
          >
            <ListPage.Header.Content.Title>Medications</ListPage.Header.Content.Title>
            <Row flexShrink={1}>
              <AddMedicationButton />
            </Row>
          </Row>
        </ListPage.Header.Content.Root>
      </ListPage.Header.Root>
      <ListPage.Content.Loader listItemHeight={120} />
    </ListPage.Root>
  );
};

const NoMedicationResults = () => {
  const dispatch = useDispatchShared();
  const handleResetFilters = useCallback(() => {
    // retain sort and reset the filters for prescription status and family members
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <ListPage.Content.EmptyState
      ctaText="Reset filters"
      description="Try broadening your filters"
      image={noMedicationResultsImage}
      onPressCTA={handleResetFilters}
      title="No results"
    />
  );
};

const AddMedication = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const navigateToAddMedications = useCallback(() => {
    trackEvent({ event: EVENTS.ADD_MED_CLICKED });
    navigate('RouteTransferPrescription', { origin: ORIGIN_NAMES.MED_LIST });
  }, [navigate, trackEvent]);

  return (
    <ListPage.Content.EmptyState
      ctaText="Get started"
      description="Provide your current prescription information and we'll do the rest"
      image={transferPrescriptionImage}
      onPressCTA={navigateToAddMedications}
      title="Add a medication"
    />
  );
};

export const MedList = ({
  origin,
  useAddToCartInterstitialSheets,
  ViewCartComponent,
}: {
  readonly origin?: string;
  readonly useAddToCartInterstitialSheets: any;
  readonly ViewCartComponent?: ReactElement;
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const { trackPageView } = useAnalytics();
  const { setOptions } = useNavigation();
  const hasFamily = useSelectorShared(getHasFamily);
  const sortedBy = useSelectorShared((state) => state.persistedUI.medList.sortType);
  const filteredFor = useSelectorShared((state) => state.persistedUI.medList.filters);

  // setOptions is here to override the header options in TabNavigator.tsx - we don't show a header on this page
  useEffect(() => {
    setOptions({ headerShown: false });
  }, [setOptions]);

  const {
    areFiltersApplied,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPendingMedications,
    medications = [],
    refetch,
  } = useMedList();

  /**
   * We only want to show sorting and filters under these conditions:
   * 1. there are applied filters
   *    - we need to be able to update or clear filters if they're applied and the
   *    amount of returned meds is less than 4
   * 2. there are family members
   * 3. there are 4 or more medications
   */
  const shouldShowTags = areFiltersApplied || hasFamily || medications.length >= MIN_MEDS_TO_SHOW_FILTERS;

  useEffect(() => {
    trackPageView({
      event: EVENTS.MED_LIST_VIEWED,
      params: {
        origin,
        hasSortFeature: shouldShowTags,
        hasFamilyFilterFeature: (areFiltersApplied || medications.length >= MIN_MEDS_TO_SHOW_FILTERS) && hasFamily,
        hasFilterFeature: shouldShowTags,
        sortedBy,
        filteredFor,
      },
    });
  });

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      refetch();
      setRefreshing(false);
    }, 1000);
  }, [refetch]);

  if (isPendingMedications || refreshing) return <MedicationsLoader />;

  return (
    <ListPage.Root
      backgroundColor={Platform.OS !== 'web' ? COLORS.BACKGROUND_COLORS.GREY_LIGHTEST : COLORS.BACKGROUND_COLORS.WHITE}
    >
      <ListPage.Content.FlatList
        data={medications}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{ backgroundColor: COLORS.BACKGROUND_COLORS.WHITE, flexGrow: 1 }}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={areFiltersApplied ? NoMedicationResults : AddMedication}
        ListFooterComponent={isFetchingNextPage ? <Loader /> : null}
        ListHeaderComponent={
          <ListPage.Header.Root>
            <ListPage.Header.Content.Root>
              <Row
                wrap
                centerVertically
                spaceBetween
              >
                <ListPage.Header.Content.Title>Medications</ListPage.Header.Content.Title>
                <Row flexShrink={1}>
                  <AddMedicationButton />
                </Row>
              </Row>
              <SmSpacing />

              {shouldShowTags ? (
                <Row
                  centerVertically
                  gap="XXS"
                  wrap
                >
                  <SortTag />
                  <FilterTypeTag />
                  {hasFamily ? <FamilyMemberFilterTag /> : null}
                </Row>
              ) : undefined}
            </ListPage.Header.Content.Root>
          </ListPage.Header.Root>
        }
        onEndReached={() => {
          if (hasNextPage && !isFetchingNextPage) fetchNextPage();
        }}
        renderItem={({ item }) => (
          <MedListItem
            medication={item}
            useAddToCartInterstitialSheets={useAddToCartInterstitialSheets}
          />
        )}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      />
      {ViewCartComponent}
    </ListPage.Root>
  );
};
