// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getNextAvailableDateAddressID } from './getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from './getNextAvailableDateFacilityId';
import { getNextAvailableDates } from './getNextAvailableDates';
import { getNextAvailableDateID } from '~shared/features/next-available-date/helpers/getNextAvailableDateID';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { type NextAvailableDate } from '~shared/types/clients';

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getNextAvailableDateIDForPrescription__DEPRECATED = createSelector(
  [getNextAvailableDateAddressID, getNextAvailableDateFacilityID, getPrescriptionByID],
  (addressID, facilityID, prescription): string | undefined => {
    if (!prescription || !(addressID || facilityID)) {
      return undefined;
    }

    return getNextAvailableDateID({
      addressID,
      facilityID,
      prescriptionIDs: [prescription.id],
    });
  },
);

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getNextAvailableDateObjectForPrescription = createSelector(
  [getNextAvailableDates, getNextAvailableDateIDForPrescription__DEPRECATED],
  (nextAvailableDates, nextAvailableDateID): NextAvailableDate | undefined => {
    return nextAvailableDateID ? nextAvailableDates[nextAvailableDateID] : undefined;
  },
);

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getEarliestAvailableDateForPrescription__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForPrescription],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.earliest?.date || '';
  },
);

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getEarliestAvailableReasonForPrescription__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForPrescription],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.earliest?.reason || '';
  },
);

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getNextAutoRefillDateForPrescription__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForPrescription],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.nextAutoRefill || '';
  },
);

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getDeliveryMethodForPrescription__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForPrescription],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.deliveryMethod || '';
  },
);
