// @owners { team: patients-team }
import { COLORS, SIZES } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';

const StyledFullPageContainer = styled.div`
  display: flex;
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
`;

const StyledMaxWidthContainer = styled.div`
  display: flex;
  /* First value is the footer height and the second the navbar height */
  min-height: calc(100vh - ${SIZES.PAGE.WEB_FOOTER_HEIGHT.LG} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG});
  margin: 0 auto;
  width: 100%;
`;

const StyledDualContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `flex-direction: row;`}
`;

type Props = {
  readonly children: ReactNode;
};

export const FullPage = ({ children }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <StyledFullPageContainer>
      <StyledMaxWidthContainer>
        <StyledDualContainer isSMScreenOrBigger={isSMScreenOrBigger}>{children}</StyledDualContainer>
      </StyledMaxWidthContainer>
    </StyledFullPageContainer>
  );
};
