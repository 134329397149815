// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding } from '@alto/design-system';
import React, { useContext } from 'react';
import insuranceCard from '~shared/assets/images/insurance_card.png';

export const BenefitInputActionSheet = () => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  return (
    <ActionSheetV2
      title="Where can I find this?"
      illustrationSrc={insuranceCard}
      analyticsName="insurance input info"
      buttons={[
        <Button
          key="got-it"
          onPress={closeActionSheet}
        >
          Got It
        </Button>,
      ]}
    >
      <LgPadding
        bottomPadding={SPACING.STATIC.NONE}
        topPadding={SPACING.STATIC.NONE}
      >
        <Body>Typically your prescription insurance card will have an "Rx" symbol, and "BIN" and "PCN" numbers.</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
