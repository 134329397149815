import { createSelector } from 'reselect';
import { getAllAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { type ShipmentIDParamLong } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getShipments = (state: ReduxStateShared) => state.shipments.shipments;

export const getLastRequestAttemptedAt = (state: ReduxStateShared) => state.shipments.lastRequestAttemptedAt;
export const getRequestAttemptCount = (state: ReduxStateShared) => state.shipments.requestAttemptCount;
export const getLastRequestSucceededAt = (state: ReduxStateShared) => state.shipments.lastRequestSucceededAt;

const getShipmentID = (_: ReduxStateShared, { shipmentID }: ShipmentIDParamLong) => shipmentID;

export const getShipmentWithID = createSelector([getShipments, getShipmentID], (shipments, shipmentID) => {
  return shipments.find((shipment) => String(shipment.id) === String(shipmentID));
});

export const getShipmentAddress = createSelector([getShipmentWithID, getAllAddresses], (shipment, addresses) => {
  return addresses.find((address) => address.id === shipment?.address_id);
});

export default getShipments;
