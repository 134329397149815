// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Column,
  H1,
  ListButton,
  MdPadding,
  MdSpacing,
  SecondaryPage,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { Fragment, useEffect } from 'react';
import { selectInsurance } from '~shared/actions/insurances';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { useGetInsurancesByUserID } from '~shared/queries/query-keys/insurances';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types';
import { InsuranceCard } from '../../onboarding';
import { FamilyMemberProgressBar } from './FamilyMemberProgressBar';

const isWeb = getPlatformOS() === 'web';

export const FamilyMemberCompleteProfile = () => {
  const dispatch = useDispatchShared();
  const { navigate } = useNavigation<'RouteFamilyMemberCompleteProfile'>();
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const selectedUser = useSelectorShared(getSelectedUser);
  const userID = selectedUser?.id;
  const { insurances } = useGetInsurancesByUserID({ userID });
  const familyMemberName = selectedUser?.preferred_first_name ?? selectedUser?.first_name ?? 'family member';

  useEffect(() => {
    if (!relationship) {
      navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
    }
  }, [navigate, relationship]);

  const handleCompleteProfile = () => {
    navigate('RouteFamilyMemberAdded');
  };

  const handleAddAnotherBenefit = () => {
    if (!userID) return;
    navigate('RouteInsuranceAdd', { source: 'add family member', userID });
  };

  const handleEditInsurance = (insurance: Insurance) => {
    if (!userID) return;
    dispatch(selectInsurance(insurance)); // for web
    navigate('RouteInsuranceForm', { insuranceID: insurance.id, source: 'add family member', userID });
  };

  return (
    <SecondaryPage
      buttons={[
        <Button
          accessibilityLabel={`Press to complete ${getPossessiveName(familyMemberName)} profile`}
          key="complete-profile"
          label="Complete profile"
          onPress={handleCompleteProfile}
        />,
      ]}
      dismissIcon="chevronleft"
      onDismiss={() => {
        if (!userID) return;
        // navigate to prev screen; cannot pass navigation params to goBack
        navigate('RouteFamilyMemberBenefits', { userID });
      }}
      headerBackgroundColor={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      HeaderContent={
        <MdPadding topPadding={SPACING.STATIC.NONE}>
          <Column center>
            <FamilyMemberProgressBar />
            <XlSpacing />
            <H1 center>{`Complete ${getPossessiveName(familyMemberName)} profile:\nAdd benefits`}</H1>
            <XsSpacing />
            <Body center>Add all insurance and prescription savings cards so we can get you the best pricing.</Body>
            <MdSpacing />
          </Column>
        </MdPadding>
      }
      footerPlacementContext={isWeb ? 'webScreenWithoutPolicyFooter' : undefined}
    >
      {insurances.map((insurance, index) => {
        return (
          <Fragment key={`insurance-card-${insurance.id}`}>
            <InsuranceCard
              insurance={insurance}
              onEditInsurance={() => {
                handleEditInsurance(insurance);
              }}
            />
            {index !== insurances.length - 1 ? <MdSpacing /> : null}
          </Fragment>
        );
      })}
      <MdSpacing />
      <ListButton
        onPress={handleAddAnotherBenefit}
        label="Add another benefit"
        rightIcon="plus"
      />
    </SecondaryPage>
  );
};
