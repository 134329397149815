// @owners { team: patients-team }
import { ActionSheetContext, AltoIcon, Card, ListItem } from '@alto/design-system';
import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import { selectMedication } from '~shared/actions/ui/medications';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantPressedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { reasonIsRefillTooSoon } from '~shared/features/delivery/helpers';
import { MULTIPLE_ACTIVE_RX_READY, PROCESSING, RX_READY } from '~shared/features/my-meds/constants';
import { makeGetMedicationTagKey } from '~shared/features/my-meds/selectors/getMedicationTagKey';
import { type Medication } from '~shared/features/my-meds/types';
import {
  getEarliestAvailableDateForPrescription__DEPRECATED,
  getEarliestAvailableReasonForPrescription__DEPRECATED,
} from '~shared/features/next-available-date/selectors/getNextAvailableDateForPrescription';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useNavigation } from '../../../../../../navigation';
import {
  MedicationImage,
  NextAvailableDateActionSheet,
  NextAvailableDateInfoRow,
  OptionsToGetItSoonerActionSheet,
  getMedImageURL,
} from '../../../../../index';

type MedicationRowProps = {
  readonly medication: Medication;
  readonly showPrescriptionTracker?: boolean;
  readonly preferredDateUnavailable?: boolean;
  readonly loadingNextAvailableDate?: boolean;
};

const MedicationRow = ({
  medication,
  showPrescriptionTracker,
  preferredDateUnavailable,
  loadingNextAvailableDate,
}: MedicationRowProps) => {
  const { navigate } = useNavigation();

  const imageUrl = getMedImageURL(medication);
  const dispatch = useDispatchShared();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);

  const earliestAvailableDate = useSelectorShared((state) =>
    getEarliestAvailableDateForPrescription__DEPRECATED(state, medication.representativePrescription.id),
  );
  const earliestAvailableReason = useSelectorShared((state) =>
    getEarliestAvailableReasonForPrescription__DEPRECATED(state, medication.representativePrescription.id),
  );
  const prescription = useSelectorShared((state) =>
    getPrescriptionByID(state, medication.representativePrescription.id),
  );

  const tagKey = useSelectorShared((state) =>
    makeGetMedicationTagKey(state, {
      medication,
      userID: medication.userID,
    }),
  );

  const navigateToMessages = (subject: string) => {
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_MED_SELECTION,
      category: ASSISTANT_CATEGORIES.ORDERING,
      messagePrompt: MESSAGE_PROMPTS.HELP_WITH_ORDER,
      cacheKey: preferredDateUnavailable ? 'assistant-preferred-date-unavailable' : 'assistant',
      subject,
    });
  };

  const isRefillTooSoon = reasonIsRefillTooSoon(earliestAvailableReason);

  const openNextAvailableDateOrGetItSoonerFlow = () => {
    if (isRefillTooSoon && prescription) {
      setActiveActionSheet(
        <OptionsToGetItSoonerActionSheet
          prescription={prescription}
          handleModalClose={closeActionSheet}
          analyticsOrigin={ASSISTANT_CHAT_ORIGINS.ORDERING}
          showHelpButtons={preferredDateUnavailable}
        />,
      );
    } else {
      setActiveActionSheet(
        <NextAvailableDateActionSheet
          earliestAvailableDate={earliestAvailableDate}
          earliestDateReason={earliestAvailableReason}
          prescriptionID={medication.representativePrescription.id}
          analyticsOrigin={ASSISTANT_CHAT_ORIGINS.ORDERING}
          showHelpButtons={preferredDateUnavailable}
        />,
      );
    }
  };

  const onMedicationSelect = () => {
    dispatch(
      sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.MEDICATION_PICKER_CHOSEN_MED, {
        medication_status: tagKey.key,
      }),
    );
    dispatch(selectMedication(medication.key, medication.medicationName));
    if (preferredDateUnavailable) {
      if (earliestAvailableDate) {
        openNextAvailableDateOrGetItSoonerFlow();
      } else {
        navigateToMessages(`My preferred date for ${medication.medicationName} is unavailable`);
      }
    } else if (tagKey.key === MULTIPLE_ACTIVE_RX_READY || tagKey.key === RX_READY) {
      // tagkey contains the current status of the medication.
      // if classification of the medication is "ready", navigate to med details
      navigate('RouteMedDetails', { primaryPrescriptionId: medication.representativePrescription.id });
      // if the tagkey classification of the medication is in Processing status, show the order in progress action sheet
    } else if (tagKey.key === PROCESSING && showPrescriptionTracker) {
      navigate('RoutePrescriptionStatusPage', { medication });
    } else {
      navigateToMessages(medication.medicationName);
    }
  };

  return (
    <ListItem
      onPress={onMedicationSelect}
      title={medication.medicationName}
      descriptions={
        preferredDateUnavailable && earliestAvailableDate
          ? [
              <NextAvailableDateInfoRow
                key={earliestAvailableDate}
                isLoading={loadingNextAvailableDate || false}
                earliestAvailableDate={earliestAvailableDate}
                earliestAvailableReason={earliestAvailableReason}
                prescriptionID={medication.representativePrescription.id}
                excludeDayOfWeek
                analyticsOrigin={ASSISTANT_CHAT_ORIGINS.ORDERING}
                disabled
              />,
            ]
          : []
      }
      LeftContent={<MedicationImage imageUrl={imageUrl} />}
      RightContent={<AltoIcon name="chevronright-small" />}
    />
  );
};

export type Props = {
  readonly medications: Medication[];
  readonly showPrescriptionTracker?: boolean;
  readonly preferredDateUnavailable?: boolean;
};

export const MedicationRows = ({ medications, showPrescriptionTracker, preferredDateUnavailable }: Props) => {
  const dispatch = useDispatchShared();
  const { overrideActionSheetStack } = useContext(ActionSheetContext);
  const [loadingNextAvailableDate, setLoadingNextAvailableDate] = useState(false);

  useEffect(() => {
    const fetchNAD = async () => {
      setLoadingNextAvailableDate(true);
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchNextAvailableDates());
      setLoadingNextAvailableDate(false);
    };
    if (preferredDateUnavailable) {
      overrideActionSheetStack([]);
      fetchNAD().catch(() => {
        setLoadingNextAvailableDate(false);
      });
    }
  }, [dispatch, overrideActionSheetStack, preferredDateUnavailable]);

  return (
    <Card overflow="hidden">
      {medications.map((medication) => (
        <MedicationRow
          key={medication.medicationName}
          medication={medication}
          showPrescriptionTracker={showPrescriptionTracker}
          preferredDateUnavailable={preferredDateUnavailable}
          loadingNextAvailableDate={loadingNextAvailableDate}
        />
      ))}
    </Card>
  );
};
