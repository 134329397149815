// @owners { team: patients-team }
import { useMemo } from 'react';
import { useSelectorShared } from '~shared/store';
import { useQueryEssentialsFeeConfigs } from '../queries/useQueryEssentialsFeeConfigs';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const imageEssentialsBanner = require('../assets/essentials-banner.png');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const imageEssentialsIcon = require('../assets/essentials-icon.png');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const imageEssentialsItems = require('../assets/essentials-items.png');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const imageEssentialsTitle = require('../assets/essentials-title.png');
const upSellCountThreshold = 3;

export const useEssentialsStoreCard = () => {
  const viewUpSellCount = useSelectorShared((state) => state.essentials.viewStoreUpsellCount);
  const showEssentialCard = viewUpSellCount < upSellCountThreshold;
  const showEssentialListItem = viewUpSellCount >= upSellCountThreshold;
  const { essentialsFreeDeliveryText } = useQueryEssentialsFeeConfigs();

  return useMemo(
    () => ({
      showEssentialListItem,
      showEssentialCard,
      upSellCountThreshold,
      upSellCardButton: 'Browse products',
      upSellCardTitle: 'Shop for Essentials',
      upSellCardSubtitle: essentialsFreeDeliveryText,
      imageEssentialsIcon,
      imageEssentialsBanner,
      imageEssentialsTitle,
      imageEssentialsItems,
    }),
    [essentialsFreeDeliveryText, showEssentialCard, showEssentialListItem],
  );
};
