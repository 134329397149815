import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
// eslint-disable-next-line import/no-deprecated
import { CenteredActivityIndicator } from '../../../loaders';
import { Body } from '../../../typography';

const SideTextContainer = styled(View)`
  justify-content: center;
  align-items: center;
  min-width: ${SPACING.STATIC.LG.px};
  border-radius: ${SPACING.STATIC.SM.px};
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER};
  padding: 0 ${SPACING.STATIC.XS.px};
`;

type Props = {
  readonly children: string | string[];
  readonly loading?: boolean;
};

export const ButtonSideCopy = ({ children, loading }: Props) => {
  return loading ? (
    <CenteredActivityIndicator />
  ) : (
    <SideTextContainer>
      <Body color={COLORS.TEXT_COLORS.PRIMARY}>{children}</Body>
    </SideTextContainer>
  );
};
