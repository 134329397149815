import { createSelector } from 'reselect';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getIsFertilityUserByID } from '~shared/features/users/selectors/getIsFertilityUser';

export const getPrescriptionUserID = createSelector([getPrescriptionByID], (prescription) => prescription?.user_id);

export const getPrescriptionUserIsFertility = createSelector(
  [getPrescriptionUserID, getIsFertilityUserByID],
  (userID, isFertilityUser) => {
    if (!userID) return false;

    return !!isFertilityUser[userID];
  },
);
