// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ListPage, Row, XlSpacing, isSMScreenOrBigger } from '@alto/design-system';
import React from 'react';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useNavigation } from '../../../../navigation';

const isDesktopWeb = getPlatformOS() === 'web' && isSMScreenOrBigger();
export const NotificationLoader = () => {
  const { goBack } = useNavigation();

  return (
    <ListPage.Root>
      {getPlatformOS() === 'web' ? (
        <Row backgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}>
          <XlSpacing />
        </Row>
      ) : null}
      <ListPage.Header.Root
        backgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
        onDismiss={goBack}
        CenterContent={<ListPage.Header.Content.Title>Notifications</ListPage.Header.Content.Title>}
      >
        {isDesktopWeb ? <XlSpacing /> : null}
      </ListPage.Header.Root>
      <ListPage.Content.Loader listItemHeight={120} />
    </ListPage.Root>
  );
};
