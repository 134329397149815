// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, Column, H1, Link, MdSpacing, SecondaryPage, XlPadding } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useCancelAddingBenefit } from '../../insurance/hooks/useCancelAddingBenefit';
import { BenefitForm } from './BenefitForm';
import { BenefitInputActionSheet } from './BenefitInputActionSheet';

const isWeb = getPlatformOS() === 'web';

export const BenefitPage = () => {
  const { trackPageView } = useAnalytics();
  const { getParam } = useNavigation<'RouteInsuranceForm'>();
  const insuranceID = getParam('insuranceID', undefined);
  const source = getParam('source', 'profile');
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { handleGoBack } = useCancelAddingBenefit({ source });

  const handlePressWhereToFindIt = () => {
    setActiveActionSheet(<BenefitInputActionSheet />);
  };

  useEffect(() => {
    trackPageView({
      event: EVENTS.ADD_INSURANCE_VIEWED,
      params: { source, method: 'manual' },
    });
  }, [trackPageView, source]);

  return (
    <SecondaryPage
      onDismiss={handleGoBack}
      dismissIcon="chevronleft"
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      HeaderContent={
        <XlPadding>
          <Column center>
            <H1>Enter benefit details</H1>
            <MdSpacing />
            <Link onPress={handlePressWhereToFindIt}>Where can I find this?</Link>
          </Column>
        </XlPadding>
      }
      footerPlacementContext={isWeb ? 'webScreenWithoutPolicyFooter' : undefined}
      withoutWebFramingElementHeights={isWeb}
    >
      <BenefitForm
        insuranceID={insuranceID}
        source={source}
        onCancel={handleGoBack}
      />
    </SecondaryPage>
  );
};
