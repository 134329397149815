import {
  type ActionSetBadgeCounts,
  type ActionViewNotice,
  type BadgeCounts,
  SET_BADGE_COUNTS,
  VIEW_NOTICE,
} from '~shared/actions/notices';
import { REHYDRATE } from '~shared/constants';
import { type NoticeNames } from '~shared/features/notices/selectors/getNotices';
import { type ReduxStateShared } from '~shared/types';

export type StateNotices = {
  viewed: Record<NoticeNames, string | null | undefined>;
  badgeCounts: BadgeCounts;
};

export const initialState: StateNotices = {
  viewed: {
    CheckoutNotice: null,
    FamilyAccountBetaNotice: null,
    AutoRefillNotice: null,
    RemindersNotice: null,
    AutoRefillNudgeCardNotice: null,
    AutoRefillCrossSellNotice: null,
    PostDeliveryModalNotice: null,
    AutoRefillManagementNotice: null,
  },
  badgeCounts: {
    messages: 0,
    patientTasks: 0,
  },
};

type RehydrateAction = {
  type: typeof REHYDRATE;
  payload: ReduxStateShared;
};

type Action = ActionSetBadgeCounts | ActionViewNotice | RehydrateAction;

export default (state: StateNotices = initialState, action: Action): StateNotices => {
  /**
   * This reducer's state is persisted in local storage, so merge
   * current state with initial to handle the later addition of any
   * new state keys.
   */
  state = { ...initialState, ...state };
  state.viewed = { ...initialState.viewed, ...state.viewed };
  state.badgeCounts = { ...initialState.badgeCounts, ...state.badgeCounts };

  const { type, payload } = action;

  switch (type) {
    case REHYDRATE:
      if (action.payload.notices) {
        return { ...state, ...action.payload.notices };
      }

      return { ...state };

    case VIEW_NOTICE:
      if (typeof payload === 'string') {
        return { ...state, viewed: { ...state.viewed, [payload]: new Date().toISOString() } };
      }

      return state;

    case SET_BADGE_COUNTS: {
      const { payload } = action;
      return { ...state, badgeCounts: { ...state.badgeCounts, ...payload } };
    }

    default:
      return state;
  }
};
