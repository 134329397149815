// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { MdPadding, SmSpacing } from '@alto/design-system';
import {
  InsuranceBinInput,
  InsuranceGroupInput,
  InsuranceMemberIdInput,
  InsuranceNicknameInput,
  InsurancePcnInput,
  InsuranceRelationshipInputSelect,
} from '@alto/features';
import React from 'react';
import { RELATIONSHIP_CODES } from '~shared/constants';
import { type Values } from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { createValidator, validatePresentIf, validatePresentWithMessage } from '~shared/validations';

type Props = {
  readonly values: Values;
  readonly submitCount: number;
  readonly errors: Record<string, string>;
  readonly handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T,
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    ): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
};

export const validate = createValidator<Values>({
  relationship_code: validatePresentWithMessage('Relationship'),
  first_name: validatePresentIf((_, values) => {
    return values.relationship_code !== RELATIONSHIP_CODES.SELF;
  }, 'First Name'),
  last_name: validatePresentIf((_, values) => {
    return values.relationship_code !== RELATIONSHIP_CODES.SELF;
  }, 'Last Name'),
  member_id: validatePresentWithMessage('Member ID'),
  bin: validatePresentWithMessage('BIN'),
});

export const InsuranceFormFields = ({ values, submitCount, errors, handleChange }: Props) => {
  const hasSubmitted = submitCount > 0;
  const getErrorForField = (fieldName: keyof Values) => {
    return hasSubmitted ? errors[fieldName] : undefined;
  };
  return (
    <>
      <SmSpacing />
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <InsuranceMemberIdInput
          error={getErrorForField('member_id')}
          onChange={handleChange('member_id')}
          value={values.member_id ?? ''}
        />
      </MdPadding>
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <InsuranceBinInput
          error={getErrorForField('bin')}
          onChange={handleChange('bin')}
          value={values.bin ?? ''}
        />
      </MdPadding>
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <InsuranceGroupInput
          error={getErrorForField('group')}
          value={values.group ?? ''}
          onChange={handleChange('group')}
        />
      </MdPadding>
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <InsurancePcnInput
          error={getErrorForField('pcn')}
          onChange={handleChange('pcn')}
          value={values.pcn ?? ''}
        />
      </MdPadding>
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <InsuranceRelationshipInputSelect
          firstName={values.first_name}
          firstNameError={getErrorForField('first_name')}
          lastName={values.last_name}
          lastNameError={getErrorForField('last_name')}
          onChangeFirstName={handleChange('first_name')}
          onChangeLastName={handleChange('last_name')}
          onChangeRelationship={handleChange('relationship_code')}
          relationshipCode={values.relationship_code ?? ''}
          relationshipCodeError={getErrorForField('relationship_code')}
        />
      </MdPadding>
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <InsuranceNicknameInput
          error={getErrorForField('nickname')}
          value={values.nickname ?? ''}
          onChange={handleChange('nickname')}
        />
      </MdPadding>
      <SmSpacing />
    </>
  );
};
