// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetToast,
  Body,
  type DateData,
  DatePickerActionSheet,
  LgPadding,
  SmPadding,
  type ToastVariant,
  fromDateData,
  toDateData,
} from '@alto/design-system';
import { format, isSameDay, isValid, parse, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { getInvalidDateKey } from '~shared/features/cart/hooks/useCartDateInfoKey';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { dateInfoKeysForToast, getDateValidationErrors } from '~shared/features/checkout/hooks/useCheckoutDateErrors';
import { useDatePickerViewedEvent } from '~shared/features/checkout/hooks/useDatePickerViewedEvent';
import { getDisabledDates } from '~shared/features/next-available-date/helpers/getDisabledDates';
import { formatRelativeDateWithDow } from '~shared/helpers/date';
import { ContactUs } from '../../../alto-assistant';
import { DelayedAvailabilityInlineAlert } from '../select-date-card/DelayedAvailabilityInlineAlert';

type Props = {
  readonly minDate: string;
  readonly orderBundlingDates: string[]; //do not allow patient to select order bundling date in the calendar
  readonly handleDateSelection: (dateData: DateData) => void;
  readonly openGetItSoonerFlow: () => void;
};

type ToastProps = {
  readonly message: string;
  readonly variant: ToastVariant;
};

export const SelectCustomDateActionSheet = ({
  minDate,
  orderBundlingDates,
  handleDateSelection,
  openGetItSoonerFlow,
}: Props) => {
  const [toast, setToast] = useState<ToastProps | null>(null);
  const {
    earliestAvailableDate,
    lastAvailableDate,
    lastAvailableDateReason,
    excludedDates = [],
  } = useCartNextAvailableDate();
  const formattedLastAvailableDate = lastAvailableDate
    ? format(parse(lastAvailableDate, 'yyyy-MM-dd', 0), 'EEE, MMM do')
    : null;
  const hasProgynyAuthEndDate = !!(
    lastAvailableDate &&
    lastAvailableDateReason === 'progyny_authorization_expiration' &&
    formattedLastAvailableDate
  );
  const disabledDates = getDisabledDates(excludedDates).concat(orderBundlingDates);

  useDatePickerViewedEvent();

  const validateDate = useCallback(
    (date: DateData) => {
      const parsedDate = new Date(date.timestamp);
      const invalidDateKey = getInvalidDateKey({
        date: parsedDate,
        earliestAvailableDate,
        excludedDates,
        lastAvailableDate,
      });

      if (invalidDateKey && dateInfoKeysForToast.has(invalidDateKey)) {
        const errors = getDateValidationErrors({
          dateInfoKeyApplied: invalidDateKey,
          earliestAvailableDate,
          lastAvailableDate,
        });
        // there's currently no way to show a toast above a react-native modal
        // create a toast manually to show above the modal content for native and web
        setToast({ message: errors[0].message, variant: 'error' });
      }

      if (orderBundlingDates.includes(fromDateData(date))) {
        setToast({
          message:
            'There is another order scheduled for this day. Please select it from the previous menu to bundle your orders.',
          variant: 'neutral',
        });
      }
    },
    [earliestAvailableDate, excludedDates, lastAvailableDate, orderBundlingDates],
  );

  return (
    <DatePickerActionSheet
      closeOnSelect={false}
      disabledDates={disabledDates}
      modalTitle="Select delivery date"
      selectedDate={toDateData(minDate)}
      minDate={minDate}
      maxDate={lastAvailableDate}
      onDayPress={validateDate}
      onDaySelect={handleDateSelection}
      testID="select-delivery-date"
    >
      {!isSameDay(new Date(), parseISO(earliestAvailableDate)) && isValid(parseISO(earliestAvailableDate)) ? (
        <LgPadding
          bottomPadding={SPACING.STATIC.NONE}
          topPadding={SPACING.STATIC.NONE}
        >
          <DelayedAvailabilityInlineAlert
            nextAvailableDateTitle={
              'Get it as early as ' + formatRelativeDateWithDow(earliestAvailableDate, true) + '.'
            }
            openGetItSoonerFlow={openGetItSoonerFlow}
          />
        </LgPadding>
      ) : null}
      <SmPadding />
      {hasProgynyAuthEndDate ? (
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <Body>
            Your Progyny authorization is valid until {formattedLastAvailableDate}. If you need to schedule a delivery
            beyond your active coverage dates, please send us a message, or if urgent, please{' '}
            <ContactUs
              origin={CONTACT_US_ORIGIN.DatePickerActionSheet}
              type="link"
              method="phone"
            />
            .
          </Body>
        </LgPadding>
      ) : null}
      {toast ? (
        <ActionSheetToast
          variant={toast.variant}
          dismissible
          onHide={() => {
            setToast(null);
          }}
        >
          {toast.message}
        </ActionSheetToast>
      ) : null}
    </DatePickerActionSheet>
  );
};
