import { type Middleware } from 'redux';
import { eventsGenerated } from './actions';
import getAnalyticsEvents from './getAnalyticsEvents';
import { type ReduxDispatch, type ReduxStateShared } from '~shared/types';

export const analyticsMiddleware: Middleware<unknown, ReduxStateShared, ReduxDispatch> =
  (store) => (next) => (action) => {
    const state = store.getState();
    const result = next(action);
    const nextState = store.getState();

    // eslint-disable-next-line promise/catch-or-return
    getAnalyticsEvents(action, state, nextState).then((events) => {
      // eslint-disable-next-line promise/always-return
      if (events.length > 0) {
        store.dispatch(eventsGenerated(events));
      }
    });

    return result;
  };
