// @owners { team: patients-team }
import { getPlatformOS } from '~shared/helpers/getPlatformOS';

// staging on web is actually a sandbox, but we are currently using sandbox to refer to the dev environment so check current url
// if the current url includes .stg then we can determine that it needs to staging api key
const isWebStaging = (backendEnv: string) => {
  return getPlatformOS() === 'web' && backendEnv === 'sandbox' && window.location.origin.includes('app.stg.alto.com');
};

type GrowthbookApiKeyProps = {
  env: string;
  production: string;
  staging: string;
  sandbox: string;
};

export const getGrowthbookApiKey = ({ env, production, staging, sandbox }: GrowthbookApiKeyProps) => {
  if (isWebStaging(env)) {
    return staging;
  }
  switch (env) {
    case 'staging':
      return staging;
    case 'production':
      return production;
    default:
      return sandbox;
  }
};
