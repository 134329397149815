import { type ColorVariant, type SmallIconName } from '@alto/design-library-tokens';
import React from 'react';
import { Pressable } from 'react-native';
import { Row } from '../../../containers';
import { AltoIcon } from '../../../icon';
import { getTextColorFromType } from '../../../icon/src/getTextColorFromType';
import { AltoSpinningLoader } from '../../../loaders';
import { XsSpacing } from '../../../separators';
import { Description } from '../../../typography';

type Position = 'left' | 'right';

export type ListDescriptionProps = {
  readonly children?: string | React.ReactElement<typeof Description>;
  readonly iconName?: SmallIconName;
  readonly loading?: boolean;
  readonly onPress?: () => void;
  readonly position?: Position;
  readonly semibold?: boolean;
  readonly type?: ColorVariant;
  readonly fullWidth?: boolean;
};

export const ListDescription = ({
  children,
  iconName,
  loading,
  onPress,
  position = 'left',
  semibold,
  type = 'grey',
  fullWidth = false,
}: ListDescriptionProps) => {
  const textColor = getTextColorFromType(type);
  const Content = loading ? (
    <Row centerHorizontally>
      <AltoSpinningLoader small />
    </Row>
  ) : (
    <Row flexShrink={1}>
      {!!iconName && position === 'left' && (
        <>
          <AltoIcon
            name={iconName}
            type={type}
          />
          <XsSpacing />
        </>
      )}
      <Row
        centerVertically
        flexShrink={fullWidth ? 0 : 1}
        wrap
      >
        <Description
          fontFamily={semibold ? 'semibold' : undefined}
          color={textColor}
        >
          {children}
        </Description>
      </Row>
      {!!iconName && position === 'right' && (
        <>
          <XsSpacing />
          <AltoIcon
            name={iconName}
            type={type}
          />
        </>
      )}
    </Row>
  );

  return onPress ? (
    <Pressable
      onPress={onPress}
      accessibilityRole="button"
    >
      {Content}
    </Pressable>
  ) : (
    Content
  );
};
