import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import { getNextDeliveryForPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { formatNameWithCommas, getDoctorNameOrEmptyString, isAlternativeMed } from '~shared/features/my-meds/helper';
import { type Medication } from '~shared/features/my-meds/types';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import {
  getIsPrescriptionScheduleable,
  isDeliveryForRefillTooSoon,
} from '~shared/features/prescriptions/selectors/getScheduleableForPrescriptionID';
import { formatRelativeDate } from '~shared/helpers/date';
import { useSelectorShared } from '~shared/store';

export const InfoboxFlowType = {
  ALTERNATIVE_MED: 'ALTERNATIVE_MED',
  CLARIFICATION: 'CLARIFICATION',
  MENOPUR: 'MENOPUR',
  PRIOR_AUTH: 'PRIOR_AUTH',
  REFILL_TOO_SOON: 'REFILL_TOO_SOON',
  EARLY_ORDER_ELIGIBLE: 'EARLY_ORDER_ELIGIBLE',
  EARLY_ORDER_REQUESTED: 'EARLY_ORDER_REQUESTED',
  PROGYNY_EXPIRED_COVERAGE: 'PROGYNY_EXPIRED_COVERAGE',
};

const InfoboxFlowTitle = {
  [InfoboxFlowType.ALTERNATIVE_MED]: 'Alternative medication authorized',
  [InfoboxFlowType.CLARIFICATION]: 'Clarification in progress',
  [InfoboxFlowType.PRIOR_AUTH]: 'Prior authorization in progress',
  [InfoboxFlowType.REFILL_TOO_SOON]: 'Too early to refill',
  [InfoboxFlowType.EARLY_ORDER_ELIGIBLE]: 'Waiting on Progyny',
  [InfoboxFlowType.EARLY_ORDER_REQUESTED]: 'Early order requested',
  [InfoboxFlowType.PROGYNY_EXPIRED_COVERAGE]: 'Waiting on Progyny',
};

type InfoboxFlowInfo = {
  type: string;
  title: string;
  message: string;
  extraDetailsLinkText?: string;
}; // Custom hook for powering the med details infobox components in web and native.

export const useMedInfoboxFlows = (medication: Medication): InfoboxFlowInfo | null | undefined => {
  const prescription = medication.representativePrescription;
  const prescriptionID = prescription.id;
  const providerName = getDoctorNameOrEmptyString(prescription?.doctor);
  const isScheduleable = useSelectorShared((state) => getIsPrescriptionScheduleable(state, { prescriptionID }));
  const nextDelivery = useSelectorShared((state) => getNextDeliveryForPrescriptionID(state, { prescriptionID }));
  const { nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID });
  const earliestAvailableDate = nextAvailableDate?.earliest?.date;

  if (nextDelivery?.prior_authorization_status === 'pending') {
    return {
      type: InfoboxFlowType.PRIOR_AUTH,
      title: InfoboxFlowTitle[InfoboxFlowType.PRIOR_AUTH],
      message: 'Your insurance needs additional details from your provider before covering this medication.',
      extraDetailsLinkText: 'What does this mean?',
    };
  }

  if (nextDelivery?.internal_status === 'rph_clarification') {
    return {
      type: InfoboxFlowType.CLARIFICATION,
      title: InfoboxFlowTitle[InfoboxFlowType.CLARIFICATION],
      message: `We're waiting on additional details on your prescription from your provider${formatNameWithCommas(
        providerName,
        true,
      )}`,
      extraDetailsLinkText: 'What does this mean?',
    };
  }

  if (isDeliveryForRefillTooSoon(nextDelivery) && isScheduleable) {
    // A refill too soon prescription can still be scheduled for delivery as long as it's for the next available date
    // and beyond. Once a delivery has been scheduled, there is no need to explain why the prescription can't be
    // delivered earlier under the refill too soon case, though the billing status remains unchanged. In addition,
    // because the next available date request only includes scheduleable prescriptions, without the isScheduleable
    // check, no next available date would be found for the prescription.
    return {
      type: InfoboxFlowType.REFILL_TOO_SOON,
      title: InfoboxFlowTitle[InfoboxFlowType.REFILL_TOO_SOON],
      message: earliestAvailableDate
        ? `Your insurance will cover a refill for this medication after: ${formatRelativeDate(earliestAvailableDate, {
            includeRelative: true,
          })}`
        : '',
      extraDetailsLinkText: 'Get it sooner',
    };
  }

  if (isAlternativeMed(medication)) {
    return {
      type: InfoboxFlowType.ALTERNATIVE_MED,
      title: InfoboxFlowTitle[InfoboxFlowType.ALTERNATIVE_MED],
      message: `Your provider${formatNameWithCommas(
        providerName,
        false,
      )}updated your prescription to an equivalent medication.`,
      extraDetailsLinkText: 'What does this mean?',
    };
  }

  if (prescription.status === PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE) {
    return {
      type: InfoboxFlowType.EARLY_ORDER_ELIGIBLE,
      title: InfoboxFlowTitle[InfoboxFlowType.EARLY_ORDER_ELIGIBLE],
      message:
        'Progyny authorization is needed for new cycles and can take 1-7 days. Use "order early" to speed things up.',
      extraDetailsLinkText: 'Learn more',
    };
  }

  if (prescription.status === PrescriptionStatusMap.PROGYNY_EARLY_ORDER_REQUESTED) {
    return {
      type: InfoboxFlowType.EARLY_ORDER_REQUESTED,
      title: InfoboxFlowTitle[InfoboxFlowType.EARLY_ORDER_REQUESTED],
      message: 'We are processing your request and will get back to you soon.',
    };
  }

  if (prescription.status === PrescriptionStatusMap.PROGYNY_EXPIRED_COVERAGE) {
    return {
      type: InfoboxFlowType.PROGYNY_EXPIRED_COVERAGE,
      title: InfoboxFlowTitle[InfoboxFlowType.PROGYNY_EXPIRED_COVERAGE],
      message:
        'Your Progyny authorization expired. If you need this medication, please reach out to us so we can request an extension or new authorization.',
    };
  }

  return undefined;
};
