// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Button,
  Column,
  Description,
  H3,
  InputSelect,
  InputText,
  KeyboardAvoidingScrollView,
  LgPadding,
  LgSpacing,
  MdSpacing,
  XsSpacing,
  XxsPadding,
  XxsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { type TextInput } from 'react-native';
import { setDraftMessage } from '~shared/actions/altoAssistant';
import { ASSISTANT_PRESSED_EVENTS, ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  type ActionSheetProviderStackCacheKey,
  type AssistantCategory,
  type AssistantChatOrigin,
} from '~shared/features/alto-assistant/constants';
import { MESSAGE_RESPONSE_TIME, MESSAGE_TAGS, type MessageTag } from '~shared/features/messages/constants';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getDraftMessage } from '~shared/selectors/alto-assistant/getDraftMessage';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { useGetSupportCases } from '../../queries/queries';
import { useSendMessage } from '../../queries/useSendMessage';
import { type FaqActionSheet, getFaqsForMessageBody } from '../faq/helpers';

export type Params = {
  cacheKey?: ActionSheetProviderStackCacheKey; // used to restore an action sheet stack
  category: AssistantCategory; // assistant category for the message sent
  description?: string; // mini gray text beneath subject field
  isEditableSubject?: boolean; // whether the topic/subject input is editable
  message?: string; // body of the message
  onPressBack?: () => void; // navigate back and maybe restore action sheet stack
  onSubmitSuccess?: (supportCaseID?: number) => void; // navigation handler after successfully submitting a message
  origin: AssistantChatOrigin; // prior page or action sheet before navigating to the assistant message form
  subject: string; // topic of the message
  tag?: MessageTag; // message tag used to classify a message to route to particular queues (i.e. medication question tag routed to pharmacists)
};

export const MessageWithSubject = () => {
  const { getParam, navigate, setParams, goBack } = useNavigation<'RouteAssistantMessage'>();
  const dispatch = useDispatchShared();
  const { refetchSupportCases } = useGetSupportCases();
  const { isFamilyOwner, familyMembers } = useGetSupportCases();
  const { sendMessageMutate } = useSendMessage();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const userID = useSelectorShared(getCurrentUserID);
  const currentUser = useSelectorShared(getCurrentUser);
  const draftMessage = useSelectorShared(getDraftMessage);

  // handle navigation after creating a support case
  const navigateToSupportCase = (supportCaseID?: number) => {
    if (supportCaseID) {
      navigate('RouteAssistantSupportCase', { supportCaseID });
    } else {
      navigateToAssistantLanding({ activeTab: 'messages' });
    }
  };

  const prefilledSubject = getParam('subject', '');
  const prefilledBody = getParam('message', '');
  const origin = getParam('origin', 'unknown');
  const category = getParam('category', 'unknown');
  const tag = getParam('tag', undefined);
  const description = getParam('description', '');
  const cacheKey = getParam('cacheKey', undefined);
  const isEditableSubject = getParam('isEditableSubject', true);
  const onSubmitSuccess = getParam('onSubmitSuccess', navigateToSupportCase);

  const isMedicationQuestion = tag === MESSAGE_TAGS.MEDICATION_QUESTION;

  const [subject, setSubject] = useState(prefilledSubject ?? '');
  const [patientID, setPatientID] = useState(userID);
  const [body, setBody] = useState(prefilledBody || draftMessage || '');
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const [faqs, setFaqs] = useState<ReturnType<typeof getFaqsForMessageBody>>([]);

  const { restoreStack, setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent, trackPageView } = useAnalytics();

  const onPressBack = () => {
    goBack();
    restoreStack<ActionSheetProviderStackCacheKey>(cacheKey || 'assistant');
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.MESSAGE_WITH_SUBJECT_BACK,
        type: 'button',
      },
    });
  };

  useEffect(() => {
    trackPageView({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: {
        name: ASSISTANT_VIEWED_EVENTS.MESSAGE_FORM,
        origin,
        category,
      },
    });
  }, [trackPageView, origin, category]);

  useEffect(() => {
    setParams({ onPressBack });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // NOTE: save the current draft before unmounting the component
    return () => {
      // NOTE: reset the draft when the text input is cleared, never save un-edited prefilled body as a draft
      if (body === '' || body !== prefilledBody) {
        dispatch(setDraftMessage(body));
      }
    };
  }, [body, dispatch, prefilledBody]);

  const messageInputRef = useRef<TextInput | null>(null);
  const viewedLearnMoreButtonsRef = useRef<Record<string, boolean>>({});

  const validateForm = () => {
    if (!body || !subject) {
      setFormErrors({
        subject: !subject ? 'Topic cannot be blank' : '',
        body: !body ? 'Details cannot be blank' : '',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) return;

    setSendingMessage(true);
    const trimmedBody = body.trim();
    const trimmedSubject = subject.trim();
    const familyMemberID = patientID === userID ? undefined : patientID;
    const response = await sendMessageMutate({
      body: trimmedBody,
      tag,
      topic: trimmedSubject,
      // only send familyMemberID if this case is for a family member, not the family owner
      familyMemberID,
      category: category as AssistantCategory,
      origin: origin as AssistantChatOrigin,
    });
    setBody('');
    dispatch(setDraftMessage(''));
    onSubmitSuccess?.(response.support_case_id ?? undefined);
    refetchSupportCases?.();
    setSendingMessage(false);
  };

  const handlePressFaqButton = ({ component: ActionSheet, label }: { component: FaqActionSheet; label: string }) => {
    setActiveActionSheet(
      React.cloneElement(<ActionSheet />, {
        toPayments: () => {
          navigate('RoutePayments');
        },
        toDeliveries: () => {
          navigate('RouteAppTabNavigator', { screen: 'RouteTabOrders' });
        },
      }),
    );
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: { name: ASSISTANT_PRESSED_EVENTS.LEARN_MORE_FAQ_BUTTON, label },
    });
  };

  const trackLearnMoreButtonViewed = (label: string, matchedKeyword: string) => {
    // track views for learn more buttons per "session" when a patient gets to message form
    // do not track multiple views if button is re-rendered as message composes longer message
    if (viewedLearnMoreButtonsRef.current[label]) {
      return;
    }
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: { name: ASSISTANT_PRESSED_EVENTS.LEARN_MORE_FAQ_BUTTON, label, matchedKeyword },
    });
    viewedLearnMoreButtonsRef.current[label] = true;
  };

  // show buttons to surface FAQs when certain keywords are in the message body
  const getLearnMoreButtons = (body = '') => {
    const trimmedBody = body.trim();
    if (!trimmedBody) {
      setFaqs([]);
      return;
    }

    setFaqs(getFaqsForMessageBody(trimmedBody));
  };
  const getLearnMoreButtonsDebounced = debounce(getLearnMoreButtons, 300);

  const bodyPlaceholder = isMedicationQuestion
    ? 'Pharmacists can answer questions related to directions for use, side effects & interactions, and medication storage.'
    : 'Enter your detailed request';

  return (
    <KeyboardAvoidingScrollView backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}>
      <LgPadding>
        <Column top>
          <InputText
            disabled={!isEditableSubject}
            accessibilityLabel="The subject line for your message"
            autoCorrect
            error={formErrors.subject}
            label="Topic"
            onChangeText={(subject) => {
              if (formErrors.subject && subject.length > 1) {
                setFormErrors({ subject: '' });
              }
              setSubject(subject);
            }}
            placeholder="Enter your subject"
            returnKeyType="next"
            value={subject}
            onSubmitEditing={() => messageInputRef?.current?.focus()}
          />
          {description ? (
            <>
              <XsSpacing />
              <Description color={COLORS.TEXT_COLORS.GREY}>{description}</Description>
            </>
          ) : null}
          <MdSpacing />
          {isFamilyOwner ? (
            <>
              <InputSelect
                label="Who is this request about?"
                defaultValue={currentUser?.id.toString()}
                required
                placeholder="Choose someone"
                value={patientID?.toString()}
                options={[currentUser, ...familyMembers].map((familyMember) => ({
                  label: `${familyMember?.first_name} ${familyMember?.last_name}`,
                  value: familyMember?.id.toString() || '',
                }))}
                onValueChange={(value) => {
                  setPatientID(parseInt(value));
                }}
              />
              <MdSpacing />
            </>
          ) : null}
          <InputText
            accessibilityLabel="Your message"
            autoCorrect
            error={formErrors.body}
            label="Details"
            numberOfLines={6}
            onChangeText={(body) => {
              if (formErrors.body && body.length > 1) {
                setFormErrors({ body: '' });
              }
              setBody(body);
              getLearnMoreButtonsDebounced(body);
            }}
            placeholder={bodyPlaceholder}
            returnKeyType="default"
            value={body}
            onRef={messageInputRef}
          />
          {faqs.length > 0 && (
            <>
              <XsSpacing />
              <H3>Learn more</H3>
              <XxsSpacing />
              {faqs.map(({ label, matchedKeyword, component }) => {
                trackLearnMoreButtonViewed(label, matchedKeyword);
                return (
                  <XxsPadding key={label}>
                    <Button
                      small
                      width="inline"
                      type="secondary"
                      label={label}
                      accessibilityLabel={label}
                      onPress={() => {
                        handlePressFaqButton({ component, label });
                      }}
                    />
                  </XxsPadding>
                );
              })}
            </>
          )}
        </Column>
        <LgSpacing />
        <Description center>We typically respond within {MESSAGE_RESPONSE_TIME} during business hours.</Description>
        <LgSpacing />
        <Button
          width="full"
          label="Send request"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleSubmit}
          loading={sendingMessage}
          disabled={sendingMessage}
        />
      </LgPadding>
    </KeyboardAvoidingScrollView>
  );
};
