import { createSelector } from 'reselect';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type ReduxStateShared } from '~shared/types';

const getUpdateUserLoading: (arg0: ReduxStateShared) => boolean = createSelector(
  getLoading,
  (loading) => loading.updateUserLoading,
);

export default getUpdateUserLoading;
