// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { type ReduxStateShared } from '~shared/types';

type UpdatedBelowMinProps = {
  initialQuantity: number;
  updatedQuantity: number;
  essentialPrice: number;
  minimumThresholdToWaiveFee: number;
};
const getInitialQuantity = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.initialQuantity;
const getUpdatedQuantity = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.updatedQuantity;
const getEssentialPrice = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.essentialPrice;
const getMinimumThresholdToWaiveFee = (_: ReduxStateShared, props: UpdatedBelowMinProps) =>
  props.minimumThresholdToWaiveFee;

export const getUpdatedEssentialQuantityIsBelowWaivedDeliveryFeeMin = createSelector(
  [getShipmentPricing, getInitialQuantity, getUpdatedQuantity, getEssentialPrice, getMinimumThresholdToWaiveFee],
  (shipmentPricing, initialQuantity, updatedQuantity, essentialPrice, minimumThreshold): boolean => {
    const baseTotal = shipmentPricing.line_items.find((line_item) => line_item.key === 'cash_price')?.value || 0;
    const insuranceCoveredTotal =
      shipmentPricing.line_items.find((line_item) => line_item.key === 'insurance_paid')?.value || 0;
    const priceDiff = (initialQuantity - updatedQuantity) * essentialPrice;
    return baseTotal - insuranceCoveredTotal - priceDiff < minimumThreshold;
  },
);
