// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  Column,
  H2,
  LgPadding,
  Row,
  XlSpacing,
  XsPadding,
  XsSpacing,
  XxsPadding,
  useScreenSize,
} from '@alto/design-system';
import { useEssentialsStoreCard } from '@alto/features';
import React, { useCallback, useEffect } from 'react';
import { push } from 'react-router-redux';
import { viewStoreUpsell } from '~shared/features/essentials/actions';
import { useAnalytics } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';
import { Image, View } from '~web/features/essentials/primitives';
import { FeaturedEssentialsCard } from '~web/features/homescreen/sections/FeaturedEssentialsCard';

type Props = {
  readonly hasPatientTasks?: boolean;
  readonly hasFeaturedTasks?: boolean;
};

export const EssentialsStoreCard = ({ hasPatientTasks, hasFeaturedTasks }: Props) => {
  const {
    showEssentialCard,
    upSellCardButton,
    upSellCardSubtitle,
    imageEssentialsTitle,
    imageEssentialsItems,
    imageEssentialsBanner,
  } = useEssentialsStoreCard();
  const dispatch = useDispatchShared();
  const { isSMScreenOrBigger } = useScreenSize();
  const largerThanSmall = isSMScreenOrBigger;
  const { trackEvent } = useAnalytics();

  const navigateToEssentialsStore = useCallback(() => {
    trackEvent({ event: EVENTS.ESSENTIALS__INTRODUCING_ESSENTIALS_CARD_TAPPED });
    dispatch(push('/store'));
  }, [dispatch, trackEvent]);

  useEffect(() => {
    if (!showEssentialCard) return;
    dispatch(viewStoreUpsell());
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.ESSENTIALS__INTRODUCING_ESSENTIALS_CARD_VIEWED)));
  }, [showEssentialCard, dispatch]);

  if (!showEssentialCard) return null;
  if (hasPatientTasks) return null;
  if (hasFeaturedTasks) return <FeaturedEssentialsCard />;
  return (
    <>
      <Card>
        <LgPadding>
          {largerThanSmall ? (
            <Row
              flexShrink={1}
              wrap={false}
              centerVertically
            >
              <Column flexShrink={1}>
                <Row centerVertically>
                  <H2>Introducing</H2>
                  <XxsPadding />
                  <Image
                    width={`${770 * 0.18}px`}
                    height={`${257 * 0.18}px`}
                    alt="essential items"
                    src={imageEssentialsTitle}
                  />
                </Row>
                <XsPadding />
                <Body color={COLORS.TEXT_COLORS.GREY}>{upSellCardSubtitle}</Body>
                <XsPadding />
                <View>
                  <Button
                    onPress={navigateToEssentialsStore}
                    label={upSellCardButton}
                  />
                </View>
              </Column>
              <XlSpacing />
              <View
                flexGrow={1}
                justifyContent="center"
              >
                <Image
                  width={`${1150 * 0.25}px`}
                  height={`${697 * 0.25}px`}
                  alt="essential items"
                  src={imageEssentialsItems}
                />
              </View>
            </Row>
          ) : (
            <>
              <Column
                centerHorizontally
                centerVertically
              >
                <Image
                  height="250px"
                  alt="essential items"
                  src={imageEssentialsBanner}
                />
                <XsPadding />
                <Body
                  color={COLORS.TEXT_COLORS.GREY}
                  center
                >
                  {upSellCardSubtitle}
                </Body>
              </Column>
              <XsPadding />
              <Button
                onPress={navigateToEssentialsStore}
                label={upSellCardButton}
              />
            </>
          )}
        </LgPadding>
      </Card>
      <XsSpacing />
    </>
  );
};
