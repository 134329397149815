// @owners { team: patients-team }
import { useMemo } from 'react';
import { useCartEssentials } from '~shared/features/essentials/hooks';
import { getCartPricing } from '~shared/features/pricing/selectors/getPricing';
import { useSelectorShared } from '~shared/store';

export const MINIMUM_THRESHOLD = 10;

export const useCartEssentialsPrice = () => {
  const { essentials, hasEssentials, hasNonEssentials } = useCartEssentials();
  const cartPricing = useSelectorShared(getCartPricing);

  const totalPrice = essentials.reduce(
    (sum, prescription) => sum + (cartPricing.item_pricing[prescription.id]?.prices[0]?.price || 0),
    0,
  );
  const essentialsOnlyPriceBelowMinimum = !hasEssentials || hasNonEssentials ? false : totalPrice < MINIMUM_THRESHOLD;
  const essentialsOnlyPriceMinRemainder = !essentialsOnlyPriceBelowMinimum
    ? null
    : Number(MINIMUM_THRESHOLD - totalPrice).toFixed(2);

  return useMemo(
    () => ({
      essentialsOnlyPriceBelowMinimum,
      essentialsOnlyPriceMinRemainder,
      MINIMUM_THRESHOLD,
      totalPrice,
    }),
    [essentialsOnlyPriceBelowMinimum, essentialsOnlyPriceMinRemainder, totalPrice],
  );
};
