// IE's fetch implementation is broken.
// https://github.com/github/fetch/issues/391
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8546263/
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/?page=1&q=fetch
// So, rather than assuming `window.fetch` works, we'll always replace it with
// our own ponyfill
import fetchPonyfill from 'fetch-ponyfill';
import { isNative } from './isNative';

// on ReactNative, we want to trust the global fetch (especially since the fetchPonyfill doesn't work)
export const { fetch }: { fetch: typeof global.fetch } = isNative ? global : fetchPonyfill();
