export const ACTION_SHEET_CART_UPDATED = 'cartUpdated';
export const ACTION_SHEET_CONTACT_VERIFICATION = 'contactVerification';

export type ActionSheetKey = typeof ACTION_SHEET_CART_UPDATED | typeof ACTION_SHEET_CONTACT_VERIFICATION;

export const getActionSheetKey: (arg0: any) => ActionSheetKey | null | undefined = (state) => state.ui.actionSheet.key;

export const getActionSheetProps: (arg0: any) => Record<string, any> | null | undefined = (state) =>
  state.ui.actionSheet.props;

export type ShowActionSheetPayload = {
  key: ActionSheetKey;
  props?: Record<string, unknown>;
};

export const SHOW_ACTION_SHEET = 'SHOW_ACTION_SHEET';

export type ActionShowActionSheet = {
  type: typeof SHOW_ACTION_SHEET;
  payload: ShowActionSheetPayload;
};

export function showActionSheet(payload: ShowActionSheetPayload) {
  return {
    type: SHOW_ACTION_SHEET,
    payload,
  };
}

export const HIDE_ACTION_SHEET = 'HIDE_ACTION_SHEET';

export type ActionHideActionSheet = {
  type: typeof HIDE_ACTION_SHEET;
};

export function hideActionSheet() {
  return {
    type: HIDE_ACTION_SHEET,
  };
}
