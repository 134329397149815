// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, MdSpacing } from '@alto/design-system';
import React from 'react';

export const TransferLearnMoreActionSheet = () => {
  return (
    <ActionSheetV2
      title="How do transfers work?"
      analyticsName="transfer learn more"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          Let us know which pharmacy to reach out to and we'll coordinate the transfer. We'll notify you when it's time
          to schedule your delivery.
        </Body>
        <MdSpacing />
        <Body fontFamily="bold">How long does it typically take?</Body>
        <Body>
          Because the transfer depends on your current pharmacy, it may take up to a few business days. If you need it
          sooner, we recommend having your doctor send us a new prescription.
        </Body>
        <MdSpacing />
        <Body fontFamily="bold">Do you carry my medication?</Body>
        <Body>Yes. As a full-service pharmacy, Alto can fill nearly all medications.</Body>
        <MdSpacing />
        <Body fontFamily="bold">Will my medications cost more through Alto?</Body>
        <Body>
          Nope! We accept most major insurance plans, including government-administered plans, and always work to get
          you the lowest price.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
