// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, ParentWidthColumn, SimpleContainer, SimpleContainerHeader, spacing } from '@alto/pocky';
import { useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { setSelectedVideo } from '~shared/actions/instructionalVideos';
import { type ModalType } from '~shared/actions/modal';
import { getDisplayFullName } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { generateSendPrescriptionOptionsViewedEvent } from '~shared/lib/analytics/src/events/getSendPrescriptionOptionsViewed';
import { DESTINATION_NAMES, generateSupportViewedEvent } from '~shared/lib/analytics/src/events/getSupportViewed';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { type Event } from '~shared/lib/analytics/src/types';
import { type User } from '~shared/types';
import { ReferralModal } from './ReferralModal';
import { SectionNavigator } from './SectionNavigator';
import { ContentWrapper, FullFlexColumn, Main, PageBackground, Sidebar } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import SidebarItem from '~web/features/my-meds/components/SidebarItem';
import referral from '~web/images/inviteFriends@2x.png';

type Props = {
  readonly isFamilyMember: boolean;
  readonly logout: () => void;
  readonly navigate: (route: string) => void;
  readonly navigateAndGenerateAnalyticsEvent: (route: string, event: Event) => void;
  readonly openModal: (name: ModalType) => void;
  readonly users: User[];
  readonly showFertVideosLink: boolean;
};

const Profile = ({
  isFamilyMember,
  logout,
  navigate,
  navigateAndGenerateAnalyticsEvent,
  openModal,
  users,
  showFertVideosLink,
}: Props) => {
  const queryClient = useQueryClient();
  const { trackPageView } = useAnalytics();

  const handleLogout = () => {
    logout();
    queryClient.clear();
  };

  useEffect(() => {
    trackPageView({ event: EVENTS.PROFILE_VIEWED });
  }, [trackPageView]);

  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexColumn evenlySpaceContent>
          <PageHeader>Account</PageHeader>
          <Button
            kind="secondary"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </FullFlexColumn>
        <Main>
          <SimpleContainer>
            <SimpleContainerHeader>Settings</SimpleContainerHeader>
            <SectionNavigator
              icon="mail"
              label="Contact & Login Info"
              description="Email, Password & Phone Number"
              onClick={() => {
                navigate('/me/contact-info');
              }}
            />
            <SectionNavigator
              icon="sync"
              label="Auto Refill"
              onClick={() => {
                navigate('/me/auto-refills');
              }}
            />
            <SectionNavigator
              icon="creditcard"
              label="Payments and Receipts"
              onClick={() => {
                navigate('/me/payments');
              }}
            />
            <SectionNavigator
              icon="mapmarker"
              label="Delivery Addresses"
              onClick={() => {
                navigate('/me/addresses');
              }}
            />
          </SimpleContainer>
          <SimpleContainer>
            <SimpleContainerHeader>{pluralize('Profile', users.length)}</SimpleContainerHeader>
            {users.map((user) => {
              return (
                <SectionNavigator
                  key={user.id}
                  icon="usercircle"
                  label={getDisplayFullName(user)}
                  description="Personal & Insurance Info"
                  onClick={() => {
                    navigate(`/me/${user.id}`);
                  }}
                />
              );
            })}
            {isFamilyMember ? null : (
              <ParentWidthColumn
                padding={spacing.smSpacing}
                rightAlignContent
              >
                <Button
                  kind="secondary"
                  onClick={() => {
                    navigate('/me/family_member');
                  }}
                >
                  Add a Family Member
                </Button>
              </ParentWidthColumn>
            )}
          </SimpleContainer>
          <SimpleContainer>
            <SimpleContainerHeader>Support</SimpleContainerHeader>
            <SectionNavigator
              icon="doctor"
              label="Send prescriptions to Alto"
              onClick={() => {
                navigateAndGenerateAnalyticsEvent(
                  '/me/send-prescription-options',
                  generateSendPrescriptionOptionsViewedEvent(ORIGIN_NAMES.PROFILE),
                );
              }}
            />
            {showFertVideosLink ? (
              <SectionNavigator
                icon="play"
                label="Fertility Instructional Videos"
                onClick={() => {
                  // clear the selected video so that we don't pre-fill the search bar
                  setSelectedVideo(null);
                  navigateAndGenerateAnalyticsEvent(
                    '/me/fertility-videos',
                    generateSupportViewedEvent(ORIGIN_NAMES.PROFILE, DESTINATION_NAMES.FERTILITY_VIDEOS),
                  );
                }}
              />
            ) : null}
            <SectionNavigator
              icon="questionmark"
              label="Frequently asked questions"
              onClick={() => {
                navigateAndGenerateAnalyticsEvent(
                  '/me/faq',
                  generateSupportViewedEvent(ORIGIN_NAMES.PROFILE, DESTINATION_NAMES.FAQ),
                );
              }}
            />
            <SectionNavigator
              icon="message"
              label="Contact support"
              onClick={() => {
                navigateAndGenerateAnalyticsEvent(
                  '/me/contact-support',
                  generateSupportViewedEvent(ORIGIN_NAMES.PROFILE, DESTINATION_NAMES.CONTACT_SUPPORT),
                );
              }}
            />
          </SimpleContainer>
          <ReferralModal />
        </Main>
        <Sidebar>
          <SidebarItem
            buttonText="Invite A Friend"
            img={referral}
            imgAlt="Illustration of two friends"
            onClick={() => {
              openModal('REFERRAL_MODAL');
            }}
            text="Invite your friends and family to use Alto! Click below to generate a shareable link."
          />
        </Sidebar>
      </ContentWrapper>
    </PageBackground>
  );
};

export default Profile;
