// @owners { team: patients-team }
import { type AvailableShipmentFee } from '@alto/scriptdash/alto/orders/types/v1/available_shipment_fee';
import { type ShipmentFeeType } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import { type ShipmentFeesEndpointFetchAvailableRequest } from '@alto/scriptdash/alto/patient_app/scheduling/v3/shipment_fees_endpoint';
import { type PrescriptionSelectedPriceType } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_selected_price_type';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, format, isBefore } from 'date-fns';
import { memoize } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { getSelectedPriceTypesForPrescriptions } from '../../../features/src/checkout/helpers/getSelectedPriceTypesForPrescriptions';
import { queries } from './query-keys';
import { hasScheduleableDeliveryFeesInCart } from '~shared/actions/cart';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getOrderAddressId } from '~shared/features/checkout/selectors/getOrder';
import getSelectedPaymentTypes from '~shared/features/checkout/selectors/getSelectedPaymentTypes';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';
import { staleTimes } from '~shared/queries/constants';
import { useQuery } from '~shared/react-query';
import { useDispatchShared, useSelectorShared } from '~shared/store';

type DeliveryPayload = {
  removedPrescriptionId: number;
  shipmentId: number | undefined;
  addressId: number | undefined;
};

type Props = {
  /**
   * required for delivery prescription removal
   */
  delivery?: DeliveryPayload;
};

// This happens whenever the component loads in the background. However, the request will not be sent because of 'enabled' prop on react-query
const invalidAddressId = 0;

const useShipmentFeesAvailableParams = (delivery?: DeliveryPayload): ShipmentFeesEndpointFetchAvailableRequest => {
  const orderAddressId = useSelectorShared(getOrderAddressId);
  const editShipmentId = useSelectorShared(getEditShipmentID);
  const selectedPaymentTypes = useSelectorShared(getSelectedPaymentTypes);
  const shipment_id = delivery?.shipmentId ?? editShipmentId;
  const shipmentPrescriptions = useSelectorShared((state) =>
    getPrescriptionsForShipment(state, { shipmentID: shipment_id }),
  ).map((prescription) => prescription.id);
  const { prescriptionIDs: cartPrescriptions } = usePrescriptionsInCart();
  const prescriptionIds = shipment_id ? shipmentPrescriptions : cartPrescriptions;

  const selectedPriceTypes: PrescriptionSelectedPriceType[] = useMemo(
    () =>
      getSelectedPriceTypesForPrescriptions({
        prescriptionIDs: prescriptionIds,
        cartSelectedPaymentTypes: selectedPaymentTypes,
      }),
    [prescriptionIds, selectedPaymentTypes],
  );

  if (delivery) {
    return {
      address_id: delivery.addressId ?? invalidAddressId,
      shipment_id: null,
      prescription_ids: shipmentPrescriptions.filter((id) => id !== delivery.removedPrescriptionId),
      selected_price_types: undefined,
    };
  }

  return {
    address_id: orderAddressId ?? invalidAddressId,
    prescription_ids: prescriptionIds,
    shipment_id,
    selected_price_types: selectedPriceTypes,
  };
};

type GetShipmentFeeAvailableHashParams = {
  availableShipmentFee: AvailableShipmentFee[] | null | undefined;
  dataUpdatedAt: number;
};

const getShipmentFeeAvailableHash = memoize(
  ({ availableShipmentFee }: GetShipmentFeeAvailableHashParams) =>
    (availableShipmentFee ?? []).reduce<Record<ShipmentFeeType, AvailableShipmentFee | null>>(
      (hash, fee) => {
        hash[fee.fee_type] = fee;
        return hash;
      },
      {
        asap_delivery: null,
        on_demand_delivery: null,
        essentials_only_delivery: null,
        same_day_delivery: null,
        next_day_delivery: null,
        standard_delivery: null,
      },
    ),
  ({ dataUpdatedAt }) => dataUpdatedAt,
);

// If the fee type is standard_delivery, it can be assumed that any date including and after then
// will be standard_delivery applicable.
const getDeliveryDateToShipmentFeeHash = memoize(
  ({ availableShipmentFee }: GetShipmentFeeAvailableHashParams) => {
    const hash: Record<string, ShipmentFeeType | null> = {};
    availableShipmentFee?.forEach((fee) => {
      const { fee_type, date } = fee;
      if (fee_type === 'on_demand_delivery' || fee_type === 'essentials_only_delivery') return;
      hash[date] = fee_type;
    });
    return hash;
  },
  ({ dataUpdatedAt }) => `dateHash-${dataUpdatedAt}`,
);

const getShipmentFeeToDeliveryDateHash = memoize(
  ({ availableShipmentFee }: GetShipmentFeeAvailableHashParams) => {
    const hash: Record<ShipmentFeeType, string | null> = {
      asap_delivery: null,
      on_demand_delivery: null,
      essentials_only_delivery: null,
      same_day_delivery: null,
      next_day_delivery: null,
      standard_delivery: null,
    };
    availableShipmentFee?.forEach((fee) => {
      const { fee_type, date } = fee;
      if (fee_type === 'on_demand_delivery' || fee_type === 'essentials_only_delivery') return;
      hash[fee_type] = date;
    });
    return hash;
  },
  ({ dataUpdatedAt }) => `feeHash-${dataUpdatedAt}`,
);

const getHasScheduleableDeliveryFees = memoize(
  ({
    earliestAvailableDate,
    availableShipmentFees,
  }: {
    earliestAvailableDate: string;
    availableShipmentFees: AvailableShipmentFee[] | null | undefined;
    dataUpdatedAt: number;
  }) => {
    return (availableShipmentFees || []).some((fee, index) => {
      const { fee_type } = fee;
      if (fee_type === 'on_demand_delivery' || fee_type === 'essentials_only_delivery') return;
      const date = format(addDays(new Date(), index), 'yyyy-MM-dd');
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (!isBefore(new Date(date), new Date(earliestAvailableDate))) {
        return true;
      }
      return false;
    });
  },
  ({ dataUpdatedAt, earliestAvailableDate }) => `schedulableFeeHash-${dataUpdatedAt}-${earliestAvailableDate}`,
);

export type QueryShipmentFeesAvailableResponse = {
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  shipmentFeeAvailableHash: Record<ShipmentFeeType, AvailableShipmentFee | null>;
  dateToFeeHash: Record<string, ShipmentFeeType | null>;
  feeToDateHash: Record<ShipmentFeeType, string | null>;
  invalidateShipmentFeesAvailable: () => void;
  availableShipmentFees: AvailableShipmentFee[] | null | undefined;
  hasScheduleableFees: (earliestAvailableDate: string) => boolean;
};

export const useQueryShipmentFeesAvailable = ({ delivery }: Props = {}): QueryShipmentFeesAvailableResponse => {
  const dispatch = useDispatchShared();
  const queryClient = useQueryClient();
  const params = useShipmentFeesAvailableParams(delivery);
  const enabled = !!params.address_id && !!params.prescription_ids.length;
  const { earliestAvailableDate } = useCartNextAvailableDate();
  const hasScheduleableDeliveryFeesFromRedux = useSelectorShared(
    (state) => state.cart.hasScheduleableDeliveryFeesInCart,
  );
  const { isPending, isError, isSuccess, data, dataUpdatedAt } = useQuery({
    ...queries.shipmentFees.available(params),
    enabled,
    staleTime: staleTimes.fiveMinutes,
  });
  const shipmentFeeAvailableHash = getShipmentFeeAvailableHash({ availableShipmentFee: data?.data, dataUpdatedAt });
  const dateToFeeHash = getDeliveryDateToShipmentFeeHash({ availableShipmentFee: data?.data, dataUpdatedAt });
  const feeToDateHash = getShipmentFeeToDeliveryDateHash({ availableShipmentFee: data?.data, dataUpdatedAt });
  const hasScheduleableDeliveryFees = getHasScheduleableDeliveryFees({
    availableShipmentFees: data?.data,
    dataUpdatedAt,
    earliestAvailableDate,
  });

  // this isn't as simple as checking if there are any available fees.
  // for example, a same-day fee could be available, but if it's after cutoff, it's not scheduleable.
  const hasScheduleableFees = useCallback(
    (earliestAvailableDate: string) => {
      if (dateToFeeHash.asap_delivery) {
        return true;
      }

      // if earliest available date coincides with a fee, return true
      if (dateToFeeHash[earliestAvailableDate]) {
        return true;
      }

      // standard_fee is only represented by a min date, so if there is an available standard fee and its corresponding
      // date is before the earliest available date, the order also has fees.
      const standardDeliveryMinDate = feeToDateHash.standard_delivery;
      if (!standardDeliveryMinDate) {
        return false;
      }

      return isBefore(new Date(standardDeliveryMinDate), new Date(earliestAvailableDate));
    },
    [dateToFeeHash, feeToDateHash],
  );

  const invalidateShipmentFeesAvailable = useCallback(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryClient.invalidateQueries({ queryKey: ['shipmentFees', 'available'] });
  }, [queryClient]);

  useEffect(() => {
    if (!!delivery || hasScheduleableDeliveryFees === hasScheduleableDeliveryFeesFromRedux) return;
    dispatch(hasScheduleableDeliveryFeesInCart(hasScheduleableDeliveryFees));
  }, [delivery, hasScheduleableDeliveryFees, dispatch, hasScheduleableDeliveryFeesFromRedux]);
  return {
    isPending,
    isError,
    isSuccess,
    shipmentFeeAvailableHash,
    invalidateShipmentFeesAvailable,
    dateToFeeHash,
    feeToDateHash,
    availableShipmentFees: data?.data,
    hasScheduleableFees,
  };
};
