// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { PrimaryPage, Toast, ToastContext } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getURLParams } from '~shared/helpers/helper';
import { useDeliveriesListViewed } from '~shared/lib/analytics/src/hooks/useDeliveriesListViewed';
import { DeliverySections } from './DeliverySections';
import { EmptyDeliveriesOverview } from './EmptyDeliveriesOverview';

export type DeliveriesOverviewProps = {
  readonly upcomingShipments: ShipmentWithItemsAndAdditionalFields[];
  readonly pastShipments: ShipmentWithItemsAndAdditionalFields[];
  readonly loading: boolean;
  readonly updateOrder: (params: { origin: string }) => void;
  readonly hasPrescriptions: boolean;
  readonly areShipmentsStale: boolean;
};
const isTest = !!process.env.JEST_WORKER_ID;

export const DeliveriesOverview = ({
  upcomingShipments,
  pastShipments,
  loading,
  updateOrder,
  hasPrescriptions,
  areShipmentsStale,
}: DeliveriesOverviewProps) => {
  useDeliveriesListViewed();
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    if (getPlatformOS() === 'web') {
      const { verified } = getURLParams();

      if (verified === 'false') {
        addToast(
          <Toast variant="error">
            Sorry, we couldn't verify your email address. Please contact us if this error persists.
          </Toast>,
        );
      } else if (verified === 'true') {
        addToast(<Toast variant="success">Your email address has been verified.</Toast>);
      }
    }
  }, [addToast]);

  useEffect(() => {
    const staleTimeout = setTimeout(() => {
      if (areShipmentsStale && !isTest) {
        addToast(
          <Toast variant="error">
            We're having some trouble loading your deliveries. Please contact support if the problem persists.
          </Toast>,
        );
      }
    }, 1000 * 10); // wait 10s while fetching deliveries

    return () => {
      clearTimeout(staleTimeout);
    };
  }, [addToast, areShipmentsStale]);

  const hasDeliveries = upcomingShipments.length || pastShipments.length;

  return (
    <PrimaryPage
      headerBgColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      keyboardAvoidingEnabled={false}
      title={hasDeliveries ? 'Upcoming Orders' : 'Orders'}
    >
      {hasDeliveries ? (
        <DeliverySections
          upcomingShipments={upcomingShipments}
          pastShipments={pastShipments}
          loading={loading}
        />
      ) : (
        <EmptyDeliveriesOverview
          hasPrescriptions={hasPrescriptions}
          updateOrder={updateOrder}
          loading={loading}
        />
      )}
    </PrimaryPage>
  );
};
