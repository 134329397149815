// @owners { team: patients-team }
import { useNavigation } from '@alto/navigation';
import { CheckoutFlowTypeMap } from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchEnterCart } from '~shared/actions/cart';
import { useDispatchShared } from '~shared/store';

export const useNavigateToCart = () => {
  const { navigate } = useNavigation();
  const dispatch = useDispatchShared();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigateToCart = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchEnterCart()).then(({ data, errors }) => {
        if (errors) {
          setError(true);
          return;
        }
        // eslint-disable-next-line promise/always-return
        if (data.checkout_flow_types.includes(CheckoutFlowTypeMap.NEEDLE_PROMPT)) {
          const needleRxs = data.checkout_flow_data?.[CheckoutFlowTypeMap.NEEDLE_PROMPT]?.require_needle_prescriptions;
          if (needleRxs?.length) {
            navigate('RouteNeedlePrompt');
            return;
          }
        }
        navigate('RouteCart');
      });
    } catch (e) {
      console.warn(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [dispatch, navigate]);

  return { navigateToCart, error, loading };
};
