export const GooglePayIcon = `<svg version="1.1" id="G_Pay_Acceptance_Mark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
 width="24px" height="24px" x="0px" y="0px" viewBox="0 0 755.1 755.1" style="enable-background:new 0 0 755.1 755.1;" xml:space="preserve">
<path id="Base_1_" fill="#FFFFFF" d="M553.3,176.6h-352c-110,0-200,90-200,200l0,0c0,110,90,200,200,200h352c110,0,200-90,200-200l0,0
C753.3,266.6,663.3,176.6,553.3,176.6z"/>
<path id="Outline" fill="#3C4043" d="M553.3,192.8c24.7,0,48.7,4.9,71.3,14.5c21.9,9.3,41.5,22.6,58.5,39.5
c16.9,16.9,30.2,36.6,39.5,58.5c9.6,22.6,14.5,46.6,14.5,71.3s-4.9,48.7-14.5,71.3c-9.3,21.9-22.6,41.5-39.5,58.5
c-16.9,16.9-36.6,30.2-58.5,39.5c-22.6,9.6-46.6,14.5-71.3,14.5h-352c-24.7,0-48.7-4.9-71.3-14.5c-21.9-9.3-41.5-22.6-58.5-39.5
c-16.9-16.9-30.2-36.6-39.5-58.5c-9.6-22.6-14.5-46.6-14.5-71.3s4.9-48.7,14.5-71.3c9.3-21.9,22.6-41.5,39.5-58.5
c16.9-16.9,36.6-30.2,58.5-39.5c22.6-9.6,46.6-14.5,71.3-14.5L553.3,192.8 M553.3,176.6h-352c-110,0-200,90-200,200l0,0
c0,110,90,200,200,200h352c110,0,200-90,200-200l0,0C753.3,266.6,663.3,176.6,553.3,176.6L553.3,176.6z"/>
<g id="G_Pay_Lockup_1_">
<g id="Pay_Typeface_3_">
<path id="Letter_p_3_" fill="#3C4043" d="M359.9,390.8v60.5h-19.2V301.9h50.9c12.9,0,23.9,4.3,32.9,12.9c9.2,8.6,13.8,19.1,13.8,31.5
c0,12.7-4.6,23.2-13.8,31.7c-8.9,8.5-19.9,12.7-32.9,12.7h-31.7V390.8z M359.9,320.3v52.1H392c7.6,0,14-2.6,19-7.7
c5.1-5.1,7.7-11.3,7.7-18.3c0-6.9-2.6-13-7.7-18.1c-5-5.3-11.3-7.9-19-7.9h-32.1V320.3z"/>
<path id="Letter_a_3_" fill="#3C4043" d="M488.5,345.7c14.2,0,25.4,3.8,33.6,11.4c8.2,7.6,12.3,18,12.3,31.2v63h-18.3v-14.2h-0.8
c-7.9,11.7-18.5,17.5-31.7,17.5c-11.3,0-20.7-3.3-28.3-10s-11.4-15-11.4-25c0-10.6,4-19,12-25.2c8-6.3,18.7-9.4,32-9.4
c11.4,0,20.8,2.1,28.1,6.3v-4.4c0-6.7-2.6-12.3-7.9-17c-5.3-4.7-11.5-7-18.6-7c-10.7,0-19.2,4.5-25.4,13.6l-16.9-10.6
C456.5,352.4,470.3,345.7,488.5,345.7z M463.7,419.9c0,5,2.1,9.2,6.4,12.5c4.2,3.3,9.2,5,14.9,5c8.1,0,15.3-3,21.6-9
c6.3-6,9.5-13,9.5-21.1c-6-4.7-14.3-7.1-25-7.1c-7.8,0-14.3,1.9-19.5,5.6C466.3,409.7,463.7,414.4,463.7,419.9z"/>
<path id="Letter_y_3_" fill="#3C4043" d="M638.8,349l-64,147.2H555l23.8-51.5L536.6,349h20.9l30.4,73.4h0.4l29.6-73.4H638.8z"/>
</g>
<g id="G_Mark_1_">
<path id="Blue_500" fill="#4285F4" d="M283.5,378.6c0-6.3-0.6-12.2-1.6-18h-80.5v33l46.4,0c-1.9,11-7.9,20.3-17.2,26.6v21.4h27.6
C274.3,426.6,283.5,404.6,283.5,378.6z"/>
<path id="Green_500_1_" fill="#34A853" d="M230.6,420.1c-7.7,5.2-17.6,8.2-29.1,8.2c-22.4,0-41.3-15.1-48.1-35.4h-28.5v22.1
c14.1,28,43.1,47.2,76.6,47.2c23.1,0,42.6-7.6,56.7-20.7L230.6,420.1z"/>
<path id="Yellow_500_1_" fill="#FABB05" d="M150.7,376.6c0-5.7,1-11.2,2.7-16.4v-22.1h-28.5c-5.8,11.6-9.1,24.6-9.1,38.5
s3.3,26.9,9.1,38.5l28.5-22.1C151.6,387.8,150.7,382.3,150.7,376.6z"/>
<path id="Red_500" fill="#E94235" d="M201.4,324.9c12.6,0,23.9,4.4,32.9,12.9l24.5-24.4c-14.9-13.8-34.2-22.3-57.3-22.3
c-33.5,0-62.5,19.2-76.6,47.2l28.5,22.1C160.1,339.9,179.1,324.9,201.4,324.9z"/>
</g>
</g>
</svg>`;
