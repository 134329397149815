// @owners { team: patients-team }
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ReactNativeBiometrics } from './biometrics';
import { BIOMETRIC_SESSION_EXPIRY } from '~shared/constants';
import { type ApiOptions } from '~shared/helpers/api';
import { post } from '~shared/helpers/apiHelper';
import getDeviceID from '~shared/helpers/getDeviceID';

const V3_API_VERSION = 'v3';
const v3Options: ApiOptions = {
  version: V3_API_VERSION,
};

async function savePublicKey(userID: number, publicKey: string) {
  const deviceID = getDeviceID();
  return post(
    `/users/${userID}/biometric/${deviceID}`,
    {
      public_key: publicKey,
    },
    v3Options,
  );
}

async function upgradeSession(userID: number) {
  const deviceID = getDeviceID();
  return post(`/users/${userID}/biometric/${deviceID}/upgrade_session`, {}, v3Options);
}

// Creates and saves biometric keypair, then upgrades session length and saves biometric session cookies
export async function configureBiometricSession(currentUserID: number) {
  const keypairResponse = await createKeypair();

  if (!keypairResponse.publicKey) {
    return false;
  }

  const res = await savePublicKey(currentUserID, keypairResponse.publicKey);

  if (res.error) {
    return false;
  }

  const upgradeResponse = await upgradeSession(currentUserID);

  if (upgradeResponse.error) {
    return false;
  }

  await AsyncStorage.setItem(BIOMETRIC_SESSION_EXPIRY, upgradeResponse.expires_at);
  return true;
}

export type AvailableBiometricsResponse = {
  available: boolean;
  biometryType: string | undefined;
  error?: string;
};

export const availableBiometrics = async (): Promise<AvailableBiometricsResponse> => {
  // @ts-expect-error TS(2322): Type 'IsSensorAvailableResult' is not assignable to type 'AvailableBiometricsResponse'.
  return ReactNativeBiometrics.isSensorAvailable();
};

export type CreateKeypairResponse = {
  publicKey: string;
};

export const createKeypair = async (): Promise<CreateKeypairResponse> => {
  // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
  return ReactNativeBiometrics.createKeys('Confirm fingerprint');
};

export type CreateSignatureResponse = {
  success: boolean;
  signature: string;
  error?: string;
};

export const BIOMETRIC_SIGNATURE_PAYLOAD = 'Verify your face or fingerprint to unlock';
export const createSignature = async (): Promise<CreateSignatureResponse> => {
  // @ts-expect-error TS(2322): Type 'CreateSignatureResult' is not assignable to type 'CreateSignatureResponse'.
  return ReactNativeBiometrics.createSignature({
    promptMessage: 'Face or fingerprint ID for Alto Pharmacy',
    payload: BIOMETRIC_SIGNATURE_PAYLOAD,
  });
};

export const promptBiometrics = async () => {
  return ReactNativeBiometrics.simplePrompt({
    promptMessage: 'Confirm ID',
  });
};
