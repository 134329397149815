// eslint-disable-next-line @alto/no-pocky-import
import { palette } from '@alto/pocky';
import styled from 'styled-components';

const Required = styled.span`
  color: ${palette.red1};
  font-weight: 400;
`;

export default Required;
