import { type ReduxStateShared } from '~shared/types';

export const getPrescriptions = (state: ReduxStateShared) => state.prescriptions.prescriptions;

export const getLastRequestAttemptedAt = (state: ReduxStateShared) => state.prescriptions.lastRequestAttemptedAt;

export const getRequestAttemptCount = (state: ReduxStateShared) => state.prescriptions.requestAttemptCount;

export const getLastRequestSucceededAt = (state: ReduxStateShared) => state.prescriptions.lastRequestSucceededAt;

export const getSelectedPrescriptionID = (state: ReduxStateShared) => state.prescriptions.selectedPrescriptionID;

export const getLastFetchAllRequestSucceededAt = (state: ReduxStateShared) =>
  state.prescriptions.lastFetchAllRequestSucceededAt;
