import { BORDERS, COLORS, SHADOWS, SPACING } from '@alto/design-library-tokens';
import React, { useEffect, useState } from 'react';
import { type LayoutChangeEvent, View } from 'react-native';
import styled from 'styled-components/native';
import { MoveX } from '../../animations';
import { Border } from '../../borders';
import { Column, Row, XxsPadding } from '../../containers';
import { XsSpacing } from '../../separators';
import { Caption, Text } from '../../typography';
import { ButtonSelectorOption, StyledMdPadding } from './ButtonSelectorOption';

export type OptionValue = number | string | undefined;

type ButtonOption = {
  label: string;
  value: OptionValue;
  sublabel?: string;
  testID?: string;
};

type Props = {
  readonly options: ButtonOption[];
  readonly onChange: (value: OptionValue) => void;
  readonly disabled?: boolean;
  readonly value?: OptionValue;
  readonly leftLabel?: string;
  readonly rightLabel?: string;
  testID?: string;
};

const PaddingContainer = styled(XxsPadding)`
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
`;

const SelectedContainer = styled(Row)<{ itemsQuantity: number; height: number }>`
  position: absolute;
  ${({ height }) => `height: ${height}px;`}
  ${({ itemsQuantity }) => `width: ${100 / itemsQuantity}%;`}
  bottom: 0;

  border-radius: ${BORDERS.RADIUS.XL.value - SPACING.STATIC.XXS.value}px;
  border: 1px solid ${COLORS.BACKGROUND_COLORS.SECONDARY};
  box-shadow: ${SPACING.STATIC.NONE.value} ${SPACING.STATIC.XXS.px} ${SPACING.STATIC.MD.px} ${SHADOWS.BOTTOM};
`;

const isTest = !!process.env.JEST_WORKER_ID;

export const ButtonSelector = ({
  disabled,
  onChange: onChangeCallback,
  options,
  value,
  leftLabel,
  rightLabel,
  testID,
}: Props) => {
  const initialValue = value || options[0].value;
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [itemWidth, setItemWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [showSelected, setShowSelected] = useState(false);
  const [previousSelectedOption, setPreviousSelectedOption] = useState(0);
  const selectedOption = options.find((option) => currentValue === option.value);
  const selectedOptionIndex = selectedOption ? options.indexOf(selectedOption) : 0;
  const optionQuantity = options.length;
  const getElemWidth = (event: LayoutChangeEvent) => {
    const { width, height } = event?.nativeEvent?.layout || {};
    setItemWidth(width / optionQuantity);
    setHeight(height);
  };
  const position = itemWidth * selectedOptionIndex;
  const [xPosition1, setXPosition1] = useState(position);
  const [xPosition2, setXPosition2] = useState(position);
  const duration = 100 * Math.abs(selectedOptionIndex - previousSelectedOption);
  useEffect(() => {
    if (itemWidth > 0) {
      if (isTest) {
        setShowSelected(true);
      } else {
        setTimeout(() => {
          setShowSelected(true);
        }, duration + 600);
      }
    }
  }, [itemWidth, duration]);

  useEffect(() => {
    setXPosition2(position);
    if (isTest) {
      setXPosition1(position);
    } else {
      setTimeout(() => {
        setXPosition1(position);
      }, duration);
    }
  }, [position, duration]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const selectorOptions = options.map((option) => {
    const isOptionSelected = currentValue === option.value;

    const onPress = () => {
      if (!disabled) {
        setPreviousSelectedOption(selectedOptionIndex);
        setCurrentValue(option.value);
        onChangeCallback(option.value);
      }
    };

    return (
      options && (
        <ButtonSelectorOption
          key={String(option.value)}
          disabled={disabled}
          label={option.label}
          sublabel={option.sublabel}
          onPress={onPress}
          selected={isOptionSelected}
          itemsQuantity={optionQuantity}
          showSelected={!showSelected}
          testID={option.testID}
        />
      )
    );
  });
  return (
    <Column wrap={false}>
      <Border radius="XL">
        <PaddingContainer>
          <View onLayout={getElemWidth}>
            <View
              accessibilityRole="radiogroup"
              testID={testID}
            >
              <Row>{selectorOptions}</Row>
            </View>
            {!disabled && selectedOption && showSelected ? (
              <MoveX
                duration={duration}
                xPosition1={xPosition1}
                xPosition2={xPosition2}
              >
                <SelectedContainer
                  center
                  flexGrow={1}
                  backgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY}
                  itemsQuantity={optionQuantity}
                  height={height}
                >
                  <StyledMdPadding
                    topPadding={SPACING.STATIC.XS}
                    bottomPadding={SPACING.STATIC.XS}
                  >
                    <Text
                      center
                      textSize="mini"
                      fontFamily="semibold"
                      color={COLORS.TEXT_COLORS.WHITE}
                    >
                      {selectedOption.label}
                    </Text>
                    {selectedOption.sublabel ? (
                      <Caption
                        center
                        fontFamily="semibold"
                        color={COLORS.TEXT_COLORS.WHITE}
                      >
                        {selectedOption.sublabel}
                      </Caption>
                    ) : null}
                  </StyledMdPadding>
                </SelectedContainer>
              </MoveX>
            ) : null}
          </View>
        </PaddingContainer>
      </Border>
      {leftLabel || rightLabel ? (
        <View>
          <XsSpacing />
          <Row
            spaceBetween={!!leftLabel && !!rightLabel}
            right={!!rightLabel && !leftLabel}
          >
            {leftLabel ? (
              <Row
                left
                flexShrink={1}
                flexGrow={1}
              >
                <Caption
                  center
                  color={COLORS.TEXT_COLORS.GREY}
                >
                  {leftLabel}
                </Caption>
              </Row>
            ) : null}
            {rightLabel ? (
              <Row
                right
                flexShrink={1}
                flexGrow={1}
              >
                <Caption
                  center
                  color={COLORS.TEXT_COLORS.GREY}
                >
                  {rightLabel}
                </Caption>
              </Row>
            ) : null}
          </Row>
        </View>
      ) : null}
    </Column>
  );
};
