// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2 } from '@alto/design-system';
import {
  AccountActionSheet,
  MenuOption,
  OrderingActionSheet,
  PricingPaymentsActionSheet,
  TransferActionSheet,
} from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { type ReactElement, useCallback, useContext } from 'react';
import { push } from 'react-router-redux';
import { sendAssistantCategoryChosen } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type AssistantCategory,
  type AssistantMessageSubject,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { useDispatchShared } from '~shared/store';

export const HowCanWeHelpActionSheet = () => {
  const dispatch = useDispatchShared();
  const { navigate } = useNavigation();
  const { closeActionSheet, setActiveActionSheet } = useContext(ActionSheetContext);

  const navigateToMessageForm = (category: AssistantCategory, subject?: AssistantMessageSubject) => () => {
    closeActionSheet();
    dispatch(sendAssistantCategoryChosen(category));
    dispatch(
      push({
        pathname: '/help/message',
        state: {
          category,
          origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU,
          messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
          subject,
        },
      }),
    );
  };

  const handlePressDeliveries = useCallback(() => {
    // TODO: put saveStack back in
    // atm, restore stack doesn't work for web, and results in a blank action sheet
    // saveStack<ActionSheetProviderStackCacheKey>('assistant-deliveries');
    closeActionSheet();
    dispatch(sendAssistantCategoryChosen('deliveries'));
    navigate('RouteMessagesDeliverySelection', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU,
      category: 'deliveries',
    });
  }, [closeActionSheet, dispatch, navigate]);

  const handleMenuOptionPress = useCallback(
    (category: AssistantCategory, ActionSheet?: ReactElement) => {
      dispatch(sendAssistantCategoryChosen(category));
      setActiveActionSheet(ActionSheet);
    },
    [dispatch, setActiveActionSheet],
  );

  const handleOrderingPress = useCallback(() => {
    handleMenuOptionPress('ordering', <OrderingActionSheet />);
  }, [handleMenuOptionPress]);

  const handleAccountPress = useCallback(() => {
    handleMenuOptionPress('account', <AccountActionSheet />);
  }, [handleMenuOptionPress]);

  const handlePricingPaymentsPress = useCallback(() => {
    handleMenuOptionPress('pricing_payments', <PricingPaymentsActionSheet />);
  }, [handleMenuOptionPress]);

  const handleTransferPress = useCallback(() => {
    handleMenuOptionPress('transfer', <TransferActionSheet />);
  }, [handleMenuOptionPress]);

  return (
    <ActionSheetV2
      title="How can we help?"
      analyticsName="assistant help"
    >
      <MenuOption
        text="Deliveries"
        iconName="box-duo"
        onPress={handlePressDeliveries}
      />
      <MenuOption
        text="Ordering"
        iconName="truck-duo"
        onPress={handleOrderingPress}
      />
      <MenuOption
        text="Account"
        iconName="usercircle-duo"
        onPress={handleAccountPress}
      />
      <MenuOption
        text="Medications"
        iconName="pillbottle-duo"
        onPress={navigateToMessageForm(ASSISTANT_CATEGORIES.MEDICATIONS, ASSISTANT_MESSAGE_SUBJECTS.MEDICATION)}
        key="medications"
      />
      <MenuOption
        text="Pricing & payments"
        iconName="creditcard-duo"
        onPress={handlePricingPaymentsPress}
      />
      <MenuOption
        text="Transfer prescriptions"
        iconName="swap-duo"
        onPress={handleTransferPress}
      />
    </ActionSheetV2>
  );
};
