import { useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

export const useClaimAccountViewed = (tempPassword: string) => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__CLAIM_ACCOUNT_VIEWED,
      additionalFields: {
        userIdentifier: tempPassword,
        userIdentifierType: 'temp_password',
      },
    });
  }, [trackPageView, tempPassword]);
};
