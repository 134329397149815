// @owners { team: patients-team }
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { type NextAvailableDate } from '~shared/types/clients';

type NextAvailableDatesForOrderResponse = {
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  nextAvailableDate: NextAvailableDate | null;
};

export const useQueryNextAvailableDateForOrder = (): NextAvailableDatesForOrderResponse => {
  const { nextAvailableDateObject } = useCartNextAvailableDate();

  return {
    isSuccess: true,
    isLoading: false,
    isError: false,
    nextAvailableDate: nextAvailableDateObject || null,
  };
};
