// @owners { team: patients-team }
import { useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { Input } from '~web/features/essentials/primitives';

const StyledInputOffset = styled.div<{ isSMScreenOrBigger: boolean }>`
  position: relative;
  top: 28px;
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `top: 0px;`}
`;

type Props = {
  readonly value: string;
  readonly onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
};

export const SearchInput = ({ value, onChange }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <StyledInputOffset isSMScreenOrBigger={isSMScreenOrBigger}>
      <Input
        isSearch
        placeholder="Search products"
        label="search by category"
        labelHidden
        id="search"
        value={value}
        onChange={onChange}
        shadow={!isSMScreenOrBigger}
      />
    </StyledInputOffset>
  );
};
