// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, H1, InputCheckbox, Modal, ModalContent, Text, spacing } from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Formik, type FormikProps } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type UserPhiAuthValues } from '~shared/helpers/communicationPreferences';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { type PhiAuthInfo } from '~shared/types';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import messaging from '~web/images/messaging@3x.png';

export type Props = {
  readonly onSubmit: (arg0: UserPhiAuthValues) => void;
  readonly userPhiAuth: PhiAuthInfo | null | undefined;
  readonly handleClose: () => void;
  readonly recordInterstitialViewed: () => void;
};

const TitleText = styled(H1)<{ isSMScreenOrBigger: boolean }>`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  margin-top: ${spacing.xlSpacing};
  padding: 0px ${spacing.xlSpacing} ${SPACING.STATIC.LG.rem} ${spacing.xlSpacing};
  ${({ isSMScreenOrBigger }) =>
    !isSMScreenOrBigger &&
    `
      padding: 0px ${SPACING.STATIC.MD.rem} ${SPACING.STATIC.LG.rem} ${SPACING.STATIC.MD.rem};
      margin-top: ${SPACING.STATIC.MD.rem}; font-size: 20px;
    `}
`;

const PaddedText = styled(Text).attrs({
  center: true,
})<{ isSMScreenOrBigger: boolean }>`
  padding: 0px ${spacing.xlSpacing} ${SPACING.STATIC.LG.rem} ${spacing.xlSpacing};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `padding: 0px 0px ${SPACING.STATIC.MD.rem} 0px;`}
`;

const PaddedColumn = styled(Column).attrs({
  spacing: SPACING.STATIC.LG.rem,
  padding: `${SPACING.STATIC.MD.rem} 0px 0px 0px`,
})`
  display: inline-block;
`;

const MessagingImage = styled.img<{ isSMScreenOrBigger: boolean }>`
  height: 128px;
  align-items: center;
  margin-top: ${SPACING.STATIC.LG.rem};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `display: none;`}
`;

const PhiAuthModal = ({ onSubmit, userPhiAuth, handleClose, recordInterstitialViewed }: Props) => {
  useEffect(() => {
    recordInterstitialViewed();
  }, [recordInterstitialViewed]);
  const { isSMScreenOrBigger } = useScreenSize();

  return (
    <Modal
      showDismiss
      dismissOnOutsideClick
      showing
      onClose={handleClose}
    >
      <ModalContent>
        <MessagingImage
          src={messaging}
          alt={IMG_ALT_TEXTS.handheldMessaging}
          isSMScreenOrBigger={isSMScreenOrBigger}
        />
        <TitleText isSMScreenOrBigger={isSMScreenOrBigger}>Include health info when we contact you?</TitleText>
        <PaddedText isSMScreenOrBigger={isSMScreenOrBigger}>
          We would like to send you important health information, such as refill reminders, medication names, or
          condition names.
        </PaddedText>
        <Formik
          initialValues={{
            sms_authorized: userPhiAuth?.sms_authorized || false,
            push_authorized: userPhiAuth?.push_authorized || false,
            email_authorized: userPhiAuth?.email_authorized || false,
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit }: FormikProps<UserPhiAuthValues>) => {
            return (
              <PaddedColumn spacing={spacing.mdSpacing}>
                <Field name="sms_authorized">
                  {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => (
                    <InputCheckbox
                      input={{
                        value: String(values.sms_authorized),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(field.name, e?.target?.checked);
                        },
                      }}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      Include in texts/SMS
                    </InputCheckbox>
                  )}
                </Field>
                <Field name="push_authorized">
                  {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => (
                    <InputCheckbox
                      input={{
                        value: String(values.push_authorized),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(field.name, e?.target?.checked);
                        },
                      }}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      Include in push notifications
                    </InputCheckbox>
                  )}
                </Field>
                <Field name="email_authorized">
                  {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => (
                    <InputCheckbox
                      input={{
                        value: String(values.email_authorized),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(field.name, e?.target?.checked);
                        },
                      }}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      Include in emails
                    </InputCheckbox>
                  )}
                </Field>
                <ButtonWrapper
                  horizontallyAlignContent
                  padding={`${spacing.mdSpacing} 0 ${spacing.mdSpacing} 0`}
                >
                  <Button
                    kind="primary"
                    // @ts-expect-error: Property 'onClick' has incorrect type
                    onClick={handleSubmit}
                    type="submit"
                  >
                    Submit
                  </Button>
                </ButtonWrapper>
              </PaddedColumn>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default PhiAuthModal;
