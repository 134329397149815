import { ButtonSelector, Description, type OptionValue, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, useFormikContext } from 'formik';
import React from 'react';
import { NO_KNOWN_ALLERGIES } from '~shared/constants';
import AllergenSearchField from './AllergenSearchField';

const RADIO_YES_VALUE = 'Yes';
const RADIO_NO_VALUE = 'No';

const getHasAllergiesRadioValue = (
  allergies: string | null | undefined,
): typeof RADIO_YES_VALUE | typeof RADIO_NO_VALUE | null => {
  if (allergies === NO_KNOWN_ALLERGIES) return RADIO_NO_VALUE;
  return RADIO_YES_VALUE;
};

export const AllergensFields = () => {
  const {
    values: { allergies },
  } = useFormikContext<{ allergies: string | null | undefined }>();
  const hasAllergiesRadioValue = getHasAllergiesRadioValue(allergies);

  return (
    <>
      <Field name="allergies">
        {({ field, form: { setFieldValue } }: FieldProps<string>) => {
          return (
            <>
              <Description fontFamily="semibold">Any allergies?</Description>
              <XsSpacing />
              <ButtonSelector
                options={[
                  { label: RADIO_NO_VALUE, value: RADIO_NO_VALUE },
                  { label: RADIO_YES_VALUE, value: RADIO_YES_VALUE },
                ]}
                value={hasAllergiesRadioValue as OptionValue}
                onChange={() => {
                  if (hasAllergiesRadioValue === RADIO_NO_VALUE) {
                    setFieldValue(field.name, null);
                    setFieldValue('allergens', []);
                  } else {
                    setFieldValue(field.name, NO_KNOWN_ALLERGIES);
                    setFieldValue('allergens', null);
                  }
                }}
              />
            </>
          );
        }}
      </Field>
      {hasAllergiesRadioValue === RADIO_YES_VALUE && (
        <>
          <XsSpacing />
          <AllergenSearchField />
        </>
      )}
    </>
  );
};
