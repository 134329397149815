// @owners { team: patients-team }
import { XxsSpacing } from '@alto/design-system';
import { type PaymentBreakdown } from '@alto/scriptdash/alto/pricing/types/v3';
import { type PayerInformation } from '@alto/scriptdash/alto/pricing/types/v3/payer_information';
import React, { Fragment } from 'react';
import { PAYMENT_BREAKDOWN_LABELS } from '~shared/features/pricing/constants';
import { PricingInfoRow } from '../../pricing-info/components/PricingInfoRow';

type Props = {
  paymentBreakdown: PaymentBreakdown;
};

export const SavingsAttributions = ({ paymentBreakdown }: Props) => {
  return (
    <>
      {paymentBreakdown.payers.map((payer: PayerInformation) => {
        return (
          <Fragment key={payer.applied_order}>
            <XxsSpacing />
            <PricingInfoRow
              label={payer.label}
              savings
              price={payer.amount_covered}
            />
          </Fragment>
        );
      })}
      {paymentBreakdown.remainder_paid !== 0 && (
        <>
          <XxsSpacing />
          <PricingInfoRow
            savings
            label={PAYMENT_BREAKDOWN_LABELS.ADDITIONAL_SAVINGS}
            price={paymentBreakdown.remainder_paid}
          />
        </>
      )}
    </>
  );
};
