// @owners { team: patients-team }
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck {Button} - index signature for type 'string' is missing in type 'Element'.
import { SPACING } from '@alto/design-library-tokens';
import { Button, useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { ButtonWrapper } from '~web/components/ButtonWrapper';

// Assumes this will be used in the context of a Flex component with
// mdSpacing spacing, so that the space between the content and bottom button
// adds up to the desired amount
type Props = {
  readonly children: React.ReactNode;
  readonly horizontallyAlignContent?: boolean;
};

const StyledOnboardingScreenButtonWrapper = styled(ButtonWrapper)<{ isSMScreenOrBigger: boolean }>`
  > ${Button} {
    width: 100%;
    align-self: center;
  }
  width: 100%;
  margin-top: ${SPACING.STATIC.XL.px};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-top: 0;`}
`;

export const OnboardingScreenButtonWrapper = ({ children, horizontallyAlignContent }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <StyledOnboardingScreenButtonWrapper
      isSMScreenOrBigger={isSMScreenOrBigger}
      horizontallyAlignContent={horizontallyAlignContent}
    >
      {children}
    </StyledOnboardingScreenButtonWrapper>
  );
};
