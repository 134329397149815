// @owners { team: patients-team }
import { type Option } from '@alto/design-system';
import { FertilityCycleMedicationTypeMap } from '@alto/scriptdash/alto/fertility_cycles/types/v1/fertility_cycle_medication_type';

const { GONAL_F, MENOPUR, CETROTIDE } = FertilityCycleMedicationTypeMap;
export const MEDICATION_TYPES_DISPLAY = {
  [GONAL_F]: 'Gonal-f',
  [MENOPUR]: 'Menopur',
  [CETROTIDE]: 'Cetrotide',
  any: '',
};

export const CALCULATION_INFO_STRINGS = {
  [GONAL_F]: {
    STEP_1:
      'If you have Gonal-f pens on hand, determine the amount you have left by measuring the number of IUs remaining in the pens. If you have vials, count the number of unopened vials and multiply that number by the size of the vial',
    STEP_2: 'Divide the total amount on hand by your current prescribed dosage per day',
    STEP_3: 'Round down to the nearest whole day',
    EXAMPLE:
      '450 IU remaining on hand / dosage of 250 IU per day = 1.8, which rounds down to 1 day of medication on hand',
  },
  [MENOPUR]: {
    STEP_1:
      'Count the number of Menopur powder vials you have on hand and multiple that number by 75 IU (1 vial = 75 IU)',
    STEP_2: 'Divide the total amount on hand by your current prescribed dosage per day',
    STEP_3: 'Round down to the nearest whole day',
    EXAMPLE: '450 IU remaining on hand / dosage of 150 IU (2 vials) per day = 3 days of medication on hand',
  },
  [CETROTIDE]: {
    STEP_1: 'Count the number of Cetrotide kits you have on hand',
    STEP_2:
      'Divide the total amount on hand by your current prescribed dosage per day (this may be whole kits or a partial kit)',
    STEP_3: 'Round down to the nearest whole day',
    EXAMPLE: '2 kits on hand / dosage of 1 kit per day = 2 days of medication on hand',
  },
  any: {
    STEP_1: '',
    STEP_2: '',
    STEP_3: '',
    EXAMPLE: '',
  },
};

export const DAYS_SUPPLY_OPTIONS: Option[] = [
  {
    label: '0 days worth',
    value: '0',
  },
  {
    label: '1 days worth',
    value: '1',
  },
  {
    label: '2 days worth',
    value: '2',
  },
  {
    label: '3 days worth',
    value: '3',
  },
  {
    label: '4 days worth',
    value: '4',
  },
  {
    label: '5 days worth',
    value: '5',
  },
  {
    label: '6 days worth',
    value: '6',
  },
  {
    label: '7+ days worth',
    value: '7',
  },
];

export const CETROTIDE_FIRST_FILL_DAYS_SUPPLY_OPTIONS: Option[] = [
  {
    label: '0 days worth',
    value: '0',
  },
  {
    label: '1 days worth',
    value: '1',
  },
  {
    label: '2 days worth',
    value: '2',
  },
  {
    label: '3 days worth',
    value: '3',
  },
  {
    label: '4+ days worth',
    value: '4',
  },
];
