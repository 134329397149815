// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ListButton, SmSpacing } from '@alto/design-system';
import { useAddOnsContext, useQueryEssentials } from '@alto/features';
import { AddOnOtcCategoryTypeMap } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc_category';
import React from 'react';
import { push } from 'react-router-redux';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { GridCard, ProductScrollView } from '~web/features/essentials/components';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';
import { Text, View } from '~web/features/essentials/primitives';

export const MobileProducts = () => {
  const { shipmentID, origin } = useAddOnsContext();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID }));
  const { error, essentials, categoriesHash } = useQueryEssentials({
    addressID: shipment?.address_id,
    date: shipment?.scheduled_date,
  });
  useTrackViewedEvent(EVENTS.ESSENTIALS__ORDER_SUCCESS_WITH_ESSENTIALS, shipmentID, origin);
  const dispatch = useDispatchShared();

  const topSellerCategory = categoriesHash[AddOnOtcCategoryTypeMap.TOP_SELLERS];
  if (error || !topSellerCategory) {
    return null;
  }

  const products = topSellerCategory.add_on_otcs;
  const handleNavToEssentials = () =>
    dispatch(push({ pathname: '/store', search: `?order=${shipmentID}&origin=${origin}` }));

  return (
    <GridCard>
      <View flexDirection="column">
        <View
          flexDirection="column"
          padding={SPACING.STATIC.LG.px}
        >
          <Text
            title="Need anything else?"
            variant="h3"
          />
          <SmSpacing />
          <Text
            title="Add some essentials to this order"
            variant="body"
          />
          <SmSpacing />
          <ProductScrollView
            count={2.5}
            products={products}
            categoryName={topSellerCategory.name}
            scrollOverflow
            isStoreItems
          />
          <SmSpacing />
          <ListButton
            label={`See all ${essentials.length} products`}
            rightIcon="chevronright"
            onPress={handleNavToEssentials}
          />
        </View>
      </View>
    </GridCard>
  );
};
