// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Button, Column, H1 } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { Image } from './styles/image';
import pharmacist from '~web/images/pharmacist@3x.png';
import logoAlto from '~web/images/progynyAltoLogo@3x.png';
import progynyWomanPackage from '~web/images/progynyWomanPackage@3x.png';

const LOGO_IMAGE_HEIGHT = 84;
const HEAD_IMAGE_SIZE = 100;

const PageContainer = styledNative(Column).attrs({
  centerHorizontally: true,
})`
  height: 100vh;
  padding-top: ${SPACING.STATIC.LG.px};
  background-color: ${COLORS.BACKGROUND_COLORS.PINK_LIGHTEST};
`;

const InnerContainer = styledNative(Column).attrs({
  centerHorizontally: true,
  flexGrow: 1,
})`
  max-width: 500px;
`;

const HeaderContainer = styledNative(Column).attrs({
  centerHorizontally: true,
})`
  z-index: 1;
`;

const LogoContainer = styled.a`
  width: 233px;
  height: ${LOGO_IMAGE_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundImageContainer = styled.div`
  padding-left: ${SPACING.STATIC.XXL.value + SPACING.STATIC.LG.value}px;
  padding-right: ${SPACING.STATIC.XXL.value + SPACING.STATIC.LG.value}px;
  transform: translateY(${LOGO_IMAGE_HEIGHT / 4}px);
`;

const ContentContainer = styledNative(Column).attrs({
  centerHorizontally: true,
  flexGrow: 1,
})`
  background-color: white;
  border-top-left-radius: 75% ${HEAD_IMAGE_SIZE * 0.75}px;
  border-top-right-radius: 75% ${HEAD_IMAGE_SIZE * 0.75}px;
  padding-top: calc(${SPACING.STATIC.LG.px} + ${HEAD_IMAGE_SIZE / 2}px);
  padding-left: ${SPACING.STATIC.XL.px};
  padding-right: ${SPACING.STATIC.XL.px};
  padding-bottom: ${SPACING.STATIC.XL.px};
`;

const Head = styled.img`
  transform: translateY(${HEAD_IMAGE_SIZE / 2}px);
  height: ${HEAD_IMAGE_SIZE}px;
  width: ${HEAD_IMAGE_SIZE}px;
`;

const Content = styledNative(Column)`
  margin-bottom: ${SPACING.STATIC.XXL.px};
`;

type Props = {
  readonly handleOnboard: () => void;
  readonly loading: boolean;
};

const ProgynyLandingPage = ({ handleOnboard, loading }: Props) => {
  return (
    <>
      <PageContainer>
        <InnerContainer>
          <HeaderContainer>
            <LogoContainer
              href="https://alto.com"
              aria-label="Progyny Rx by Alto Logo"
            >
              <Image src={logoAlto} />
            </LogoContainer>
            <BackgroundImageContainer>
              <Image
                src={progynyWomanPackage}
                alt="Woman with Alto Package"
              />
            </BackgroundImageContainer>
            <Head
              src={pharmacist}
              alt={IMG_ALT_TEXTS.pharmacist}
            />
          </HeaderContainer>
          <ContentContainer>
            <Column>
              <Content>
                <H1 center>Welcome!</H1>
                <Body center>
                  I’m Jenny, a pharmacist here at Alto. We’re a full-service pharmacy, and we’ve partnered with Progyny
                  and your clinic to support you through your fertility journey.
                </Body>
              </Content>
              <Button
                loading={loading}
                loadingLabel="Loading..."
                onPress={handleOnboard}
                label="Get Started"
              />
            </Column>
          </ContentContainer>
        </InnerContainer>
      </PageContainer>
    </>
  );
};

export default ProgynyLandingPage;
