// @owners { team: patients-team }
import { useEffect, useMemo } from 'react';
import { getNextAvailableDateObjectForPrescription } from '~shared/features/next-available-date/selectors/getNextAvailableDateForPrescription';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { type ANALYTICS_EVENT } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { type BackorderOrigin } from '../constants';

type Props = {
  origin: BackorderOrigin;
  prescriptionId: number | undefined;
  event: ANALYTICS_EVENT;
};

export const useBackorderedAnalytics = ({ origin, prescriptionId, event }: Props) => {
  const { trackPageView } = useAnalytics();
  const user = useSelectorShared(getCurrentUser);
  const nad = useSelectorShared((state) => getNextAvailableDateObjectForPrescription(state, prescriptionId));
  const params = useMemo(
    () => ({
      userId: user?.id,
      origin,
      platform: getPlatformOS(),
      delayedAvailabilityReason: nad?.earliest.reason,
      nextAvailableDateFromNow: nad?.earliest.date,
    }),
    [user?.id, origin, nad?.earliest],
  );

  useEffect(() => {
    const additionalFields = { prescriptionId };
    trackPageView({ event, params, additionalFields });
    trackPageView({ event: 'Scheduling Blocked Due to Back Order', params, additionalFields });
  }, [trackPageView, event, params, prescriptionId]);
};
