// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { FlexRow } from '@alto/pocky';
import styled from 'styled-components';
import { spacing } from './styles';

export const ValuePropsGrid = styled.div<{ isLGScreenOrBigger: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
  align-items: center;

  ${({ isLGScreenOrBigger }) =>
    !isLGScreenOrBigger &&
    `
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      width: 90%;
    `}
`;

export const ValuePropCell = styled(FlexRow).attrs({
  verticallyAlignContent: true,
  horizontallyAlignContent: true,
})<{ isLGScreenOrBigger: boolean }>`
  width: 90%;
  max-width: 325px;
  text-align: center;
  margin: 0 auto;

  ${({ isLGScreenOrBigger }) =>
    !isLGScreenOrBigger &&
    `
      padding-bottom: ${spacing.mdSpacing};

      &:last-child {
        padding-bottom: 0;
      }
    `}
`;

export const LogosGrid = styled.div<{ isLGScreenOrBigger: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  width: 90%;

  ${({ isLGScreenOrBigger }) =>
    !isLGScreenOrBigger &&
    `
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
    `}
`;

export const LogoCell = styled(FlexRow).attrs({
  verticallyAlignContent: true,
  horizontallyAlignContent: true,
})`
  text-align: center;
  margin: 0 auto;
`;
