// @owners { team: patients-team }
import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  readonly title: string;
  readonly variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'body'
    | 'body-italic'
    | 'body-semibold'
    | 'body-semibold-italic'
    | 'link'
    | 'description'
    | 'description-italic'
    | 'description-semibold'
    | 'description-semibold-italic'
    | 'caption';
  readonly color?: keyof typeof COLORS.TEXT_COLORS;
  readonly numberOfLines?: number;
  readonly align?: 'center' | 'left' | 'right';
  readonly textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
};

const StyledReset = css`
  padding: 0;
  margin: 0;
`;
const StyledNumberOfLines = css<Props>`
  line-clamp: ${({ numberOfLines = 0 }) => numberOfLines};
  overflow: ${({ numberOfLines = 0 }) => (!numberOfLines ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ numberOfLines = 0 }) => numberOfLines};
  line-clamp: ${({ numberOfLines = 0 }) => numberOfLines};
  -webkit-box-orient: vertical;
`;
const StyledCustom = css<Props>`
  text-align: ${({ align = 'left' }) => align};
  text-transform: ${({ textTransform = 'none' }) => textTransform};
`;
const StyledH1 = styled.h1<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.HEADER.SEMIBOLD};
  font-size: ${TYPOGRAPHY.TEXT.HEADER.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.MD};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.SM};
  color: ${({ color = 'PRIMARY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledH2 = styled.h2<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD};
  font-size: ${TYPOGRAPHY.TEXT.HEADER.SM};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.MD};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'PRIMARY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledH3 = styled.h3<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD};
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.MD};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'PRIMARY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledBody = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledBodyItalic = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR_ITALIC};
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledBodySemiBold = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD};
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledBodySemiBoldItalic = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD_ITALIC};
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledDescription = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledDescriptionItalic = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR_ITALIC};
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledDescriptionSemiBold = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD};
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledDescriptionSemiBoldItalic = styled.p<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD_ITALIC};
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;
const StyledCaption = styled.caption<Props>`
  ${StyledReset};
  ${StyledNumberOfLines};
  ${StyledCustom}
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.MD};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  color: ${({ color = 'GREY' }) => COLORS.TEXT_COLORS[color]};
`;

export const Text = (props: Props) => {
  switch (props.variant) {
    case 'h1':
      return <StyledH1 {...props}>{props.title}</StyledH1>;
    case 'h2':
      return <StyledH2 {...props}>{props.title}</StyledH2>;
    case 'h3':
      return <StyledH3 {...props}>{props.title}</StyledH3>;
    case 'body':
      return <StyledBody {...props}>{props.title}</StyledBody>;
    case 'body-italic':
      return <StyledBodyItalic {...props}>{props.title}</StyledBodyItalic>;
    case 'body-semibold':
      return <StyledBodySemiBold {...props}>{props.title}</StyledBodySemiBold>;
    case 'body-semibold-italic':
      return <StyledBodySemiBoldItalic {...props}>{props.title}</StyledBodySemiBoldItalic>;
    case 'description':
      return <StyledDescription {...props}>{props.title}</StyledDescription>;
    case 'description-italic':
      return <StyledDescriptionItalic {...props}>{props.title}</StyledDescriptionItalic>;
    case 'description-semibold':
      return <StyledDescriptionSemiBold {...props}>{props.title}</StyledDescriptionSemiBold>;
    case 'description-semibold-italic':
      return <StyledDescriptionSemiBoldItalic {...props}>{props.title}</StyledDescriptionSemiBoldItalic>;
    case 'caption':
      return <StyledCaption {...props}>{props.title}</StyledCaption>;
    default:
      return <StyledBody {...props}>{props.title}</StyledBody>;
  }
};
