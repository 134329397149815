// @owners { team: patients-team }
import {
  Body,
  BulletedList,
  BulletedListItem,
  Button,
  Column,
  LgSpacing,
  Row,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import React from 'react';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';

type Props = {
  readonly inlineButton?: boolean;
  readonly onAddInsurance: () => void;
  readonly onSelectCashPay: () => void;
  readonly onSkip?: () => void;
};

const isWeb = getPlatformOS() === 'web';

export const EmptyInsurance = ({ inlineButton = false, onAddInsurance, onSelectCashPay, onSkip }: Props) => {
  const width = inlineButton ? 'inline' : 'full';
  const buttons = [
    <Button
      key="add-benefits-button"
      small
      width={width}
      label="Add benefits"
      onPress={onAddInsurance}
    />,
    <Button
      key="no-benefits-button"
      small
      width={width}
      label="I don’t have any benefits"
      onPress={onSelectCashPay}
      type="secondary"
    />,
  ];

  if (onSkip) {
    buttons.push(
      <Button
        key="do-this-later-button"
        small
        width={width}
        label="I’ll do this later"
        onPress={onSkip}
        type="tertiary"
      />,
    );
  }

  const orderedButtons = inlineButton ? buttons.reverse() : buttons;

  return (
    <>
      <Body fontFamily="bold">This helps us find you the best price possible for your medications.</Body>
      <SmSpacing />
      <Body>Benefits include:</Body>
      <XsSpacing />
      <BulletedList>
        <BulletedListItem>Your primary insurance card</BulletedListItem>
        <BulletedListItem>Your secondary insurance card</BulletedListItem>
        <BulletedListItem>Savings card (Team Cuban Card, etc)</BulletedListItem>
      </BulletedList>
      <LgSpacing />
      {isWeb ? (
        <Row right={inlineButton}>
          {orderedButtons.map((button, index) => (
            <React.Fragment key={`button-${index}`}>
              {button}
              {index !== orderedButtons.length - 1 ? <XsSpacing /> : null}
            </React.Fragment>
          ))}
        </Row>
      ) : (
        <Column>
          {orderedButtons.map((button, index) => (
            <React.Fragment key={`button-${index}`}>
              {button}
              {index !== orderedButtons.length - 1 ? <XsSpacing /> : null}
            </React.Fragment>
          ))}
        </Column>
      )}
    </>
  );
};
