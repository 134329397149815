import { BASE_FONT_SIZE } from '../general';

export const XS = {
  value: 2,
  px: '2px',
  rem: `${2 / BASE_FONT_SIZE}rem`,
} as const;

export const SM = {
  value: 4,
  px: '4px',
  rem: `${4 / BASE_FONT_SIZE}rem`,
} as const;

export const MD = {
  value: 8,
  px: '8px',
  rem: `${8 / BASE_FONT_SIZE}rem`,
} as const;

export const LG = {
  value: 16,
  px: '16px',
  rem: `${16 / BASE_FONT_SIZE}rem`,
} as const;

export const XL = {
  value: 24,
  px: '24px',
  rem: `${24 / BASE_FONT_SIZE}rem`,
} as const;

export const XXL = {
  value: 32,
  px: '32px',
  rem: `${32 / BASE_FONT_SIZE}rem`,
} as const;

export const CIRCLE = {
  value: 50,
  px: '50px',
  rem: `${50 / BASE_FONT_SIZE}rem`,
} as const;

export const NONE = {
  value: 0,
  px: '0px',
  rem: '0rem',
} as const;

export const DEFAULT = {
  value: 4,
  px: '4px',
  rem: `${4 / BASE_FONT_SIZE}rem`,
} as const;

export const RADIUS = {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
  CIRCLE,
  NONE,
  DEFAULT,
} as const;

export type BORDERS_RADIUS_SIZE = (typeof RADIUS)[keyof typeof RADIUS];
