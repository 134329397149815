// @owners { team: patients-team }
import {
  type LineItem,
  type LineItemType,
  LineItemTypeMap,
} from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { isNumber } from 'lodash';
import {
  NEW_ESTIMATED_PREFIX_COPY,
  PAYMENT_BREAKDOWN_LABELS,
  PRESCRIPTION_PRICING_LABELS,
  PRICE_FREE,
  PRICING_LABELS,
  TOTAL_PRICE_PENDING,
} from '~shared/features/pricing/constants';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';

export const isItemTypeExpeditedDeliveryFee = (itemType?: LineItemType) => {
  return (
    itemType === LineItemTypeMap.ASAP_DELIVERY_FEE ||
    itemType === LineItemTypeMap.SAME_DAY_DELIVERY_FEE ||
    itemType === LineItemTypeMap.NEXT_DAY_DELIVERY_FEE ||
    itemType === LineItemTypeMap.STANDARD_DELIVERY_FEE
  );
};

export const parseLineItem = (lineItem: LineItem) => {
  const maybeEstimated = lineItem.info_key === 'estimated' ? NEW_ESTIMATED_PREFIX_COPY : '';
  let shownPrice = lineItem.value === null || lineItem.value === undefined ? '-' : lineItem.value;
  let originalPrice;
  const priceUnavailable = lineItem.info_key === 'price_unavailable';

  if (priceUnavailable) {
    shownPrice = TOTAL_PRICE_PENDING;
  }

  if (lineItem.key === 'delivery_fee' || lineItem.key === 'pickup') {
    shownPrice = lineItem.value || PRICE_FREE;
  }

  if (isItemTypeExpeditedDeliveryFee(lineItem.key)) {
    originalPrice = lineItem?.original_value;
    shownPrice = lineItem.value || PRICE_FREE;
  }

  const label = `${maybeEstimated}${lineItem.label}`;
  const savings = PRICING_LABELS[lineItem.key].savings;

  return { label, originalPrice, shownPrice, savings };
};

export const getFormattedPrice = (price: number | string | null | undefined, savings?: boolean) => {
  if (price === undefined || price === null) {
    return '-';
  }

  let formattedPrice = String(price);

  if (isNumber(price)) {
    formattedPrice = formatDollarsWithPlaceholder(price);

    if (savings) {
      formattedPrice = `- ${formattedPrice}`;
    }
  }

  return formattedPrice;
};

export const isTotal = (label: string) => {
  return (
    label === PRICING_LABELS.total.display || label === `${NEW_ESTIMATED_PREFIX_COPY}${PRICING_LABELS.total.display}`
  );
};

export const shouldBeSemibold = (label: string) => {
  return (
    label === PRESCRIPTION_PRICING_LABELS.YOUR_COPAY ||
    label === PRESCRIPTION_PRICING_LABELS.LAST_COPAY ||
    label === PAYMENT_BREAKDOWN_LABELS.TOTAL ||
    label === PAYMENT_BREAKDOWN_LABELS.TOTAL_WITH_INSURANCE ||
    label === PAYMENT_BREAKDOWN_LABELS.PREV_TOTAL ||
    label === PAYMENT_BREAKDOWN_LABELS.PREV_TOTAL_WITH_INSURANCE ||
    isTotal(label)
  );
};
