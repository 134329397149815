import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { useAccessibilityFocus } from '../../../hooks/useAccessibilityFocus';
import { Text, type TextProps, type TextTransform } from './Text';

export type BodyProps = Pick<
  TextProps,
  'center' | 'children' | 'color' | 'numberOfLines' | 'textDecorationLine' | 'fontFamily'
> & {
  readonly autofocus?: boolean;
  readonly textSize?: 'detail';
  testID?: string;
  readonly textTransform?: TextTransform;
};

export const Body = ({
  center,
  children,
  color = COLORS.TEXT_COLORS.GREY,
  numberOfLines,
  textDecorationLine,
  autofocus,
  textSize,
  textTransform,
  fontFamily,
  testID,
}: BodyProps) => {
  const ref = useAccessibilityFocus(autofocus);

  return (
    <Text
      accessible={autofocus}
      fontFamily={fontFamily}
      textTransform={textTransform}
      center={center}
      color={color}
      numberOfLines={numberOfLines}
      textDecorationLine={textDecorationLine}
      ref={ref}
      textSize={textSize}
      testID={testID}
    >
      {children}
    </Text>
  );
};
