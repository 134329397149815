// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import { ASSISTANT_PRESSED_EVENTS, ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantViewedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  ASSISTANT_ORDERING_ACTIONS,
  type ActionSheetProviderStackCacheKey,
  type AssistantCategory,
  type AssistantMessageSubject,
  type AssistantOrderingAction,
  MESSAGE_PROMPTS,
  ORDER_CATEGORY,
} from '~shared/features/alto-assistant/constants';
import { partitionMedicationsByActiveState } from '~shared/features/my-meds/selectors/partitionMedicationsByActiveState';
import { getCurrentUserIsEligibleForQuantityChange } from '~shared/features/users/selectors/getUserIsEligibleForQuantityChange';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type AssistantMessageParams } from '../../constants';
import { HowToEditQuantityActionSheet } from './HowToEditQuantityActionSheet';
import { HowToOrderActionSheet } from './HowToOrderActionSheet';
import { MenuOption } from './MenuOption';

const getNamePlaceholder = (prescriptionName?: string) => prescriptionName ?? '**Prescription Name**';

export const getQuantityChangeAssistantMessageSubject = (prescriptionName?: string) => {
  const nameForSubject = ` of ${getNamePlaceholder(prescriptionName)}`;
  return `${ASSISTANT_MESSAGE_SUBJECTS.DIFFERENT_AMOUNT}${nameForSubject}`;
};

export const getQuantityChangeAssistantMessageBody = (prescriptionName?: string) => {
  return `I would like to change the quantity of ${getNamePlaceholder(prescriptionName)} to **New Quantity**.`;
};

export const OrderingActionSheet = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const isEligibleForQuantityChange = useSelectorShared(getCurrentUserIsEligibleForQuantityChange);
  useEffect(() => {
    dispatch(sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.ORDERING_ACTION_SHEET));
  }, [dispatch]);
  const { saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);

  const { active, inactive, needsAttention } = useSelectorShared(partitionMedicationsByActiveState);

  const trackActionChosen = (action: AssistantOrderingAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.ORDERING_ACTION_CHOSEN,
        action,
        category: ORDER_CATEGORY,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const onNavigate = (action: AssistantOrderingAction, subject: AssistantMessageSubject | string, message?: string) => {
    // set the cache key so the wizard correctly reopens when navigating back from AssistantMedSelection
    const stackCacheKey =
      action === ASSISTANT_ORDERING_ACTIONS.UNABLE_TO_ORDER || action === ASSISTANT_ORDERING_ACTIONS.DATE_UNAVAILABLE
        ? 'assistant-ordering'
        : 'assistant';
    saveStack<ActionSheetProviderStackCacheKey>(stackCacheKey);
    closeActionSheet();

    const category: AssistantCategory = `${ORDER_CATEGORY}.${action}`;
    const messageParams: AssistantMessageParams = {
      messagePrompt: MESSAGE_PROMPTS.HELP_WITH_ORDER,
      origin: ASSISTANT_CHAT_ORIGINS.ORDERING,
      category,
      subject,
      message: message ?? '',
    };

    const totalMedications = active.length + needsAttention.length + inactive.length;
    if (totalMedications === 0) {
      navigate('RouteAssistantMessage', messageParams);
    } else if (action === ASSISTANT_ORDERING_ACTIONS.UNABLE_TO_ORDER) {
      navigate('RouteAssistantMedSelection', { stackCacheKey, category });
    } else if (action === ASSISTANT_ORDERING_ACTIONS.DATE_UNAVAILABLE) {
      navigate('RouteAssistantMedSelection', { preferredDateUnavailable: true, stackCacheKey, category });
    } else {
      navigate('RouteAssistantMessage', messageParams);
    }
  };

  const items = [
    {
      key: 'cannot-add-to-cart',
      component: (
        <MenuOption
          iconName="cart-duo"
          onPress={() => {
            trackActionChosen('unable_to_order');
            onNavigate('unable_to_order', ASSISTANT_MESSAGE_SUBJECTS.UNABLE_TO_ORDER);
          }}
          text="Unable to add to cart"
        />
      ),
    },
    {
      key: 'get-med-sooner',
      component: (
        <MenuOption
          iconName="calendar-duo"
          onPress={() => {
            trackActionChosen('date_unavailable');
            saveStack<ActionSheetProviderStackCacheKey>('assistant-ordering');
            closeActionSheet();
            onNavigate('date_unavailable', ASSISTANT_MESSAGE_SUBJECTS.DATE_UNAVAILABLE);
          }}
          text="Get my medication sooner"
        />
      ),
    },
    {
      key: 'different-quantity',
      component: (
        <MenuOption
          iconName="pills-duo"
          onPress={() => {
            trackActionChosen('different_amount');
            if (isEligibleForQuantityChange) {
              setActiveActionSheet(<HowToEditQuantityActionSheet />);
            } else {
              onNavigate(
                ASSISTANT_ORDERING_ACTIONS.DIFFERENT_AMOUNT,
                getQuantityChangeAssistantMessageSubject(),
                getQuantityChangeAssistantMessageBody(),
              );
            }
          }}
          text="Get a different amount"
        />
      ),
    },
    {
      key: 'how-to-order',
      component: (
        <MenuOption
          iconName="loader-duo"
          onPress={() => {
            trackActionChosen('need_to_order');
            setActiveActionSheet(<HowToOrderActionSheet />);
          }}
          text="How to order a medication"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            trackActionChosen('something_else');
            onNavigate(ASSISTANT_ORDERING_ACTIONS.SOMETHING_ELSE, ASSISTANT_MESSAGE_SUBJECTS.ORDERING);
          }}
          text="Something else"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="Ordering"
      analyticsName="assistant ordering"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
