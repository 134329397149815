import { BASE_FONT_SIZE } from '../general';

const SM = {
  value: 32,
  px: '32px',
  rem: `${32 / BASE_FONT_SIZE}rem`,
} as const;

const MD = {
  value: 40,
  px: '40px',
  rem: `${40 / BASE_FONT_SIZE}rem`,
} as const;

const LG = {
  value: 80,
  px: '80px',
  rem: `${80 / BASE_FONT_SIZE}rem`,
} as const;

const XL = {
  value: 160,
  px: '160px',
  rem: `${160 / BASE_FONT_SIZE}rem`,
} as const;

export const AVATAR = {
  SM,
  MD,
  LG,
  XL,
} as const;
