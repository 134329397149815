// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, Button, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { MenuOption } from './MenuOption';

const MedsTabButton = () => {
  const { trackEvent } = useAnalytics();
  const { navigate } = useNavigation();

  const { closeActionSheet } = useContext(ActionSheetContext);

  const onPress = () => {
    closeActionSheet();
    navigate('RouteAppTabNavigator', { screen: 'RouteTabMyMeds' });
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.VIEW_MEDS,
      },
    });
  };

  return (
    <Button
      label="Take me to meds tab"
      onPress={onPress}
    />
  );
};

export const HowToEditQuantityActionSheet = () => {
  const items = [
    {
      key: 'check-quantity-change-button',
      component: (
        <MenuOption
          description="Check to see if your medication has a ‘change quantity’ button."
          iconName="pillbottle-duo"
          text="Go to your 'Meds' tab"
        />
      ),
    },
    {
      key: 'change-quantity',
      component: (
        <MenuOption
          description="You can change the quantity of medications that have been approved for adjustment."
          iconName="pills-duo"
          text="Change the quantity"
        />
      ),
    },
    {
      key: 'add-to-cart',
      component: (
        <MenuOption
          description="Once the quantity has been updated, you can checkout with the medication as usual."
          iconName="sync-duo"
          text="Add to cart"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="How to edit your quantity"
      buttons={[<MedsTabButton key="edit" />]}
      analyticsName="assistant edit quantity"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
