// @owners { team: patients-team }
import { Body, Card, LgPadding } from '@alto/design-system';
import React from 'react';

type Props = {
  readonly title: string;
};

export const EmptyDeliveryCard = ({ title }: Props) => {
  return (
    <Card>
      <LgPadding>
        <Body center>You don't have any {title.toLowerCase()}.</Body>
      </LgPadding>
    </Card>
  );
};
