import { unauthenticatedApis, unauthenticatedRoutes } from './constants';

export const pathRequiresAuthentication = (path: string) => {
  if (unauthenticatedRoutes.includes(path)) return false;

  const api = path.replace(/^\//, '').split('/')[0];
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (unauthenticatedApis.includes(api)) return false;

  return true;
};
