// @owners { team: patients-team }
import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  width: 189px;
  height: 159px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

type ContainedImageProps = {
  readonly href?: string;
  readonly src: string;
  readonly alt: string;
};
export const ContainedImage = ({ href, src, alt }: ContainedImageProps) => (
  <ImageContainer
    as={href ? 'a' : undefined}
    href={href}
  >
    <Image
      src={src}
      alt={alt}
    />
  </ImageContainer>
);

export const HeadImage = styled.img<{ isSMScreenOrBigger: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 98px; height: 98px;`}
`;
