// @owners { team: patients-team }
import { type AddOnOtcCategory } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc_category';
import { CategorySearch } from './types';

export const getActiveCategoryName = (activeCategory: number, categories: AddOnOtcCategory[]) => {
  switch (activeCategory) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    case CategorySearch.Search:
      return 'Search';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    case CategorySearch.All:
      return 'All';
    default:
      return categories[activeCategory]?.name;
  }
};
