// @owners { team: patients-team }
import { memoize } from 'lodash';

/**
 * Returns a unique id for the set of address (or facility) and prescriptions
 * associated with the NextAvailableDate.
 */
export type NextAvailableDateIDOpts = {
  addressID: number | null | undefined;
  facilityID: number | null | undefined;
  prescriptionIDs: number[];
};

export const getNextAvailableDateID = memoize(
  ({ addressID, facilityID, prescriptionIDs }: NextAvailableDateIDOpts): string => {
    if (!(addressID || facilityID)) {
      throw new Error('One of addressId or facilityId is required.');
    }

    const addressType = addressID ? 'address' : 'facility';
    const addressOrFacilityID = addressID || facilityID;

    return `${addressType}:${addressOrFacilityID};${prescriptionIDs.sort().join('-')}`;
  },
  ({ addressID, facilityID, prescriptionIDs }) => `${addressID}${facilityID}${prescriptionIDs.join('')}`,
);
