// @owners { team: patients-team }
import { type DeliveryMethodType } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method_type';
import { type Address, type DeliveryDetails, type LightDelivery } from '~shared/types';

export const formatDeliveryDetails = (
  address: Address | undefined,
  upcomingDelivery: LightDelivery | undefined,
): DeliveryDetails | undefined => {
  return upcomingDelivery?.date && address
    ? {
        address,
        date: upcomingDelivery.date,
        startTime: upcomingDelivery.deliver_after,
        endTime: upcomingDelivery.deliver_before,
        deliveryMethod: upcomingDelivery.delivery_method,
        status: upcomingDelivery.status,
      }
    : undefined;
};

export const getIcon = (method: DeliveryMethodType | undefined) => {
  switch (method) {
    case 'courier':
      return 'car';
    case 'pickup':
      return 'handbox';
    default:
      return 'box';
  }
};
