// @owners { team: patients-team }
import { getUserCashPayOnly } from '~shared/features/users/selectors/getUserCashPayOnly';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { type ReduxStateShared } from '~shared/store';

const resolutionValues = {
  skip: 'skip',
  cashPayOnly: "don't use insurance",
  insurance: 'use insurance',
};
export type ResolutionValues = (typeof resolutionValues)[keyof typeof resolutionValues];

export const getResolution = (state: ReduxStateShared): ResolutionValues => {
  const userID = getCurrentUserID(state);
  const insurances = state.insurances.insurances.filter(({ user_id }) => user_id === userID);
  const isCashPayOnly = getUserCashPayOnly(state, {
    userID,
  });
  if (isCashPayOnly) return resolutionValues.cashPayOnly;
  const hasNoInsurances = insurances.length === 0;
  if (hasNoInsurances) return resolutionValues.skip;
  return resolutionValues.insurance;
};
