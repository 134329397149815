import { pathRequiresAuthentication } from './pathRequiresAuthentication';

const isTest = !!process.env.JEST_WORKER_ID;

export const responseRedirect = (response: Response) => {
  // If the request is unauthorized and requires authentication
  if (
    response.status === 401 &&
    !isTest &&
    !!window?.location &&
    pathRequiresAuthentication(window.location.pathname)
  ) {
    window.location.href = `/login?next=${window.location.pathname}&authorized=0`;
  }
};
