// @owners { team: patients-team }
import { type FulfillmentOption } from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import { formatWindowTimeLong } from '~shared/helpers/date';

/**
 * The properties of OrderDeliveryWindow are returned as isoDate strings.
 * This function formats the delivery window to a format that matches the redux store.
 */
export const formatDeliveryWindow = (fulfillmentOption: FulfillmentOption) => {
  if (fulfillmentOption.fulfillment_window_option?.delivery_window) {
    const {
      fulfillment_window_option: {
        delivery_window: { start_time, end_time, cutoff_time },
      },
      ...rest
    } = fulfillmentOption;
    return {
      ...rest,
      fulfillment_window_option: {
        ...fulfillmentOption.fulfillment_window_option,
        delivery_window: {
          start_time: formatWindowTimeLong(start_time, 'a'),
          end_time: formatWindowTimeLong(end_time, 'a'),
          cutoff_time: cutoff_time ? formatWindowTimeLong(cutoff_time, 'a') : null,
        },
      },
    } as FulfillmentOption;
  }
  return fulfillmentOption;
};
