// @owners { team: patients-team }
import { BORDERS, COLORS } from '@alto/design-library-tokens';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import { StyledTouchable } from '~web/features/essentials/primitives/Styled';

type Props = {
  readonly children?: ReactNode | ReactNode[];
  readonly display?: 'flex' | 'inline' | 'block' | 'inline-block' | 'none';
  readonly position?: 'sticky' | 'absolute' | 'relative' | 'static' | 'fixed';
  readonly flexDirection?: 'row' | 'column';
  readonly borderRadius?: keyof typeof BORDERS.RADIUS | 'initial';
  readonly justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around' | 'normal';
  readonly alignItems?: 'center' | 'flex-end' | 'flex-start' | 'normal';
  readonly alignSelf?: 'center' | 'flex-end' | 'flex-start' | 'normal';
  readonly alignContent?: 'center' | 'flex-end' | 'flex-start' | 'normal';
  readonly flexGrow?: number | 'initial';
  readonly flexShrink?: number | 'initial';
  readonly flexWrap?: 'wrap' | 'nowrap' | 'initial';
  readonly flexBasis?: string;
  readonly padding?: string;
  readonly margin?: string;
  readonly maxWidth?: string;
  readonly width?: string;
  readonly height?: string;
  readonly style?: React.CSSProperties;
  readonly cursor?: 'default' | 'pointer';
  readonly overflow?: 'scroll' | 'visible' | 'hidden' | 'initial';
  readonly opacity?: number;
  readonly onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  readonly backgroundColor?: keyof typeof COLORS.BACKGROUND_COLORS | 'transparent';
};

const StyledView = styled.div`
  padding: ${({ padding = '0px' }: Props) => padding};
  margin: ${({ margin = '0px' }: Props) => margin};
  max-width: ${({ maxWidth = '' }: Props) => maxWidth};
  width: ${({ width = '' }: Props) => width};
  height: ${({ height = '' }: Props) => height};
  position: ${({ position = 'static' }: Props) => position};
  border-radius: ${({ borderRadius = 'initial' }: Props) =>
    borderRadius === 'initial' ? 'initial' : BORDERS.RADIUS[borderRadius].px}};
  background-color: ${({ backgroundColor = 'transparent' }: Props) =>
    backgroundColor === 'transparent' ? 'transparent' : COLORS.BACKGROUND_COLORS[backgroundColor]};
  display: ${({ display = 'flex' }: Props) => display}; 
  flex-direction: ${({ flexDirection = 'row' }: Props) => flexDirection};
  justify-content: ${({ justifyContent = 'normal' }: Props) => justifyContent};
  align-items: ${({ alignItems = 'normal' }: Props) => alignItems};
  align-self: ${({ alignSelf = 'normal' }: Props) => alignSelf};
  align-content: ${({ alignContent = 'normal' }: Props) => alignContent};
  flex-wrap: ${({ flexWrap = 'initial' }: Props) => flexWrap};
  flex-grow: ${({ flexGrow = 'initial' }: Props) => flexGrow};
  flex-shrink: ${({ flexShrink = 'initial' }: Props) => flexShrink};
  flex-basis: ${({ flexBasis = 'auto' }: Props) => flexBasis};
  cursor: ${({ cursor = 'default' }: Props) => cursor};
  overflow: ${({ overflow = 'initial' }: Props) => overflow};
  ${({ onClick }: Props) => onClick && StyledTouchable};
  opacity: ${({ opacity = 1 }: Props) => opacity};
`;

export const View = ({ children, ...rest }: Props) => {
  return <StyledView {...rest}>{children}</StyledView>;
};
