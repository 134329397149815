// eslint-disable-next-line @alto/no-pocky-import
import {
  InputRow,
  InputUploadImage,
  type File as PockyFile,
  createValidator,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldInputProps, type FieldProps, type FormikProps } from 'formik';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

export type Values = {
  image_url: PockyFile[] | undefined;
  image_url_2: PockyFile[] | undefined;
};

export const validate = createValidator({
  image_url: validatePresentWithMessage('Front image'),
  image_url_2: validatePresentWithMessage('Back image'),
});

type Props = {
  readonly viewOnly?: boolean;
};

const disabledStyles = css`
  & > div > div {
    cursor: not-allowed;
    & > div > button {
      display: none;
    }
  }
`;

const StyledInputUploadImage = styled(InputUploadImage)<{ disabled?: boolean }>`
  ${({ disabled = false }) => (disabled ? disabledStyles : '')}
`;

const InsurancePhotoFormElements = ({ viewOnly = false }: Props) => {
  const onChangePhotoInput = useCallback((field: FieldInputProps<any>, form: FormikProps<any>) => {
    return (files: File[] | null | undefined) => {
      form.setFieldValue(field.name, files);
    };
  }, []);

  return (
    <>
      <InputRow>
        <Field name="image_url">
          {({ field, meta, form }: FieldProps<string, Values>) => (
            <StyledInputUploadImage
              disabled={viewOnly}
              label="Front of Card"
              name="frontOfCard"
              id="frontOfCard"
              uploadText="Upload photo"
              meta={meta}
              value={field.value}
              onChange={onChangePhotoInput(field, form)}
              required
            />
          )}
        </Field>
      </InputRow>
      <InputRow>
        <Field name="image_url_2">
          {({ field, meta, form }: FieldProps<string, Values>) => (
            <StyledInputUploadImage
              disabled={viewOnly}
              label="Back of Card"
              name="backOfCard"
              id="backOfCard"
              uploadText="Upload photo"
              meta={meta}
              value={field.value}
              onChange={onChangePhotoInput(field, form)}
              required
            />
          )}
        </Field>
      </InputRow>
    </>
  );
};

export default InsurancePhotoFormElements;
