import { createSelector } from 'reselect';
import { getPrescriptionsHash } from './getPrescriptionByID';
import { getSelectedPrescriptionID } from './getPrescriptions';
import { type Prescription } from '~shared/types';

export const getSelectedPrescription = createSelector(
  [getPrescriptionsHash, getSelectedPrescriptionID],
  (prescriptions, prescriptionID): Prescription | undefined => {
    if (!prescriptionID) return;
    return prescriptions[prescriptionID];
  },
);
