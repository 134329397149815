import { SPACING } from '@alto/design-library-tokens';
import { Card, Description, ExternalLink, LgPadding, MdSpacing } from '@alto/design-system';
import React from 'react';
import { ALTO_LOCATIONS_URL } from '~shared/constants';
import { AddressSelector } from '../../../address';
import { useCheckoutAddressInfoKeys } from '../../hooks/useCheckoutAddressInfoKeys';
import { AddressInfoAlert } from './address-info/AddressInfoAlert';

type Props = {
  readonly disabled?: boolean; // address selection is disabled for split shipments on the courier shipment
};

export const DeliveryAddressCard = ({ disabled }: Props) => {
  const { isOutOfZone } = useCheckoutAddressInfoKeys();

  return (
    <Card
      title="Your address"
      overflow="hidden"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <AddressSelector
          disabled={disabled}
          origin="checkout"
        />
      </LgPadding>
      <MdSpacing />
      {isOutOfZone && <OutOfZoneAlert />}
    </Card>
  );
};

const OutOfZoneAlert = () => (
  <AddressInfoAlert type="error">
    <Description>
      This address is outside of our service area. Select an address within{' '}
      <ExternalLink href={ALTO_LOCATIONS_URL}>our service area</ExternalLink>.
    </Description>
  </AddressInfoAlert>
);
