// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, Button, InputCheckbox, MdPadding } from '@alto/design-system';
import React, { useCallback, useContext, useState } from 'react';
import { setFamilyMemberFilters } from '~shared/actions/ui/medList';
import { getUsers } from '~shared/features/users/selectors/getUsers';
import { getFamilyMemberFilters } from '~shared/selectors/ui/medList';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type User } from '~shared/types';
import { getNextToggleState } from './toggleCheckbox';

const getUserLabel = ({ first_name, preferred_first_name }: Pick<User, 'first_name' | 'preferred_first_name'>) => {
  const firstName = preferred_first_name || first_name;
  const possessiveForm = firstName?.endsWith('s') ? `${firstName}’` : `${firstName}’s`;
  return `${possessiveForm} medications`;
};

export const FamilyMemberFilterActionSheet = () => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();
  const users = useSelectorShared(getUsers);
  const familyMemberFilters = useSelectorShared(getFamilyMemberFilters);
  const [checkedByInputName, setCheckedByInputName] = useState(() => {
    // initialize the state with all family members selected.
    if (familyMemberFilters.ALL) {
      return { ALL: true, ...users.reduce((userMap, user) => ({ ...userMap, [user.id]: true }), {}) };
    }
    return familyMemberFilters;
  });
  const handleToggleCheckbox = useCallback(
    (name: string) => () => {
      setCheckedByInputName((prevState) => getNextToggleState(prevState, name));
    },
    [],
  );

  const applyFilters = useCallback(() => {
    dispatch(setFamilyMemberFilters(checkedByInputName));
    closeActionSheet();
  }, [checkedByInputName, closeActionSheet, dispatch]);

  return (
    <ActionSheetV2
      title="Filter family members"
      analyticsName="filter by family member"
    >
      <>
        {users.map((user) => {
          const label = getUserLabel(user);
          const name = user.id.toString();

          return (
            <InputCheckbox
              key={`filter-family-member-user-id-${user.id}`}
              label={label}
              name={name}
              noHighlight
              onPress={handleToggleCheckbox(name)}
              value={checkedByInputName[name]}
            />
          );
        })}
      </>
      <MdPadding>
        <Button
          label="Apply"
          onPress={applyFilters}
          disabled={!Object.values(checkedByInputName).some(Boolean)}
        />
      </MdPadding>
    </ActionSheetV2>
  );
};
