// @owners { team: patients-team }
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { removeUnschedulablePrescriptionsFromCart } from '~shared/actions/cart';
import {
  fetchActivePrescriptionsSucceeded,
  fetchPrescriptionsFailed,
  fetchPrescriptionsSucceeded,
} from '~shared/actions/prescriptions';
import { get } from '~shared/helpers/apiHelper';
import { type ReduxDispatchShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { type Prescription } from '~shared/types/clients';

type FetchPrescriptionResponse = Prescription[] & { error: APIError };

export const prescriptions = createQueryKeys('prescriptions', {
  /**
   * The fetchPrescriptions call will either fetch just active prescriptions or all prescriptions if it's been an hour
   * since the last fetch all prescriptions.
   *
   * We've strategically done this to avoid the performance hit of fetching all prescriptions every time we call
   * fetchPrescriptions, because in most cases, we don't need to do this.
   *
   * Ideally, the default fetchPrescriptions call does not do this fetch split based off of time. We should be explicit
   * about which prescriptions we care about and make targeted calls to fetch only those prescriptions.
   *
   * 📝 We're also doing the redux store syncing in the `queryFn` because the `useQuery` `onSuccess` handler as well as
   * the other handlers will be deprecated in the v5.
   * @see https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
   */
  prescriptions: ({ dispatch, shouldFetchAll }: { dispatch: ReduxDispatchShared; shouldFetchAll: boolean }) => ({
    queryKey: [{ shouldFetchAll }],
    queryFn: async (): Promise<FetchPrescriptionResponse> => {
      // if we shouldn't fetch all prescriptions, we'll fetch only active prescriptions
      const response: FetchPrescriptionResponse = await get(
        '/prescriptions',
        { batchley: true, active: !shouldFetchAll },
        { version: 'v3' },
      );

      if (response.error) {
        dispatch(fetchPrescriptionsFailed(response.error));
      } else {
        if (shouldFetchAll) {
          dispatch(fetchPrescriptionsSucceeded(response));
        } else {
          dispatch(fetchActivePrescriptionsSucceeded(response));
        }
        dispatch(removeUnschedulablePrescriptionsFromCart());
      }
      return response;
    },
  }),
});
