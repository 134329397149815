import { type Address as HomescreenAddress } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/address';
import { capitalizeString } from './string';
import { getFormattedAddress } from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type Address } from '~shared/types/clients';

export type GooglePlacesAddress = Address & {
  neighborhood?: string;
};

export type ShortAddressOptions = {
  street_address_1?: string | null;
  street_address_2?: string | null;
  city?: string | null;
  neighborhood?: string | null;
};

// 1400 Tennessee Street Unit 2, San Francisco
export function formatShortAddress(address?: ShortAddressOptions): string {
  if (!address) {
    return '';
  }

  const { street_address_1, street_address_2, city, neighborhood } = address;

  let addressString = street_address_1 || '';

  if (street_address_2) {
    addressString += ` ${street_address_2}`;
  }

  if (neighborhood) {
    addressString += `, ${neighborhood}`;
  }

  if (city) {
    addressString += `, ${city}`;
  }

  return addressString;
}

// 1400 Tennessee Street Unit 2, San Francisco CA, 94107
export function formatFullAddress(address: Partial<GooglePlacesAddress> | HomescreenAddress | undefined): string {
  if (!address) {
    return '';
  }

  const { state, zip } = address;
  let fullAddress = formatShortAddress(address);

  if (state) {
    fullAddress += ` ${state.toUpperCase()}`;
  }

  if (zip) {
    fullAddress += `, ${zip}`;
  }

  return fullAddress;
}

// 1400 Tennessee Street (Office)
export const getAddressOneLiner = (address: Partial<GooglePlacesAddress>): string => {
  const { street_address_1, kind } = address;
  // eslint-disable-next-line sonarjs/no-nested-template-literals
  return address ? `${street_address_1 || ''} ${kind ? `(${capitalizeString(kind)})` : ''}` : '';
};

// 1400 Tennessee Street Unit 2
export function formatStreetAddress(address?: Partial<GooglePlacesAddress> | HomescreenAddress): string {
  if (!address) {
    return '';
  }

  const { street_address_1, street_address_2 } = address;

  let addressString = street_address_1 || '';

  if (street_address_2) {
    addressString += ` ${street_address_2}`;
  }

  return addressString;
}

// San Francisco, CA 94107
export function formatCityStateZip(address: Partial<GooglePlacesAddress>): string {
  if (!address) {
    return '';
  }

  const { city, state, zip } = address;
  let cityStateZip = city || '';

  if (state || zip) {
    cityStateZip += `, ${[state, zip].filter(Boolean).join(' ')}`;
  }

  return cityStateZip;
}

// San Francisco, CA
export function formatCityState(address: Partial<GooglePlacesAddress>) {
  if (!address) {
    return '';
  }

  const { city, state } = address;
  return [city, state].filter(Boolean).join(', ');
}

/**
 * @param address {string | Address}
 * @param isFacility {boolean} - if true, will append Alto Pharmacy to the address
 * @returns {string} - patient-friendly readable address
 */
export function formatLocationCopy({
  address,
  isFacility = false,
}: {
  address: string | Address;
  isFacility?: boolean;
}): string {
  if (typeof address === 'string') {
    return (isFacility ? 'Alto Pharmacy, ' : '') + address;
  } else if (isFacility) {
    return 'Alto Pharmacy, ' + getFormattedAddress(address);
  } else {
    return formatShortAddress(address);
  }
}
