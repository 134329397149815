// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, LgPadding } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type Prescription } from '~shared/types';
import { VacationOverrideForm } from './VacationOverrideForm';

type Props = {
  readonly prescription: Prescription;
};

export const RequestVacationSupply = ({ prescription }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({
      event: EVENTS.VACATION_SUPPLY_FORM_VIEWED,
      params: {},
      additionalFields: {
        prescriptionId: prescription.id,
      },
    });
  }, [prescription.id, trackEvent]);

  return (
    <ActionSheetV2
      title="Request vacation supply"
      description={
        'When traveling, your insurance will typically cover your medication earlier than usual so we can deliver it to you before you leave.\n\nProvide your travel info and we’ll contact your insurance.'
      }
      analyticsName="vacation supply"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <VacationOverrideForm
          buttonLabel="Submit & add to cart"
          prescriptionID={prescription.id}
          onClose={closeActionSheet}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
