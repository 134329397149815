// @owners { team: patients-team }
import { XxsSpacing } from '@alto/design-system';
import { type PaymentBreakdown } from '@alto/scriptdash/alto/pricing/types/v3';
import React from 'react';
import { PAYMENT_BREAKDOWN_LABELS } from '~shared/features/pricing/constants';
import { PricingInfoRow } from '../../pricing-info/components/PricingInfoRow';

type Props = {
  paymentBreakdown: PaymentBreakdown;
};

export const EstimatedSavingsAttributions = ({ paymentBreakdown }: Props) => {
  const estimatedSavings =
    paymentBreakdown.non_insurance_payers_paid +
    paymentBreakdown.remainder_paid +
    paymentBreakdown.pending_progyny_coverage;

  return (
    <>
      {paymentBreakdown.insurance_payers_paid !== 0 && (
        <>
          <XxsSpacing />
          <PricingInfoRow
            savings
            label={PAYMENT_BREAKDOWN_LABELS.PREV_INSURANCE}
            price={paymentBreakdown.insurance_payers_paid}
          />
        </>
      )}
      {estimatedSavings !== 0 && (
        <>
          <XxsSpacing />
          <PricingInfoRow
            savings
            label={PAYMENT_BREAKDOWN_LABELS.PREV_SAVINGS}
            price={estimatedSavings}
          />
        </>
      )}
    </>
  );
};
