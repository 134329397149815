import {
  type LineItem,
  type OrderPricingMetadata,
  type OrderPricingParams,
  type PrescriptionPricing,
} from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { useQuery } from '@tanstack/react-query';
import { staleTimes } from './constants';
import { queries } from './query-keys';

export type OrderPricingQueryData = {
  item_pricing: PrescriptionPricing[];
  line_items: LineItem[];
  metadata: OrderPricingMetadata;
  totalPrice: number | null | undefined;
};

export const useOrderPricing = ({
  shipmentID,
  order,
  bundlingOptionShipmentID,
}: {
  shipmentID?: number;
  order?: OrderPricingParams;
  bundlingOptionShipmentID?: number;
}) => {
  const { data, isError, isPending, isSuccess } = useQuery({
    ...queries.pricing.order({ shipmentID, order, bundlingOptionShipmentID }),
    staleTime: staleTimes.fiveMinutes,
  });
  return { isError, isPending, isSuccess, orderPricing: data };
};
