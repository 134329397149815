import { createSelector } from 'reselect';
import { type ReduxStateShared, type User } from '~shared/types';

// Users
export const getUsers = (state: ReduxStateShared) => state.users.users || [];
export const getUsersByID = createSelector([getUsers], (users) => {
  return users.reduce<Record<number, User>>((userMap, user) => {
    userMap[user.id] = user;
    return userMap;
  }, {});
});
export const getUserIDs = createSelector([getUsers], (users) => users.map((user) => user.id));
export const getCurrentUserID = (state: ReduxStateShared) => state.users.currentUserID;
export const getLastRequestAttemptedAt = (state: ReduxStateShared) => state.users.lastRequestAttemptedAt;
export const getRequestAttemptCount = (state: ReduxStateShared) => state.users.requestAttemptCount;
export const getLastRequestSucceededAt = (state: ReduxStateShared) => state.users.lastRequestSucceededAt;
export const getUpdateUserSuccess = (state: ReduxStateShared) => state.users.updateUserSuccess;
