import { AltoIcon, Toast, ToastContext } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { LoadingButton } from '@alto/pocky';
import * as React from 'react';
import styled from 'styled-components';
import { COMMS_CHANNEL } from '~shared/constants';
import CommsVerificationCard from './CommsVerificationCard';

type Response = {
  success: boolean;
  error?: { message: string };
};

export type Props = {
  readonly commsChannel: string;
  readonly token: string;
  readonly navigateToNextRoute: () => void;
  readonly requestVerification: (token: string, commsChannel: string) => Promise<Response>;
  readonly requestVerificationLoading: boolean;
};

const StyledLoadingButton = styled(LoadingButton)`
  width: 220px;
`;

const CommsVerificationFail = ({
  commsChannel,
  token,
  requestVerification,
  navigateToNextRoute,
  requestVerificationLoading,
}: Props) => {
  const { addToast } = React.useContext(ToastContext);
  const [isVerificationSent, setIsVerificationSent] = React.useState(false);
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
  const formattedChannelText = COMMS_CHANNEL[commsChannel].toLowerCase();

  const handleSendingVerification = () => {
    // eslint-disable-next-line promise/catch-or-return
    requestVerification(token, commsChannel).then((resp: Response) => {
      // eslint-disable-next-line promise/always-return
      if (resp.success) {
        addToast(<Toast>{`Verification link has been sent to your ${formattedChannelText}!`}</Toast>);
        setIsVerificationSent(true);
      } else {
        addToast(<Toast variant="error">{`Error: ${resp.error?.message}`}</Toast>);
        setIsVerificationSent(false);
      }
    });
  };

  return (
    <CommsVerificationCard
      title="Verification failed"
      text={`Your link has expired or an error occurred while attempting to verify your
      ${formattedChannelText}.`}
    >
      {isVerificationSent ? (
        <AltoIcon
          name="checkcircle-small"
          type="secondary"
        />
      ) : (
        <StyledLoadingButton
          loading={requestVerificationLoading}
          onClick={handleSendingVerification}
          loadingText="Sending..."
        >
          Send new verification link
        </StyledLoadingButton>
      )}
      <StyledLoadingButton
        kind="secondary"
        onClick={navigateToNextRoute}
      >
        Continue to Alto
      </StyledLoadingButton>
    </CommsVerificationCard>
  );
};

export default CommsVerificationFail;
