// @owners { team: patients-team }
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { getDeliveriesByPrescriptionID } from '~shared/features/delivery/selectors/getDeliveriesForPrescriptionID';

export type PriceByPrescriptionID = Record<number, number | null | undefined>;

export const getPriceByPrescriptionID = createSelector(
  [getDeliveriesByPrescriptionID],
  (deliveriesByPrescriptionID) => {
    const priceByPrescriptionID: PriceByPrescriptionID = {};

    Object.keys(deliveriesByPrescriptionID).forEach((id) => {
      const prescriptionID = Number(id);

      const deliveries = deliveriesByPrescriptionID[prescriptionID];

      if (deliveries.length === 0) {
        priceByPrescriptionID[prescriptionID] = undefined;
        return;
      }

      const { show_copay, payment_breakdown } = sortBy(deliveries, 'created_at')[0];

      if (show_copay && payment_breakdown) {
        priceByPrescriptionID[prescriptionID] = payment_breakdown.total;
      } else {
        priceByPrescriptionID[prescriptionID] = null;
      }
    });

    return priceByPrescriptionID;
  },
);
