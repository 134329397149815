// @owners { team: patients-team }
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  InputCheckbox,
  InputCheckboxGroup,
  LgPadding,
  ListDescription,
  ListItem,
} from '@alto/design-system';
import { useRemoveFromCart } from '@alto/features';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchWindows } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { cancelDeliveries, fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { type DeliveryWithPrescription } from '~shared/features/delivery-info/types';
import { getNextAvailableDatesByPrescriptionID } from '~shared/features/next-available-date/selectors/getNextAvailableDatesByPrescriptionID';
import getShipmentID from '~shared/features/next-available-date/selectors/getShipmentId';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { formatRelativeDateWithDow } from '~shared/helpers/date';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type NextAvailableDate, type Prescription } from '~shared/types';
import { GetItSoonerActionSheet } from '~web/features/my-meds/components/modals/GetItSoonerActionSheet';

type MultipleRxCheckboxGroupProps = {
  readonly initialState: Record<string, boolean>;
  readonly prescriptions: Prescription[];
  readonly availabilityData: Record<number, NextAvailableDate>;
  readonly setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  readonly onGetItSoonerPress: (prescription: Prescription) => void;
};

const MultipleRxCheckboxGroup = ({
  setFieldValue,
  initialState,
  prescriptions,
  availabilityData,
  onGetItSoonerPress,
}: MultipleRxCheckboxGroupProps) => {
  const handleValueChange = useCallback(
    (state: Record<string, boolean>) => {
      setFieldValue('selected_meds', state);
    },
    [setFieldValue],
  );

  return (
    <InputCheckboxGroup
      onValueChange={handleValueChange}
      initialState={initialState}
    >
      {prescriptions.map((p) => {
        const date = availabilityData[p.id]?.earliest.date;

        return (
          <InputCheckbox
            name={p.id.toString()}
            key={`${p.medication_name}-${p.id}`}
            label={p.medication_name || ''}
            descriptions={[
              <ListDescription
                key="list-description"
                iconName="info-small"
                type="secondary"
                onPress={() => {
                  onGetItSoonerPress(p);
                }}
              >
                {`Available ${formatRelativeDateWithDow(date)}`}
              </ListDescription>,
            ]}
          />
        );
      })}
    </InputCheckboxGroup>
  );
};

type MultipleRxListGroupProps = {
  readonly prescriptions: Prescription[];
  readonly availabilityData: Record<number, NextAvailableDate>;
  readonly onGetItSoonerPress: (prescription: Prescription) => void;
};

const MultipleRxListGroup = ({ prescriptions, availabilityData, onGetItSoonerPress }: MultipleRxListGroupProps) => {
  return (
    <div>
      {prescriptions.map((p) => {
        const date = availabilityData[p.id]?.earliest.date;

        return (
          <ListItem
            key={`${p.medication_name}-${p.id}`}
            title={`${p.medication_name}`}
            descriptions={[
              <ListDescription
                key="available-date"
                iconName="info-small"
                type="secondary"
                // TODO: open NAD action sheet after migrating modals to actionsheets
                onPress={() => {
                  onGetItSoonerPress(p);
                }}
              >
                {`Available ${formatRelativeDateWithDow(date)}`}
              </ListDescription>,
            ]}
          />
        );
      })}
    </div>
  );
};

export const MultipleRxNextAvailableDateActionSheet = () => {
  const dispatch = useDispatchShared();
  const { prescriptions } = usePrescriptionsInCart();
  const availabilityData = useSelectorShared((state) => getNextAvailableDatesByPrescriptionID(state));
  const { earliestAvailableDate } = useCartNextAvailableDate();
  const isEditOrder = useSelectorShared((state) => getIsEditingExistingOrder(state));
  const shipmentID = useSelectorShared((state) => getShipmentID(state));
  const deliveriesForShipment = useSelectorShared((state) => getDeliveriesForShipmentID(state, { shipmentID }));
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const [multipleEAD, setMultipleEAD] = useState(false);
  const { handleRemoveFromCart } = useRemoveFromCart();
  const originName = isEditOrder ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT;

  const description = multipleEAD
    ? 'Your medications have different available dates. To get a medication sooner you can remove any medications with a later available date.'
    : '';

  useEffect(() => {
    const earliestDateSet = new Set();
    prescriptions.forEach((p) => earliestDateSet.add(availabilityData[p.id].earliest.date));
    if (earliestDateSet.size > 1) {
      setMultipleEAD(true);
    }

    dispatch(
      sendAnalyticsEvent(
        createEvent(EVENTS.SHIPMENT_NEXT_AVAILABLE_DATE_ACTION_SHEET_VIEWED, {
          shipment_next_available_date: earliestAvailableDate,
          num_prescriptions: prescriptions.length,
          num_unique_next_available_dates: earliestDateSet.size,
        }),
      ),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rxCheckboxMapping: Record<string, boolean> = useMemo(
    () =>
      prescriptions.reduce<Record<string, boolean>>((mapping, currentRx) => {
        const key = currentRx.id.toString();
        mapping[key] = false;
        return mapping;
      }, {}),
    [prescriptions],
  );

  const handleGetItSoonerPress = useCallback(
    (prescription: Prescription) => {
      setActiveActionSheet(
        <GetItSoonerActionSheet
          prescription={prescription}
          onClose={() => undefined}
          origin={originName}
        />,
      );
    },
    [setActiveActionSheet, originName],
  );

  const fireAnalyticsOnRemoval = (removedPrescriptions: Prescription[], earliestDeliveryDate: string) => {
    // Loop over removed prescriptions to send off analytics with newly determined earliestDeliveryDate
    removedPrescriptions.forEach((removedPrescription) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(
            EVENTS.CART__ITEM_REMOVED,
            {
              new_shipment_next_available_date: earliestDeliveryDate,
              prescription_next_available_date: availabilityData[removedPrescription.id].earliest.date,
              edit_order_flow: isEditOrder,
            },
            {
              prescriptionId: removedPrescription.id,
              shipmentId: shipmentID,
              deliveryId: deliveriesForShipment.find((delivery) => delivery.prescription_id === removedPrescription.id)
                ?.id,
            },
          ),
        ),
      );
    });
  };

  const removePrescriptionsInEditOrderFlow = async (removedPrescriptionIDs: number[]) => {
    // eslint-disable-next-line @typescript-eslint/no-for-in-array
    for (const rxID in removedPrescriptionIDs) {
      const autoRefillFound =
        prescriptions?.some?.((p) => p.id === removedPrescriptionIDs[rxID] && p.auto_refill) ?? false;

      if (autoRefillFound) {
        // TODO: setActiveActionSheet(<DatePicker toast={errorToast} />)
        closeActionSheet();
        return;
      }
    }

    const removedDeliveries: DeliveryWithPrescription[] = [];
    let earliestDeliveryDate = '';
    const removedPrescriptions = prescriptions.reduce<Prescription[]>((removed, prescription) => {
      if (removedPrescriptionIDs.includes(prescription.id)) {
        const delivery = deliveriesForShipment.find((delivery) => delivery.prescription_id === prescription.id);
        if (delivery) {
          removedDeliveries.push(delivery);
          removed.push(prescription);
        }
      } else {
        const date = availabilityData[prescription.id]?.earliest.date;
        if (date && date > earliestDeliveryDate) {
          earliestDeliveryDate = date;
        }
      }
      return removed;
    }, []);

    // eslint-disable-next-line import/no-deprecated
    await dispatch(cancelDeliveries(removedDeliveries, 'other', '', false));
    fireAnalyticsOnRemoval(removedPrescriptions, earliestDeliveryDate);
  };

  const removePrescriptionsInCheckoutFlow = (removedPrescriptionIDs: number[]) => {
    let earliestDeliveryDate = '';
    const removedPrescriptions = prescriptions.reduce<Prescription[]>((removed, prescription) => {
      if (removedPrescriptionIDs.includes(prescription.id)) {
        // Remove prescription from cart if selected for removal
        removed.push(prescription);
        handleRemoveFromCart({ item: { ...prescription, ...prescriptionToItemKey(prescription.id) } });
      } else {
        // If prescription is not to be removed, save its EAD if it's later than current earliestDeliveryDate
        const date = availabilityData[prescription.id]?.earliest.date;
        if (date && date > earliestDeliveryDate) {
          earliestDeliveryDate = date;
        }
      }

      return removed;
    }, []);
    fireAnalyticsOnRemoval(removedPrescriptions, earliestDeliveryDate);
  };

  const onSubmit = async ({ selected_meds }: { selected_meds: Record<number, boolean> }) => {
    const selectedPrescriptionIDs: number[] = [];
    const savedPrescriptionIDs: number[] = [];
    for (const rxID in selected_meds) {
      if (selected_meds[rxID]) {
        selectedPrescriptionIDs.push(parseInt(rxID, 10));
      } else {
        const prescriptionID = parseInt(rxID, 10);
        const savedPrescription = prescriptions.find((p) => p.id === prescriptionID);
        if (savedPrescription) {
          savedPrescriptionIDs.push(prescriptionID);
        }
      }
    }

    if (isEditOrder) {
      await removePrescriptionsInEditOrderFlow(selectedPrescriptionIDs);
    } else {
      removePrescriptionsInCheckoutFlow(selectedPrescriptionIDs);
    }

    // Refetch checkout data and go back to date picker action sheet
    await Promise.all([
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchNextAvailableDates({ unbundledPrescriptionIDs: savedPrescriptionIDs })),
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      dispatch(fetchWindows()),
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchDeliveries()),
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipments()),
    ]);

    // TODO: Navigate back to the delivery date selector after awaiting fetch and show success toast
    // setActiveActionSheet(<DatePicker toast={successToast} />);
    closeActionSheet();
  };

  return (
    <ActionSheetV2
      title="Multiple medications"
      description={description}
      handleClose={closeActionSheet}
      analyticsName="get it sooner multiple rx"
    >
      <Formik
        initialValues={{ selected_meds: rxCheckboxMapping }}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const selectedMedMapping = Object.values(values.selected_meds);
          const allSelected = selectedMedMapping.every((e) => e);
          const noneSelected = selectedMedMapping.every((e) => !e);
          return (
            <div>
              {multipleEAD ? (
                <>
                  <MultipleRxCheckboxGroup
                    setFieldValue={setFieldValue}
                    initialState={rxCheckboxMapping}
                    prescriptions={prescriptions}
                    availabilityData={availabilityData}
                    onGetItSoonerPress={handleGetItSoonerPress}
                  />
                  <LgPadding>
                    <Button
                      width="full"
                      onPress={handleSubmit}
                      disabled={allSelected || noneSelected}
                      label="Remove from cart"
                    />
                  </LgPadding>
                </>
              ) : (
                <MultipleRxListGroup
                  prescriptions={prescriptions}
                  availabilityData={availabilityData}
                  onGetItSoonerPress={handleGetItSoonerPress}
                />
              )}
            </div>
          );
        }}
      </Formik>
    </ActionSheetV2>
  );
};
