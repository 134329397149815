// @owners { team: patients-team }
import { ActionSheetContext } from '@alto/design-system';
import { CSATActionSheet, useCanShowCSAT } from '@alto/features';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { getAsapShipmentFee } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { useSelectorShared } from '~shared/store';
import { LoadingPage } from '~web/features/essentials/components';
import { OrderSuccessWithProducts } from '~web/features/essentials/pages/OrderSuccess/OrderSuccessWithProducts';
import { OrderSuccessWithoutProducts } from '~web/features/essentials/pages/OrderSuccess/OrderSuccessWithoutProducts';

type Props = {
  readonly params: {
    shipmentID: string;
  };
};

export const OrderSuccess = ({ params: { shipmentID } }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { canShowCSAT, updateCSATViewedDate } = useCanShowCSAT();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: Number(shipmentID) }));
  const isAsapDelivery = !shipment ? false : !!getAsapShipmentFee(shipment);

  const timer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  const showCSAT = useCallback(() => {
    if (canShowCSAT) {
      updateCSATViewedDate();
      if (!timer.current) {
        timer.current = setTimeout(() => {
          setActiveActionSheet(<CSATActionSheet />);
        }, 2000);
      }
    }
  }, [canShowCSAT, setActiveActionSheet, updateCSATViewedDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    showCSAT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!shipment) return <LoadingPage />;

  return isAsapDelivery ? (
    <OrderSuccessWithoutProducts shipment={shipment} />
  ) : (
    <OrderSuccessWithProducts shipment={shipment} />
  );
};
