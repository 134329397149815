import {
  type AltoIconName,
  type BaseIconName,
  COLORS,
  type ColorVariant,
  SIZES,
  type SmallIconName,
  type TEXT_COLORS_VALUES,
} from '@alto/design-library-tokens';
import { PixelRatio } from 'react-native';

export type IconSize = 'small' | 'regular';

export const smallifyIconName = (iconName: AltoIconName): SmallIconName => {
  // have to coerce this type, but this is correct
  const baseName = iconName.replace('-duo', '').replace('-small', '') as BaseIconName;
  return `${baseName}-small`;
};
export const calculateIconPxSize = (size: IconSize, allowFontScaling: boolean) => {
  const fontScale = allowFontScaling ? PixelRatio.getFontScale() : 1;

  if (size === 'small') {
    return SIZES.ICON.MD.value * fontScale;
  }

  return SIZES.ICON.LG.value * fontScale;
};
export const getIconTypeFromColor = (color: TEXT_COLORS_VALUES): ColorVariant | undefined => {
  switch (color) {
    case COLORS.TEXT_COLORS.WHITE:
      return 'white';
    case COLORS.TEXT_COLORS.DISABLED:
      return 'disabled';
    case COLORS.TEXT_COLORS.GREY:
      return 'grey';
    case COLORS.TEXT_COLORS.SECONDARY:
    case COLORS.TEXT_COLORS.SECONDARY_DARK:
      return 'secondary';
    case COLORS.TEXT_COLORS.SUCCESS:
      return 'success';
    case COLORS.TEXT_COLORS.DANGER:
      return 'danger';
    case COLORS.TEXT_COLORS.PRIMARY:
    default:
      return undefined;
  }
};
