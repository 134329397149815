// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantMessageSubject,
  type AssistantPaymentsAction,
  MESSAGE_PROMPTS,
  PRICING_PAYMENTS_CATEGORY,
} from '~shared/features/alto-assistant/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { type FAQKey } from '../faq/Constants';
import { MenuOption } from './MenuOption';

export const PricingPaymentsActionSheet = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { restoreStack, saveStack, closeActionSheet } = useContext(ActionSheetContext);
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const trackActionChosen = (action: AssistantPaymentsAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        category: ASSISTANT_CATEGORIES.PRICING_PAYMENTS,
        action,
      },
    });
  };

  const navigateToMessageForm = (action: AssistantPaymentsAction, subject: AssistantMessageSubject) => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      messagePrompt: MESSAGE_PROMPTS.HELP_WITH_PRICING_PAYMENTS,
      origin: ASSISTANT_CHAT_ORIGINS.PRICING_PAYMENTS,
      category: `${PRICING_PAYMENTS_CATEGORY}.${action}`,
      subject,
    });
  };

  const navigateToPayments = () => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RoutePayments');
  };

  const navigateToFAQ = (faqKey: FAQKey) => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteFaq', {
      faqKey,
      goBack: () => {
        restoreStack<ActionSheetProviderStackCacheKey>('assistant');
        navigateToAssistantLanding();
      },
      onDismiss: () => {
        navigateToAssistantLanding();
      },
      buttonLabelDone: 'Got it',
    });
  };

  const items = [
    {
      key: 'price-quote',
      component: (
        <MenuOption
          iconName="pricetag-duo"
          onPress={() => {
            trackActionChosen('get_a_price_quote');
            navigateToFAQ('price_quotes');
          }}
          text="Get a price quote"
        />
      ),
    },
    {
      key: 'price-investigation',
      component: (
        <MenuOption
          iconName="alert-duo"
          onPress={() => {
            trackActionChosen('investigation_request');
            navigateToFAQ('price_investigation');
          }}
          text="My price isn't what I expected"
        />
      ),
    },
    {
      key: 'price-unavailable-or-pending',
      component: (
        <MenuOption
          iconName="hourglass-duo"
          onPress={() => {
            trackActionChosen('price_unavailable_or_pending');
            navigateToFAQ('price_unavailable_or_pending');
          }}
          text="My price is unavailable or pending"
        />
      ),
    },
    {
      key: 'fsa-hsa-funds',
      component: (
        <MenuOption
          iconName="signpaper-duo"
          onPress={() => {
            trackActionChosen('fsa_hsa_funds');
            navigateToFAQ('price_fsa_hsa_funds');
          }}
          text="FSA/HSA Funds"
        />
      ),
    },
    {
      key: 'download-receipts',
      component: (
        <MenuOption
          iconName="receipt-duo"
          onPress={() => {
            trackActionChosen('download_receipts');
            navigateToPayments();
          }}
          text="Download receipts"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            trackActionChosen('something_else');
            navigateToMessageForm('something_else', ASSISTANT_MESSAGE_SUBJECTS.PRICING_PAYMENT);
          }}
          text="Something else"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="Pricing & payments"
      analyticsName="assistant pricing payments"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
