import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import { MdSpacing } from '../../separators';
import { H2 } from '../../typography';

export type InputGroupProps = {
  readonly label: string;
  readonly required?: boolean;
  readonly children: React.ReactNode;
};

export const InputGroup = ({ required, label, children }: InputGroupProps) => {
  return (
    <View>
      <H2
        color={COLORS.TEXT_COLORS.PRIMARY}
        accessibilityLabel={`${label}${required ? ', required' : ''}`}
      >
        {label}
        {required ? <H2 color={COLORS.TEXT_COLORS.DANGER}>*</H2> : null}
      </H2>
      <MdSpacing />
      {children}
    </View>
  );
};
