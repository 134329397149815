// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import { type Actions, INCREMENT_TRANSFER_UPSELL_VIEW_COUNT } from '~shared/actions/transfers';
import { type TransferCreatePayload } from '~shared/features/transfers/types';

export type TransfersState = {
  transferRequests: TransferCreatePayload[];
  transferUpsellViewCount: number;
};

const initialState: TransfersState = {
  transferRequests: [],
  transferUpsellViewCount: 0,
};

const transfers = (state = initialState, action: Actions): TransfersState => {
  switch (action.type) {
    case INCREMENT_TRANSFER_UPSELL_VIEW_COUNT:
      return { ...state, transferUpsellViewCount: state.transferUpsellViewCount + 1 };

    default:
      return state;
  }
};

export default withRehydration(transfers, initialState);
