// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, LgPadding, Link, SmSpacing } from '@alto/design-system';
import { type Navigate } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantPressedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import { useDispatchShared } from '~shared/store';

type ReceiptsActionSheetProps = {
  readonly navigate?: Navigate;
  readonly toDeliveries?: () => void;
  readonly toPayments?: () => void;
};

export const ReceiptsActionSheet = ({ navigate, toDeliveries, toPayments }: ReceiptsActionSheetProps) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();

  const onPressPayments = () => {
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.RECEIPTS_FAQ_PAYMENTS_LINK));
    closeActionSheet();
    if (navigate) {
      navigate('RoutePayments');
    } else {
      toPayments?.();
    }
  };

  const onPressDeliveries = () => {
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.RECEIPTS_FAQ_DELIVERIES_LINK));
    closeActionSheet();
    if (navigate) {
      navigate('RouteAppTabNavigator', { screen: 'RouteTabOrders' });
    } else {
      toDeliveries?.();
    }
  };

  return (
    <ActionSheetV2
      title="Receipts"
      analyticsName="assistant receipts"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          You can view and download your annual receipts for FSA/HSA claims or taxes from{' '}
          <Link onPress={onPressPayments}>Payments</Link>, found within the Account tab.
        </Body>
        <SmSpacing />
        <Body>
          View and download individual order receipts by clicking "View delivery" on the{' '}
          <Link onPress={onPressDeliveries}>Deliveries tab</Link>.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
