// @owners { team: patients-team }
import { type Video } from './types';

export const BE_SURE_TAGLINE = 'Be sure to have the following items before you begin:';

export const WASH_HANDS_TAGLINE =
  'Wash your hands with soap and water and dry thoroughly. Make sure you are working on a clean, flat surface.';

export const CETROTIDE_DISCLAIMER =
  'The plastic backstop should not be removed—it is in place to ensure the syringe cannot be removed completely and the sterility of the medication is not compromised.';

export const CLINIC_DISCLAIMER = [
  'The specific materials you have for your injections may look different than what is displayed in the video. If your provider has instructed you to use a specific type of syringe or needle, always follow their instructions.',
  'This content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or another qualified health provider with any questions you may have regarding a medical condition.',
];

export const GONAL = Object.freeze({
  videoID: 'CsL3wWAT-UE',
  title: 'How to inject Gonal-f\u00AE RFF Redi-ject\u00AE',
  medName: 'Gonal',
  prepItems: ['1 Gonal-f\u00AE Pen', '1 new, single-use pen needle', 'Alcohol swabs', 'Sharps container'],
});

export const CETROTIDE = Object.freeze({
  videoID: '4Psvwqxt8-0',
  title: 'How to inject Cetrotide\u00AE',
  medName: 'Cetrotide',
  prepItems: [
    '1 vial of Cetrotide\u00AE powder',
    '1 prefilled syringe with diluent',
    '20G 1.5" mixing needle',
    '27G ¾" injection needle',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const GANIRELIX = Object.freeze({
  videoID: '8q8k9X74vA8',
  title: 'How to inject Ganirelix\u00AE',
  medName: 'Ganirelix',
  prepItems: ['1 prefilled, premixed syringe with injection needle attached', 'Alcohol swabs', 'Sharps container'],
});

export const FYREMADEL = Object.freeze({
  videoID: '8q8k9X74vA8',
  title: 'How to inject Fyremadel\u00AE',
  medName: 'Fyremadel',
  prepItems: ['1 prefilled, premixed syringe with injection needle attached', 'Alcohol swabs', 'Sharps container'],
});

export const LEUROPLIDE_ACETATE = Object.freeze({
  videoID: 'rKUV1WbUQwE',
  title: 'How to inject Leuroplide Acetate 14 day kit',
  medName: 'Leuroplide Acetate',
  prepItems: [
    '1 vial of 2.8 mL multi-dose Leuprolide',
    'Insulin syringe that is either 1 mL or 0.5 mL depending on your dose',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const LEUROPLIDE_TRIGGER = Object.freeze({
  videoID: 'VfeN_1tzyW4',
  title: 'How to inject Leuroplide Trigger',
  medName: 'Leuroplide Trigger',
  prepItems: [
    '1 vial of single-dose Leuprolide',
    '1 mL insulin syringe for injection',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const MENOPUR_INT = Object.freeze({
  videoID: 'DVYL5N09fJo',
  title: 'How to inject Menopur\u00AE with mixing needle intramuscularly',
  medName: 'Menopur',
  prepItems: [
    'At least 1 vial of Menopur\u00AE 75 IU powder depending on your dose',
    '1 vial of diluent',
    '22G 1.5" mixing needle',
    '25G 1.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const MENOPUR_SUB = Object.freeze({
  videoID: 'U1a_HU4qL6w',
  title: 'How to inject Menopur\u00AE with mixing needle subcutaneously',
  medName: 'Menopur',
  prepItems: [
    'At least 1 vial of Menopur\u00AE 75 IU powder depending on your dose',
    '1 vial of diluent',
    '22G 1.5" mixing needle',
    '27G 0.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const MENOPUR_QCAP_INT = Object.freeze({
  videoID: '0XUmuGIqRzk',
  title: 'How to inject Menopur\u00AE with Q-Cap intramuscularly',
  medName: 'Menopur',
  prepItems: [
    'At least 1 vial of Menopur\u00AE 75 IU powder depending on your dose',
    '1 vial of diluent',
    'Q-CAP',
    '25G 1.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const MENOPUR_QCAP_SUB = Object.freeze({
  videoID: 'UkqAPiy5LeI',
  title: 'How to inject Menopur\u00AE with Q-Cap subcutaneously',
  medName: 'Menopur',
  prepItems: [
    'At least 1 vial of Menopur\u00AE 75 IU powder depending on your dose',
    '1 vial of diluent',
    'Q-CAP',
    '27G 0.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const NOVAREL_INT = Object.freeze({
  videoID: '5AnM56vn47M',
  title: 'How to inject Novarel\u00AE intramuscularly',
  medName: 'Novarel',
  prepItems: [
    'At least 1 vial of Novarel\u00AE powder depending on your dose',
    '1 vial of diluent',
    '22G 1.5" mixing needle',
    '25G 1.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const NOVAREL_SUB = Object.freeze({
  videoID: 'rKJVidCwf6E',
  title: 'How to inject Novarel\u00AE subcutaneously',
  medName: 'Novarel',
  prepItems: [
    'At least 1 vial of Novarel\u00AE powder depending on your dose',
    '1 vial of diluent',
    '22G 1.5" mixing needle',
    '27G 0.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const OMNITROPE = Object.freeze({
  videoID: 'ltU0VQVAdiI',
  title: 'How to inject Omnitrope\u00AE',
  medName: 'Omnitrope',
  prepItems: [
    '1 vial of Omnitrope\u00AE 5.8mg powder',
    '1 vial of diluent',
    '25G 0.625" mixing needle',
    '3 mL syringe',
    '28G insulin syringe for injection',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const OVIDREL = Object.freeze({
  videoID: 'NxM5aAFls5Q',
  title: 'How to inject Ovidrel\u00AE',
  medName: 'Ovidrel',
  prepItems: ['1 prefilled, premixed syringe with injection needle attached', 'Alcohol swabs', 'Sharps container'],
});

export const PREGNYL_INT = Object.freeze({
  videoID: 'gGi9ty7sHEI',
  title: 'How to inject Pregnyl\u00AE intramuscularly',
  medName: 'Pregnyl',
  prepItems: [
    '1 vial of Pregnyl\u00AE powder',
    '1 vial of diluent',
    '22G 1.5" mixing needle',
    '25G 1.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

export const PREGNYL_SUB = Object.freeze({
  videoID: 'u-ncMNgY_5o',
  title: 'How to inject Pregnyl\u00AE subcutaneously',
  medName: 'Pregnyl',
  prepItems: [
    '1 vial of Pregnyl\u00AE powder',
    '1 vial of diluent',
    '22G 1.5" mixing needle',
    '27G 0.5" injection needle',
    '3 mL syringe',
    'Alcohol swabs',
    'Sharps container',
  ],
});

// @ts-expect-error TS(4104): The type 'readonly Readonly<{ videoID: string; title: string; medName: string; prepItems: string[]; ... (Delete me to see the full error)
export const allVideos: Video[] = Object.freeze([
  GONAL,
  CETROTIDE,
  GANIRELIX,
  FYREMADEL,
  LEUROPLIDE_ACETATE,
  LEUROPLIDE_TRIGGER,
  MENOPUR_QCAP_SUB,
  MENOPUR_SUB,
  MENOPUR_QCAP_INT,
  MENOPUR_INT,
  NOVAREL_INT,
  NOVAREL_SUB,
  OMNITROPE,
  OVIDREL,
  PREGNYL_INT,
  PREGNYL_SUB,
]);

export function getVideosFromNdc(ndc: string) {
  switch (ndc) {
    case '44087122501':
      return [CETROTIDE];

    case '55566100001':
    case '00052030151':
    case '00548500100':
    case '78206013801':
    case '71288055480':
      return [GANIRELIX];

    case '55566101001':
      return [FYREMADEL];

    case '44087111501':
    case '44087111601':
    case '44087111701':
      return [GONAL];

    case '00781400332':
    case '47335093640':
      return [LEUROPLIDE_ACETATE];

    case 'C0000002008':
    case 'C0000002108':
    case 'C1234567839':
    case 'C1234567838':
      return [LEUROPLIDE_TRIGGER];

    case '55566750102':
      return [MENOPUR_QCAP_SUB, MENOPUR_SUB, MENOPUR_QCAP_INT, MENOPUR_INT];

    case '55566150201':
      return [NOVAREL_INT, NOVAREL_SUB];

    case '00781400436':
      return [OMNITROPE];

    case '44087115001':
      return [OVIDREL];

    case '00052031510':
      return [PREGNYL_INT, PREGNYL_SUB];

    default:
      return [];
  }
}
