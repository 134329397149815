// @owners { team: patients-team }
export const VIEW_STORE = 'VIEW_STORE';
export const VIEW_STORE_UPSELL = 'VIEW_STORE_UPSELL';
export const SET_ESSENTIALS_ACTIVE_CATEGORY = 'SET_ESSENTIALS_ACTIVE_CATEGORY';
export const SET_ESSENTIALS_SEARCH_TERM = 'SET_ESSENTIALS_SEARCH_TERM';

export type ViewStoreUpSell = { type: typeof VIEW_STORE_UPSELL };
export const viewStoreUpsell = (): ViewStoreUpSell => ({ type: VIEW_STORE_UPSELL });

export type ViewStore = { type: typeof VIEW_STORE };
export const viewStore = (): ViewStore => ({ type: VIEW_STORE });

export type SetActiveEssentialCategory = { type: typeof SET_ESSENTIALS_ACTIVE_CATEGORY; payload: number };
export const setActiveEssentialCategory = (payload: number): SetActiveEssentialCategory => ({
  type: SET_ESSENTIALS_ACTIVE_CATEGORY,
  payload,
});

export type SetEssentialsSearchTerm = { type: typeof SET_ESSENTIALS_SEARCH_TERM; payload: string };
export const setEssentialsSearchTerm = (payload: string): SetEssentialsSearchTerm => ({
  type: SET_ESSENTIALS_SEARCH_TERM,
  payload,
});
