// @owners { team: patients-team }
import { Column } from '@alto/design-system';
import React from 'react';
import { Icon } from '~web/features/essentials/primitives';

export const LoadingPage = () => {
  return (
    <Column
      flexGrow={1}
      center
    >
      <Icon
        name="loader"
        spinning
        interactive={false}
        label="loading"
        size="LG"
      />
    </Column>
  );
};
