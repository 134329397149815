// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getNextAvailableDateAddressID } from './getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from './getNextAvailableDateFacilityId';
import { getScheduleablePrescriptions } from '~shared/features/my-meds/selectors/getScheduleablePrescriptions';
import { getNextAvailableDateID } from '~shared/features/next-available-date/helpers/getNextAvailableDateID';
import { type Prescription } from '~shared/types/clients';

export type ScheduleablePrescriptionsByNextAvailableDateID = Record<
  number,
  {
    nextAvailableDateID: string;
    prescription: Prescription;
  }
>;

/**
 * Returns a map of scheduleable prescription id to NextAvailableDate.id.
 */
const getScheduleablePrescriptionsByNextAvailableDateID = createSelector(
  [getNextAvailableDateAddressID, getNextAvailableDateFacilityID, getScheduleablePrescriptions],
  (addressID, facilityID, prescriptions) => {
    if (!(addressID || facilityID)) {
      return {};
    }

    return prescriptions.reduce<ScheduleablePrescriptionsByNextAvailableDateID>((result, prescription) => {
      result[prescription.id] = {
        nextAvailableDateID: getNextAvailableDateID({
          addressID,
          facilityID,
          prescriptionIDs: [prescription.id],
        }),
        prescription,
      };

      return result;
    }, {});
  },
);

export default getScheduleablePrescriptionsByNextAvailableDateID;
