import { type PricingResultPaymentType } from '~shared/types';

export const SELECT_PRICE_OPTION = 'SELECT_PRICE_OPTION';
export type ActionSelectPriceOption = {
  type: typeof SELECT_PRICE_OPTION;
  payload: {
    prescriptionID: number;
    paymentType: PricingResultPaymentType;
  };
};
export function selectPriceOption(
  prescriptionID: number,
  paymentType: PricingResultPaymentType,
): ActionSelectPriceOption {
  return {
    type: SELECT_PRICE_OPTION,
    payload: {
      prescriptionID,
      paymentType,
    },
  };
}

export const CLEAR_SELECTED_PAYMENT_TYPES = 'CLEAR_SELECTED_PAYMENT_TYPES';
export type ActionClearSelectedPaymentTypes = {
  type: typeof CLEAR_SELECTED_PAYMENT_TYPES;
};
export function clearSelectedPaymentTypes(): ActionClearSelectedPaymentTypes {
  return {
    type: CLEAR_SELECTED_PAYMENT_TYPES,
  };
}
