// @owners { team: patients-team }
import { ActionSheetV2, LgPadding } from '@alto/design-system';
import React, { useEffect } from 'react';
import touchID from '~shared/assets/images/touch_id.png';
import { areCookiesDisabled } from '~shared/helpers/getDeviceID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { Verify, type VerifyProps } from './Verify';
import CookieError from '~web/features/auth/components/CookieError';

export type Props = VerifyProps & {
  readonly showProgyny: boolean;
};

export const VerifyActionSheet = ({ onVerify, showProgyny, tempPassword }: Props) => {
  const { trackPageView } = useAnalytics();
  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__VERIFY_ACCOUNT_VIEWED,
      params: {
        is_progyny_rx_view: showProgyny,
      },
    });
  }, [showProgyny, trackPageView]);

  const cookiesDisabled = areCookiesDisabled();

  return (
    <ActionSheetV2
      title="Let’s verify your identity"
      illustrationSrc={touchID}
      analyticsName="onboarding verify identity"
    >
      <LgPadding>
        {cookiesDisabled ? <CookieError /> : null}
        <Verify
          onVerify={onVerify}
          disabled={cookiesDisabled}
          tempPassword={tempPassword}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
