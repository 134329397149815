const POSITIVE_CURRENCY_REGEX = /[^0-9.]+/g;
const LEADING_ZEROS_REGEX = /^0+(?!\.)/;

export function formatDollars(value: number, includeCurrencySymbol: boolean | null | undefined = true): string {
  // FIXME(tom): Apparently Number.toLocaleString() and Intl.NumberFormat()
  // don't work on Android because of its ancient JavaScriptCore.
  // (see https://github.com/facebook/react-native/issues/19410)
  // There's a polyfill for Intl but we'll use this lovely regex for now.
  const currencyString = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return includeCurrencySymbol ? `$${currencyString}` : currencyString;
}

export function formatDollarsWithPlaceholder(value: number | null | undefined, placeholder = '-'): string {
  if (!value && value !== 0) return placeholder;
  return formatDollars(value);
}

export function parseDollarsToNumber(dollars: string | null | undefined): number | null | undefined {
  if (!dollars) return undefined;

  // Regexp source: https://stackoverflow.com/questions/559112/how-to-convert-a-currency-string-to-a-double-with-jquery-or-javascript
  return Number(dollars.replace(/[^0-9.-]+/g, ''));
}

// NOTE: Intl.NumberFormat and  Number.toLocaleString don't work on Android Q
// or older because of its ancient JavaScriptCore. We're using regex here until
// we can use jsc-android as a dependency.
export function formatInputAsCurrency(input: string | null | undefined): string {
  if (!input) return '';
  if (input === '0') return input;

  const validCurrencyString = input.replace(POSITIVE_CURRENCY_REGEX, '');
  const currencyStringParts = validCurrencyString.split('.');
  const validCurrencyParts = currencyStringParts.slice(0, 2);
  const dollars = validCurrencyParts[0];
  const cents = validCurrencyParts[1];

  let formattedString = '';

  if (dollars) {
    formattedString = dollars;
  }

  if (cents || input.endsWith('.')) {
    formattedString = `${formattedString}.${cents.slice(0, 2)}`;
  }

  if (formattedString.startsWith('0')) {
    if (formattedString === '00') return '0';
    formattedString = formattedString.replace(LEADING_ZEROS_REGEX, '');
  }

  if (formattedString === '.') return '0.';

  return formattedString;
}

//rounds a number to two decimals max. returns NaN if not a number.
export function roundToTwo(num: number) {
  return Math.round(num * 100) / 100;
}
