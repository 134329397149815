// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, H3, LgPadding, SmSpacing, XxsSpacing } from '@alto/design-system';
import React from 'react';
import {
  BE_SURE_TAGLINE,
  CLINIC_DISCLAIMER,
  WASH_HANDS_TAGLINE,
} from '~shared/features/instructional-videos/constants';

type PreparationInfoProps = {
  readonly title: string;
  readonly prepItems: string[];
};

export const PreparationInfoActionSheet = ({ title, prepItems }: PreparationInfoProps) => {
  return (
    <ActionSheetV2 title="Injectable Preparation Info">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <H3>{title}</H3>
        <SmSpacing />
        <Body>{BE_SURE_TAGLINE}</Body>
        <SmSpacing />
        {prepItems?.map((prepItem, index) => (
          <React.Fragment key={prepItem}>
            <Body>• {prepItem}</Body>
            {prepItems.length > 1 && index < prepItems.length - 1 && <XxsSpacing />}
          </React.Fragment>
        ))}
        <SmSpacing />
        <Body>{WASH_HANDS_TAGLINE}</Body>
        <SmSpacing />
        <Body fontFamily="bold">Note:</Body>
        {CLINIC_DISCLAIMER.map((disclaimer) => (
          <React.Fragment key={disclaimer}>
            <XxsSpacing />
            <Body>{disclaimer}</Body>
          </React.Fragment>
        ))}
      </LgPadding>
    </ActionSheetV2>
  );
};
