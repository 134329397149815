// @owners { team: patients-team }
import { SIZES } from '@alto/design-library-tokens';
import { LgSpacing, ListItem } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { type AddOnOtcCategory } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc_category';
import React from 'react';
import { push } from 'react-router-redux';
import { useDispatchShared } from '~shared/store';
import { CategorySearch } from './types';
import {
  FullPage,
  GridProductList,
  LoadingPage,
  NoSearchResults,
  ProductItem,
  ProductScrollView,
} from '~web/features/essentials/components';
import { Icon, Image, View } from '~web/features/essentials/primitives';

type MobileProductListProps = {
  readonly categories: AddOnOtcCategory[];
  readonly activeCategory: CategorySearch;
  readonly activeProducts: AddOnOtc[];
  readonly isSearching: boolean;
  readonly searchQuery: string;
  readonly origin: string;
  readonly shipmentID?: number;
  readonly facilityId?: number;
};

export const MobileProductList = ({
  categories,
  activeProducts,
  activeCategory,
  isSearching,
  searchQuery,
  origin,
  shipmentID,
  facilityId,
}: MobileProductListProps) => {
  const dispatch = useDispatchShared();

  const navigateToCategory = (category: AddOnOtcCategory) => () => {
    dispatch(push(`/store/category/${category.name}?order=${shipmentID}&origin=${origin}`));
  };

  if (activeCategory === CategorySearch.Search && activeProducts.length === 0) {
    return isSearching ? (
      <FullPage>
        <LoadingPage />
      </FullPage>
    ) : (
      <NoSearchResults
        queryString={searchQuery}
        origin={origin}
        facilityId={facilityId}
        shipmentId={shipmentID}
      />
    );
  }

  if (activeCategory === CategorySearch.Search) {
    return (
      <GridProductList>
        {activeProducts.map((product, index) => (
          <ProductItem
            key={product.sku}
            categoryName="search"
            count={1}
            product={product}
            index={index}
            searchQuery={searchQuery}
            isStoreItem
          />
        ))}
      </GridProductList>
    );
  }

  return (
    <>
      {categories.map((category) => (
        <View
          flexDirection="column"
          key={`product-list-for-${category.name}`}
        >
          <ListItem
            onPress={navigateToCategory(category)}
            RightContent={
              <Icon
                interactive
                label={`navigate to ${category.name}`}
                size="MD"
                name="chevronright"
              />
            }
            title={category.name}
            LeftContent={
              <Image
                src={category.image_url}
                width={SIZES.ILLUSTRATION.MD.px}
                height={SIZES.ILLUSTRATION.MD.px}
                alt={`icon for ${category.name}`}
              />
            }
          />
          <ProductScrollView
            count={2.5}
            products={category.add_on_otcs}
            categoryName={category.name}
            searchQuery={searchQuery}
            isStoreItems
          />
          <LgSpacing />
        </View>
      ))}
    </>
  );
};
