// @owners { team: patients-team }
import { BREAKPOINTS, COLORS, SIZES } from '@alto/design-library-tokens';
import { AltoIcon, Avatar, Row as DSRow, Toast, ToastContext } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Column,
  Loader,
  Row,
  SimpleContainer,
  SimpleContainerHeader,
  SimpleContainerSection,
  StaticTextBlock,
  spacing,
} from '@alto/pocky';
import React, { useContext, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { type ModalType, openModal } from '~shared/actions/modal';
import { selectUser } from '~shared/actions/ui/users';
// eslint-disable-next-line import/no-deprecated
import { uploadImageToS3 } from '~shared/actions/upload';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers, updateUser } from '~shared/actions/users';
import { PRONOUNS } from '~shared/constants';
import getUser from '~shared/features/users/selectors/getUser';
import { normalizeDateString } from '~shared/helpers/date';
import { getDisplayFullName } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type User } from '~shared/types';
import MedicalInfoModal from './MedicalInfoModalContainer';
import { PersonalInfoModal } from './PersonalInfoModal';
import { ProfileInsurances } from './ProfileInsurances';
import Breadcrumbs from '~web/components/Breadcrumbs';
import ClickableAltoIcon from '~web/components/ClickableAltoIcon';
import { PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';

type Props = {
  readonly user: User;
  readonly isPatientsOtherScriptsOn: boolean | null | undefined;
};

const PatientPhoto = styled.div<{ imgUrl?: string }>`
  ${({ imgUrl }) =>
    imgUrl &&
    `
    background-image: url(${imgUrl});
    background-size: 150% auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  `}
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

const UploadUserPhoto = ({ user }: Pick<Props, 'user'>) => {
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();

  const handleUpdateAvatar = async (image: File) => {
    try {
      // eslint-disable-next-line import/no-deprecated
      const url = await dispatch(uploadImageToS3(image, 'users'));
      if (url && user) {
        dispatch(
          // eslint-disable-next-line import/no-deprecated
          updateUser({
            id: user.id,
            avatar_url: url,
          }),
        );
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchUsers());
      }
    } catch {
      addToast(<Toast variant="error">Something went wrong. Try uploading another photo.</Toast>);
    }
  };

  return (
    <Row horizontallyAlignContent>
      <Dropzone
        multiple={false}
        onDrop={(filesToUpload) => {
          handleUpdateAvatar(filesToUpload[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          // @ts-expect-error Types of property backfaceVisibility are incompatible.
          <DSRow
            {...getRootProps()}
            bottom
          >
            <input {...getInputProps()} />
            {user.avatar_url ? (
              <PatientPhoto
                imgUrl={user.avatar_url}
                aria-label="Your uploaded photo"
              />
            ) : (
              <Avatar
                name={user.first_name ?? 'Patient'}
                initials={`${user.first_name?.charAt(0)?.toUpperCase()}${user.last_name?.charAt(0)?.toUpperCase()}`}
                size={SIZES.AVATAR.LG.value}
                backgroundColor={COLORS.BACKGROUND_COLORS.GREY}
              />
            )}
            <ClickableAltoIcon
              onClick={() => {
                /* do nothing; dropzone handles click */
              }}
              AltoIcon={
                <AltoIcon
                  name="edit"
                  type="secondary"
                />
              }
            />
          </DSRow>
        )}
      </Dropzone>
    </Row>
  );
};

const PersonalInfo = ({ user }: Pick<Props, 'user'>) => {
  return (
    <>
      <SimpleContainerSection>
        <StaticTextBlock
          label="First & last name"
          input={{
            value: (user.first_name && user.last_name && `${user.first_name} ${user.last_name}`) || 'Full name',
          }}
        />
      </SimpleContainerSection>
      {user.preferred_first_name ? (
        <SimpleContainerSection>
          <StaticTextBlock
            label="Preferred name"
            input={{
              value: user.preferred_first_name || 'Unspecified',
            }}
          />
        </SimpleContainerSection>
      ) : null}
      <SimpleContainerSection>
        <StaticTextBlock
          label="Date of birth"
          input={{
            value: normalizeDateString(user.date_of_birth || '') || 'Date of birth',
          }}
        />
      </SimpleContainerSection>
      <SimpleContainerSection>
        <StaticTextBlock
          label="Sex"
          input={{
            value: user.gender || 'Unspecified',
          }}
        />
      </SimpleContainerSection>
      {user.preferred_pronoun ? (
        <SimpleContainerSection>
          <StaticTextBlock
            label="Pronouns"
            input={{
              value: PRONOUNS[user.preferred_pronoun] || 'Unspecified',
            }}
          />
        </SimpleContainerSection>
      ) : null}
      <SimpleContainerSection>
        <StaticTextBlock
          label="Photo ID"
          input={{
            value: user.first_name && user.last_name && user.photo_id_url ? 'On file' : 'No photo ID on file',
          }}
        />
      </SimpleContainerSection>
    </>
  );
};

const MedicalInfo = ({ user, isPatientsOtherScriptsOn }: Props) => {
  const { allergies, medical_conditions, onboarding_other_scripts, other_scripts_list } = user;
  let otherMedListString = '';

  if (isPatientsOtherScriptsOn) {
    otherMedListString =
      other_scripts_list && other_scripts_list?.length > 0
        ? other_scripts_list?.join(', ')
        : 'No Other Medications Listed'; // title case to match above string values from server
  }

  return (
    <>
      <SimpleContainerSection>
        <StaticTextBlock
          label="Allergies"
          input={{
            value: allergies || 'No allergies on file',
          }}
        />
      </SimpleContainerSection>
      <SimpleContainerSection>
        <StaticTextBlock
          label="Medical conditions"
          input={{
            value: medical_conditions || 'No medical conditions on file',
          }}
        />
      </SimpleContainerSection>
      {isPatientsOtherScriptsOn ? (
        <SimpleContainerSection>
          <StaticTextBlock
            label="Other active medications"
            input={{
              value: onboarding_other_scripts ? otherMedListString : 'No other medications on file',
            }}
          />
        </SimpleContainerSection>
      ) : null}
    </>
  );
};

const UserProfile = ({
  isPatientsOtherScriptsOn,
  params,
}: Pick<Props, 'isPatientsOtherScriptsOn'> & { params: { userID: string } }) => {
  const dispatch = useDispatchShared();
  const { userID: userIdString } = params;
  const userID: number | null = parseInt(userIdString, 10) || null;
  const user = useSelectorShared((state) =>
    getUser(state, {
      userID,
    }),
  );
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.PROFILE__INSURANCE_INFO_VIEWED,
    });
  }, [trackPageView]);

  useEffect(() => {
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchUsers());
  }, [dispatch]);

  if (!user) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const handleEditProfile = (modalType: ModalType) => {
    if (user) {
      dispatch(selectUser(user.id));
    }
    dispatch(openModal(modalType));
  };

  const name = getDisplayFullName(user);
  const breadcrumbs = [
    {
      text: 'Account',
      to: '/me',
    },
    {
      text: name || '',
    },
  ];

  return (
    <PageBackground maxWidth={`${BREAKPOINTS.SM}px`}>
      <Column spacing={spacing.mdSpacing}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <PageHeader>{name || ''}</PageHeader>
        <UploadUserPhoto user={user} />
        <SimpleContainer>
          <SimpleContainerHeader
            linkText="Edit"
            onClick={() => {
              handleEditProfile('PERSONAL_INFO_MODAL');
            }}
          >
            Personal Info
          </SimpleContainerHeader>
          <PersonalInfo user={user} />
        </SimpleContainer>
        <SimpleContainer>
          <SimpleContainerHeader
            linkText="Edit"
            onClick={() => {
              handleEditProfile('MEDICAL_INFO_MODAL');
            }}
          >
            Medical Info
          </SimpleContainerHeader>
          <MedicalInfo
            user={user}
            isPatientsOtherScriptsOn={isPatientsOtherScriptsOn}
          />
        </SimpleContainer>
        <ProfileInsurances userID={user.id} />
      </Column>
      <PersonalInfoModal />
      <MedicalInfoModal />
    </PageBackground>
  );
};

export const ProfileUserProfile = withRouter(UserProfile);
