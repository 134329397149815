import { isSameDay, parseISO } from 'date-fns';
import { formatRelativeDateWithDow } from '~shared/helpers/date';
import { type DeliveryWindow } from '~shared/types';

export const getNextAvailableDateTitle = (earliestAvailableDate: string | null) => {
  if (!earliestAvailableDate) {
    return 'Next Available Date';
  }

  const isToday = isSameDay(new Date(), parseISO(earliestAvailableDate));
  return isToday ? 'Available Today' : `Available ${formatRelativeDateWithDow(earliestAvailableDate, true)}`;
};

const TIME_RANGE_STRING_SEPARATOR = ' to ';
export const getTimeRangeString = ({
  deliver_after,
  deliver_before,
}: {
  deliver_after: string | undefined;
  deliver_before: string | undefined;
}) => {
  return `${deliver_after}${TIME_RANGE_STRING_SEPARATOR}${deliver_before}`;
};

export const getSplitTimeRangeStrings = (value: string) => {
  const [deliverAfter, deliverBefore] = value.split(TIME_RANGE_STRING_SEPARATOR);
  return [deliverAfter, deliverBefore];
};

export const getUniqueDeliveryWindows = (windows: DeliveryWindow[]) => {
  return [
    ...new Map(
      windows.map((window) => {
        const { deliver_before, deliver_after } = window;
        return [getTimeRangeString({ deliver_before, deliver_after }), window];
      }),
    ).values(),
  ];
};
