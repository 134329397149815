// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import {
  AccessibilityInfo,
  ActionSheetContext,
  Body,
  Card,
  Column,
  Description,
  LgPadding,
  Link,
  ListDescription,
  MdSpacing,
  Row,
  SizedImage,
  XsSpacing,
  useScreenSize,
} from '@alto/design-system';
import React, { useContext, useEffect, useState } from 'react';
import styledNative, { css } from 'styled-components/native';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { PLAN_NAME_TO_LOGO } from '~shared/features/onboarding/helpers/insuranceLogos';
import { capitalizeString } from '~shared/helpers/string';
import { type Insurance } from '~shared/types/clients';
import { InsuranceDeletionActionSheet } from './InsuranceDeletionActionSheet';
import { InsuranceImages } from './InsuranceImages';

const popIn = css`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
const StyledContainer = styledNative(Column)<{ isReduceMotionEnabled: boolean }>`
  border-radius: ${BORDERS.RADIUS.LG.px};
  ${({ isReduceMotionEnabled }) =>
    isReduceMotionEnabled &&
    `
    animation-duration: 600ms;
    animation-name: ${popIn};
    animation-fill-mode: forwards;
  `}
`;
/* eslint-enable @typescript-eslint/restrict-template-expressions */

const StyledRow = styledNative(Row)`
  margin-right: ${SPACING.STATIC.XL.px};
`;

type Props = {
  readonly insurance: Insurance;
  readonly onEditInsurance: (insurance: Insurance) => void;
};

const InsuranceContent = ({ isPhotoUpload, insurance }: { isPhotoUpload: boolean; insurance: Insurance }) => {
  const { isSMScreen } = useScreenSize();
  const { image_url, image_url_2, insurance_plan_name, first_name, last_name, bin, pcn, source } = insurance;
  const insuranceLogo = insurance_plan_name && PLAN_NAME_TO_LOGO[insurance_plan_name];
  const insurancePlanName = insurance_plan_name ? capitalizeString(insurance_plan_name) : '';

  return isPhotoUpload ? (
    <InsuranceImages
      imageFrontUrl={image_url}
      imageBackUrl={image_url_2}
    />
  ) : (
    <>
      <Row
        spaceBetween
        centerVertically
      >
        {insurancePlanName && (
          <StyledRow
            centerVertically
            flexShrink={1}
            flexGrow={1}
            flexBasis="0%"
          >
            <Description
              fontFamily="semibold"
              color={COLORS.PALETTE.PRIMARY.DARK}
            >
              {insurancePlanName}
            </Description>
          </StyledRow>
        )}
        {insuranceLogo ? (
          <SizedImage
            pixelSize={100}
            maxHeight={SIZES.ILLUSTRATION.SM.value}
            resizeMode="contain"
            source={insuranceLogo}
          />
        ) : null}
      </Row>
      {isSMScreen ? <XsSpacing /> : null}
      {first_name || last_name ? (
        <>
          <ListDescription iconName="user-small">{`${first_name} ${last_name}`}</ListDescription>
          <XsSpacing />
        </>
      ) : null}
      <ListDescription iconName="shield-small">{`BIN ${bin}`}</ListDescription>
      <XsSpacing />
      {pcn ? (
        <>
          <ListDescription iconName="idcard-small">{`PCN ${pcn}`}</ListDescription>
          <XsSpacing />
        </>
      ) : null}
      {source === 'cardfinder' ? (
        <>
          <MdSpacing key="insurance spacing" />
          <Description fontFamily="italic">
            Securely matched via the national insurance database using your name and date of birth.
          </Description>
        </>
      ) : null}
    </>
  );
};

export const InsuranceCard = ({ insurance, onEditInsurance }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const [isReduceMotionEnabled, setIsReduceMotionEnabled] = useState(true);
  const isPhotoUpload = getIsPhotoUpload(insurance);

  useEffect(() => {
    const handleSetReduceMotion = async () => {
      const enabled = await AccessibilityInfo.isReduceMotionEnabled();
      setIsReduceMotionEnabled(enabled);
    };
    handleSetReduceMotion();
  }, []);

  const handleDeleteInsurance = () => {
    setActiveActionSheet(
      <InsuranceDeletionActionSheet
        insurance={insurance}
        source="onboarding"
      />,
    );
  };

  return (
    <Card>
      <LgPadding>
        <Column>
          <StyledContainer isReduceMotionEnabled={isReduceMotionEnabled}>
            <InsuranceContent
              isPhotoUpload={isPhotoUpload}
              insurance={insurance}
            />
          </StyledContainer>
          {insurance.source !== 'cardfinder' && (
            <>
              <XsSpacing />
              <Row centerVertically>
                {!isPhotoUpload ? (
                  <>
                    <Link
                      textSize="mini"
                      onPress={() => {
                        onEditInsurance(insurance);
                      }}
                      accessibilityLabel="edit insurance"
                    >
                      EDIT
                    </Link>
                    <Body color={COLORS.PALETTE.SECONDARY.DARK}>{' | '}</Body>
                  </>
                ) : null}
                <Link
                  textSize="mini"
                  onPress={handleDeleteInsurance}
                  accessibilityLabel="delete insurance"
                >
                  DELETE
                </Link>
              </Row>
            </>
          )}
        </Column>
      </LgPadding>
    </Card>
  );
};
