import { isSameDay, parseISO } from 'date-fns';
import { createSelector } from 'reselect';
import { getEnterCartTypeHash } from './getCheckoutFlow';
import getCrossSellPrescriptions__DEPRECATED from './getCrossSellPrescriptions';
import { REFILL_CROSS_SELL_NOTICE } from '~shared/features/notices/selectors/getNotices';
import { getViewedNotices } from '~shared/features/notices/selectors/getViewedNotices';

// @deprecated - use libs/shared/features/cart/hooks/useShouldShowCrossSellPage hook
const getShouldShowCrossSellPage__DEPRECATED = createSelector(
  [getViewedNotices, getCrossSellPrescriptions__DEPRECATED, getEnterCartTypeHash],
  (viewedNotices, crossSellPrescriptions, enterCartTypeHash) => {
    const notice = viewedNotices[REFILL_CROSS_SELL_NOTICE] || undefined;
    const crossSellSeenToday = notice && isSameDay(parseISO(notice), new Date());
    const needlePromptShown = enterCartTypeHash.needle_prompt || enterCartTypeHash.needle_prompt_no_needles;

    return !!crossSellPrescriptions.length && !crossSellSeenToday && !needlePromptShown;
  },
);

export default getShouldShowCrossSellPage__DEPRECATED;
