import { type BACKGROUND_COLORS_VALUES, BORDERS, type BORDER_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';
import * as React from 'react';
import { Platform, type StyleProp, View, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';

type BorderProps = {
  readonly noBottomLeftRadius?: boolean;
  readonly noBottomRightRadius?: boolean;
  readonly noTopLeftRadius?: boolean;
  readonly noTopRightRadius?: boolean;
  readonly hideTop?: boolean;
  readonly hideBottom?: boolean;
  readonly hideLeft?: boolean;
  readonly hideRight?: boolean;
  readonly children?: React.ReactNode;
  readonly color?: BORDER_COLORS_VALUES | BACKGROUND_COLORS_VALUES;
  /**
   * XS = 2, SM = 4, MD = 8, LG = 16, XL = 24, XXL = 32, CIRCLE = 50, NONE = 0, DEFAULT = 4
   */
  readonly radius?: keyof typeof BORDERS.RADIUS;
  readonly fullWidth?: boolean;
  readonly style?: StyleProp<ViewStyle>;
};

const StyledBorder = styled(View)<BorderProps>`
  overflow: hidden;
  border-style: solid;
  border-width: ${BORDERS.SIZE}px;
  border-color: ${({ color }) => color || COLORS.BORDER_COLORS.LIGHT};
  ${({ hideTop }) => hideTop && 'border-top-width: 0;'}
  ${({ hideRight }) => hideRight && 'border-right-width: 0;'}
  ${({ hideBottom }) => hideBottom && 'border-bottom-width: 0;'}
  ${({ hideLeft }) => hideLeft && 'border-left-width: 0;'}
  ${({ noTopLeftRadius, radius }: BorderProps) =>
    noTopLeftRadius || !radius ? '' : `border-top-left-radius: ${BORDERS.RADIUS[radius].px};`}
  ${({ noTopRightRadius, radius }: BorderProps) =>
    noTopRightRadius || !radius ? '' : `border-top-right-radius: ${BORDERS.RADIUS[radius].px};`}
  ${({ noTopRightRadius, radius }: BorderProps) =>
    noTopRightRadius || !radius ? '' : `border-top-right-radius: ${BORDERS.RADIUS[radius].px};`}
  ${({ noBottomLeftRadius, radius }: BorderProps) =>
    noBottomLeftRadius || !radius ? '' : `border-bottom-left-radius: ${BORDERS.RADIUS[radius].px};`}
  ${({ noBottomRightRadius, radius }: BorderProps) =>
    noBottomRightRadius || !radius ? '' : `border-bottom-right-radius: ${BORDERS.RADIUS[radius].px};`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${() => Platform.OS === 'web' && 'flex-shrink: 1;'}
`;

export const Border = ({
  children,
  color = COLORS.BORDER_COLORS.LIGHT,
  radius = 'DEFAULT',
  noBottomLeftRadius,
  noBottomRightRadius,
  noTopLeftRadius,
  noTopRightRadius,
  fullWidth = false,
  hideTop,
  hideBottom,
  hideLeft,
  hideRight,
  style,
}: BorderProps) => {
  return (
    <StyledBorder
      color={color}
      radius={radius}
      fullWidth={fullWidth}
      noBottomLeftRadius={noBottomLeftRadius}
      noBottomRightRadius={noBottomRightRadius}
      noTopLeftRadius={noTopLeftRadius}
      noTopRightRadius={noTopRightRadius}
      hideTop={hideTop}
      hideBottom={hideBottom}
      hideLeft={hideLeft}
      hideRight={hideRight}
      style={style}
    >
      {children}
    </StyledBorder>
  );
};
