const PAYMENT_METHOD_DATA_TYPES = Object.freeze({
  STRIPE_DATA: 'StripeData',
  PLAID_DATA: 'PlaidData',
} as const);

export type PaymentMethodTypeKey = keyof typeof PAYMENT_METHOD_DATA_TYPES;
export type PaymentMethodType = (typeof PAYMENT_METHOD_DATA_TYPES)[PaymentMethodTypeKey];

const PAYMENT_TYPES = Object.freeze({
  CREDIT_CARD: 'CC',
  ACH: 'ACH',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
} as const);

export type PaymentTypeKey = keyof typeof PAYMENT_TYPES;
export type PaymentType = (typeof PAYMENT_TYPES)[PaymentTypeKey];

const ACH_NOT_AVAILABLE_SPLIT_PAYMENTS =
  'We are currently unable to accept direct bank payments when splitting payments';

const CHARGE_STATUS = Object.freeze({
  FAILED: 'failed',
});

const PAYMENT_ERRORS = Object.freeze({
  PREAUTH_FAILED_ERROR: 'Error::PreauthFailedError',
});

export { PAYMENT_TYPES, PAYMENT_METHOD_DATA_TYPES, ACH_NOT_AVAILABLE_SPLIT_PAYMENTS, CHARGE_STATUS, PAYMENT_ERRORS };
