import { push } from 'react-router-redux';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import OrderSuccess from './OrderSuccess';

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state: ReduxStateShared, ownProps) => {
  const {
    params: { shipmentID },
  } = ownProps;
  return {
    shipmentID,
  };
};

const mapDispatchToProps = {
  push,
};

// @ts-expect-error TS(7006): Parameter 'stateProps' implicitly has an 'any' type.
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const previousRoute = ORIGIN_NAMES.CHECKOUT;
  const { shipmentID, ...newStateProps } = stateProps;
  const { push, ...newDispatchProps } = dispatchProps;

  return {
    ...newStateProps,
    ...newDispatchProps,
    ...ownProps,
    navigateToNextRoute: () => {
      push({
        pathname: `/orders/${shipmentID}`,
        state: {
          previousRoute,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OrderSuccess);
