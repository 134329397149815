import { Platform } from 'react-native';
import { getUniqueIdSync } from 'react-native-device-info';
import { v4 as uuid } from 'uuid';
import getURLParam from '~shared/helpers/getURLParam';

const isTest = !!process.env.JEST_WORKER_ID;

const saveAnonymousID = (anonymousID: string): void => {
  localStorage?.setItem('anonymousID', anonymousID);
};

const getAnonymousID = (): string | null | undefined => {
  if (isTest) return 'test_device_id';
  if (Platform.OS === 'web') {
    return localStorage?.getItem('anonymousID');
  }
  return null;
};

// runs on initial page load

const ampDeviceID = Platform.OS === 'web' ? getURLParam('amp_device_id', window.location.href) : null;

if (ampDeviceID && !getAnonymousID()) {
  saveAnonymousID(ampDeviceID);
}

export default function getDeviceID() {
  if (Platform.OS !== 'web') {
    return getUniqueIdSync();
  }

  if (areCookiesDisabled()) return null;

  const id = getAnonymousID();

  if (!id) {
    const newID = uuid();
    saveAnonymousID(newID);
    return newID;
  }

  return id;
}

export const areCookiesDisabled = () => !navigator.cookieEnabled;
