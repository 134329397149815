// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, Button, LgSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import OutOfStockImage from '~shared/assets/images/out_of_stock.png';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import {
  MedicationDeliveryStatusPanel,
  NextAvailableDatePanel,
  type OrderTypeContext,
  WantItSoonerPanel,
  getNextAvailableDateTitle,
} from '../../../../index';
import { useNavigateToAssistantLanding } from '../../../alto-assistant/helpers/useNavigateToAssistantLanding';

type Props = {
  readonly handleModalClose?: () => void;
  readonly earliestAvailableDate: string | null;
  readonly earliestDateReason: string;
  readonly prescriptionID: number;
  readonly orderTypeContext?: OrderTypeContext;
  readonly analyticsOrigin?: OriginName;
  readonly showHelpButtons?: boolean;
};

export const NextAvailableDateActionSheet = ({
  handleModalClose,
  earliestAvailableDate,
  earliestDateReason,
  prescriptionID,
  orderTypeContext,
  analyticsOrigin,
  showHelpButtons = false,
}: Props) => {
  const { navigate } = useNavigation();
  const { saveStack, closeActionSheet } = useContext(ActionSheetContext);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const isMedicationOOS = earliestDateReason === 'oos';
  const { trackEvent } = useAnalytics();

  const handleGotItPress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.GET_IT_SOONER_GOT_IT_BUTTON,
        origin: ASSISTANT_CHAT_ORIGINS.NEXT_AVAILABLE_DATE_ACTION_SHEET,
      },
      additionalFields: { prescriptionId: prescriptionID },
    });

    closeActionSheet();

    navigateToAssistantLanding({ preferredDateUnavailable: false });
  };

  const handleNavigateToMessages = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.GET_IT_SOONER_ASK_QUESTION_BUTTON,
        origin: ASSISTANT_CHAT_ORIGINS.NEXT_AVAILABLE_DATE_ACTION_SHEET,
      },
      additionalFields: { prescriptionId: prescriptionID },
    });

    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();

    navigate('RouteAssistantMessage', {
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      origin: ASSISTANT_CHAT_ORIGINS.NEXT_AVAILABLE_DATE_ACTION_SHEET,
      category: 'ordering.date_unavailable',
      subject: `${ASSISTANT_MESSAGE_SUBJECTS.GET_IT_SOONER} - ${prescription?.medication_name ?? ''}`,
    });
  };

  useEffect(() => {
    trackEvent({
      event: EVENTS.NEXT_AVAILABLE_DATE_ACTION_SHEET_VIEWED,
      params: { reason: earliestDateReason, origin: analyticsOrigin },
      additionalFields: { prescriptionId: prescriptionID },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActionSheetV2
      title={isMedicationOOS ? 'Out of stock' : getNextAvailableDateTitle(earliestAvailableDate)}
      illustrationSrc={isMedicationOOS ? OutOfStockImage : undefined}
      buttons={
        showHelpButtons
          ? [
              <Button
                label="Got it"
                key="next-available-date-got-it-button"
                onPress={handleGotItPress}
                accessibilityLabel="Press to return to Help and messages"
              />,
              <Button
                type="tertiary"
                label="I still have a question"
                key="next-available-date-send-message-button"
                onPress={handleNavigateToMessages}
                accessibilityLabel="Press to go to message form"
              />,
            ]
          : undefined
      }
      analyticsName="next available date"
    >
      {isMedicationOOS ? (
        <>
          <NextAvailableDatePanel
            date={earliestAvailableDate}
            prescriptionID={prescriptionID}
            orderTypeContext={orderTypeContext}
          />
          <WantItSoonerPanel
            prescriptionID={prescriptionID}
            handleModalClose={handleModalClose ?? closeActionSheet}
            orderTypeContext={orderTypeContext}
          />
        </>
      ) : (
        <MedicationDeliveryStatusPanel
          earliestAvailableDate={earliestAvailableDate}
          earliestDateReason={earliestDateReason}
          prescriptionID={prescriptionID}
          orderTypeContext={orderTypeContext}
          origin={analyticsOrigin}
        />
      )}
      <LgSpacing />
    </ActionSheetV2>
  );
};
