// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { getIsScheduleableByPrescriptionID } from '~shared/features/prescriptions/selectors/getScheduleableForPrescriptionID';

export const getScheduleablePrescriptions = createSelector(
  [getPrescriptions, getIsScheduleableByPrescriptionID],
  (prescriptions, isScheduleableByPrescriptionID) => {
    return prescriptions.filter((rx) => isScheduleableByPrescriptionID[rx.id] || false);
  },
);

export const getScheduleablePrescriptionIDs = createSelector([getScheduleablePrescriptions], (prescriptions) => {
  return prescriptions.map((prescription) => prescription.id);
});

export const getScheduleableNonEssentialPrescriptions = createSelector(
  [getScheduleablePrescriptions],
  (scheduleablePrescriptions) => {
    return scheduleablePrescriptions.filter((rx) => !rx.is_add_on_otc);
  },
);
