import { BORDERS, COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';
import { useScreenSize } from '../../../../../utils';
import { getCssFromOrientation } from '../../helpers';
import { type VerticalEnabled } from '../../types';
import { ACTIVE_BORDER_SIZE, INACTIVE_CIRCLE_SIZE } from './constants';

type Props = { readonly color?: typeof COLORS.PALETTE.GREYSCALE.DEFAULT | typeof COLORS.PALETTE.DANGER.DEFAULT };

const horizontalCss = css`
  margin-top: ${ACTIVE_BORDER_SIZE}px;
  margin-bottom: ${ACTIVE_BORDER_SIZE}px;
`;
const verticalCss = css`
  top: 6px;
  margin-left: ${ACTIVE_BORDER_SIZE}px;
  margin-right: ${ACTIVE_BORDER_SIZE}px;
`;

const StyledIndicator = styled(View)<{ isMDScreenOrBigger: boolean; verticalEnabled: VerticalEnabled } & Props>`
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  ${({ verticalEnabled, color, isMDScreenOrBigger }) => {
    return css`
      background-color: ${color};
      width: ${INACTIVE_CIRCLE_SIZE}px;
      height: ${INACTIVE_CIRCLE_SIZE}px;
      ${getCssFromOrientation({
        isMDScreenOrBigger,
        verticalEnabled,
        horizontalCss,
        verticalCss,
      })}
    `;
  }}}
`;

export const SmallIndicator = ({ color = COLORS.PALETTE.GREYSCALE.DEFAULT }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  const { verticalEnabled } = useTheme();
  return (
    <StyledIndicator
      verticalEnabled={verticalEnabled}
      color={color}
      isMDScreenOrBigger={isMDScreenOrBigger}
    />
  );
};
