// @owners { team: patients-team }
import {
  type ActionDisableBiometricsFailed,
  type ActionDisableBiometricsSucceeded,
  type ActionEnableBiometricsFailed,
  type ActionEnableBiometricsSucceeded,
  type ActionSetBiometricsPrompted,
  type ActionSyncBiometricsSucceeded,
  DISABLE_BIOMETRICS_FAILED,
  DISABLE_BIOMETRICS_SUCCEEDED,
  ENABLE_BIOMETRICS_FAILED,
  ENABLE_BIOMETRICS_SUCCEEDED,
  SET_BIOMETRICS_PROMPTED,
  SYNC_BIOMETRICS_SUCCEEDED,
} from '~shared/actions/biometrics';
import { immutableUpsert } from '~shared/helpers/immutableUpsert';
import { withRehydration } from '~shared/reducers/withRehydration';

type BiometricUserState = {
  userID: number;
  enabled: boolean;
  prompted: boolean;
};

export type StateBiometrics = {
  users: BiometricUserState[];
  available: boolean;
  supportedType: string | undefined;
  devicePrompted: boolean;
};

export const initialState: StateBiometrics = {
  users: [],
  devicePrompted: false,
  available: false,
  supportedType: undefined,
};

type Action =
  | ActionSyncBiometricsSucceeded
  | ActionEnableBiometricsSucceeded
  | ActionEnableBiometricsFailed
  | ActionDisableBiometricsSucceeded
  | ActionDisableBiometricsFailed
  | ActionSetBiometricsPrompted;

const biometrics = (state: StateBiometrics = initialState, action: Action): StateBiometrics => {
  const { type } = action;

  switch (type) {
    case SYNC_BIOMETRICS_SUCCEEDED: {
      const { available, supportedType } = action.payload;
      return { ...state, available, supportedType };
    }

    case ENABLE_BIOMETRICS_SUCCEEDED:
    case ENABLE_BIOMETRICS_FAILED:
    case DISABLE_BIOMETRICS_SUCCEEDED:
    case DISABLE_BIOMETRICS_FAILED: {
      const { payload } = action;
      return {
        ...state,
        users: immutableUpsert({
          state: state.users,
          payload,
          idKey: 'userID',
        }),
        devicePrompted: true,
      };
    }

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case SET_BIOMETRICS_PROMPTED: {
      const { payload } = action;
      return {
        ...state,
        // @ts-expect-error TS(2322): Type 'SetBiometricsPromptedPayload[]' is not assignable to type 'BiometricUserState[]'.
        users: immutableUpsert({
          state: state.users,
          payload,
          idKey: 'userID',
        }),
        devicePrompted: true,
      };
    }

    default:
      return state;
  }
};

export default withRehydration(biometrics, initialState);
