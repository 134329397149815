// @owners { team: patients-team }

import { ActionSheetContext, Button } from '@alto/design-system';
import React, { useCallback, useContext, useState } from 'react';
import { getPrescriptionsByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { RenewalRequestActionSheet, SelectMedicationsActionSheet } from '../../my-meds';

type Props = {
  readonly inline?: boolean;
  readonly label?: string;
  readonly onComplete?: () => void;
  readonly prescriptionIDs: number[];
  readonly secondary?: boolean;
  readonly small?: boolean;
};

export const RenewalRequestButton = ({
  inline,
  label = 'Get more refills',
  onComplete,
  prescriptionIDs,
  secondary,
  small,
}: Props) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const [hasRequestedRefills, setHasRequestedRefills] = useState(false);
  const requestRenewalLoadingByID = useSelectorShared((state) => state.ui.loading.requestRenewalLoadingByID);
  const requestRenewalErrorByID = useSelectorShared((state) => state.ui.errors.requestRenewalErrorByID);
  const prescriptions = useSelectorShared((state) => getPrescriptionsByIDs(state, prescriptionIDs));
  const isRenewalRequestLoading = prescriptions.some((p) => requestRenewalLoadingByID[p.id]);
  const hasRenewalRequestError = prescriptions.some((p) => requestRenewalErrorByID[p.id]);

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleSubmitRequestRenewal = useCallback(async () => {
    if (hasRenewalRequestError) return;

    setHasRequestedRefills(true);
    onComplete?.();
  }, [hasRenewalRequestError, onComplete]);

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleRefillsRenewalButtonPress = useCallback(async () => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.RENEWAL_REQUEST__CONFIRMATION_VIEWED)));
    if (prescriptionIDs.length > 1) {
      setActiveActionSheet(
        <SelectMedicationsActionSheet
          title="Which medications should we request refills for?"
          label="Request refills"
          prescriptionIDs={prescriptionIDs}
          onSubmit={(selectedPrescriptionIDs) => {
            setActiveActionSheet(
              <RenewalRequestActionSheet
                prescriptionIDs={selectedPrescriptionIDs}
                onSubmit={handleSubmitRequestRenewal}
                requestRenewalLoading={isRenewalRequestLoading || hasRequestedRefills}
              />,
            );
          }}
        />,
      );
    } else {
      setActiveActionSheet(
        <RenewalRequestActionSheet
          prescriptionIDs={prescriptionIDs}
          onSubmit={handleSubmitRequestRenewal}
          requestRenewalLoading={isRenewalRequestLoading || hasRequestedRefills}
        />,
      );
    }
  }, [
    dispatch,
    handleSubmitRequestRenewal,
    hasRequestedRefills,
    isRenewalRequestLoading,
    prescriptionIDs,
    setActiveActionSheet,
  ]);

  return (
    <>
      {!hasRequestedRefills && (
        <Button
          testID="e2e-renewal-request-refills"
          accessibilityLabel={label}
          width={inline ? 'inline' : undefined}
          label={label}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleRefillsRenewalButtonPress}
          type={secondary ? 'secondary' : 'primary'}
          small={small}
        />
      )}
    </>
  );
};
