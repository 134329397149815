// @owners { team: patients-team }
import { isAfter, isSameDay, isValid, parseISO } from 'date-fns';
import { createSelector } from 'reselect';
import { getDeliveriesByPrescriptionID } from './getDeliveriesForPrescriptionID';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type LightDelivery, type ReduxStateShared } from '~shared/types';

export type DeliveryByPrescriptionID = Record<number, LightDelivery | undefined>;

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

export const nextDelivery = (deliveries: LightDelivery[]) => {
  const laterDeliveries = deliveries.filter((delivery) => {
    const date = parseISO(delivery.date ?? '');

    // TODO: Verify `shipped` is a valid delivery status
    // @see Delivery Statuses: https://github.com/scriptdash/scriptdash/blob/44aeabc/app/assets/javascripts/wunderbar/constants/constants.json#L3767-L3776
    // @see Shipment Statuses: https://github.com/scriptdash/scriptdash/blob/44aeabc/app/assets/javascripts/wunderbar/constants/constants.json#L3719-L3728
    if (isValid(date) && ['upcoming', 'shipped'].includes(delivery.status)) {
      const now = new Date();
      return isAfter(date, now) || isSameDay(date, now);
    }

    return false;
  });

  let delivery = laterDeliveries.pop();

  if (!delivery) {
    delivery = deliveries.find(({ status }) => status === 'unscheduled');
  }

  return delivery;
};

export const getNextDeliveryByPrescriptionID = createSelector(
  [getDeliveriesByPrescriptionID, getPrescriptions],
  (deliveriesByPrescriptionID, prescriptions) => {
    const nextDeliveriesByPrescriptionID: DeliveryByPrescriptionID = {};

    prescriptions.forEach((prescription) => {
      const deliveries = deliveriesByPrescriptionID[prescription.id] || [];
      nextDeliveriesByPrescriptionID[prescription.id] = nextDelivery(deliveries);
    });

    return nextDeliveriesByPrescriptionID;
  },
);

// If there is no upcoming delivery, nextDelivery used by getNextDeliveryForPrescriptionID will retrieve an
// unscheduled delivery. This is different from getNextUpcomingDeliveryForPrescription which will retrieve the
// next upcoming delivery only if it exists.
export const getNextDeliveryForPrescriptionID = createSelector(
  [getNextDeliveryByPrescriptionID, getPrescriptionID],
  (nextDeliveryByPrescriptionID, prescriptionID) => {
    return nextDeliveryByPrescriptionID[prescriptionID];
  },
);

export const getNextDeliveriesMappedToPrescriptionID = createSelector(
  [getNextDeliveryByPrescriptionID],
  (nextDeliveryByPrescriptionID) => {
    return nextDeliveryByPrescriptionID;
  },
);
