// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  H1,
  ListButton,
  MdPadding,
  MdSpacing,
  SecondaryPage,
  SmPadding,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useContext, useEffect } from 'react';
import { Platform } from 'react-native';
import { ASSISTANT_PRESSED_EVENTS, ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantPressedEvent, sendAssistantViewedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantMessageSubject,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { PROCESSING } from '~shared/features/my-meds/constants';
import { makeGetMedicationTagKey } from '~shared/features/my-meds/selectors/getMedicationTagKey';
import { partitionMedicationsByActiveState } from '~shared/features/my-meds/selectors/partitionMedicationsByActiveState';
import { type Medication } from '~shared/features/my-meds/types';
import { shallowEqual, useDispatchShared, useSelectorShared } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { MedicationRows } from './ordering/MedicationRows';

const isWeb = Platform.OS === 'web';
export const MedicationSelection = () => {
  const { getParam, goBack, navigate } = useNavigation<'RouteAssistantMedSelection'>();

  const dispatch = useDispatchShared();
  const { restoreStack } = useContext(ActionSheetContext);
  const showOnlyProcessingMeds = getParam('showOnlyProcessingMeds', false);
  const preferredDateUnavailable = getParam('preferredDateUnavailable', false);
  const category = getParam('category', undefined);
  const stackCacheKey = getParam('stackCacheKey', undefined);

  const fallbackCategory = showOnlyProcessingMeds ? ASSISTANT_CATEGORIES.MEDICATIONS : ASSISTANT_CATEGORIES.ORDERING;

  const { active, inactive, needsAttention } = useSelectorShared(partitionMedicationsByActiveState);
  let medications: Medication[];
  if (showOnlyProcessingMeds) {
    medications = needsAttention.concat(active);
  } else {
    medications = needsAttention.concat(active).concat(inactive);
  }

  medications = useSelectorShared(
    (state: ReduxStateShared) =>
      medications.filter((medication) => {
        const { key } = makeGetMedicationTagKey(state, { medication, userID: medication.userID });
        return showOnlyProcessingMeds ? key === PROCESSING : true;
      }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.MEDICATION_PICKER, { medication_count: medications.length }),
    );
  }, [dispatch, medications.length]);

  const onPressBack = useCallback(() => {
    if (stackCacheKey) {
      restoreStack<ActionSheetProviderStackCacheKey>(stackCacheKey);
    }
    goBack();
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.MEDICATION_PICKER_BACK));

    return false;
  }, [goBack, dispatch, restoreStack, stackCacheKey]);

  const navigateToMessages = (subject: AssistantMessageSubject) => {
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_MED_SELECTION,
      category: category ?? fallbackCategory,
      messagePrompt: showOnlyProcessingMeds ? MESSAGE_PROMPTS.HELP_WITH_MEDICATION : MESSAGE_PROMPTS.HELP_WITH_ORDER,
      subject,
    });
  };

  const onPressSomethingElse = () => {
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.MEDICATION_PICKER_SOMETHING_ELSE));
    navigateToMessages(ASSISTANT_MESSAGE_SUBJECTS.MEDICATION);
  };

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      HeaderContent={
        <MdPadding
          leftPadding={isWeb ? SPACING.STATIC.NONE : undefined}
          rightPadding={isWeb ? SPACING.STATIC.NONE : undefined}
        >
          <H1>{medications.length > 1 ? 'Which medication?' : 'For this medication?'}</H1>
          <MdSpacing />
        </MdPadding>
      }
      onDismiss={onPressBack}
      dismissIcon="chevronleft"
    >
      <MedicationRows
        medications={medications}
        showPrescriptionTracker
        preferredDateUnavailable={preferredDateUnavailable}
      />
      <SmPadding
        leftPadding={SPACING.STATIC.NONE}
        rightPadding={SPACING.STATIC.NONE}
      >
        <ListButton
          LeftContent={<AltoIcon name="questionmark-duo" />}
          rightIcon="chevronright-small"
          label="Something else"
          onPress={onPressSomethingElse}
        />
      </SmPadding>
    </SecondaryPage>
  );
};
