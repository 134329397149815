// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, AltoIcon, Tag, Touchable, XsSpacing } from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { Platform } from 'react-native';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type BackorderOrigin } from '../constants';
import { useBackorderedAnalytics } from '../hooks/useBackorderedAnalytics';
import { BackorderedActionSheet } from './BackorderedActionSheet';

type Props = {
  readonly origin: BackorderOrigin;
  readonly prescriptionId: number | undefined;
};

export const BackorderedTag = ({ origin, prescriptionId }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  useBackorderedAnalytics({ origin, prescriptionId, event: EVENTS.C2_BACKORDER_TAG_VIEWED });

  const handleTagPress = useCallback(() => {
    setActiveActionSheet(
      <BackorderedActionSheet
        origin={origin}
        prescriptionId={prescriptionId}
      />,
    );
  }, [setActiveActionSheet, origin, prescriptionId]);

  return (
    <Touchable
      onPress={handleTagPress}
      backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
    >
      {Platform.OS === 'web' ? null : <XsSpacing />}
      <Tag
        label="Backorder"
        type="error"
        right
        icon={<AltoIcon name="chevrondown-small" />}
        isPressable
      />
    </Touchable>
  );
};
