// @owners { team: patients-team }
import { useMutation } from '@tanstack/react-query';
import { createPlaidLinkTokenSucceeded } from '~shared/actions/plaid';
import { post } from '~shared/helpers/apiHelper';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';
import { Sentry } from '~shared/sentry';
import { useDispatchShared } from '~shared/store';
import { type PlaidLinkToken } from '~shared/types';

export const useCreateLinkToken = () => {
  const dispatch = useDispatchShared();
  const { mutate } = useMutation({
    mutationFn: async () => {
      const response = await post('/plaid/create_link_token');
      if (response.error) {
        throw new Error(getErrorMessageFromResponse(response));
      }
      return response;
    },
    onError: (error: Error) => {
      Sentry.captureException(error);
    },
    onSuccess: (data: PlaidLinkToken) => {
      dispatch(createPlaidLinkTokenSucceeded(data));
      return data;
    },
  });

  return { createLinkToken: mutate };
};
