import { closeBanner } from '~shared/actions/banners';
import { clearInsuranceError, deselectInsurance } from '~shared/actions/insurances';
import { closeModal } from '~shared/actions/modal';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import InsuranceModal from './InsuranceModal';

const mapStateToProps = (state: ReduxStateShared) => {
  const selectedInsurance = state.insurances.insurances.find((a) => a.id === state.insurances.selectedInsuranceID);
  return {
    ...state.insurances,
    ...state.modal,
    ...state.banners,
    selectedInsurance,
    success: state.insurances.createInsuranceSuccess || state.insurances.updateInsuranceSuccess,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    closeModal: () => {
      dispatch(closeModal('INSURANCE_MODAL'));
      dispatch(deselectInsurance());
      dispatch(clearInsuranceError());
    },
    closeBanner: () => {
      dispatch(closeBanner());
    },
    deselectInsurance: () => {
      dispatch(deselectInsurance());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceModal);
