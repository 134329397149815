// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Illustration, MdSpacing, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, H1, Link, Modal, ModalContent, ModalFooter, Row } from '@alto/pocky';
import React, { useEffect } from 'react';
import { Link as RRLink } from 'react-router';
import styled from 'styled-components';
import { sendNewYorkFertilityNeedlePromptViewed } from '~shared/actions/cart';
import { closeModal } from '~shared/actions/modal';
import rx from '~shared/assets/images/needle_meds.png';
import { getEnterCartData } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { formatList } from '~shared/helpers/list';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const StyledColumn = styled(Column)`
  margin-top: -${SPACING.STATIC.XL.px};
  align-items: center;
`;

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
    margin-bottom: ${SPACING.STATIC.XS.px};
  }
`;

const StyledButton = styled(Button)`
  justify-content: center;
  padding: 0 ${SPACING.STATIC.XXL.px};
`;

const TitleText = styled(H1)`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  padding-top: 0;
  white-space: pre-wrap;
  margin-top: -${SPACING.STATIC.XL.px};
`;

export default function NoNeedlesFoundModal() {
  const dispatch = useDispatchShared();

  const showNoNeedlesFoundModal = useSelectorShared(
    (state) => getIsModalShowing(state) && getModalType(state) === 'NO_NEEDLES_FOUND_MODAL',
  );

  const closeNoNeedlesFoundModal = () => {
    dispatch(closeModal('NO_NEEDLES_FOUND_MODAL'));
  };

  const enterCartData = useSelectorShared(getEnterCartData);
  const requireNeedlePrescriptions = enterCartData?.needle_prompt_no_needles?.require_needle_prescriptions ?? [];

  const requireMedicationNames = () => {
    const medicationNames = requireNeedlePrescriptions.map(
      (medication: { medication_name: string }) => medication.medication_name,
    );

    return formatList(medicationNames);
  };

  useEffect(() => {
    dispatch(sendNewYorkFertilityNeedlePromptViewed());
  }, [dispatch, enterCartData]);

  if (!showNoNeedlesFoundModal) return null;
  return (
    <StyledModal
      showing={showNoNeedlesFoundModal}
      onClose={closeNoNeedlesFoundModal}
    >
      <StyledColumn>
        <Illustration
          size="lg"
          source={rx}
          accessibilityLabel="Missing needles"
        />
        <MdSpacing />
        <MdSpacing />
        <TitleText>Missing needles?</TitleText>
      </StyledColumn>
      <MdSpacing />
      <ModalContent textAlignment="left">
        <Body color={COLORS.TEXT_COLORS.GREY}>Your {requireMedicationNames()} will not come with needles.</Body>
        <MdSpacing />
        <MdSpacing />
        <Body color={COLORS.TEXT_COLORS.GREY}>
          If you need needles or are out of refills, you can request more from an existing needle prescription on your
          profile,
          {' or '}
          <RRLink to="/messages">
            <Link onClick={closeNoNeedlesFoundModal}>message us</Link>
          </RRLink>{' '}
          for assistance.
        </Body>
        <XsSpacing />
      </ModalContent>
      <ModalFooter>
        <Row
          horizontallyAlignContent
          evenlySpaceContent
          spacing={SPACING.STATIC.MD.rem}
        >
          <RRLink to="/checkout">
            <StyledButton
              kind="primary"
              onClick={closeNoNeedlesFoundModal}
            >
              Got it
            </StyledButton>
          </RRLink>
        </Row>
      </ModalFooter>
    </StyledModal>
  );
}
