// eslint-disable-next-line @alto/no-pocky-import
import { Loader } from '@alto/pocky';
import React, { useCallback, useEffect, useState } from 'react';
import { markWrongUser } from '~shared/actions/verifications';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getURLParams } from '~shared/helpers/helper';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import NotMe from './NotMe';

type StateProps = {
  readonly loading: boolean;
};

type DispatchProps = {
  readonly markWrongUser: (token: string, commsChannel: string) => void;
};

type OwnProps = {
  readonly params: {
    verificationType: string;
  };
};

type Props = OwnProps & StateProps & DispatchProps;

const NotMeContainer = ({ loading, markWrongUser, params }: Props) => {
  const { verificationType } = params;
  const { token } = getURLParams();
  const [isMarkedWrongUser, setIsMarkedWrongUser] = useState<boolean | null | undefined>(null);
  const onIsMarkedWrongUser = useCallback(() => {
    // @ts-expect-error TS(7006): Parameter 'response' implicitly has an 'any' type.
    // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression, promise/always-return
    markWrongUser(token, verificationType).then((response) => {
      setIsMarkedWrongUser(response);
    });
  }, [token, verificationType, markWrongUser]);

  useEffect(() => {
    onIsMarkedWrongUser();
  }, [onIsMarkedWrongUser]);

  if (loading) return <Loader />;

  return (
    <NotMe
      commsChannel={verificationType}
      result={isMarkedWrongUser}
    />
  );
};

const mapStateToProps = (state: ReduxStateShared): StateProps => {
  const loading = getLoading(state);
  return {
    loading: loading.markingWrongUserLoading,
  };
};

const mapDispatchToProps = {
  markWrongUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotMeContainer);
