import { COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Row, shadows } from '@alto/pocky';
import React, { type ReactNode, useEffect, useMemo } from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { VERIFY } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { AuthorizedDashboardNavbar } from '~web/features/app/components/AuthorizedDashboardNavBar';
import logo from '~web/images/altoLogo@2x.png';

const logoStyles = css`
  height: 44px;
  > img {
    height: inherit;
    width: inherit;
  }
`;

const StyledLinkLogo = styled(Link)`
  ${logoStyles}
`;

type LogoLinkProps = {
  readonly to: string;
};

export const LinkLogo = ({ to }: LogoLinkProps) => (
  <StyledLinkLogo to={to}>
    <img
      src={logo}
      alt="Alto logo"
    />
  </StyledLinkLogo>
);

export const Navbar = styled(Column).attrs({
  verticallyAlignContent: true,
  horizontallyAlignContent: true,
})<{ showProgynyBackground: boolean; isOnboarding: boolean; authorized: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  background: ${COLORS.BACKGROUND_COLORS.WHITE};
  background-color: ${({ showProgynyBackground, isOnboarding }) => {
    if (showProgynyBackground) return COLORS.BACKGROUND_COLORS.PINK_LIGHTEST;
    if (isOnboarding) return '#FFFAF5';
    return 'white';
  }};
  z-index: 1;
  ${({ authorized }) =>
    authorized
      ? `
    z-index: 999;
  `
      : `
    z-index: 1000;
  `};
  position: ${({ isOnboarding }) => (isOnboarding ? 'absolute' : 'fixed')};
  ${({ isOnboarding }) => !isOnboarding && shadows.boxShadow1}
`;

export const Container = styled(Row).attrs({
  verticallyAlignContent: true,
})<{ isMDScreenOrBigger: boolean; isLGScreenOrBigger: boolean }>`
  align-content: center;
  padding: 0 ${SPACING.STATIC.LG.rem};
  width: 100%;
  height: ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM};
  ${({ isMDScreenOrBigger }) =>
    isMDScreenOrBigger &&
    `
      width: 100%;
      height: ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG};
    `}
  ${({ isLGScreenOrBigger }) =>
    isLGScreenOrBigger &&
    `
      width: ${parseFloat(SIZES.PAGE.WEB_NAVBAR_WIDTH.LG)};
      height: ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG};
    `}
`;

type Props = {
  readonly authorized: boolean;
  readonly closeBanner?: () => void;
  readonly location: Record<string, any>;
  readonly onboarding: boolean;
  readonly showBanner: boolean;
  readonly showProgyny: boolean;
};

type WrapperProps = {
  readonly showProgynyBackground: boolean;
  readonly authorized: boolean;
  readonly isOnboarding: boolean;
  readonly children: ReactNode;
};

const NavBarWrapper = ({ children, showProgynyBackground, authorized, isOnboarding }: WrapperProps) => {
  return (
    <Navbar
      authorized={authorized}
      showProgynyBackground={showProgynyBackground}
      isOnboarding={isOnboarding}
    >
      <div className="branch-journeys-top" />
      {children}
    </Navbar>
  );
};

export const DashboardNavbar = ({ authorized, closeBanner, onboarding, showBanner, showProgyny, location }: Props) => {
  const { getCurrentRouteName } = useNavigation();
  const { isMDScreenOrBigger, isLGScreenOrBigger } = useScreenSize();
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const brandLink = authorized ? '/home' : '/';
  const showProgynyBackground = showProgyny && onboarding;
  const isOnboardingVerify = currentRoute === VERIFY;
  const isOnboarding = onboarding && !isOnboardingVerify;
  const pathname: string = location.pathname ?? '';
  const inCheckout = useMemo(() => pathname.match(/checkout/g), [pathname]);

  useEffect(() => {
    if (inCheckout && showBanner) {
      closeBanner?.();
    }
  }, [inCheckout, closeBanner, showBanner]);

  if (onboarding) {
    return null;
  }

  if (authorized) {
    return (
      <AuthorizedDashboardNavbar
        authorized={authorized}
        closeBanner={closeBanner}
        showBanner={showBanner}
        showProgyny={showProgyny}
        location={location}
        onboarding={onboarding}
      />
    );
  }

  return (
    <NavBarWrapper
      showProgynyBackground={showProgynyBackground}
      authorized={authorized}
      isOnboarding={isOnboarding}
    >
      <Container
        horizontallyAlignContent
        isMDScreenOrBigger={isMDScreenOrBigger}
        isLGScreenOrBigger={isLGScreenOrBigger}
      >
        <LinkLogo to={brandLink} />
      </Container>
    </NavBarWrapper>
  );
};
