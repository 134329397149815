// @owners { team: patients-team }
import { allVideos } from '~shared/features/instructional-videos/constants';
import { getForYouVideos } from '~shared/features/instructional-videos/selectors/getForYouVideos';
import { getSelectedVideo } from '~shared/features/instructional-videos/selectors/getSelectedVideo';
import { type Video } from '~shared/features/instructional-videos/types';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import ProfileInstructionalVideos from './ProfileInstructionalVideos';

const mapStateToProps = (state: ReduxStateShared) => ({
  forYouVideos: getForYouVideos(state),
  allVideos,
  initialSearchTerm: getSelectedVideo(state)?.medName || '',
});

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    sendVideoPlayedAnalyticsEvent: (video: Video) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(EVENTS.INSTRUCTIONAL_VIDEO_PLAYED, {
            videoID: video.videoID,
            origin: ORIGIN_NAMES.PROFILE,
          }),
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInstructionalVideos);
