// @owners { team: patients-team }

import { AltoIcon, Row } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Text, palette } from '@alto/pocky';
import React from 'react';
import { Link as RRLink } from 'react-router';
import styled from 'styled-components';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';

const Link = styled(RRLink)`
  color: ${palette.royalBlue};
  font-size: unset;
`;

export default class DeliveryConfirmedPage extends React.Component {
  render() {
    return (
      <PageBackground>
        <SingleColumnContentWrapper>
          <UnauthorizedFormWrapper header="Delivery Confirmed!">
            <Row center>
              <AltoIcon
                name="checkmark"
                type="disabled"
              />
            </Row>
            <Text>
              <Link to="/login">Login to your dashboard</Link> to edit this delivery.
            </Text>
          </UnauthorizedFormWrapper>
        </SingleColumnContentWrapper>
      </PageBackground>
    );
  }
}
