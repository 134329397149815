import { createSelector } from 'reselect';
import { getCurrentUserID, getUsers } from './getUsers';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

type IsFertilityUserByID = Record<number, boolean>;

export const getIsFertilityUserByID = createSelector(getUsers, (users) => {
  const isFertilityUserByID: IsFertilityUserByID = {};

  users.forEach((user) => {
    isFertilityUserByID[user.id] = user.is_fertility ?? false;
  });

  return isFertilityUserByID;
});

const getUserID = (_: ReduxStateShared, props: UserIDParam) => props.userID;

export const getIsFertilityUser = createSelector([getUserID, getIsFertilityUserByID], (id, isFertilityUser) => {
  if (!id) return false;

  return !!isFertilityUser[id];
});

export const getIsCurrentUserFertility = createSelector(
  [getCurrentUserID, getIsFertilityUserByID],
  (id, isFertilityUser) => {
    if (!id) return false;

    return !!isFertilityUser[id];
  },
);
