// @owners { team: patients-team }
import { SIZES } from '@alto/design-library-tokens';
import { MdSpacing } from '@alto/design-system';
import { useEssentialsFsaBadge } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import { Image, Text, View } from '~web/features/essentials/primitives';

type ProductTitleProps = {
  readonly product: AddOnOtc;
  readonly isMobile: boolean;
};

export const ProductTitle = ({ product, isMobile }: ProductTitleProps) => {
  const { showFsaBadge, fsaEligibleImage } = useEssentialsFsaBadge({ addOnOtc: product });

  if (!showFsaBadge) {
    return (
      <Text
        title={product.name}
        variant={isMobile ? 'h2' : 'h1'}
      />
    );
  }

  if (isMobile) {
    return (
      <View flexDirection="row">
        <Text
          title={product.name}
          variant="h2"
        />
        <View
          justifyContent="flex-end"
          margin={`-${SIZES.ILLUSTRATION.SM.px} 0 0 0`}
        >
          <Image
            src={fsaEligibleImage}
            alt="fsa eligible item"
            width={SIZES.ILLUSTRATION.LG.px}
            height={SIZES.ILLUSTRATION.LG.px}
          />
        </View>
      </View>
    );
  }

  return (
    <>
      <Image
        src={fsaEligibleImage}
        alt="fsa eligible item"
        width="100px"
        height="100px"
      />
      <MdSpacing />
      <Text
        title={product.name}
        variant="h1"
      />
    </>
  );
};
