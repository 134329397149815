// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { H1, MdPadding, MdSpacing, XlSpacing } from '@alto/design-system';
import React from 'react';
import { SectionList, type SectionListRenderItemInfo } from 'react-native';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { DeliveryCard } from './delivery-card/DeliveryCard';
import { EmptyDeliveryCard } from './delivery-card/EmptyDeliveryCard';

type Props = {
  readonly upcomingShipments: ShipmentWithItemsAndAdditionalFields[];
  readonly pastShipments: ShipmentWithItemsAndAdditionalFields[];
  readonly loading: boolean;
};

const ROUGH_NUMBER_OF_SECTIONS_ON_SCREEN = 3;

const ItemSeparatorComponent = () => <MdSpacing />;

export const DeliverySections = ({ upcomingShipments, pastShipments, loading }: Props) => {
  const keyExtractor = (item: { id: number }) => {
    return item ? `order-${item.id}` : '';
  };

  const sections = [
    {
      title: 'Upcoming Orders',
      data: upcomingShipments,
    },
    {
      title: 'Past Orders',
      data: pastShipments,
    },
  ];

  const renderItem = (
    item: SectionListRenderItemInfo<
      ShipmentWithItemsAndAdditionalFields,
      { title: string; data: ShipmentWithItemsAndAdditionalFields[] }
    >,
  ) => {
    const shipment = item.item;
    const isPastDelivery = item.section.title === 'Past Orders';

    return (
      <MdPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <DeliveryCard
          shipment={shipment}
          isEditable={isShipmentEditable(shipment)}
          isPastDelivery={isPastDelivery}
        />
      </MdPadding>
    );
  };

  return (
    <SectionList
      renderSectionHeader={({ section: { title } }) => {
        // Only render the 2nd section because the first always taken care of by the page header.
        if (title === 'Upcoming Orders') {
          return null;
        }
        return (
          <MdPadding
            topPadding={SPACING.STATIC.LG}
            bottomPadding={SPACING.STATIC.LG}
          >
            <H1>{title}</H1>
          </MdPadding>
        );
      }}
      renderSectionFooter={({ section: { title, data } }) => {
        if (data.length === 0) {
          return (
            <>
              <MdPadding
                topPadding={SPACING.STATIC.NONE}
                bottomPadding={SPACING.STATIC.NONE}
              >
                <EmptyDeliveryCard title={title} />
              </MdPadding>
              <XlSpacing />
            </>
          );
        }

        return null;
      }}
      sections={sections}
      ItemSeparatorComponent={ItemSeparatorComponent}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      refreshing={loading}
      initialNumToRender={ROUGH_NUMBER_OF_SECTIONS_ON_SCREEN}
      stickySectionHeadersEnabled={false}
    />
  );
};
