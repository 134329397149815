// @owners { team: patients-team }
import {
  OrdersEndpoint,
  type OrdersEndpointCreateParams,
  type OrdersEndpointUpdateParams,
} from '@alto/scriptdash/alto/orders/patients/v1/orders_endpoint';
import { useMutation } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';
import { buildError } from '~shared/helpers/apiHelper';
import { useHandleCreateOrderError } from '../hooks/useHandleCreateOrderError';
import { useHandleCreateOrderSuccess } from '../hooks/useHandleCreateOrderSuccess';

const ordersEndpoint = OrdersEndpoint(apiEndpointHandler);

const isUpdateParams = (
  params: OrdersEndpointCreateParams | OrdersEndpointUpdateParams,
): params is OrdersEndpointUpdateParams => 'shipment_id' in params;

export const CREATE_ORDER_MUTATION_KEY = ['createOrder'];

/**
 * Returns mutation function to create (POST /v1/orders) or update (PUT /v1/orders) an order
 */
export const useCreateOrder = ({ isEditingOrder }: { isEditingOrder: boolean }) => {
  const { handleCreateOrderSuccess } = useHandleCreateOrderSuccess();
  const { handleCreateOrderError } = useHandleCreateOrderError();

  const { mutateAsync } = useMutation({
    mutationKey: CREATE_ORDER_MUTATION_KEY,
    mutationFn: async (params: OrdersEndpointCreateParams | OrdersEndpointUpdateParams) => {
      if (isEditingOrder && isUpdateParams(params)) {
        return await ordersEndpoint.update({ params });
      }

      return await ordersEndpoint.create({ params });
    },
    onSuccess: ({ data, errors }) => {
      if (errors) {
        handleCreateOrderError(buildError(0, errors));
        return;
      }

      handleCreateOrderSuccess({ shipmentIds: data?.shipment_ids || [] });
      return data;
    },
  });

  return {
    createOrder: async (orderParams: OrdersEndpointCreateParams | OrdersEndpointUpdateParams) => {
      return mutateAsync(orderParams);
    },
  };
};
