import { type BACKGROUND_COLORS_VALUES, BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable, View, useWindowDimensions } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import styled from 'styled-components/native';
import { Constants } from '../../../utils';
import { PressableAltoIcon } from '../../buttons';
import { Row, XlPadding, XsPadding } from '../../containers';
import { Illustration, type ImageSourceAndLabel, SizedImage } from '../../images';
import { BasePage } from './BasePage';
import { NavBar } from './NavBar';

const ImageThumbnailWrapper = styled(XsPadding)<{ selected: boolean }>`
  margin: ${SPACING.STATIC.XXS.px};
  border-radius: ${BORDERS.RADIUS.MD.px};
  border: 1px solid
    ${({ selected }) => (selected ? COLORS.BORDER_COLORS.SECONDARY : COLORS.BACKGROUND_COLORS.TRANSPARENT)};
`;

type ImageThumbnailsProps = {
  readonly images: ImageSourceAndLabel[];
  readonly onSelect: (index: number) => void;
  readonly currentIndex: number;
};

const ImageThumbnails = ({ images, onSelect, currentIndex }: ImageThumbnailsProps) => {
  return (
    <XlPadding>
      <Row center>
        {images.map((image, i) => (
          <Pressable
            // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
            key={`${image.source}`}
            onPress={() => {
              onSelect(i);
            }}
          >
            <ImageThumbnailWrapper selected={currentIndex === i}>
              <Illustration
                source={image.source}
                size="sm"
              />
            </ImageThumbnailWrapper>
          </Pressable>
        ))}
      </Row>
    </XlPadding>
  );
};

type ImageGalleryPageProps = {
  readonly images: ImageSourceAndLabel[];
  readonly index: number;
  readonly onClose: () => void;
  readonly onChange?: (index?: number) => void;
  readonly onPan?: () => void;
  readonly onZoom?: () => void;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
};

export const ImageGalleryPage = ({
  images,
  index,
  onClose,
  onChange,
  onPan,
  onZoom,
  backgroundColor = COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST,
}: ImageGalleryPageProps) => {
  const { width: windowWidth } = useWindowDimensions();
  const [activeIndex, setActiveIndex] = useState(index);
  const imageScale = useRef(1);

  useEffect(() => {
    imageScale.current = 1;
  }, [index]);

  return (
    <BasePage
      scrollViewBounces={false}
      backgroundColor={backgroundColor}
      transparentNavBar
      NavBar={
        <NavBar
          LeftPressableAltoIcon={
            <PressableAltoIcon
              name="close"
              onPress={onClose}
              accessibilityLabel="Dismiss this screen"
            />
          }
        />
      }
    >
      <ImageViewer
        imageUrls={images.map((image) => ({
          // @ts-expect-error TS(2339): Property 'uri' does not exist on type 'ImageSourcePropType'.
          url: image.source.uri || '',
          props: image,
        }))}
        renderImage={({ source, accessibilityLabel }) => (
          <SizedImage
            source={source}
            accessibilityLabel={accessibilityLabel}
            pixelSize={windowWidth * 0.8}
            resizeMode="contain"
            center
          />
        )}
        renderFooter={(currentIndex) => (
          <ImageThumbnails
            images={images}
            onSelect={(i) => {
              setActiveIndex(i);
              if (onChange) {
                onChange(i);
              }
            }}
            currentIndex={currentIndex}
          />
        )}
        // eslint-disable-next-line react-native/no-inline-styles
        footerContainerStyle={{
          width: '100%',
        }}
        backgroundColor={backgroundColor}
        index={activeIndex}
        onCancel={onClose}
        onChange={onChange}
        onMove={(moveProps) => {
          if (
            moveProps?.type !== 'onPanResponderRelease' ||
            (moveProps?.positionX === 0 && moveProps?.positionY === 0 && moveProps.scale === 1)
          ) {
            return;
          }
          if (imageScale.current !== moveProps.scale) {
            if (onZoom && moveProps.scale > imageScale.current) {
              onZoom();
            }
            imageScale.current = moveProps.scale;
          } else if (onPan) {
            onPan();
          }
        }}
        renderIndicator={() => <View />}
        saveToLocalByLongPress={false}
        useNativeDriver={Constants.useNativeDriver}
        enableSwipeDown
      />
    </BasePage>
  );
};
