// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getCurrentUserDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { type ShipmentIDParamLong } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getShipmentID = (_: ReduxStateShared, { shipmentID }: ShipmentIDParamLong) => shipmentID;
export const getEssentialsAddress = createSelector(
  [getShipmentAddress, getOrderAddress, getCurrentUserDefaultAddress, getShipmentID, getCurrentUserID],
  (shipmentAddress, orderAddress, defaultAddress, shipmentID, userId) => {
    if (shipmentID) {
      return shipmentAddress;
    }

    if (orderAddress?.user_id === userId) {
      return orderAddress || defaultAddress;
    } else {
      return defaultAddress;
    }
  },
);
