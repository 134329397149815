// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, AltoIcon, Body, H3, LgPadding, LgSpacing, ListItem } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { format, parse } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { type ActionSheetProviderStackCacheKey } from '~shared/features/alto-assistant/constants';
import { getWindows } from '~shared/features/checkout/selectors/getCart';
import { DATE_FORMATS } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { FAQ_KEYS } from '../../../../index';

export const PastCutoff = () => {
  const { navigate, goBack } = useNavigation();
  const { trackEvent } = useAnalytics();
  const windows = useSelectorShared(getWindows);
  const latestCutOffTime = windows[windows.length - 1]?.cutoff_time;
  const { closeActionSheet, saveStack } = useContext(ActionSheetContext);

  useEffect(() => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: { name: ASSISTANT_VIEWED_EVENTS.PAST_CUTOFF_ACTION_SHEET },
    });
  }, [trackEvent]);

  const onPressUseDifferentPharmacy = () => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();

    const onDismiss = () => {
      goBack();
    };
    const onBack = () => {
      goBack();
    };
    navigate('RouteFaq', { faqKey: FAQ_KEYS.TRANSFER_OUT, onDismiss, goBack: onBack });
  };

  return (
    <>
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <H3>Unable to deliver today</H3>
        {latestCutOffTime ? (
          <Body>
            Orders needed to be placed by{' '}
            {format(parse(latestCutOffTime, DATE_FORMATS.DELIVERY_WINDOW_CUTOFF, 0), DATE_FORMATS.LOCALIZED_TIME)} for
            same day delivery
          </Body>
        ) : null}
        <LgSpacing />
        <H3>Options to get it sooner</H3>
      </LgPadding>
      <ListItem
        onPress={onPressUseDifferentPharmacy}
        LeftContent={<AltoIcon name="paperairplane" />}
        title="Use a different pharmacy"
        RightContent={<AltoIcon name="chevronright-small" />}
      />
    </>
  );
};
