import { COLORS } from '@alto/design-library-tokens'; // if we have an animating props explicitly set to false, we're not animating
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';
// and the indicator won't show. So, don't take any height on the screen. We
// use this in the photo id screen.
export type CenteredActivityIndicatorProps = {
  large?: boolean;
  animating?: boolean;
  color?: string;
};

/**
 * @deprecated Use AltoSpinningLoader instead
 */
export const CenteredActivityIndicator = styled(ActivityIndicator).attrs(
  ({ large }: CenteredActivityIndicatorProps) => ({
    size: large ? 'large' : 'small',
  }),
)<CenteredActivityIndicatorProps>`
  flex: ${({ animating }) => (animating ? 0 : 1)};
  ${({ color }) => `color: ${color ?? COLORS.TEXT_COLORS.GREY};`}
  justify-content: center;
  align-items: center;
`;
