import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import React from 'react';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import getPrescriptionPricingByID from '~shared/features/pricing/selectors/getPrescriptionPricingByID';
import { getItemPricingTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import AddToCart from './AddToCart';

export type Props = {
  readonly prescription: Prescription;
  readonly redirectToNative: boolean;
  readonly fullWidthButton?: boolean;
  readonly isProgynyOrderEarlyEligible?: boolean;
};

export const AddToCartContainer = (props: Props) => {
  return <AddToCart {...props} />;
};

type StateProps = {
  fullWidthButton?: boolean;
  redirectToNative: boolean;
};
type OwnProps = {
  fullWidthButton?: boolean;
  isProgynyOrderEarlyEligible?: boolean;
  prescription: Prescription;
};

const mapStateToProps = (state: ReduxStateShared, ownProps: OwnProps): StateProps => {
  const { prescription, fullWidthButton, isProgynyOrderEarlyEligible } = ownProps;
  const prescriptionPricing = getPrescriptionPricingByID(state, prescription.id);
  const redirectToNative =
    isProgynyOrderEarlyEligible ||
    getItemPricingTotal(prescriptionPricing).reason === PRESCRIPTION_PRICING_LABELS.PROGYNY;

  return {
    fullWidthButton,
    redirectToNative,
  };
};

export default connect(mapStateToProps)(AddToCartContainer);
