import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

const ProgressBarOuter = styled(View)`
  height: ${SPACING.STATIC.XXS.px};
  width: 100%;
  background-color: ${COLORS.PALETTE.GREYSCALE.DEFAULT};
`;

const ProgressBarInner = styled(View)<{ percentDecimal: number }>`
  height: ${SPACING.STATIC.XXS.px};
  width: ${({ percentDecimal }) => `${percentDecimal * 100}%`};
  background-color: ${COLORS.PALETTE.SUCCESS.DEFAULT};
  border-top-right-radius: ${({ percentDecimal }) =>
    percentDecimal === 1 ? BORDERS.RADIUS.NONE.px : BORDERS.RADIUS.DEFAULT.px};
  border-bottom-right-radius: ${({ percentDecimal }) =>
    percentDecimal === 1 ? BORDERS.RADIUS.NONE.px : BORDERS.RADIUS.DEFAULT.px};
`;

type ProgressBarProps = {
  readonly currentStep: number;
  readonly totalSteps: number;
};

export const ProgressBar = ({ currentStep, totalSteps }: ProgressBarProps) => {
  const percentDecimal = Math.max(Math.min(currentStep / totalSteps, 1), 0);
  return (
    <ProgressBarOuter>
      <ProgressBarInner percentDecimal={percentDecimal} />
    </ProgressBarOuter>
  );
};
