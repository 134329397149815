// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { type FertilityCycle, type ReduxStateShared } from '~shared/types';

// hack because we cannot reference the native redux state via shared, yet analytics needs these selectors
type NativeState = any;

export const getProgynyWizardCycle = (state: ReduxStateShared): FertilityCycle | null | undefined =>
  state.ui.progynyWizard?.fertilityCycle;

export const getInProgynyWizard: (state: ReduxStateShared) => boolean = createSelector(
  [getProgynyWizardCycle],
  (cycle: FertilityCycle | null | undefined) => {
    return !!cycle;
  },
);

const getProgynyModifiedInCheckout = (state: NativeState): boolean => state.ui?.progynyWizard?.hasChangedDeliveryMethod;

export const getIsUnmodifiedProgynyWizardFlow: (state: ReduxStateShared) => boolean = createSelector(
  [getInProgynyWizard, getProgynyModifiedInCheckout],
  (inProgynyWizard, modifiedInCheckout) => {
    return inProgynyWizard && !modifiedInCheckout;
  },
);

export const getProgynyWizardFirstFill = (state: NativeState): boolean | null | undefined =>
  state.ui.progynyWizard?.firstFill;

export const getProgynyWizardUpdateAttributes = (state: ReduxStateShared): Record<string, any> =>
  state.ui.progynyWizard?.updateAttributes;
