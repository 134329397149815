import {
  type AltoIconName,
  type AltoIconNameWithColors,
  type ColorVariant,
  altoIconToUrlMap,
} from '@alto/design-library-tokens';
import * as React from 'react';
import { Image } from 'react-native';
import { INTERNAL_IconWrapper } from './INTERNAL_IconWrapper';
import { calculateIconPxSize } from './utils';

export type AltoIconProps = {
  readonly name: AltoIconName;
  readonly accessibilityLabel?: string;
  readonly allowFontScaling?: boolean;
  readonly type?: ColorVariant;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
};

export const AltoIcon = ({ name, accessibilityLabel, type, allowFontScaling = true, testID }: AltoIconProps) => {
  const sizeType = name.endsWith('-small') ? 'small' : 'regular';
  const sizePx = calculateIconPxSize(sizeType, allowFontScaling);
  const iconName: AltoIconNameWithColors = type ? `${name}-${type}` : name;
  const uri = altoIconToUrlMap[iconName];

  return (
    <INTERNAL_IconWrapper
      allowFontScaling={allowFontScaling}
      size={sizeType}
      testID={testID}
      accessibilityLabel={accessibilityLabel}
    >
      <Image source={{ uri, width: sizePx, height: sizePx }} />
    </INTERNAL_IconWrapper>
  );
};
