import getAppOpened from './events/getAppOpened';
import getErrorEvent from './events/getErrorEvent';
import getOrigin from './getOrigin';

const MAPPERS = [getAppOpened, getErrorEvent];

// Try to set a sensible origin prop, if one is not passed in to the analytics event
// @ts-expect-error TS(7006): Parameter 'event' implicitly has an 'any' type.
export const setDefaultOrigin = (event) => {
  event.properties = {
    origin: getOrigin(),
    ...event.properties,
  };

  if ([undefined, null, 'unknown'].includes(event.properties.origin)) {
    // Don't send vague origin.
    // Note: We explicitly check `undefined` because `event.properties.origin` can itself have the value undefined
    delete event.properties.origin;
  }
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/require-await
export default async function getAnalyticsEvents(action, state, nextState) {
  // @ts-expect-error TS(7034): Variable 'events' implicitly has type 'any[]' in some locations where its type cannot be determined.
  const events = [];

  MAPPERS.forEach((mapper) => {
    // @ts-expect-error the last parameter "nextState" is dropped when it is not needed
    const event = mapper(action, state, nextState);

    if (event) {
      // Allows for array of multiple events to be sent
      if (Array.isArray(event)) {
        event.forEach((e) => {
          setDefaultOrigin(e);
          events.push(e);
        });
      } else {
        setDefaultOrigin(event);
        events.push(event);
      }
    }
  });

  // @ts-expect-error TS(7005): Variable 'events' implicitly has an 'any[]' type.
  return events;
}
