// @owners { team: patients-team }
import { add, format, getDay, isAfter, nextDay } from 'date-fns';
import { uniq } from 'lodash';
import { type ExcludedDate } from '~shared/types';

// Find all dates in a date range that fall on a given day of the week, returned as an array of date strings formatted YYYY-MM-DD
const allDaysOnWeekdayInRange = (dayOfWeek: string, start: Date, end: Date) => {
  const dayIndex = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(dayOfWeek);
  if (dayIndex === -1) return [];

  // figure out the first week day after the start date (e.g. the first tuesday after the start date if dayOfWeek = 'tuesday')
  let date = getDay(start) === dayIndex ? start : nextDay(start, dayIndex as Day);

  // go week-by-week building an array of dates until we hit the end date
  const dates: string[] = [];
  while (!isAfter(date, end)) {
    dates.push(format(date, 'yyyy-MM-dd'));
    date = add(date, { weeks: 1 });
  }

  return dates;
};

// format excludedDates for date picker
export const getDisabledDates = (excludedDates: ExcludedDate[]) => {
  const dates: string[] = [];
  excludedDates.forEach(({ date, filter }) => {
    if (date) {
      dates.push(date);
    } else {
      // If there is no date the filter value is probably a day of the week, such as 'monday', which we need to convert into a list of dates.
      // It would be preferable to pass disabledDayIndexes to the date picker, but this is currently broken in react-native-calendars (https://github.com/wix/react-native-calendars/issues/2030)
      dates.push(...allDaysOnWeekdayInRange(filter, new Date(), add(new Date(), { years: 1 })));
    }
  });
  return uniq(dates);
};
