// @owners { team: patients-team }
import React, { PureComponent } from 'react';
import { getUserTagText } from '~shared/features/family-accounts/selectors/getUserTagText';
import { getHasFamily } from '~shared/features/users/selectors/getHasFamily';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import UserTag from './UserTag';

type Props = {
  readonly hasFamily: boolean;
  readonly text: string;
};

class FamilyAccountUserTagContainer extends PureComponent<Props> {
  render() {
    const { hasFamily, ...props } = this.props;

    if (!hasFamily) {
      return null;
    }

    return <UserTag {...props} />;
  }
}

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
function mapStateToProps(state: ReduxStateShared, ownProps) {
  const { userIDs } = ownProps;
  const hasFamily = getHasFamily(state);

  return {
    hasFamily,
    text: getUserTagText(state, {
      userIDs,
    }),
  };
}

export default connect(mapStateToProps)(FamilyAccountUserTagContainer);
