import { includesAsapDeliveryFee } from '~shared/features/shipment-fees/helpers/includesAsapDeliveryFee';
import { type Shipment } from '~shared/types';

export const UNEDITABLE_STATUSES = new Set([
  'delivered',
  'shipped',
  'unscheduled',
  'packaged',
  'failed',
  'awaiting_packing',
  'redo',
  'returned',
]);

/**
 * Check if shipment is editable
 * @param status
 * @param shipment_fees
 * @param is_pickup
 */
export const isShipmentEditable = ({
  status,
  shipment_fees,
  is_pickup,
}: Pick<Shipment, 'status' | 'shipment_fees' | 'is_pickup'>): boolean => {
  const isAsapOrder = includesAsapDeliveryFee(shipment_fees);
  if (isAsapOrder || !status || is_pickup) return false;

  return isShipmentInEditableStatus(status);
};

/**
 * Check if shipment is in editable status
 * @param status
 */
export const isShipmentInEditableStatus = (status: Shipment['status']): boolean => {
  if (!status) return false;
  return !UNEDITABLE_STATUSES.has(status);
};
