// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import {
  type CreateShipmentTipRequest,
  type UpdateShipmentTipRequest,
} from '@alto/scriptdash/alto/patients/v3/courier_tips/wunderbar/v1/shipment_tips_endpoint';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPricing } from '~shared/actions/pricing';
import { DEFAULT_ERROR_ALERT_MESSAGE, DEFAULT_ERROR_ALERT_TITLE } from '~shared/constants';
import { type AnalyticsProps, SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
// eslint-disable-next-line import/no-deprecated
import { createShipmentTip } from '~shared/features/courier-tips/actions';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
// eslint-disable-next-line import/no-deprecated
import { updateShipmentTip } from '~shared/features/courier-tips/actions/updateShipmentTip';
import { COURIER_TIP_COPY } from '~shared/features/courier-tips/constants';
import { getShipmentTipForShipmentID } from '~shared/features/courier-tips/selectors';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { formatDollars } from '~shared/helpers/currency';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import EditOrAddTipModal, { type Props } from './EditOrAddTipModal';

type OwnProps = {
  onClose: () => void;
  routerParams: Record<string, any>;
};

type StateProps = {
  currentUserID: number | null | undefined;
  shipmentID: number;
  shipmentTip: ShipmentTip | null | undefined;
  analyticsProps: AnalyticsProps;
};

const mapStateToProps = (state: ReduxStateShared, ownProps: OwnProps): StateProps => {
  const shipmentID = ownProps.routerParams?.orderID;
  const address = getShipmentAddress(state, { shipmentID });

  return {
    currentUserID: getCurrentUserID(state),
    shipmentID,
    shipmentTip: getShipmentTipForShipmentID(state, {
      shipmentID,
    }),
    analyticsProps: {
      shipment_id: shipmentID,
      facility_id: address?.dispensing_facility_id,
      source: SOURCES.EDIT_DELIVERY,
    },
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => ({
  // eslint-disable-next-line import/no-deprecated
  createShipmentTip: (params: CreateShipmentTipRequest) => dispatch(createShipmentTip(params)),
  // eslint-disable-next-line import/no-deprecated
  updateShipmentTip: (params: UpdateShipmentTipRequest) => dispatch(updateShipmentTip(params)),
  sendTipAnalyticsEvent,
  // eslint-disable-next-line import/no-deprecated
  refreshShipmentPricing: (shipmentID: number) => dispatch(fetchShipmentPricing(shipmentID)),
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: OwnProps,
): Props => {
  const { currentUserID, shipmentID, shipmentTip, analyticsProps } = stateProps;

  const {
    createShipmentTip: dispatchCreateShipmentTip,
    updateShipmentTip: dispatchUpdateShipmentTip,
    sendTipAnalyticsEvent,
    refreshShipmentPricing,
  } = dispatchProps;

  // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
  const handleSuccess = (data) => {
    sendTipAnalyticsEvent({
      eventKey: EVENTS.COURIER_TIPS__TIP_UPDATED,
      analyticsProps,
      additionalProps: {
        tip_value: formatDollars(data.amount, false),
        tip_payment_method_id: data.payment_method_id,
      },
    });
    toaster.toast({
      kind: 'success',
      title: COURIER_TIP_COPY.TOAST_SUCCESSFUL_UPDATE,
    });
    ownProps.onClose();
  };

  const handleError = () => {
    toaster.toast({
      kind: 'danger',
      title: DEFAULT_ERROR_ALERT_TITLE,
      description: DEFAULT_ERROR_ALERT_MESSAGE,
    });
  };

  const refreshPricing = () => refreshShipmentPricing(shipmentID);

  // @ts-expect-error TS(7006): Parameter 'tipAmount' implicitly has an 'any' type.
  const handleUpdateTip = (tipAmount, tipPaymentMethodID, validatedShipmentTip) => {
    const updatedShipmentTip: ShipmentTip = {
      ...validatedShipmentTip,
      payment_method_id: tipPaymentMethodID,
      amount: tipAmount,
    };
    const request: UpdateShipmentTipRequest = {
      id: validatedShipmentTip.id,
      shipment_tip: updatedShipmentTip,
    };

    // eslint-disable-next-line promise/catch-or-return
    dispatchUpdateShipmentTip(request).then(handleSuccess, handleError).then(refreshPricing);
  };

  // @ts-expect-error TS(7006): Parameter 'tipAmount' implicitly has an 'any' type.
  const handleCreateTip = (tipAmount, tipPaymentMethodID, validatedCurrentUserID) => {
    const request: CreateShipmentTipRequest = {
      user_id: validatedCurrentUserID,
      shipment_id: shipmentID,
      payment_method_id: tipPaymentMethodID,
      amount: tipAmount,
    };

    // eslint-disable-next-line promise/catch-or-return
    dispatchCreateShipmentTip(request).then(handleSuccess, handleError).then(refreshPricing);
  };

  return {
    ...ownProps,
    shipmentID,
    shipmentTip,
    onSubmit: (tipAmount, tipPaymentMethodID) => {
      if (shipmentTip) {
        handleUpdateTip(tipAmount, tipPaymentMethodID, shipmentTip);
      } else if (currentUserID) {
        handleCreateTip(tipAmount, tipPaymentMethodID, currentUserID);
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditOrAddTipModal);
