// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, LgPadding } from '@alto/design-system';
import React from 'react';
import { HOLIDAY_CLOSURE_INFO } from '~shared/features/alto-assistant/constants';
import { FaqTextContent } from '~web/features/alto-assistant/components/action-sheets/FaqTextContent';

export const HolidayClosureActionSheet = () => {
  return (
    <ActionSheetV2
      title="Holiday Closure"
      analyticsName="holiday closure"
    >
      {HOLIDAY_CLOSURE_INFO.map((faqElement) => {
        return (
          <LgPadding
            key={`faq_answer_${faqElement.title}`}
            topPadding={SPACING.STATIC.NONE}
          >
            <FaqTextContent
              title={faqElement.title}
              body={faqElement.answer}
            />
          </LgPadding>
        );
      })}
    </ActionSheetV2>
  );
};
