import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import { Row } from '../../../containers';
import { XsSpacing } from '../../../separators';
import { Description, Text } from '../../../typography';
import { getLabelColor } from '../InputsUtils';

type Props = {
  readonly label: string | null | undefined;
  readonly labelID?: string | undefined;
  readonly sublabel?: string;
  readonly required?: boolean;
  readonly optional?: boolean;
  readonly disabled?: boolean;
  readonly error?: string;
};

export const InputLabelRow = ({ label, labelID, sublabel, required, optional, disabled, error }: Props) => {
  return (
    <View
      accessibilityRole="text"
      nativeID={labelID}
    >
      <Row centerVertically>
        <Description
          fontFamily="semibold"
          color={getLabelColor(disabled, error)}
        >
          {label}
        </Description>
        {!!sublabel && (
          <>
            <XsSpacing />
            <Text
              textSize="detail"
              color={COLORS.TEXT_COLORS.GREY}
            >
              {sublabel}
            </Text>
          </>
        )}
        {!!required && (
          <Description
            fontFamily="semibold"
            color={COLORS.TEXT_COLORS.DANGER}
          >
            {' '}
            *
          </Description>
        )}
        {!!optional && !required && (
          <>
            <XsSpacing />
            <Text
              textSize="detail"
              color={COLORS.TEXT_COLORS.GREY}
            >
              Optional
            </Text>
          </>
        )}
      </Row>
      <XsSpacing />
    </View>
  );
};
