// @owners { team: patients-team }
import { Body, useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, H1, InputTextArea, RadioGroup, RadioOption, palette, spacing } from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { put } from '~shared/helpers/apiHelper';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS, USER_IDENTIFIERS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import logoAlto from '~web/images/altoLogo@2x.png';

const Navbar = styled.div`
  padding: ${spacing.smSpacing};
  margin-bottom: ${spacing.xxsSpacing};
  background: ${palette.altoColorsW};
  box-shadow:
    0 0 1px 0 rgba(195, 201, 207, 0.1),
    0 1px 5px 0 rgba(195, 201, 207, 0.3);
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.a`
  width: 115px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const TitleText = styled(H1)`
  color: ${palette.navy};
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem !important;
`;

const ThankYouText = styledNative(Body)`
  color: ${palette.navy};
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem !important;
`;

const RadioText = styledNative(Body)`
  color: ${palette.navy};
`;

const SurveyComment = styled(InputTextArea)`
  border-radius: 16px;
  background-color: #f6f9fc;
`;

const SubmitButton = styled(Button)<{ isSMScreenOrBigger: boolean; fullWidth: boolean }>`
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `align-self: end;max-width: 250px;`}
`;

type ScoreSelectProps = {
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly value: string;
};

const ScoreSelect = ({ onChange, value }: ScoreSelectProps) => {
  return (
    <RadioGroup
      input={{
        name: 'ScoreSelect',
        onChange,
        value,
      }}
    >
      <RadioOption value="5">
        <RadioText fontFamily="bold">😍 &nbsp; Amazing</RadioText>
      </RadioOption>
      <br />
      <RadioOption value="4">
        <RadioText fontFamily="bold">👍 &nbsp; Pretty good</RadioText>
      </RadioOption>
      <br />
      <RadioOption value="3">
        <RadioText fontFamily="bold">😐 &nbsp;Average</RadioText>
      </RadioOption>
      <br />
      <RadioOption value="2">
        <RadioText fontFamily="bold">👎 &nbsp; Not great</RadioText>
      </RadioOption>
      <br />
      <RadioOption value="1">
        <RadioText fontFamily="bold">💔 &nbsp; Very poor</RadioText>
      </RadioOption>
    </RadioGroup>
  );
};

type CsatFormValues = {
  score: string;
  comment: string;
};

type CsatSurveyPageProps = {
  readonly params: {
    hashID: string;
  };
};

export const CsatSurveyPage = ({ params: { hashID } }: CsatSurveyPageProps) => {
  const dispatch = useDispatchShared();
  const { isSMScreenOrBigger } = useScreenSize();

  useEffect(() => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(
          EVENTS.CSAT_FORM__VIEWED,
          {},
          {
            userIdentifier: hashID,
            userIdentifierType: USER_IDENTIFIERS.REVIEW_SCORE_HASH,
          },
        ),
      ),
    );
  }, [dispatch, hashID]);

  const [surveyCompleted, setSurveyCompleted] = useState<boolean>(false);
  const initialCsatFormValues: CsatFormValues = { score: '0', comment: '' };
  const submitCsatSurvey = async (values: CsatFormValues) => {
    await put(`/review_scores/${hashID}`, {
      id: hashID,
      score: values.score,
      comment: values.comment,
    });
    setSurveyCompleted(true);

    dispatch(
      sendAnalyticsEvent(
        createEvent(
          EVENTS.CSAT_FORM__SUBMITTED,
          {},
          {
            userIdentifier: hashID,
            userIdentifierType: USER_IDENTIFIERS.REVIEW_SCORE_HASH,
          },
        ),
      ),
    );
  };

  return (
    <>
      <Navbar>
        <LogoContainer
          href="https://alto.com"
          aria-label="Alto Logo"
        >
          <Image src={logoAlto} />
        </LogoContainer>
      </Navbar>
      <PageBackground
        fullWidth
        white
      >
        {surveyCompleted ? (
          <SingleColumnContentWrapper>
            <TitleText>Thank you!</TitleText>
            <br />
            <ThankYouText>We really appreciate your feedback and for letting us take care of you.&nbsp;❤️</ThankYouText>
          </SingleColumnContentWrapper>
        ) : (
          <Formik
            initialValues={initialCsatFormValues}
            onSubmit={submitCsatSurvey}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <SingleColumnContentWrapper>
                    <TitleText>How was your call experience?</TitleText>
                    <ScoreSelect
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('score', event.currentTarget.value);
                      }}
                      value={values.score}
                    />
                    <SurveyComment
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('comment', event.currentTarget.value);
                      }}
                      placeholder="Share your thoughts..."
                    />
                    {/* @ts-expect-error: Property 'onClick' is missing in type */}
                    <SubmitButton
                      isSMScreenOrBigger={isSMScreenOrBigger}
                      type="submit"
                      fullWidth
                      disabled={values.score === '0'}
                    >
                      Submit
                    </SubmitButton>
                  </SingleColumnContentWrapper>
                </Form>
              );
            }}
          </Formik>
        )}
      </PageBackground>
    </>
  );
};
