// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { getOrderAddress } from '~shared/features/checkout/selectors/getOrder';

export const getNextAvailableDateAddressID = createSelector(
  [getOrderAddress, getDefaultAddress],
  (orderAddress, defaultAddress) => {
    return orderAddress?.id || defaultAddress?.id;
  },
);

export const getNextAvailableDateAddress = createSelector(
  [getOrderAddress, getDefaultAddress],
  (orderAddress, defaultAddress) => {
    return orderAddress || defaultAddress;
  },
);
