// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { createSelector } from 'reselect';
import { getEditShipmentID } from './getCart';
import { getShipmentsWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { isCourier } from '~shared/helpers/order';
import { type ReduxStateShared } from '~shared/types';

type GetIsTippableForShipmentIDParams = {
  shipmentID?: number | null;
  hasGovernmentInsurance: boolean;
  deliveryMethod: DeliveryMethod | null | undefined;
};

const getShipmentID = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) => params.shipmentID;
const getHasGovernmentInsurance = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) =>
  params.hasGovernmentInsurance;
const getDeliveryMethod = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) => params.deliveryMethod;

// Determines whether or not a specific shipment is tippable or not.
// Takes a provided shipmentID, if one does not exist it will try to get the items
// for the editShipmentID (only works during checkout flow).
// A shipment is tippable if the courier_tips GK is on and one of the delivery items
// has a courier delivery method.
export const getIsTippableForShipmentID = createSelector(
  [getShipmentID, getHasGovernmentInsurance, getDeliveryMethod, getEditShipmentID, getShipmentsWithItemsByID],
  (shipmentID, hasGovernmentInsurance, deliveryMethod, editShipmentID, shipmentsWithItemsByID) => {
    let shipment: ShipmentWithItems | null = null;

    if (shipmentID) {
      shipment = shipmentsWithItemsByID[shipmentID];
    } else if (editShipmentID) {
      shipment = shipmentsWithItemsByID[Number(editShipmentID)];
    }

    return !hasGovernmentInsurance && !!shipment?.courier_tips_available && isCourier(deliveryMethod?.mode);
  },
);
