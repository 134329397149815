// @owners { team: patients-team }
import { Button } from '@alto/design-system';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

type CancelButtonProps = {
  readonly openCancelDeliveryModal: () => void;
  readonly shipmentID?: number;
};

export const CancelButton = ({ openCancelDeliveryModal, shipmentID }: CancelButtonProps) => {
  const { trackEvent } = useAnalytics();

  const onPress = () => {
    openCancelDeliveryModal();
    trackEvent({ event: EVENTS.DELIVERY_DETAILS_CANCEL_BUTTON_CLICKED, additionalFields: { shipmentId: shipmentID } });
  };

  return (
    <Button
      accessibilityLabel="Cancel Order Button"
      onPress={onPress}
      small
      testID="cancel-order-button"
      type="secondary"
      variant="danger"
      width="inline"
    >
      Cancel
    </Button>
  );
};
