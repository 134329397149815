// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  BulletedList,
  BulletedListItem,
  Button,
  LgPadding,
  LgSpacing,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React, { useContext } from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { ASSISTANT_CHAT_ORIGINS } from '~shared/features/alto-assistant/constants';
import { MESSAGE_RESPONSE_TIME } from '~shared/features/messages/constants';
import { getUserSupportPhone } from '~shared/features/users/selectors/getUserSupportPhone';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ReactNativeLocalize } from '~shared/lib/react-native-localize';
import { useSelectorShared } from '~shared/store';
import { HelpTopicsActionSheet } from '../wizard/HelpTopicsActionSheet';
import { ContactUs } from './ContactUs';
import { getTimezoneRelevantCopy } from './utils';

type ContactUsActionSheetProps = {
  readonly onPressNewRequest?: () => void;
};

export const ContactUsActionSheet = ({ onPressNewRequest }: ContactUsActionSheetProps) => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const currentUserID = useSelectorShared(getCurrentUserID);

  const phoneNumber = useSelectorShared((state) => getUserSupportPhone(state, { userID: currentUserID }));
  // Intl crashes on Android, so we use react-native-localize to get time zone from the device settings
  const timezoneLocal = ReactNativeLocalize.getTimeZone();
  const { weekendCopy, weekdayCopy } = getTimezoneRelevantCopy(timezoneLocal);
  const { value: isContactUsExperimentEnabled } = Experimentation.useFeatureFlag('contact_us_action_sheet');

  const handlePressSendMessage = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.SUPPORT_CASE_NEW_REQUEST,
        origin: ASSISTANT_CHAT_ORIGINS.SUPPORT_CASE_INBOX,
      },
    });

    if (onPressNewRequest) {
      // NOTE: Needed until we move away from HowCanWeHelpActionSheet
      onPressNewRequest();
    } else {
      setActiveActionSheet(<HelpTopicsActionSheet />);
    }
  };

  return (
    <ActionSheetV2 title="Contact Alto">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {isContactUsExperimentEnabled ? (
          <Body>
            Messaging is the best way to reach our Care Team - we typically respond within {MESSAGE_RESPONSE_TIME}{' '}
            during business hours.
          </Body>
        ) : (
          <>
            <Body fontFamily="semibold">Phone</Body>
            <ContactUs
              customLabel={phoneNumber}
              type="link"
              fontFamily="regular"
              method="phone"
              origin={CONTACT_US_ORIGIN.ContactUsActionSheet}
            />
          </>
        )}
        <SmSpacing />
        <Body fontFamily="semibold">Support hours</Body>
        <XsSpacing />
        <BulletedList>
          <BulletedListItem>{weekdayCopy}</BulletedListItem>
          <BulletedListItem>{weekendCopy}</BulletedListItem>
        </BulletedList>
        <LgSpacing />
        {isContactUsExperimentEnabled ? (
          <>
            <Button onPress={handlePressSendMessage}>Send us a message</Button>
            <SmSpacing />
            <ContactUs
              customLabel={`Call ${phoneNumber}`}
              type="button"
              buttonType="tertiary"
              fontFamily="regular"
              method="phone"
              origin={CONTACT_US_ORIGIN.ContactUsActionSheet}
            />
          </>
        ) : (
          <Button
            label="Send us a message"
            onPress={handlePressSendMessage}
          />
        )}
      </LgPadding>
    </ActionSheetV2>
  );
};
