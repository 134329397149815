// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDeliveries } from './getDeliveries';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type LightDelivery, type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, { prescriptionID }: PrescriptionIDParam) => prescriptionID;

export type DeliveriesByPrescriptionID = Record<number, LightDelivery[]>;

export const getDeliveriesByPrescriptionID = createSelector([getDeliveries], (deliveries) => {
  const deliveriesByPrescription: DeliveriesByPrescriptionID = {};

  deliveries.forEach((delivery) => {
    if (!delivery.prescription_id) {
      return;
    }

    if (!deliveriesByPrescription[delivery.prescription_id]) {
      deliveriesByPrescription[delivery.prescription_id] = [];
    }
    deliveriesByPrescription[delivery.prescription_id].push(delivery);
  });

  return deliveriesByPrescription;
});

export const getDeliveriesForPrescriptionID = createSelector(
  [getPrescriptionID, getDeliveriesByPrescriptionID],
  (prescriptionID: number, deliveriesByPrescriptionID: DeliveriesByPrescriptionID): LightDelivery[] => {
    return deliveriesByPrescriptionID[prescriptionID] || [];
  },
);
