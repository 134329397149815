// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  AltoIcon,
  Body,
  Description,
  InlineAlert,
  LgPadding,
  ListDescription,
  ListItem,
  MdSpacing,
  XsSpacing,
} from '@alto/design-system';
import React from 'react';

export const FamilyMemberConsentAlert = () => {
  return (
    <InlineAlert type="recommended">
      <Description fontFamily="semibold">Adding adult family members</Description>
      <XsSpacing />
      <Description>
        Anyone 18 years or older will need to provide oral or written authorization to be added to your account.
        Complete the request form and our care team will follow up to assist you.
      </Description>
    </InlineAlert>
  );
};

export const FamilyAccountActionSheet = () => {
  return (
    <ActionSheetV2 title="How family accounts work">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>When you add a family member to your account:</Body>
        <MdSpacing />
        <ListItem
          fullBleed
          LeftContent={
            <AltoIcon
              name="pillbottle"
              type="grey"
            />
          }
          descriptions={
            <ListDescription>
              <Body>You can manage their medications, including scheduling orders and requesting refills</Body>
            </ListDescription>
          }
        />
        <MdSpacing />
        <ListItem
          fullBleed
          LeftContent={
            <AltoIcon
              name="shield"
              type="grey"
            />
          }
          descriptions={
            <ListDescription>
              <Body>You can update insurance on their behalf</Body>
            </ListDescription>
          }
        />
        <MdSpacing />
        <ListItem
          fullBleed
          LeftContent={
            <AltoIcon
              name="message"
              type="grey"
            />
          }
          descriptions={
            <ListDescription>
              <Body>You’ll receive all messages regarding their prescriptions</Body>
            </ListDescription>
          }
        />
        <MdSpacing />
        <FamilyMemberConsentAlert />
      </LgPadding>
    </ActionSheetV2>
  );
};
