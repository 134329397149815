import { type CheckoutFlowType } from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';

export const getEnterCartTypes = (state: ReduxStateShared): CheckoutFlowType[] => state.ui.checkoutFlow.enterCartTypes;

type EnterCartTypeHash = Partial<Record<CheckoutFlowType, boolean>>;
export const getEnterCartTypeHash = createSelector([getEnterCartTypes], (enterCartTypes): EnterCartTypeHash => {
  return enterCartTypes.reduce<EnterCartTypeHash>((hash, type) => {
    hash[type] = true;
    return hash;
  }, {});
});

export const getEnterCartData = (state: ReduxStateShared) => state.ui.checkoutFlow.enterCartData;
export const getIsOrderBundling = (state: ReduxStateShared) => state.ui.checkoutFlow.isOrderBundling;
