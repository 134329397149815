import { PALETTE } from './palette';

export const TEXT_COLORS = {
  PRIMARY: PALETTE.PRIMARY.DEFAULT,
  SECONDARY: PALETTE.SECONDARY.DEFAULT,
  SECONDARY_DARK: PALETTE.SECONDARY.DARK,
  SUCCESS: PALETTE.SUCCESS.DARK,
  WARNING: PALETTE.WARNING.DARKER,
  DANGER: PALETTE.DANGER.DARK,
  BLACK: PALETTE.GREYSCALE.BLACK,
  GREY: PALETTE.GREYSCALE.DARKEST,
  DISABLED: PALETTE.GREYSCALE.DARKER,
  WHITE: PALETTE.GREYSCALE.WHITE,
} as const;
