import { SPACING } from '@alto/design-library-tokens';
import React, { type ReactNode } from 'react';
import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';
import { Border } from '../../borders';
import { Pressable, PressableAltoIcon } from '../../buttons';
import { MdPadding, Row } from '../../containers';
import { Body } from '../../typography';
import { getColors } from './AlertUtils';

type Variant = 'error' | 'success' | 'neutral';

type ToastBodyProps = {
  readonly onClose: () => void;
  readonly variant: Variant;
  readonly children: ReactNode;
  readonly dismissible?: boolean;
  readonly actionLabel?: string;
  readonly actionOnPress?: () => void;
};

const StyledRow = styled(Row)`
  width: ${Platform.OS === 'web' ? 'initial' : `${Dimensions.get('screen').width - SPACING.STATIC.XL.value}px`};
  max-width: ${Platform.OS === 'web' ? '600px' : 'initial'};
`;

const StyledMdPadding =
  Platform.OS === 'web'
    ? styled(MdPadding)`
        flex-shrink: 1;
        flex-flow: row-wrap;
      `
    : MdPadding;

export const ToastBody = ({ onClose, variant, children, dismissible, actionLabel, actionOnPress }: ToastBodyProps) => {
  const color = getColors(variant);
  return (
    <Border
      color={color.borderColor}
      radius="MD"
    >
      <StyledRow
        wrap={false}
        center
        spaceBetween
        backgroundColor={color.background}
      >
        <Row flexShrink={1}>
          <StyledMdPadding flexGrow={1}>
            <Body
              autofocus
              color={color.text}
            >
              {children}
            </Body>
          </StyledMdPadding>
        </Row>
        <Row
          center
          flexShrink={1}
        >
          {actionLabel && !!actionOnPress ? (
            <Pressable
              onPress={actionOnPress}
              backgroundColor={color.background}
            >
              <MdPadding leftPadding={SPACING.STATIC.NONE}>
                <Body
                  fontFamily="bold"
                  color={color.text}
                  textTransform="capitalize"
                >
                  {actionLabel}
                </Body>
              </MdPadding>
            </Pressable>
          ) : null}
          {dismissible ? (
            <MdPadding leftPadding={SPACING.STATIC.NONE}>
              <PressableAltoIcon
                name="close"
                onPress={onClose}
                accessibilityLabel="close toast"
                type="white"
              />
            </MdPadding>
          ) : null}
        </Row>
      </StyledRow>
    </Border>
  );
};
