import { SIZES, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { type StyleProp, View, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { useScreenSize } from '../../../utils';

export const StyledContent = styled(View)<{ isMDScreenOrBigger: boolean }>`
  z-index: 1;
  margin-top: -${SPACING.STATIC.XL.px};
  flex-grow: 1;
  flex-shrink: 1;
  align-self: center;
  width: ${SIZES.PAGE.CONTENT_WIDTH.SM};

  ${({ isMDScreenOrBigger }) =>
    isMDScreenOrBigger &&
    `
        width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
        max-width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
  `}
`;

type PageOffsetContentSectionProps = {
  readonly children: React.ReactNode;
  readonly style?: StyleProp<ViewStyle>;
};

export const PageOffsetContentSection = ({ children, style }: PageOffsetContentSectionProps) => {
  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <StyledContent
      style={style}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      {children}
    </StyledContent>
  );
};
