// @owners { team: patients-team }
export const UPDATE_TIP_PAYMENT_METHOD_ID = 'UPDATE_TIP_PAYMENT_METHOD_ID';
export type UpdateTipPaymentMethodIDAction = {
  type: typeof UPDATE_TIP_PAYMENT_METHOD_ID;
  payload: number;
};
export const updateTipPaymentMethodID = (tipPaymentMethodID: number | null | undefined) => ({
  type: UPDATE_TIP_PAYMENT_METHOD_ID,
  payload: tipPaymentMethodID,
});
