// @owners { team: patients-team }
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { getMedicationsFromPrescriptions as getMedications } from './getMedicationsFromPrescriptions';
import compareFamilyMembers from '~shared/features/family-accounts/helpers/compareFamilyMembers';
import {
  prescriptionIsActive,
  prescriptionIsEligibleForRenewal,
  shouldShowReadyToRefillIndicator,
  sortByActiveState,
} from '~shared/features/my-meds/helper';
import { type Medication, type PartitionedMedications } from '~shared/features/my-meds/types';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getUsers } from '~shared/features/users/selectors/getUsers';
import { type Prescription, type User } from '~shared/types';

const sortByMedicationName = (medication: Medication) => {
  return medication.medicationName.toUpperCase();
};

const sortByRxNumber = (medication: Medication) => {
  return medication.rxNumber;
};

const sortByPrescriptionWrittenDate = (medication: Medication) => {
  // We return the prescriptions in order by date_written, so we're accessing
  // the first here to avoid performing a sort on each of the
  return Date.parse(medication.prescriptions[0].date_written || '');
};

const sortByUser = (users: User[], currentUser?: User | null) => {
  return (medication1: Medication, medication2: Medication) => {
    const user1 = users.find((u) => u.id === medication1.userID);
    const user2 = users.find((u) => u.id === medication2.userID);

    return compareFamilyMembers(user1, user2, currentUser);
  };
};

const getSortOrderForMedication = (medication: Medication) => {
  return sortByActiveState(medication.representativePrescription);
};

const isMedicationActive = (medication: Medication) => {
  return medication?.prescriptions?.some?.(prescriptionIsActive) ?? false;
};

const isPrescriptionDueForRenewal = (prescription: Prescription) => {
  return prescriptionIsEligibleForRenewal(prescription);
};

const isPrescriptionDueForRefill = (prescription: Prescription, medicationIsActive: boolean) => {
  return medicationIsActive && shouldShowReadyToRefillIndicator(prescription.next_refill_date, true);
};

const prescriptionNeedsAttention = (prescription: Prescription, medicationIsActive: boolean) => {
  return isPrescriptionDueForRenewal(prescription) || isPrescriptionDueForRefill(prescription, medicationIsActive);
};

/**
 * Returns medications partitioned by:
 *   Needs Attention - last dispensed rx is out of refills, or an active rx is due for a refill
 *   Active - at least one rx is active (and any out of refills are archived)
 *   Inactive - no active prescriptions (and any out of refills are archived)
 *   Add on OTCs - additional over the counter medications
 */
export const partitionMedicationsByActiveState = createSelector(
  [getMedications, getUsers, getCurrentUser],
  (medications, users, currentUser) => {
    const result: PartitionedMedications = {
      needsAttention: [],
      active: [],
      inactive: [],
      addOnOtcs: [],
    };

    for (const medication of medications) {
      const medicationIsActive = isMedicationActive(medication);

      if (medication.representativePrescription.is_add_on_otc) {
        result.addOnOtcs?.push(medication);
      } else if (prescriptionNeedsAttention(medication.representativePrescription, medicationIsActive)) {
        result.needsAttention.push(medication);
      } else if (medicationIsActive) {
        result.active.push(medication);
      } else {
        result.inactive.push(medication);
      }
    }

    result.active = sortBy(result.active, [
      sortByPrescriptionWrittenDate,
      sortByMedicationName,
      sortByRxNumber,
    ]).reverse();

    result.inactive = sortBy(result.inactive, [getSortOrderForMedication, sortByMedicationName, sortByRxNumber]);

    result.needsAttention = sortBy(result.needsAttention, [sortByMedicationName, sortByRxNumber]).sort(
      sortByUser(users, currentUser),
    );

    return result;
  },
);
