// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { type ReduxStateShared } from '~shared/types';

type RemovingWillAddFeeProps = {
  deliveryID: number;
  minimumThresholdToWaiveFee: number;
};

const getDeliveryID = (_: ReduxStateShared, { deliveryID }: RemovingWillAddFeeProps) => deliveryID;
const getMinimumThresholdToWaiveFee = (_: ReduxStateShared, { minimumThresholdToWaiveFee }: RemovingWillAddFeeProps) =>
  minimumThresholdToWaiveFee;

export const getRemovingDeliveryWillAddDeliveryFee = createSelector(
  [getDeliveriesForShipmentID, getShipmentPricing, getShipmentWithID, getDeliveryID, getMinimumThresholdToWaiveFee],
  (deliveries, shipmentPricing, shipment, deliveryID, minimumThresholdToWaiveFee) => {
    if (!shipment) return false;

    const remainingDeliveries = deliveries.filter((delivery) => delivery.id !== deliveryID);

    const remainingPrice = remainingDeliveries.reduce((sum, item) => {
      const price = item.prescription?.id ? shipmentPricing.item_pricing[item.prescription.id]?.prices[0]?.price : 0;
      return sum + (price || 0);
    }, 0);

    return remainingPrice < minimumThresholdToWaiveFee;
  },
);
