// @owners { team: patients-team }
import {
  Avatar,
  Button,
  Card,
  Column,
  LgPadding,
  ListDescription,
  ListItem,
  MdSpacing,
  Row,
  SmSpacing,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { setAddFamilyMemberRelationship } from '~shared/actions/ui/addFamilyMember';
import { selectUser } from '~shared/actions/ui/users';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { useDispatchShared, useSelectorShared } from '~shared/store';

type Props = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
};

const isWeb = getPlatformOS() === 'web';

export const FamilyMemberAddedCard = ({ dateOfBirth, firstName, lastName }: Props) => {
  const dispatch = useDispatchShared();
  const { navigate } = useNavigation();
  const patient = useSelectorShared(getCurrentUser);
  const familyMemberID = useSelectorShared(getSelectedUserID);
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const name = `${firstName} ${lastName}`;

  const handlePressTransfer = () => {
    if (!familyMemberID) return;
    dispatch(setAddFamilyMemberRelationship(''));
    dispatch(selectUser(familyMemberID));
    navigate('RouteTransferPrescription', { origin: 'FamilyMemberAdded' });
  };

  const handlePressDone = () => {
    dispatch(setAddFamilyMemberRelationship(''));
    navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
  };

  return (
    <Card>
      <LgPadding>
        <Row>
          <Avatar
            name={name}
            size={50}
          />
          <MdSpacing />
          <Column
            flexGrow={isWeb ? 1 : 0}
            flexShrink={isWeb ? 0 : 1}
          >
            <ListItem
              title={name}
              fullBleed
            />
            <XsSpacing />
            <ListDescription
              iconName="calendar-small"
              type="grey"
            >
              {dateOfBirth}
            </ListDescription>
            <XsSpacing />
            <ListDescription
              iconName="user-small"
              type="grey"
            >
              {`${getPossessiveName(patient?.first_name)} ${relationship}`}
            </ListDescription>
          </Column>
        </Row>
        <XlSpacing />
        <Button
          key="family-member-added-transfer"
          onPress={handlePressTransfer}
          label="Transfer a prescription"
          accessibilityLabel="Press to transfer prescriptions to Alto"
        />
        <SmSpacing />
        <Button
          key="family-member-added-done"
          onPress={handlePressDone}
          label="Done"
          type="tertiary"
          accessibilityLabel="Press to view your account information"
        />
      </LgPadding>
    </Card>
  );
};
