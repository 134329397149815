// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Description, LgPadding, MdSpacing } from '@alto/design-system';
import { DateRestrictionReasonMap } from '@alto/scriptdash/alto/scheduling/types/v1/date_restriction_reason';
import { isToday, parse } from 'date-fns';
import React from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { useQueryNextAvailableDateMessaging } from '~shared/features/cart/hooks/usePrescriptionNextAvailableDateMessaging';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getIsFertilityUser } from '~shared/features/users/selectors/getIsFertilityUser';
import { DATE_FORMATS } from '~shared/helpers/date';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { AddressSelector, ContactUs, type OrderTypeContext, PastCutoff } from '../../../../index';

type Props = {
  readonly earliestAvailableDate: string | null;
  readonly earliestDateReason: string;
  readonly prescriptionID: number;
  readonly orderTypeContext?: OrderTypeContext;
  readonly origin?: OriginName;
};

type FertilityMessageProps = {
  readonly prescription: Prescription;
};

const FertilityMessage = ({ prescription }: FertilityMessageProps) => {
  return (
    <Description>
      Please{' '}
      <ContactUs
        origin={CONTACT_US_ORIGIN.MedicationDeliveryStatusPanel}
        method="message"
        type="link"
        textSize="mini"
      />{' '}
      if you need {prescription.medication_name} sooner.
    </Description>
  );
};

export const MedicationDeliveryStatusPanel = ({
  earliestAvailableDate,
  earliestDateReason,
  prescriptionID,
  orderTypeContext,
  origin,
}: Props) => {
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const { message } = useQueryNextAvailableDateMessaging({ prescriptionID, excludeDateFromMessage: true });
  const isFertilityUser = useSelectorShared(
    (state) => prescription && getIsFertilityUser(state, { userID: prescription.user_id }),
  );
  const showFertilityMessage =
    isFertilityUser &&
    earliestDateReason !== DateRestrictionReasonMap.OUT_OF_ZONE &&
    earliestAvailableDate &&
    !isToday(parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0));

  if (earliestDateReason === DateRestrictionReasonMap.PAST_CUTOFF) {
    return <PastCutoff />;
  }

  return (
    <LgPadding topPadding={SPACING.STATIC.NONE}>
      <Description>{`${message} Dates are estimated based on your delivery location.`.trim()}</Description>
      <MdSpacing />
      {showFertilityMessage && prescription ? <FertilityMessage prescription={prescription} /> : null}
      {orderTypeContext !== 'Checkout' && <AddressSelector origin={origin} />}
    </LgPadding>
  );
};
