// @owners { team: patients-team }
import { Column, Description, H3, Link, ListDescription, ListItem, XxsSpacing } from '@alto/design-system';
import { type Notification } from '@alto/scriptdash/alto/customer_support/types/v1/notification';
import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import getUser from '~shared/features/users/selectors/getUser';
import { formatTimeAgo } from '~shared/helpers/date';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { handlePressLink, urlRegExp } from '~shared/helpers/links';
import { useSelectorShared } from '~shared/store';
import { Dot } from '../../navigation';

type NotificationCenterListItemBodyProps = {
  body?: string | null;
  wundercom_message_id: number;
};

const NotificationCenterListItemBody = ({ body, wundercom_message_id }: NotificationCenterListItemBodyProps) => {
  if (!body) return null;

  const urls = body.match(urlRegExp);
  if (urls?.length) {
    return (
      <Column>
        <ListDescription key={`notification-body-${wundercom_message_id}`}>
          {body.replace(urlRegExp, '')}
        </ListDescription>
        {urls.map((url) => {
          return (
            <Column key={`notification-body-url-link-${wundercom_message_id}-${url}`}>
              <XxsSpacing />
              <Link
                textSize="mini"
                onPress={() => {
                  handlePressLink(url);
                }}
              >
                {url}
              </Link>
            </Column>
          );
        })}
      </Column>
    );
  }

  return <ListDescription key={`notification-body-${wundercom_message_id}`}>{body}</ListDescription>;
};

const Container = styled.View`
  position: relative;
`;

const isWeb = getPlatformOS() === 'web';

export const NotificationCenterListItem = (notification: Notification) => {
  const { body, created_at, patient_id, read_at, title, wundercom_message_id } = notification;
  const relativeTime = formatTimeAgo(created_at);
  const hasFamily = useSelectorShared((state) => getHasFamily(state));
  const user = useSelectorShared((state) => getUser(state, { userID: patient_id }));
  const fullTitle = useMemo(() => {
    const text = (hasFamily ? `(For ${user?.first_name})\n ` : '') + (title || 'Notification');
    return (
      <>
        {!read_at && (
          <>
            {isWeb ? (
              <Dot
                accessibilityLabel="unread-notification-dot"
                leftPosition={-20}
                topPosition={4}
              />
            ) : (
              <Container>
                <Dot
                  accessibilityLabel="unread-notification-dot"
                  leftPosition={-20}
                  topPosition={4}
                />
              </Container>
            )}
          </>
        )}
        <H3>{text}</H3>
      </>
    );
  }, [hasFamily, read_at, title, user?.first_name]);
  // view is needed to make the bell icon show up in the middle of the list item
  return (
    <ListItem
      centerVertically={false}
      descriptions={
        <NotificationCenterListItemBody
          body={body}
          wundercom_message_id={wundercom_message_id}
        />
      }
      RightContent={
        <Column
          columnStart
          flexGrow={1}
        >
          <Description>{relativeTime}</Description>
          <XxsSpacing />
        </Column>
      }
      title={fullTitle}
    />
  );
};
