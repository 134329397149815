import { COLORS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { Border } from '../../borders';
import { XsPadding } from '../../containers';
import { ListBase } from '../../lists/src/ListBase';
import { MdSpacing } from '../../separators';
import { Text, type TextProps } from '../../typography/src/Text';

type StringOnlyTextProps = TextProps & { readonly children: string };
type QuickReplyButtonProps = {
  readonly children: string;
  readonly onSend: (text: string) => void;
};
type QuickReplyBarProps = {
  readonly options: string[];
  readonly onSend: (text: string) => void;
};

// "When rendering emoji with a custom font, the text sits higher than its regular baseline.
// To fix it you need to wrap the emoji in another Text element with `fontFamily` set to `System`."
// ~ https://twitter.com/markdalgleish/status/1443751982578110465 (yes, a tweet)
const EMOJI_REGEX = /([\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
const Emoji = styled(Text)`
  font-family: System;
`;

// <EmojiSafeText /> simply wraps emojis in the <Emoji> tag, without touching other props, for example
// these two are identical:
// <Text {...props}><Emoji {...props}>👍</Emoji> thanks</Text>
// <EmojiSafeText {...props}>👍 thanks</EmojiSafeText>
const EmojiSafeText = ({ children, ...textProps }: StringOnlyTextProps) => {
  // wrap all emojis in the Emoji tag, for example:
  // "👍 thanks" will become [<Emoji>👍</Emoji>, " thanks"]
  const strWithSeparatedEmojis = children.split(EMOJI_REGEX);
  const strWithWrappedEmojis = strWithSeparatedEmojis.map((substr) =>
    EMOJI_REGEX.test(substr) ? (
      <Emoji
        key={substr}
        {...textProps}
      >
        {substr}
      </Emoji>
    ) : (
      substr
    ),
  );

  return <Text {...textProps}>{strWithWrappedEmojis}</Text>;
};

const QuickReplyButton = ({ children, onSend }: QuickReplyButtonProps) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onSend(children);
      }}
    >
      <Border
        radius="CIRCLE"
        color={COLORS.PALETTE.SECONDARY.DEFAULT}
      >
        <XsPadding
          leftPadding={SPACING.STATIC.MD}
          rightPadding={SPACING.STATIC.MD}
        >
          <EmojiSafeText
            fontFamily="semibold"
            color={COLORS.TEXT_COLORS.SECONDARY}
          >
            {children}
          </EmojiSafeText>
        </XsPadding>
      </Border>
    </TouchableOpacity>
  );
};

export const QuickReplyBar = ({ options, onSend }: QuickReplyBarProps) => {
  const listItems = options.map((opt) => ({
    key: opt,
    component: <QuickReplyButton onSend={onSend}>{opt}</QuickReplyButton>,
  }));

  return (
    <ListBase
      horizontal
      items={listItems}
      separator={<MdSpacing />}
    />
  );
};
