// @owners { team: patients-team }
import React from 'react';
import ResetPasswordForm from './ResetPasswordFormContainer';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';

export default class ResetPasswordPage extends React.Component {
  render() {
    return (
      <PageBackground>
        <SingleColumnContentWrapper>
          <UnauthorizedFormWrapper header="Reset Password">
            <ResetPasswordForm />
          </UnauthorizedFormWrapper>
        </SingleColumnContentWrapper>
      </PageBackground>
    );
  }
}
