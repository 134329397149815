// @owners { team: patients-team }
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';

/**
 * Get wundercom messages IDs for unread messages
 */
export const getUnreadMessageIDs = (messages: WundercomMessage[]) => {
  return messages.reduce<number[]>((ids, message) => {
    if (!message.read_at) {
      ids.push(Number(message.id));
    }
    return ids;
  }, []);
};
