// @owners { team: patients-team }
import { ActionSheetContext, Toast, ToastContext } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { type OrderBundlingOrigin } from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/order_bundling_origin';
import {
  AddToShipmentEndpoint,
  type AddToShipmentEndpointAddToShipmentRequest,
} from '@alto/scriptdash/alto/patient_app/scheduling/v1/add_to_shipment_endpoint';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses } from '~shared/actions/addresses';
import { clearCart, removeAutoRefills__DEPRECATED, saveOrderSucceeded } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getBundledOrder } from '~shared/features/checkout/selectors/getOrder';
import { updateAddToShipmentError } from '~shared/features/essentials/actions';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Address } from '~shared/types';
import { useInvalidateMedDetails } from '../../med-details/queries/useMedDetails';
import { useInvalidateMedList } from '../../med-list/queries/useMedList';
import { useOrderBundlingOptions } from './useOrderBundlingOptions';

type Props = {
  origin: OrderBundlingOrigin;
  address: Address | null | undefined;
};

const endpoint = AddToShipmentEndpoint(apiEndpointHandler);

export const ADD_TO_SHIPMENT_MUTATION_KEY = ['addToShipment'];
export const useAddToShipment = ({ origin, address }: Props) => {
  const queryClient = useQueryClient();
  const {
    invalidatePrescriptionsQuery: invalidateMedDetailsPrescriptionsQuery,
    invalidateMedicationQuery: invalidateMedDetailsMedicationQuery,
  } = useInvalidateMedDetails();
  const { invalidateListQuery } = useInvalidateMedList();
  const { earliestUpcomingShipment, cartShipment } = useOrderBundlingOptions({
    origin,
    address_id: address?.id,
  });
  const editOrderShipmentID = useSelectorShared(getEditShipmentID);
  const { cartAutoRefills } = useCartData();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { addToast } = useContext(ToastContext);
  const { navigate } = useNavigation();
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const bundledOrder = useSelectorShared((state) => getBundledOrder(state, origin));
  const shipmentID = earliestUpcomingShipment?.id;
  const newPrescriptions = cartShipment?.prescriptions;
  const newPrescriptionIDs = newPrescriptions?.map((prescription) => prescription.id);
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationKey: ADD_TO_SHIPMENT_MUTATION_KEY,
    mutationFn: (params: AddToShipmentEndpointAddToShipmentRequest) => endpoint.addToShipment(params),
    onMutate(params: AddToShipmentEndpointAddToShipmentRequest) {
      queryClient.setQueryData(queries.addToShipment.addToShipment(params).queryKey, () => ({ data: params }));
    },
  });

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleAddToUpcomingDelivery = useCallback(async () => {
    if (!shipmentID || !newPrescriptions || !newPrescriptionIDs) {
      throw new Error('cannot add to upcoming shipment without a shipment or prescription');
    }
    const orderPrescriptions = newPrescriptions.map((prescription) => ({
      id: prescription.id,
      patient_paid_shown: prescription.price,
      auto_refill_opt_in: cartAutoRefills[prescription.id],
    }));
    const eventParams = {
      origin: ORIGIN_NAMES.ORDER_BUNDLING,
      facility_id: address?.dispensing_facility_id,
      shipment_id: shipmentID,
      prescriptionIds: orderPrescriptions.map((prescription) => prescription.id),
    };
    trackEvent({ event: EVENTS.CHECKOUT__PLACE_ORDER_TAPPED, params: eventParams });
    const params = {
      order_prescriptions: orderPrescriptions,
      shipment_id: shipmentID,
      order: bundledOrder,
      edit_order_shipment_id: editOrderShipmentID,
    };
    mutate(params, {
      onSuccess: (response) => {
        if (response.errors?.length) {
          const error = getErrorMessageFromResponse({ errors: response.errors });
          throw new Error(error);
        }
        closeActionSheet();
        trackEvent({ event: EVENTS.CHECKOUT__PLACE_ORDER_SUCCEEDED, params: eventParams });
        addToast(
          <Toast
            dismissible
            duration={5000}
            variant="success"
          >
            Successfully added your medications to your upcoming delivery
          </Toast>,
        );
        dispatch(saveOrderSucceeded());
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchDeliveries());
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchAddresses());
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchShipments());
        invalidateMedDetailsPrescriptionsQuery();
        invalidateMedDetailsMedicationQuery();
        invalidateListQuery();
        dispatch(updateAddToShipmentError({ error: null, quantityAdded: null }));
        dispatch(removeAutoRefills__DEPRECATED(newPrescriptionIDs));
        dispatch(clearCart());
        navigate('RouteOrderSuccess', {
          shipmentIDs: [shipmentID],
          isEditingOrder: true,
          bundledPrescriptionIDs: newPrescriptionIDs,
        });
      },
      onError: (error: unknown) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : 'Unable to add medications to your upcoming delivery. If the problem persists please contact support.';
        trackEvent({ event: EVENTS.CHECKOUT__PLACE_ORDER_FAILED, params: eventParams });
        addToast(
          <Toast
            dismissible
            duration={5000}
            variant="error"
          >
            {errorMessage}
          </Toast>,
        );
        // eslint-disable-next-line sonarjs/no-redundant-jump
        return;
      },
    });
  }, [
    shipmentID,
    newPrescriptions,
    newPrescriptionIDs,
    address?.dispensing_facility_id,
    trackEvent,
    bundledOrder,
    editOrderShipmentID,
    mutate,
    cartAutoRefills,
    closeActionSheet,
    addToast,
    dispatch,
    invalidateMedDetailsPrescriptionsQuery,
    invalidateMedDetailsMedicationQuery,
    invalidateListQuery,
    navigate,
  ]);

  return { handleAddToUpcomingDelivery, isPending, isError, isSuccess };
};
