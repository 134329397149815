// @owners { team: patients-team }
import { PHONE } from '~shared/constants';
import { type FAQTopic } from './FAQActionSheet';

export const getFAQDetails = (topic: FAQTopic) => {
  let title = '';
  let description = '';

  switch (topic) {
    case 'carry_medication':
      title = 'Do you carry my medication?';
      description =
        "Yes. As a full-service pharmacy, Alto can fill nearly all medications, including specialty, over-the-counter, brand, and generic. Alto's pharmacists have deep expertise in many clinical areas, including heart and lung health, fertility, HIV/PrEP/HRT, diabetes and more.";
      break;
    case 'insurance_covered':
      title = 'Will I be covered by my insurance?';
      description = `We accept most major insurance plans, including government-administered plans, and always work to get you the lowest price. Please note that coverage varies by state. For up to date information on coverage in your area call us at ${PHONE}.`;
      break;
    case 'medication_cost':
      title = 'Will my medications cost more through Alto?';
      description =
        "Nope! Your copay stays the same at Alto. Our team works tirelessly behind-the-scenes to find you the best price—we've saved our patients more than $190 million on their medications.";
      break;
    case 'transfer':
      title = 'How does a transfer work?';
      description =
        "We'll take care of it for you! Simply create an account and select “Transfer to Alto”. Let us know which pharmacy to reach out to, and we'll coordinate the transfer. Our pharmacists will then search for any savings on your medications. Because the transfer relies on your current pharmacy to provide information, it may take up to a few business days for the transfer to complete. When it's ready, we'll reach out to schedule your delivery.";
      break;
    case 'delivery':
      title = 'Do you offer delivery?';
      description =
        'Yes, you can schedule your prescription delivery right to your door! We offer fast and reliable delivery options for your medications at the time and location that works best for you.';
      break;
    default:
      break;
  }
  return { title, description };
};
