// @owners { team: patients-team }
import { type AnalyticsProps } from '~shared/features/courier-tips/AnalyticsProps';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { type ANALYTICS_EVENT } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type ReduxDispatch } from '~shared/types';

const sendTipAnalyticsEvent: (arg0: {
  eventKey: ANALYTICS_EVENT;
  analyticsProps: AnalyticsProps;
  additionalProps?: Record<string, unknown>;
}) => (arg0: ReduxDispatch) => void =
  ({ eventKey, analyticsProps, additionalProps }) =>
  (dispatch) => {
    const props = {
      ...analyticsProps,
      ...(additionalProps || {}),
    };
    if (analyticsProps.shipment_id) {
      dispatch(sendAnalyticsEvent(createEvent(eventKey, props)));
    } else if (analyticsProps.prescription_ids) {
      analyticsProps.prescription_ids.forEach((prescription_id) => {
        dispatch(sendAnalyticsEvent(createEvent(eventKey, { ...props, prescription_id, prescription_ids: undefined })));
      });
    }
  };

export default sendTipAnalyticsEvent;
