// @owners { team: patients-team }
import { type LineItem } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { useMemo } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

/**
 * The analytics hook used for both PricingBreakdownActionSheet and PricingBreakdownCard
 * on mount.
 */
export const useTrackPriceBreakdownViewed = ({
  lineItems,
  numberOfRxs,
  origin,
  breakdownKey,
}: {
  lineItems: LineItem[] | undefined;
  numberOfRxs: number;
  origin: string;
  breakdownKey: string;
}) => {
  const { trackPageView } = useAnalytics();
  const cartTotal = useMemo(() => lineItems?.find((item) => item.key === 'total'), [lineItems]);

  trackPageView({
    event: EVENTS.PAYMENT_BREAKDOWN_VIEWED,
    params: {
      origin,
      items_count: numberOfRxs,
      cart_total: cartTotal?.value,
      estimated: cartTotal?.info_key === 'estimated',
      unavailable: cartTotal?.info_key === 'price_unavailable',
      // uuid to enable querying 'item viewed' events for given 'payment breakdown viewed' event
      breakdown_key: breakdownKey,
    },
  });
};
