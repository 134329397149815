// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { sortPrescriptionsByActive } from '~shared/features/my-meds/helpers/sortPrescriptions';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';

export const getNextAvailableDateFacilityID = createSelector([getPrescriptions], (prescriptions) => {
  if (prescriptions.length === 0) {
    return undefined;
  }

  return prescriptions.sort(sortPrescriptionsByActive)[0].facility_id;
});
