import { type LineItem, type LineItemType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createSelector } from 'reselect';
import { getCartPricing, getOrderPricing, getShipmentPricing } from './getPricing';
import { roundToTwo } from '~shared/helpers/currency';

// Assumes tip amount is included in total returned by /pricing/order
export const getOrderTotal = createSelector([getOrderPricing], (orderPricing): LineItem | null => {
  const { line_items } = orderPricing;

  for (const lineItem of line_items) {
    const { key } = lineItem;

    if (key === 'total') {
      return lineItem;
    }
  }

  return null;
});

const IGNORED_LINE_ITEMS_FOR_SUBTOTAL = new Set<LineItemType>([
  'tip_amount',
  'on_demand_fee',
  'delivery_fee',
  'same_day_delivery_fee',
  'next_day_delivery_fee',
  'standard_delivery_fee',
  'asap_delivery_fee',
]);

// total not accounting for tip and other fees (i.e delivery).
// used as the summed amount for split payments (tip and fees charged separately)
export const getItemsSubtotal = createSelector(
  [getOrderPricing, getOrderTotal],
  (orderPricing, orderTotal): number | null => {
    if (orderTotal === null) {
      return null;
    }

    const { line_items } = orderPricing;

    if (orderTotal.value === 0) {
      return 0;
    }

    if (!orderTotal.value) {
      return null;
    }

    let total = orderTotal.value;
    for (const lineItem of line_items) {
      const { key, value } = lineItem;

      if (IGNORED_LINE_ITEMS_FOR_SUBTOTAL.has(key)) {
        total -= value ?? 0;
      }
    }
    return roundToTwo(total);
  },
);

export const getShipmentTotal = createSelector([getShipmentPricing], (orderPricing): LineItem | null => {
  const { line_items } = orderPricing;

  for (const lineItem of line_items) {
    const { key } = lineItem;

    if (key === 'total') {
      return lineItem;
    }
  }

  return null;
});

export const getTipTotal = createSelector([getOrderPricing], (orderPricing): LineItem | null => {
  const { line_items } = orderPricing;

  for (const lineItem of line_items) {
    const { key } = lineItem;

    if (key === 'tip_amount') {
      return lineItem;
    }
  }

  return null;
});

export const getCartTotal = createSelector([getCartPricing], (cartPricing): LineItem | null => {
  const { line_items } = cartPricing;

  for (const lineItem of line_items) {
    const { key } = lineItem;

    if (key === 'total') {
      return lineItem;
    }
  }

  return null;
});
