// @owners { team: patients-team }
import { type ShipmentFeeType } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import { createSelector } from 'reselect';
import { getAppliedShipmentFeesForOrder } from '~shared/features/shipment-fees/selectors/getAppliedShipmentFeesForOrder';

export const getAppliedDeliveryFee = createSelector([getAppliedShipmentFeesForOrder], (appliedShipmentFees) => {
  // @ts-expect-error not all code paths return a value
  const fee = (feeType: ShipmentFeeType) => {
    const fee = appliedShipmentFees?.find((f) => f.fee_type === feeType);
    if (fee) {
      return fee;
    }
  };

  const isSameDayDeliveryFee = fee('same_day_delivery');
  const isNextDayDeliveryFee = fee('next_day_delivery');
  const isStandardDeliveryFee = fee('standard_delivery');
  const isAsapDeliveryFee = fee('asap_delivery');

  return isSameDayDeliveryFee || isNextDayDeliveryFee || isStandardDeliveryFee || isAsapDeliveryFee;
});
