import { COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexRow } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { LargeText, SmallText } from '~web/features/onboarding/components/styles';
import bikeCourier from '~web/images/freeDelivery@3x.png';

const ImageContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  width: 234px;
  margin: 4.5rem auto 0 auto;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 178px;`}
  img {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const MessageContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  text-align: center;
  width: 500px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 280px;`}
`;

const BackToPreferencesButton = styled.a`
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
  font-size: 18px;
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  margin: ${SPACING.STATIC.LG.rem} 0 0 0;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
`;

const PrefsConfirmationPage = () => {
  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <FlexRow evenlySpaceContent>
      <ImageContainer isSMScreenOrBigger={isSMScreenOrBigger}>
        <img
          src={bikeCourier}
          alt="Bike Courier"
        />
      </ImageContainer>
      <MessageContainer isSMScreenOrBigger={isSMScreenOrBigger}>
        <LargeText isSMScreenOrBigger={isSMScreenOrBigger}>Preferences Saved!</LargeText>
        <SmallText isSMScreenOrBigger={isSMScreenOrBigger}>
          Thanks for updating your email preferences. Please allow 48 hours for all systems to update.
        </SmallText>
      </MessageContainer>
      <BackToPreferencesButton
        onClick={() => {
          window.location.reload();
        }}
      >
        Back to preferences
      </BackToPreferencesButton>
    </FlexRow>
  );
};

export default PrefsConfirmationPage;
