// @owners { team: patients-team }
import { getPlatformOS } from './getPlatformOS';

export default (cookieName: string): string | null | undefined => {
  if (getPlatformOS() !== 'web') {
    return null;
  }

  const cookieRegEx = new RegExp(`(?:^|; *)${cookieName}=([^;]*)`);
  return (cookieRegEx.exec(document.cookie) || [])[1];
};
