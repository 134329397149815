export const buildQueryString = (params: Record<string, any>, splitArrayParams = false) => {
  const paramsKeys = Object.keys(params);
  return paramsKeys.reduce(
    (out, k) => {
      if (params[k] !== undefined && params[k] !== null) {
        if (splitArrayParams && Array.isArray(params[k])) {
          // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
          params[k].forEach((value) => {
            out += `${k}[]=${encodeURIComponent(value)}&`;
          });
        } else {
          out += `${k}=${encodeURIComponent(params[k])}&`;
        }
      }

      return out;
    },
    paramsKeys.length ? '?' : '',
  );
};
