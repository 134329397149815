import { useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

export const useDeliveriesListViewed = () => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.DELIVERIES_LIST_VIEWED,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
