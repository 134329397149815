// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Column, H1, Illustration, MdSpacing, Row } from '@alto/design-system';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import checklist from '~shared/assets/images/checklist.png';
import { IMG_ALT_TEXTS } from '~shared/constants';

export const CalculatingQuantities = () => {
  const translateY = useRef(new Animated.Value(-5)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(translateY, {
          toValue: 5,
          duration: 750,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: -5,
          duration: 750,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [translateY]);

  return (
    <Column
      flexGrow={1}
      flexShrink={1}
      backgroundColor={COLORS.BACKGROUND_COLORS.PINK_LIGHT}
    >
      <Row
        center
        flexGrow={1}
      >
        <Column center>
          <Animated.View style={{ transform: [{ translateY }] }}>
            <Illustration
              size="lg"
              source={checklist}
              accessibilityLabel={IMG_ALT_TEXTS.checklist}
            />
          </Animated.View>
          <MdSpacing />
          <H1 center>Thanks! Now calculating your order...</H1>
        </Column>
      </Row>
    </Column>
  );
};
