import * as React from 'react';
import { CenterView } from '../../containers';
// eslint-disable-next-line import/no-deprecated
import { CenteredActivityIndicator } from '../../loaders';

export const LoadingEmptyState = () => {
  return (
    <CenterView>
      <CenteredActivityIndicator
        accessibilityLabel="loading"
        testID="loading"
        large
      />
    </CenterView>
  );
};
