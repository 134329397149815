import { type AltoIconName, SPACING } from '@alto/design-library-tokens';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { Keyboard, type TextInput } from 'react-native';
import { useDebounce } from '../../../../hooks/useDebounce';
import { ActionSheetContext, ActionSheetV2 } from '../../../action-sheet';
import { Button } from '../../../buttons';
import { LgPadding, Row } from '../../../containers';
import { AltoSpinningLoader } from '../../../loaders';
import { MdSpacing } from '../../../separators';
import { InputText } from '../InputText/InputText';
import { AutocompleteRow } from './AutocompleteRow';
import { type SelectionTypes } from './InputAutocomplete';
import { clearResults, inputBlurred, inputFocused, removeSelectedItem, selectItem, updateText } from './actions';
import { callApi, summarizeEntries } from './helpers';
import { type State, reducer } from './reducer';
import { type ApiResponseRow, type DataSource } from './types';

type Props = {
  readonly accessibilityLabel: string;
  readonly dataSource: DataSource<any>;
  readonly leftIcon?: AltoIconName;
  readonly onSelectCallback: (arg: ApiResponseRow<any>[]) => void;
  readonly setParentText: (arg: string) => void;

  readonly initialValues?: ApiResponseRow<any>[];
  readonly selectionType: SelectionTypes;
  readonly placeholderText?: string;
  readonly shouldAllowManualInput?: boolean;
  readonly label?: string;
  readonly title: string;
  readonly hasDefaultOptions?: boolean;
  readonly onSelectAutoCloseActionSheet?: boolean;
};

const getInitialState = (initialValues: ApiResponseRow<string>[] | null | undefined): State => ({
  text: summarizeEntries(initialValues || []),
  selectedValues: initialValues || [],
  selectedValueKeys: initialValues?.reduce((acc, row) => ({ ...acc, [row.key]: true }), {}) || {},
  resultsToDisplay: null,
  isInputFocused: false,
  isLoading: false,
});

export const InputAutocompleteActionSheet = ({
  dataSource,
  placeholderText,
  leftIcon,
  selectionType,
  onSelectCallback,
  initialValues,
  accessibilityLabel,
  shouldAllowManualInput,
  setParentText,
  label,
  title,
  hasDefaultOptions,
  onSelectAutoCloseActionSheet = true,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, null, () => getInitialState(initialValues));
  const inputComponent = useRef<TextInput>(null);
  const { text, selectedValues, selectedValueKeys, resultsToDisplay, isInputFocused, isLoading } = state;
  const { goToPreviousActionSheet } = useContext(ActionSheetContext);

  const debouncedText = useDebounce(text?.trim(), 200);

  // intentionally not listening to dataSource or isInputFocused
  useEffect(
    () => {
      if (debouncedText === summarizeEntries(selectedValues)) {
        return;
      }
      return callApi(debouncedText, dataSource, isInputFocused, dispatch, shouldAllowManualInput);
    },
    [debouncedText, dispatch, shouldAllowManualInput], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // focus the input on mount
  useEffect(() => {
    inputComponent.current?.focus?.();
  }, []);

  const noResults = useMemo(() => {
    if (resultsToDisplay && selectedValues.length === 0) {
      if (shouldAllowManualInput) {
        return resultsToDisplay.length === 1;
      } else {
        return resultsToDisplay.length === 0;
      }
    }

    return !!resultsToDisplay;
  }, [resultsToDisplay, selectedValues, shouldAllowManualInput]);

  if (!text && noResults) {
    dispatch(clearResults());
  }

  const shouldShowSelections =
    selectionType === 'multi' && selectedValues.length > 0 && !resultsToDisplay && !isLoading;

  const handleRemoveSelectedItem = useCallback(
    (selectedItem: ApiResponseRow<string>) => () => {
      dispatch(removeSelectedItem(selectedItem, selectionType));
    },
    [selectionType],
  );

  const handleFocus = useCallback(() => {
    dispatch(inputFocused());
    dispatch(updateText(''));
    return callApi(debouncedText, dataSource, !!hasDefaultOptions, dispatch, shouldAllowManualInput);
  }, [debouncedText, dataSource, hasDefaultOptions, shouldAllowManualInput]);

  const handleBlur = useCallback(() => {
    dispatch(inputBlurred());
  }, []);

  const handleClose = useCallback(() => {
    inputComponent.current?.blur();
    onSelectCallback(selectedValues);
    setParentText(summarizeEntries(selectedValues));
    if (onSelectAutoCloseActionSheet) {
      goToPreviousActionSheet();
    }
  }, [goToPreviousActionSheet, onSelectAutoCloseActionSheet, onSelectCallback, selectedValues, setParentText]);

  // close the action sheet when the user has selected an item and it's single entry
  useEffect(() => {
    if (selectionType === 'multi') {
      return;
    }

    // don't close if nothing has been selected or if there's only one selection and it's the initial value
    if (selectedValues.length === 0 || selectedValues === initialValues) {
      return;
    }

    inputComponent.current?.blur();
    handleClose();
  }, [handleClose, selectedValues, selectionType, setParentText, initialValues]);

  const handleRowPress = useCallback(
    (selectedItem: ApiResponseRow<any>) => () => {
      if (selectionType === 'multi') {
        // move focus back into the input so that the user can continue typing
        inputComponent.current?.focus();
      } else {
        Keyboard.dismiss();
      }
      dispatch(selectItem(selectedItem, selectionType));
    },
    [selectionType],
  );

  const handleSubmit = useCallback(() => {
    // if manual input is allowed, there will be a result here that will match
    const item = resultsToDisplay?.find((result) => result.value === text);
    if (item) {
      handleRowPress(item)();
    }
  }, [handleRowPress, resultsToDisplay, text]);

  const buttons = useMemo<[React.ReactElement<typeof Button>] | undefined>(() => {
    if (!shouldShowSelections) {
      return undefined;
    }
    return [
      <Button
        key="done-button"
        onPress={handleClose}
        label="Done"
        accessibilityLabel="Done"
      />,
    ];
  }, [handleClose, shouldShowSelections]);

  return (
    <ActionSheetV2
      fullscreen
      title={title}
      handleClose={handleClose}
      buttons={buttons}
      analyticsName="input auto complete"
    >
      <>
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.MD}
        >
          <InputText
            autoCorrect={false}
            label={label}
            value={text}
            placeholder={placeholderText}
            onChangeText={(text) => {
              dispatch(updateText(text));
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onSubmitEditing={handleSubmit}
            onRef={inputComponent}
            accessibilityLabel={accessibilityLabel}
            testID="AsyncTextInput"
            leftIconProps={{ name: leftIcon }}
            required
          />
        </LgPadding>
        {isLoading && text !== '' ? (
          <>
            <MdSpacing />
            <Row centerHorizontally>
              <AltoSpinningLoader small={false} />
            </Row>
          </>
        ) : null}
        {!!resultsToDisplay && !isLoading ? (
          <>
            {resultsToDisplay
              .filter((result) => !selectedValueKeys[result.key]) // don't include results we've already selected
              .map((result) => (
                <AutocompleteRow
                  title={result.title}
                  subtitle={result.subtitle}
                  key={result.key}
                  onPress={handleRowPress(result)}
                  onRemove={handleRemoveSelectedItem(result)}
                  textToHighlight={debouncedText}
                  isSelected={selectedValues.findIndex((i) => result.title === i.title) !== -1}
                />
              ))}
          </>
        ) : null}
        {shouldShowSelections
          ? selectedValues
              .map((selection) => (
                <AutocompleteRow
                  title={selection.title}
                  subtitle={selection.subtitle}
                  key={selection.key}
                  textToHighlight={selection.title}
                  onPress={handleRowPress(selection)}
                  onRemove={handleRemoveSelectedItem(selection)}
                  isSelected
                />
              ))
              .reverse() // reverse so that the most recent selection is at the top
          : null}
      </>
    </ActionSheetV2>
  );
};
