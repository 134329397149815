import { createSelector } from 'reselect';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { type LightDelivery, type Prescription } from '~shared/types';

export const getPrescriptionsForShipment = createSelector(
  [getDeliveriesForShipmentID, getPrescriptions],
  (deliveries, prescriptions): Prescription[] => {
    return deliveries.reduce<Prescription[]>((rxs: Prescription[], d: LightDelivery) => {
      return [...rxs, ...prescriptions.filter((rx: Prescription) => rx.id === d.prescription_id)];
    }, []);
  },
);
