// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetToast,
  ActionSheetV2,
  AltoSpinningLoader,
  Body,
  Button,
  FlatCard,
  H3,
  LgPadding,
  MdSpacing,
  Toast,
  ToastContext,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { GetItSoonerOptionTypeMap } from '@alto/scriptdash/alto/patient_app/availability_options/types/v1/get_it_sooner';
import {
  AvailabilityOptionsEndpoint,
  type AvailabilityOptionsEndpointFetchAllResponse,
} from '@alto/scriptdash/alto/patient_app/availability_options/v2/availability_options_endpoint';
import { PriceOverrideReasonMap } from '@alto/scriptdash/alto/pricing/types/v3/price_override_reason';
import { DateRestrictionReasonMap } from '@alto/scriptdash/alto/scheduling/types/v1/date_restriction_reason';
import { differenceInDays, parseISO } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
// eslint-disable-next-line import/no-deprecated
import { autobillPrescription } from '~shared/actions/prescriptions';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getNextAvailableDateAddressID } from '~shared/features/next-available-date/selectors/getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from '~shared/features/next-available-date/selectors/getNextAvailableDateFacilityId';
import { getEarliestAvailableDateForPrescription__DEPRECATED } from '~shared/features/next-available-date/selectors/getNextAvailableDateForPrescription';
import { getOrderPricingLastFetchedAt } from '~shared/features/pricing/selectors/getPricing';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { apiEndpointHandler } from '~shared/helpers/api';
import { formatDollars } from '~shared/helpers/currency';
import { formatNumberOfHoursAgo, formatRelativeDate } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
// eslint-disable-next-line import/no-deprecated
import { fetchLatestVacationSupply } from '~shared/queries/useFetchLatestVacationSupply';
import { useQuery } from '~shared/react-query';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { MenuOptions, useNavigateToAssistantLanding } from '../../../alto-assistant';
import { useAddToCart } from '../../../cart';
import { RequestVacationSupply } from '../../../vacation-override';

const availabilityOptionsEndpoint = AvailabilityOptionsEndpoint(apiEndpointHandler);

const OptionLoaderContainer = styled(View)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
`;

export const GET_IT_SOONER_OPTIONS = {
  pay_cash: 'pay_cash',
  run_insurance: 'run_insurance',
  vacation_override: 'vacation_override',
  lost_medication_override: 'lost_medication_override',
} as const;

type OptionsToGetItSoonerActionSheetProps = {
  readonly prescription: Prescription;
  readonly handleModalClose: () => void;
  readonly analyticsOrigin?: OriginName;
  readonly showHelpButtons?: boolean;
};

export const OptionsToGetItSoonerActionSheet = ({
  prescription,
  handleModalClose,
  analyticsOrigin,
  showHelpButtons = false,
}: OptionsToGetItSoonerActionSheetProps) => {
  const { goBack, navigate } = useNavigation();

  const { saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const addressID = useSelectorShared(getNextAvailableDateAddressID);
  const facilityID = useSelectorShared(getNextAvailableDateFacilityID);
  const earliestAvailableDate = useSelectorShared((state) =>
    getEarliestAvailableDateForPrescription__DEPRECATED(state, prescription.id),
  );
  const [optedIntoCashPayAt, setOptedIntoCashPayAt] = React.useState<number | undefined>(undefined);
  const [shouldShowCashPriceErrorToast, setShouldShowCashPriceErrorToast] = useState(false);
  const [showLoadingState, setShowLoadingState] = useState(false);
  const isEditingExistingOrder = useSelectorShared(getIsEditingExistingOrder);
  const pricingLastFetchedAt = useSelectorShared(getOrderPricingLastFetchedAt);
  const { trackEvent } = useAnalytics();
  const { handleAddToCart } = useAddToCart();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const availabilityOptionsParams = {
    prescription_id: prescription.id,
    address_id: Number(addressID),
    facility_id: facilityID,
  };
  const { data, isPending } = useQuery<AvailabilityOptionsEndpointFetchAllResponse>({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['prescription-availability-options', availabilityOptionsParams],
    queryFn: () => availabilityOptionsEndpoint.fetchAll(availabilityOptionsParams),
  });

  const cashPayOption = data?.data?.options.one_time_cash_pay;
  const formatRelativeDateOptions = {
    includeRelative: false,
    makeLowerCase: true,
    includeDayOfWeek: false,
  };
  let actionSheetTitle = `Delivery as early as ${formatRelativeDate(earliestAvailableDate, formatRelativeDateOptions)}`;
  if (cashPayOption) {
    actionSheetTitle = `Delivery as early as ${formatRelativeDate(
      cashPayOption.available_date.date ?? '',
      formatRelativeDateOptions,
    )}`;
  }

  // Compare the time patient opted into cash price with when order pricing is updated.
  // This determines when to close the action sheet flow
  useEffect(() => {
    if (optedIntoCashPayAt && pricingLastFetchedAt && pricingLastFetchedAt > optedIntoCashPayAt) {
      setOptedIntoCashPayAt(undefined);
      handleModalClose();
    }
  }, [optedIntoCashPayAt, pricingLastFetchedAt, handleModalClose]);

  useEffect(() => {
    if (!isPending) {
      trackEvent({
        event: EVENTS.NEXT_AVAILABLE_DATE_ACTION_SHEET_VIEWED,
        params: {
          reason: DateRestrictionReasonMap.REFILL_TOO_SOON,
          origin: analyticsOrigin,
        },
        additionalFields: {
          prescriptionId: prescription.id,
        },
      });
    }
  }, [analyticsOrigin, isPending, prescription.id, trackEvent]);

  const sendAnalyticsOnOptionSelect = (option: (typeof GET_IT_SOONER_OPTIONS)[keyof typeof GET_IT_SOONER_OPTIONS]) => {
    trackEvent({
      event: EVENTS.GET_IT_SOONER_ACTION_SHEET_OPTION_SELECTED,
      params: {
        option,
      },
      additionalFields: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        prescriptionID: prescription.id,
      },
    });
  };

  const handleCashPaySelected = async () => {
    sendAnalyticsOnOptionSelect(GET_IT_SOONER_OPTIONS.pay_cash);
    if (isEditingExistingOrder) {
      setShouldShowCashPriceErrorToast(true);
    } else {
      setShowLoadingState(true);
      const res = await handleAddToCart({
        resource_id: prescription.id,
        resource_type: 'Prescription',
        resetOrderDate: true,
        billing_overrides: {
          reason: PriceOverrideReasonMap.ONE_TIME_CASH_PAY,
        },
      });
      setShowLoadingState(false);

      if (res.success) {
        // Keep track of when user opted in to cash price
        setOptedIntoCashPayAt(new Date().valueOf());
      }
    }
  };

  const handleRunInsurance = async () => {
    sendAnalyticsOnOptionSelect(GET_IT_SOONER_OPTIONS.run_insurance);
    setShowLoadingState(true);
    // eslint-disable-next-line import/no-deprecated
    const autobillResult = await dispatch(autobillPrescription(prescription.id));
    setShowLoadingState(false);

    if (autobillResult.autobillSuccess) {
      addToast(<Toast variant="success">We’ve run this medication through your insurance on file.</Toast>);
    } else {
      addToast(<Toast variant="error">There was an error running your insurance. Please try again later.</Toast>);
    }
    handleModalClose();
    await Promise.all([
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchNextAvailableDates({ unbundledPrescriptionIDs: [prescription.id] })),
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchDeliveries()),
    ]);
  };

  const handleVacationOverride = async () => {
    sendAnalyticsOnOptionSelect(GET_IT_SOONER_OPTIONS.vacation_override);
    const showVacationSupplySelfService = data?.data?.options.vacation_supply?.self_service_allowed;

    if (showVacationSupplySelfService) {
      setShowLoadingState(true);
      // eslint-disable-next-line import/no-deprecated
      const vacationSupplyRequest = await fetchLatestVacationSupply({
        prescription_id: prescription.id,
        status: 'failed',
      });
      setShowLoadingState(false);

      if (vacationSupplyRequest.data) {
        navigate('RouteVacationRequestFailure', {
          prescriptionID: prescription.id,
          vacationSupply: vacationSupplyRequest.data,
        });

        handleModalClose();
      } else {
        setActiveActionSheet(<RequestVacationSupply prescription={prescription} />);
      }
    } else {
      navigate('RouteAssistantMessage', {
        onSubmitSuccess: () => {
          goBack();
        },
        subject: 'I need a medication vacation supply',
        message: `I'm going to be traveling and need a vacation supply of my ${prescription.medication_name}.\n\n**FILL IN YOUR TRAVEL INFO BELOW**\n\nTravel dates:\n\nTravel destination:\n\nNeed medication before:`,
        messagePrompt: ' ', // must be non-empty string for MessageWithSubject component
        origin: ASSISTANT_CATEGORIES.VACATION_OVERRIDE,
        category: ASSISTANT_CATEGORIES.VACATION_OVERRIDE,
      });

      handleModalClose();
    }
  };

  const handleLostMedicationOverride = () => {
    sendAnalyticsOnOptionSelect(GET_IT_SOONER_OPTIONS.lost_medication_override);
    navigate('RouteAssistantMessage', {
      onSubmitSuccess: () => {
        goBack();
      },
      subject: 'Report lost medication',
      message: `I lost my ${prescription.medication_name} and need to get more.\n\n**ADD ADDITIONAL INFO BELOW**`,
      messagePrompt: ' ', // must be non-empty string for MessageWithSubject component
      origin: ASSISTANT_CATEGORIES.LOST_MEDICATION_OVERRIDE,
      category: ASSISTANT_CATEGORIES.LOST_MEDICATION_OVERRIDE,
    });
    handleModalClose();
  };

  const buildOptions = () => {
    const getItSoonerOptions = data?.data?.options;
    if (!getItSoonerOptions) {
      return [];
    }

    return Object.keys(getItSoonerOptions).map((option: string) => {
      if (option === GetItSoonerOptionTypeMap.ONE_TIME_CASH_PAY && cashPayOption) {
        const { price } = cashPayOption.price_option;
        const cashText = 'Pay cash price';
        return {
          iconName: 'pricetag-duo' as const,
          text: price ? `${cashText}: ${formatDollars(price)}` : cashText,
          description: `Get it ${formatRelativeDate(cashPayOption.available_date.date ?? '', {
            includeRelative: false,
            makeLowerCase: true,
          })} without insurance`,
          onPress: handleCashPaySelected,
        };
      }

      if (option === GetItSoonerOptionTypeMap.RUN_INSURANCE) {
        let description = 'If you’ve resolved any open issues, we can run your insurance again';
        const lastRunAt = getItSoonerOptions?.run_insurance?.last_billed_at;
        if (lastRunAt) {
          description += `\n\nLast attempt: ${formatNumberOfHoursAgo(lastRunAt)}`;
        }

        return {
          iconName: 'shieldslash-duo' as const,
          text: 'Run your insurance',
          description,
          onPress: handleRunInsurance,
        };
      }

      if (option === GetItSoonerOptionTypeMap.VACATION_SUPPLY) {
        return {
          iconName: 'paperairplane-duo' as const,
          text: 'Request vacation supply',
          description: 'Available once every 6 months',
          onPress: handleVacationOverride,
        };
      }

      return {
        iconName: 'alert-duo' as const,
        text: 'Report lost medication',
        description: 'Get medication override',
        onPress: handleLostMedicationOverride,
      };
    });
  };

  const daysSinceLastDelivery = prescription.last_delivered_at
    ? differenceInDays(new Date(), parseISO(prescription.last_delivered_at))
    : 0;

  const infoBoxPrimaryText = prescription.last_delivered_at
    ? `Insurance covered this ${daysSinceLastDelivery} days ago`
    : 'Insurance covered this recently';

  const infoBoxDescription = prescription.last_delivered_at
    ? `This was last filled on ${formatRelativeDate(prescription.last_delivered_at, {
        includeRelative: true,
        makeLowerCase: true,
      })}`
    : '';

  const handleGotItPress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.GET_IT_SOONER_GOT_IT_BUTTON,
        origin: 'options_to_get_it_sooner_action_sheet',
      },
      additionalFields: { prescriptionId: prescription.id },
    });

    closeActionSheet();

    navigateToAssistantLanding({ preferredDateUnavailable: false });
  };

  const handleNavigateToMessages = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.GET_IT_SOONER_ASK_QUESTION_BUTTON,
        origin: ASSISTANT_CHAT_ORIGINS.OPTIONS_TO_GET_IT_SOONER_ACTION_SHEET,
      },
      additionalFields: { prescriptionId: prescription.id },
    });

    closeActionSheet();
    saveStack<ActionSheetProviderStackCacheKey>('assistant');

    navigate('RouteAssistantMessage', {
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      origin: ASSISTANT_CHAT_ORIGINS.OPTIONS_TO_GET_IT_SOONER_ACTION_SHEET,
      category: 'ordering.date_unavailable',
      subject: `${ASSISTANT_MESSAGE_SUBJECTS.GET_IT_SOONER} - ${prescription?.medication_name ?? ''}`,
    });
  };

  return (
    <ActionSheetV2
      title={actionSheetTitle}
      buttons={
        showHelpButtons
          ? [
              <Button
                label="Got it"
                key="get-it-sooner-got-it-button"
                onPress={handleGotItPress}
                accessibilityLabel="Press to return to Help and messages"
              />,
              <Button
                type="tertiary"
                label="I still have a question"
                key="get-it-sooner-send-message-button"
                onPress={handleNavigateToMessages}
                accessibilityLabel="Press to go to message form"
              />,
            ]
          : undefined
      }
      analyticsName="get it sooner"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <FlatCard>
          <LgPadding>
            <Body
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.PRIMARY}
            >
              {infoBoxPrimaryText}
            </Body>
            {!!infoBoxDescription && (
              <>
                <MdSpacing />
                <Body color={COLORS.TEXT_COLORS.GREY}>{infoBoxDescription}</Body>
              </>
            )}
          </LgPadding>
        </FlatCard>
      </LgPadding>

      <LgPadding bottomPadding={SPACING.STATIC.NONE}>
        <H3>Options to get it sooner</H3>
      </LgPadding>
      {isPending ? (
        <View>
          <AltoSpinningLoader />
        </View>
      ) : (
        <>
          <MenuOptions options={buildOptions()} />
          {shouldShowCashPriceErrorToast ? (
            <ActionSheetToast
              variant="error"
              dismissible
              onHide={() => {
                setShouldShowCashPriceErrorToast(false);
              }}
            >
              Unable to switch to cash price because the order is already scheduled.
            </ActionSheetToast>
          ) : null}
        </>
      )}
      {optedIntoCashPayAt || showLoadingState ? (
        <OptionLoaderContainer>
          <AltoSpinningLoader />
        </OptionLoaderContainer>
      ) : null}
    </ActionSheetV2>
  );
};
