// @owners { team: patients-team }
import { Button, LgSpacing, Row, XsSpacing, useScreenSize } from '@alto/design-system';
import React from 'react';
import getUpdateQuantityLoading from '~shared/selectors/quantityChange/getUpdateQuantityLoading';
import { useSelectorShared } from '~shared/store';

export type Props = {
  readonly onClose: () => void;
  readonly disabled: boolean;
  readonly isOptionsEmpty: boolean;
  readonly onConfirm: () => void;
};

export const ChangeQuantityFooter = ({ onClose, disabled, isOptionsEmpty, onConfirm }: Props) => {
  const { isSMScreenOrBigger, isMDScreenOrBigger } = useScreenSize();
  const isMobile = isSMScreenOrBigger && !isMDScreenOrBigger;
  const isLoading = useSelectorShared(getUpdateQuantityLoading);

  return (
    <Row
      wrapReverse={isMobile}
      wrap={false}
      flexGrow={1}
    >
      <Button
        width="full"
        type="secondary"
        onPress={onClose}
        label="Close"
      />
      {!isOptionsEmpty && (
        <>
          {isMobile ? <XsSpacing /> : <LgSpacing />}
          <Button
            width="full"
            onPress={onConfirm}
            label="Confirm"
            disabled={disabled}
            loading={isLoading}
          />
        </>
      )}
    </Row>
  );
};
