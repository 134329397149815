// @owners { team: patients-team }
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { fetchPaymentMethodsSucceeded } from '~shared/actions/paymentMethods';
import { get } from '~shared/helpers/apiHelper';
import { staleTimes } from '~shared/queries/constants';
import { useDispatchShared } from '~shared/store';
import { type PaymentMethod, type ReduxDispatchShared } from '~shared/types';

export const paymentMethods = createQueryKeys('paymentMethods', {
  fetchAll: ({ dispatch }: { dispatch: ReduxDispatchShared }) => ({
    queryKey: ['fetch-all-payment-methods'],
    queryFn: async (): Promise<PaymentMethod[]> => {
      const response = await get('/payment_methods');
      if (response.error) {
        throw new Error(response.error.message);
      }
      // TODO: payment method is in used in selector getExistingOrder.
      // Keep redux state in sync until usage is removed
      dispatch(fetchPaymentMethodsSucceeded(response));
      return response;
    },
  }),
});

/**
 * Fetch all payment methods for a patient
 * @returns Array of payment methods
 */
export const useGetPaymentMethods = ({ enabled } = { enabled: true }) => {
  const dispatch = useDispatchShared();
  const { data, isPending, isSuccess, error, refetch } = useQuery({
    ...paymentMethods.fetchAll({ dispatch }),
    enabled,
    staleTime: staleTimes.fifteenMinutes,
    gcTime: staleTimes.fifteenMinutes,
  });

  return {
    error,
    isPending,
    isSuccess,
    paymentMethods: data ?? [],
    refetch,
  };
};

/**
 * Fetch ACH payment methods for a patient
 * @returns Array of payment methods
 */
export const useGetAchPaymentMethods = () => {
  const dispatch = useDispatchShared();
  const getACHPaymentMethods = useCallback((methods: PaymentMethod[] = []) => {
    return methods.filter((method) => method.type === 'PlaidData');
  }, []);

  const { data, isPending, isSuccess, error } = useQuery({
    ...paymentMethods.fetchAll({ dispatch }),
    select: getACHPaymentMethods,
    staleTime: staleTimes.fifteenMinutes,
    gcTime: staleTimes.fifteenMinutes,
  });

  return {
    error,
    isPending,
    isSuccess,
    achPaymentMethods: data ?? [],
  };
};
