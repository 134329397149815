// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getEarliestAvailableDateForPrescription__DEPRECATED } from './getNextAvailableDateForPrescription';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';

// @deprecated - use /libs/shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions.ts
export const getRemoveBlockingMedMessagingForPrescription__DEPRECATED = createSelector(
  [getEarliestAvailableDateForPrescription__DEPRECATED, getPrescriptionByID],
  (earliestAvailableDate, prescription) => {
    if (!earliestAvailableDate) {
      return '';
    }

    const medicationName = prescription?.medication_name || 'this medication';

    return `Remove ${medicationName} from your cart.`;
  },
);
