import {
  type ActionHideActionSheet,
  type ActionSheetKey,
  type ActionShowActionSheet,
  HIDE_ACTION_SHEET,
  SHOW_ACTION_SHEET,
} from '~shared/actions/ui/actionSheet';
import { withRehydration } from '~shared/reducers/withRehydration';

type Action = ActionShowActionSheet | ActionHideActionSheet;

export type StateActionSheet = {
  key: ActionSheetKey | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  props: unknown | null | undefined;
};

const initialState: StateActionSheet = {
  key: null,
  props: null,
};

const actionSheet = (state: StateActionSheet = initialState, action: Action): StateActionSheet => {
  switch (action.type) {
    case SHOW_ACTION_SHEET:
      return {
        key: action.payload.key,
        props: action.payload.props,
      };

    case HIDE_ACTION_SHEET:
      return {
        key: null,
        props: null,
      };

    default:
      return state;
  }
};

export default withRehydration(actionSheet, initialState);
