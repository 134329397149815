// @owners { team: patients-team }
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '~shared/helpers/apiHelper';
import { wundercomMessages } from '~shared/queries/query-keys/wundercom-messages';
import { Sentry } from '~shared/sentry';
import { readOnlyWundercoms } from './queries';

export const useReadAllMessages = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async (supportCaseID?: number) => {
      await put('/wundercom_messages/read_all', { support_case_id: supportCaseID });
    },
    onError: (error: Error) => {
      Sentry.captureException(error);
    },
    onSuccess: (_, supportCaseID) => {
      if (supportCaseID) {
        queryClient.invalidateQueries({
          queryKey: wundercomMessages.fetchWundercomMessages({ supportCaseID }).queryKey,
        });
      } else {
        queryClient.invalidateQueries({ queryKey: readOnlyWundercoms._def });
      }
    },
  });
  return { readAllMutate: mutate };
};
