import { ActionSheetContext, Body, Column, FullViewEmptyState, MdSpacing } from '@alto/design-system';
import React, { useContext } from 'react';
import { useNavigateToAssistantLanding } from '../../alto-assistant/helpers/useNavigateToAssistantLanding';

const FallbackDescription = () => (
  <Column>
    <Body
      center
      fontFamily="semibold"
    >
      Please visit our Help Center or message support if the problem persists.
    </Body>
    <MdSpacing />
  </Column>
);

/**
 * The default error fallback used for main screens/pages. The CTA routes to the Help Center.
 */
export const PageErrorFallback = () => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  return (
    <FullViewEmptyState
      ctaText="Visit Help Center"
      description={<FallbackDescription />}
      onPressCTA={() => {
        closeActionSheet();
        navigateToAssistantLanding();
      }}
      title="Something went wrong"
    />
  );
};
