// @owners { team: patients-team }
import { ActionSheetContext, LoadingEmptyState, XlPadding } from '@alto/design-system';
import { useQueryLandingPageConfigs } from '@alto/features';
import React, { useContext, useEffect, useState } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchOnboardState, fetchWelcomeTrackerState, setTempPassword } from '~shared/actions/onboarding';
import { ONBOARD_STATE_DONE } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import VerifyActionSheet from '~web/features/onboarding/components/redesign/VerifyActionSheetContainer';
import { PartnershipLandingPage } from '~web/features/web-landing-page-tracker/components/redesign/PartnerLandingPage';
import { WebLandingPage } from '~web/features/web-landing-page-tracker/components/redesign/WebLandingPage';

type StateProps = {
  readonly loading: boolean;
  readonly tempPassword: string;
};

type DispatchProps = {
  readonly push: (path: string) => void;
  // eslint-disable-next-line import/no-deprecated
  readonly fetchOnboardState: (onboardingState: string) => void;
  // eslint-disable-next-line import/no-deprecated
  readonly fetchWelcomeTrackerState: (tempPassword: string) => void;
  readonly setTempPassword: (tempPassword: string) => void;
};

type Props = StateProps & DispatchProps;

const WebLandingPageTrackerContainer = ({
  tempPassword,
  push,
  fetchOnboardState,
  fetchWelcomeTrackerState,
  setTempPassword,
  loading,
}: Props) => {
  const { trackPageView } = useAnalytics();
  const [providerName, setProviderName] = useState(null);
  const [hasFetchedTrackerState, setHasFetchedTrackerState] = useState(false);
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { isFetching, isSuccess, partnershipName } = useQueryLandingPageConfigs({ tempPassword });

  useEffect(() => {
    setTempPassword(tempPassword);
    // @ts-expect-error TS(7006): Parameter 'result' implicitly has an 'any' type.
    // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression, promise/always-return
    fetchWelcomeTrackerState(tempPassword).then((result) => {
      setProviderName(result.provider_name);
      setHasFetchedTrackerState(true);
    });
  }, [tempPassword, fetchWelcomeTrackerState, setTempPassword]);

  useEffect(() => {
    if (!hasFetchedTrackerState) return;
    trackPageView({
      event: EVENTS.ONBOARDING__WEB_LANDING_PAGE_VIEWED,
      params: {
        providerNameShown: providerName,
      },
      additionalFields: {
        userIdentifier: tempPassword,
        userIdentifierType: 'temp_password',
      },
    });
  }, [trackPageView, tempPassword, providerName, hasFetchedTrackerState]);

  const handleGetStarted = () => {
    // @ts-expect-error TS(7006): Parameter 'result' implicitly has an 'any' type.
    // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression
    fetchOnboardState(tempPassword).then((result) => {
      const onboardState = result.onboard_state;

      // If the user has already completed onboarding, redirect to login. Otherwise, show verify modal/drawer
      // eslint-disable-next-line promise/always-return
      if (onboardState === ONBOARD_STATE_DONE) {
        push('/');
      } else {
        setActiveActionSheet(
          <VerifyActionSheet
            tempPassword={tempPassword}
            onboardState={onboardState}
            onVerifySuccess={closeActionSheet}
          />,
        );
      }
    });
  };

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  // Onboarding Platform moves the copy and illustration to the backend.
  // If the onboarding config partnership is non-alto, use the partner
  // specific landing page. If not use the default Alto landing page.
  if (isSuccess && partnershipName !== 'alto') {
    return (
      <PartnershipLandingPage
        handleGetStarted={handleGetStarted}
        getStartedLoading={loading}
        tempPassword={tempPassword}
      />
    );
  }

  return (
    <WebLandingPage
      handleGetStarted={handleGetStarted}
      providerName={providerName}
      loading={loading}
      tempPassword={tempPassword}
    />
  );
};

type OwnProps = {
  params: {
    tempPassword: string;
  };
};

const mapStateToProps = (state: ReduxStateShared, ownProps: OwnProps): StateProps => {
  const { fetchOnboardStateLoading } = getLoading(state);

  return {
    loading: fetchOnboardStateLoading,
    tempPassword: ownProps.params?.tempPassword,
  };
};

const mapDispatchToProps: DispatchProps = {
  push,
  // eslint-disable-next-line import/no-deprecated
  fetchOnboardState,
  // eslint-disable-next-line import/no-deprecated
  fetchWelcomeTrackerState,
  setTempPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebLandingPageTrackerContainer);
