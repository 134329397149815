// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';

const getShipmentID = createSelector([getIsEditingExistingOrder, getEditShipmentID], (isEditingOrder, shipmentID) => {
  // editOrderShipmentID shouldn't be present unless isEditingOrder is true,
  // but taking an extra precaution just in case.
  if (isEditingOrder) {
    return shipmentID;
  }

  return undefined;
});

export default getShipmentID;
