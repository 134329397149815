import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Button,
  Card,
  LgPadding,
  ListBase,
  Pressable,
  Row,
  Tag,
} from '@alto/design-system';
import { RenewalRequestActionSheet, RenewalRequestStatusActionSheet } from '@alto/features';
import { type RenewalPrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/renewal_prescription';
import React, { type RefObject, useContext } from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { PrescriptionInfo } from '~web/features/homescreen/sections/PrescriptionInfo';
import { useTrackNodeSeen } from '~web/hooks';
import RefillIllustration from '~web/images/autoRefill@2x.png';

const RefAnchor = styled.div``;

const CustomCard = styledNative(Card)`
  margin: 0 0 0;
  z-index: 0;
  position: relative;
`;

type RowProps = {
  readonly prescription: RenewalPrescription;
};

const OutOfRefillCardRow = ({ prescription }: RowProps) => {
  const { dosage, family_member_name, id, image_url, name, renewal_request_id } = prescription;
  const legacyPrescription = useSelectorShared((state) => getPrescriptionByID(state, prescription.id));
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const isCTAButton = !renewal_request_id;

  const fetchButton = () => {
    if (isCTAButton) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          role="button"
          tabIndex={0}
        >
          <Row>
            <Button
              small
              onPress={() => {
                setActiveActionSheet(<RenewalRequestActionSheet prescriptionIDs={[prescription.id]} />);
              }}
              label="Get more refills"
            />
          </Row>
        </div>
      );
    }

    if (!isCTAButton && legacyPrescription) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          role="button"
          tabIndex={0}
        >
          <Pressable
            backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
            onPress={() => {
              setActiveActionSheet(<RenewalRequestStatusActionSheet prescription={legacyPrescription} />);
            }}
          >
            <Tag
              right
              icon={<AltoIcon name="chevrondown-small" />}
              label="Refill Requested"
            />
          </Pressable>
        </div>
      );
    }

    return <Tag label="Refill Requested" />;
  };

  const CTA = fetchButton();

  return (
    <PrescriptionInfo
      key={id}
      byline={family_member_name || undefined}
      CTA={CTA}
      isCTAButton={isCTAButton}
      prescriptionID={id}
      origin={ORIGIN_NAMES.HOMESCREEN_RENEWALS}
      title={name}
      description={dosage.toLowerCase() || ''}
      imageURL={image_url || undefined}
    />
  );
};

type OutOfRefillsCardProps = {
  readonly renewalPrescriptions: RenewalPrescription[];
};

export const OutOfRefillsCard = ({ renewalPrescriptions }: OutOfRefillsCardProps) => {
  const dispatch = useDispatchShared();

  const refNode = useTrackNodeSeen({
    onSeen: () => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(EVENTS.HOMESCREEN__RENEWALS_VIEWED, {
            num_rx_without_renewal_request: renewalPrescriptions.filter((rx) => !rx.renewal_request_id).length,
            num_rx_total: renewalPrescriptions.length,
          }),
        ),
      );
    },
  });

  const Rows = renewalPrescriptions.map((prescription) => ({
    key: prescription.id,
    component: (
      <OutOfRefillCardRow
        key={prescription.id}
        prescription={prescription}
      />
    ),
  }));

  return (
    <RefAnchor ref={refNode as RefObject<HTMLDivElement>}>
      <CustomCard
        title="Out of refills"
        illustrationSrc={RefillIllustration}
        illustrationAccessibilityLabel={IMG_ALT_TEXTS.insuranceCard}
      >
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <ListBase items={Rows} />
        </LgPadding>
      </CustomCard>
    </RefAnchor>
  );
};
