// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { type ReduxStateShared, type User } from '~shared/types';

const getUserOnboardState: (arg0: ReduxStateShared) => string | null | undefined = createSelector(
  getCurrentUser,
  (user: User | null | undefined): string | null | undefined => {
    return user?.onboard_state;
  },
);

export default getUserOnboardState;
