import { createSelector } from 'reselect';
import { getIsFertilityUser } from '~shared/features/users/selectors/getIsFertilityUser';
import { getIsProgynyUser } from '~shared/features/users/selectors/getIsProgynyUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { type ReduxStateShared } from '~shared/types';

export const getUserIsEligibleForQuantityChange = createSelector(
  [getIsFertilityUser, getIsProgynyUser],
  (isFertilityUser, isProgynyUser) => {
    return isFertilityUser && !isProgynyUser;
  },
);

export const getCurrentUserIsEligibleForQuantityChange = (state: ReduxStateShared) => {
  const userID = getCurrentUserID(state);
  return getUserIsEligibleForQuantityChange(state, { userID });
};
