// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { getMailCopy, isMail, isPickup } from '~shared/helpers/order';
import { type AltoIconName } from '../../../../design-library-tokens';

type Props = {
  readonly method?: DeliveryMethod | null;
  readonly status?: APIOrderStatus | null;
  readonly showDefault?: boolean; //show default copy if method is not provided
};

const getIcon = ({ method, status }: Props): AltoIconName => {
  if (status === 'failed') {
    return 'cancel';
  }
  if (isPickup(method?.mode)) {
    return 'handbox';
  }
  if (isMail(method?.mode)) {
    return 'box';
  }
  return 'car';
};

const getText = ({ method, status }: Props) => {
  // failed
  // assume this only happens to deliveries (courier + mail)
  if (status === 'failed') {
    return 'Failed delivery';
  }

  // pick up logic
  if (isPickup(method?.mode)) {
    return status !== 'delivered' ? 'Pick up order' : 'Picked up';
  }

  // mail logic
  if (isMail(method?.mode)) {
    return getMailCopy(method, status);
  }

  //courier logic + default
  return status !== 'delivered' ? 'Arrives via courier' : 'Delivered via courier';
};

export const FulfillmentMethodRow = ({ method, status, showDefault = true }: Props) => {
  // if no method and showDefault is false, then return nothing
  if (!showDefault && !method) return null;

  return (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name={getIcon({ method, status })}
          type="grey"
        />
      }
      text={getText({ method, status })}
    />
  );
};
