// @owners { team: patients-team }
import { BillableTypeMap } from '@alto/billing_api/types/v1/index';
import { type PaymentBreakdown } from '@alto/scriptdash/alto/pricing/types/v3';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

export const useTrackPaymentBreakdownItemViewed = ({
  eventParams,
  paymentBreakdown,
}: {
  eventParams: Record<string, any>;
  paymentBreakdown?: PaymentBreakdown;
}) => {
  const { trackPageView } = useAnalytics();
  const params: Record<string, any> = {
    // uuid to enable querying 'item viewed' events for given 'payment breakdown viewed' event
    breakdown_key: eventParams.breakdownKey,
    estimated: eventParams.estimated,
    unavailable: eventParams.unavailable,
    origin: eventParams.origin,
  };
  if (paymentBreakdown) {
    params.base_price = paymentBreakdown.cash_price;
    params.additional_savings_amount = paymentBreakdown.remainder_paid;
    params.savings_applied_types = [...new Set(paymentBreakdown.payers.map((payer) => payer.billable_type))].sort();
    params.total_price = paymentBreakdown.total;
    params.total_insurance = paymentBreakdown.insurance_payers_paid;
    params.total_savings =
      paymentBreakdown.non_insurance_payers_paid +
      paymentBreakdown.remainder_paid +
      paymentBreakdown.pending_progyny_coverage;

    paymentBreakdown.payers.forEach((payer) => {
      switch (payer.billable_type) {
        case BillableTypeMap.DEAL: {
          params.deal_payer_name = payer.name;
          params.deal_paid_amount = payer.amount_covered;
          break;
        }
        case BillableTypeMap.VOUCHER: {
          params.voucher_payer_name = payer.name;
          params.voucher_paid_amount = payer.amount_covered;
          break;
        }
        // There should only be up to 3 payers with these billable types. They are applied
        // before deals or vouchers, so should have applied_order 0, 1, or 2
        case BillableTypeMap.INSURANCE:
        case BillableTypeMap.REBATE:
        case BillableTypeMap.COUPON:
        case BillableTypeMap.SAVINGS_CARD: {
          const prefix = ['primary', 'secondary', 'tertiary'][payer.applied_order] ?? payer.applied_order;
          params[prefix + '_payer_name'] = payer.name;
          params[prefix + '_payer_paid'] = payer.amount_covered;
          params[prefix + '_payer_type'] = payer.billable_type;
          break;
        }
      }
    });
  }
  trackPageView({
    event: EVENTS.PAYMENT_BREAKDOWN_ITEM_VIEWED,
    params,
    additionalFields: { prescriptionId: eventParams.prescriptionID },
  });
};
