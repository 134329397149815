// @owners { team: patients-team }
import { addDays, format, isSameDay, isToday, parse } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';

export const formattedNextAvailableDate = (
  earliestAvailableDate: string,
  earliestAvailableReason: string,
  lineBreakDate?: boolean | null,
  excludeDayOfWeek?: boolean,
): string | null | undefined => {
  if (earliestAvailableReason === 'out_of_zone') {
    return 'Unavailable';
  }

  if (!earliestAvailableDate) {
    return undefined;
  }

  const parsedDate = parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const today = new Date();
  const tomorrow = addDays(today, 1);

  if (isToday(parsedDate)) {
    if (lineBreakDate) {
      return `Available Today\n${format(today, 'MMM do')}`;
    }

    return `Available Today, ${format(today, 'MMM do')}`;
  }

  if (isSameDay(parsedDate, tomorrow)) {
    if (lineBreakDate) {
      return `Available Tomorrow\n${format(tomorrow, 'MMM do')}`;
    }

    return `Available Tomorrow, ${format(tomorrow, 'MMM do')}`;
  }

  if (excludeDayOfWeek) {
    return `Available ${format(parsedDate, 'MMM do')}`;
  }

  return `Available on ${format(parsedDate, 'eee, MMM do')}`;
};
