// @owners { team: patients-team }
import { memoize } from 'lodash';
import { createSelector } from 'reselect';
import { getIsNeedsInsuranceForMedication } from './getIsNeedsInsuranceForMedication';
import { getIsProcessingInsuranceForMedication } from './getIsProcessingInsuranceForMedication';
import { RENEWAL_STATE_PENDING, getRenewalStatesForPrescriptions } from './getRenewalStateForPrescription';
import { getScheduleablePrescriptions } from './getScheduleablePrescriptions';
import { PRESCRIPTION_STATES } from '~shared/constants';
import { getUpcomingDeliveries } from '~shared/features/delivery/selectors/getUpcomingDeliveries';
import {
  ARCHIVED,
  DISCONTINUED,
  MULTIPLE_ACTIVE_RX,
  MULTIPLE_ACTIVE_RX_READY,
  MULTIPLE_PRICES,
  MULTIPLE_RX,
  NEEDS_INSURANCE,
  OUT_OF_FILLS,
  PROCESSING,
  PROCESSING_INSURANCE,
  RENEWAL_PENDING,
  RENEWED,
  RX_EXPIRED,
  RX_READY,
  SCHEDULED,
  TRANSFERRED_OUT,
} from '~shared/features/my-meds/constants';
import { type MedicationTagKey } from '~shared/features/my-meds/types';
import { isActiveRx } from '~shared/features/prescriptions/helper';
import getMedicationHasPriceOptions from '~shared/features/price-options/selectors/getMedicationHasPriceOptions';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import {
  type PriceInfo,
  pricingInfoForPrescription,
} from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { formatRelativeDateWithDow } from '~shared/helpers/date';
import { type MedicationParam } from '~shared/selectors/selectorParamTypes';
import { type Prescription, type ReduxStateShared } from '~shared/types';

const getMedication = (_: ReduxStateShared, props: MedicationParam) => props.medication;

type TagDataMultipleActiveRxReady = {
  'schedulable rxs'?: Prescription[];
};
type TagDataMultipleActiveRx = {
  'active rxs': Prescription[];
};
type TagDataRxReady = {
  price: string;
  priceLabel: string;
};
export type TagDataScheduled = {
  date: string;
  deliver_after: string | undefined;
  deliver_before: string | undefined;
  shipment_id: number | undefined;
};
export type TagKeyMultipleActiveRxReady = {
  key: 'multiple_active_rx_ready';
  data: TagDataMultipleActiveRxReady;
};
export type TagKeyMultipleActiveRx = {
  key: 'multiple_active_rx';
  data: TagDataMultipleActiveRx;
};
export type TagKeyRxReady = {
  key: 'rx_ready';
  data: TagDataRxReady;
};
export type TagKeyScheduled = {
  key: 'scheduled';
  data: TagDataScheduled;
};
type RemainingTagKeys = Exclude<
  MedicationTagKey,
  'multiple_active_rx_ready' | 'multiple_active_rx' | 'ready' | 'scheduled'
> | null;
export type TagKeyBasic = {
  key: RemainingTagKeys;
  data: Record<string, never>;
};

export type TagKey =
  | TagKeyMultipleActiveRxReady
  | TagKeyMultipleActiveRx
  | TagKeyRxReady
  | TagKeyScheduled
  | TagKeyBasic;

const emptyData = {} as const;

const generateBasicTagKey = memoize((key: RemainingTagKeys): TagKeyBasic => {
  return {
    key,
    data: emptyData,
  };
});

const multipleActiveRxReadyTagKey = memoize(
  (readyToSchedule: Prescription[]): TagKeyMultipleActiveRxReady => {
    return {
      key: MULTIPLE_ACTIVE_RX_READY,
      data: {
        'schedulable rxs': readyToSchedule,
      },
    };
  },
  (readyToSchedule) => readyToSchedule.map((prescription) => prescription.id).join(':'),
);

const multipleActiveRxTagKey = memoize(
  (activePrescriptions: Prescription[]): TagKeyMultipleActiveRx => {
    return {
      key: MULTIPLE_ACTIVE_RX,
      data: {
        'active rxs': activePrescriptions,
      },
    };
  },
  (prescriptions) => prescriptions.map((prescription) => prescription.id).join(':'),
);

const rxReadyTagKey = memoize(
  (price: string | null | undefined, priceLabel: string | null | undefined): TagKeyRxReady => {
    return {
      key: RX_READY,
      data: {
        price: price ?? '',
        priceLabel: priceLabel ?? '',
      },
    };
  },
);

const scheduleTagKey = memoize(
  (date, deliver_after: string | undefined, deliver_before?: string, shipment_id?: number): TagKeyScheduled => {
    return {
      key: SCHEDULED,
      data: {
        date: formatRelativeDateWithDow(date || ''),
        deliver_after,
        deliver_before,
        shipment_id,
      },
    };
  },
  (date, deliver_after, deliver_before, shipment_id) => `${date}:${deliver_after}:${deliver_before}:${shipment_id}`,
);

export const makeGetMedicationTagKey = createSelector(
  [
    getIsNeedsInsuranceForMedication,
    getIsProcessingInsuranceForMedication,
    getMedication,
    getScheduleablePrescriptions,
    getUpcomingDeliveries,
    getRenewalStatesForPrescriptions,
    getMedicationHasPriceOptions,
    getPrescriptionPricing,
  ],
  (
    isNeedsInsuranceForMedication,
    isProcessingInsuranceForMedication,
    medication,
    scheduleablePrescriptions,
    upcomingDeliveries,
    renewalStates,
    medicationHasPriceOptions,
    prescriptionPricing,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): TagKey => {
    const scheduleablePrescriptionIDs = scheduleablePrescriptions.map((prescription) => prescription.id);
    const activePrescriptions = medication?.prescriptions?.filter(isActiveRx) || [];
    const [firstActivePrescription] = activePrescriptions;
    const inactivePrescriptions = medication?.prescriptions?.filter((prescription) => !isActiveRx(prescription)) || [];
    const [firstInactivePrescription] = inactivePrescriptions;

    // Needs Insurance
    if (isNeedsInsuranceForMedication) {
      return generateBasicTagKey(NEEDS_INSURANCE);
    }

    if (isProcessingInsuranceForMedication) {
      return generateBasicTagKey(PROCESSING_INSURANCE);
    }

    // Multiple Rxs
    if (activePrescriptions.length > 1) {
      const readyToSchedule = activePrescriptions.filter((prescription) =>
        scheduleablePrescriptionIDs.includes(prescription.id),
      );

      if (readyToSchedule.length > 0) {
        return multipleActiveRxReadyTagKey(readyToSchedule);
      }

      return multipleActiveRxTagKey(activePrescriptions);
    }

    if (inactivePrescriptions.length > 1 && activePrescriptions.length === 0) {
      return generateBasicTagKey(MULTIPLE_RX);
    }

    // One Rx
    // @ts-expect-error TS(7034): Variable 'prescription' implicitly has type 'any' in some locations where its type cannot be determi... (Delete me to see the full error)
    let prescription;

    if (activePrescriptions.length === 1) {
      prescription = firstActivePrescription;
    } else if (inactivePrescriptions.length === 1) {
      prescription = firstInactivePrescription;
    } else {
      // This really shouldn't happen
      return generateBasicTagKey(null);
    }

    const upcomingDeliveriesForPrescription = upcomingDeliveries.filter(
      // @ts-expect-error TS(7005): Variable 'prescription' implicitly has an 'any' type.
      (delivery) => prescription && delivery.prescription_id === prescription.id,
    );

    if (upcomingDeliveriesForPrescription.length > 0) {
      const firstDeliveryForPrescription = upcomingDeliveriesForPrescription.sort(
        // @ts-expect-error TS(2345): Argument of type '(a: LightDelivery, b: LightDelivery) => number | undefined' is not assignable to p... (Delete me to see the full error)
        (a, b) => a.date?.localeCompare(b.date),
      )[0];

      const { date, deliver_after, deliver_before, shipment_id } = firstDeliveryForPrescription;
      return scheduleTagKey(date, deliver_after, deliver_before, shipment_id);
    }

    if (scheduleablePrescriptionIDs.includes(prescription.id)) {
      const pricingInfo = pricingInfoForPrescription(prescriptionPricing[prescription.id]);
      const priceInfo = pricingInfo.length ? pricingInfo[0] : ({} as PriceInfo);

      if (pricingInfo.length > 1 || medicationHasPriceOptions) {
        return generateBasicTagKey(MULTIPLE_PRICES);
      }

      return rxReadyTagKey(priceInfo?.price, priceInfo?.priceLabel);
    }

    if (renewalStates[prescription.id] && renewalStates[prescription.id] === RENEWAL_STATE_PENDING) {
      return generateBasicTagKey(RENEWAL_PENDING);
    }

    switch (prescription.active_state) {
      case PRESCRIPTION_STATES.EXPIRED:
        return generateBasicTagKey(RX_EXPIRED);

      case PRESCRIPTION_STATES.RENEWED:
        return generateBasicTagKey(RENEWED);

      case PRESCRIPTION_STATES.NO_REFILLS:
        return generateBasicTagKey(OUT_OF_FILLS);

      case PRESCRIPTION_STATES.TRANSFERRED:
        return generateBasicTagKey(TRANSFERRED_OUT);

      case PRESCRIPTION_STATES.DISCONTINUED:
        return generateBasicTagKey(DISCONTINUED);

      case PRESCRIPTION_STATES.ARCHIVED:
        return generateBasicTagKey(ARCHIVED);

      default:
        return generateBasicTagKey(PROCESSING);
    }
  },
);
