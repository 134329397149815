import type { Errback, Unsub } from '~shared/types/websocket';
/*
 * ErrorChannel is a simplified interface of `Channel` that is strictly responsible for
 * managing error callbacks. Errors are published if:
 *   - a websocket connection is unexpectedly closed.
 *   - a websocket connection's `onerror` callback is fired.
 */

export default class ErrorChannel {
  subscriptions: Errback[];

  constructor() {
    this.subscriptions = [];
  }

  publish(e: Event) {
    this.subscriptions.forEach((cb) => {
      cb(e);
    });
  }

  subscribe(subscriber: Errback): Unsub {
    this.subscriptions.push(subscriber);

    return () => {
      this.unsubscribe(subscriber);
    };
  }

  unsubscribe(subscriber: Errback) {
    const idx = this.subscriptions.indexOf(subscriber);

    if (idx > -1) {
      this.subscriptions.splice(idx, 1);
    }
  }
}
