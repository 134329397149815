// @owners { team: patients-team }
import React from 'react';
import YouTube, { type Options } from 'react-youtube';

type VideoPlayerProps = {
  readonly height: number;
  readonly width?: number;
  readonly youtubeVideoID: string;
  readonly onPlayVideo?: () => void;
};

export const VideoPlayer = ({ height, width, youtubeVideoID, onPlayVideo }: VideoPlayerProps) => {
  return (
    <YouTube
      videoId={youtubeVideoID}
      onPlay={onPlayVideo}
      onStateChange={onPlayVideo}
      opts={
        {
          height: height.toString(),
          width: width?.toString(),
        } as Options
      }
    />
  );
};
