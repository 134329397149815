// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getDeliveriesForShipmentID, { getDeliveriesByShipment } from './getDeliveriesForShipmentID';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { type Item } from '~shared/features/checkout/types';
import DeliveryItem from '~shared/features/delivery-info/deliveryItem';
import {
  getMedicationsFromPrescriptions,
  medicationKeyForPrescription,
} from '~shared/features/my-meds/selectors/getMedicationsFromPrescriptions';
import { type Medication } from '~shared/features/my-meds/types';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { type OrderPricing } from '~shared/features/pricing/types';
import { type LightDelivery as Delivery, type Prescription, type ReduxStateShared } from '~shared/types';

export function deliveryToItem(
  delivery: Delivery,
  prescriptions: Prescription[],
  medications: Medication[],
  orderPricing: OrderPricing,
) {
  const prescription = prescriptions.find((p) => p.id === delivery.prescription_id);

  if (prescription) {
    const key = medicationKeyForPrescription(prescription);
    const medication = medications.find((m) => m.key === key);

    if (medication) {
      return new DeliveryItem({
        resource_id: delivery.id,
        delivery,
        medication,
        prescription,
        pricing: orderPricing.item_pricing[prescription.id],
      });
    }
  }

  return null;
}

export const getDeliveryItemsForShipment = createSelector(
  [getDeliveriesForShipmentID, getPrescriptions, getMedicationsFromPrescriptions, getShipmentPricing],
  (deliveries, prescriptions, medications, orderPricing) => {
    return deliveries.reduce<DeliveryItem[]>((items, delivery) => {
      const item = deliveryToItem(delivery, prescriptions, medications, orderPricing);

      if (item) {
        items.push(item);
      }

      return items;
    }, []);
  },
);

const getPrescriptionID = (_: ReduxStateShared, props: { prescriptionID: number }) => props.prescriptionID;

export const getPricingForPrescriptionFromDeliveryItemsForShipment = createSelector(
  [getDeliveryItemsForShipment, getPrescriptionID],
  (deliveryItems, prescriptionID) => {
    const deliveryItem = deliveryItems.find(({ prescription }) => prescription.id === prescriptionID);

    if (!deliveryItem) return undefined;
    return deliveryItem.pricing;
  },
);

type DeliveryItemsByShipment = Record<number, Item[]>;

export const getDeliveryItemsByShipment = createSelector(
  [getDeliveriesByShipment, getPrescriptions, getMedicationsFromPrescriptions, getShipmentPricing],
  (deliveries, prescriptions, medications, orderPricing) => {
    const itemsByShipment: DeliveryItemsByShipment = {};
    TypescriptUtils.objectKeys(deliveries).forEach((shipment_id) => {
      itemsByShipment[shipment_id] = [];
      deliveries[shipment_id].forEach((d) => {
        const item = deliveryToItem(d, prescriptions, medications, orderPricing);

        if (item) {
          itemsByShipment[shipment_id].push(item);
        }
      });
    });
    return itemsByShipment;
  },
);
