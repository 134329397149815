// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, ExternalLink, LgPadding, SmSpacing } from '@alto/design-system';
import React from 'react';

const ReturningUnusedMedicationContent = () => (
  <>
    <Body>
      Due to safety concerns, we are unable to accept returns of medications once they have left our pharmacy.
    </Body>
    <SmSpacing />
    <Body>
      Learn more about your options for safely disposing of your medications at{' '}
      <ExternalLink href="https://www.fda.gov/drugs/disposal-unused-medicines-what-you-should-know/drug-disposal-drug-take-back-locations/">
        https://www.fda.gov/drugs/disposal-unused-medicines-what-you-should-know/drug-disposal-drug-take-back-locations/
      </ExternalLink>
    </Body>
  </>
);

export const ReturningUnusedMedicationActionSheet = () => {
  return (
    <ActionSheetV2
      title="Returning unused medication"
      analyticsName="assistant returning unused medication"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <ReturningUnusedMedicationContent />
      </LgPadding>
    </ActionSheetV2>
  );
};
