// @owners { team: patients-team }
import { getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useSelectorShared } from '~shared/store';
import { getOrderBundlingCheckoutOrigin, useOrderBundlingOptions } from './useOrderBundlingOptions';

export const useOrderBundlingOptionsCurrentRoute = () => {
  const address = useSelectorShared(getOrderAddress);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const orderBundlingOrigin = getOrderBundlingCheckoutOrigin({ isEditingOrder });

  return useOrderBundlingOptions({
    origin: orderBundlingOrigin,
    address_id: address?.id,
  });
};
