// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Body, FlatCard, LgPadding, ListDescription, ListItem } from '@alto/design-system';
import React from 'react';
import { useCartNextAvailableDateMessaging } from '~shared/features/cart/hooks/useCartNextAvailableDateMessaging';
import { usePrescriptionNextAvailableDateMessaging } from '~shared/features/cart/hooks/usePrescriptionNextAvailableDateMessaging';
import { type OrderTypeContext } from '../../../../index';
import { getNextAvailableDateTitle } from '../../../checkout/components/delivery-date-card/helpers';

type Props = {
  readonly date: string | null;
  readonly prescriptionID: number;
  readonly orderTypeContext?: OrderTypeContext;
};

export const NextAvailableDatePanel = ({ date, prescriptionID, orderTypeContext }: Props) => {
  const { message: reasonMessageForCheckout } = useCartNextAvailableDateMessaging({
    prescriptionID,
    excludeDateFromMessage: true,
  });
  const { message: reasonMessageForPrescription } = usePrescriptionNextAvailableDateMessaging({
    prescriptionID,
    excludeDateFromMessage: true,
  });

  const reasonMessage = orderTypeContext === 'Checkout' ? reasonMessageForCheckout : reasonMessageForPrescription;

  return (
    <LgPadding
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      <FlatCard>
        <ListItem
          title={getNextAvailableDateTitle(date)}
          descriptions={
            <ListDescription>
              <Body>{reasonMessage}</Body>
            </ListDescription>
          }
        />
      </FlatCard>
    </LgPadding>
  );
};
