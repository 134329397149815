// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { useEffect } from 'react';
import { updateOrder } from '~shared/actions/cart';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getIsOrderPickupMethod } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { isMail } from '~shared/helpers/order';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useCheckoutAddressInfoKeys } from './useCheckoutAddressInfoKeys';

export const useSignatureConfirmation = (): {
  // Whether to show the UI for waiving the signature or adding a safe place
  homeToSignConfirmationRequired: boolean;
  // Whether a signature is required. Will still be true if the signature is waivable.
  signatureRequired: boolean;
} => {
  const dispatch = useDispatchShared();
  const orderIndex = useSelectorShared(getOrderIndex);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const { hasCompounds } = usePrescriptionsInCart();
  const { keys: addressInfoKeys, homeToSignConfirmationRequired } = useCheckoutAddressInfoKeys();
  const { deliveryMethods } = useCartDeliveryMethods();
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];

  // hide the home to sign confirmation for compound meds
  const isMailedCompound = orderIndex === 0 && hasCompounds && isMail(deliveryMethod?.mode) && !isOrderPickupMethod;

  // set the home to sign confirmation to waive for shipment with compound med
  // home to sign confirmations are not passed onto compounding pharmacies
  useEffect(() => {
    if (isMailedCompound) {
      dispatch(updateOrder({ home_to_sign_confirmation: 'WAIVE' }));
    }
  }, [dispatch, isMailedCompound]);

  useEffect(() => {
    if (isOrderPickupMethod) {
      dispatch(updateOrder({ home_to_sign_confirmation: 'WAIVE' }));
    }
  }, [dispatch, isOrderPickupMethod]);

  if (addressInfoKeys.out_of_zone || isOrderPickupMethod) {
    return {
      homeToSignConfirmationRequired: false,
      signatureRequired: false,
    };
  }

  if (isMail(deliveryMethod?.mode)) {
    const isHomeToSignConfirmationRequired =
      !addressInfoKeys.has_controls && !isMailedCompound && homeToSignConfirmationRequired;

    return {
      homeToSignConfirmationRequired: isHomeToSignConfirmationRequired,
      signatureRequired: homeToSignConfirmationRequired,
    };
  }

  if (addressInfoKeys.has_controls) {
    return {
      homeToSignConfirmationRequired: false,
      signatureRequired: true,
    };
  }

  if (addressInfoKeys.expensive) {
    return {
      homeToSignConfirmationRequired: true,
      signatureRequired: homeToSignConfirmationRequired,
    };
  }

  if (addressInfoKeys.no_safe_place) {
    return {
      homeToSignConfirmationRequired: true,
      signatureRequired: homeToSignConfirmationRequired,
    };
  }

  return {
    homeToSignConfirmationRequired: false,
    signatureRequired: false,
  };
};
