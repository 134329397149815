import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { v4 as uuidv4 } from 'uuid';
import { Anchor } from '../../containers';
import { type ToastElement } from './Toast';
import { ToastContext } from './ToastContext';

export const MAX_TOASTS = 5;

type ToastItem = {
  id: string;
  toast: ToastElement;
};

type ToastProviderProps = {
  readonly children: React.ReactNode;
  /**
   * Offset from the top or bottom of the screen where the toasts will be rendered
   *
   * If the platform is web, this will be top
   *
   * If the platform is not web, this will be bottom
   */
  readonly offset?: number;
};

const StyledAnchor = styled(Anchor)`
  align-items: center;
`;

export const ToastProvider = ({ children, offset }: ToastProviderProps) => {
  const [toasts, setToasts] = React.useState<ToastItem[]>([]);

  const addToast = React.useCallback((toast: ToastElement) => {
    setToasts((prevStack) => [...prevStack.slice(1 - MAX_TOASTS), { id: uuidv4(), toast }]);
  }, []);

  const clearToasts = React.useCallback(() => {
    setToasts([]);
  }, []);

  const context = React.useMemo(
    () => ({
      addToast,
      clearToasts,
    }),
    [addToast, clearToasts],
  );

  return (
    <ToastContext.Provider value={context}>
      {toasts.length > 0 && (
        <StyledAnchor
          top={Platform.OS === 'web' ? true : undefined}
          offset={offset}
          pointerEvents="box-none"
        >
          {toasts.map(({ id, toast }) => (
            <React.Fragment key={id}>{toast}</React.Fragment>
          ))}
        </StyledAnchor>
      )}
      {children}
    </ToastContext.Provider>
  );
};
