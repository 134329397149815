// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Body, Pressable, Row } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';

export const AddMedicationButton = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const navigateToAddMedications = () => {
    trackEvent({ event: EVENTS.ADD_MED_CLICKED });
    navigate('RouteTransferPrescription', { origin: ORIGIN_NAMES.ADD_MED_BUTTON });
  };

  return (
    <Pressable onPress={navigateToAddMedications}>
      <Row
        centerVertically
        gap="XXS"
      >
        <AltoIcon name="pluscircle-small" />
        <Row flexShrink={1}>
          <Body
            fontFamily="semibold"
            color={COLORS.TEXT_COLORS.PRIMARY}
          >
            Add meds
          </Body>
        </Row>
      </Row>
    </Pressable>
  );
};
