// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  InfoBox,
  InfoText,
  InputBlockText,
  InputCheckbox,
  InputRow,
  Link,
  LoadingButton,
  Paragraph,
  validatePassword,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldInputProps, type FieldMetaProps, type FieldProps, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import {
  ALTO_PATIENT_NOTICE_URL,
  DEFAULT_ERROR_ALERT_MESSAGE,
  PRIVACY_POLICY_URL,
  PRIVACY_PRACTICES_URL,
  TERMS_OF_SERVICE_URL,
  UNDER_AGE_EIGHTEEN_ERROR_MESSAGE,
} from '~shared/constants';
import { formatDateInput, isUnderAgeEighteen } from '~shared/helpers/date';
import { formatPhone } from '~shared/helpers/helper';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type APIError } from '~shared/types/APIError';
import { validateEmail } from '~shared/validations';
import { PhoneSupport } from '~web/constants';

export type FormFields = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  phone: string;
  email: string;
  password: string;
  terms: Record<string, unknown>;
  referral_code: string;
};

type DefaultProps = {
  readonly authError: APIError | null;
};

type Errors = {
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  email?: string;
  terms?: string;
  referral_code?: string;
};

type Props = DefaultProps & {
  readonly errors: Errors;
  readonly loading: boolean;
  readonly onSubmit: (values: FormFields) => void;
};

type SignUpErrors = {
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  email?: string;
  phone?: string;
  password?: string;
  terms?: string;
};

const SignupForm = ({ authError, errors, loading, onSubmit }: Props) => {
  const { trackPageView } = useAnalytics();
  const displayingAgeValidationError = errors?.date_of_birth === UNDER_AGE_EIGHTEEN_ERROR_MESSAGE;
  const errorMessage = authError?.details?.message;
  let validationErrors: SignUpErrors = {};

  useEffect(() => {
    trackPageView({
      event: EVENTS.SIGNUP_CREATE_ACCOUNT_VIEWED,
    });
  }, [trackPageView]);

  const onSubmitForm = (values: FormFields) => {
    const valid = !Object.keys(validationErrors).length;

    if (valid) {
      onSubmit(values);
    }
  };

  const validate = (formFields: FormFields) => {
    const { first_name, last_name, date_of_birth, email, terms, phone, password } = formFields;
    const firstNameError = validatePresentWithMessage('First name')(first_name);
    const lastNameError = validatePresentWithMessage('Last name')(last_name);
    const dobError = validatePresentWithMessage('Birthdate')(date_of_birth);
    const emailErrors = validatePresentWithMessage('Email')(email) || validateEmail(email);
    const phoneErrors = validatePresentWithMessage('Mobile phone')(phone);
    const passwordErrors = validatePresentWithMessage('Password')(password) || validatePassword(password);
    validationErrors = {};

    if (firstNameError) {
      validationErrors.first_name = firstNameError;
    }

    if (lastNameError) {
      validationErrors.last_name = lastNameError;
    }

    if (dobError) {
      validationErrors.date_of_birth = dobError;
    }

    if (emailErrors) {
      validationErrors.email = emailErrors;
    }

    if (phoneErrors) {
      validationErrors.phone = phoneErrors;
    }

    if (passwordErrors) {
      validationErrors.password = passwordErrors;
    }

    if (isUnderAgeEighteen(date_of_birth)) {
      validationErrors.date_of_birth = UNDER_AGE_EIGHTEEN_ERROR_MESSAGE;
    }

    const dateRegExp = /((0[1-9]|1[0-2]) \/ (0[1-9]|[12]\d|3[01]) \/ [12]\d{3})/;

    if (!dateRegExp.test(date_of_birth)) {
      validationErrors.date_of_birth = 'Birthdate should be in format MM / DD / YYYY';
    }

    if (!terms) {
      validationErrors.terms = 'Please accept the terms and conditions';
    }

    return validationErrors;
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone: '',
        email: '',
        password: '',
        // @ts-expect-error TS(2322): Type 'false' is not assignable to type 'Record<string, unknown> & boolean'.
        terms: false,
        referral_code: '',
      }}
      onSubmit={onSubmitForm}
      validate={validate}
      validateOnBlur
    >
      {(formik) => {
        const { values, dirty, isValid } = formik;
        return (
          <Form>
            {errorMessage ? (
              <InfoBox warning>
                <Paragraph>
                  {errorMessage === DEFAULT_ERROR_ALERT_MESSAGE ? (
                    <>
                      Account creation failed. If you already have an account try <Link href="/">logging in here</Link>.
                      If the problem persists please contact support.
                    </>
                  ) : (
                    errorMessage
                  )}
                </Paragraph>
              </InfoBox>
            ) : null}
            <div>
              <InputRow>
                <Field name="first_name">
                  {({ field, meta }: { field: FieldInputProps<FormFields>; meta: FieldMetaProps<FormFields> }) => {
                    return (
                      <InputBlockText
                        required
                        label="First name"
                        type="text"
                        placeholder="First name"
                        {...mapFormikFieldToInputProps(field, meta)}
                      />
                    );
                  }}
                </Field>
              </InputRow>
              <InputRow>
                <Field name="last_name">
                  {({ field, meta }: { field: FieldInputProps<FormFields>; meta: FieldMetaProps<FormFields> }) => {
                    return (
                      <InputBlockText
                        required
                        label="Last name"
                        type="text"
                        placeholder="Last name"
                        {...mapFormikFieldToInputProps(field, meta)}
                      />
                    );
                  }}
                </Field>
              </InputRow>
              <InputRow>
                <Field name="date_of_birth">
                  {({ field, meta }: { field: any; meta: FieldMetaProps<FormFields> }) => {
                    const { onChange } = field;
                    return (
                      <InputBlockText
                        required
                        label="Date of birth"
                        type="text"
                        placeholder="MM / DD / YYYY"
                        {...mapFormikFieldToInputProps(field, meta)}
                        onChange={(event) => {
                          onChange(event.target.name)(formatDateInput(event.target.value));
                        }}
                      />
                    );
                  }}
                </Field>
              </InputRow>
              {!displayingAgeValidationError && (
                <InputRow>
                  <InfoText>
                    If you are under 18 years old, please have your parent or guardian call us at {PhoneSupport}
                  </InfoText>
                </InputRow>
              )}
            </div>

            <InputRow>
              <Field name="phone">
                {({ field, meta }: { field: any; meta: FieldMetaProps<FormFields> }) => {
                  const { onChange } = field;
                  return (
                    <InputBlockText
                      required
                      label="Mobile phone"
                      type="text"
                      placeholder="555-555-5555"
                      {...mapFormikFieldToInputProps(field, meta)}
                      onChange={(event) => {
                        onChange(event.target.name)(formatPhone(event.target.value));
                      }}
                    />
                  );
                }}
              </Field>
            </InputRow>
            <InputRow>
              <Field name="email">
                {({ field, meta }: { field: FieldInputProps<FormFields>; meta: FieldMetaProps<FormFields> }) => {
                  return (
                    <InputBlockText
                      required
                      label="Email"
                      type="email"
                      placeholder="name@email.com"
                      {...mapFormikFieldToInputProps(field, meta)}
                    />
                  );
                }}
              </Field>
            </InputRow>
            <InputRow>
              <Field name="password">
                {({
                  field,
                  meta,
                }: {
                  readonly field: FieldInputProps<FormFields>;
                  readonly meta: FieldMetaProps<FormFields>;
                }) => {
                  return (
                    <InputBlockText
                      required
                      label="Create password"
                      type="password"
                      placeholder="Password"
                      info="Must be at least 8 characters."
                      {...mapFormikFieldToInputProps(field, meta)}
                    />
                  );
                }}
              </Field>
            </InputRow>
            <InputRow>
              <Field name="terms">
                {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => {
                  return (
                    <InputCheckbox
                      input={{
                        // @ts-expect-error terms is an object
                        value: values.terms,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(field.name, e?.target?.checked);
                        },
                      }}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      <span>
                        I have read and accept the{' '}
                        <a
                          href={TERMS_OF_SERVICE_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>
                        ,{' '}
                        <a
                          href={PRIVACY_POLICY_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        ,{' '}
                        <a
                          href={PRIVACY_PRACTICES_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Notice of Privacy Practices
                        </a>
                        , and{' '}
                        <a
                          href={ALTO_PATIENT_NOTICE_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Patient Notices
                        </a>
                        .
                      </span>
                    </InputCheckbox>
                  );
                }}
              </Field>
            </InputRow>
            <LoadingButton
              full
              kind="primary"
              disabled={!isValid || loading || !dirty}
              type="submit"
            >
              {loading ? 'Creating Account...' : 'Create Account'}
            </LoadingButton>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignupForm;
