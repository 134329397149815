// @owners { team: patients-team }
import { destroyWithParams } from '~shared/helpers/apiHelper';

const V1_VERSION = 'v1';

export function deleteAddOnPrescription(prescription_id: number) {
  return destroyWithParams(
    '/add_ons/delete_prescription',
    {
      prescription_id,
    },
    {
      version: V1_VERSION,
    },
  );
}
