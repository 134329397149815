import { type PaymentMethod as PatientTaskPaymentMethod } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/update_payment_method_patient_task';
import { PAYMENT_TYPES, type PaymentType } from '~shared/features/payment-methods/constants';

// Apple Pay gives us a tokenized card through Stripe to protect the customer's
// information, so the expiration date given to us is not the real expiration
// date.
// https://support.stripe.com/questions/expiration-dates-for-tokenized-apple-pay-cards
export function shouldShowExpiration(paymentType: PaymentType | null | undefined): boolean {
  return paymentType !== PAYMENT_TYPES.APPLE_PAY;
}

export const formatCreditCards = (paymentMethods: PatientTaskPaymentMethod[]): string => {
  return paymentMethods.map((paymentMethod) => `${paymentMethod.card_type} - ${paymentMethod.card_last_4}`).join(', ');
};
