import { type COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { Image, type ImageSourcePropType, View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { LgPadding } from '../../containers';
import { Column, Row } from '../../containers/src/Flex';
import { XlSpacing, XxlSpacing } from '../../separators';
import { H1 } from '../../typography';
import { BasePage, type BasePageProps } from './BasePage';
import { PageHeaderSection } from './PageHeaderSection';
import { PageOffsetContentSection } from './PageOffsetContentSection';

type Props = {
  readonly title: string;
  readonly HeaderContent?: React.ReactElement;
  readonly NavBarRightPressable?: React.ReactElement;
  readonly headerBgColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  readonly bgColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  readonly illustrationSrc?: ImageSourcePropType;
  readonly illustrationAccessibilityLabel?: string;
  readonly children: React.ReactNode;
} & Pick<BasePageProps, 'buttons' | 'Footnote' | 'transparentNavBar' | 'scrollEnabled' | 'keyboardAvoidingEnabled'>;

const StatusBarOffset = styled(View)<{ topInset: number }>`
  ${({ topInset }) => `
    height: ${topInset}px;
    width: ${topInset}px;
  `}
`;

const IllustrationContainer = styled(View)`
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

export const PrimaryPage = ({
  title,
  HeaderContent,
  NavBarRightPressable,
  headerBgColor,
  bgColor,
  illustrationSrc,
  illustrationAccessibilityLabel,
  children,
  // Defaulting transparentNavBar to true, since most uses of PrimaryPage are without safe area
  transparentNavBar = true,
  Footnote,
  buttons,
  scrollEnabled = true,
  keyboardAvoidingEnabled = true,
}: Props) => {
  const { top } = useSafeAreaInsets();
  // Need headerHeight to be percentage height of Screen not of ScrollView
  const { height } = useWindowDimensions();
  return (
    <BasePage
      buttons={buttons}
      Footnote={Footnote}
      transparentNavBar={transparentNavBar}
      backgroundColor={bgColor}
      scrollEnabled={scrollEnabled}
      keyboardAvoidingEnabled={keyboardAvoidingEnabled}
    >
      <PageHeaderSection
        minHeaderHeight={height * 0.18 + top}
        backgroundColor={headerBgColor}
      >
        <StatusBarOffset topInset={top} />
        <LgPadding
          flexGrow={1}
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <Column flexGrow={1}>
            <XxlSpacing />
            <Row
              spaceBetween={!!NavBarRightPressable}
              bottom
              flexGrow={1}
            >
              <H1>{title}</H1>
              {NavBarRightPressable}
            </Row>
            {HeaderContent ? (
              <Column
                flexGrow={1}
                flexShrink={1}
              >
                {HeaderContent}
              </Column>
            ) : (
              <XlSpacing />
            )}
          </Column>
          <XlSpacing />
        </LgPadding>
      </PageHeaderSection>
      <PageOffsetContentSection>{children}</PageOffsetContentSection>
      {!!illustrationSrc && !!illustrationAccessibilityLabel && (
        <IllustrationContainer>
          <Image
            source={illustrationSrc}
            accessibilityLabel={illustrationAccessibilityLabel}
          />
        </IllustrationContainer>
      )}
    </BasePage>
  );
};
