// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';
import { AltoIcon, AltoSpinningLoader, Link, PresentationListItem, Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { type Address as HomescreenAddress } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/address';
import React, { useContext } from 'react';
import { Linking } from 'react-native';
import { type ShortAddressOptions, formatLocationCopy } from '~shared/helpers/address';
import { type Address } from '~shared/types';

type LocationProps = {
  readonly address?: Address | HomescreenAddress | ShortAddressOptions | string;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly isPickup?: boolean;
  readonly showMap?: boolean;
  readonly copyOnly?: boolean; // only return copy, no icon
};

export const LocationRow = ({
  address,
  backgroundColor,
  isPickup,
  showMap = false,
  copyOnly = false,
}: LocationProps) => {
  const { addToast } = useContext(ToastContext);
  const { value: isSelfSchedulePickupAvailable, isLoading } =
    Experimentation.useFeatureFlag('self_scheduled_pickup_fe');
  const showPickUpLocation = isSelfSchedulePickupAvailable && isPickup && showMap;
  // if address is a string, no need to format it to be used as an argument
  const formattedAddress = formatLocationCopy({
    address: typeof address === 'string' ? address : (address as Address),
    isFacility: isPickup,
  });
  const encodedAddress = encodeURIComponent(formattedAddress);
  const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

  const handleOpenMap = () => {
    Linking.canOpenURL(url)
      .then(() => {
        return window.open(url, '_blank');
      })
      .catch(() => {
        addToast(<Toast variant="error">Unable to open map</Toast>);
      });
  };

  if (isLoading) {
    return <PresentationListItem text={<AltoSpinningLoader testID="loading location" />} />;
  }

  if (copyOnly) {
    return showPickUpLocation ? (
      <Link
        color={COLORS.TEXT_COLORS.SECONDARY_DARK}
        fontFamily="regular"
        onPress={handleOpenMap}
        testID="location-link"
        textSize="mini"
        underline
      >
        {formattedAddress}
      </Link>
    ) : (
      <>{formattedAddress}</>
    );
  }

  return (
    <PresentationListItem
      data-testid="location-row"
      LeftContent={
        <AltoIcon
          name="mapmarker"
          type="grey"
        />
      }
      text={
        showPickUpLocation ? (
          <Link
            color={COLORS.TEXT_COLORS.SECONDARY_DARK}
            fontFamily="regular"
            onPress={handleOpenMap}
            testID="location-link"
            underline
          >
            {formattedAddress}
          </Link>
        ) : (
          formattedAddress
        )
      }
      backgroundColor={backgroundColor}
    />
  );
};
