// @owners { team: patients-team }
import { NavBarLogo } from '@alto/design-system';
import React from 'react';
import progynyLogo from '~shared/assets/images/progyny_by_alto_logo@2x.png';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { useSelectorShared } from '~shared/store';
import logo from '~web/images/altoLogo@2x.png';

export type Props = {
  readonly illustrationSrc?: string | null;
  readonly isPartnerLogo?: boolean;
};

export const OnboardingNavBarLogo = ({ illustrationSrc, isPartnerLogo = false }: Props) => {
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);

  if (showProgyny) {
    return (
      <NavBarLogo
        height="SM"
        width="XL"
        source={progynyLogo}
        accessibilityLabel="progyny-logo"
      />
    );
  }

  return (
    <NavBarLogo
      height={isPartnerLogo ? 'SM' : 'XS'}
      width={isPartnerLogo ? 'XL' : 'MD'}
      source={illustrationSrc || logo}
      accessibilityLabel="alto-logo"
    />
  );
};
