// @owners { team: patients-team }

// @deprecated - keeping this for continuity purposes but we should be using "origin" going forwards
export const SOURCES = Object.freeze({
  ALL_OTCS_CAROUSELS: 'all_otcs_carousels',
  CATEGORY_DETAILS: 'category_details',
  CHECKOUT: 'checkout',
  HOMESCREEN_CAROUSEL: 'homescreen_carousel',
  SEARCH_RESULTS: 'search_results',
  POST_CHECKOUT_INTERSTITIAL: 'post_checkout_interstitial',
  ORDER_SUCCESS: 'order_success',
  STORE_PRODUCTS: 'store_products',
  RELATED_OTCS_INTERSTITIAL: 'related_otcs_interstitial',
  THERAPEUTIC_AREA_INTERSTITIAL: 'therapeutic_area_interstitial',
});

export const ORIGINS = Object.freeze({
  CART: 'cart',
  CHECKOUT: 'checkout',
  HOMESCREEN: 'homescreen',
  MED_DETAILS: 'med_details',
  MED_LIST: 'med_list',
  POST_CHECKOUT_INTERSTITIAL: 'post_checkout_interstitial',
  EDIT_DELIVERY_DETAILS: 'edit_delivery_details',
  ORDER_SUCCESS: 'order_success',
  STORE: 'store',
  THERAPEUTIC_AREA_TARGETED_OTC_UPSELL: 'therapeutic_area_targeted_otc_upsell',
});

type OriginKeys = keyof typeof ORIGINS;
export type OriginValues = (typeof ORIGINS)[OriginKeys];

export type ToastParams = {
  duration?: number;
  success: boolean;
  text: string;
  ctaLabel?: string;
  onPress?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-require-imports
const locationImage = require('../../assets/images/location.png');
export const MISSING_ADDRESS = {
  image: locationImage,
  title: 'Add a delivery address',
  description: 'To see products available in your area, we need your delivery address.',
  buttonText: 'Add address',
} as const;
