// @owners { team: patients-team }
import {
  Button,
  Card,
  Column,
  Description,
  H2,
  Illustration,
  LgPadding,
  LgSpacing,
  MdSpacing,
  XsSpacing,
} from '@alto/design-system';
import React from 'react';
import location from '~shared/assets/images/location.png';

type Props = {
  // Adding an address is a modal on web and a screen on native.
  // This is here temporarily until we migrate the screen on native to
  // an ActionSheet.
  readonly onAddAddress: () => void;
};
export const AddAddressCard = ({ onAddAddress }: Props) => {
  const handleAddAddress = () => {
    onAddAddress();
  };

  return (
    <Card>
      <LgPadding>
        <Column
          center
          centerHorizontally
          centerVertically
        >
          <Illustration
            accessibilityLabel="location icon"
            source={location}
          />
          <MdSpacing />
          <H2 center>Add a delivery address</H2>
          <XsSpacing />
          <Description center>
            Keeping your address details up to date helps Alto deliver your medications to the right place, on schedule.
          </Description>
        </Column>
        <LgSpacing />
        <Button onPress={handleAddAddress}>Add address</Button>
      </LgPadding>
    </Card>
  );
};
