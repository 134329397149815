import {
  type AltoIconName,
  type BaseIconName,
  type ColorVariant,
  type DuoIconName,
  SPACING,
  type SmallIconName,
} from '@alto/design-library-tokens';
import React, { useState } from 'react';
import { Pressable, type PressableProps } from 'react-native';
import { AltoIcon } from '../../icon';

export type PressableAltoIconProps = {
  readonly name: BaseIconName | SmallIconName;
  readonly onPress: () => void;
  readonly disabled?: boolean;
  readonly accessibilityLabel: string;
  readonly testID?: string;
  readonly type?: ColorVariant;
  readonly hitSlop?: PressableProps['hitSlop'];
};

export const PressableAltoIcon = ({
  name,
  onPress,
  disabled = false,
  type,
  accessibilityLabel,
  hitSlop = SPACING.STATIC.SM.value,
  testID,
}: PressableAltoIconProps) => {
  // small icons have no duo variant, so we just don't change the name in those cases
  const duoName: AltoIconName = name.endsWith('-small') ? name : (`${name}-duo` as DuoIconName);
  const iconType = disabled ? 'disabled' : type;

  const [isDuo, setIsDuo] = useState<boolean>(false);

  return (
    <Pressable
      onPress={onPress}
      onPressIn={() => {
        setIsDuo(true);
      }}
      onPressOut={() => {
        setIsDuo(false);
      }}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel}
      hitSlop={hitSlop}
      testID={testID}
    >
      <AltoIcon
        name={isDuo ? duoName : name}
        type={iconType}
      />
    </Pressable>
  );
};
