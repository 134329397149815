// @owners { team: patients-team }
import { SPACING, type SPACING_STATIC_SIZE } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { ContactUs } from '../contact-us/ContactUs';

type TransferOutContentProps = {
  origin: 'TransferOutFaq' | 'FAQ';
  topPadding?: SPACING_STATIC_SIZE;
};

export const TransferOutContent = ({ origin, topPadding = SPACING.STATIC.LG }: TransferOutContentProps) => {
  return (
    <LgPadding topPadding={topPadding}>
      <Body>
        Please have your new pharmacy contact us to request the transfer. They can{' '}
        <ContactUs
          type="link"
          method="phone"
          origin={origin}
        />
        . Once we've received their request, we'll take care of the rest.
        {'\n\n'}
        As a reminder, prescriptions may only be transferred if they have refills remaining. Some additional
        restrictions may apply and vary by state.
      </Body>
    </LgPadding>
  );
};

export const TransferOutActionSheet = () => {
  return (
    <ActionSheetV2 title="Transferring medication to another pharmacy">
      <TransferOutContent
        origin={CONTACT_US_ORIGIN.TransferOutFaq}
        topPadding={SPACING.STATIC.NONE}
      />
    </ActionSheetV2>
  );
};
