import { ButtonSelector, Description, InputText, type OptionValue, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps } from 'formik';
import React from 'react';
import { NO_KNOWN_MEDICAL_CONDITIONS } from '~shared/constants';

const RADIO_YES_VALUE = 'Yes';
const RADIO_NO_VALUE = 'No';

const getMedicalConditionsRadioValue = (
  medicalConditions: string | null,
): typeof RADIO_YES_VALUE | typeof RADIO_NO_VALUE | null => {
  if (medicalConditions === NO_KNOWN_MEDICAL_CONDITIONS) return RADIO_NO_VALUE;

  if (typeof medicalConditions === 'string') return RADIO_YES_VALUE;

  return null;
};

const MedicalConditionsField = () => {
  return (
    <Field name="medical_conditions">
      {({ field, form: { errors, handleChange, setFieldValue, submitCount, values } }: FieldProps<string>) => {
        return (
          <div>
            <Description fontFamily="semibold">Any medical conditions?</Description>
            <XsSpacing />
            <ButtonSelector
              options={[
                { label: RADIO_NO_VALUE, value: RADIO_NO_VALUE },
                { label: RADIO_YES_VALUE, value: RADIO_YES_VALUE },
              ]}
              value={getMedicalConditionsRadioValue(field.value) as OptionValue}
              onChange={() => {
                if (getMedicalConditionsRadioValue(field.value) === RADIO_NO_VALUE) {
                  setFieldValue(field.name, '');
                } else {
                  setFieldValue(field.name, NO_KNOWN_MEDICAL_CONDITIONS);
                }
              }}
            />
            {getMedicalConditionsRadioValue(field.value) === RADIO_YES_VALUE && (
              <>
                <XsSpacing />
                <InputText
                  value={values.medical_conditions}
                  placeholder="e.g. High Blood Pressure, Diabetes"
                  error={submitCount > 0 ? (errors.medical_conditions as unknown as string) : undefined}
                  onChangeText={handleChange('medical_conditions')}
                  required
                />
              </>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default MedicalConditionsField;
