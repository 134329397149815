// @owners { team: patients-team }
import { useMemo } from 'react';
import { useCartEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { getOtcPrescriptionInShipmentByProductID } from '~shared/features/prescriptions/selectors/getOtcPrescriptionByProductID';
import { useSelectorShared } from '~shared/store';

type Props = {
  productID: number;
  shipmentID?: number | null | undefined;
};

export const useEssentialPrescriptionByProductID = ({ productID, shipmentID }: Props) => {
  const { essential: cartEssential } = useCartEssentialPrescriptionByProductID({ productID });
  const shipmentEssential = useSelectorShared((state) =>
    getOtcPrescriptionInShipmentByProductID(state, { productID, shipmentID }),
  );

  const essential = shipmentID ? shipmentEssential : cartEssential;
  return useMemo(() => ({ essential }), [essential]);
};
