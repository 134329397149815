// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoSpinningLoader,
  Button,
  ButtonGroup,
  Card,
  Description,
  Illustration,
  LgPadding,
  ListDescription,
  ListItem,
  SecondaryPage,
  Toast,
  ToastContext,
  useScreenSize,
} from '@alto/design-system';
import { MedSyncStatusMap } from '@alto/scriptdash/alto/patients/types/v1/med_sync_status';
import React, { useCallback, useEffect } from 'react';
import { goBack, push } from 'react-router-redux';
import styled from 'styled-components';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
// eslint-disable-next-line import/no-deprecated
import { useFetchMedSyncStatus } from '~shared/queries/useFetchMedSyncStatus';
// eslint-disable-next-line import/no-deprecated
import { useUpdateMedSyncStatus } from '~shared/queries/useUpdateMedSyncStatus';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { MED_SYNC_SELF_SERVICE_ROUTES } from '~web/features/med-sync-self-service/RoutingContainer';
import { MedSyncSelfServiceProgressHeader } from '~web/features/med-sync-self-service/components/ProgressHeader';
import autoRefill from '~web/images/autoRefill@3x.png';
import calendar from '~web/images/calendar@3x.png';
import fulfillmentBoxWithMeds from '~web/images/fulfillmentBoxWithMeds@3x.png';

const CustomListItemContainer = styled.div<{ isMDScreenOrBigger: boolean }>`
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `padding: 0 ${SPACING.STATIC.LG.px};`}
`;

export const MedSyncSelfServiceInfoPage = () => {
  const { isSMScreen, isMDScreenOrBigger } = useScreenSize();
  const dispatch = useDispatchShared();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const {
    data,
    isPending: isFetchMedSyncStatusLoading,
    isError: isFetchMedSyncStatusError,
    // eslint-disable-next-line import/no-deprecated
  } = useFetchMedSyncStatus(currentUserID);
  const medSyncStatus = data?.data?.status;
  const {
    mutate: updateMedSyncStatus,
    isPending: isUpdateMedSyncStatusLoading,
    isError: isUpdateMedSyncStatusError,
    isSuccess: isUpdateMedSyncStatusSuccess,
    // eslint-disable-next-line import/no-deprecated
  } = useUpdateMedSyncStatus();
  const { addToast } = React.useContext(ToastContext);
  const isMobileScreen = isSMScreen;

  useEffect(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_BUNDLE_MEDICATIONS_INFO_VIEWED)));
  }, [dispatch]);

  useEffect(() => {
    if (isUpdateMedSyncStatusSuccess) {
      dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.SELECT_MEDICATIONS));
    }
  }, [dispatch, isUpdateMedSyncStatusSuccess]);

  useEffect(() => {
    if (isFetchMedSyncStatusError || isUpdateMedSyncStatusError || currentUserID === null) {
      addToast(
        <Toast variant="error">
          Something went wrong signing you up for bundling. Please report this as a bug if the problem persists.
        </Toast>,
      );
      dispatch(push('/home'));
    }
  }, [addToast, currentUserID, dispatch, isFetchMedSyncStatusError, isUpdateMedSyncStatusError]);

  const handleConfirm = useCallback(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_BUNDLE_MEDICATIONS_REQUEST_CLICKED)));
    if (
      ![MedSyncStatusMap.INTERESTED, MedSyncStatusMap.CONFIRMED_MED_LIST, MedSyncStatusMap.ENROLLED].includes(
        medSyncStatus,
      )
    ) {
      updateMedSyncStatus({ id: Number(currentUserID), params: { status: MedSyncStatusMap.INTERESTED } });
    } else {
      dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.SELECT_MEDICATIONS));
    }
  }, [currentUserID, dispatch, medSyncStatus, updateMedSyncStatus]);

  const handleCancel = () => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_BUNDLE_MEDICATIONS_REQUEST_DISMISS_CLICKED)));
    dispatch(push('/home'));
  };

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      onDismiss={() => {
        dispatch(goBack());
      }}
      HeaderContent={
        <MedSyncSelfServiceProgressHeader
          currentStep={1}
          title="Simplify your deliveries by bundling."
        />
      }
    >
      {isFetchMedSyncStatusLoading ? (
        <AltoSpinningLoader />
      ) : (
        <Card>
          <LgPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <CustomListItemContainer isMDScreenOrBigger={isMDScreenOrBigger}>
              <ListItem
                LeftContent={
                  <Illustration
                    source={fulfillmentBoxWithMeds}
                    accessibilityLabel="Box with meds"
                    size={isMobileScreen ? 'sm' : undefined}
                  />
                }
                descriptions={[
                  <ListDescription key="get medications">
                    <Description>
                      Get your medications <Description fontFamily="semibold">delivered together</Description> -- no
                      extra hassle!
                    </Description>
                  </ListDescription>,
                ]}
              />
              <ListItem
                LeftContent={
                  <Illustration
                    source={calendar}
                    accessibilityLabel="Calendar"
                    size={isMobileScreen ? 'sm' : undefined}
                  />
                }
                descriptions={[
                  <ListDescription key="choose medications">
                    <Description>
                      Choose which medications, then we'll{' '}
                      <Description fontFamily="semibold">build a plan to bundle them.</Description>
                    </Description>
                  </ListDescription>,
                ]}
              />
              <ListItem
                LeftContent={
                  <Illustration
                    source={autoRefill}
                    accessibilityLabel="Pill bottle"
                    size={isMobileScreen ? 'sm' : undefined}
                  />
                }
                descriptions={[
                  <ListDescription key="ensure medications">
                    <Description>
                      We'll also{' '}
                      <Description fontFamily="semibold">
                        ensure your medication supply won't be interrupted
                      </Description>{' '}
                      while we work on bundling.
                    </Description>
                  </ListDescription>,
                ]}
              />
            </CustomListItemContainer>
            <LgPadding
              topPadding={SPACING.STATIC.NONE}
              bottomPadding={SPACING.STATIC.NONE}
            >
              <ButtonGroup
                buttons={[
                  <Button
                    onPress={handleCancel}
                    label="Not now"
                    key="Not now"
                    type="tertiary"
                  />,
                  <Button
                    onPress={handleConfirm}
                    label="Continue"
                    key="Continue"
                    loading={isUpdateMedSyncStatusLoading}
                  />,
                ]}
              />
            </LgPadding>
          </LgPadding>
        </Card>
      )}
    </SecondaryPage>
  );
};
