import { buildHeaders } from './buildHeaders';
import { generateRequestID } from './generateRequestID';
import { fetch as resolvedFetch } from '~shared/helpers/fetch';

const shouldUseBuiltInFetch = !!process.env.JEST_WORKER_ID;

export const request = async (url: string, config: RequestInit) => {
  const requestID = generateRequestID();
  const requestConfig: RequestInit = {
    headers: buildHeaders(requestID),
    credentials: 'include',
    mode: 'cors',
    ...config,
  };

  if (shouldUseBuiltInFetch) return fetch(url, requestConfig);

  return resolvedFetch(url, requestConfig);
};
