// @owners { team: patients-team }
import { get } from '~shared/helpers/apiHelper';
import { type DataSource } from './types';

export const medicationsAutocompleteDataSource: DataSource<string> = (text) => {
  return get(`/products/medication_name_search?query=${text}&limit=8&`)
    .then((response) => {
      // the 'get' method doesn't reject failed promises, instead it just annotates them with 'error'
      if (response.error) {
        throw response.error;
      }

      return response;
    })
    .then((response: string[]) =>
      response.map((medName) => ({
        title: medName,
        value: medName,
        label: medName,
        key: medName,
      })),
    );
};
