// @owners { team: patients-team }
import React, { useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import BackLink from '~web/components/BackLink';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';

export function ForgotPasswordPage({ location }: { readonly location: { query: { source?: string; id?: string } } }) {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    let additionalFields = {};
    if (location.query.id) {
      additionalFields = {
        userIdentifier: location.query.id,
        userIdentifierType: 'external_uuid',
      };
    }

    trackEvent({
      event: EVENTS.FORGOT_PASSWORD_VIEWED,
      params: {
        source: location.query.source,
      },
      additionalFields,
    });
  }, [trackEvent, location.query.source, location.query.id]);

  return (
    <PageBackground>
      <SingleColumnContentWrapper>
        <UnauthorizedFormWrapper header="Password Reset">
          <ForgotPasswordForm />
          <BackLink
            to="/login"
            text="Go Back"
          />
        </UnauthorizedFormWrapper>
      </SingleColumnContentWrapper>
    </PageBackground>
  );
}
