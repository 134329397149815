// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Paragraph } from '@alto/pocky';
import React from 'react';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';

export default class ContactRequestedPage extends React.Component {
  render() {
    return (
      <PageBackground>
        <SingleColumnContentWrapper>
          <UnauthorizedFormWrapper header="Thank you!">
            <Paragraph>Thanks for your interest. Someone from our team will be in touch shortly.</Paragraph>
          </UnauthorizedFormWrapper>
        </SingleColumnContentWrapper>
      </PageBackground>
    );
  }
}
