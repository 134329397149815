// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, InputAutocomplete, InputSelect, Switch, XlSpacing } from '@alto/design-system';
import {
  KaiserActionSheet,
  googlePlacesEstablishmentDataSource,
  medicationsAutocompleteDataSource,
} from '@alto/features';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { type FormikProps } from 'formik';
import React, { useContext } from 'react';
import { getFamilyMembers } from '~shared/features/family-accounts/selectors/getFamilyMembers';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { type ApiResponseRow, type Pharmacy } from '~shared/features/transfers/types';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { getDisplayFullName } from '~shared/helpers/helper';
import isKaiserPharmacy from '~shared/helpers/isKaiserPharmacy';
import { useAnalytics } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent, previousWebURL } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export type TransferFormikFormFields = {
  transfer_all: boolean;
  medications: ApiResponseRow<string>[];
  pharmacy: ApiResponseRow<Pharmacy>[];
  selected_user_id: number | null | undefined;
  hasFamily: boolean;
};

export type Props = {
  readonly formikProps: FormikProps<TransferFormikFormFields>;
};

/**
 * @deprecated do not use this, use TransferForm from \@alto/features instead
 */
export const TransferFormFields = ({ formikProps }: Props) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  const hasFamily = useSelectorShared(getHasFamily);
  const users = useSelectorShared(getFamilyMembers);
  const previousWebUrl = useSelectorShared(previousWebURL);
  const origin = previousWebUrl ? routeFromPath(previousWebUrl) : '';
  const { errors, handleChange, setFieldValue, submitCount, values } = formikProps;
  const hasSubmittedOnce = submitCount > 0;

  return (
    <>
      <InputAutocomplete
        accessibilityLabel="Search for your pharmacy"
        label="Current pharmacy"
        title="Find a pharmacy"
        shouldAllowManualInput
        onSelectCallback={(data: ApiResponseRow<Pharmacy>[]) => {
          dispatch(
            sendAnalyticsEvent(
              createEvent(EVENTS.TRANSFER__PHARMACY_SELECTED, {
                origin,
              }),
            ),
          );
          const selectedPharmacy = data[data.length - 1];
          const selectedPharmacyName = selectedPharmacy?.title ?? '';
          if (isKaiserPharmacy(selectedPharmacyName)) {
            setActiveActionSheet(<KaiserActionSheet onDismiss={closeActionSheet} />);
          }
          setFieldValue('pharmacy', [selectedPharmacy].filter(Boolean));
        }}
        dataSource={googlePlacesEstablishmentDataSource}
        placeholder="Search for your pharmacy"
        initialValues={values.pharmacy}
        error={hasSubmittedOnce ? (errors.pharmacy as unknown as string) : null}
        required
        onSelectAutoCloseActionSheet={false}
      />
      <XlSpacing />
      {hasFamily ? (
        <>
          <InputSelect
            label="Name"
            onValueChange={handleChange('selected_user_id')}
            value={values.selected_user_id?.toString()}
            error={hasSubmittedOnce ? errors.selected_user_id : undefined}
            placeholder="Select who this is for"
            options={users.map((user) => ({
              label: getDisplayFullName(user),
              value: user.id.toString(),
            }))}
          />
          <XlSpacing />
        </>
      ) : null}
      {!values.transfer_all && (
        <>
          <InputAutocomplete
            leftIcon="pillbottle-small"
            accessibilityLabel="List the medications you want to transfer to Alto"
            label="Medication names"
            title="Find medications"
            shouldAllowManualInput
            selectionType="multi"
            initialValues={values.medications}
            dataSource={medicationsAutocompleteDataSource}
            onSelectCallback={(data) => {
              trackEvent({
                event: EVENTS.TRANSFER__MEDICATION_SELECTED,
                params: { origin },
              });
              setFieldValue('medications', data);
            }}
            placeholder="e.g. Albuterol, Amoxicillin"
            error={hasSubmittedOnce ? (errors.medications as unknown as string) : null}
            required
          />
          <XlSpacing />
        </>
      )}
      <Switch
        label="Transfer all medications on file"
        labelColor={COLORS.TEXT_COLORS.PRIMARY}
        value={values.transfer_all}
        onValueChange={(val) => {
          dispatch(
            sendAnalyticsEvent(
              createEvent(EVENTS.TRANSFER__TRANSFER_ALL_TAPPED, {
                origin,
                transfer_all: val,
              }),
            ),
          );
          setFieldValue('transfer_all', val);
        }}
        accessibilityLabel="switch"
      />
    </>
  );
};
