// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES } from '@alto/design-library-tokens';
import { Caption } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import styled from 'styled-components';
import { getItemQuantityForAddOnOtc } from '~shared/features/checkout/helpers';
import { useEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { FloatingIcon } from './FloatingIcon';
import { Icon } from '~web/features/essentials/primitives';

type Props = {
  readonly product: AddOnOtc;
  readonly shipmentID?: number | null;
  readonly hidePlusIcon?: boolean;
};

const StyledTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SIZES.ICON.LG.px};
  width: ${SIZES.ICON.LG.px};
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY};
`;

export const StoreProductTag = ({ product, shipmentID, hidePlusIcon }: Props) => {
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: product.product_id,
    shipmentID,
  });

  if (!prescription && hidePlusIcon) {
    return null;
  }

  return (
    <FloatingIcon>
      {prescription ? (
        <StyledTag>
          <Caption color={COLORS.TEXT_COLORS.WHITE}>
            {getItemQuantityForAddOnOtc(prescription, product.quantity).toString()}
          </Caption>
        </StyledTag>
      ) : (
        <Icon
          name="plus"
          label={`add ${product.name}`}
          interactive
        />
      )}
    </FloatingIcon>
  );
};
