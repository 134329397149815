import { isNil } from 'lodash';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { NO_SHARPS_CONTAINER_SELECTED } from '~shared/features/checkout/constants';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { type ValidationError } from '~shared/features/checkout/types';
import { useSelectorShared } from '~shared/store';

export const useCheckoutSharpsContainerErrors = () => {
  const order = useSelectorShared(getOrder);
  const { hasInjectables, hasOnlyInjectableCompounds } = usePrescriptionsInCart();
  const { include_sharps_container } = order;

  const errors: ValidationError[] = [];

  if (hasInjectables && !hasOnlyInjectableCompounds && isNil(include_sharps_container)) {
    errors.push({
      key: NO_SHARPS_CONTAINER_SELECTED,
      message: 'Please select whether you would like a sharps container.',
    });
  }

  return { sharpsContainerValidationErrors: errors };
};
