// @owners { team: patients-team }

// limit the fields returned from places api details request
export const PLACE_DETAILS_FIELDS = ['address_components', 'formatted_phone_number', 'name', 'vicinity', 'place_id'];

// see https://developers.google.com/maps/documentation/places/web-service/supported_types for all possible Google Place types
export type GooglePlaceType = 'establishment' | 'address';

export type GooglePlacesAddressType =
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | 'street_number'
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | 'route'
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | 'locality'
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | 'postal_code'
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | 'administrative_area_level_1'
  | string;

export type GooglePlacesAddressComponent = {
  long_name: string;
  short_name: string;
  types: GooglePlacesAddressType[];
};
