// @owners { team: patients-team }
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import { useCallback } from 'react';
import { useCartData } from './useCartData';
import { getCartDetails } from '~shared/features/cart/selectors/getCartDetails';
import { getPrescriptionsHashWithUpdatedAt } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getOrderPricingWithUpdatedAt } from '~shared/features/pricing/selectors/getPricing';
import { type ItemPriceTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types/clients';

type UsePrescriptionsInCart = {
  prescriptions: Prescription[];
  prescriptionsHash: Record<number, Prescription>;
  prescriptionIDs: number[];
  isPrescriptionInCart: (prescriptionId: number) => boolean;
  compounds: Prescription[];
  injectables: CartItem[];
  injectableCompounds: CartItem[];
  cartPricingTotals: Record<number, ItemPriceTotal>;
  hasInjectables: boolean;
  hasOnlyInjectableCompounds: boolean;
  hasCompounds: boolean;
  updatedAt: number;
};

export const usePrescriptionsInCartInitialState: UsePrescriptionsInCart = {
  prescriptions: [],
  prescriptionsHash: {},
  prescriptionIDs: [],
  isPrescriptionInCart: () => false,
  compounds: [],
  hasCompounds: false,
  hasInjectables: false,
  injectables: [],
  injectableCompounds: [],
  hasOnlyInjectableCompounds: false,
  updatedAt: 0,
  cartPricingTotals: {},
};

export const usePrescriptionsInCart = () => {
  const { cartItems, updatedAt: cartUpdatedAt } = useCartData();
  const prescriptionsHash = useSelectorShared(getPrescriptionsHashWithUpdatedAt);
  const orderPricing = useSelectorShared(getOrderPricingWithUpdatedAt);
  const {
    cartPrescriptionIds,
    cartPrescriptions,
    cartCompounds,
    cartPricingTotals,
    cartPrescriptionIdHash,
    cartInjectable,
    cartInjectableCompounds,
  } = getCartDetails({
    cartItems,
    prescriptionsHash,
    orderPricing,
    cartUpdatedAt,
  });
  const hasInjectables = cartInjectable.length > 0;
  const hasOnlyInjectableCompounds = hasInjectables && cartInjectable.length === cartInjectableCompounds.length;
  const hasCompounds = cartCompounds.length > 0;

  const isPrescriptionInCart = useCallback(
    (prescriptionId: number): boolean => !!cartPrescriptionIdHash[prescriptionId],
    [cartPrescriptionIdHash],
  );

  return {
    prescriptions: cartPrescriptions,
    prescriptionsHash: cartPrescriptionIdHash,
    prescriptionIDs: cartPrescriptionIds,
    isPrescriptionInCart,
    compounds: cartCompounds,
    injectables: cartInjectable,
    injectableCompounds: cartInjectableCompounds,
    cartPricingTotals,
    hasInjectables,
    hasOnlyInjectableCompounds,
    hasCompounds,
    updatedAt: cartUpdatedAt,
  };
};
