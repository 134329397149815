import { type AltoIcon } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
  readonly onClick: () => void;
  readonly AltoIcon: React.ReactElement<typeof AltoIcon>;
};

const Clickable = styled.div`
  cursor: auto;
  &:hover {
    cursor: pointer;
  }
`;

export default class ClickableAltoIcon extends React.PureComponent<Props> {
  render() {
    const { onClick, AltoIcon } = this.props;
    return <Clickable onClick={onClick}>{AltoIcon}</Clickable>;
  }
}
