// @owners { team: patients-team }
import React, { useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import { completeOnboarding } from '~shared/actions/auth';
import { type ModalType, openModal } from '~shared/actions/modal';
import { clearOnboardingState } from '~shared/actions/onboarding';
// eslint-disable-next-line import/no-deprecated
import { fetchAppDownloadLink } from '~shared/actions/users';
import { ONBOARD_SUCCESS } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getTempPassword } from '~shared/features/onboarding/selectors/getOnboarding';
import {
  type OnboardingSuccessMessageType,
  getOnboardingSuccessMessageType,
} from '~shared/features/onboarding/selectors/getOnboardingSuccessMessageType';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getCurrentUserFirstName } from '~shared/features/users/selectors/getCurrentUser';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { isMobileWeb } from '~shared/helpers/helper';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent, previousWebURL } from '~shared/lib/analytics/src/helper';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import OnboardingProviderSuccess from './OnboardingProviderSuccess';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

type Props = {
  readonly messageType: OnboardingSuccessMessageType;
  readonly nextRoute: string;
  readonly navigateTo: (url: string) => void;
  readonly openModal: (modalName: ModalType, props: Record<string, unknown> | undefined) => void;
  readonly clearOnboardingState: () => void;
  readonly completeOnboarding: () => void;
  readonly showProgyny: boolean;
  readonly fetchAppUrl: (tempPassword: string) => Promise<string>;
  readonly fetchAppDownloadLinkLoading: boolean;
  readonly tempPassword: string | null | undefined;
  readonly dispatchSendAnalyticsEvent: (event: ReturnType<typeof createEvent>) => void;
  readonly firstName: string | null | undefined;
};

const OnboardingProviderSuccessContainer = ({
  messageType,
  nextRoute,
  navigateTo,
  openModal,
  clearOnboardingState,
  completeOnboarding,
  showProgyny,
  fetchAppUrl,
  fetchAppDownloadLinkLoading,
  tempPassword,
  dispatchSendAnalyticsEvent,
  firstName,
}: Props) => {
  const [appDownloadUrl, setAppDownloadUrl] = useState('');
  const [appDownloadClicked, setAppDownloadClicked] = useState(false);
  useEffect(() => {
    if (showProgyny) {
      // eslint-disable-next-line promise/catch-or-return
      fetchAppUrl(tempPassword ?? '').then((url: string) => {
        setAppDownloadUrl(url);

        // Open the link immediately if the user wanted to open the link but it wasn't available yet
        // eslint-disable-next-line promise/always-return
        if (appDownloadClicked) {
          window.location.assign(url);
        }
      });
    }
  }, [showProgyny, tempPassword, fetchAppUrl, appDownloadClicked]);

  const onContinue = () => {
    clearOnboardingState();
    completeOnboarding();

    if (showProgyny) {
      dispatchSendAnalyticsEvent(createEvent(EVENTS.PROGYNY_UPSELL__DOWNLOAD_APP_TAPPED, {}));

      if (appDownloadUrl) {
        window.location.assign(appDownloadUrl);
      } else {
        setAppDownloadClicked(true);
      }
    } else {
      navigateTo(nextRoute);

      if (isMobileWeb()) {
        setTimeout(() => {
          openModal('GET_THE_APP_DRAWER', {
            tempPassword,
          });
        }, 300);
      }
    }
  };

  return (
    <OnboardingProviderSuccess
      onContinue={onContinue}
      messageType={messageType}
      showProgyny={showProgyny}
      fetchAppDownloadLinkLoading={fetchAppDownloadLinkLoading}
      firstName={firstName}
    />
  );
};

const mapStateToProps = (state: ReduxStateShared) => {
  return {
    tempPassword: getTempPassword(state),
    showProgyny: getShowProgynySelfServiceForUser(state),
    messageType: getOnboardingSuccessMessageType(state),
    nextRoute: nextUrl(state, ONBOARD_SUCCESS),
    fetchAppDownloadLinkLoading: getLoading(state).fetchAppDownloadLinkLoading,
    firstName: getCurrentUserFirstName(state),
    origin: routeFromPath(previousWebURL(state) ?? ''),
  };
};

const mapDispatchToProps = {
  // eslint-disable-next-line import/no-deprecated
  fetchAppUrl: fetchAppDownloadLink,
  dispatchSendAnalyticsEvent: sendAnalyticsEvent,
  navigateTo: push,
  openModal,
  clearOnboardingState,
  completeOnboarding,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingProviderSuccessContainer);
