// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import styled from 'styled-components';
import { StyledTouchable } from '~web/features/essentials/primitives';

export const FloatingIcon = styled.div`
  position: absolute;
  top: 0;
  right 0;
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${SPACING.STATIC.LG.px};
  height: ${SPACING.STATIC.LG.px};
  border-radius: ${SPACING.STATIC.MD.px};
  ${StyledTouchable}
`;
