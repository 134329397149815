// @owners { team: patients-team }
import { LgSpacing, ListBase } from '@alto/design-system';
import { type Transfer as HomescreenTransfer } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/transfer';
import React, { useMemo } from 'react';
import { CompletedTransferCard } from './CompletedTransferCard';

type Props = {
  readonly transfers: HomescreenTransfer[];
  readonly onPress: () => void;
};

export const CompletedTransferSection = ({ transfers, onPress }: Props) => {
  const transferItems = useMemo(
    () =>
      transfers.map((t) => ({
        key: t.id,
        component: (
          <CompletedTransferCard
            transfer={t}
            onPress={onPress}
          />
        ),
      })),
    [transfers, onPress],
  );

  return (
    <ListBase
      items={transferItems}
      separator={<LgSpacing />}
    />
  );
};
