import { camelCase } from 'lodash';
import { type APIError } from '~shared/types';

export type UIActionType = string;
export type UIAction = { type: UIActionType; id?: number; error?: boolean; payload?: APIError | null };

export const isLoading = (type: UIActionType): boolean => type.endsWith('_LOADING');
export const isSuccess = (type: UIActionType): boolean => type.endsWith('_SUCCEEDED');
export const isError = (type: UIActionType): boolean => type.endsWith('_ERROR') && !type.startsWith('CLEAR_');
export const isFailure = (type: UIActionType): boolean => type.endsWith('_FAILED');
export const getBaseName = (type: UIActionType): string => {
  if (type.match(/.*_IDS?_.*/g)) {
    // If the action type includes '_IDS_' or '_ID_', split the I and D so we return 'ID' instead of 'Id'
    type = type.replace(/_ID(S?)_/g, '_I_D$1_');
  }

  return camelCase(type.split('_').slice(0, -1).join('_'));
};
