// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  Column,
  Description,
  InlineAlert,
  LgPadding,
  PresentationListItem,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import {
  type OrderBundlingOrigin,
  OrderBundlingOriginMap,
} from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/order_bundling_origin';
import React, { useContext } from 'react';
import boxWithWings from '~shared/assets/images/box_with_wings.png';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { formatShortAddress } from '~shared/helpers/address';
import { formatRelativeDateWithDow, formatWindowTime } from '~shared/helpers/date';
import { formatListMaxTwo } from '~shared/helpers/list';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { useAddToShipment } from '../hooks/useAddToShipment';
import { useOrderBundlingOptions } from '../hooks/useOrderBundlingOptions';
import { OrderBundlingPaymentSummary } from './OrderBundlingPaymentSummary';

export const OrderBundlingActionSheet = () => {
  const origin = OrderBundlingOriginMap.ENTER_CHECKOUT;
  const address = useSelectorShared(getDefaultAddress);
  const { isPending: isBundlingLoading, earliestUpcomingShipment } = useOrderBundlingOptions({
    origin,
    address_id: address?.id,
  });
  const {
    handleAddToUpcomingDelivery,
    isPending: isAddToShipmentLoading,
    isError,
  } = useAddToShipment({ origin, address });
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { navigate } = useNavigation();
  const oldMedicationNames = earliestUpcomingShipment?.prescriptions.map(
    (prescription) => prescription.medication_name,
  );
  const shipmentAddress = earliestUpcomingShipment?.address;
  const shipmentDate = earliestUpcomingShipment?.date;
  const shipmentStartTime = earliestUpcomingShipment?.start_time;
  const shipmentEndTime = earliestUpcomingShipment?.end_time;
  const isValidShipment = shipmentDate && shipmentStartTime && shipmentEndTime;
  const dateTime = isValidShipment
    ? `${formatRelativeDateWithDow(shipmentDate)}, ${formatWindowTime({ windowTime: shipmentStartTime, meridiem: 'aaa' })} - ${formatWindowTime(
        {
          windowTime: shipmentEndTime,
          meridiem: 'aaa',
        },
      )}`
    : '';

  const handleCloseAndNavigate = () => {
    closeActionSheet();
    navigate('RouteCheckout');
  };

  return (
    <ActionSheetV2
      illustrationSrc={boxWithWings}
      analyticsName={EVENTS.ORDER_BUNDLING_ACTION_SHEET_VIEWED}
      buttons={[
        <Button
          disabled={isBundlingLoading}
          loading={isAddToShipmentLoading}
          key="Combine deliveries"
          label="Combine deliveries"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleAddToUpcomingDelivery}
        />,
        <Button
          type="tertiary"
          key="Schedule new delivery"
          label="Schedule new delivery"
          onPress={handleCloseAndNavigate}
        />,
      ]}
      title="Combine with your upcoming delivery"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.XS}
      >
        {/* Upcoming originalShipment description */}
        <Column gap="LG">
          <Column>
            <PresentationListItem
              iconName="calendar"
              fullBleed
              text={dateTime}
            />
            <PresentationListItem
              iconName="mapmarker"
              fullBleed
              text={shipmentAddress ? formatShortAddress(shipmentAddress) : ''}
            />
            <PresentationListItem
              iconName="pillbottle"
              fullBleed
              text={oldMedicationNames ? formatListMaxTwo(oldMedicationNames) : ''}
            />
          </Column>
          <OrderBundlingPaymentSummary
            showPaymentBreakdown={false}
            origin={OrderBundlingOriginMap.ENTER_CHECKOUT as OrderBundlingOrigin}
          />
          {/* Add error info box when mutation fails */}
          {isError ? (
            <InlineAlert type="error">
              <Description fontFamily="semibold">Error adding to upcoming delivery</Description>
              <XsSpacing />
              <Description>
                There was an error trying to add this item to your upcoming delivery. Please try again.
              </Description>
            </InlineAlert>
          ) : null}
        </Column>
      </LgPadding>
    </ActionSheetV2>
  );
};
