// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Card, SecondaryPage, SmPadding } from '@alto/design-system';
import { ReplyForm as ReplyFormComponent } from '@alto/features';
import { type RouteParams } from '@alto/navigation';
import React from 'react';
import { withRouter } from 'react-router';

const componentWrapper = ({ params }: { params: RouteParams['RouteAssistantSupportCaseReply'] }) => {
  return (
    <SecondaryPage headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}>
      <Card>
        <SmPadding>
          <ReplyFormComponent params={params} />
        </SmPadding>
      </Card>
    </SecondaryPage>
  );
};

const ReplyForm = withRouter(componentWrapper);
export { ReplyForm };
