// @owners { team: patients-team }
import { MedList } from '@alto/features';
import React from 'react';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useSelectorShared } from '~shared/store';
import { useAddToCartInterstitialSheets } from '~web/features/checkout/hooks/useAddToCartInterstitialSheets';

export const MedListWrapper = () => {
  const routeState = useSelectorShared(getRouteState);
  const origin: string = routeState?.origin || '';

  return (
    <MedList
      origin={origin}
      // temporary until consolidating web & native's useAddToCartInterstitialSheets into RNW in features
      useAddToCartInterstitialSheets={useAddToCartInterstitialSheets}
    />
  );
};
