// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { Description } from '@alto/design-system';
import React from 'react';
import { getWhatToExpectCopy } from '~shared/features/shipments/helpers/getWhatToExpectCopy';
import { useShipmentStatusMap } from '~shared/features/shipments/hooks/useShipmentStatusMap';
import { type ShipmentStatus } from '~shared/types';

type WhatToExpectOnDeliveryDayRowProps = {
  readonly method: DeliveryMethod | undefined;
  readonly shipmentStatus?: ShipmentStatus;
  readonly isLoading?: boolean;
};
export const WhatToExpectOnDeliveryDayRow = ({
  method,
  shipmentStatus,
  isLoading,
}: WhatToExpectOnDeliveryDayRowProps) => {
  const shipmentStatusMap = useShipmentStatusMap();
  const copy = isLoading ? '...' : getWhatToExpectCopy(method, shipmentStatusMap, shipmentStatus);

  return copy ? <Description>{copy}</Description> : null;
};
