// @owners { team: patients-team }
import { COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import styled from 'styled-components';
import { palette } from './styles';

export const IntroText = styled.p<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  max-width: 410px;
  font-size: 18px;
  line-height: 30px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 14px; line-height: 26px;`}
`;

export const LargeText = styled.p<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  font-size: 48px;
  text-align: center;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 30px;`}
`;

export const SmallText = styled.p<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.BODY.SEMIBOLD}', sans-serif;
  color: ${palette.gray};
  font-size: 16px;
  line-height: 24px;
  width: 360px;
  text-align: center;
  margin: 0 auto;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 14px; width: 100%;`}
`;

export const CheckBoxText = styled.span<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.BODY.REGULAR}', sans-serif;
  color: ${palette.gray};
  font-size: 16px;
  line-height: 32px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 14px; line-height: 32px;`}
`;

export const ValuePropsTitle = styled.h1<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  letter-spacing: -0.75px;
  max-width: 600px;
  font-size: 48px;
  line-height: 60px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 30px; line-height: 38px;`}
`;

export const ValuePropTitle = styled.h4<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  letter-spacing: -0.266667px;
  padding-bottom: ${SPACING.STATIC.LG.rem};
  font-size: 24px;
  line-height: 24px;
  ${({ isSMScreenOrBigger }) =>
    !isSMScreenOrBigger &&
    `
      font-size: 21px;
      line-height: 30px;
      padding-bottom: ${SPACING.STATIC.XS.rem};
    `}
`;

export const ValuePropDescription = styled.p<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.BODY.REGULAR}', sans-serif;
  color: ${palette.gray};
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 15px; line-height: 26px;`}
`;

export const LogosText = styled.p`
  font-family: '${TYPOGRAPHY.FONT.BODY.REGULAR}', sans-serif;
  color: ${palette.gray};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;
