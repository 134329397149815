import React, { useState } from 'react';
import { findFirstDifferentCharacter, toFormattedDate, toFormattedISODate, toNumbersOnly } from '../../../utils';
import { InputText, type InputTextProps } from './InputText/InputText';

export type InputDateProps = Omit<
  InputTextProps,
  | 'autoCapitalize'
  | 'autoComplete'
  | 'autoCorrect'
  | 'dataDetectorTypes'
  | 'keyboardType'
  | 'multiline'
  | 'numberOfLines'
  | 'onContentSizeChange'
  | 'optional'
  | 'passwordRules'
  | 'scrollEnabled'
  | 'secureTextEntry'
  | 'textContentType'
>;

export const getNewDateDigitsOnChange = (previousFormattedDate: string, newFormattedDate: string) => {
  const numbersOnly = toNumbersOnly(newFormattedDate);

  const oneCharacterDeleted = previousFormattedDate.length - newFormattedDate.length === 1;
  if (oneCharacterDeleted) {
    const { character: characterDeleted, index } = findFirstDifferentCharacter(newFormattedDate, previousFormattedDate);
    if (characterDeleted === '/') {
      switch (index) {
        case 2:
          return numbersOnly.slice(0, 1) + numbersOnly.slice(3, numbersOnly.length);
        case 5:
          return numbersOnly.slice(0, 3) + numbersOnly.slice(5, numbersOnly.length);
        default:
          break;
      }
    }
  }
  return numbersOnly;
};

export const InputDate = ({
  accessibilityLabel,
  autoFocus,
  backgroundColor,
  blurOnSubmit,
  caption,
  defaultValue,
  disabled,
  error,
  hideClearButton,
  label,
  leftIconProps,
  onBlur,
  onChangeText,
  onFocus,
  onKeyPress,
  onRef,
  onSubmitEditing,
  placeholder,
  required,
  returnKeyType,
  rightIconProps,
  sublabel,
  testID,
  value,
}: InputDateProps) => {
  const [dateDigits, setDateDigits] = useState(toNumbersOnly(value));

  const handleChangeText = (incomingFormattedDate: string) => {
    const previousFormattedDate = toFormattedDate(dateDigits);
    const newDateDigits = getNewDateDigitsOnChange(previousFormattedDate, incomingFormattedDate);

    setDateDigits(newDateDigits);

    if (onChangeText) {
      onChangeText(toFormattedISODate(newDateDigits));
    }
  };

  return (
    <InputText
      accessibilityLabel={accessibilityLabel || 'Date'}
      autoCapitalize="none"
      autoComplete="off"
      autoCorrect={false}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      backgroundColor={backgroundColor}
      blurOnSubmit={blurOnSubmit}
      caption={caption}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      hideClearButton={hideClearButton}
      keyboardType="number-pad"
      label={label}
      leftIconProps={leftIconProps}
      // Set to length of "xx/xx/xxxx" to stop flicker
      maxLength={10}
      onBlur={onBlur}
      onChangeText={handleChangeText}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      onRef={onRef}
      onSubmitEditing={onSubmitEditing}
      placeholder={placeholder || 'MM/DD/YYYY'}
      required={required}
      returnKeyType={returnKeyType}
      rightIconProps={rightIconProps}
      sublabel={sublabel}
      testID={testID}
      textContentType="none"
      value={toFormattedDate(dateDigits)}
    />
  );
};
