// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
import React from 'react';
import { push } from 'react-router-redux';
import { closeModal } from '~shared/actions/modal';
import { clearUserError, uploadPhotoIDs } from '~shared/actions/users';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import PhotoIDUpload, { type FormValues } from './PhotoIDUpload';

type Props = {
  readonly clearUserError: () => void;
  readonly closeModal: () => void;
  readonly onSubmit: (arg0: FormValues) => void;
  readonly error: string | null | undefined;
  readonly isShowing: boolean;
  readonly loading: boolean;
  readonly routerPush: () => void;
  readonly success: boolean;
};

class PhotoIDUploadContainer extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { clearUserError, closeModal, error, loading, routerPush, success } = this.props;

    const wasLoading = prevProps.loading && !loading;

    if (wasLoading && !success && error) {
      toaster.toast({
        kind: 'danger',
        title: 'Whoops!',
        description: 'Please try again or message support if the problem persists.',
      });
    }

    if (wasLoading && success) {
      toaster.toast({
        kind: 'success',
        title: 'Success!',
        description: 'Your photo ID has been uploaded.',
      });

      clearUserError();
      closeModal();

      const route = window.location.pathname;
      if (route !== '/checkout') routerPush();
    }
  }

  render() {
    const { closeModal, onSubmit, isShowing, loading } = this.props;

    if (!isShowing) return null;

    return (
      <PhotoIDUpload
        closeModal={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStateShared) => {
  const {
    modal: { modalType, show },
    users: { uploadPhotoIDsSuccess },
  } = state;
  const { uploadPhotoIDsError } = getErrors(state);
  const { uploadPhotoIDsLoading } = getLoading(state);

  const isShowing = show && modalType === 'PHOTO_ID_UPLOAD_MODAL';

  return {
    error: uploadPhotoIDsError,
    isShowing,
    loading: uploadPhotoIDsLoading,
    success: uploadPhotoIDsSuccess,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    clearUserError: () => dispatch(clearUserError()),
    closeModal: () => {
      dispatch(closeModal('PHOTO_ID_UPLOAD_MODAL'));
    },
    routerPush: () => dispatch(push('/checkout')),
    onSubmit: (data: FormValues) => {
      const payload = Object.keys(data).reduce((obj, userID) => {
        const files = data[userID];
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
        obj[userID] = files[0];
        return obj;
      }, {});
      dispatch(uploadPhotoIDs(payload));
    },
  };
};

// @ts-expect-error TS(2345): Argument of type 'typeof PhotoIDUploadContainer' is not assignable to parameter of type 'ComponentTy... (Delete me to see the full error)
export default connect(mapStateToProps, mapDispatchToProps)(PhotoIDUploadContainer);
