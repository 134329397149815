// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexRow, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { Image } from '~web/features/onboarding/components/styles';
import logoAlto from '~web/images/altoLogo@2x.png';

export const ColoredFlexRow = styled(FlexRow).attrs({
  horizontallyAlignContent: true,
  verticallyAlignContent: true,
})<{ mSpacing?: string; mPadding?: string; color?: string; isSMScreenOrBigger: boolean }>`
  ${({ color }) => color && `background-color: ${color};`}

  ${({ isSMScreenOrBigger, mSpacing, mPadding }) =>
    !isSMScreenOrBigger &&
    `
      > *:not(:last-child) {
        margin-bottom: ${mSpacing || 0};
      }
      padding: ${mPadding || 0};
    `}
  }
`;

const LogoContainer = styled.a`
  width: 115px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = () => {
  const { isSMScreenOrBigger } = useScreenSize();

  return (
    <ColoredFlexRow
      color={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      padding={spacing.lgSpacing}
      mPadding={spacing.smSpacing}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <LogoContainer
        href="https://alto.com"
        aria-label="Alto logo"
      >
        <Image src={logoAlto} />
      </LogoContainer>
    </ColoredFlexRow>
  );
};
