import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type ModeType } from '@alto/deliver_api/types/delivery_methods/v1/mode_type';
import BikeCourierTransparentFlipped from '~shared/assets/images/bike_courier_transparent_flipped.png';
import CalendarAngled from '~shared/assets/images/calendar-angled.png';
import MailDelivery from '~shared/assets/images/mail.png';
import ServiceFee from '~shared/assets/images/service-fee.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type APIOrderMethod__DEPRECATED, type APIOrderStatus } from '~shared/features/delivery-info/types';
import { SHIPMENT_STATUS_MAP, type StatusMap } from '~shared/features/shipments/constants';

export const COURIER_DELIVERY_METHODS = ['courier', 'onfleet'];

export const isMail = (mode: ModeType | null | undefined) => {
  return mode === 'MAIL';
};

export const isPickup = (mode: ModeType | null | undefined) => {
  return mode === 'PICKUP';
};

export const isCourier = (mode: ModeType | null | undefined) => {
  return mode === 'COURIER';
};

// Please use isCourier by passing in the delivery mode: ModeType instead
export const isCourier__DEPRECATED = (method: APIOrderMethod__DEPRECATED | undefined | null) => {
  return COURIER_DELIVERY_METHODS.includes(method ?? '');
};

// add null and undefined to set typing to satisfy TypeScript
export const STATUSES_TO_SHOW_MAIL_CARRIER = new Set<APIOrderStatus | null | undefined>([
  'delivered',
  'failed',
  'returned',
  'shipped',
]);

export const getMailCopy = (method: DeliveryMethod | null | undefined, status: APIOrderStatus | null | undefined) => {
  if (STATUSES_TO_SHOW_MAIL_CARRIER.has(status)) {
    const statusText = status === 'delivered' ? 'Delivered' : 'Ships';
    return `${statusText} via ${method?.patient_facing_name || 'mail'}`;
  }

  return 'Ships via mail';
};

export const getMailLogo = (method: DeliveryMethod | null | undefined, status: APIOrderStatus | null | undefined) => {
  if (STATUSES_TO_SHOW_MAIL_CARRIER.has(status)) {
    return method?.mail_carrier_struct?.logo_path;
  }

  return MailDelivery;
};

export type orderHelperProps = {
  method: DeliveryMethod | undefined | null;
  status: APIOrderStatus | undefined | null;
  failedPaymentMethods?: string;
  shipmentStatusMap?: StatusMap;
};

export const getOrderStatusTitle = ({ method, status, failedPaymentMethods, shipmentStatusMap }: orderHelperProps) => {
  if (!status) {
    return '';
  }
  const statusMap = shipmentStatusMap || SHIPMENT_STATUS_MAP;
  if (failedPaymentMethods) {
    return 'Update your payment method';
  }
  if (isMail(method?.mode)) {
    return statusMap[status]?.mailInfoText || '';
  }
  if (isPickup(method?.mode)) {
    return status === 'shipped' ? 'Your order is ready' : statusMap[status]?.pickupInfoText || '';
  }
  // pickup and courier have same title in processing state
  return statusMap[status]?.infoText || '';
};

export const getIllustration = ({ method, status }: orderHelperProps) => {
  // show delivery logo throughout pickup lifecycle
  if (isPickup(method?.mode)) {
    return 'https://assets.prod.alto.com/delivery_methods/pickup.png';
  }
  // show mail logo throughout lifecycle
  if (isMail(method?.mode)) {
    return getMailLogo(method, status);
  }
  // show calendar for all processing stages
  if (status === 'processing' || status === 'awaiting_packing' || status === 'packaged') {
    return CalendarAngled;
  }
  // show bike if courier, and delivery if pickup for shipped stage
  if (status === 'shipped') {
    return BikeCourierTransparentFlipped;
  }
  // show service fee for delivered stage
  if (status === 'delivered') {
    return ServiceFee;
  }
};

export const getAccessibilityLabel = ({ method, status }: orderHelperProps) => {
  if (isPickup(method?.mode)) {
    return IMG_ALT_TEXTS.delivery;
  }
  if (status === 'processing' || status === 'awaiting_packing' || status === 'packaged') {
    return IMG_ALT_TEXTS.calendar;
  }

  if (status === 'shipped') {
    if (isMail(method?.mode)) {
      return `Illustration of ${method?.patient_facing_name || 'mail carrier'}`;
    }
    return IMG_ALT_TEXTS.bikeCourier;
  }

  if (status === 'delivered') {
    return IMG_ALT_TEXTS.serviceFee;
  }

  return undefined;
};
