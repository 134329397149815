import { COLORS } from '@alto/design-library-tokens';
import { type ColorVariant } from '../../alerts';

export const getBackgroundColor = (type?: ColorVariant) => {
  switch (type) {
    case 'success':
      return COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTER;
    case 'warning':
      return COLORS.BACKGROUND_COLORS.WARNING_LIGHTER;
    case 'error':
      return COLORS.BACKGROUND_COLORS.DANGER_LIGHTER;
    case 'recommended':
      return COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTER;
    case 'counter':
      return COLORS.BACKGROUND_COLORS.PRIMARY;
    case 'neutral':
      return COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER;
    case 'action':
      return COLORS.BACKGROUND_COLORS.WHITE;
    default:
      return COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER;
  }
};

export const getHoveredBackgroundColor = (type?: ColorVariant) => {
  switch (type) {
    case 'success':
      return COLORS.BACKGROUND_COLORS.SUCCESS_LIGHT;
    case 'warning':
      return COLORS.BACKGROUND_COLORS.WARNING_LIGHT;
    case 'error':
      return COLORS.BACKGROUND_COLORS.DANGER_LIGHT;
    case 'recommended':
      return COLORS.BACKGROUND_COLORS.TERTIARY_LIGHT;
    case 'counter':
      return COLORS.BACKGROUND_COLORS.PRIMARY_DARK;
    case 'neutral':
      return COLORS.BACKGROUND_COLORS.PRIMARY_LIGHT;
    case 'action':
      return COLORS.BACKGROUND_COLORS.WHITE;
    default:
      return COLORS.BACKGROUND_COLORS.PRIMARY_LIGHT;
  }
};

export const getLabelColor = (type?: ColorVariant) => {
  switch (type) {
    case 'action':
      return COLORS.TEXT_COLORS.SECONDARY;
    case 'counter':
      return COLORS.TEXT_COLORS.WHITE;
    default:
      return COLORS.TEXT_COLORS.PRIMARY;
  }
};

export const getBorderColor = (type?: ColorVariant) => {
  if (type === 'action') {
    return COLORS.BORDER_COLORS.SECONDARY;
  }

  return getBackgroundColor(type);
};
