// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React from 'react';
import icePacks from '~shared/assets/images/ice-packs.png';

export const RefrigerationActionSheet = ({ medicationName }: { readonly medicationName: string }) => {
  return (
    <ActionSheetV2
      title="Refrigeration required"
      analyticsName="checkout refrigeration"
      illustrationSrc={icePacks}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          Store <Body fontFamily="semibold">{medicationName}</Body> in the refrigerator to ensure it remains stable and
          potent.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
