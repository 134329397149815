// @owners { team: patients-team }
import { AltoIcon } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Row, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  justify-content: space-between;
`;

export type Props = {
  readonly openCreditCardModal: () => void;
};

export default function AddPaymentMethodButton({ openCreditCardModal }: Props) {
  return (
    <StyledButton
      kind="secondary"
      onClick={openCreditCardModal}
    >
      <Row
        spacing={spacing.smSpacing}
        verticallyAlignContent
      >
        <AltoIcon
          name="plus-small"
          type="secondary"
        />
        <div>Add Payment Method</div>
      </Row>
    </StyledButton>
  );
}
