// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Body,
  Button,
  Column,
  FlatCard,
  H1,
  H2,
  InputSelect,
  LgPadding,
  LgSpacing,
  ListNavigation,
  MdPadding,
  MdSpacing,
  Row,
  SectionedPage,
  XlPadding,
  XlSpacing,
  XsPadding,
  XsSpacing,
  useScreenSize,
} from '@alto/design-system';
import { type Option } from '@alto/design-system/src/components/inputs/src/InputSelect';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect, useState } from 'react';
import { Linking } from 'react-native';
// eslint-disable-next-line import/no-deprecated
import { fetchFacilities } from '~shared/actions/facilities';
import { ALTO_FAQS_URL, FAX, PHONE } from '~shared/constants';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getFacilityOptions from '~shared/selectors/getFacilityOptions';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Facility } from '~shared/types/facility';
import { AltoLogoNavBar } from './AltoLogoNavBar';
import { AltoMedBagImage } from './AltoMedBagImage';
import { AndroidAppDownloadImageLink } from './AndroidAppDownloadImageLink';
import { FAQActionSheet } from './FAQActionSheet';
import { IosAppDownloadImageLink } from './IosAppDownloadImageLink';
import { PhoneImage } from './PhoneImage';

export const OnboardingLandingPage = () => {
  const dispatch = useDispatchShared();
  const { goBack, navigate } = useNavigation();
  const { trackPageView } = useAnalytics();
  const { isMDScreenOrBigger } = useScreenSize();
  const facilityOptions = useSelectorShared(getFacilityOptions);
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const [selectedFacilityID, setSelectedFacilityID] = useState<string | null>(null);
  const [selectedFacilityData, setSelectedFacilityData] = useState<Facility | null>(null);
  const isWeb = getPlatformOS() === 'web';

  const selectOptions: Option[] = facilityOptions
    ? facilityOptions.map((facility) => {
        return {
          label: facility.label || '',
          value: facility.value.toString(),
        };
      })
    : [];

  const facilityIdToData: Record<string, Facility> = facilityOptions.reduce(
    (acc, facility) => ({ ...acc, [facility.value]: facility?.data }),
    {},
  );

  const selectFacility = (facilityID: string) => {
    setSelectedFacilityID(facilityID);
    setSelectedFacilityData(facilityIdToData[facilityID]);
  };
  const createAccount = () => {
    navigate('RouteOnboardingGeoVerify');
  };

  const openFAQsLink = () => {
    Linking.openURL(ALTO_FAQS_URL);
  };

  useEffect(() => {
    trackPageView({
      event: EVENTS.ORGANIC_ONBOARDING_LANDING_PAGE_VIEWED,
    });
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchFacilities());
  }, [dispatch, trackPageView]);

  return (
    <SectionedPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      onDismiss={isWeb ? undefined : goBack}
      NavBarCenterContent={<AltoLogoNavBar />}
      HeaderContent={
        <LgPadding bottomPadding={SPACING.STATIC.MD}>
          <Column center>
            <AltoMedBagImage />
            <H1>Create an account</H1>
            <XsSpacing />
            <XsPadding bottomPadding={SPACING.STATIC.MD}>
              <Body center>
                Transfer prescriptions, manage your medications and orders, contact the Alto care team, and more.
              </Body>
            </XsPadding>
          </Column>
          <MdPadding bottomPadding={SPACING.STATIC.NONE}>
            <Row center>
              <Button
                onPress={createAccount}
                width={isWeb ? 'inline' : 'full'}
                accessibilityLabel="Press to create an account"
                testID="create-account"
              >
                Create account
              </Button>
            </Row>
          </MdPadding>
        </LgPadding>
      }
    >
      <XlPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <H1 center>Share Alto’s information with your healthcare provider</H1>
        <XsSpacing />
        <Body center>Select the Alto location closest to you:</Body>
        <MdSpacing />
        <XsPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <InputSelect
            leftIcon="mapmarker-small"
            options={selectOptions}
            onValueChange={(value) => {
              selectFacility(value ? value.toString() : '');
            }}
            value={selectedFacilityID ? selectedFacilityID.toString() : ''}
            placeholder="Select a location"
          />
        </XsPadding>
        {selectedFacilityData ? (
          <>
            <MdSpacing />
            <FlatCard>
              <MdPadding>
                {selectedFacilityData.full_address ? (
                  <>
                    <Row>
                      <Column>
                        <H2>Address</H2>
                        <XsSpacing />
                        <Body>{selectedFacilityData.full_address}</Body>
                        <XsSpacing />
                      </Column>
                    </Row>
                    <XsSpacing />
                  </>
                ) : null}
                <Row spaceBetween>
                  <Column>
                    <H2>Phone</H2>
                    <XsSpacing />
                    <Body>{PHONE}</Body>
                    <XsSpacing />
                  </Column>
                  <Column>
                    <H2>Fax</H2>
                    <XsSpacing />
                    <Body>{FAX}</Body>
                    <XsSpacing />
                  </Column>
                </Row>
                {selectedFacilityData.npi ? (
                  <>
                    <XsSpacing />
                    <Row>
                      <Column>
                        <H2>NPI</H2>
                        <XsSpacing />
                        <Body>{selectedFacilityData.npi}</Body>
                        <XsSpacing />
                      </Column>
                    </Row>
                  </>
                ) : null}
              </MdPadding>
            </FlatCard>
          </>
        ) : null}
      </XlPadding>
      <LgPadding
        leftPadding={SPACING.STATIC.NONE}
        rightPadding={SPACING.STATIC.NONE}
        topPadding={SPACING.STATIC.XL}
        bottomPadding={SPACING.STATIC.MD}
      >
        <H1 center>FAQs</H1>
      </LgPadding>
      <ListNavigation
        text="Do you carry my medication?"
        onPress={() => {
          setActiveActionSheet(<FAQActionSheet topic="carry_medication" />);
        }}
      />
      <ListNavigation
        text="Will I be covered by my insurance?"
        onPress={() => {
          setActiveActionSheet(<FAQActionSheet topic="insurance_covered" />);
        }}
      />
      <ListNavigation
        text="Will my medications cost more through Alto?"
        onPress={() => {
          setActiveActionSheet(<FAQActionSheet topic="medication_cost" />);
        }}
      />
      <ListNavigation
        text="How does a transfer work?"
        onPress={() => {
          setActiveActionSheet(<FAQActionSheet topic="transfer" />);
        }}
      />
      <ListNavigation
        text="Do you offer delivery?"
        onPress={() => {
          setActiveActionSheet(<FAQActionSheet topic="delivery" />);
        }}
      />
      <LgPadding
        topPadding={SPACING.STATIC.MD}
        bottomPadding={SPACING.STATIC.MD}
      >
        <Row center>
          <Button
            onPress={openFAQsLink}
            width={isWeb ? 'inline' : 'full'}
          >
            Read all FAQs
          </Button>
        </Row>
      </LgPadding>
      {isWeb ? (
        <>
          <XlSpacing />
          <LgPadding>
            <Column center>
              <PhoneImage />
              <LgSpacing />
              <H1>Download the Alto app</H1>
              <LgSpacing />
              {isMDScreenOrBigger ? (
                <Row>
                  <IosAppDownloadImageLink />
                  <MdSpacing />
                  <AndroidAppDownloadImageLink />
                </Row>
              ) : (
                <Column>
                  <IosAppDownloadImageLink />
                  <MdSpacing />
                  <AndroidAppDownloadImageLink />
                </Column>
              )}
            </Column>
          </LgPadding>
        </>
      ) : null}
    </SectionedPage>
  );
};
