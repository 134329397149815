// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoSpinningLoader, Column, Link, ListDescription, ListItem, Row, XxsSpacing } from '@alto/design-system';
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';
import { format, isSameYear, parseISO } from 'date-fns';
import React from 'react';
import { handlePressLink, urlRegExp } from '~shared/helpers/links';
import { Dot } from '../../../navigation';
import { ThreadUserIcon } from './ThreadUserIcon';
import { chatbotMessage } from './utils';

type GetNameProps = {
  admin_user_id: number | null | undefined;
  is_support_chatbot: boolean;
};

export const getName = ({ admin_user_id, is_support_chatbot }: GetNameProps) => {
  if (is_support_chatbot) return 'Alto Assistant';
  if (admin_user_id) return 'Alto Care Team Member';
  return 'You';
};

type UseBylineProps = {
  created_at: string;
  admin_user_id: number | null | undefined;
  is_support_chatbot: boolean;
};

export const useByline = ({ created_at, admin_user_id, is_support_chatbot }: UseBylineProps) => {
  const name = getName({ admin_user_id, is_support_chatbot });
  const date = parseISO(created_at);
  const sameYear = isSameYear(date, new Date());
  const dateFormat = sameYear ? 'MMM d, h:mm aa' : 'MMM d, yyyy h:mm aa';
  return `${name} • ${format(date, dateFormat)}`;
};

type ThreadDescriptionsProps = {
  readonly body: string | null | undefined;
  readonly id: number;
};

const ThreadDescriptions = ({ body, id }: ThreadDescriptionsProps) => {
  if (!body) return null;
  if (body === chatbotMessage) {
    return (
      <Row
        centerHorizontally
        key={`wundercom-body-${id}`}
      >
        <AltoSpinningLoader small />
        <XxsSpacing />
        <ListDescription>Processing your request... this could take a minute</ListDescription>
      </Row>
    );
  }

  const urlMatches = new Set(body.match(urlRegExp) ?? []);
  const words = urlMatches.size === 0 ? [] : body.split(' ');

  const parsedWords =
    urlMatches.size === 0
      ? body
      : words.map((word, index) => {
          if (urlMatches.has(word)) {
            return (
              <React.Fragment key={`url-link-${id}-${index}`}>
                <Link
                  onPress={() => {
                    handlePressLink(word);
                  }}
                  fontFamily="regular"
                  underline
                  textSize="mini"
                >
                  {word}
                </Link>
                {index !== words.length - 1 && ' '}
              </React.Fragment>
            );
          }
          return word + (index !== words.length - 1 ? ' ' : '');
        });

  return (
    <ListDescription key={`wundercom-body-${id}`}>
      <React.Fragment>{parsedWords}</React.Fragment>
    </ListDescription>
  );
};

type ThreadMessageProps = Pick<
  WundercomMessage,
  'id' | 'admin_user_id' | 'body' | 'created_at' | 'is_support_chatbot' | 'user_id' | 'read_at'
>;

/**
 * Component for messages (wundercoms) in a single thread. Can be from Alto or the patient
 * @param {WundercomMessage} props component props
 * @returns ThreadMessage UI
 */
export const ThreadMessage = ({
  id,
  admin_user_id,
  body,
  created_at,
  is_support_chatbot,
  user_id,
  read_at,
}: ThreadMessageProps) => {
  const byline = useByline({ created_at, admin_user_id, is_support_chatbot });
  const backgroundColor =
    admin_user_id || is_support_chatbot ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST : COLORS.BACKGROUND_COLORS.WHITE;
  const unread = !!admin_user_id && !is_support_chatbot && read_at === undefined;

  return (
    <ListItem
      RightContent={
        <Column
          flexGrow={1}
          columnStart
        >
          <Dot
            relative
            rightPosition={0}
            topPosition={0}
            withAnimation
            hidden={!unread}
          />
        </Column>
      }
      LeftContent={
        <ThreadUserIcon
          admin_user_id={admin_user_id}
          is_support_chatbot={is_support_chatbot}
          user_id={user_id}
        />
      }
      byline={byline}
      descriptions={
        <ThreadDescriptions
          body={body}
          id={id}
        />
      }
      backgroundColor={backgroundColor}
    />
  );
};
