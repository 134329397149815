// @owners { team: patients-team }
import { UPDATE_TIP_PAYMENT_METHOD_ID, type UpdateTipPaymentMethodIDAction } from '~shared/actions/ui/shipmentTipInput';
import { withRehydration } from '~shared/reducers/withRehydration';

type Action = UpdateTipPaymentMethodIDAction;

export type ShipmentTipInputState = {
  tipPaymentMethodID: number | null | undefined;
};

const initialState: ShipmentTipInputState = {
  tipPaymentMethodID: null,
};

// During checkout we use the Order (cart) to store tip input,
// and we use React state during post-delivery tipping on web
// (since we don't have an order at that point).
// This causes issues on mobile because we navigate a lot and can't
// always pass down state. Having a single store for input is a
// major convenience in these scenarios.
const shipmentTipInput = (state: ShipmentTipInputState = initialState, action: Action): ShipmentTipInputState => {
  switch (action.type) {
    case UPDATE_TIP_PAYMENT_METHOD_ID:
      return { ...state, tipPaymentMethodID: action.payload };

    default:
      return state;
  }
};

export default withRehydration(shipmentTipInput, initialState);
