import { COLORS, TYPOGRAPHY, Z_INDEX } from '@alto/design-library-tokens';
import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { useAccessibilityFocus } from '../../../hooks/useAccessibilityFocus';
import { useScreenSize } from '../../../utils';
import { Text, type TextProps } from './Text';

export type H1Props = Pick<TextProps, 'center' | 'children'> & {
  readonly autofocus?: boolean;
  readonly zIndex?: number;
};

const StyledH1 = styled(Text)<H1Props & { isMDScreenOrBigger?: boolean; zIndex?: number }>`
  ${({ isMDScreenOrBigger }) => {
    if (Platform.OS === 'web' && isMDScreenOrBigger) {
      return `
        font-size: ${TYPOGRAPHY.TEXT.HEADER.LG.px};
        line-height: ${TYPOGRAPHY.TEXT.HEADER.LG.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
      `;
    }
    return `
      font-size: ${TYPOGRAPHY.TEXT.HEADER.MD.px};
      line-height: ${TYPOGRAPHY.TEXT.HEADER.MD.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
    `;
  }}
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.SM.px};
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  font-family: ${TYPOGRAPHY.FONT.HEADER.SEMIBOLD};
  ${({ zIndex }) => {
    if (zIndex) {
      return `z-index: ${zIndex};`;
    }
    return `z-index: ${Z_INDEX.Z_DEFAULT};`;
  }}}
`;

export const H1 = ({ center, children, autofocus, zIndex }: H1Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  const ref = useAccessibilityFocus(autofocus);

  return (
    <StyledH1
      accessible={autofocus}
      focusable
      role="heading"
      aria-level={1}
      center={center}
      ref={ref}
      isMDScreenOrBigger={isMDScreenOrBigger}
      zIndex={zIndex}
    >
      {children}
    </StyledH1>
  );
};
