// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { isActiveRx } from '~shared/features/prescriptions/helper';
import { type MedicationParam } from '~shared/selectors/selectorParamTypes';
import { type LightDelivery, type Prescription, type ReduxStateShared } from '~shared/types';

const getMedication = (_: ReduxStateShared, props: MedicationParam) => props.medication;

const getDeliveriesByMedicationKey = createSelector([getDeliveries, getMedication], (deliveries, medication) => {
  const deliveriesByMedicationKey: Record<string, LightDelivery[]> = {};

  if (medication?.key) {
    // Index active prescriptions by ID
    const activePrescriptionsByID = medication.prescriptions.reduce<Record<number, Prescription>>(
      (prescriptions, prescription) => {
        if (isActiveRx(prescription)) {
          prescriptions[prescription.id] = prescription;
        }
        return prescriptions;
      },
      {},
    );

    // As long as an upcoming delivery matches one of the active prescriptions
    // it counts as a delivery for that medication
    const deliveriesForMedication = deliveries.filter(({ prescription_id }) => {
      return !!activePrescriptionsByID[prescription_id];
    });

    const key = medication.key || '';
    deliveriesByMedicationKey[key] = deliveriesForMedication || [];
  }

  return deliveriesByMedicationKey;
});

export const getDeliveriesForMedication = createSelector(
  [getMedication, getDeliveriesByMedicationKey],
  (medication, deliveriesByMedicationKey) => {
    return (medication && deliveriesByMedicationKey[medication.key || '']) || [];
  },
);
