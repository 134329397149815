// @owners { team: patients-team }
import { openModal } from './modal';
// eslint-disable-next-line import/no-deprecated
import { fetchPendingRateShipment } from './rateShipment';
import getShouldShowRateShipmentModal from '~shared/features/rate-shipment/selectors/getShouldShowModal';
import { getShouldPromptUserForPhiAuth } from '~shared/features/users/selectors/getUserPhiAuth';
import { type ReduxDispatch, type ReduxGetStateShared } from '~shared/types';

export function onAuthSuccessful() {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();

    if (getShouldPromptUserForPhiAuth(state)) {
      dispatch(openModal('PHI_AUTH_MODAL'));
    } else {
      // eslint-disable-next-line import/no-deprecated, promise/catch-or-return
      dispatch(fetchPendingRateShipment()).then((success: boolean) => {
        if (!success) return;

        // eslint-disable-next-line promise/always-return
        if (getShouldShowRateShipmentModal(getState())) {
          dispatch(openModal('POST_DELIVERY_MODAL'));
        }
      });
    }
  };
}
