// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Column, H3, MdPadding, Pressable, Row } from '@alto/design-system';
import React, { useState } from 'react';

type Props = {
  readonly title: string;
  readonly content: React.ReactNode;
};

export const CollapsibleSection = ({ content, title }: Props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Row wrap>
      <Pressable
        onPress={toggleOpen}
        accessibilityLabel={open ? 'press to hide content' : 'press to show content'}
        accessibilityRole="togglebutton"
        width={'100%'}
      >
        <MdPadding>
          <Row
            left
            spaceBetween
            wrap={false}
            flexShrink={1}
          >
            <H3
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.BLACK}
            >
              {title}
            </H3>
            <Column
              flexShrink={0}
              flexGrow={1}
              right
            >
              {open ? (
                <AltoIcon
                  name="chevroncircleup-small"
                  type="grey"
                />
              ) : (
                <AltoIcon
                  name="chevroncircledown-small"
                  type="grey"
                />
              )}
            </Column>
          </Row>
          {open ? content : null}
        </MdPadding>
      </Pressable>
    </Row>
  );
};
