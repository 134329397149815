import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding } from '@alto/design-system';
import React, { useContext } from 'react';
import { PRICING_INFO } from '~shared/features/my-meds/constants';

type Props = {
  readonly totalItemCount: number;
};

export const EstimatedPriceActionSheet = ({ totalItemCount }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);

  return (
    <ActionSheetV2
      buttons={[
        <Button
          key="close"
          type="secondary"
          onPress={closeActionSheet}
        >
          Close
        </Button>,
      ]}
      title="Estimated pricing"
      analyticsName="estimated pricing"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          {`${PRICING_INFO.cannot_bill_automatically(totalItemCount)} ${PRICING_INFO.price_estimated(totalItemCount)}`}
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
