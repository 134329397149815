import { type PaymentMethod } from '~shared/types';

export type PaymentMethodIconName =
  | 'cc-visa'
  | 'cc-mastercard'
  | 'cc-discover'
  | 'cc-amex'
  | 'cc-jcb'
  | 'cc-diners-club'
  | 'university'
  | 'credit-card'
  | 'apple-pay'
  | 'google-pay';

export default function getPaymentMethodIconName({
  details,
  type,
}: Pick<PaymentMethod, 'details' | 'type'> = {}): PaymentMethodIconName {
  if (type === 'StripeData' && details) {
    if (details.native_pay_type) {
      switch (details.native_pay_type) {
        case 'apple_pay':
          return 'apple-pay';

        case 'google_pay':
          return 'google-pay';

        default:
          return 'credit-card';
      }
    }

    switch (details.card_type) {
      case 'Visa':
        return 'cc-visa';

      case 'MasterCard':
        return 'cc-mastercard';

      case 'Discover':
        return 'cc-discover';

      case 'American Express':
        return 'cc-amex';

      case 'JCB':
        return 'cc-jcb';

      case 'Diners Club':
        return 'cc-diners-club';

      default:
        return 'credit-card';
    }
  } else if (type === 'PlaidData') {
    return 'university';
  }

  return 'credit-card';
}
