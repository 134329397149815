import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { requestVerification } from '~shared/actions/verifications';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import CommsVerificationFail from './CommsVerificationFail';

type OwnProps = {
  commsChannel: string;
  token: string;
  authorized: boolean;
};

const mapStateToProps = (state: ReduxStateShared, ownProps: OwnProps) => {
  const { requestVerificationLoading } = getLoading(state);
  return {
    ...ownProps,
    requestVerificationLoading,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared, ownProps: OwnProps) => ({
  navigateToNextRoute: () => {
    if (ownProps.authorized) {
      dispatch(
        push({
          pathname: '/prescriptions',
        }),
      );
    } else {
      // TODO: Get app store route
      dispatch(
        push({
          pathname: '/',
        }),
      );
    }
  },
  // eslint-disable-next-line import/no-deprecated
  requestVerification: (token: string, commsChannel: string) => dispatch(requestVerification(token, commsChannel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommsVerificationFail);
