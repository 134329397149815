// @owners { team: patients-team }
import {
  AnnouncementsEndpoint,
  type AnnouncementsEndpointFetchAnnouncementRequest,
  type AnnouncementsEndpointFetchAnnouncementResponse,
} from '@alto/scriptdash/alto/patient_app/engagement/v1/announcements_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';

const announcementsEndpoint = AnnouncementsEndpoint(apiEndpointHandler);

export const announcements = createQueryKeys('announcements', {
  fetchAnnouncement: ({ params }: { params: AnnouncementsEndpointFetchAnnouncementRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<AnnouncementsEndpointFetchAnnouncementResponse> => {
      const response = await announcementsEndpoint.fetchAnnouncement(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
});
