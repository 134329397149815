import { type ApiResponseRow, type DataSourceResponse } from './types';

export const UPDATE_TEXT = 'UPDATE_TEXT';
type UpdateTextAction = {
  type: typeof UPDATE_TEXT;
  payload: string;
};
export const updateText = (payload: string): UpdateTextAction => ({
  type: UPDATE_TEXT,
  payload,
});

export const CLEAR_RESULTS = 'CLEAR_RESULTS';
type ClearResultsAction = {
  type: typeof CLEAR_RESULTS;
};
export const clearResults: () => ClearResultsAction = () => ({
  type: CLEAR_RESULTS,
});

export const API_SUCCESS = 'API_SUCCESS';
type ApiSuccessAction = {
  type: typeof API_SUCCESS;
  payload: {
    response: DataSourceResponse<any>;
    shouldAllowManualInput: boolean;
  };
};
export const apiSuccess: (arg0: DataSourceResponse<any>, arg1: boolean | null | undefined) => ApiSuccessAction = (
  payload,
  shouldAllowManualInput,
) => ({
  type: API_SUCCESS,
  payload: {
    response: payload,
    shouldAllowManualInput: !!shouldAllowManualInput,
  },
});

type SelectionType = 'multi' | 'single' | undefined;

export const SELECT_ITEM = 'SELECT_ITEM';
type SelectItemAction = {
  type: typeof SELECT_ITEM;
  payload: {
    selectedItem: ApiResponseRow<any>;
    selectionType: SelectionType;
  };
};
export const selectItem = (selectedItem: ApiResponseRow<any>, selectionType: SelectionType): SelectItemAction => ({
  type: SELECT_ITEM,
  payload: {
    selectedItem,
    selectionType,
  },
});

export const REMOVE_SELECTED_ITEM = 'REMOVE_SELECTED_ITEM';
type RemoveSelectedItem = {
  type: typeof REMOVE_SELECTED_ITEM;
  payload: {
    rowToRemove: ApiResponseRow<any>;
    selectionType: SelectionType;
  };
};
export const removeSelectedItem = (
  rowToRemove: ApiResponseRow<any>,
  selectionType: SelectionType,
): RemoveSelectedItem => ({
  type: REMOVE_SELECTED_ITEM,
  payload: {
    rowToRemove,
    selectionType,
  },
});

export const INCREMENT_HIGHLIGHTED_RESULT_INDEX = 'INCREMENT_HIGHLIGHTED_RESULT_INDEX';
type IncrementHighlightedResultIndex = {
  type: typeof INCREMENT_HIGHLIGHTED_RESULT_INDEX;
};
export const incrementHighlightedResultIndex: () => IncrementHighlightedResultIndex = () => ({
  type: INCREMENT_HIGHLIGHTED_RESULT_INDEX,
});

export const DECREMENT_HIGHLIGHTED_RESULT_INDEX = 'DECREMENT_HIGHLIGHTED_RESULT_INDEX';
type DecrementHighlightedResultIndex = {
  type: typeof DECREMENT_HIGHLIGHTED_RESULT_INDEX;
};
export const decrementHighlightedResultIndex: () => DecrementHighlightedResultIndex = () => ({
  type: DECREMENT_HIGHLIGHTED_RESULT_INDEX,
});

export const INPUT_FOCUSED = 'INPUT_FOCUSED';
type InputFocused = {
  type: typeof INPUT_FOCUSED;
};
export const inputFocused: () => InputFocused = () => ({
  type: INPUT_FOCUSED,
});

export const INPUT_BLURRED = 'INPUT_BLURRED';
type InputBlurred = {
  type: typeof INPUT_BLURRED;
};
export const inputBlurred: () => InputBlurred = () => ({
  type: INPUT_BLURRED,
});

export const API_SENT = 'API_SENT';
type ApiSent = {
  type: typeof API_SENT;
};
export const apiSent: () => ApiSent = () => ({
  type: API_SENT,
});

export const API_ERROR = 'API_ERROR';
type ApiError = {
  type: typeof API_ERROR;
  payload: {
    shouldAllowManualInput: boolean;
  };
};
export const apiError: (arg0: boolean | null | undefined) => ApiError = (shouldAllowManualInput) => ({
  type: API_ERROR,
  payload: {
    shouldAllowManualInput: !!shouldAllowManualInput,
  },
});

export type Actions =
  | UpdateTextAction
  | ClearResultsAction
  | ApiSuccessAction
  | SelectItemAction
  | RemoveSelectedItem
  | IncrementHighlightedResultIndex
  | DecrementHighlightedResultIndex
  | InputFocused
  | InputBlurred
  | ApiSent
  | ApiError;
