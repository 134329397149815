// @owners { team: patients-team }
import { type PriceOption, type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createSelector } from 'reselect';
import { getOrderPricing, getShipmentPricing } from './getPricing';
import { type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, prescriptionID: number) => prescriptionID;
const getPriceType = (_state: ReduxStateShared, _prescriptionID: number, priceType: PriceType | null | undefined) =>
  priceType;

export const getSavingsAppliedAmountForPrescription = createSelector(
  [getOrderPricing, getShipmentPricing, getPrescriptionID, getPriceType],
  (orderPricing, shipmentPricing, prescriptionID, priceType) => {
    const pricing = orderPricing.item_pricing[prescriptionID] || shipmentPricing.item_pricing[prescriptionID];
    const priceOption = pricing?.prices?.find((price: PriceOption) => price.type === priceType);

    return (
      (priceOption?.payment_breakdown?.non_insurance_payers_paid ?? 0) +
      (priceOption?.payment_breakdown?.remainder_paid ?? 0)
    );
  },
);
