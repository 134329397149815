import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { useEscapeKeyBinding } from '~web/hooks';

const ModalContainer = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.BACKGROUND_COLORS.GREY_LIGHTER};
  overflow: hidden;
  padding: ${SPACING.STATIC.XXL.px} 0;
  transition: opacity 0.3s;

  opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
  pointer-events: ${({ isOpen }) => (isOpen ? `auto` : `none`)};
`;

const ModalImage = styled.img<{ isOpen: boolean }>`
  cursor: default;
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.4s;

  transform: ${({ isOpen }) => (isOpen ? `transform: translateY(0);` : `translateY(15%) scale(0.9)`)};
`;

const CloseButton = styled.button.attrs({
  type: 'button',
})`
  top: 0;
  left: 0;
  position: absolute;
  border: none;
  display: flex;
  background: none;
  align-items: center;
  justify-content: center;
  width: ${SPACING.STATIC.XXL.px};
  height: ${SPACING.STATIC.XXL.px};
`;

const ImageContainer = styled.div`
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type EnlargeableImageProps = {
  readonly alt: string;
  readonly className?: string;
  readonly src: string;
  readonly onClose?: () => void;
  readonly onOpen?: () => void;
};

const noop = () => undefined;

export const EnlargeableImage = ({
  alt,
  children,
  className,
  src,
  onClose = noop,
  onOpen = noop,
}: React.PropsWithChildren<EnlargeableImageProps>) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  // Open modal and trigger callback
  const openModal = () => {
    setIsOpen(true);
    onOpen();
  };

  // Close modal and trigger callback
  const closeModal = () => {
    setIsOpen(false);
    onClose();
  };

  // Close modal when pressing "escape" key
  useEscapeKeyBinding(closeModal);

  // Handle click on modal container
  const handleContainerClick = (event: any) => {
    // Ignore clicks on image element
    if (event.target instanceof HTMLImageElement) {
      return;
    }

    closeModal();
  };

  return (
    <>
      <ImageContainer
        className={className}
        onClick={openModal}
      >
        <Image
          src={src}
          alt={alt}
        />
        {children}
      </ImageContainer>

      <ModalContainer
        onClick={handleContainerClick}
        isOpen={isOpen}
      >
        <CloseButton
          onClick={() => {
            closeModal();
          }}
        >
          <AltoIcon
            name="close"
            accessibilityLabel="Close"
          />
        </CloseButton>
        <ModalImage
          src={src}
          alt={alt}
          isOpen={isOpen}
        />
      </ModalContainer>
    </>
  );
};
