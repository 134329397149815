// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import { SUPPORT_CASES_WITH_UNREAD_MESSAGES, type updateSupportCaseWithUnreadMessages } from '~shared/actions/messages';

export type StateMessages = {
  supportCasesWithUnreadMessages: Set<number>;
};

export const initialState: StateMessages = {
  supportCasesWithUnreadMessages: new Set(),
};

type MessagesActions = ReturnType<typeof updateSupportCaseWithUnreadMessages>;

const messages = (state: StateMessages = initialState, action: MessagesActions): StateMessages => {
  switch (action.type) {
    case SUPPORT_CASES_WITH_UNREAD_MESSAGES: {
      const updatedSet = action.payload instanceof Set ? action.payload : new Set<number>();

      const mergedSet = new Set(updatedSet);

      return {
        ...state,
        supportCasesWithUnreadMessages: mergedSet,
      };
    }
    default:
      return state;
  }
};

export default withRehydration(messages, initialState);
