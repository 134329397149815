import {
  type ShipmentFeesEndpointFetchConfigsRequest,
  ShipmentFeesEndpoint as ShipmentFeesEndpointV1,
} from '@alto/scriptdash/alto/patient_app/scheduling/v1/shipment_fees_endpoint';
import {
  type ShipmentFeesEndpointFetchAvailableRequest,
  ShipmentFeesEndpoint as ShipmentFeesEndpointV3,
} from '@alto/scriptdash/alto/patient_app/scheduling/v3/shipment_fees_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';

const ShipmentFeesApiV1 = ShipmentFeesEndpointV1(apiEndpointHandler);
const ShipmentFeesApiV3 = ShipmentFeesEndpointV3(apiEndpointHandler);

export const shipmentFees = createQueryKeys('shipmentFees', {
  available: (params: ShipmentFeesEndpointFetchAvailableRequest) => ({
    queryKey: [params],
    queryFn: () => ShipmentFeesApiV3.fetchAvailable(params),
  }),
  configs: (params: ShipmentFeesEndpointFetchConfigsRequest = {}) => ({
    queryKey: [params],
    queryFn: () => ShipmentFeesApiV1.fetchConfigs(params),
  }),
});
