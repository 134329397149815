// @owners { team: patients-team }
import { Body } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { InputIcon, Row } from '@alto/pocky';
import React, { useCallback, useState } from 'react';
import { CUSTOM_TIP_PLACEHOLDER, MAX_TIP_AMOUNT_LENGTH } from '~shared/features/courier-tips/constants';
import { formatDollars, formatInputAsCurrency } from '~shared/helpers/currency';

type Props = {
  readonly alignCenter?: boolean;
  readonly editTipAmount: (tipAmount: number) => void;
  readonly initialTipAmount: number;
};

export default function CustomTipInput({ alignCenter = false, editTipAmount, initialTipAmount }: Props) {
  let initialDisplayValue = '';

  if (initialTipAmount > 0) {
    initialDisplayValue = formatDollars(initialTipAmount, false);
  }

  const [displayValue, setDisplayValue] = useState(initialDisplayValue);
  const [isTouched, setIsTouched] = useState(false);

  const handleChangeTipAmount: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const currencyValue = formatInputAsCurrency(event.target.value);
      const currencyValueAsNumber = Number(currencyValue) || 0;

      setIsTouched(true);
      setDisplayValue(currencyValue);

      editTipAmount(currencyValueAsNumber);
    },
    [editTipAmount, setDisplayValue, setIsTouched],
  );

  return (
    <Row horizontallyAlignContent={alignCenter}>
      <InputIcon
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        icon={<Body>$</Body>}
        maxLength={MAX_TIP_AMOUNT_LENGTH}
        onChange={handleChangeTipAmount}
        placeholder={CUSTOM_TIP_PLACEHOLDER}
        // @ts-expect-error this technically isn't a valid prop but it may be related to react-redux-form, so I'm hesitant to remove it without more research
        touched={isTouched}
        value={displayValue}
      />
    </Row>
  );
}
