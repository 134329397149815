// @owners { team: patients-team }
import { ListDescription, ListItem, MdSpacing, SubwayStation } from '@alto/design-system';
import { type ProposedShipment } from '@alto/scriptdash/alto/med_sync/types/patients/v1/proposed_shipment';
import React from 'react';
import { formatRelativeDateWithDow, normalizeDateString } from '~shared/helpers/date';

type Props = {
  readonly proposedShipment: ProposedShipment;
};

export const MedSyncSelfServiceShipmentSubwayStation = ({ proposedShipment }: Props) => {
  const proposedDeliveries = proposedShipment.proposed_deliveries?.map((proposedDelivery, index) => {
    const lastDelivery = index === (proposedShipment.proposed_deliveries?.length || 0) - 1;

    return (
      <>
        <ListItem
          fullBleed
          descriptions={[
            <ListDescription
              key={proposedDelivery.medication_name}
              iconName="pillbottle-small"
            >
              {proposedDelivery.medication_name}
            </ListDescription>,
          ]}
        />
        <ListItem
          fullBleed
          descriptions={[
            <ListDescription
              key={proposedDelivery.days_supply}
              iconName="pills-small"
            >{`${proposedDelivery.days_supply} day supply`}</ListDescription>,
          ]}
        />
        {!lastDelivery && <MdSpacing />}
      </>
    );
  });

  return (
    <SubwayStation
      title={formatRelativeDateWithDow(normalizeDateString(proposedShipment.date) || '')}
      descriptions={proposedDeliveries}
      id={proposedShipment.date}
      key={proposedShipment.date}
    />
  );
};
