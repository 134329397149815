import { type CheckoutFlowType } from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import { type EnterCartMetadata } from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import {
  CLEAR_ENTER_CART_TYPES,
  FETCH_ENTER_CART_SUCCEEDED,
  FETCH_ENTER_CHECKOUT_SUCCEEDED,
} from '~shared/actions/cart';

type Action = {
  type:
    | typeof CLEAR_ENTER_CART_TYPES
    | typeof FETCH_ENTER_CART_SUCCEEDED
    | typeof FETCH_ENTER_CHECKOUT_SUCCEEDED
    | typeof INIT_ORDER_BUNDLING
    | typeof EXIT_ORDER_BUNDLING;
  payload?: any;
};

export type StateUiCheckoutFlow = {
  flowTypes: CheckoutFlowType[];
  enterCartTypes: CheckoutFlowType[];
  enterCartData?: EnterCartMetadata;
  isOrderBundling: boolean;
};

export const initialState: StateUiCheckoutFlow = {
  flowTypes: [],
  enterCartTypes: [],
  enterCartData: undefined,
  isOrderBundling: false,
};

export const INIT_ORDER_BUNDLING = 'INIT_ORDER_BUNDLING';
export type ActionInitOrderBundling = {
  type: typeof INIT_ORDER_BUNDLING;
};

export function initializeOrderBundling(): ActionInitOrderBundling {
  return {
    type: INIT_ORDER_BUNDLING,
  };
}

export const EXIT_ORDER_BUNDLING = 'EXIT_ORDER_BUNDLING';
export type ActionExitOrderBundling = {
  type: typeof EXIT_ORDER_BUNDLING;
};

export function exitOrderBundling(): ActionExitOrderBundling {
  return {
    type: EXIT_ORDER_BUNDLING,
  };
}

const checkoutFlow = (state: StateUiCheckoutFlow = initialState, action: Action): StateUiCheckoutFlow => {
  const { type, payload } = action;

  switch (type) {
    case INIT_ORDER_BUNDLING:
      return {
        ...state,
        isOrderBundling: true,
      };
    case EXIT_ORDER_BUNDLING:
      return {
        ...state,
        isOrderBundling: false,
      };
    case FETCH_ENTER_CHECKOUT_SUCCEEDED: {
      const { checkout_flow_types } = payload;

      if (checkout_flow_types?.length) {
        return {
          ...state,
          flowTypes: checkout_flow_types,
        };
      }

      return state;
    }

    case FETCH_ENTER_CART_SUCCEEDED: {
      const { checkout_flow_types, checkout_flow_data } = payload.data || payload;
      if (checkout_flow_types?.length) {
        return {
          ...state,
          enterCartTypes: checkout_flow_types,
          enterCartData: checkout_flow_data,
        };
      }

      return state;
    }

    case CLEAR_ENTER_CART_TYPES: {
      return {
        ...state,
        enterCartTypes: initialState.enterCartTypes,
      };
    }

    default:
      return state;
  }
};

export default checkoutFlow;
