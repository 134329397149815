import { COLORS } from '@alto/design-library-tokens';
import { ICON } from '@alto/design-library-tokens/src/sizes/icons';
import React from 'react';
import { Image } from 'react-native';
import smallchevron from '../../../assets/images/small_chevron';
import { Pressable } from '../../buttons';
import { TagText } from '../../typography';
import { Tag } from './Tag';

type Props = {
  readonly children: string;
  readonly onPress: () => void;
};

export const FilterTag = ({ children, onPress }: Props) => {
  return (
    <Pressable onPress={onPress}>
      <Tag
        icon={
          <Image
            source={{
              uri: `data:image/svg+xml;base64,${btoa(smallchevron)}`,
              width: ICON.MD.value,
              height: ICON.MD.value,
            }}
          />
        }
        isPressable
        label={
          <TagText
            color={COLORS.TEXT_COLORS.WHITE}
            variant="small"
          >
            {children}
          </TagText>
        }
        right
        type="counter"
      />
    </Pressable>
  );
};
