import { type ReduxStateShared } from '~shared/types';

export default (state: ReduxStateShared) => state.cart.cart;
export const getEditOrder = (state: ReduxStateShared) => state.cart.editOrder;
export const getEditShipmentID = (state: ReduxStateShared) => state.cart.editOrderShipmentID;
/**
 * @deprecated use useCartData hook instead
 */
export const getAutoRefills__DEPRECATED = (state: ReduxStateShared) => state.cart.autoRefills;
export const getShowingValidationErrors = (state: ReduxStateShared) => state.cart.showingValidationErrors;
export const getPaymentAmount = (state: ReduxStateShared) => state.cart.cart.payment_method_amount;
export const getSecondPaymentAmount = (state: ReduxStateShared) => state.cart.cart.second_payment_method_amount;
export const getEditPaymentAmount = (state: ReduxStateShared) => state.cart.editOrder.payment_method_amount;
export const getEditSecondPaymentAmount = (state: ReduxStateShared) =>
  state.cart.editOrder.second_payment_method_amount;
export const getWindows = (state: ReduxStateShared) => state.cart.deliveryWindows;
export const getHasScheduleableDeliveryFeesInCart = (state: ReduxStateShared) =>
  state.cart.hasScheduleableDeliveryFeesInCart;
