// @owners { team: patients-team }
import { ActionSheetContext } from '@alto/design-system';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { RESTRICTED_PARTNER_ERRORS, getRestrictedPartnerError } from '~shared/selectors/ui/errors/getInsuranceError';
import { useSelectorShared } from '~shared/store';
import { InsuranceWarningActionSheet } from '../components/InsuranceWarningActionSheet';

type Props = {
  handleContinue: () => void;
  handleAddAnotherBenefit?: () => void;
};

export const useInsuranceWarningActionSheet = ({ handleContinue, handleAddAnotherBenefit }: Props) => {
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const insuranceError = useSelectorShared(getRestrictedPartnerError);
  const insuranceErrorCode: string | null = useMemo(() => {
    return insuranceError?.details?.code || null;
  }, [insuranceError]);

  const insuranceErrorPartnerName: string | undefined = useMemo(() => {
    return insuranceError?.details?.internalMessage;
  }, [insuranceError]);

  const continueOnboarding = useCallback(() => {
    handleContinue();
    closeActionSheet();
  }, [closeActionSheet, handleContinue]);

  const addAnotherBenefit = useCallback(() => {
    handleAddAnotherBenefit?.();
    closeActionSheet();
  }, [closeActionSheet, handleAddAnotherBenefit]);

  useEffect(() => {
    if (insuranceErrorCode) {
      setActiveActionSheet(
        <InsuranceWarningActionSheet
          partnerName={insuranceErrorPartnerName}
          confirmedCoverage={insuranceErrorCode === RESTRICTED_PARTNER_ERRORS.ALREADY_CONFIRMED}
          handleClose={closeActionSheet}
          handleContinue={continueOnboarding}
          handleAddAnotherBenefit={handleAddAnotherBenefit ? addAnotherBenefit : undefined}
        />,
      );
    }
  }, [
    insuranceErrorCode,
    insuranceErrorPartnerName,
    closeActionSheet,
    setActiveActionSheet,
    addAnotherBenefit,
    handleAddAnotherBenefit,
    continueOnboarding,
  ]);

  return { showActionSheet: !!insuranceError };
};
