// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { LgSpacing, useScreenSize } from '@alto/design-system';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { type Prescription } from '~shared/types';
import { Icon, Select, StyledTouchable, Text, View } from '~web/features/essentials/primitives';

type Props = {
  readonly itemQuantity: number;
  readonly onIncrease: () => void;
  readonly onDecrease: () => void;
  readonly onQuantityClick: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  readonly onQuantityPress: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  readonly prescription: Prescription | null | undefined;
};

const StyledIcon = styled.button<{ visible: boolean }>`
  ${StyledTouchable}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING.STATIC.SM.px};
  border-radius: ${BORDERS.RADIUS.XXL.px};
  border: 2px solid ${COLORS.BORDER_COLORS.SECONDARY};
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const ProductQuantityToggle = memo(function MemoProductQuantityToggle({
  itemQuantity,
  onDecrease,
  onIncrease,
  onQuantityClick,
  onQuantityPress,
  prescription,
}: Props) {
  const quantityMax = 3;
  const minQuantity = useMemo(() => (prescription ? 0 : 1), [prescription]);
  const { isSMScreenOrBigger } = useScreenSize();

  if (isSMScreenOrBigger) {
    return (
      <Select.Container
        id="edit-quantity"
        label="select quantity"
        onClick={onQuantityClick}
        onChange={onQuantityPress}
        value={itemQuantity.toString()}
      >
        {prescription ? (
          <Select.Item
            value="0"
            label="0"
          />
        ) : null}
        <Select.Item
          value="1"
          label="1"
        />
        <Select.Item
          value="2"
          label="2"
        />
        <Select.Item
          value="3"
          label="3"
        />
      </Select.Container>
    );
  }

  return (
    <View
      flexDirection="row"
      alignItems="center"
    >
      <StyledIcon
        onClick={onDecrease}
        visible={itemQuantity > minQuantity}
        disabled={itemQuantity === minQuantity}
      >
        <Icon
          name="minus-secondary"
          label="decrease quantity"
          interactive
          size="MD"
        />
      </StyledIcon>
      <LgSpacing />
      <Text
        title={itemQuantity.toString()}
        color="SECONDARY"
        variant="h3"
      />
      <LgSpacing />
      <StyledIcon
        onClick={onIncrease}
        visible={itemQuantity < quantityMax}
        disabled={itemQuantity >= quantityMax}
      >
        <Icon
          name="plus-secondary"
          label="increase quantity"
          interactive
          size="MD"
        />
      </StyledIcon>
    </View>
  );
});
