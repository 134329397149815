import { createSelector } from 'reselect';
import { getIsOrderPayAtPickup, getIsOrderPickupMethod } from '~shared/features/checkout/selectors/getOrder';
import { getItemsSubtotal, getOrderTotal } from '~shared/features/pricing/selectors/getOrderTotal';

export const getIsPriceUnconfirmed = createSelector([getOrderTotal], (total) => {
  if (!total) {
    return true;
  }

  return total.info_key === 'price_unavailable' || total.info_key === 'estimated';
});

// items subtotal returns total minus all fees
export const getHasNoCopay = createSelector([getItemsSubtotal, getIsPriceUnconfirmed], (itemsSubtotal, unconfirmed) => {
  return !unconfirmed && !itemsSubtotal;
});

export const getIsPaymentMethodRequired = createSelector(
  [getOrderTotal, getIsOrderPickupMethod, getIsOrderPayAtPickup],
  (total, isOrderPickupMethod, isOrderPayAtPickup): boolean => {
    if (isOrderPickupMethod && isOrderPayAtPickup) {
      return false;
    }

    if (!total) {
      return true;
    }

    return (total.value ?? 0) > 0 || total.info_key === 'price_unavailable';
  },
);
