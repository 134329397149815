// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import { EventTypes } from 'redux-segment';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from './deliveries';
// eslint-disable-next-line import/no-deprecated
import { uploadImageToS3 } from './upload';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { get, put } from '~shared/helpers/apiHelper';
import getDeviceID from '~shared/helpers/getDeviceID';
import { directAppStoreLink } from '~shared/helpers/helper';
import { Sentry } from '~shared/sentry';
import { type ReduxDispatch, type S3Source, type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const S3_BUCKET = 'users';

export type FetchAppDownloadLinkLoading = {
  type: typeof FETCH_APP_DOWNLOAD_LINK_LOADING;
};

export type FetchAppDownloadLinkSucceeded = {
  type: typeof FETCH_APP_DOWNLOAD_LINK_SUCCEEDED;
  payload: string;
};

export type FetchAppDownloadLinkFailed = {
  type: typeof FETCH_APP_DOWNLOAD_LINK_FAILED;
  payload: APIError;
  error: true;
};

export const FETCH_APP_DOWNLOAD_LINK_LOADING = 'FETCH_APP_DOWNLOAD_LINK_LOADING';

export function fetchingAppDownloadLink(): FetchAppDownloadLinkLoading {
  return {
    type: FETCH_APP_DOWNLOAD_LINK_LOADING,
  };
}

export const FETCH_APP_DOWNLOAD_LINK_SUCCEEDED = 'FETCH_APP_DOWNLOAD_LINK_SUCCEEDED';

export function fetchAppDownloadLinkSucceeded(link: string): FetchAppDownloadLinkSucceeded {
  return {
    type: FETCH_APP_DOWNLOAD_LINK_SUCCEEDED,
    payload: link,
  };
}

export const FETCH_APP_DOWNLOAD_LINK_FAILED = 'FETCH_APP_DOWNLOAD_LINK_FAILED';

export function fetchAppDownloadLinkFailed(error: APIError): FetchAppDownloadLinkFailed {
  return {
    type: FETCH_APP_DOWNLOAD_LINK_FAILED,
    payload: error,
    error: true,
  };
}

export type FetchUsersLoading = {
  type: typeof FETCH_USERS_LOADING;
};

export type FetchUsersPayload = {
  users: User[];
  current_user_id: number;
};

export type FetchUsersSucceeded = {
  type: typeof FETCH_USERS_SUCCEEDED;
  payload: FetchUsersPayload;
};

export type FetchUsersFailed = {
  type: typeof FETCH_USERS_FAILED;
  payload: APIError;
  error: true;
};

export const FETCH_USERS_LOADING = 'FETCH_USERS_LOADING';

export function fetchingUsers(): FetchUsersLoading {
  return {
    type: FETCH_USERS_LOADING,
  };
}

export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';

export function fetchUsersSucceeded(users: FetchUsersPayload): FetchUsersSucceeded {
  return {
    type: FETCH_USERS_SUCCEEDED,
    payload: users,
  };
}

export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

export function fetchUsersFailed(error: APIError): FetchUsersFailed {
  return {
    type: FETCH_USERS_FAILED,
    payload: error,
    error: true,
  };
}

export type UploadPhotoIDsLoading = {
  type: typeof UPLOAD_PHOTO_IDS_LOADING;
};

export type UploadPhotoIDsSucceeded = {
  type: typeof UPLOAD_PHOTO_IDS_SUCCEEDED;
};

export type UploadPhotoIDsFailed = {
  type: typeof UPLOAD_PHOTO_IDS_FAILED;
  payload: APIError | Error;
  error: true;
};

export const UPLOAD_PHOTO_IDS_LOADING = 'UPLOAD_PHOTO_IDS_LOADING';

export function uploadingPhotoIDs(): UploadPhotoIDsLoading {
  return {
    type: UPLOAD_PHOTO_IDS_LOADING,
  };
}

export const UPLOAD_PHOTO_IDS_SUCCEEDED = 'UPLOAD_PHOTO_IDS_SUCCEEDED';

export function uploadPhotoIDsSucceeded(): UploadPhotoIDsSucceeded {
  return {
    type: UPLOAD_PHOTO_IDS_SUCCEEDED,
  };
}

export const UPLOAD_PHOTO_IDS_FAILED = 'UPLOAD_PHOTO_IDS_FAILED';

export function uploadPhotoIDsFailed(error: APIError | Error): UploadPhotoIDsFailed {
  return {
    type: UPLOAD_PHOTO_IDS_FAILED,
    payload: error,
    error: true,
  };
}

type MetaAnalyticsTrack<T = undefined> = {
  meta: {
    analytics: {
      eventType: EventTypes.track;
      eventPayload?: T;
    };
  };
};

export type UploadAvatarLoading = {
  type: typeof UPLOAD_AVATAR_LOADING;
};

export type UploadAvatarSucceeded = MetaAnalyticsTrack & {
  type: typeof UPLOAD_AVATAR_SUCCEEDED;
};

export type UploadAvatarFailed = MetaAnalyticsTrack & {
  type: typeof UPLOAD_AVATAR_FAILED;
};

export const UPLOAD_AVATAR_LOADING = 'UPLOAD_AVATAR_LOADING';

export function uploadingAvatar(): UploadAvatarLoading {
  return {
    type: UPLOAD_AVATAR_LOADING,
  };
}

export const UPLOAD_AVATAR_SUCCEEDED = 'UPLOAD_AVATAR_SUCCEEDED';

export function uploadAvatarSucceeded(): UploadAvatarSucceeded {
  return {
    type: UPLOAD_AVATAR_SUCCEEDED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED';

export function uploadAvatarFailed(): UploadAvatarFailed {
  return {
    type: UPLOAD_AVATAR_FAILED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export type UpdateUserLoading = {
  type: typeof UPDATE_USER_LOADING;
};

type UpdateUserSucceededAnalyticsPayload = {
  event: typeof UPDATE_USER_SUCCEEDED;
  properties: {
    resource_type: 'User';
    resource_id: User['id'];
  };
};
export type UpdateUserSucceeded = MetaAnalyticsTrack<UpdateUserSucceededAnalyticsPayload> & {
  type: typeof UPDATE_USER_SUCCEEDED;
  payload: User;
};

export type UpdateUserFailed = MetaAnalyticsTrack & {
  type: typeof UPDATE_USER_FAILED;
  payload: APIError;
  error: true;
};

export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';

export function updatingUser(): UpdateUserLoading {
  return {
    type: UPDATE_USER_LOADING,
  };
}

export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';

export function updateUserSucceeded(user: User): UpdateUserSucceeded {
  return {
    type: UPDATE_USER_SUCCEEDED,
    payload: user,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: UPDATE_USER_SUCCEEDED,
          properties: {
            resource_type: 'User',
            resource_id: user.id,
          },
        },
      },
    },
  };
}

export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export function updateUserFailed(error: APIError): UpdateUserFailed {
  return {
    type: UPDATE_USER_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export type ClearUserError = {
  type: typeof CLEAR_USER_ERROR;
};

export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';

export function clearUserError(): ClearUserError {
  return {
    type: CLEAR_USER_ERROR,
  };
}

export type UserActions =
  | FetchAppDownloadLinkFailed
  | FetchAppDownloadLinkSucceeded
  | FetchAppDownloadLinkLoading
  | FetchUsersFailed
  | FetchUsersSucceeded
  | FetchUsersLoading
  | UpdateUserFailed
  | UpdateUserSucceeded
  | UpdateUserLoading
  | UploadPhotoIDsFailed
  | UploadPhotoIDsSucceeded
  | UploadPhotoIDsLoading
  | UploadAvatarFailed
  | UploadAvatarSucceeded
  | UploadAvatarLoading;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchAppDownloadLink(tempPassword: string) {
  return (dispatch: ReduxDispatch) => {
    dispatch(fetchingAppDownloadLink());
    return get('/users/app_onboard_link', {
      temp_password: tempPassword,
    }).then((json) => {
      if (json.error) {
        dispatch(fetchAppDownloadLinkFailed(json.error));
        return directAppStoreLink();
      }

      dispatch(fetchAppDownloadLinkSucceeded(json.url));
      return json.url;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchUsers() {
  return (dispatch: ReduxDispatch) => {
    dispatch(fetchingUsers());
    return get('/users').then((json) => {
      if (json.error) {
        dispatch(fetchUsersFailed(json.error));
        return false;
      }
      Sentry.setUser({ id: json.current_user_id });
      dispatch(fetchUsersSucceeded(json));
      return true;
    });
  };
}

type ExtraArgs = {
  current_password?: string;
  other_scripts_list: string;
  phi_auth_data?: string;
  terms?: string;
  utm_params?: string;
};
/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function updateUser(user: Partial<Omit<User, 'other_scripts_list'> & ExtraArgs>) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(updatingUser());
    const anonymousID = getDeviceID();
    const json = await put(
      '/users/me',
      {
        user,
        anonymousID,
      },
      {
        version: 'v3',
      },
    );

    if (json.error) {
      dispatch(updateUserFailed(json.error));
      return {
        success: false,
      };
    }

    dispatch(updateUserSucceeded(json));
    return {
      success: true,
    };
  };
}

export function updateCashPay(userID: number, isCashPayOnly: boolean) {
  return async (dispatch: ReduxDispatch) => {
    const result = await dispatch(
      updateUser({
        id: userID,
        cash_pay_only: isCashPayOnly,
      }),
    );

    // We should refetch the deliveries after updateCashPay, because we're
    // guaranteed to be going from needs insurance state to cash pay only
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchDeliveries());
    return result;
  };
}

export type PhotoIDs = Record<number, S3Source>;

export function uploadPhotoIDs(photoIDs: PhotoIDs) {
  const errorMessage = 'Failed to upload photo insurance, please try again or message support if the problem persists.';

  return (dispatch: ReduxDispatch) => {
    dispatch(uploadingPhotoIDs());

    const uploadIDs = TypescriptUtils.objectKeys(photoIDs).map((id) => ({
      userID: id,
      source: photoIDs[id],
    }));

    // eslint-disable-next-line import/no-deprecated
    const requests = uploadIDs.map((id) => dispatch(uploadImageToS3(id.source, S3_BUCKET)));

    return (
      Promise.all(requests)
        // @ts-expect-error not sure how to correctly type this deeply nested promise/thunk issue
        .then((urls) => {
          const failed = !urls || urls.some((url) => !url);

          if (failed) {
            dispatch(uploadPhotoIDsFailed(new Error(errorMessage)));
            return false;
          }

          const updateRequests = urls.map((url, i) =>
            dispatch(
              updateUser({
                id: uploadIDs[i].userID,
                photo_id_url: url || '',
              }),
            ),
          );

          return Promise.all(updateRequests);
        })
        .then((updates) => {
          // @ts-expect-error not sure how to correctly type this deeply nested promise/thunk issue
          const failed = updates.some((update) => !update?.success);

          if (failed) {
            dispatch(uploadPhotoIDsFailed(new Error(errorMessage)));
            return false;
          }

          dispatch(uploadPhotoIDsSucceeded());
          return true;
        })
        .catch(() => {
          dispatch(uploadPhotoIDsFailed(new Error(errorMessage)));
          return false;
        })
    );
  };
}

type UploadAvatarUserParams = {
  avatar_url: string;
  id?: number;
};

export function uploadAvatarToS3(source: S3Source, userID: number | null = null) {
  return (dispatch: ReduxDispatch) => {
    dispatch(uploadingAvatar());

    const errorHandler = () => {
      dispatch(uploadAvatarFailed());
    };

    // eslint-disable-next-line import/no-deprecated
    return dispatch(uploadImageToS3(source, S3_BUCKET))
      .then((url) => {
        if (url) {
          const params: UploadAvatarUserParams = {
            avatar_url: url,
          };
          if (userID) params.id = userID;

          // eslint-disable-next-line promise/no-nesting
          return dispatch(updateUser(params)).then(({ success }) => {
            if (success) {
              dispatch(uploadAvatarSucceeded());
              return true;
            }

            dispatch(uploadAvatarFailed());
            return false;
          });
        }

        dispatch(uploadAvatarFailed());
        return false;
      })
      .catch(errorHandler);
  };
}
