import React from 'react';

type EventCallback = () => void;

export const useEscapeKeyBinding = (callback: EventCallback) => {
  React.useEffect(() => {
    const eventCallback = (event: KeyboardEvent) => {
      if ((event.key || '').toLowerCase() === 'escape') {
        callback();
      }
    };

    window.addEventListener('keyup', eventCallback);
    return () => {
      window.removeEventListener('keyup', eventCallback);
    };
  }, [callback]);
};
