// @owners { team: patients-team }
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { setTempPassword } from '~shared/actions/onboarding';
// eslint-disable-next-line import/no-deprecated
import { fetchAppDownloadLink } from '~shared/actions/users';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { Header } from './landing_page/Header';
import { StartOrderButton } from './landing_page/StartOrderButton';
import { TrustedPartnerLogos } from './landing_page/TrustedPartnerLogos';
import { ValueProps } from './landing_page/ValueProps';

type Props = {
  params: {
    tempPassword: string;
  };
};

const appDownloadClicked = false;

const LandingPageComponent = (props: Props) => {
  const { tempPassword } = props.params ?? '';
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__WEB_LANDING_PAGE_VIEWED,
      params: {
        is_progyny_rx_view: showProgyny,
      },
      additionalFields: {
        userIdentifier: tempPassword,
        userIdentifierType: 'temp_password',
      },
    });
  }, [trackPageView, tempPassword, showProgyny]);

  useEffect(() => {
    dispatch(setTempPassword(tempPassword));
  }, [tempPassword, dispatch]);

  useEffect(() => {
    const openAppDownloadLink = async () => {
      // eslint-disable-next-line import/no-deprecated
      const url = await dispatch(fetchAppDownloadLink(tempPassword));
      // Open the link immediately if the user wanted to open the link but it wasn't available yet
      if (appDownloadClicked) {
        window.location.assign(url);
      }
    };
    openAppDownloadLink();
  }, [tempPassword, dispatch]);

  return (
    <>
      <Header />
      <StartOrderButton tempPassword={tempPassword} />
      <ValueProps />
      <TrustedPartnerLogos />
    </>
  );
};

export const LandingPage = withRouter(LandingPageComponent);
