import { BASE_FONT_SIZE } from '../general';

export const TEXT = {
  HEADER: {
    XL: {
      value: 48,
      px: '48px',
      rem: `${48 / BASE_FONT_SIZE}rem`,
    } as const,
    LG: {
      value: 32,
      px: '32px',
      rem: `${32 / BASE_FONT_SIZE}rem`,
    } as const,
    MD: {
      value: 24,
      px: '24px',
      rem: `${24 / BASE_FONT_SIZE}rem`,
    } as const,
    SM: {
      value: 20,
      px: '20px',
      rem: `${20 / BASE_FONT_SIZE}rem`,
    } as const,
    XS: {
      value: 18,
      px: '18px',
      rem: `${18 / BASE_FONT_SIZE}rem`,
    },
  } as const,
  BODY: {
    LG: {
      value: 18,
      px: '18px',
      rem: `${18 / BASE_FONT_SIZE}rem`,
    } as const,
    MD: {
      value: 16,
      px: '16px',
      rem: `${16 / BASE_FONT_SIZE}rem`,
    } as const,
    SM: {
      value: 14,
      px: '14px',
      rem: `${14 / BASE_FONT_SIZE}rem`,
    } as const,
  } as const,
  DETAIL: {
    LG: {
      value: 12,
      px: '12px',
      rem: `${12 / BASE_FONT_SIZE}rem`,
    } as const,
    MD: {
      value: 10,
      px: '10px',
      rem: `${10 / BASE_FONT_SIZE}rem`,
    } as const,
  },
} as const;
