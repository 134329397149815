import { createSelector } from 'reselect';
import { updateOrder } from '~shared/actions/cart';
import { getWindows } from '~shared/features/checkout/selectors/getCart';
import getDefaultDeliveryWindow from '~shared/features/checkout/selectors/getDefaultDeliveryWindow';
import { getIsOrderPickupMethod, getOrder } from '~shared/features/checkout/selectors/getOrder';
import { type LightDeliveryWindow, type Order, type Window } from '~shared/features/checkout/types';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { type ReduxStateShared } from '~shared/store';
import { type ReduxAction } from '~shared/types';

const getFetchDeliveryWindowsLoading = (state: ReduxStateShared) => state.ui.loading.fetchDeliveryWindowsLoading;

/**
 * Reactor that handles the concerns of updating the cart with defaults when
 * they are missing and cleaning up references to objects that no longer exist
 */
export default createSelector(
  [
    getOrder,
    getWindows,
    getDefaultDeliveryWindow,
    getFetchDeliveryWindowsLoading,
    getIsEditingExistingOrder,
    getIsOrderPickupMethod,
  ],
  (
    order: Order,
    windows: Window[],
    defaultDeliveryWindow: LightDeliveryWindow | null | undefined,
    fetchDeliveryWindowsLoading: boolean,
    isEditingExistingOrder,
    isOrderPickupMethod,
  ): ReduxAction | null | undefined => {
    const { deliver_after, deliver_before, is_asap: isAsapOrder, items } = order;

    const orderParams: Partial<Order> = {};

    // items in your cart is a proxy for the fact that you are within the
    // checkout flow or have started one
    if (!items?.length) {
      return undefined;
    }

    if (!isAsapOrder && !isEditingExistingOrder && !fetchDeliveryWindowsLoading && deliver_after && deliver_before) {
      // Clear window if we can't find that window
      const window = windows.find((w) => w.deliver_after === deliver_after && w.deliver_before === deliver_before);

      if (!window) {
        // @ts-expect-error TS(2339): Property 'deliver_after' does not exist on type '{}'.
        orderParams.deliver_after = null;
        // @ts-expect-error TS(2339): Property 'deliver_before' does not exist on type '{}'.
        orderParams.deliver_before = null;
      }
    }

    // set a default delivery window if one hasn't been set yet
    if (!isOrderPickupMethod && !deliver_after && !deliver_before && defaultDeliveryWindow) {
      orderParams.deliver_after = defaultDeliveryWindow.deliver_after;
      orderParams.deliver_before = defaultDeliveryWindow.deliver_before;
    }

    if (Object.keys(orderParams).length > 0) {
      return updateOrder(orderParams);
    }

    return undefined;
  },
);
