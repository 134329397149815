// @owners { team: patients-team }
import React from 'react';
import { closeModal } from '~shared/actions/modal';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { MessagesInfoModal } from './MessagesInfoModal';

export const MessagesInfoModalContainer = () => {
  const dispatch = useDispatchShared();
  const getIsShowing = useSelectorShared(getIsModalShowing);
  const modalType = useSelectorShared(getModalType);
  const shouldShowModal = getIsShowing && modalType === 'MESSAGES_INFO_MODAL';

  return (
    <MessagesInfoModal
      showMessagesInfoModal={shouldShowModal}
      closeMessagesInfoModal={() => {
        dispatch(closeModal('MESSAGES_INFO_MODAL'));
      }}
    />
  );
};
