// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
// eslint-disable-next-line import/no-deprecated
import { updateUser } from '~shared/actions/users';
import { getUserPhiAuth } from '~shared/features/users/selectors/getUserPhiAuth';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { connect } from '~shared/store';
import { type PhiAuthInfo, type ReduxStateShared } from '~shared/types';
import PhiAuthModal, { type Props } from './PhiAuthModal';

type OwnProps = {
  onClose: () => void;
};

type StateProps = {
  userPhiAuth: PhiAuthInfo | null | undefined;
};

type DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  updateUser: typeof updateUser;
  sendAnalyticsEvent: typeof sendAnalyticsEvent;
};

const mapStateToProps = (state: ReduxStateShared): StateProps => {
  return {
    userPhiAuth: getUserPhiAuth(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  updateUser,
  sendAnalyticsEvent,
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps): Props => {
  const { updateUser, sendAnalyticsEvent } = dispatchProps;

  return {
    ...stateProps,
    onSubmit: ({ sms_authorized, email_authorized, push_authorized }) => {
      const phi_auth_data = {
        sms_authorized,
        email_authorized,
        push_authorized,
      };
      // eslint-disable-next-line promise/catch-or-return
      updateUser({
        phi_auth_data: JSON.stringify(phi_auth_data),
        // @ts-expect-error TS(2339): Property 'then' does not exist on type '(dispatch: any) => Promise<{ success: boolean; }>'.
      }).then(({ success }) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          toaster.toast({
            kind: 'success',
            title: 'Preferences saved! To make changes, visit Account.',
          });
          sendAnalyticsEvent(createEvent(EVENTS.PHI_PREFERENCES_INTERSTITIAL_SUBMITTED));
        }
      });
      ownProps.onClose();
    },
    handleClose: () => {
      // eslint-disable-next-line promise/catch-or-return
      updateUser({
        phi_auth_data: JSON.stringify({
          sms_authorized: null,
          push_authorized: null,
          email_authorized: null,
        }),
        // @ts-expect-error TS(2339): Property 'then' does not exist on type '(dispatch: any) => Promise<{ success: boolean; }>'.
        // eslint-disable-next-line promise/always-return
      }).then(() => {
        toaster.toast({
          kind: 'success',
          title: 'To change your Communication Preferences, visit Account.',
        });
        sendAnalyticsEvent(createEvent(EVENTS.PHI_PREFERENCES_INTERSTITIAL_DISMISSED));
      });
      ownProps.onClose();
    },
    recordInterstitialViewed: () => {
      sendAnalyticsEvent(createEvent(EVENTS.PHI_PREFERENCES_INTERSTITIAL_VIEWED));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PhiAuthModal);
