import React from 'react';
import { InputText, type InputTextProps } from './InputText/InputText';

type Props = {
  readonly textContentType?: 'password' | 'newPassword';
} & Omit<
  InputTextProps,
  | 'autoCapitalize'
  | 'autoComplete'
  | 'autoCorrect'
  | 'dataDetectorTypes'
  | 'multiline'
  | 'numberOfLines'
  | 'onContentSizeChange'
  | 'optional'
  | 'required'
  | 'rightIconProps'
  | 'scrollEnabled'
  | 'secureTextEntry'
>;

export const InputPassword = ({
  accessibilityLabel,
  autoFocus,
  backgroundColor,
  blurOnSubmit,
  caption,
  defaultValue,
  disabled,
  error,
  hideClearButton,
  keyboardType,
  label,
  leftIconProps,
  maxLength,
  onBlur,
  onChangeText,
  onFocus,
  onKeyPress,
  onRef,
  onSubmitEditing,
  passwordRules,
  placeholder,
  returnKeyType,
  sublabel,
  testID,
  textContentType,
  value,
}: Props) => {
  return (
    <InputText
      accessibilityLabel={accessibilityLabel || 'Password'}
      autoCapitalize="none"
      autoComplete="password"
      autoCorrect={false}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      backgroundColor={backgroundColor}
      blurOnSubmit={blurOnSubmit}
      caption={caption}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      hideClearButton={hideClearButton}
      keyboardType={keyboardType}
      label={label}
      leftIconProps={leftIconProps}
      maxLength={maxLength}
      onBlur={onBlur}
      onChangeText={onChangeText}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      onRef={onRef}
      onSubmitEditing={onSubmitEditing}
      passwordRules={passwordRules}
      placeholder={placeholder || 'Enter password'}
      required
      returnKeyType={returnKeyType}
      secureTextEntry
      sublabel={sublabel}
      testID={testID}
      textContentType={textContentType || 'password'}
      value={value}
    />
  );
};
