import { createSelector } from 'reselect';
import { getUsers } from './getUsers';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared, type User } from '~shared/types';

const getUserID = (_: ReduxStateShared, props: UserIDParam) => props.userID;

export const getUser: (state: ReduxStateShared, props: UserIDParam) => User | null | undefined = createSelector(
  getUsers,
  getUserID,
  (users: User[], userID: number | null | undefined): User | null | undefined => {
    if (!userID) {
      return null;
    }

    return users.find((u) => u.id === userID);
  },
);

export default getUser;
