import { createSelector } from 'reselect';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type ReduxStateShared } from '~shared/types';

export const getAddressesObject = (state: ReduxStateShared) => state.addresses;

export const getAddressCreateSuccess = createSelector(
  [getAddressesObject],
  (addresses) => addresses.createAddressSuccess,
);

export const getAddressUpdateSuccess = createSelector(
  [getAddressesObject],
  (addresses) => addresses.createAddressSuccess || addresses.updateAddressSuccess,
);

export const getAddressDeleteSuccess = createSelector(
  [getAddressesObject],
  (addresses) => addresses.deleteAddressSuccess,
);

export const getAddressUpdateLoading = createSelector(
  [getLoading],
  (loading) => loading.createAddressLoading || loading.updateAddressLoading,
);

export const getAddressDeleteLoading = createSelector([getLoading], (loading) => loading.deleteAddressLoading);

export const getAddresses = createSelector([getAddressesObject], (addresses) => addresses.addresses);

export const getAllAddresses = createSelector([getAddressesObject], (addresses) => addresses.allAddresses);

export const getLastRequestAttemptedAt = createSelector(
  [getAddressesObject],
  (addresses) => addresses.lastRequestAttemptedAt,
);

export const getRequestAttemptCount = createSelector(
  [getAddressesObject],
  (addresses) => addresses.requestAttemptCount,
);

export const getLastRequestSucceededAt = createSelector(
  [getAddressesObject],
  (addresses) => addresses.lastRequestSucceededAt,
);
