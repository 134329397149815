import { BASE_FONT_SIZE } from '../general';

const XS = {
  value: 24,
  px: '24px',
  rem: `${24 / BASE_FONT_SIZE}rem`,
} as const;

const SM = {
  value: 48,
  px: '48px',
  rem: `${48 / BASE_FONT_SIZE}rem`,
} as const;

const MD = {
  value: 60,
  px: '60px',
  rem: `${60 / BASE_FONT_SIZE}rem`,
} as const;

const LG = {
  value: 80,
  px: '80px',
  rem: `${80 / BASE_FONT_SIZE}rem`,
} as const;

const XL = {
  value: 160,
  px: '160px',
  rem: `${160 / BASE_FONT_SIZE}rem`,
} as const;

const XXL = {
  value: 240,
  px: '240px',
  rem: `${240 / BASE_FONT_SIZE}rem`,
} as const;

export const ILLUSTRATION = {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
} as const;

export const IMAGE: Record<string, never> = {} as const;
