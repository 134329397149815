import { BORDERS, COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';
import { useScreenSize } from '../../../../../utils';
import { getCssFromOrientation } from '../../helpers';
import { type VerticalEnabled } from '../../types';
import { ACTIVE_BORDER_SIZE, DEFAULT_CIRCLE_SIZE, VERTICAL_OFFSET } from './constants';

const verticalCss = css`
  top: ${VERTICAL_OFFSET}px;
`;

const StyledIndicator = styled(View)<{ isMDScreenOrBigger: boolean; verticalEnabled: VerticalEnabled }>`
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  ${({ verticalEnabled, isMDScreenOrBigger }) => {
    return css`
      background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY};
      width: ${DEFAULT_CIRCLE_SIZE}px;
      height: ${DEFAULT_CIRCLE_SIZE}px;
      border-width: ${ACTIVE_BORDER_SIZE}px;
      border-color: ${COLORS.BORDER_COLORS.LIGHT};
      ${getCssFromOrientation({
        isMDScreenOrBigger,
        verticalEnabled,
        verticalCss,
      })}
    `;
  }}}
`;

export const ActiveIndicator = () => {
  const { isMDScreenOrBigger } = useScreenSize();
  const { verticalEnabled } = useTheme();
  return (
    <StyledIndicator
      verticalEnabled={verticalEnabled}
      isMDScreenOrBigger={isMDScreenOrBigger}
    />
  );
};
