import { useEffect, useState } from 'react';

/*
Debounces any fast-changing value. If you want to debounce an API call fired in a useEffect hook,
use this function to debounce the main field(s) that API effect is listening to, which will
effectively debounce the call itself.

Takes major inspiration from the useHooks implementation: https://usehooks.com/useDebounce/
 */
export const useDebounce = <T>(value: T, delayInMS: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delayInMS);

    // cancel older setDebouncedValue calls whenever the component renders with new values
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [value, delayInMS]);

  return debouncedValue;
};
