// @owners { team: patients-team }

import { type PrescriptionSelectedPriceType } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_selected_price_type';
import { type PricingResultPaymentType } from '~shared/types';

type Props = {
  prescriptionIDs: number[];
  cartSelectedPaymentTypes: Record<number, PricingResultPaymentType> | undefined;
};

export const getSelectedPriceTypesForPrescriptions = ({ prescriptionIDs, cartSelectedPaymentTypes }: Props) => {
  const res: PrescriptionSelectedPriceType[] = [];
  prescriptionIDs.forEach((prescriptionID) => {
    const priceType = cartSelectedPaymentTypes ? cartSelectedPaymentTypes[prescriptionID] : null;
    if (priceType) {
      res.push({
        prescription_id: prescriptionID,
        price_type: priceType,
      });
    }
  });
  return res;
};
