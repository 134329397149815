// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  Description,
  ExternalLink,
  FlatCard,
  InlineAlert,
  LgPadding,
  ListDescription,
  MdSpacing,
  Subway,
  SubwayStation,
  Toast,
  ToastContext,
} from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { requestRenewal } from '~shared/actions/prescriptions';
import autoRefillImage from '~shared/assets/images/auto_refill.png';
import { getDoctorNameOrEmptyString } from '~shared/features/my-meds/helper';
import { getPrescriptionsByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getErrorMessage } from '~shared/helpers/helper';
import { formatList } from '~shared/helpers/list';
import { stripNonDigits } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { homescreenMethods } from '../../../homescreen/queries/queries';
import { useInvalidateMedDetails } from '../../../med-details/queries/useMedDetails';
import { useInvalidateMedList } from '../../../med-list/queries/useMedList';

const PhoneNumber = styled(ExternalLink)`
  font-size: 14px;
  font-weight: normal;
`;

type Props = {
  readonly onSubmit?: () => Promise<void>;
  readonly prescriptionIDs: number[];
  readonly requestRenewalLoading?: boolean;
};

export const RenewalRequestActionSheet = ({ onSubmit, prescriptionIDs, requestRenewalLoading = false }: Props) => {
  const { invalidatePrescriptionsQuery, invalidateMedicationQuery } = useInvalidateMedDetails();
  const { invalidateListQuery } = useInvalidateMedList();
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { closeActionSheet } = useContext(ActionSheetContext);
  const userID = useSelectorShared(getCurrentUserID);
  const prescriptions = useSelectorShared((state) => getPrescriptionsByIDs(state, prescriptionIDs));
  const providerNames = new Set(prescriptions.map((p) => getDoctorNameOrEmptyString(p.doctor)));
  const providerPhoneNumber = providerNames.size === 1 ? prescriptions[0].provider_phone : undefined;
  const formattedProviders = formatList(Array.from(providerNames));
  const requestRenewalErrorByID = useSelectorShared((state) => state.ui.errors.requestRenewalErrorByID);
  const errors = prescriptionIDs.reduce<string[]>((errors, prescriptionID) => {
    const errorMessage = getErrorMessage(requestRenewalErrorByID[prescriptionID]);
    if (errorMessage) {
      errors.push(errorMessage);
    }
    return errors;
  }, []);

  const handlePressRequestRefills = async () => {
    trackEvent({ event: EVENTS.RENEWAL_REQUEST__SUBMIT_CLICKED });

    const renewalStatuses = await Promise.all(
      prescriptionIDs.map((prescriptionID) => dispatch(requestRenewal(prescriptionID))),
    );
    const renewedMedications = prescriptions.reduce<string[]>((names, prescription, index) => {
      if (renewalStatuses[index] && prescription.medication_name) {
        names.push(prescription.medication_name);
      }
      return names;
    }, []);

    addToast(<Toast variant="success">We've requested refills for {formatList(renewedMedications)}.</Toast>);

    // close the action if all requests were successful, otherwise show errors in this action sheet
    if (renewalStatuses.every(Boolean)) {
      closeActionSheet();
    }

    invalidateListQuery();
    prescriptionIDs.forEach((primaryPrescriptionId) => {
      invalidatePrescriptionsQuery(primaryPrescriptionId);
      invalidateMedicationQuery(primaryPrescriptionId);
    });
    queryClient.invalidateQueries({ queryKey: homescreenMethods.fetchHomescreen({ userID }).queryKey });
    onSubmit?.();
  };

  return (
    <ActionSheetV2
      title="Confirm refill request"
      illustrationSrc={autoRefillImage}
      analyticsName="renewal request confirmation"
      buttons={[
        <Button
          testID="e2e-renewal-request-refills"
          key="request_refills_button"
          label="Request refills"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handlePressRequestRefills}
          loading={requestRenewalLoading}
          loadingLabel="Requesting..."
          width="full"
        />,
      ]}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {errors.length > 0 ? (
          <>
            <InlineAlert type="error">
              <Description>{errors.join('\n')}</Description>
            </InlineAlert>
            <MdSpacing />
          </>
        ) : null}
        <Subway
          verticalEnabled={Platform.OS === 'web' ? 'always' : 'mobile'}
          activeStation="request"
        >
          <SubwayStation
            id="request"
            title={formattedProviders ? `Send request to ${formattedProviders}` : 'Send request'}
            descriptions={[
              <ListDescription key="send request">We'll send a request to your provider.</ListDescription>,
            ]}
          />
          <SubwayStation
            id="provider-follow-up"
            title="Progress check"
            descriptions={[
              <ListDescription key="Progress check">
                {`We'll check back in with ${providerPhoneNumber ? 'your provider' : 'them'}.`}
              </ListDescription>,
            ]}
          />
          <SubwayStation
            id="patient-follow-up"
            title="Resolved"
            descriptions={[
              <ListDescription key="Resolved">
                We'll notify you when we get a response. If we haven't heard anything in 7 days, we'll check in with
                you.
              </ListDescription>,
            ]}
          />
        </Subway>
        <FlatCard>
          <LgPadding>
            <Description>
              <Description fontFamily="semibold">If this is urgent, </Description>
              {providerPhoneNumber ? (
                <Description>
                  call your provider at{' '}
                  <PhoneNumber href={`tel://${stripNonDigits(providerPhoneNumber)}`}>{providerPhoneNumber}</PhoneNumber>{' '}
                  to accelerate your request.
                </Description>
              ) : (
                <Description>ask your provider to accelerate your request.</Description>
              )}
              {'\n\n'}
              <Description fontFamily="semibold">If you have changed providers, </Description>
              <Description>they need to send us a new prescription.</Description>
            </Description>
          </LgPadding>
        </FlatCard>
      </LgPadding>
    </ActionSheetV2>
  );
};
