import { createSelector } from 'reselect';
import { getCurrentUser } from './getCurrentUser';
import { getIsOnboardingComplete } from '~shared/features/onboarding/selectors/getIsOnboardingComplete';

export const getUserPhiAuth = createSelector(getCurrentUser, (user) => {
  return user?.phi_auth_info;
});

export const getShouldPromptUserForPhiAuth = createSelector(
  [getUserPhiAuth, getIsOnboardingComplete],
  (phiAuthInfo, isOnboardingComplete) => {
    return isOnboardingComplete && !phiAuthInfo?.user_prompted;
  },
);

export default getUserPhiAuth;
