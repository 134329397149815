import { withRehydration } from './withRehydration';
import {
  type ActionAuthSignupSucceeded,
  type ActionValidatingUserSucceeded,
  LOGIN_SUCCEEDED,
  type LoginSucceeded,
  RESETTING_PASSWORD_SUCCEEDED,
  type ResettingPasswordSucceeded,
  SIGNUP_SUCCEEDED,
  VALIDATING_USER_SUCCEEDED,
} from '~shared/actions/auth';
import { UPDATE_ONBOARDING_USER_SUCCEEDED, type UpdateOnboardingUserSucceeded } from '~shared/actions/onboarding';
import {
  FETCH_USERS_LOADING,
  FETCH_USERS_SUCCEEDED,
  UPDATE_USER_FAILED,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCEEDED,
  UPLOAD_AVATAR_FAILED,
  UPLOAD_AVATAR_LOADING,
  UPLOAD_AVATAR_SUCCEEDED,
  UPLOAD_PHOTO_IDS_FAILED,
  UPLOAD_PHOTO_IDS_LOADING,
  UPLOAD_PHOTO_IDS_SUCCEEDED,
  type UserActions,
} from '~shared/actions/users';
import { immutableUpsert } from '~shared/helpers/immutableUpsert';
import { type User } from '~shared/types';

type ApiRequestInfo = {
  lastRequestAttemptedAt: number;
  requestAttemptCount: number;
  lastRequestSucceededAt: number;
};

export type UserState = ApiRequestInfo & {
  users: User[];
  currentUserID: number | null;
  updateUserSuccess?: boolean | null;
  uploadPhotoIDsSuccess?: boolean | null;
  uploadAvatarSuccess?: boolean | null;
};

const initialState: UserState = {
  users: [],
  currentUserID: null,
  lastRequestAttemptedAt: 0,
  requestAttemptCount: 0,
  lastRequestSucceededAt: 0,
  updateUserSuccess: null,
  uploadPhotoIDsSuccess: null,
  uploadAvatarSuccess: null,
};

type UserReducerActions =
  | UserActions
  | UpdateOnboardingUserSucceeded
  | ActionValidatingUserSucceeded
  | ActionAuthSignupSucceeded
  | LoginSucceeded
  | ResettingPasswordSucceeded;

const users = (state: UserState = initialState, action: UserReducerActions): UserState => {
  switch (action.type) {
    case FETCH_USERS_SUCCEEDED: {
      const { users, current_user_id } = action.payload;
      return {
        ...state,
        users,
        currentUserID: current_user_id,
        lastRequestSucceededAt: Date.now(),
        requestAttemptCount: 0,
      };
    }

    case FETCH_USERS_LOADING:
      return { ...state, lastRequestAttemptedAt: Date.now(), requestAttemptCount: state.requestAttemptCount + 1 };

    case UPDATE_USER_LOADING:
      return { ...state, updateUserSuccess: null };

    case UPDATE_USER_SUCCEEDED:
      return {
        ...state,
        users: immutableUpsert<User>({
          state: state.users,
          payload: action.payload,
        }),
        updateUserSuccess: true,
      };

    case UPDATE_USER_FAILED:
      return { ...state, updateUserSuccess: false };

    case UPDATE_ONBOARDING_USER_SUCCEEDED:
      return {
        ...state,
        users: immutableUpsert<User>({
          state: state.users,
          payload: action.payload,
        }),
      };

    case UPLOAD_PHOTO_IDS_LOADING:
      return { ...state, uploadPhotoIDsSuccess: null };

    case UPLOAD_PHOTO_IDS_SUCCEEDED:
      return { ...state, uploadPhotoIDsSuccess: true };

    case UPLOAD_PHOTO_IDS_FAILED:
      return { ...state, uploadPhotoIDsSuccess: false };

    case UPLOAD_AVATAR_LOADING:
      return { ...state, uploadAvatarSuccess: null };

    case UPLOAD_AVATAR_SUCCEEDED:
      return { ...state, uploadAvatarSuccess: true };

    case UPLOAD_AVATAR_FAILED:
      return { ...state, uploadAvatarSuccess: false };

    case VALIDATING_USER_SUCCEEDED:
    case LOGIN_SUCCEEDED:
    case RESETTING_PASSWORD_SUCCEEDED:
    case SIGNUP_SUCCEEDED:
      return {
        ...state,
        users: immutableUpsert<User>({
          state: state.users,
          payload: action.payload || {},
        }),
        currentUserID: action.payload?.id, // currentUserID is updated specifically for web
      };

    default:
      return state;
  }
};

export default withRehydration(users, initialState);
