// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  ASSISTANT_TRANSFER_ACTIONS,
  type ActionSheetProviderStackCacheKey,
  type AssistantTransferAction,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { MenuOption } from './MenuOption';

export const TransferActionSheet = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();

  const { restoreStack, saveStack, closeActionSheet } = useContext(ActionSheetContext);
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const trackActionChosen = (action: AssistantTransferAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        category: ASSISTANT_CATEGORIES.TRANSFER,
        name: ASSISTANT_PRESSED_EVENTS.TRANSFER_ACTION_CHOSEN,
        action,
      },
    });
  };

  const handleCloseActionSheet = () => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
  };

  const items = [
    {
      key: 'transfer-in',
      component: (
        <MenuOption
          iconName="swap-duo"
          onPress={() => {
            trackActionChosen('transfer_in');
            handleCloseActionSheet();
            navigate('RouteTransferPrescription', {
              origin: ASSISTANT_CHAT_ORIGINS.TRANSFER,
            });
          }}
          text="Transfer to Alto"
        />
      ),
    },
    {
      key: 'transfer-out',
      component: (
        <MenuOption
          iconName="paperairplane-duo"
          onPress={() => {
            trackActionChosen('transfer_out');
            handleCloseActionSheet();
            navigate('RouteFaq', { faqKey: 'transfer_out' });
          }}
          text="Transfer out of Alto"
        />
      ),
    },
    {
      key: 'price-quote',
      component: (
        <MenuOption
          iconName="pricetag-duo"
          onPress={() => {
            trackActionChosen('price_quote');
            handleCloseActionSheet();
            navigate('RouteFaq', {
              faqKey: 'price_quotes',
              goBack: () => {
                restoreStack<ActionSheetProviderStackCacheKey>('assistant');
                navigateToAssistantLanding();
              },
              onDismiss: () => {
                navigateToAssistantLanding();
              },
              buttonLabelDone: 'Got it',
            });
          }}
          text="Get a price quote"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            trackActionChosen('something_else');
            handleCloseActionSheet();
            navigate('RouteAssistantMessage', {
              messagePrompt: MESSAGE_PROMPTS.HELP_WITH_TRANSFER,
              origin: ASSISTANT_CHAT_ORIGINS.TRANSFER,
              category: `${ASSISTANT_CATEGORIES.TRANSFER}.${ASSISTANT_TRANSFER_ACTIONS.SOMETHING_ELSE}`,
              subject: ASSISTANT_MESSAGE_SUBJECTS.TRANSFER,
            });
          }}
          text="Something else"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="Transfer prescriptions"
      analyticsName="assistant transfer"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
