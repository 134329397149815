// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  Column,
  InputCheckbox,
  LgPadding,
  LoadingEmptyState,
  MdPadding,
  SecondaryPage,
  SizedImage,
  Switch,
  XlPadding,
  useScreenSize,
} from '@alto/design-system';
import { useQueryPhiAuthorizationConfigs } from '@alto/features';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Formik, type FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import styledNative from 'styled-components/native';
// eslint-disable-next-line import/no-deprecated
import { updateOnboardingUser } from '~shared/actions/onboarding';
import {
  ONBOARD_STATE_PHI_AUTHORIZATION,
  PHI_AUTHORIZATION,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import getLoading from '~shared/features/ui/selectors/getLoading';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { getUserPhiAuth } from '~shared/features/users/selectors/getUserPhiAuth';
import { type UserPhiAuthValues } from '~shared/helpers/communicationPreferences';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type PhiAuthInfo } from '~shared/types';
import { FAQs } from './FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { nextUrl as getNextUrl, previousUrl as getPreviousUrl } from '~web/features/onboarding/helpers/urlHandler';
import onboardingPhiImage from '~web/images/onboarding_phi.png';

const StyledSizedImage = styledNative(SizedImage)`
  width: 100%;
`;

const OnboardingPhiAuthorization = () => {
  const dispatch = useDispatchShared();
  const { isLGScreenOrBigger } = useScreenSize();
  const { trackEvent, trackPageView } = useAnalytics();
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const nextUrl = useSelectorShared((state) => getNextUrl(state, PHI_AUTHORIZATION));
  const previousUrl = useSelectorShared((state) => getPreviousUrl(state, PHI_AUTHORIZATION));
  const { updateOnboardingUserLoading } = useSelectorShared(getLoading);
  const origin = previousUrl ? routeFromPath(previousUrl) : '';
  const userPhiAuth = useSelectorShared(getUserPhiAuth);
  const title = 'Can we include health info in messages?';
  const subTitle = 'This helps our team communicate with you about health information, like medication or price.';
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryPhiAuthorizationConfigs();

  const onBack = () => {
    if (previousUrl) {
      dispatch(push(previousUrl));
    }
  };

  const onSubmit = ({ sms_authorized, email_authorized, push_authorized }: PhiAuthInfo) => {
    const phi_auth_data = {
      sms_authorized,
      email_authorized,
      push_authorized,
    };
    // eslint-disable-next-line promise/catch-or-return
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        // @ts-expect-error TS(2769): No overload matches this call.
        phi_auth_data: JSON.stringify(phi_auth_data),
      }),
    ).then((success: boolean) => {
      // eslint-disable-next-line promise/always-return
      if (success) {
        dispatch(push(nextUrl));
        const phiPref = [];

        if (sms_authorized) {
          phiPref.push('text');
        }

        if (email_authorized) {
          phiPref.push('email');
        }

        if (push_authorized) {
          phiPref.push('push_notifications');
        }
        trackEvent({
          event: EVENTS.ONBOARDING__PHI_AUTHORIZATION_COMPLETED,
          params: {
            origin: ORIGIN_NAMES.ONBOARDING,
            message_preference: phiPref,
          },
        });
      }
    });
  };

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        onboard_state: ONBOARD_STATE_PHI_AUTHORIZATION,
      }),
    );
    trackPageView({
      event: EVENTS.ONBOARDING__PHI_AUTHORIZATION_VIEWED,
      params: {
        is_progyny_rx_view: showProgyny,
        origin,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <Formik
      initialValues={
        // If this user has never been prompted before, set all PHI to true
        userPhiAuth?.user_prompted
          ? userPhiAuth
          : {
              sms_authorized: true,
              push_authorized: true,
              email_authorized: true,
            }
      }
      onSubmit={onSubmit}
    >
      {({ values, setValues, handleSubmit }: FormikProps<UserPhiAuthValues>) => {
        const allPhiAuthed = values.sms_authorized && values.email_authorized && values.push_authorized;

        return (
          <>
            <SecondaryPage
              headerBackgroundColor={headerBackgroundColor}
              withoutWebFramingElementHeights
              dismissIcon="chevronleft"
              onDismiss={onBack}
              NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
              NavBarRightPressable={<FAQs />}
              HeaderContent={
                <OnboardingHeader
                  title={title}
                  subtitle={<Body center>{subTitle}</Body>}
                  illustration={
                    <LgPadding
                      topPadding={SPACING.STATIC.NONE}
                      bottomPadding={SPACING.STATIC.NONE}
                      leftPadding={isLGScreenOrBigger ? SPACING.STATIC.LG : SPACING.STATIC.NONE}
                      rightPadding={isLGScreenOrBigger ? SPACING.STATIC.LG : SPACING.STATIC.NONE}
                    >
                      <StyledSizedImage
                        center
                        source={onboardingPhiImage}
                        resizeMode="contain"
                        aspectRatio={3}
                      />
                    </LgPadding>
                  }
                />
              }
              buttons={[
                <Button
                  label="Continue"
                  onPress={handleSubmit}
                  key="submit button"
                  disabled={updateOnboardingUserLoading}
                  loading={updateOnboardingUserLoading}
                />,
              ]}
              footerPlacementContext="webScreenWithoutPolicyFooter"
            >
              <Card>
                <MdPadding>
                  <Column>
                    <LgPadding
                      topPadding={SPACING.STATIC.XS}
                      bottomPadding={SPACING.STATIC.XS}
                    >
                      <Field name="all_phi">
                        {() => (
                          <Switch
                            testID="switch-phi"
                            accessibilityLabel="switch"
                            label="Include in all messages"
                            labelColor={COLORS.TEXT_COLORS.PRIMARY}
                            value={allPhiAuthed}
                            onValueChange={(val) => {
                              setValues({
                                sms_authorized: val,
                                email_authorized: val,
                                push_authorized: val,
                              });
                            }}
                          />
                        )}
                      </Field>
                    </LgPadding>
                    {!allPhiAuthed ? (
                      <>
                        <Field name="push_authorized">
                          {({ form: { values, setFieldValue } }: FieldProps<string>) => (
                            <InputCheckbox
                              key="push_authorized"
                              name="push_authorized"
                              label="Push Notifications"
                              onPress={() => {
                                setFieldValue('push_authorized', !values.push_authorized);
                              }}
                              value={values.push_authorized}
                            />
                          )}
                        </Field>
                        <Field name="sms_authorized">
                          {({ form: { values, setFieldValue } }: FieldProps<string>) => (
                            <InputCheckbox
                              key="sms_authorized"
                              name="sms_authorized"
                              label="Text Messages/SMS"
                              onPress={() => {
                                setFieldValue('sms_authorized', !values.sms_authorized);
                              }}
                              value={values.sms_authorized}
                            />
                          )}
                        </Field>
                        <Field name="email_authorized">
                          {({ form: { values, setFieldValue } }: FieldProps<string>) => (
                            <InputCheckbox
                              key="email_authorized"
                              name="email_authorized"
                              label="Emails"
                              onPress={() => {
                                setFieldValue('email_authorized', !values.email_authorized);
                              }}
                              value={values.email_authorized}
                            />
                          )}
                        </Field>
                      </>
                    ) : null}
                  </Column>
                </MdPadding>
              </Card>
            </SecondaryPage>
          </>
        );
      }}
    </Formik>
  );
};

export default OnboardingPhiAuthorization;
