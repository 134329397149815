// @owners { team: patients-team }
import { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartEssentialsPrice } from '~shared/features/essentials/hooks';
import { getScheduleableNonEssentialPrescriptions } from '~shared/features/my-meds/selectors/getScheduleablePrescriptions';
import {
  getAutobillPrescriptionLoadingByID,
  getFetchOrderPricingLoading,
} from '~shared/features/pricing/selectors/getPricing';
import { useSelectorShared } from '~shared/store';
import { useQueryEssentialsFeeConfigs } from '../queries/useQueryEssentialsFeeConfigs';

export const useEssentialsCartThreshold = () => {
  const { essentialsMinimumBasket } = useQueryEssentialsFeeConfigs();
  const fetchingOrderPricing = useSelectorShared(getFetchOrderPricingLoading);
  const { prescriptionIDs: cartPrescriptionIDs } = usePrescriptionsInCart();
  const autobillLoadingByID = useSelectorShared(getAutobillPrescriptionLoadingByID);
  const hasScheduleablePrescriptions = Boolean(useSelectorShared(getScheduleableNonEssentialPrescriptions).length);
  const { essentialsOnlyPriceMinRemainder, essentialsOnlyPriceBelowMinimum } = useCartEssentialsPrice();
  const updatingPricing = fetchingOrderPricing || cartPrescriptionIDs.some((rxID) => autobillLoadingByID[rxID]);

  return useMemo(
    () => ({
      showEssentialsThreshold: essentialsOnlyPriceBelowMinimum,
      orderMinimumRemainder: essentialsOnlyPriceMinRemainder,
      minimumThreshold: essentialsMinimumBasket?.value,
      minimumFooterCopy: hasScheduleablePrescriptions ? 'Add a prescription or spend' : 'Spend',
      minimumAlertCopy: hasScheduleablePrescriptions
        ? 'Add more Essentials or a prescription to checkout'
        : 'Add more Essentials to checkout',
      updatingPricing,
    }),
    [
      essentialsMinimumBasket?.value,
      essentialsOnlyPriceBelowMinimum,
      essentialsOnlyPriceMinRemainder,
      hasScheduleablePrescriptions,
      updatingPricing,
    ],
  );
};
