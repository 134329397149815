// @owners { team: patients-team }
import {
  DetailsEndpoint,
  type DetailsEndpointFetchMedicationResponse,
  type DetailsEndpointFetchPrescriptionsResponse,
} from '@alto/scriptdash/alto/medications/patients/v1/details_endpoint';
import { type MedDetailsPrescription } from '@alto/scriptdash/alto/medications/patients/v1/types';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { apiEndpointHandler } from '~shared/helpers/api';

const MedDetailsApi = DetailsEndpoint(apiEndpointHandler);

export const details = createQueryKeys('medDetails', {
  medication: ({ primaryPrescriptionId }: { primaryPrescriptionId: number }) => ({
    queryKey: ['medDetails', primaryPrescriptionId],

    queryFn: async () => {
      const response: DetailsEndpointFetchMedicationResponse = await MedDetailsApi.fetchMedication({
        primary_prescription_id: primaryPrescriptionId,
      });

      return {
        data: response.data,
        errors: response.errors,
      };
    },
  }),
  prescriptions: ({ primaryPrescriptionId }: { primaryPrescriptionId: number }) => ({
    queryKey: [primaryPrescriptionId, 'pageNumber'],

    queryFn: async ({ pageParam }: QueryFunctionContext<QueryKey, string>) => {
      const response: DetailsEndpointFetchPrescriptionsResponse = await MedDetailsApi.fetchPrescriptions({
        primary_prescription_id: primaryPrescriptionId,
        pagination: { page_size: 10, page_token: pageParam },
      });
      return {
        prescriptions: response.data,
        nextPageToken: response.metadata?.pagination?.next_page_token,
        totalCount: response.metadata?.pagination?.total_count,
      };
    },
  }),
});

export const useMedDetailsMedication = ({ primaryPrescriptionId }: { primaryPrescriptionId: number }) => {
  const { data, isFetching } = useQuery({
    ...details.medication({ primaryPrescriptionId }),
  });

  return {
    isFetching,
    medication: data?.data,
    errors: data?.errors,
  };
};

export const useMedDetailsPrescriptions = ({ primaryPrescriptionId }: { primaryPrescriptionId: number }) => {
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isPending } = useInfiniteQuery<{
    prescriptions: [];
    nextPageToken: string;
  }>({
    ...details.prescriptions({ primaryPrescriptionId }),
    initialPageParam: '0',
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    select: (data) => ({ ...data, pages: (data?.pages || []).flatMap(({ prescriptions }) => prescriptions) }),
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isPendingPrescriptions: isPending,
    prescriptions: data?.pages as unknown as MedDetailsPrescription[],
  };
};

export const useInvalidateMedDetails = () => {
  const queryClient = useQueryClient();
  const invalidateMedicationQuery = useCallback(
    (primaryPrescriptionId?: number) => {
      queryClient.invalidateQueries({
        queryKey: primaryPrescriptionId
          ? details.medication({ primaryPrescriptionId }).queryKey
          : details.medication._def,
      });
    },
    [queryClient],
  );

  const invalidatePrescriptionsQuery = useCallback(
    (primaryPrescriptionId?: number) => {
      queryClient.invalidateQueries({
        queryKey: primaryPrescriptionId
          ? details.prescriptions({ primaryPrescriptionId }).queryKey
          : details.prescriptions._def,
      });
    },
    [queryClient],
  );

  return {
    invalidateMedicationQuery,
    invalidatePrescriptionsQuery,
  };
};
