// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Body, Button } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Modal, ModalContent, ModalFooter } from '@alto/pocky';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { snakeToProperCase } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

const StyledModal = styled(Modal)`
  height: 80%;
`;

export type Props = {
  readonly onClose: () => void;
  readonly origin: string;
};

export const MenopurModal = ({ onClose, origin }: Props) => {
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    trackEvent({ event: EVENTS.MODAL_VIEWED, params: { origin, type: snakeToProperCase('MENOPUR_MODAL') } });
  }, [trackEvent, origin]);

  return (
    <StyledModal
      dismissOnOutsideClick
      showDismiss
      onClose={onClose}
      title="Menopur shortage"
    >
      <ModalContent textAlignment="left">
        <Column spacing={SPACING.STATIC.MD.rem}>
          <Body fontFamily="bold">What is causing the shortage?</Body>
          <Body>
            The manufacturer of Menopur, Ferring, has halted distribution and did not share an estimated return date.
          </Body>
          <Body fontFamily="bold">Does Alto dispense Menopur?</Body>
          <Body>At this time we no longer have Menopur available to dispense.</Body>
          <Body fontFamily="bold">What can I do if I've been prescribed Menopur?</Body>
          <Body>
            There are alternatives available. We're reaching out to providers to request updated prescriptions. You'll
            get a notification from us any time we receive a new prescription for you. If urgent and your doctor has not
            yet prescribed an alternative, please contact your provider to discuss and ask them to send us a
            prescription for an alternative medication.
          </Body>
          <Body fontFamily="bold">What are the available alternatives?</Body>
          <Body>
            Every patient has a specific protocol determined by their doctor, so we will not dispense an alternative
            without their direction. Once an alternative has been prescribed for you, it will appear in your Medications
            list. Alternative medication options include:
            <br />
            <ul>
              <li>
                Novarel - patients who are prescribed Novarel as an alternative may have two separate prescriptions for
                Novarel and would need to fill both: the two Novarels would be mixed differently with one to be used in
                place of Menopur while the other is to be used as a trigger injection when instructed by your provider.
              </li>
              <li>
                Low Dose hCG - this is a compound medication with a short, 14-day expiration date. It's best to order
                1-2 days before you need to start using it to maximize the expiration date. If you schedule it too
                early, it will expire before you are done with treatment and you will need to schedule a delivery
                mid-cycle for a 2nd vial.
              </li>
              <li>
                And/or increased dosage for Gonal-F - be sure to order the most recent prescription for Gonal-F as it
                will have the latest dosage prescribed for you.
              </li>
            </ul>
          </Body>
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button
          onPress={onClose}
          label="Done"
        />
      </ModalFooter>
    </StyledModal>
  );
};
