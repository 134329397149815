import { withRehydration } from './withRehydration';
import {
  type AddressActions,
  CREATE_ADDRESS_FAILED,
  CREATE_ADDRESS_LOADING,
  CREATE_ADDRESS_SUCCEEDED,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_LOADING,
  DELETE_ADDRESS_SUCCEEDED,
  DESELECT_ADDRESS,
  FETCH_ADDRESSES_LOADING,
  FETCH_ADDRESSES_SUCCEEDED,
  SELECT_ADDRESS,
  UPDATE_ADDRESS_FAILED,
  UPDATE_ADDRESS_LOADING,
  UPDATE_ADDRESS_SUCCEEDED,
} from '~shared/actions/addresses';
import { immutableBulkUpsert } from '~shared/helpers/immutableUpsert';
import { type Address } from '~shared/types';

export type StateAddresses = {
  addresses: Address[];
  // this is to hold deleted addresses for display on past deliveries
  allAddresses: Address[];
  createAddressSuccess: boolean | null | undefined;
  lastRequestAttemptedAt: number;
  lastRequestSucceededAt: number;
  requestAttemptCount: number;
  selectedAddressID: number | null | undefined;
  updateAddressSuccess: boolean | null | undefined;
  deleteAddressSuccess: boolean | null | undefined;
};

const initialState: StateAddresses = {
  addresses: [],
  // this is to hold deleted addresses for display on past deliveries
  allAddresses: [],
  createAddressSuccess: null,
  lastRequestAttemptedAt: 0,
  requestAttemptCount: 0,
  lastRequestSucceededAt: 0,
  selectedAddressID: null,
  updateAddressSuccess: null,
  deleteAddressSuccess: null,
};

const addresses = (state: StateAddresses = initialState, action: AddressActions): StateAddresses => {
  switch (action.type) {
    case FETCH_ADDRESSES_LOADING:
      return { ...state, lastRequestAttemptedAt: Date.now(), requestAttemptCount: state.requestAttemptCount + 1 };

    case FETCH_ADDRESSES_SUCCEEDED:
      return {
        ...state,
        addresses: action.payload.filter((a) => a.deleted_at === null && !a.is_placeholder),
        allAddresses: action.payload,
        lastRequestSucceededAt: Date.now(),
        requestAttemptCount: 0,
      };

    case SELECT_ADDRESS:
      return { ...state, selectedAddressID: action.payload.id };

    case DESELECT_ADDRESS:
      return { ...state, selectedAddressID: null };

    case CREATE_ADDRESS_LOADING:
      return { ...state, createAddressSuccess: null, updateAddressSuccess: null };

    case CREATE_ADDRESS_SUCCEEDED: {
      let newAllAddresses = state.allAddresses;

      if (action.payload.primary) {
        newAllAddresses = state.allAddresses.map((address) => {
          address.primary = false;
          return address;
        });
      }

      const newAddresses = newAllAddresses.filter((a) => a.deleted_at === null && !a.is_placeholder);

      return {
        ...state,
        addresses: [...newAddresses, action.payload],
        allAddresses: [...newAllAddresses, action.payload],
        createAddressSuccess: true,
      };
    }

    case CREATE_ADDRESS_FAILED:
      return { ...state, createAddressSuccess: false };

    case UPDATE_ADDRESS_LOADING:
      return { ...state, createAddressSuccess: null, updateAddressSuccess: null };

    case UPDATE_ADDRESS_SUCCEEDED: {
      const updatedAllAddresses = immutableBulkUpsert({
        state: state.allAddresses,
        payload: action.payload,
      });

      return {
        ...state,
        addresses: updatedAllAddresses.filter((a) => a.deleted_at === null && !a.is_placeholder),
        allAddresses: updatedAllAddresses,
        updateAddressSuccess: true,
      };
    }
    case UPDATE_ADDRESS_FAILED:
      return { ...state, updateAddressSuccess: false };

    case DELETE_ADDRESS_LOADING:
      return { ...state, deleteAddressSuccess: null };

    case DELETE_ADDRESS_SUCCEEDED:
      return {
        ...state,
        addresses: state.addresses.filter((address) => {
          return address.id !== action.payload.id;
        }),
        allAddresses: state.allAddresses.map((address) => {
          if (address.id === action.payload.id) {
            return action.payload;
          }

          return address;
        }),
        deleteAddressSuccess: true,
      };

    case DELETE_ADDRESS_FAILED:
      return { ...state, deleteAddressSuccess: false };

    default:
      return state;
  }
};

export default withRehydration(addresses, initialState);
