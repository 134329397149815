import { EVENTS } from './constants';
import { createEvent } from './helper';
import { type Event } from './types';

// https://plaid.com/docs/#onevent-callback
const PLAID_EVENTS = Object.freeze({
  CLOSE_OAUTH: 'CLOSE_OAUTH',
  // The user closed the third-party website or mobile app without completing the OAuth flow.
  ERROR: 'ERROR',
  // A recoverable error occurred in the Link flow, see the error_code metadata.
  EXIT: 'EXIT',
  // The user has exited without completing the Link flow and the onExit callback is fired.
  FAIL_OAUTH: 'FAIL_OAUTH',
  // The user encountered an error while completing the third-party's OAuth login flow.
  HANDOFF: 'HANDOFF',
  // The user has completed the Link flow and the onSuccess callback is fired.
  OPEN: 'OPEN',
  // The user has opened Link.
  OPEN_MY_PLAID: 'OPEN_MY_PLAID',
  // The user has opened my.plaid.com. This event is only sent when Link is initialized with assets as a product.
  OPEN_OAUTH: 'OPEN_OAUTH',
  // The user has navigated to a third-party website or mobile app in order to complete the OAuth login flow.
  MATCHED_SELECT_INSTITUTION: 'MATCHED_SELECT_INSTITUTION',
  // The user selected an institution that was presented as a matched institution.
  MATCHED_SELECT_VERIFY_METHOD: 'MATCHED_SELECT_VERIFY_METHOD',
  // The user selected a verification method for a matched institution.
  SEARCH_INSTITUTION: 'SEARCH_INSTITUTION',
  // The user has searched for an institution.
  SELECT_BRAND: 'SELECT_BRAND',
  // The user selected a brand, e.g. Bank of America. The brand selection interface occurs before the institution select pane and is only provided for large financial institutions with multiple online banking portals.
  SELECT_INSTITUTION: 'SELECT_INSTITUTION',
  // The user selected an institution.
  SUBMIT_CREDENTIALS: 'SUBMIT_CREDENTIALS',
  // The user has submitted credentials.
  SUBMIT_MFA: 'SUBMIT_MFA',
  // The user has submitted MFA (multi-factor authentication).
  TRANSITION_VIEW: 'TRANSITION_VIEW',
  // The TRANSITION_VIEW event indicates that the user has moved from one view to the next.
  UNKNOWN: 'UNKNOWN', // The UNKNOWN event indicates that the event is not handled by the current version of the SDK.
});

type LinkEventViewName =
  | 'CONNECTED' // The user has connected their account.
  | 'CONSENT' // We ask the user to consent to the privacy policy.
  | 'CREDENTIAL' // Asking the user for their account credentials.
  | 'ERROR' // An error has occurred.
  | 'EXIT' // Confirming if the user wishes to close Link.
  | 'LOADING' // Link is making a request to our servers.
  | 'MATCHED_CONSENT' // We ask the matched user to consent to the privacy policy and SMS terms.
  | 'MATCHED_CREDENTIAL' // We ask the matched user for their account credentials to a matched institution.
  | 'MATCHED_MFA' // We ask the matched user for MFA authentication to verify their identity.
  | 'MFA' // The user is asked by the institution for additional MFA authentication.
  | 'NUMBERS' // The user is asked to insert their account and routing numbers.
  | 'RECAPTCHA' // The user was presented with a Google reCAPTCHA to verify they are human.
  | 'SELECT_ACCOUNT' // We ask the user to choose an account.
  | 'SELECT_BRAND' // The user selected a brand, e.g. Bank of America. The brand selection interface occurs before the institution select pane and is only provided for large financial institutions with multiple online banking portals.
  | 'SELECT_INSTITUTION'; // We ask the user to choose their institution.

export type PlaidEventMetadata = {
  // The error code that the user encountered. Emitted by: ERROR, EXIT.
  errorCode?: string;
  // The error message that the user encountered. Emitted by: ERROR, EXIT.
  errorMessage?: string;
  // The error type that the user encountered. Emitted by: ERROR, EXIT.
  errorType?: string;
  // The status key indicates the point at which the user exited the Link flow. Emitted by: EXIT.
  exitStatus?: string;
  // The ID of the selected institution. Emitted by: all events.
  institutionId: string;
  // The name of the selected institution. Emitted by: all events.
  institutionName: string;
  // The query used to search for institutions. Emitted by: SEARCH_INSTITUTION.
  institutionSearchQuery?: string;
  // The linkSessionId is a unique identifier for a single session of Link. It's always available and will stay constant throughout the flow. Emitted by: all events.
  linkSessionId: string;
  // If set, the user has encountered one of the following MFA types: code device questions selections. Emitted by: SUBMIT_MFA and TRANSITION_VIEW when view_name is MFA.
  mfaType?: string;
  // The request ID for the last request made by Link. This can be shared with Plaid Support to expedite investigation. Emitted by: all events.
  requestId: string;
  // The verification method for a matched institution selected by the user. Possible values are phoneotp, password. Emitted by: MATCHED_SELECT_VERIFY_METHOD.
  selection?: string;
  // An ISO 8601 representation of when the event occurred. For example, 2017-09-14T14:42:19.350Z. Emitted by: all events.
  timestamp: string;
  // The name of the view that is being transitioned to. Emitted by: TRANSITION_VIEW.
  viewName?: LinkEventViewName;
};

export type PlaidEventName = keyof typeof PLAID_EVENTS;

export function getPlaidEvent(eventName: PlaidEventName, metadata: PlaidEventMetadata): Event | null | undefined {
  switch (eventName) {
    case PLAID_EVENTS.ERROR:
      return createEvent(EVENTS.PLAID_ERROR, {
        error_code: metadata.errorCode,
        error_message: metadata.errorMessage,
        error_type: metadata.errorType,
      });

    case PLAID_EVENTS.EXIT:
      return createEvent(EVENTS.PLAID_EXITED, {
        error_code: metadata.errorCode,
        error_message: metadata.errorMessage,
        error_type: metadata.errorType,
        exit_status: metadata.exitStatus,
      });

    case PLAID_EVENTS.HANDOFF:
      return createEvent(EVENTS.PLAID_FINISHED, {
        institution_id: metadata.institutionId,
        institution_name: metadata.institutionName,
      });

    case PLAID_EVENTS.OPEN_MY_PLAID:
    case PLAID_EVENTS.OPEN:
      return createEvent(EVENTS.PLAID_STARTED);

    default:
  }

  return null;
}
