// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, LgPadding } from '@alto/design-system';
import React, { useContext } from 'react';
import { type PaymentMethodType } from '~shared/constants';
import { AddPaymentCard } from './AddPaymentCard';

type Props = {
  readonly paymentMethodType: PaymentMethodType;
};

export const AddPaymentCardActionSheet = ({ paymentMethodType }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);

  return (
    <ActionSheetV2
      handleClose={closeActionSheet}
      title="Add credit or debit card"
      analyticsName="add payment card"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <AddPaymentCard
          onClose={closeActionSheet}
          paymentMethodType={paymentMethodType}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
