// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Text, palette, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import Image from '~web/components/Image';

type Props = {
  readonly buttonText: string;
  readonly img: string;
  readonly text: string;
  readonly onClick: () => void;
  readonly imgAlt: string;
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.grey2};
  background-color: ${palette.altoColorsW};
  padding: ${spacing.lgSpacing};
  > *:not(:last-child) {
    margin-bottom: ${spacing.smSpacing};
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const SidebarText = styled(Text)`
  text-align: center;
`;

const SidebarButton = styled(Button)`
  justify-content: center;
  width: 100%;
`;

export default class SidebarItem extends React.PureComponent<Props> {
  render() {
    const { buttonText, img, onClick, text, imgAlt } = this.props;

    return (
      <Container>
        <Image
          src={img}
          alt={imgAlt}
          sidebar
        />
        <SidebarText>{text}</SidebarText>
        <SidebarButton
          kind="secondary"
          onClick={onClick}
        >
          {buttonText}
        </SidebarButton>
      </Container>
    );
  }
}
