import type React from 'react';
import { useKeyPress } from '../../../../../hooks/useKeyPress';
import {
  type Actions,
  decrementHighlightedResultIndex,
  incrementHighlightedResultIndex,
  inputBlurred,
  removeSelectedItem,
  selectItem,
  updateText,
} from '../actions';
import { summarizeEntries } from '../helpers';
import { type State } from '../reducer';

type KeyboardShortcutDeps = {
  text: State['text'];
  selectedValues: State['selectedValues'];
  filteredResultsToDisplay: State['resultsToDisplay'];
  highlightedResultIndex: State['highlightedResultIndex'];
  dispatch: React.Dispatch<Actions>;
  selectionType: 'multi' | 'single';
  /**
   * This should be the InputText component ref. TS thinks that this hook is being used in
   * a React Native context, but this hook is only used on web.
   *
   * I've purposefully typed this as a generic 'any' because 'current' will actually
   * be an HTMLInputElement (what it is on the web) instead of a TextInput (what TS thinks it is).
   */
  inputComponent: { current: any };
};

/**
 * A hook that adds keyboard shortcuts to the InputAutocompleteDropdown component.
 * We only need keyboard shortcuts on desktop web, therefore, this hook will only be called on web.
 */
export const useKeyboardShortcuts = ({
  text,
  selectedValues,
  selectionType,
  dispatch,
  inputComponent,
  filteredResultsToDisplay,
  highlightedResultIndex,
}: KeyboardShortcutDeps) => {
  useKeyPress(
    'Escape',
    () => {
      if (selectionType === 'single' && selectedValues.length) {
        dispatch(updateText(summarizeEntries([selectedValues[0]])));
      } else {
        dispatch(updateText(''));
      }
      // Since InputAutocompleteDropdown cannot set inputBlurred onBlur, we need to manually set both here.
      inputComponent.current.blur();
      dispatch(inputBlurred());
    },
    inputComponent.current,
  );

  useKeyPress(
    'Backspace',
    () => {
      // Backspace keyboard shortcuts are only relevant for multi selects
      if (selectedValues.length && selectionType === 'multi' && text === '') {
        dispatch(removeSelectedItem(selectedValues[selectedValues.length - 1], selectionType));
      }
    },
    inputComponent.current,
  );

  useKeyPress(
    'ArrowDown',
    () => {
      dispatch(incrementHighlightedResultIndex());
    },
    inputComponent.current,
  );

  useKeyPress(
    'ArrowUp',
    () => {
      dispatch(decrementHighlightedResultIndex());
    },
    inputComponent.current,
  );

  useKeyPress(
    'Enter',
    (event) => {
      if (filteredResultsToDisplay && highlightedResultIndex !== undefined) {
        // By default, pressing enter will unfocus the input. We preventDefault here so that the focus on the input can persist.
        event.preventDefault();
        dispatch(selectItem(filteredResultsToDisplay[highlightedResultIndex], selectionType));
      }
    },
    inputComponent.current,
  );
};
