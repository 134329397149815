// @owners { team: patients-team }
import { ActionSheetContext } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { setAnnouncementViewed } from '~shared/actions/ui/announcements';
import { useAnalytics } from '~shared/hooks';
import { getShouldPromptBiometricsSheet } from '~shared/selectors/biometrics/getShouldPromptBiometricsSheet';
import { getAnnouncementViewedStatus } from '~shared/selectors/ui/announcements';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useQueryFetchAnnouncement } from '../queries/useQueryFetchAnnouncement';
import { AnnouncementActionSheet } from './AnnouncementActionSheet';

export const AnnouncementNotifier = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { announcement, isSuccess, isFetching, isError } = useQueryFetchAnnouncement();

  // Get announcement viewed status. Will return false if no entry found in redux store.
  const announcementViewed = useSelectorShared((state) => getAnnouncementViewedStatus(state, announcement?.id));
  // In the case where it is a fresh install we show a biometric auth prompt first. Hold off on the announcement until after.
  const shouldPromptBiometricsSheet = useSelectorShared(getShouldPromptBiometricsSheet);

  useEffect(() => {
    if (isFetching || isError) {
      return;
    }

    // Only show when announcement is present in response and has not been
    // marked as viewed yet on the device.
    if (!shouldPromptBiometricsSheet && isSuccess && announcement && !announcementViewed) {
      setActiveActionSheet(
        <AnnouncementActionSheet
          id={announcement.id}
          title={announcement.title}
          content={announcement.content}
          imageURL={announcement.image_url}
          routeURL={announcement.route_url}
          ctaText={announcement.cta_text}
        />,
      );
      dispatch(setAnnouncementViewed(announcement.id));
      trackEvent({
        event: 'Announcement Viewed',
        params: {
          announcementID: announcement.id,
          announcementTitle: announcement.title,
        },
      });
    }
  }, [
    isSuccess,
    announcement,
    announcementViewed,
    isError,
    isFetching,
    setActiveActionSheet,
    dispatch,
    shouldPromptBiometricsSheet,
    trackEvent,
  ]);

  return null;
};
