import {
  CHANGE_BANNER,
  CLOSE_BANNER,
  type ChangeBannerArg,
  OPEN_BANNER,
  type changeBanner,
  type closeBanner,
  type openBanner,
} from '~shared/actions/banners';
import { withRehydration } from '~shared/reducers/withRehydration';

export type BannersState = {
  showBanner: boolean;
  bannerType?: ChangeBannerArg['bannerType'];
  metadata?: { medication_name?: string | null };
};

const initialState = {
  showBanner: false,
};

type Actions = ReturnType<typeof openBanner> | ReturnType<typeof closeBanner> | ReturnType<typeof changeBanner>;

const banners = (state: BannersState = initialState, action: Actions): BannersState => {
  switch (action.type) {
    case OPEN_BANNER:
      return { ...state, showBanner: true };

    case CLOSE_BANNER:
      return { ...state, showBanner: false };

    case CHANGE_BANNER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default withRehydration(banners, initialState);
