import { type Dispatch } from 'redux';
import { get, put } from '~shared/helpers/apiHelper';
import getURLParam from '~shared/helpers/getURLParam';
import { type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export type FetchUserSubscriptionsLoading = {
  type: typeof FETCH_USER_SUBSCRIPTIONS_LOADING;
};

type FetchUserSubscriptionsSucceededPayload = {
  email: string;
  subscriptions: string[];
};
export type FetchUserSubscriptionsSucceeded = {
  type: typeof FETCH_USER_SUBSCRIPTIONS_SUCCEEDED;
  payload: FetchUserSubscriptionsSucceededPayload;
};

export type FetchUserSubscriptionsFailed = {
  type: typeof FETCH_USER_SUBSCRIPTIONS_FAILED;
  payload: APIError;
};

export const FETCH_USER_SUBSCRIPTIONS_LOADING = 'FETCH_USER_SUBSCRIPTIONS_LOADING';

export function fetchUserSubscriptionsLoading(): FetchUserSubscriptionsLoading {
  return {
    type: FETCH_USER_SUBSCRIPTIONS_LOADING,
  };
}

export const FETCH_USER_SUBSCRIPTIONS_SUCCEEDED = 'FETCH_USER_SUBSCRIPTIONS_SUCCEEDED';

export function fetchUserSubscriptionsSucceeded(
  payload: FetchUserSubscriptionsSucceededPayload,
): FetchUserSubscriptionsSucceeded {
  return {
    type: FETCH_USER_SUBSCRIPTIONS_SUCCEEDED,
    payload,
  };
}

export const FETCH_USER_SUBSCRIPTIONS_FAILED = 'FETCH_USER_SUBSCRIPTIONS_FAILED';

export function fetchUserSubscriptionsFailed(error: APIError): FetchUserSubscriptionsFailed {
  return {
    type: FETCH_USER_SUBSCRIPTIONS_FAILED,
    payload: error,
  };
}

export const TOGGLE_SUBSCRIPTION = 'TOGGLE_SUBSCRIPTION';
export type ToggleSubscription = {
  type: typeof TOGGLE_SUBSCRIPTION;
  comms_type: string;
};
export function toggleSubscription(comms_type: string): ToggleSubscription {
  return {
    type: TOGGLE_SUBSCRIPTION,
    comms_type,
  };
}

export type UpdateUserSubscriptionsLoading = {
  type: typeof UPDATE_USER_SUBSCRIPTIONS_LOADING;
};

export type UpdateUserSubscriptionsSucceeded = {
  type: typeof UPDATE_USER_SUBSCRIPTIONS_SUCCEEDED;
};

export type UpdateUserSubscriptionsFailed = {
  type: typeof UPDATE_USER_SUBSCRIPTIONS_FAILED;
  payload: APIError;
};

export const UPDATE_USER_SUBSCRIPTIONS_LOADING = 'UPDATE_USER_SUBSCRIPTIONS_LOADING';

export function updateUserSubscriptionsLoading(): UpdateUserSubscriptionsLoading {
  return {
    type: UPDATE_USER_SUBSCRIPTIONS_LOADING,
  };
}

export const UPDATE_USER_SUBSCRIPTIONS_SUCCEEDED = 'UPDATE_USER_SUBSCRIPTIONS_SUCCEEDED';
export function updateUserSubscriptionsSucceeded(): UpdateUserSubscriptionsSucceeded {
  return {
    type: UPDATE_USER_SUBSCRIPTIONS_SUCCEEDED,
  };
}

export const UPDATE_USER_SUBSCRIPTIONS_FAILED = 'UPDATE_USER_SUBSCRIPTIONS_FAILED';

export function updateUserSubscriptionsFailed(error: APIError): UpdateUserSubscriptionsFailed {
  return {
    type: UPDATE_USER_SUBSCRIPTIONS_FAILED,
    payload: error,
  };
}

export type SubscriptionsActions =
  | FetchUserSubscriptionsLoading
  | FetchUserSubscriptionsFailed
  | FetchUserSubscriptionsSucceeded
  | UpdateUserSubscriptionsLoading
  | UpdateUserSubscriptionsFailed
  | UpdateUserSubscriptionsSucceeded
  | ToggleSubscription;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchUserSubscriptions(uuid: string) {
  return (
    dispatch: Dispatch<FetchUserSubscriptionsSucceeded | FetchUserSubscriptionsFailed | FetchUserSubscriptionsLoading>,
  ) => {
    dispatch(fetchUserSubscriptionsLoading());

    return get(`/subscriptions/${uuid}`).then((json) => {
      if (json.error) {
        dispatch(fetchUserSubscriptionsFailed(json.error));
        return false;
      }

      dispatch(fetchUserSubscriptionsSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function updateUserSubscriptions(uuid: string, unSubAll = false) {
  return (
    dispatch: Dispatch<
      UpdateUserSubscriptionsSucceeded | UpdateUserSubscriptionsFailed | UpdateUserSubscriptionsLoading
    >,
    getState: () => ReduxStateShared,
  ) => {
    const unsubscribeAllComms = {
      comms_type: 'all_comms',
      subscribed: false,
    };
    const subscriptions = unSubAll ? [unsubscribeAllComms] : getState().subscriptions.subscriptions;
    dispatch(updateUserSubscriptionsLoading());

    const iterableTemplateID = getURLParam('iterableTemplateId', window.location.href);

    const iterableCampaignID = getURLParam('iterableCampaignId', window.location.href);

    const source = getURLParam('source', window.location.href);

    const params = {
      subscriptions,
      source,
      attribution_params: {
        iterable_template_id: iterableTemplateID,
        iterable_campaign_id: iterableCampaignID,
      },
    };

    return put(`/subscriptions/${uuid}`, params).then((json) => {
      if (json.error) {
        dispatch(updateUserSubscriptionsFailed(json.error));
        return false;
      }

      // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
      // eslint-disable-next-line sonarjs/no-extra-arguments
      dispatch(updateUserSubscriptionsSucceeded(json));
      return true;
    });
  };
}
