import { COLORS, type ColorVariant } from '@alto/design-library-tokens';

export const getTextColorFromType = (type: ColorVariant | undefined) => {
  switch (type) {
    case 'grey':
      return COLORS.TEXT_COLORS.GREY;
    case 'danger':
      return COLORS.TEXT_COLORS.DANGER;
    case 'secondary':
      return COLORS.TEXT_COLORS.SECONDARY;
    case 'disabled':
      return COLORS.TEXT_COLORS.DISABLED;
    case 'success':
      return COLORS.TEXT_COLORS.SUCCESS;
    case 'white':
      return COLORS.TEXT_COLORS.WHITE;
    default:
      return COLORS.TEXT_COLORS.GREY;
  }
};
