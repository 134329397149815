// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Column, H1, LgPadding, LgSpacing, XsSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { totalSteps } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { type APIError } from '~shared/types/APIError';
import OnboardingProgress from '~web/features/onboarding/components/redesign/OnboardingProgress';
import { currentUrlStep } from '~web/features/onboarding/helpers/urlHandler';

export type Props = {
  readonly title: string;
  readonly error?: APIError | null;
  readonly illustration?: React.ReactNode;
  readonly loading?: boolean;
  readonly link?: React.ReactNode;
  readonly subtitle?: React.ReactNode;
};

export const OnboardingHeader = ({ error = null, illustration, link, loading = false, subtitle, title }: Props) => {
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName() || '';
  const completedSteps = currentUrlStep(routeName);
  const totalNumSteps = totalSteps();
  const showProgressBar = completedSteps !== 0;

  return (
    <Column centerHorizontally>
      {showProgressBar ? (
        <OnboardingProgress
          completedSteps={completedSteps}
          totalSteps={totalNumSteps}
        />
      ) : null}
      {!error && !loading && (
        <LgPadding
          topPadding={SPACING.STATIC.XS}
          bottomPadding={SPACING.STATIC.XL}
        >
          <H1 center>{title}</H1>

          {subtitle ? (
            <>
              <XsSpacing />
              {subtitle}
            </>
          ) : null}
          {link ? (
            <>
              <XsSpacing />
              {link}
            </>
          ) : null}
          {illustration ? (
            <>
              <LgSpacing />
              {illustration}
            </>
          ) : null}
        </LgPadding>
      )}
    </Column>
  );
};
