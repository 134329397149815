import { BASE_FONT_SIZE } from '../general';

const XS = {
  value: 8,
  px: '8px',
  rem: `${8 / BASE_FONT_SIZE}rem`,
} as const;

const SM = {
  value: 12,
  px: '12px',
  rem: `${12 / BASE_FONT_SIZE}rem`,
} as const;

const MD = {
  value: 16,
  px: '16px',
  rem: `${16 / BASE_FONT_SIZE}rem`,
} as const;

const LG = {
  value: 24,
  px: '24px',
  rem: `${24 / BASE_FONT_SIZE}rem`,
} as const;

export const ICON = {
  XS,
  SM,
  MD,
  LG,
} as const;
