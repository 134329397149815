import { type AltoIconName, SHADOWS, SPACING, Z_INDEX } from '@alto/design-library-tokens';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { Platform, type TextInput, View } from 'react-native';
import styled from 'styled-components/native';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Card } from '../../../cards';
import { Column, Row, XsPadding } from '../../../containers';
import { AltoSpinningLoader } from '../../../loaders';
import { InputText } from '../InputText/InputText';
import { AutocompleteRow } from './AutocompleteRow';
import { type SelectionTypes } from './InputAutocomplete';
import { clearResults, inputFocused, removeSelectedItem, selectItem, updateText } from './actions';
import { callApi, summarizeEntries } from './helpers';
import { useHandleClickOutside } from './hooks/useHandleClickOutside';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import { type State, reducer } from './reducer';
import { type ApiResponseRow, type DataSource } from './types';

const getInitialState = (initialValues: ApiResponseRow<string>[] | null | undefined): State => ({
  text: summarizeEntries(initialValues || []),
  selectedValues: initialValues || [],
  selectedValueKeys: initialValues?.reduce((acc, row) => ({ ...acc, [row.key]: true }), {}) || {},
  resultsToDisplay: null,
  isInputFocused: false,
  isLoading: false,
  highlightedResultIndex: 0,
});

// Because RNW sets all z-indexes to 0, we need to make sure that when InputAutocomplete
// is focused, its dropdown appears above everything else.
const DesktopWebWrapper = styled(Column)<{ focused: boolean }>`
  ${({ focused }) => focused && `z-index: ${Z_INDEX.Z_200};`}
`;

const InputAutocompleteDropdownWrapper = styled(Column)`
  position: ${Platform.OS === 'web' ? 'relative' : 'absolute'};
  width: 100%;
  top: calc(100% + ${SPACING.STATIC.XS.px});
`;

const CardWithShadow = styled(Card)`
  ${SHADOWS.BOTTOM_LIGHT}
  max-height: calc(${SPACING.STATIC.XXXXXL.px} * 2);
  overflow-y: auto;
`;

type Props = {
  readonly accessibilityLabel: string;
  readonly label: string;
  readonly error?: string | undefined;
  readonly leftIcon?: AltoIconName;
  readonly dataSource: DataSource<any>;
  readonly initialValues?: ApiResponseRow<any>[];
  readonly selectionType: SelectionTypes;
  readonly placeholder?: string;
  readonly required?: boolean;
  readonly shouldAllowManualInput?: boolean;
  readonly onSelectCallback: (arg: ApiResponseRow<any>[]) => void;
  readonly hasDefaultOptions?: boolean;
};

export const InputAutocompleteDropdown = ({
  error,
  label,
  initialValues,
  selectionType,
  placeholder,
  accessibilityLabel,
  leftIcon,
  shouldAllowManualInput,
  dataSource,
  onSelectCallback,
  required,
  hasDefaultOptions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const inputComponent = useRef<TextInput>(null);
  const highlightedResult = useRef<View>(null);
  const [state, dispatch] = useReducer(reducer, null, () => getInitialState(initialValues));
  const {
    text,
    selectedValues,
    selectedValueKeys,
    resultsToDisplay,
    isInputFocused,
    isLoading,
    highlightedResultIndex,
  } = state;

  // don't include results we've already selected
  const filteredResultsToDisplay = resultsToDisplay?.filter((result) => !selectedValueKeys[result.key]);

  useKeyboardShortcuts({
    text,
    selectedValues,
    selectionType,
    dispatch,
    inputComponent,
    filteredResultsToDisplay,
    highlightedResultIndex,
  });

  useHandleClickOutside({ dispatch, inputComponent });

  useEffect(() => {
    // @ts-expect-error: The highlighted result from TS' perspective is a RN View, but RNW actually subs this out for a div.
    // This works on web, but we need to ignore the TS error.
    highlightedResult.current?.scrollIntoView();
  }, [highlightedResultIndex]);

  const debouncedText = useDebounce(text?.trim(), 200);

  const onFocus = useCallback(() => {
    dispatch(inputFocused());
    return callApi(debouncedText, dataSource, !!hasDefaultOptions, dispatch, shouldAllowManualInput);
  }, [dataSource, debouncedText, shouldAllowManualInput, hasDefaultOptions]);

  // intentionally not listening to dataSource or isInputFocused
  useEffect(
    () => {
      if (debouncedText === summarizeEntries(selectedValues)) {
        return;
      }
      return callApi(debouncedText, dataSource, isInputFocused, dispatch, shouldAllowManualInput);
    },
    [debouncedText, dispatch, shouldAllowManualInput], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const noResults = useMemo(() => {
    if (resultsToDisplay && selectedValues.length === 0) {
      if (shouldAllowManualInput) {
        return resultsToDisplay.length === 1;
      } else {
        return resultsToDisplay.length === 0;
      }
    }

    return !!resultsToDisplay;
  }, [resultsToDisplay, shouldAllowManualInput, selectedValues]);

  if (!text && noResults) {
    dispatch(clearResults());
  }

  const handleRowPress = useCallback(
    (selectedItem: ApiResponseRow<any>) => () => {
      if (selectionType === 'multi') {
        // move focus back into the input so that the user can continue typing
        inputComponent.current?.focus();
      }
      if (selectionType === 'single') {
        // update the input text with the correctly formatted selected item
        // we're doing this here instead of the reducer because we only want this to happen for
        // desktop web single selects
        dispatch(updateText(summarizeEntries([selectedItem])));
      }
      dispatch(selectItem(selectedItem, selectionType));
      onSelectCallback([...selectedValues, selectedItem]);
    },
    [selectionType, onSelectCallback, selectedValues],
  );

  const handleRemoveSelectedItem = useCallback(
    (selectedItem: ApiResponseRow<string>) => () => {
      dispatch(removeSelectedItem(selectedItem, selectionType));
      onSelectCallback(selectedValues.filter((row) => row.key !== selectedItem.key));
    },
    [selectionType, onSelectCallback, selectedValues],
  );

  const doesInputHaveText = text !== '';

  const shouldShowResults = !!filteredResultsToDisplay && filteredResultsToDisplay.length > 0 && !isLoading;
  const shouldShowLoading = isLoading && doesInputHaveText;
  const shouldShowDropdown = shouldShowResults && isInputFocused;

  return (
    <DesktopWebWrapper focused={isInputFocused}>
      <InputText
        value={text}
        onChangeText={(text) => {
          dispatch(updateText(text));
        }}
        placeholder={placeholder}
        selectedValues={selectionType === 'multi' ? selectedValues : undefined}
        accessibilityLabel={accessibilityLabel}
        leftIconProps={{ name: leftIcon }}
        onRef={inputComponent}
        label={label}
        error={error}
        onFocus={onFocus}
        onRemoveValue={handleRemoveSelectedItem}
        required={required}
      />
      {shouldShowDropdown ? (
        <InputAutocompleteDropdownWrapper>
          {shouldShowLoading || shouldShowResults ? (
            <CardWithShadow>
              <Column
                wrap={false}
                flexShrink={0}
              >
                {shouldShowLoading ? (
                  <XsPadding>
                    <Row centerHorizontally>
                      <AltoSpinningLoader small={false} />
                    </Row>
                  </XsPadding>
                ) : null}
                {shouldShowResults
                  ? filteredResultsToDisplay.map((result, index) => (
                      <View
                        ref={index === highlightedResultIndex ? highlightedResult : undefined}
                        key={result.key}
                      >
                        <AutocompleteRow
                          isHighlighted={index === highlightedResultIndex}
                          title={result.title}
                          subtitle={result.subtitle}
                          key={result.key}
                          onPress={handleRowPress(result)}
                          onRemove={handleRemoveSelectedItem(result)}
                          textToHighlight={debouncedText}
                          isSelected={selectedValues.findIndex((i) => result.title === i.title) !== -1}
                        />
                      </View>
                    ))
                  : null}
              </Column>
            </CardWithShadow>
          ) : null}
        </InputAutocompleteDropdownWrapper>
      ) : null}
    </DesktopWebWrapper>
  );
};
