// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { useQuery } from '@tanstack/react-query';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { staleTimes } from '~shared/queries/constants';
import { queries } from '~shared/queries/query-keys';
import { useSelectorShared } from '~shared/store';
import { onboardingBackgroundColor } from '../helpers/onboardingBackgroundColor';

export const useQueryLandingPageConfigs = ({ tempPassword }: { tempPassword: string }) => {
  const { isLoading, value: enabled } = Experimentation.useFeatureFlag('onboarding_platformization');
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const { isFetching, isSuccess, data, isError } = useQuery({
    ...queries.onboardingConfigs.fetchLandingPage({ params: { temp_password: tempPassword } }),
    enabled,
    staleTime: staleTimes.fiveMinutes,
  });

  const partnershipName = data?.data?.partnership_name;
  const headerBackgroundColor = onboardingBackgroundColor(partnershipName, showProgyny);

  return {
    isFetching: isLoading || isFetching,
    isSuccess,
    isError,
    headerBackgroundColor,
    headerLogoUrl: data?.data?.header_logo_url,
    partnershipName: data?.data?.partnership_name,
    welcomeImageUrl: data?.data?.welcome_image_url,
    welcomeHeader: data?.data?.welcome_header,
    welcomeBody: data?.data?.welcome_body,
  };
};
