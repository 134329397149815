// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';
import { type AppPromoCardType } from '@alto/scriptdash/alto/patients/engagement/types/v1/app_promo';

// TODO: switching key to AppPromoCardType once new protos are applied
const PromoCardTypesMapping: Record<AppPromoCardType, BACKGROUND_COLORS_VALUES> = {
  primary_lighter: COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER,
  secondary_lighter: COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTER,
  pink_lighter: COLORS.BACKGROUND_COLORS.PINK_LIGHTER,
  tertiary_lighter: COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTER,
  success_lighter: COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTER,
};

export const getPromoCardBackgroundColor = (cardType?: AppPromoCardType): BACKGROUND_COLORS_VALUES => {
  return cardType && PromoCardTypesMapping[cardType]
    ? PromoCardTypesMapping[cardType]
    : COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER;
};
