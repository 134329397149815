// @owners { team: patients-team }
import { NewPrescriptionStatusActionSheet } from './NewPrescriptionStatusActionSheet';
import { ReceiptsActionSheet } from './ReceiptsActionSheet';
import { ReturningUnusedMedicationActionSheet } from './ReturningUnusedMedicationActionSheet';
import { SharpsDisposalActionSheet } from './SharpsDisposalActionSheet';
import { TransferOutActionSheet } from './TransferOutActionSheet';

export type FaqActionSheet =
  | typeof NewPrescriptionStatusActionSheet
  | typeof ReceiptsActionSheet
  | typeof ReturningUnusedMedicationActionSheet
  | typeof SharpsDisposalActionSheet;

type FaqButton = {
  label: string;
  component: FaqActionSheet;
};

type FaqRegExp = FaqButton & {
  regex: RegExp;
};

type FaqMatch = FaqButton & {
  matchedKeyword: string;
};

const faqKeywords: FaqRegExp[] = [
  {
    label: 'New prescription status',
    regex:
      /search(ing)? for savings|finaliz(e|ing)( best)? price|processing|pharmacist reviewing|pharmacist to review|ready|new prescription|just sent in|status|how long/giu,
    component: NewPrescriptionStatusActionSheet,
  },
  {
    label: 'Receipts',
    regex: new RegExp(`tax(es)?|receipt(s)?|summary|statement|[fh]sa|${new Date().getFullYear() - 1}`, 'giu'),
    component: ReceiptsActionSheet,
  },
  {
    label: 'Returning unused medication',
    regex: /return(s)?|unused|unopened|donat(e|ion)|extra/giu,
    component: ReturningUnusedMedicationActionSheet,
  },
  {
    label: 'Sharps container disposal',
    regex: /sharp|needle|disposal|container/giu,
    component: SharpsDisposalActionSheet,
  },
  {
    label: 'Transfer medication to another pharmacy',
    regex: /transfer|send my med|send med|send all|another pharmacy|other pharmacy/giu,
    component: TransferOutActionSheet,
  },
];

const getFaqsForMessageBody = (body: string) => {
  return faqKeywords.reduce<FaqMatch[]>((components, { regex, label, component }) => {
    const match = body.match(regex);
    if (match) {
      components.push({ label, matchedKeyword: match[0], component });
    }
    return components;
  }, []);
};

export { getFaqsForMessageBody };
