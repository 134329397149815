// @owners { team: patients-team }
import { Column, Description, H2, LgSpacing, SmSpacing, XxxlPadding } from '@alto/design-system';
import React from 'react';
import { Image } from '~web/features/essentials/primitives';

type Props = {
  readonly title: string;
  readonly description: string;
};

export const ErrorPage = ({ title, description }: Props) => {
  return (
    <XxxlPadding>
      <Column center>
        <Image
          src="https://assets.prod.alto.com/otc_categories/alert.png"
          alt="error icon"
          height="100px"
          width="100px"
        />
        <LgSpacing />
        <H2>{title}</H2>
        <SmSpacing />
        <Description>{description}</Description>
      </Column>
    </XxxlPadding>
  );
};
