// @owners { team: patients-team }
import { type SelfServiceEvent } from '~shared/features/alto-assistant/constants';

export const SET_DRAFT_MESSAGE = 'SET_DRAFT_MESSAGE';
export type SetDraftMessage = {
  type: typeof SET_DRAFT_MESSAGE;
  payload: string;
};

export function setDraftMessage(payload: string): SetDraftMessage {
  return {
    type: SET_DRAFT_MESSAGE,
    payload,
  };
}

export const SET_SELF_SERVICE_EVENT = 'SET_SELF_SERVICE_EVENT';
export type SetSelfServiceEvent = {
  type: typeof SET_SELF_SERVICE_EVENT;
  payload: SelfServiceEvent | null;
};

export function setSelfServiceEvent(payload: SelfServiceEvent | null): SetSelfServiceEvent {
  return {
    type: SET_SELF_SERVICE_EVENT,
    payload,
  };
}

export type AltoAssistantActions = SetDraftMessage | SetSelfServiceEvent;
