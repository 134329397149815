// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Card, H1, SecondaryPage, SmPadding, SmSpacing, XlPadding, useScreenSize } from '@alto/design-system';
import React, { useCallback, useEffect } from 'react';
import { Dimensions, FlatList, Platform, View } from 'react-native';
import styled from 'styled-components/native';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { useGetReadOnlyWundercoms } from '../../queries/queries';
import { useReadAllMessages } from '../../queries/useReadAllMessages';
import { MessagesReadOnlyAlert } from './MessagesReadOnlyAlert';
import { ThreadMessage } from './ThreadMessage';

const SCREEN_HEIGHT = Dimensions.get('window').height;

const ReadOnlyThreadContainer = styled(View)`
  height: ${SCREEN_HEIGHT * 0.6};
`;

export const ReadOnlyThread = () => {
  const { trackPageView, trackEvent } = useAnalytics();
  const { readAllMutate } = useReadAllMessages();
  const { isMDScreenOrBigger } = useScreenSize();
  const { data, fetchNextPage, hasNextPage, isPending, isFetching } = useGetReadOnlyWundercoms();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const handleFetchMore = useCallback(async () => {
    if (hasNextPage && !isPending && !isFetching) {
      trackEvent({
        event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
        params: { name: ASSISTANT_VIEWED_EVENTS.FETCH_MORE_NON_CASE_WUNDERCOMS },
      });
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isPending, trackEvent]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ASSISTANT_READ_ONLY_THREAD,
    });
    readAllMutate(undefined);
  }, [readAllMutate, trackPageView]);

  const headerVertPaddingForScreen = isMDScreenOrBigger ? SPACING.STATIC.XL : SPACING.STATIC.MD;
  const headerLeftPaddingForScreen = isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.MD;

  // on web we need the card to be scroll enabled so things render correct, on mobile the flatlist will scroll correctly
  const overflowCardScroll = ['android', 'ios'].includes(Platform.OS) ? undefined : 'scroll';

  return (
    <SecondaryPage
      HeaderContent={
        <XlPadding
          topPadding={headerVertPaddingForScreen}
          bottomPadding={headerVertPaddingForScreen}
          leftPadding={headerLeftPaddingForScreen}
        >
          <H1>Updates</H1>
        </XlPadding>
      }
      scrollEnabled={false}
      onDismiss={navigateToAssistantLanding}
      dismissIcon="chevronleft"
      justifyButtons="left"
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
    >
      <ReadOnlyThreadContainer>
        <Card overflow={overflowCardScroll}>
          <SmPadding>
            <FlatList
              nestedScrollEnabled
              data={data || []}
              renderItem={({ item }) => {
                if (!item) {
                  return null;
                }
                return (
                  <ThreadMessage
                    id={item.id}
                    is_support_chatbot={false}
                    user_id={item.user_id}
                    body={item.body}
                    created_at={item.created_at}
                    read_at={item.read_at}
                    admin_user_id={item.admin_user_id}
                  />
                );
              }}
              keyExtractor={(item, index) => item?.id.toString() || `wundercom-${index}`}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onEndReached={handleFetchMore}
              onEndReachedThreshold={0.1}
            />
          </SmPadding>
        </Card>
      </ReadOnlyThreadContainer>
      <SmSpacing />
      <MessagesReadOnlyAlert openWizardActionSheet={navigateToAssistantLanding} />
    </SecondaryPage>
  );
};
