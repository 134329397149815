export type AsyncValue = {
  label: string;
  value: string;
};

export const formatAsyncSelectInitialValue = (
  value: (string | null | undefined) | (string[] | null | undefined),
): AsyncValue | AsyncValue[] | null => {
  if (!value) return null;

  if (Array.isArray(value)) {
    return value.map(
      (v): AsyncValue => ({
        label: v,
        value: v,
      }),
    );
  }

  return {
    label: value,
    value,
  };
};

export const formatAsyncSelectSubmitValue = (value: AsyncValue | AsyncValue[] | null | undefined) => {
  if (!value) return null;

  if (Array.isArray(value)) {
    return value.map(({ value }) => value).join(', ');
  }

  return value.value;
};
