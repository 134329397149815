// @owners { team: patients-team }
import { MOUNJARO_NDCS } from './constants';
import { PRESCRIPTION_STATES } from '~shared/constants';
import { type AvailabilitySortData } from '~shared/features/prescriptions/selectors/getAvailabilityDataByPrescriptionID';
import { makeCachedSortByISODateString } from '~shared/helpers/sort';
import { type Prescription } from '~shared/types';

const getSortDate = ({ active_state, date_written, effective_at }: Prescription) => {
  let date = date_written;

  if (active_state === PRESCRIPTION_STATES.TOO_EARLY) {
    date = effective_at;
  }

  return date;
};

const sortByDate = makeCachedSortByISODateString();

export const sortPrescriptionsByActive = (rxA: Prescription, rxB: Prescription) => {
  const rxADate = getSortDate(rxA);
  const rxBDate = getSortDate(rxB);
  return sortByDate(rxADate, rxBDate) * -1;
};

const sortByDates = (rxA: Prescription, rxB: Prescription, rxADate: string, rxBDate: string) => {
  let sortResult = sortByDate(rxADate, rxBDate);
  if (sortResult === 0) {
    sortResult = sortPrescriptionsByActive(rxA, rxB);
  }

  return sortResult;
};

// Sort order:
// - Prescriptions with upcoming deliveries
// - Prescriptions ordered by earliest available date
// - Prescriptions ordered by date written / effective at
export const sortMultipleActivePrescriptions = (
  prescriptions: Prescription[],
  availabilityDataByPrescriptionID: Record<number, AvailabilitySortData>,
) => {
  const [prescriptionsWithUpcomingDeliveries, prescriptionsWithEarliestAvailableDate, rest] = prescriptions.reduce(
    (result: [Prescription[], Prescription[], Prescription[]], prescription: Prescription) => {
      let index = 2;
      if (availabilityDataByPrescriptionID[prescription.id].upcomingDeliveryDate) {
        index = 0;
      } else if (availabilityDataByPrescriptionID[prescription.id].earliestAvailableDate) {
        index = 1;
      }
      result[index].push(prescription);
      return result;
    },
    [[], [], []],
  );

  prescriptionsWithUpcomingDeliveries.sort((a, b) =>
    sortByDates(
      a,
      b,
      availabilityDataByPrescriptionID[a.id].upcomingDeliveryDate ?? '',
      availabilityDataByPrescriptionID[b.id].upcomingDeliveryDate ?? '',
    ),
  );

  prescriptionsWithEarliestAvailableDate.sort((a, b) =>
    sortByDates(
      a,
      b,
      availabilityDataByPrescriptionID[a.id].earliestAvailableDate ?? '',
      availabilityDataByPrescriptionID[b.id].earliestAvailableDate ?? '',
    ),
  );

  const restOfPrescriptions = rest.sort((a, b) => sortPrescriptionsByActive(a, b));

  return prescriptionsWithUpcomingDeliveries.concat(prescriptionsWithEarliestAvailableDate).concat(restOfPrescriptions);
};

// Sort order:
// dosage in mg smallest to largest
// for multiple active of same dosage, user sortMultipleActivePrescriptions

export const sortMultipleMounjaroDosages = ({
  prescriptions,
  availabilityDataByPrescriptionID,
}: {
  prescriptions: Prescription[];
  availabilityDataByPrescriptionID: Record<number, AvailabilitySortData>;
}) => {
  return prescriptions.reduce<Record<string, Prescription[]>>((sortedMonjouroPrescriptions, prescription) => {
    const ndc = prescription.ndc || '';
    if (MOUNJARO_NDCS.includes(ndc)) {
      sortedMonjouroPrescriptions[ndc] = sortMultipleActivePrescriptions(
        [...(sortedMonjouroPrescriptions[ndc] || []), prescription],
        availabilityDataByPrescriptionID,
      );
    }
    return sortedMonjouroPrescriptions;
  }, {});
};
