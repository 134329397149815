import { NavigationService } from '@alto/navigation';
import { isNative } from '~shared/helpers/isNative';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
import { isError, isFailure } from '~shared/reducers/ui/helpers';

const FAILURE_TYPES = {
  NETWORK_FAILURE: 'network_failure',
  // 598
  SERVER_ERROR: 'fatal_error',
  // 500
  AUTH: 'auth',
  // 401
  VALIDATION: 'serverside_validation',
  // 4XX
  UNKNOWN: 'unknown', // not a 5XX or 4XX
};

const ACTION_EXCLUSION_LIST = ['EVENTS_FLUSHED_FAILED'];

export default function getErrorEvent(action: any): Event | null | undefined {
  if ((!isFailure(action.type) && !isError(action.type)) || ACTION_EXCLUSION_LIST.includes(action.type)) {
    return undefined;
  }

  const statusCode = action.payload?.details?.statusCode;
  let failureType = FAILURE_TYPES.UNKNOWN;

  if (statusCode === 401) {
    failureType = FAILURE_TYPES.AUTH;
  } else if (statusCode >= 400 && statusCode < 500) {
    failureType = FAILURE_TYPES.VALIDATION;
  } else if (statusCode === 500) {
    failureType = FAILURE_TYPES.SERVER_ERROR;
  } else if (statusCode === 598) {
    failureType = FAILURE_TYPES.NETWORK_FAILURE;
  }

  const location = isNative ? NavigationService.getCurrentRouteName() : window.location.pathname;

  return createEvent(EVENTS.CRITICAL_ACTION_FAILURE, {
    type: failureType,
    reasoning: action.type,
    location,
  });
}
