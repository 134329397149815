import { createSelector } from 'reselect';
import { getCurrentUser } from './getCurrentUser';
import { NO_KNOWN_ALLERGIES, NO_KNOWN_MEDICAL_CONDITIONS } from '~shared/constants';
import { type User } from '~shared/types';

function hasAllergies({ allergies }: User) {
  return !!allergies && allergies !== NO_KNOWN_ALLERGIES;
}

function hasMedicalConditions({ medical_conditions }: User) {
  return !!medical_conditions && medical_conditions !== NO_KNOWN_MEDICAL_CONDITIONS;
}

export const getCurrentUserHasHealthConditions = createSelector(getCurrentUser, (user) => {
  return !!user && (hasAllergies(user) || hasMedicalConditions(user));
});

export default getCurrentUserHasHealthConditions;
