import { type ActionSelectUser, SELECT_USER } from '~shared/actions/ui/users';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateUiUsers = {
  selectedUserID: number | null | undefined;
};

export const initialState: StateUiUsers = {
  selectedUserID: null,
};

type Action = ActionSelectUser;

const users = (state: StateUiUsers = initialState, action: Action): StateUiUsers => {
  const { type } = action;

  switch (type) {
    case SELECT_USER: {
      const { payload } = action;
      const { userID } = payload;
      return { ...state, selectedUserID: userID };
    }

    default:
      return state;
  }
};

export default withRehydration(users, initialState);
