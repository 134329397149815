// @owners { team: patients-team }
import { BORDERS, COLORS, SHADOWS } from '@alto/design-library-tokens';
import styled from 'styled-components';

export const GridCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px ${BORDERS.STYLE.SOLID} ${COLORS.BORDER_COLORS.LIGHT};
  border-radius: ${BORDERS.RADIUS.LG.px};
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  ${SHADOWS.BOTTOM_LIGHT}
`;
