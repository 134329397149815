import { Row } from '@alto/design-system';
import { PrescriptionRefillCard } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Slider, SliderNavigation, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { type Prescription } from '~shared/types';
import MedicationImageLegacy, { type MedicationImageLegacyProps } from '~web/components/MedicationImageLegacy';

const MedImage = styled(MedicationImageLegacy)<MedicationImageLegacyProps>`
  height: 48px;
  width: 60px;
`;

type Props = {
  readonly prescriptions: Prescription[];
  readonly addNumItemsAddedFromCrossSell: () => void;
};

const PrescriptionCardCarousel = ({ prescriptions, addNumItemsAddedFromCrossSell }: Props) => (
  <Slider
    // eslint-disable-next-line react/display-name
    slides={prescriptions.map((prescription, index) => () => (
      <Row>
        <PrescriptionRefillCard
          addNumItemsAddedFromCrossSell={addNumItemsAddedFromCrossSell}
          key={prescription.id}
          MedImage={<MedImage imageUrl={prescription.image_url} />}
          prescription={prescription}
          prescriptionIndex={index}
        />
      </Row>
    ))}
    render={({ CurrentSlideComponent, slideIndex, setSlideIndex }) => (
      <Column spacing={spacing.mdSpacing}>
        <CurrentSlideComponent />
        <SliderNavigation
          slideCount={prescriptions.length}
          slideIndex={slideIndex}
          setSlideIndex={setSlideIndex}
        />
      </Column>
    )}
  />
);

export default PrescriptionCardCarousel;
