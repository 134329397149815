import { type BACKGROUND_COLORS_VALUES, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { type StyleProp, View, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';

// can't use just 'top', 'bottom', 'left', etc because they seem to collide with internal react-native prop names
type PaddingSize = {
  px: string;
  value: number;
  rem: string;
};

export type PaddingProps = {
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly bottomPadding?: PaddingSize;
  readonly children?: React.ReactNode;
  readonly flexGrow?: number;
  readonly leftPadding?: PaddingSize;
  readonly rightPadding?: PaddingSize;
  readonly topPadding?: PaddingSize;
  readonly style?: StyleProp<ViewStyle>;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
};

const BasePadding = styled(View)<PaddingProps>`
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
  ${({ topPadding }) => (topPadding ? `padding-top: ${topPadding.px};` : '')}
  ${({ rightPadding }) => (rightPadding ? `padding-right: ${rightPadding.px};` : '')}
  ${({ bottomPadding }) => (bottomPadding ? `padding-bottom: ${bottomPadding.px};` : '')}
  ${({ leftPadding }) => (leftPadding ? `padding-left: ${leftPadding.px};` : '')}
  ${({ flexGrow }) => (flexGrow ? `flex-grow: ${flexGrow};` : '')}
`;

export const XxsPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.XXS}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.XXS}
      rightPadding={rightPadding ?? SPACING.STATIC.XXS}
      topPadding={topPadding ?? SPACING.STATIC.XXS}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};

export const XsPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.XS}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.XS}
      rightPadding={rightPadding ?? SPACING.STATIC.XS}
      topPadding={topPadding ?? SPACING.STATIC.XS}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};

export const SmPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.SM}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.SM}
      rightPadding={rightPadding ?? SPACING.STATIC.SM}
      topPadding={topPadding ?? SPACING.STATIC.SM}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};
export const MdPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.MD}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.MD}
      rightPadding={rightPadding ?? SPACING.STATIC.MD}
      topPadding={topPadding ?? SPACING.STATIC.MD}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};

export const LgPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.LG}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.LG}
      rightPadding={rightPadding ?? SPACING.STATIC.LG}
      topPadding={topPadding ?? SPACING.STATIC.LG}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};

export const XlPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.XL}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.XL}
      rightPadding={rightPadding ?? SPACING.STATIC.XL}
      topPadding={topPadding ?? SPACING.STATIC.XL}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};

export const XxlPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.XXL}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.XXL}
      rightPadding={rightPadding ?? SPACING.STATIC.XXL}
      topPadding={topPadding ?? SPACING.STATIC.XXL}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};

export const XxxlPadding = ({
  backgroundColor,
  bottomPadding,
  children,
  flexGrow,
  leftPadding,
  rightPadding,
  topPadding,
  style,
  testID,
}: PaddingProps) => {
  return (
    <BasePadding
      backgroundColor={backgroundColor}
      bottomPadding={bottomPadding ?? SPACING.STATIC.XXXL}
      flexGrow={flexGrow}
      leftPadding={leftPadding ?? SPACING.STATIC.XXXL}
      rightPadding={rightPadding ?? SPACING.STATIC.XXXL}
      topPadding={topPadding ?? SPACING.STATIC.XXXL}
      style={style}
      testID={testID}
    >
      {children}
    </BasePadding>
  );
};
