// @owners { team: patients-team }
import { type SortType } from '@alto/scriptdash/alto/medications/patients/v1/types';
import { type CheckboxStateByFilterType } from '~shared/reducers/ui/medList';

export function setSortType(sortType: SortType) {
  return {
    type: 'SET_SORT_TYPE' as const,
    payload: { sortType },
  };
}

export function setMedListFilters(filters: CheckboxStateByFilterType) {
  return {
    type: 'SET_MED_LIST_FILTERS' as const,
    payload: { filters },
  };
}

export function setFamilyMemberFilters(familyMemberFilters: Record<string, boolean>) {
  return {
    type: 'SET_FAMILY_MEMBER_FILTERS' as const,
    payload: { familyMemberFilters },
  };
}

export function resetFilters() {
  return {
    type: 'RESET_FILTERS' as const,
  };
}
