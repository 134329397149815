// @owners { team: patients-team }
import React, { PureComponent } from 'react';
import { type ModalType, openModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers } from '~shared/actions/users';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared, type User } from '~shared/types';
import ProfileContactInfo from './ProfileContactInfo';

type Props = {
  readonly fetchUsers: () => void;
  readonly openModal: (name: ModalType) => void;
  readonly user: User;
};

class ProfileContactInfoContainer extends PureComponent<Props> {
  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  render() {
    const { openModal, user } = this.props;

    return (
      <ProfileContactInfo
        openModal={openModal}
        user={user}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStateShared) => {
  const user = getCurrentUser(state);

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    fetchUsers: () => {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchUsers());
    },
    openModal: (modalType: ModalType) => {
      dispatch(openModal(modalType));
    },
  };
};

// @ts-expect-error TS(2345): Argument of type 'typeof ProfileContactInfoContainer' is not assignable to parameter of type 'Compon... (Delete me to see the full error)
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContactInfoContainer);
