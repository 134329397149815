import * as React from 'react';
import { XxsSpacing } from '../../../separators';
import { type ListDescription } from '../ListDescription';

type ListItemDescriptionsProps = {
  readonly descriptions: React.ReactElement<typeof ListDescription> | React.ReactElement<typeof ListDescription>[];
  readonly hasTopSpacing: boolean;
};

export const ListItemDescriptions = ({ descriptions, hasTopSpacing }: ListItemDescriptionsProps) => {
  const isDescriptionsAnArray = Array.isArray(descriptions) && !!descriptions?.length;

  if (isDescriptionsAnArray) {
    return (
      <>
        {descriptions.map((description, index) => (
          <React.Fragment key={index}>
            <XxsSpacing />
            {description}
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      {hasTopSpacing ? <XxsSpacing /> : null}
      {descriptions}
    </>
  );
};
