// @owners { team: patients-team }
import { AddOnsEndpoint } from '@alto/scriptdash/alto/patient_app/add_ons/v1/add_ons_endpoint';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useQuery } from '~shared/react-query';

const addOnsEndpoint = AddOnsEndpoint(apiEndpointHandler);

type QueryParams = {
  addressID?: number;
  date?: string | null | undefined;
  sku: string;
  skuType: string;
};

export const useQueryRelatedEssentials = ({ addressID, date, sku, skuType }: QueryParams) => {
  const query = useQuery({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['related-otcs', { addressID, date, skuType, sku }],
    queryFn: () => {
      if (!addressID) return null;

      return addOnsEndpoint.fetchRelated({
        address_id: addressID,
        fill_date: date,
        sku_type: skuType,
        sku,
      });
    },
    refetchOnMount: false,
  });

  return {
    isSuccess: query.isSuccess,
    isPending: query.isPending,
    error: query.error,
    isError: query.isError,
    relatedEssentials: query.data?.data,
  };
};
