import { type BACKGROUND_COLORS_VALUES, COLORS, SIZES } from '@alto/design-library-tokens';
import * as React from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Platform, type ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { getHeaderHeight, useScreenSize } from '../../../utils';
import { AnchoredButtonGroup, type AnchoredButtonGroupProps, type ButtonGroupProps } from '../../buttons';
import { Column, KeyboardAvoidingScrollView, type KeyboardAvoidingScrollViewProps } from '../../containers';
import { type FixedFooterProps } from '../../containers/src/FixedFooter';
import { type NavBar } from './NavBar';

export const PageRoot = styled(Column)<{ isMDScreenOrBigger: boolean; withoutWebFramingElementHeights?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: nowrap;

  ${({ isMDScreenOrBigger, withoutWebFramingElementHeights }) => {
    if (Platform.OS === 'web') {
      if (withoutWebFramingElementHeights) {
        return `min-height: 100vh;`;
      }

      if (isMDScreenOrBigger) {
        return `min-height: calc(100vh - ${SIZES.PAGE.WEB_FOOTER_HEIGHT.LG} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG});`;
      }
      return `min-height: calc(100vh - ${SIZES.PAGE.WEB_FOOTER_HEIGHT.LG} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM});`;
    }
    return ``;
  }}
`;

const StyledScrollView = styled(KeyboardAvoidingScrollView).attrs<{ scrollEnabled?: boolean }>(({ scrollEnabled }) => ({
  contentContainerStyle: {
    maxWidth: Platform.OS === 'web' ? '100vw' : undefined,
    flexGrow: 1,
    flexShrink: scrollEnabled ? 0 : 1,
    marginBottom: Platform.OS === 'web' ? SIZES.PAGE.WEB_FOOTER_HEIGHT.LG : undefined,
  },
}))``;

export const ContentOffsetNavBarSpacing = styled(View)<{
  topInset: number;
  backgroundColor?: BACKGROUND_COLORS_VALUES;
}>`
  width: 100%;
  height: ${({ topInset }) => getHeaderHeight() + (topInset || 0)}px;
  color: ${({ backgroundColor }) => backgroundColor ?? COLORS.BACKGROUND_COLORS.WHITE};
`;

export type BasePageProps = {
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly headerBackgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly NavBar?: React.ReactElement<typeof NavBar>;
  readonly children: React.ReactNode;
  /**
   * Enables or disables scrolling on the ScrollView so that we can provide
   * our own FlatList or ScrollView for infinite scroll for pagination or auto-scroll to
   * thing purposes. Please only use this if you are implementing the above things.
   */
  readonly scrollEnabled?: boolean;
  readonly scrollViewBounces?: boolean;
  readonly hideTopBorderButtons?: boolean;
  readonly buttons?: ButtonGroupProps['buttons'];
  readonly footerPlacementContext?: FixedFooterProps['footerPlacementContext'];
  readonly inlineButtons?: ButtonGroupProps['inline'];
  readonly justifyButtons?: ButtonGroupProps['justifyButtons'];
  readonly transparentNavBar?: boolean;
  readonly showTopShadow?: boolean;
  // Currently only needed by SplitPage because the footer are handled differently relative to the bottom safe area.
  readonly bottomSafeAreaColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  /** Sometimes we don't show either of the Web specific framing elements, the NavBar, nor the Privacy policy
   * Footer, and we need to not account for their heights when they're not shown  */
  readonly withoutWebFramingElementHeights?: boolean;
} & Pick<AnchoredButtonGroupProps, 'Footnote'> &
  Pick<KeyboardAvoidingScrollViewProps, 'keyboardAvoidingEnabled'>;

const getBottomSafeAreaColor = ({
  bottomSafeAreaColor,
  hasButtons,
}: {
  bottomSafeAreaColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  hasButtons?: boolean;
}) => {
  if (bottomSafeAreaColor) {
    return bottomSafeAreaColor;
  }
  // AnchoredButtonGroup has a white background, so we should have the bottom safe area color match since it's fixed to the bottom.
  if (hasButtons) {
    return COLORS.BACKGROUND_COLORS.WHITE;
  }
  return undefined;
};

export type PageRef = {
  scrollToTop: () => void;
};

const Page = forwardRef<PageRef, BasePageProps>(
  (
    {
      children,
      scrollEnabled = true,
      scrollViewBounces,
      backgroundColor = COLORS.BACKGROUND_COLORS.WHITE,
      headerBackgroundColor,
      NavBar,
      buttons = [],
      footerPlacementContext,
      hideTopBorderButtons = false,
      inlineButtons = false,
      justifyButtons,
      transparentNavBar = false,
      withoutWebFramingElementHeights,
      showTopShadow = false,
      Footnote,
      bottomSafeAreaColor,
      keyboardAvoidingEnabled = true,
    },
    ref,
  ) => {
    const { top } = useSafeAreaInsets();
    const scrollViewRef = useRef<ScrollView>(null);
    const { isMDScreenOrBigger } = useScreenSize();
    // Always show NavBar on native devices. Show on web if smaller than MDScreen ( < 905)
    const showNavBar = Platform.OS === 'ios' || Platform.OS === 'android' || !!NavBar;
    const showOffset = !transparentNavBar && showNavBar;

    const hasButtons = buttons.length > 0;
    const styledScrollViewBottomSafeAreaColor = getBottomSafeAreaColor({ bottomSafeAreaColor, hasButtons });

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        if (scrollViewRef.current) {
          if (Platform.OS === 'web') {
            window.scrollTo(0, 0);
          } else {
            scrollViewRef.current.scrollTo({ y: 0, animated: true });
          }
        }
      },
    }));

    return (
      <PageRoot
        isMDScreenOrBigger={isMDScreenOrBigger}
        withoutWebFramingElementHeights={withoutWebFramingElementHeights}
      >
        <StyledScrollView
          keyboardAvoidingEnabled={keyboardAvoidingEnabled}
          scrollEnabled={scrollEnabled}
          bounces={scrollViewBounces}
          backgroundColor={backgroundColor}
          scrollViewRef={scrollViewRef}
          disableKeyboardVerticalOffset
          bottomSafeAreaColor={styledScrollViewBottomSafeAreaColor}
          footerPlacementContext={footerPlacementContext}
          fixedFooterChildren={
            hasButtons ? (
              <AnchoredButtonGroup
                buttons={buttons}
                hideTopBorder={hideTopBorderButtons}
                showTopShadow={showTopShadow}
                Footnote={Footnote}
                justifyButtons={justifyButtons}
                inline={inlineButtons}
              />
            ) : null
          }
        >
          {showOffset ? (
            <ContentOffsetNavBarSpacing
              backgroundColor={headerBackgroundColor}
              topInset={top}
            />
          ) : null}
          {children}
        </StyledScrollView>
        {showNavBar ? NavBar : null}
      </PageRoot>
    );
  },
);

export const BasePage = Page;

Page.displayName = 'Page';
