import { type BadgeCounts } from '~shared/actions/notices';
import { type ReduxStateShared } from '~shared/types';

export const combineBadgeCounts = (badgeCounts: BadgeCounts) => Object.values(badgeCounts).reduce((a, b) => a + b);

export const getBadgeCounts: (state: ReduxStateShared) => BadgeCounts = (state) => state.notices.badgeCounts;

export const getBadgeCountsTotal: (state: ReduxStateShared) => number = (state) => {
  const badgeCounts = getBadgeCounts(state);

  return combineBadgeCounts(badgeCounts);
};
