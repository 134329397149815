import { COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexRow, H1 } from '@alto/pocky';
import React from 'react';
import styled, { css } from 'styled-components';

export const MODAL_SIZE = 'MODAL_SIZE';
export const LARGE_SIZE = 'LARGE_SIZE';
type ImgSizeType = typeof MODAL_SIZE | typeof LARGE_SIZE;

const largeCss = css`
  width: 120px;
  height: 120px;
`;

const Img = styled.img<{ imgSize?: ImgSizeType; isSMScreenOrBigger: boolean }>`
  margin: 0 auto ${SPACING.STATIC.MD.rem};
  ${({ imgSize, isSMScreenOrBigger }) => {
    switch (imgSize) {
      case MODAL_SIZE:
        return `
          width: 120px;
          height: 120px;
          ${!isSMScreenOrBigger && `width: 80px; height: 80px;`}
        `;

      case LARGE_SIZE:
        return largeCss;

      default:
        return `
          width: 80px;
          height: 80px;
          ${!isSMScreenOrBigger && `width: 60px; height: 60px;`}
        `;
    }
  }};
`;

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const TitleText = styled(H1)<{ children?: undefined | string | any }>`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  white-space: pre-wrap;
`;

type HeaderProps = {
  readonly title: string;
};

export const Header = ({ title }: HeaderProps) => {
  return (
    <FlexRow
      spacing={SPACING.STATIC.MD.rem}
      horizontallyAlignContent
      noShrink
    >
      <TitleText>{title}</TitleText>
    </FlexRow>
  );
};

type HeaderImageProps = {
  readonly src: string;
  readonly alt: string;
  readonly title: string | React.ReactNode;
  readonly imgSize?: ImgSizeType;
};

const HeaderImage = (props: HeaderImageProps) => {
  const { src, alt, title, imgSize } = props;
  const { isSMScreenOrBigger } = useScreenSize();

  return (
    <FlexRow
      spacing={SPACING.STATIC.MD.rem}
      horizontallyAlignContent
      noShrink
    >
      <Img
        src={src}
        alt={alt}
        imgSize={imgSize}
        isSMScreenOrBigger={isSMScreenOrBigger}
      />
      <TitleText>{title}</TitleText>
    </FlexRow>
  );
};

export default HeaderImage;
