// @owners { team: patients-team }
import { Pressable } from '@alto/design-system';
import React from 'react';
import { Linking } from 'react-native';
import { IOS_APP_STORE_LINK } from '~shared/constants';
import { AppleStoreImage } from './AppleStoreImage';

export const IosAppDownloadImageLink = () => {
  return (
    <Pressable
      onPress={() => {
        Linking.openURL(IOS_APP_STORE_LINK);
      }}
    >
      <AppleStoreImage />
    </Pressable>
  );
};
