// @owners { team: patients-team }
import { ActionSheetContext, AltoIcon, Description, Row, SmSpacing, Touchable } from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { MESSAGE_RESPONSE_TIME } from '~shared/features/messages/constants';
import { AltoAssistantActionSheet } from './AltoAssistantActionSheet';
import { MessagingActionSheet } from './MessagingActionSheet';

export const SupportCaseContentFootnote = ({
  withSpacing,
  chatbotSessionStatus,
}: {
  readonly withSpacing?: boolean;
  readonly chatbotSessionStatus: string | null | undefined;
}) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const handleIconPress = useCallback(() => {
    setActiveActionSheet(<MessagingActionSheet />);
  }, [setActiveActionSheet]);

  const handleAltoAssistantIconPress = useCallback(() => {
    setActiveActionSheet(<AltoAssistantActionSheet />);
  }, [setActiveActionSheet]);

  if (chatbotSessionStatus === 'active') {
    return (
      <Touchable onPress={handleAltoAssistantIconPress}>
        <Row
          centerHorizontally
          centerVertically
        >
          <Description>Learn more about Alto Assistant </Description>
          <AltoIcon
            name="info-small"
            accessibilityLabel="Press to learn more about Alto Assistant"
          />
        </Row>
        {withSpacing ? <SmSpacing /> : null}
      </Touchable>
    );
  }

  return (
    <Touchable onPress={handleIconPress}>
      <Row
        centerHorizontally
        centerVertically
      >
        <Description>
          We typically respond within {MESSAGE_RESPONSE_TIME} during business hours.
          <AltoIcon
            name="info-small"
            accessibilityLabel="Press to view why messages are not instant"
          />
        </Description>
      </Row>
      {withSpacing ? <SmSpacing /> : null}
    </Touchable>
  );
};
