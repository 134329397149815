// @owners { team: patients-team }
import { Description, InlineAlert, Link, XxsSpacing } from '@alto/design-system';
import React from 'react';

type Props = {
  readonly nextAvailableDateTitle: string;
  readonly openGetItSoonerFlow: () => void;
};

export const DelayedAvailabilityInlineAlert = ({ nextAvailableDateTitle, openGetItSoonerFlow }: Props) => {
  return (
    <InlineAlert
      borderless
      type="warning"
    >
      <Description fontFamily="semibold">Delayed availability</Description>
      <XxsSpacing />
      <Description>{nextAvailableDateTitle}</Description>
      <XxsSpacing />
      <Link
        onPress={openGetItSoonerFlow}
        textSize="mini"
      >
        Why this date?
      </Link>
    </InlineAlert>
  );
};
