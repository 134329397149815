// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { ListDescription } from '@alto/design-system';
import {
  type FulfillmentOption,
  type FulfillmentWindowOption,
} from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import React from 'react';
import { getMailCopy, isMail } from '~shared/helpers/order';
import { PickupTimeDescription } from './PickupTimeDescription';
import { WindowDescription } from './WindowDescription';

type Props = {
  deliveryMethod?: DeliveryMethod;
  selectedWindowOption?: FulfillmentWindowOption;
  fulfillmentOption: FulfillmentOption;
};
export const OptionDescription = ({ deliveryMethod, fulfillmentOption, selectedWindowOption }: Props) => {
  const { date, pickup_data, fulfillment_window_option } = fulfillmentOption;
  const isPickup = !!pickup_data;
  const isMailDelivery = isMail(deliveryMethod?.mode);
  if (isPickup) {
    return <PickupTimeDescription time={pickup_data?.ready_by_time} />;
  } else if (isMailDelivery) {
    return <ListDescription>{getMailCopy(deliveryMethod, 'unscheduled')}</ListDescription>;
  } else
    return (
      <WindowDescription
        window={selectedWindowOption?.date === date ? selectedWindowOption : fulfillment_window_option}
        /* for single window and bundling options (that have a specified fulfillment window in their option), we cannot change
         *  the delivery window for those options */
        areWindowsChangeable={!fulfillmentOption.fulfillment_window_option}
      />
    );
};
