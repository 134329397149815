// @owners { team: patients-team }
import {
  ActionSheetContext,
  AltoIcon,
  AltoSpinningLoader,
  Description,
  Link,
  ListDescription,
  Row,
} from '@alto/design-system';
import { NextAvailableDateActionSheet, type OrderTypeContext } from '@alto/features';
import { isToday as isDateToday, parse } from 'date-fns';
import React, { useContext } from 'react';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { DATE_FORMATS, formatRelativeDate } from '~shared/helpers/date';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { type Prescription } from '~shared/types/clients';

type Props = {
  readonly prescription: Prescription;
  readonly orderTypeContext?: OrderTypeContext;
};

export const NextAvailableDateInfoRow = ({ prescription, orderTypeContext }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { isLoading, nextAvailableDate } = useNextAvailableDatesForPrescriptions({
    prescriptionID: prescription.id,
  });

  if (isLoading) {
    return (
      <Row>
        <AltoIcon
          name="calendar-small"
          type="grey"
        />
        <AltoSpinningLoader />
      </Row>
    );
  }

  if (!nextAvailableDate?.earliest?.date) return null;

  const earliestNextAvailableDate = nextAvailableDate?.earliest?.date;
  const earliestDateReason = nextAvailableDate?.earliest?.reason;
  const isToday = isDateToday(parse(earliestNextAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0));

  return (
    <ListDescription iconName="calendar-small">
      <Description>
        {`Available ${formatRelativeDate(earliestNextAvailableDate, { abbreviated: true, includeRelative: true })}`}
        {!isToday && (
          <>
            {' '}
            •{' '}
            <Link
              textSize="mini"
              onPress={() => {
                setActiveActionSheet(
                  <NextAvailableDateActionSheet
                    earliestAvailableDate={earliestNextAvailableDate}
                    earliestDateReason={earliestDateReason}
                    prescriptionID={prescription.id}
                    orderTypeContext={orderTypeContext}
                    analyticsOrigin={ORIGIN_NAMES.CART}
                  />,
                );
              }}
            >
              Details
            </Link>
          </>
        )}
      </Description>
    </ListDescription>
  );
};
