// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModal } from '@alto/pocky';
import React from 'react';
import { type ModalType } from '~shared/actions/modal';
import { type Insurance } from '~shared/types';
import { InsuranceFormContainer } from './InsuranceFormContainer';
import { InsurancePhotoForm } from './InsurancePhotoForm';

const UPLOAD_PHOTO_STEP = 1;

type Props = {
  readonly closeModal: () => void;
  readonly currentStep: number;
  readonly modalType?: ModalType | null;
  readonly bannerType?: string | null;
  readonly showBanner?: boolean;
  readonly selectedInsurance?: Insurance;
  readonly show?: boolean;
  readonly success?: boolean | null;
  readonly closeBanner: () => void;
};

export default class InsuranceModal extends React.Component<Props> {
  static defaultProps = {
    bannerType: null,
    currentStep: 0,
    modalType: null,
    selectedInsurance: undefined,
    show: false,
    showBanner: false,
    success: false,
  };

  UNSAFE_componentWillReceiveProps(props: Props) {
    const { closeModal, success } = props;

    const { show, showBanner, closeBanner, bannerType, modalType } = this.props; // we could get these from `newProps`, but eslint otherwise invalidates our prop types.

    if (this.props.success !== success && !!success) {
      if (showBanner && bannerType === 'ADD_INSURANCE') {
        closeBanner();
      }

      if (show && modalType === 'INSURANCE_MODAL') {
        closeModal();
      }
    }
  }

  renderTitle() {
    const { currentStep, selectedInsurance } = this.props;

    let title = '';

    if (currentStep === UPLOAD_PHOTO_STEP) {
      title = 'Add Insurance Card Photos';
    } else {
      let actionString = 'Add';

      if (selectedInsurance && Object.keys(selectedInsurance).length > 0) {
        actionString = 'Update';
      }

      title = `${actionString} Insurance Card`;
    }

    return title;
  }

  renderBody() {
    const { closeModal, currentStep } = this.props;

    let body = <InsuranceFormContainer onCancel={closeModal} />;

    if (currentStep === UPLOAD_PHOTO_STEP) {
      body = <InsurancePhotoForm onCancel={closeModal} />;
    }

    return body;
  }

  render() {
    const { closeModal, modalType, show } = this.props;
    const shown = show && modalType === 'INSURANCE_MODAL';

    return (
      shown && (
        <DeprecatedModal
          showing={shown}
          onClose={closeModal}
          title={this.renderTitle()}
        >
          {this.renderBody()}
        </DeprecatedModal>
      )
    );
  }
}
