// @owners { team: patients-team }
import { ANIMATION, BORDERS, COLORS, type SIZES, SPACING } from '@alto/design-library-tokens';
import { LgSpacing } from '@alto/design-system';
import React, { type ReactNode, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { usePrevious } from '~shared/hooks';
import { Icon, StyledTouchable, View } from '~web/features/essentials/primitives';
import { useDeprecatedMediaQuery } from '~web/hooks';

const StyledList = styled.ul<{ index: number }>`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  transform: ${({ index }) => `translateX(calc(${index} * -100%))`};
  transition: all ${ANIMATION.SPEEDS.FASTER / 1000}s ease-in-out;
`;

const StyledDotList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  & > li:not(:last-child) {
    margin-right: ${SPACING.STATIC.XS.px};
  }
`;

const StyledDot = styled.li<{ active: boolean; isMobile: boolean }>`
  border-radius: ${BORDERS.RADIUS.LG.px};
  padding: ${({ isMobile }) => (isMobile ? SPACING.STATIC.XXS.px : SPACING.STATIC.XS.px)};
  ${StyledTouchable}
  background-color:  ${({ active }) => (active ? COLORS.BACKGROUND_COLORS.PRIMARY : COLORS.PALETTE.GREYSCALE.DEFAULT)};
`;

type ContainerProps = {
  readonly numOfItems: number;
  readonly numOfColumns: number;
  readonly children: ReactNode[];
  readonly iconSize?: keyof typeof SIZES.ICON;
  readonly showDots?: boolean;
  readonly onChange?: (index: number, prevIndex?: number) => void;
};

const Container = ({ numOfItems, numOfColumns, children, iconSize, showDots, onChange }: ContainerProps) => {
  const [index, setIndex] = useState(0);
  const prevIndex = usePrevious(index);
  const isMobile = !useDeprecatedMediaQuery('SM');
  const dots = useMemo(() => Array(numOfItems).fill(0), [numOfItems]);
  const last = (index + 1) * numOfColumns >= numOfItems;

  const handleIncrement = () => {
    setIndex((p) => ((p + 1) * numOfColumns >= numOfItems ? p : p + 1));
  };
  const handleDecrement = () => {
    setIndex((p) => (p * numOfColumns <= 0 ? p : p - 1));
  };
  const handleDotClick = (index: number) => () => {
    setIndex(index);
  };

  useEffect(() => {
    if (onChange) onChange(index, prevIndex);
  }, [index]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View flexDirection="column">
      <View flexDirection="row">
        {index > 0 || showDots ? (
          <View
            onClick={index === 0 ? undefined : handleDecrement}
            alignItems="center"
            padding={SPACING.STATIC.SM.px}
            opacity={index === 0 ? 0 : 1}
          >
            <Icon
              name="chevronleft"
              size={iconSize}
              interactive={index > 0}
              label="view previous products"
            />
          </View>
        ) : null}
        <View
          overflow="hidden"
          display="inline"
          flexGrow={1}
        >
          <StyledList index={index}>{children}</StyledList>
        </View>
        {!last || showDots ? (
          <View
            onClick={last ? undefined : handleIncrement}
            alignItems="center"
            padding={SPACING.STATIC.XS.px}
            opacity={last ? 0 : 1}
          >
            <Icon
              name="chevronright"
              size={iconSize}
              interactive={!last}
              label="view next products"
            />
          </View>
        ) : null}
      </View>
      {showDots ? (
        <>
          {!isMobile && <LgSpacing />}
          <StyledDotList>
            {dots.map((_, idx) => (
              <StyledDot
                key={`dot for ${idx + 1}`}
                active={idx === index}
                onClick={handleDotClick(idx)}
                isMobile={isMobile}
              />
            ))}
          </StyledDotList>
        </>
      ) : null}
    </View>
  );
};

const StyledListItem = styled.li<{ numOfColumns: number }>`
  padding: 0;
  margin: 0;
  flex: ${({ numOfColumns }) => `0 0 calc(100% / ${numOfColumns})`};
  width: ${({ numOfColumns }) => `calc(100% / ${numOfColumns})`};
`;

type ItemProps = {
  readonly numOfColumns: number;
  readonly children: ReactNode;
};

const Item = ({ numOfColumns, children }: ItemProps) => {
  return <StyledListItem numOfColumns={numOfColumns}>{children}</StyledListItem>;
};

export const Carousel = { Container, Item };
