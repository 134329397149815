import React from 'react';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { useScreenSize } from '../../../../../utils';
import { getCssFromOrientation } from '../../helpers';
import { DISTANCE_FROM_TOP_TO_CENTER_OF_INDICATOR, HORIZONTAL_OFFSET, LINE_WIDTH, VERTICAL_OFFSET } from './constants';
import { type RailSegmentProps } from './types';

const horizontalCss = css`
  height: ${LINE_WIDTH}px;
  top: ${HORIZONTAL_OFFSET}px;
  width: 100%;
`;

// Would prefer calc(100% - DISTANCE_FROM_TOP_TO_CENTER_OF_INDICATOR)
// but RN doesn't allow calc(percentage - pixel)
// 95% is enough to prevent overflow into next Component,
// and not too much to create a gap between this Rail, and the next Stations Rail
const verticalCss = css`
  height: 95%;
  width: ${LINE_WIDTH}px;
  top: ${DISTANCE_FROM_TOP_TO_CENTER_OF_INDICATOR}px;
  left: ${VERTICAL_OFFSET}px;
`;

const Rail = styled(View)<RailSegmentProps & { isMDScreenOrBigger: boolean }>`
  position: absolute;
  z-index: -1;
  ${({ color, verticalEnabled, isMDScreenOrBigger }) => css`
    background-color: ${color};
    ${getCssFromOrientation({
      isMDScreenOrBigger,
      verticalEnabled,
      verticalCss,
      horizontalCss,
    })}
  `}
`;

export const AfterRail = ({ verticalEnabled, color }: RailSegmentProps) => {
  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <Rail
      verticalEnabled={verticalEnabled}
      color={color}
      isMDScreenOrBigger={isMDScreenOrBigger}
    />
  );
};
