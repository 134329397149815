import { type Insurance } from '~shared/types';

type InsuranceName = {
  primaryName: string;
  secondaryName: string;
};

export function getInsuranceName(insurance: Insurance): InsuranceName {
  const { bin, company, image_url, image_url_2, is_valid, nickname, insurance_plan_name } = insurance;
  const showImages = !is_valid && (image_url || image_url_2);

  let primaryName = '';
  let secondaryName = '';
  const rxBIN = `RxBIN ${bin || ''}`;

  if (showImages) {
    primaryName = 'Insurance uploaded';
  } else if (nickname && bin) {
    primaryName = nickname;
    secondaryName = rxBIN;
  } else if (insurance_plan_name && bin) {
    primaryName = insurance_plan_name;
    secondaryName = rxBIN;
  } else if (company && bin) {
    primaryName = company;
    secondaryName = rxBIN;
  } else if (bin) {
    primaryName = rxBIN;
  } else {
    primaryName = 'Insurance';
  }

  return {
    primaryName,
    secondaryName,
  };
}
