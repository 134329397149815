// @owners { team: patients-team }
import { DeliveriesOverview, type DeliveriesOverviewProps } from '@alto/features';
import React from 'react';
import { bindActionCreators } from 'redux';
import { updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchPrescriptions } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import getAreShipmentsAvailable from '~shared/features/shipments/selectors/getAreShipmentsAvailable';
import getAreShipmentsStale from '~shared/features/shipments/selectors/getAreShipmentsStale';
import {
  getPastShipmentsWithItemsAndAdditionalFields,
  getUpcomingShipmentsWithItemsAndAdditionalFields,
} from '~shared/features/shipments/selectors/getShipmentsWithAdditionalFields';
import { makeCachedSortByISODateString } from '~shared/helpers/sort';
import { connect } from '~shared/store';
import { type ReduxDispatchShared } from '~shared/types';

type Props = DeliveriesOverviewProps & {
  readonly fetchDeliveries: () => void;
  readonly fetchPrescriptions: () => void;
  readonly fetchShipments: () => void;
};

class DeliveriesListContainer extends React.PureComponent<Props> {
  componentDidMount() {
    /**
     * make explicit attempts to refresh these resources to try to show the
     * most up to date data to the user.
     */
    this.props.fetchDeliveries();
    this.props.fetchPrescriptions();
    this.props.fetchShipments();
  }

  render() {
    return <DeliveriesOverview {...this.props} />;
  }
}

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => {
  const prescriptions = getPrescriptions(state);
  const sortByDate = makeCachedSortByISODateString();

  // Ascending sort - smallest dates (soonest upcoming) first
  const upcomingShipments = getUpcomingShipmentsWithItemsAndAdditionalFields(state).sort((a, b) =>
    sortByDate(a.date, b.date),
  );

  // Descending sort - largest dates (most recent) first
  const pastShipments = getPastShipmentsWithItemsAndAdditionalFields(state).sort((a, b) => sortByDate(b.date, a.date));
  return {
    areShipmentsAvailable: getAreShipmentsAvailable(state),
    areShipmentsStale: getAreShipmentsStale(state),
    hasPrescriptions: prescriptions.length > 0,
    pastShipments,
    upcomingShipments,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    ...bindActionCreators(
      {
        // eslint-disable-next-line import/no-deprecated
        fetchDeliveries,
        // eslint-disable-next-line import/no-deprecated
        fetchPrescriptions,
        // eslint-disable-next-line import/no-deprecated
        fetchShipments,
      },
      dispatch,
    ),
    updateOrder,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveriesListContainer);
