// @owners { team: patients-team }
import { isAfter, parse } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';
import { type NextAvailableDate } from '~shared/types';

// there are available dates if earliest available date is before (or same as) latest available date
// and no available dates if latest available date is before earliest available date or earliest available date is null
export const getNoAvailableDates = (nextAvailableDate: NextAvailableDate | undefined): boolean => {
  if (!nextAvailableDate) return false;

  // for long term backorders, earliest will include oos as a reason, but no earliest available date
  // we should consider this unavailable for scheduling
  if (!nextAvailableDate.earliest?.date) return true;

  const earliest = parse(nextAvailableDate?.earliest?.date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const latest = parse(nextAvailableDate?.lastAvailableDate?.date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  if (!earliest || !latest) return false;

  return isAfter(earliest, latest);
};
