// @owners { team: patients-team }
import { type FilterType } from '@alto/scriptdash/alto/medications/patients/v2/list_endpoint';
import { createSelector } from 'reselect';
import { getUserIDs, getUsersByID } from '~shared/features/users/selectors/getUsers';
import { type ReduxStateShared } from '~shared/store';

/**
 * Helper to get a typed array of the entries of the prescription status filter object.
 */
const filterTypeObjectEntries = <T>(obj: Record<FilterType, T>) => Object.entries(obj || {}) as [FilterType, T][];

const MedListFilterTagContent: Record<FilterType, string> = {
  ALL: 'All medications',
  ACTIVE: 'Active prescriptions',
  INACTIVE: 'No active prescriptions',
};

/**
 * Returns the prescription status filter state, which is a map of the applied filters.
 */
export const getMedListFilters = (state: ReduxStateShared) => state.persistedUI.medList.filters;

/**
 * Returns a comma-separated string of the applied prescription status filters.
 */
export const getAppliedMedListFiltersContent = createSelector([getMedListFilters], (filters) => {
  if (filters.ALL) return MedListFilterTagContent.ALL;

  return filterTypeObjectEntries(filters)
    .map(([key, value]) => {
      if (!value) return null;
      return MedListFilterTagContent[key];
    })
    .filter(Boolean)
    .join(', ');
});

/**
 * Returns an array of the applied prescription status filters,
 * excluding the 'ALL' filter, used to pass to the API.
 */
export const getAppliedMedListFilters = createSelector([getMedListFilters], (prescriptionStatusFilters) => {
  return filterTypeObjectEntries(prescriptionStatusFilters)
    .map(([key, value]) => {
      if (!value) return null;
      return key;
    })
    .filter(Boolean) as FilterType[];
});

export const getFamilyMemberFilters = (state: ReduxStateShared) => state.persistedUI.medList.familyMemberFilters;

export const getIncludedFamilyMembersContent = createSelector(
  [getFamilyMemberFilters, getUsersByID],
  (familyMemberFilters, usersByID) => {
    if (familyMemberFilters.ALL) return 'All family members';

    const familyMembers = Object.entries(familyMemberFilters)
      .map(([key, value]) => {
        if (!value) return null;
        const patient = usersByID[Number(key)];
        if (!patient?.first_name) return null;
        return patient.first_name.endsWith('s') ? `${patient.first_name}'` : `${patient.first_name}'s`;
      })
      .filter(Boolean)
      .join(', ');

    return `${familyMembers} medications`;
  },
);

/**
 * Returns an array of the applied family member IDs,
 * excluding the 'ALL' filter, used to pass to the API.
 */
export const getIncludedFamilyMemberPatientIDs = createSelector(
  [getUserIDs, getFamilyMemberFilters],
  (userIDs, familyMemberFilters) => {
    if (familyMemberFilters.ALL) return userIDs;

    return Object.entries(familyMemberFilters)
      .map(([key, value]) => {
        if (!value || key === 'ALL') return null;
        return Number(key);
      })
      .filter(Boolean) as number[];
  },
);
