// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoIcon,
  Body,
  Card,
  Column,
  H1,
  H3,
  LgPadding,
  ListBase,
  type ListBaseItem,
  MdPadding,
  PresentationListItem,
  SecondaryPage,
  SmSpacing,
  XlSpacing,
  XxsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { format, parse } from 'date-fns';
import React, { useEffect } from 'react';
import alertIssue from '~shared/assets/images/alert_issue.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { DATE_FORMATS } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { VacationOverrideForm } from './VacationOverrideForm';

const Section = ({ title, children }: { readonly title: string; readonly children: React.ReactNode }) => {
  return (
    <Column key={title}>
      <H3>{title}</H3>
      <XxsSpacing />

      {children}
    </Column>
  );
};

const InsuranceSection = ({ title }: { readonly title: string }) => (
  <Section title={title}>
    <Body>Call the number on the back of your insurance card.</Body>
  </Section>
);

const PrescriptionSection = ({
  title,
  rxNumber,
  quantity,
}: {
  readonly title?: string | null;
  readonly rxNumber?: string | null;
  readonly quantity: string;
}) => (
  <Section title={title ?? 'Prescription'}>
    <PresentationListItem
      LeftContent={<AltoIcon name="rxchart-small" />}
      text={`Rx ${rxNumber}`}
      fullBleed
    />
    <XxsSpacing />
    <PresentationListItem
      LeftContent={<AltoIcon name="pillbottle-small" />}
      text={quantity}
      fullBleed
    />
  </Section>
);

export const VacationOverrideFailure = () => {
  const { getParam, goBack } = useNavigation<'RouteVacationRequestFailure'>();
  const { trackPageView } = useAnalytics();
  const prescriptionID = getParam('prescriptionID', 0);
  const vacationSupply = getParam('vacationSupply', {
    travel_destination: '',
    travel_start_date: '',
    travel_end_date: '',
    insurance_name: '',
    approved_at: '',
    prescription: {
      rx_number: '',
      medication_name: '',
      quantity_with_unit: '',
    },
  });

  useEffect(() => {
    trackPageView({
      event: EVENTS.VACATION_SUPPLY_AUTOBILL_FAIL_FORM_VIEWED,
      params: {},
      additionalFields: {
        prescriptionId: prescriptionID,
      },
    });
  }, [prescriptionID, trackPageView]);

  const {
    insurance_name,
    prescription: { medication_name, rx_number, quantity_with_unit },
    travel_destination,
    travel_start_date,
    travel_end_date,
  } = vacationSupply;

  const startDate = format(
    parse(travel_start_date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0),
    DATE_FORMATS.MONTH_DAY_YEAR_SLASHED,
  );
  const endDate = format(
    parse(travel_end_date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0),
    DATE_FORMATS.MONTH_DAY_YEAR_SLASHED,
  );

  const handleDismiss = () => {
    goBack();
  };

  const contents = [
    <Body key="description">
      To get your medication you will need to contact your insurance yourself and get them to provide the override.
    </Body>,
    <InsuranceSection
      key="title"
      title={insurance_name}
    />,
    <PrescriptionSection
      key={medication_name}
      title={medication_name}
      rxNumber={rx_number}
      quantity={quantity_with_unit}
    />,
    <VacationOverrideForm
      buttonLabel="Re-submit vacation supply"
      description="Once you have received confirmation from your insurance we can process it and deliver your medication."
      initialValues={{
        travelDestination: travel_destination,
        startDate,
        returnDate: endDate,
      }}
      key="form"
      prescriptionID={prescriptionID}
      onClose={handleDismiss}
    />,
  ].map((content) => ({ key: content.key, component: content })) as ListBaseItem[];

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST}
      HeaderContent={
        <MdPadding>
          <H1 center>Unable to confirm vacation supply with your insurance</H1>
          <SmSpacing />
        </MdPadding>
      }
      onDismiss={handleDismiss}
    >
      <MdPadding topPadding={SPACING.STATIC.NONE}>
        <Card
          illustrationSrc={alertIssue}
          illustrationAccessibilityLabel={IMG_ALT_TEXTS.alertIssue}
          title="Next steps"
        >
          <LgPadding topPadding={SPACING.STATIC.NONE}>
            <ListBase
              items={contents}
              separator={<XlSpacing />}
            />
          </LgPadding>
        </Card>
      </MdPadding>
    </SecondaryPage>
  );
};
