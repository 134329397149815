import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { Text, type TextProps } from './Text';

export type DescriptionProps = Pick<
  TextProps,
  'center' | 'children' | 'color' | 'textDecorationLine' | 'fontFamily' | 'testID'
>;

export const Description = ({
  center,
  children,
  color = COLORS.TEXT_COLORS.GREY,
  fontFamily,
  textDecorationLine,
  testID,
}: DescriptionProps) => {
  return (
    <Text
      textDecorationLine={textDecorationLine}
      center={center}
      color={color}
      textSize="mini"
      fontFamily={fontFamily}
      testID={testID}
    >
      {children}
    </Text>
  );
};
