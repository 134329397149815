import { LOGIN_SUCCEEDED, LOGOUT_SUCCEEDED } from '~shared/actions/auth';
import { INIT } from '~shared/constants';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';

const APP_OPENED_ACTIONS = [LOGIN_SUCCEEDED, LOGOUT_SUCCEEDED, 'SET_PRESENCE', INIT];

export default function getAppOpened(action: any, state: any): Event | null | undefined {
  if (!APP_OPENED_ACTIONS.includes(action.type)) return undefined;

  let loggedIn = false;

  if (action.type === INIT) {
    loggedIn = !!state.auth.loggedInAt;
  }

  if (action.type === 'SET_PRESENCE') {
    if (action.payload.appState !== 'active') {
      return undefined;
    }

    loggedIn = !!state.auth.loggedInAt;
  }

  if (action.type === LOGIN_SUCCEEDED) {
    loggedIn = true;
  }

  return createEvent(EVENTS.APP_OPENED, {
    'logged in': loggedIn,
  });
}
