// @owners { team: patients-team }
import { type Dispatch } from 'redux';
import { type ApiOptions } from '~shared/helpers/api';
import { get } from '~shared/helpers/apiHelper';
import { type QuantityOption } from '~shared/types/clients';

const V3_API_VERSION = 'v3';
export const v3Options: ApiOptions = {
  version: V3_API_VERSION,
};

export const FETCH_QUANTITY_OPTIONS_LOADING = 'FETCH_QUANTITY_OPTIONS_LOADING';
type FetchingQuantityOptions = {
  type: typeof FETCH_QUANTITY_OPTIONS_LOADING;
};
export function fetchingQuantityOptions(): FetchingQuantityOptions {
  return {
    type: FETCH_QUANTITY_OPTIONS_LOADING,
  };
}

export const FETCH_QUANTITY_OPTIONS_SUCCEEDED = 'FETCH_QUANTITY_OPTIONS_SUCCEEDED';
export type FetchQuantityOptionsSucceeded = {
  type: typeof FETCH_QUANTITY_OPTIONS_SUCCEEDED;
  payload: {
    prescriptionID: number;
    options: QuantityOption[];
  };
};

function fetchQuantityOptionsSucceeded(
  prescriptionID: number,
  options: QuantityOption[],
): FetchQuantityOptionsSucceeded {
  return {
    type: FETCH_QUANTITY_OPTIONS_SUCCEEDED,
    payload: {
      prescriptionID,
      options,
    },
  };
}

export const FETCH_QUANTITY_OPTIONS_FAILED = 'FETCH_QUANTITY_OPTIONS_FAILED';
export type FetchQuantityOptionsFailed = {
  type: typeof FETCH_QUANTITY_OPTIONS_FAILED;
  payload: {
    prescriptionID: number;
  };
};

function fetchQuantityOptionsFailed(prescriptionID: number): FetchQuantityOptionsFailed {
  return {
    type: FETCH_QUANTITY_OPTIONS_FAILED,
    payload: {
      prescriptionID,
    },
  };
}

export type QuantityOptionsAction =
  | FetchingQuantityOptions
  | FetchQuantityOptionsSucceeded
  | FetchQuantityOptionsFailed;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export default function fetchQuantityOptions(prescriptionID: number) {
  return async (dispatch: Dispatch<QuantityOptionsAction>) => {
    dispatch(fetchingQuantityOptions());
    return get(`/prescriptions/${prescriptionID}/quantity_options`, {}, v3Options).then((json) => {
      if (json.error) {
        dispatch(fetchQuantityOptionsFailed(prescriptionID));
        return false;
      }

      dispatch(fetchQuantityOptionsSucceeded(prescriptionID, json.data));
      return true;
    });
  };
}
