import { PRESCRIPTION_STATES } from '~shared/constants';
import { type Prescription } from '~shared/types/clients';

export const isActiveRx = ({ active_state }: Prescription): boolean => {
  return [PRESCRIPTION_STATES.ACTIVE, PRESCRIPTION_STATES.ACTIVE_RENEWED, PRESCRIPTION_STATES.TOO_EARLY].includes(
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    active_state,
  );
};

export const isMenopur = ({
  gcnSeqno,
  medicationName,
}: {
  gcnSeqno: string | null | undefined;
  medicationName: string | null | undefined;
}) => {
  // we check for two gcnSeqno because a migration exists to remove the leading 0 in production,
  // however test seeds still use a gcn with a leading 0 so check for both
  if (gcnSeqno) {
    return ['58907', '058907'].includes(gcnSeqno);
  }
  if (!medicationName) {
    return false;
  }

  return medicationName.toLowerCase().includes('menopur') ?? false;
};

export const medNameIsMenopur = (medicationName: string | null | undefined) => {
  if (!medicationName) {
    return false;
  }
  return medicationName.toLowerCase().includes('menopur');
};

export const isMounjaro = ({ gcn_seqno }: { gcn_seqno?: string | null }) => {
  if (!gcn_seqno) {
    return false;
  }
  return ['083388', '083389', '083390', '083391', '083392', '083393'].includes(gcn_seqno);
};
