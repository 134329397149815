// eslint-disable-next-line @alto/no-pocky-import
import { H5, Text } from '@alto/pocky';
import React, { PureComponent } from 'react';

export default class ACHDescription extends PureComponent {
  render() {
    return (
      <div>
        <H5>Save 1&#37;</H5>
        <Text>Link your bank account to automatically save 1&#37; off every order that is paid without insurance.</Text>
      </div>
    );
  }
}
