// @owners { team: patients-team }

export const getOnboardingInsuranceInputTitle = ({
  hasInsurance,
  showProgyny,
}: {
  hasInsurance: boolean;
  showProgyny: boolean;
}) => {
  if (hasInsurance) {
    return 'Add another benefit';
  }

  return showProgyny ? 'Along with your Progyny benefits, do you have additional insurance?' : 'Add more benefits';
};

export const getOnboardingInsuranceInputSubtitle = ({ showProgyny }: { showProgyny: boolean }) => {
  return showProgyny
    ? 'This helps us find you a great price on medications not covered by Progyny.'
    : 'Have additional insurance or prescription savings cards? Add them all so we can get you the best pricing.';
};

export const getSentenceCaseDosageForm = (dosageForm?: string | null) => {
  if (!dosageForm) return '';
  return dosageForm
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
