import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components/native';
import { useAccessibilityFocus } from '../../../hooks/useAccessibilityFocus';
import { Text, type TextProps } from './Text';

export type H2Props = Pick<TextProps, 'center' | 'children' | 'color' | 'contain' | 'accessibilityLabel'> & {
  readonly autofocus?: boolean;
};

const StyledH2 = styled(Text)<H2Props>`
  font-size: ${TYPOGRAPHY.TEXT.HEADER.SM.px};
  line-height: ${TYPOGRAPHY.TEXT.HEADER.SM.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
`;

export const H2 = ({
  accessibilityLabel,
  center,
  children,
  contain,
  color = COLORS.TEXT_COLORS.PRIMARY,
  autofocus,
}: H2Props) => {
  const ref = useAccessibilityFocus(autofocus);

  return (
    <StyledH2
      accessible={autofocus}
      accessibilityLabel={accessibilityLabel}
      role="heading"
      aria-level={2}
      center={center}
      color={color}
      contain={contain}
      focusable
      ref={ref}
      fontFamily="semibold"
    >
      {children}
    </StyledH2>
  );
};
