import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchAppDownloadLink } from '~shared/actions/users';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
import { connect } from '~shared/store';
import { type ReduxDispatchShared } from '~shared/types';
import GetTheAppDrawer from './GetTheAppDrawer';

type OwnProps = {
  onClose: () => void;
  tempPassword?: string;
};

type MergedProps = {
  readonly onClose: () => void;
  readonly getAppDownloadUrl: () => Promise<string>;
  readonly dispatchSendAnalyticsEvent: typeof sendAnalyticsEvent;
};

const GetTheAppDrawerContainer = ({ getAppDownloadUrl, dispatchSendAnalyticsEvent, onClose }: MergedProps) => {
  const [appDownloadUrl, setAppDownloadUrl] = useState(null);
  const [appDownloadClicked, setAppDownloadClicked] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    getAppDownloadUrl().then((url) => {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
      setAppDownloadUrl(url);

      // Open the link immediately if the user wanted to open the link but it wasn't available yet
      // eslint-disable-next-line promise/always-return
      if (appDownloadClicked) {
        window.location.assign(url);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAppDownloadUrl]);

  const handleAppSelected = () => {
    dispatchSendAnalyticsEvent(createEvent(EVENTS.UPSELL_GET_THE_APP_TAPPED));
    onClose();

    if (appDownloadUrl) {
      window.location.assign(appDownloadUrl);
    } else {
      setAppDownloadClicked(true);
    }
  };

  const handleBrowserSelected = () => {
    dispatchSendAnalyticsEvent(createEvent(EVENTS.UPSELL_CONTINUE_WITH_BROWSER));
    onClose();
  };

  return (
    <GetTheAppDrawer
      onClose={onClose}
      handleAppSelected={handleAppSelected}
      handleBrowserSelected={handleBrowserSelected}
    />
  );
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    // eslint-disable-next-line import/no-deprecated
    dispatchAppDownloadLink: (tempPassword: string) => dispatch(fetchAppDownloadLink(tempPassword)),
    dispatchSendAnalyticsEvent: (event: Event) => {
      dispatch(sendAnalyticsEvent(event));
    },
  };
};

// @ts-expect-error TS(7006): Parameter 'stateProps' implicitly has an 'any' type.
const mergeProps = (stateProps, dispatchProps, ownProps: OwnProps): MergedProps => {
  const { tempPassword, onClose } = ownProps;
  const { dispatchAppDownloadLink, ...otherDispatchProps } = dispatchProps;
  return { ...otherDispatchProps, onClose, getAppDownloadUrl: () => dispatchAppDownloadLink(tempPassword) };
};

export default connect(null, mapDispatchToProps, mergeProps)(GetTheAppDrawerContainer);
