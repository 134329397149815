// @owners { team: patients-team }
import { type AddOnOtcTagType } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc_tag_type';
import { useMemo } from 'react';

export const useEssentialsPreviouslyPurchased = () => {
  return useMemo(
    () => ({
      getIconName: (tagName: AddOnOtcTagType | null | undefined) => {
        switch (tagName) {
          case 'BUY_AGAIN_TAG':
            return { name: 'Buy Again', icon: 'sync-small' };
          case 'TOP_SELLER_TAG':
            return { name: 'Top Seller', icon: 'star-small' };
          default:
            return { name: '', icon: 'close-small' };
        }
      },
    }),
    [],
  );
};
