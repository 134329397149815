import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoIcon,
  type AltoIconProps,
  Card,
  Column,
  LgPadding,
  ListButton,
  MdSpacing,
  Tag,
  useScreenSize,
} from '@alto/design-system';
import {
  CalendarRow,
  FulfillmentMethodRow,
  LocationRow,
  MedRow,
  PriceRow,
  useGetDeliveryMethodsByShipmentIDs,
} from '@alto/features';
import { type DeliveredShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/delivered_shipment';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { getDeliveredOrderLabel } from '~shared/features/homescreen/utils';
import { getAccessibilityLabel, getIllustration, isPickup } from '~shared/helpers/order';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';
import { type ShipmentStatus } from '~shared/types';
import { EnlargeableImage } from '~web/components/image/EnlargeableImage';
import { ImageMask, type ImageMaskProps } from '~web/components/image/ImageMask';

const CustomCard = styledNative(Card)<{ zIndex?: string; isSMScreenOrBigger: boolean }>`
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : '')}
  margin: 0 0 ${SPACING.STATIC.XL.px};

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `margin: 0 0 ${SPACING.STATIC.LG.value * 2}px;`}
`;

const CardContent = styled.div<{ isSMScreenOrBigger: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  color: ${COLORS.TEXT_COLORS.GREY};

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `flex-direction: row;`}
`;

const DeliveryImage = styled(EnlargeableImage)<{ isSMScreenOrBigger: boolean }>`
  width: 100%;
  height: 172px;
  overflow: hidden;
  position: relative;
  margin: ${SPACING.STATIC.LG.px} 0 0;
  border-radius: ${BORDERS.RADIUS.LG.px};
  cursor: zoom-in;

  ${({ isSMScreenOrBigger }) =>
    isSMScreenOrBigger &&
    `
      width: 172px;
      margin: 0 ${SPACING.STATIC.LG.px} 0 0;
      border-radius: ${BORDERS.RADIUS.MD.px};
    `}
`;

const DeliveryImageMask = styled(ImageMask).attrs<unknown, ImageMaskProps>({
  opacity: 0.5,
  opacityHover: 0.33,
})<{ isSMScreenOrBigger: boolean }>`
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `display: none;`}
`;

const DeliveryImageIcon = styledNative(AltoIcon).attrs<unknown, AltoIconProps>({
  name: 'search',
  accessibilityLabel: 'Search icon',
  type: 'white',
})<{ isSMScreenOrBigger: boolean }>`
  z-index: 1;
  right: ${SPACING.STATIC.MD.px};
  bottom: ${SPACING.STATIC.MD.px};
  position: absolute;
  color: ${COLORS.TEXT_COLORS.WHITE};
  pointer-events: none;

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `display: none;`}
`;

type Props = {
  readonly delivery: DeliveredShipment;
  readonly onViewDelivery: (id: number) => void;
};

export const DeliveredShipmentSection = ({ delivery, onViewDelivery }: Props) => {
  // Track open state to toggle z-index
  const [imageIsOpen, setImageIsOpen] = React.useState<boolean>(false);

  // Context for image modal
  const dispatch = useDispatchShared();
  const { address, delivered_at, medication_names, id, image_url, price } = delivery;
  const { isSMScreenOrBigger } = useScreenSize();

  // arguments passed to get illustration
  const { deliveryMethodsByShipmentID } = useGetDeliveryMethodsByShipmentIDs([id]);
  const deliveryMethod = deliveryMethodsByShipmentID[id];
  const illustrationArgs = {
    method: deliveryMethod,
    status: 'delivered' as ShipmentStatus,
  };

  return (
    <CustomCard
      title={getDeliveredOrderLabel({ isPickup: isPickup(deliveryMethod?.mode) })}
      illustrationSrc={getIllustration(illustrationArgs)}
      illustrationAccessibilityLabel={getAccessibilityLabel(illustrationArgs)}
      zIndex={imageIsOpen ? undefined : '1'}
      isSMScreenOrBigger={isSMScreenOrBigger}
      tag={
        <Tag
          label={isPickup(deliveryMethod?.mode) ? 'Picked up' : 'Delivered'}
          type="recommended"
        />
      }
    >
      <LgPadding topPadding={SPACING.STATIC.MD}>
        <CardContent isSMScreenOrBigger={isSMScreenOrBigger}>
          {!!image_url && (
            <DeliveryImage
              src={image_url}
              alt="Delivery location"
              onOpen={() => {
                setImageIsOpen(true);
                dispatch(sendAnalyticsEvent(createEvent(EVENTS.SHIPMENT_IMAGE_VIEWED, { origin: 'homescreen' })));
              }}
              onClose={() => {
                setImageIsOpen(false);
              }}
              isSMScreenOrBigger={isSMScreenOrBigger}
            >
              <DeliveryImageMask isSMScreenOrBigger={isSMScreenOrBigger} />
              <DeliveryImageIcon isSMScreenOrBigger={isSMScreenOrBigger} />
            </DeliveryImage>
          )}
          <Column
            flexGrow={1}
            flexShrink={1}
            flexBasis="0%"
          >
            <CalendarRow
              date={delivered_at}
              status={'delivered'}
            />
            <LocationRow address={address} />
            <FulfillmentMethodRow
              method={deliveryMethod}
              status={'delivered'}
            />
            <MedRow medicationNames={medication_names} />
            <PriceRow price={price} />
          </Column>
        </CardContent>
        <MdSpacing />
        <ListButton
          label={isSMScreenOrBigger ? 'View order details' : 'View order'}
          onPress={() => {
            onViewDelivery(delivery.id);
          }}
          rightIcon="chevronright"
        />
      </LgPadding>
    </CustomCard>
  );
};
