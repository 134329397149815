// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoSpinningLoader,
  Button,
  ButtonGroup,
  LgPadding,
  MdSpacing,
  SecondaryPage,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { type Plan } from '@alto/scriptdash/alto/med_sync/types/patients/v1/plan';
import React, { useEffect } from 'react';
import { goBack, push } from 'react-router-redux';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
// eslint-disable-next-line import/no-deprecated
import { useConfirmPlan } from '~shared/queries/useConfirmPlan';
// eslint-disable-next-line import/no-deprecated
import { useFetchLatestMedSyncPlan } from '~shared/queries/useFetchLatestMedSyncPlan';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { MED_SYNC_SELF_SERVICE_ROUTES } from '~web/features/med-sync-self-service/RoutingContainer';
import { MedSyncSelfServicePlanDetails } from '~web/features/med-sync-self-service/components/PlanDetails';
import { MedSyncSelfServicePlanSummaryCard } from '~web/features/med-sync-self-service/components/PlanSummaryCard';
import { MedSyncSelfServiceProgressHeader } from '~web/features/med-sync-self-service/components/ProgressHeader';

export const MedSyncSelfServiceReviewPage = () => {
  const dispatch = useDispatchShared();
  const { addToast } = React.useContext(ToastContext);

  const currentUserID = useSelectorShared(getCurrentUserID);
  // eslint-disable-next-line import/no-deprecated
  const { data, isPending: isFetchPlanLoading, isError: isFetchPlanError } = useFetchLatestMedSyncPlan(currentUserID);
  const {
    mutate: confirmPlan,
    isPending: isConfirmPlanLoading,
    isError: isConfirmPlanError,
    isSuccess: isConfirmPlanSuccess,
    // eslint-disable-next-line import/no-deprecated
  } = useConfirmPlan();

  const plan: Plan | null | undefined = data?.data;

  useEffect(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_PLAN_REVIEW_VIEWED, {})));
  }, [dispatch]);

  const isFetchError = !isFetchPlanLoading && (isFetchPlanError || !plan || plan.proposed_shipments.length <= 0);
  useEffect(() => {
    if (isFetchError) {
      addToast(
        <Toast variant="error">
          Something went wrong signing you up for bundling. Please report this as a bug if the problem persists.
        </Toast>,
      );
      dispatch(push('/home'));
    }
  }, [addToast, dispatch, isFetchError]);

  useEffect(() => {
    if (isConfirmPlanError) {
      addToast(
        <Toast variant="error">
          Something went wrong with confirming the plan. Please report this as a bug if the problem persists.
        </Toast>,
      );
    }
  }, [addToast, isConfirmPlanError]);

  useEffect(() => {
    if (isConfirmPlanSuccess) {
      dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.CONFIRMED));
    }
  }, [dispatch, isConfirmPlanSuccess]);

  const handleCancel = () => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_PLAN_REVIEW_DISMISS_CLICKED, {})));
    dispatch(push('/home'));
  };

  const handleConfirm = () => {
    if (plan) {
      dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_PLAN_REVIEW_CONFIRM_CLICKED, {})));
      confirmPlan({ id: plan.id });
    }
  };

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      onDismiss={() => {
        dispatch(goBack());
      }}
      dismissIcon="chevronleft"
      HeaderContent={
        <MedSyncSelfServiceProgressHeader
          currentStep={3}
          title="Review and confirm your customized plan"
        />
      }
    >
      {isFetchPlanLoading || !plan ? (
        <AltoSpinningLoader />
      ) : (
        <>
          <MedSyncSelfServicePlanSummaryCard
            proposedShipment={plan.proposed_shipments[plan.proposed_shipments.length - 1]}
            omittedPrescriptions={plan.omitted_prescriptions}
          />
          <XsSpacing />
          {plan.proposed_shipments.length > 1 ? (
            <MedSyncSelfServicePlanDetails
              proposedShipments={plan.proposed_shipments}
              finalShipmentDaysSupply={plan.days_supply}
            />
          ) : (
            <MdSpacing />
          )}
          <LgPadding
            topPadding={SPACING.STATIC.NONE}
            bottomPadding={SPACING.STATIC.NONE}
          >
            <ButtonGroup
              buttons={[
                <Button
                  onPress={handleCancel}
                  label="Not now"
                  key="Not now"
                  type="tertiary"
                  loading={isFetchPlanLoading || isConfirmPlanLoading}
                />,
                <Button
                  onPress={handleConfirm}
                  label="Confirm plan"
                  key="Confirm plan"
                  loading={isFetchPlanLoading || isConfirmPlanLoading}
                />,
              ]}
            />
          </LgPadding>
        </>
      )}
    </SecondaryPage>
  );
};
