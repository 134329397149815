// @owners { team: patients-team }
import { InputText } from '@alto/design-system';
import React, { type MutableRefObject, useCallback, useState } from 'react';
import { type TextInput } from 'react-native';
import { PixelRatio } from 'react-native';
import styled from 'styled-components/native';
import { CUSTOM_TIP_PLACEHOLDER, MAX_TIP_AMOUNT_LENGTH } from '~shared/features/courier-tips/constants';
import { formatDollars, formatInputAsCurrency } from '~shared/helpers/currency';

function scaleWithFontSize(pixels: number): number {
  return pixels * PixelRatio.getFontScale();
}

export const TEST_ID = 'CustomAmountInput';

type CustomAmountInputViewProps = {
  centered?: boolean | null | undefined;
};

// todo no need to handle pixel ratio for web
const CustomAmountInputView = styled.View<CustomAmountInputViewProps>`
  align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
`;

const CustomAmountInputSizingView = styled.View`
  min-height: ${scaleWithFontSize(37)}px; // Fixes iOS height bug on render
  width: ${scaleWithFontSize(104)}px;
`;

type Props = {
  readonly centered?: boolean | null;
  readonly editTipAmount: (value: number) => void;
  readonly initialTipAmount: number;
  readonly inputRef: MutableRefObject<TextInput | null>;
  readonly disabled?: boolean;
};

export const CustomAmountInput = React.memo(function CustomAmountInputMemo({
  centered,
  editTipAmount,
  initialTipAmount,
  inputRef,
  disabled,
}: Props) {
  let initialDisplayValue = '';

  if (initialTipAmount > 0) {
    initialDisplayValue = formatDollars(initialTipAmount, false);
  }

  const [displayValue, setDisplayValue] = useState(initialDisplayValue);

  const onChangeText = useCallback(
    (inputValue: string) => {
      const currencyValue = formatInputAsCurrency(inputValue);
      const currencyValueAsNumber = Number(currencyValue) || 0;

      setDisplayValue(currencyValue);

      editTipAmount(currencyValueAsNumber);
    },
    [editTipAmount, setDisplayValue],
  );

  return (
    <CustomAmountInputView centered={centered}>
      <CustomAmountInputSizingView>
        <InputText
          disabled={disabled}
          accessibilityLabel="Input a custom tip amount in USD"
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect={false}
          keyboardType="decimal-pad"
          maxLength={MAX_TIP_AMOUNT_LENGTH}
          onChangeText={onChangeText}
          placeholder={CUSTOM_TIP_PLACEHOLDER}
          onRef={inputRef}
          returnKeyType="done"
          testID={TEST_ID}
          textContentType="none"
          value={displayValue}
        />
      </CustomAmountInputSizingView>
    </CustomAmountInputView>
  );
});
