import ChannelManager from './ChannelManager';
import Connection from './Connection';
import ErrorChannel from './ErrorChannel';
import { API_WEBSOCKET_URL } from '~shared/config';
import type { Callback, Errback, Unsub } from '~shared/types/websocket';

const MESSAGE_TYPE_SUBSCRIBE = 'SUBSCRIBE';
const MESSAGE_TYPE_ACK = 'ACKNOWLEDGEMENT';
const MESSAGE_TYPE_MESSAGE = 'MESSAGE';
/*
 * Socket provides an interface for subscribing to websocket channels.
 *
 * This module provides a default export which is a singleton instance of
 * the Socket class. There should be exactly one open websocket connection
 * for any number of subscribers.
 *
 * Example usage:
 *
 *   import socket from '~shared/websocket/socket';
 *
 *   ...
 *
 *   useEffect(() => {
 *     const wundercomChannelNames = [`users:${email}:wundercom`];
 *       const unsubscriberHandlers: Unsub[] = [];
 *
 *       if (payload.event_type === 'create') {
 *         // logic here to handle payload
 *       }
 *
 *      unsubscriberHandlers.push(unsub);
 *    });
 *
 *    const errorUnsub = socket.bindError(() => {
 *      setError(SOCKET_CONNECTION_ERROR);
 *    });
 *    unsubscriberHandlers.push(errorUnsub);
 *
 *   // cleanup subscriptions on unmount
 *   return () => {
 *    unsubscriberHandlers.forEach((unsub) => unsub());
 *   };
 *  }, []);
 */

class Socket {
  ws: Connection;
  channels: ChannelManager;
  errorChannel: ErrorChannel;

  constructor() {
    this.channels = new ChannelManager();
    this.errorChannel = new ErrorChannel();
    this.ws = new Connection({
      endpoint: this.endpoint(),
      onOpen: this.onOpen,
      onError: this.onError,
      onMessage: this.onMessage,
      onClose: this.onClose,
    });
  }

  bind(channelName: string, subscriber: Callback): Unsub {
    this.ws.connect(); // defer connection until something tries to subscribe

    const unsub = this.channels.subscribe(channelName, subscriber);
    this.checkSubscriptions();
    return unsub;
  }

  bindError(subscriber: Errback): Unsub {
    return this.errorChannel.subscribe(subscriber);
  }
  onOpen = (): void => {
    this.checkSubscriptions();
  };
  onError = (e: Event): void => {
    this.errorChannel.publish(e);
  };
  onClose = (): void => {
    this.channels.onDisconnect();
  };

  onMessage = (e: any): void => {
    const result = this.parseRawMessage(e);

    if (!result) {
      // typically an empty "keep-alive" message.
      return;
    }

    const { type, channel, payload } = result;

    if (type === MESSAGE_TYPE_ACK) {
      this.ws.handleAck();
      return;
    }

    if (type === MESSAGE_TYPE_MESSAGE && payload && typeof payload === 'string') {
      this.channels.publish(channel, JSON.parse(payload));
    }
  };

  checkSubscriptions(): void {
    if (!this.ws.isReady()) {
      return;
    }

    this.channels.disconnectedChannels().forEach((c) => {
      this.sendSubscriptionRequest(c.name);
    });

    this.channels.onConnect();
  }

  sendSubscriptionRequest(channelName: string): void {
    this.ws.send({
      type: MESSAGE_TYPE_SUBSCRIBE,
      channel: channelName,
    });
  }

  parseRawMessage(e: any): Record<string, any> | null | undefined {
    if (!e.data || typeof e.data !== 'string') {
      return null;
    }

    const { result } = JSON.parse(e.data);

    if (!result) {
      return null;
    }

    return result;
  }
  endpoint() {
    return API_WEBSOCKET_URL;
  }
} // There should only ever be this one instance of `Socket`.

export default new Socket();
