// @owners { team: patients-team }
import { TYPOGRAPHY } from '@alto/design-library-tokens';
import { PressableAltoIcon, Row, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Link } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-deprecated
import fetchQuantityOptions from '~shared/actions/quantityOptions';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';

type Props = {
  readonly prescriptionID: number;
  readonly origin: (typeof ORIGIN_NAMES)[keyof typeof ORIGIN_NAMES];
  readonly showQtyModal: () => void;
};

const StyledLink = styled(Link)`
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM.px};
`;

export const ChangeQuantityLink = ({ prescriptionID, origin, showQtyModal }: Props) => {
  const dispatch = useDispatchShared();

  const showChangeQuantity = () => {
    const event = createEvent(
      EVENTS.QUANTITY_CHANGE_INITIATED,
      {
        origin,
      },
      { prescriptionId: prescriptionID },
    );

    dispatch(sendAnalyticsEvent(event));
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchQuantityOptions(prescriptionID));

    showQtyModal();
  };

  return (
    <Row centerVertically>
      <StyledLink onClick={showChangeQuantity}>Change quantity</StyledLink>
      <XsSpacing />
      <PressableAltoIcon
        onPress={showChangeQuantity}
        name="edit-small"
        type="secondary"
        accessibilityLabel="Press to change quantity"
      />
    </Row>
  );
};
