// @owners { team: patients-team }
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  ListDescription,
} from '@alto/design-system';
import { NextAvailableDateActionSheet } from '@alto/features';
import { Sentry } from '@alto/performance';
import { type MultipleActiveRxPrescription } from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import React, { useContext, useState } from 'react';
import RX from '~shared/assets/images/RX.png';
import { getDoctorName } from '~shared/features/my-meds/helper';
import { getEarliestAvailableReasonForPrescription__DEPRECATED } from '~shared/features/next-available-date/selectors/getNextAvailableDateForPrescription';
import { useAvailabilityDataByPrescriptionID } from '~shared/features/prescriptions/hooks/useAvailabilityDataByPrescriptionID';
import { type AvailabilitySortData } from '~shared/features/prescriptions/selectors/getAvailabilityDataByPrescriptionID';
import { formatRelativeDate, formatRelativeDateWithDow } from '~shared/helpers/date';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly hasSheetsLeft: boolean;
  readonly onPrescriptionSelect: (p: MultipleActiveRxPrescription) => void;
  readonly prescriptions: MultipleActiveRxPrescription[];
};

export const MultipleRxSelectionActionSheet = ({ hasSheetsLeft, onPrescriptionSelect, prescriptions }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const [currentSelectedPrescription, setCurrentSelectedPrescription] = useState<MultipleActiveRxPrescription>();
  const availabilityData = useAvailabilityDataByPrescriptionID();
  const earliestAvailableReason = useSelectorShared((state) =>
    getEarliestAvailableReasonForPrescription__DEPRECATED(state, currentSelectedPrescription?.id),
  );
  const handleSelectPrescription = (prescriptionID: string) => {
    const selectedPrescription = prescriptions.find((prescription) => prescription.id === parseInt(prescriptionID, 10));
    setCurrentSelectedPrescription(selectedPrescription);
  };
  const openNextAvailableDateActionSheet = ({ prescriptionID }: { prescriptionID: number }) => {
    const availabilityForPrescription: AvailabilitySortData | undefined = availabilityData[prescriptionID];
    const earliestAvailableDate = availabilityForPrescription.earliestAvailableDate ?? null;
    setActiveActionSheet(
      <NextAvailableDateActionSheet
        earliestAvailableDate={earliestAvailableDate}
        earliestDateReason={earliestAvailableReason}
        prescriptionID={prescriptionID}
        orderTypeContext="Add to cart"
        analyticsOrigin={ORIGIN_NAMES.MULTIPLE_ACTIVE_RX_SAME_AVAILABILITY_DATE}
      />,
    );
  };

  return (
    <ActionSheetV2
      illustrationSrc={RX}
      title="Select which prescription to add"
      description="You have multiple prescriptions for this medication"
      analyticsName="multiple active rx"
    >
      <InputRadioGroup onValueChange={handleSelectPrescription}>
        {prescriptions?.map((prescription) => {
          const earliestAvailableDate = availabilityData[prescription.id].earliestAvailableDate;

          if (!earliestAvailableDate) {
            Sentry.captureMessage('cannot locate earliestAvailableDate', {
              level: 'info',
              extra: {
                availabilityData,
                prescriptionId: prescription.id,
                error: 'https://alto-pharmacy.sentry.io/issues/4609709056',
              },
            });
          }

          return (
            <InputRadio
              key={prescription.id}
              label={`Prescribed ${formatRelativeDate(prescription.date_written ?? '', { includeRelative: true })}`}
              value={prescription.id.toString()}
              descriptions={[
                <ListDescription
                  key={prescription.doctor?.first_name}
                  iconName="doctor-small"
                  type="grey"
                >{`by ${getDoctorName(prescription.doctor)}`}</ListDescription>,
                <ListDescription
                  key={prescription.refills_remaining}
                  iconName="pillbottle-small"
                  type="grey"
                >
                  {`${prescription.refills_remaining ?? 'no'} refills remaining`}
                </ListDescription>,
                <ListDescription
                  key={earliestAvailableDate}
                  iconName="calendar-small"
                  type="secondary"
                  onPress={() => {
                    openNextAvailableDateActionSheet({ prescriptionID: prescription.id });
                  }}
                >{`Available ${formatRelativeDateWithDow(earliestAvailableDate || '')}`}</ListDescription>,
              ]}
            />
          );
        })}
      </InputRadioGroup>
      <LgPadding>
        <Button
          disabled={!currentSelectedPrescription}
          label={hasSheetsLeft ? 'Select prescription' : 'Add to cart'}
          onPress={() => {
            if (currentSelectedPrescription) {
              onPrescriptionSelect(currentSelectedPrescription);
            }
          }}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
