// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getLastDismissedRatingUuid from './getLastDismissedRatingUuid';
import getPendingRating from './getPendingRating';
import { getViewedNotices } from '~shared/features/notices/selectors/getViewedNotices';

const getShouldShowModal = createSelector(
  [getPendingRating, getLastDismissedRatingUuid, getViewedNotices],
  (pendingRating, lastDismissedRatingUuid) => {
    return !!pendingRating && pendingRating.uuid !== lastDismissedRatingUuid;
  },
);

export default getShouldShowModal;
