import { BREAKPOINTS, COLORS, SPACING } from '@alto/design-library-tokens';
import { Column, H1, Row, XlSpacing, useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import altoBox from '~web/images/altoBox2.png';

const overlapMainSM = '46px';
const overlapMainLG = '70px';

const Page = styledNative(Column)<{ isMDScreenOrBigger: boolean; backgroundColor?: string | null | undefined }>`
  min-height: calc(100vh - 141px - 72px);
  align-items: normal;
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `align-items: center;`}
`;

const TitleWrapper = styledNative(Column)<{
  fullWidth?: boolean;
  isMDScreenOrBigger: boolean;
  isLGScreenOrBigger: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '1170px')};
  max-width: 100%;
  padding: ${SPACING.STATIC.XL.px} ${SPACING.STATIC.LG.px};
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `padding-right: 250px; margin: 0 auto;`}
  ${({ isLGScreenOrBigger }) =>
    isLGScreenOrBigger && `padding: ${SPACING.STATIC.XXL.value + SPACING.STATIC.XS.value}px ${SPACING.STATIC.LG.px};`}
`;

const BannerWithTitle = styledNative(Column)<{
  bgColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS] | null | undefined;
  isLGScreenOrBigger: boolean;
}>`
  width: 100%;
  padding-bottom: ${overlapMainSM};
  background-color: ${({ bgColor }) => bgColor || COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST};
  position: relative;
  align-items: flex-start;
  ${({ isLGScreenOrBigger }) => isLGScreenOrBigger && `padding-bottom: ${overlapMainLG};`}
`;

// eslint-disable-next-line @alto/no-media-queries
const WrapperRow = styled.div<{ fullWidth?: boolean; children: React.ReactNode }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '1170px')};
  max-width: 100%;
  min-width: 900px;
  display: flex;
  z-index: 1;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINTS.MD}px) {
    min-width: unset;
    width: 100%;
  }
`;

type ContentProps = {
  readonly children: React.ReactNode;
};

export const ContentWrapper = (props: ContentProps) => <WrapperRow>{props.children}</WrapperRow>;

// eslint-disable-next-line @alto/no-media-queries
export const Main = styled.div`
  flex: 3;
  min-width: 480px;
  max-width: 100%;
  background-color: transparent;
  @media (max-width: ${BREAKPOINTS.MD}px) {
    min-width: unset;
    > * {
      margin-bottom: ${SPACING.STATIC.LG.px};
    }
  }
`;

// eslint-disable-next-line @alto/no-media-queries
export const Sidebar = styled.div`
  flex: 1;
  min-width: 280px;
  @media (max-width: ${BREAKPOINTS.MD}px) {
    display: none;
  }
  margin-top: ${overlapMainLG};
  @media (max-width: ${BREAKPOINTS.LG}px) {
    margin-top: ${overlapMainSM};
  }
`;

// eslint-disable-next-line @alto/no-media-queries
const ChildContainer = styled.div`
  margin-top: -${overlapMainLG};
  @media (max-width: ${BREAKPOINTS.LG}px) {
    margin-top: -${overlapMainSM};
  }
  width: 100%;
`;

const Footer = styledNative(Row)<{ isSMScreenOrBigger?: boolean }>`
  height: 11rem;
  background-color: ${COLORS.BACKGROUND_COLORS.GREY_LIGHTER};
  width: 100%;
  padding: 0 ${SPACING.STATIC.MD.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `padding: initial`}
`;

const FooterContent = styledNative(Row)<{ isSMScreenOrBigger?: boolean; isMDScreenOrBigger?: boolean }>`
  max-width: initial;
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `max-width: 66%;`}
`;

// eslint-disable-next-line @alto/no-media-queries
const Img = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: ${BREAKPOINTS.MD}px) {
    display: none;
  }
`;

type Props = {
  readonly title?: string;
  readonly headerBgColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS] | null | undefined;
  readonly bgColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS] | null | undefined;
  readonly footer?: React.ReactNode;
  readonly children: React.ReactNode;
};

export const PageWithHeader = ({ title, headerBgColor, bgColor, footer, children }: Props) => {
  const { isSMScreenOrBigger, isMDScreenOrBigger, isLGScreenOrBigger } = useScreenSize();

  return (
    <Page
      isMDScreenOrBigger={isMDScreenOrBigger}
      backgroundColor={bgColor as string}
    >
      <BannerWithTitle
        isLGScreenOrBigger={isLGScreenOrBigger}
        centerHorizontally
        bgColor={headerBgColor}
      >
        <Img src={altoBox} />
        <TitleWrapper
          isMDScreenOrBigger={isMDScreenOrBigger}
          isLGScreenOrBigger={isLGScreenOrBigger}
        >
          <H1>{title}</H1>
        </TitleWrapper>
      </BannerWithTitle>
      <ChildContainer>{children}</ChildContainer>
      {footer ? (
        <>
          <XlSpacing />
          <Footer
            isSMScreenOrBigger={isSMScreenOrBigger}
            center
          >
            <FooterContent isMDScreenOrBigger={isMDScreenOrBigger}>{footer}</FooterContent>
          </Footer>
        </>
      ) : null}
    </Page>
  );
};
