// @owners { team: patients-team }
import { AltoIcon, Card, LgPadding, ListDescription, ListItem, Pressable, Tag } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { useGetReadOnlyWundercoms } from '../../queries/queries';

/**
 *  Show read-only message history for support case patients that includes
 *  alto auto-comms, one-off messages, and prior message chat history
 *  This message history does not include any messages sent in support cases
 * @returns MessageHistory UI
 */
export const MessageHistory = () => {
  const { navigate } = useNavigation();
  const { unreadMessageCount } = useGetReadOnlyWundercoms();

  const navigateToChat = () => {
    navigate('RouteReadOnlyThread');
  };

  return (
    <Pressable
      onPress={navigateToChat}
      accessibilityLabel={`Press to view ${unreadMessageCount ? 'new' : 'past'} messages`}
    >
      <Card>
        <LgPadding>
          <ListItem
            title="Updates"
            descriptions={<ListDescription>Alto messages and past conversations</ListDescription>}
            fullBleed
            RightContent={<AltoIcon name="chevronright-small" />}
            tags={
              unreadMessageCount > 0
                ? [
                    <Tag
                      circle
                      key="unread-alto-message-count"
                      label={unreadMessageCount.toString()}
                      type="counter"
                    />,
                  ]
                : undefined
            }
          />
        </LgPadding>
      </Card>
    </Pressable>
  );
};
