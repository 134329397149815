// @owners { team: patients-team }
import { Row, SteppedProgressBar } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import styledNative from 'styled-components/native';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';

const NATIVE_FAMILY_MEMBER_ROUTES: Record<string, number> = {
  RouteAddFamilyMember: 0, // step 1 but don't show the progress bar
  RouteFamilyMemberMedicalInfo: 2,
  RouteFamilyMemberBenefits: 3,
  RouteFamilyMemberCompleteProfile: 3, // add more benefits or complete profile
};

const WEB_FAMILY_MEMBER_ROUTES: Record<string, number> = {
  family_member: 0, // step 1 but don't show the progress bar
  'medical-info': 2,
  'add-benefits': 3,
  'complete-profile': 3, // add more benefits or complete profile
};

const TOTAL_STEPS = 3;

const ProgressContainer = styledNative(Row)`
  width: 240px;
`;

const isWeb = getPlatformOS() === 'web';

export const FamilyMemberProgressBar = () => {
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName();
  if (!routeName) {
    return null;
  }

  const completedSteps = isWeb
    ? (WEB_FAMILY_MEMBER_ROUTES[routeName.split('/').pop() ?? ''] ?? 0)
    : (NATIVE_FAMILY_MEMBER_ROUTES[routeName] ?? 0);
  const showProgressBar = completedSteps !== 0;

  if (!showProgressBar) {
    return null;
  }

  return (
    <ProgressContainer>
      <SteppedProgressBar
        completedSteps={completedSteps}
        totalSteps={TOTAL_STEPS}
      />
    </ProgressContainer>
  );
};
