// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getNextAvailableDates } from './getNextAvailableDates';
import { getAppTime } from '~shared/features/app-time/selectors/getAppTime';

// 5 minutes
export const FRESH_THRESHOLD = 1000 * 60 * 5;

/**
 * Returns a map of known next available dates by id to whether they're fresh.
 */

type NextAvailableDateIDFreshnessMap = Record<string, boolean>;

export const getNextAvailableDateFreshnessByIDMap = createSelector(
  [getNextAvailableDates, getAppTime],
  (nextAvailableDates, appTime) => {
    return Object.entries(nextAvailableDates).reduce<NextAvailableDateIDFreshnessMap>(
      (available, [id, { computedAt }]) => {
        available[id] = appTime - computedAt < FRESH_THRESHOLD;

        return available;
      },
      {},
    );
  },
);
