// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getUsers } from './getUsers';
import { PHONE } from '~shared/constants';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getUserSupportPhoneByID = createSelector(getUsers, (users) => {
  const userSupportPhoneByID: Record<number, string | undefined> = {};

  users.forEach((user) => {
    userSupportPhoneByID[user.id] = user.support_phone;
  });

  return userSupportPhoneByID;
});

const getUserID = (_: ReduxStateShared, props: UserIDParam) => props.userID;

export const getUserSupportPhone = createSelector([getUserID, getUserSupportPhoneByID], (id, userSupportPhoneByID) => {
  if (!id) return PHONE;

  return userSupportPhoneByID[id] || PHONE;
});
