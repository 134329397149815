import { openModal } from '~shared/actions/modal';
import getAllowPriceOptionChange from '~shared/features/checkout/selectors/getAllowPriceOptionChange';
import { getPriceOptionsForPrescriptionInOrder } from '~shared/features/price-options/selectors/getPriceOptionsForPrescriptionInOrder';
import { getAvailableManualCoupon } from '~shared/features/pricing/selectors/getAvailableManualCoupon';
import { getCartSelectedPricingInfoForPrescription } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getIsFertilityUser } from '~shared/features/users/selectors/getIsFertilityUser';
import { getIsProgynyUser } from '~shared/features/users/selectors/getIsProgynyUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { type Props as OwnProps } from './Item';
import { PrescriptionItem } from './PrescriptionItem';

const mapStateToProps = (state: ReduxStateShared, ownProps: OwnProps) => {
  const { cartItem } = ownProps;
  const userID = getCurrentUserID(state);
  const { fetchOrderPricingLoading: priceLoading } = getLoading(state);
  const prescriptionID = cartItem.resource_id;
  const pricingInfoForPrescription = getCartSelectedPricingInfoForPrescription(state, prescriptionID);
  const availableManualCoupon = getAvailableManualCoupon(state, { prescriptionID });
  const allowPriceOptionChange = getAllowPriceOptionChange(state, { prescriptionID });
  const priceOptions = getPriceOptionsForPrescriptionInOrder(state, { prescriptionID });

  const showChangeQuantityLink =
    getIsFertilityUser(state, {
      userID,
    }) &&
    !getIsProgynyUser(state, {
      userID,
    });

  return {
    priceLoading,
    pricingInfoForPrescription,
    availableManualCoupon,
    allowPriceOptionChange,
    showChangeQuantityLink,
    priceOptions,
  };
};

const mapDispatchToProps = {
  openModal,
};

// @ts-expect-error TS(2345): Argument of type 'typeof PrescriptionItem' is not assignable to parameter of type 'ComponentType<Mat... (Delete me to see the full error)
export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionItem);
