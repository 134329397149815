import { BORDERS, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { Body, Column, XxsSpacing, useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { InfoText } from '@alto/pocky';
import { type Prescription as HomescreenPrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { MedicationCTA } from './MedicationCTA';
import { useNavigateToMedDetails } from '~web/hooks/useNavigateToMedDetails';
import IconPill from '~web/images/bluePill.svg';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const TruncatedText = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ComplementaryInfoFontSize = {
  MOBILE: TYPOGRAPHY.TEXT.DETAIL.LG.px,
  DESKTOP: TYPOGRAPHY.TEXT.BODY.MD.px,
};

const NameFontSize = {
  MOBILE: TYPOGRAPHY.TEXT.BODY.MD.px,
  DESKTOP: TYPOGRAPHY.TEXT.BODY.LG.px,
};

const FamilyMemberTruncatedText = styledNative(Body)<{ isSMScreenOrBigger: boolean }>`
  opacity: 0.6;
  ${TruncatedText}
  font-size: ${ComplementaryInfoFontSize.MOBILE};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `font-size: ${ComplementaryInfoFontSize.DESKTOP};`}
`;

const NameTruncatedText = styledNative(Body)<{ isSMScreenOrBigger: boolean }>`
  font-size: ${NameFontSize.MOBILE};
  overflow-wrap: anywhere;

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `font-size: ${NameFontSize.DESKTOP}; overflow-wrap: initial;`}
`;

const Img = styled.img`
  width: ${SPACING.STATIC.XL.px};
  height: ${SPACING.STATIC.XL.px};
  border-radius: ${BORDERS.RADIUS.MD.px};
`;

const ImgContainer = styled.div`
  align-items: center;
  margin-right: ${SPACING.STATIC.XS.px};
`;

type Props = {
  readonly prescription: HomescreenPrescription;
};

export default function DueForRefillMedicationInfo({ prescription }: Props) {
  const handleOnPrescriptionClick = useNavigateToMedDetails();
  const price = prescription.price ? formatDollarsWithPlaceholder(prescription.price, '') : 'View Price in Cart';
  const { isSMScreenOrBigger } = useScreenSize();
  const img = (
    <Img
      src={prescription.image_url ? prescription.image_url : IconPill}
      onClick={() => {
        handleOnPrescriptionClick(prescription.id, ORIGIN_NAMES.HOMESCREEN);
      }}
    />
  );
  return (
    <Section>
      <Column left>
        <ImgContainer>{img}</ImgContainer>
        <XxsSpacing />
        {prescription.family_member_name ? (
          <FamilyMemberTruncatedText
            isSMScreenOrBigger={isSMScreenOrBigger}
            color={COLORS.TEXT_COLORS.PRIMARY}
          >
            ${prescription.family_member_name}’s
          </FamilyMemberTruncatedText>
        ) : null}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */}
        <a
          role="button"
          tabIndex={0}
          onClick={() => {
            handleOnPrescriptionClick(prescription.id, ORIGIN_NAMES.HOMESCREEN);
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ textDecoration: 'none' }}
        >
          <NameTruncatedText
            isSMScreenOrBigger={isSMScreenOrBigger}
            color={COLORS.TEXT_COLORS.PRIMARY}
          >
            {prescription.name}
          </NameTruncatedText>
        </a>
        <InfoText>{price}</InfoText>
      </Column>
      <MedicationCTA
        fullWidthButton
        prescription={prescription}
      />
    </Section>
  );
}
