import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import { useEffect, useRef } from 'react';
import { EXPENSIVE, HAS_CONTROLS, MAIL_ONLY_ZONE, NO_SAFE_PLACE } from '~shared/features/checkout/constants';
import { type AddressInfoKey } from '~shared/features/checkout/types';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useCheckoutAddressInfoKeys } from './useCheckoutAddressInfoKeys';

const WAIVABLE = 'waivable';
const MUST_BE_HOME = 'must be home';

type HomeToSignRequirements = typeof WAIVABLE | typeof MUST_BE_HOME;

export const getHomeToSignRequirements = (
  keys: Partial<Record<AddressInfoKey, boolean>>,
): HomeToSignRequirements | null => {
  if ((keys[MAIL_ONLY_ZONE] && (keys[HAS_CONTROLS] || keys[EXPENSIVE])) || keys[HAS_CONTROLS]) {
    return MUST_BE_HOME;
  }

  if (keys[EXPENSIVE] && keys[NO_SAFE_PLACE]) {
    return WAIVABLE;
  }

  if (keys[NO_SAFE_PLACE]) {
    return MUST_BE_HOME;
  }

  return null;
};

type Props = {
  shipmentID: string | number | null | undefined;
  items: CartItem[];
  isEditingOrder: boolean;
  isSplitShipment: boolean;
};

export const useCheckoutViewed = ({ shipmentID, items, isEditingOrder, isSplitShipment }: Props) => {
  const { keys } = useCheckoutAddressInfoKeys();
  const homeToSignRequirements = getHomeToSignRequirements(keys);
  const { trackEvent } = useAnalytics();
  const runOnce = useRef(false);

  useEffect(() => {
    if (runOnce.current) return;
    runOnce.current = true;
    items.map((item) => {
      const prescriptionID = item.resource_type === 'Prescription' ? item.resource_id : undefined;
      trackEvent({
        event: EVENTS.CHECKOUT_VIEWED,
        params: {
          origin: isEditingOrder ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
          shipmentId: isEditingOrder ? shipmentID : null,
          'home to sign requirements': homeToSignRequirements,
          isSplitShipment,
        },
        additionalFields: { prescriptionId: prescriptionID },
      });
    });
  }, [items, isEditingOrder, homeToSignRequirements, trackEvent, shipmentID, isSplitShipment]);
};
