import { createSelector } from 'reselect';
import { getCurrentUser } from './getCurrentUser';
import { getUsers } from './getUsers';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getIsCashPayOnlyByUser = createSelector(getUsers, (users) => {
  const isCashPayOnlyByUser: Record<number, boolean> = {};

  users.forEach((user) => {
    isCashPayOnlyByUser[user.id] = !!user.cash_pay_only;
  });

  return isCashPayOnlyByUser;
});

const getUserID = (_: ReduxStateShared, props: UserIDParam) => props.userID;

export const getUserCashPayOnly = createSelector([getUserID, getIsCashPayOnlyByUser], (userID, isCashPayOnlyByUser) => {
  if (!userID) {
    return false;
  }

  return isCashPayOnlyByUser[userID] || false;
});

export const getIsCurrentUserCashPayOnly = createSelector(getCurrentUser, (user) => {
  if (user) {
    return !!user.cash_pay_only;
  }

  return false;
});
