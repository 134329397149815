import { TYPOGRAPHY } from '@alto/design-library-tokens';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { type PlaidData } from '~shared/types';

type Props = {
  plaidData: PlaidData;
};

const Label = styled.p`
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD.px};
`;

const Title = styled.span`
  text-transform: uppercase;
  font-weight: 700;
`;

export default class ACHLabel extends React.PureComponent<Props> {
  static propTypes = {
    plaidData: PropTypes.shape({
      institution_name: PropTypes.string,
      account_name: PropTypes.string,
      account_mask: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      plaidData: { institution_name, account_name, account_mask },
    } = this.props;

    return (
      <Label>
        <Title>{institution_name || ''}</Title> {account_name || ''} {account_mask || ''}
      </Label>
    );
  }
}
