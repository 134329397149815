// @owners { team: patients-team }
import {
  ASSISTANT_PRESSED_EVENTS,
  ASSISTANT_VIEWED_EVENTS,
  type AssistantPressedEvent,
  type AssistantViewedEvents,
} from './constants';
import {
  ASSISTANT_CATEGORIES,
  type AssistantCategory,
  type AssistantChatOrigin,
  type AssistantMedicationsAction,
  type AssistantPaymentsAction,
} from '~shared/features/alto-assistant/constants';
import { type MessageFormType } from '~shared/features/messages/constants';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';

// Viewed event helpers

export const sendAssistantViewedEvent = (name: AssistantViewedEvents, params: Record<string, any> = {}) => {
  return sendAnalyticsEvent(createEvent(EVENTS.ASSISTANT_COMPONENT_VIEWED, { name, ...params }));
};

export const sendAltoAssistantOrderingViewed = () =>
  sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.ORDERING_ACTION_SHEET);

export const sendAltoAssistantHowToEditQuantityViewed = () => {
  return sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.HOW_TO_EDIT_QUANTITY_ACTION_SHEET);
};

// Press event helpers

export const sendAssistantPressedEvent = (name: AssistantPressedEvent, params: Record<string, any> = {}) => {
  return sendAnalyticsEvent(createEvent(EVENTS.ASSISTANT_COMPONENT_PRESSED, { name, ...params }));
};

export const sendAssistantCategoryChosen = (category: AssistantCategory) => {
  return sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.CATEGORY_CHOSEN, { category });
};

export const sendAssistantCheckboxPressedEvent = (name: AssistantPressedEvent) => {
  return sendAssistantPressedEvent(name, { type: 'checkbox' });
};

export const sendAssistantLinkPressedEvent = (name: AssistantPressedEvent) => {
  return sendAssistantPressedEvent(name, { type: 'link' });
};

type DeliveryChosenParams = {
  editable: boolean;
  delivery_ids?: number[];
  shipmentID: number;
};

export const sendAssistantPastDeliveryChosen = (params: DeliveryChosenParams) => {
  return sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.PAST_DELIVERY_CHOSEN, {
    category: ASSISTANT_CATEGORIES.DELIVERIES,
    is_past_delivery: true,
    ...params,
  });
};

export const sendAssistantPricingPaymentsActionChosen = (action: AssistantPaymentsAction) => {
  return sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.PRICING_PAYMENTS_ACTION_CHOSEN, {
    category: ASSISTANT_CATEGORIES.PRICING_PAYMENTS,
    action,
  });
};

export const sendAssistantMedicationActionChosen = (action: AssistantMedicationsAction) => {
  return sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.MEDICATIONS_ACTION_CHOSEN, {
    category: ASSISTANT_CATEGORIES.MEDICATIONS,
    action,
  });
};

// Messaging event helpers

export type AssistantMessageSentParams = {
  patientID: number;
  category: AssistantCategory;
  origin: AssistantChatOrigin;
  characterCount: number;
  formType: MessageFormType;
  subject?: string;
};

export const sendAssistantMessageSentEvent = (params: AssistantMessageSentParams) => {
  return sendAnalyticsEvent(createEvent(EVENTS.ASSISTANT_MESSAGE_SENT, params));
};
