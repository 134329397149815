// @owners { team: patients-team }

/**
 *
 * @returns { weekdayCopy, weekendCopy }
 *  string local of current time https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *  (America/New_York, America/Chicago, America/Denver, America/Los_Angeles) used to determine
 *  the support hours we're surfacing to patients
 *
 *  We need to manually assign the timezone abbreviation for main areas because Date.toLocaleDateString() doesn't work on android
 *  https://stackoverflow.com/questions/51399551/why-isnt-tolocaledatestring-working-in-react-native-android
 */
export const getTimezoneRelevantCopy = (timezoneLocal: string): Record<'weekdayCopy' | 'weekendCopy', string> => {
  let hourModifier = 0;
  let usTimeZone = 'PT';

  switch (timezoneLocal) {
    case 'America/New_York':
      hourModifier = 3;
      usTimeZone = 'ET';
      break;
    case 'America/Chicago':
      hourModifier = 2;
      usTimeZone = 'CT';
      break;
    case 'America/Denver':
      hourModifier = 1;
      usTimeZone = 'MT';
      break;
    default:
      break;
  }
  const weekdayClose = hourModifier === 3 ? '12am' : `${9 + hourModifier}pm`;
  return {
    weekdayCopy: `Monday - Friday ${6 + hourModifier}am - ${weekdayClose} ${usTimeZone}`,
    weekendCopy: `Saturday - Sunday ${7 + hourModifier}am - ${6 + hourModifier}pm ${usTimeZone}`,
  };
};
