import { createSelector } from 'reselect';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { type ShipmentIDParamLong } from '~shared/selectors/selectorParamTypes';
import { type PaymentMethod, type ReduxStateShared } from '~shared/types';

export const getShipmentPaymentMethods = (state: ReduxStateShared) => state.shipments.shipmentPaymentMethods || [];

const getShipmentID = (_: ReduxStateShared, props: ShipmentIDParamLong) => props.shipmentID?.toString();

export const getShipmentPaymentMethodsByShipmentID = createSelector(
  [getShipmentPaymentMethods, getShipmentID],
  (shipmentPaymentMethods, shipmentID) => {
    return shipmentPaymentMethods.filter(({ shipment_id }) => shipment_id.toString() === shipmentID);
  },
);

export const getShipmentPaymentMethodsWithDetails = createSelector(
  [getShipmentPaymentMethodsByShipmentID, getPaymentMethods],
  (shipmentPaymentMethods, paymentMethods) => {
    // Index payment methods by ID
    const paymentMethodsByID = paymentMethods.reduce<Record<number, PaymentMethod>>((methods, method) => {
      methods[method.id] = method;
      return methods;
    }, {});

    return shipmentPaymentMethods.map((method) => {
      const { payment_method_id } = method;
      const paymentMethod: PaymentMethodWithAmount = {
        ...method,
        paymentMethod: paymentMethodsByID[payment_method_id] || undefined,
      };
      return paymentMethod;
    });
  },
);
