// @owners { team: patients-team }
import { type AltoIconName } from '@alto/design-library-tokens';
import { AltoIcon, Tag } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import { useEssentialsPreviouslyPurchased } from '~shared/features/essentials';

type EssentialTagProps = {
  readonly addOnOtc: AddOnOtc;
};

export const EssentialTag = ({ addOnOtc }: EssentialTagProps) => {
  const { getIconName } = useEssentialsPreviouslyPurchased();
  const hasTags = !!addOnOtc.tags?.length;
  const firstTag = addOnOtc.tags?.[0];
  const { name, icon } = getIconName(firstTag);

  if (!hasTags) return null;

  return (
    <Tag
      label={name}
      icon={<AltoIcon name={icon as AltoIconName} />}
      type="recommended"
    />
  );
};
