// @owners { team: patients-team }
// Central location to manage all routing concerns for the onboarding flow
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { getHasInsurances } from '~shared/features/insurances/selectors/getHasInsurances';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import { getUserHasOtherScripts } from '~shared/features/onboarding/selectors/getUserHasOtherScripts';
import { type ReduxStateShared } from '~shared/types';

export const WEB_LANDING_PAGE = 'WEB_LANDING_PAGE';
export const RX_TRACKER = 'TRACKER';
export const VERIFY = 'VERIFY';
export const MED_CONFIRMATION = 'MED_CONFIRMATION';
export const TARGETED_DIAGNOSES = 'TARGETED_DIAGNOSES';
export const INSURANCE_FOUND = 'INSURANCE_FOUND';
export const INSURANCE_INPUT = 'INSURANCE_INPUT';
export const INSURANCE_INPUT_MANUAL = 'INSURANCE_INPUT_MANUAL';
export const INSURANCE_INPUT_PHOTO = 'INSURANCE_INPUT_PHOTO';
export const MEDICAL_INFO = 'MEDICAL_INFO';
export const TRANSFER = 'TRANSFER';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const ONBOARD_SUCCESS = 'ONBOARD_SUCCESS';
export const HOME = 'HOME';
export const PHI_AUTHORIZATION = 'PHI_AUTHORIZATION';

export const ONBOARD_STATE_MED_CONFIRMATION = 'provider_med_confirmation';
export const ONBOARD_STATE_TARGETED_DIAGNOSES = 'provider_targeted_diagnoses';
export const ONBOARD_STATE_INSURANCE_FOUND = 'provider_insurance_found';
export const ONBOARD_STATE_INSURANCE_INPUT = 'provider_insurance_input';
export const ONBOARD_STATE_MEDICAL_INFO = 'provider_medical_info';
export const ONBOARD_STATE_TRANSFER = 'provider_transfer';
export const ONBOARD_STATE_CREATE_ACCOUNT = 'provider_create_account';
export const ONBOARD_STATE_DONE = 'done';
export const ONBOARD_STATE_PHI_AUTHORIZATION = 'provider_phi_authorization';

export const ONBOARD_STATE_TO_ROUTE: Record<string, Route> = {
  [ONBOARD_STATE_MED_CONFIRMATION]: MED_CONFIRMATION,
  [ONBOARD_STATE_TARGETED_DIAGNOSES]: TARGETED_DIAGNOSES,
  [ONBOARD_STATE_INSURANCE_FOUND]: INSURANCE_FOUND,
  [ONBOARD_STATE_INSURANCE_INPUT]: INSURANCE_INPUT,
  [ONBOARD_STATE_MEDICAL_INFO]: MEDICAL_INFO,
  [ONBOARD_STATE_TRANSFER]: TRANSFER,
  [ONBOARD_STATE_PHI_AUTHORIZATION]: PHI_AUTHORIZATION,
  [ONBOARD_STATE_CREATE_ACCOUNT]: CREATE_ACCOUNT,
};

export type Route =
  | typeof WEB_LANDING_PAGE
  | typeof RX_TRACKER
  | typeof VERIFY
  | typeof MED_CONFIRMATION
  | typeof TARGETED_DIAGNOSES
  | typeof INSURANCE_FOUND
  | typeof INSURANCE_INPUT
  | typeof INSURANCE_INPUT_MANUAL
  | typeof INSURANCE_INPUT_PHOTO
  | typeof MEDICAL_INFO
  | typeof TRANSFER
  | typeof PHI_AUTHORIZATION
  | typeof CREATE_ACCOUNT
  | typeof ONBOARD_SUCCESS
  | typeof HOME;

type Routing = Record<
  Route,
  {
    next: (arg0: ReduxStateShared, arg1: Route | null | undefined) => Route | null | undefined;
    previous: (arg0: ReduxStateShared, arg1: Route | null | undefined) => Route | null | undefined;
    currentStep: () => number;
  }
>;

// @ts-expect-error TS(2739): Type '{ VERIFY: { next: (state: State | null | undefined, desiredRoute: Route | null | undefined) =>... (Delete me to see the full error)
const onboardingRouting: Routing = {
  [VERIFY]: {
    next: (_, desiredRoute) => {
      // When a patient has left the onboarding flow midway and verifies again,
      // we can send them to where they left off
      return desiredRoute ? desiredRoute : MED_CONFIRMATION;
    },
    previous: () => null,
    currentStep: () => 0,
  },
  [MED_CONFIRMATION]: {
    next: (state) => {
      return onboardingRouting[TARGETED_DIAGNOSES].next(state, undefined);
    },
    previous: () => null,
    currentStep: () => 1,
  },
  [TARGETED_DIAGNOSES]: {
    next: (state: ReduxStateShared) => {
      const hasInsurance = getHasInsurances(state);

      return hasInsurance ? INSURANCE_FOUND : INSURANCE_INPUT;
    },
    previous: () => MED_CONFIRMATION,
    currentStep: () => 1,
  },
  [INSURANCE_FOUND]: {
    next: (_, desiredRoute) => {
      // When a patient clicks "Add other insurance", INSURANCE_INPUT is passed into desiredRoute
      return desiredRoute ? desiredRoute : MEDICAL_INFO;
    },
    previous: () => {
      return MED_CONFIRMATION;
    },
    currentStep: () => 2,
  },
  [INSURANCE_INPUT]: {
    next: (_, desiredRoute) => {
      // When a patient inputs their insurance manually or by photo, a
      // desiredRoute is passed in
      return desiredRoute ? desiredRoute : MEDICAL_INFO;
    },
    previous: (state) => {
      const hasInsurances = getHasInsurances(state);

      return hasInsurances ? INSURANCE_FOUND : MED_CONFIRMATION;
    },
    currentStep: () => 2,
  },
  [INSURANCE_INPUT_MANUAL]: {
    next: (_, desiredRoute) => {
      // When a patient inputs their insurance manually or by photo, a
      // desiredRoute is passed in
      return desiredRoute ? desiredRoute : INSURANCE_FOUND;
    },
    previous: (state) => {
      // If the user is editing an insurance, go back to INSURANCE_FOUND. Otherwise, route to INSURANCE_INPUT
      const editingInsuranceId = getEditingOnboardingInsuranceID(state);
      return editingInsuranceId ? INSURANCE_FOUND : INSURANCE_INPUT;
    },
    currentStep: () => 2,
  },
  [INSURANCE_INPUT_PHOTO]: {
    next: (_, desiredRoute) => {
      // When a patient inputs their insurance manually or by photo, a
      // desiredRoute is passed in
      return desiredRoute ? desiredRoute : INSURANCE_FOUND;
    },
    previous: (state) => {
      // If the user is editing an insurance, go back to INSURANCE_FOUND. Otherwise, route to INSURANCE_INPUT
      const editingInsuranceId = getEditingOnboardingInsuranceID(state);
      return editingInsuranceId ? INSURANCE_FOUND : INSURANCE_INPUT;
    },
    currentStep: () => 2,
  },
  [MEDICAL_INFO]: {
    next: (state) => {
      if (getUserHasOtherScripts(state)) {
        return TRANSFER;
      }

      return PHI_AUTHORIZATION;
    },
    previous: (state) => {
      const hasInsurance = getHasInsurances(state);

      if (hasInsurance) {
        return INSURANCE_FOUND;
      }

      return INSURANCE_INPUT;
    },
    currentStep: () => 3,
  },
  [TRANSFER]: {
    next: () => PHI_AUTHORIZATION,
    previous: () => MEDICAL_INFO,
    currentStep: () => 3,
  },
  [PHI_AUTHORIZATION]: {
    next: () => CREATE_ACCOUNT,
    previous: (state) => {
      if (getUserHasOtherScripts(state)) {
        return TRANSFER;
      }

      return MEDICAL_INFO;
    },
    currentStep: () => 4,
  },
  [CREATE_ACCOUNT]: {
    next: () => ONBOARD_SUCCESS,
    previous: () => PHI_AUTHORIZATION,
    currentStep: () => 5,
  },
  [ONBOARD_SUCCESS]: {
    next: () => HOME,
    previous: () => null,
    currentStep: () => 5,
  },
  [HOME]: {
    next: () => null,
    previous: () => null,
    currentStep: () => 0,
  },
};

export function nextOnboardingRoute(state: ReduxStateShared, currentRoute: Route, desiredRoute?: Route): Route {
  return onboardingRouting[currentRoute]?.next(state, desiredRoute) || currentRoute;
}

export function previousOnboardingRoute(state: ReduxStateShared, currentRoute: Route): Route | null | undefined {
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  return onboardingRouting[currentRoute]?.previous(state);
}

export function totalSteps(): number {
  return Math.max(...TypescriptUtils.objectKeys(onboardingRouting).map((key) => onboardingRouting[key].currentStep()));
}

export function currentStep(route: Route | null | undefined): number {
  return (route && onboardingRouting[route]?.currentStep()) || 0;
}
