import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';

export const chatbotMessage = 'CHATBOT_LOADING_MESSAGE';

export const getChatbotLoadingMessage = (): WundercomMessage => {
  const date = new Date();
  return {
    id: date.valueOf(),
    classified_intent: null,
    user_id: null,
    admin_user_id: null,
    body: chatbotMessage,
    read_at: date.toISOString(),
    created_at: date.toISOString(),
    updated_at: date.toISOString(),
    support_case_id: null,
    admin_user_name: null,
    suggested_responses: null,
    is_support_chatbot: true,
    title: null,
  };
};
