// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  Description,
  InlineAlert,
  LgPadding,
  MdPadding,
} from '@alto/design-system';
import React, { useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { cancelRenewal } from '~shared/actions/prescriptions';
import { prescriptionRenewalHasStarted } from '~shared/features/my-meds/helper';
import { getErrorMessage } from '~shared/helpers/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { useInvalidateMedDetails } from '../../med-details/queries/useMedDetails';
import { useInvalidateMedList } from '../queries/useMedList';

type Props = {
  readonly prescription: Prescription;
};

export const RenewalCancelActionSheet = ({ prescription }: Props) => {
  const cancelRenewalError = useSelectorShared((state) => state.ui.errors.cancelRenewalError);
  const cancelRenewalLoading = useSelectorShared((state) => state.ui.loading.cancelRenewalLoading);
  const { invalidatePrescriptionsQuery, invalidateMedicationQuery } = useInvalidateMedDetails();
  const { invalidateListQuery } = useInvalidateMedList();
  const { goToPreviousActionSheet } = useContext(ActionSheetContext);

  const dispatch = useDispatchShared();

  const handleCancelRefillRequest = async () => {
    // eslint-disable-next-line import/no-deprecated
    const success = await dispatch(cancelRenewal(prescription));
    if (success) {
      goToPreviousActionSheet();
      invalidateListQuery();
      invalidateMedicationQuery(prescription.id);
      invalidatePrescriptionsQuery(prescription.id);
    }
  };

  return (
    <ActionSheetV2
      title="Are you sure you want to cancel?"
      buttons={[
        <Button
          variant="danger"
          label="Yes, cancel"
          key="Yes, cancel"
          testID="cancel-renewal-button"
          loading={cancelRenewalLoading}
          loadingLabel="Canceling..."
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleCancelRefillRequest}
        />,
        <Button
          type="secondary"
          label="No, don’t cancel"
          key="No, don’t cancel"
          onPress={goToPreviousActionSheet}
        />,
      ]}
      analyticsName="renewal request cancel confirmation"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {cancelRenewalError ? (
          <InlineAlert type="error">
            <MdPadding>
              <Description fontFamily="bold">{getErrorMessage(cancelRenewalError)}</Description>
              <Description>Please contact us if this error persists.</Description>
            </MdPadding>
          </InlineAlert>
        ) : null}
        <Body>
          {prescriptionRenewalHasStarted(prescription) &&
            'We have already sent the request to your doctor, so you still may hear back from them. '}
          If you want to restart this request, you will need to message our Care Team in the app.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
