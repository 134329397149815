// @owners { team: patients-team }
import { useScreenSize } from '@alto/design-system';
import React from 'react';
import { ColoredFlexRow } from './Header';
import {
  ContainedImage,
  ValuePropCell,
  ValuePropDescription,
  ValuePropTitle,
  ValuePropsGrid,
  ValuePropsTitle,
  palette,
  spacing,
} from '~web/features/onboarding/components/styles';
import delivery from '~web/images/freeDelivery@3x.png';
import messaging from '~web/images/messaging@3x.png';
import pricing from '~web/images/priceShopping@3x.png';

export const ValueProps = () => {
  const { isSMScreenOrBigger, isLGScreenOrBigger } = useScreenSize();

  return (
    <ColoredFlexRow
      color={palette.babyBlue}
      spacing={spacing.xlSpacing}
      mSpacing={spacing.mdlgSpacing}
      padding={spacing.xxlSpacing}
      mPadding={`${spacing.mdlgSpacing} ${spacing.lgSpacing} ${spacing.xlSpacing}`}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <ValuePropsTitle isSMScreenOrBigger={isSMScreenOrBigger}>How Alto Works</ValuePropsTitle>
      <ValuePropsGrid isLGScreenOrBigger={isLGScreenOrBigger}>
        <ValuePropCell isLGScreenOrBigger={isLGScreenOrBigger}>
          <ContainedImage
            src={pricing}
            alt="Receipt with copay and out of pocket amounts"
          />
          <ValuePropTitle isSMScreenOrBigger={isSMScreenOrBigger}>Committed to low prices</ValuePropTitle>
          <ValuePropDescription isSMScreenOrBigger={isSMScreenOrBigger}>
            Our care team works with your doctor and insurance to find you the best price.
          </ValuePropDescription>
        </ValuePropCell>
        <ValuePropCell isLGScreenOrBigger={isLGScreenOrBigger}>
          <ContainedImage
            src={messaging}
            alt="Hand holding a phone with messages"
          />
          <ValuePropTitle isSMScreenOrBigger={isSMScreenOrBigger}>Help at your fingertips</ValuePropTitle>
          <ValuePropDescription isSMScreenOrBigger={isSMScreenOrBigger}>
            Our pharmacists are available by phone or chat every day of the week.
          </ValuePropDescription>
        </ValuePropCell>
        <ValuePropCell isLGScreenOrBigger={isLGScreenOrBigger}>
          <ContainedImage
            src={delivery}
            alt="Man riding a bike with a gift box"
          />
          <ValuePropTitle isSMScreenOrBigger={isSMScreenOrBigger}>Delivery to your door</ValuePropTitle>
          <ValuePropDescription isSMScreenOrBigger={isSMScreenOrBigger}>
            Track our couriers in real-time as they safely deliver your medications.
          </ValuePropDescription>
        </ValuePropCell>
      </ValuePropsGrid>
    </ColoredFlexRow>
  );
};
