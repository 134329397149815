// @owners { team: patients-team }
import { type Dispatch } from 'redux';
import { post } from '~shared/helpers/apiHelper';
import { type APIError } from '~shared/types/APIError';

const CREATE_SURVEY_RESPONSE_LOADING = 'CREATE_SURVEY_RESPONSE_LOADING';
export const CREATE_SURVEY_RESPONSE_SUCCEEDED = 'CREATE_SURVEY_RESPONSE_SUCCEEDED';
const CREATE_SURVEY_RESPONSE_FAILED = 'CREATE_SURVEY_RESPONSE_FAILED';
export const CLEAR_CREATE_SURVEY_RESPONSE_ERROR = 'CLEAR_CREATE_SURVEY_RESPONSE_ERROR';
export type CreateSurveyResponseSucceeded = {
  type: typeof CREATE_SURVEY_RESPONSE_SUCCEEDED;
  payload: SurveyResponsePayload;
};

export function creatingSurveyResponse() {
  return {
    type: CREATE_SURVEY_RESPONSE_LOADING,
  };
}

export function createSurveyResponseSucceeded(payload: SurveyResponsePayload) {
  return {
    type: CREATE_SURVEY_RESPONSE_SUCCEEDED,
    payload,
  };
}

export function createSurveyResponseFailed(error: APIError) {
  return {
    type: CREATE_SURVEY_RESPONSE_FAILED,
    payload: error,
    error: true,
  };
}

export function clearCreateSurveyResponseError() {
  return {
    type: CLEAR_CREATE_SURVEY_RESPONSE_ERROR,
  };
}

export type SurveyResponsePayload = {
  data?: string | Record<string, any>; // defined as jsonb column in survey_response model
  name: string;
  uuid?: string | null | undefined;
  survey_uuid?: string;
  version: number;
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function submitSurveyResponse(payload: SurveyResponsePayload) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(creatingSurveyResponse());

    return post('/survey_responses', payload).then((json) => {
      if (json.error) {
        dispatch(createSurveyResponseFailed(json.error));
        return false;
      }

      dispatch(createSurveyResponseSucceeded(payload));
      return true;
    });
  };
}
