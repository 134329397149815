// @owners { team: patients-team }
import { useEffect } from 'react';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { type OriginValues } from '~shared/features/essentials/add_ons_consts';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { type ANALYTICS_EVENT } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const useTrackViewedEvent = (
  eventKey: ANALYTICS_EVENT,
  shipmentID?: number | null,
  origin?: OriginValues,
  analyticsProps?: AnalyticsProps,
) => {
  const dispatch = useDispatchShared();
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID: Number(shipmentID) }));

  useEffect(() => {
    if (address?.dispensing_facility_id && origin) {
      dispatch(
        sendAnalyticsEvent(
          createEvent(eventKey, {
            shipment_id: shipmentID,
            facility_id: address?.dispensing_facility_id,
            origin,
            ...(analyticsProps || {}),
          }),
        ),
      );
    }
  }, [address?.dispensing_facility_id, dispatch, eventKey, origin, analyticsProps, shipmentID]);
};
