// @owners { team: patients-team }
import { push } from 'react-router-redux';
import { resetPassword } from '~shared/actions/auth';
import { getErrorMessage, getURLParams } from '~shared/helpers/helper';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import ResetPasswordForm, { type Values } from './ResetPasswordForm';

const mapStateToProps = (state: ReduxStateShared) => {
  return { ...state.auth, ...state.routing, resetPasswordError: getErrorMessage(state.auth.resetPasswordError) };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    onSubmit: (values: Values) => {
      const params = getURLParams();
      const { uuid } = params;
      // eslint-disable-next-line promise/catch-or-return
      dispatch(resetPassword(values.password, values.passwordConfirmation, uuid)).then((success) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          dispatch(push('/prescriptions'));
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
