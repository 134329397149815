function hasError(error: string) {
  return !!error;
}

const join = (rules: ((...args: any[]) => any)[]) => {
  return (value: string | null | undefined, data: Record<string, string | null | undefined>) => {
    return rules.map((rule) => rule(value, data)).filter(hasError)[0];
  };
};

export const createValidator = <T extends Record<string, any>>(
  rules: Partial<Record<keyof T, ((...args: any[]) => void) | ((...args: any[]) => any)[]>>,
) => {
  return (data: T) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      // @ts-expect-error TS(2769): No overload matches this call.
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions

      const error = rule(data[key], data);

      if (error) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
        errors[key] = error;
      }
    });
    return errors;
  };
};
