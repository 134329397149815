// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ButtonSelector,
  Description,
  InlineAlert,
  InputText,
  LgPadding,
  type OptionValue,
  XsSpacing,
} from '@alto/design-system';
import React, { useState } from 'react';

type Props = {
  readonly error?: string;
  readonly familyMemberName?: string; // first name of family member
  readonly handleChange: (value: string) => void;
  readonly hasSubmitted: boolean;
  readonly isTouched?: boolean;
  readonly medicalConditions: string;
};

export const NO_KNOWN_MEDICAL_CONDITIONS = 'No Known Medical Conditions';

const YES_VALUE = 'Yes';
const NO_VALUE = 'No';

const hasMedicalConditionsOptions = [
  { label: NO_VALUE, value: NO_VALUE, testID: 'medical-conditions-no' },
  { label: YES_VALUE, value: YES_VALUE, testID: 'medical-conditions-yes' },
];

const getDefaultValue = (hasMedicalConditions: boolean, medical_conditions: string | undefined) => {
  if (hasMedicalConditions) {
    return YES_VALUE;
  }
  if (medical_conditions === NO_KNOWN_MEDICAL_CONDITIONS) {
    return NO_VALUE;
  }
  return undefined;
};

export const MedicalConditionsInput = ({
  error,
  familyMemberName,
  handleChange,
  hasSubmitted,
  isTouched,
  medicalConditions,
}: Props) => {
  const [hasMedicalConditions, setHasMedicalConditions] = useState<boolean>(
    !!medicalConditions && medicalConditions !== NO_KNOWN_MEDICAL_CONDITIONS,
  );
  const handleMedicalCondition = (value: OptionValue) => {
    if (value === YES_VALUE) {
      setHasMedicalConditions(true);
      handleChange('');
    } else {
      setHasMedicalConditions(false);
      handleChange(NO_KNOWN_MEDICAL_CONDITIONS);
    }
  };

  const defaultValue = getDefaultValue(hasMedicalConditions, medicalConditions);
  const title = familyMemberName
    ? `Does ${familyMemberName} have any medical conditions?`
    : 'Do you have any medical conditions?';
  const placeholder = familyMemberName
    ? `Please list any medical conditions ${familyMemberName} has that their doctor has told you about. (e.g. high blood pressure or diabetes)`
    : 'Please list any medical conditions you have that your doctor has told you about. (e.g. high blood pressure or diabetes)';

  return (
    <>
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Description
          color={COLORS.TEXT_COLORS.PRIMARY}
          fontFamily="semibold"
        >
          {title}
        </Description>
        <XsSpacing />
        <ButtonSelector
          options={hasMedicalConditionsOptions}
          onChange={handleMedicalCondition}
          value={defaultValue}
        />
      </LgPadding>
      {hasMedicalConditions ? (
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <InputText
            accessibilityLabel="Medical Conditions (Required)"
            numberOfLines={3}
            label="Medical Conditions"
            required
            autoCapitalize="none"
            onChangeText={handleChange}
            defaultValue={medicalConditions}
            error={(hasSubmitted || isTouched) && error ? error : undefined}
            placeholder={placeholder}
            testID="medical-conditions-input-text"
          />
        </LgPadding>
      ) : null}
      {(isTouched || hasSubmitted) && !!error && defaultValue === undefined ? (
        <LgPadding
          topPadding={SPACING.STATIC.MD}
          bottomPadding={SPACING.STATIC.MD}
        >
          <InlineAlert type="error">
            <Description>{error}</Description>
          </InlineAlert>
        </LgPadding>
      ) : null}
    </>
  );
};
