// @owners { team: patients-team }
import { ActionSheetContext, Toast, ToastContext } from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { deleteInsurance } from '~shared/actions/insurances';
import { type InsuranceSource } from '~shared/constants';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getDeliveriesBilledWithInsuranceCount } from '~shared/lib/analytics/src/getDeliveriesBilledWithInsuranceCount';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types';
import { ConfirmDeleteActionSheet } from '../../action-sheet/components/ConfirmDeleteActionSheet';

type Props = {
  readonly insurance: Insurance;
  readonly source: InsuranceSource;
};

export const InsuranceDeletionActionSheet = ({ insurance, source }: Props) => {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { closeActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();
  const deliveries = useSelectorShared(getDeliveries);

  const handleDeleteInsurance = async () => {
    // eslint-disable-next-line import/no-deprecated
    const success = await dispatch(deleteInsurance(insurance));
    if (success) {
      const billedDeliveriesCount = getDeliveriesBilledWithInsuranceCount(deliveries, insurance.id);
      trackEvent({
        event: EVENTS.INSURANCE_REMOVED,
        params: {
          source,
          'insurance source': insurance.source,
          'num deliveries billed with insurance': billedDeliveriesCount,
        },
      });
      queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
      closeActionSheet();
    } else {
      addToast(
        <Toast variant="error">
          There was an error deleting your benefits. Please try again or message support if the problem persists.
        </Toast>,
      );
    }
  };

  return (
    <ConfirmDeleteActionSheet
      onConfirm={handleDeleteInsurance}
      description="this benefit"
      analyticsName="confirm delete insurance"
    />
  );
};
