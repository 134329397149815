// @owners { team: supply-chain }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type DeliveryMethodType } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method_type';
import { DeliveryMethodsEndpoint } from '@alto/scriptdash/alto/deliver/patients/v1/delivery_methods_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';
import { staleTimes } from '~shared/queries/constants';

const deliveryMethodsEndpoint = DeliveryMethodsEndpoint(apiEndpointHandler);
const deliveryMethods = createQueryKeys('deliveryMethods', {
  deliveryMethodByName: ({ name }) => ({
    queryKey: [{ name }],
    queryFn: async () => {
      const data = await deliveryMethodsEndpoint.fetchByName({ name });

      return { deliveryMethod: data.data };
    },
  }),
  deliveryMethodsByStateAndZip: ({ state, zip }) => ({
    queryKey: [{ state, zip }],
    queryFn: async () => {
      const data = await deliveryMethodsEndpoint.fetchByStateAndZip({ state, zip });

      return { deliveryMethods: data.data };
    },
  }),
  deliveryMethodsByShipmentIDs: ({ shipmentIDs }) => ({
    queryKey: [{ shipmentIDs }],
    queryFn: async () => {
      const data = await deliveryMethodsEndpoint.fetchByShipmentIDs(
        { shipment_ids: shipmentIDs },
        { splitArrayParams: true },
      );

      return { deliveryMethods: data.data };
    },
  }),
});

/**
 * Given a DeliveryMethod name, fetch the delivery method configuration
 * @param name
 * @returns The delivery method, or null if not found
 */
export const useGetDeliveryMethodByName = (name: DeliveryMethodType) => {
  const { data, isPending, isSuccess, error } = useQuery({
    ...deliveryMethods.deliveryMethodByName({ name }),
    enabled: !!name && name.trim() !== '',
    staleTime: staleTimes.oneDay,
  });

  return {
    deliveryMethod: data?.deliveryMethod,
    deliveryMethodError: error,
    deliveryMethodLoading: isPending,
    deliveryMethodSuccess: isSuccess,
  };
};

/**
 * Given a state and zip, fetch the delivery methods available to that address
 * @param state
 * @param zip
 * @returns available delivery methods for this address based on if the delivery method has a disabled reason or not
 */
export const useGetDeliveryMethodsByStateAndZip = (state: string, zip: string) => {
  const { data, isPending, isSuccess, error } = useQuery({
    ...deliveryMethods.deliveryMethodsByStateAndZip({ state, zip }),
    enabled: !!state && !!zip && state.trim() !== '' && zip.trim() !== '',
    select: (data) => {
      return { deliveryMethods: data.deliveryMethods?.filter((method) => method.disabled_reasons.length === 0) };
    },
    staleTime: staleTimes.oneDay,
  });

  return {
    availableDeliveryMethods: data?.deliveryMethods,
    deliveryMethodsError: error,
    deliveryMethodsLoading: isPending,
    deliveryMethodsSuccess: isSuccess,
  };
};

/**
 * Given a list of shipment IDs, fetch the delivery methods for each shipment
 * @param shipmentIDs
 * @returns delivery methods for each shipment
 */
export const useGetDeliveryMethodsByShipmentIDs = (shipmentIDs: number[]) => {
  const { data, isPending, isSuccess, error } = useQuery({
    ...deliveryMethods.deliveryMethodsByShipmentIDs({ shipmentIDs }),
    enabled: !!shipmentIDs && shipmentIDs.length > 0,
    staleTime: staleTimes.fifteenMinutes,
  });

  // Create a map of shipment ID to delivery method. The order of the delivery methods is the same as the order of the shipment IDs
  const deliveryMethodsByShipmentID = shipmentIDs.reduce<Record<number, DeliveryMethod>>((acc, shipmentID, index) => {
    if (!data?.deliveryMethods) return acc;

    acc[shipmentID] = data?.deliveryMethods[index];
    return acc;
  }, {});

  return {
    deliveryMethodsByShipmentID,
    deliveryMethodsError: error,
    deliveryMethodsLoading: isPending,
    deliveryMethodsSuccess: isSuccess,
  };
};
