import { type InputTextProps } from './InputText';

export const getAccessibilityLabel = ({
  accessibilityLabel,
  disabled,
  error,
  isFocused,
  isAndroid,
  label,
  optional,
  required,
  secureTextEntry,
}: { isFocused: boolean; isAndroid: boolean } & Pick<
  InputTextProps,
  'accessibilityLabel' | 'disabled' | 'error' | 'label' | 'optional' | 'required' | 'secureTextEntry'
>) => {
  const defaultDescriptor = accessibilityLabel ? `${accessibilityLabel}, ` : '';
  const errorDescriptor = error ? ', invalid data' : '';
  const isEditingDescriptor = isFocused && !disabled ? ', is editing' : '';
  const textFieldDescriptor = label ? ` for ${label}` : '';
  const requiredDescriptor = required ? ', required' : '';
  const optionalDescriptor = optional ? ', optional' : '';
  const secureTextFieldDescriptor = secureTextEntry ? 'Secure ' : '';
  const finalAccessibilityLabel = `${defaultDescriptor}${secureTextFieldDescriptor}Text Field${textFieldDescriptor}${requiredDescriptor}${optionalDescriptor}${errorDescriptor}${isEditingDescriptor}`;

  // iOS voiceover does not announce accessibility state disabled
  if (!isAndroid && disabled) {
    return `${finalAccessibilityLabel}, disabled`;
  }

  return finalAccessibilityLabel;
};

export const getAccessibilityValue = ({
  value,
  defaultValue,
  placeholder,
  shouldRedactInput,
}: { shouldRedactInput?: boolean } & Pick<InputTextProps, 'defaultValue' | 'placeholder' | 'value'>) => {
  if (shouldRedactInput && value) {
    return `${value.length} characters`;
  }
  return value || defaultValue || placeholder;
};
