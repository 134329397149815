import { type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { type Item, type ItemKey } from './types';
import { type Medication } from '~shared/features/my-meds/types';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { getItemPricingTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { type Pricing } from '~shared/features/pricing/types';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { isControl } from '~shared/helpers/helper';
import { type LightDelivery, type Prescription } from '~shared/types';

type ConstructorProps = {
  resource_id: number;
  medication: Medication;
  prescription: Prescription;
  nextDelivery?: LightDelivery;
  autoRefill?: boolean;
  pricing: Pricing;
  selectedPaymentType: PriceType | undefined;
};

export default class PrescriptionItem implements Item {
  resource_type: ItemKey['resource_type'];

  resource_id: number;

  userID: number;

  medication: Medication;

  prescription: Prescription;

  nextDelivery?: LightDelivery;

  hasAncillaryItems: boolean;

  isRefrigerated: boolean;

  isSignatureRequired: boolean;

  isCompound: boolean;

  autoRefill: boolean | null | undefined;

  pricing: Pricing;

  selectedPaymentType: PriceType | undefined;

  constructor({
    medication,
    prescription,
    nextDelivery,
    autoRefill,
    resource_id,
    pricing,
    selectedPaymentType,
  }: ConstructorProps) {
    this.resource_id = resource_id;
    this.resource_type = 'Prescription';

    this.medication = medication;
    this.prescription = prescription;
    this.nextDelivery = nextDelivery;

    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to type 'number'.
    this.userID = prescription.user_id;

    this.hasAncillaryItems = !!prescription.has_ancillary_items;
    this.isRefrigerated = !!prescription.is_refrigerated;
    this.isSignatureRequired = isControl(prescription);
    this.isCompound = !!prescription.is_compound;
    this.autoRefill = autoRefill;
    this.pricing = pricing;
    this.selectedPaymentType = selectedPaymentType;
  }

  equals(item: Pick<Item, 'resource_type' | 'resource_id'>): boolean {
    return item.resource_type === this.resource_type && item.resource_id === this.resource_id;
  }

  displayName(): string {
    return this.prescription.medication_name || this.medication.medicationName;
  }

  key(): string {
    const { key } = this.medication;
    return `${key || ''}-${this.resource_id || ''}`;
  }

  itemKey(): ItemKey {
    return {
      resource_type: this.resource_type,
      resource_id: this.resource_id,
    };
  }

  paymentTotal() {
    return getItemPricingTotal(this.pricing, this.selectedPaymentType);
  }

  paymentString(): string {
    const total = this.paymentTotal();

    if (total.reason === PRESCRIPTION_PRICING_LABELS.COVERED_BY_CLINIC) {
      return 'Billed to Clinic';
    }

    if (total.reason === PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE) {
      return 'Price N/A';
    }

    const valueString = formatDollarsWithPlaceholder(total.value);

    if (total.reason === PRESCRIPTION_PRICING_LABELS.PROGYNY) {
      return `${valueString}*`;
    }

    return valueString;
  }

  apiPayload() {
    return {
      prescription_id: this.resource_id,
      patient_paid_shown: this.paymentTotal().value,
      auto_refill: this.autoRefill,
      priceType: this.selectedPaymentType,
    };
  }

  // TODO: Implement `paymentBreakdown`
  paymentBreakdown(): Record<string, any> {
    return {};
  }
}

export type PrescriptionItemApiPayload = ReturnType<typeof PrescriptionItem.prototype.apiPayload>;
