// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Body,
  Button,
  Description,
  MdSpacing,
  PressableAltoIcon,
  Row,
  XxsPadding,
} from '@alto/design-system';
import React, { useContext } from 'react';
import { ORDER_TYPE_CONTEXT, type OrderTypeContext } from '../constants';
import { OrderTypeActionSheet } from './order-type-action-sheet/OrderTypeActionSheet';

type Props = {
  readonly orderTypeContext: OrderTypeContext;
  readonly prescriptionID: number;
  readonly isEditable?: boolean;
  readonly initialAutoRefillEnabledValue: boolean;
};

export const OrderTypeSelectionSection = ({
  orderTypeContext,
  prescriptionID,
  isEditable,
  initialAutoRefillEnabledValue,
}: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  //ui
  const showChangeButton = isEditable ?? true;
  const showSmallText = orderTypeContext === ORDER_TYPE_CONTEXT.CART;
  const showEditIcon =
    orderTypeContext === ORDER_TYPE_CONTEXT.CART || orderTypeContext === ORDER_TYPE_CONTEXT.POST_CHECKOUT;

  const handlePressChangeOrderTypeButton = () => {
    setActiveActionSheet(
      <OrderTypeActionSheet
        prescriptionID={prescriptionID}
        orderTypeContext={orderTypeContext}
        initialAutoRefillEnabledValue={initialAutoRefillEnabledValue}
      />,
    );
  };

  return (
    <Row centerVertically>
      <XxsPadding
        leftPadding={SPACING.STATIC.NONE}
        rightPadding={SPACING.STATIC.NONE}
      >
        <Row>
          {showSmallText ? (
            <Description>{initialAutoRefillEnabledValue ? 'Auto refill' : 'One-time fill'}</Description>
          ) : (
            <Body>{initialAutoRefillEnabledValue ? 'Auto refill' : 'One-time fill'}</Body>
          )}
        </Row>
      </XxsPadding>
      <MdSpacing />
      {showChangeButton ? (
        <Row centerHorizontally>
          <Button
            width="inline"
            type="tertiary"
            small
            noPadding
            onPress={handlePressChangeOrderTypeButton}
          >
            Change order type
          </Button>
          {showEditIcon ? (
            <PressableAltoIcon
              onPress={handlePressChangeOrderTypeButton}
              name="edit-small"
              accessibilityLabel="Click to change order type"
              type="secondary"
            />
          ) : null}
        </Row>
      ) : null}
    </Row>
  );
};
