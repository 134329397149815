import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { Body } from '../../../typography';
import { getOriginalCase } from './helpers';

type Props = {
  readonly fullText: string;
  readonly textToHighlight: string | null | undefined;
};

export const HighlightText = ({ fullText, textToHighlight }: Props) => {
  const originalCaseTextToHighlight = getOriginalCase(fullText, textToHighlight);

  if (!originalCaseTextToHighlight) {
    return <Body color={COLORS.TEXT_COLORS.PRIMARY}>{fullText}</Body>;
  }

  // guarantee we only split the text in one place
  const textSplitOnce = fullText.replace(originalCaseTextToHighlight, '__REPLACE_ME__').split('__REPLACE_ME__');

  // escape hatch
  if (textSplitOnce.length !== 2) {
    return <Body color={COLORS.TEXT_COLORS.PRIMARY}>{fullText}</Body>;
  }

  const [splitFront, splitBack] = textSplitOnce;

  if (!splitFront) {
    // matched text was at the front of the string
    return (
      <Body>
        <Body
          color={COLORS.TEXT_COLORS.PRIMARY}
          fontFamily="semibold"
        >
          {originalCaseTextToHighlight}
        </Body>
        <Body color={COLORS.TEXT_COLORS.PRIMARY}>{splitBack}</Body>
      </Body>
    );
  }

  return (
    <Body>
      <Body color={COLORS.TEXT_COLORS.PRIMARY}>{splitFront}</Body>
      <Body
        fontFamily="semibold"
        color={COLORS.TEXT_COLORS.PRIMARY}
      >
        {originalCaseTextToHighlight}
      </Body>
      <Body color={COLORS.TEXT_COLORS.PRIMARY}>{splitBack}</Body>
    </Body>
  );
};
