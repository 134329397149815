import { createSelector } from 'reselect';
import { getPrescriptions } from './getPrescriptions';
import { PRESCRIPTION_STATES } from '~shared/constants';
import { type Prescription } from '~shared/types';

const sortPrescriptionsByDateWritten = (a: Prescription, b: Prescription) => {
  if (a.date_written && b.date_written) {
    return a.date_written.localeCompare(b.date_written);
  }

  return 0;
};

export const getDoctorFromMostRecentPrescriptions = createSelector([getPrescriptions], (prescriptions) => {
  const activePrescriptions = prescriptions.filter(({ active_state }) => active_state === PRESCRIPTION_STATES.ACTIVE);
  const mostRecentPrescription = activePrescriptions.sort(sortPrescriptionsByDateWritten)[0];
  return mostRecentPrescription?.doctor;
});
