// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, useScreenSize } from '@alto/design-system';
import { AddOnsContext, useEssentialsStoreCard } from '@alto/features';
import React, { useMemo } from 'react';
import styledNative from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { SECTION_LABELS } from '~shared/features/homescreen/constants';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';
import { StandaloneEssentialsCarousel } from '~web/features/homescreen/components/StandaloneEssentialsCarousel';

const CustomCard = styledNative(Card)<{ isSMScreenOrBigger: boolean }>`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `margin: 0 0 ${SPACING.STATIC.LG.value * 2}px;`}
  z-index: 0;
`;

const CustomPadding = styledNative(LgPadding)`
  width: 100%;
`;

export const FeaturedEssentialsCard = () => {
  const { imageEssentialsIcon, upSellCardSubtitle } = useEssentialsStoreCard();
  const { isSMScreenOrBigger } = useScreenSize();
  const essentialsContext = useMemo(
    () =>
      ({
        origin: ORIGINS.HOMESCREEN,
      }) as const,
    [],
  );

  useTrackViewedEvent(EVENTS.ESSENTIALS__FEATURED_ESSENTIALS_VIEWED, undefined, ORIGINS.HOMESCREEN);

  return (
    <AddOnsContext.Provider value={essentialsContext}>
      <CustomCard
        title={SECTION_LABELS.FEATURED_OTCS}
        description={upSellCardSubtitle}
        illustrationSrc={imageEssentialsIcon}
        illustrationAccessibilityLabel={IMG_ALT_TEXTS.topSellers}
        isSMScreenOrBigger={isSMScreenOrBigger}
      >
        <CustomPadding topPadding={SPACING.STATIC.NONE}>
          <StandaloneEssentialsCarousel origin={ORIGINS.HOMESCREEN} />
        </CustomPadding>
      </CustomCard>
    </AddOnsContext.Provider>
  );
};
