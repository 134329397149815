// @owners { team: patients-team }
import { ActionSheetContext, LoadingEmptyState } from '@alto/design-system';
import { SupportCaseContent, SupportCaseNotFound, useGetSupportCase, useGetSupportCaseFromCache } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useContext } from 'react';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { ASSISTANT_CHAT_ORIGINS } from '~shared/features/alto-assistant/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { Sentry } from '~shared/sentry';
import { useDispatchShared } from '~shared/store';
import { HowCanWeHelpActionSheet } from '~web/features/alto-assistant/components/action-sheets/HowCanWeHelpActionSheet';

/**
 * Component for a single support case on the web, this is a wrapper for the @alto/features RNW SupportCaseContent component
 * @param {NavigationParams} props component props
 * @returns SupportCase UI
 */
const SupportCase = ({ params }: NavigationParams) => {
  const dispatch = useDispatchShared();
  const { goBack } = useNavigation();
  const { supportCaseID } = params;

  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const { supportCaseCached } = useGetSupportCaseFromCache(supportCaseID);
  const {
    supportCaseNetwork,
    error: supportCaseError,
    isFetching: isFetchingSupportCase,
  } = useGetSupportCase(supportCaseID, !!supportCaseCached);

  const supportCase = supportCaseCached || supportCaseNetwork; // use cached data and fallback to network if needed (ie branch link click)

  const handleReplyPress = useCallback(() => {
    dispatch(push(`/help/support/${supportCaseID}/reply`));
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: { name: ASSISTANT_PRESSED_EVENTS.SUPPORT_CASE_REPLY_BUTTON, supportCaseID },
    });
  }, [dispatch, supportCaseID, trackEvent]);

  if (isFetchingSupportCase && !supportCase) {
    return <LoadingEmptyState />;
  }

  if (!supportCase) {
    if (supportCaseError) {
      const context = {
        tags: { featureOwner: 'alto-assistant' },
        contexts: { 'Error Message Context': { error: supportCaseError } },
      };
      Sentry.captureMessage(`Error finding support case ${supportCaseID}`, context);
    }
    return <SupportCaseNotFound supportCaseID={supportCaseID} />;
  }

  const handleNewRequestPress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.SUPPORT_CASE_NEW_REQUEST,
        origin: ASSISTANT_CHAT_ORIGINS.CLOSED_SUPPORT_CASE,
        supportCaseID,
      },
    });
    setActiveActionSheet(<HowCanWeHelpActionSheet />);
  };

  return (
    <SupportCaseContent
      supportCase={supportCase}
      handleNewRequestPress={handleNewRequestPress}
      handleReplyPress={handleReplyPress}
      handleBackPress={goBack}
    />
  );
};

export default withRouter(SupportCase);
