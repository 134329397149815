// @owners { team: patients-team }
import { ActionSheetContext, FilterTag } from '@alto/design-system';
import React, { useContext } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { SortActionSheet, sortTypeContent } from './SortActionSheet';

export const SortTag = () => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const sortType = useSelectorShared((state) => state.persistedUI.medList.sortType);
  const openActionSheet = () => {
    trackEvent({
      event: EVENTS.MED_LIST__SORT_MEDICATIONS_VIEWED,
      params: {
        origin: ORIGIN_NAMES.MED_LIST,
      },
    });
    setActiveActionSheet(<SortActionSheet />);
  };

  return <FilterTag onPress={openActionSheet}>{sortTypeContent[sortType].label}</FilterTag>;
};
