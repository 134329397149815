// @owners { team: patients-team }
import { type AltoIconName } from '@alto/design-library-tokens';
import { AltoIcon, ListDescription, ListItem } from '@alto/design-system';
import React from 'react';

export type MenuOptionProps = {
  readonly iconName?: AltoIconName;
  readonly text: string;
  readonly description?: string;
  readonly onPress?: () => void; // when present right chevron is shown on menu item
  readonly rightIconName?: AltoIconName;
  readonly hide?: boolean;
};

export const MenuOption = ({
  iconName,
  text,
  description,
  hide = false,
  onPress,
  rightIconName,
}: MenuOptionProps): React.ReactElement<typeof ListItem> | null => {
  if (hide) {
    return null;
  }

  return (
    <ListItem
      title={text}
      LeftContent={iconName ? <AltoIcon name={iconName} /> : undefined}
      RightContent={
        onPress ? (
          <AltoIcon
            type="grey"
            name={rightIconName ?? 'chevronright-small'}
          />
        ) : undefined
      }
      descriptions={description ? [<ListDescription key={description}>{description}</ListDescription>] : undefined}
      onPress={onPress}
    />
  );
};
