import { type PaymentMethod } from '~shared/types';

// @ts-expect-error TS(2741): Property 'id' is missing in type '{}' but required in type 'PaymentMethod'.
export default function formatPaymentMethod(paymentMethod: PaymentMethod | null | undefined = {}): string {
  if (!paymentMethod) {
    return 'Not Available';
  }

  const { details, type } = paymentMethod;

  if (type === 'StripeData') {
    const card_type = details?.card_type || 'Unknown Card Type';
    const card_last_4 = details?.card_last_4 || '????';

    return `${card_type} - ${card_last_4}`;
  }

  if (type === 'PlaidData' && details) {
    const institution_name = details?.institution_name || 'Unknown Institution';
    const account_mask = details?.account_mask || '????';

    return `${institution_name} - ${account_mask}`;
  }

  return 'Not Available';
}
