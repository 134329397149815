// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { LgSpacing, ListDescription, ListItem, Separator, SmSpacing } from '@alto/design-system';
import { type LineItem, type LineItemType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { Fragment, useMemo } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { getHasScheduleableDeliveryFeesInCart } from '~shared/features/checkout/selectors/getCart';
import { getOrderDate } from '~shared/features/checkout/selectors/getOrder';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { type OrderPricing } from '~shared/features/pricing/types';
import { type OrderPricingQueryData } from '~shared/queries/useOrderPricing';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { ItemPaymentBreakdown } from './ItemPaymentBreakdown';
import { PricingBreakdownOrderLineItem } from './PricingBreakdownOrderLineItem';

export type PricingBreakdownPrescription = { id: number; medication_name: string; product_quantity: string };

type Props = {
  readonly prescriptions: Prescription[] | PricingBreakdownPrescription[];
  readonly orderPricing?: OrderPricing | OrderPricingQueryData;
  readonly forSinglePrescription?: boolean;
  readonly origin: string;
  readonly eventUuid: string;
};

const LineItemsContainer = styled(View)`
  border-color: ${COLORS.BORDER_COLORS.LIGHT};
  border-left-width: ${BORDERS.SIZE * 2}px;
  padding-left: ${SPACING.STATIC.MD.px};
`;

const initialLineItemHash = {
  alto_credit: null,
  alto_savings: null,
  cash_price: null,
  delivery_fee: null,
  essentials_only_fee: null,
  insurance_paid: null,
  on_demand_fee: null,
  same_day_delivery_fee: null,
  next_day_delivery_fee: null,
  pickup: null,
  standard_delivery_fee: null,
  asap_delivery_fee: null,
  tip_amount: null,
  total: null,
  pending_progyny_coverage: null,
};

export const PaymentBreakdown = ({
  forSinglePrescription = false,
  prescriptions,
  orderPricing,
  origin,
  eventUuid,
}: Props) => {
  const prescriptionPricing = useSelectorShared(getPrescriptionPricing);
  const orderDate = useSelectorShared(getOrderDate);
  const hasScheduleableDeliveryFeesInCart = useSelectorShared(getHasScheduleableDeliveryFeesInCart);

  const lineItemHash = useMemo(
    () =>
      (orderPricing?.line_items || []).reduce<Record<LineItemType, LineItem | null>>(
        (acc, lineItem) => {
          acc[lineItem.key] = lineItem;
          return acc;
        },
        { ...initialLineItemHash },
      ),
    [orderPricing?.line_items],
  );
  const deliveryFeeLineItems = TypescriptUtils.objectValues(lineItemHash)
    .filter((lineItem) => lineItem?.key.endsWith('delivery_fee'))
    .filter(TypescriptUtils.isPresent);

  // If the cart is eligible for delivery fees, and no order date is selected, we don't want to show any line item
  // related to delivery fees yet. Only show if it's not eligible or there is an order date selected
  const showDeliveryFeeLineItems = !hasScheduleableDeliveryFeesInCart || !!orderDate;

  return (
    <>
      {prescriptions.map((prescription) => {
        let pricing = orderPricing?.item_pricing?.[prescription.id];
        if (forSinglePrescription) {
          pricing = prescriptionPricing[prescription.id];
        }

        return (
          <Fragment key={prescription?.id}>
            <ListItem
              title={prescription?.medication_name}
              descriptions={[
                <ListDescription key={prescription?.medication_name}>
                  {prescription?.product_quantity?.toLocaleLowerCase()}
                </ListDescription>,
              ]}
              fullBleed
            />
            <SmSpacing />
            <LineItemsContainer>
              {pricing ? (
                <ItemPaymentBreakdown
                  pricingItem={pricing}
                  origin={origin}
                  eventUuid={eventUuid}
                />
              ) : null}
            </LineItemsContainer>
            <LgSpacing />
          </Fragment>
        );
      })}
      {orderPricing && !forSinglePrescription ? (
        <>
          <Separator />
          {lineItemHash.essentials_only_fee ? (
            <PricingBreakdownOrderLineItem lineItem={lineItemHash.essentials_only_fee} />
          ) : null}
          {showDeliveryFeeLineItems &&
            deliveryFeeLineItems.map((lineItem) => {
              return (
                <PricingBreakdownOrderLineItem
                  key={lineItem.key}
                  lineItem={lineItem}
                />
              );
            })}
          {showDeliveryFeeLineItems && lineItemHash.pickup ? (
            <PricingBreakdownOrderLineItem lineItem={lineItemHash.pickup} />
          ) : null}
          {lineItemHash.tip_amount?.value ? <PricingBreakdownOrderLineItem lineItem={lineItemHash.tip_amount} /> : null}
          {lineItemHash.total ? (
            <PricingBreakdownOrderLineItem
              lineItem={lineItemHash.total}
              noSeparator
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};
