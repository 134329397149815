import { withRehydration } from './withRehydration';
import {
  type ModalType,
  type changeModalStep,
  type closeModal,
  type nextModalStep,
  type openModal,
  type openOriginModal,
  type previousModalStep,
} from '~shared/actions/modal';

type BaseModalState = {
  modalType: ModalType | null;
  modalProps: Record<string, unknown> | undefined;
  currentStep: number;
};

export type ModalState = {
  show?: boolean;
  originModal: BaseModalState;
} & BaseModalState;

const initialOriginModalState = {
  modalType: null,
  modalProps: {},
  currentStep: 0,
};

const initialState: ModalState = {
  show: false,
  modalType: null,
  modalProps: {},
  currentStep: 0,
  originModal: initialOriginModalState,
};

export type ModalActions =
  | ReturnType<typeof openModal>
  | ReturnType<typeof closeModal>
  | ReturnType<typeof changeModalStep>
  | ReturnType<typeof nextModalStep>
  | ReturnType<typeof previousModalStep>
  | ReturnType<typeof openOriginModal>;

const modal = (state: ModalState = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        show: true,
        modalType: action.payload?.type,
        modalProps: action.payload?.props,
        currentStep: 0,
        originModal: {
          modalType: state.modalType,
          modalProps: state.modalProps,
          currentStep: state.currentStep,
        },
      };

    case 'CLOSE_MODAL':
      if (action.payload === state.modalType) {
        return { ...state, show: false, modalType: null, modalProps: {} };
      }
      return state;
    case 'CHANGE_MODAL_STEP':
      return { ...state, currentStep: action.payload };

    case 'NEXT_MODAL_STEP':
      return { ...state, currentStep: state.currentStep + 1 };

    case 'PREVIOUS_MODAL_STEP':
      return { ...state, currentStep: state.currentStep - 1 };

    case 'OPEN_ORIGIN_MODAL':
      return {
        ...state,
        modalType: state.originModal.modalType,
        modalProps: state.originModal.modalProps,
        currentStep: state.originModal.currentStep,
        originModal: { ...initialOriginModalState },
      };

    default:
      return state;
  }
};

export default withRehydration(modal, initialState);
