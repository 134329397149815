// @owners { team: patients-team }
import React from 'react';
import { openModal } from '~shared/actions/modal';
import { useGetPaymentMethods } from '~shared/queries/query-keys/paymentMethods';
import { useDispatchShared } from '~shared/store';
import AddPaymentMethodButton from './AddPaymentMethodButton';
import PaymentMethodDropdown from './PaymentMethodDropdown';

export type Props = {
  paymentMethodID: number | null | undefined;
  selectPaymentMethodID: (paymentMethodID: number) => void;
};

export const ChoosePaymentMethod = ({ paymentMethodID, selectPaymentMethodID }: Props) => {
  const dispatch = useDispatchShared();
  const { paymentMethods } = useGetPaymentMethods();
  const openCreditCardModal = () => {
    dispatch(openModal('COURIER_TIPS_CREDIT_CARD_MODAL'));
  };

  let content;

  if (paymentMethods?.length) {
    content = (
      <PaymentMethodDropdown
        openCreditCardModal={openCreditCardModal}
        paymentMethodID={paymentMethodID}
        paymentMethods={paymentMethods}
        selectPaymentMethodID={selectPaymentMethodID}
      />
    );
  } else {
    content = <AddPaymentMethodButton openCreditCardModal={openCreditCardModal} />;
  }

  return content;
};
