// @owners { team: patients-team }
export const SUPPORT_KINDS = {
  FEEDBACK: 'feedback',
  TECHNICAL_ISSUE: 'bug',
};

export const SEND_MESSAGE_FORK = {
  BODY: 'I need help with a prescription, a delivery or my account.',
  CTA_TEXT: 'Send us a message',
  CTA_TEXT_WEB: 'Send Us a Message',
};

export const FEEDBACK_FORK = {
  BODY: 'I have an idea to make Alto better.',
  CTA_TEXT: 'Share feedback',
  CTA_TEXT_WEB: 'Share Feedback',
};

export const CONTACT_SUPPORT_HEADER = {
  TITLE: 'We’re here to help!',
};

export const CONTACT_SUPPORT_SUCCESS_TOAST = (isTechIssue: boolean | null | undefined) =>
  `${isTechIssue ? 'Report' : 'Feedback'} submitted! Thanks for ${
    isTechIssue ? 'your feedback' : 'sharing your thoughts'
  }.`;

export const FEEDBACK_FORM = {
  HEADER:
    'We’re always looking for ways to improve your Alto experience. We review all feedback but are unable to respond individually. If you are experiencing an issue with a prescription, a delivery or your account, please',
  PLACEHOLDER_TEXT: 'Type your feedback...',
};
