import React, { type ReactElement } from 'react';
import * as ReactIs from 'react-is';
import { View } from 'react-native';
import styled, { ThemeProvider, css } from 'styled-components/native';
import { useScreenSize } from '../../../utils';
import { getCssFromOrientation } from './helpers';
import { type StationType, type Status, type VerticalEnabled } from './types';

type Props = {
  readonly activeStation?: string | null | undefined;
  readonly verticalEnabled?: VerticalEnabled;
  readonly extendRails?: 'first' | 'last' | 'both';
  readonly status?: Status;
  readonly children: React.ReactNode;
};

const Stations = styled(View)<Pick<Props, 'verticalEnabled' | 'children'> & { isMDScreenOrBigger: boolean }>`
  ${({ verticalEnabled, isMDScreenOrBigger }) =>
    getCssFromOrientation({
      verticalEnabled,
      isMDScreenOrBigger,
      verticalCss: css`
        flex-direction: column;
      `,
      horizontalCss: css`
        flex-direction: row;
      `,
    })}
`;

export const Subway = ({ activeStation, verticalEnabled = 'mobile', extendRails, status, children }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  // Fragments as a child is treated as 1 child instead of its children
  const stations: ReactElement[] = ReactIs.isFragment(children)
    ? children.props.children
    : React.Children.toArray(children);
  const activeIndex = stations.findIndex((station) => station.props.id === activeStation);
  const markAllStationsComplete =
    status === 'completed' || (activeIndex === stations.length - 1 && status !== 'failed');

  return (
    <Stations
      verticalEnabled={verticalEnabled}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      {React.Children.map(stations, (child, i) => {
        const isFirst = i === 0;
        const isFinal = i === stations.length - 1;

        let type: StationType;
        if (markAllStationsComplete) {
          type = 'completed';
        } else if (i === activeIndex) {
          type = 'active';
        } else if (i < activeIndex) {
          type = status || 'completed';
        } else {
          type = 'inactive';
        }

        const stationContext = {
          numStations: stations.length,
          verticalEnabled,
          isFirst,
          isFinal,
          type,
          status,
          extendRails,
        };

        return (
          <ThemeProvider
            theme={stationContext}
            key={child.props.id}
          >
            {child}
          </ThemeProvider>
        );
      })}
    </Stations>
  );
};
