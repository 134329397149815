// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, LgSpacing, ListBase, MdPadding, Row, XsSpacing } from '@alto/design-system';
import React from 'react';
import { COURIER_TIP_COPY } from '~shared/features/courier-tips/constants';

type Props = {
  readonly onCloseModal: () => void;
};

type TextListProps = {
  readonly listItems: string[];
};

const UnorderedTextList = ({ listItems }: TextListProps) => {
  const initialPadding = {
    key: 'padding',
    component: <LgSpacing />,
  };

  const itemProps = [initialPadding].concat(
    listItems.map((item) => ({
      key: item,
      component: (
        <MdPadding topPadding={SPACING.STATIC.NONE}>
          <Row>
            <Body
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.PRIMARY}
            >
              •
            </Body>
            <XsSpacing />
            <Body
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.PRIMARY}
            >
              {item}
            </Body>
          </Row>
        </MdPadding>
      ),
    })),
  );

  return <ListBase items={itemProps} />;
};

export const TipInfoActionSheet = ({ onCloseModal }: Props) => (
  <ActionSheetV2
    handleClose={onCloseModal}
    title="Courier tipping"
    analyticsName="checkout courier tip"
  >
    <LgPadding topPadding={SPACING.STATIC.NONE}>
      <UnorderedTextList listItems={COURIER_TIP_COPY.INFO_BULLETS} />
    </LgPadding>
  </ActionSheetV2>
);
