// @owners { team: patients-team }
import { push } from 'react-router-redux';
import { connect } from '~shared/store';
import { type ReduxDispatchShared } from '~shared/types';
import ProfileContactSupport from './ProfileContactSupport';

type DispatchProps = {
  openMessages: () => void;
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared): DispatchProps => {
  return {
    openMessages: () => {
      dispatch(push('/help'));
    },
  };
};

export default connect(null, mapDispatchToProps)(ProfileContactSupport);
