// @owners { team: patients-team }
import { Button, Card, LgPadding, MdSpacing, SmSpacing, Toast, ToastContext, XlSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import { useController, useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-deprecated
import { createInsurance, updateInsurance } from '~shared/actions/insurances';
// eslint-disable-next-line import/no-deprecated
import { updateUser } from '~shared/actions/users';
import { type InsuranceSource } from '~shared/constants';
import { getInferredInsuranceFormValues } from '~shared/features/insurances/helpers/getInferredInsuranceFormValues';
import { getInitialInsuranceFormValues } from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getPossessiveName } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types';
import { useInsuranceWarningActionSheet } from '../../insurance';
import {
  InsuranceBinInput,
  InsuranceGroupInput,
  InsuranceMemberIdInput,
  InsuranceNicknameInput,
  InsurancePcnInput,
  InsuranceRelationshipInputSelect,
} from '../../onboarding';

type Props = {
  insuranceID?: number;
  onCancel: () => void;
  source?: InsuranceSource;
};

type BenefitFormValues = {
  member_id: string;
  bin: string;
  group: string;
  pcn: string;
  relationship_code: string;
  nickname: string;
  first_name: string; // required when relationship code is not self
  last_name: string; // required when relationship code is not self
};

export const BenefitForm = ({ insuranceID, onCancel, source }: Props) => {
  const queryClient = useQueryClient();
  const { goBack, navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const selectedInsurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const dispatch = useDispatchShared();
  const selectedUser = useSelectorShared(getSelectedUser); // for selected family member
  const currentUser = useSelectorShared(getCurrentUser);
  const user = selectedUser || currentUser;
  const loading = useSelectorShared(
    (state) => state.ui.loading.createInsuranceLoading || state.ui.loading.updateInsuranceLoading,
  );
  const familyMemberName = selectedUser?.preferred_first_name || selectedUser?.first_name || undefined;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<BenefitFormValues>({
    defaultValues: getInitialInsuranceFormValues(selectedInsurance),
  });

  const memberIdController = useController({
    control,
    name: 'member_id',
    rules: { required: 'Member ID cannot be blank' },
  });

  const binController = useController({
    control,
    name: 'bin',
    rules: { required: 'Rx BIN cannot be blank' },
  });

  const groupController = useController({
    control,
    name: 'group',
    rules: { required: false },
  });

  const pcnController = useController({
    control,
    name: 'pcn',
    rules: { required: false },
  });

  const relationshipController = useController({
    control,
    name: 'relationship_code',
    rules: { required: 'Relationship cannot be blank' },
  });

  const nicknameController = useController({
    control,
    name: 'nickname',
    rules: { required: false },
  });

  const firstNameController = useController({
    control,
    name: 'first_name',
    rules: {
      validate: (value = '', { relationship_code }) => {
        if (relationship_code && relationship_code !== '1' && !value.trim()) {
          return 'First name cannot be blank';
        }
        return true;
      },
    },
  });

  const lastNameController = useController({
    control,
    name: 'last_name',
    rules: {
      validate: (value = '', { relationship_code }) => {
        if (relationship_code && relationship_code !== '1' && !value.trim()) {
          return 'Last name cannot be blank';
        }
        return true;
      },
    },
  });

  const { showActionSheet } = useInsuranceWarningActionSheet({ handleContinue: goBack });

  const handleCashPayPatient = async () => {
    if (user?.cash_pay_only) {
      const { success } = await dispatch(
        // eslint-disable-next-line import/no-deprecated
        updateUser({
          id: user.id,
          cash_pay_only: false,
        }),
      );

      if (success) {
        trackEvent({
          event: EVENTS.CASH_PAY_ONLY_SET,
          params: {
            source,
            'new value': false,
          },
        });
      } else {
        addToast(<Toast variant="error">Unable to update preference to pay with cash.</Toast>);
      }
    }
  };

  const handleNavigate = (insurance: Insurance | false) => {
    if (insurance === false) {
      if (!showActionSheet) {
        addToast(<Toast variant="error">Unable to save insurance.</Toast>);
      }
    } else {
      if (!user) return;
      queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });

      if (source === 'add family member') {
        addToast(
          <Toast variant="success">{capitalize(getPossessiveName(familyMemberName))} insurance has been saved!</Toast>,
        );
        navigate('RouteFamilyMemberCompleteProfile', { userID: user.id });
      } else if (source === 'onboarding') {
        addToast(<Toast variant="success">Your insurance has been saved!</Toast>);
        navigate('RouteOnboardingInsurance');
      } else {
        // go back to patient profile instead of the add benefits page
        addToast(<Toast variant="success">Your insurance has been saved!</Toast>);
        navigate('RouteUserProfile', { userID: user.id });
      }
    }
  };

  const handleUpdateBenefit = () => {
    handleSubmit(async (formValues) => {
      if (!user || !selectedInsurance) return;

      const values = getInferredInsuranceFormValues(formValues, user);
      // eslint-disable-next-line import/no-deprecated
      const insurance = await dispatch(updateInsurance({ ...values, id: selectedInsurance.id }));

      handleNavigate(insurance);
      handleCashPayPatient();
    })();
  };

  const handleSaveBenefit = () => {
    handleSubmit(async (formValues) => {
      if (!user) return;

      const values = getInferredInsuranceFormValues(formValues, user);
      const insurance: Insurance | false = await dispatch(
        // eslint-disable-next-line import/no-deprecated
        createInsurance({
          user_id: user.id,
          ...values,
        }),
      );
      if (insurance) {
        trackEvent({
          event: EVENTS.INSURANCE_ADDED,
          params: {
            source,
            type: 'manual',
            entity_name: insurance.insurance_plan_name ?? '',
          },
        });
      }

      reset(); // reset form fields
      handleNavigate(insurance);
      handleCashPayPatient();
    })();
  };

  return (
    <Card>
      <LgPadding>
        <InsuranceMemberIdInput
          onChange={memberIdController.field.onChange}
          value={memberIdController.field.value}
          error={memberIdController.fieldState.error?.message}
        />
        <MdSpacing />
        <InsuranceBinInput
          onChange={binController.field.onChange}
          value={binController.field.value}
          error={binController.fieldState.error?.message}
        />
        <MdSpacing />
        <InsuranceGroupInput
          onChange={groupController.field.onChange}
          value={groupController.field.value}
          error={groupController.fieldState.error?.message}
        />
        <MdSpacing />
        <InsurancePcnInput
          onChange={pcnController.field.onChange}
          value={pcnController.field.value}
          error={pcnController.fieldState.error?.message}
        />
        <MdSpacing />
        <InsuranceRelationshipInputSelect
          firstName={firstNameController.field.value}
          firstNameError={firstNameController.fieldState.error?.message}
          lastName={lastNameController.field.value}
          lastNameError={lastNameController.fieldState.error?.message}
          onChangeFirstName={firstNameController.field.onChange}
          onChangeLastName={lastNameController.field.onChange}
          onChangeRelationship={relationshipController.field.onChange}
          relationshipCode={relationshipController.field.value}
          relationshipCodeError={relationshipController.fieldState.error?.message}
          familyMemberName={familyMemberName}
        />
        <MdSpacing />
        <InsuranceNicknameInput
          onChange={nicknameController.field.onChange}
          value={nicknameController.field.value}
          error={nicknameController.fieldState.error?.message}
        />
        <XlSpacing />
        <Button
          label={selectedInsurance ? 'Update benefit' : 'Save benefit'}
          onPress={selectedInsurance ? handleUpdateBenefit : handleSaveBenefit}
          loading={loading}
          disabled={isSubmitting}
        />
        <SmSpacing />
        <Button
          label="Cancel"
          onPress={onCancel}
          type="tertiary"
        />
      </LgPadding>
    </Card>
  );
};
