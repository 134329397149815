import { type Prescription as HomescreenPrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type SourcePrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/source_prescription';
import {
  type PrescriptionStatus,
  PrescriptionStatusMap,
} from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import { type Prescription } from '~shared/types';

// Subway station IDs
const {
  RECEIVED_BY_ALTO,
  PHARMACIST_REVIEW,
  SEARCHING_FOR_SAVINGS,
  READY,
  PRIOR_AUTH_IN_PROGRESS,
  PROGYNY_EARLY_ORDER_ELIGIBLE,
  PROGYNY_EARLY_ORDER_REQUESTED,
  PROGYNY_EXPIRED_COVERAGE,
} = PrescriptionStatusMap;
export const RECEIVED = 'received_by_alto';
export const STATIONS: ConstantStatusTypes[] = [RECEIVED_BY_ALTO, PHARMACIST_REVIEW, SEARCHING_FOR_SAVINGS, READY];

// Subway statuses
export const NOT_STARTED = 'not_started';
export const IN_PROGRESS = 'in_progress';
export const COMPLETED = 'completed';

const CONJUGATED_TITLES = {
  [RECEIVED]: {
    past: 'Prescription received',
    present: 'Prescription received',
    future: '',
  },
  [PHARMACIST_REVIEW]: {
    past: 'Pharmacist reviewed',
    present: 'Pharmacist reviewing',
    future: 'Pharmacist review',
  },
  [SEARCHING_FOR_SAVINGS]: {
    past: 'Finalized best price',
    present: 'Finalizing best price',
    future: 'Finalize best price',
  },
  [READY]: {
    past: '',
    present: 'Ready to schedule your delivery',
    future: 'Schedule your delivery',
  },
  // dynamic stations that may or may not show
  [PRIOR_AUTH_IN_PROGRESS]: {
    past: 'Prior authorization',
    present: 'Prior authorization',
    future: 'Prior authorization',
  },
  [PROGYNY_EARLY_ORDER_ELIGIBLE]: {
    past: 'Progyny Authorization Received',
    present: 'Waiting on Progyny',
    future: '',
  },
  [PROGYNY_EARLY_ORDER_REQUESTED]: {
    past: 'Progyny Authorization Received',
    present: 'Waiting on Progyny',
    future: '',
  },
  [PROGYNY_EXPIRED_COVERAGE]: {
    past: 'Progyny Authorization Received',
    present: 'Waiting on Progyny',
    future: '',
  },
};

export type Tense = 'past' | 'present' | 'future'; // Subway station to title/description constants

export type ConstantStatusTypes = Exclude<PrescriptionStatus, 'multiple_prices' | 'backordered' | 'obsoleted'>;
export const getStationTitleAndDescription: Record<
  ConstantStatusTypes,
  {
    title: (tense?: Tense, isProgyny?: boolean) => string;
    description: (
      medications?: (SourcePrescription | HomescreenPrescription | Prescription | null)[],
      isProgyny?: boolean,
    ) => string;
  }
> = {
  [RECEIVED]: {
    title: (tense = 'present') => CONJUGATED_TITLES[RECEIVED][tense],
    description: () => 'Hang tight! We’ve got your prescription.',
  },
  [PHARMACIST_REVIEW]: {
    title: (tense = 'present') => CONJUGATED_TITLES[PHARMACIST_REVIEW][tense],
    description: () => 'Double checking your prescription details.',
  },
  [SEARCHING_FOR_SAVINGS]: {
    title: (tense = 'present', isProgyny) =>
      isProgyny ? 'Progyny authorization pending' : CONJUGATED_TITLES[SEARCHING_FOR_SAVINGS][tense],
    description: (_, isProgyny) =>
      isProgyny
        ? 'We are currently waiting on authorization approval from Progyny.'
        : 'Getting you the lowest cost possible by billing insurance and finding coupons.',
  },
  [PRIOR_AUTH_IN_PROGRESS]: {
    title: (tense = 'present') => CONJUGATED_TITLES[PRIOR_AUTH_IN_PROGRESS][tense],
    description: () =>
      "We're working with your insurance on additional details needed to cover this medication.\n\nTypically this takes two to seven days.",
  },
  [PROGYNY_EARLY_ORDER_ELIGIBLE]: {
    title: (tense = 'present') => CONJUGATED_TITLES[PROGYNY_EARLY_ORDER_ELIGIBLE][tense],
    description: () =>
      'Progyny authorization is needed for new cycles and can take 1-7 days. Use "Order Early" to speed things up.',
  },
  [PROGYNY_EARLY_ORDER_REQUESTED]: {
    title: (tense = 'present') => CONJUGATED_TITLES[PROGYNY_EARLY_ORDER_REQUESTED][tense],
    description: () =>
      "Progyny authorization is needed for new cycles and can take 1-7 days. You've requested to order early and we're working on it",
  },
  [PROGYNY_EXPIRED_COVERAGE]: {
    title: (tense = 'present') => CONJUGATED_TITLES[PROGYNY_EXPIRED_COVERAGE][tense],
    description: () =>
      'Your Progyny authorization expired. If you need this medication, please reach out to us so we can request an extension or new authorization.',
  },
  [READY]: {
    title: (tense: Tense = 'present') => CONJUGATED_TITLES[READY][tense],
    description: (medications = []) =>
      `${
        medications.length > 1 ? 'These medications are' : 'This medication is'
      } ready! Place your order for delivery to your door.`,
  },
};

export const buildDynamicStations = (rxStatus: PrescriptionStatus | undefined) => {
  // in the event this station is not in the pre-built out of the box station list STATIONS, add the new station
  const copyStations: ConstantStatusTypes[] = [...STATIONS];
  if (rxStatus === PRIOR_AUTH_IN_PROGRESS) {
    copyStations.splice(2, 0, PRIOR_AUTH_IN_PROGRESS);
  }
  if (rxStatus === PROGYNY_EARLY_ORDER_ELIGIBLE) {
    copyStations.splice(2, 0, PROGYNY_EARLY_ORDER_ELIGIBLE);
  }
  if (rxStatus === PROGYNY_EARLY_ORDER_REQUESTED) {
    copyStations.splice(2, 0, PROGYNY_EARLY_ORDER_REQUESTED);
  }
  if (rxStatus === PROGYNY_EXPIRED_COVERAGE) {
    copyStations.splice(2, 0, PROGYNY_EXPIRED_COVERAGE);
  }
  return copyStations;
};
