// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getUsers } from '~shared/features/users/selectors/getUsers';
import { getDisplayName } from '~shared/helpers/helper';
import { formatListMaxTwo } from '~shared/helpers/list';
import { type UserIDsParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared, type User } from '~shared/types';

const getUserIDs = (_: ReduxStateShared, props: UserIDsParam) => props.userIDs;

const getUserNames = createSelector(getUsers, getUserIDs, (users, userIDs) => {
  // Index users by ID
  const usersByID = users.reduce<Record<number, User>>((indexed, user) => {
    indexed[user.id] = user;
    return indexed;
  }, {});

  // filter for any undefineds, if all undefined, return null
  const filteredUserIDs = userIDs.filter((id) => id !== undefined) as number[];
  if (filteredUserIDs.length === 0) {
    return null;
  }

  return filteredUserIDs.reduce<string[]>((userNames, id) => {
    const user = usersByID[id];

    if (user) {
      // Fallback name should never happen, but putting here just in case
      userNames.push(getDisplayName(user) || ' ');
    }
    return userNames;
  }, []);
});

export const getUserTagText = createSelector(getUserNames, (userNames) => {
  // TODO make sure You is always first
  return `For ${formatListMaxTwo(userNames || []) || ''}`;
});
