import { useCallback, useEffect } from 'react';
import { type Actions, inputBlurred } from '../actions';

type HandleClickOutsideDeps = {
  dispatch: React.Dispatch<Actions>;
  /**
   * This should be the InputText component ref. TS thinks that this hook is being used in
   * a React Native context, but this hook is only used on web.
   *
   * I've purposefully typed this as a generic 'any' because 'current' will actually
   * be an HTMLInputElement (what it is on the web) instead of a TextInput (what TS thinks it is).
   */
  inputComponent: { current: any };
};

/**
 * A hook that handles blurring the input if the user clicks outside of it.
 * We can't use onBlur on InputText because it'll interrupt selecting the correct autocomplete result in the dropdown.
 */
export const useHandleClickOutside = ({ dispatch, inputComponent }: HandleClickOutsideDeps) => {
  const handleClickOutside = useCallback(
    (event: Event | null) => {
      if (event && event.target !== inputComponent.current) {
        dispatch(inputBlurred());
      }
    },
    [dispatch, inputComponent],
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
};
