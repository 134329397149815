// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { type ReduxStateShared } from '~shared/types';
import { type Address } from '~shared/types/clients';

export type AddressOption = {
  label: string;
  value: number | string;
};

// Only web uses a dropdown select for addresses.
const getAddressOptions: (arg0: ReduxStateShared) => AddressOption[] = createSelector(
  [getAddresses],
  (addresses: Address[]): AddressOption[] => {
    const list = addresses.map((addr) => {
      let streetAddress = addr.street_address_1 || '';

      if (addr.street_address_2) {
        streetAddress += ` ${addr.street_address_2}`;
      }

      if (addr.kind) {
        return {
          value: addr.id,
          label: `${addr.kind} - ${streetAddress}`,
        };
      }

      return {
        value: addr.id,
        label: streetAddress,
      };
    });

    list.push({
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
      value: 'NEW_ADDRESS',
      label: '+ New Address',
    });

    return list;
  },
);

export default getAddressOptions;
