// map to SPECIALTY_STATUSES in scriptdash:
// https://github.com/scriptdash/scriptdash/blob/6dbc9ec835a9a476fece299e8aa1886998184487/config/initializers/constants/constants.rb#L542
export const SPECIALTY_STATUS = {
  ENDOCRINOLOGY: 'endocrinology',
  INCUBATION: 'incubation',
  FERTILITY: 'fertility',
  CARDIOLOGY: 'cardiology',
  PULMONARY: 'pulmonary',
  SPECIALTY: 'specialty',
  SPECIALTY_PREP: 'specialty-prep',
  SPECIALTY_HIV: 'specialty-hiv',
  CORE: 'core',
  DERMATOLOGY: 'dermatology',
  // Not sure if these are used client side
  BLUE_SHIELD: 'blue-shield',
  RETAIL: 'retail',
  QUIT_PROGRAM: 'quit-program',
} as const;

export type SpecialtyStatus = (typeof SPECIALTY_STATUS)[keyof typeof SPECIALTY_STATUS] | null | undefined;
