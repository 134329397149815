// stolen from scriptdash helpers/sort
// sorts in asc order
// try to only parse each unique date once in a given sort.
export function makeCachedSortByISODateString() {
  const dateCache: Record<string, Date> = {};

  // assumes you're giving it an iso date string like "2018-03-23T15:00:00.000-07:00"
  // @ts-expect-error TS(7006): Parameter 'iso' implicitly has an 'any' type.
  const parseDate = (iso) => {
    let d = dateCache[iso];
    if (d) return d;

    d = new Date(iso);
    dateCache[iso] = d;
    return d;
  };

  return (aVal: string | null | undefined, bVal: string | null | undefined) => {
    if (aVal === bVal) return 0;
    if (!aVal) return 1;
    if (!bVal) return -1;

    const aDate = parseDate(aVal);
    const bDate = parseDate(bVal);

    return aDate < bDate ? -1 : 1;
  };
}
