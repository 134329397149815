// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import Clipboard from '@react-native-clipboard/clipboard';
import React, { useCallback, useContext } from 'react';
import { Linking, Platform } from 'react-native';
import { Sentry } from '~shared/sentry';

export const useOpenTrackingLink = () => {
  const { addToast } = useContext(ToastContext);

  const openTrackingLink = useCallback(
    async (trackingLink: string) => {
      // Copying link is a fallback solution for failing to open link
      // https://github.com/scriptdash/clients/pull/7743
      if (Platform.OS === 'web') {
        await navigator.clipboard.writeText(trackingLink);
      } else {
        Clipboard.setString(trackingLink);
      }
      addToast(<Toast delay={500}>Tracking link copied.</Toast>);

      try {
        const canOpenTrackingLink = await Linking.canOpenURL(trackingLink);

        if (!canOpenTrackingLink) {
          throw new Error('Cannot open tracking link');
        }

        Linking.openURL(trackingLink);
      } catch (error) {
        Sentry.captureMessage('Cannot open tracking link', {
          contexts: { 'Tracking link error': { error, trackingLink } },
          level: 'warning',
        });
      }
    },
    [addToast],
  );

  return { openTrackingLink };
};
