import { Body } from '@alto/design-system';
import { useInsuranceWarningActionSheet } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, DeprecatedModalBody, DeprecatedModalFooter, FlexColumn, InfoBox, LoadingButton } from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Form, Formik } from 'formik';
import React, { type MouseEventHandler, useCallback, useEffect, useState } from 'react';
import {
  type Values,
  getInitialInsuranceFormValues,
} from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type Insurance } from '~shared/types/clients';
import InsuranceFormElements, { validate } from '~web/features/insurances/components/InsuranceFormElements';

type Props = {
  readonly onSubmit: (insurance: Values) => void;
  readonly onCancel: () => void;
  readonly insuranceError: Error | null | undefined;
  readonly selectedInsurance: Insurance | null | undefined;
  readonly showPhotoForm?: () => void;
  readonly loading: boolean;
}; // Formik doesn't have a way to validateOnChange and validateOnBlur after first
// submit so we have to keep track of that ourselves

const InsuranceForm = ({ insuranceError, onCancel, onSubmit, selectedInsurance, showPhotoForm, loading }: Props) => {
  const { trackPageView } = useAnalytics();

  const [dirty, setDirty] = useState<boolean>(false);
  const submitText = selectedInsurance ? 'Update' : 'Add';
  const markDirty = useCallback(() => {
    setDirty(true);
  }, []);
  const { showActionSheet } = useInsuranceWarningActionSheet({ handleContinue: onCancel });

  useEffect(() => {
    trackPageView({
      event: EVENTS.ADD_INSURANCE_VIEWED,
    });
  }, [trackPageView]);

  return (
    <Formik
      initialValues={getInitialInsuranceFormValues(selectedInsurance)}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={dirty}
      validateOnBlur={dirty}
      enableReinitialize
    >
      {(formikProps) => (
        <Form
          {...formikProps}
          noValidate
        >
          <DeprecatedModalBody>
            <InfoBox title="Where can I find this?">
              <Body>
                Typically, your prescription benefit card will have an &quot;Rx&quot; symbol and &quot;BIN&quot; and
                &quot;PCN&quot; numbers.
              </Body>
            </InfoBox>
            <InsuranceFormElements values={formikProps.values} />
            {!selectedInsurance && (
              <InfoBox>
                <FlexColumn
                  evenlySpaceContent
                  verticallyAlignContent
                >
                  <Body>Or you can upload a photo of your benefit card</Body>
                  <Button
                    kind="secondary"
                    onClick={showPhotoForm as unknown as MouseEventHandler<HTMLButtonElement>}
                  >
                    Upload Photos
                  </Button>
                </FlexColumn>
              </InfoBox>
            )}
            {insuranceError && !showActionSheet ? (
              <InfoBox
                danger
                title="Something went wrong"
              >
                <Body>There was an error submitting your benefit information. Please try again.</Body>
              </InfoBox>
            ) : null}
          </DeprecatedModalBody>
          <DeprecatedModalFooter>
            <Button
              kind="tertiary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              type="submit"
              onClick={markDirty}
            >
              {submitText} benefits
            </LoadingButton>
          </DeprecatedModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default InsuranceForm;
