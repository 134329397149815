import { type Insurance } from '~shared/types';

export type Values = {
  relationship_code?: string;
  first_name?: string;
  last_name?: string;
  member_id?: string;
  bin?: string;
  group?: string;
  pcn?: string;
  nickname?: string;
};

export const getInitialInsuranceFormValues = (insurance: Insurance | null | undefined) => {
  const { relationship_code, first_name, last_name, member_id, bin, group, pcn, nickname } = insurance || {};
  return {
    relationship_code: relationship_code || '',
    first_name: first_name || '',
    last_name: last_name || '',
    member_id: member_id || '',
    bin: bin || '',
    group: group || '',
    pcn: pcn || '',
    nickname: nickname || '',
  };
};
