// @owners { team: patients-team }
import { ActionSheetV2 } from '@alto/design-system';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { STRIPE_PUBLISHABLE_KEY } from '~shared/config';
import CreditCardForm from './CreditCardForm';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export type Props = {
  readonly onClose: () => void;
};

export function CreditCardActionSheet({ onClose }: Props) {
  return (
    <ActionSheetV2
      title="Add Credit Card"
      handleClose={onClose}
      analyticsName="credit card"
    >
      <Elements stripe={stripePromise}>
        <CreditCardForm onClose={onClose} />
      </Elements>
    </ActionSheetV2>
  );
}
