// @owners { team: patients-team }
import { TYPOGRAPHY } from '@alto/design-library-tokens';
// eslint-disable-next-line import/no-deprecated
import { Body, CenteredActivityIndicator, Column, Description, InlineAlert, MdPadding } from '@alto/design-system';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
// eslint-disable-next-line import/no-deprecated
import { fetchPrescriptions } from '~shared/actions/prescriptions';
import { useProduct } from '~shared/queries';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { PageBackground } from '~web/components/Page';

const Markdown = styled(ReactMarkdown)`
  > p {
    font-size: ${TYPOGRAPHY.TEXT.BODY.MD.rem};

    > strong {
      font-size: ${TYPOGRAPHY.TEXT.HEADER.SM.rem};
    }
  }
`;

const MissingInfoPrompt = () => (
  <Body>
    We don&#39;t seem to have any additional information on file for this product. If you have questions regarding your
    prescription, feel free to send us a message.
  </Body>
);

type Props = {
  readonly params: { rx_number: string };
};

export const DrugInfo = ({ params }: Props) => {
  const dispatch = useDispatchShared();
  const selectedPrescription = useSelectorShared((state) =>
    state.prescriptions.prescriptions.find((rx: Prescription) => rx.rx_number === params.rx_number),
  );
  const { isError, isProductPending, product } = useProduct({ productID: selectedPrescription?.product_id });

  useEffect(() => {
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchPrescriptions());
  }, [dispatch]);

  const breadcrumbs = [
    { text: 'Prescriptions', to: '/prescriptions' },
    { text: (product ? product.name : selectedPrescription?.medication_name) ?? '' },
  ];

  if (isError && !product) {
    return (
      <PageBackground>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <InlineAlert type="error">
          <Column
            center
            gap="SM"
          >
            <Description>Something went wrong loading product information.</Description>
            <Description>Please try again or message support if the problem persists.</Description>
          </Column>
        </InlineAlert>
      </PageBackground>
    );
  }

  if (isProductPending && !product) {
    return (
      <PageBackground>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Column>
          <MdPadding>
            <CenteredActivityIndicator accessibilityHint="loading product info" />
          </MdPadding>
          <Body center>Loading product info...</Body>
        </Column>
      </PageBackground>
    );
  }

  return (
    <PageBackground>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {product?.monograph ? <Markdown source={product.monograph} /> : <MissingInfoPrompt />}
    </PageBackground>
  );
};
