// @owners { team: patients-team }
import {
  ActionSheetContext,
  Description,
  InlineAlert,
  Link,
  MdSpacing,
  PressableAltoIcon,
  Row,
  XsSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React, { useContext, useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { PriceIncreaseActionSheet } from './PriceIncreaseActionSheet';

export const PriceIncreaseAlert = () => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { value: billingStormEnabled } = Experimentation.useFeatureFlag('billing_storm');
  const [dismiss, setDismiss] = React.useState(false);

  useEffect(() => {
    if (billingStormEnabled) {
      trackEvent({
        event: EVENTS.PRICE_INCREASE_ALERT_VIEWED,
      });
    }
  }, [billingStormEnabled, trackEvent]);

  const handleDismiss = () => {
    setDismiss(true);
    trackEvent({
      event: EVENTS.PRICE_INCREASE_ALERT_DISMISSED,
    });
  };

  if (!billingStormEnabled || dismiss) return null;

  return (
    <>
      <MdSpacing />
      <InlineAlert
        type="warning"
        data-testid="priceIncreaseAlert"
      >
        <>
          <Row spaceBetween>
            <Description fontFamily="bold">Need support regarding a medication price increase?</Description>
            <PressableAltoIcon
              name="close"
              accessibilityLabel="dismiss price increase alert"
              onPress={handleDismiss}
            />
          </Row>
          <XsSpacing />
          <Description>
            Contact your insurance provider directly as they will be best equipped to provide an explanation about your
            specific benefits.
          </Description>
          <XsSpacing />
          <Link
            accessibilityLabel="more info about price change"
            onPress={() => {
              setActiveActionSheet(<PriceIncreaseActionSheet />);
            }}
            textSize="mini"
          >
            Why did my price change?
          </Link>
        </>
      </InlineAlert>
      <MdSpacing />
    </>
  );
};
