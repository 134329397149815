// @owners { team: patients-team }
import { Button } from '@alto/design-system';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

type EditButtonProps = {
  readonly handleEditShipment: () => void;
  readonly shipmentID?: number;
};

export const EditButton = ({ handleEditShipment, shipmentID }: EditButtonProps) => {
  const { trackEvent } = useAnalytics();

  const onPress = () => {
    handleEditShipment();
    trackEvent({ event: EVENTS.DELIVERY_DETAILS_EDIT_BUTTON_CLICKED, additionalFields: { shipmentId: shipmentID } });
  };

  return (
    <Button
      accessibilityLabel="Edit Order Button"
      onPress={onPress}
      small
      testID="edit-order-button"
      type="secondary"
      width="inline"
    >
      Edit
    </Button>
  );
};
