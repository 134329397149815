// @owners { team: patients-team }
import { BORDERS, COLORS, SHADOWS } from '@alto/design-library-tokens';
import { css } from 'styled-components';

type Props = {
  disabled?: boolean;
};

export const StyledHoverable = css`
  border-radius: ${BORDERS.RADIUS.LG.px};
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  ${({ disabled }: Props) =>
    disabled
      ? ''
      : `
      &:hover {
        border-radius: ${BORDERS.RADIUS.LG.px};
        border-color: ${COLORS.BORDER_COLORS.LIGHT};
        ${SHADOWS.BOTTOM_DARK}
      }
    `}
`;
