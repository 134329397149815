// @owners { team: patients-team }

import { type CoachmarkKey, type MarkCoachmarkViewed, SET_COACHMARK_VIEWED } from '~shared/actions/ui/coachmarks';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateUiCoachmarks = {
  seenCoachmarks: Partial<Record<CoachmarkKey, boolean>>;
};

export const initialState: StateUiCoachmarks = {
  seenCoachmarks: {},
};

const coachmarks = (state: StateUiCoachmarks = initialState, action: MarkCoachmarkViewed): StateUiCoachmarks => {
  const { type } = action;

  switch (type) {
    case SET_COACHMARK_VIEWED: {
      const { payload: coachmarkId } = action;
      return { ...state, seenCoachmarks: { ...state.seenCoachmarks, [coachmarkId]: true } };
    }

    default:
      return state;
  }
};

export default withRehydration(coachmarks, initialState);
