// eslint-disable-next-line @alto/no-pocky-import
import { Column, H1, Text, palette, spacing } from '@alto/pocky';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  readonly headerIcon?: React.ReactElement;
  readonly title?: string;
  readonly text?: string;
  readonly children: React.ReactElement | React.ReactElement[];
};

const StyledH1 = styled(H1)`
  color: ${palette.altoColorsP2};
`;

const StyledContainer = styled.div`
  margin: 94px auto 0 auto;
  width: 325px;
  text-align: center;
`;

const CommsVerificationCard = ({ headerIcon, title, text, children }: Props) => {
  return (
    <StyledContainer>
      <Column spacing={spacing.lgSpacing}>
        <Column spacing={spacing.smSpacing}>
          {headerIcon}
          {title ? <StyledH1>{title}</StyledH1> : null}
          {text ? <Text>{text}</Text> : null}
        </Column>
        <Column
          horizontallyAlignContent
          spacing={spacing.smSpacing}
        >
          {children}
        </Column>
      </Column>
    </StyledContainer>
  );
};

export default CommsVerificationCard;
