// @owners { team: patients-team }
export const UPDATE_CYCLE_ATTRIBUTES = 'UPDATE_CYCLE_ATTRIBUTES';
export type ActionUpdateCycleAttributes = {
  type: typeof UPDATE_CYCLE_ATTRIBUTES;
  payload: {
    attributes: Record<string, unknown>;
  };
};
export function updateCycleAttributes(attributes: Record<string, unknown>): ActionUpdateCycleAttributes {
  return {
    type: UPDATE_CYCLE_ATTRIBUTES,
    payload: {
      attributes,
    },
  };
}

export const UPDATE_MEDICATIONS_SUPPLY_ON_HAND = 'UPDATE_MEDICATIONS_SUPPLY_ON_HAND';
export type ActionUpdateMedicationsSupplyOnHand = {
  type: typeof UPDATE_MEDICATIONS_SUPPLY_ON_HAND;
  payload: {
    medicationsSupplyOnHand: Record<string, any>[];
  };
};
export function updateMedicationsSupplyOnHand(
  medicationsSupplyOnHand: Record<string, any>[],
): ActionUpdateMedicationsSupplyOnHand {
  return {
    type: UPDATE_MEDICATIONS_SUPPLY_ON_HAND,
    payload: {
      medicationsSupplyOnHand,
    },
  };
}

export const SET_HAS_CHANGED_DELIVERY_METHOD = 'SET_HAS_CHANGED_DELIVERY_METHOD';
export type ActionSetHasChangedDeliveryMethod = {
  type: typeof SET_HAS_CHANGED_DELIVERY_METHOD;
  payload: { hasChangedDeliveryMethod: boolean };
};
export function setHasChangedDeliveryMethod({
  hasChangedDeliveryMethod,
}: {
  hasChangedDeliveryMethod: boolean;
}): ActionSetHasChangedDeliveryMethod {
  return {
    type: SET_HAS_CHANGED_DELIVERY_METHOD,
    payload: { hasChangedDeliveryMethod },
  };
}
