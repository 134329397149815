// @owners { team: patients-team }
import {
  FETCH_QUANTITY_OPTIONS_FAILED,
  FETCH_QUANTITY_OPTIONS_SUCCEEDED,
  type QuantityOptionsAction,
} from '~shared/actions/quantityOptions';
import { withRehydration } from '~shared/reducers/withRehydration';
import { type QuantityOption } from '~shared/types/clients';

export const initialState = {};

export type StateQuantityOptions = Record<number, QuantityOption[]>;

const quantityOptions = (
  state: StateQuantityOptions = initialState,
  action: QuantityOptionsAction,
): StateQuantityOptions => {
  switch (action.type) {
    case FETCH_QUANTITY_OPTIONS_SUCCEEDED: {
      const { payload } = action;
      const { prescriptionID, options } = payload;
      return { ...state, [prescriptionID]: options };
    }

    case FETCH_QUANTITY_OPTIONS_FAILED: {
      const { payload } = action;
      const { prescriptionID } = payload;
      return { ...state, [prescriptionID]: [] };
    }

    default:
      return state;
  }
};

export default withRehydration(quantityOptions, initialState);
