// @owners { team: patients-team }
import { type NotificationsEndpointFetchAllRequest } from '@alto/scriptdash/alto/customer_support/patients/v1/notifications_endpoint';
import { SupportCasesEndpoint as SupportCasesEndpointV1 } from '@alto/scriptdash/alto/customer_support/patients/v1/support_cases_endpoint';
import {
  SupportCategoriesEndpoint,
  type SupportCategoriesEndpointFetchAllResponse,
} from '@alto/scriptdash/alto/customer_support/patients/v1/support_categories_endpoint';
import {
  type SupportCasesEndpointFetchByPatientResponse,
  SupportCasesEndpoint as SupportCasesEndpointV2,
} from '@alto/scriptdash/alto/customer_support/patients/v2/support_cases_endpoint';
import {
  WundercomMessagesEndpoint as ReadOnlyWundercomEndpoint,
  type WundercomMessagesEndpointFetchAllResponse as ReadOnlyWundercomEndpointFetchAllResponse,
  type WundercomMessagesEndpointFetchAllRequest,
} from '@alto/scriptdash/alto/customer_support/patients/v3/wundercom_messages_endpoint';
import { type SupportCase } from '@alto/scriptdash/alto/customer_support/types/v1/support_case';
import { type SupportCategory } from '@alto/scriptdash/alto/customer_support/types/v1/support_category';
import { type User } from '@alto/scriptdash/alto/user/patients/types/v1/user';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { apiEndpointHandler } from '~shared/helpers/api';
import { ellipsize } from '~shared/helpers/string';
import { queries } from '~shared/queries/query-keys';
import { useSelectorShared } from '~shared/store';

export const DEFAULT_PAGE_SIZE = 50;

const supportCategoriesEndpoint = SupportCategoriesEndpoint(apiEndpointHandler);
const supportCasesEndpointV1 = SupportCasesEndpointV1(apiEndpointHandler);
const supportCasesEndpointV2 = SupportCasesEndpointV2(apiEndpointHandler);
const readOnlyWundercomEndpoint = ReadOnlyWundercomEndpoint(apiEndpointHandler);

const support = createQueryKeys('support', {
  supportCategories: () => ({
    queryKey: ['support-categories'],
    queryFn: async (): Promise<SupportCategoriesEndpointFetchAllResponse> => {
      return await supportCategoriesEndpoint.fetchAll({ ids: null });
    },
  }),
  supportCases: (patientID: number, page_size = DEFAULT_PAGE_SIZE) => ({
    queryKey: ['support-cases', patientID],
    queryFn: async ({ pageParam = 0 }) => {
      return await supportCasesEndpointV2.fetchByPatient({
        patient_id: Number(patientID),
        pagination: { page_size, page_token: pageParam as string },
      });
    },
  }),
  supportCase: (supportCaseID: number) => ({
    queryKey: ['support-case', supportCaseID],
    queryFn: async () => await supportCasesEndpointV1.fetchOne({ id: Number(supportCaseID) }),
  }),
});

export const readOnlyWundercoms = createQueryKeys('readOnlyWundercomEndpoint', {
  fetchReadOnlyWundercoms: (params: WundercomMessagesEndpointFetchAllRequest) => ({
    queryKey: [params],
  }),
});

export const notifications = createQueryKeys('notifications', {
  fetchNotifications: (params: NotificationsEndpointFetchAllRequest) => ({
    queryKey: [params],
  }),
});

export const getUnreadSupportCaseCount = (supportCases: SupportCase[]) => {
  return supportCases.reduce((count, { most_recent_message }) => {
    if (most_recent_message?.admin_user_id && !most_recent_message.read_at) {
      count += 1;
    }
    return count;
  }, 0);
};

export const mapSupportCategoriesByID = (data: SupportCategoriesEndpointFetchAllResponse) => {
  const supportCategories = data?.data || [];
  return supportCategories.reduce<Record<string, SupportCategory>>((map, supportCategory) => {
    map[supportCategory.id] = supportCategory;
    return map;
  }, {});
};

/**
 * Get the all support case topics that ops can select when creating a new case in wunderbar
 */
export const useGetSupportCategories = () => {
  const { data, isPending } = useQuery({
    ...support.supportCategories(),
    select: mapSupportCategoriesByID,
  });
  return { supportCategoriesMap: data ?? {}, isPending };
};

/**
 * Get all the the support cases for this patient (with pagination)
 * @returns supportCases - SupportCase[]
 * @returns error - if the query has any errors
 * @returns fetchNextPage - is a function that will trigger the getNextPageParam when reach end of current support cases
 * @returns hasNextPage - boolean for if there are more cases to be fetched
 * @returns isPending - boolean for when loading
 * @returns refetch - refetch function
 */
export const useGetSupportCases = () => {
  const patientID = useSelectorShared(getCurrentUserID);
  const { data, error, fetchNextPage, hasNextPage, isPending, refetch } = useInfiniteQuery({
    initialPageParam: '0',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...support.supportCases(patientID!),
    enabled: patientID !== null,
    getNextPageParam: (lastPage) => {
      return lastPage.metadata?.pagination?.next_page_token;
    },
  });

  let supportCases = [] as SupportCase[];
  let familyMembers = [] as User[];
  let isFamilyOwner = undefined;
  let isFamilyMember = undefined;
  data?.pages.flatMap((page) => {
    if (page.data?.length) {
      supportCases = supportCases.concat(page.data[0]?.data || []);
      familyMembers = page.data[0]?.family_members || [];
      isFamilyOwner = page.data[0]?.is_family_owner;
      isFamilyMember = page.data[0]?.is_family_member;
    }
  });

  return {
    supportCases,
    isFamilyOwner,
    isFamilyMember,
    familyMembers,
    error,
    fetchNextPage,
    hasNextPage,
    isPending,
    refetchSupportCases: refetch,
  };
};

/**
 * Read support case data from react-query cache
 * support case should possibly exist in the cache when we've fetched a list of patients existing cases (like on Inbox)
 * @param supportCaseID
 * @returns supportCaseCached - SupportCase | undefined depending on if it exists in the cache
 */
export const useGetSupportCaseFromCache = (supportCaseID: number | null) => {
  const patientID = useSelectorShared(getCurrentUserID);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<Pick<SupportCasesEndpointFetchByPatientResponse, 'data'>>([
    'support-cases',
    patientID,
  ]);
  const supportCases = data?.data?.[0].data ?? [];

  return {
    supportCaseCached: supportCases.find(({ id }) => id === supportCaseID) ?? null,
  };
};

/**
 * Get a specific support case for this patient
 * @param supportCaseID - number | null of the support case.
 * @param supportCaseCachedExists - boolean of if in cache.
 */

export const useGetSupportCase = (supportCaseID: number | null, supportCaseCachedExists: boolean) => {
  const { data, isPending, error, refetch, isFetching, isRefetching } = useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...support.supportCase(supportCaseID!),
    enabled: supportCaseID !== null && !supportCaseCachedExists && !isNaN(supportCaseID),
  });

  return {
    supportCaseNetwork: data?.data ?? null,
    isPending,
    error,
    refetchSupportCase: refetch,
    isFetching,
    isRefetching,
  };
};

type UseGetWundercomMessagesProps = {
  supportCaseID: number | null;
};

/**
 * Get wundercoms for a specific support case given the ID
 */
export const useGetWundercomMessages = ({ supportCaseID }: UseGetWundercomMessagesProps) => {
  const { data, isPending, error, refetch } = useQuery({
    ...queries.wundercomMessages.fetchWundercomMessages({ supportCaseID }),
    enabled: !!supportCaseID,
  });

  return {
    messages: data?.data || [],
    isPending,
    error,
    refetchMessages: refetch,
  };
};

/**
 * Get the support case topic for a given case (if exists) that ops can select when creating a new case
 * @param supportCase
 * @returns { topic: string, isPending: boolean }
 */
export const useGetSupportCaseTopic = (supportCase: Pick<SupportCase, 'support_category_id' | 'topic'> | null) => {
  const { supportCategoriesMap, isPending } = useGetSupportCategories();
  // NOTE: for push notifications, wait for the support case and support categories to be fetched then show the topic
  if (!supportCase) {
    return { topic: 'Support request', isPending: true };
  }
  const { support_category_id, topic } = supportCase;
  const supportCategory = support_category_id ? supportCategoriesMap?.[support_category_id] : null;
  const formattedTopic = supportCategory ? supportCategory.name : ellipsize(topic, 30);
  return { topic: formattedTopic || 'Support request', isPending };
};

/**
 * Get the wundercom messages on the read only thread, this powers the infinite scroll
 */
export const useGetReadOnlyWundercoms = () => {
  const currentUserID = useSelectorShared(getCurrentUserID);
  const apiParams = {
    patient_id: currentUserID || 0,
    pagination: {
      page_size: DEFAULT_PAGE_SIZE,
      page_token: '0',
    },
  };

  const { data, error, fetchNextPage, fetchStatus, hasNextPage, isPending, isFetching } =
    useInfiniteQuery<ReadOnlyWundercomEndpointFetchAllResponse>({
      ...readOnlyWundercoms.fetchReadOnlyWundercoms(apiParams),
      queryFn: async ({ pageParam = 0 }) => {
        return await readOnlyWundercomEndpoint.fetchAll({
          patient_id: apiParams.patient_id,
          pagination: { page_size: DEFAULT_PAGE_SIZE, page_token: pageParam as string },
        });
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage) => {
        return lastPage.metadata?.pagination?.next_page_token;
      },
      enabled: apiParams.patient_id > 0,
    });

  const wundercomMessages = (data?.pages ?? []).flatMap((page) => page.data).filter(TypescriptUtils.isPresent);
  // get count of read only wundercoms with no read_at timestamp
  const unreadMessageCount = 0;
  return {
    data: wundercomMessages,
    unreadMessageCount,
    error,
    fetchNextPage,
    fetchStatus,
    hasNextPage,
    isPending,
    isFetching,
  };
};
