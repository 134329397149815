import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { formatDollars } from '~shared/helpers/currency';

type PricingTotal = {
  reason: (typeof PRESCRIPTION_PRICING_LABELS)[keyof typeof PRESCRIPTION_PRICING_LABELS];
  value: number | null | undefined;
};

export const getPaymentString = (total: PricingTotal) => {
  if (total?.reason === PRESCRIPTION_PRICING_LABELS.COVERED_BY_CLINIC) {
    return 'Billed to Clinic';
  }

  if (total?.reason === PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE) {
    return 'Price pending';
  }

  const valueString = formatDollars(total?.value || 0);

  if (total?.reason === PRESCRIPTION_PRICING_LABELS.PROGYNY) {
    return `${valueString}*`;
  }

  return valueString;
};
