// @owners { team: patients-team }
import { InputAutocomplete } from '@alto/design-system';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps } from 'formik';
import React from 'react';
import { allergensAutocompleteDataSource } from '~shared/data-sources/allergensAutocompleteDataSource';

const AllergenSearchField = () => {
  return (
    <Field name="allergens">
      {({ form: { errors, setFieldValue, submitCount } }: FieldProps<string>) => {
        {
          return (
            <InputAutocomplete
              label=""
              leftIcon="search-small"
              accessibilityLabel="Select or search for all allergies that apply"
              shouldAllowManualInput
              selectionType="multi"
              dataSource={allergensAutocompleteDataSource}
              onSelectCallback={(data) => {
                const allergens = data.map((allergen) => allergen.value);
                setFieldValue('allergens', allergens);
              }}
              placeholder="Select or search for all allergies that apply"
              error={submitCount > 0 ? (errors.allergens as unknown as string) : null}
              hasDefaultOptions
              required
            />
          );
        }
      }}
    </Field>
  );
};

export default AllergenSearchField;
