import { createSelector } from 'reselect';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { type ShipmentIDParamShort } from '~shared/selectors/selectorParamTypes';
import { type LightDelivery, type ReduxStateShared } from '~shared/types';

const getShipmentID = (_: ReduxStateShared, props: ShipmentIDParamShort) => props.id;

export const getIsFirstSelfScheduledShipment = createSelector(
  [getDeliveries, getShipmentID],
  (deliveries, shipmentID) => {
    const deliveriesInShipment: LightDelivery[] = [];
    const deliveriesNotInShipment: LightDelivery[] = [];

    deliveries.forEach((delivery) => {
      if (delivery.shipment_id && delivery.shipment_id === shipmentID) {
        deliveriesInShipment.push(delivery);
      } else {
        deliveriesNotInShipment.push(delivery);
      }
    });

    return (
      deliveriesInShipment.some((d) => d.self_scheduled) && deliveriesNotInShipment.every((d) => !d.self_scheduled)
    );
  },
);
