// @owners { team: patients-team }
import { changeModalStep, closeModal, openModal } from '~shared/actions/modal';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { connect } from '~shared/store';
import { type User } from '~shared/types';
import OnboardingResume from './OnboardingResume';

// @ts-expect-error TS(7031): Binding element 'show' implicitly has an 'any' type.
const mapStateToProps = ({ modal: { show, modalType } }) => {
  return {
    show,
    modalType,
  };
};

const onboardingStep = ({ allergies, medical_conditions }: User): number => {
  if (!allergies || !medical_conditions) {
    return 0;
  }

  return 2;
};

const next = () => {
  // @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
  return (dispatch, getState) => {
    const state = getState();
    const user = getCurrentUser(state);
    dispatch(closeModal('ONBOARDING_RESUME_MODAL'));
    dispatch(openModal('ONBOARDING_MODAL'));

    if (user) {
      dispatch(changeModalStep(onboardingStep(user), 'ONBOARDING_MODAL'));
    }
  };
};

const mapDispatchToProps = {
  next,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingResume);
