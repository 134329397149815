import { type LightDelivery } from '~shared/types';

/**
 * Get the number of upcoming or unscheduled deliveries with the supplied insurance id
 * @param deliveries
 * @param insuranceID
 * @returns
 */
export const getDeliveriesBilledWithInsuranceCount = (deliveries: LightDelivery[], insuranceID: number) => {
  const unscheduledOrUpcomingDeliveries = deliveries.filter(
    ({ status }) => status === 'upcoming' || status === 'unscheduled',
  );
  const deliveriesBilledWithInsurance = unscheduledOrUpcomingDeliveries.filter(
    ({ primary_insurance, secondary_insurance, tertiary_insurance }) =>
      (primary_insurance && primary_insurance.id === insuranceID) ||
      (secondary_insurance && secondary_insurance.id === insuranceID) ||
      (tertiary_insurance && tertiary_insurance.id === insuranceID),
  );
  return deliveriesBilledWithInsurance.length;
};
