// @owners { team: patients-team }
import {
  AddOnsEndpoint,
  type AddOnsEndpointUpdatePrescriptionRequest,
} from '@alto/scriptdash/alto/patient_app/add_ons/v2/add_ons_endpoint';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useMutation } from '~shared/react-query';

const AddOnsApi = AddOnsEndpoint(apiEndpointHandler);

export const ADD_ONS_UPDATE_MUTATION_KEY = ['addOnsUpdatePrescription'];

export const useAddOnsUpdatePrescription = () => {
  return useMutation({
    mutationFn: (params: AddOnsEndpointUpdatePrescriptionRequest) => AddOnsApi.updatePrescription(params),
    mutationKey: ADD_ONS_UPDATE_MUTATION_KEY,
  });
};
