// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoIcon,
  Button,
  Caption,
  Card,
  Description,
  H1,
  InputCheckbox,
  LgPadding,
  LgSpacing,
  ListButton,
  MdSpacing,
  SecondaryPage,
  XlSpacing,
  XxxlPadding,
  useScreenSize,
} from '@alto/design-system';
import React, { useEffect, useState } from 'react';
import { goBack, push } from 'react-router-redux';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantViewedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { MESSAGE_TAGS } from '~shared/features/messages/constants';
import { useDispatchShared } from '~shared/store';

export const MEDICATION_QUESTIONS = [
  { text: 'Directions for use', description: 'Proper application, dosage or ancillaries' },
  { text: 'Side effects & interactions', description: 'Concerns about reactions, preconditions that may affect me' },
  { text: 'Medication storage', description: 'Storage and refrigeration' },
];

export const WhatMedicationQuestion = () => {
  const { isMDScreenOrBigger } = useScreenSize();
  const dispatch = useDispatchShared();
  const [questionList, setQuestionList] = useState<string[]>([]);

  useEffect(() => {
    dispatch(sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.WHAT_MEDICATION_QUESTION));
  }, [dispatch]);

  const handleCheckboxItemPress = (text: string) => {
    if (questionList.includes(text)) {
      setQuestionList(questionList.filter((data) => data !== text));
    } else {
      setQuestionList([...questionList, text]);
    }
  };

  const navigateToMessageForm = ({ toPharmacist } = { toPharmacist: false }) => {
    dispatch(
      push({
        pathname: '/help/message',
        state: {
          origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_WHAT_MEDICATION_QUESTION,
          category: toPharmacist ? ASSISTANT_CATEGORIES.MEDICATIONS : ASSISTANT_CATEGORIES.SOMETHING_ELSE,
          messagePrompt: toPharmacist ? MESSAGE_PROMPTS.PHARMACIST_SLA : MESSAGE_PROMPTS.LET_US_HELP,
          subject: toPharmacist ? questionList.join(', ') : '',
          tag: toPharmacist ? MESSAGE_TAGS.MEDICATION_QUESTION : undefined,
        },
      }),
    );
  };

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      HeaderContent={
        <XxxlPadding
          topPadding={isMDScreenOrBigger ? SPACING.STATIC.XXXL : SPACING.STATIC.MD}
          bottomPadding={isMDScreenOrBigger ? SPACING.STATIC.XXXL : SPACING.STATIC.XL}
          leftPadding={SPACING.STATIC.MD}
          rightPadding={SPACING.STATIC.MD}
        >
          <H1 center>What medication questions do you have?</H1>
        </XxxlPadding>
      }
      dismissIcon="chevronleft"
      onDismiss={() => {
        dispatch(goBack());
      }}
    >
      <Card>
        <LgSpacing />
        {MEDICATION_QUESTIONS.map(({ text, description }) => (
          <InputCheckbox
            key={text}
            name={text}
            label={text}
            descriptions={[<Description key={description}>{description}</Description>]}
            value={questionList.includes(text)}
            onPress={() => {
              handleCheckboxItemPress(text);
            }}
          />
        ))}
        <MdSpacing />
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <ListButton
            LeftContent={<AltoIcon name="questionmark-duo" />}
            rightIcon="chevronright-small"
            label="None of these"
            onPress={navigateToMessageForm}
          />
          <XlSpacing />
          <Caption center>If this is an emergency, dial 9-1-1</Caption>
          <MdSpacing />
          <Button
            label="Continue"
            onPress={() => {
              navigateToMessageForm({ toPharmacist: true });
            }}
            disabled={questionList.length === 0}
          />
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};
