// @owners { team: patients-team }
import { BORDERS, COLORS, SHADOWS, SIZES, SPACING } from '@alto/design-library-tokens';
import { LgSpacing } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { Image, StyledTouchable, Text } from '~web/features/essentials/primitives';

type Props = {
  readonly name: string;
  readonly image: string;
  readonly active: boolean;
  readonly onPress: () => void;
};

const StyledListItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? COLORS.BACKGROUND_COLORS.WHITE : 'transparent')};
  padding: ${SPACING.STATIC.SM.px};
  border-radius: ${BORDERS.RADIUS.LG.px};
  border-color: ${({ active }) => (active ? COLORS.BORDER_COLORS.LIGHT : 'transparent')};
  border-style: solid;
  border-width: 1px;
  ${({ active }) => active && SHADOWS.BOTTOM_LIGHT}
  &:hover {
    background-color: ${({ active }) =>
      active ? COLORS.BACKGROUND_COLORS.WHITE : COLORS.BACKGROUND_COLORS.PRIMARY_LIGHT};
  }
  ${StyledTouchable}
`;

export const CategoryItem = ({ name, image, active, onPress }: Props) => {
  return (
    <StyledListItem
      active={active}
      onClick={onPress}
    >
      <Image
        src={image}
        width={SIZES.AVATAR.MD.px}
        height={SIZES.AVATAR.MD.px}
        alt={`icon for ${name}`}
      />
      <LgSpacing />
      <Text
        title={name}
        variant="body-semibold"
        color="PRIMARY"
      />
      <LgSpacing />
    </StyledListItem>
  );
};
