import { AltoIcon, Row, Tag } from '@alto/design-system';
import { ORDER_TYPE_CONTEXT, OrderTypeSelectionSection } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, LoaderImage, Text, spacing, spinnerStyles } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getPaymentString } from '~shared/features/cart/selectors/getPaymentString';
import { getMedicationForPrescription } from '~shared/features/my-meds/selectors/getMedicationsFromPrescriptions';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { formatQuantity } from '~shared/helpers/helper';
import { useSelectorShared } from '~shared/store';
import UserTag from '~web/features/family-accounts/components/UserTagContainer';

type Props = {
  readonly prescriptionID: number;
  readonly includeNewItemTag?: boolean;
};
export const MedContainer = styled.div`
  flex: 2;
  > div {
    height: auto;
  }
`;

export const PriceContainer = styled.div`
  flex: 1;
  text-align: right;
`;

export const HeaderText = styled(Text)`
  font-size: 1.1rem;
  font-weight: 600;
`;

export const MedSummaryRow = ({ prescriptionID, includeNewItemTag = false }: Props) => {
  const priceLoading = useSelectorShared((state) => state.ui.loading.fetchOrderPricingLoading);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const medication = useSelectorShared((state) => getMedicationForPrescription(state, prescription));
  const { cartPricingTotals } = usePrescriptionsInCart();
  const { cartAutoRefills } = useCartData();

  if (!prescription) {
    return null;
  }

  const total = cartPricingTotals[prescription.id];

  return (
    <FlexColumn
      evenlySpaceContent
      spacing={spacing.smSpacing}
      padding={`0 0 ${spacing.smSpacing}`}
    >
      <MedContainer>
        <HeaderText>{medication?.medicationName}</HeaderText>
        <Row centerVertically>
          <Text>{prescription.dosage_form}</Text>
          {prescription.is_refrigerated ? (
            <AltoIcon
              name="snowflake-small"
              type="disabled"
              accessibilityLabel="Medication requires refrigeration"
            />
          ) : null}
          {prescription.has_ancillary_items ? (
            <AltoIcon
              name="syringe-small"
              type="disabled"
              accessibilityLabel="Medication comes with additional items"
            />
          ) : null}
        </Row>
        <Text>Qty: {formatQuantity(prescription)}</Text>
        <UserTag userIDs={[prescription.user_id]} />
        {prescription.auto_refill_eligible ? (
          <OrderTypeSelectionSection
            prescriptionID={prescription.id}
            orderTypeContext={ORDER_TYPE_CONTEXT.CHECKOUT}
            isEditable={false}
            initialAutoRefillEnabledValue={cartAutoRefills[prescription.id]}
          />
        ) : null}
        {includeNewItemTag ? (
          <Tag
            type="recommended"
            key="new-item"
            label="new item"
          />
        ) : null}
      </MedContainer>
      <PriceContainer>
        {priceLoading ? <LoaderImage style={spinnerStyles} /> : <HeaderText>{getPaymentString(total)}</HeaderText>}
      </PriceContainer>
    </FlexColumn>
  );
};
