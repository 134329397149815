// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Button, LandingPage } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useEffect } from 'react';
import { setWelcomeCarouselIndex } from '~shared/actions/ui/welcome';
import thumbsUp from '~shared/assets/images/thumbs_up.png';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';

type TransferSuccessProps = {
  readonly isOnboardingFlowTransfer?: boolean;
};

export const TransferSuccess = ({ isOnboardingFlowTransfer }: TransferSuccessProps) => {
  const dispatch = useDispatchShared();
  const { navigate } = useNavigation();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.TRANSFER_SUCCESS_VIEWED,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToWelcome = () => {
    dispatch(setWelcomeCarouselIndex(0));
    navigate('RouteWelcome');
  };

  const navigateToHomescreen = () => {
    navigate('RouteAppTabNavigator', { screen: 'RouteTabHomescreen' });
  };

  return (
    <LandingPage
      title="You're all set!"
      HeaderBodyCopy={
        <Body>
          We'll notify you when it's time to schedule your delivery. Transfers may take up to a few business days.
        </Body>
      }
      illustrationSize="xl"
      illustrationSrc={thumbsUp}
      backgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      buttons={
        isOnboardingFlowTransfer
          ? [
              <Button
                key="onboarding-return"
                width="full"
                label="Return Home"
                onPress={navigateToWelcome}
              />,
            ]
          : [
              <Button
                key="back-to-home"
                width="full"
                label="Got It"
                onPress={navigateToHomescreen}
              />,
            ]
      }
    />
  );
};
