// @owners { team: patients-team }
export const UPDATE_CSAT_VIEWED_DATE = 'UPDATE_CSAT_VIEWED_DATE';

export function updateCSATViewedDate() {
  return {
    type: UPDATE_CSAT_VIEWED_DATE,
  } as const;
}

export const UPDATE_CSAT_SUBMITTED_DATE = 'UPDATE_CSAT_SUBMITTED_DATE';

export function updateCSATSubmittedDate() {
  return {
    type: UPDATE_CSAT_SUBMITTED_DATE,
  } as const;
}

export type CSATActions = ReturnType<typeof updateCSATViewedDate> | ReturnType<typeof updateCSATSubmittedDate>;
