import { createSelector } from 'reselect';
import { getAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';

export const getDeliveriesSortedByDateDesc = createSelector([getDeliveries], (deliveries) => {
  return (
    deliveries
      .filter((d) => ['delivered', 'upcoming'].includes(d.status))
      // Sort date descending
      .sort((a, b) => (a.date?.localeCompare(b.date || '') || 0) * -1)
  );
});

const getMostRecentlyUsedAddress = createSelector(
  [getAddresses, getDeliveriesSortedByDateDesc],
  (addresses, deliveries) => {
    for (const delivery of deliveries) {
      const address = addresses.find((a) => a.id === delivery.address_id);

      if (address) {
        return address;
      }
    }

    return undefined;
  },
);

export const getDefaultAddress = createSelector(
  [getAddresses, getMostRecentlyUsedAddress],
  (addresses, mostRecentAddress) => {
    const primaryAddress = addresses.find((a) => a.primary);
    const firstAddress = addresses.length > 0 ? addresses[0] : undefined;

    return primaryAddress || mostRecentAddress || firstAddress;
  },
);

export const getCurrentUserDefaultAddress = createSelector(
  [getAddresses, getCurrentUserID],
  (addresses, currentUserID) => {
    const userAddresses = addresses.filter((address) => address.user_id === currentUserID);
    const primaryAddress = userAddresses.find((a) => a.primary);
    const firstAddress = userAddresses.length > 0 ? userAddresses[0] : undefined;

    return primaryAddress || firstAddress;
  },
);
