import { type BACKGROUND_COLORS_VALUES, BORDERS, COLORS, SIZES } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { useScreenSize } from '../../../utils';

type Props = {
  readonly children: React.ReactNode;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly minHeaderHeight?: number;
};

type HeaderContainerProps = {
  minHeaderHeight: Props['minHeaderHeight'];
  backgroundColor: Props['backgroundColor'];
};

const HeaderBackground = styled(View)<HeaderContainerProps>`
  ${({ minHeaderHeight }) => (minHeaderHeight ? `min-height: ${minHeaderHeight}px;` : '')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom-width: ${BORDERS.SIZE}px;
  border-color: ${COLORS.BORDER_COLORS.LIGHT};
`;

const Container = styled(View)<{ isMDScreenOrBigger: boolean }>`
  width: ${SIZES.PAGE.CONTENT_WIDTH.SM};
  ${({ isMDScreenOrBigger }) =>
    isMDScreenOrBigger &&
    `
        align-self: center;
        width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
        max-width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
  `}
`;

export const PageHeaderSection = ({
  children,
  backgroundColor = COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST,
  minHeaderHeight,
}: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <HeaderBackground
      minHeaderHeight={minHeaderHeight}
      backgroundColor={backgroundColor}
    >
      <Container isMDScreenOrBigger={isMDScreenOrBigger}>{children}</Container>
    </HeaderBackground>
  );
};
