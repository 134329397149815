// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { H1, SteppedProgressBar, XlSpacing, XxlSpacing, XxxlPadding, useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';

const MaxWidthRow = styled.div`
  align-self: center;
  width: 100%;
  max-width: 375px;
`;

type Props = {
  readonly currentStep: 1 | 2 | 3;
  readonly title: string;
};

export const MedSyncSelfServiceProgressHeader = ({ currentStep, title }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <>
      <XxxlPadding
        topPadding={isMDScreenOrBigger ? SPACING.STATIC.XXXL : SPACING.STATIC.SM}
        bottomPadding={isMDScreenOrBigger ? SPACING.STATIC.XXXL : SPACING.STATIC.XL}
        leftPadding={SPACING.STATIC.MD}
        rightPadding={SPACING.STATIC.MD}
      >
        <MaxWidthRow>
          <SteppedProgressBar
            completedSteps={currentStep}
            totalSteps={3}
          />
        </MaxWidthRow>
        {isMDScreenOrBigger ? <XxlSpacing /> : <XlSpacing />}
        <H1 center>{title}</H1>
      </XxxlPadding>
    </>
  );
};
