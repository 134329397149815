// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import styled from 'styled-components';
import { ProductItem } from './ProductItem';

type ProductScrollViewProps = {
  readonly count: number;
  readonly products: AddOnOtc[];
  readonly categoryName: string;
  readonly searchQuery?: string;
  readonly scrollOverflow?: boolean;
  readonly isStoreItems?: boolean;
};

const StyledScrollViewList = styled.ul<{ children?: React.ReactNode; scrollOverflow?: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0 ${SPACING.STATIC.LG.px};
  margin: 0 ${({ scrollOverflow }) => (scrollOverflow ? `-${SPACING.STATIC.LG.px}` : '0')};
  overflow: scroll;
`;

export const ProductScrollView = ({
  count,
  products,
  categoryName,
  scrollOverflow,
  searchQuery,
  isStoreItems,
}: ProductScrollViewProps) => {
  return (
    <StyledScrollViewList scrollOverflow={scrollOverflow}>
      {products.map((product, index) => (
        <ProductItem
          categoryName={categoryName}
          key={product.sku}
          count={count}
          product={product}
          index={index}
          searchQuery={searchQuery}
          isStoreItem={isStoreItems}
        />
      ))}
    </StyledScrollViewList>
  );
};
