export const SECTION_LABELS = {
  AWAITING_PICKUP: 'Awaiting pickup',
  COMPLETED_DELIVERY: 'Completed delivery',
  COMPLETED_PICKUP: 'Completed pickup',
  DUE_FOR_REFILL: 'Medications ready to refill',
  FEATURED_OTCS: 'Shop for Essentials',
  NEW_PRESCRIPTIONS: 'New medications',
  PENDING_TRANSFER: 'Pending prescription transfer',
  TRANSFER_REQUESTS: 'Requested prescription transfer',
  UNCONFIRMED_SHIPMENT: 'Pending delivery',
  UPCOMING_DELIVERY: 'Upcoming delivery',
  UPCOMING_PICKUP: 'Upcoming pickup',
  UPDATE_INSURANCE: 'Update your insurance',
  UPDATE_PAYMENT_METHOD: 'Failed payment',
};

export const UNCONFIRMED_SHIPMENT_LABELS = {
  waive_signature_requirement:
    'If you waive the signature requirement, you accept responsibility for lost or stolen packages.',
  generic_confirmation_error: 'Please try again or message support if the problem persists.',
};
