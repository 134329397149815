// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  Description,
  ExternalLink,
  FlatCard,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  ListDescription,
} from '@alto/design-system';
import {
  type MounjaroPrescriptionsForNdc,
  type MultipleActiveRxPrescription,
} from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { stripNonDigits } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { type Prescription } from '~shared/types';

const MOUNJARO_NDC_DOSAGE_MAP: Record<string, string> = {
  '00002150680': '2.5mg',
  '00002149580': '5mg',
  '00002148480': '7.5mg',
  '00002147180': '10mg',
  '00002146080': '12.5mg',
  '00002145780': '15mg',
} as const;

type Props = {
  readonly addToCart: (prescription: MultipleActiveRxPrescription) => void;
  readonly origin: OriginName;
  readonly prescriptionsForNdc: MounjaroPrescriptionsForNdc[];
  readonly selectedPrescription?: Prescription;
};

export const MounjaroDosageSelectionActionSheet = ({
  addToCart,
  origin,
  prescriptionsForNdc,
  selectedPrescription,
}: Props) => {
  const { trackPageView } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const [selectedNDC, setSelectedNDC] = useState<string>(selectedPrescription?.ndc ?? prescriptionsForNdc[0].ndc);
  const prescriptionsForSelectedNDC = prescriptionsForNdc.find((p) => p.ndc === selectedNDC);
  const prescriptionForSelectedNDC = prescriptionsForSelectedNDC?.prescriptions?.[0];
  const providerPhoneNumber = prescriptionForSelectedNDC?.provider_phone;

  useEffect(() => {
    trackPageView({ event: EVENTS.MOUNJARO_DOSAGE_SELECTION_VIEWED, params: { origin } });
  }, [trackPageView, origin]);

  const onButtonPress = useCallback(() => {
    if (prescriptionForSelectedNDC) addToCart(prescriptionForSelectedNDC);
    closeActionSheet();
  }, [addToCart, closeActionSheet, prescriptionForSelectedNDC]);

  return (
    <ActionSheetV2
      title="Select your Mounjaro dosage"
      description="Double check that you are adding the correct medication strength to your cart."
      analyticsName="mounjaro prompt"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.MD}
      >
        <FlatCard>
          <LgPadding>
            <Description>
              Mounjaro has various dosages and your provider created a specific plan for you. Please call your provider
              {providerPhoneNumber ? ' at ' : null}
              {providerPhoneNumber ? (
                <ExternalLink
                  textSize="mini"
                  href={`tel://${stripNonDigits(providerPhoneNumber)}`}
                >
                  {providerPhoneNumber}
                </ExternalLink>
              ) : null}{' '}
              if you are unsure of which strength you need at this time.
            </Description>
          </LgPadding>
        </FlatCard>
      </LgPadding>
      <InputRadioGroup
        onValueChange={(value) => {
          setSelectedNDC(value);
        }}
        defaultValue={selectedNDC}
      >
        {prescriptionsForNdc.map((prescriptionForNdc) => {
          // grabs the first prescription for specified dosage (in the case of multiple active prescriptions, we want to make sure we only show one on this screen)
          const prescription = prescriptionForNdc.prescriptions[0];
          const ndc = prescriptionForNdc.ndc;

          return (
            <InputRadio
              key={ndc}
              value={ndc}
              label={`${prescription.medication_name} ${MOUNJARO_NDC_DOSAGE_MAP[ndc]}`}
              descriptions={
                prescription.refills_remaining !== undefined
                  ? [
                      <ListDescription
                        key={prescription.refills_remaining}
                        iconName="pillbottle-small"
                        type="grey"
                      >
                        {prescription?.refills_remaining ? `${prescription.refills_remaining} refills remaining` : ''}
                      </ListDescription>,
                    ]
                  : []
              }
            />
          );
        })}
      </InputRadioGroup>
      <LgPadding>
        <Button
          label="Add to cart"
          onPress={onButtonPress}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
