// @owners { team: patients-team }
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { type AddOnOtcCategory } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc_category';
import { AddOnsEndpoint } from '@alto/scriptdash/alto/patient_app/add_ons/v1/add_ons_endpoint';
import { memoize } from 'lodash';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useQuery } from '~shared/react-query';

type FormattedEssentials = {
  categories: {
    array: AddOnOtcCategory[];
    hash: Record<string, AddOnOtcCategory>;
  };
  products: {
    array: AddOnOtc[];
    hash: Record<string, AddOnOtc>;
  };
};

const addOnsEndpoint = AddOnsEndpoint(apiEndpointHandler);

type GetFormattedEssentialsParams = {
  categories: AddOnOtcCategory[] | undefined;
  dataUpdatedAt: number;
};

const getFormattedEssentials = memoize(
  ({ categories }: GetFormattedEssentialsParams): FormattedEssentials => {
    const formattedEssentials = (categories ?? []).reduce(
      (hash: FormattedEssentials, category) => {
        hash.categories.array.push(category);
        hash.categories.hash[category.type] = category;
        category.add_on_otcs.forEach((product) => {
          if (!hash.products.hash[product.product_id]) {
            hash.products.array.push(product);
            hash.products.hash[product.product_id] = product;
          }
        });
        return hash;
      },
      { categories: { array: [], hash: {} }, products: { array: [], hash: {} } },
    );
    formattedEssentials.products.array = formattedEssentials.products.array.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    return formattedEssentials;
  },
  ({ dataUpdatedAt }) => dataUpdatedAt,
);

type QueryParams = {
  addressID?: number | undefined;
  date?: string | null | undefined;
};

export const useQueryEssentials = ({ addressID, date }: QueryParams) => {
  const { isPending, error, isError, data, dataUpdatedAt, isSuccess } = useQuery({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['essentials', { addressID, date }],
    queryFn: () => {
      if (!addressID) return null;

      return addOnsEndpoint.fetchCategorized({
        address_id: addressID,
        fill_date: date,
        prescription_ids: [],
      });
    },
    refetchOnMount: false,
  });

  const formattedEssentials = getFormattedEssentials({ categories: data?.data?.add_on_otc_categories, dataUpdatedAt });

  return {
    isPending,
    error,
    isError,
    isSuccess,
    essentials: formattedEssentials.products.array,
    categories: formattedEssentials.categories.array,
    essentialsHash: formattedEssentials.products.hash,
    categoriesHash: formattedEssentials.categories.hash,
  };
};
