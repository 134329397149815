// @owners { team: patients-team }
import { ActionSheetContext, Link } from '@alto/design-system';
import { TransferForm, TransferLearnMoreActionSheet } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { SimpleContainer, SimpleContainerHeader, SimpleContainerSection } from '@alto/pocky';
import React, { useContext, useEffect } from 'react';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, Main, PageBackground, Sidebar } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';

export const TransferPrescriptions = () => {
  const { trackPageView } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const showProgynySelfServiceForUser = useSelectorShared(getShowProgynySelfServiceForUser);

  useEffect(() => {
    trackPageView({
      event: EVENTS.TRANSFER_PRESCRIPTION_VIEWED,
      params: {
        origin: 'onboarding',
        is_progyny_rx_view: showProgynySelfServiceForUser,
        value_prop_variation: 'transfer-today',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transferSection = (
    <div>
      <TransferForm origin={ORIGIN_NAMES.TRANSFER} />
    </div>
  );

  const breadcrumbs = [
    {
      text: 'Account',
      to: '/me',
    },
    {
      text: 'Send prescriptions to Alto',
      to: '/me/send-prescription-options',
    },
    {
      text: 'Transfer Prescriptions',
    },
  ];

  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexRow>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <PageHeader>Transfer Prescriptions</PageHeader>
          <Link
            onPress={() => {
              setActiveActionSheet(<TransferLearnMoreActionSheet />);
            }}
          >
            How do transfers work?
          </Link>
        </FullFlexRow>
        <Main>
          <SimpleContainer>
            <SimpleContainerHeader>Start Transfer</SimpleContainerHeader>
            <SimpleContainerSection>{transferSection}</SimpleContainerSection>
          </SimpleContainer>
        </Main>
        <Sidebar>&nbsp;</Sidebar>
      </ContentWrapper>
    </PageBackground>
  );
};
