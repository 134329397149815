// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Column,
  Description,
  FlatCard,
  MdPadding,
  Pressable,
  Row,
  Separator,
  XxsSpacing,
} from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { usePrescriptionPricingById } from '~shared/queries/usePrescriptionPricingById';
import { useSelectorShared } from '~shared/store';
import { PricingBreakdownActionSheet } from '../../../pricing-breakdown';
import { PricingRow } from './PricingRow';
import { getMedDetailsPricingInfoFromPricing } from './getMedDetailsPricingInfoFromPricing';

export const PricingFlatCard = ({
  id,
  nextBillableDate,
  quantity,
  medicationName,
}: {
  readonly id: number;
  readonly nextBillableDate: string | null | undefined;
  readonly quantity: string;
  readonly medicationName: string;
}) => {
  // TODO: how do we handle family members' insurance?
  const { cash_pay_only } = useSelectorShared(getCurrentUser) || {};
  const { isPending, pricing } = usePrescriptionPricingById({ prescriptionId: id });
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const handleOpenPricingBreakdownActionSheet = useCallback(() => {
    setActiveActionSheet(
      <PricingBreakdownActionSheet
        prescriptions={[{ id, medication_name: medicationName, product_quantity: quantity }]}
        origin="MedDetailsPrice"
        forSinglePrescription
      />,
    );
  }, [id, quantity, setActiveActionSheet, medicationName]);

  // Loading State && Empty State
  // Empty loading state because we don't want to flash the FlatCard if we don't end up with pricing
  if (isPending || !pricing?.prices.length) {
    return <XxsSpacing />;
  }

  const { hasFullPricingDetails, insurancePriceInfo, progynyPriceInfo, cashPriceInfo } =
    getMedDetailsPricingInfoFromPricing({
      pricing,
      nextBillableDate,
      cashPayOnly: cash_pay_only,
    });

  return (
    <FlatCard>
      <MdPadding
        topPadding={SPACING.STATIC.SM}
        bottomPadding={SPACING.STATIC.SM}
      >
        <Column gap="XS">
          {progynyPriceInfo ? (
            <PricingRow
              prescriptionId={id}
              pricingInfo={progynyPriceInfo}
            />
          ) : null}
          {insurancePriceInfo ? (
            <PricingRow
              prescriptionId={id}
              pricingInfo={insurancePriceInfo}
            />
          ) : null}
          {cashPriceInfo ? (
            <PricingRow
              prescriptionId={id}
              pricingInfo={cashPriceInfo}
            />
          ) : null}
        </Column>
      </MdPadding>
      {hasFullPricingDetails ? (
        <>
          <Separator />
          <Pressable onPress={handleOpenPricingBreakdownActionSheet}>
            <MdPadding
              topPadding={SPACING.STATIC.SM}
              bottomPadding={SPACING.STATIC.SM}
            >
              <Row
                centerVertically
                spaceBetween
              >
                <Description
                  color={COLORS.TEXT_COLORS.SECONDARY_DARK}
                  fontFamily="semibold"
                >
                  Full pricing details
                </Description>
                <AltoIcon
                  type="secondary"
                  name="chevronright-small"
                />
              </Row>
            </MdPadding>
          </Pressable>
        </>
      ) : undefined}
    </FlatCard>
  );
};
