import { BORDERS, COLORS, SHADOWS, SPACING } from '@alto/design-library-tokens';
import React, { useState } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { useScreenSize } from '../../../utils';
import { MdPadding, Row, XxsPadding } from '../../containers';
import { Caption, Text } from '../../typography';

type TouchableProps = {
  disabled?: boolean;
  selected: boolean;
  isPressed: boolean;
  showSelected: boolean;
};

type Props = {
  readonly disabled?: boolean;
  readonly label: string;
  readonly sublabel?: string;
  readonly onPress: () => void;
  readonly selected: boolean;
  readonly itemsQuantity: number;
  readonly showSelected: boolean;
  readonly testID?: string;
};

const getTextColor = (showSelected: boolean, selected: boolean, disabled = false) => {
  if (disabled) {
    return COLORS.TEXT_COLORS.DISABLED;
  }
  return selected && showSelected ? COLORS.TEXT_COLORS.WHITE : COLORS.TEXT_COLORS.SECONDARY_DARK;
};

const getBackgroundColor = (isPressed: boolean, selected: boolean, disabled = false) => {
  if (selected && disabled) {
    return COLORS.PALETTE.GREYSCALE.DEFAULT;
  }
  return isPressed && !selected ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER : COLORS.BACKGROUND_COLORS.TRANSPARENT;
};

const getBorderColor = (isPressed: boolean, selected: boolean) => {
  return !selected && isPressed ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER : COLORS.BACKGROUND_COLORS.TRANSPARENT;
};

const OptionContainer = styled(XxsPadding)<{ itemsQuantity: number }>`
  width: ${({ itemsQuantity }) => (itemsQuantity ? `${100 / itemsQuantity}%` : 'auto')};
`;

const StyledPressable = styled(Pressable)<TouchableProps>`
  flex-grow: 1;
  background-color: ${({ disabled, selected, isPressed }) => getBackgroundColor(isPressed, selected, disabled)};
  border: 1px solid ${({ selected, isPressed }) => getBorderColor(isPressed, selected)};
  border-radius: ${BORDERS.RADIUS.XL.px};
  ${({ showSelected, selected, disabled }) =>
    !disabled &&
    showSelected &&
    selected &&
    `
    background-color: ${COLORS.BACKGROUND_COLORS.SECONDARY};
    box-shadow: ${SPACING.STATIC.NONE.value} ${SPACING.STATIC.XXS.px} ${SPACING.STATIC.MD.px} ${SHADOWS.BOTTOM};
  `}
`;

export const StyledMdPadding = styled(MdPadding)`
  width: 100%;
`;

export const ButtonSelectorOption = ({
  disabled = false,
  label,
  sublabel,
  onPress,
  selected,
  itemsQuantity,
  showSelected,
  testID,
}: Props) => {
  const [isTouchablePressed, setIsTouchablePressed] = useState(false);
  const { isXSScreen } = useScreenSize();

  return (
    <OptionContainer
      itemsQuantity={itemsQuantity}
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      <StyledPressable
        accessibilityLabel={label}
        accessibilityRole="radio"
        accessibilityState={{
          disabled,
          selected,
          checked: selected,
        }}
        // @ts-expect-error issue with react-native-web types
        accessibilityChecked={selected}
        disabled={disabled}
        isPressed={isTouchablePressed}
        onPress={onPress}
        onPressIn={() => {
          setIsTouchablePressed(true);
        }}
        onPressOut={() => {
          setIsTouchablePressed(false);
        }}
        selected={selected}
        showSelected={showSelected}
        testID={testID}
      >
        <Row
          center
          flexGrow={1}
        >
          <StyledMdPadding
            topPadding={SPACING.STATIC.XS}
            bottomPadding={SPACING.STATIC.XS}
            leftPadding={isXSScreen ? SPACING.STATIC.XS : SPACING.STATIC.MD}
            rightPadding={isXSScreen ? SPACING.STATIC.XS : SPACING.STATIC.MD}
          >
            <Text
              center
              textSize="mini"
              fontFamily="semibold"
              color={getTextColor(showSelected, selected, disabled)}
            >
              {label}
            </Text>
            {sublabel ? (
              <Caption
                center
                fontFamily="semibold"
                color={getTextColor(showSelected, selected, disabled)}
              >
                {sublabel}
              </Caption>
            ) : null}
          </StyledMdPadding>
        </Row>
      </StyledPressable>
    </OptionContainer>
  );
};
