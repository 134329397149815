// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, XsSpacing } from '@alto/design-system';
import React from 'react';
import doublePillBottle from '~shared/assets/images/double-pill-bottle.png';

export const AlternativeMedicationActionSheet = ({
  medicationName,
  providerName,
}: {
  readonly medicationName: string | undefined;
  readonly providerName: string;
}) => (
  <ActionSheetV2
    title="Alternative medication"
    illustrationSrc={doublePillBottle}
  >
    <LgPadding
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      <Body>
        Your original prescription
        {medicationName ? (
          <>
            {' '}
            for <Body fontFamily="semibold">{medicationName}</Body>{' '}
          </>
        ) : (
          ' '
        )}
        was not covered by your insurance. Your provider{providerName ? `, ${providerName}, ` : ' '}has chosen an
        alternative medication to reduce your costs.
      </Body>
      <XsSpacing />
      <Body>
        This alternative is in the same therapeutic class, which means it has similar chemical characteristics and ways
        of working and targets the same condition.
      </Body>
    </LgPadding>
  </ActionSheetV2>
);
