// @owners { team: patients-team }
export const SET_ADD_FAMILY_MEMBER_RELATIONSHIP = 'SET_ADD_FAMILY_MEMBER_RELATIONSHIP';

export type ActionSetAddFamilyMemberRelationship = {
  type: typeof SET_ADD_FAMILY_MEMBER_RELATIONSHIP;
  payload: string;
};

export function setAddFamilyMemberRelationship(relationship: string) {
  return {
    type: SET_ADD_FAMILY_MEMBER_RELATIONSHIP,
    payload: relationship,
  };
}
