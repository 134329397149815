import { createSelector } from 'reselect';
import { getAppTime } from '~shared/features/app-time/selectors/getAppTime';
import { type ReduxStateShared } from '~shared/types';

const DEFAULT_FRESH_THRESHOLD = 1000 * 60 * 10; // 10 minutes

const STALE_THRESHOLD_MULTIPLIER = 6;
const AVAILABLE_THRESHOLD_MULTIPLIER = 2;

type ShouldFetchOptions = {
  availableThreshold?: number;
  // threshold to allow showing a resource in the UI even if slightly old
  staleThreshold?: number;
  // threshold to never show the resource in the UI
  freshThreshold?: number;
  // threshold to consider a resource fresh/trigger a refresh
  fetcher: () => any;
  // actually fetch
  isFetching: (state: ReduxStateShared) => boolean;
  // selector for when we're already fetching
  lastFetchAttempt: (state: ReduxStateShared) => number | null;
  // selector for last time we attempted to fetch this resource
  fetchAttemptCount: (state: ReduxStateShared) => number;
  // selector for how many times we have attempted to fetch this resource since last success
  lastFetchSuccess: (state: ReduxStateShared) => number | null; // selector for last time we successfully fetched this resource
};

export function getResourceStale(options: ShouldFetchOptions): (state: ReduxStateShared) => boolean {
  return createSelector([getAppTime, options.lastFetchSuccess], (currentTime, lastFetchSuccess) => {
    const staleThreshold =
      options.staleThreshold ||
      (options.freshThreshold && options.freshThreshold * STALE_THRESHOLD_MULTIPLIER) ||
      DEFAULT_FRESH_THRESHOLD * STALE_THRESHOLD_MULTIPLIER;
    const msSinceLastSuccess = currentTime - (lastFetchSuccess || 0);
    return msSinceLastSuccess > staleThreshold;
  });
}

export function getResourceAvailable(options: ShouldFetchOptions): (state: ReduxStateShared) => boolean {
  return createSelector([getAppTime, options.lastFetchSuccess], (currentTime, lastFetchSuccess) => {
    const availableThreshold =
      options.availableThreshold ||
      (options.freshThreshold && options.freshThreshold * AVAILABLE_THRESHOLD_MULTIPLIER) ||
      DEFAULT_FRESH_THRESHOLD * AVAILABLE_THRESHOLD_MULTIPLIER;
    const msSinceLastSuccess = currentTime - (lastFetchSuccess || 0);
    return msSinceLastSuccess < availableThreshold;
  });
}
