// @owners { team: patients-team }
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/no-deprecated
import { signUp } from '~shared/actions/auth';
import { changeModalStep, openModal } from '~shared/actions/modal';
import {
  type VerificationCommsChannel,
  clearOnboardingVerificationRequired,
  // eslint-disable-next-line import/no-deprecated
  completeVerification,
} from '~shared/actions/verifications';
import {
  getOnboardingVerificationChannel,
  getOnboardingVerificationContact,
  getOnboardingVerificationUser,
  getOnboardingVerificationUuid,
} from '~shared/features/onboarding/selectors/getOnboardingVerification';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { connect } from '~shared/store';
import { type APIError, type ReduxStateShared } from '~shared/types';
import OnboardingContactVerification, { type Props } from './OnboardingContactVerification';

type StateProps = {
  channel: VerificationCommsChannel | null | undefined;
  contact: string;
  error: APIError | null | undefined;
  loading: boolean;
  user: Record<string, unknown>;
  uuid: string | null | undefined;
};

type DispatchProps = {
  changeModalStep: typeof changeModalStep;
  clearOnboardingVerificationRequired: typeof clearOnboardingVerificationRequired;
  completeVerification: any;
  onResendCode: () => void;
  push: typeof push;
  // eslint-disable-next-line import/no-deprecated
  signUp: typeof signUp;
};

const mapStateToProps = (state: ReduxStateShared): StateProps => {
  const loading = getLoading(state);
  const errors = getErrors(state);

  // filter out 409 status signup errors, these will exist by definition of being inside this flow
  const error =
    errors.completeVerificationError || (errors.signupError?.details?.statusCode === 409 ? null : errors.signupError);

  const user = getOnboardingVerificationUser(state);
  const channel = getOnboardingVerificationChannel(state);
  const uuid = getOnboardingVerificationUuid(state);
  const contact = getOnboardingVerificationContact(state);
  return {
    channel,
    contact,
    error,
    loading: loading.completeVerificationLoading || loading.signupLoading,
    // @ts-expect-error TS(2322): Type 'Record<string, unknown> | null | undefined' is not assignable to type 'Record<string, unknown>'.
    user,
    uuid,
  };
};

// @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    onResendCode: () =>
      dispatch(
        openModal('CONTACT_VERIFICATION', {
          resend: true,
        }),
      ),
    ...bindActionCreators(
      {
        changeModalStep,
        clearOnboardingVerificationRequired,
        // eslint-disable-next-line import/no-deprecated
        completeVerification,
        push,
        // eslint-disable-next-line import/no-deprecated
        signUp,
      },
      dispatch,
    ),
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): Props => {
  const { uuid, user, ...otherStateProps } = stateProps;
  const { channel } = otherStateProps;

  const {
    changeModalStep,
    clearOnboardingVerificationRequired,
    completeVerification,
    push,
    signUp,
    ...otherDispatchProps
  } = dispatchProps;

  // @ts-expect-error TS(7031): Binding element 'code' implicitly has an 'any' type.
  const onSubmit = ({ code }) => {
    // eslint-disable-next-line promise/catch-or-return
    completeVerification({
      code,
      comms_channel: channel,
      uuid,
      // @ts-expect-error TS(7031): Binding element 'verificationSuccess' implicitly has an 'any' type.
    }).then(({ success: verificationSuccess, result }) => {
      // eslint-disable-next-line promise/always-return
      if (verificationSuccess) {
        // @ts-expect-error TS(7031): Binding element 'success' implicitly has an 'any' type.
        // eslint-disable-next-line promise/catch-or-return, promise/no-nesting
        signUp({ ...user, ...result }).then(({ success }) => {
          // eslint-disable-next-line promise/always-return
          if (success) {
            push('/prescriptions');
            changeModalStep(0, 'ONBOARDING_MODAL');
          }
        });
      }
    });
  };

  const back = () => {
    clearOnboardingVerificationRequired();
  };

  return { ...otherStateProps, ...otherDispatchProps, onSubmit, back };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OnboardingContactVerification);
