// @owners { team: patients-team }
import { differenceInWeeks, parseISO } from 'date-fns';
import {
  getPastShipmentsWithItemsAndAdditionalFields,
  getUpcomingShipmentsWithItemsAndAdditionalFields,
} from '~shared/features/shipments/selectors/getShipmentsWithAdditionalFields';
import { makeCachedSortByISODateString } from '~shared/helpers/sort';
import { type ReduxStateShared } from '~shared/types';

export const MAX_SHIPMENTS_SHOWN = 3;
const sortByDate = makeCachedSortByISODateString();

export const getUpcomingShipments = (state: ReduxStateShared) =>
  getUpcomingShipmentsWithItemsAndAdditionalFields(state).sort((a, b) => sortByDate(a.date, b.date));

export const getPastShipments = (state: ReduxStateShared) =>
  getPastShipmentsWithItemsAndAdditionalFields(state).sort((a, b) => sortByDate(b.date, a.date));

/**
 * was the past shipment delivered in the last 26 weeks
 * Shipments are already sorted by date descending.
 */
export const isRecentPastShipment = (shipment: ReturnType<typeof getPastShipments>[0]) => {
  const { delivered_at } = shipment;
  const weeksSinceDelivery = delivered_at ? differenceInWeeks(new Date(), parseISO(delivered_at)) : 0;
  return weeksSinceDelivery <= 26;
};

export const getRecentShipments = (state: ReduxStateShared) => {
  const pastShipments = getPastShipments(state);
  const upcomingShipments = getUpcomingShipments(state);
  return [...upcomingShipments, ...pastShipments.slice(0, MAX_SHIPMENTS_SHOWN).filter(isRecentPastShipment)];
};
