// @owners { team: patients-team }
import {
  ActionSheetContext,
  Button,
  Column,
  InputAutocomplete,
  InputCheckbox,
  InputSelect,
  Row,
} from '@alto/design-system';
import React, { useCallback, useContext, useState } from 'react';
import { getFamilyMembers } from '~shared/features/family-accounts/selectors/getFamilyMembers';
import { ALL_ACTIVE_RXS } from '~shared/features/transfers/constants';
import { type ApiResponseRow, type Pharmacy } from '~shared/features/transfers/types';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getDisplayFullName } from '~shared/helpers/helper';
import isKaiserPharmacy from '~shared/helpers/isKaiserPharmacy';
import { formatMedications } from '~shared/helpers/transfers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type TransferFormOrigin } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { googlePlacesEstablishmentDataSource } from '../../address/components/data-source/googlePlacesDataSource';
import { medicationsAutocompleteDataSource } from '../../medication/components/data-sources/medicationsAutocompleteDataSource';
import { useCreateTransfer } from '../queries/useCreateTransfer';
import { KaiserActionSheet } from './KaiserActionSheet';

type TransferFormProps = {
  /**
   * Where this TransferForm component is being used, this is needed for analytics and attributing inbound transfer
   * requests to the appropriate page
   */
  readonly origin: TransferFormOrigin;
};

const isWeb = getPlatformOS() === 'web';

export const TransferForm = ({ origin }: TransferFormProps) => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const users = useSelectorShared(getFamilyMembers);
  const hasFamily = useSelectorShared(getHasFamily);
  const preselectedPatientID = useSelectorShared(getSelectedUserID);

  const [transferAllMeds, setTransferAllMeds] = useState(false);
  const [transferMedList, setTransferMedList] = useState<ApiResponseRow<any>[]>([]);
  const [pharmacyError, setPharmacyError] = useState(false);
  const [medicationError, setMedicationError] = useState(false);
  const [pharmacy, setPharmacy] = useState<Pharmacy | undefined>(undefined);
  const [selectedPatientID, setSelectedPatientID] = useState<number>(preselectedPatientID || users[0].id);
  const { createTransferMutate, isCreateTransferPending } = useCreateTransfer();

  const medsToTransferAreValid = transferAllMeds || transferMedList.length;
  const submitButtonWidth = isWeb ? 'inline' : 'full';

  const submitButtonFunction = useCallback(() => {
    if (!pharmacy) {
      setPharmacyError(true);
    }
    if (!medsToTransferAreValid) {
      setMedicationError(true);
    }
    if (!pharmacy || !medsToTransferAreValid) {
      return; // don't submit if there are validation errors on inputs
    }
    createTransferMutate({
      medications: transferAllMeds ? ALL_ACTIVE_RXS : JSON.stringify(formatMedications(transferMedList)),
      user_id: selectedPatientID,
      pharmacy,
      origin,
    });
  }, [
    createTransferMutate,
    medsToTransferAreValid,
    origin,
    pharmacy,
    selectedPatientID,
    transferAllMeds,
    transferMedList,
  ]);

  return (
    <Column gap="LG">
      <InputAutocomplete
        accessibilityLabel="Search for your pharmacy"
        label="Current pharmacy"
        title="Find a pharmacy"
        onSelectCallback={(data: ApiResponseRow<Pharmacy>[]) => {
          const selectedPharmacyName = data[data.length - 1]?.title ?? '';
          if (isKaiserPharmacy(selectedPharmacyName)) {
            setActiveActionSheet(<KaiserActionSheet onDismiss={closeActionSheet} />);
          } else {
            closeActionSheet();
          }
          if (data.length) {
            setPharmacy(data[data.length - 1].value);
          }
        }}
        error={pharmacyError && !pharmacy ? 'Please select a pharmacy' : undefined}
        dataSource={googlePlacesEstablishmentDataSource}
        placeholder="Search for your pharmacy"
        required
        onSelectAutoCloseActionSheet={false}
      />
      {hasFamily ? (
        <InputSelect
          label="Who is this for?"
          onValueChange={(value) => {
            setSelectedPatientID(parseInt(value, 10));
          }}
          value={selectedPatientID.toString()}
          placeholder="Select who this is for"
          options={users.map((user) => ({
            label: getDisplayFullName(user),
            value: user.id.toString(),
          }))}
          required
        />
      ) : null}
      {!transferAllMeds && (
        <InputAutocomplete
          leftIcon="pillbottle-small"
          accessibilityLabel="List the medications you want to transfer to Alto"
          label="Medication names"
          title="Find medications"
          shouldAllowManualInput
          selectionType="multi"
          onSelectCallback={(medList) => {
            trackEvent({
              event: EVENTS.TRANSFER__MEDICATION_SELECTED,
              params: { origin },
            });
            setTransferMedList(medList);
          }}
          error={
            medicationError && !medsToTransferAreValid
              ? 'Please select at least one medication, or transfer all medications on file'
              : undefined
          }
          dataSource={medicationsAutocompleteDataSource}
          placeholder="e.g. Albuterol, Amoxicillin"
          required
        />
      )}
      <InputCheckbox
        label="Transfer all medications on file"
        name="transfer_all"
        onPress={() => {
          setTransferAllMeds(!transferAllMeds);
          setTransferMedList([]); // clear out the medication list if the user selects "transfer all"
        }}
        value={transferAllMeds}
        testID="e2e-checkbox-transfer-all-meds"
        fullBleed
        noHighlight
      />
      <Row>
        <Button
          label="Send transfer request"
          testID="send-transfer-request"
          onPress={submitButtonFunction}
          width={submitButtonWidth}
          loading={isCreateTransferPending}
          disabled={isCreateTransferPending}
        />
      </Row>
    </Column>
  );
};
