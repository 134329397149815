// @owners { team: patients-team }
import React, { useCallback, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { type OnboardingUserUpdate, setInsuranceLoadingSeen, updateOnboardingUser } from '~shared/actions/onboarding';
import { setEditingInsuranceID } from '~shared/actions/onboarding';
import { ONBOARD_STATE_INSURANCE_FOUND } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getInsuranceLoadingSeen } from '~shared/features/onboarding/selectors/getOnboarding';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { OnboardingInsuranceLoadingPage } from './OnboardingInsuranceLoadingPage';
import { OnboardingInsuranceFoundPage } from '~web/features/onboarding/components/redesign/OnboardingInsuranceFoundPage';

type Props = {
  readonly insuranceLoadingSeen: boolean;
  readonly previousUrl?: string;
  // eslint-disable-next-line import/no-deprecated
  readonly updateOnboardingUser: (update: OnboardingUserUpdate) => Promise<boolean>;
  readonly setInsuranceLoadingSeen: () => void;
  readonly showProgyny: boolean;
};

const OnboardingInsuranceFoundContainer = ({
  previousUrl,
  showProgyny,
  updateOnboardingUser,
  setInsuranceLoadingSeen,
  insuranceLoadingSeen,
}: Props) => {
  const { trackPageView, trackEvent } = useAnalytics();
  const onSetInsuranceLoadingSeen = useCallback(() => {
    setInsuranceLoadingSeen();
    trackEvent({ event: EVENTS.ONBOARDING__INSURANCE_ANIMATION_VIEWED });
  }, [setInsuranceLoadingSeen, trackEvent]);

  useEffect(() => {
    updateOnboardingUser({
      onboard_state: ONBOARD_STATE_INSURANCE_FOUND,
    });

    trackPageView({
      event: EVENTS.ONBOARDING__INSURANCE_FOUND_VIEWED,
      params: { origin: previousUrl ? routeFromPath(previousUrl) : '', is_progyny_rx_view: showProgyny },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (insuranceLoadingSeen) {
    return <OnboardingInsuranceFoundPage />;
  }

  return <OnboardingInsuranceLoadingPage onContinue={onSetInsuranceLoadingSeen} />;
};

const mapStateToProps = (state: ReduxStateShared) => {
  const previousUrl = previousWebURL(state);

  return {
    showProgyny: getShowProgynySelfServiceForUser(state),
    previousUrl,
    insuranceLoadingSeen: getInsuranceLoadingSeen(state),
  };
};

const mapDispatchToProps = {
  // eslint-disable-next-line import/no-deprecated
  updateOnboardingUser,
  setInsuranceLoadingSeen,
  navigateTo: push,
  setEditingInsuranceID,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingInsuranceFoundContainer);
