// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getCurrentUserID, getUsers } from './getUsers';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

type IsProgynyUserByID = Record<number, boolean>;

const getIsProgynyUserByID = createSelector(getUsers, (users) => {
  const isProgynyUserByID: IsProgynyUserByID = {};

  users.forEach((user) => {
    isProgynyUserByID[user.id] = !!user.is_progyny;
  });

  return isProgynyUserByID;
});

const getUserID = (_: ReduxStateShared, props: UserIDParam) => props.userID;

export const getIsProgynyUser = createSelector([getUserID, getIsProgynyUserByID], (id, isProgynyUser) => {
  if (!id) return false;

  return !!isProgynyUser[id];
});

export const getIsCurrentUserProgyny = createSelector([getCurrentUserID, getIsProgynyUserByID], (id, isProgynyUser) => {
  if (!id) return false;

  return !!isProgynyUser[id];
});
