import { createSelector } from 'reselect';
import getSelectedPaymentTypes from './getSelectedPaymentTypes';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

const getSelectedPaymentTypeForPrescription = createSelector(
  [getSelectedPaymentTypes, getPrescriptionID],
  (paymentTypes, prescriptionID) => paymentTypes?.[prescriptionID] || null,
);

export default getSelectedPaymentTypeForPrescription;
