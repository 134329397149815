import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, spacing } from '@alto/pocky';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/prescription';
import React from 'react';
import { HeaderText, MedContainer, MedSummaryRow, PriceContainer } from './MedSummaryRow';

type Props = {
  readonly includeSharpsContainer: boolean;
  readonly cartItems: CartItem[];
  readonly upcomingBundlePrescriptionIDs?: Prescription['id'][];
};

export const MedSummaryList = ({ includeSharpsContainer, cartItems, upcomingBundlePrescriptionIDs }: Props) => {
  return (
    <div>
      {upcomingBundlePrescriptionIDs?.map((id) => (
        <MedSummaryRow
          key={id}
          prescriptionID={id}
        />
      ))}
      {cartItems.map((item) => (
        <MedSummaryRow
          key={`${item.resource_id}${item.cart_id}`}
          prescriptionID={item.resource_id}
          includeNewItemTag={(upcomingBundlePrescriptionIDs || []).length > 0}
        />
      ))}
      {includeSharpsContainer ? (
        <FlexColumn
          evenlySpaceContent
          spacing={spacing.smSpacing}
          padding={`0 0 ${spacing.smSpacing}`}
        >
          <MedContainer>
            <HeaderText>Sharps Container</HeaderText>
          </MedContainer>
          <PriceContainer>
            <HeaderText>Free</HeaderText>
          </PriceContainer>
        </FlexColumn>
      ) : null}
    </div>
  );
};
