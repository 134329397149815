import { type BACKGROUND_COLORS_VALUES, type BORDERS_RADIUS_SIZE, COLORS } from '@alto/design-library-tokens';
import * as React from 'react';
import {
  type AccessibilityProps,
  type Insets,
  Pressable as OriginalPressable,
  Platform,
  type PressableProps as RNPressableProps,
  type StyleProp,
  View,
  type ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import { type PlatformSpecificPressableProps } from './PlatformSpecificPressableProps';

const noop = () => null;

type StyledPressableProps = {
  width: PressableProps['width'];
  height: PressableProps['height'];
  borderRadius: PressableProps['borderRadius'];
  backgroundColor: PressableProps['backgroundColor'];
};

export type PressableProps = PlatformSpecificPressableProps &
  Pick<RNPressableProps, 'onPressIn' | 'onPressOut' | 'onHoverIn' | 'onHoverOut'> &
  AccessibilityProps & {
    readonly pressedBackgroundColor?: BACKGROUND_COLORS_VALUES;
    readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
    readonly children: React.ReactNode;
    readonly height?: string;
    readonly hoverBackgroundColor?: BACKGROUND_COLORS_VALUES;
    readonly disabled?: boolean;
    /**
     * This defines how far your touch can start away from the button.
     */
    readonly hitSlop?: Insets;
    readonly width?: string;
    /**
     * Use for testing-library identification purposes.
     */
    readonly testID?: string;
    readonly borderRadius?: BORDERS_RADIUS_SIZE;
    readonly opacity?: boolean;
    readonly accessibilityChecked?: boolean;
    readonly style?: StyleProp<ViewStyle>;
  };

const StyledPressable = styled(OriginalPressable)<StyledPressableProps>`
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius.px}`};
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
  ${() => Platform.OS === 'web' && 'display: inline-flex; '};
`;

const StyledView = styled(View)<{
  backgroundColor: PressableProps['backgroundColor'];
  borderRadius: PressableProps['borderRadius'];
  disabled?: PressableProps['disabled'];
  hoverBackgroundColor?: PressableProps['hoverBackgroundColor'];
  hovered?: boolean;
  opacity: PressableProps['opacity'];
  pressed: boolean;
  pressedBackgroundColor?: PressableProps['pressedBackgroundColor'];
  style?: StyleProp<ViewStyle>;
}>`
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius.px}`};
  background-color: ${({ pressed, backgroundColor, pressedBackgroundColor }) =>
    pressed && pressedBackgroundColor ? pressedBackgroundColor : backgroundColor};
  opacity: ${({ pressed, opacity }) => (pressed && opacity ? 0.5 : 1)};

  ${({ hovered, hoverBackgroundColor, pressed }) =>
    hovered && hoverBackgroundColor && !pressed && `background-color: ${hoverBackgroundColor};`};
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`;

export const Pressable = ({
  accessibilityLabel,
  accessibilityRole = 'button',
  accessibilityState,
  accessibilityChecked,
  backgroundColor = COLORS.BACKGROUND_COLORS.TRANSPARENT,
  borderRadius,
  children,
  disabled,
  height,
  hitSlop,
  hoverBackgroundColor,
  onPress,
  onPressIn,
  onPressOut,
  onHoverIn,
  onHoverOut,
  opacity = true,
  pressedBackgroundColor,
  testID = 'pressable',
  width,
  style,
}: PressableProps) => (
  <StyledPressable
    borderRadius={borderRadius}
    accessibilityLabel={accessibilityLabel}
    accessibilityRole={accessibilityRole}
    accessibilityState={accessibilityState}
    // @ts-expect-error issue with react-native-web types
    accessibilityChecked={accessibilityChecked}
    backgroundColor={backgroundColor}
    height={height}
    onPress={onPress}
    onPressIn={onPressIn ?? noop}
    onPressOut={onPressOut ?? noop}
    onHoverIn={onHoverIn ?? noop}
    onHoverOut={onHoverOut ?? noop}
    disabled={disabled}
    width={width}
    hitSlop={hitSlop}
    testID={testID}
    style={style}
  >
    {/* @ts-expect-error issue with react-native-web types */}
    {({ pressed, hovered }) => (
      <StyledView
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        hoverBackgroundColor={hoverBackgroundColor || pressedBackgroundColor}
        hovered={hovered}
        opacity={opacity}
        pressed={pressed}
        pressedBackgroundColor={pressedBackgroundColor}
        disabled={disabled}
        style={style}
      >
        {children}
      </StyledView>
    )}
  </StyledPressable>
);
