import { BORDERS, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { AltoIcon, Column, Description, LgSpacing, Row, TagText, XxsSpacing } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { Drawer } from '~web/components/Drawer';
import ImageComponent from '~web/components/Image';
import altoMiniIcon from '~web/images/alto-a_icon@2x.png';
import appAlto from '~web/images/app-icon_spot-illo@2x.png';
import browserIcon from '~web/images/browser-icon_spot-illo@2x.png';

const OptionRow = styledNative(Row)<{ borderBottom?: boolean }>`
  padding: ${SPACING.STATIC.MD.px} 0;
  border-bottom: ${({ borderBottom }) => (borderBottom ? `1px solid ${COLORS.BORDER_COLORS.LIGHT}` : 'none')};
`;

const Tag = styledNative(Row)`
  padding: 6px 12px;
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER};
  border-radius: ${BORDERS.RADIUS.LG.px};
  margin-top: ${SPACING.STATIC.XS.px};
  width: fit-content;
`;

const ImageMini = styled.img`
  width: 12px;
`;

const OptionLabel = styledNative(Description)`
  font-size: 21px;
  font-family: ${TYPOGRAPHY.FONT.HEADER.SEMIBOLD};
  color: ${COLORS.TEXT_COLORS.PRIMARY};
`;

export type Props = {
  readonly onClose: () => void;
  readonly handleAppSelected: () => void;
  readonly handleBrowserSelected: () => void;
};

const GetTheAppDrawer = ({ onClose, handleBrowserSelected, handleAppSelected }: Props) => {
  return (
    <Drawer
      title="How do you want to use Alto?"
      onClose={onClose}
      dismissable
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        onClick={handleAppSelected}
        role="button"
        tabIndex={0}
      >
        <OptionRow
          center
          borderBottom
        >
          <ImageComponent
            alt="Alto App Logo"
            src={appAlto}
            sidebar
          />
          <LgSpacing />
          <Column flexGrow={1}>
            <OptionLabel>Mobile App</OptionLabel>
            <XxsSpacing />
            <Tag>
              <ImageMini
                alt="Alto Mini Logo"
                src={altoMiniIcon}
              />
              <XxsSpacing />
              <TagText>Recommended</TagText>
            </Tag>
          </Column>
          <AltoIcon
            name="chevronright"
            type="grey"
          />
        </OptionRow>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        onClick={handleBrowserSelected}
        role="button"
        tabIndex={0}
      >
        <OptionRow center>
          <ImageComponent
            alt="Browser Logo"
            src={browserIcon}
            sidebar
          />
          <LgSpacing />
          <Column flexGrow={1}>
            <OptionLabel>Website</OptionLabel>
          </Column>
          <AltoIcon
            name="chevronright"
            type="grey"
          />
        </OptionRow>
      </div>
    </Drawer>
  );
};

export default GetTheAppDrawer;
