// @owners { team: patients-team }
import { Card, LgPadding, LoadingEmptyState, Pressable } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { type SupportCase } from '@alto/scriptdash/alto/customer_support/types/v1/support_case';
import React from 'react';
import { useGetSupportCaseTopic } from '../../queries/queries';
import { SupportCaseCardItem } from './SupportCaseCardItem';

type SupportCaseCardProps = {
  readonly supportCase: SupportCase;
  readonly pressable?: boolean;
};

/**
 * Component for a support case card that shows the topic of the case, status of the case, and
 * when it was last updated. Unread support cases are indicated with a dot.
 * @param {SupportCaseCardProps} props component props
 * @returns SupportCaseCard UI
 */
export const SupportCaseCard = ({ supportCase, pressable = true }: SupportCaseCardProps) => {
  const { navigate } = useNavigation();
  const supportCaseID = supportCase.id;
  const { isPending } = useGetSupportCaseTopic(supportCase);

  const handleSupportCasePress = () => {
    navigate('RouteAssistantSupportCase', { supportCaseID });
  };

  if (isPending) {
    return (
      <Card>
        <LgPadding>
          <LoadingEmptyState />
        </LgPadding>
      </Card>
    );
  }

  if (pressable) {
    return (
      <Pressable
        onPress={handleSupportCasePress}
        accessibilityLabel={`Press to view conversation about ${supportCase?.most_recent_message?.body}`}
      >
        <SupportCaseCardItem
          supportCase={supportCase}
          pressable
        />
      </Pressable>
    );
  }

  return <SupportCaseCardItem supportCase={supportCase} />;
};
