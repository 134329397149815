import { Toast, ToastContext } from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { clearInsuranceError, createInsurance, updateInsurance } from '~shared/actions/insurances';
import { changeModalStep } from '~shared/actions/modal';
import { updateCashPay } from '~shared/actions/users';
import { getInferredInsuranceFormValues } from '~shared/features/insurances/helpers/getInferredInsuranceFormValues';
import { type Values } from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getSelectedInsuranceID } from '~shared/features/insurances/selectors/getInsurances';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getSelectedUser } from '~shared/features/users/selectors/getSelectedUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getInsuranceSource } from '~shared/lib/analytics/src/getInsuranceSource';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types/clients';
import InsuranceForm from './InsuranceForm';
import { removeEmptyValues } from './InsuranceFormElements';

type Props = {
  readonly onCancel: () => void;
  readonly showPhotoForm?: () => void;
};

export const InsuranceFormContainer = ({ onCancel, showPhotoForm }: Props) => {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const selectedUser = useSelectorShared(getSelectedUser);
  const currentUser = useSelectorShared(getCurrentUser);
  const user = selectedUser || currentUser;
  const dispatch = useDispatchShared();
  const source = useSelectorShared(getInsuranceSource);
  const loading = useSelectorShared(
    (state) => state.ui.loading.createInsuranceLoading || state.ui.loading.updateInsuranceLoading,
  );
  const insuranceID = useSelectorShared(getSelectedInsuranceID);
  const selectedInsurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const insuranceError = useSelectorShared(
    (state) =>
      state.ui.errors.createInsuranceError ||
      state.ui.errors.updateInsuranceError ||
      state.ui.errors.createPhotoInsuranceError,
  );

  const handleShowPhotoForm = () => {
    dispatch(clearInsuranceError);
    if (showPhotoForm) {
      showPhotoForm();
    } else {
      dispatch(changeModalStep(1, 'INSURANCE_MODAL'));
    }
  };

  const onFinish = async (insurance: Insurance | false) => {
    if (!insurance) return;

    // update user's cash_pay_only when insurance is saved
    if (user?.cash_pay_only) {
      const { success } = await dispatch(updateCashPay(user.id, false));
      if (success) {
        trackEvent({
          event: EVENTS.CASH_PAY_ONLY_SET,
          params: {
            source,
            'new value': false,
          },
        });
      }
    }

    if (selectedInsurance) {
      addToast(<Toast>Your insurance has been updated!</Toast>);
    } else {
      addToast(<Toast>Your insurance has been saved!</Toast>);
      trackEvent({
        event: EVENTS.INSURANCE_ADDED,
        params: {
          type: 'manual',
          source,
          entity_name: insurance.insurance_plan_name ?? '',
        },
      });
    }

    // either close add benefits form in onboarding or close insurance modal in user profile
    onCancel();
    queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
  };

  const onSubmit = async (values: Values) => {
    if (!user) return;
    const newInsuranceValues = getInferredInsuranceFormValues(values, user);

    if (selectedInsurance) {
      const insurance = await dispatch(
        // eslint-disable-next-line import/no-deprecated
        updateInsurance({
          id: selectedInsurance.id,
          ...removeEmptyValues(newInsuranceValues),
        }),
      );
      addToast(<Toast>Your insurance has been updated!</Toast>);
      onFinish(insurance);
    } else {
      const insurance = await dispatch(
        // eslint-disable-next-line import/no-deprecated
        createInsurance({
          user_id: user.id,
          ...removeEmptyValues(newInsuranceValues),
        }),
      );
      addToast(<Toast>Your insurance has been saved!</Toast>);
      onFinish(insurance);
    }
  };

  return (
    <InsuranceForm
      insuranceError={insuranceError}
      loading={loading}
      onCancel={onCancel}
      onSubmit={onSubmit}
      selectedInsurance={selectedInsurance}
      showPhotoForm={handleShowPhotoForm}
    />
  );
};
