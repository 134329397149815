// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { selectAddress } from '~shared/actions/addresses';
import { initializeExistingOrder, setOrderAtIndex, toggleEditOrderWithAssistant } from '~shared/actions/cart';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantEditDeliveryAction,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getItemMedNames } from '~shared/features/checkout/helpers';
import { getExistingOrder } from '~shared/features/checkout/selectors/getOrder';
import { formatPaymentMethod } from '~shared/features/payment-methods/helpers';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { formatShortAddress } from '~shared/helpers/address';
import { formatRelativeDate, formatWindowLong } from '~shared/helpers/date';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { ellipsize } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getSelectedPaymentMethod } from '~shared/selectors/alto-assistant/getSelectedPaymentMethod';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Address } from '~shared/types/addresses';
import { AddressFormActionSheet } from '../../../../address';
import { useNavigateToAssistantLanding } from '../../../helpers/useNavigateToAssistantLanding';
import { FAQ_KEYS } from '../../faq/Constants';
import { MenuOption } from '../MenuOption';
import { WizardRemoveMedicationActionSheet } from './WizardRemoveMedicationActionSheet';

type Props = {
  readonly address?: Address;
  readonly date?: string;
  readonly deliverAfter?: string;
  readonly deliverBefore?: string;
  readonly items: ShipmentWithItemsAndAdditionalFields['items'];
  readonly shipmentID: number;
};

export const EditDeliveryActionSheet = ({
  address,
  date,
  deliverAfter,
  deliverBefore,
  items: shipmentItems,
  shipmentID,
}: Props) => {
  const dispatch = useDispatchShared();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { restoreStack, saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const existingOrder = useSelectorShared((state) =>
    getExistingOrder(state, {
      shipmentID,
    }),
  );

  const selectedPayment = useSelectorShared((state) => getSelectedPaymentMethod(state, shipmentID));
  const paymentMethod = selectedPayment.map((spm) => formatPaymentMethod(spm.paymentMethod)).join(' and ');

  const isMedSyncUser = useSelectorShared(getCurrentUser)?.is_med_sync ?? false;

  const trackActionChosen = (action: AssistantEditDeliveryAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        action,
        category: ASSISTANT_CATEGORIES.DELIVERIES,
        editable: true,
        name: ASSISTANT_PRESSED_EVENTS.EDIT_DELIVERY_ACTION_CHOSEN,
        shipmentID,
      },
    });
  };

  const navigateToEditOrder = () => {
    dispatch(setOrderAtIndex({ order: existingOrder, orderIndex: 0 }));
    dispatch(
      initializeExistingOrder({
        order: existingOrder,
        shipmentID,
      }),
    );
    dispatch(toggleEditOrderWithAssistant());
    // edit order is a little different on web, so we need to navigate to a different route for now until we can refactor
    if (getPlatformOS() === 'web') {
      navigate('RouteOrderDetails', { shipmentID });
    } else {
      navigate('RouteEditOrder');
    }
  };

  const navigateToMessageForm = () => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      category: 'deliveries.something_else',
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_EDIT_DELIVERY_ACTION_MENU,
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      subject: ASSISTANT_MESSAGE_SUBJECTS.UPCOMING_DELIVERY,
    });
  };

  const formattedDeliveryWindow = `${formatRelativeDate(date || '')}, ${formatWindowLong(deliverAfter, deliverBefore)}`;

  const items = [
    {
      key: 'order-date-or-time',
      component: (
        <MenuOption
          description={formattedDeliveryWindow}
          iconName="calendar-duo"
          onPress={() => {
            trackActionChosen('delivery_date_or_time');
            closeActionSheet();
            navigateToEditOrder();
          }}
          text="Order date or time"
        />
      ),
    },
    {
      key: 'payment-method',
      component: (
        <MenuOption
          description={paymentMethod}
          iconName="creditcard-duo"
          onPress={() => {
            trackActionChosen('payment');
            closeActionSheet();
            navigateToEditOrder();
          }}
          text="Payment method"
        />
      ),
    },
    {
      key: 'your-address',
      component: (
        <MenuOption
          description={formatShortAddress(address)}
          iconName="mapmarker-duo"
          onPress={() => {
            trackActionChosen('delivery_address');
            closeActionSheet();
            navigateToEditOrder();
          }}
          text="Your address"
        />
      ),
    },
    {
      key: '',
      component: (
        <MenuOption
          description={ellipsize(address?.delivery_instructions)}
          iconName="checklist-duo"
          onPress={() => {
            trackActionChosen('delivery_instructions');
            if (!address) {
              const context = {
                tags: { featureOwner: 'patients-team' },
                contexts: {
                  'Error Message Context': { error: `There is no address associated with shipment ${shipmentID}` },
                },
              };
              Sentry.captureMessage('Patient attempted to edit delivery instructions', context);
            } else {
              dispatch(selectAddress(address));
              setActiveActionSheet(<AddressFormActionSheet origin={'alto_assistant_edit_delivery_action_menu'} />);
            }
          }}
          text="Delivery instructions"
        />
      ),
    },
    {
      key: 'add-medication',
      component: (
        <MenuOption
          iconName="pluscircle-duo"
          onPress={() => {
            trackActionChosen('add_medication');
            saveStack<ActionSheetProviderStackCacheKey>('assistant-add-medication');
            closeActionSheet();
            navigate('RouteFaq', {
              faqKey: FAQ_KEYS.ADD_MEDICATION,
              goBack: () => {
                restoreStack<ActionSheetProviderStackCacheKey>('assistant-add-medication');
                navigateToAssistantLanding();
              },
              onDismiss: () => {
                navigate('RouteAppTabNavigator', { screen: 'RouteTabMyMeds' });
              },
              buttonLabelDone: 'Go to my med list',
            });
          }}
          text="Add medication"
        />
      ),
    },
    {
      key: 'remove-medication',
      component: (
        <MenuOption
          iconName="trash-duo"
          hide={isMedSyncUser}
          onPress={() => {
            trackActionChosen('remove_medication');
            setActiveActionSheet(<WizardRemoveMedicationActionSheet shipmentID={shipmentID} />);
          }}
          text="Remove medication"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            trackActionChosen('something_else');
            navigateToMessageForm();
          }}
          text="Something else"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="Edit upcoming order"
      description={getItemMedNames(shipmentItems) ?? ''}
      analyticsName="assistant edit delivery"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
