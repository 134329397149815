// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import pluralize from 'pluralize';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantUpcomingDeliveryAction,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getItemMedNames } from '~shared/features/checkout/helpers';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatDate } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useOpenTrackingLink } from '../../../../delivery-overview';
import { type AssistantMessageParams } from '../../../constants';
import { MenuOption } from '../MenuOption';
import { EditDeliveryActionSheet } from './EditDeliveryActionSheet';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
  readonly deliveredAt?: string;
};

export const UpcomingDeliveryActionSheet = ({ shipment, deliveredAt }: Props) => {
  const { trackEvent } = useAnalytics();
  const { navigate } = useNavigation();
  const { openTrackingLink } = useOpenTrackingLink();
  const { saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);

  const isEditable = isShipmentEditable(shipment);

  const trackUpcomingDeliveryActionChosen = (action: AssistantUpcomingDeliveryAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        action,
        category: 'deliveries',
        editable: isEditable,
        name: ASSISTANT_PRESSED_EVENTS.UPCOMING_DELIVERY_ACTION_CHOSEN,
        shipmentID: shipment.id,
      },
    });
  };

  const navigateToMessageForm = (params: Pick<AssistantMessageParams, 'category' | 'message' | 'subject'>) => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_DELIVERY_ACTION_MENU,
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      ...params,
    });
  };

  const items = [
    {
      key: 'track-order',
      component: (
        <MenuOption
          description={shipment.tracking_link}
          iconName="truck-duo"
          hide={!shipment.tracking_link}
          onPress={() => {
            trackUpcomingDeliveryActionChosen('track_my_delivery');
            openTrackingLink(shipment.tracking_link ?? '');
          }}
          text="Track my order"
        />
      ),
    },
    {
      key: 'cancel-order',
      component: (
        <MenuOption
          iconName="cancel-duo"
          onPress={() => {
            closeActionSheet();
            trackUpcomingDeliveryActionChosen('cancel_order');
            if (isEditable) {
              saveStack<ActionSheetProviderStackCacheKey>('assistant-cancel-order');
              navigate('RouteOrderDetails', { shipmentID: shipment.id });
            } else {
              const medicationNames =
                shipment.medication_names?.join(', ') ??
                `${shipment.items.length} ${pluralize('item', shipment.items.length)}`;
              navigateToMessageForm({
                subject: ASSISTANT_MESSAGE_SUBJECTS.CANCEL_DELIVERY,
                category: 'deliveries.cancel_order',
                message: `I need to cancel my upcoming order scheduled for ${formatDate(shipment.date)} containing ${medicationNames}.`,
              });
            }
          }}
          text="Cancel order"
        />
      ),
    },
    {
      key: 'edit-order',
      component: (
        <MenuOption
          iconName="edit-duo"
          onPress={() => {
            trackUpcomingDeliveryActionChosen('edit_delivery');
            setActiveActionSheet(
              <EditDeliveryActionSheet
                address={shipment.address}
                date={shipment.date}
                deliverAfter={shipment.deliver_after}
                deliverBefore={shipment.deliver_before}
                items={shipment.items}
                shipmentID={shipment.id}
              />,
            );
          }}
          text="Edit order"
        />
      ),
    },
    {
      key: 'pricing-question',
      component: (
        <MenuOption
          iconName="pricetag-duo"
          onPress={() => {
            saveStack<ActionSheetProviderStackCacheKey>('assistant-price-details');
            closeActionSheet();
            trackUpcomingDeliveryActionChosen('pricing_question');
            navigate('RouteUpcomingDeliveryPriceDetails', { shipmentID: shipment.id });
          }}
          text="Pricing question"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            closeActionSheet();
            trackUpcomingDeliveryActionChosen('something_else');
            navigateToMessageForm({
              subject: ASSISTANT_MESSAGE_SUBJECTS.UPCOMING_DELIVERY,
              category: 'deliveries.something_else',
            });
          }}
          text="Something else"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title="Upcoming order"
      description={deliveredAt || getItemMedNames(shipment.items) || ''}
      analyticsName="assistant upcoming delivery"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
