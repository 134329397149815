// @owners { team: patients-team }
import {
  ActionSheetContext,
  ActionSheetV2,
  InputRadio,
  InputRadioGroup,
  LgSpacing,
  ListDescription,
} from '@alto/design-system';
import { type SortType, SortTypeMap } from '@alto/scriptdash/alto/medications/patients/v1/types';
import React, { useCallback, useContext } from 'react';
import { Platform } from 'react-native';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { setSortType } from '~shared/actions/ui/medList';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const sortTypeContent: Record<
  SortType,
  { label: string; descriptions: React.ReactElement<typeof ListDescription>[] }
> = {
  ALPHABETICAL: {
    label: 'Sort alphabetically',
    descriptions: [<ListDescription key={SortTypeMap.ALPHABETICAL}>Ascending A to Z</ListDescription>],
  },
  PRESCRIBED_DATE: {
    label: 'Sort by prescribed date',
    descriptions: [<ListDescription key={SortTypeMap.PRESCRIBED_DATE}>Most recent to least recent</ListDescription>],
  },
  LAST_DISPENSED_DATE: {
    label: 'Sort by last filled date',
    descriptions: [
      <ListDescription key={SortTypeMap.LAST_DISPENSED_DATE}>Most recent to least recent</ListDescription>,
    ],
  },
};

const sortTypes = TypescriptUtils.objectKeys(sortTypeContent);

export const SortActionSheet = () => {
  const dispatch = useDispatchShared();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const sortType = useSelectorShared((state) => state.persistedUI.medList.sortType);
  const applySortMethod = useCallback(
    (value: string) => {
      dispatch(setSortType(value as SortType));
      closeActionSheet();
    },
    [closeActionSheet, dispatch],
  );

  return (
    <ActionSheetV2
      title="Sort medications"
      analyticsName="sort medications"
    >
      <InputRadioGroup
        onValueChange={applySortMethod}
        value={sortType}
      >
        {sortTypes.map((sortMethod) => (
          <InputRadio
            descriptions={sortTypeContent[sortMethod].descriptions}
            key={sortMethod}
            label={sortTypeContent[sortMethod].label}
            testID={`sort-${sortMethod}`}
            value={sortMethod}
          />
        ))}
      </InputRadioGroup>
      {Platform.OS === 'web' ? <LgSpacing /> : null}
    </ActionSheetV2>
  );
};
