// eslint-disable-next-line @alto/no-pocky-import
import { Loader, Row } from '@alto/pocky';
import * as React from 'react';
import styled from 'styled-components';
import CommsVerificationFailContainer from './CommsVerificationFailContainer';
import CommsVerificationSuccessContainer from './CommsVerificationSuccessContainer';
import { PageBackground } from '~web/components/Page';
import altoFullNeighborhood from '~web/images/altoFullNeighborhood@2x.png';

type Props = {
  readonly loading: boolean;
  readonly isVerified: boolean | null | undefined;
  readonly commsChannel: string;
  readonly token: string;
  readonly authorized: boolean;
};

const StylePageBackground = styled(PageBackground)`
  position: absolute;
  left: 0;
  width: 100%;
  max-width: none;
  background-color: #fff7f0;
  background-image: url('${altoFullNeighborhood}');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
`;

const Loading = () => (
  <Row
    parentWidth
    horizontallyAlignContent
  >
    <Loader text="Verifying..." />
  </Row>
);

const CommsVerificationStatus = ({ loading, isVerified, commsChannel, token, authorized }: Props) => {
  if (loading)
    return (
      <StylePageBackground>
        <Loading />
      </StylePageBackground>
    );

  if (isVerified) {
    return (
      <StylePageBackground>
        <CommsVerificationSuccessContainer
          commsChannel={commsChannel}
          authorized={authorized}
        />
      </StylePageBackground>
    );
  }

  return (
    <StylePageBackground>
      <CommsVerificationFailContainer
        commsChannel={commsChannel}
        token={token}
        authorized={authorized}
      />
    </StylePageBackground>
  );
};

export default CommsVerificationStatus;
