// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, ExternalLink, LgPadding, SmSpacing } from '@alto/design-system';
import React from 'react';

export const SharpsDisposalContent = () => (
  <>
    <Body>Alto is not an approved collection center and we cannot accept any used sharps containers.</Body>
    <SmSpacing />
    <Body>If your sharps container is full, there are several options for its safe disposal.</Body>
    <SmSpacing />
    <Body>
      Find your nearest approved collection center at{' '}
      <ExternalLink href="https://safeneedledisposal.org/sharps-management/management-and-disposal/">
        https://safeneedledisposal.org/sharps-management/management-and-disposal/
      </ExternalLink>
    </Body>
  </>
);

export const SharpsDisposalActionSheet = () => {
  return (
    <ActionSheetV2
      title="Sharps container disposal"
      analyticsName="assistant sharps disposal"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <SharpsDisposalContent />
      </LgPadding>
    </ActionSheetV2>
  );
};
