import {
  type AltoIconName,
  type BACKGROUND_COLORS_VALUES,
  type BORDER_COLORS_VALUES,
  SPACING,
} from '@alto/design-library-tokens';
import React from 'react';
import type { Alignment } from '../../../../utils';
import { Border } from '../../../borders';
import { MdPadding, Row, Touchable, XsPadding } from '../../../containers';
import { type AltoIcon } from '../../../icon';
import * as buttonUtils from '../../buttonUtils';
import { type PlatformSpecificButtonProps } from '../ButtonPlatformSpecificProps';
import { ButtonLabel, type ButtonLabelProps } from './ButtonLabel';
import { type ButtonSideCopy } from './ButtonSideCopy';

export type ButtonType = 'primary' | 'secondary' | 'tertiary';

export type ButtonVariant = 'danger' | 'normal';

export type ButtonWidth = 'full' | 'inline';

export type ButtonProps = PlatformSpecificButtonProps & {
  readonly accessibilityLabel?: string;
  /**
   * Use to add a label to the button. The children prop takes precedence over the label prop.
   *
   * Prefer using children over label. We intend to move existing code to the children prop in a future migration.
   *
   * @see label
   */
  readonly children?: string;
  readonly LeftContent?: React.ReactElement<typeof ButtonSideCopy | typeof AltoIcon>;
  readonly RightContent?: React.ReactElement<typeof ButtonSideCopy | typeof AltoIcon>;
  /*
    @deprecated use LeftContent instead
  */
  readonly iconLeftName?: AltoIconName;
  /*
    @deprecated use RightContent instead
  */
  readonly iconRightName?: AltoIconName;
  /**
   * Use to add a label to the button. The children prop takes precedence over the label prop.
   *
   * Prefer using children over label. We intend to move existing code to the children prop in a future migration.
   *
   * @see children
   */
  readonly label?: string;
  readonly type?: ButtonType;
  readonly variant?: ButtonVariant;
  readonly width?: ButtonWidth;
  readonly small?: boolean;
  readonly loading?: boolean;
  readonly loadingLabel?: string;
  readonly disabled?: boolean;
  readonly noPadding?: boolean;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
  readonly alignment?: Alignment;
  readonly spaceBetween?: boolean;
};

type TouchableButtonProps = PlatformSpecificButtonProps & {
  readonly accessibilityLabel?: string;
  readonly backgroundColor: string;
  readonly borderColor: BORDER_COLORS_VALUES | BACKGROUND_COLORS_VALUES;
  readonly buttonLabelProps: ButtonLabelProps;
  readonly fullWidth?: boolean;
  readonly small?: boolean;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly noPadding?: boolean;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
  readonly alignment?: Alignment;
};

type WrappedButtonLabelProps = {
  readonly noPadding?: boolean;
  readonly small?: boolean;
  readonly buttonLabelProps: ButtonLabelProps;
};

const WrappedButtonLabel = ({ noPadding, small, buttonLabelProps }: WrappedButtonLabelProps) => {
  if (noPadding) {
    return <ButtonLabel {...buttonLabelProps} />;
  }

  if (small) {
    return (
      <XsPadding
        leftPadding={SPACING.STATIC.MD}
        rightPadding={SPACING.STATIC.MD}
      >
        <ButtonLabel {...buttonLabelProps} />
      </XsPadding>
    );
  }

  return (
    <MdPadding
      leftPadding={SPACING.STATIC.LG}
      rightPadding={SPACING.STATIC.LG}
    >
      <ButtonLabel {...buttonLabelProps} />
    </MdPadding>
  );
};

/**
 * The main `Touchable` Button
 * @param {TouchableButtonProps} props component props
 * @returns TouchableButton
 */
const TouchableButton = ({
  accessibilityLabel,
  backgroundColor,
  borderColor,
  disabled,
  buttonLabelProps,
  fullWidth,
  onPress,
  noPadding,
  small,
  loading,
  testID,
  alignment,
}: TouchableButtonProps) => {
  return (
    <Border
      radius="XXL"
      color={borderColor}
      fullWidth={fullWidth}
    >
      <Touchable
        accessibilityLabel={accessibilityLabel}
        alignment={alignment}
        onPress={onPress}
        centerVertically
        centerHorizontally={!buttonLabelProps.spaceBetween}
        disabled={loading || disabled}
        backgroundColor={backgroundColor}
        testID={testID}
      >
        <WrappedButtonLabel
          small={small}
          noPadding={noPadding}
          buttonLabelProps={buttonLabelProps}
        />
      </Touchable>
    </Border>
  );
};

export const Button = ({
  accessibilityLabel,
  children,
  label,
  type = 'primary',
  variant = 'normal',
  width,
  small = false,
  alignment,
  onPress,
  LeftContent,
  RightContent,
  iconLeftName,
  iconRightName,
  loading = false,
  loadingLabel,
  disabled,
  noPadding,
  testID,
  spaceBetween = false,
}: ButtonProps) => {
  // colors
  const borderColor = buttonUtils.getBorderColor({
    type,
    variant,
    disabled,
  });
  const labelColor = buttonUtils.getLabelColor({
    type,
    variant,
    disabled,
  });
  const backgroundColor = buttonUtils.getBackgroundColor({
    type,
    variant,
    disabled,
  });

  // component props
  const buttonLabelProps = {
    LeftContent,
    RightContent,
    iconLeftName,
    iconRightName,
    label: children || label || '',
    labelColor,
    small,
    loading,
    loadingLabel,
    spaceBetween,
    width,
  };
  const buttonProps = {
    accessibilityLabel,
    backgroundColor,
    borderColor,
    buttonLabelProps,
    fullWidth: width === 'full',
    onPress,
    testID,
    small,
    loading,
    alignment,
    disabled,
    noPadding,
  };

  return width === 'inline' ? (
    <Row testID="inline-wrapper">
      <TouchableButton {...buttonProps} />
    </Row>
  ) : (
    <TouchableButton {...buttonProps} />
  );
};
