// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';

export const getAutoRefillNamesForShipment = createSelector(getPrescriptionsForShipment, (prescriptions) =>
  prescriptions.reduce<string[]>((names, { auto_refill, medication_name }) => {
    if (auto_refill && medication_name) {
      names.push(medication_name);
    }

    return names;
  }, []),
);
