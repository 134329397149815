import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import React, { PureComponent } from 'react';
import PrescriptionItem from './PrescriptionItemContainer';

export type Props = {
  readonly cartItem: CartItem;
};

export default class Item extends PureComponent<Props> {
  render() {
    const { cartItem } = this.props;
    const { resource_type } = cartItem;

    switch (resource_type) {
      case 'Prescription':
        return <PrescriptionItem {...this.props} />;

      default:
        // TODO raise an exception here?
        return null;
    }
  }
}
