import { BASE_FONT_SIZE, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { TextInput, View } from 'react-native';
import styled from 'styled-components/native';

export const INPUT_BASE_LINE_HEIGHT = Object.freeze({
  value: 20,
  px: '20px',
});

export const InputBase = styled(TextInput).attrs({
  placeholderTextColor: COLORS.TEXT_COLORS.DISABLED,
})`
  font-size: ${BASE_FONT_SIZE}px;
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.SM.px};
  line-height: ${INPUT_BASE_LINE_HEIGHT.px};
  color: ${COLORS.TEXT_COLORS.BLACK};
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-weight: normal;
  padding: ${SPACING.STATIC.NONE.px};
`;

export const InputWrapper = styled(View)`
  flex: 1;
  padding-top: 15.5px;
  padding-bottom: 16.5px;
`;
