// @owners { team: patients-team }
import { isAfter, isToday, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { formatDateWithDowWithoutYear } from '~shared/helpers/date';
import { getDoctorName } from '~shared/helpers/doctorName';
import { getErrorMessage } from '~shared/helpers/helper';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';

type Station = {
  id: 'request' | 'waiting' | 'resolved';
  title: string;
  description: string;
};

export type RenewalStatusTimelineConfig = {
  error?: string;
  loading: boolean;
  activeStation?: 'waiting' | 'request';
  stations: Record<string, Station>;
};

export const useRenewalStatusTimeline = (prescription: Prescription): RenewalStatusTimelineConfig => {
  const fetchRenewalRequestTimelineError = useSelectorShared(
    (state) => state.ui.errors.fetchRenewalRequestTimelineError,
  );
  const fetchRenewalRequestTimelineLoading = useSelectorShared(
    (state) => state.ui.loading.fetchRenewalRequestTimelineLoading,
  );

  const config: RenewalStatusTimelineConfig = useMemo(
    () => ({
      error: getErrorMessage(fetchRenewalRequestTimelineError),
      loading: fetchRenewalRequestTimelineLoading,
      stations: {
        request: { id: 'request' } as Station,
        waiting: { id: 'waiting' } as Station,
        resolved: {
          id: 'resolved',
          title: 'Resolved',
          description: "We'll notify you when we get a response.",
        },
      },
    }),
    [fetchRenewalRequestTimelineError, fetchRenewalRequestTimelineLoading],
  );

  const timeline = useSelectorShared((state) => {
    return state.prescriptions.renewalRequestTimelines?.[prescription.id];
  });

  if (timeline?.length) {
    const renewalStartDateTime = parseISO(timeline[0].date);
    const doctorName = getDoctorName(prescription.doctor);
    const renewalHasStarted = isToday(renewalStartDateTime) || isAfter(renewalStartDateTime, new Date());
    const formattedDate = formatDateWithDowWithoutYear(renewalStartDateTime);

    if (timeline[0].failed) {
      config.stations.request.title = 'Request failed';
      config.stations.request.description =
        'We tried sending a request but it did not go through. We will call your doctor to request this for you.';
      config.stations.waiting.title = 'Wait for response';
      config.stations.waiting.description = 'If we do not get ahold of them, we will check back in a couple days.';
    } else if (renewalHasStarted) {
      config.activeStation = 'waiting';
      config.stations.request.title = 'Refills requested';
      config.stations.request.description = `On ${formattedDate}, we asked ${doctorName} for more refills of this medication.`;
      config.stations.waiting.title = 'Waiting on your provider';
      config.stations.waiting.description = 'We are waiting to hear back and will check again soon.';
    } else {
      config.activeStation = 'request';
      config.stations.request.title = 'Upcoming refill request';
      config.stations.request.description = `We're set to request refills from ${doctorName} on ${formattedDate}, closer to the next fill date.`;
      config.stations.waiting.title = 'Wait for response';
      config.stations.waiting.description = `We will follow up with ${doctorName} if we do not hear back.`;
    }
  }

  return config;
};
