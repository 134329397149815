// @owners { team: patients-team }
import React from 'react';
import { type Option } from '~shared/features/onboarding/selectors/getOnboardingVerification';
import { type APIError } from '~shared/types';
import VerificationBlockedModal from '~web/features/onboarding/components/VerificationBlockedModal';
import VerificationOptionsModal, { type Values } from '~web/features/onboarding/components/VerificationOptionsModal';
import VerificationSingleOptionModal from '~web/features/onboarding/components/VerificationSingleOptionModal';

type Props = {
  readonly error: APIError | null | undefined;
  readonly loading: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (arg0: Values) => void;
  readonly options: Option[];
  readonly resend?: boolean;
  readonly uuid: string | null | undefined;
};

export default function VerificationModal(props: Props) {
  const { options, ...rest } = props;

  if (options.length === 0) {
    return <VerificationBlockedModal {...rest} />;
  }

  if (options.length === 1) {
    const option = options[0];
    return (
      <VerificationSingleOptionModal
        {...rest}
        option={option}
      />
    );
  }

  return (
    <VerificationOptionsModal
      {...rest}
      options={options}
    />
  );
}
