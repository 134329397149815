// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { SecondaryPage } from '@alto/design-system';
import { PageErrorFallback } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import React from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses } from '~shared/actions/addresses';
import { initializeExistingOrder, setOrderAtIndex, updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
import { openModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { fetchPaymentMethods } from '~shared/actions/paymentMethods';
// eslint-disable-next-line import/no-deprecated
import { fetchPrescriptions } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPricing } from '~shared/actions/pricing';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPaymentMethods } from '~shared/actions/shipmentPaymentMethods';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { closeCart } from '~shared/actions/ui/cart';
import { getExistingOrder } from '~shared/features/checkout/selectors/getOrder';
import { type Order } from '~shared/features/checkout/types';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentTipsForUser } from '~shared/features/courier-tips/actions';
import { getShipmentTipForShipmentID } from '~shared/features/courier-tips/selectors';
import { getShipmentPaymentMethodsWithDetails } from '~shared/features/payments/selectors/getShipmentPaymentMethods';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { type OrderPricing } from '~shared/features/pricing/types';
import { getIsFirstSelfScheduledShipment } from '~shared/features/shipments/selectors/getIsFirstSelfScheduledShipment';
import { getShipmentWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { OrderDetails } from './OrderDetails';

type OwnProps = {
  params: {
    orderID: number;
  };
};

export type StateProps = {
  error: APIError | null | undefined;
  existingOrder: Order;
  isFirstSelfScheduledShipment: boolean;
  previousRoute: string | null | undefined;
  shipment?: ShipmentWithItems;
  shipmentPaymentMethods: PaymentMethodWithAmount[];
  shipmentTip: ShipmentTip | null | undefined;
  userID: number | null | undefined;
  uuid: string;
  shipmentID: number;
  shipmentPricing: OrderPricing;
  priceLoading: boolean;
};

export type DispatchProps = {
  closeCart: typeof closeCart;
  // eslint-disable-next-line import/no-deprecated
  fetchAddresses: typeof fetchAddresses;
  // eslint-disable-next-line import/no-deprecated
  fetchDeliveries: typeof fetchDeliveries;
  // eslint-disable-next-line import/no-deprecated
  fetchPaymentMethods: typeof fetchPaymentMethods;
  // eslint-disable-next-line import/no-deprecated
  fetchPrescriptions: typeof fetchPrescriptions;
  // eslint-disable-next-line import/no-deprecated
  fetchShipmentPaymentMethods: typeof fetchShipmentPaymentMethods;
  // eslint-disable-next-line import/no-deprecated
  fetchShipments: typeof fetchShipments;
  // eslint-disable-next-line import/no-deprecated
  fetchShipmentTipsForUser: typeof fetchShipmentTipsForUser;
  // eslint-disable-next-line import/no-deprecated
  fetchShipmentPricing: typeof fetchShipmentPricing;
  initializeExistingOrder: typeof initializeExistingOrder;
  openModal: typeof openModal;
  push: typeof push;
  setOrderAtIndex: typeof setOrderAtIndex;
  updateOrder: typeof updateOrder;
};

type MergedStateProps = {
  readonly error: StateProps['error'];
  readonly shipment?: StateProps['shipment'];
  readonly shipmentPaymentMethods: StateProps['shipmentPaymentMethods'];
  readonly shipmentTip: StateProps['shipmentTip'];
  readonly shipmentPricing: StateProps['shipmentPricing'];
  readonly priceLoading: StateProps['priceLoading'];
};

type MergedDispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  readonly fetchAddresses: DispatchProps['fetchAddresses'];
  // eslint-disable-next-line import/no-deprecated
  readonly fetchDeliveries: DispatchProps['fetchDeliveries'];
  // eslint-disable-next-line import/no-deprecated
  readonly fetchPaymentMethods: DispatchProps['fetchPaymentMethods'];
  // eslint-disable-next-line import/no-deprecated
  readonly fetchPrescriptions: DispatchProps['fetchPrescriptions'];
  // eslint-disable-next-line import/no-deprecated
  readonly fetchShipmentPaymentMethods: DispatchProps['fetchShipmentPaymentMethods'];
  // eslint-disable-next-line import/no-deprecated
  readonly fetchShipments: DispatchProps['fetchShipments'];
  // eslint-disable-next-line import/no-deprecated
  readonly fetchShipmentPricing: DispatchProps['fetchShipmentPricing'];
};

export type MergedProps = {
  readonly editShipment: () => void;
  readonly fetchShipmentTips: () => void;
  readonly openCancelDeliveryPage: () => void;
  readonly openEditOrAddTipModal: () => void;
};

type Props = MergedStateProps & MergedDispatchProps & MergedProps & { goBack: () => void };
class OrderDetailsContainer extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.fetchAddresses();
    this.props.fetchDeliveries();
    this.props.fetchPaymentMethods();
    this.props.fetchPrescriptions();
    this.props.fetchShipments();
    this.props.fetchShipmentPaymentMethods();
    this.props.fetchShipmentTips();

    if (this.props.shipment) {
      // @ts-expect-error fetchShipmentPricing passed shipmentID in mergeProps
      this.props.fetchShipmentPricing();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.error && prevProps.error !== this.props.error) {
      toaster.toast({
        kind: 'danger',
        // TODO: Make this more helpful by mapping internal server errors to
        // patient-friendly messages
        title: 'Whoops!',
        description: this.props.error.details.message,
      });
    }
  }

  render() {
    const {
      // State Props
      shipment,
      shipmentPaymentMethods,
      shipmentTip,
      shipmentPricing,
      priceLoading,
      // Merged Props
      editShipment,
      openCancelDeliveryPage,
      openEditOrAddTipModal,
    } = this.props;

    if (!shipment) {
      return (
        <SecondaryPage
          headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
          dismissIcon="chevronleft"
          onDismiss={this.props.goBack}
        >
          <PageErrorFallback />
        </SecondaryPage>
      );
    }

    return (
      <OrderDetails
        shipment={shipment}
        shipmentPaymentMethods={shipmentPaymentMethods}
        shipmentTip={shipmentTip}
        shipmentPricing={shipmentPricing}
        priceLoading={priceLoading}
        editShipment={editShipment}
        openCancelDeliveryPage={openCancelDeliveryPage}
        openEditOrAddTipModal={openEditOrAddTipModal}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStateShared, ownProps: OwnProps): StateProps => {
  const {
    routing: { locationBeforeTransitions },
  } = state;
  const shipmentID = ownProps.params.orderID;
  const existingOrder = getExistingOrder(state, {
    shipmentID,
  });
  const isFirstSelfScheduledShipment = getIsFirstSelfScheduledShipment(state, {
    id: shipmentID,
  });
  const previousRoute = locationBeforeTransitions?.state?.previousRoute || null;
  const user = getCurrentUser(state);
  const shipmentTip = getShipmentTipForShipmentID(state, {
    shipmentID,
  });
  const { fetchShipmentTipsError } = getErrors(state);
  const { fetchOrderPricingLoading: priceLoading } = getLoading(state);

  const shipment = getShipmentWithItemsByID(state, {
    id: shipmentID,
  });

  return {
    error: fetchShipmentTipsError,
    existingOrder,
    isFirstSelfScheduledShipment,
    previousRoute,
    shipment,
    shipmentPaymentMethods: getShipmentPaymentMethodsWithDetails(state, {
      shipmentID,
    }),
    shipmentTip,
    userID: user?.id,
    uuid: user?.uuid || '',
    shipmentID,
    shipmentPricing: getShipmentPricing(state),
    priceLoading,
  };
};

const mapDispatchToProps: DispatchProps = {
  closeCart,
  // eslint-disable-next-line import/no-deprecated
  fetchAddresses,
  // eslint-disable-next-line import/no-deprecated
  fetchDeliveries,
  // eslint-disable-next-line import/no-deprecated
  fetchPaymentMethods,
  // eslint-disable-next-line import/no-deprecated
  fetchPrescriptions,
  // eslint-disable-next-line import/no-deprecated
  fetchShipmentPaymentMethods,
  // eslint-disable-next-line import/no-deprecated
  fetchShipments,
  // eslint-disable-next-line import/no-deprecated
  fetchShipmentTipsForUser,
  // eslint-disable-next-line import/no-deprecated
  fetchShipmentPricing,
  initializeExistingOrder,
  openModal,
  push,
  setOrderAtIndex,
  updateOrder,
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): Props => {
  const { existingOrder: editOrder, userID, shipmentID, ...newStateProps } = stateProps;
  const {
    closeCart: closeCartModal,
    fetchShipmentTipsForUser,
    initializeExistingOrder: initializeEditOrder,
    openModal,
    push: routerPush,
    updateOrder,
    fetchShipmentPricing,
    setOrderAtIndex,
    ...newDispatchProps
  } = dispatchProps;

  return {
    ...newStateProps,
    ...newDispatchProps,
    editShipment: () => {
      setOrderAtIndex({ order: editOrder, orderIndex: 0 });
      initializeEditOrder({
        order: editOrder,
        shipmentID: stateProps.shipment?.id ?? 0,
      });
      updateOrder({
        origin: 'Existing Order',
      });
      closeCartModal();
      routerPush('/checkout');
    },
    fetchShipmentTips: () => {
      if (userID) {
        fetchShipmentTipsForUser(userID);
      }
    },
    goBack: () => {
      routerPush('/orders');
    },
    openCancelDeliveryPage: () => {
      dispatchProps.push(`/orders/cancel?shipmentID=${shipmentID}`);
    },
    openEditOrAddTipModal: () => {
      openModal('EDIT_OR_ADD_TIP_MODAL');
    },
    fetchShipmentPricing: () => {
      return fetchShipmentPricing(shipmentID);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OrderDetailsContainer);
