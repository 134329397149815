// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModal } from '@alto/pocky';
import React, { Component } from 'react';
import { type ModalType } from '~shared/actions/modal';
import CommunicationPreferencesForm from './CommunicationPreferencesFormContainer';

type Props = {
  readonly closeModal: () => void;
  readonly modalType: ModalType | null;
  readonly show?: boolean;
};

export default class CommunicationPreferencesModal extends Component<Props> {
  render() {
    const { closeModal, modalType, show } = this.props;
    const showing = show && modalType === 'COMMUNICATION_PREFERENCES_MODAL';
    return (
      showing && (
        <DeprecatedModal
          showing={showing}
          onClose={closeModal}
          scrollable
          title="Communication Preferences"
        >
          <CommunicationPreferencesForm />
        </DeprecatedModal>
      )
    );
  }
}
