import { createSelector } from 'reselect';
import getModal from './getModal';
import { type ReduxStateShared } from '~shared/types';

const getIsWelcomeBackDisplayed: (state: ReduxStateShared) => boolean = createSelector(
  getModal,
  (modal) => modal.isWelcomeBackDisplayed,
);

export default getIsWelcomeBackDisplayed;
