// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getPrescriptionPricing } from './getPricing';
import { PRICING_SCENARIOS } from '~shared/features/pricing/constants';

export const getPrescriptionIDsWithCashLessThanInsurancePricingScenarios = createSelector(
  [getPrescriptionPricing],
  (prescriptionPricing) => {
    return Object.values(prescriptionPricing)
      .filter((pricing) => pricing.scenario === PRICING_SCENARIOS.CASH_LESS_THAN_INSURANCE)
      .map((pricing) => pricing.prescription_id);
  },
);
