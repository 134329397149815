import { get } from '~shared/helpers/apiHelper';
import { type ReduxDispatch } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { type Facility } from '~shared/types/facility';

export type FetchFacilitiesLoading = {
  type: typeof FETCH_FACILITIES_LOADING;
};

export type FetchFacilitiesSucceeded = {
  type: typeof FETCH_FACILITIES_SUCCEEDED;
  payload: Facility[];
};

export type FetchFacilitiesFailed = {
  type: typeof FETCH_FACILITIES_FAILED;
  payload: APIError;
  error: true;
};

export type FacilitiesActions = FetchFacilitiesLoading | FetchFacilitiesSucceeded | FetchFacilitiesFailed;

export const FETCH_FACILITIES_LOADING = 'FETCH_FACILITIES_LOADING';

export function fetchingFacilities(): FetchFacilitiesLoading {
  return {
    type: FETCH_FACILITIES_LOADING,
  };
}

export const FETCH_FACILITIES_SUCCEEDED = 'FETCH_FACILITIES_SUCCEEDED';

export function fetchFacilitiesSucceeded(facilities: Facility[]): FetchFacilitiesSucceeded {
  return {
    type: FETCH_FACILITIES_SUCCEEDED,
    payload: facilities,
  };
}

export const FETCH_FACILITIES_FAILED = 'FETCH_FACILITIES_FAILED';

export function fetchFacilitiesFailed(error: APIError): FetchFacilitiesFailed {
  return {
    type: FETCH_FACILITIES_FAILED,
    payload: error,
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchFacilities() {
  return (dispatch: ReduxDispatch) => {
    dispatch(fetchingFacilities());
    return get('/facilities').then((json) => {
      if (json.error) {
        dispatch(fetchFacilitiesFailed(json.error));
        return false;
      }

      dispatch(fetchFacilitiesSucceeded(json));
      return true;
    });
  };
}
