// @owners { team: patients-team }
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setEssentialsSearchTerm } from '~shared/features/essentials/actions';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const fuseOptions = {
  keys: [
    { name: 'name', weight: 1000 },
    { name: 'keywords', weight: 10 },
    { name: 'description', weight: 1 },
  ],
  threshold: 0.4,
};

type Props = {
  essentials?: AddOnOtc[];
};

export const useEssentialsSearch = ({ essentials = [] }: Props) => {
  const title = 'No Results';
  const description = "Can't find what you are looking for?";
  const searchTerm = useSelectorShared((state) => state.essentials.searchTerm);
  const dispatch = useDispatchShared();
  const [searching, setSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<AddOnOtc[]>([]);
  const fuse = useMemo(() => new Fuse(essentials, fuseOptions), [essentials]);

  const handleSearch = useCallback(
    (term: string) => {
      const searchTerm = term.trim();
      const filteredOtcs = fuse.search(searchTerm).map(({ item }: Fuse.FuseResult<AddOnOtc>) => item);
      setSearchResults(filteredOtcs);
      setSearching(false);
    },
    [fuse],
  );

  const debounceSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  const onSearchUpdate = useCallback(
    (newSearchTerm: string) => {
      setSearching(true);
      debounceSearch(newSearchTerm);
      dispatch(setEssentialsSearchTerm(newSearchTerm));
    },
    [dispatch, debounceSearch],
  );

  const onSearchClear = useCallback(() => {
    dispatch(setEssentialsSearchTerm(''));
  }, [dispatch]);

  return useMemo(
    () => ({
      title,
      description,
      searching,
      searchTerm,
      searchResults,
      onSearchUpdate,
      onSearchClear,
    }),
    [onSearchClear, onSearchUpdate, searchResults, searchTerm, searching],
  );
};
