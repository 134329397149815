import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components/native';
import { Text, type TextProps } from './Text';

export type CaptionProps = Pick<TextProps, 'center' | 'children' | 'color' | 'fontFamily'>;

export const StyledCaption = styled(Text)<CaptionProps>`
  color: ${({ color }) => color ?? COLORS.TEXT_COLORS.GREY};
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  line-height: ${TYPOGRAPHY.TEXT.DETAIL.LG.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
`;

export const Caption = ({ center, color, fontFamily, children }: CaptionProps) => {
  return (
    <StyledCaption
      accessibilityRole="text"
      center={center}
      color={color}
      fontFamily={fontFamily}
    >
      {children}
    </StyledCaption>
  );
};
