import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Row, useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, DeprecatedModalBody, DeprecatedModalFooter, H1, Modal, Text, spacing } from '@alto/pocky';
import React, { useCallback, useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { closeModal } from '~shared/actions/modal';
import { viewNotice } from '~shared/actions/notices';
import { MAX_NUM_ITEMS_TO_SHOW_ON_CROSS_SELL } from '~shared/features/checkout/constants';
import { getCrossSellHeader, getCrossSellSubHeader } from '~shared/features/checkout/helpers';
import { useCrossSellPrescriptions } from '~shared/features/checkout/hooks/useCrossSellPrescriptions';
import { REFILL_CROSS_SELL_NOTICE } from '~shared/features/notices/selectors/getNotices';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import PrescriptionCardCarousel from './PrescriptionCardCarousel';

const TitleText = styled(H1)<{ isSMScreenOrBigger: boolean }>`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  margin-top: ${spacing.xlSpacing};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-top: ${SPACING.STATIC.MD.rem}; font-size: 20px;`}
`;

const PaddedText = styled(Text).attrs({
  center: true,
})<{ isSMScreenOrBigger: boolean }>`
  padding: 0px ${spacing.xlSpacing} ${SPACING.STATIC.LG.rem} ${spacing.xlSpacing};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `padding: 0px 0px ${SPACING.STATIC.MD.rem} 0px;`}
`;

export const CrossSellModal = () => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { crossSellPrescriptions } = useCrossSellPrescriptions();
  const prescriptions = crossSellPrescriptions.slice(0, MAX_NUM_ITEMS_TO_SHOW_ON_CROSS_SELL);
  const numberOfItemsShown = prescriptions.length;
  const [numItemsAddedFromCrossSell, setNumItemsAddedFromCrossSell] = useState(0);
  const { isSMScreenOrBigger } = useScreenSize();

  const addNumItemsAddedFromCrossSell = useCallback(() => {
    setNumItemsAddedFromCrossSell(numItemsAddedFromCrossSell + 1);
  }, [numItemsAddedFromCrossSell]);

  useEffect(() => {
    dispatch(viewNotice(REFILL_CROSS_SELL_NOTICE));
    trackEvent({
      event: EVENTS.REFILL_CROSS_SELL_VIEWED,
      params: { numberOfItemsShown },
    });
  }, [dispatch, numberOfItemsShown, trackEvent]);

  const handleModalClose = useCallback(() => {
    trackEvent({
      event: EVENTS.REFILL_CROSS_SELL_ITEMS_ADDED,
      params: {
        numberOfItemsShown,
        numberOfItemsAdded: numItemsAddedFromCrossSell,
      },
    });
    dispatch(closeModal('CROSS_SELL_MODAL'));
    dispatch(push('/checkout'));
  }, [dispatch, numberOfItemsShown, numItemsAddedFromCrossSell, trackEvent]);

  return (
    <Modal
      onClose={handleModalClose}
      showDismiss
      showing
    >
      <DeprecatedModalBody>
        <TitleText isSMScreenOrBigger={isSMScreenOrBigger}>{getCrossSellHeader(numberOfItemsShown)}</TitleText>
        <PaddedText isSMScreenOrBigger={isSMScreenOrBigger}>{getCrossSellSubHeader(numberOfItemsShown)}</PaddedText>
        <Row centerHorizontally>
          <PrescriptionCardCarousel
            prescriptions={prescriptions}
            addNumItemsAddedFromCrossSell={addNumItemsAddedFromCrossSell}
          />
        </Row>
      </DeprecatedModalBody>
      <DeprecatedModalFooter>
        {numItemsAddedFromCrossSell > 0 ? (
          <Button
            kind="primary"
            onClick={handleModalClose}
          >
            Schedule Delivery
          </Button>
        ) : (
          <Button
            kind="secondary"
            onClick={handleModalClose}
          >
            No Thanks
          </Button>
        )}
      </DeprecatedModalFooter>
    </Modal>
  );
};
