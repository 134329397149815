// @owners { team: patients-team }
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import {
  DEFAULT_TIP_AMOUNT,
  HOURS_TO_CREATE_OR_UPDATE_TIPS_AFTER_DELIVERY,
  MAX_TIP_AMOUNT,
  MIN_RATING_TO_TIP,
  MIN_TIP_AMOUNT,
  TIP_AMOUNT_ERRORS,
} from './constants';
import { PAYMENT_METHOD_TYPES, type PaymentMethodType } from '~shared/constants';
import { type Order } from '~shared/features/checkout/types';
import { IN_PROGRESS_STATUSES } from '~shared/features/shipments/constants';
import { numberOfHoursAgo } from '~shared/helpers/date';
import { type Shipment } from '~shared/types';

export const canEditCourierTip = (
  shipment: Shipment | null | undefined,
  shipmentTip: ShipmentTip | null | undefined,
): boolean => {
  if (shipmentTip) {
    return shipmentTip.updatable;
  }

  if (shipment?.status === 'delivered' && shipment?.delivered_at) {
    const hoursSinceDelivery = numberOfHoursAgo(shipment.delivered_at);
    return hoursSinceDelivery < HOURS_TO_CREATE_OR_UPDATE_TIPS_AFTER_DELIVERY;
  }

  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (IN_PROGRESS_STATUSES.includes(shipment?.status)) {
    return true;
  }

  return false;
};

export const canSubmitCourierTip = (tipAmount: number, tipPaymentMethodID: number | null | undefined): boolean =>
  (!!tipAmount || tipAmount === 0) && !!tipPaymentMethodID;

export const findShipmentTipForShipment = (
  shipmentTips: ShipmentTip[] = [],
  shipmentID: (string | number) | null | undefined,
): ShipmentTip | null | undefined => {
  return shipmentTips.find((shipmentTip) => String(shipmentTip.shipment_id) === String(shipmentID));
};

export const getTipAmountValidationError = (amount: number): string | null | undefined => {
  if (amount > MAX_TIP_AMOUNT) {
    return TIP_AMOUNT_ERRORS.OVER_MAX;
  }

  if (amount < MIN_TIP_AMOUNT && amount !== 0) {
    return TIP_AMOUNT_ERRORS.UNDER_MIN;
  }

  return null;
};

export const getTipAmountWithDefault = (
  tipAmount: number | null | undefined,
  defaultAmount: number = DEFAULT_TIP_AMOUNT,
): number => {
  if (typeof tipAmount === 'number') {
    return tipAmount;
  }

  return defaultAmount;
};

export const getTipAmount = (order: Order, shipmentTip: ShipmentTip | null | undefined): number | null | undefined =>
  order.tip_amount ?? shipmentTip?.amount;

export const getTipPaymentMethodID = (
  order: Order,
  shipmentTip: ShipmentTip | null | undefined,
): number | null | undefined => order.tip_payment_method_id ?? shipmentTip?.payment_method_id;

export const canTipAfterRating = ({
  isTippable,
  rating,
  shipment,
  shipmentTip,
}: {
  isTippable: boolean;
  rating: number;
  shipment: Shipment | null | undefined;
  shipmentTip: ShipmentTip | null | undefined;
}): boolean => // Move on to asking the user for a tip if
  // 1. The shipment is eligible for tips
  // 2. The user has no tip or a tip of $0
  // 3. The user left a high enough rating
  // 4. The shipment can receive a tip
  isTippable && !shipmentTip?.amount && rating >= MIN_RATING_TO_TIP && canEditCourierTip(shipment, shipmentTip);

type GetSelectedPaymentMethodProps = {
  order: Order;
  paymentMethodType?: PaymentMethodType;
  shipmentTip?: ShipmentTip | null;
  postTipPaymentMethodID?: number | null;
  isSecondaryPaymentMethod?: boolean;
};

export const getSelectedPaymentMethodId = ({
  order,
  paymentMethodType,
  shipmentTip,
  postTipPaymentMethodID,
  isSecondaryPaymentMethod,
}: GetSelectedPaymentMethodProps): number | null | undefined => {
  switch (paymentMethodType) {
    case PAYMENT_METHOD_TYPES.PRE_TIP:
      return getTipPaymentMethodID(order, shipmentTip);
    case PAYMENT_METHOD_TYPES.POST_TIP:
      return postTipPaymentMethodID ?? shipmentTip?.payment_method_id;
    default:
      return isSecondaryPaymentMethod ? order.second_payment_method_id : order.payment_method_id;
  }
};

export const getPrefixCCKey = (key: string | number): string => `profile-cc-${key}`;

type GetDisabledPaymentMethodID = {
  order?: Order;
  isSecondaryPaymentMethod?: boolean;
  isSplitPaymentEnabled?: boolean;
};

export const getDisabledPaymentMethodID = ({
  order,
  isSecondaryPaymentMethod,
  isSplitPaymentEnabled,
}: GetDisabledPaymentMethodID): number | null | undefined => {
  let disabledPaymentMethodID = null;
  if (order && isSplitPaymentEnabled) {
    disabledPaymentMethodID = isSecondaryPaymentMethod ? order.payment_method_id : order.second_payment_method_id;
  }
  return disabledPaymentMethodID;
};
