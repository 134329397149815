import { type SmallIconName } from '@alto/design-library-tokens';
import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { AltoIcon } from '../../../icon';

const InputIconWrapper = styled(View)`
  padding-top: 17.5px;
  padding-bottom: 18.5px;
`;

export const InputIcon = ({ name }: { readonly name: SmallIconName }) => {
  return (
    <InputIconWrapper>
      <AltoIcon
        name={name}
        type="grey"
      />
    </InputIconWrapper>
  );
};
