// @owners { team: patients-team }
import { format, parse } from 'date-fns';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { validateUser } from '~shared/actions/auth';
import { setInsuranceLoadingSeen } from '~shared/actions/onboarding';
import {
  ONBOARD_STATE_MED_CONFIRMATION,
  ONBOARD_STATE_TO_ROUTE,
  type Route,
  VERIFY,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { DATE_FORMATS } from '~shared/helpers/date';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { type Values as VerificationDetails } from './Verify';
import { VerifyActionSheet } from './VerifyActionSheet';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

type OwnProps = {
  tempPassword: string;
  onboardState: string | null | undefined;
  onVerifySuccess: () => void;
};

type StateProps = {
  nextRoute: (route?: Route) => string;
  showProgyny: boolean;
};

type DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  validateUser: typeof validateUser;
  setInsuranceLoadingSeen: typeof setInsuranceLoadingSeen;
  push: typeof push;
};

const mapStateToProps = (state: ReduxStateShared): StateProps => ({
  nextRoute: (desiredOnboardRoute?: Route) => nextUrl(state, VERIFY, desiredOnboardRoute),
  showProgyny: getShowProgynySelfServiceForUser(state),
});

const mapDispatchToProps = {
  // eslint-disable-next-line import/no-deprecated
  validateUser,
  setInsuranceLoadingSeen,
  push,
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
  const { tempPassword, onboardState, onVerifySuccess } = ownProps;
  const { nextRoute, ...otherStateProps } = stateProps;
  const {
    push: pushDispatch,
    validateUser: validateUserDispatch,
    setInsuranceLoadingSeen: setInsuranceLoadingSeenDispatch,
    ...otherDispatchProps
  } = dispatchProps;
  return {
    ...otherDispatchProps,
    ...otherStateProps,
    onVerify: (data: VerificationDetails) => {
      const dob = format(
        parse(data.date_of_birth, DATE_FORMATS.DATE_INPUT_FORMAT, 0),
        DATE_FORMATS.YEAR_MONTH_DAY_DASHED,
      );

      return validateUserDispatch({
        last_name: data.last_name,
        date_of_birth: dob,
        token: tempPassword,
        terms: data.terms,
        // @ts-expect-error TS(2339): Property 'then' does not exist on type '(dispatch: Dispatch<any>) => Promise<boolean>'.
      }).then((success) => {
        if (!success) {
          return;
        }
        onVerifySuccess();

        // eslint-disable-next-line promise/always-return
        if (onboardState) {
          if (onboardState !== ONBOARD_STATE_MED_CONFIRMATION) {
            setInsuranceLoadingSeenDispatch();
          }

          pushDispatch(nextRoute(ONBOARD_STATE_TO_ROUTE[onboardState]));
        } else {
          pushDispatch(nextRoute());
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VerifyActionSheet);
