// @owners { team: patients-team }
import React from 'react';
import InfoRow from '~web/components/InfoRow';

type Props = {
  readonly medicationName: string;
};

export const AncillaryItemsTag = ({ medicationName }: Props) => {
  return (
    <InfoRow
      icon="syringe-small"
      infoText={`We'll include the necessary supplies to help you administer ${medicationName} safely and correctly. Examples of ancillary supplies include needles and syringes.`}
    >
      Ancillary items included
    </InfoRow>
  );
};
