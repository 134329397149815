// @owners { team: patients-team }
import { ActionSheetContext, AltoIcon, Description, ListDescription, ListItem } from '@alto/design-system';
import React, { useContext } from 'react';
import { sendAssistantPastDeliveryChosen } from '~shared/features/alto-assistant/analytics/helpers';
import { getItemMedNames } from '~shared/features/checkout/helpers';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatRelativeDate } from '~shared/helpers/date';
import { useDispatchShared } from '~shared/store';
import { PastDeliveryActionSheet } from './PastDeliveryActionSheet';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
};

export const PastDeliveryRow = ({ shipment }: Props) => {
  const { delivery_ids, date, items } = shipment;
  const itemMedNames = getItemMedNames(items);
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();

  const handleOnPress = () => {
    const editable = false;
    dispatch(sendAssistantPastDeliveryChosen({ editable, delivery_ids, shipmentID: shipment.id }));
    setActiveActionSheet(<PastDeliveryActionSheet shipment={shipment} />);
  };

  return (
    <ListItem
      onPress={handleOnPress}
      title={formatRelativeDate(date || '')}
      RightContent={<AltoIcon name="chevronright-small" />}
      descriptions={
        itemMedNames
          ? [
              <ListDescription
                key={itemMedNames}
                iconName={'pillbottle-small'}
              >
                <Description>{itemMedNames}</Description>
              </ListDescription>,
            ]
          : undefined
      }
    />
  );
};
