// @owners { team: patients-team }
import {
  CartEndpoint,
  type CartEndpointFetchActiveRequest,
} from '@alto/scriptdash/alto/patient_app/carts/v1/cart_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';

const CartApi = CartEndpoint(apiEndpointHandler);

export const cart = createQueryKeys('cart', {
  active: (params: CartEndpointFetchActiveRequest) => ({
    queryKey: [params],
    queryFn: () => CartApi.fetchActive(params),
  }),
});
