import { COLORS } from '@alto/design-library-tokens';
import { type ColorVariant } from './types';

export const getBackgroundColor = (type: ColorVariant) => {
  if (type === 'error') {
    return COLORS.ALERT_COLORS.ERROR;
  }

  if (type === 'warning') {
    return COLORS.ALERT_COLORS.WARNING;
  }

  if (type === 'success') {
    return COLORS.ALERT_COLORS.SUCCESS;
  }

  return COLORS.ALERT_COLORS.NEUTRAL;
};

export const getBorderColor = (type: ColorVariant) => {
  if (type === 'error') {
    return COLORS.BORDER_COLORS.DANGER;
  }

  if (type === 'warning') {
    return COLORS.BORDER_COLORS.WARNING;
  }

  if (type === 'success') {
    return COLORS.BORDER_COLORS.SUCCESS;
  }

  return COLORS.BORDER_COLORS.SECONDARY;
};

export const getColors = (variant: ColorVariant) => {
  switch (variant) {
    case 'error':
      return {
        background: COLORS.BACKGROUND_COLORS.DANGER,
        text: COLORS.TEXT_COLORS.WHITE,
        pressBackground: COLORS.BACKGROUND_COLORS.DANGER,
        borderColor: COLORS.BORDER_COLORS.DANGER,
      };
    case 'warning':
      return {
        background: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
        text: COLORS.TEXT_COLORS.WARNING,
        pressBackground: COLORS.BACKGROUND_COLORS.WARNING_LIGHTER,
        borderColor: COLORS.BORDER_COLORS.WARNING,
      };
    case 'success':
      return {
        background: COLORS.BACKGROUND_COLORS.SUCCESS,
        text: COLORS.TEXT_COLORS.WHITE,
        pressBackground: COLORS.BACKGROUND_COLORS.SUCCESS,
        borderColor: COLORS.BORDER_COLORS.SUCCESS,
      };
    default:
      return {
        background: COLORS.BACKGROUND_COLORS.SECONDARY,
        text: COLORS.TEXT_COLORS.WHITE,
        pressBackground: COLORS.BACKGROUND_COLORS.SECONDARY,
        borderColor: COLORS.BORDER_COLORS.SECONDARY,
      };
  }
};

export const getInlineAlertColors = ({ variant, borderless }: { variant: ColorVariant; borderless: boolean }) => {
  switch (variant) {
    case 'error':
      return {
        backgroundColor: borderless
          ? COLORS.BACKGROUND_COLORS.DANGER_LIGHTER
          : COLORS.BACKGROUND_COLORS.DANGER_LIGHTEST,
        borderColor: COLORS.BACKGROUND_COLORS.DANGER_LIGHTER,
      };
    case 'warning':
      return {
        backgroundColor: borderless
          ? COLORS.BACKGROUND_COLORS.WARNING_LIGHTER
          : COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
        borderColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTER,
      };
    case 'success':
      return {
        backgroundColor: borderless
          ? COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTER
          : COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTEST,
        borderColor: COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTER,
      };
    default:
      return {
        backgroundColor: borderless
          ? COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTER
          : COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST,
        borderColor: COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTER,
      };
  }
};
