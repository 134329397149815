// @owners { team: patients-team }
import { TYPOGRAPHY } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, H2, Modal, ModalContent, palette, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { getSelectedVideo } from '~shared/features/instructional-videos/selectors/getSelectedVideo';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { useSelectorShared } from '~shared/store';
import DescriptionPane from '~web/features/instructional-videos/components/DescriptionPane';

const PaddedColumn = styled(Column)`
  padding: ${spacing.xlSpacing} 0;
`;

const ColoredH2 = styled(H2)`
  color: ${palette.altoColorsP2};
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
`;

const LeftAlignedColumn = styled(Column)`
  text-align: left;
`;

export type Props = {
  readonly onClose: () => void;
};

export const MedListVideoPrepInfoModal = ({ onClose }: Props) => {
  const video = useSelectorShared(getSelectedVideo);
  const isModalShowing = useSelectorShared(getIsModalShowing);
  const modalType = useSelectorShared(getModalType);
  const show = !!video && isModalShowing && modalType === 'VIDEO_PREP_INFO_MODAL';

  return show ? (
    <Modal
      showing={show}
      showDismiss
      onClose={onClose}
      title="Preparation Info"
    >
      <ModalContent>
        <PaddedColumn spacing={spacing.xlSpacing}>
          <ColoredH2>Preparation Info</ColoredH2>
          <LeftAlignedColumn>
            <DescriptionPane video={video} />
          </LeftAlignedColumn>
        </PaddedColumn>
      </ModalContent>
    </Modal>
  ) : null;
};
