// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { SPACING } from '@alto/design-library-tokens';
import { type Button, ButtonGroup, LgPadding } from '@alto/design-system';
import React from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { getAutoRefillNamesForShipment } from '~shared/features/auto-refill/selectors/getAutoRefillNamesForShipment';
import { includesAsapDeliveryFee } from '~shared/features/shipment-fees/helpers/includesAsapDeliveryFee';
import { isShipmentInEditableStatus } from '~shared/features/shipments/helpers/isShipmentEditable';
import getIsMedSyncUser from '~shared/features/users/selectors/getIsMedSyncUser';
import { isPickup } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { type ShipmentFee, type ShipmentStatus } from '~shared/types';
import { ContactUs } from '../../../alto-assistant';
import { CancelButton } from './CancelButton';
import { DownloadReceiptButton } from './DownloadReceiptButton';
import { EditButton } from './EditButton';
import { SnoozeButton } from './SnoozeButton';

type ActionButtonsGroupProps = {
  readonly charged?: boolean;
  readonly handleDownloadReceipt: () => void;
  readonly handleEditShipment: () => void;
  readonly handleOpenSnooze?: () => void;
  readonly method?: DeliveryMethod;
  readonly openCancelDeliveryModal: () => void;
  readonly shipmentFees?: ShipmentFee[] | null;
  readonly shipmentID: number;
  readonly shipmentIsEditable?: boolean;
  readonly status?: ShipmentStatus;
  readonly userID?: number;
};

export const ActionButtonsGroup = ({
  charged = false,
  handleDownloadReceipt,
  handleEditShipment,
  handleOpenSnooze,
  method,
  openCancelDeliveryModal,
  shipmentFees,
  shipmentID,
  shipmentIsEditable,
  status,
  userID,
}: ActionButtonsGroupProps) => {
  const buttons: React.ReactElement<typeof Button>[] = [];
  const shipmentInEditableStatus = isShipmentInEditableStatus(status);
  const pickup = method !== undefined ? isPickup(method?.mode) : undefined;
  const isAsapDelivery = includesAsapDeliveryFee(shipmentFees);

  const autoRefillNamesForShipment = useSelectorShared((state) => getAutoRefillNamesForShipment(state, { shipmentID }));
  const isMedSync = useSelectorShared((state) => getIsMedSyncUser(state, { userID }));
  const shipmentContainsAutoRefillPrescriptions = autoRefillNamesForShipment.length > 0;
  const isSnoozeEnabled = shipmentContainsAutoRefillPrescriptions && !isMedSync && !pickup;

  if (shipmentIsEditable) {
    buttons.push(
      <EditButton
        key="Edit order button"
        handleEditShipment={handleEditShipment}
        shipmentID={shipmentID}
      />,
    );
  }

  if ((pickup || isAsapDelivery) && status !== 'delivered') {
    buttons.push(
      <ContactUs
        type="button"
        method="message"
        origin={CONTACT_US_ORIGIN.DeliveryDetailsPickup}
        customLabel="Contact Alto to edit"
        buttonType="secondary"
        buttonWidth="inline"
        small
      />,
    );
  }

  if (charged) {
    buttons.push(
      <DownloadReceiptButton
        key="Download receipt button"
        handleDownloadReceipt={handleDownloadReceipt}
        shipmentID={shipmentID}
      />,
    );
  }

  if (shipmentIsEditable && isSnoozeEnabled && handleOpenSnooze) {
    buttons.push(
      <SnoozeButton
        key="Snooze order button"
        handleOpenSnooze={handleOpenSnooze}
        shipmentID={shipmentID}
      />,
    );
  }

  // only render if shipment is in editable status and is not ASAP delivery
  if (shipmentInEditableStatus && !isAsapDelivery) {
    buttons.push(
      <CancelButton
        key="Cancel order button"
        openCancelDeliveryModal={openCancelDeliveryModal}
        shipmentID={shipmentID}
      />,
    );
  }

  return (
    <LgPadding topPadding={SPACING.STATIC.NONE}>
      <ButtonGroup
        buttons={buttons}
        inline
        justifyButtons="left"
      />
    </LgPadding>
  );
};
