// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding, MdSpacing } from '@alto/design-system';
import { useAddToCart } from '@alto/features';
import { PriceOverrideReasonMap } from '@alto/scriptdash/alto/pricing/types/v3/price_override_reason';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
// eslint-disable-next-line import/no-deprecated
import { type BillingOverridePayload, autobillPrescription } from '~shared/actions/prescriptions';
import { openCart } from '~shared/actions/ui/cart';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import {
  type VacationSupplyFormValues,
  initialFormValues,
  // eslint-disable-next-line import/no-deprecated
  validateVacationForm,
} from '~shared/features/vacation-supply/helpers';
import { formatDateStringForAPI } from '~shared/helpers/date';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
// eslint-disable-next-line import/no-deprecated
import { fetchLatestVacationSupply } from '~shared/queries/useFetchLatestVacationSupply';
import { useDispatchShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { VacationSupplyFailureActionSheet } from '~web/features/my-meds/components/modals/VacationSupply/VacationSupplyFailure.action-sheet';
import { VacationSupplyForm } from '~web/features/my-meds/components/modals/VacationSupply/VacationSupplyForm';

type VacationSupplyActionSheetProps = {
  readonly prescription: Prescription;
};

export const VacationSupplyActionSheet = ({ prescription }: VacationSupplyActionSheetProps) => {
  const { setActiveActionSheet, closeActionSheet, goToPreviousActionSheet } = useContext(ActionSheetContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useDispatchShared();
  const { handleAddToCart } = useAddToCart();
  const { nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID: prescription.id });
  const earliestAvailableDate = nextAvailableDate?.earliest.date || '';

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: initialFormValues,
    onSubmit: (values) => {
      onVacationSupplyFormSubmit(values);
    },
    // eslint-disable-next-line import/no-deprecated
    validate: (values) => validateVacationForm(earliestAvailableDate, values),
  });

  useEffect(() => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(
          EVENTS.VACATION_SUPPLY_FORM_VIEWED,
          {},
          {
            prescriptionId: prescription.id,
          },
        ),
      ),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onVacationSupplyFormSubmit = async (values: VacationSupplyFormValues) => {
    const vacationOverrideParams: BillingOverridePayload = {
      reason: PriceOverrideReasonMap.VACATION_SUPPLY,
      data: {
        travel_destination: values.travelDestination,
        travel_start_date: formatDateStringForAPI(values.startDate) || '',
        travel_end_date: formatDateStringForAPI(values.returnDate) || '',
      },
    };
    setIsLoading(true);
    // eslint-disable-next-line import/no-deprecated
    const result = await dispatch(autobillPrescription(prescription.id, vacationOverrideParams));

    if (result.autobillSuccess) {
      const res = await handleAddToCart({
        resource_id: prescription.id,
        resource_type: 'Prescription',
        resetOrderDate: true,
      });
      setIsLoading(false);
      if (res.success) {
        dispatch(openCart());
        closeActionSheet();
      }
    } else {
      setIsLoading(false);
      // eslint-disable-next-line import/no-deprecated
      const latestVacationSupplyResponse = await fetchLatestVacationSupply({
        prescription_id: prescription.id,
        status: 'failed',
      });
      if (latestVacationSupplyResponse?.data) {
        setActiveActionSheet(
          <VacationSupplyFailureActionSheet
            vacationSupply={latestVacationSupplyResponse.data}
            prescription={prescription}
          />,
        );
      }
    }
  };

  return (
    <ActionSheetV2
      title="Request vacation supply"
      handleClose={goToPreviousActionSheet}
      buttons={[
        <Button
          key="submit"
          onPress={formik.handleSubmit}
          loading={isLoading}
          label="Submit & add to cart"
        />,
      ]}
      analyticsName="vacation supply"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          When traveling, your insurance will typically cover your medication earlier than usual so we can deliver it to
          you before you leave.
          <br />
          <br />
          Provide your travel info and we’ll contact your insurance.
        </Body>
        <MdSpacing />
        <VacationSupplyForm
          values={formik.values}
          errors={formik.errors}
          handleChange={formik.handleChange}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
