import {
  type PatientDiagnosesEndpointCreateRequest,
  type PatientDiagnosesEndpointCreateResponse,
  type PatientDiagnosesEndpointDeleteByCodeRequest,
  type PatientDiagnosesEndpointDeleteByCodeResponse,
} from '@alto/scriptdash/alto/patient_app/patient_diagnoses/v1/patient_diagnoses_endpoint';
import { destroyWithParams, post } from '~shared/helpers/apiHelper';

export const createPatientDiagnosis = async (
  params: PatientDiagnosesEndpointCreateRequest,
): Promise<PatientDiagnosesEndpointCreateResponse> => {
  return await post('/patient_diagnoses', params, { version: 'v1' });
};

export const deletePatientDiagnosisByCode = async (
  params: PatientDiagnosesEndpointDeleteByCodeRequest,
): Promise<PatientDiagnosesEndpointDeleteByCodeResponse> => {
  return await destroyWithParams(`/patient_diagnoses/delete_by_code`, params, { version: 'v1' });
};
