// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import { StyledTouchable, StyledVisuallyHidden } from '~web/features/essentials/primitives/Styled';

type ContainerProps = {
  readonly onChange: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  readonly onClick?: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  readonly value: string;
  readonly label: string;
  readonly id: string;
  readonly children: ReactNode[];
  readonly disabled?: boolean;
  readonly minWidth?: number;
};

const StyledSelect = styled.select<{ children: React.ReactNode; minWidth?: number }>`
  background: url('https://assets.prod.alto.com/icons/chevrondown.svg') no-repeat right;
  -webkit-appearance: none;
  background-position-x: ${({ minWidth = 100 }) => `${minWidth - 30}px`};
  background-size: 16px 16px;
  margin-bottom: 0;
  border: none;
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
  padding: ${SPACING.STATIC.MD.px};
  border-radius: ${BORDERS.RADIUS.LG.px};
  min-width: ${({ minWidth = 100 }) => `${minWidth}px`};
  outline: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
  ${StyledTouchable};
`;

const StyledSelectLabel = styled.label`
  ${StyledVisuallyHidden};
`;

const Container = ({ onChange, minWidth, value, children, id, label, onClick, disabled }: ContainerProps) => {
  return (
    <>
      <StyledSelectLabel htmlFor={id}>{label}</StyledSelectLabel>
      <StyledSelect
        minWidth={minWidth}
        name={id}
        onChange={onChange}
        onClick={onClick}
        value={value}
        disabled={disabled}
      >
        {children}
      </StyledSelect>
    </>
  );
};

type ItemProps = {
  readonly disabled?: boolean;
  readonly selected?: boolean;
  readonly label: string;
  readonly value: string;
};

const Item = ({ value, label, selected, disabled }: ItemProps) => {
  return (
    <option
      value={value}
      label={label}
      selected={selected}
      disabled={disabled}
    >
      {label}
    </option>
  );
};

export const Select = { Container, Item };
