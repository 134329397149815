import { type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-deprecated
import { getAutoRefills__DEPRECATED } from './getCart';
import { getOrder } from './getOrder';
import PrescriptionItem from '~shared/features/checkout/prescriptionItem';
import { type CartAutoRefill, type Item, type ItemKey } from '~shared/features/checkout/types';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { nextDelivery } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import DeliveryItem from '~shared/features/delivery-info/deliveryItem';
import {
  getMedicationsFromPrescriptions,
  medicationKeyForPrescription,
} from '~shared/features/my-meds/selectors/getMedicationsFromPrescriptions';
import { type Medication } from '~shared/features/my-meds/types';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { getOrderPricing } from '~shared/features/pricing/selectors/getPricing';
import { type OrderPricing } from '~shared/features/pricing/types';
import { type LightDelivery as Delivery, type Prescription } from '~shared/types';

function itemToWrapper(
  item: ItemKey,
  deliveries: Delivery[],
  prescriptions: Prescription[],
  medications: Medication[],
  pricing: OrderPricing,
  autoRefill: CartAutoRefill,
  selectedPaymentType: PriceType | undefined,
) {
  const { resource_type, resource_id } = item;

  if (resource_type === 'Prescription') {
    const prescription = prescriptions.find((p) => p.id === resource_id);

    if (prescription) {
      const delivery = nextDelivery(deliveries.filter((d) => d.prescription_id === prescription.id));
      const key = medicationKeyForPrescription(prescription);
      const medication = medications.find((m) => m.key === key);

      if (medication) {
        return new PrescriptionItem({
          ...item,
          medication,
          prescription,
          nextDelivery: delivery,
          autoRefill: autoRefill?.[prescription.id],
          pricing: pricing.item_pricing[prescription.id],
          selectedPaymentType,
        });
      }
    }
  } else if (resource_type === 'Delivery') {
    const delivery = deliveries.find((d) => d.id === resource_id);
    const prescription = prescriptions.find((p) => p.id === delivery?.prescription_id);

    if (delivery && prescription) {
      const key = medicationKeyForPrescription(prescription);
      const medication = medications.find((m) => m.key === key);

      if (medication) {
        return new DeliveryItem({
          ...item,
          delivery,
          prescription,
          medication,
          pricing: pricing.item_pricing[prescription.id],
        });
      }
    }
  }

  return null;
}

// @deprecated - use libs/shared/features/cart/hooks/useCartData hook
export const getItemsInOrder__DEPRECATED = createSelector(
  [
    getDeliveries,
    getPrescriptions,
    getOrder,
    getMedicationsFromPrescriptions,
    // eslint-disable-next-line import/no-deprecated
    getAutoRefills__DEPRECATED,
    getOrderPricing,
  ],
  (deliveries, prescriptions, order, medications, autoRefills, pricing) => {
    if (!order.items) {
      return [];
    }

    return order.items.reduce<Item[]>((items, item) => {
      const prices = pricing.item_pricing[item.resource_id]?.prices ?? [];
      const selectedPaymentType = prices.find((price) => price.selected)?.type ?? undefined;
      const wrapped = itemToWrapper(
        item,
        deliveries,
        prescriptions,
        medications,
        pricing,
        autoRefills,
        selectedPaymentType,
      );

      if (wrapped) {
        items.push(wrapped);
      }

      return items;
    }, []);
  },
);

// @deprecated - use libs/shared/features/cart/hooks/usePrescriptionsInCart hook
export const getPrescriptionsInOrder__DEPRECATED = createSelector([getItemsInOrder__DEPRECATED], (items) => {
  return items.reduce<Prescription[]>((prescriptions, item) => {
    if (item?.prescription) {
      prescriptions.push(item.prescription);
    }

    return prescriptions;
  }, []);
});

// @deprecated - use libs/shared/features/cart/hooks/usePrescriptionsInCart hook
export const getInjectablesInOrder__DEPRECATED = createSelector([getItemsInOrder__DEPRECATED], (items) => {
  return items.filter((item) => item.prescription?.injectable);
});

// @deprecated - use libs/shared/features/cart/hooks/usePrescriptionsInCart hook
export const getOrderHasInjectable__DEPRECATED = createSelector([getInjectablesInOrder__DEPRECATED], (injectables) => {
  return injectables.length > 0;
});
