// @owners { team: patients-team }
import { type AltoAssistantActions, SET_DRAFT_MESSAGE, SET_SELF_SERVICE_EVENT } from '~shared/actions/altoAssistant';
import { type SelfServiceEvent } from '~shared/features/alto-assistant/constants';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateAltoAssistant = {
  draftMessage: string;
  selfServiceEvent: SelfServiceEvent | null;
};

const initialState: StateAltoAssistant = {
  draftMessage: '',
  selfServiceEvent: null,
};

const altoAssistant = (state: StateAltoAssistant = initialState, action: AltoAssistantActions): StateAltoAssistant => {
  const { payload, type } = action;

  switch (type) {
    case SET_DRAFT_MESSAGE: {
      return {
        ...state,
        draftMessage: payload,
      };
    }

    case SET_SELF_SERVICE_EVENT: {
      return { ...state, selfServiceEvent: payload };
    }

    default:
      return state;
  }
};

export default withRehydration(altoAssistant, initialState);
