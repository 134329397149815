import { addDays, isBefore, isEqual, isWithinInterval, startOfDay } from 'date-fns';
import { memoize } from 'lodash';
import { useRef } from 'react';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getScheduleablePrescriptions } from '~shared/features/my-meds/selectors/getScheduleablePrescriptions';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { type ItemPricing } from '~shared/features/pricing/types';
import { useSelectorShared } from '~shared/store';
import { type NextAvailableDate, type Prescription } from '~shared/types';

export const MAX_DAYS_AFTER_REFILL_DATE = 14;

export const hasEligibleNextRefillDate = (nextRefillDate?: string) => {
  if (!nextRefillDate) {
    return false;
  }

  const today = startOfDay(new Date());
  const startOfRefillDate = startOfDay(new Date(nextRefillDate));
  const maxDate = addDays(startOfRefillDate, MAX_DAYS_AFTER_REFILL_DATE);
  return isWithinInterval(today, { start: startOfRefillDate, end: maxDate });
};

export const hasEligibleNextAvailableDate = (
  prescriptionID: number,
  delivery_method: string,
  earliestAvailableDate: string,
  nextAvailableDatesByPrescriptionID: Record<number, NextAvailableDate>,
) => {
  const nextAvailableDateForPrescription = nextAvailableDatesByPrescriptionID[prescriptionID];

  if (!nextAvailableDateForPrescription || delivery_method !== nextAvailableDateForPrescription.deliveryMethod) {
    return false;
  }

  const nextAvailableDate = new Date(nextAvailableDateForPrescription.earliest.date);
  const availableDate = new Date(earliestAvailableDate);
  return isEqual(nextAvailableDate, availableDate) || isBefore(nextAvailableDate, availableDate);
};

export const isInCart = (prescriptionID: number, prescriptionsInCart: Prescription[]) => {
  return prescriptionsInCart.some(({ id }) => id === prescriptionID);
};

export const hasMultiplePriceOptions = (prescriptionID: number, prescriptionPricing: ItemPricing) => {
  return prescriptionPricing[prescriptionID]?.prices?.length > 1;
};

export const sortByRefillDate = (a: Prescription, b: Prescription) => {
  if (!a?.next_refill_date || !b?.next_refill_date) return 0;
  return new Date(a.next_refill_date).getTime() - new Date(b.next_refill_date).getTime();
};

type GetCrossSellPrescriptionsParams = {
  earliestAvailableDate: string;
  prescriptions: Prescription[];
  deliveryMethod: string;
  nextAvailableDatesByPrescriptionID: Record<number, NextAvailableDate>;
  prescriptionsInCart: Prescription[];
  prescriptionPricing: ItemPricing;
};

const getCrossSellPrescriptions = memoize(
  ({
    earliestAvailableDate,
    prescriptions,
    deliveryMethod,
    nextAvailableDatesByPrescriptionID,
    prescriptionsInCart,
    prescriptionPricing,
  }: GetCrossSellPrescriptionsParams) => {
    return prescriptions
      .filter(
        (prescription) =>
          !prescription.is_add_on_otc &&
          hasEligibleNextRefillDate(prescription.next_refill_date) &&
          earliestAvailableDate &&
          hasEligibleNextAvailableDate(
            prescription.id,
            deliveryMethod,
            earliestAvailableDate,
            nextAvailableDatesByPrescriptionID,
          ) &&
          !isInCart(prescription.id, prescriptionsInCart) &&
          !hasMultiplePriceOptions(prescription.id, prescriptionPricing),
      )
      .sort(sortByRefillDate);
  },
  (props) => JSON.stringify(props),
);

export const useCrossSellPrescriptions = () => {
  const prescriptions = useSelectorShared(getScheduleablePrescriptions);
  const { earliestAvailableDate, deliveryMethod } = useCartNextAvailableDate();
  const { nextAvailableDatesByPrescriptionID } = useNextAvailableDatesForPrescriptions();
  const prescriptionPricing = useSelectorShared(getPrescriptionPricing);
  const { prescriptions: prescriptionsInCart } = usePrescriptionsInCart();
  const initialPrescriptionsInCart = useRef(prescriptionsInCart);
  const crossSellPrescriptions = getCrossSellPrescriptions({
    prescriptionsInCart: initialPrescriptionsInCart.current,
    prescriptionPricing,
    nextAvailableDatesByPrescriptionID,
    earliestAvailableDate,
    deliveryMethod,
    prescriptions,
  });

  return { crossSellPrescriptions };
};
