// @owners { team: patients-team }
import { ActionSheetV2, Button, ListItem, MdSpacing, StandaloneCheckbox } from '@alto/design-system';
import React, { useState } from 'react';
import autoRefillImage from '~shared/assets/images/auto_refill.png';
import { getPrescriptionsByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { useSelectorShared } from '~shared/store';
import { MedicationImage } from '../../../medication';

type Props = {
  readonly label: string;
  readonly onSubmit: (prescriptionIDs: number[]) => void;
  readonly prescriptionIDs: number[];
  readonly title: string;
};

export const SelectMedicationsActionSheet = ({ label, onSubmit, prescriptionIDs, title }: Props) => {
  const prescriptions = useSelectorShared((state) => getPrescriptionsByIDs(state, prescriptionIDs));
  const [selectedPrescriptionIDs, setSelectedPrescriptionIDs] = useState<number[]>(prescriptions.map((p) => p.id));

  const onButtonPress = () => {
    onSubmit(selectedPrescriptionIDs);
  };

  const handleCheckboxPress = (prescriptionID: number) => {
    if (selectedPrescriptionIDs.includes(prescriptionID)) {
      setSelectedPrescriptionIDs(selectedPrescriptionIDs.filter((id) => id !== prescriptionID));
    } else {
      setSelectedPrescriptionIDs([...selectedPrescriptionIDs, prescriptionID]);
    }
  };

  return (
    <ActionSheetV2
      illustrationSrc={autoRefillImage}
      title={title}
      buttons={[
        <Button
          key="select-medications-button"
          label={label}
          onPress={onButtonPress}
          disabled={selectedPrescriptionIDs.length === 0}
        />,
      ]}
    >
      {prescriptions.map((prescription) => {
        return (
          <React.Fragment key={prescription.id}>
            <ListItem
              onPress={() => {
                handleCheckboxPress(prescription.id);
              }}
              title={prescription.medication_name}
              LeftContent={<MedicationImage imageUrl={prescription.image_url} />}
              RightContent={<StandaloneCheckbox value={selectedPrescriptionIDs.includes(prescription.id)} />}
            />
            <MdSpacing />
          </React.Fragment>
        );
      })}
    </ActionSheetV2>
  );
};
