// @owners { team: patients-team }
import { InputText } from '@alto/design-system';
import React from 'react';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  value: string;
};

export const InsuranceGroupInput = ({ error, onChange, value }: Props) => {
  return (
    <InputText
      autoCapitalize="characters"
      autoCorrect={false}
      caption="Can also be RxGrp"
      error={error}
      id="group"
      keyboardType="default"
      label="Rx Group"
      onChangeText={onChange}
      placeholder="00000000"
      value={value}
    />
  );
};
