// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getPriceByPrescriptionID } from './getPriceByPrescriptionID';
import { getCurrentUserHasHealthConditions } from '~shared/features/users/selectors/getCurrentUserHasHealthConditions';

export const SCHEDULE_DELIVERY = 'SCHEDULE_DELIVERY';
export const PROCESSING_INFORMATION = 'PROCESSING_INFORMATION';

export type OnboardingSuccessMessageType = typeof SCHEDULE_DELIVERY | typeof PROCESSING_INFORMATION;

export const getOnboardingSuccessMessageType = createSelector(
  [getPriceByPrescriptionID, getCurrentUserHasHealthConditions],
  (priceByPrescriptionID, hasHealthConditions): OnboardingSuccessMessageType => {
    const hasNullPrices = Object.values(priceByPrescriptionID).some((price) => price === null);

    // we assume that this is only used for onboarding,
    // health conditions are added by the user during this flow
    if (!hasNullPrices && !hasHealthConditions) {
      return SCHEDULE_DELIVERY;
    }

    return PROCESSING_INFORMATION;
  },
);
