// @owners { team: patients-team }
import { useCheckoutCourierTipping } from '~shared/features/checkout/hooks/useCheckoutCourierTipping';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getHasAppliedDeliveryFee } from '~shared/features/shipment-fees/selectors/getHasAppliedDeliveryFee';
import { useSelectorShared } from '~shared/store';
type Results = {
  fsa: string;
  label: string;
  description: string;
};

export const useDeliveryFeeCopy = (): Results => {
  const { isEligibleForTipping } = useCheckoutCourierTipping();
  const order = useSelectorShared(getOrder);
  const hasTipAmount = order.tip_amount !== undefined && order.tip_amount > 0;
  const hasTip = isEligibleForTipping && hasTipAmount;
  const hasDeliveryFeeAmount = useSelectorShared(getHasAppliedDeliveryFee);

  if (hasTip && hasDeliveryFeeAmount) {
    return {
      label: 'Tip & delivery payment',
      fsa: 'Depending on your plan, tips &  delivery fees may not be eligible expenses. You can change your payment method here to pay for tip & delivery.',
      description:
        'If paying with an FSA/HSA/Carrot card, depending on your plan, tips & delivery fees may not be a qualified expense.',
    };
  }
  if (hasDeliveryFeeAmount) {
    return {
      label: 'Delivery payment',
      fsa: 'Depending on your plan, delivery fees may not be eligible expenses. You can change your payment method here to pay for delivery.',
      description:
        'If paying with an FSA/HSA/Carrot card, depending on your plan, delivery fees may not be a qualified expense.',
    };
  }
  return {
    label: 'Tip payment',
    fsa: 'Depending on your plan, tips may not be eligible expenses. You can change your payment method here to pay for tips.',
    description: 'If paying with an FSA/HSA/Carrot card, depending on your plan, tips may not be a qualified expense.',
  };
};
