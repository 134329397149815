// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getErrors from '~shared/features/ui/selectors/getErrors';
import { type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const getSudoError: (state: ReduxStateShared) => APIError | null = createSelector(
  getErrors,
  (errors) => errors.fetchSudoError || errors.setSudoError,
);

export default getSudoError;
