// @owners { team: patients-team }
import { useQueryShipmentFeesConfig } from '~shared/queries';

export const useQueryEssentialsFeeConfigs = () => {
  const res = useQueryShipmentFeesConfig();
  const { essentials_only_delivery } = res.shipmentFeeConfigHash || {};

  const essentialsDeliveryFee = essentials_only_delivery?.presentable_price;
  const essentialsFreeDeliveryMinimumBasket = essentials_only_delivery?.minimum_threshold_to_waive_fee;
  const essentialsMinimumBasket = essentials_only_delivery?.minimum_threshold_to_allow_order;
  const essentialsFreeDeliveryText = `No Essentials fee for orders with a prescription or for orders over ${essentialsFreeDeliveryMinimumBasket?.display_value}. Additional delivery fees may still apply.`;

  return {
    isSuccess: res.isSuccess,
    isPending: res.isPending,
    error: res.error,
    isError: res.isError,
    essentialsDeliveryFee,
    essentialsFreeDeliveryMinimumBasket,
    essentialsFreeDeliveryText,
    essentialsMinimumBasket,
  };
};
