// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  LgPadding,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { cancelDeliveries, clearDeliveriesError } from '~shared/actions/deliveries';
import { deselectPrescription } from '~shared/actions/prescriptions';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { getAutoRefillNamesForShipment } from '~shared/features/auto-refill/selectors/getAutoRefillNamesForShipment';
import { getAutoRefillNamesForDeliveries } from '~shared/features/delivery/helpers/getAutoRefillNamesForDeliveries';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { getSelectedPrescription } from '~shared/features/prescriptions/selectors/getSelectedPrescription';
import { usePrescriptionDeliveryWaiveFeeWarning } from '~shared/features/same-day-delivery/hooks/usePrescriptionDeliveryWaiveFeeWarning';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { isInProgressMedSync } from '~shared/helpers/isInProgressMedSync';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useInvalidatePrescriptions } from '~shared/queries';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { homescreenMethods } from '../../homescreen/queries/queries';
import { useInvalidateMedDetails } from '../../med-details/queries/useMedDetails';
import { useInvalidateMedList } from '../../med-list/queries/useMedList';
import { AutoRefillOrderCancel, RemovalWillAddEssentialsFeeWarning } from '../../order-cancel';
import { DeliveryWaivedFeeWarning } from '../../same-day-delivery';

type RemoveMedicationActionSheetProps = {
  readonly shipmentID?: number;
  readonly deliveryID?: number;
};

export const RemoveMedicationActionSheet = ({ deliveryID, shipmentID }: RemoveMedicationActionSheetProps) => {
  const {
    invalidatePrescriptionsQuery: invalidateMedDetailsPrescriptionsQuery,
    invalidateMedicationQuery: invalidateMedDetailsMedicationQuery,
  } = useInvalidateMedDetails();
  const { invalidateListQuery } = useInvalidateMedList();
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { invalidatePrescriptionsQuery } = useInvalidatePrescriptions();
  const isLoading = useSelectorShared((state) => state.ui.loading.cancelDeliveriesLoading);
  const userID = useSelectorShared(getCurrentUserID);
  const prescription = useSelectorShared(getSelectedPrescription);
  let deliveries = useSelectorShared((state) => getDeliveriesForShipmentID(state, { shipmentID }));
  let autoRefillRxNames = useSelectorShared((state) => getAutoRefillNamesForShipment(state, { shipmentID }));
  if (deliveryID) {
    deliveries = deliveries.filter((delivery) => delivery.id === deliveryID);
    autoRefillRxNames = getAutoRefillNamesForDeliveries(deliveries);
  }

  const { message, showDeliveryWaiveFeeWarning } = usePrescriptionDeliveryWaiveFeeWarning({
    delivery: deliveries[0],
  });

  const autoRefillCount = autoRefillRxNames.length;
  const medSyncCount = deliveries.filter(isInProgressMedSync).length;

  const handleDelete = useCallback(async () => {
    try {
      // eslint-disable-next-line import/no-deprecated
      const res = await dispatch(cancelDeliveries(deliveries, 'remove_medication', '', true));
      invalidatePrescriptionsQuery();
      closeActionSheet();
      dispatch(deselectPrescription());
      if (res) {
        invalidateMedDetailsPrescriptionsQuery();
        invalidateMedDetailsMedicationQuery();
        invalidateListQuery();
        queryClient.invalidateQueries({ queryKey: homescreenMethods.fetchHomescreen({ userID }).queryKey });
        dispatch(clearDeliveriesError());
        trackEvent({
          event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
          params: { name: ASSISTANT_PRESSED_EVENTS.MEDICATION_REMOVED },
          additionalFields: { shipmentId: shipmentID, deliveryId: deliveryID },
        });
        addToast(<Toast variant="success">Medication removed</Toast>);
      } else {
        addToast(<Toast variant="error">There was an error removing this medication.</Toast>);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [
    addToast,
    closeActionSheet,
    deliveries,
    deliveryID,
    dispatch,
    invalidateListQuery,
    invalidateMedDetailsMedicationQuery,
    invalidateMedDetailsPrescriptionsQuery,
    invalidatePrescriptionsQuery,
    queryClient,
    shipmentID,
    trackEvent,
    userID,
  ]);

  const handleClose = useCallback(() => {
    dispatch(deselectPrescription());
  }, [dispatch]);

  return (
    <ActionSheetV2
      title="Remove medication?"
      handleClose={handleClose}
      analyticsName="delivery details remove medication"
      buttons={[
        <Button
          key="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleDelete}
          label="Yes, remove"
          loading={isLoading}
        />,
      ]}
    >
      <LgPadding
        bottomPadding={SPACING.STATIC.NONE}
        topPadding={SPACING.STATIC.NONE}
      >
        <Body>Are you sure you want to remove {prescription?.medication_name}?</Body>
        {showDeliveryWaiveFeeWarning ? (
          <>
            <XsSpacing />
            <DeliveryWaivedFeeWarning message={message} />
          </>
        ) : null}
        {deliveries[0] ? (
          <>
            <XsSpacing />
            <RemovalWillAddEssentialsFeeWarning delivery={deliveries[0]} />
          </>
        ) : null}
        {(autoRefillCount > 0 || medSyncCount > 0) && (
          <>
            <XsSpacing />
            <AutoRefillOrderCancel />
          </>
        )}
      </LgPadding>
    </ActionSheetV2>
  );
};
