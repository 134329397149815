// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Card, Description, InlineAlert, LgPadding, MdSpacing, SecondaryPage } from '@alto/design-system';
import React, { useEffect } from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { ContactUs } from '../contact-us/ContactUs';

type SupportCaseNotFoundProps = {
  readonly supportCaseID: number | null;
};

/**
 * Component for when a support case is not found or invalid for this patient
 * @param {SupportCaseNotFoundProps} props component props
 * @returns SupportCaseNotFound UI
 */
export const SupportCaseNotFound = ({ supportCaseID }: SupportCaseNotFoundProps) => {
  const { trackPageView } = useAnalytics();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  useEffect(() => {
    trackPageView({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: {
        name: ASSISTANT_VIEWED_EVENTS.SUPPORT_CASE_NOT_FOUND_PAGE,
        supportCaseID,
      },
    });
  }, [trackPageView, supportCaseID]);

  const handleBackPress = () => {
    navigateToAssistantLanding();
  };

  return (
    <SecondaryPage
      onDismiss={handleBackPress}
      dismissIcon="chevronleft"
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      navBarTitle="Request not found"
    >
      <InlineAlert type="error">
        <Description>We were unable to find your help request. Please try again later.</Description>
      </InlineAlert>
      <MdSpacing />
      <Card>
        <LgPadding>
          <Body>
            If you continue to see this error, please{' '}
            <ContactUs
              type="link"
              method="message"
              origin={CONTACT_US_ORIGIN.SupportCase}
            />
            .
          </Body>
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};
