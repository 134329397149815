// @owners { team: patients-team }
import { type RouteMessagesParams, useNavigation } from '@alto/navigation';
import { useCallback } from 'react';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';

export const useNavigateToAssistantLanding = () => {
  const { navigate } = useNavigation();
  const isNotWeb = getPlatformOS() !== 'web';

  const navigateToAssistantLanding = useCallback(
    (params?: RouteMessagesParams) => {
      if (isNotWeb) {
        navigate('RouteAppTabNavigator', { screen: 'RouteTabMessages', params });
      } else {
        navigate('RouteMessages', { ...params });
      }
    },
    [navigate, isNotWeb],
  );

  return { navigateToAssistantLanding };
};
