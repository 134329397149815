// eslint-disable-next-line @alto/no-pocky-import
import { palette, spacing } from '@alto/pocky';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'react-tabs'. '/Users/Boyle/go/src/github.com/scriptdas... (Delete me to see the full error)
import { Tab as ReactTab, TabList as ReactTabList, TabPanel as ReactTabPanel, Tabs as ReactTabs } from 'react-tabs';
import styled from 'styled-components';

export const Tabs = ReactTabs;

export const TabList = styled(ReactTabList)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-bottom: ${spacing.mdSpacing};
  border-bottom: 2px solid ${palette.grey2};
`;

export const Tab = styled(ReactTab)<{ selected?: boolean }>`
  ${(props) =>
    props.selected
      ? `border-bottom: 2px solid ${palette.royalBlue}; margin-bottom: -2px; color: ${palette.black1};`
      : `color: ${palette.grey1};`}
  box-sizing: border-box;
  list-style-type: none;
  padding: calc(${spacing.xsSpacing} + ${spacing.xxsSpacing}) ${spacing.mdSpacing};
  cursor: pointer;
  &:hover {
    background-color: ${palette.altoColorsW};
  }
`;

export const TabPanel = ReactTabPanel;
