// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES } from '@alto/design-library-tokens';
import { AltoIcon, type Link, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { formatDollars } from '~shared/helpers/currency';

type PriceProps = {
  readonly price: number | null | undefined;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly rightContent?: React.ReactElement<typeof Link>;
};

export const PriceRow = ({ price, backgroundColor, rightContent }: PriceProps) =>
  price === null || price === undefined ? null : (
    <PresentationListItem
      testID="price-row"
      LeftContent=<AltoIcon
        name="pricetag"
        type="grey"
      />
      text={formatDollars(price)}
      backgroundColor={backgroundColor}
      RightContent={rightContent}
    />
  );
