// @owners { team: patients-team }
import { closeModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { deselectPrescription, updatePrescription } from '~shared/actions/prescriptions';
import { getSelectedPrescription } from '~shared/features/prescriptions/selectors/getSelectedPrescription';
import getLoading from '~shared/features/ui/selectors/getLoading';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import AutoRefillModal, { type Props } from './AutoRefillModal';

const mapStateToProps = (state: ReduxStateShared) => {
  const { updatePrescriptionLoading } = getLoading(state);
  const showing = getIsModalShowing(state) && getModalType(state) === 'AUTO_REFILL';
  const selectedPrescription = getSelectedPrescription(state);

  return {
    selectedPrescription,
    showing,
    loading: updatePrescriptionLoading,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  const close = () => {
    dispatch(deselectPrescription());
    dispatch(closeModal('AUTO_REFILL'));
  };

  return {
    closeModal: () => {
      close();
    },
    // @ts-expect-error TS(7006): Parameter 'prescription' implicitly has an 'any' type.
    turnOffAutoRefill: (prescription) => {
      // eslint-disable-next-line promise/catch-or-return
      dispatch(
        // eslint-disable-next-line import/no-deprecated
        updatePrescription({
          auto_refill: false,

          id: prescription.id,
        }),
        // eslint-disable-next-line promise/always-return
      ).then(() => {
        close();
      });
    },
  };
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
): Props => {
  const { selectedPrescription } = stateProps;
  const { turnOffAutoRefill } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    onPressTurnOffAutoRefill: () => {
      turnOffAutoRefill(selectedPrescription);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AutoRefillModal);
