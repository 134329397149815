// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import { type ApiOptions } from '~shared/helpers/api';
import { get } from '~shared/helpers/apiHelper';
import { type ReduxDispatch, type Shipment } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const SHIPMENTS_API_VERSION = 'v3';
const shipmentOptions: ApiOptions = {
  version: SHIPMENTS_API_VERSION,
};

export type FetchShipmentsLoading = {
  type: typeof FETCH_SHIPMENTS_LOADING;
};

export type FetchShipmentsSucceeded = {
  type: typeof FETCH_SHIPMENTS_SUCCEEDED;
  payload: Shipment[];
};

export type FetchShipmentsFailed = {
  type: typeof FETCH_SHIPMENTS_FAILED;
  payload: APIError;
  error: true;
};

export type ShipmentActions = FetchShipmentsLoading | FetchShipmentsSucceeded | FetchShipmentsFailed;

export const FETCH_SHIPMENTS_LOADING = 'FETCH_SHIPMENTS_LOADING';

export function fetchingShipments() {
  return {
    type: FETCH_SHIPMENTS_LOADING,
  };
}

export const FETCH_SHIPMENTS_SUCCEEDED = 'FETCH_SHIPMENTS_SUCCEEDED';

export function fetchShipmentsSucceeded(shipments: Shipment[]) {
  return {
    type: FETCH_SHIPMENTS_SUCCEEDED,
    payload: shipments,
  };
}

export const FETCH_SHIPMENTS_FAILED = 'FETCH_SHIPMENTS_FAILED';

export function fetchShipmentsFailed(error: APIError) {
  return {
    type: FETCH_SHIPMENTS_FAILED,
    payload: error,
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchShipments() {
  return async (dispatch: ReduxDispatch) => {
    dispatch(fetchingShipments());
    return get('/shipments', {}, shipmentOptions).then((json) => {
      if (json.error) {
        dispatch(fetchShipmentsFailed(json.error));
        return false;
      }

      dispatch(fetchShipmentsSucceeded(json));
      return true;
    });
  };
}
