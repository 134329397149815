// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Body,
  Button,
  ExpandableImage,
  LgPadding,
  Row,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantPressedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CHAT_ORIGINS,
  type ActionSheetProviderStackCacheKey,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { formatDateWithoutDowWithoutYear } from '~shared/helpers/date';
import { useDispatchShared } from '~shared/store';
import { useNavigateToAssistantLanding } from '../../../helpers/useNavigateToAssistantLanding';

export type Props = {
  readonly date?: string;
  readonly imageUrl?: string | null;
  readonly safePlaceInstructions?: string;
};

export const SafePlaceConfirmationActionSheet = ({ date, imageUrl, safePlaceInstructions }: Props) => {
  const { navigate } = useNavigation();
  const { saveStack, closeActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const handleDeliveryFound = () => {
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.SAFE_PLACE_CONFIRMATION_FOUND));
    closeActionSheet();
    navigateToAssistantLanding();
  };

  const handleDeliveryNotFound = () => {
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.SAFE_PLACE_CONFIRMATION_NOT_FOUND));
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();

    navigate('RouteAssistantMessage', {
      category: 'deliveries.never_arrived',
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_DELIVERY_ACTION_MENU,
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      subject: `Delivery from ${formatDateWithoutDowWithoutYear(date)} - Never arrived`,
    });
  };

  return (
    <ActionSheetV2
      title="Is the delivery at this location?"
      description="Please check the location described below to see if your package is still there"
      analyticsName="assistant safe place confirmation"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {safePlaceInstructions ? (
          <>
            <Row>
              <AltoIcon name="homelock-duo" />
              <XsSpacing />
              <Body
                fontFamily="semibold"
                color={COLORS.TEXT_COLORS.PRIMARY}
              >
                {safePlaceInstructions}
              </Body>
            </Row>
            <XlSpacing />
          </>
        ) : null}
        {imageUrl ? (
          <>
            <ExpandableImage
              accessibilityLabel={IMG_ALT_TEXTS.deliveredDelivery}
              imageUrl={imageUrl}
              height={200}
            />
            <XlSpacing />
          </>
        ) : null}
        <Button
          width="full"
          label="I found my delivery"
          onPress={handleDeliveryFound}
        />
        <Button
          width="full"
          type="tertiary"
          label="Delivery is not there"
          onPress={handleDeliveryNotFound}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
