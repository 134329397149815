import { ANIMATION, type ANIMATION_SPEEDS, COLORS } from '@alto/design-library-tokens';
import { type HTMLAttributes } from 'react';
import styled from 'styled-components';

export type ImageMaskProps = {
  animationSpeed?: ANIMATION_SPEEDS;
  backgroundColor?: string;
  backgroundHover?: string;
  opacity?: number;
  opacityHover?: number;
} & HTMLAttributes<HTMLDivElement>;

export const ImageMask = styled.div<ImageMaskProps>`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  ${({
    animationSpeed = ANIMATION.SPEEDS.DEFAULT,
    backgroundColor = COLORS.PALETTE.GREYSCALE.BLACK_TRANSPARENT,
    backgroundHover,
    opacity = 1,
    opacityHover,
  }: ImageMaskProps) => `
    transition-property: background-color, opacity;
    transition-duration: ${animationSpeed}ms;
    opacity: ${opacity};
    background-color: ${backgroundColor};

    &:hover {
      opacity: ${opacityHover || opacity};
      background-color: ${backgroundHover || backgroundColor};
    }
  `}
`;
