// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDeliveriesForMedication } from './getDeliveriesForMedication';
import { BILLING_ISSUES } from '~shared/constants';
import { getUserCashPayOnly } from '~shared/features/users/selectors/getUserCashPayOnly';

export const getIsNeedsInsuranceForMedication = createSelector(
  [getUserCashPayOnly, getDeliveriesForMedication],
  (isUserCashPayOnly, deliveriesForMedication) => {
    const hasUpcomingDeliveries = deliveriesForMedication.some(({ status }) => status === 'upcoming');

    if (hasUpcomingDeliveries) {
      return false;
    }

    const someNeedInsurance = deliveriesForMedication.some(
      ({ comms_state }) => comms_state === BILLING_ISSUES.needs_insurance,
    );

    return !isUserCashPayOnly && someNeedInsurance;
  },
);
