// @owners { team: patients-team }
import { type AltoIconName, COLORS, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/prefer-composed-typography-components-over-text
import {
  AltoIcon,
  Border,
  Column,
  Description,
  Illustration,
  MdPadding,
  Row,
  TabBarText,
  Tag,
  Text,
  XsSpacing,
  XxsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { memo, useCallback } from 'react';
import { StyleSheet, TouchableHighlight, View } from 'react-native';
import styled from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getItemQuantityForAddOnOtc } from '~shared/features/checkout/helpers';
import { useEssentialPrescriptionByProductID, useEssentialsPreviouslyPurchased } from '~shared/features/essentials';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { formatDollars } from '~shared/helpers/currency';
import { useAddOnsContext } from '../../checkout/components/essentials/AddOnsContext';
import { useEditEssential } from '../../essentials/hooks/useEditEssential';
import { FSABadge } from './FSABadge';

type Props = {
  readonly addOnOtc: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
  readonly isStoreItem?: boolean;
  readonly onCloseActionSheet?: () => void;
};

const TagContainer = styled(View)`
  position: absolute;
  right: ${SPACING.STATIC.MD.px};
  top: ${SPACING.STATIC.MD.px};
  z-index: 100;
`;

type EssentialTagProps = {
  readonly addOnOtc: AddOnOtc;
};

const EssentialTag = memo(function EssentialTagMemo({ addOnOtc }: EssentialTagProps) {
  const { getIconName } = useEssentialsPreviouslyPurchased();
  const visibleTag = addOnOtc.tags;
  const firstTag = addOnOtc.tags?.[0];
  const { name, icon } = getIconName(firstTag);
  const styles = StyleSheet.create({
    container: { opacity: visibleTag ? 1 : 0 },
  });

  return (
    <View style={styles.container}>
      <Tag
        label={name}
        icon={<AltoIcon name={icon as AltoIconName} />}
        type="recommended"
      />
    </View>
  );
});

export const EssentialItem = memo(function EssentialItemMemo({
  addOnOtc,
  analyticsProps,
  isStoreItem,
  onCloseActionSheet,
}: Props) {
  const { navigate } = useNavigation();
  const { shipmentID } = useAddOnsContext();
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: addOnOtc.product_id,
    shipmentID,
  });
  const { onChangeEssential } = useEditEssential({ essential: addOnOtc, shipmentID });

  const onOpenDetails = useCallback(() => {
    if (prescription && shipmentID) {
      onChangeEssential();
      return;
    }
    if (onCloseActionSheet) {
      onCloseActionSheet();
    }

    navigate('RouteEssentialsDetails', { addOnOtc, analyticsProps });
  }, [navigate, addOnOtc, analyticsProps, onCloseActionSheet, onChangeEssential, prescription, shipmentID]);

  const quantity = prescription ? getItemQuantityForAddOnOtc(prescription, addOnOtc.quantity) : 0;

  return (
    <Column centerHorizontally>
      <Border
        radius="LG"
        color={COLORS.BACKGROUND_COLORS.TRANSPARENT}
      >
        <TouchableHighlight
          activeOpacity={1}
          onPress={onOpenDetails}
          underlayColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTER}
        >
          <MdPadding>
            <TagContainer>
              {quantity ? (
                <Tag
                  label={String(quantity)}
                  type="counter"
                  circle
                />
              ) : (
                <Tag
                  icon={
                    <AltoIcon
                      testID="plus-icon"
                      type="secondary"
                      name="plus-small"
                    />
                  }
                  type="action"
                  circle
                />
              )}
            </TagContainer>
            <Row centerHorizontally>
              <Illustration
                size="lg"
                source={{ uri: addOnOtc.image_urls[0] }}
                accessibilityLabel={IMG_ALT_TEXTS.medication}
              />
            </Row>
            {isStoreItem ? <FSABadge addOnOtc={addOnOtc} /> : <EssentialTag addOnOtc={addOnOtc} />}
            <XsSpacing />
            {/* eslint-disable-next-line @alto/prefer-composed-typography-components-over-text */}
            <Text
              textSize="mini"
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.PRIMARY}
              numberOfLines={2}
              selectable={false}
            >
              {addOnOtc.name}
            </Text>
            <XxsSpacing />
            <TabBarText numberOfLines={1}>{addOnOtc.quantity_description}</TabBarText>
            <XxsSpacing />
            <Description
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.PRIMARY}
            >
              {formatDollars(addOnOtc.price)}
            </Description>
          </MdPadding>
        </TouchableHighlight>
      </Border>
    </Column>
  );
});
