import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components/native';
import { Text, type TextProps } from './Text';

export type TagTextProps = Pick<TextProps, 'color' | 'children'> & { readonly variant?: 'small' | 'default' };

const StyledTagText = styled(Text)<TagTextProps>`
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.MD.px};
  line-height: ${TYPOGRAPHY.TEXT.DETAIL.MD.value * TYPOGRAPHY.LINE_HEIGHT.LG}px;
  letter-spacing: ${({ variant }) => {
    return variant === 'small' ? TYPOGRAPHY.LETTER_SPACING.MD.px : TYPOGRAPHY.LETTER_SPACING.LG.px;
  }};
  text-transform: ${({ variant }) => {
    return variant === 'small' ? 'none' : 'uppercase';
  }};
  overflow: hidden;
  flex-shrink: 1;
`;

const StyledFilterTagText = styled(StyledTagText)`
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  line-height: ${TYPOGRAPHY.TEXT.DETAIL.LG.value * TYPOGRAPHY.LINE_HEIGHT.LG}px;
`;

export const TagText = ({ color = COLORS.TEXT_COLORS.PRIMARY, children, variant }: TagTextProps) => {
  return (
    <StyledTagText
      center
      fontFamily="bold"
      color={color}
      variant={variant}
      numberOfLines={1}
      ellipsizeMode="tail"
      white-space="nowrap"
      maxFontSizeMultiplier={1.4}
    >
      {children}
    </StyledTagText>
  );
};

export const FilterTagText = ({ color = COLORS.TEXT_COLORS.PRIMARY, children, variant }: TagTextProps) => {
  return (
    <StyledFilterTagText
      center
      fontFamily="bold"
      color={color}
      variant={variant}
      numberOfLines={1}
      ellipsizeMode="tail"
      white-space="nowrap"
      maxFontSizeMultiplier={1.4}
    >
      {children}
    </StyledFilterTagText>
  );
};
