import { type COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

const StyledSpacing = styled(View)<{
  size:
    | typeof SPACING.STATIC.XXS.px
    | typeof SPACING.STATIC.XS.px
    | typeof SPACING.STATIC.SM.px
    | typeof SPACING.STATIC.MD.px
    | typeof SPACING.STATIC.LG.px
    | typeof SPACING.STATIC.XL.px
    | typeof SPACING.STATIC.XXL.px
    | typeof SPACING.STATIC.XXXL.px
    | typeof SPACING.STATIC.XXXXL.px
    | typeof SPACING.STATIC.XXXXXL.px;
}>`
  ${({ size }) => `
  height: ${size};
  width: ${size};
  `}
`;

export const XxsSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XXS.px} />;
};

export const XsSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XS.px} />;
};

export const SmSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.SM.px} />;
};

export const MdSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.MD.px} />;
};

export const LgSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.LG.px} />;
};

export const XlSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XL.px} />;
};

export const XxlSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XXL.px} />;
};

export const XxxlSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XXXL.px} />;
};

export const XxxxlSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XXXXL.px} />;
};

export const XxxxxlSpacing = () => {
  return <StyledSpacing size={SPACING.STATIC.XXXXXL.px} />;
};

const BottomSafeView = styled(SafeAreaView)<{
  color?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
}>`
  background-color: ${({ color }) => color ?? ''};
`;

export const EdgelessScreenHomeButton = ({
  color,
}: {
  readonly color?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
}) => (
  <BottomSafeView
    edges={['bottom']}
    color={color}
  />
);

export type Spacings =
  | typeof XxsSpacing
  | typeof XsSpacing
  | typeof MdSpacing
  | typeof LgSpacing
  | typeof XlSpacing
  | typeof XxlSpacing
  | typeof XxxlSpacing
  | typeof XxxxlSpacing
  | typeof XxxxxlSpacing;
