// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type BaseIconName } from '@alto/design-library-tokens';
import { isAfter, parseISO } from 'date-fns';
import { type HomeToSignConfirmation, type Item } from '~shared/features/checkout/types';
import {
  type Instructions,
  leavePackageInstruction,
  photoIDRequiredInstruction,
  pickUpInstruction,
  presenceRequiredInstruction,
  signatureRequiredInstruction,
} from '~shared/features/delivery-info/constants';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { formatFullAddress, getAddressOneLiner } from '~shared/helpers/address';
import { formatDeliveredAt, formatRelativeDateWithDow, formatWindow, formatWindowTime } from '~shared/helpers/date';
import { getIcon } from '~shared/helpers/delivery';
import { isExpensive } from '~shared/helpers/helper';
import { STATUSES_TO_SHOW_MAIL_CARRIER, isCourier, isMail, isPickup } from '~shared/helpers/order';
import { type Address } from '~shared/types';

type IconRowProps = {
  bold?: boolean;
  danger?: boolean;
  icon?: string;
  success?: boolean;
  text?: string;
  warning?: boolean;
};

function getMethodString(method: DeliveryMethod, status: APIOrderStatus | null | undefined) {
  if (isMail(method?.mode) && !STATUSES_TO_SHOW_MAIL_CARRIER.has(status)) {
    return 'mail';
  }
  let methodString = method?.patient_facing_name;
  if (isCourier(method?.mode)) {
    methodString = methodString.toLowerCase();
  }
  return methodString;
}

export const getDeliveryMethod = (
  method: DeliveryMethod | null | undefined,
  showMethod: boolean,
  status: APIOrderStatus | null | undefined,
  isOrderPickupMethod?: boolean,
): { iconName: BaseIconName; text: string } => {
  if (!method || !showMethod) {
    return { iconName: 'box', text: '' };
  }

  const isPickupMethod = isPickup(method?.mode) || !!isOrderPickupMethod;
  const isCourierMethod = isCourier(method?.mode) && !isOrderPickupMethod;
  const isMailMethod = isMail(method?.mode) && !isOrderPickupMethod;
  const methodString = getMethodString(method, status);

  if (!methodString) {
    return { iconName: 'box', text: '' };
  }

  switch (status) {
    case 'delivered':
      return {
        iconName: getIcon(method.name),
        text: isPickupMethod ? 'Picked up' : `Delivered via ${methodString}`,
      };

    case 'failed':
      return {
        iconName: 'alert',
        text: `Failed delivery (${methodString})`,
      };

    default:
      if (isPickupMethod) {
        return {
          iconName: 'handbox',
          text: 'Pick up order',
        };
      }
      return {
        iconName: isCourierMethod ? 'car' : 'box',
        text: isMailMethod ? `Ships via ${methodString}` : `Arrives via ${methodString}`,
      };
  }
};

type GetDeliveryDateTextProps = {
  deliverAfter: string | null | undefined;
  deliverBefore: string | null | undefined;
  date: string | null | undefined;
  method: DeliveryMethod | null | undefined;
  isOnDemand?: boolean;
  isAsapDelivery?: boolean;
};

export const getDeliveryDateText = ({
  deliverAfter,
  deliverBefore,
  date,
  method,
  isOnDemand,
  isAsapDelivery,
}: GetDeliveryDateTextProps) => {
  if (!date) return '';

  const dateText = formatRelativeDateWithDow(date);
  const today = new Date();

  if (isPickup(method?.mode) && isAfter(today, parseISO(date))) {
    return `Ready on ${dateText}`;
  }

  if (isAsapDelivery && deliverBefore) {
    return `${dateText}, by ${formatWindowTime({ windowTime: deliverBefore, meridiem: 'aaa' })} (estimated)`;
  }

  if ((!deliverBefore || !deliverAfter) && date) {
    const formattedDate = formatRelativeDateWithDow(date);
    return method?.min_transit_days !== method?.max_transit_days ? `Expected ${formattedDate}` : formattedDate;
  }

  if (isCourier(method?.mode)) {
    return isOnDemand ? `${dateText}, ASAP` : `${dateText} ${formatWindow(deliverAfter, deliverBefore)}`;
  }

  return dateText;
};

export const getDeliveredAtText = (date: string | null | undefined) => {
  if (!date) {
    return null;
  }
  return formatDeliveredAt(date);
};

export function getFormattedAddress_deprecated(address: Address | undefined): IconRowProps | null | undefined {
  if (!address?.street_address_1) {
    return null;
  }

  return {
    icon: 'map-marker-alt',
    text: getAddressOneLiner(address),
  };
}

export const getFormattedAddress = (address: Address | null | undefined) => {
  if (!address) return 'Address deleted';
  return formatFullAddress(address);
};

type GetInstructionsProps = {
  address: Address | undefined;
  homeToSignConfirmation: HomeToSignConfirmation | null | undefined;
  items: Item[];
  method: DeliveryMethod | null | undefined;
  status: string;
  use3000Threshold: boolean;
  courierAgeRestrictionEnabled: boolean;
};
export const getInstructions = ({
  address,
  homeToSignConfirmation,
  items,
  method,
  status,
  use3000Threshold,
  courierAgeRestrictionEnabled,
}: GetInstructionsProps): Instructions | null => {
  if (!address || ['delivered', 'failed'].includes(status)) {
    return null;
  }

  if (isPickup(method?.mode)) {
    return pickUpInstruction;
  }

  const prescriptions = items.map((i) => i.prescription);

  if (items.some((i) => i.isSignatureRequired)) {
    return courierAgeRestrictionEnabled ? photoIDRequiredInstruction : signatureRequiredInstruction;
  }

  if (isExpensive(prescriptions, use3000Threshold) && homeToSignConfirmation === 'CONFIRM') {
    return signatureRequiredInstruction;
  }

  if (isMail(method?.mode)) {
    return null;
  }

  if (!address.safe_place) {
    return presenceRequiredInstruction;
  }

  return leavePackageInstruction;
};
