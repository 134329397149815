import getCSRF from '~shared/helpers/getCSRF';
import getDeviceID from '~shared/helpers/getDeviceID';
import platform from '~shared/helpers/getPlatform';
import version from '~shared/helpers/getVersion';

export const buildHeaders = (requestID: string) => {
  const result: Record<string, any> = {
    'Content-Type': 'application/json',
    'x-request-id': requestID,
    // Platform header here will cue the different lifetimes of tokens that may be
    // issued by the backend. Native apps get much longer sessions than web apps.
    'x-platform': platform,
    'x-app-version': version,
    'x-device-info-id': getDeviceID(),
  };

  const CSRFToken = getCSRF();
  if (CSRFToken) {
    result['x-csrf-token'] = CSRFToken;
  }

  return result;
};
