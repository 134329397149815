// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Column,
  H1,
  InputBlockRadio,
  LoadingButton,
  Modal,
  ModalContent,
  RadioOption,
  Text,
  createValidator,
  palette,
  spacing,
  validatePresent,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { type VerificationCommsChannel } from '~shared/actions/verifications';
import {
  RESEND_VERIFICATION_TITLE,
  VERIFICATION_CTA_LOADING_TEXT,
  VERIFICATION_CTA_TEXT,
  VERIFICATION_MULTIPLE_OPTIONS_BODY,
  VERIFICATION_OPTIONS_TEXT,
  VERIFICATION_TITLE,
} from '~shared/features/onboarding/constants';
import { type Option } from '~shared/features/onboarding/selectors/getOnboardingVerification';
import { getErrorMessage } from '~shared/helpers/helper';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { type APIError } from '~shared/types';
import { ButtonWrapper } from '~web/components/ButtonWrapper';

const Title = styled(H1)`
  color: ${palette.navy};
  text-align: center;
`;

const Buttons = styled(ButtonWrapper)`
  justify-content: center;
`;

const Content = styled(Column)`
  text-align: left;
`;

const validate = createValidator({
  channel: (value) => {
    if (validatePresent(value)) {
      return 'Please select a verification method';
    }

    return null;
  },
});

export type Values = {
  channel: VerificationCommsChannel | null | undefined;
};

type Props = {
  readonly error: APIError | null | undefined;
  readonly loading: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (arg0: Values) => void;
  readonly options: Option[];
  readonly resend?: boolean;
};

export default function VerificationOptionsModal({ error, loading, onClose, onSubmit, options, resend }: Props) {
  const radioOptions = options.map((option) => {
    const { channel, contact } = option;
    const optionText = VERIFICATION_OPTIONS_TEXT(channel, contact);
    return (
      <RadioOption
        value={channel}
        key={channel}
      >
        <Text>{optionText}</Text>
      </RadioOption>
    );
  });

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Column spacing={spacing.smSpacing}>
          <Title>{resend ? RESEND_VERIFICATION_TITLE : VERIFICATION_TITLE}</Title>
          <Formik
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              channel: null,
            }}
          >
            <Form noValidate>
              <Content spacing={spacing.smSpacing}>
                <Text>{VERIFICATION_MULTIPLE_OPTIONS_BODY}</Text>
                <Field name="channel">
                  {({ field, meta }: FieldProps<string>) => (
                    <InputBlockRadio
                      required
                      input={field}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      {radioOptions}
                    </InputBlockRadio>
                  )}
                </Field>
                {!!error && <Text danger>{getErrorMessage(error)}</Text>}
                <Buttons>
                  <LoadingButton
                    loading={loading}
                    loadingText={VERIFICATION_CTA_LOADING_TEXT}
                    type="submit"
                  >
                    {VERIFICATION_CTA_TEXT}
                  </LoadingButton>
                </Buttons>
              </Content>
            </Form>
          </Formik>
        </Column>
      </ModalContent>
    </Modal>
  );
}
