// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';

export const getOnboarding = (state: ReduxStateShared) => state.onboarding;

export const getTempPassword = createSelector(getOnboarding, (onboarding) => onboarding?.tempPassword);

export const getOnboardingInsuranceID = createSelector(getOnboarding, (onboarding) => onboarding?.insuranceID);
export const getEditingOnboardingInsuranceID = createSelector(
  getOnboarding,
  (onboarding) => onboarding?.editingInsuranceID,
);

export const getInsuranceLoadingSeen = createSelector(
  getOnboarding,
  (onboarding) => !!onboarding?.insuranceLoadingSeen,
);
