import {
  DeliveriesEndpoint,
  type DeliveriesEndpointNextDeliveryDatesIfCancelledResponse,
} from '@alto/scriptdash/alto/patient_app/deliveries/v1/deliveries_endpoint';
import { useQuery } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';
import { type DeliveryWithPrescription } from '~shared/types';

const deliveriesEndpoint = DeliveriesEndpoint(apiEndpointHandler);

/**
 * Get next delivery dates for each delivery should they end up being cancelled
 *
 * @param deliveries - DeliveryWithPrescription[]
 * @returns nextDeliveryDatesIfCancelled - NextDeliveryDateIfCancelledData[]
 * @returns isLoading - boolean for when loading
 * @returns error - error
 */
export const useGetNextDeliveryDatesIfCancelled = (deliveries: DeliveryWithPrescription[]) => {
  const delivery_ids = deliveries.map((delivery) => delivery.id);

  const filterAndSortNextDeliveryDates = (data: DeliveriesEndpointNextDeliveryDatesIfCancelledResponse) => {
    const deliveriesById = deliveries.reduce((map: Record<number, DeliveryWithPrescription>, delivery) => {
      map[delivery.id] = delivery;
      return map;
    }, {});
    return (data.data?.next_delivery_dates_if_cancelled || [])
      .filter((nextDate) => {
        const delivery = deliveriesById[nextDate.delivery_id];

        return !!nextDate.next_delivery_date_if_cancelled && !!delivery?.medication_name;
      })
      .sort((nextDate1, nextDate2) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const medName1 = deliveriesById[nextDate1.delivery_id].medication_name!.toLowerCase();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const medName2 = deliveriesById[nextDate2.delivery_id].medication_name!.toLowerCase();
        if (medName1 < medName2) {
          return -1;
        }
        if (medName1 > medName2) {
          return 1;
        }
        return 0;
      });
  };

  const { data, isPending, error } = useQuery({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['next-delivery-dates-if-cancelled', delivery_ids],
    queryFn: () => {
      return deliveriesEndpoint.nextDeliveryDatesIfCancelled({ delivery_ids }, { splitArrayParams: true });
    },
    select: filterAndSortNextDeliveryDates,
    enabled: deliveries.length > 0,
  });

  return { nextDeliveryDatesIfCancelled: data, isPending, error };
};
