// @owners { team: patients-team }
import { ActionSheetContext, Button } from '@alto/design-system';
import { HolidayClosureAlert } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexRow, Link, Modal, ModalContent, ModalFooter, Paragraph, Row, spacing } from '@alto/pocky';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { BUSINESS_HOURS, MESSAGE_RESPONSE_TIME } from '~shared/features/messages/constants';
import Image from '~web/components/Image';
import { phoneSupport } from '~web/constants';
import { HolidayClosureActionSheet } from '~web/features/alto-assistant/components/action-sheets/HolidayClosureActionSheet';
import img from '~web/images/secureMessaging@2x.png';

type Props = {
  readonly showMessagesInfoModal?: boolean;
  readonly closeMessagesInfoModal: () => void;
};

export const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  white-space: pre-line;
`;

export const MessagesInfoModal = ({ showMessagesInfoModal, closeMessagesInfoModal }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  return showMessagesInfoModal ? (
    <StyledModal
      showing={showMessagesInfoModal}
      onClose={closeMessagesInfoModal}
      title="Business Hours"
    >
      <ModalContent textAlignment="left">
        <Image
          center
          src={img}
          alt="Illustration of phone with messages"
        />
        <HolidayClosureAlert
          onPress={() => {
            setActiveActionSheet(<HolidayClosureActionSheet />);
          }}
        />
        <FlexRow spacing={spacing.mdSpacing}>
          <Paragraph>
            We typically respond within {MESSAGE_RESPONSE_TIME} during business hours. If this is an urgent matter,
            please call us at <Link href={`tel:${phoneSupport}`}>{phoneSupport}</Link>.
          </Paragraph>
          <StyledParagraph>{BUSINESS_HOURS}</StyledParagraph>
        </FlexRow>
      </ModalContent>
      <ModalFooter>
        <Row horizontallyAlignContent>
          <Button onPress={closeMessagesInfoModal}>Got It</Button>
        </Row>
      </ModalFooter>
    </StyledModal>
  ) : null;
};
