// @owners { team: patients-team }
import { closeModal } from '~shared/actions/modal';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import CommunicationPreferencesModal from './CommunicationPreferencesModal';

const mapStateToProps = (state: ReduxStateShared) => {
  const { modalType, show } = state.modal;
  return {
    modalType,
    show,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    closeModal: () => {
      dispatch(closeModal('COMMUNICATION_PREFERENCES_MODAL'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferencesModal);
