// @owners { team: patients-team }
import React from 'react';
import styled from 'styled-components/native';
import { IMAGE_DIMENSIONS } from './constants';

const { width, height } = IMAGE_DIMENSIONS;

const StyledImage = styled.Image`
  width: 100%;
  object-fit: contain;
  aspect-ratio: ${width / height};
  max-width: ${width}px;
  max-height: ${height}px;
`;

export const MedSyncCalendarImage = () => (
  <StyledImage
    accessibilityLabel="Illustration of a calendar."
    testID="medsync-calendar-image"
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    source={require('./medsync_calendar@2x.png')}
  />
);
