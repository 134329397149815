// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React from 'react';

export const ControlledMedicationActionSheet = () => (
  <ActionSheetV2 title="Controlled medication">
    <LgPadding
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      <Body>
        Controlled medications require a signature upon delivery. There are restrictions on how controlled medications
        can be filled because they are regulated by the government based on how likely they are for abuse and
        dependence.
      </Body>
    </LgPadding>
  </ActionSheetV2>
);
