import { type AltoIconName, type BaseIconName, type ColorVariant, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Spin } from '../../../animations';
import { AltoIconButton } from '../../../buttons';
import type { RowProps } from '../../../containers/src/Flex';
import { Column, Row } from '../../../containers/src/Flex';
import { AltoIcon, ThirdPartyIcon, type ThirdPartyIconName } from '../../../icon';
import { XsSpacing, XxsSpacing } from '../../../separators';

const IconWrapper = styled(Row).attrs({
  flexGrow: 1,
  flexShrink: 0,
})<RowProps>`
  height: 100%;
`;

export type LeftIconProps = {
  readonly name?: AltoIconName | ThirdPartyIconName;
  readonly type?: ColorVariant;
  readonly error?: string;
  readonly isFocused?: boolean;
};

export const LeftIcon = ({ name, type, error, isFocused }: LeftIconProps) => {
  return (
    <IconWrapper center>
      {name ? (
        <>
          <XsSpacing />
          {name === 'googlepay' || name === 'applepay' ? (
            <ThirdPartyIcon name={name} />
          ) : (
            <AltoIcon
              name={name as AltoIconName}
              type={error && !isFocused ? 'danger' : type}
            />
          )}
          <XsSpacing />
        </>
      ) : (
        <XsSpacing />
      )}
    </IconWrapper>
  );
};

const mdHitSlop = {
  top: SPACING.STATIC.MD.value,
  right: SPACING.STATIC.MD.value,
  bottom: SPACING.STATIC.MD.value,
  left: SPACING.STATIC.MD.value,
};

export type RightIconProps = {
  readonly name?: AltoIconName;
  readonly buttonType?: 'primary' | 'disabled';
  readonly type?: Exclude<ColorVariant, 'disabled'>;
  readonly onPress?: () => void;
  readonly spin?: boolean;
  readonly accessibilityLabel?: string;
  readonly hasValue: boolean;
  readonly isFocused: boolean;
  readonly hideClearButton: boolean;
  readonly setShouldRedactInput: (arg0: boolean) => void;
  readonly shouldRedactInput?: boolean;
  readonly secureTextEntry: boolean | null | undefined;
  readonly handleClearValue: () => void;
  /**
   * By default, the RightIcon is positioned center to the input.
   * Sometimes, we'd want the RightIcon anchored to the bottom of the input,
   * like the "send" AltoIconButton in InputMessage.
   */
  readonly position?: 'center' | 'bottom';
};

export const RightIcon = ({
  name,
  type,
  onPress,
  accessibilityLabel,
  hasValue,
  isFocused,
  hideClearButton,
  setShouldRedactInput,
  shouldRedactInput,
  secureTextEntry,
  handleClearValue,
  buttonType,
  spin,
  position = 'center',
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: RightIconProps) => {
  let rightIcon;

  // intentionally not using PressableAltoIcon in these cases because we don't want duo icons on press
  // and want to be able to use small icons if needed
  if (secureTextEntry) {
    rightIcon = (
      <Pressable
        onPress={() => {
          setShouldRedactInput(!shouldRedactInput);
        }}
        accessibilityLabel={shouldRedactInput ? 'Press to show password text' : 'Press to hide password text'}
        hitSlop={mdHitSlop}
      >
        <AltoIcon
          name={shouldRedactInput ? 'eyeopen' : 'eyeclosed'}
          type="grey"
        />
      </Pressable>
    );
  } else if (hasValue && isFocused && !hideClearButton && !name) {
    rightIcon = (
      <Pressable
        onPress={handleClearValue}
        accessibilityLabel="Press to clear text input"
        hitSlop={mdHitSlop}
      >
        <AltoIcon
          name="cancel-small"
          type="grey"
        />
      </Pressable>
    );
  } else if (name && onPress) {
    if (buttonType) {
      const iconName = name.replace('-small', '') as BaseIconName;
      rightIcon = (
        <AltoIconButton
          onPress={onPress}
          accessibilityLabel={accessibilityLabel || ''}
          small
          iconName={iconName}
          disabled={buttonType === 'disabled'}
        />
      );
      if (spin) {
        rightIcon = <Spin>{rightIcon}</Spin>;
      }
      if (position === 'bottom') {
        rightIcon = (
          <Column>
            {rightIcon}
            <XxsSpacing />
            <XxsSpacing />
          </Column>
        );
      }
    } else {
      rightIcon = (
        <Pressable
          onPress={onPress}
          accessibilityLabel={accessibilityLabel || ''}
          hitSlop={mdHitSlop}
        >
          <AltoIcon
            name={name}
            type={type}
          />
        </Pressable>
      );
    }
  } else if (name) {
    rightIcon = (
      <AltoIcon
        name={name}
        type={type}
      />
    );
  }

  return rightIcon ? (
    <IconWrapper
      center={position === 'center'}
      bottom={position === 'bottom'}
    >
      <XsSpacing />
      {rightIcon}
      <XsSpacing />
    </IconWrapper>
  ) : (
    <XsSpacing />
  );
};
