// @owners { team: patients-team }
import { openOriginModal } from '~shared/actions/modal';
import { connect } from '~shared/store';
import InfoModal from '~web/features/courier-tips/components/InfoModal';

type DispatchProps = {
  onClose: () => void;
};

const mapDispatchToProps: DispatchProps = {
  onClose: openOriginModal,
};

const CourierTipsInfoModal = connect(null, mapDispatchToProps)(InfoModal);

export default CourierTipsInfoModal;
