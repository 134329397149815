// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Description,
  InlineAlert,
  LgPadding,
  LgSpacing,
  MdSpacing,
  Row,
  SmSpacing,
} from '@alto/design-system';
import { DeliveryWaivedFeeWarning, useAddOnsContext, useQueryEssentialsFeeConfigs } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getItemQuantityForAddOnOtc } from '~shared/features/checkout/helpers';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { useEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { getUpdatedEssentialQuantityIsBelowMinimum } from '~shared/features/pricing/selectors/getEssentialsOnlyOrderBelowMinimum';
import { useEssentialsDeliveryWaiveFeeWarning } from '~shared/features/same-day-delivery/hooks/useEssentialsDeliveryWaiveFeeWarning';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { ProductQuantityToggle } from '~web/features/essentials/components/ProductQuantityToggle';
import { ProductUpdateButton } from '~web/features/essentials/components/ProductUpdateButton';
import { Text } from '~web/features/essentials/primitives';

type Props = {
  readonly essential: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
};

export const ProductItemUpdateActionSheet = ({ essential, analyticsProps }: Props) => {
  const { trackPageView } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { shipmentID, origin } = useAddOnsContext();
  const [showMinimumWarning, setShowMinimumWarning] = useState(false);
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: essential.product_id,
    shipmentID,
  });
  const initialQuantity = prescription ? getItemQuantityForAddOnOtc(prescription, essential.quantity) : 1;
  const [quantity, setQuantity] = useState(initialQuantity);
  const { essentialsMinimumBasket } = useQueryEssentialsFeeConfigs();
  const quantityChanged = quantity !== initialQuantity;
  const minimumBasketThreshold = essentialsMinimumBasket?.value;
  const updatedQuantityBelowMinimum = useSelectorShared((state: ReduxStateShared) =>
    shipmentID
      ? getUpdatedEssentialQuantityIsBelowMinimum(state, {
          id: shipmentID,
          initialQuantity,
          updatedQuantity: quantity,
          essentialPrice: essential.price,
          minimumThreshold: minimumBasketThreshold || 0,
        })
      : false,
  );
  const { message, showDeliveryWaiveFeeWarning } = useEssentialsDeliveryWaiveFeeWarning({
    essential,
    initialQuantity,
    quantity,
    quantityChanged,
    shipmentID,
  });

  useEffect(() => {
    trackPageView({
      event: EVENTS.ESSENTIALS__UPDATE_ACTION_SHEET_VIEWED,
      params: {
        shipment_id: shipmentID,
        origin,
      },
    });
  }, [origin, shipmentID, trackPageView]);

  const handleQuantityChange = useCallback(
    (type: 'increase' | 'decrease') => () => {
      setQuantity((p) => Math.max(p + (type === 'increase' ? 1 : -1), 0));
    },
    [],
  );
  const handleQuantityClick = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    event.stopPropagation();
  };
  const handleQuantityPress = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    setQuantity(Number(event.currentTarget.value));
  };

  useEffect(() => {
    const shouldShowWarning = quantityChanged && Boolean(shipmentID) && updatedQuantityBelowMinimum;
    if (shouldShowWarning !== showMinimumWarning) {
      setShowMinimumWarning(shouldShowWarning);
    }
  }, [updatedQuantityBelowMinimum, quantityChanged, shipmentID, showMinimumWarning]);

  return (
    <ActionSheetV2
      title={`Edit ${essential.name}`}
      analyticsName="essentials product update"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.SM}
      >
        {!prescription?.is_upcoming_delivery_paid_by_insurance && (
          <Row
            centerVertically
            spaceBetween
          >
            <Row>
              <AltoIcon name="pillbottle" />
              <MdSpacing />
              <Text
                title="Quantity"
                color="PRIMARY"
              />
            </Row>
            <Row>
              <ProductQuantityToggle
                itemQuantity={quantity}
                onIncrease={handleQuantityChange('increase')}
                onDecrease={handleQuantityChange('decrease')}
                onQuantityClick={handleQuantityClick}
                onQuantityPress={handleQuantityPress}
                prescription={prescription}
              />
              <SmSpacing />
            </Row>
          </Row>
        )}
        <LgSpacing />
        {showDeliveryWaiveFeeWarning ? <DeliveryWaivedFeeWarning message={message} /> : null}
        {showMinimumWarning ? (
          <>
            <InlineAlert type="warning">
              <Description>{`A ${essentialsMinimumBasket?.display_value} minimum subtotal applies to deliveries of Essentials`}</Description>
            </InlineAlert>
            <LgSpacing />
          </>
        ) : null}
        <Row centerHorizontally>
          <ProductUpdateButton
            essential={essential}
            analyticsProps={analyticsProps}
            itemQuantity={quantity}
            onClose={closeActionSheet}
            disabled={!quantityChanged || showMinimumWarning}
            onDelete={closeActionSheet}
            showPrice={false}
          />
        </Row>
      </LgPadding>
    </ActionSheetV2>
  );
};
