// @owners { team: patients-team }
/** @format */
import pluralize from 'pluralize';
import { PRESCRIPTION_STATES } from '~shared/constants';
import { type PrescriptionState } from '~shared/types';

export const ACTIONABLE_PRESCRIPTION_STATES: PrescriptionState[] = [
  PRESCRIPTION_STATES.ACTIVE,
  PRESCRIPTION_STATES.ACTIVE_RENEWED,
  PRESCRIPTION_STATES.EXPIRED,
  PRESCRIPTION_STATES.NO_REFILLS,
  PRESCRIPTION_STATES.TOO_EARLY,
];

export const ARCHIVABLE_PRESCRIPTION_STATES: PrescriptionState[] = [
  PRESCRIPTION_STATES.ACTIVE,
  PRESCRIPTION_STATES.ACTIVE_RENEWED,
  PRESCRIPTION_STATES.ARCHIVED,
  PRESCRIPTION_STATES.EXPIRED,
  PRESCRIPTION_STATES.NO_REFILLS,
  PRESCRIPTION_STATES.RENEWED,
  PRESCRIPTION_STATES.TOO_EARLY,
];

export const MULTIPLE_ACTIVE_RX_READY = 'multiple_active_rx_ready';
export const MULTIPLE_ACTIVE_RX = 'multiple_active_rx';
export const RX_READY = 'rx_ready';
export const PROCESSING = 'processing';
export const SCHEDULED = 'scheduled';
export const RX_EXPIRED = 'rx_expired';
export const RENEWAL_PENDING = 'renewal_pending';
export const OUT_OF_FILLS = 'out_of_fills';
export const ARCHIVED = 'archived';
export const DISCONTINUED = 'discontinued';
export const TRANSFERRED_OUT = 'transferred_out';
export const RENEWED = 'renewed';
export const MULTIPLE_RX = 'multiple_rx';
export const NEEDS_INSURANCE = 'needs_insurance';
export const PROCESSING_INSURANCE = 'processing_insurance';
export const MULTIPLE_PRICES = 'multiple_prices';

export const PRICING_INFO = {
  when_charged: 'When will I get charged?',
  how_estimated: 'How is this estimated?',
  wont_be_charged_until: "Your card won't be charged until we've verified your order and confirmed your price.",
  // @ts-expect-error TS(7006): Parameter 'count' implicitly has an 'any' type.
  cannot_bill_automatically: (count) =>
    `Our team is hard at work finalizing the ${pluralize('price', count)} for your ${pluralize('medication', count)}.`,
  // @ts-expect-error TS(7006): Parameter 'count' implicitly has an 'any' type.
  price_estimated: (count) =>
    `The price estimate shown is based on what you paid for your last fill of ${pluralize('this', count)} ${pluralize(
      'medication',
      count,
    )}. Our team will confirm the final price with you as soon as it's available.`,
  price_unavailable: "We'll reach out to you for final confirmation once the price is available.",
};

export const CART_UPDATED_LOCATION = Object.freeze({
  CHECKOUT: 'checkout',
  AUTO_REFILL: 'auto refill',
  NON_SCHEDULING: 'non scheduling',
} as const);
export type CartUpdatedLocation = (typeof CART_UPDATED_LOCATION)[keyof typeof CART_UPDATED_LOCATION];

export const cartUpdatedCopyMapping = {
  [CART_UPDATED_LOCATION.CHECKOUT]: {
    title: 'Sorry, a medication has changed in your cart.',
    body: 'We’re unable to continue this order because one or more medications have changed since you added them to your cart.',
    buttonText: 'Back to My Meds',
  },
  [CART_UPDATED_LOCATION.AUTO_REFILL]: {
    title: 'Sorry, this Auto Refill medication has changed.',
    body: 'We’re unable to continue enrolling this medication in Auto Refill as it is no longer eligible.',
    buttonText: 'Back to Auto Refills',
  },
  [CART_UPDATED_LOCATION.NON_SCHEDULING]: {
    title: 'Sorry, a medication has changed in your cart.',
    body: 'Just a heads up that we had to remove one or more medications from your cart because they had been changed.',
    buttonText: 'Got it',
  },
};
