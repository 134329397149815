import getIsAuthorized from '~shared/selectors/auth/getIsAuthorized';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import Page from './Page';

const mapStateToProps = (state: ReduxStateShared) => {
  return {
    authorized: getIsAuthorized(state),
  };
};

export default connect(mapStateToProps, null)(Page);
