// @owners { team: patients-team }
import { InputText } from '@alto/design-system';
import React from 'react';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  value: string;
};

export const InsuranceMemberIdInput = ({ error, onChange, value }: Props) => {
  return (
    <InputText
      autoCapitalize="none"
      autoCorrect={false}
      keyboardType="default"
      caption="Can also be ID Number or Enrollee ID"
      error={error}
      id="member_id"
      label="Member ID"
      onChangeText={onChange}
      placeholder="00000000"
      required
      value={value}
    />
  );
};
