// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  FlexRow,
  SimpleContainer,
  SimpleContainerHeader,
  SimpleContainerSection,
  StaticTextBlock,
  spacing,
} from '@alto/pocky';
import React, { PureComponent } from 'react';
import { type ModalType } from '~shared/actions/modal';
import { PREFERRED_COMMUNICATION_OPTIONS, secondaryCommString } from '~shared/helpers/communicationPreferences';
import { formatPhone } from '~shared/helpers/helper';
import { type User } from '~shared/types';
import CommunicationPreferencesModal from './CommunicationPreferencesModalContainer';
import { ContactInfoModal } from './ContactInfoModal';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Contact & Login Info',
  },
];

type Props = {
  readonly openModal: (name: ModalType) => void;
  readonly user: User;
};

export default class ProfileContactInfo extends PureComponent<Props> {
  renderContactInfo() {
    const { user } = this.props;

    return (
      <>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Phone number"
            input={{
              value: formatPhone(user.phone || '') || 'No phone number on file',
            }}
          />
        </SimpleContainerSection>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Alternate number"
            input={{
              value: formatPhone(user.secondary_contact_phone || '') || 'No secondary phone on file',
            }}
          />
        </SimpleContainerSection>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Email & login"
            input={{
              value: user.email || '',
            }}
          />
        </SimpleContainerSection>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Password"
            input={{
              value: '************',
            }}
          />
        </SimpleContainerSection>
      </>
    );
  }

  renderCommunicationPreferencesInfo() {
    const { user } = this.props;
    const commOption =
      PREFERRED_COMMUNICATION_OPTIONS.find((option) => option.value === user.preferred_communication) || {};
    const secondary = secondaryCommString(
      // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
      commOption.value,
      user.phi_auth_info,
      user.secondary_communication_preference,
    );
    return (
      <SimpleContainerSection>
        <StaticTextBlock
          label="Preferred contact method"
          input={{
            // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
            value: commOption.label ? `${commOption.label} ${secondary}` : 'unspecified',
          }}
        />
      </SimpleContainerSection>
    );
  }

  render() {
    const { openModal } = this.props;

    return (
      <PageBackground>
        <FlexRow spacing={spacing.mdSpacing}>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <PageHeader>Contact & Login Info</PageHeader>
          <SimpleContainer>
            <SimpleContainerHeader
              linkText="Edit"
              onClick={() => {
                openModal('CONTACT_INFO_MODAL');
              }}
            >
              Phone Number &amp; Login
            </SimpleContainerHeader>
            {this.renderContactInfo()}
          </SimpleContainer>
          <SimpleContainer>
            <SimpleContainerHeader
              linkText="Edit"
              onClick={() => {
                openModal('COMMUNICATION_PREFERENCES_MODAL');
              }}
            >
              Communication Preferences
            </SimpleContainerHeader>
            {this.renderCommunicationPreferencesInfo()}
          </SimpleContainer>
        </FlexRow>
        <ContactInfoModal />
        <CommunicationPreferencesModal />
      </PageBackground>
    );
  }
}
