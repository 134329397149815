import { EventTypes } from 'redux-segment';

export type ModalType =
  | 'ADDING_NEEDLES_MODAL'
  | 'ADDRESS_MODAL'
  | 'ALLERGIES_MODAL'
  | 'ALTERNATIVE_MEDICATION_MODAL'
  | 'ARCHIVE_PRESCRIPTION_MODAL'
  | 'AUTO_REFILL'
  | 'CANCEL_DELIVERY_MODAL'
  | 'CLARIFICATION_MODAL'
  | 'COMMUNICATION_PREFERENCES_MODAL'
  | 'CONTACT_INFO_MODAL'
  | 'CONTACT_VERIFICATION'
  | 'COURIER_TIPS_CREDIT_CARD_MODAL'
  | 'COURIER_TIPS_INFO_MODAL'
  | 'CREDIT_CARD_MODAL'
  | 'CROSS_SELL_MODAL'
  | 'EDIT_OR_ADD_TIP_MODAL'
  | 'GET_THE_APP_DRAWER'
  | 'GET_UPDATES_MODAL'
  | 'INSURANCE_INPUT_INFO_MODAL'
  | 'INSURANCE_MODAL'
  | 'MEDICAL_INFO_MODAL'
  | 'MEDICATION_ALTERNATIVE_MODAL'
  | 'MEDICATION_BACKORDERED_MODAL'
  | 'MEDICATION_OBSOLETE_MODAL'
  | 'MEDICATION_TRANSFER_MODAL'
  | 'MENOPUR_MODAL'
  | 'MESSAGES_INFO_MODAL'
  | 'MOUNJARO_DIAGNOSIS_MODAL'
  | 'NEXT_AVAILABLE_DATE_MODAL'
  | 'NO_NEEDLES_FOUND_MODAL'
  | 'ONBOARDING_MODAL'
  | 'ONBOARDING_RESUME_MODAL'
  | 'PAY_PRIOR_AUTH_MODAL'
  | 'PERSONAL_INFO_MODAL'
  | 'PHARMACY_INFO_MODAL'
  | 'PHI_AUTH_MODAL'
  | 'PHOTO_ID_UPLOAD_MODAL'
  | 'POST_DELIVERY_MODAL'
  | 'PRESCRIPTION_TRACKER_MODAL'
  | 'PRICE_INFO_MODAL'
  | 'PRIOR_AUTH_MODAL'
  | 'REFERRAL_MODAL' // web only
  | 'SELECT_PRICE_OPTION_MODAL'
  | 'SIGNUP'
  | 'UPDATED_CART_MODAL'
  | 'USER_SIGNATURE'
  | 'VIDEO_PREP_INFO_MODAL';

export function openModal(modalType: ModalType, props?: Record<string, unknown>) {
  return {
    type: 'OPEN_MODAL',
    payload: {
      type: modalType,
      props,
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'OPEN_MODAL',
          properties: {
            modalType,
          },
        },
      },
    },
  } as const;
}

export function closeModal(modalType: ModalType | null) {
  return {
    type: 'CLOSE_MODAL',
    payload: modalType,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'CLOSE_MODAL',
          properties: {
            modalType,
          },
        },
      },
    },
  } as const;
}

export function changeModalStep(step: number, modalType: ModalType) {
  return {
    type: 'CHANGE_MODAL_STEP',
    payload: step,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'CHANGE_MODAL_STEP',
          properties: {
            modalType,
            step,
          },
        },
      },
    },
  } as const;
}

export function nextModalStep(modalType: ModalType) {
  return {
    type: 'NEXT_MODAL_STEP',
    payload: modalType,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'NEXT_MODAL_STEP',
          properties: {
            modalType,
          },
        },
      },
    },
  } as const;
}

export function previousModalStep(modalType: ModalType) {
  return {
    type: 'PREVIOUS_MODAL_STEP',
    payload: modalType,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'PREVIOUS_MODAL_STEP',
          properties: {
            modalType,
          },
        },
      },
    },
  } as const;
}

export function openOriginModal() {
  return {
    type: 'OPEN_ORIGIN_MODAL',
  } as const;
}
