export const Z_INDEX = {
  Z_99999: 99999,
  Z_400: 400,
  Z_300: 300,
  Z_200: 200,
  Z_100: 100,
  Z_DEFAULT: 0,
  Z_MINUS_100: -100,
  Z_MINUS_200: -200,
  Z_MINUS_300: -300,
  Z_MINUS_400: -400,
  Z_MINUS_999999: -999999,
} as const;
