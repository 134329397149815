// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModal } from '@alto/pocky';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { STRIPE_PUBLISHABLE_KEY } from '~shared/config';
import CreditCardForm from './CreditCardForm';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export type Props = {
  readonly onClose: () => void;
};

// @deprecated - use CreditCardActionSheet instead
export default function CreditCardModal({ onClose }: Props) {
  return (
    <DeprecatedModal
      onClose={onClose}
      showing
      title="Add Credit Card"
    >
      <Elements stripe={stripePromise}>
        <CreditCardForm onClose={onClose} />
      </Elements>
    </DeprecatedModal>
  );
}
