// @owners { team: patients-team }
import { Row } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, SimpleContainerSection } from '@alto/pocky';
import React from 'react';
import { type PaymentMethod } from '~shared/types';
import { ConfirmLink } from '~web/components/legacy';
import ACHDescription from '~web/features/payments/components/ACHDescription';
import ACHLabel from '~web/features/payments/components/ACHLabel';
import { PlaidLinkButton } from '~web/features/payments/components/PlaidLinkButton';

type Props = {
  paymentMethods: PaymentMethod[];
  deletePaymentMethod: (paymentMethod: PaymentMethod) => void;
};

export const AchPaymentMethods = ({ paymentMethods, deletePaymentMethod }: Props) => {
  if (paymentMethods.length > 0) {
    return (
      <>
        {paymentMethods.map((achPaymentMethod) => {
          const bank = achPaymentMethod.details || {};
          return (
            <SimpleContainerSection key={`ach-${achPaymentMethod.id}`}>
              <FlexColumn evenlySpaceContent>
                <ACHLabel plaidData={bank} />
                <ConfirmLink
                  confirmPrompt="Are you sure you want to delete this?"
                  onClick={() => {
                    deletePaymentMethod(achPaymentMethod);
                  }}
                >
                  Delete
                </ConfirmLink>
              </FlexColumn>
            </SimpleContainerSection>
          );
        })}
      </>
    );
  }

  return (
    <>
      <SimpleContainerSection>
        <ACHDescription />
      </SimpleContainerSection>
      <SimpleContainerSection>
        <Row right>
          <PlaidLinkButton />
        </Row>
      </SimpleContainerSection>
    </>
  );
};
