// @owners { team: patients-team }
import carrot from '~shared/assets/images/carrot.jpg';
import aetna from '~shared/assets/images/insurances/Aetna.jpg';
import anthem from '~shared/assets/images/insurances/Anthem.jpg';
import blueCrossBlueShield from '~shared/assets/images/insurances/BlueCrossBlueShield.jpg';
import blueShieldCa from '~shared/assets/images/insurances/BlueShieldCalifornia.jpg';
import caremark from '~shared/assets/images/insurances/Caremark.jpg';
import cigna from '~shared/assets/images/insurances/Cigna.jpg';
import expressScripts from '~shared/assets/images/insurances/ExpressScripts.jpg';
import fep from '~shared/assets/images/insurances/FEP.jpg';
import mediCal from '~shared/assets/images/insurances/Medi-Cal.jpg';
import medicare from '~shared/assets/images/insurances/Medicare.jpg';
import optumRx from '~shared/assets/images/insurances/OptumRx.jpg';
import progyny from '~shared/assets/images/insurances/Progyny.jpg';
import sfHealthPlan from '~shared/assets/images/insurances/SFHealthPlan.jpg';
import tht from '~shared/assets/images/insurances/THT.jpg';
import tcc from '~shared/assets/images/insurances/TeamCubanCard.png';
import unitedHealthcare from '~shared/assets/images/insurances/UnitedHealthcare.jpg';

export const PLAN_NAME_TO_LOGO: Record<string, any> = {
  AETNA: aetna,
  ANTHEM: anthem,
  'ANTHEM COMMERCIAL': anthem,
  'ANTHEM MEDICAID': anthem,
  'BLUE CROSS BLUE SHIELD FLORIDA COMMERCIAL PLAN': blueCrossBlueShield,
  'BLUE CROSS BLUE SHIELD ILLINOIS': blueCrossBlueShield,
  'BLUE CROSS BLUE SHIELD KANSAS CITY': blueCrossBlueShield,
  'BLUE CROSS BLUE SHIELD NORTH CAROLINA': blueCrossBlueShield,
  'BLUE CROSS BLUE SHIELD TEXAS': blueCrossBlueShield,
  'BLUE SHIELD OF CALIFORNIA': blueShieldCa,
  'BLUECROSS BLUESHIELD MINNESOTA': blueCrossBlueShield,
  CAREMARK: caremark,
  'CAREMARK MANAGED CARE MEDICAID': caremark,
  CARROT: carrot,
  'CIGNA COMMERCIAL': cigna,
  'CIGNA MEDICARE': cigna,
  'EXPRESS SCRIPTS': expressScripts,
  'FEDERAL EMPLOYEE PROGRAM': fep,
  'GWH CIGNA COMMERCIAL': cigna,
  'INFORMEDRX NATIONAL': optumRx,
  'MANAGED CARE MEDICAID ALAMEDA ALLIANCE HEALTH MEDICAID': medicare,
  MEDCO: expressScripts,
  'MEDICAID CALIFORNIA': mediCal,
  'MEDICARE PART D AETNA PDP AND MAPD PLANS': medicare,
  'MEDICARE PART D ALIGNMENT HEALTH PLAN': medicare,
  'MEDICARE PART D EMBLEM HEALTH/HIP': medicare,
  'MEDICARE PART D ENVISION RX OPTIONS': medicare,
  'MEDICARE PART D EXPRESS SCRIPTS': medicare,
  'MEDICARE PART D HEALTH PLAN OF SAN MATEO': medicare,
  'MEDICARE PART D HUMANA (PDP_MAPD_CAREPLUS)': medicare,
  'MEDICARE PART D IBC': medicare,
  'MEDICARE PART D LEGACY MEDCO MEDICARE': medicare,
  'MEDICARE PART D MEDIMPACT': medicare,
  'MEDICARE PART D NRECA DRUG PROGRAM': medicare,
  'MEDICARE PART D PRESCRIPTION SOLUTIONS': medicare,
  'PCS ADVANCE PARADIGM': caremark,
  'PRESCRIPTION SOLUTIONS SUPER CARRIER PLAN': optumRx,
  PROGYNY: progyny,
  'SAN FRANCISCO HEALTH PLAN': sfHealthPlan,
  'TEACHERS HEALTH TRUST': tht,
  'TEAM CUBAN CARD': tcc,
  'UNITED HEALTHCARE COMMERCIAL': unitedHealthcare,
};
