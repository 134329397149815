// @owners { team: patients-team }

import {
  WaitlistUsersEndpoint,
  type WaitlistUsersEndpointRegisterRequest,
} from '@alto/scriptdash/alto/patient_app/waitlist/v1/waitlist_users_endpoint';
import { useMutation } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';
const waitlistUsersEndpoint = WaitlistUsersEndpoint(apiEndpointHandler);

export const useRegisterWaitlistUser = () => {
  return useMutation({
    mutationFn: (params: WaitlistUsersEndpointRegisterRequest) => waitlistUsersEndpoint.register(params),
  });
};
