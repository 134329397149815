// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getMedicationsFromPrescriptions } from './getMedicationsFromPrescriptions';
import {
  prescriptionHasActiveRenewal,
  prescriptionIsEligibleForRenewal,
  prescriptionNeedsRenewal,
} from '~shared/features/my-meds/helper';
import { makeCachedSortByISODateString } from '~shared/helpers/sort';
import { type PrescriptionIDParamNested } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';
import { type Prescription } from '~shared/types/clients';

export const RENEWAL_STATE_ELIGIBLE = 'eligible';
export const RENEWAL_STATE_INELIGIBLE = 'ineligible';
export const RENEWAL_STATE_PENDING = 'pending';
export const RENEWAL_STATE_NONE = 'none';

export type RenewalState =
  | typeof RENEWAL_STATE_ELIGIBLE
  | typeof RENEWAL_STATE_INELIGIBLE
  | typeof RENEWAL_STATE_PENDING
  | typeof RENEWAL_STATE_NONE;

export type RenewalStatesByPrescription = Record<number, RenewalState>;

const sortByDate = makeCachedSortByISODateString();

// Returns a map of the prescription's id to its renewal state
//
// A prescription has one of the four renewal states:
// - eligible: patient can request a renewal
// - pending: renewal is in progress
// - ineligible: patient cannot request a renewal
// - none: no renewal information to display
const getRenewalStates = (prescriptions: Prescription[]) => {
  const prescriptionsRenewalState: RenewalStatesByPrescription = {};

  // Sorts prescriptions by active_renewal_request_start_at with the most recent
  // first in order to check if a medication has an active renewal.
  const sortedPrescriptions = prescriptions.sort((a, b) =>
    sortByDate(a.active_renewal_request_start_at, b.active_renewal_request_start_at),
  );
  let medicationHasActiveRenewal = false;

  sortedPrescriptions.forEach((prescription) => {
    if (prescriptionNeedsRenewal(prescription)) {
      if (prescriptionIsEligibleForRenewal(prescription)) {
        // if there is an active renewal for the medication already, then the prescription is ineligible for renewal
        if (medicationHasActiveRenewal) {
          prescriptionsRenewalState[prescription.id] = RENEWAL_STATE_INELIGIBLE; // if the prescription has an active renewal, then renewal is pending
        } else if (prescriptionHasActiveRenewal(prescription)) {
          prescriptionsRenewalState[prescription.id] = RENEWAL_STATE_PENDING;
          medicationHasActiveRenewal = true; // if the prescription:
          // - needs renewal
          // - is eligible
          // - has no active renewals for medication
          // - has no active renewal for prescription
          // then prescription is eligible for a renewal
        } else {
          prescriptionsRenewalState[prescription.id] = RENEWAL_STATE_ELIGIBLE;
        }
      } else {
        prescriptionsRenewalState[prescription.id] = RENEWAL_STATE_INELIGIBLE;
      }
    } else {
      prescriptionsRenewalState[prescription.id] = RENEWAL_STATE_NONE;
    }
  });

  return prescriptionsRenewalState;
};

export const getRenewalStatesForPrescriptions = createSelector(getMedicationsFromPrescriptions, (medications) => {
  let renewalStateByPrescription: RenewalStatesByPrescription = {};

  medications.forEach((medication) => {
    renewalStateByPrescription = { ...renewalStateByPrescription, ...getRenewalStates(medication.prescriptions) };
  });

  return renewalStateByPrescription;
});

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParamNested) => props.prescription.id;

export const getRenewalStateForPrescription = createSelector(
  getRenewalStatesForPrescriptions,
  getPrescriptionID,
  (renewalStatesByPrescription, prescriptionID) => {
    return renewalStatesByPrescription[prescriptionID];
  },
);
