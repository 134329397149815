// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '~web/features/essentials/primitives/Icon';
import { StyledTouchable } from '~web/features/essentials/primitives/Styled';
import { Text } from '~web/features/essentials/primitives/Text';
import { View } from '~web/features/essentials/primitives/View';

type Props = {
  readonly type?: 'submit' | 'reset' | 'button';
  readonly onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly alignSelf?: 'center' | 'flex-start' | 'auto';
  readonly title: string;
  readonly backgroundColor?: keyof typeof COLORS.BACKGROUND_COLORS;
  readonly color?: keyof typeof COLORS.TEXT_COLORS;
  readonly borderColor?: keyof typeof COLORS.BORDER_COLORS | 'transparent';
  readonly Left?: React.ReactNode;
  readonly Right?: React.ReactNode;
};

const StyledButton = styled.button<Props>`
  display: flex;
  align-self: ${({ alignSelf = 'auto' }) => alignSelf};
  align-items: center;
  justify-content: center;
  min-height: 48px;
  border-radius: ${BORDERS.RADIUS.XXL.px};
  border: ${({ borderColor = 'transparent' }) =>
    `2px solid ${borderColor === 'transparent' ? 'transparent' : COLORS.BORDER_COLORS[borderColor]}`};
  background-color: ${({ backgroundColor = 'PRIMARY' }) => COLORS.BACKGROUND_COLORS[backgroundColor]};
  padding: ${SPACING.STATIC.SM.px} ${SPACING.STATIC.LG.px};
  ${StyledTouchable}
  &:hover {
    cursor: ${({ disabled, loading }) => (disabled || loading ? 'auto' : 'pointer')};
  }
`;

const LeftContent = styled(View)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const RightContent = styled(View)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const DefaultView = <View />;

export const Button = ({
  title,
  type = 'button',
  disabled,
  alignSelf,
  borderColor,
  onClick,
  backgroundColor,
  loading,
  color = 'WHITE',
  Left = DefaultView,
  Right = DefaultView,
}: Props) => {
  return (
    <StyledButton
      borderColor={borderColor}
      type={type}
      backgroundColor={backgroundColor}
      onClick={onClick}
      aria-pressed={disabled || loading ? 'true' : 'false'}
      aria-describedby={title}
      title={title}
      alignSelf={alignSelf}
      loading={loading}
      disabled={loading || disabled}
    >
      {!loading && <LeftContent>{Left}</LeftContent>}
      {loading ? (
        <Icon
          spinning
          name="loader-white"
          interactive={false}
          label="loading icon"
          size="LG"
        />
      ) : (
        <Text
          title={title}
          color={color}
          align="center"
          variant="body-semibold"
        />
      )}
      {!loading && <RightContent>{Right}</RightContent>}
    </StyledButton>
  );
};
