// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { Button, Modal, ModalFooter, Row, spacing } from '@alto/pocky';
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import { DEFAULT_TIP_AMOUNT } from '~shared/features/courier-tips/constants';
import { canSubmitCourierTip } from '~shared/features/courier-tips/helpers';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import EditOrAddTip from './EditOrAddTip';

// There's a bug in Pocky's `ModalContent` container that causes our
// EditOrAddTip component to completely reload (i.e. mount) every time
// a prop changes.
// https://app.asana.com/0/1199906372554532/1199983500642897/f
const SimpleModalContent = styled.div`
  text-align: center;
  padding: ${spacing.mdSpacing};
`;

export type Props = {
  readonly onClose: () => void;
  readonly onSubmit: (tipAmount: number, tipPaymentMethodID: number) => void;
  readonly shipmentID: number | string;
  readonly shipmentTip: ShipmentTip | null | undefined;
};

export default function EditOrAddTipModal({ onClose, onSubmit, shipmentID, shipmentTip }: Props) {
  const [tipAmount, setTipAmount] = useState(shipmentTip?.amount ?? DEFAULT_TIP_AMOUNT);
  const [paymentMethodID, setPaymentMethodID] = useState(shipmentTip?.payment_method_id);
  const canSubmitTip = canSubmitCourierTip(tipAmount, paymentMethodID);
  const dispatch = useDispatchShared();
  const address = useSelectorShared((state: ReduxStateShared) =>
    getShipmentAddress(state, { shipmentID: Number(shipmentID) }),
  );

  const analyticsProps = {
    shipment_id: shipmentID,
    source: SOURCES.EDIT_DELIVERY,
    facility_id: address?.dispensing_facility_id,
  } as const;

  const onCloseModal = () => {
    dispatch(sendTipAnalyticsEvent({ eventKey: EVENTS.COURIER_TIPS__EDIT_TIP_DISMISSED, analyticsProps }));
    onClose();
  };

  const handleOnSubmit = () => {
    // for flow
    if (paymentMethodID) {
      onSubmit(tipAmount, paymentMethodID);
    }
  };

  return (
    <Modal
      dismissOnOutsideClick
      onClose={onCloseModal}
      showDismiss
    >
      <SimpleModalContent>
        <EditOrAddTip
          editTipAmount={setTipAmount}
          editTipPaymentMethodID={setPaymentMethodID}
          tipAmount={tipAmount}
          tipPaymentMethodID={paymentMethodID}
          analyticsProps={analyticsProps}
        />
      </SimpleModalContent>
      <ModalFooter>
        <Row
          rightAlignContent
          spacing={spacing.smSpacing}
        >
          <Button
            onClick={onClose}
            kind="secondary"
          >
            Dismiss
          </Button>
          <Button
            disabled={!canSubmitTip}
            onClick={handleOnSubmit}
          >
            Submit
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
