import { BORDERS, COLORS, SPACING, Z_INDEX } from '@alto/design-library-tokens';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Row } from '../../containers';
import { CoachmarkContent } from './CoachmarkContent';
import { type Position } from './types';

export type CoachmarkProps = {
  readonly children: React.ReactNode;
  readonly content: string;
  readonly dismissible?: boolean;
  readonly onDismiss?: () => void;
  readonly position?: Position;
  readonly timeToFade?: number;
  readonly visible: boolean;
  readonly yOffset?: number;
  readonly xOffset?: number;
  readonly onHide?: () => void;
};

const WebCoachmarkContent = styled(View)<{ yOffset: number; position: Position; xOffset: number }>`
  position: absolute;
  z-index: ${Z_INDEX.Z_100};
  ${({ yOffset, position }) =>
    position === 'bottom' || position === 'bottom-left' || position === 'bottom-right'
      ? `top: ${yOffset}px;`
      : `bottom: ${yOffset}px;`};
  ${({ position }) =>
    position === 'top-left' || position === 'bottom-left' ? `left: calc(50% - ${SPACING.STATIC.LG.px});` : ''};
  ${({ position }) =>
    position === 'top-right' || position === 'bottom-right' ? `right: calc(50% - ${SPACING.STATIC.LG.px});` : ''};
  padding: ${SPACING.STATIC.SM.px};
  background-color: ${COLORS.BACKGROUND_COLORS.SECONDARY};
  color: ${COLORS.TEXT_COLORS.WHITE};
  border-radius: ${BORDERS.RADIUS.SM.px};
  width: max-content;
  max-width: 400px;
`;

export const Coachmark = ({
  children,
  content,
  dismissible = false,
  onDismiss,
  position = 'top',
  timeToFade = 7000,
  visible,
  yOffset = SPACING.STATIC.XXL.value,
  xOffset = 0,
  onHide,
}: CoachmarkProps) => {
  const [showCoachmark, setShowCoachmark] = useState(visible);

  const dismissCoachmark = () => {
    setShowCoachmark(false);

    if (onHide) {
      onHide();
    }

    if (onDismiss) {
      onDismiss();
    }
  };

  useEffect(() => {
    setShowCoachmark(visible);
  }, [visible]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCoachmark(false);

      if (onHide) {
        onHide();
      }
    }, timeToFade);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeToFade, onHide]);

  return (
    <Row center>
      {children}
      {showCoachmark ? (
        <WebCoachmarkContent
          position={position}
          yOffset={yOffset}
          xOffset={xOffset}
        >
          <CoachmarkContent
            content={content}
            dismissCoachmark={dismissCoachmark}
            dismissible={dismissible}
            position={position}
          />
        </WebCoachmarkContent>
      ) : null}
    </Row>
  );
};
