// @owners { team: patients-team }
import { BORDERS, COLORS, SHADOWS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import { StyledTouchable, StyledVisuallyHidden } from '~web/features/essentials/primitives/Styled';

type Props = {
  readonly id: string;
  readonly isSearch?: boolean;
  readonly placeholder: string;
  readonly disabled?: boolean;
  readonly label: string;
  readonly required?: boolean;
  readonly value: string;
  readonly onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  readonly labelHidden?: boolean;
  readonly shadow?: boolean;
};

const StyledLabel = styled.label<{ labelHidden?: boolean }>`
  ${({ labelHidden }) => labelHidden && StyledVisuallyHidden};
`;

const fill = encodeURIComponent(COLORS.BACKGROUND_COLORS.PRIMARY);
const StyledSearch = css`
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='${fill}' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 16px center;
`;

const StyledInput = styled.input<{ isSearch?: boolean; shadow?: boolean; label?: string }>`
  width: 100%;
  padding: ${SPACING.STATIC.MD.px};
  ${({ isSearch }) => isSearch && `padding-left: ${SPACING.STATIC.XL.value + SPACING.STATIC.MD.value}px`};
  border-radius: ${BORDERS.RADIUS.LG.px};
  border: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD};
  line-height: ${TYPOGRAPHY.LINE_HEIGHT.LG};
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD};
  ${({ isSearch }) => isSearch && StyledSearch};
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  &:focus {
    outline: 1px solid ${COLORS.BORDER_COLORS.PRIMARY};
  }
  ${StyledTouchable}
  user-select: auto;
  ${({ shadow }) => shadow && SHADOWS.BOTTOM_LIGHT}
`;

export const Input = ({
  id,
  required,
  isSearch,
  label,
  placeholder,
  value,
  onChange,
  disabled,
  labelHidden,
  shadow,
}: Props) => {
  return (
    <>
      <StyledLabel
        htmlFor={id}
        labelHidden={labelHidden}
      >
        {label}
      </StyledLabel>
      <StyledInput
        required={required}
        type="text"
        isSearch={isSearch}
        name={id}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        shadow={shadow}
      />
    </>
  );
};
