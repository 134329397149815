// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  Column,
  H1,
  LgPadding,
  LgSpacing,
  MdPadding,
  MdSpacing,
  SecondaryPage,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-deprecated
import { updateUser as updateUserAction } from '~shared/actions/users';
import { NO_KNOWN_ALLERGIES, NO_KNOWN_MEDICAL_CONDITIONS } from '~shared/constants';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Gender } from '~shared/types';
import { AllergiesInput, MedicalConditionsInput, SexInputSelect } from '../../onboarding';
import { FamilyMemberProgressBar } from './FamilyMemberProgressBar';

type MedicalInfoFormValues = {
  gender: Gender;
  allergies: string;
  medical_conditions: string;
};

const isWeb = getPlatformOS() === 'web';

export const FamilyMemberMedicalInfo = () => {
  const { navigate } = useNavigation<'RouteFamilyMemberMedicalInfo'>();
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const selectedUser = useSelectorShared(getSelectedUser);
  const user = selectedUser;
  const familyMemberName = selectedUser?.preferred_first_name || selectedUser?.first_name || 'family member';

  useEffect(() => {
    if (!relationship) {
      navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
    }
  }, [navigate, relationship]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, submitCount },
  } = useForm<MedicalInfoFormValues>({
    defaultValues: {
      gender: user?.gender,
      allergies: user?.allergies || NO_KNOWN_ALLERGIES,
      medical_conditions: user?.medical_conditions || NO_KNOWN_MEDICAL_CONDITIONS,
    },
  });

  const sexSelectController = useController({
    control,
    name: 'gender',
    rules: { required: 'Please select an option' },
  });

  const allergiesController = useController({
    control,
    name: 'allergies',
    rules: {
      validate: (value) => {
        if (!value) {
          return 'Please add an allergy';
        }
        return true;
      },
    },
  });

  const medicalConditionsController = useController({
    control,
    name: 'medical_conditions',
    rules: {
      validate: (value) => {
        if (!value) {
          return 'Please add a medical condition';
        }
        return true;
      },
    },
  });

  const handleSubmitForm = () => {
    if (!user) return;
    handleSubmit(async (formValues) => {
      // eslint-disable-next-line import/no-deprecated
      const { success } = await dispatch(updateUserAction({ ...formValues, id: user.id }));
      if (success) {
        navigate('RouteFamilyMemberBenefits', { userID: user.id });
      } else {
        addToast(<Toast variant="error">Unable to update medical info. Please try again or contact support.</Toast>);
      }
    })();
  };

  return (
    <SecondaryPage
      buttons={[
        <Button
          accessibilityLabel="Press to submit medical info"
          key="submit"
          label="Continue"
          onPress={handleSubmitForm}
          type="primary"
          disabled={isSubmitting}
          loading={isSubmitting}
        />,
      ]}
      headerBackgroundColor={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      HeaderContent={
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <Column center>
            <MdPadding>
              <FamilyMemberProgressBar />
            </MdPadding>
            <LgSpacing />
            <H1 center>{`Complete ${getPossessiveName(familyMemberName)} profile:\nAdd medical info`}</H1>
            <XsSpacing />
            <Body center>Our pharmacists review every order, and use this info to ensure your well-being.</Body>
            <XsSpacing />
          </Column>
        </LgPadding>
      }
      footerPlacementContext={isWeb ? 'webScreenWithoutPolicyFooter' : undefined}
    >
      <Card>
        <LgPadding bottomPadding={SPACING.STATIC.XS}>
          <SexInputSelect
            defaultValue={sexSelectController.field.value}
            familyMemberName={familyMemberName}
            onChange={sexSelectController.field.onChange}
            error={sexSelectController.fieldState?.error?.message}
          />
        </LgPadding>
        <MdSpacing />
        <AllergiesInput
          familyMemberName={familyMemberName}
          allergies={allergiesController.field.value}
          handleChange={allergiesController.field.onChange}
          error={allergiesController.fieldState.error?.message}
          isTouched={allergiesController.fieldState.isTouched}
          hasSubmitted={submitCount > 0}
        />
        <XsSpacing />
        <MedicalConditionsInput
          medicalConditions={medicalConditionsController.field.value}
          handleChange={medicalConditionsController.field.onChange}
          familyMemberName={familyMemberName}
          error={medicalConditionsController.fieldState.error?.message}
          isTouched={medicalConditionsController.fieldState.isTouched}
          hasSubmitted={submitCount > 0}
        />
      </Card>
    </SecondaryPage>
  );
};
