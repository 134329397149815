// used for overall Delivery Price Breakdown and for individual Rx Pricing Info
import { type LineItemType, type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';

export const NEW_ESTIMATED_PREFIX_COPY = 'Est. ';
export const PRICE_FREE = 'Free';

type LabelValues = { display: string; savings: boolean };

type PricingLabels = Record<LineItemType, LabelValues>;

export const PRICING_LABELS: PricingLabels = {
  alto_credit: { display: 'Alto credit', savings: true },
  alto_savings: { display: 'Savings', savings: true },
  cash_price: { display: 'Base price', savings: false },
  pickup: { display: 'Pickup', savings: false },
  asap_delivery_fee: { display: 'Express delivery fee', savings: false },
  same_day_delivery_fee: { display: 'Same-day delivery', savings: false },
  next_day_delivery_fee: { display: 'Next-day delivery', savings: false },
  standard_delivery_fee: { display: 'Standard delivery', savings: false },
  essentials_only_fee: { display: 'Essentials-only fee', savings: false },
  delivery_fee: { display: 'Delivery', savings: false },
  insurance_paid: { display: 'Insurance covered', savings: true },
  on_demand_fee: { display: 'On-demand', savings: false },
  tip_amount: { display: 'Courier tip', savings: false },
  total: { display: 'Total', savings: false },
  pending_progyny_coverage: { display: 'Pending coverage', savings: true },
};

export const TOTAL_PRICE_PENDING = 'Price pending';
export const PRICE_PENDING = 'Pending';

type PriceOptionLabels = Record<PriceType, string>;

export const PRICE_OPTION_LABELS: PriceOptionLabels = {
  with_insurance: 'With Insurance',
  without_insurance: 'Cash price without insurance',
};

// keep in sync with server
export const PRICING_SCENARIOS = {
  CASH_LESS_THAN_INSURANCE: 'cash_less_than_insurance',
  VOUCHER_PAY: 'voucher_pay',
  UNAVAILABLE: 'unavailable',
  CASH_AND_CATEGORIZED_REJECT: 'cash_and_categorized_reject',
  PROGYNY: 'progyny',
};

export const PRESCRIPTION_PRICING_LABELS = {
  // Not Estimated & Insurance
  YOUR_COPAY: 'Your price',
  // Estimated & With Insurance
  LAST_COPAY: 'Last price',
  // Estimated & Without Insurance
  ESTIMATED_PRICE: `${NEW_ESTIMATED_PREFIX_COPY}Pricing`,
  // Scenario === unavailable
  PRICE_UNAVAILABLE: TOTAL_PRICE_PENDING,
  // Scenario === unavailable
  VIEW_PRICE_IN_CART: 'View Price in Cart',
  // Scenario === voucher_pay
  COVERED_BY_CLINIC: 'Billed to Clinic',
  // Scenario === progyny
  PROGYNY: 'Your Alto Copay',
};

export const PAYMENT_BREAKDOWN_LABELS = {
  ITEM_PRICE: 'Item price',
  ADDITIONAL_SAVINGS: 'Additional savings',
  TOTAL: 'Total price',
  TOTAL_WITH_INSURANCE: 'Total price with insurance',
  // estimated labels
  PREV_ITEM_PRICE: 'Previous item price',
  PREV_INSURANCE: 'Previous insurance covered',
  PREV_SAVINGS: 'Previous savings (coupons & benefits)',
  PREV_TOTAL: 'Previous price',
  PREV_TOTAL_WITH_INSURANCE: 'Previous price with insurance',
};
