import { BREAKPOINTS } from '@alto/design-library-tokens';
import { Dimensions, Platform, StatusBar, useWindowDimensions } from 'react-native';
import { css } from 'styled-components/native';

const isiOS = Platform.OS === 'ios';

//
// NOTE: many of these calculations work because we don't allow landscape
//
// Dimensions pulled from link [1] or by using Dimensions.get('window').height. At time of writing, iPhone 12 is not listed in link [1]
// [1] https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/
const IPHONE_X_HEIGHT = 812;
const IPHONE_XR_HEIGHT = 896;
const IPHONE_12_HEIGHT = 844;
const IPHONE_12_PRO_MAX_HEIGHT = 926;
const EDGELESS_PHONE_HEIGHTS_PIXELS = {
  IPHONE_X_HEIGHT,
  IPHONE_XR_HEIGHT,
  IPHONE_12_HEIGHT,
  IPHONE_12_PRO_MAX_HEIGHT,
};

// defined by iOS safe area https://useyourloaf.com/blog/supporting-iphone-x/
export const EDGELESS_HOME_BAR_HEIGHT = 34;

export const IPHONE_14_TOP_SAFE_AREA_INSET = 59;

// defined by iOS and set in react-navigation
// https://github.com/react-community/react-navigation/blob/2b40182cd72861691956f059e56535c1994abd57/src/views/Header/Header.js#L36
const HEADER_HEIGHT = isiOS ? 44 : 56;

// iOS: https://github.com/react-community/react-navigation/blob/2b40182cd72861691956f059e56535c1994abd57/src/views/TabView/TabBarBottom.js#L187
// android: This is the height of https://material.io/guidelines/components/bottom-navigation.html#bottom-navigation-usage
const TAB_BAR_HEIGHT = isiOS ? 49 : 57;
const PHONE_SCREEN_MAX_WIDTH = 480;

// export a function in case in the future we support landscape and we can't
// rely on the window height at app start
const SCREEN_HEIGHT = Dimensions.get('window').height;
export const isEdgeless = (): boolean => {
  return (
    Platform.OS === 'ios' &&
    !Platform.isPad &&
    !Platform.isTV &&
    Object.values(EDGELESS_PHONE_HEIGHTS_PIXELS).includes(SCREEN_HEIGHT)
  );
};

export const hasNotch = (): boolean => {
  return Platform.OS === 'ios' && Dimensions.get('window').height === IPHONE_X_HEIGHT;
};

export const getStatusBarHeight = (): number => {
  // TODO: maybe this should always be 0 on Android? https://github.com/react-community/react-navigation/blob/2b40182cd72861691956f059e56535c1994abd57/src/views/Header/Header.js#L37
  if (Platform.OS === 'android') {
    return StatusBar.currentHeight || 0;
  }

  // https://useyourloaf.com/blog/supporting-iphone-x/
  const safeArea = hasNotch() ? 88 : 64;
  return safeArea - HEADER_HEIGHT;
};

export const getHeaderHeight = (): number => HEADER_HEIGHT;

export const getTabBarHeight = (): number => TAB_BAR_HEIGHT;

export const getEdgelessHomeBarHeight = (): number => EDGELESS_HOME_BAR_HEIGHT;

export const getTopSafeAreaMargin = (): number => {
  return getStatusBarHeight() + getHeaderHeight();
};

export const getBottomSafeAreaMargin = (): number => {
  return isEdgeless() ? getEdgelessHomeBarHeight() : 0;
};

export const getSafeAreaHeight = (): number => {
  const { height } = Dimensions.get('window');
  return height - getTopSafeAreaMargin() - getBottomSafeAreaMargin();
};

export const getSafeAreaWidth = (): number => {
  return Dimensions.get('window').width;
};

type StyledCSS = any;

export const isPhone = (strings: StyledCSS, ...interpolations: StyledCSS) => css`
  ${() => (getSafeAreaWidth() < PHONE_SCREEN_MAX_WIDTH ? css(strings, ...interpolations) : null)}
`;

export const isTablet = (strings: StyledCSS, ...interpolations: StyledCSS) => css`
  ${() => (getSafeAreaWidth() >= PHONE_SCREEN_MAX_WIDTH ? css(strings, ...interpolations) : null)}
`;

export const isSMScreenOrBigger = () => {
  return getSafeAreaWidth() > BREAKPOINTS.SM;
};

export const isMDScreenOrBigger = () => {
  return getSafeAreaWidth() > BREAKPOINTS.MD;
};

export const isLGScreenOrBigger = () => {
  return getSafeAreaWidth() > BREAKPOINTS.LG;
};

export const isXLScreenOrBigger = () => {
  return getSafeAreaWidth() > BREAKPOINTS.XL;
};

export const getPercentageOfScreenWidth = (percentage: number): number => {
  const SCREEN_WIDTH = Dimensions.get('window').width;
  const value = (percentage * SCREEN_WIDTH) / 100;
  return Math.round(value);
};

export const getPercentageOfScreenHeight = (percentage: number): number => {
  const SCREEN_HEIGHT = Dimensions.get('window').height;
  const value = (percentage * SCREEN_HEIGHT) / 100;
  return Math.round(value);
};

/**
 * @deprecated
 * NOTE: This function should only be used inside class components. If this is not the case please use useScreenSize hook
 */
export const getScreenSize = () => {
  const windowWidth = Dimensions.get('window').width;

  return {
    isSMScreenOrBigger: windowWidth > BREAKPOINTS.SM,
    isMDScreenOrBigger: windowWidth > BREAKPOINTS.MD,
    isLGScreenOrBigger: windowWidth > BREAKPOINTS.LG,
    isXLScreenOrBigger: windowWidth > BREAKPOINTS.XL,
  };
};

export const useScreenSize = () => {
  const windowWidth = useWindowDimensions().width;

  return {
    isXSScreen: windowWidth <= BREAKPOINTS.XS,
    isSMScreen: windowWidth <= BREAKPOINTS.SM,
    isSMScreenOrBigger: windowWidth > BREAKPOINTS.SM,
    isMDScreenOrBigger: windowWidth > BREAKPOINTS.MD,
    isLGScreenOrBigger: windowWidth > BREAKPOINTS.LG,
    isXLScreenOrBigger: windowWidth > BREAKPOINTS.XL,
  };
};
