// @owners { team: patients-team }
import { AddOnsEndpoint } from '@alto/scriptdash/alto/patient_app/add_ons/v1/add_ons_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';

const EssentialsApi = AddOnsEndpoint(apiEndpointHandler);

export const essentials = createQueryKeys('essentials', {
  therapeuticAreaUpsell: ({ address_id, shipment_id }: { address_id?: number; shipment_id?: number | null }) => ({
    queryKey: [{ address_id, shipment_id }],
    queryFn: () => {
      if (!address_id || !shipment_id) return null;

      return EssentialsApi.fetchTherapeuticAreaUpsell({ address_id, shipment_id });
    },
  }),
});
