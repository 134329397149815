// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Modal, ModalContent, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { formatNameWithCommas } from '~shared/features/my-meds/helper';
import HeaderImage, { MODAL_SIZE } from '~web/components/HeaderImage';
import telephone from '~web/images/telephone@2x.png';

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

type Props = {
  readonly onClose: () => void;
  readonly providerName: string;
};

export const ClarificationModal = ({ onClose, providerName }: Props) => (
  <StyledModal
    dismissOnOutsideClick
    showDismiss
    onClose={onClose}
  >
    <ModalContent textAlignment="left">
      <Column spacing={SPACING.STATIC.MD.rem}>
        <HeaderImage
          src={telephone}
          alt={IMG_ALT_TEXTS.telephone}
          title="Clarification"
          imgSize={MODAL_SIZE}
        />
        <Text>
          We have some questions for your provider{formatNameWithCommas(providerName)}about your prescription.
        </Text>
        <Text>Our pharmacists have reached out to your provider to get this resolved quickly.</Text>
        <Text>We'll notify you when your medication is ready to schedule for delivery.</Text>
      </Column>
    </ModalContent>
  </StyledModal>
);
