// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Body,
  Description,
  Pressable,
  Row,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import { isToday as isDateToday, parse } from 'date-fns';
import React, { useContext } from 'react';
import { ActivityIndicator } from 'react-native';
import { getWindows } from '~shared/features/checkout/selectors/getCart';
import { reasonIsRefillTooSoon } from '~shared/features/delivery/helpers';
import { formattedNextAvailableDate } from '~shared/features/next-available-date/helpers/formattedNextAvailableDate';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { DATE_FORMATS } from '~shared/helpers/date';
import { formatTimeToCutoff, getTimeToCutoff } from '~shared/helpers/timeCutoff';
import { ORIGIN_NAMES, type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { OptionsToGetItSoonerActionSheet } from '../../checkout/components/get-it-sooner/OptionsToGetItSoonerActionSheet';
import { NextAvailableDateActionSheet } from './next-available-date-action-sheet/NextAvailableDateActionSheet';

type Props = {
  readonly analyticsOrigin: OriginName;
  readonly isLoading: boolean;
  readonly earliestAvailableDate: string;
  readonly earliestAvailableReason: string;
  readonly prescriptionID: number;
  readonly lineBreakDate?: boolean;
  readonly excludeDayOfWeek?: boolean;
  readonly smallCopy?: boolean; // Temporary until we fully refactor to be a true ListDescription
  readonly disabled?: boolean;
};

export const NextAvailableDateInfoRow = ({
  analyticsOrigin,
  isLoading,
  earliestAvailableDate,
  earliestAvailableReason,
  prescriptionID,
  lineBreakDate,
  excludeDayOfWeek,
  smallCopy,
  disabled,
}: Props) => {
  const isMedDetails = analyticsOrigin === ORIGIN_NAMES.MED_DETAILS;
  const windows = useSelectorShared(getWindows);
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));

  const copy = isLoading
    ? 'Refreshing...'
    : formattedNextAvailableDate(earliestAvailableDate, earliestAvailableReason, lineBreakDate, excludeDayOfWeek);

  const isRefillTooSoon = reasonIsRefillTooSoon(earliestAvailableReason);
  const isToday = isDateToday(parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0));

  const openNextAvailableDateOrGetItSoonerFlow = () => {
    if (isRefillTooSoon && prescription) {
      setActiveActionSheet(
        <OptionsToGetItSoonerActionSheet
          prescription={prescription}
          handleModalClose={closeActionSheet}
          analyticsOrigin={analyticsOrigin}
        />,
      );
    } else {
      setActiveActionSheet(
        <NextAvailableDateActionSheet
          earliestAvailableDate={earliestAvailableDate}
          earliestDateReason={earliestAvailableReason}
          prescriptionID={prescriptionID}
          analyticsOrigin={analyticsOrigin}
          orderTypeContext="Add to cart"
        />,
      );
    }
  };

  const timeToCutOff = isToday ? formatTimeToCutoff(getTimeToCutoff(windows)) : '';

  return (
    <Pressable
      disabled={disabled}
      backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
      onPress={openNextAvailableDateOrGetItSoonerFlow}
    >
      <Row
        centerVertically
        spaceBetween
        flexShrink={1}
      >
        <Row
          flexShrink={1}
          centerVertically
        >
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <AltoIcon
              name="calendar-small"
              type="grey"
            />
          )}
          <XsSpacing />
          <Row
            centerVertically
            flexShrink={1}
          >
            {smallCopy ? (
              <Description>{copy || ''}</Description>
            ) : (
              <Body color={COLORS.TEXT_COLORS.BLACK}>{copy || ''}</Body>
            )}
          </Row>
        </Row>
        {!disabled && smallCopy ? (
          <>
            <SmSpacing />
            <Description
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.SECONDARY_DARK}
            >
              Details
            </Description>
          </>
        ) : null}
        {!disabled && !smallCopy && (
          <>
            <SmSpacing />
            <Body
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.SECONDARY_DARK}
            >
              Details
            </Body>
          </>
        )}
      </Row>
      {earliestAvailableDate && isMedDetails && !!timeToCutOff ? (
        <Body color={COLORS.TEXT_COLORS.SUCCESS}>Order {timeToCutOff}</Body>
      ) : null}
    </Pressable>
  );
};
