import { ActionSheetContext } from '@alto/design-system';
import { type LineItemInfoKey, type LineItemType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { useContext } from 'react';
import { getHasScheduleableDeliveryFeesInCart } from '~shared/features/checkout/selectors/getCart';
import { getOrderDate } from '~shared/features/checkout/selectors/getOrder';
import {
  NEW_ESTIMATED_PREFIX_COPY,
  PRICE_FREE,
  PRICING_LABELS,
  TOTAL_PRICE_PENDING,
} from '~shared/features/pricing/constants';
import { isItemTypeExpeditedDeliveryFee } from '~shared/helpers/pricingBreakdown';
import { useSelectorShared } from '~shared/store';
import { EstimatedPriceActionSheet } from './EstimatedPriceActionSheet';
import { PricingInfoRow } from './PricingInfoRow';

type Props = {
  readonly infoKey?: LineItemInfoKey | null;
  readonly itemType: LineItemType;
  readonly itemValue: number | null | undefined;
  readonly label: string | null | undefined;
  readonly openEditTip?: () => void;
  readonly originalValue?: number | null;
  readonly showEditTipOption?: boolean;
  readonly showEstimatedTotal?: boolean;
  readonly totalItemCount: number;
  readonly origin: 'Cart' | 'Checkout' | 'DeliveryDetails';
};

export const PricingInfoLineItem = ({
  infoKey,
  itemType,
  itemValue,
  label,
  openEditTip,
  originalValue,
  showEditTipOption,
  showEstimatedTotal,
  totalItemCount,
  origin,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const orderDate = useSelectorShared(getOrderDate);
  const isCartOrCheckout = ['Cart', 'Checkout'].includes(origin);
  const hasScheduleableDeliveryFeesInCart = useSelectorShared(getHasScheduleableDeliveryFeesInCart);
  const isEstimatedAmount = infoKey === 'estimated' || (itemType === 'total' && !!showEstimatedTotal);
  const lineItemLabel = `${isEstimatedAmount ? NEW_ESTIMATED_PREFIX_COPY : ''}${label}`;
  const savings = PRICING_LABELS[itemType].savings;

  // If this is cart/checkout, the cart is eligible for delivery fees, and no order date is selected,
  // we don't want to show any line item related to delivery fees
  const isDeliveryFeeLineItem = itemType.includes('delivery_fee');
  if (isCartOrCheckout && hasScheduleableDeliveryFeesInCart && isDeliveryFeeLineItem && !orderDate) {
    return null;
  }

  if ('delivery_fee' === itemType) {
    return (
      <PricingInfoRow
        label={lineItemLabel}
        price={itemValue || PRICE_FREE}
        savings={savings}
      />
    );
  }
  if (isItemTypeExpeditedDeliveryFee(itemType)) {
    return (
      <PricingInfoRow
        itemType={itemType}
        label={lineItemLabel}
        originalPrice={originalValue}
        price={itemValue || PRICE_FREE}
        savings={savings}
      />
    );
  }

  if ('tip_amount' === itemType) {
    return (
      <PricingInfoRow
        label={lineItemLabel}
        onPressChange={showEditTipOption ? openEditTip : undefined}
        price={itemValue}
        savings={savings}
      />
    );
  }

  if ('pickup' === itemType) {
    return (
      <PricingInfoRow
        label={lineItemLabel}
        price={PRICE_FREE}
      />
    );
  }

  if ('total' === itemType) {
    if ('price_unavailable' === infoKey) {
      return (
        <PricingInfoRow
          label={lineItemLabel}
          price={TOTAL_PRICE_PENDING}
          savings={savings}
        />
      );
    }

    if ('estimated' === infoKey) {
      const handlePressMoreInfo = () => {
        setActiveActionSheet(<EstimatedPriceActionSheet totalItemCount={totalItemCount} />);
      };
      return (
        <PricingInfoRow
          onPressMoreInfo={handlePressMoreInfo}
          label={lineItemLabel}
          price={itemValue}
          savings={savings}
        />
      );
    }
  }

  return (
    <PricingInfoRow
      label={lineItemLabel}
      price={itemValue}
      savings={savings}
    />
  );
};
