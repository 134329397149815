import { withRehydration } from './withRehydration';
import {
  FETCH_USER_SUBSCRIPTIONS_SUCCEEDED,
  type SubscriptionsActions,
  TOGGLE_SUBSCRIPTION,
} from '~shared/actions/subscriptions';
import { subscriptionsConstants } from '~shared/constants';
import { type Subscription } from '~shared/types';

export type SubscriptionsState = {
  subscriptions: Subscription[];
  email: string;
};

const initialState: SubscriptionsState = {
  subscriptions: [],
  email: '',
};

const subscriptionsReducer = (
  state: SubscriptionsState = initialState,
  action: SubscriptionsActions,
): SubscriptionsState => {
  switch (action.type) {
    case FETCH_USER_SUBSCRIPTIONS_SUCCEEDED: {
      const { email, subscriptions } = action.payload;

      return {
        ...state,
        email,
        subscriptions: Object.keys(subscriptionsConstants).map((comms_type) => ({
          comms_type,
          subscribed: subscriptions.includes(comms_type),
        })),
      };
    }

    case TOGGLE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state.subscriptions.map((sub) => {
          const subscribed = action.comms_type === sub.comms_type ? !sub.subscribed : sub.subscribed;

          return { ...sub, subscribed };
        }),
      };

    default:
      return state;
  }
};

export default withRehydration(subscriptionsReducer, initialState);
