import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import styled from 'styled-components/native';
import { Text } from './Text';

export const TabBarText = styled(Text).attrs({
  color: COLORS.TEXT_COLORS.GREY,
})`
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  line-height: ${TYPOGRAPHY.TEXT.DETAIL.LG.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
`;
