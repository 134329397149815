import { type Section } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/section';
import { SectionTypeMap } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/section_type';
import { type MedSyncStatus } from '@alto/scriptdash/alto/patients/types/v1/med_sync_status';

type SectionCounts = {
  OUT_OF_REFILLS: number;
  NEW_PRESCRIPTIONS: number;
  DUE_FOR_REFILL: number;
  PENDING_TRANSFERS: number;
  PATIENT_TASK: number;
  UPCOMING_SHIPMENTS: number;
  UNCONFIRMED_SHIPMENTS: number;
  MED_SYNC_UPSELL: MedSyncStatus | null;
};

export function generateSectionCounts(sections: Section[] | undefined): Partial<SectionCounts> {
  const counts: Partial<SectionCounts> = {};
  sections?.forEach((section) => {
    switch (section.type) {
      case SectionTypeMap.OUT_OF_REFILLS:
      case SectionTypeMap.NEW_PRESCRIPTIONS:
      case SectionTypeMap.DUE_FOR_REFILL:
        counts[section.type] = section.prescriptions ? section.prescriptions.length : 0;
        break;

      case SectionTypeMap.PENDING_TRANSFERS:
        counts[section.type] = section.transfers ? section.transfers.length : 0;
        break;

      case SectionTypeMap.PATIENT_TASK:
        counts[section.type] = section.patient_tasks ? section.patient_tasks.length : 0;
        break;
      case SectionTypeMap.UPCOMING_SHIPMENTS:
        counts[section.type] = section.shipments ? section.shipments.length : 0;
        break;
      case SectionTypeMap.MED_SYNC_UPSELL:
        counts[section.type] = section.med_sync_status;
        break;
      default:
        break;
    }
  });

  return counts;
}
