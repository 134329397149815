// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  LgPadding,
  SmSpacing,
  Toast,
  ToastContext,
} from '@alto/design-system';
import React, { useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { archivePrescription } from '~shared/actions/prescriptions';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getErrorMessage } from '~shared/helpers/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useRemoveFromCart } from '../../cart';
import { useInvalidateMedList } from '../../med-list/queries/useMedList';
import { useInvalidateMedDetails } from '../queries/useMedDetails';

type Props = {
  readonly medicationName: string;
  readonly prescriptionId: number;
  readonly primaryPrescriptionId: number;
};

export const DiscontinueActionSheet = ({ medicationName, prescriptionId, primaryPrescriptionId }: Props) => {
  const dispatch = useDispatchShared();
  const { invalidatePrescriptionsQuery, invalidateMedicationQuery } = useInvalidateMedDetails();
  const { invalidateListQuery } = useInvalidateMedList();
  const { addToast } = useContext(ToastContext);
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { handleRemoveFromCart } = useRemoveFromCart();
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionId));
  const error = useSelectorShared((state) => state.ui.errors.archivePrescriptionError);
  const { isPrescriptionInCart } = usePrescriptionsInCart();

  const handleDiscontinuePrescription = async () => {
    if (!prescription) return;
    // eslint-disable-next-line import/no-deprecated
    const success = await dispatch(archivePrescription(prescription));
    closeActionSheet();
    if (success) {
      invalidateMedicationQuery(primaryPrescriptionId);
      invalidateListQuery();

      // only need to invalidate OtherPrescriptions if not discontinuing the primary prescription
      if (primaryPrescriptionId !== prescriptionId) {
        invalidatePrescriptionsQuery();
      }

      if (isPrescriptionInCart(prescriptionId)) {
        handleRemoveFromCart({ item: prescriptionToItemKey(prescriptionId) });
      }
      addToast(<Toast variant="success">The prescription was discontinued.</Toast>);
    } else {
      addToast(<Toast variant="error">{getErrorMessage(error)}</Toast>);
    }
  };

  const handleCancel = () => {
    closeActionSheet();
  };

  return (
    <ActionSheetV2
      title={`Discontinue ${medicationName ? medicationName : 'this medication'}?`}
      analyticsName="discontinue prescription"
      buttons={[
        <Button
          testID="discontinue-prescription"
          key="discontinue"
          label="Yes, discontinue"
          type="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleDiscontinuePrescription}
        />,
        <Button
          key="cancel"
          testID="cancel-discontinue-prescription"
          label="No, cancel"
          type="tertiary"
          onPress={handleCancel}
        />,
      ]}
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Body>
          Discontinue if you no longer plan to fill this prescription. This keeps your medication list and prescriptions
          up-to-date.
        </Body>
        <SmSpacing />
        <Body fontFamily="semibold">Discontinuing cannot be undone.</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
