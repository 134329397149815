// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type BACKGROUND_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { type HOME_TO_SIGN_CONFIRMATION_MAP } from '~shared/features/checkout/constants';
import { isCourier } from '~shared/helpers/order';

type HomeToSignProps = {
  readonly hasControlledOrExpensiveMedication?: boolean;
  readonly isUnconfirmedShipment?: boolean;
  readonly homeToSignConfirmation?: keyof typeof HOME_TO_SIGN_CONFIRMATION_MAP;
  readonly safePlace?: boolean;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly method?: DeliveryMethod;
};

export const HomeToSignRow = ({
  backgroundColor,
  hasControlledOrExpensiveMedication,
  isUnconfirmedShipment,
  homeToSignConfirmation,
  method,
  safePlace,
}: HomeToSignProps) => {
  if ((hasControlledOrExpensiveMedication && homeToSignConfirmation === 'confirmed') || isUnconfirmedShipment) {
    return (
      <PresentationListItem
        backgroundColor={
          backgroundColor ??
          (isUnconfirmedShipment ? COLORS.BACKGROUND_COLORS.WHITE : COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST)
        }
        data-testid="home-to-sign-row"
        LeftContent={
          <AltoIcon
            name="signpaper"
            type="grey"
          />
        }
        text="Someone needs to be present to sign for this delivery"
      />
    );
  }

  if (isCourier(method?.mode) && safePlace === false && homeToSignConfirmation === 'confirmed') {
    return (
      <PresentationListItem
        data-testid="home-to-sign-row"
        LeftContent={
          <AltoIcon
            name="box"
            type="grey"
          />
        }
        text="Someone needs to be present to receive this delivery"
        backgroundColor={backgroundColor ?? COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST}
      />
    );
  }

  return null;
};
