// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getNextAvailableDateAddressID } from './getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from './getNextAvailableDateFacilityId';
import { getNextAvailableDates } from './getNextAvailableDates';
import { getPrescriptionsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import { getNextAvailableDateID } from '~shared/features/next-available-date/helpers/getNextAvailableDateID';
import { type ExcludedDate } from '~shared/types';

// @deprecated - use libs/shared/features/cart/hooks/useCartNextAvailableDate hook
const getNextAvailableDateIDForOrder__DEPRECATED = createSelector(
  [getNextAvailableDateAddressID, getNextAvailableDateFacilityID, getPrescriptionsInOrder__DEPRECATED],
  (addressID, facilityID, prescriptions): string | undefined => {
    if (prescriptions.length === 0 || !(addressID || facilityID)) {
      return undefined;
    }

    return getNextAvailableDateID({
      addressID,
      facilityID,
      prescriptionIDs: prescriptions.map((rx) => rx.id),
    });
  },
);

// @deprecated - use libs/shared/features/cart/hooks/useCartNextAvailableDate hook
const getNextAvailableDateObjectForOrder__DEPRECATED = createSelector(
  [getNextAvailableDates, getNextAvailableDateIDForOrder__DEPRECATED],
  (nextAvailableDates, nextAvailableDateIDForOrder) => {
    return nextAvailableDateIDForOrder ? nextAvailableDates[nextAvailableDateIDForOrder] : undefined;
  },
);

// @deprecated - use libs/shared/features/cart/hooks/useCartNextAvailableDate hook
export const getEarliestAvailableDateForOrder__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForOrder__DEPRECATED],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.earliest?.date || '';
  },
);

// @deprecated - use libs/shared/features/cart/hooks/useCartNextAvailableDate hook
export const getEarliestAvailableReasonForOrder__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForOrder__DEPRECATED],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.earliest?.reason || '';
  },
);

// @deprecated - use libs/shared/features/cart/hooks/useCartNextAvailableDate hook
export const getDeliveryMethodForOrder__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForOrder__DEPRECATED],
  (nextAvailableDateObject): string => {
    return nextAvailableDateObject?.deliveryMethod || '';
  },
);

// @deprecated - use libs/shared/features/cart/hooks/useCartNextAvailableDate hook
export const getExcludedDatesForOrder__DEPRECATED = createSelector(
  [getNextAvailableDateObjectForOrder__DEPRECATED],
  (nextAvailableDateObject): ExcludedDate[] => {
    return nextAvailableDateObject?.excluded || [];
  },
);
