// @owners { team: patients-team }
import { ANIMATION } from '@alto/design-library-tokens';
import { css } from 'styled-components';

type Props = {
  disabled?: boolean;
  loading?: boolean;
};

export const StyledTouchable = css`
  opacity: ${({ disabled, loading }: Props) => (disabled || loading ? '0.75' : '1')};
  transition: all ${ANIMATION.SPEEDS.FASTER / 1000}s ease-in-out;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  &:active {
    ${({ disabled, loading }: Props) => !disabled && !loading && 'opacity: 0.75;'};
  }
`;
