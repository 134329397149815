import { type ANALYTICS_EVENT, WEB_NAVIGATION_ACTIONS } from './constants';
import { type AdditionalFields, type Event } from './types';
import getCookie from '~shared/helpers/getCookie';

export const createEvent = (
  key: ANALYTICS_EVENT,
  properties?: Record<string, unknown> | null,
  additionalFields: AdditionalFields | null | undefined = {},
): Event => {
  const event: Event = {
    key,
    occurredAt: new Date(),
    ...additionalFields,
  };

  event.properties = properties || {};

  // if admin is logged in as user (only possible on web)
  // add property to every event to indicate this
  if (getCookie('admin')) {
    event.properties.is_admin = true;
  }

  return event;
};

export const viewedWebRoute = (action: any, routes: string[]): boolean => {
  if (!WEB_NAVIGATION_ACTIONS.includes(action.type)) {
    return false;
  }

  return routes.some((route) => action.payload && action.payload.pathname === route);
};

export const viewedWebMatchingRoute = (action: any, route: string): boolean => {
  if (!WEB_NAVIGATION_ACTIONS.includes(action.type)) {
    return false;
  }

  return action?.payload?.pathname.startsWith(`/${route}/`);
};

export const getUserIdentifier = (events: Event[]) => {
  const eventWithUserIdentifier = events.find((event) => event.userIdentifier && event.userIdentifierType);
  return {
    userIdentifier: eventWithUserIdentifier ? eventWithUserIdentifier.userIdentifier : null,
    userIdentifierType: eventWithUserIdentifier ? eventWithUserIdentifier.userIdentifierType : null,
  };
};

export const previousWebURL = (previousState: any): string | undefined => {
  return previousState?.routing?.locationBeforeTransitions?.pathname;
};
