// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Button, Column, LandingPage, Link, SmPadding, SmSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { logout } from '~shared/actions/auth';
import chatQuestion from '~shared/assets/images/chat_question.png';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { getUserSupportPhone } from '~shared/features/users/selectors/getUserSupportPhone';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { ContactUs } from '../contact-us/ContactUs';

export const FamilyMemberContact = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { goBack } = useNavigation();
  const { trackEvent } = useAnalytics();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const defaultPhoneNumber = useSelectorShared((state) => getUserSupportPhone(state, { userID: currentUserID }));

  const onLogoutPress = () => {
    dispatch(logout());
    queryClient.clear();
  };

  const onPressBack = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: { name: ASSISTANT_PRESSED_EVENTS.CLOSE_STATIC_FAMILY_MEMBER_CONTACT },
    });
    goBack();
  };

  return (
    <LandingPage
      title="Only family account owners can send messages"
      buttons={[
        <Button
          key="got-it"
          label="Got it"
          onPress={onPressBack}
        />,
      ]}
      onDismiss={onPressBack}
      illustrationSrc={chatQuestion}
      illustrationSize="xl"
      backgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
    >
      <SmPadding
        bottomPadding={SPACING.STATIC.NONE}
        topPadding={SPACING.STATIC.NONE}
      >
        <Column center>
          <Body center>
            If you need help, ask the family account owner to contact Alto on your behalf or call us at{' '}
            <ContactUs
              origin={CONTACT_US_ORIGIN.FamilyMemberStaticContactScreen}
              type="link"
              method="phone"
              customLabel={defaultPhoneNumber}
            />
            .
          </Body>
          <SmSpacing />
          <Body center>
            If you are the account owner, <Link onPress={onLogoutPress}>log out</Link> and sign in as the owner.
          </Body>
        </Column>
      </SmPadding>
    </LandingPage>
  );
};
