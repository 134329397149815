// @owners { team: patients-team }
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchFacilities } from '~shared/actions/facilities';
import { openModal } from '~shared/actions/modal';
import { getRouteState } from '~shared/selectors/getRouteState';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import SendPrescriptionOptions from './SendPrescriptionOptions';

type StateProps = {
  origin: string | null | undefined;
};

const mapStateToProps = (state: ReduxStateShared): StateProps => {
  const routeState = getRouteState(state);
  const origin = routeState?.origin;
  return {
    origin,
  };
};

type DispatchProps = {
  fetchFacilities: () => void;
  getStartTransfer: (origin: string | null | undefined) => () => void;
  openPharmacyInfoModal: () => void;
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared): DispatchProps => {
  return {
    // eslint-disable-next-line import/no-deprecated
    fetchFacilities: () => dispatch(fetchFacilities()),
    getStartTransfer: (origin) => {
      return () => {
        dispatch(
          push({
            pathname: '/transfer',
            state: {
              origin,
            },
          }),
        );
      };
    },
    openPharmacyInfoModal: () => {
      dispatch(openModal('PHARMACY_INFO_MODAL'));
    },
  };
};

type MergedProps = StateProps & DispatchProps;

// @ts-expect-error TS(7006): Parameter 'stateProps' implicitly has an 'any' type.
const mergeProps = (stateProps, dispatchProps, ownProps): MergedProps => {
  const { origin } = stateProps;

  const { getStartTransfer, ...otherDispatchProps } = dispatchProps;

  return { ...ownProps, ...otherDispatchProps, startTransfer: getStartTransfer(origin) };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SendPrescriptionOptions);
