import { useState } from 'react';
import { type NativeScrollEvent, type NativeSyntheticEvent } from 'react-native';

const SCROLL_EVENT_THROTTLE_MS = 200;

/**
 * Get if the scroll position of a <ScrollView> is at the top or bottom.
 */
export function useScrollPositionExtremes() {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  // Get the scroll position of the scroll view and set if we're at the top or
  // bottom so we can show the scroll indicator borders appropriately
  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // get the content height of the action sheet and subtract a pixel just in case, probably because of the border
    const sheetContentHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height - 1;
    const scrollPosition = event.nativeEvent.contentOffset.y;

    setIsAtTop(scrollPosition <= 0);
    setIsAtBottom(scrollPosition >= sheetContentHeight);
  };

  return {
    handleScroll,
    isAtBottom,
    isAtTop,
    scrollEventThrottle: SCROLL_EVENT_THROTTLE_MS,
  };
}
