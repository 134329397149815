import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { Platform, View } from 'react-native';
import styled from 'styled-components/native';
import { Pressable } from '../../buttons';
import { AltoIcon } from '../../icon';
import { XsSpacing } from '../../separators';
import { Text } from '../../typography';
import { type CoachmarkProps } from './Coachmark';
import { type Position } from './types';
import { COACHMARK_ARROW_WIDTH } from './utils';

const NoWrapBody = styled(Text)`
  text-align: center;
`;

const BaseArrow = styled(View)`
  position: absolute;
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-left-width: ${COACHMARK_ARROW_WIDTH * 2}px;
  border-right-width: ${COACHMARK_ARROW_WIDTH * 2}px;
  border-left-color: transparent;
  border-right-color: transparent;
  ${() =>
    Platform.OS === 'web'
      ? `left: calc(50% - ${COACHMARK_ARROW_WIDTH * 2}px);`
      : `left: 50%; margin-left: ${COACHMARK_ARROW_WIDTH}px;`}
`;

const TopArrow = styled(BaseArrow)<{ position?: Position }>`
  border-bottom-color: ${COLORS.BACKGROUND_COLORS.SECONDARY};
  border-bottom-width: ${COACHMARK_ARROW_WIDTH * 2}px;
  ${() => (Platform.OS === 'web' ? 'bottom: 100%;' : `top: -${COACHMARK_ARROW_WIDTH * 2}px;`)}
  ${({ position }) => {
    if (Platform.OS === 'web') {
      return position === 'bottom-left' ? `left: ${COACHMARK_ARROW_WIDTH * 3}px;` : '';
    }
    return position === 'bottom-left'
      ? `left: ${COACHMARK_ARROW_WIDTH * 2}px; margin-left: ${COACHMARK_ARROW_WIDTH}px;`
      : '';
  }}
  ${({ position }) => {
    if (Platform.OS === 'web') {
      return position === 'bottom-right' ? `left: calc(100% - ${COACHMARK_ARROW_WIDTH * 7}px);` : '';
    }
    return position === 'bottom-right' ? `left: 100%; margin-left: -${COACHMARK_ARROW_WIDTH * 2}px;` : '';
  }}
`;

const BottomArrow = styled(BaseArrow)<{ position?: Position }>`
  border-top-color: ${COLORS.BACKGROUND_COLORS.SECONDARY};
  border-top-width: ${COACHMARK_ARROW_WIDTH * 2}px;
  ${() => (Platform.OS === 'web' ? 'top: 100%;' : `bottom: -${COACHMARK_ARROW_WIDTH * 2}px;`)}
  ${({ position }) => {
    if (Platform.OS === 'web') {
      return position === 'top-left' ? `left: ${COACHMARK_ARROW_WIDTH * 3}px;` : '';
    }
    return position === 'top-left'
      ? `left: ${COACHMARK_ARROW_WIDTH * 2}px; margin-left: ${COACHMARK_ARROW_WIDTH}px;`
      : '';
  }}
  ${({ position }) => {
    if (Platform.OS === 'web') {
      return position === 'top-right' ? `left: calc(100% - ${COACHMARK_ARROW_WIDTH * 7}px);` : '';
    }
    return position === 'top-right' ? `left: 100%; margin-left: -${COACHMARK_ARROW_WIDTH * 2}px;` : '';
  }}
`;

const Container = styled(View)`
  flex-direction: row;
`;

type CoachmarkContentProps = Pick<CoachmarkProps, 'position' | 'content' | 'dismissible'> & {
  readonly dismissCoachmark: () => void;
};

export const CoachmarkContent = ({ position, content, dismissible, dismissCoachmark }: CoachmarkContentProps) => (
  <>
    {position === 'bottom' || position === 'bottom-left' || position === 'bottom-right' ? (
      <TopArrow position={position} />
    ) : null}
    <Container>
      <NoWrapBody color={COLORS.TEXT_COLORS.WHITE}>{content}</NoWrapBody>
      {dismissible ? (
        <>
          <XsSpacing />
          <Pressable
            backgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY}
            onPress={dismissCoachmark}
          >
            <AltoIcon
              name="close"
              type="white"
            />
          </Pressable>
        </>
      ) : null}
    </Container>
    {position === 'top' || position === 'top-left' || position === 'top-right' ? (
      <BottomArrow position={position} />
    ) : null}
  </>
);
