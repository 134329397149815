import {
  ActionSheetV2,
  InputRadio,
  InputRadioGroup,
  ListDescription,
  Tag,
  Toast,
  ToastContext,
} from '@alto/design-system';
import { type PriceOption, type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { useContext } from 'react';
import wallet from '~shared/assets/images/wallet.png';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { SELECT_PRICE_OPTION } from '~shared/features/checkout/constants';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getSelectedPrescriptionID } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { getPriceOptionsForPrescriptionInOrder } from '~shared/features/price-options/selectors/getPriceOptionsForPrescriptionInOrder';
import { PRICE_OPTION_LABELS } from '~shared/features/pricing/constants';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { getInsuranceName } from '~shared/helpers/insuranceName';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types';
import { useUpdateCartItem } from '../../cart';

function getOptionDescription(option: PriceOption, insurance: Insurance | undefined) {
  const { primaryName: insuranceName } = insurance ? getInsuranceName(insurance) : { primaryName: '' };

  let optionTypeText: string = PRICE_OPTION_LABELS[option.type ?? 'without_insurance'];
  if (option.type === 'with_insurance') {
    if (insuranceName) {
      optionTypeText = insuranceName;
    }
    optionTypeText += SELECT_PRICE_OPTION.with_insurance_subtitle;
  } else {
    optionTypeText += SELECT_PRICE_OPTION.without_insurance_subtitle;
  }
  return optionTypeText;
}

type SelectPriceOptionActionSheetProps = {
  readonly handleClose: () => void;
};

export const SelectPriceOptionActionSheet = ({ handleClose }: SelectPriceOptionActionSheetProps) => {
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const prescriptionID = useSelectorShared((state) => getSelectedPrescriptionID(state)) || 0;
  const priceOptions = useSelectorShared((state) => getPriceOptionsForPrescriptionInOrder(state, { prescriptionID }));
  const { cartItems } = useCartData();
  const selectedCartPaymentType = cartItems.find((item) => item.resource_id === prescriptionID)?.selected_price_type;
  const { handleUpdateCartItem } = useUpdateCartItem();
  const defaultOption = selectedCartPaymentType || priceOptions.find((o) => o?.selected)?.type || 'without_insurance';

  const option1 = priceOptions[0];
  const option2 = priceOptions[1];
  const option1Insurance = useSelectorShared((state) => getInsuranceByID(state, option1?.insurance_id || undefined));
  const option2Insurance = useSelectorShared((state) => getInsuranceByID(state, option2?.insurance_id || undefined));

  if (!option1 || !option2) {
    return null;
  }

  const lowestPricePaymentType = (option2.price || 0) < (option1.price || 0) ? option2.type : option1.type;

  const selectOption = async (type: string) => {
    if (prescriptionID) {
      const selectedUIPaymentType = type as PriceType;

      const res = await handleUpdateCartItem({
        resource_type: 'Prescription',
        resource_id: prescriptionID,
        selected_price_type: selectedUIPaymentType,
      });
      if (res.success) {
        const priceOption = priceOptions.find((option) => option.type === selectedUIPaymentType);
        trackEvent({
          event: EVENTS.PRICE_SELECTED,
          params: {
            price: priceOption?.price,
            price_type: priceOption?.type,
            payment_type: selectedUIPaymentType,
            source: 'checkout',
          },
        });

        handleClose();
      } else {
        addToast(
          <Toast variant="error">
            Unable to select payment type selection for prescription. If the problem persists please contact support.
          </Toast>,
        );
      }
    }
  };

  return (
    <ActionSheetV2
      title={SELECT_PRICE_OPTION.title}
      description={SELECT_PRICE_OPTION.subtitle}
      illustrationSrc={wallet}
      analyticsName="checkout select price option"
    >
      <InputRadioGroup
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onValueChange={selectOption}
        defaultValue={defaultOption}
      >
        <InputRadio
          value={option1.type ?? 'without_insurance'}
          label={formatDollarsWithPlaceholder(option1.price)}
          descriptions={[
            <ListDescription key={option1.insurance_id}>
              {getOptionDescription(option1, option1Insurance)}
            </ListDescription>,
          ]}
          tag={
            lowestPricePaymentType === option1.type ? (
              <Tag
                type="recommended"
                label={SELECT_PRICE_OPTION.lowest_price_tag}
              />
            ) : undefined
          }
        />
        <InputRadio
          value={option2.type ?? 'without_insurance'}
          label={formatDollarsWithPlaceholder(option2.price)}
          descriptions={[
            <ListDescription key={option2.insurance_id}>
              {getOptionDescription(option2, option2Insurance)}
            </ListDescription>,
          ]}
          tag={
            lowestPricePaymentType === option2.type ? (
              <Tag
                type="recommended"
                label={SELECT_PRICE_OPTION.lowest_price_tag}
              />
            ) : undefined
          }
        />
      </InputRadioGroup>
    </ActionSheetV2>
  );
};
