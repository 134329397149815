// @owners { team: patients-team }
import { type DeliveryWithPrescription } from '~shared/types';

export const getAutoRefillNamesForDeliveries = (deliveries: DeliveryWithPrescription[]) => {
  return deliveries.reduce<string[]>((names, { prescription }) => {
    const { auto_refill, medication_name } = prescription;
    if (auto_refill && medication_name) {
      names.push(medication_name);
    }

    return names;
  }, []);
};
