// @owners { team: patients-team }

import { SET_ANNOUNCEMENT_VIEWED, type SetAnnouncementViewed } from '~shared/actions/ui/announcements';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateUiAnnouncements = {
  viewedAnnouncements: Partial<Record<number, boolean>>;
};

export const initialState: StateUiAnnouncements = {
  viewedAnnouncements: {},
};

const announcements = (
  state: StateUiAnnouncements = initialState,
  action: SetAnnouncementViewed,
): StateUiAnnouncements => {
  const { type } = action;

  switch (type) {
    case SET_ANNOUNCEMENT_VIEWED: {
      const { payload: announcementID } = action;
      return { ...state, viewedAnnouncements: { ...state.viewedAnnouncements, [announcementID]: true } };
    }

    default:
      return state;
  }
};

export default withRehydration(announcements, initialState);
