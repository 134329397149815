// @owners { team: patients-team }
import { NavBarLogo } from '@alto/design-system';
import React from 'react';

export const AltoLogoNavBar = () => {
  return (
    <NavBarLogo
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      source={require('./organic_onboarding_alto_logo@2x.png')}
      height={'SM'}
      width={'LG'}
      accessibilityLabel="alto-logo"
      testID="alto-logo"
    />
  );
};
