// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, Description, Pressable } from '@alto/design-system';
import React, { useContext } from 'react';
import { FeeDetailsActionSheet } from '../select-date-card/FeeDetailsActionSheet';

export const FeeDetails = () => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const handleFeeDetailsPress = () => {
    setActiveActionSheet(<FeeDetailsActionSheet />);
  };

  return (
    <Pressable onPress={handleFeeDetailsPress}>
      <Description
        color={COLORS.TEXT_COLORS.SECONDARY_DARK}
        fontFamily="semibold"
      >
        Fee details
      </Description>
    </Pressable>
  );
};
