export const FONT = {
  BODY: {
    REGULAR: 'Inter-Regular',
    REGULAR_ITALIC: 'Inter-Italic',
    SEMIBOLD: 'Inter-SemiBold',
    SEMIBOLD_ITALIC: 'Inter-SemiBoldItalic',
    BOLD: 'Inter-Bold',
    BOLD_ITALIC: 'Inter-BoldItalic',
  } as const,
  HEADER: {
    REGULAR: 'GalaxieCopernicus-Book',
    SEMIBOLD: 'GalaxieCopernicus-Semibold',
  } as const,
} as const;
