import React from 'react';
import { Image, type ImageErrorEventData, type ImageSourcePropType, type NativeSyntheticEvent } from 'react-native';
import styled from 'styled-components/native';
import { Row } from '../../containers';

export type SizedImageProps = {
  // TODO: make accessibilityLabel required
  readonly accessibilityLabel?: string;
  readonly aspectRatio?: number;
  readonly borderRadius?: number;
  readonly maxHeight?: number;
  readonly pixelSize?: number;
  readonly center?: boolean;
  readonly resizeMethod?: 'auto' | 'resize' | 'scale';
  readonly resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center';
  readonly source: ImageSourcePropType;
  readonly onError?: (error: NativeSyntheticEvent<ImageErrorEventData>) => void;
};

type StyledImageProps = {
  aspectRatio: SizedImageProps['aspectRatio'];
  borderRadius: SizedImageProps['borderRadius'];
  maxHeight: SizedImageProps['maxHeight'];
  pixelSize: SizedImageProps['pixelSize'];
};

const StyledImage = styled(Image)<StyledImageProps>`
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
  ${({ pixelSize }) => (pixelSize ? `width: ${pixelSize}px` : `width: 100%`)};
  ${({ pixelSize }) => pixelSize && `height: ${pixelSize}px`};
`;

export const SizedImage = ({
  accessibilityLabel,
  aspectRatio,
  borderRadius,
  maxHeight,
  pixelSize,
  resizeMethod,
  center,
  resizeMode,
  source,
  onError,
}: SizedImageProps) => {
  return (
    <Row center={center}>
      <StyledImage
        accessibilityLabel={accessibilityLabel}
        aspectRatio={aspectRatio}
        borderRadius={borderRadius}
        maxHeight={maxHeight}
        pixelSize={pixelSize}
        resizeMethod={resizeMethod || 'auto'}
        resizeMode={resizeMode}
        source={source}
        onError={onError}
        testID="image"
      />
    </Row>
  );
};
