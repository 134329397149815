import { type BACKGROUND_COLORS_VALUES, BORDERS, COLORS, SHADOWS, SIZES, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { type ImageSourcePropType, type LayoutChangeEvent, Platform, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { useScrollPositionExtremes } from '../../../hooks/useScrollPositionExtremes';
import { IPHONE_14_TOP_SAFE_AREA_INSET, getSafeAreaHeight } from '../../../utils';
import { PressableAltoIcon } from '../../buttons';
import { Column, LgPadding, Row } from '../../containers';
import { Illustration } from '../../images';
import { MdSpacing, SmSpacing, XsSpacing } from '../../separators';
import { type Tag } from '../../tags';
import { Description, H2 } from '../../typography';
import { BasePage } from './BasePage';
import { NavBar } from './NavBar';

type SplitPageProps = {
  readonly HeaderContent: React.ReactNode | React.ReactNode[];
  readonly onBack: () => void;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly FooterContent?: React.ReactNode | React.ReactNode[];
  readonly Tag?: React.ReactElement<typeof Tag>;
  readonly title: string;
  readonly subtitle?: string;
  readonly description?: string;
  readonly illustrationSrc?: ImageSourcePropType;
};

const PageContainer = styled(Column)<{ topInset: number }>`
  padding-top: ${({ topInset }) => topInset}px;
`;

const TopContainer = styled(Row)<{ footerHeight: number; windowHeight: number }>`
  height: ${({ footerHeight, windowHeight }) => windowHeight / 2 - footerHeight / 2}px;
`;

const BottomContainer = styled(Column)<{ footerHeight: number; windowHeight: number }>`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: ${BORDERS.RADIUS.LG.px};
  border-top-right-radius: ${BORDERS.RADIUS.LG.px};
  box-shadow: 0 1px 1px ${COLORS.BORDER_COLORS.DARK};
  height: ${({ footerHeight, windowHeight }) => windowHeight / 2 + footerHeight / 2}px;
`;

const FooterContainer = styled(LgPadding)<{ isAtBottom: boolean }>`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-top-width: ${BORDERS.SIZE}px;
  border-top-color: ${({ isAtBottom }) => (isAtBottom ? 'transparent' : COLORS.BORDER_COLORS.LIGHT)};
  ${({ isAtBottom }) => (isAtBottom ? '' : SHADOWS.TOP)}
`;

const IllustrationAnchor = styled(View)`
  position: absolute;
  right: ${SPACING.STATIC.LG.px};
  top: -${SIZES.ILLUSTRATION.LG.value / 2}px;
  z-index: 1;
`;

const StyledTitle = styled(Row)<{ illustrationSrc?: ImageSourcePropType }>`
  width: ${({ illustrationSrc }) => (illustrationSrc ? '75%' : '100%')};
`;

export const SplitPage = ({
  HeaderContent,
  onBack,
  backgroundColor = COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST,
  FooterContent,
  Tag,
  title,
  subtitle,
  description,
  illustrationSrc,
}: SplitPageProps) => {
  const { top } = useSafeAreaInsets();
  const safeWindowHeight = getSafeAreaHeight();
  // handling dynamic island for iPhone 14 Pro / ProMax
  const iconTopInset =
    top === IPHONE_14_TOP_SAFE_AREA_INSET && Platform.OS === 'ios'
      ? SPACING.STATIC.XL.value
      : top + SPACING.STATIC.SM.value;
  const [scrollEnabled, setScrollEnabled] = React.useState(false);
  const { handleScroll, isAtBottom, scrollEventThrottle } = useScrollPositionExtremes();

  // Get the height of the bottom section content that wraps the ScrollView so
  // that we can determine if the content needs to scroll or not
  const [bottomContentHeight, setBottomContentHeight] = React.useState(0);
  const handleBottomContentLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setBottomContentHeight(height);
  };

  // Get the height of the footer section so we can subtract it from the top container height and
  // add it to the bottom container height. This makes the and bottom sections look more equal.
  const [footerHeight, setFooterHeight] = React.useState(0);
  const handleFooterLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };

  return (
    <BasePage
      backgroundColor={backgroundColor}
      scrollEnabled={false}
      transparentNavBar
      bottomSafeAreaColor={COLORS.BACKGROUND_COLORS.WHITE}
      NavBar={
        <NavBar
          LeftPressableAltoIcon={
            <PressableAltoIcon
              name="chevronleft"
              onPress={onBack}
              accessibilityLabel="Press to go back"
            />
          }
        />
      }
    >
      <PageContainer
        backgroundColor={backgroundColor}
        flexGrow={1}
        topInset={iconTopInset}
      >
        <TopContainer
          footerHeight={footerHeight}
          windowHeight={safeWindowHeight}
        >
          {HeaderContent}
        </TopContainer>
        <BottomContainer
          flexGrow={1}
          footerHeight={footerHeight}
          windowHeight={safeWindowHeight}
        >
          {!!illustrationSrc && (
            <IllustrationAnchor>
              <Illustration
                size="lg"
                source={illustrationSrc}
              />
            </IllustrationAnchor>
          )}
          <Column
            flexGrow={1}
            flexShrink={1}
            onLayout={handleBottomContentLayout}
          >
            <ScrollView
              onContentSizeChange={(_, height) => {
                setScrollEnabled(height > bottomContentHeight);
              }}
              onScroll={handleScroll}
              scrollEnabled={scrollEnabled}
              scrollEventThrottle={scrollEventThrottle}
            >
              <LgPadding bottomPadding={SPACING.STATIC.NONE}>
                {!!Tag && (
                  <>
                    {Tag}
                    <MdSpacing />
                  </>
                )}
                <StyledTitle illustrationSrc={illustrationSrc}>
                  <H2 color={COLORS.TEXT_COLORS.PRIMARY}>{title}</H2>
                </StyledTitle>
                {!!subtitle && (
                  <>
                    <SmSpacing />
                    <Description fontFamily="semibold">{subtitle}</Description>
                  </>
                )}
                {!!description && (
                  <>
                    <XsSpacing />
                    <Description>{description}</Description>
                  </>
                )}
              </LgPadding>
            </ScrollView>
          </Column>
          <FooterContainer
            isAtBottom={!scrollEnabled || isAtBottom}
            topPadding={SPACING.STATIC.MD}
            bottomPadding={SPACING.STATIC.MD}
          >
            <View onLayout={handleFooterLayout}>{FooterContent}</View>
          </FooterContainer>
        </BottomContainer>
      </PageContainer>
    </BasePage>
  );
};
