import { type Component, useCallback } from 'react';
import { AccessibilityInfo, Platform, UIManager, findNodeHandle } from 'react-native';

/**
 * A hook that will set screen reader focus on mount of whichever component you pass the returned ref into
 *
 * Usage:
 * ```ts
 * const MyComponent = () => {
 *   const ref = useAccessibilityFocus(true);
 *   return (
 *     <Text ref={ref}>
 *       Some Text
 *     </Text>
 *   )
 * }
 * ```
 *
 * @param autofocus controls whether the focus code will run.
 * Allows for use of this hook in generic components like H1 where you don't always want to focus.
 */
export const useAccessibilityFocus = (autofocus?: boolean) => {
  return useCallback(
    (node: Component<any, any> | null) => {
      if (!autofocus) {
        return;
      }
      if (node !== null) {
        if (Platform.OS === 'web') {
          // focus using react-native-web's recommended technique.
          // Unfortunately this crashes on native, so we're only doing it in web.
          // https://necolas.github.io/react-native-web/docs/accessibility/#keyboard-focus
          // @ts-expect-error TS doesn't know about the focus method, but it is there
          UIManager.focus(node);
        }

        const nodeHandle = findNodeHandle(node);
        if (nodeHandle) {
          AccessibilityInfo.setAccessibilityFocus(nodeHandle);
        }
      }
    },
    [autofocus],
  );
};
