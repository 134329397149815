import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';
import { type Facility } from '~shared/types/facility';

const getFacilities = (state: ReduxStateShared) => state.facilities.facilities;

export type FacilityOptions = {
  label: Facility['name'];
  value: Facility['id'];
  data: Facility;
}[];

const getFacilityOptions = createSelector(
  getFacilities,
  (facilities): FacilityOptions =>
    facilities
      .sort((f1, f2) => {
        return f1.name && f2.name && f1.name < f2.name ? -1 : 1; // alphabetical order
      })
      .map((facility) => {
        return {
          label: facility.name,
          value: facility.id,
          data: facility,
        };
      }),
);

export default getFacilityOptions;
