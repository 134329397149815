// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Card,
  LgPadding,
  ListBase,
  ListButton,
  MdPadding,
  PresentationListItem,
  SmSpacing,
  Tag,
} from '@alto/design-system';
import { CloseTransferReasonMap } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/close_transfer_reason';
import { type Transfer } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/transfer';
import React from 'react';
import { Platform, View } from 'react-native';
import prescription from '~shared/assets/images/prescription.png';
import { formatFullAddress } from '~shared/helpers/address';
import { formatRelativeDate } from '~shared/helpers/date';

type Props = {
  readonly transfer: Transfer;
  readonly onPress: () => void;
};

export const CompletedTransferCard = ({ transfer, onPress }: Props) => {
  const { all_active, medication_names, pharmacy_name, pharmacy_address, date_requested, close_reason } = transfer;
  const dateString = formatRelativeDate(date_requested, { includeRelative: true });
  const medicationString = all_active ? 'All medications on file' : medication_names?.join(', ');
  const completed =
    close_reason === CloseTransferReasonMap.TRANSFER_COMPLETED ||
    close_reason === CloseTransferReasonMap.TRANSFER_COMPLETED_WAS_NOT_LINKED_AT_INTAKE;
  const tagString = completed ? 'COMPLETED' : 'CLOSED';

  const getPharmacyString = () => {
    const pharmacyAddressString = formatFullAddress(pharmacy_address);

    if (pharmacy_name && pharmacy_address) {
      if (pharmacy_address.street_address_1) {
        return `From ${pharmacy_name}, ${pharmacyAddressString}`;
      }

      return `From ${pharmacy_name + pharmacyAddressString}`;
    }

    if (pharmacy_name) {
      return `From ${pharmacy_name}`;
    }

    if (pharmacy_address) {
      return `From pharmacy located at ${pharmacyAddressString}`;
    }

    return '';
  };

  // makes sure that styling matches existing homescreen components on web and native
  // we can remove this once we have React Native web support across the entire homescreen
  const Container = Platform.OS === 'web' ? View : MdPadding;

  return (
    <Container
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      <Card
        title="Prescription transfer"
        illustrationSrc={prescription}
        illustrationAccessibilityLabel="Illustration of a Prescription"
        tag={
          <Tag
            type={completed ? 'success' : undefined}
            label={tagString}
          />
        }
      >
        <ListBase
          items={[
            {
              key: 'date',
              component: (
                <PresentationListItem
                  text={`Requested ${dateString}`}
                  iconName="calendar"
                  type="grey"
                />
              ),
            },
            {
              key: 'pharmacy',
              component: (
                <PresentationListItem
                  text={getPharmacyString()}
                  iconName="mapmarker"
                  type="grey"
                />
              ),
            },
            {
              key: 'medications',
              component: (
                <PresentationListItem
                  text={medicationString || ''}
                  iconName="pillbottle"
                  type="grey"
                />
              ),
            },
          ]}
        />
        <SmSpacing />
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <ListButton
            label="View Details"
            onPress={onPress}
            rightIcon="chevronright"
          />
        </LgPadding>
      </Card>
    </Container>
  );
};
