// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column } from '@alto/pocky';
import React from 'react';
import { push } from 'react-router-redux';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { ColoredFlexRow } from './Header';
import ChecklistInfoRow from '~web/components/ChecklistInfoRow';
import { CTAButton, CheckBoxText, HeadImage, IntroText } from '~web/features/onboarding/components/styles';
import { palette, spacing } from '~web/features/onboarding/components/styles/styles';
import pharmacist from '~web/images/pharmacistJennyGreen@3x.png';

type Props = {
  tempPassword: string;
};

export const StartOrderButton = ({ tempPassword }: Props) => {
  const { trackEvent } = useAnalytics();
  const { isSMScreenOrBigger } = useScreenSize();
  const dispatch = useDispatchShared();

  const handleStartOrderPress = () => {
    trackEvent({
      event: EVENTS.WEB_LANDING_PAGE__GET_STARTED_TAPPED,
      params: {},
      additionalFields: {
        userIdentifier: tempPassword,
        userIdentifierType: 'temp_password',
      },
    });

    dispatch(push(`/onboard/verify/${tempPassword}`));
  };

  return (
    <ColoredFlexRow
      color={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      spacing={spacing.lgSpacing}
      mSpacing={spacing.mdSpacing}
      padding={spacing.xxlSpacing}
      mPadding={`${spacing.mdSpacing} ${spacing.mdlgSpacing} ${spacing.mdlgSpacing}`}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <HeadImage
        src={pharmacist}
        alt="Headshot of friendly pharmacist"
        isSMScreenOrBigger={isSMScreenOrBigger}
      />
      <ColoredFlexRow
        spacing={spacing.mdSpacing}
        mSpacing={spacing.smSpacing}
        isSMScreenOrBigger={isSMScreenOrBigger}
      >
        <IntroText isSMScreenOrBigger={isSMScreenOrBigger}>
          Hi I’m Jenny, a pharmacist at Alto. We received your prescriptions and are ready to:
        </IntroText>
        <Column>
          <ChecklistInfoRow>
            <CheckBoxText isSMScreenOrBigger={isSMScreenOrBigger}>Find you a great price</CheckBoxText>
          </ChecklistInfoRow>
          <ChecklistInfoRow>
            <CheckBoxText isSMScreenOrBigger={isSMScreenOrBigger}>Deliver the same day for free!</CheckBoxText>
          </ChecklistInfoRow>
        </Column>
      </ColoredFlexRow>
      <CTAButton
        buttonText="Start Your Order"
        color={palette.citrus}
        hoverColor={palette.darkCitrus}
        onClick={handleStartOrderPress}
      />
    </ColoredFlexRow>
  );
};
