// @owners { team: patients-team }
import { useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses } from '~shared/actions/addresses';
import { clearCart, clearEditOrder, removeAutoRefills__DEPRECATED, saveOrderSucceeded } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPricing } from '~shared/actions/pricing';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { getItemsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import { getOrder, getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { type AnalyticsProps, SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentTipsForUser } from '~shared/features/courier-tips/actions';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import { updateAddToShipmentError } from '~shared/features/essentials/actions';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { formatDollars } from '~shared/helpers/currency';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useQueryShipmentFeesAvailable } from '~shared/queries/useQueryShipmentFeesAvailable';
import { exitOrderBundling } from '~shared/reducers/ui/checkoutFlow';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { homescreenMethods } from '../../homescreen';
import { medications } from '../../med-list/queries/useMedList';
import { useSendCreateOrderAnalyticsEvent } from './useSendCreateOrderAnalyticsEvent';

export const useHandleCreateOrderSuccess = () => {
  const dispatch = useDispatchShared();
  const queryClient = useQueryClient();
  const { sendCreateOrderAnalyticsEvent } = useSendCreateOrderAnalyticsEvent();
  const { invalidateShipmentFeesAvailable } = useQueryShipmentFeesAvailable();
  const order = useSelectorShared(getOrder);
  const isEditing = useSelectorShared(getIsEditingExistingOrder);
  const isOrderBundling = useSelectorShared(getIsOrderBundling);
  const address = useSelectorShared(getOrderAddress);
  const user = useSelectorShared(getCurrentUser);
  const items = useSelectorShared(getItemsInOrder__DEPRECATED);

  const handleCreateOrderSuccess = ({ shipmentIds }: { shipmentIds?: number[] | null }) => {
    const shipmentIDs = shipmentIds ?? [];
    sendCreateOrderAnalyticsEvent({ shipmentIds });

    // when editing delivery, we don't have a good way of knowing whether the tip was updated or left as is.
    // we'll just send an analytics event for any non-zero tip.
    if (order.tip_amount) {
      const eventKey = isEditing
        ? EVENTS.COURIER_TIPS__EDIT_DELIVERY_TIP_SUBMITTED
        : EVENTS.COURIER_TIPS__PRE_DELIVERY_TIP_SUBMITTED;
      const analyticsProps: AnalyticsProps = {
        // the courier delivery is the last shipment id for split shipments
        shipment_id: isEditing ? shipmentIDs[0] : shipmentIDs[shipmentIDs.length - 1],
        tip_value: formatDollars(order.tip_amount, false),
        source: isEditing ? SOURCES.EDIT_DELIVERY : SOURCES.PRE_DELIVERY,
        facility_id: address?.dispensing_facility_id,
      };
      dispatch(sendTipAnalyticsEvent({ eventKey, analyticsProps }));
    }

    dispatch(saveOrderSucceeded());

    // refresh shipment-related data
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchDeliveries());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchAddresses());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchShipments());
    dispatch(updateAddToShipmentError({ error: null, quantityAdded: null }));
    if (isEditing && !!order.tip_amount && shipmentIds?.length === 1) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipmentPricing(shipmentIds[0]));
    }

    if (!!order.tip_amount && user?.id) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipmentTipsForUser(user?.id));
    }

    const deliveries = items.map((item) => item.apiPayload());
    const prescriptionIDs = deliveries.map((item) => item.prescription_id);
    dispatch(removeAutoRefills__DEPRECATED(prescriptionIDs));

    if (isEditing) {
      dispatch(clearEditOrder());
    } else {
      dispatch(clearCart());
    }

    if (isOrderBundling) {
      dispatch(exitOrderBundling());
    }

    queryClient.invalidateQueries({ queryKey: homescreenMethods.fetchHomescreen({ userID: user?.id }).queryKey });
    // invalidate all med list queries, ignoring query parameters
    queryClient.invalidateQueries({ queryKey: medications.list._def });
    invalidateShipmentFeesAvailable();
  };

  return { handleCreateOrderSuccess };
};
