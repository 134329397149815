// @owners { team: patients-team }
import { Sentry } from '@alto/performance';
import {
  NotificationsEndpoint,
  type NotificationsEndpointReadAllCurrentlyUnreadRequest,
} from '@alto/scriptdash/alto/customer_support/patients/v1/notifications_endpoint';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';
import { notifications } from '../../alto-assistant';

const notificationsEndpoint = NotificationsEndpoint(apiEndpointHandler);

const markAllCurrentlyUnread = async (params: NotificationsEndpointReadAllCurrentlyUnreadRequest) => {
  return notificationsEndpoint.readAllCurrentlyUnread(params);
};

export const useReadAllCurrentlyUnread = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: markAllCurrentlyUnread,
    onError: (error: Error) => {
      Sentry.captureException(error);
    },
    onSettled: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: notifications.fetchNotifications._def });
    },
  });
  return { readAllCurrentlyUnread: mutate };
};
