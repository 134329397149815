export const SELECT_MEDICATION = 'SELECT_MEDICATION';
export type ActionSelectMedication = {
  type: typeof SELECT_MEDICATION;
  payload: {
    medicationKey: string | null | undefined;
    medicationName: string | null | undefined;
  };
};
export function selectMedication(
  medicationKey: string | null | undefined,
  medicationName?: string | null,
): ActionSelectMedication {
  return {
    type: SELECT_MEDICATION,
    payload: {
      medicationKey,
      medicationName,
    },
  };
}
