// @owners { team: patients-team }
import { getNextAvailableDates } from '~shared/features/next-available-date/selectors/getNextAvailableDates';
import { getNextAvailableDatesByPrescriptionID } from '~shared/features/next-available-date/selectors/getNextAvailableDatesByPrescriptionID';
import { type NextAvailableDateMapping } from '~shared/reducers/nextAvailableDates';
import { useSelectorShared } from '~shared/store';
import { type NextAvailableDate } from '~shared/types';

type NextAvailableDatesForPrescriptionsParams = {
  addressID?: number;
  facilityID?: number;
  prescriptionID?: number;
  shipmentID?: number;
};

type NextAvailableDatesForPrescriptionsResponse = {
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  nextAvailableDate: NextAvailableDate | null;
  nextAvailableDates?: NextAvailableDateMapping;
  nextAvailableDatesByPrescriptionID: Record<number, NextAvailableDate>;
};

export const useNextAvailableDatesForPrescriptions = (
  params: NextAvailableDatesForPrescriptionsParams = {},
): NextAvailableDatesForPrescriptionsResponse => {
  const { prescriptionID } = params;
  const nextAvailableDatesByPrescriptionID = useSelectorShared(getNextAvailableDatesByPrescriptionID);
  const nextAvailableDates = useSelectorShared(getNextAvailableDates);

  return {
    isError: false,
    isLoading: false,
    isSuccess: true,
    nextAvailableDate: prescriptionID ? nextAvailableDatesByPrescriptionID[prescriptionID] : null,
    nextAvailableDates,
    nextAvailableDatesByPrescriptionID,
  };
};
