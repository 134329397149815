// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Column, ListBase, ListButton, MdPadding, MdSpacing } from '@alto/design-system';
import React, { useEffect, useState } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useMedDetailsPrescriptions } from '../queries/useMedDetails';
import { PrescriptionCard } from './prescription-card';

export const OtherPrescriptions = ({
  primaryPrescriptionId,
  useAddToCartInterstitialSheets,
}: {
  readonly primaryPrescriptionId: number;
  readonly useAddToCartInterstitialSheets: any;
}) => {
  const [showingOtherPrescriptions, setShowingOtherPrescriptions] = useState(false);
  const { trackEvent } = useAnalytics();
  const { prescriptions } = useMedDetailsPrescriptions({ primaryPrescriptionId });
  const handleToggleOtherPrescriptions = () => {
    setShowingOtherPrescriptions(!showingOtherPrescriptions);
  };

  useEffect(() => {
    if (showingOtherPrescriptions) {
      trackEvent({
        event: EVENTS.MED_DETAILS__OTHER_PRESCRIPTIONS_VIEWED,
      });
    }
  }, [showingOtherPrescriptions, trackEvent]);

  return (
    <Column>
      <ListButton
        rightIcon={showingOtherPrescriptions ? 'chevronup-small' : 'chevrondown-small'}
        label={showingOtherPrescriptions ? 'Hide other prescriptions' : 'Other prescriptions'}
        onPress={handleToggleOtherPrescriptions}
      />
      {prescriptions && showingOtherPrescriptions ? (
        <MdPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <ListBase
            separator={<MdSpacing />}
            items={prescriptions.map((prescription) => ({
              key: `${prescription.rx_number}-${prescription.id}`,
              component: (
                <PrescriptionCard
                  isPrimary={false}
                  key={prescription.rx_number}
                  prescription={prescription}
                  useAddToCartInterstitialSheets={useAddToCartInterstitialSheets}
                  primaryPrescriptionId={primaryPrescriptionId}
                />
              ),
            }))}
          />
        </MdPadding>
      ) : null}
    </Column>
  );
};
