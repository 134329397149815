// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModalBody, LoaderImage, spinnerStyles } from '@alto/pocky';
import React, { type ReactNode, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchSudo, setSudo } from '~shared/actions/auth';
import { type ModalType, closeModal } from '~shared/actions/modal';
import getModalType from '~shared/selectors/modal/getModalType';
import getSudoError from '~shared/selectors/ui/errors/getSudoError';
import getFetchSudoLoading from '~shared/selectors/ui/loading/getSudoLoading';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import ReauthModal from '~web/features/profile/components/ReauthModal';

type Props = {
  readonly modalType: ModalType | null;
  readonly fetchSudoLoading: boolean;
  readonly sudoError: APIError | null | undefined;
  readonly closeModal: (modalType: ModalType | null) => void;
  readonly fetchSudo: () => Promise<any>;
  readonly setSudo: (password: string) => Promise<any>;
  readonly children?: ReactNode | ReactNode[];
};

function ReauthRequired({ modalType, fetchSudoLoading, sudoError, closeModal, fetchSudo, setSudo, children }: Props) {
  const [sudoState, setSudoState] = useState(false);

  // @ts-expect-error TS(7031): Binding element 'password' implicitly has an 'any' type.
  const submitPassword = ({ password }) => {
    return setSudo(password).then(({ sudo, error }) => {
      // eslint-disable-next-line promise/always-return
      if (!error) {
        setSudoState(sudo);
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    fetchSudo().then(({ sudo, error }) => {
      // eslint-disable-next-line promise/always-return
      if (!error) {
        setSudoState(sudo);
      }
    });
  }, [fetchSudo]);

  if (fetchSudoLoading) {
    return (
      <DeprecatedModalBody>
        <LoaderImage style={spinnerStyles} />
      </DeprecatedModalBody>
    );
  }

  if (sudoState && !!children) {
    return <div>{children}</div>;
  }

  if (!sudoState) {
    return (
      <ReauthModal
        onSubmit={submitPassword}
        onCancel={() => {
          closeModal(modalType);
        }}
        error={sudoError}
      />
    );
  }

  return null;
}

const mapStateToProps = (state: ReduxStateShared) => ({
  modalType: getModalType(state),
  fetchSudoLoading: getFetchSudoLoading(state),
  sudoError: getSudoError(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    closeModal: (modalType: ModalType | null) => dispatch(closeModal(modalType)),
    // eslint-disable-next-line import/no-deprecated
    fetchSudo: () => dispatch(fetchSudo()),
    // eslint-disable-next-line import/no-deprecated
    setSudo: (password: string) => dispatch(setSudo(password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReauthRequired);
