import { SmSpacing } from '@alto/design-system';
import React from 'react';
import { push } from 'react-router-redux';
import essentialsIcon from '~shared/assets/images/essentials_nav_list_icon@2x.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { MedicationsSectionNavigator } from './MedicationsSectionNavigator';
import illustrationMedications from '~web/images/prescription@2x.png';
import illustrationStethoscope from '~web/images/stethoscope@2x.png';

type Props = {
  readonly navigateToSendPrescriptionOptions: () => void;
  readonly openPharmacyInfoModal: () => void;
};

export default function SendMedications({ navigateToSendPrescriptionOptions, openPharmacyInfoModal }: Props) {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();

  const navigateToEssentialsStore = () => {
    trackEvent({ event: EVENTS.ESSENTIALS__SHOP_ESSENTIALS_CARD_TAPPED });
    dispatch(push('/store'));
  };

  return (
    <>
      <MedicationsSectionNavigator
        illustration={essentialsIcon}
        illustrationAlt="image of essential items"
        label="Shop Essentials"
        description="Browse over-the-counter products"
        onClick={navigateToEssentialsStore}
      />
      <MedicationsSectionNavigator
        illustration={illustrationMedications}
        illustrationAlt={IMG_ALT_TEXTS.prescription}
        label="Transfer to Alto"
        description="Transfer from another pharmacy"
        onClick={navigateToSendPrescriptionOptions}
      />
      <MedicationsSectionNavigator
        illustration={illustrationStethoscope}
        illustrationAlt={IMG_ALT_TEXTS.stethoscope}
        label="See Alto's pharmacy info"
        description="Share it with your provider"
        onClick={openPharmacyInfoModal}
      />
      <SmSpacing />
    </>
  );
}
