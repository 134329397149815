// @owners { team: patients-team }
import {
  AddOnsEndpoint,
  type AddOnsEndpointAddToCartRequest,
} from '@alto/scriptdash/alto/patient_app/add_ons/v1/add_ons_endpoint';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useMutation } from '~shared/react-query';

const AddOnsApi = AddOnsEndpoint(apiEndpointHandler);

export const ADD_ONS_ADD_TO_CART_MUTATION_KEY = ['addOnsAddToCart'];

export const useAddOnsAddToCart = () => {
  return useMutation({
    mutationFn: (params: AddOnsEndpointAddToCartRequest) => AddOnsApi.addToCart(params),
    mutationKey: ADD_ONS_ADD_TO_CART_MUTATION_KEY,
  });
};
