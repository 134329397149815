import { isEqual } from 'lodash';
import React, { memo } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Column, Row } from '../../containers';

const ContentWrapper = styled(View)`
  overflow: visible;
`;

export type ListBaseItem = {
  key: number | string;
  component: React.ReactNode;
};

type Props = {
  readonly items: ListBaseItem[];
  readonly separator?: React.ReactNode;
  readonly horizontal?: boolean;
};

type ItemsProps = Pick<Props, 'items' | 'separator'>;

const ItemsArray = ({ items, separator }: ItemsProps) => {
  const nonFalsyItems = items.filter((item) => item?.component);
  return (
    <>
      {nonFalsyItems.map((item, index) => (
        <React.Fragment key={item.key}>
          {item.component}
          {index < nonFalsyItems.length - 1 && separator}
        </React.Fragment>
      ))}
    </>
  );
};

const Items = memo<ItemsProps>(ItemsArray, ({ items, separator }, nextProps) => {
  return isEqual(items, nextProps.items) && separator === nextProps.separator;
});

export const ListBase = ({ items, separator, horizontal }: Props) => {
  const Content = horizontal ? Row : Column;

  return (
    <ContentWrapper as={Content}>
      <Items
        items={items}
        separator={separator}
      />
    </ContentWrapper>
  );
};
