// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Button, Column, LgSpacing, Row, useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';

const MobileContainer = styledNative(Row)`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${COLORS.PALETTE.GREYSCALE.WHITE};
  padding: 1rem;
  border-top: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  border-bottom: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  margin-top: auto;
`;

const DesktopContainer = styled.div`
  margin-top: ${SPACING.STATIC.XL.px};
`;

type ButtonProps = {
  readonly danger?: boolean;
  readonly disabled?: boolean;
  readonly small?: boolean;
  readonly loading?: boolean;
  readonly onClick: React.ComponentProps<typeof Button>['onPress'];
  readonly secondaryLabel?: string;
  readonly secondaryOnClick?: React.ComponentProps<typeof Button>['onPress'];
} & Omit<React.ComponentProps<typeof Button>, 'onPress'>;

export const OnboardingButtonDesktop = ({
  danger,
  disabled,
  small,
  onClick,
  loading,
  label,
  secondaryLabel,
  secondaryOnClick,
}: ButtonProps) => {
  const { isSMScreenOrBigger } = useScreenSize();
  return isSMScreenOrBigger ? (
    <DesktopContainer>
      <Column>
        <Button
          width="full"
          variant={danger ? 'danger' : undefined}
          disabled={disabled || loading}
          small={small}
          onPress={onClick}
          loading={loading}
          label={label || ''}
        />
        {secondaryLabel && secondaryOnClick
          ? [
              <LgSpacing key="secondary label spacing" />,
              <Button
                key="secondary label button"
                label={secondaryLabel}
                onPress={secondaryOnClick}
                type="tertiary"
                noPadding
              />,
            ]
          : null}
      </Column>
    </DesktopContainer>
  ) : null;
};

export const OnboardingButtonMobile = ({
  danger,
  disabled,
  small,
  onClick,
  loading,
  label,
  secondaryLabel,
  secondaryOnClick,
}: ButtonProps) => {
  const { isSMScreenOrBigger } = useScreenSize();
  return !isSMScreenOrBigger ? (
    <MobileContainer>
      <Column flexGrow={1}>
        <Button
          variant={danger ? 'danger' : undefined}
          disabled={disabled || loading}
          small={small}
          onPress={onClick}
          loading={loading}
          label={label || ''}
        />
        {secondaryLabel && secondaryOnClick
          ? [
              <LgSpacing key="spacing" />,
              <Button
                label={secondaryLabel}
                key={secondaryLabel}
                onPress={secondaryOnClick}
                type="tertiary"
                noPadding
              />,
            ]
          : null}
      </Column>
    </MobileContainer>
  ) : null;
};
