// @owners { team: patients-team }
import { InputSelect, InputText, MdSpacing } from '@alto/design-system';
import React from 'react';
import { RELATIONSHIP_OPTIONS } from '~shared/constants';
import { getPossessiveName } from '~shared/helpers/string';

type Props = {
  familyMemberName?: string;
  firstNameError?: string;
  firstName?: string;
  lastNameError?: string;
  lastName?: string;
  onChangeRelationship: (value: string) => void;
  onChangeFirstName: (value: string) => void;
  onChangeLastName: (value: string) => void;
  relationshipCode: string;
  relationshipCodeError?: string;
};

export const InsuranceRelationshipInputSelect = ({
  relationshipCodeError,
  familyMemberName,
  onChangeRelationship,
  relationshipCode,
  firstName = '',
  lastName = '',
  onChangeFirstName,
  onChangeLastName,
  lastNameError,
  firstNameError,
}: Props) => {
  const caption =
    familyMemberName !== undefined
      ? `${getPossessiveName(familyMemberName) || 'Family member’s'} relationship to the primary benefit holder`
      : 'Your relationship to the primary benefit holder';

  return (
    <>
      <InputSelect
        caption={caption}
        description={caption}
        error={relationshipCodeError}
        label="Relationship"
        leftIcon="people"
        modalTitle="Select relationship"
        onValueChange={onChangeRelationship}
        options={RELATIONSHIP_OPTIONS}
        placeholder="Self, Spouse, Child, etc."
        required
        testID="relationship-input"
        value={relationshipCode}
      />
      {relationshipCode !== '' && relationshipCode !== '1' && (
        <>
          <MdSpacing />
          <InputText
            id="first_name"
            label="Primary benefit holder’s first name"
            value={firstName}
            placeholder="Enter first name"
            error={firstNameError}
            onChangeText={onChangeFirstName}
            required
          />
          <MdSpacing />
          <InputText
            id="last_name"
            label="Primary benefit holder’s last name"
            value={lastName}
            placeholder="Enter last name"
            error={lastNameError}
            onChangeText={onChangeLastName}
            required
          />
        </>
      )}
    </>
  );
};
