// @owners { team: patients-team }

import {
  FamilyMembersEndpoint,
  type FamilyMembersEndpointCreateRequest,
} from '@alto/scriptdash/alto/patient_app/family_members/v1/family_members_endpoint';
import { useMutation } from '@tanstack/react-query';
import { selectUser } from '~shared/actions/ui/users';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers } from '~shared/actions/users';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useDispatchShared } from '~shared/store';
const familyMembersEndpoint = FamilyMembersEndpoint(apiEndpointHandler);

export const useAddFamilyMember = () => {
  const dispatch = useDispatchShared();
  return useMutation({
    mutationFn: async (params: FamilyMembersEndpointCreateRequest) => {
      const { data, errors } = await familyMembersEndpoint.create(params);

      return {
        familyMemberPatientID: data?.family_member_patient_id,
        hasCompletedOnboarding: data?.has_onboarded,
        supportCaseID: data?.support_case_id,
        errors,
      };
    },
    onSuccess: ({ familyMemberPatientID }) => {
      if (familyMemberPatientID) {
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchUsers());
        // select family member to complete onboarding or transfer prescription
        dispatch(selectUser(familyMemberPatientID));
      }
    },
  });
};
