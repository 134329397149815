// @owners { team: patients-team }
import { AltoIcon, Body, Column, Row } from '@alto/design-system';
import React from 'react';
import { getDisabledColor } from './utils';

type RightContentProps = {
  // string representing the fee for choosing this option. ex: "+$0.00" for bundling, "$9.99" for a same-day fee
  readonly feeAmountLabel: string | undefined;
  // if this radio option is for a fee, this represent the fee for the original amount, ie: "$9.99".
  // in cases when the fee is waived, we want to show the patient the discount they are getting, so we show the original amount crossed out
  readonly feeOriginalAmountLabel: string | undefined;
  readonly loading?: boolean;
  // include a right chevron icon when this selection will open a calendar
  readonly showIconOpenCalendar?: boolean;
};

export const FeeRadioRightContent = ({
  feeAmountLabel,
  feeOriginalAmountLabel,
  loading = false,
  showIconOpenCalendar = false,
}: RightContentProps) => {
  const showLineThoughOriginalFeeOnFeeWaived =
    feeAmountLabel && feeOriginalAmountLabel && feeAmountLabel !== feeOriginalAmountLabel;
  const disabledColor = getDisabledColor(loading);

  return (
    <Column flexGrow={1}>
      <Row
        centerHorizontally
        centerVertically
      >
        {showLineThoughOriginalFeeOnFeeWaived ? (
          <Body
            color={disabledColor}
            textDecorationLine="line-through"
            fontFamily="semibold"
            testID="waived-fee"
          >
            {feeOriginalAmountLabel}
          </Body>
        ) : null}
        <Body
          color={disabledColor}
          fontFamily="semibold"
        >
          {showLineThoughOriginalFeeOnFeeWaived && ' '}
          {feeAmountLabel}
        </Body>
        {showIconOpenCalendar ? (
          <AltoIcon
            name="chevronright-small"
            type={loading ? 'disabled' : undefined}
          />
        ) : null}
      </Row>
    </Column>
  );
};
