import { BORDERS, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { format, isAfter, isBefore, parse } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { PixelRatio } from 'react-native';
import {
  Calendar as ReactNativeCalendar,
  type DateData as ReactNativeCalendarDate,
  type CalendarProps as ReactNativeCalendarProps,
} from 'react-native-calendars';
import { type DateData, toDateObject } from '../../../utils';
import { AltoIcon } from '../../icon';
import { H2 } from '../../typography';

export type CalendarProps = {
  /**
   * Date string should be in ISO format.
   */
  readonly initialSelectedDate?: string;
  /**
   * Array of date strings should be in ISO format.
   */
  readonly disabledDates?: string[];
  readonly onDayPress?: (date: DateData, disabled: boolean) => void;
  readonly testID?: string;
} & Pick<ReactNativeCalendarProps, 'minDate' | 'maxDate'>;

const renderArrow = (direction: 'left' | 'right') => {
  switch (direction) {
    case 'left':
      return (
        <AltoIcon
          name="chevronleft"
          testID="prev-month"
        />
      );
    case 'right':
      return (
        <AltoIcon
          name="chevronright"
          testID="next-month"
        />
      );
    default:
      return null;
  }
};

const renderHeader = (date?: { toString: () => string }) => {
  if (date) {
    const utcDate = new Date(date.toString());
    // without this, the date will use a time of midnight utc. date-fns will format using the local time, which means
    // we will render this header as if the previous day is selected. i.e. if March 1 is selected, the header will say
    // February. adding on the timezone offset so that date-fns registers the date as midnight local time.
    const normalizedDate = new Date(utcDate.valueOf() + utcDate.getTimezoneOffset() * 60 * 1000);
    return <H2>{format(normalizedDate, 'MMMM yyyy')}</H2>;
  }
  return null;
};

const fontFamily = TYPOGRAPHY.FONT.BODY;

const customHeaderStyles = {
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: SPACING.STATIC.LG.value,
    paddingRight: SPACING.STATIC.LG.value,
  },
  arrow: {
    padding: 0,
  },
  week: {
    alignItems: 'center',
    flexDirection: 'row',
    height: SPACING.STATIC.LG.value,
    justifyContent: 'space-around',
    marginTop: SPACING.STATIC.LG.value,
  },
  dayHeader: {
    color: COLORS.TEXT_COLORS.PRIMARY,
    fontFamily: fontFamily.BOLD,
    fontSize: TYPOGRAPHY.TEXT.DETAIL.MD.value * PixelRatio.getFontScale(),
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: SPACING.STATIC.XL.value * PixelRatio.getFontScale(),
  },
};

const dayBackgroundBorderRadius = BORDERS.RADIUS.SM.value + BORDERS.RADIUS.MD.value;
const dayBackgroundSize = SPACING.STATIC.XL.value + SPACING.STATIC.SM.value * PixelRatio.getFontScale();
const getCustomDayStyles = ({ isTodayDisabled }: { isTodayDisabled: boolean }) => ({
  base: {
    alignItems: 'center',
    justifyContent: 'center',
    height: dayBackgroundSize,
    width: dayBackgroundSize,
  },
  today: {
    backgroundColor: COLORS.PALETTE.GREYSCALE.LIGHT,
    borderRadius: dayBackgroundBorderRadius,
  },
  selected: {
    backgroundColor: COLORS.TEXT_COLORS.PRIMARY,
    borderRadius: dayBackgroundBorderRadius,
  },
  selectedText: {
    color: COLORS.TEXT_COLORS.WHITE,
  },
  text: {
    backgroundColor: COLORS.PALETTE.GREYSCALE.TRANSPARENT,
    color: COLORS.TEXT_COLORS.GREY,
    fontFamily: fontFamily.REGULAR,
    fontSize: TYPOGRAPHY.TEXT.BODY.MD.value * PixelRatio.getFontScale(),
    fontWeight: '400',
    lineHeight: TYPOGRAPHY.TEXT.BODY.MD.value * TYPOGRAPHY.LINE_HEIGHT.LG * PixelRatio.getFontScale(),
    textAlign: 'center',
  },
  todayText: {
    color: isTodayDisabled ? COLORS.PALETTE.GREYSCALE.DARK : COLORS.TEXT_COLORS.GREY,
  },
  disabledText: {
    color: COLORS.PALETTE.GREYSCALE.DARK,
  },
});

const getTheme = ({ isTodayDisabled }: { isTodayDisabled: boolean }) => ({
  /**
   * `stylesheet.day.basic` and `stylesheet.calendar.header` provides us with the most flexible and readable
   * option to override `react-native-calendars`'s way of styling. Refer to
   * - https://github.com/wix/react-native-calendars/blob/master/src/calendar/day/basic/style.ts
   * - https://github.com/wix/react-native-calendars/blob/master/src/calendar/header/style.ts
   * for how to extend the styles.
   *
   * Since the implementation currently is an object spread of `stylesheet.day.basic` and `stylesheet.calendar.header`,
   * any attribute you define as a key/value pair in this object will be completely overridden.
   * If you want to keep any of the pre-existing styles, you must copy them over.
   */
  // As of v1.1288.2, their types do not match the implementation.
  // Open issue about this here: https://github.com/wix/react-native-calendars/issues/1864
  'stylesheet.calendar.header': customHeaderStyles,
  'stylesheet.day.basic': getCustomDayStyles({ isTodayDisabled }),
  /**
   * This is a just-in-case defensive implementation for if/when we upgrade and the package fixes its
   * implementation to match the expected types.
   *
   * (Alternatively, they could change the types to match the implementation for which case, the following
   * can be removed.)
   */
  stylesheet: {
    calendar: {
      header: customHeaderStyles,
    },
    day: {
      basic: getCustomDayStyles({ isTodayDisabled }),
    },
  },
});
/**
 *  Calculate the initial date to use, prioritizing:
 *  1. initialSelectedDate
 *  2. minDate
 *  3. Default to today's date
 */
const getInitialSelectedDate = (initialSelectedDate: string | undefined, minDate: string | undefined) => {
  const ISOFormatWithoutTimestamp = 'yyyy-MM-dd';
  let isoInitialSelectedString;

  if (initialSelectedDate) {
    isoInitialSelectedString = toDateObject(initialSelectedDate);

    return format(isoInitialSelectedString, ISOFormatWithoutTimestamp);
  }

  if (minDate) {
    isoInitialSelectedString = toDateObject(minDate);

    return format(isoInitialSelectedString, ISOFormatWithoutTimestamp);
  }

  const today = new Date();
  return format(today, ISOFormatWithoutTimestamp);
};

const defaultDisabledDates: string[] = [];
export const Calendar = ({
  initialSelectedDate,
  minDate,
  maxDate,
  onDayPress,
  disabledDates = defaultDisabledDates,
  testID,
}: CalendarProps) => {
  const [selectedDate, setSelectedDate] = useState(getInitialSelectedDate(initialSelectedDate, minDate));

  const today = new Date();
  const disabledBeforeDate = minDate ? new Date(minDate) : today;
  // appending 'T00:00:00' to ensure that the date is interpreted in the local time zone
  const disabledAfterDate = maxDate ? new Date(maxDate + 'T00:00:00') : undefined;
  const handleDayPress = ({ year, month, day, dateString }: ReactNativeCalendarDate) => {
    const dateDisabled =
      disabledDates.includes(dateString) ||
      isBefore(parse(dateString, 'yyyy-MM-dd', today), disabledBeforeDate) ||
      (!!disabledAfterDate && isAfter(parse(dateString, 'yyyy-MM-dd', today), disabledAfterDate));
    const dateFormat = new Date(year, month - 1, day);
    const selectedDate: DateData = {
      year,
      month,
      day,
      timestamp: dateFormat.getTime(),
      dateString: dateFormat.toDateString(),
    };

    if (onDayPress) {
      onDayPress(selectedDate, dateDisabled);
    }

    if (!dateDisabled) {
      setSelectedDate(dateString);
    }
  };

  const markedDates = useMemo(() => {
    const disabledProps = {
      disabled: true,
    };
    const disabledMarkedDates = disabledDates.reduce((prevObject, currentDisabledDate) => {
      return { ...prevObject, [currentDisabledDate]: disabledProps };
    }, {});
    const baseMarkedDates = { ...disabledMarkedDates };

    if (selectedDate && !disabledDates.includes(selectedDate)) {
      return {
        ...baseMarkedDates,
        [selectedDate]: {
          selected: true,
        },
      };
    }

    return baseMarkedDates;
  }, [selectedDate, disabledDates]);

  const isTodayDisabled = minDate ? isAfter(parse(minDate, 'yyyy-MM-dd', today), today) : false;
  const theme = useMemo(() => getTheme({ isTodayDisabled }), [isTodayDisabled]);

  return (
    <ReactNativeCalendar
      allowSelectionOutOfRange
      current={selectedDate}
      enableSwipeMonths
      hideExtraDays
      markedDates={markedDates}
      markingType="custom"
      maxDate={maxDate}
      minDate={minDate}
      onDayPress={handleDayPress}
      renderArrow={renderArrow}
      renderHeader={renderHeader}
      testID={testID}
      theme={theme}
    />
  );
};
