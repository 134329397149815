import { BORDERS, COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';
import { useScreenSize } from '../../../../../utils';
import { AltoIcon } from '../../../../icon';
import { getCssFromOrientation } from '../../helpers';
import { type VerticalEnabled } from '../../types';
import { DEFAULT_CIRCLE_SIZE, VERTICAL_OFFSET } from './constants';

const verticalCss = css`
  top: ${VERTICAL_OFFSET}px;
`;

const StyledIndicator = styled(View)<{ isMDScreenOrBigger: boolean; verticalEnabled: VerticalEnabled }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  ${({ isMDScreenOrBigger, verticalEnabled }) => {
    return css`
      background-color: ${COLORS.BACKGROUND_COLORS.DANGER};
      width: ${DEFAULT_CIRCLE_SIZE}px;
      height: ${DEFAULT_CIRCLE_SIZE}px;
      ${getCssFromOrientation({
        isMDScreenOrBigger,
        verticalEnabled,
        verticalCss,
      })}
    `;
  }}}
`;

export const FailedIndicator = () => {
  const { verticalEnabled } = useTheme();
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <StyledIndicator
      verticalEnabled={verticalEnabled}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      <AltoIcon
        name="close-small"
        type="white"
      />
    </StyledIndicator>
  );
};
