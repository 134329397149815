import { type COLORS } from '@alto/design-library-tokens';
import React, { type ReactElement } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { EdgelessScreenHomeButton } from '../../separators';

export type FixedFooterProps = {
  readonly fixedFooterChildren: ReactElement;
  readonly bottomSafeAreaColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  readonly footerPlacementContext?:
    | 'edgelessScreenWithTabBar'
    | 'edgelessScreenWithoutTabBar'
    | 'webScreenWithoutPolicyFooter'
    | 'webScreenWithPolicyFooter';
};

const StyledWebFixedFooter = styled.View`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
`;

export const FixedFooter = ({
  fixedFooterChildren,
  bottomSafeAreaColor,
  footerPlacementContext = 'edgelessScreenWithoutTabBar',
}: FixedFooterProps) => {
  if (Platform.OS === 'web' && footerPlacementContext === 'webScreenWithoutPolicyFooter') {
    return (
      <StyledWebFixedFooter>
        {fixedFooterChildren}
        <EdgelessScreenHomeButton color={bottomSafeAreaColor} />
      </StyledWebFixedFooter>
    );
  }

  if (footerPlacementContext === 'edgelessScreenWithTabBar') {
    return <>{fixedFooterChildren}</>;
  }

  if (
    footerPlacementContext === 'edgelessScreenWithoutTabBar' ||
    footerPlacementContext === 'webScreenWithPolicyFooter'
  ) {
    return (
      <>
        {fixedFooterChildren}
        <EdgelessScreenHomeButton color={bottomSafeAreaColor} />
      </>
    );
  }

  return null;
};
