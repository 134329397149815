// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { getEssentialsOnlyShipmentFee } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { type ReduxStateShared } from '~shared/types';

type RemovingWillAddFeeProps = {
  deliveryID: number;
  minimumFreeDeliveryThreshold: number;
};

const getDeliveryID = (_: ReduxStateShared, { deliveryID }: RemovingWillAddFeeProps) => deliveryID;
const getMinimumFreeDeliveryThreshold = (
  _: ReduxStateShared,
  { minimumFreeDeliveryThreshold }: RemovingWillAddFeeProps,
) => minimumFreeDeliveryThreshold;

export const getRemovingDeliveryWillAddEssentialsFee = createSelector(
  [getDeliveriesForShipmentID, getShipmentPricing, getShipmentWithID, getDeliveryID, getMinimumFreeDeliveryThreshold],
  (deliveries, shipmentPricing, shipment, deliveryID, minimumFreeDeliveryThreshold) => {
    if (!shipment) return false;

    const remainingDeliveries = deliveries.filter((delivery) => delivery.id !== deliveryID);

    // If this is the last item on the order, return false
    if (!remainingDeliveries.length) {
      return false;
    }

    // If some of the remaining deliveries are not Essentials, return false
    if (remainingDeliveries.some((delivery) => !delivery.prescription.is_add_on_otc)) {
      return false;
    }

    // If the shipment already has an Essentials-only shipment fee on it, return false
    if (getEssentialsOnlyShipmentFee(shipment)) {
      return false;
    }

    const remainingPrice = remainingDeliveries.reduce((sum, item) => {
      const price = item.prescription?.id ? shipmentPricing.item_pricing[item.prescription.id]?.prices[0]?.price : 0;
      return sum + (price || 0);
    }, 0);

    // Otherwise, return true only if the new total is < minimumFreeDeliveryThreshold
    return remainingPrice < minimumFreeDeliveryThreshold;
  },
);
