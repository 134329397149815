// @owners { team: patients-team }
// eslint-disable-next-line @alto/prefer-alto-navigation-library
import { LOCATION_CHANGE } from 'react-router-redux';
import { REHYDRATE } from 'redux-persist/constants';
import {
  CLEAR_DEEP_LINK_PATH,
  CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR,
  CLEAR_REGISTRATION_NOTIFICATIONS,
  FETCH_SERVICE_AVAILABILITY_FAILED,
  FETCH_SERVICE_AVAILABILITY_LOADING,
  FETCH_SERVICE_AVAILABILITY_SUCCEEDED,
  FORGOTTEN_PASSWORD_FAILED,
  FORGOTTEN_PASSWORD_LOADING,
  FORGOTTEN_PASSWORD_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_LOADING,
  LOGIN_SUCCEEDED,
  LOGOUT_FAILED,
  LOGOUT_SUCCEEDED,
  RESETTING_PASSWORD,
  RESETTING_PASSWORD_FAILED,
  RESETTING_PASSWORD_SUCCEEDED,
  SET_POST_LOGIN_ROUTE,
  SIGNUP_FAILED,
  SIGNUP_LOADING,
  SIGNUP_SUCCEEDED,
  STORE_DEEPLINK_PATH,
  UPDATE_TEMP_SIGNUP_USER,
  VALIDATING_USER_FAILED,
  VALIDATING_USER_LOADING,
  VALIDATING_USER_SUCCEEDED,
  type clearDeepLinkPath,
  type clearFetchServiceAvailabilityError,
  type clearRegistrationNotifications,
  type completeOnboarding,
  type fetchServiceAvailabilityFailed,
  type fetchServiceAvailabilityLoading,
  type fetchServiceAvailabilitySucceeded,
  type loginFailed,
  type loginLoading,
  type loginSucceeded,
  type logoutFailed,
  type logoutSucceeded,
  type requestForgottenPasswordFailed,
  type requestForgottenPasswordSucceeded,
  type requestingForgottenPassword,
  type resettingPassword,
  type resettingPasswordFailed,
  type resettingPasswordSucceeded,
  type setPostLoginRoute,
  type signingUp,
  type signupFailed,
  type signupSucceeded,
  type storeDeepLinkPath,
  type updateTempSignupUser,
  type validatingUser,
  type validatingUserFailed,
  type validatingUserSucceeded,
} from '~shared/actions/auth';
import { BIOMETRICS_AUTHENTICATED, type biometricAuthenticationSuccess } from '~shared/actions/biometrics';
import { SET_PRESENCE, type setPresence } from '~shared/actions/presence';
import { COMPLETE_ONBOARDING } from '~shared/constants';
import { type User, type UserCreateRequest } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export type AuthState = {
  loggedInAt: string | null;
  biometricsAuthenticated: boolean;
  authError: APIError | null;
  authorized: boolean;
  loggingIn: boolean;
  onboarding: boolean;
  resetPasswordError: any;
  requestPasswordResetSuccess: boolean | null;
  serviceAvailabilityChecked: boolean;
  serviceAvailable: boolean;
  signUpSuccess: boolean | null;
  tempSignupUser: UserCreateRequest;
  user: User | null;
  validatingUser: boolean;
  signingUp: boolean;
  resetPasswordResetSuccess?: boolean | null;
  postLoginRoute: string | null;
  deepLinkPath: string | null;
};

const initialState: AuthState = {
  loggedInAt: null,
  biometricsAuthenticated: false,
  authError: null,
  authorized: false,
  loggingIn: false,
  onboarding: false,
  resetPasswordError: null,
  requestPasswordResetSuccess: null,
  serviceAvailabilityChecked: false,
  serviceAvailable: false,
  signUpSuccess: null,
  tempSignupUser: {},
  // still being used to hold referred_by_id
  user: null,
  validatingUser: false,
  signingUp: false,
  postLoginRoute: null,
  deepLinkPath: null,
};

type AuthActions =
  | { type: typeof REHYDRATE }
  | { type: '@@router/LOCATION_CHANGE' }
  | ReturnType<typeof loginLoading>
  | ReturnType<typeof loginSucceeded>
  | ReturnType<typeof loginFailed>
  | ReturnType<typeof updateTempSignupUser>
  | ReturnType<typeof signingUp>
  | ReturnType<typeof signupSucceeded>
  | ReturnType<typeof signupFailed>
  | ReturnType<typeof logoutSucceeded>
  | ReturnType<typeof logoutFailed>
  | ReturnType<typeof validatingUser>
  | ReturnType<typeof validatingUserSucceeded>
  | ReturnType<typeof validatingUserFailed>
  | ReturnType<typeof clearDeepLinkPath>
  | ReturnType<typeof clearRegistrationNotifications>
  | ReturnType<typeof requestingForgottenPassword>
  | ReturnType<typeof requestForgottenPasswordSucceeded>
  | ReturnType<typeof requestForgottenPasswordFailed>
  | ReturnType<typeof resettingPassword>
  | ReturnType<typeof resettingPasswordFailed>
  | ReturnType<typeof resettingPasswordSucceeded>
  | ReturnType<typeof completeOnboarding>
  | ReturnType<typeof fetchServiceAvailabilityLoading>
  | ReturnType<typeof fetchServiceAvailabilitySucceeded>
  | ReturnType<typeof fetchServiceAvailabilityFailed>
  | ReturnType<typeof clearFetchServiceAvailabilityError>
  | ReturnType<typeof setPostLoginRoute>
  | ReturnType<typeof storeDeepLinkPath>
  | ReturnType<typeof biometricAuthenticationSuccess>
  | ReturnType<typeof setPresence>;

const authReducer = (state: AuthState | undefined, action: AuthActions): AuthState => {
  /**
   * This reducer's state is persisted in local storage, so merge
   * current state with initial to handle the later addition of any
   * new state keys.
   */
  state = { ...initialState, ...state };

  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case REHYDRATE:
      return initialState;

    case LOGIN_LOADING:
      return { ...state, loggingIn: true };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        authorized: true,
        authError: null,
        loggingIn: false,
        user: action.payload,
        loggedInAt: new Date().toISOString(),
      };

    case LOGIN_FAILED:
      return { ...state, authError: action.payload, loggingIn: false };

    case UPDATE_TEMP_SIGNUP_USER:
      return { ...state, tempSignupUser: { ...state.tempSignupUser, ...action.payload } };

    case SIGNUP_LOADING:
      return { ...state, signingUp: true, signUpSuccess: null };

    case SIGNUP_SUCCEEDED:
      return {
        ...state,
        loggedInAt: new Date().toISOString(),
        authError: null,
        authorized: true,
        onboarding: true,
        signingUp: false,
        signUpSuccess: true,
        user: action.payload,
      };

    case SIGNUP_FAILED:
      return { ...state, authError: action.payload, signingUp: false, signUpSuccess: false };

    case STORE_DEEPLINK_PATH:
      return { ...state, deepLinkPath: action.payload };

    case CLEAR_DEEP_LINK_PATH:
      return { ...state, deepLinkPath: null };

    case LOGOUT_SUCCEEDED:
      return initialState;

    case LOGOUT_FAILED:
      return { ...state, authError: action.payload };

    case VALIDATING_USER_LOADING:
      return { ...state, authError: null, validatingUser: true };

    case VALIDATING_USER_SUCCEEDED:
      return {
        ...state,
        authError: null,
        authorized: true,
        onboarding: true,
        user: action.payload,
        validatingUser: false,
      };

    case VALIDATING_USER_FAILED:
      return { ...state, authError: action.payload, validatingUser: false };

    case CLEAR_REGISTRATION_NOTIFICATIONS:
      return { ...state, signUpSuccess: null, resetPasswordResetSuccess: null };

    case FORGOTTEN_PASSWORD_LOADING:
      return { ...state, resetPasswordError: null, resetPasswordResetSuccess: null };

    case FORGOTTEN_PASSWORD_SUCCEEDED:
      return { ...state, resetPasswordError: null, requestPasswordResetSuccess: true };

    case FORGOTTEN_PASSWORD_FAILED:
      return { ...state, resetPasswordError: action.payload, requestPasswordResetSuccess: false };

    case RESETTING_PASSWORD:
      return { ...state, resetPasswordError: null };

    case RESETTING_PASSWORD_SUCCEEDED:
      return { ...state, resetPasswordError: null };

    case RESETTING_PASSWORD_FAILED:
      return { ...state, resetPasswordError: action.payload };

    case COMPLETE_ONBOARDING:
      return { ...state, onboarding: false };

    case FETCH_SERVICE_AVAILABILITY_LOADING:
      return state;

    case FETCH_SERVICE_AVAILABILITY_SUCCEEDED:
      return { ...state, serviceAvailabilityChecked: true, serviceAvailable: action.payload.available };

    case FETCH_SERVICE_AVAILABILITY_FAILED:
      return state;

    case CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR:
      return { ...state, serviceAvailabilityChecked: false };

    case LOCATION_CHANGE as '@@router/LOCATION_CHANGE':
      return { ...state, authError: null };

    case SET_POST_LOGIN_ROUTE:
      return { ...state, postLoginRoute: action.payload };

    case SET_PRESENCE:
      return { ...state, biometricsAuthenticated: false };

    case BIOMETRICS_AUTHENTICATED:
      return { ...state, biometricsAuthenticated: true };

    default:
      return state;
  }
};

export default authReducer;
