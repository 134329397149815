// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Column, H1, MdSpacing, SecondaryPage, XlPadding } from '@alto/design-system';
import React from 'react';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { useSelectorShared } from '~shared/store';

type Props = {
  children: React.ReactNode;
  onDismiss: () => void;
};

const isWeb = getPlatformOS() === 'web';

export const BenefitPhotoUpload = ({ children, onDismiss }: Props) => {
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const selectedUser = useSelectorShared(getSelectedUser);
  const possessiveName = relationship
    ? getPossessiveName(selectedUser?.preferred_first_name || selectedUser?.first_name || 'family member')
    : 'your';

  return (
    <SecondaryPage
      dismissIcon="chevronleft"
      onDismiss={onDismiss}
      HeaderContent={
        <XlPadding
          leftPadding={SPACING.STATIC.XXL}
          rightPadding={SPACING.STATIC.XXL}
        >
          <Column>
            <H1 center>Take a photo of {possessiveName} benefits card</H1>
            <MdSpacing />
            <Body center>
              Include both sides of {possessiveName} benefits card, and take the photos on a neutral background.
            </Body>
          </Column>
        </XlPadding>
      }
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      footerPlacementContext={isWeb ? 'webScreenWithoutPolicyFooter' : undefined}
      withoutWebFramingElementHeights={isWeb}
    >
      {children}
    </SecondaryPage>
  );
};
