// @owners { team: patients-team }
import {
  AltoIcon,
  Avatar,
  Button,
  Card,
  Column,
  LgPadding,
  ListDescription,
  ListItem,
  MdSpacing,
  Row,
  SmSpacing,
  Tag,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { useSelectorShared } from '~shared/store';

type Props = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  relationship: string;
  supportCaseID: number; // the support case tied to the request to add family member
};

const isWeb = getPlatformOS() === 'web';

export const FamilyMemberRequestCard = ({ dateOfBirth, firstName, lastName, relationship, supportCaseID }: Props) => {
  const { navigate } = useNavigation();
  const patient = useSelectorShared(getCurrentUser);
  const name = `${firstName} ${lastName}`;

  const handlePressViewRequest = () => {
    navigate('RouteAssistantSupportCase', { supportCaseID });
  };

  const handlePressAddAnotherFamilyMember = () => {
    navigate('RouteAddFamilyMember');
  };

  const handlePressDone = () => {
    navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
  };

  return (
    <Card>
      <LgPadding>
        <Row>
          <Avatar
            name={name}
            size={50}
          />
          <MdSpacing />
          <Column
            flexGrow={isWeb ? 1 : 0}
            flexShrink={isWeb ? 0 : 1}
          >
            <Tag
              icon={<AltoIcon name="hourglass-small" />}
              label="pending"
              type="warning"
            />
            <XsSpacing />
            <ListItem
              title={name}
              fullBleed
            />
            <XsSpacing />
            <ListDescription
              iconName="calendar-small"
              type="grey"
            >
              {dateOfBirth}
            </ListDescription>
            <XsSpacing />
            <ListDescription
              iconName="user-small"
              type="grey"
            >
              {`${getPossessiveName(patient?.first_name)} ${relationship}`}
            </ListDescription>
          </Column>
        </Row>
        <XlSpacing />
        <Button
          key="pending-request-view-request"
          label="View request"
          onPress={handlePressViewRequest}
        />
        <SmSpacing />
        <Button
          key="pending-request-add-another"
          label="Add another family member"
          onPress={handlePressAddAnotherFamilyMember}
          type="secondary"
        />
        <SmSpacing />
        <Button
          key="pending-request-done"
          label="Done"
          onPress={handlePressDone}
          type="tertiary"
        />
      </LgPadding>
    </Card>
  );
};
