// @owners { team: patients-team }
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import {
  CREATE_SHIPMENT_TIP_FAILED,
  CREATE_SHIPMENT_TIP_LOADING,
  CREATE_SHIPMENT_TIP_SUCCEEDED,
  type CreateShipmentTipActions,
  FETCH_SHIPMENT_TIPS_FAILED,
  FETCH_SHIPMENT_TIPS_LOADING,
  FETCH_SHIPMENT_TIPS_SUCCEEDED,
  type FetchShipmentTipsActions,
  UPDATE_SHIPMENT_TIP_FAILED,
  UPDATE_SHIPMENT_TIP_LOADING,
  UPDATE_SHIPMENT_TIP_SUCCEEDED,
  type UpdateShipmentTipActions,
} from '~shared/features/courier-tips/actions';
import { immutableUpsert } from '~shared/helpers/immutableUpsert';

export type ShipmentTipsState = {
  shipmentTips: ShipmentTip[];
};

type Action = CreateShipmentTipActions | FetchShipmentTipsActions | UpdateShipmentTipActions;

const initialState: ShipmentTipsState = {
  shipmentTips: [],
};

function shipmentTips(state: ShipmentTipsState = initialState, action: Action): ShipmentTipsState {
  switch (action.type) {
    // Create actions
    case CREATE_SHIPMENT_TIP_LOADING:
      return state;

    case CREATE_SHIPMENT_TIP_SUCCEEDED:
      return {
        ...state,
        shipmentTips: immutableUpsert<any>({
          state: state.shipmentTips,
          payload: action.payload,
        }),
      };

    case CREATE_SHIPMENT_TIP_FAILED:
      return state;

    // Fetch actions
    case FETCH_SHIPMENT_TIPS_LOADING:
      return state;

    case FETCH_SHIPMENT_TIPS_SUCCEEDED:
      return { ...state, shipmentTips: action.payload };

    case FETCH_SHIPMENT_TIPS_FAILED:
      return state;

    // Update actions
    case UPDATE_SHIPMENT_TIP_LOADING:
      return state;

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case UPDATE_SHIPMENT_TIP_SUCCEEDED:
      return {
        ...state,
        shipmentTips: immutableUpsert<any>({
          state: state.shipmentTips,
          payload: action.payload,
        }),
      };

    case UPDATE_SHIPMENT_TIP_FAILED:
      return state;

    default:
      return state;
  }
}

export default shipmentTips;
