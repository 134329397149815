import { DANGER } from './danger';
import { GREYSCALE } from './greyscale';
import { PINK } from './pink';
import { PRIMARY } from './primary';
import { SECONDARY } from './secondary';
import { SUCCESS } from './success';
import { TERTIARY } from './tertiary';
import { WARNING } from './warning';

export const PALETTE = {
  DANGER,
  GREYSCALE,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  TERTIARY,
  WARNING,
  PINK,
} as const;
