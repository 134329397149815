import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

/**
 * A hook for use only on the web to listen to key press events and handle them.
 * @param key Keyboard event keys: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 * @param callback Function to execute when matching key is pressed.
 * @param node The HTML element to listen to. Use React refs to grab the actual element and pass in ref.current.
 */
export const useKeyPress = (key: string, callback: (event: KeyboardEvent) => void, node?: any) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: Event | null) => {
      if (event && event.type === 'keydown') {
        const keyboardEvent = event as KeyboardEvent;
        if (keyboardEvent.key === key) {
          callbackRef.current(keyboardEvent);
        }
      }
    },
    [key],
  );

  useEffect(() => {
    node?.addEventListener('keydown', handleKeyPress);

    return () => node?.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};
