// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { LgSpacing, XlSpacing, useScreenSize } from '@alto/design-system';
import { AddOnsContext, type AddOnsContextProps } from '@alto/features';
import { useQueryEssentials } from '@alto/features';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useEssentialsSearch } from '~shared/features/essentials';
import { setActiveEssentialCategory, viewStore } from '~shared/features/essentials/actions';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Address, type Shipment } from '~shared/types';
import { CategoryItem } from './CategoryItem';
import { MobileProductList } from './MobileProductList';
import { ProductItem } from './ProductItem';
import { SearchInput } from './SearchInput';
import { StoreHeaderWrapper } from './StoreHeaderWrapper';
import { getActiveCategoryName } from './getActiveCategoryName';
import { CategorySearch } from './types';
import { useSidePadding } from './useSidePadding';
import { ErrorPage, FullPage, LoadingPage, NoSearchResults } from '~web/features/essentials/components';
import { View } from '~web/features/essentials/primitives';
import { useDeprecatedMediaQuery } from '~web/hooks';

const StyledProductList = styled.ul<{
  isMDScreenOrBigger: boolean;
  isLGScreenOrBigger: boolean;
}>`
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: ${SPACING.STATIC.MD.px};
  grid-template-columns: repeat(1, minmax(0, 1fr));
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};

  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `grid-template-columns: repeat(2, minmax(0, 1fr));`}
  ${({ isLGScreenOrBigger }) => isLGScreenOrBigger && `grid-template-columns: repeat(3, minmax(0, 1fr));`}
`;

const StyledListGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  & li:not(:last-child) {
    margin-bottom: ${SPACING.STATIC.XS.px};
  }
`;

type Props = {
  readonly address: Address;
  readonly shipment: Shipment | undefined;
};

const origin = ORIGINS.STORE;

export const StoreContent = ({ address, shipment }: Props) => {
  const { isMDScreenOrBigger, isLGScreenOrBigger } = useScreenSize();
  const dispatch = useDispatchShared();
  const { isPending, error, essentials, categories } = useQueryEssentials({
    addressID: address.id,
    date: shipment?.scheduled_date,
  });
  const activeCategory = useSelectorShared((state) => state.essentials.activeCategory);
  const [activeProduct, setActiveProduct] = useState<number | null>(null);
  const isMobile = !useDeprecatedMediaQuery('SM');
  const sidePadding = useSidePadding();
  const { searchResults, searching, searchTerm, onSearchClear, onSearchUpdate } = useEssentialsSearch({ essentials });
  const facilityId = address?.dispensing_facility_id;
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(viewStore());
  }, [dispatch]);

  useEffect(() => {
    onSearchClear();
    dispatch(setActiveEssentialCategory(CategorySearch.All));
  }, [onSearchClear, dispatch]);

  useEffect(() => {
    const categoryName = getActiveCategoryName(activeCategory, categories);
    if (!searching && categoryName) {
      trackEvent({
        event:
          (activeCategory as CategorySearch) === CategorySearch.Search
            ? EVENTS.ESSENTIALS__SEARCH_RESULTS_VIEWED
            : EVENTS.ESSENTIALS__CATEGORY_DETAILS_VIEWED,
        params: {
          category: categoryName,
          shipment_id: shipment?.id,
          facility_id: facilityId,
          query_string: searchTerm,
          origin,
        },
      });
    }
  }, [activeCategory, searchTerm, searching]); // eslint-disable-line react-hooks/exhaustive-deps

  const addOnsContext = useMemo<AddOnsContextProps>(
    () => ({
      shipmentID: shipment?.id,
      origin,
      setActiveProduct,
      activeProduct,
    }),
    [shipment?.id, activeProduct],
  );

  const activeProducts = useMemo(() => {
    switch (activeCategory) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      case CategorySearch.Search:
        return searchResults;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      case CategorySearch.All:
        return essentials;
      default:
        return categories[activeCategory]?.add_on_otcs || [];
    }
  }, [activeCategory, searchResults, essentials, categories]);

  const handleCategoryPress = (index: number) => () => {
    dispatch(setActiveEssentialCategory(index));
    onSearchClear();
  };

  const handleSearchChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const category = value.trim().length === 0 ? CategorySearch.All : CategorySearch.Search;
    onSearchUpdate(value);
    dispatch(setActiveEssentialCategory(category));
  };

  if (isPending) {
    return <LoadingPage />;
  }
  if (error) {
    return (
      <ErrorPage
        title="Unable to load Essentials"
        description={error?.message || 'unknown error'}
      />
    );
  }
  if (!categories) {
    return (
      <ErrorPage
        title="Unable to load Essentials"
        description="missing categories"
      />
    );
  }

  if (isMobile) {
    return (
      <AddOnsContext.Provider value={addOnsContext}>
        <StoreHeaderWrapper shipment={shipment}>
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </StoreHeaderWrapper>
        <View flexDirection="column">
          <XlSpacing />
          <MobileProductList
            facilityId={facilityId}
            categories={categories}
            activeProducts={activeProducts}
            activeCategory={activeCategory}
            isSearching={searching}
            searchQuery={searchTerm}
            origin={origin}
            shipmentID={shipment?.id}
          />
        </View>
      </AddOnsContext.Provider>
    );
  }
  return (
    <AddOnsContext.Provider value={addOnsContext}>
      <StoreHeaderWrapper shipment={shipment}>
        <XlSpacing />
        <SearchInput
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <View flexDirection="column">
          <LgSpacing />
          <StyledListGroup>
            <CategoryItem
              name="All"
              image="https://assets.prod.alto.com/otc_categories/browser.png"
              active={(activeCategory as CategorySearch) === CategorySearch.All}
              onPress={handleCategoryPress(CategorySearch.All)}
            />
            {categories.map((category, index) => (
              <CategoryItem
                key={category.name}
                name={category.name}
                image={category.image_url}
                active={activeCategory === index}
                onPress={handleCategoryPress(index)}
              />
            ))}
          </StyledListGroup>
        </View>
      </StoreHeaderWrapper>
      <View
        display="block"
        flexShrink={1}
        flexBasis="65%"
        backgroundColor="WHITE"
        padding={`${SPACING.STATIC.XXXL.px} ${sidePadding} ${SPACING.STATIC.XXXL.px} ${SPACING.STATIC.XXL.px}`}
      >
        {searching ? (
          <FullPage>
            <LoadingPage />
          </FullPage>
        ) : null}
        {!searching && activeProducts.length === 0 ? (
          <NoSearchResults
            queryString={searchTerm}
            origin={origin}
            facilityId={facilityId}
            shipmentId={shipment?.id}
          />
        ) : (
          <StyledProductList
            isMDScreenOrBigger={isMDScreenOrBigger}
            isLGScreenOrBigger={isLGScreenOrBigger}
          >
            {activeProducts.map((product, i) => (
              <ProductItem
                key={product.sku}
                product={product}
                analyticsProps={{
                  slot: i + 1,
                  sku: product.sku,
                  sku_type: product.sku_type,
                  otc_name: product.name,
                  category: getActiveCategoryName(activeCategory, categories),
                  query_string: searchTerm,
                  shipment_id: shipment?.id,
                  facility_id: facilityId,
                  origin,
                }}
              />
            ))}
          </StyledProductList>
        )}
      </View>
    </AddOnsContext.Provider>
  );
};
