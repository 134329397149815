import { type BACKGROUND_COLORS_VALUES, COLORS, SIZES } from '@alto/design-library-tokens';
import * as React from 'react';
import { type ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';
import { Column } from '../../containers';
import { SizedImage } from '../../images';
import { Text } from '../../typography';

export type AvatarProps = {
  readonly image?: ImageSourcePropType;
  readonly initials?: string;
  readonly name: string;
  readonly size?: number;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
};

const AvatarContainer = styled(Column)<{ size: number }>`
  ${({ size }) => `
    width: ${size}px
    height: ${size}px
    border-radius: ${size / 2}px;
  `}
`;

export const Avatar = ({
  image,
  initials,
  name,
  size = SIZES.AVATAR.SM.value,
  backgroundColor = COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER,
}: AvatarProps) => {
  // This is a fallback for when the initials are not supplied, but can lead to weird behavior:
  // Examples: "Dr. Amy Chen" (DC), "Salvador Felipe Jacinto Dalí y Domenech, MD" (SM)
  const approximateInitials = () => {
    const words = name.split(' ');

    if (words.length === 1) {
      return words[0].slice(0, 1);
    }

    return words[0].slice(0, 1).concat(words[words.length - 1].slice(0, 1));
  };

  return image ? (
    <SizedImage
      source={image}
      pixelSize={size}
      borderRadius={size / 2}
      accessibilityLabel={`Avatar for ${name}`}
    />
  ) : (
    <AvatarContainer
      center
      size={size}
      backgroundColor={backgroundColor}
    >
      <Text
        fontFamily="semibold"
        color={COLORS.TEXT_COLORS.PRIMARY}
      >
        {initials || approximateInitials()}
      </Text>
    </AvatarContainer>
  );
};
