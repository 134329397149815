// @owners { team: patients-team }
import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
import {
  getLastRequestAttemptedAt,
  getLastRequestSucceededAt,
  getRequestAttemptCount,
} from '~shared/features/delivery/selectors/getDeliveries';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type StateUiLoading } from '~shared/reducers/ui/loading';
import { getResourceAvailable } from '~shared/selectors/getResource';

const getIsFetchingDeliveries = createSelector([getLoading], (loading: StateUiLoading): boolean => {
  return loading.fetchActiveDeliveriesLoading || loading.fetchDeliveriesLoading;
});

export const options = {
  // eslint-disable-next-line import/no-deprecated
  fetcher: fetchDeliveries,
  isFetching: getIsFetchingDeliveries,
  lastFetchAttempt: getLastRequestAttemptedAt,
  fetchAttemptCount: getRequestAttemptCount,
  lastFetchSuccess: getLastRequestSucceededAt,
};

const getAreShipmentsAvailable = getResourceAvailable(options);

export default getAreShipmentsAvailable;
