// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  Danger,
  FlexRow,
  InputBlockPassword,
  spacing,
  validatePassword,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  margin-bottom: ${spacing.smSpacing};
`;

const SubmitButton = styled(Button)`
  margin-top: ${spacing.mdSpacing};
`;

export type Values = {
  password: string;
  passwordConfirmation: string;
};

type Props = {
  readonly onSubmit: (values: Values) => void;
  readonly resetPasswordError: string | null | undefined;
  readonly loading?: boolean;
};

type ResetPasswordErrors = {
  password?: string;
  passwordConfirmation?: string;
};

class ResetPasswordForm extends React.Component<Props> {
  static defaultProps = {
    resetPasswordError: null,
  };

  validate(values: Values) {
    const { password, passwordConfirmation } = values;
    const errors: ResetPasswordErrors = {};
    const passwordErrors = validatePresentWithMessage('Password')(password) || validatePassword(password);
    const passwordConfirmationErrors = password !== passwordConfirmation ? 'Passwords do not match' : '';

    if (passwordErrors) {
      errors.password = passwordErrors;
    }

    if (passwordConfirmationErrors) {
      errors.passwordConfirmation = passwordConfirmationErrors;
    }

    return errors;
  }

  render() {
    const { onSubmit, resetPasswordError } = this.props;

    return (
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        onSubmit={onSubmit}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        validate={this.validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          {resetPasswordError ? (
            <ErrorContainer>
              <Danger>{resetPasswordError}</Danger>
            </ErrorContainer>
          ) : null}
          <FlexRow spacing={spacing.mdSpacing}>
            <Field name="password">
              {({ field, meta }: FieldProps<string, Values>) => (
                <InputBlockPassword
                  type="password"
                  label="New password"
                  info="Must be at least 8 characters long"
                  placeholder="New password"
                  meta={meta}
                  {...field}
                />
              )}
            </Field>
            <Field name="passwordConfirmation">
              {({ field, meta }: FieldProps<string, Values>) => (
                <InputBlockPassword
                  type="password"
                  label="Retype password"
                  placeholder="Retype password"
                  meta={meta}
                  {...field}
                />
              )}
            </Field>
          </FlexRow>
          {/* @ts-expect-error: Property 'onClick' is missing in type */}
          <SubmitButton
            full
            kind="primary"
            type="submit"
          >
            Login and Update Password
          </SubmitButton>
        </Form>
      </Formik>
    );
  }
}

export default ResetPasswordForm;
