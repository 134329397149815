// @owners { team: patients-team }
import { H1 } from '@alto/design-system';
import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { validateUser } from '~shared/actions/auth';
// eslint-disable-next-line import/no-deprecated
import { fetchOnboardState, setInsuranceLoadingSeen } from '~shared/actions/onboarding';
import {
  ONBOARD_STATE_DONE,
  ONBOARD_STATE_MED_CONFIRMATION,
  ONBOARD_STATE_TO_ROUTE,
  type Route,
  VERIFY,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { DATE_FORMATS } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Values as VerificationDetails, Verify } from './Verify';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

type Props = {
  params: { tempPassword?: string };
};

export const OnboardingVerifyContainer = ({ params: { tempPassword = '' } }: Props) => {
  const [onboardState, setOnboardState] = useState('');
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const state = useSelectorShared((state) => state);
  const nextRoute = (desiredOnboardRoute?: Route) => nextUrl(state, VERIFY, desiredOnboardRoute);

  useEffect(() => {
    const navigateIfOnboardingDone = async () => {
      // eslint-disable-next-line import/no-deprecated
      const result: { onboard_state?: string } = await dispatch(fetchOnboardState(tempPassword));
      const onboardState = result.onboard_state;
      setOnboardState(onboardState ?? '');

      if (onboardState === ONBOARD_STATE_DONE) {
        dispatch(push('/'));
      }
    };
    navigateIfOnboardingDone();
  }, [dispatch, tempPassword]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__VERIFY_ACCOUNT_VIEWED,
      params: {
        flow: 'onboard',
      },
      additionalFields: {
        userIdentifier: tempPassword,
        userIdentifierType: 'temp_password',
      },
    });
  }, [tempPassword, trackPageView]);

  const onVerify = async (data: VerificationDetails) => {
    setDisabled(true);
    const dob = format(
      parse(data.date_of_birth, DATE_FORMATS.DATE_INPUT_FORMAT, 0),
      DATE_FORMATS.YEAR_MONTH_DAY_DASHED,
    );
    try {
      const success = await dispatch(
        // eslint-disable-next-line import/no-deprecated
        validateUser({
          last_name: data.last_name,
          date_of_birth: dob,
          token: tempPassword,
          terms: data.terms,
        }),
      );

      if (!success) {
        return;
      }

      if (onboardState) {
        if (isAfterInsuranceLoading(onboardState)) {
          dispatch(setInsuranceLoadingSeen());
        }

        dispatch(push(nextRoute(ONBOARD_STATE_TO_ROUTE[onboardState])));
      } else {
        dispatch(push(nextRoute()));
      }
    } finally {
      setDisabled(false);
    }
  };

  const isAfterInsuranceLoading = (onboardState: string) => {
    return onboardState !== ONBOARD_STATE_MED_CONFIRMATION;
  };

  return (
    <PageBackground white>
      <SingleColumnContentWrapper>
        <H1 center>Let’s verify your identity.</H1>
        <Verify
          tempPassword={tempPassword}
          onVerify={onVerify}
          disabled={disabled}
        />
      </SingleColumnContentWrapper>
    </PageBackground>
  );
};
