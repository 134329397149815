import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type StatusMap } from '~shared/features/shipments/constants';
import { isMail, isPickup } from '~shared/helpers/order';
import { type ShipmentStatus } from '~shared/types';

export const getShipmentStatusString = (
  status: ShipmentStatus | undefined,
  shipmentStatusMap: StatusMap,
  isPickup = false,
): string | undefined => {
  if (!status) {
    return '';
  }
  if (isPickup) {
    return shipmentStatusMap[status]?.pickupStatusString;
  }
  return shipmentStatusMap[status]?.statusString;
};

export const getShipmentStatusInfoText = (
  status: ShipmentStatus | undefined,
  method: DeliveryMethod | undefined,
  shipmentStatusMap: StatusMap,
) => {
  if (!status || !shipmentStatusMap[status]) {
    return '';
  }

  const config = shipmentStatusMap[status];

  if (!method) {
    return config?.infoText;
  }

  if (isMail(method?.mode)) {
    return config?.mailInfoText;
  }

  if (isPickup(method?.mode)) {
    return config?.pickupInfoText;
  }

  return config?.infoText;
};
