// @owners { team: patients-team }
import { InputText } from '@alto/design-system';
import React from 'react';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  value: string;
};

export const InsuranceBinInput = ({ error, onChange, value }: Props) => {
  return (
    <InputText
      autoCorrect={false}
      caption="6 Digit Number"
      error={error}
      id="bin"
      keyboardType="numeric"
      label="Rx BIN"
      onChangeText={onChange}
      placeholder="000000"
      required
      value={value}
    />
  );
};
