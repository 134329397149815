import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import { createSelector } from 'reselect';
import { getPrescriptions } from './getPrescriptions';
import { BILLING_ISSUES, BILLING_STATUS } from '~shared/constants';
import { getNextDeliveryByPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { getUpcomingDeliveriesByPrescriptionID } from '~shared/features/delivery/selectors/getUpcomingDeliveriesForPrescription';
import { getNoAvailableDates } from '~shared/features/next-available-date/helpers/getNoAvailableDates';
import { getNextAvailableDatesByPrescriptionID } from '~shared/features/next-available-date/selectors/getNextAvailableDatesByPrescriptionID';
import { isActiveRx } from '~shared/features/prescriptions/helper';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type LightDelivery, type NextAvailableDate, type Prescription, type ReduxStateShared } from '~shared/types';

export type ScheduleableByPrescriptionID = Record<number, boolean>;

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

export const isDeliveryForRefillTooSoon = (delivery?: LightDelivery) => {
  return delivery?.billing_status === BILLING_STATUS.REFILL_TOO_SOON;
};

const isDeliveryScheduleable = (delivery: LightDelivery) => {
  /**
   * If there are any insurance issues with the delivery, we should
   * not allow the patient to schedule it.
   */
  if (
    delivery.comms_state === BILLING_ISSUES.needs_insurance ||
    delivery.comms_state === BILLING_ISSUES.process_insurance_choice
  ) {
    return false;
  }

  /**
   * Patients can schedule refill too soon deliveries because the next available
   * date system will enforce appropriate date restrictions.
   */
  return isDeliveryForRefillTooSoon(delivery) || !delivery.processing;
};

const isFirstFill = ({ refills_used }: Prescription) => {
  return refills_used === 0 || refills_used === null;
};

/**
 * Returns a boolean indicating whether the prescription is scheduleable.
 *
 * The `respectPriorAuthStatus` flag option is needed because prior auth
 * conditions are currently only implemented for web. The need for this
 * exception should go away after pricing choices lands on both platforms.
 */
export const isPrescriptionScheduleable = (
  prescription: Prescription,
  delivery: LightDelivery | undefined,
  upcomingDeliveries: LightDelivery[],
  nextAvailableDate: NextAvailableDate | undefined,
) => {
  // Always allow Essentials/OTCs to be scheduled if is not already scheduled or delivered.
  if (prescription.is_add_on_otc) {
    return !prescription.last_delivered_at && upcomingDeliveries.length === 0;
  }

  if (!isActiveRx(prescription)) {
    return false;
  }

  if (upcomingDeliveries.length > 0) {
    return false;
  }

  // return prescription not schedulable if latest available date is before next available date (eg rx expires before it
  // is next in stock), or if there's no next available date (long term backorder).
  // both dates provided from the backend to preserve the restriction reasons, so that we may surface these to the user
  if (getNoAvailableDates(nextAvailableDate)) {
    return false;
  }

  /**
   * First fills are visible to patients as soon as they're intaken, but we
   * don't allow a patient to schedule until the prescription has been billed.
   *
   * We create a delivery for a new prescription at intake, but it's
   * possible that that delivery has been canceled by the time the
   * patient actually schedules the first fill. We treat these cases
   * like refills and simply respect refills remaining.
   */
  if (isFirstFill(prescription) && delivery) {
    return isDeliveryScheduleable(delivery);
  }

  return (
    (prescription.refills_remaining || 0) > 0 &&
    prescription.status !== PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE &&
    prescription.status !== PrescriptionStatusMap.PROGYNY_EARLY_ORDER_REQUESTED &&
    prescription.status !== PrescriptionStatusMap.PROGYNY_EXPIRED_COVERAGE
  );
};

export const getIsScheduleableByPrescriptionID = createSelector(
  [
    getPrescriptions,
    getNextDeliveryByPrescriptionID,
    getUpcomingDeliveriesByPrescriptionID,
    getNextAvailableDatesByPrescriptionID,
  ],
  (prescriptions, nextDeliveriesByPrescription, upcomingDeliveriesByPrescriptionID, availabilityData) => {
    const scheduleableByPrescriptionID: ScheduleableByPrescriptionID = {};

    prescriptions.forEach((prescription) => {
      const delivery = nextDeliveriesByPrescription[prescription.id];
      const upcomingDeliveries = upcomingDeliveriesByPrescriptionID[prescription.id] || [];
      const nextAvailableDate = availabilityData[prescription.id];
      scheduleableByPrescriptionID[prescription.id] = isPrescriptionScheduleable(
        prescription,
        delivery,
        upcomingDeliveries,
        nextAvailableDate,
      );
    });

    return scheduleableByPrescriptionID;
  },
);

export const getIsPrescriptionScheduleable = createSelector(
  [getIsScheduleableByPrescriptionID, getPrescriptionID],
  (scheduleableByPrescriptionID, prescriptionID) => {
    return scheduleableByPrescriptionID[prescriptionID] || false;
  },
);
