// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { type Shipment } from '~shared/types';
import { type ShipmentFee } from '~shared/types/shipments';

export const getShipmentFees = (shipment: Shipment): ShipmentFee[] => shipment.shipment_fees || [];

export const getAsapShipmentFee = (shipment: Shipment): ShipmentFee | undefined =>
  getShipmentFees(shipment).find((shipmentFee) => shipmentFee.fee_type === 'asap_delivery');

export const getOnDemandShipmentFee = (shipment: Shipment): ShipmentFee | undefined =>
  getShipmentFees(shipment).find((shipmentFee) => shipmentFee.fee_type === 'on_demand_delivery');

export const getEssentialsOnlyShipmentFee = (shipment: Shipment): ShipmentFee | undefined =>
  getShipmentFees(shipment).find((shipmentFee) => shipmentFee.fee_type === 'essentials_only_delivery');

export const getDeliveryFeeFromShipment = (shipment: Shipment): ShipmentFee | undefined =>
  getShipmentFees(shipment).find((shipmentFee) =>
    ['asap_delivery', 'same_day_delivery', 'next_day_delivery', 'standard_delivery'].includes(shipmentFee.fee_type),
  );

export const getHasEssentialsOnlyShipmentFee = createSelector([getShipmentWithID], (shipment) => {
  if (!shipment) {
    return false;
  }

  return Boolean(getEssentialsOnlyShipmentFee(shipment));
});
