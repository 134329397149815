// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';

const getPathname = (state: ReduxStateShared): string | null | undefined =>
  state.routing.locationBeforeTransitions?.pathname || window.location.pathname;

const getIsOnboardingRoute: (arg0: ReduxStateShared) => boolean = createSelector(
  [getPathname],
  (pathname: string | null | undefined) => {
    // FYI the first screen of the old onboarding flow is on /onboard/<temp_password>
    return Boolean(pathname?.startsWith('/onboard'));
  },
);

export const getIsOrganicOnboardingLandingPage: (arg0: ReduxStateShared) => boolean = createSelector(
  [getPathname],
  (pathname: string | null | undefined) => {
    return pathname === '/patient-onboarding';
  },
);

export default getIsOnboardingRoute;
