export const GREYSCALE = {
  BLACK: '#192026',
  DARKEST: '#404A52',
  DARKER: '#687382',
  DARK: '#A9B1BC',
  DEFAULT: '#DCE1E6',
  LIGHT: '#EDEDE8',
  LIGHTER: '#F7F5F4',
  LIGHTEST: '#FFFAF4',
  WHITE: '#FFFFFF',
  BLACK_TRANSPARENT: 'rgba(25, 32, 38, 0.75)',
  TRANSPARENT: 'transparent',
} as const;
