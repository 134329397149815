import { Experimentation } from '@alto/experimentation';
import { type Cart } from '@alto/orders_api/types/v1/cart';
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import { type CartEndpointFetchActiveRequest } from '@alto/scriptdash/alto/patient_app/carts/v1/cart_endpoint';
import { useMemo } from 'react';
import { getReduxCart } from '~shared/features/cart/selectors/getReduxCart';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { staleTimes } from '~shared/queries/constants';
import { queries } from '~shared/queries/query-keys';
import { useQuery } from '~shared/react-query';
import { useSelectorShared } from '~shared/store';

const params: CartEndpointFetchActiveRequest = {};
type CartData = {
  cart: Cart | null | undefined;
  cartItems: CartItem[];
  cartSize: number;
  // map from prescription id to auto refill status
  cartAutoRefills: Record<number, boolean>;
  isError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error: unknown | undefined;
  isLoading: boolean;
  updatedAt: number;
};

export const useCartData = (): CartData => {
  const { value: serverSideCartEnabled } = Experimentation.useFeatureFlag('server_side_cart');
  const isEditingExistingOrder = useSelectorShared(getIsEditingExistingOrder);

  // query is disabled only if enabled === false
  // https://alto.slack.com/archives/C017B78JVL0/p1674166049892829
  const queryEnabled: boolean = (serverSideCartEnabled && !isEditingExistingOrder) || false;
  const { data, isError, error, isLoading, dataUpdatedAt } = useQuery({
    ...queries.cart.active(params),
    enabled: queryEnabled,
    staleTime: staleTimes.fiveMinutes,
  });
  const reduxCart = useSelectorShared(getReduxCart);
  const cart = data?.data?.data;

  const useRedux = !serverSideCartEnabled || isEditingExistingOrder;
  const cartItems = useMemo(() => {
    return useRedux ? reduxCart.items : cart?.items || [];
  }, [useRedux, reduxCart.items, cart?.items]);
  const cartAutoRefills = useMemo(() => {
    return buildCartAutoRefillMap(cartItems);
  }, [cartItems]);

  if (useRedux) {
    return {
      cart: reduxCart,
      cartItems: reduxCart.items,
      cartSize: reduxCart.items.length,
      cartAutoRefills,
      isError: false,
      error: undefined,
      isLoading: false,
      updatedAt: new Date(reduxCart.updated_at).valueOf(),
    };
  }

  return {
    cart,
    cartItems: cart?.items || [],
    cartSize: cart?.items?.length || 0,
    cartAutoRefills,
    isError,
    error,
    isLoading,
    updatedAt: dataUpdatedAt,
  };
};

const buildCartAutoRefillMap = (cartItems: CartItem[]): Record<number, boolean> => {
  return cartItems.reduce<Record<number, boolean>>((acc, cartItem) => {
    if (cartItem.auto_refill_enabled === null || cartItem.auto_refill_enabled === undefined) {
      return acc;
    }
    acc[cartItem.resource_id] = cartItem.auto_refill_enabled;
    return acc;
  }, {});
};
