// @owners { team: patients-team }
import { ListButton } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { type ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { type DeliveryDetails } from '~shared/types';

type DeliveryCardCTAProps = {
  readonly shipmentID: number | string;
  readonly origin?: (typeof ORIGIN_NAMES)[keyof typeof ORIGIN_NAMES];
} & CTALabelArgs;

type CTALabelArgs = {
  readonly deliveryDetails?: DeliveryDetails;
  readonly isEditable?: boolean;
};

const getLabel = ({ deliveryDetails, isEditable }: CTALabelArgs) => {
  if (deliveryDetails) {
    return 'Upcoming order';
  }

  if (isEditable) {
    return 'View or edit order';
  } else {
    return 'View order';
  }
};

export const DeliveryCardCTA = ({ deliveryDetails, isEditable, shipmentID, origin }: DeliveryCardCTAProps) => {
  const { navigate } = useNavigation();
  const handlePress = () => {
    navigate('RouteOrderDetails', { shipmentID, state: { origin } });
  };

  return (
    <ListButton
      label={getLabel({ deliveryDetails, isEditable })}
      rightIcon={isEditable ? 'edit' : 'chevronright-small'}
      onPress={handlePress}
    />
  );
};
