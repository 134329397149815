import { Platform } from 'react-native';
import getCookie from './getCookie';
import { BACKEND_ENVIRONMENT } from '~shared/config';

const getCookieName = () => {
  switch (BACKEND_ENVIRONMENT) {
    case 'staging':
    case 'sandbox':
      return 'sandbox_csrf_token';

    case 'local':
      return 'dev_csrf_token';

    default:
      return 'csrf_token';
  }
};

export default (): string | null | undefined => {
  if (Platform.OS !== 'web') {
    return '';
  }
  return getCookie(getCookieName());
};
