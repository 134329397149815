// @owners { team: patients-team }
import { sortBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { type LightDoctor } from '~shared/types';

const mapDoctorToNamePair = ({ first_name, last_name }: LightDoctor) => `${first_name || ''}:${last_name || ''}`;

export const getDoctorsFromPrescriptions = createSelector([getPrescriptions], (prescriptions) => {
  const doctors = prescriptions.reduce<LightDoctor[]>((doctors, { doctor }) => {
    if (doctor) {
      doctors.push(doctor);
    }
    return doctors;
  }, []);

  // Get a list of unique doctors
  const uniqueDoctors = uniqBy(doctors, mapDoctorToNamePair);

  // Sort alphabetically by last name
  return sortBy(uniqueDoctors, 'last_name');
});
