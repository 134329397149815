import * as React from 'react';
import Loader, { BulletList } from 'react-content-loader';

export const ContentLoader = ({
  accessibilityLabel,
}: {
  /**
   * The aria-label for the loader.
   */
  readonly accessibilityLabel: string;
}) => {
  return <BulletList aria-label={accessibilityLabel} />;
};

/**
 * Generic rectangle loader that fills its container width and has a fixed height.
 */
export const RectLoader = ({
  accessibilityLabel,
  height,
}: {
  /**
   * The aria-label for the loader.
   */
  readonly accessibilityLabel: string;

  /**
   * The height of the loader.
   */
  readonly height: number;
}) => {
  return (
    <Loader
      aria-label={accessibilityLabel}
      preserveAspectRatio="none"
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        height,
        overflow: 'hidden',
      }}
      viewBox="0 0 300 24"
    >
      <rect
        height={height}
        rx="0"
        ry="0"
        width="100%"
        x="0"
        y="0"
      />
    </Loader>
  );
};
