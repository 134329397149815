// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext } from '@alto/design-system';
import { AddressFormActionSheet, ContactUs } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Loader, Modal, ModalContent, Select, Text } from '@alto/pocky';
import { DateRestrictionReasonMap } from '@alto/scriptdash/alto/scheduling/types/v1/date_restriction_reason';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { getAddressCreateSuccess } from '~shared/features/addresses/selectors/getAddresses';
import { useQueryNextAvailableDateMessaging } from '~shared/features/cart/hooks/usePrescriptionNextAvailableDateMessaging';
import getAddressPlaceholder from '~shared/features/next-available-date/helpers/getAddressPlaceholder';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getNextAvailableDateAddress } from '~shared/features/next-available-date/selectors/getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from '~shared/features/next-available-date/selectors/getNextAvailableDateFacilityId';
import { getPrescriptionUserIsFertility } from '~shared/features/prescriptions/selectors/getPrescriptionUser';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types/clients';
import getAddressOptions from '~web/features/my-meds/selectors/getAddressOptions';

const RefreshingText = styled(Text)`
  padding-left: 2px;
`;

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

const StyledModalContent = styled(ModalContent)<{ hasMinHeight: boolean }>`
  min-height: ${(props) => (props.hasMinHeight ? '500px' : '0px')};
`;

export type NextAvailableDateModalProps = {
  readonly onClose: () => void;
  readonly origin?: OriginName;
  readonly prescription: Prescription;
};

export const NextAvailableDateModal = ({ onClose, origin, prescription }: NextAvailableDateModalProps) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const [showForm, setShowForm] = useState(false);
  const address = useSelectorShared(getNextAvailableDateAddress);
  const addressOptions = useSelectorShared(getAddressOptions);
  const createdAddress = useSelectorShared(getAddressCreateSuccess);
  const isFertUser = useSelectorShared((state) => getPrescriptionUserIsFertility(state, prescription.id));
  const facilityID = useSelectorShared(getNextAvailableDateFacilityID);

  const [hasMinHeight, setHasMinHeight] = useState(false);
  const createdAddressRef = useRef(createdAddress);
  const prescriptionID = prescription.id;
  const { isLoading, nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID });
  const earliestAvailableDateReason = nextAvailableDate?.earliest?.reason;
  const { message } = useQueryNextAvailableDateMessaging({ prescriptionID });

  useEffect(() => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(
          EVENTS.NEXT_AVAILABLE_DATE_ACTION_SHEET_VIEWED,
          { reason: earliestAvailableDateReason, origin },
          { prescriptionId: prescription.id },
        ),
      ),
    );
  }, [dispatch, earliestAvailableDateReason, origin, prescription]);

  useEffect(() => {
    if (createdAddressRef.current !== createdAddress) {
      onClose();
    }
  }, [createdAddress, onClose]);

  const handleInputChange = (addressID: number | 'NEW_ADDRESS') => {
    if (addressID === 'NEW_ADDRESS') {
      setShowForm(true);
      setActiveActionSheet(<AddressFormActionSheet />);
    } else if (addressID !== address?.id) {
      dispatch(updateOrder({ address_id: addressID }));
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchNextAvailableDates({ addressID }));
    }
  };

  const isOutOfStock = earliestAvailableDateReason === DateRestrictionReasonMap.OUT_OF_STOCK;

  return (
    <StyledModal
      onClose={onClose}
      showing
      title="Next Available Date"
    >
      {!showForm && (
        <StyledModalContent hasMinHeight={hasMinHeight}>
          <Column spacing={SPACING.STATIC.MD.rem}>
            {!!message && <Text>{message}</Text>}
            {isFertUser && isOutOfStock ? (
              <Text>
                If you need this prescription urgently, please{' '}
                <ContactUs
                  type="link"
                  method="message"
                  origin={CONTACT_US_ORIGIN.NextAvailableDateModal}
                />{' '}
                and we'll see how we can speed things up.
              </Text>
            ) : null}
            {!isFertUser && isOutOfStock ? (
              <Text>
                If you need this prescription urgently, contact another pharmacy to transfer{' '}
                {prescription.medication_name} or ask your provider to send a new prescription to them directly.
              </Text>
            ) : null}
            <Text>Dates are estimated based on your address:</Text>
            <Select
              input={{
                onChange: handleInputChange,
                value: address?.id,
              }}
              options={addressOptions}
              reactSelectConfig={{
                placeholder: getAddressPlaceholder(address, facilityID),
                onMenuOpen: () => {
                  setHasMinHeight(true);
                },
                onMenuClose: () => {
                  setHasMinHeight(false);
                },
              }}
            />
            {isLoading ? (
              <RefreshingText inline>
                <Loader text="Refreshing the date..." />
              </RefreshingText>
            ) : null}
          </Column>
        </StyledModalContent>
      )}
    </StyledModal>
  );
};
