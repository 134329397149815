// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, MdSpacing, Tag } from '@alto/design-system';
import {
  AncillaryRow,
  CalendarRow,
  DeliveryCardCTA,
  DeliveryInstructionsRow,
  DeliveryMethodRow,
  LocationRow,
  MedRow,
  PriceRow,
  useFetchAncillariesByPrescriptionIds,
  useGetDeliveryMethodsByShipmentIDs,
} from '@alto/features';
import React from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getUpcomingOrderLabel } from '~shared/features/homescreen/utils';
import { getShipmentTotal } from '~shared/features/pricing/selectors/getOrderTotal';
import { getAsapShipmentFee } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { getShipmentStatusString } from '~shared/features/shipments/helpers/getShipmentStatusConfig';
import { getWhatToExpectCopy } from '~shared/features/shipments/helpers/getWhatToExpectCopy';
import { useShipmentStatusMap } from '~shared/features/shipments/hooks/useShipmentStatusMap';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { getShipmentWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { formatFullAddress } from '~shared/helpers/address';
import { isPickup } from '~shared/helpers/order';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useSelectorShared } from '~shared/store';
import { type Shipment } from '~shared/types';
import { useDeprecatedMediaQuery } from '~web/hooks';

const orderCompleteImage = 'https://assets.prod.alto.com/otc_categories/box.svg';

type Props = {
  shipment: Shipment;
  showTitleIllustration: boolean;
};

export const OrderCard = ({ shipment, showTitleIllustration }: Props) => {
  const isMobile = !useDeprecatedMediaQuery('SM');

  const isAsapDelivery = !shipment ? false : !!getAsapShipmentFee(shipment);

  const routeState = useSelectorShared(getRouteState);
  const newPrescriptionIDs = routeState?.bundledPrescriptionIDs || [];
  const shipmentWithItems = useSelectorShared((state) =>
    getShipmentWithItemsByID(state, {
      id: shipment.id,
    }),
  );
  const { ancillaries, isPending } = useFetchAncillariesByPrescriptionIds({
    prescriptionIds: shipmentWithItems?.items.map((item) => item.prescription.id),
  });
  const address = useSelectorShared((state) => getShipmentAddress(state, { shipmentID: shipment.id }));
  const medicationNames = shipmentWithItems
    ? shipmentWithItems.items.map((item) => {
        if (!newPrescriptionIDs.includes(item.prescription.id) && item.medication) {
          return item.medication.medicationName;
        }
        return '';
      })
    : [];
  const newMedicationNames = newPrescriptionIDs
    ? newPrescriptionIDs.map((id: number) => {
        const item = shipmentWithItems?.items.find((item) => item.prescription.id === id);
        return item?.medication ? item.medication.medicationName : item?.prescription.medication_name;
      })
    : [];

  const price = useSelectorShared(getShipmentTotal)?.value;
  const shipmentStatusMap = useShipmentStatusMap();
  const shipmentStatusString = getShipmentStatusString(shipment?.status, shipmentStatusMap, shipment?.is_pickup);

  const { deliveryMethodsByShipmentID } = useGetDeliveryMethodsByShipmentIDs([shipment.id]);
  const deliveryMethod = deliveryMethodsByShipmentID[shipment.id];

  return (
    <Card
      title={getUpcomingOrderLabel({
        isPickup: isPickup(deliveryMethod?.mode),
      })}
      description={getWhatToExpectCopy(deliveryMethod, shipmentStatusMap)}
      illustrationSrc={showTitleIllustration && !isMobile ? { uri: orderCompleteImage } : undefined}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.orderBox}
      tag={
        <Tag
          label={shipmentStatusString}
          type="recommended"
        />
      }
    >
      <CalendarRow
        date={shipment?.scheduled_date}
        startTime={shipment?.scheduled_deliver_after}
        endTime={shipment?.scheduled_deliver_before}
        method={deliveryMethodsByShipmentID[shipment.id]}
        status={shipment?.status}
        origin="order_success"
        isAsapDelivery={isAsapDelivery}
      />
      <LocationRow
        address={formatFullAddress(address)}
        isPickup={shipment?.is_pickup}
        showMap={shipment?.status !== 'delivered'}
      />
      <DeliveryMethodRow shipmentID={shipment.id} />
      <AncillaryRow
        show={ancillaries.length > 0}
        isLoading={isPending}
      />
      <MedRow
        medicationNames={medicationNames}
        newMedicationNames={newMedicationNames}
        showAllMeds
      />
      {shipmentWithItems && (
        <DeliveryInstructionsRow
          shipment={shipmentWithItems}
          address={address}
        />
      )}
      <PriceRow price={price} />
      <MdSpacing />
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <DeliveryCardCTA shipmentID={shipment.id} />
      </LgPadding>
    </Card>
  );
};
