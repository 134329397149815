// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES, COLORS } from '@alto/design-library-tokens';

const onboardingBackgroundColorForPartnership = (
  partnershipName: string | null | undefined,
): BACKGROUND_COLORS_VALUES | null | undefined => {
  if (!partnershipName) {
    return null;
  }
  const colors = new Map<string, BACKGROUND_COLORS_VALUES>([
    ['alto', COLORS.BACKGROUND_COLORS.GREY_LIGHTEST],
    ['progyny', COLORS.BACKGROUND_COLORS.PINK_LIGHTEST],
  ]);
  return colors.get(partnershipName);
};

export const onboardingBackgroundColor = (partnershipName: string | null | undefined, showProgyny: boolean) => {
  const partnershipColor = onboardingBackgroundColorForPartnership(partnershipName);

  if (showProgyny) {
    return COLORS.BACKGROUND_COLORS.PINK_LIGHTEST;
  } else if (partnershipColor) {
    return partnershipColor;
  } else {
    return COLORS.BACKGROUND_COLORS.GREY_LIGHTEST;
  }
};
