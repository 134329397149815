// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Button,
  Card,
  Column,
  H1,
  InputSelect,
  InputText,
  LgPadding,
  LgSpacing,
  SecondaryPage,
  Toast,
  ToastContext,
  XxxlPadding,
  useScreenSize,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Platform } from 'react-native';
// eslint-disable-next-line import/no-deprecated
import { cancelDeliveries, clearDeliveriesError } from '~shared/actions/deliveries';
import { CANCELATION_REASONS, type CancelationReason, FIRST_FILL_REASONS, REFILL_REASONS } from '~shared/constants';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { getAutoRefillNamesForShipment } from '~shared/features/auto-refill/selectors/getAutoRefillNamesForShipment';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { isInProgressMedSync } from '~shared/helpers/isInProgressMedSync';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useInvalidatePrescriptions } from '~shared/queries';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type DeliveryWithPrescription } from '~shared/types';
import { homescreenMethods } from '../../homescreen/queries/queries';
import { useInvalidateMedDetails } from '../../med-details/queries/useMedDetails';
import { medications as medList } from '../../med-list/queries/useMedList';
import { CANCEL_DELIVERIES_FORM_ERROR_MESSAGES, type CancelDeliveriesFormFields } from '../constants';
import { AutoRefillOrderCancel } from './AutoRefillOrderCancel';

const getCancelationReasons = (deliveries: DeliveryWithPrescription[]) => {
  let reasons = CANCELATION_REASONS;

  if (deliveries.some((del) => !del.refill)) {
    reasons = { ...FIRST_FILL_REASONS, ...reasons };
  }

  if (deliveries.some((del) => del.refill)) {
    reasons = { ...REFILL_REASONS, ...reasons };
  }

  return Object.entries(reasons).map(([value, label]) => ({
    label,
    value,
  }));
};

export const CancelOrderForm = () => {
  const dispatch = useDispatchShared();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { isMDScreenOrBigger } = useScreenSize();
  const { getParam, goBack, replace } = useNavigation<'RouteCancelOrdersForm'>();
  const shipmentID = getParam('shipmentID');
  const { invalidatePrescriptionsQuery } = useInvalidatePrescriptions();
  const {
    invalidatePrescriptionsQuery: invalidateMedDetailsPrescriptionsQuery,
    invalidateMedicationQuery: invalidateMedDetailsMedicationQuery,
  } = useInvalidateMedDetails();
  const deliveries = useSelectorShared((state) => getDeliveriesForShipmentID(state, { shipmentID }));
  const cancelationReasons = getCancelationReasons(deliveries);
  const userID = useSelectorShared(getCurrentUserID);
  const autoRefillRxNames = useSelectorShared((state) => getAutoRefillNamesForShipment(state, { shipmentID }));
  const autoRefillCount = autoRefillRxNames.length;
  const medSyncCount = deliveries.filter(isInProgressMedSync).length;
  const isLoading = useSelectorShared((state) => state.ui.loading.cancelDeliveriesLoading);

  const methods = useForm<CancelDeliveriesFormFields>();
  const { control, reset } = methods;

  const cancelationReasonController = useController({
    control,
    name: 'cancelation_reason',
    rules: {
      required: CANCEL_DELIVERIES_FORM_ERROR_MESSAGES.cancelation_reason,
    },
    defaultValue: '',
  });
  const cancelationInfoController = useController({
    control,
    name: 'cancelation_info',
    rules: {
      required:
        cancelationReasonController.field.value === 'other'
          ? CANCEL_DELIVERIES_FORM_ERROR_MESSAGES.cancelation_info
          : false,
    },
    defaultValue: '',
  });

  const valid =
    !!cancelationReasonController.field.value &&
    (cancelationReasonController.field.value !== 'other' || !!cancelationInfoController.field.value);

  const handleBackPress = () => {
    goBack();
    reset();
  };

  const onSubmit = useCallback(async () => {
    try {
      const res = await dispatch(
        // eslint-disable-next-line import/no-deprecated
        cancelDeliveries(
          deliveries,
          (cancelationReasonController.field.value ?? 'Other, please specify') as CancelationReason,
          cancelationInfoController.field.value ?? '',
          true,
        ),
      );
      if (Platform.OS === 'web') {
        // eslint-disable-next-line @alto/no-direct-tab-navigation
        replace('RouteTabOrders');
      } else {
        replace('RouteAppTabNavigator', { screen: 'RouteTabOrders' });
      }
      if (res) {
        // invalidate all med list & details queries, ignoring query parameters and do not refetch the queries until needed
        queryClient.invalidateQueries({ queryKey: medList.list._def });
        invalidateMedDetailsPrescriptionsQuery();
        invalidateMedDetailsMedicationQuery();
        queryClient.invalidateQueries({ queryKey: homescreenMethods.fetchHomescreen({ userID }).queryKey });
        invalidatePrescriptionsQuery();
        dispatch(clearDeliveriesError());
        trackEvent({
          event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
          params: { name: ASSISTANT_PRESSED_EVENTS.CANCEL_DELIVERY },
          additionalFields: { shipmentId: shipmentID },
        });
        addToast(<Toast variant="success">Order cancelled</Toast>);
      } else {
        addToast(<Toast variant="error">There was an error cancelling this order</Toast>);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [
    addToast,
    cancelationInfoController.field.value,
    cancelationReasonController.field.value,
    deliveries,
    dispatch,
    invalidateMedDetailsMedicationQuery,
    invalidateMedDetailsPrescriptionsQuery,
    invalidatePrescriptionsQuery,
    queryClient,
    replace,
    shipmentID,
    trackEvent,
    userID,
  ]);

  const showHeader = Platform.OS === 'web' && isMDScreenOrBigger;

  return (
    <SecondaryPage
      HeaderContent={
        showHeader ? (
          <XxxlPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <Column centerHorizontally>
              <H1>Cancel order</H1>
            </Column>
          </XxxlPadding>
        ) : null
      }
      dismissIcon="close"
      onDismiss={handleBackPress}
      NavBarCenterContent={showHeader ? undefined : 'Cancel order'}
      buttons={[
        <Button
          testID="cancel-order-button"
          key="cancel-order-button"
          label="Cancel order"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={onSubmit}
          disabled={!valid}
          loading={isLoading}
        />,
      ]}
    >
      <Card title="Why are you canceling?">
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <InputSelect
            error={cancelationReasonController.fieldState.error?.message}
            label="Select reason"
            onValueChange={cancelationReasonController.field.onChange}
            options={cancelationReasons}
            defaultValue={cancelationReasonController.field.value}
            placeholder="Select an option"
            required
          />
          {cancelationReasonController.field.value === 'other' &&
            cancelationReasonController.field.value !== undefined && (
              <>
                <LgSpacing />
                <InputText
                  value={cancelationInfoController.field.value}
                  error={cancelationInfoController.fieldState.error?.message}
                  label="Please tell us why"
                  placeholder="Please give a reason behind cancelation"
                  onChangeText={cancelationInfoController.field.onChange}
                  returnKeyType="next"
                  keyboardType="default"
                  required
                />
              </>
            )}
          {(autoRefillCount > 0 || medSyncCount > 0) && (
            <>
              <LgSpacing />
              <AutoRefillOrderCancel />
            </>
          )}
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};
