// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, InputIcon, Row } from '@alto/pocky';
import React, { type ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { type Video } from '~shared/features/instructional-videos/types';
import { ProfileInstructionalVideo } from './ProfileInstructionalVideo';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { FullFlexRow, PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import { Tab, TabList, TabPanel, Tabs } from '~web/components/Tab';

const VideoSearch = styled(InputIcon)<{ isSMScreenOrBigger: boolean }>`
  width: 35%;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 50%;`}
`;

const VideoTabList = styled(TabList)`
  border-bottom: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
`;

type VideosProps = {
  readonly videos: Video[];
};

const Videos = ({ videos }: VideosProps) => (
  <Column>
    {videos.map((video: Video) => (
      <ProfileInstructionalVideo
        key={video.videoID}
        video={video}
      />
    ))}
  </Column>
);

const filterVideos = (searchTerm: string, videos: Video[]) =>
  videos.filter(
    (video: Video) =>
      video.medName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.title.replace('\u00AE', '').toLowerCase().includes(searchTerm.toLowerCase()),
  );

type PageContentProps = {
  readonly forYouVideos: Video[];
  readonly allVideos: Video[];
  readonly searchTerm: string;
};

const PageContent = ({ forYouVideos, allVideos, searchTerm }: PageContentProps) => {
  const includeTabs = forYouVideos?.length;
  const filteredForYouVideos = filterVideos(searchTerm, forYouVideos);
  const filteredAllVideos = filterVideos(searchTerm, allVideos);
  return includeTabs ? (
    <Tabs defaultIndex={0}>
      <VideoTabList>
        <Tab>FOR YOU</Tab>
        <Tab>ALL</Tab>
      </VideoTabList>
      <TabPanel>
        <Videos videos={filteredForYouVideos} />
      </TabPanel>
      <TabPanel>
        <Videos videos={filteredAllVideos} />
      </TabPanel>
    </Tabs>
  ) : (
    <Videos videos={filteredAllVideos} />
  );
};

export type Props = {
  readonly initialSearchTerm: string;
  readonly forYouVideos: Video[];
  readonly allVideos: Video[];
};

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Fertility Instructional Videos',
  },
];

const ProfileInstructionalVideos = ({ initialSearchTerm = '', forYouVideos, allVideos }: Props) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const { isSMScreenOrBigger } = useScreenSize();

  const onSearchTermUpdated = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <PageBackground>
      <Column spacing={SPACING.STATIC.LG.rem}>
        <FullFlexRow>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <Row
            evenlySpaceContent
            verticallyAlignContent
          >
            <PageHeader>Videos</PageHeader>
            <VideoSearch
              icon={
                <AltoIcon
                  name="search"
                  type="disabled"
                />
              }
              input={{
                onChange: onSearchTermUpdated,
                value: searchTerm,
              }}
              placeholder="Search"
              isSMScreenOrBigger={isSMScreenOrBigger}
            />
          </Row>
        </FullFlexRow>
        <Column spacing={SPACING.STATIC.LG.rem}>
          <PageContent
            forYouVideos={forYouVideos}
            allVideos={allVideos}
            searchTerm={searchTerm}
          />
        </Column>
      </Column>
    </PageBackground>
  );
};

export default ProfileInstructionalVideos;
