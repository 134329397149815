// eslint-disable-next-line @alto/no-pocky-import
import { Button, DeprecatedModal, DeprecatedModalBody, DeprecatedModalFooter, Link, Text } from '@alto/pocky';
import React from 'react';

type Props = {
  readonly cancelText?: string;
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly confirmPrompt: string;
  readonly confirmText?: string;
  readonly fullBleed?: boolean;
  readonly onClick: () => void;
};
type State = {
  show: boolean;
};

export default class ConfirmLink extends React.Component<Props, State> {
  static defaultProps = {
    cancelText: 'Cancel',
    children: null,
    className: null,
    confirmPrompt: null,
    confirmText: 'Yes',
    fullBleed: false,
    onClick: null,
  };

  state = {
    show: false,
  };

  openModal() {
    this.setState({
      show: true,
    });
  }

  closeModal() {
    this.setState({
      show: false,
    });
  }

  render() {
    const { cancelText, children, className, confirmPrompt, confirmText, fullBleed, onClick } = this.props;
    const { show } = this.state;

    let cn = '';

    if (className) {
      cn = className;
    }

    if (fullBleed) {
      cn += ' no-margin';
    }

    return (
      <>
        <Link
          small
          className={cn}
          onClick={() => {
            this.openModal();
          }}
        >
          {children}
        </Link>
        {show ? (
          <DeprecatedModal
            showing={show}
            onClose={() => {
              this.closeModal();
            }}
            size="narrow"
            verticallyAlignContent={false}
            title="Confirm"
          >
            <DeprecatedModalBody>
              <Text>{confirmPrompt}</Text>
            </DeprecatedModalBody>
            <DeprecatedModalFooter>
              <Button
                kind="tertiary"
                onClick={() => {
                  this.closeModal();
                }}
              >
                {cancelText}
              </Button>
              <Button
                kind="primary"
                danger
                onClick={() => {
                  onClick();
                  this.closeModal();
                }}
              >
                {confirmText}
              </Button>
            </DeprecatedModalFooter>
          </DeprecatedModal>
        ) : null}
      </>
    );
  }
}
