// @owners { team: patients-team }
import {
  AltoSpinningLoader,
  Body,
  Card,
  Column,
  Description,
  LgPadding,
  LoadingEmptyState,
  MdSpacing,
  Row,
  SecondaryPage,
  XlPadding,
} from '@alto/design-system';
import { useQueryInsuranceConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { FAQs } from './FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { previousUrl as getOnboardingPreviousUrl } from '~web/features/onboarding/helpers/urlHandler';

type Props = {
  readonly onContinue: () => void;
};

export const OnboardingInsuranceLoadingPage = ({ onContinue }: Props) => {
  const dispatch = useDispatchShared();
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const onboardingPreviousUrl = useSelectorShared((state) => getOnboardingPreviousUrl(state, currentRoute));
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryInsuranceConfigs();

  const onBack = () => {
    if (onboardingPreviousUrl) {
      dispatch(push(onboardingPreviousUrl));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      onContinue();
    }, 3000);
  }, [onContinue]);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      headerBackgroundColor={headerBackgroundColor}
      withoutWebFramingElementHeights
      dismissIcon="chevronleft"
      onDismiss={onBack}
      NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
      NavBarRightPressable={<FAQs />}
      HeaderContent={
        <OnboardingHeader
          title="First, we’ll look for your insurance coverage"
          subtitle={<Body center>We search a national insurance database for any matches.</Body>}
        />
      }
    >
      <Column>
        <Card>
          <LgPadding>
            <Row centerVertically>
              <AltoSpinningLoader />
              <MdSpacing />
              <Description>Searching for insurance coverage...</Description>
            </Row>
          </LgPadding>
        </Card>
      </Column>
    </SecondaryPage>
  );
};
