// @owners { team: patients-team }
import { type PrescriptionPricing } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { useQuery } from '@tanstack/react-query';
import { staleTimes } from './constants';
import { queries } from './query-keys';

export type PrescriptionPricingByIdQueryData = {
  pricing: PrescriptionPricing;
};

export const usePrescriptionPricingById = ({ prescriptionId }: { prescriptionId: number }) => {
  const { data, isError, isPending, isSuccess } = useQuery({
    ...queries.pricing.prescriptionById({ prescriptionId }),
    staleTime: staleTimes.fiveMinutes,
  });
  return { isError, isPending, isSuccess, pricing: data?.item_pricing };
};
