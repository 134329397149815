import { withRehydration } from './withRehydration';
import {
  FETCH_ORDER_PRICING_LOADING,
  FETCH_ORDER_PRICING_SUCCEEDED,
  FETCH_PRESCRIPTION_PRICING_LOADING,
  FETCH_PRESCRIPTION_PRICING_SUCCEEDED,
  type PricingActions,
} from '~shared/actions/pricing';
import { type ItemPricing, type OrderPricing, type Pricing } from '~shared/features/pricing/types';

export function itemPricingMap(itemPricing: Pricing[]): ItemPricing {
  if (!itemPricing) {
    return {};
  }

  return itemPricing.reduce<ItemPricing>((map, pricing) => {
    map[pricing.prescription_id] = pricing;
    return map;
  }, {});
}

export type StatePricing = {
  prescriptionPricing: ItemPricing;
  lastFetchedAt: number | null;
  lastAttemptedAt: number | null;
  fetchAttemptCount: number;
  orderPricing: {
    cartPricing: OrderPricing;
    editPricing: OrderPricing;
    shipmentPricing: OrderPricing;
    lastFetchedAt: number | null;
    lastAttemptedAt: number | null;
    fetchAttemptCount: number;
  };
};

export const initialState: StatePricing = {
  prescriptionPricing: {},
  lastFetchedAt: null,
  lastAttemptedAt: null,
  fetchAttemptCount: 0,
  orderPricing: {
    cartPricing: {
      line_items: [],
      item_pricing: {},
      metadata: {
        estimated: false,
        expensive: false,
      },
    },
    editPricing: {
      line_items: [],
      item_pricing: {},
      metadata: {
        estimated: false,
        expensive: false,
      },
    },
    shipmentPricing: {
      line_items: [],
      item_pricing: {},
      metadata: {
        estimated: false,
        expensive: false,
      },
    },
    lastFetchedAt: null,
    lastAttemptedAt: null,
    fetchAttemptCount: 0,
  },
};

const pricing = (state: StatePricing = initialState, action: PricingActions): StatePricing => {
  switch (action.type) {
    case FETCH_PRESCRIPTION_PRICING_LOADING:
      return { ...state, lastAttemptedAt: Date.now(), fetchAttemptCount: state.fetchAttemptCount + 1 };

    case FETCH_PRESCRIPTION_PRICING_SUCCEEDED: {
      return {
        ...state,
        prescriptionPricing: itemPricingMap(action.payload?.item_pricing),
        lastFetchedAt: Date.now(),
        fetchAttemptCount: 0,
      };
    }

    case FETCH_ORDER_PRICING_LOADING:
      return {
        ...state,
        orderPricing: {
          ...state.orderPricing,
          lastAttemptedAt: Date.now(),
          fetchAttemptCount: state.orderPricing.fetchAttemptCount + 1,
        },
      };

    case FETCH_ORDER_PRICING_SUCCEEDED: {
      let pricingKey = 'cartPricing';
      const { isEditPricing, isShipmentPricing, ...rest } = action.payload;

      if (isShipmentPricing) {
        pricingKey = 'shipmentPricing';
      } else if (isEditPricing) {
        pricingKey = 'editPricing';
      }

      return {
        ...state,
        orderPricing: {
          ...state.orderPricing,
          [pricingKey]: { ...rest, item_pricing: itemPricingMap(action.payload?.item_pricing) },
          lastFetchedAt: Date.now(),
          fetchAttemptCount: 0,
        },
      };
    }
    default:
      return state;
  }
};

export default withRehydration(pricing, initialState);
