import { INIT, REHYDRATE } from '~shared/constants';
/**
 * Reducer state is persisted in local storage, so merge
 * current state with initial to handle the later addition of any
 * new state keys.
 * We want to make sure we aren't creating a new object every time
 * for performance reasons and for any usages of autoRehydrate
 */
export function withRehydration<ReducerType extends (state: Y, action: any) => Y, Y>(
  reducer: ReducerType,
  initialState: Y,
): ReducerType {
  // @ts-expect-error it doesn't like this typing, but it gets the correct result
  return (state: Y, action: any): Y => {
    if (action.type !== REHYDRATE && action.type !== INIT) {
      return reducer(state, action);
    }

    const persistedStateKeys = state ? Object.keys(state).sort() : [];
    persistedStateKeys.filter((key) => key === 'transferRequests');
    // @ts-expect-error still wonky typing
    const initialStateKeys = Object.keys(initialState).sort();

    // remove transferRequests from local storage since they are temporary
    if (initialStateKeys.includes('transferRequests')) {
      // @ts-expect-error we shouldn't be doing this here
      state.transferRequests = [];
    }

    if (
      persistedStateKeys.length === initialStateKeys.length &&
      persistedStateKeys.every((key, i) => key === initialStateKeys[i])
    ) {
      return reducer(state, action);
    }
    return reducer({ ...initialState, ...state }, action);
  };
}
