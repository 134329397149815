// @owners { team: patients-team }

export const SET_PROMO_VIEWED = 'SET_PROMO_VIEWED';

export type SetPromoViewed = {
  type: typeof SET_PROMO_VIEWED;
  payload: {
    promoID: number;
  };
};

export function setPromoViewed(payload: { promoID: number }) {
  return {
    type: SET_PROMO_VIEWED,
    payload,
  };
}

export const SET_PROMO_DISMISSED = 'SET_PROMO_DISMISSED';

export type SetPromoDismissed = {
  type: typeof SET_PROMO_DISMISSED;
  payload: {
    promoID: number;
    maxImpressionCount: number;
  };
};

export function setPromoDismissed(payload: { promoID: number; maxImpressionCount: number }) {
  return {
    type: SET_PROMO_DISMISSED,
    payload,
  };
}
