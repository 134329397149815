// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModal,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  FlexRow,
  LoadingButton,
  Text,
  spacing,
} from '@alto/pocky';
import React, { Component } from 'react';
import { AUTO_REFILL_OFF_ACTION_SHEET } from '~shared/features/auto-refill/constants';

export type Props = {
  readonly closeModal: () => void;
  readonly showing?: boolean;
  readonly loading: boolean;
  readonly onPressTurnOffAutoRefill: () => void;
};

export default class AutoRefillModal extends Component<Props> {
  render() {
    const { closeModal, showing, loading, onPressTurnOffAutoRefill } = this.props;

    return showing ? (
      <DeprecatedModal
        showing={showing}
        onClose={closeModal}
        title={AUTO_REFILL_OFF_ACTION_SHEET.title}
        scrollable
      >
        <DeprecatedModalBody>
          <FlexRow spacing={spacing.smSpacing}>
            <Text>{AUTO_REFILL_OFF_ACTION_SHEET.body}</Text>
          </FlexRow>
        </DeprecatedModalBody>
        <DeprecatedModalFooter>
          <Button
            kind="tertiary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={onPressTurnOffAutoRefill}
          >
            {AUTO_REFILL_OFF_ACTION_SHEET.button}
          </LoadingButton>
        </DeprecatedModalFooter>
      </DeprecatedModal>
    ) : null;
  }
}
