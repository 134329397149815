import { type InsurancesEndpointCreateParams } from '@alto/scriptdash/alto/billing/patients/v3/insurances_endpoint';
import { type Insurance } from '@alto/scriptdash/alto/billing/types/v1/insurance';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from './deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing, fetchPrescriptionPricing } from './pricing';
import { post } from '~shared/helpers/apiHelper';

export const CREATE_MANUAL_COUPON_LOADING = 'CREATE_MANUAL_COUPON_LOADING';
export const CREATE_MANUAL_COUPON_SUCCEEDED = 'CREATE_MANUAL_COUPON_SUCCEEDED';
export const CREATE_MANUAL_COUPON_FAILED = 'CREATE_MANUAL_COUPON_FAILED';
export const APPLY_MANUAL_COUPON_LOADING = 'APPLY_MANUAL_COUPON_LOADING';
export const APPLY_MANUAL_COUPON_SUCCEEDED = 'APPLY_MANUAL_COUPON_SUCCEEDED';
export const APPLY_MANUAL_COUPON_FAILED = 'APPLY_MANUAL_COUPON_FAILED';
export const RAISE_SUPPORT_REQUEST_LOADING = 'RAISE_SUPPORT_REQUEST_LOADING';
export const RAISE_SUPPORT_REQUEST_SUCCEEDED = 'RAISE_SUPPORT_REQUEST_SUCCEEDED';
export const RAISE_SUPPORT_REQUEST_FAILED = 'RAISE_SUPPORT_REQUEST_FAILED';

export function createManualCouponLoading() {
  return {
    type: CREATE_MANUAL_COUPON_LOADING,
  };
}

export function createManualCouponSucceeded(insurance: Insurance) {
  return {
    type: CREATE_MANUAL_COUPON_SUCCEEDED,
    payload: insurance,
  };
}

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export function createManualCouponFailed(error, couponID) {
  return {
    type: CREATE_MANUAL_COUPON_FAILED,
    id: couponID,
    payload: {
      error,
      couponID,
    },
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function createManualCoupon(params: InsurancesEndpointCreateParams): Promise<number | null> {
  // @ts-expect-error TS(2739): Type '(dispatch: any) => Promise<any>' is missing the following properties from type 'Promise<number... (Delete me to see the full error)
  return (dispatch) => {
    dispatch(createManualCouponLoading());
    return post('/billing/v1/insurances', {
      params,
    }).then((json) => {
      if (json.error) {
        dispatch(createManualCouponFailed(json.error, params.coupon_id));
        return null;
      }

      const insurance = json.data;
      dispatch(createManualCouponSucceeded(insurance));
      return insurance.id;
    });
  };
}

export function applyManualCouponLoading() {
  return {
    type: APPLY_MANUAL_COUPON_LOADING,
  };
}

export function applyManualCouponSucceeded() {
  return {
    type: APPLY_MANUAL_COUPON_SUCCEEDED,
  };
}

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export function applyManualCouponFailed(error, prescriptionID, insuranceID) {
  return {
    type: APPLY_MANUAL_COUPON_FAILED,
    id: prescriptionID,
    payload: {
      error,
      prescriptionID,
      insuranceID,
    },
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function applyManualCoupon(deliveryID: number, insuranceID: number, prescriptionID: number) {
  // @ts-expect-error TS(2739): Type '(dispatch: any) => Promise<boolean>' is missing the following properties from type 'Promise<bo... (Delete me to see the full error)
  return (dispatch) => {
    dispatch(applyManualCouponLoading());
    const params = {
      delivery_id: deliveryID,
      insurance_id: insuranceID,
    };
    return post('/billing/v1/insurances/apply_coupon', params).then((json) => {
      if (json.error) {
        dispatch(applyManualCouponFailed(json.error, prescriptionID, insuranceID));
        return false;
      }

      dispatch(applyManualCouponSucceeded());
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchOrderPricing());
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchPrescriptionPricing());
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchDeliveries());
      return true;
    });
  };
}

export function raiseSupportRequestLoading() {
  return {
    type: RAISE_SUPPORT_REQUEST_LOADING,
  };
}

export function raiseSupportRequestSucceeded() {
  return {
    type: RAISE_SUPPORT_REQUEST_SUCCEEDED,
  };
}

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export function raiseSupportRequestFailed(error) {
  return {
    type: RAISE_SUPPORT_REQUEST_FAILED,
    payload: {
      error,
    },
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function raiseSupportRequest(deliveryID: number, insuranceID: number | null) {
  // @ts-expect-error TS(2739): Type '(dispatch: any) => Promise<boolean>' is missing the following properties from type 'Promise<bo... (Delete me to see the full error)
  return (dispatch) => {
    dispatch(raiseSupportRequestLoading());
    const params = {
      delivery_id: deliveryID,
      insurance_id: insuranceID,
    };
    return post('/billing/v1/insurances/notify_ops', params).then(async (json) => {
      if (json.error) {
        dispatch(raiseSupportRequestFailed(json.error));
        return false;
      }

      dispatch(raiseSupportRequestSucceeded());

      // Refresh the state that may have been modified by /notify_ops
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchDeliveries());
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchPrescriptionPricing());
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchOrderPricing());
      return true;
    });
  };
}
