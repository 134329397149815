import { EVENTS } from './constants';
import { createEvent } from './helper';
import { type AdditionalFields, type Event } from './types';
import { sendEvents } from '~shared/lib/analytics/src/sendEvents';
import { type ReduxDispatch } from '~shared/types';

export const EVENTS_GENERATED = 'EVENTS_GENERATED';

type EventsGenerated = {
  type: typeof EVENTS_GENERATED;
  payload: Event[];
};
export const eventsGenerated = (events: Event[]): EventsGenerated => {
  return {
    type: EVENTS_GENERATED,
    payload: events,
  };
};

export const EVENTS_FLUSHED_SUCCEEDED = 'EVENTS_FLUSHED_SUCCEEDED';

function eventsFlushedSucceeded(events: Event[]) {
  return {
    type: EVENTS_FLUSHED_SUCCEEDED,
    payload: {
      events,
    },
  };
}

export const EVENTS_FLUSHED_FAILED = 'EVENTS_FLUSHED_FAILED';

function eventsFlushedFailed() {
  return {
    type: EVENTS_FLUSHED_FAILED,
  };
}

export const EVENTS_FLUSH_STARTED = 'EVENTS_FLUSH_STARTED';

function eventsFlushStarted() {
  return {
    type: EVENTS_FLUSH_STARTED,
  };
}

export function flushAnalyticsEvents(events: Event[]) {
  // eslint-disable-next-line @typescript-eslint/require-await
  return async (dispatch: ReduxDispatch) => {
    dispatch(eventsFlushStarted());

    // eslint-disable-next-line promise/catch-or-return
    sendEvents(events).then((json) => {
      // eslint-disable-next-line promise/always-return
      if (json.error) {
        dispatch(eventsFlushedFailed());
      } else {
        dispatch(eventsFlushedSucceeded(events));
      }
    });
  };
}

export const sendAnalyticsEvent = (event: Event) => (dispatch: ReduxDispatch) => {
  dispatch(eventsGenerated([event]));
};

export const BUCKET = {
  TREATMENT: 'treatment',
  CONTROL: 'control',
};

export type ExperimentExposureParams = {
  experimentName: string;
  bucket: string;
  origin?: string | null | undefined;
  additionalFields?: AdditionalFields | null | undefined;
};
export const markExperimentExposure =
  ({ experimentName, bucket, origin, additionalFields }: ExperimentExposureParams) =>
  (dispatch: ReduxDispatch) => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(
          EVENTS.EXPERIMENT_EXPOSURE,
          {
            experiment: experimentName,
            bucket,
            origin,
          },
          additionalFields,
        ),
      ),
    );
  };
