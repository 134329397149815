// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES } from '@alto/design-library-tokens';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';

const CircleBorder = styled.View<{
  relative?: boolean;
  leftPosition?: number;
  topPosition: number;
  rightPosition: number;
}>`
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  top: ${({ topPosition }) => topPosition}px;
  ${({ leftPosition, rightPosition }) =>
    leftPosition !== undefined ? `left: ${leftPosition}px;` : `right: ${rightPosition}px;`}
  z-index: 100;
`;

const Circle = styled.View`
  border-radius: ${BORDERS.RADIUS.MD.px};
  height: ${SIZES.ICON.SM.px};
  width: ${SIZES.ICON.SM.px};
  background-color: ${COLORS.BACKGROUND_COLORS.DANGER};
`;

type Props = {
  readonly accessibilityLabel?: string;
  readonly withAnimation?: boolean;
  readonly hidden?: boolean;
  readonly relative?: boolean;
  readonly topPosition?: 8 | 4 | 2 | 0 | -2 | -8;
  readonly rightPosition?: 0 | -2 | -8;
  readonly leftPosition?: number;
};

export const Dot = ({
  accessibilityLabel,
  withAnimation,
  hidden,
  relative,
  topPosition = -2,
  rightPosition = -2,
  leftPosition,
}: Props) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const scale = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (hidden) {
      Animated.timing(opacity, { toValue: 0, duration: 600, useNativeDriver: true }).start();
      Animated.timing(scale, { toValue: 0, duration: 600, useNativeDriver: true }).start();
      return;
    }

    if (!withAnimation) {
      Animated.timing(opacity, { toValue: 1, duration: 0, useNativeDriver: true }).start();
      Animated.timing(scale, { toValue: 0.75, duration: 0, useNativeDriver: true }).start();
      return;
    }

    Animated.delay(1000).start(() => {
      Animated.timing(opacity, { toValue: 1, duration: 600, useNativeDriver: true }).start();
      Animated.timing(scale, { toValue: 1, duration: 200, useNativeDriver: true }).start(() => {
        Animated.timing(scale, { toValue: 0.75, duration: 400, useNativeDriver: true }).start();
      });
    });
  }, [opacity, withAnimation, scale, hidden]);

  return (
    <CircleBorder
      accessibilityLabel={accessibilityLabel}
      relative={relative}
      topPosition={topPosition}
      leftPosition={leftPosition}
      rightPosition={rightPosition}
    >
      <Animated.View style={{ opacity, transform: [{ scale }] }}>
        <Circle />
      </Animated.View>
    </CircleBorder>
  );
};
