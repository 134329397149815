export const SELECT_USER = 'SELECT_USER';
export type ActionSelectUser = {
  type: typeof SELECT_USER;
  payload: {
    userID: number;
  };
};
export function selectUser(userID: number): ActionSelectUser {
  return {
    type: SELECT_USER,
    payload: {
      userID,
    },
  };
}
