// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Body, Button, Column, InputDate, InputText, LgSpacing, MdSpacing, XlPadding } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { PriceOverrideReasonMap } from '@alto/scriptdash/alto/pricing/types/v3/price_override_reason';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { Keyboard, TouchableWithoutFeedback } from 'react-native';
// eslint-disable-next-line import/no-deprecated
import { type BillingOverridePayload, autobillPrescription } from '~shared/actions/prescriptions';
import { getEarliestAvailableDateForPrescription__DEPRECATED } from '~shared/features/next-available-date/selectors/getNextAvailableDateForPrescription';
import {
  type VacationSupplyFormValues,
  initialFormValues,
  validateReturnDate,
  validateStartDate,
} from '~shared/features/vacation-supply/helpers';
import { formatDateStringForAPI } from '~shared/helpers/date';
// eslint-disable-next-line import/no-deprecated
import { fetchLatestVacationSupply } from '~shared/queries/useFetchLatestVacationSupply';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useAddToCart } from '../../cart';

type VacationOverrideFormProps = {
  readonly buttonLabel: string;
  readonly description?: string;
  readonly initialValues?: VacationSupplyFormValues;
  readonly prescriptionID: number;
  readonly onClose: () => void;
};

export const VacationOverrideForm = ({
  buttonLabel,
  description,
  initialValues = initialFormValues,
  prescriptionID,
  onClose,
}: VacationOverrideFormProps) => {
  const { navigate } = useNavigation();

  const dispatch = useDispatchShared();
  const { handleAddToCart } = useAddToCart();

  const earliestAvailableDate = useSelectorShared((state) =>
    getEarliestAvailableDateForPrescription__DEPRECATED(state, prescriptionID),
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<VacationSupplyFormValues>();

  const travelDestinationController = useController({
    control,
    defaultValue: initialValues.travelDestination,
    name: 'travelDestination',
    rules: { required: 'Travel destination is required' },
  });

  const startDateController = useController({
    control,
    defaultValue: initialValues.startDate,
    name: 'startDate',
    rules: {
      required: 'Start date is required',
      validate: (value: string) => validateStartDate(value, earliestAvailableDate),
    },
  });

  const returnDateController = useController({
    control,
    defaultValue: initialValues.returnDate,
    name: 'returnDate',
    rules: {
      required: 'Return date is required',
      validate: (value: string) => validateReturnDate(value, earliestAvailableDate),
    },
  });

  const handleSubmitVacationOverride = () => {
    handleSubmit(async (values: VacationSupplyFormValues) => {
      const vacationOverrideParams: BillingOverridePayload = {
        reason: PriceOverrideReasonMap.VACATION_SUPPLY,
        data: {
          travel_destination: values.travelDestination,
          travel_start_date: formatDateStringForAPI(values.startDate) || '',
          travel_end_date: formatDateStringForAPI(values.returnDate) || '',
        },
      };

      // eslint-disable-next-line import/no-deprecated
      const result = await dispatch(autobillPrescription(prescriptionID, vacationOverrideParams));

      if (result.autobillSuccess) {
        const addToCartResult = await handleAddToCart({
          resource_id: prescriptionID,
          resource_type: 'Prescription',
          resetOrderDate: true,
        });
        if (addToCartResult.success) {
          onClose();
        }
      } else {
        // eslint-disable-next-line import/no-deprecated
        const latestVacationSupplyResponse = await fetchLatestVacationSupply({
          prescription_id: prescriptionID,
          status: 'failed',
        });

        if (latestVacationSupplyResponse?.data) {
          navigate('RouteVacationRequestFailure', {
            prescriptionID,
            vacationSupply: latestVacationSupplyResponse.data,
          });
          onClose();
        }
      }
    })();
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <Column>
        <MdSpacing />
        <InputText
          accessibilityLabel="Travel destination"
          error={errors.travelDestination?.message}
          key="travelDestination"
          label="Travel destination"
          leftIconProps={{ name: 'mapmarker-small' }}
          maxLength={150}
          onChangeText={travelDestinationController.field.onChange}
          placeholder="City, Country"
          required
          returnKeyType="default"
          value={travelDestinationController.field.value}
        />
        <LgSpacing />
        <InputDate
          accessibilityLabel="Start date"
          error={errors.startDate?.message}
          key="startDate"
          label="Start date"
          onChangeText={startDateController.field.onChange}
          required
          value={startDateController.field.value}
        />
        <LgSpacing />
        <InputDate
          accessibilityLabel="Return date"
          error={errors.returnDate?.message}
          key="returnDate"
          onChangeText={returnDateController.field.onChange}
          label="Return date"
          required
          value={returnDateController.field.value}
        />
        {description ? (
          <XlPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <Body>{description}</Body>
          </XlPadding>
        ) : (
          <LgSpacing />
        )}
        <Button
          label={buttonLabel}
          loading={isSubmitting}
          onPress={handleSubmitVacationOverride}
        />
      </Column>
    </TouchableWithoutFeedback>
  );
};
