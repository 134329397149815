import { type COLORS } from '@alto/design-library-tokens';
import * as React from 'react';
import { type ReactElement } from 'react';
import { Animated, KeyboardAvoidingView, type ScrollView, type StyleProp, type ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { getHeaderHeight } from '../../../utils';
import { EdgelessScreenHomeButton } from '../../separators';
import { FixedFooter, type FixedFooterProps } from './FixedFooter';

type Behavior = 'padding' | 'position' | 'height';

export type KeyboardAvoidingScrollViewProps = {
  readonly children: React.ReactNode;
  readonly keyboardVerticalOffset?: number;
  readonly disableKeyboardVerticalOffset?: boolean;
  readonly onContentSizeChange?: () => void;
  readonly onScroll?: () => void;
  /**
   * Enables or disables scrolling on the ScrollView so that we can provide
   * our own FlatList or ScrollView for infinite scroll for pagination or auto-scroll to
   * thing purposes. Please only use this if you are implementing the above things.
   */
  readonly scrollEnabled?: boolean;
  readonly scrollViewRef?: React.Ref<ScrollView>;
  readonly backgroundColor?: string;
  /**
   * This sets the color of the bottom safe area. By default, this is transparent and will be the same as the background color,
   * but for instances where we have a fixedFooter, we may want to set this to COLOR.BACKGROUND_COLORS.WHITE.
   */
  readonly bottomSafeAreaColor?: (typeof COLORS.BACKGROUND_COLORS)[keyof typeof COLORS.BACKGROUND_COLORS];
  readonly style?: StyleProp<ViewStyle>;
  readonly contentContainerStyle?: StyleProp<ViewStyle>;
  readonly bounces?: boolean;
  readonly fixedFooterChildren?: ReactElement | null;
  readonly footerPlacementContext?: FixedFooterProps['footerPlacementContext'];
  readonly behavior?: Behavior;
  readonly keyboardAvoidingEnabled?: boolean;
};

const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView)<{ backgroundColor?: string }>`
  flex-grow: 1;
  flex-shrink: 1;
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
`;

export const KeyboardAvoidingScrollView = ({
  children,
  keyboardVerticalOffset,
  disableKeyboardVerticalOffset,
  onContentSizeChange,
  onScroll,
  scrollEnabled = true,
  scrollViewRef,
  backgroundColor,
  bottomSafeAreaColor,
  style,
  contentContainerStyle,
  bounces = true,
  fixedFooterChildren,
  footerPlacementContext,
  behavior = 'padding',
  /**
   * keyboardAvoidingEnabled should be set to true when on screens with inputs
   */
  keyboardAvoidingEnabled = true,
}: KeyboardAvoidingScrollViewProps) => {
  const { top } = useSafeAreaInsets();
  /*
      https://reactnative.dev/docs/keyboardavoidingview#keyboardverticaloffset
      According to react native:
      This is the distance between the top of the user screen and the react native view, may be non-zero in some use cases.

      This is why we are adding the header height to the keyboardVerticalOffset.
      Strongly suggest using this default since it may affect different devices if we hardcode that value.
      Update 23/08/22:
      In design-system/storybook top is always 0 in case that top is 0 we default to getHeaderHeight
  */
  keyboardVerticalOffset = keyboardVerticalOffset || top || getHeaderHeight();
  if (disableKeyboardVerticalOffset) {
    keyboardVerticalOffset = 0;
  }

  return (
    <StyledKeyboardAvoidingView
      style={style}
      /*
          According to documentation it was not recommended to set behavior for Android devices,
          but with latest versions it is now recommended.
          Tested in 2 android simulators and works fine. (with null it was simply not working)
          https://stackoverflow.com/questions/47661480/height-vs-position-vs-padding-in-keyboardavoidingview-behavior
      */
      behavior={behavior}
      keyboardVerticalOffset={keyboardVerticalOffset}
      backgroundColor={backgroundColor}
      enabled={keyboardAvoidingEnabled}
    >
      <Animated.ScrollView
        keyboardShouldPersistTaps="handled"
        onContentSizeChange={onContentSizeChange}
        onScroll={onScroll}
        ref={scrollViewRef}
        scrollEventThrottle={onScroll ? 1 : 0}
        bounces={bounces}
        // We need to use the style prop: discussion: https://github.com/scriptdash/design-system/pull/440#discussion_r924826865

        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={contentContainerStyle || { flexGrow: 1 }}
        scrollEnabled={scrollEnabled}
      >
        {children}
        {!fixedFooterChildren && <EdgelessScreenHomeButton color={bottomSafeAreaColor} />}
      </Animated.ScrollView>
      {fixedFooterChildren ? (
        <FixedFooter
          fixedFooterChildren={fixedFooterChildren}
          bottomSafeAreaColor={bottomSafeAreaColor}
          footerPlacementContext={footerPlacementContext}
        />
      ) : null}
    </StyledKeyboardAvoidingView>
  );
};
