// @owners { team: patients-team }
import { Description, InlineAlert, MdSpacing } from '@alto/design-system';
import React from 'react';

type Props = {
  readonly message: string;
};

export const DeliveryWaivedFeeWarning = ({ message }: Props) => {
  return (
    <>
      <InlineAlert type="warning">
        <Description>{message}</Description>
      </InlineAlert>
      <MdSpacing />
    </>
  );
};
