import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { type DateData, getInitialSelectedDate } from '../../utils';
import { Button } from '../buttons';
import { Calendar } from '../calendar';
import { type InputDatePickerProps } from '../inputs';
import { ActionSheetContext } from './ActionSheetProvider';
import { ActionSheetV2 } from './ActionSheetV2';

export type DatePickerActionSheetProps = {
  readonly closeOnSelect?: boolean;
  readonly selectedDate?: DateData;
  readonly onClose?: (value: DateData) => void;
  readonly testID?: string;
} & Pick<
  InputDatePickerProps,
  | 'modalTitle'
  | 'children'
  | 'disabled'
  | 'disabledDates'
  | 'minDate'
  | 'maxDate'
  | 'initialSelectedDate'
  | 'onDayPress'
  | 'onDaySelect'
>;

export const DatePickerActionSheet = ({
  closeOnSelect = true,
  modalTitle,
  disabledDates,
  children,
  onDayPress,
  onDaySelect,
  initialSelectedDate,
  selectedDate,
  minDate,
  maxDate,
  onClose,
  testID,
}: DatePickerActionSheetProps) => {
  const { closeActionSheet } = React.useContext(ActionSheetContext);
  const [preSelectedDate, setPreSelectedDate] = useState<DateData>(
    getInitialSelectedDate(initialSelectedDate, selectedDate),
  );
  const preSelectedDateRef = useRef(preSelectedDate);
  const formattedPreSelectedDate = format(preSelectedDate.timestamp, 'MMMM do');
  const buttonLabel = `Select ${formattedPreSelectedDate}`;

  const handleDaySelect = useCallback(() => {
    onDaySelect?.(preSelectedDate);
    // adding this prop allows us to navigate to another action sheet after a day selection is made
    // example: after selecting a date, navigate to select delivery window action sheet to select delivery window
    if (closeOnSelect) {
      closeActionSheet();
    }
  }, [onDaySelect, preSelectedDate, closeOnSelect, closeActionSheet]);

  const handleDayPress = useCallback(
    (dateData: DateData, disabled: boolean) => {
      onDayPress?.(dateData);
      if (!disabled) {
        setPreSelectedDate(dateData);
        preSelectedDateRef.current = dateData;
      }
    },
    [setPreSelectedDate, onDayPress],
  );

  const handleClose = useCallback(() => {
    onClose?.(preSelectedDateRef.current);
  }, [onClose]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <ActionSheetV2
      buttons={[
        <Button
          key="select-date-button"
          testID="select-date-button"
          label={buttonLabel}
          onPress={handleDaySelect}
        />,
      ]}
      title={modalTitle}
      handleClose={closeActionSheet}
      analyticsName="date picker"
    >
      {children}
      <Calendar
        initialSelectedDate={format(preSelectedDate.timestamp, 'yyyy-MM-dd')}
        disabledDates={disabledDates}
        onDayPress={handleDayPress}
        minDate={minDate}
        maxDate={maxDate}
        testID={testID}
      />
    </ActionSheetV2>
  );
};
