// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Card, LgPadding, ListDescription, ListItem, Tag } from '@alto/design-system';
import { type SupportCase } from '@alto/scriptdash/alto/customer_support/types/v1/support_case';
import React from 'react';
import { getSupportCasesWithUnreadMessages } from '~shared/features/messages/selectors/getMessages';
import { formatRelativeDate } from '~shared/helpers/date';
import { useSelectorShared } from '~shared/store';
import { Dot } from '../../../navigation';
import { useGetSupportCaseTopic } from '../../queries/queries';

type SupportCaseCardProps = {
  readonly supportCase: SupportCase;
  readonly pressable?: boolean;
};

export const SupportCaseCardItem = ({ supportCase, pressable }: SupportCaseCardProps) => {
  const supportCasesWithUnreadMessages = useSelectorShared(getSupportCasesWithUnreadMessages);
  const supportCaseID = supportCase.id;
  const lockedAt = supportCase.locked_at;
  const isFromAlto = !!supportCase.most_recent_message?.admin_user_id;
  const isUnread = !supportCase.most_recent_message?.read_at && isFromAlto;
  const updatedAt = supportCase.most_recent_message?.updated_at;
  const { topic } = useGetSupportCaseTopic(supportCase);
  const description = `Updated ${formatRelativeDate(lockedAt || updatedAt || '', {
    makeLowerCase: false,
    includeRelative: true,
    includeDayOfWeek: false,
  })}`;

  return (
    <Card>
      <LgPadding>
        <ListItem
          backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
          title={topic}
          fullBleed
          tags={[
            <Tag
              key={`support-case-${supportCaseID}-status`}
              label={lockedAt ? 'Closed' : 'Open'}
              type={lockedAt ? 'neutral' : 'success'}
            />,
          ]}
          RightContent={
            pressable ? (
              <>
                {isUnread || supportCasesWithUnreadMessages.has(supportCaseID) ? (
                  <Dot
                    rightPosition={0}
                    topPosition={0}
                    accessibilityLabel="Press to view unread messages"
                    withAnimation
                  />
                ) : null}
                <AltoIcon
                  name="chevronright-small"
                  accessibilityLabel={`Press to view ${isUnread ? 'unread ' : ''}messages in conversation`}
                />
              </>
            ) : undefined
          }
          descriptions={<ListDescription>{description}</ListDescription>}
        />
      </LgPadding>
    </Card>
  );
};
