// @owners { team: patients-team }
import { ActionSheetContext, FilterTag } from '@alto/design-system';
import React, { useContext } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { getIncludedFamilyMembersContent } from '~shared/selectors/ui/medList';
import { useSelectorShared } from '~shared/store';
import { FamilyMemberFilterActionSheet } from './FamilyMemberFilterActionSheet';

export const FamilyMemberFilterTag = () => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const openActionSheet = () => {
    trackEvent({
      event: EVENTS.MED_LIST__FILTER_FAMILY_MEMBERS_VIEWED,
      params: {
        origin: ORIGIN_NAMES.MED_LIST,
      },
    });
    setActiveActionSheet(<FamilyMemberFilterActionSheet />);
  };
  const familyMembersTagContent = useSelectorShared(getIncludedFamilyMembersContent);

  if (!familyMembersTagContent) return null;

  return <FilterTag onPress={openActionSheet}>{familyMembersTagContent}</FilterTag>;
};
