// @owners { team: patients-team }
import { SIZES, SPACING } from '@alto/design-library-tokens';
import { useDeprecatedMediaQuery, useDeprecatedWindowResize } from '~web/hooks';

export const useSidePadding = () => {
  const isLargeScreen = useDeprecatedMediaQuery('LG');
  const windowSize = useDeprecatedWindowResize();
  return isLargeScreen
    ? `${(windowSize.width - parseFloat(SIZES.PAGE.WEB_NAVBAR_WIDTH.LG)) / 2}px`
    : SPACING.STATIC.LG.px;
};
