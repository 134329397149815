import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { verifyEmail, verifyPhone } from '~shared/actions/verifications';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import CommsVerificationStatus from './CommsVerificationStatus';

type StateProps = {
  readonly loading: boolean;
};

type DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  readonly verifyPhone: (arg0: string) => void;
  // eslint-disable-next-line import/no-deprecated
  readonly verifyEmail: (arg0: string) => void;
};

type OwnProps = {
  readonly params: {
    commsChannel: string;
    token: string;
  };
};

type Props = OwnProps & StateProps & DispatchProps;

const CommsVerificationContainer = ({ verifyPhone, verifyEmail, loading, params }: Props) => {
  const [isVerified, setIsVerified] = useState<boolean | null | undefined>(null);
  const { commsChannel, token } = params;

  const onVerify = useCallback(() => {
    if (commsChannel === 'phone') {
      // @ts-expect-error TS(7006): Parameter 'response' implicitly has an 'any' type.
      // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression, promise/always-return
      verifyPhone(token).then((response) => {
        setIsVerified(response);
      });
    } else if (commsChannel === 'email') {
      // @ts-expect-error TS(7006): Parameter 'response' implicitly has an 'any' type.
      // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression, promise/always-return
      verifyEmail(token).then((response) => {
        setIsVerified(response);
      });
    }
  }, [token, verifyPhone, verifyEmail, commsChannel]);

  useEffect(() => {
    onVerify();
  }, [onVerify]);

  return (
    <CommsVerificationStatus
      isVerified={isVerified}
      loading={loading}
      commsChannel={commsChannel}
      token={token}
      authorized
    />
  );
};

const mapDispatchToProps = {
  // eslint-disable-next-line import/no-deprecated
  verifyPhone,
  // eslint-disable-next-line import/no-deprecated
  verifyEmail,
};

const mapStateToProps = (state: ReduxStateShared) => {
  const loading = getLoading(state).verifyCommsLoading;
  return {
    loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommsVerificationContainer);
