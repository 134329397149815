// @owners { team: patients-team }
import { type FertilityCycleMedicationType } from '@alto/scriptdash/alto/fertility_cycles/types/v1/fertility_cycle_medication_type';
import { CheckoutFlowTypeMap } from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import { FETCH_ENTER_CHECKOUT_SUCCEEDED } from '~shared/actions/cart';
import { AUTOBILL_PRESCRIPTION_SUCCEEDED } from '~shared/actions/prescriptions';
import {
  SET_HAS_CHANGED_DELIVERY_METHOD,
  UPDATE_CYCLE_ATTRIBUTES,
  UPDATE_MEDICATIONS_SUPPLY_ON_HAND,
} from '~shared/actions/ui/progynyWizard';
import { immutableBulkUpsert } from '~shared/helpers/immutableUpsert';
import { type FertilityCycle } from '~shared/types';

export const PROGYNY_WIZARD_TYPES = {
  FIRST_FILL: 'first_fill',
  REFILL: 'refill',
};

type Action = {
  type:
    | typeof AUTOBILL_PRESCRIPTION_SUCCEEDED
    | typeof FETCH_ENTER_CHECKOUT_SUCCEEDED
    | typeof SET_HAS_CHANGED_DELIVERY_METHOD
    | typeof UPDATE_CYCLE_ATTRIBUTES
    | typeof UPDATE_MEDICATIONS_SUPPLY_ON_HAND;
  payload: any;
  id: number | null | undefined;
};

export type EligiblePrescription = {
  prescriptionID: number;
  medicationType: FertilityCycleMedicationType;
};

export type StateUiProgynyWizard = {
  firstFill: boolean | null | undefined;
  eligiblePrescriptions: EligiblePrescription[] | null | undefined;
  fertilityCycle: FertilityCycle | null | undefined;
  updateAttributes: Record<string, any>;
  medicationManagement: Record<string, any>;
  hasChangedDeliveryMethod: boolean;
};

export const initialState: StateUiProgynyWizard = {
  firstFill: null,
  eligiblePrescriptions: null,
  fertilityCycle: null,
  updateAttributes: {},
  medicationManagement: {},
  hasChangedDeliveryMethod: false, // used to determine if the delivery method changed after an address change on the checkout screen, post-wizard
};

const progynyWizard = (state: StateUiProgynyWizard = initialState, action: Action): StateUiProgynyWizard => {
  const { type, payload, id } = action;

  switch (type) {
    case FETCH_ENTER_CHECKOUT_SUCCEEDED: {
      const { checkout_flow_types, checkout_flow_data } = payload;

      if (checkout_flow_types.includes(CheckoutFlowTypeMap.PROGYNY_WIZARD) && checkout_flow_data) {
        const { wizard_type, fertility_cycle, eligible_prescriptions } =
          checkout_flow_data[CheckoutFlowTypeMap.PROGYNY_WIZARD];
        // @ts-expect-error TS(7006): Parameter 'rxMed' implicitly has an 'any' type.
        const eligiblePrescriptions = eligible_prescriptions?.map((rxMed) => {
          return {
            prescriptionID: rxMed.prescription_id,
            medicationType: rxMed.medication_type,
          };
        });

        return {
          firstFill: wizard_type === PROGYNY_WIZARD_TYPES.FIRST_FILL,
          eligiblePrescriptions,
          fertilityCycle: fertility_cycle,
          updateAttributes: {
            medications: [],
          },
          medicationManagement: {},
          hasChangedDeliveryMethod: false,
        };
      }

      return state;
    }

    case UPDATE_CYCLE_ATTRIBUTES: {
      return { ...state, updateAttributes: { ...state.updateAttributes, ...payload.attributes } };
    }

    case UPDATE_MEDICATIONS_SUPPLY_ON_HAND: {
      const { medications, ...otherAttributes } = state?.updateAttributes || {};
      const upsertedMedications = immutableBulkUpsert({
        state: medications,
        payload: payload.medicationsSupplyOnHand,
        idKey: 'medication_type',
      });

      return { ...state, updateAttributes: { ...otherAttributes, medications: upsertedMedications } };
    }

    case AUTOBILL_PRESCRIPTION_SUCCEEDED: {
      const { medication_management_data } = payload || {};

      if (!medication_management_data || !id) {
        return state;
      }

      return { ...state, medicationManagement: { ...state.medicationManagement, [id]: medication_management_data } };
    }

    case SET_HAS_CHANGED_DELIVERY_METHOD: {
      return { ...state, hasChangedDeliveryMethod: payload.hasChangedDeliveryMethod };
    }

    default:
      return state;
  }
};

export default progynyWizard;
