import {
  type ApiResponseRow,
  type DataSource,
  type DataSourceResponseRow,
} from '@alto/design-system/src/components/inputs/src/InputAutocomplete/types';
import GooglePlacesService from './googlePlacesService';
import { type GooglePlacesAddressComponent } from './types';

export type GooglePlacesValue = {
  city?: string;
  name?: string;
  neighborhood?: string;
  phone?: string;
  state?: string;
  street_address?: string;
  zip?: string;
};

/**
 * Transforms the google place details api response to a data source response row
 * @param details
 * @returns parsed address information as DataSourceResponseRow<GooglePlacesValue>
 */
const transformToAddressDataSourceRow = (
  details: google.maps.places.PlaceResult,
): DataSourceResponseRow<GooglePlacesValue> => {
  const addressParts: Record<string, GooglePlacesAddressComponent> = (details.address_components ?? []).reduce(
    (parts, component) => {
      if (!component.types[0]) return parts;

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
      parts[component.types[0]] = component;
      return parts;
    },
    {},
  );

  const streetAddress = `${addressParts.street_number?.short_name || ''} ${
    addressParts.route?.short_name || ''
  }`.trim();
  const city = addressParts.locality?.long_name || addressParts.sublocality_level_1?.long_name || '';
  const state = addressParts.administrative_area_level_1?.short_name;
  const zip = addressParts.postal_code?.short_name || '';
  const zoneInfo = `${city}, ${state} ${zip}`.replace(/^\s*(,|\s)*|\s*(,|\s)*$/g, '');

  return {
    title: details.name ?? '',
    subtitle: zoneInfo,
    label: `${streetAddress}, ${zoneInfo}`,
    key: details.place_id ?? '',
    value: {
      name: details.name,
      phone: details.formatted_phone_number,
      street_address: streetAddress,
      city: addressParts.locality?.long_name || addressParts.sublocality_level_1?.long_name,
      neighborhood: addressParts.neighborhood?.long_name,
      state: addressParts.administrative_area_level_1?.short_name,
      zip: addressParts.postal_code?.short_name,
    },
  };
};

/**
 * Transforms the google place autocomplete api response to a data source response row
 * @param prediction
 * @returns parsed address information as DataSourceResponseRow<GooglePlacesValue>
 */
const transformToAddressDataSourceRowV2 = (
  prediction: google.maps.places.AutocompletePrediction,
): DataSourceResponseRow<GooglePlacesValue> => {
  const streetAddress = prediction.structured_formatting.main_text;
  const zoneInfo = prediction.structured_formatting.secondary_text;
  const city = zoneInfo.split(',')[0].trim();
  const state = zoneInfo.split(',')[1].trim();

  return {
    title: prediction.structured_formatting.main_text,
    subtitle: zoneInfo,
    label: `${streetAddress}, ${zoneInfo}`,
    key: prediction.place_id ?? '',
    value: {
      name: prediction.structured_formatting.main_text,
      phone: '',
      street_address: streetAddress,
      city,
      neighborhood: '',
      state,
      zip: '',
    },
  };
};

const transformToEstablishmentsDataSourceRow = (
  details: google.maps.places.PlaceResult,
): DataSourceResponseRow<GooglePlacesValue> => {
  const addressParts = (details.address_components ?? []).reduce<Record<string, GooglePlacesAddressComponent>>(
    (parts, component) => {
      if (!component.types[0]) return parts;

      parts[component.types[0]] = component;
      return parts;
    },
    {},
  );

  return {
    title: details.name ?? '',
    subtitle: details.vicinity,
    label: `${details.name}, ${details.vicinity}`,
    key: details.place_id ?? '',
    value: {
      name: details.name,
      phone: details.formatted_phone_number,
      street_address: `${addressParts.street_number?.short_name} ${addressParts.route?.short_name}`,
      city: addressParts.locality?.long_name,
      neighborhood: addressParts.neighborhood?.long_name,
      state: addressParts.administrative_area_level_1?.short_name,
      zip: addressParts.postal_code?.short_name,
    },
  };
};

export const formatApiResponseRow = (address?: GooglePlacesValue, id?: number): ApiResponseRow<GooglePlacesValue>[] => {
  if (address === undefined || id === undefined) {
    return [];
  }

  return [
    {
      title: address.name || '',
      subtitle: `${address.city}, ${address.state} ${address.zip}`,
      label: `${address.street_address}, ${address.city}, ${address.state} ${address.zip}`,
      key: `${id}` || '',
      isFromAutocomplete: false,
      value: {
        name: address.name,
        phone: address.phone,
        street_address: address.street_address,
        city: address.city,
        neighborhood: address.neighborhood,
        state: address.state,
        zip: address.zip,
      },
    },
  ];
};

const googlePlacesService = new GooglePlacesService();

/**
 * Calls the Google Places API to help users find their pharmacy - this searches based off of establishment name
 * @param text
 * @returns transformed address data
 */
export const googlePlacesEstablishmentDataSource: DataSource<GooglePlacesValue> = async (text) => {
  const placesDetails = await googlePlacesService.getDetailsFromAutocomplete(text, ['establishment']);
  return placesDetails.map(transformToEstablishmentsDataSourceRow);
};

/**
 * Calls the Google Places API to help users find their addresses
 * @param text
 * @returns transformed address data
 */
export const googlePlacesAddressDataSource: DataSource<GooglePlacesValue> = async (text) => {
  const placesDetails = await googlePlacesService.getDetailsFromAutocomplete(text, ['address']);
  return placesDetails.map(transformToAddressDataSourceRow);
};

/**
 * Calls the Google Places API to help users find their addresses using autocomplete only
 * @param text
 * @returns transformed address data
 */
export const googlePlacesAddressDataSourceV2: DataSource<GooglePlacesValue> = async (text) => {
  const response = await googlePlacesService.autocomplete(text, ['address']);
  const predictions = response?.predictions ?? [];
  return predictions.map(transformToAddressDataSourceRowV2);
};
