// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Column, H1, Illustration, MdSpacing, SecondaryPage, XxxlPadding } from '@alto/design-system';
import React from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type Shipment } from '~shared/types';
import { OrderCard } from '~web/features/essentials/pages/OrderSuccess/OrderCard';

type Props = {
  shipment: Shipment;
};

export const OrderSuccessWithoutProducts = ({ shipment }: Props) => {
  return (
    <SecondaryPage
      HeaderContent={
        <XxxlPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <Column centerHorizontally>
            <Illustration
              source={'https://assets.prod.alto.com/otc_categories/box.svg'}
              accessibilityLabel={IMG_ALT_TEXTS.orderBox}
              size="xl"
            />
            <MdSpacing />
            <H1>Order scheduled!</H1>
          </Column>
        </XxxlPadding>
      }
    >
      <OrderCard
        shipment={shipment}
        showTitleIllustration={false}
      />
    </SecondaryPage>
  );
};
