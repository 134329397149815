import { BASE_FONT_SIZE } from '../general';

export const NONE = {
  value: 0,
  rem: '0rem',
  px: '0px',
} as const;

export const XXS = {
  value: 4,
  px: '4px',
  rem: `${4 / BASE_FONT_SIZE}rem`,
} as const;

export const XS = {
  value: 8,
  px: '8px',
  rem: `${8 / BASE_FONT_SIZE}rem`,
} as const;

export const SM = {
  value: 12,
  px: '12px',
  rem: `${12 / BASE_FONT_SIZE}rem`,
} as const;

export const MD = {
  value: 16,
  px: '16px',
  rem: `${16 / BASE_FONT_SIZE}rem`,
} as const;

export const LG = {
  value: 24,
  px: '24px',
  rem: `${24 / BASE_FONT_SIZE}rem`,
} as const;

export const XL = {
  value: 32,
  px: '32px',
  rem: `${32 / BASE_FONT_SIZE}rem`,
} as const;

export const XXL = {
  value: 48,
  px: '48px',
  rem: `${48 / BASE_FONT_SIZE}rem`,
} as const;

export const XXXL = {
  value: 64,
  px: '64px',
  rem: `${64 / BASE_FONT_SIZE}rem`,
} as const;

export const XXXXL = {
  value: 80,
  px: '80px',
  rem: `${80 / BASE_FONT_SIZE}rem`,
} as const;

export const XXXXXL = {
  value: 160,
  px: '160px',
  rem: `${160 / BASE_FONT_SIZE}rem`,
} as const;

export const STATIC = {
  XXS,
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
  XXXL,
  XXXXL,
  XXXXXL,
  NONE,
} as const;
