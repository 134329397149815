// @owners { team: patients-team }
import { DevicesEndpoint } from '@alto/scriptdash/alto/patient_app/devices/v1/devices_endpoint';
import { useMutation } from '@tanstack/react-query';
import { Platform } from 'react-native';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getDeviceInfo } from '~shared/lib/devices/deviceInfo';

export const REGISTER_DEVICE_MUTATION_KEY = ['registerDevice'];

const devicesEndpoint = DevicesEndpoint(apiEndpointHandler);

const getAndTransformDeviceInfo = async () => {
  const deviceInfo = await getDeviceInfo();
  return {
    manufacturer: deviceInfo.manufacturer,
    brand: deviceInfo.brand,
    model: deviceInfo.model,
    device_id: deviceInfo.deviceId,
    system_name: deviceInfo.systemName,
    system_version: deviceInfo.systemVersion,
    bundle_id: deviceInfo.bundleId,
    build_number: deviceInfo.buildNumber,
    version: deviceInfo.version,
    readable_version: deviceInfo.readableVersion,
    user_agent: deviceInfo.userAgent,
    device_locale: deviceInfo.deviceLocale,
    device_country: deviceInfo.deviceCountry,
    timezone: deviceInfo.timezone,
    is_emulator: deviceInfo.isEmulator,
    is_tablet: deviceInfo.isTablet,
    unique_id: deviceInfo.uniqueId,
    font_scale: deviceInfo.fontScale,
  };
};

export const useRegisterDevice = () => {
  const { mutate } = useMutation({
    mutationFn: async (pushToken: string) => {
      const deviceInfo = await getAndTransformDeviceInfo();
      const payload = {
        device_info: deviceInfo,
        os: Platform.OS,
        push_token: pushToken,
        device_info_id: deviceInfo.unique_id,
      };
      await devicesEndpoint.registerDevice(payload);
    },
    mutationKey: REGISTER_DEVICE_MUTATION_KEY,
  });
  return { registerDevice: mutate };
};
