// @owners { team: patients-team }
import { Body, XsSpacing, XxsSpacing } from '@alto/design-system';
import React from 'react';

type TextContentProps = {
  readonly title: string;
  readonly body: string[];
};

export const FaqTextContent = ({ title, body }: TextContentProps) => {
  return (
    <>
      <Body fontFamily="bold">{title}</Body>
      <XxsSpacing />
      {body.map((content) => {
        return (
          <React.Fragment key={`body_${content}`}>
            <Body>{content}</Body>
            <XsSpacing />
          </React.Fragment>
        );
      })}
    </>
  );
};
