import { useMemo } from 'react';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';

export const useHasCompoundPrescriptionsForShipment = ({ shipmentID }: { shipmentID: number | undefined }) => {
  const prescriptions = useSelectorShared((state) => getPrescriptionsForShipment(state, { shipmentID }));

  const hasCompound: boolean = useMemo(
    () => prescriptions.some((rx: Prescription) => rx.is_compound === true),
    [prescriptions],
  );
  return hasCompound;
};
