import { SPACING, type SPACING_STATIC_SIZE } from '@alto/design-library-tokens';
import { InlineAlert, LgPadding } from '@alto/design-system';
import { type ColorVariant } from '@alto/design-system/src/components/alerts/src/types';
import React from 'react';

export const AddressInfoAlert = ({
  children,
  type = 'neutral',
  topPadding = SPACING.STATIC.NONE,
}: {
  readonly children: React.ReactNode;
  readonly type?: ColorVariant;
  readonly topPadding?: SPACING_STATIC_SIZE;
}) => (
  <LgPadding
    topPadding={topPadding}
    bottomPadding={SPACING.STATIC.MD}
  >
    <InlineAlert type={type}>{children}</InlineAlert>
  </LgPadding>
);
