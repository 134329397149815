import { type ActionSelectMedication, SELECT_MEDICATION } from '~shared/actions/ui/medications';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateUiMedications = {
  selectedMedicationKey: string | null | undefined;
  selectedMedicationName: string | null | undefined;
};

export const initialState: StateUiMedications = {
  selectedMedicationKey: null,
  selectedMedicationName: null,
};

type Action = ActionSelectMedication;

const medications = (state: StateUiMedications = initialState, action: Action): StateUiMedications => {
  const { type } = action;

  switch (type) {
    case SELECT_MEDICATION: {
      const { payload } = action;
      const { medicationKey, medicationName } = payload;
      return { ...state, selectedMedicationKey: medicationKey, selectedMedicationName: medicationName };
    }

    default:
      return state;
  }
};

export default withRehydration(medications, initialState);
