// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Button, ExternalLink, LandingPage } from '@alto/design-system';
import { useQueryLandingPageConfigs } from '@alto/features';
import React from 'react';
import { PRIVACY_POLICY_URL, PRIVACY_PRACTICES_URL, TERMS_OF_SERVICE_URL } from '~shared/constants';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';

type Props = {
  readonly handleGetStarted: () => void;
  readonly getStartedLoading: boolean;
  readonly tempPassword: string;
  readonly showTermsOfService?: boolean;
};

export const PartnershipLandingPage = ({
  handleGetStarted,
  getStartedLoading,
  tempPassword,
  showTermsOfService = false,
}: Props) => {
  const { isFetching, isSuccess, headerBackgroundColor, headerLogoUrl, welcomeHeader, welcomeBody, welcomeImageUrl } =
    useQueryLandingPageConfigs({ tempPassword });
  const illustrationSrc = welcomeImageUrl ? { uri: welcomeImageUrl } : undefined;

  if (isFetching || !isSuccess) {
    return null;
  }

  return (
    <LandingPage
      withoutWebFramingElementHeights
      footerPlacementContext="webScreenWithoutPolicyFooter"
      HeaderBodyCopy={<Body>{welcomeBody}</Body>}
      backgroundColor={headerBackgroundColor || COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      NavBarCenterContent={
        <OnboardingNavBarLogo
          illustrationSrc={headerLogoUrl}
          isPartnerLogo
        />
      }
      title={welcomeHeader || 'Welcome to Alto'}
      illustrationSrc={illustrationSrc}
      illustrationSize="xxl"
      buttons={[
        <Button
          label="Get started"
          key="get-started"
          loading={getStartedLoading}
          onPress={handleGetStarted}
        />,
      ]}
    >
      {showTermsOfService ? (
        <Body>
          By clicking Get Started you have read and accept the{' '}
          <ExternalLink href={TERMS_OF_SERVICE_URL}>Terms of Service</ExternalLink>,{' '}
          <ExternalLink href={PRIVACY_POLICY_URL}>Privacy Policy</ExternalLink>, and{' '}
          <ExternalLink href={PRIVACY_PRACTICES_URL}>Notice of Privacy Practices</ExternalLink>.
        </Body>
      ) : null}
    </LandingPage>
  );
};
