import { push } from 'react-router-redux';
import { connect } from '~shared/store';
import { type ReduxDispatchShared } from '~shared/types';
import UpdatedCartModal from './UpdatedCartModal';

type OwnProps = {
  onClose: () => void;
};

type StateProps = {
  location: string;
};

type DispatchProps = {
  navigateToMedList: () => void;
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared, ownProps: OwnProps): DispatchProps => ({
  navigateToMedList: () => {
    dispatch(
      push({
        pathname: '/prescriptions',
      }),
    );
    ownProps.onClose();
  },
});

const mapStateToProps = (): StateProps => {
  const location = window.location.pathname;
  return {
    location,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatedCartModal);
