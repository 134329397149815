// @owners { team: patients-team }
import { Body, LgSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { InfoBox, Modal, ModalContent } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { openModal } from '~shared/actions/modal';
import { useDispatchShared } from '~shared/store';

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

export type MedicationTransferModalProps = {
  readonly prescriptionID: number;
  readonly medicationName: string;
};

export const MedicationTransferModal = ({ prescriptionID, medicationName }: MedicationTransferModalProps) => {
  const dispatch = useDispatchShared();

  return (
    <StyledModal
      onClose={() => dispatch(openModal('MEDICATION_BACKORDERED_MODAL', { prescriptionID, medicationName }))}
      showing
      title="Use a different pharmacy"
    >
      <ModalContent>
        <Body>
          Contact another pharmacy to transfer {medicationName || 'your medication'}, or ask your provider to send a new
          prescription to them directly.
        </Body>
        <LgSpacing />
        <InfoBox warning>
          <Body>
            Note that backorders often impact multiple pharmacies. Make sure a different pharmacy has your medication
            available.
          </Body>
        </InfoBox>
      </ModalContent>
    </StyledModal>
  );
};
