// @owners { team: patients-team }
import { type AltoIconName, COLORS } from '@alto/design-library-tokens';
import { Body, Button, Description, InlineAlert, LandingPage, LgPadding, XsSpacing } from '@alto/design-system';
import React from 'react';
import img from '~shared/assets/images/delivery_patient_and_courier_circle.png';
import { DEFAULT_ERROR_ALERT_MESSAGE } from '~shared/constants';
import { useLoginViewed } from '~shared/lib/analytics/src/hooks/useLoginViewed';

type Props = {
  readonly login: () => void;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage?: string;
  readonly disableLogin?: boolean;
  readonly navigateToOnboarding: () => void;
  readonly iconRight?: AltoIconName | undefined;
  readonly disableOnboarding?: boolean;
};
export const WelcomePage = ({
  login,
  loading,
  error,
  errorMessage,
  disableLogin = false,
  navigateToOnboarding,
  iconRight,
  disableOnboarding = false,
}: Props) => {
  useLoginViewed({ inAuth0Flow: true });
  const errorText = errorMessage ?? DEFAULT_ERROR_ALERT_MESSAGE;

  return (
    <LandingPage
      title="Welcome to Alto!"
      HeaderBodyCopy={<Body>We’re your trusted pharmacy offering prescription delivery and extraordinary care.</Body>}
      backgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      illustrationSrc={img}
    >
      {error ? (
        <LgPadding>
          <InlineAlert type="error">
            <Description center>{errorText}</Description>
          </InlineAlert>
        </LgPadding>
      ) : null}
      <Button
        label="Log in"
        onPress={login}
        loading={loading}
        alignment="center"
        iconRightName={iconRight}
        disabled={disableLogin}
      />
      <XsSpacing />
      <Button
        type="tertiary"
        label="Create an account"
        onPress={navigateToOnboarding}
        alignment="center"
        testID="create-account"
        disabled={disableOnboarding || loading}
      />
    </LandingPage>
  );
};
