// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { ALL_ACTIVE_RXS } from '~shared/features/transfers/constants';
import { type TransferCreatePayload } from '~shared/features/transfers/types';
import { post } from '~shared/helpers/apiHelper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { Sentry } from '~shared/sentry';

// TODO: document the TransferCreatePayload props for the function here in this file
export const useCreateTransfer = () => {
  const { addToast } = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const { navigate } = useNavigation();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (createTransferParams: TransferCreatePayload) => {
      trackEvent({
        event: EVENTS.TRANSFER__TRANSFER_MEDS_CLICKED,
        params: {
          origin: createTransferParams.origin,
          is_transfer_all_meds_on_file: createTransferParams.medications === ALL_ACTIVE_RXS,
        },
      });
      return await post(
        '/transfers',
        {
          transfer: createTransferParams,
        },
        { version: 'v3' },
      );
    },
    onError: (error: Error) => {
      Sentry.captureException(error);
      addToast(
        <Toast variant="error">
          Unable to submit transfer request. Please try again or message support if the problem persists.
        </Toast>,
      );
    },
    onSuccess: (_, variables: TransferCreatePayload) => {
      trackEvent({
        event: EVENTS.TRANSFER_REQUEST_SUBMITTED,
        params: {
          origin: variables.origin,
        },
      });
      addToast(<Toast>Your transfer request has been submitted. We'll notify you when it is complete.</Toast>);
      if (variables.onSuccess) {
        variables.onSuccess();
      } else {
        navigate('RouteTransferSuccess');
      }
    },
  });
  return { createTransferMutate: mutateAsync, isCreateTransferPending: isPending };
};
