// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  ButtonSelector,
  InputText,
  LgPadding,
  LgSpacing,
  type OptionValue,
} from '@alto/design-system';
import { isAfter } from 'date-fns';
import React, { useCallback, useContext, useState } from 'react';
import { updateCSATSubmittedDate, updateCSATViewedDate } from '~shared/actions/csat';
import thumbsUpDown from '~shared/assets/images/thumb-up-down.png';
import { getCSATSubmittedDate } from '~shared/features/csat/selectors/getCSATSubmittedDate';
import { getCSATViewedDate } from '~shared/features/csat/selectors/getCSATViewedDate';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const CSAT_OPTIONS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

export const useCanShowCSAT = () => {
  const dispatch = useDispatchShared();
  const csatViewedDate = new Date(useSelectorShared(getCSATViewedDate));
  const csatSubmittedDate = new Date(useSelectorShared(getCSATSubmittedDate));
  const memoizedUpdateCSATViewedDate = useCallback(() => dispatch(updateCSATViewedDate()), [dispatch]);

  const viewedCoolDownDate = new Date(
    csatViewedDate.getFullYear(),
    csatViewedDate.getMonth() + 2,
    csatViewedDate.getDate(),
  );
  const submittedCoolDownDate = new Date(
    csatSubmittedDate.getFullYear(),
    csatSubmittedDate.getMonth() + 3,
    csatSubmittedDate.getDate(),
  );
  const csatEligibleDate = viewedCoolDownDate > submittedCoolDownDate ? viewedCoolDownDate : submittedCoolDownDate;
  const today = new Date();

  return {
    canShowCSAT: isAfter(today, csatEligibleDate),
    updateCSATViewedDate: memoizedUpdateCSATViewedDate,
  };
};

export const CSATActionSheet = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState('');

  const onSubmit = () => {
    trackEvent({
      event: EVENTS.ORDER_SUCCESS__CSAT_SUBMITTED,
      params: {
        rating,
        description,
      },
    });
    dispatch(updateCSATSubmittedDate());
    closeActionSheet();
  };

  const onDismiss = () => {
    closeActionSheet();
  };

  const onRatingChange = (rating: OptionValue) => {
    setRating(Number(rating));
  };

  const onDescriptionChange = (value: string) => {
    setDescription(value);
  };

  return (
    <ActionSheetV2
      title="How satisfied are you with this checkout experience?"
      analyticsName="Order Success CSAT survey"
      illustrationSrc={thumbsUpDown}
      buttons={[
        <Button
          key="submit-button"
          disabled={!rating}
          label="Submit"
          onPress={onSubmit}
        />,
        <Button
          key="dismiss-button"
          label="Dismiss"
          onPress={onDismiss}
          type="tertiary"
        />,
      ]}
    >
      <LgPadding
        topPadding={SPACING.STATIC.SM}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <ButtonSelector
          leftLabel={'Not at all\nsatisfied'}
          rightLabel={'Completely\nsatisfied'}
          options={CSAT_OPTIONS}
          onChange={onRatingChange}
        />
        <LgSpacing />
        <InputText
          label="Tell us why"
          placeholder="Begin typing..."
          optional
          onChangeText={onDescriptionChange}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
