// eslint-disable-next-line @alto/no-pocky-import
import { Button } from '@alto/pocky';
import * as React from 'react';
import styled from 'styled-components';
import { COMMS_CHANNEL, IMG_ALT_TEXTS } from '~shared/constants';
import CommsVerificationCard from './CommsVerificationCard';
import Image from '~web/components/Image';
import checkmark from '~web/images/verifiedCheckmark@2x.png';

export type Props = {
  readonly commsChannel: string;
  readonly navigateToNextRoute: () => void;
};

const StyledButton = styled(Button)`
  width: 180px;
`;

const CommsVerificationSuccess = ({ commsChannel, navigateToNextRoute }: Props) => {
  return (
    <CommsVerificationCard
      headerIcon={
        <Image
          sidebar
          src={checkmark}
          alt={IMG_ALT_TEXTS.greenCheckmark}
        />
      }
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
      title={`${COMMS_CHANNEL[commsChannel]} verified!`}
    >
      <StyledButton onClick={navigateToNextRoute}>Continue to Alto</StyledButton>
    </CommsVerificationCard>
  );
};

export default CommsVerificationSuccess;
