// @owners { team: patients-team }
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { PRESCRIPTION_STATES } from '~shared/constants';
import { prescriptionIsActive, sortByActiveState } from '~shared/features/my-meds/helper';
import { type Medication } from '~shared/features/my-meds/types';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { type Prescription, type ReduxStateShared } from '~shared/types';

export const medicationKey = (
  gcnSeqno: string | null | undefined,
  ndc: string | null | undefined,
  rxNumber: string | null | undefined,
  userID?: number | null,
) => {
  return `${gcnSeqno || ndc || rxNumber || ''}-${userID || ''}`;
};

// All prescriptions now should have a ndc, but there are some really old
// prescriptions that don't, so we're using rx_number as a backup.
export const medicationKeyForPrescription = (prescription: Prescription) => {
  const { gcn_seqno, ndc, rx_number, user_id } = prescription;

  return medicationKey(gcn_seqno, ndc, rx_number, user_id);
};

const shouldReplaceRepresentativeRx = (medication: Medication, prescription: Prescription) => {
  const isRepresentativeRxActive = prescriptionIsActive(medication.representativePrescription);
  const isThisRxActive = prescriptionIsActive(prescription);

  // first prefer an active rx
  if (isRepresentativeRxActive && !isThisRxActive) {
    return false;
  }

  if (isThisRxActive && !isRepresentativeRxActive) {
    return true;
  }

  // else prefer the more recently written rx
  const dateWritten = prescription.date_written;
  const representativeRxDateWritten = medication.representativePrescription.date_written;

  if (!dateWritten) {
    return false;
  }

  if (!representativeRxDateWritten) {
    return true;
  }

  return dateWritten > representativeRxDateWritten;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getMedicationsFromPrescriptions = createSelector([getPrescriptions], (prescriptions) => {
  const groupedPrescriptions: Record<string, Medication> = {};

  const sortedPrescriptions = sortBy(prescriptions, sortByActiveState);

  for (const prescription of sortedPrescriptions) {
    const {
      active_state,
      brands,
      dosage_form,
      gcn_seqno,
      has_monograph,
      is_refrigerated,
      medication_name,
      ndc,
      product_friendly_dosage_form,
      product_id,
      rx_number,
      sig,
      user_id,
    } = prescription;

    const key = medicationKeyForPrescription(prescription);
    const medication = groupedPrescriptions[key];

    if (medication) {
      medication.prescriptions.push(prescription);

      // Always prefer friendly dosage form if available
      if (product_friendly_dosage_form) {
        medication.dosageForm = product_friendly_dosage_form;
      }

      // Merge any brands that aren't already present on the medication
      if (brands) {
        medication.brands = [...new Set([...medication.brands, ...brands.split('/')])];
      }

      /**
       * The medication is refrigerated if any prescription is refrigerated.
       *
       * We (erroneously) have products with the same gcn_seqno but different refrigerated values.
       * If we happen to encounter more than one of those here, always err on the side of
       * refrigerated.
       */
      if (!medication.refrigerated && is_refrigerated) {
        medication.refrigerated = is_refrigerated;
      }

      if (shouldReplaceRepresentativeRx(medication, prescription)) {
        medication.representativePrescription = prescription;

        if (medication_name) {
          medication.medicationName = medication_name;
        }

        if (has_monograph) {
          medication.monographProductID = product_id;
        }

        if (sig) {
          medication.sig = sig;
        }
      }
    } else {
      groupedPrescriptions[key] = {
        active_state,
        brands: brands ? brands.split('/') : [],
        dosageForm: product_friendly_dosage_form || dosage_form,
        gcnSeqno: gcn_seqno,
        key,
        medicationName: medication_name || '',
        monographProductID: has_monograph ? product_id : null,
        ndc,
        prescriptions: [prescription],
        refrigerated: is_refrigerated || false,
        representativePrescription: prescription,
        rxNumber: rx_number,
        sig: sig || '',
        userID: user_id,
      };
    }
  }

  return Object.keys(groupedPrescriptions).map((key) => groupedPrescriptions[key]);
});

export const getActiveMedications = createSelector([getMedicationsFromPrescriptions], (medications) => {
  return medications.filter(
    (medication) =>
      medication.active_state === PRESCRIPTION_STATES.ACTIVE ||
      medication.active_state === PRESCRIPTION_STATES.ACTIVE_RENEWED ||
      medication.active_state === PRESCRIPTION_STATES.TOO_EARLY,
  );
});

export const getMedicationsFromPrescriptionsByKey = createSelector([getMedicationsFromPrescriptions], (medications) => {
  return medications.reduce<Record<string, Medication>>((acc, medication) => {
    if (!medication.key) return acc;
    acc[medication.key] = medication;
    return acc;
  }, {});
});

const getSelectedMedicationKey = (state: ReduxStateShared): string | null | undefined =>
  state.ui.medications.selectedMedicationKey;

export const getSelectedMedication = createSelector(
  getSelectedMedicationKey,
  getMedicationsFromPrescriptionsByKey,
  (selectedMedicationKey, medications) => {
    if (!selectedMedicationKey) return;
    return medications[selectedMedicationKey];
  },
);

export const getPrescription = (_: ReduxStateShared, prescription?: Prescription) => prescription;

export const getMedicationForPrescription = createSelector(
  [getMedicationsFromPrescriptionsByKey, getPrescription],
  (medications, prescription) => {
    if (!prescription) return null;
    const medicationKey = medicationKeyForPrescription(prescription);
    return medications[medicationKey];
  },
);
