// @owners { team: patients-team }
import { SIZES } from '@alto/design-library-tokens';
import { Border, Button, LgSpacing, MdSpacing } from '@alto/design-system';
import { useAddOnsContext, useQueryEssentials } from '@alto/features';
import React from 'react';
import { push } from 'react-router-redux';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { ErrorPage, LoadingPage } from '~web/features/essentials/components';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';
import { ProductCarousel } from '~web/features/essentials/pages/OrderSuccess/ProductCarousel';
import { Icon, Image, Text, View } from '~web/features/essentials/primitives';
import { useDeprecatedMediaQuery } from '~web/hooks';

export const DesktopProducts = () => {
  const { shipmentID, origin } = useAddOnsContext();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID }));
  const { isPending, error, categories } = useQueryEssentials({
    addressID: shipment?.address_id,
    date: shipment?.scheduled_date,
  });
  useTrackViewedEvent(EVENTS.ESSENTIALS__ORDER_SUCCESS_WITH_ESSENTIALS, shipmentID, origin);
  const dispatch = useDispatchShared();
  const isMediumScreen = useDeprecatedMediaQuery('MD');
  const numItems = isMediumScreen ? 4 : 2;

  if (isPending || !categories) return <LoadingPage />;
  if (error)
    return (
      <ErrorPage
        title="Unable to load Essentials"
        description={`${error}`}
      />
    );

  const handleNavToEssentials = () => {
    dispatch(push({ pathname: '/store', search: `?order=${shipmentID}&origin=${origin}` }));
  };

  return (
    <>
      <View
        justifyContent="space-between"
        alignItems="center"
      >
        <View flexDirection="column">
          <View alignItems="center">
            <Text
              title="Add "
              variant="h2"
            />
            <Image
              src="https://assets.prod.alto.com/otc_categories/essentials_logo.svg"
              alt="add essentials to your order"
            />
          </View>
          <Text
            title=" to your order"
            variant="h2"
          />
        </View>
        {shipmentID ? (
          <Button
            label="See all products"
            onPress={handleNavToEssentials}
          />
        ) : null}
      </View>
      <LgSpacing />
      <Border fullWidth />
      <LgSpacing />
      {shipmentID &&
        categories.map((category) => (
          <View
            flexDirection="column"
            key={category.name}
          >
            <View
              flexDirection="row"
              alignItems="center"
              onClick={handleNavToEssentials}
            >
              <Image
                src={category.image_url}
                width={SIZES.ILLUSTRATION.MD.px}
                height={SIZES.ILLUSTRATION.MD.px}
                alt={`icon for ${category.name}`}
              />
              <MdSpacing />
              <Text
                title={category.name}
                variant="h3"
              />
              <MdSpacing />
              <Icon
                name="chevronright"
                size="SM"
                interactive
                label="back icon"
              />
            </View>
            <ProductCarousel
              numOfItems={category.add_on_otcs.length}
              numOfColumns={numItems}
              products={category.add_on_otcs}
              categoryName={category.name}
            />
            <LgSpacing />
          </View>
        ))}
    </>
  );
};
