// eslint-disable-next-line @alto/no-pocky-import
import { H1 } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { PageBackground } from '~web/components/Page';

const Header = styled(H1)`
  text-align: center;
`;

export default class NoMatch extends React.Component {
  render() {
    return (
      <PageBackground>
        <div>
          <Header>The page you are looking for doesn’t exist.</Header>
        </div>
      </PageBackground>
    );
  }
}
