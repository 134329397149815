// @owners { team: patients-team }
import { Linking } from 'react-native';
import { getPlatformOS } from './getPlatformOS';

// file containing helper functions for parsing and rendering comms (support cases, notifications)

const isMobile = getPlatformOS() === 'android' || getPlatformOS() === 'ios';

// modified regexp from https://www.geeksforgeeks.org/check-if-an-url-is-valid-or-not-using-regular-expression/
export const urlRegExp = new RegExp(
  '((http|https)://|(www.))[a-zA-Z0-9@:%._\\+~#?&//=-]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=\\s]*)',
  'gi',
);

export const getIsInternalURL = (url: string): boolean => {
  // generic urls used to route within the app start with followings as an example:
  // 'https://app.alto.com', 'https://app.stg.alto.com', 'https://app.alto.local.alto.com', or 'https://app-sandbox-name.dev.alto.com'
  const genericURLRegExp = new RegExp('^(http|https)://app[a-z.-]{1,256}alto\\.com', 'i');

  // internal urls starts either with a generic app prefix or a deeplinking prefix
  return genericURLRegExp.test(url) || url.startsWith('https://m.alto.com') || url.startsWith('altopharmacy://');
};

const handlePressLinkWeb = (word: string) => {
  const urlLink = word.startsWith('http') ? word.trim() : 'https://' + word.trim();
  const isInternalURL = getIsInternalURL(urlLink);
  if (isInternalURL) {
    // open internal URLs in the same tab
    window.open(urlLink, '_self');
  } else {
    // use openURL for web external links, otherwise emit url to handle in-app routing
    Linking.openURL(urlLink);
  }
};

const handlePressLinkNative = (word: string) => {
  const urlLink = word.startsWith('http') ? word.trim() : 'https://' + word.trim();
  const isBranchLink = urlLink.startsWith('https://m.alto.com');
  const isInternalURL = getIsInternalURL(urlLink);
  // on native, open branch links in the mobile app
  if (isBranchLink && isMobile) {
    // call branch.openURL to handle the deeplink URL
    // only import branch in the native code, NOT web
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const branch = require('react-native-branch').default;
    branch.openURL(urlLink);
  } else if (isInternalURL) {
    Linking.emit('url', { url: urlLink });
  } else {
    // use openURL for web external links
    Linking.openURL(urlLink);
  }
};

export const handlePressLink = (word: string) => {
  if (getPlatformOS() === 'web') {
    handlePressLinkWeb(word);
  } else {
    handlePressLinkNative(word);
  }
};
