// @owners { team: patients-team }
import { Body, Button, LandingPage } from '@alto/design-system';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';
import altoMD from '~web/images/alto_md.png';

export const MedSyncSelfServiceRequestReceivedPage = () => {
  const dispatch = useDispatchShared();

  useEffect(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_BUNDLE_MEDICATIONS_REQUEST_RECEIVED_VIEWED)));
  }, [dispatch]);

  const navigateToHomescreen = () => {
    dispatch(push('/home'));
  };

  return (
    <LandingPage
      illustrationSrc={altoMD}
      title="Request received! We'll start working on your bundle."
      HeaderBodyCopy={<Body>We'll reach out in 2-3 business days to review the details of your bundle schedule.</Body>}
    >
      <Button
        label="Got it"
        onPress={navigateToHomescreen}
      />
    </LandingPage>
  );
};
