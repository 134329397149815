// @owners { team: patients-team }

import {
  AncillariesEndpoint,
  type AncillariesEndpointFetchByPrescriptionIdRequest,
  type AncillariesEndpointFetchByPrescriptionIdsRequest,
} from '@alto/scriptdash/alto/prescriptions/patient_app/v3/ancillaries_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { apiEndpointHandler } from '~shared/helpers/api';

const ancillariesEndpoint = AncillariesEndpoint(apiEndpointHandler);

export const ancillaries = createQueryKeys('ancillaries', {
  fetchByPrescriptionId: (params: AncillariesEndpointFetchByPrescriptionIdRequest) => ({
    queryKey: ['fetchByPrescriptionId', params],
    queryFn: () => ancillariesEndpoint.fetchByPrescriptionId(params),
  }),
  fetchByPrescriptionIds: (params: AncillariesEndpointFetchByPrescriptionIdsRequest) => ({
    queryKey: ['fetchByPrescriptionIds', params],
    queryFn: () => ancillariesEndpoint.fetchByPrescriptionIDs(params, { splitArrayParams: true }),
  }),
});

export const useFetchAncillariesByPrescriptionId = ({
  prescriptionId,
  hasAncillaryItems = true,
}: {
  prescriptionId?: number;
  hasAncillaryItems?: boolean;
}) => {
  const { data, isPending, isError, isSuccess, error } = useQuery({
    ...ancillaries.fetchByPrescriptionId({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      prescription_id: prescriptionId!,
    }),
    enabled: !!prescriptionId && hasAncillaryItems,
  });

  const ancillaries_data = useMemo(() => data?.data ?? [], [data]);

  return { ancillaries: ancillaries_data, isPending, isError, isSuccess, error };
};

export const useFetchAncillariesByPrescriptionIds = ({
  prescriptionIds = [],
  hasAncillaryItems = true,
}: {
  prescriptionIds?: number[];
  hasAncillaryItems?: boolean;
}) => {
  const { data, isPending, isError, isSuccess, error } = useQuery({
    ...ancillaries.fetchByPrescriptionIds({
      prescription_ids: prescriptionIds,
    }),
    enabled: prescriptionIds.length > 0 && hasAncillaryItems,
  });

  const ancillaries_data = useMemo(() => data?.data ?? [], [data]);

  return { ancillaries: ancillaries_data, isPending, isError, isSuccess, error };
};
