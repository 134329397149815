// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { ColoredFlexRow } from './Header';
import { ContainedImage, LogoCell, LogosGrid, LogosText, spacing } from '~web/features/onboarding/components/styles';
import oneMedical from '~web/images/oneMedical.png';
import stanford from '~web/images/stanford.png';
import ucsf from '~web/images/ucsf.png';
import urac from '~web/images/urac.png';
import vipps from '~web/images/vipps.png';

const LogosTextContainer = styled.div`
  max-width: 240px;
`;

export const TrustedPartnerLogos = () => {
  const { isSMScreenOrBigger, isLGScreenOrBigger } = useScreenSize();

  return (
    <ColoredFlexRow
      color={COLORS.PALETTE.GREYSCALE.WHITE}
      padding={spacing.smSpacing}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <LogosGrid isLGScreenOrBigger={isLGScreenOrBigger}>
        <LogoCell>
          <LogosTextContainer>
            <LogosText>
              Alto Pharmacy is accredited and trusted by many of the world’s best healthcare systems.
            </LogosText>
          </LogosTextContainer>
        </LogoCell>
        <LogoCell>
          <ContainedImage
            href="https://accreditnet2.urac.org//uracportal/Directory/CompanyView/8517"
            src={urac}
            alt="URAC Accreditation Seal"
          />
        </LogoCell>
        <LogoCell>
          <ContainedImage
            href="https://nabp.pharmacy/programs/vipps/vipps-accredited-pharmacies-list/"
            src={vipps}
            alt="VIPPS Accreditation Seal"
          />
        </LogoCell>
        <LogoCell>
          <ContainedImage
            src={oneMedical}
            alt="OneMedical logo"
          />
        </LogoCell>
        <LogoCell>
          <ContainedImage
            src={ucsf}
            alt="UCSF logo"
          />
        </LogoCell>
        <LogoCell>
          <ContainedImage
            src={stanford}
            alt="Stanford logo"
          />
        </LogoCell>
      </LogosGrid>
    </ColoredFlexRow>
  );
};
