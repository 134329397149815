import { COLORS } from '@alto/design-library-tokens';
import { Description, MdSpacing, Row } from '@alto/design-system';
import { type LineItemType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { PRICE_FREE, PRICE_PENDING } from '~shared/features/pricing/constants';
import {
  getFormattedPrice,
  isItemTypeExpeditedDeliveryFee,
  isTotal,
  shouldBeSemibold,
} from '~shared/helpers/pricingBreakdown';
import { DeliveryFeeToolTip } from '../../checkout/components/select-date-card/DeliveryFeeToolTip';
import { PricingInfoLabel } from './PricingInfoLabel';

type Props = {
  readonly label: string;
  readonly itemType?: LineItemType;
  readonly onPressChange?: () => void;
  readonly onPressMoreInfo?: () => void;
  readonly originalPrice?: number | null | undefined;
  readonly price: number | string | null | undefined;
  readonly savings?: boolean;
};

export const PricingInfoRow = ({
  onPressMoreInfo,
  label,
  onPressChange,
  originalPrice,
  price,
  savings,
  itemType,
}: Props) => {
  const formattedPrice = useMemo(() => getFormattedPrice(price, savings), [price, savings]);
  const formattedOriginalPrice = useMemo(() => getFormattedPrice(originalPrice || 0), [originalPrice]);
  const isTotalPrice = useMemo(() => isTotal(label), [label]);
  const semibold = useMemo(() => shouldBeSemibold(label), [label]);
  const fontFamily = semibold ? 'semibold' : undefined;

  const priceTextColor = useMemo(() => {
    if (price === PRICE_FREE || savings) {
      return COLORS.TEXT_COLORS.SUCCESS;
    }
    if (price === PRICE_PENDING || isTotalPrice) {
      return COLORS.TEXT_COLORS.PRIMARY;
    }
    return COLORS.TEXT_COLORS.GREY;
  }, [price, isTotalPrice, savings]);

  const styles = StyleSheet.create({
    priceLabelRow: {
      flex: 1,
    },
  });

  return (
    <Row spaceBetween>
      <Row style={styles.priceLabelRow}>
        <PricingInfoLabel
          label={label}
          semibold={semibold}
          priceTextColor={priceTextColor}
          onPressChange={onPressChange}
          onPressMoreInfo={onPressMoreInfo}
        />
        {isItemTypeExpeditedDeliveryFee(itemType) ? <DeliveryFeeToolTip origin="line item" /> : null}
      </Row>
      <MdSpacing />
      {originalPrice && price === PRICE_FREE ? (
        <Row>
          <Description
            textDecorationLine="line-through"
            fontFamily={fontFamily}
          >
            {formattedOriginalPrice}
          </Description>
          <Description
            fontFamily={fontFamily}
            color={priceTextColor}
          >{` ${formattedPrice}`}</Description>
        </Row>
      ) : (
        <Description
          fontFamily={fontFamily}
          color={priceTextColor}
        >
          {formattedPrice}
        </Description>
      )}
    </Row>
  );
};
