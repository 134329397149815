// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Column, H1, LoadingButton, Modal, ModalContent, Text, palette, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import {
  RESEND_VERIFICATION_TITLE,
  VERIFICATION_CTA_LOADING_TEXT,
  VERIFICATION_CTA_TEXT,
  VERIFICATION_SINGLE_OPTION_BODY,
  VERIFICATION_TITLE,
} from '~shared/features/onboarding/constants';
import { type Option } from '~shared/features/onboarding/selectors/getOnboardingVerification';
import { getErrorMessage } from '~shared/helpers/helper';
import { type APIError } from '~shared/types';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import { type Values } from '~web/features/onboarding/components/VerificationOptionsModal';

const Title = styled(H1)`
  color: ${palette.navy};
  text-align: center;
`;

const Buttons = styled(ButtonWrapper)`
  justify-content: center;
`;

const Content = styled(Column)`
  text-align: left;
`;

type Props = {
  readonly error: APIError | null | undefined;
  readonly loading: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (arg0: Values) => void;
  readonly option: Option;
  readonly resend?: boolean;
};

export default function VerificationSingleOptionModal({ error, loading, onClose, onSubmit, option, resend }: Props) {
  const { channel, contact } = option;
  const body = VERIFICATION_SINGLE_OPTION_BODY(channel, contact);
  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Column spacing={spacing.smSpacing}>
          <Title>{resend ? RESEND_VERIFICATION_TITLE : VERIFICATION_TITLE}</Title>
          <Content spacing={spacing.smSpacing}>
            <Text>{body}</Text>
            {!!error && <Text danger>{getErrorMessage(error)}</Text>}
            <Buttons>
              <LoadingButton
                loading={loading}
                loadingText={VERIFICATION_CTA_LOADING_TEXT}
                onClick={() => {
                  onSubmit({
                    channel,
                  });
                }}
              >
                {VERIFICATION_CTA_TEXT}
              </LoadingButton>
            </Buttons>
          </Content>
        </Column>
      </ModalContent>
    </Modal>
  );
}
