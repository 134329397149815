import { BORDERS, COLORS } from '@alto/design-library-tokens';
import { getHeaderHeight } from '@alto/design-system';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

type BackgroundColor = keyof typeof COLORS.BACKGROUND_COLORS;

const StyledTopSection = styled.View<{ topColor: BackgroundColor; colorHeight: number }>`
  height: ${({ colorHeight }) => colorHeight}px;
  background-color: ${({ topColor }) => COLORS.BACKGROUND_COLORS[topColor]};
  border-color: ${COLORS.BORDER_COLORS.LIGHT};
  border-bottom-width: ${BORDERS.SIZE}px;
`;

const StyledBottomSection = styled.View<{ bottomColor: BackgroundColor }>`
  width: 100%;
  height: 100%;
  background-color: ${({ bottomColor }) => COLORS.BACKGROUND_COLORS[bottomColor]};
`;

const StyledContainerSection = styled.View<{ colorHeight: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: ${({ colorHeight }) => colorHeight}px;
`;

const StyledContentSection = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

type Props = {
  readonly children: React.ReactNode;
  readonly topColor: BackgroundColor;
  readonly bottomColor?: BackgroundColor;
  readonly colorHeight: number;
};

export const SupportCaseSplitPage = ({ children, colorHeight, topColor, bottomColor = 'WHITE' }: Props) => {
  const { top } = useSafeAreaInsets();
  const navBarHeight = getHeaderHeight() + top;
  return (
    <>
      <StyledTopSection
        topColor={topColor}
        colorHeight={colorHeight + navBarHeight}
      />
      <StyledBottomSection bottomColor={bottomColor} />
      <StyledContainerSection colorHeight={navBarHeight}>
        <StyledContentSection>{children}</StyledContentSection>
      </StyledContainerSection>
    </>
  );
};
