// eslint-disable-next-line @alto/no-pocky-import
import {
  FlexRow,
  InfoBox,
  InputBlockText,
  InputRow,
  Loader,
  LoadingButton,
  Paragraph,
  createValidator,
  spacing,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React from 'react';
import { DATE_FORMATS, formatDateInput } from '~shared/helpers/date';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { Header } from '~web/components/HeaderImage';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import { OnboardingScreenButtonWrapper } from '~web/features/onboarding/components/redesign/OnboardingScreenButtonWrapper';

type Props = {
  readonly handleSubmit: (values: Record<string, unknown>) => void;
  readonly submitLoading: boolean;
  readonly identityVerificationNeededLoading: boolean;
  readonly identityVerificationNeededError: string | null | undefined;
};

const initialValues = {
  last_name: '',
  dob: '',
};

const IdentityVerificationPage = ({
  handleSubmit,
  submitLoading,
  identityVerificationNeededLoading,
  identityVerificationNeededError,
}: Props) => {
  /* Placeholders: consult with design for final mocks */

  if (identityVerificationNeededError) {
    return (
      <InfoBox
        danger
        title="Failed to Verify"
      >
        <Paragraph>{identityVerificationNeededError}</Paragraph>
      </InfoBox>
    );
  }

  if (identityVerificationNeededLoading) {
    return (
      <div>
        <Loader text="Verifying..." />
      </div>
    );
  }

  const validate = createValidator({
    last_name: validatePresentWithMessage('Last name'),
    dob: validatePresentWithMessage('Date of birth'),
  });

  return (
    <PageBackground white>
      <SingleColumnContentWrapper>
        <Header title="Please confirm your information" />

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          <Form noValidate>
            <FlexRow spacing={spacing.mdSpacing}>
              <InputRow>
                <Field name="last_name">
                  {({ field, meta }: FieldProps<string>) => (
                    <InputBlockText
                      required
                      id="last_name"
                      // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage will be overwritten.
                      name="last_name"
                      label="Last name"
                      aria-label="Enter your last name here"
                      placeholder="Enter last name"
                      {...mapFormikFieldToInputProps(field, meta)}
                    />
                  )}
                </Field>
              </InputRow>

              <InputRow>
                <Field name="dob">
                  {({ field, meta }: FieldProps<string>) => (
                    <InputBlockText
                      required
                      id="dob"
                      // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage will be overwritten.
                      name="dob"
                      label="Date of birth"
                      aria-label="Enter your date of birth here in numbers, for example as 02 28 1991"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder={DATE_FORMATS.DATE_INPUT_PLACEHOLDER}
                      {...mapFormikFieldToInputProps(field, meta)}
                      onChange={(date) => {
                        date.target.value = formatDateInput(date.target.value);
                        field.onChange(date);
                      }}
                    />
                  )}
                </Field>
              </InputRow>

              <OnboardingScreenButtonWrapper horizontallyAlignContent>
                <LoadingButton
                  type="submit"
                  loading={submitLoading}
                  loadingText="Verifying your identity..."
                  disabled={submitLoading}
                >
                  Verify
                </LoadingButton>
              </OnboardingScreenButtonWrapper>
            </FlexRow>
          </Form>
        </Formik>
      </SingleColumnContentWrapper>
    </PageBackground>
  );
};

export default IdentityVerificationPage;
