import * as React from 'react';
import { Platform } from 'react-native';

const eventIn = 'mouseenter';
const eventOut = 'mouseleave';

/**
 * Get the node from a `ref`.
 */
const getNode = (ref: React.MutableRefObject<any>) => {
  try {
    let node = ref && (ref.current || ref);

    if (node?.getNode?.()) node = node.getNode();
    if (node?._touchableNode) node = node._touchableNode;
    if (node?._node) node = node._node;

    return node;
  } catch (error) {
    console.error('Failed to find node', error, { ref });
    return null;
  }
};

/**
 * Detects if the component is hovered.
 *
 * ⚠️ Pseudo classes only work in the browser, so this only works on web.
 *
 * @example
 * const ref = React.useRef<View>(null);
 * const { isHovered } = useHover(ref);
 * const backgroundColor = isHovered ? 'red' : 'blue';
 */
export function useHover<T>(ref: React.MutableRefObject<T>) {
  const [isActive, setActive] = React.useState(false);
  const onStart = React.useCallback(() => {
    setActive(true);
  }, []);
  const onEnd = React.useCallback(() => {
    setActive(false);
  }, []);

  React.useEffect(() => {
    if (
      // Pseudo classes only work in the browser
      Platform.OS !== 'web'
    ) {
      return;
    }

    const node = getNode(ref);
    if (!node) {
      return;
    }

    node.addEventListener(eventIn, onStart);
    node.addEventListener(eventOut, onEnd);

    return () => {
      if (document) {
        document.removeEventListener(eventOut, onEnd, false);
      }
      node.removeEventListener(eventIn, onStart);
      node.removeEventListener(eventOut, onEnd);
    };
  }, [onEnd, onStart, ref]);

  return { isHovered: isActive };
}
