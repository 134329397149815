// @owners { team: patients-team }
import {
  type PrescriptionPricing,
  PricingScenarioMap,
} from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React from 'react';
import getSelectedPaymentTypeForPrescription from '~shared/features/checkout/selectors/getSelectedPaymentTypeForPrescription';
import { PRESCRIPTION_PRICING_LABELS, PRICE_PENDING } from '~shared/features/pricing/constants';
import { selectedPriceOption } from '~shared/features/pricing/helpers';
import { type Pricing } from '~shared/features/pricing/types';
import { useSelectorShared } from '~shared/store';
import { ItemPricing } from './ItemPricing';
import { ItemPricingDetailed } from './ItemPricingDetailed';

type Props = {
  readonly pricingItem: Pricing | PrescriptionPricing;
  readonly origin: string;
  readonly eventUuid: string;
};

const PAYMENT_BREAKDOWN_HELPER_TEXT = {
  UNAVAILABLE: 'We are still calculating, and will confirm the final price with you as soon as it’s available.',
  WITHOUT_INSURANCE: 'Paying without insurance',
};

export const ItemPaymentBreakdown = ({ pricingItem, origin, eventUuid }: Props) => {
  const selectedPaymentType = useSelectorShared((state) =>
    getSelectedPaymentTypeForPrescription(state, { prescriptionID: pricingItem.prescription_id }),
  );
  const eventParams = {
    prescriptionID: pricingItem.prescription_id,
    estimated: pricingItem.estimated,
    unavailable: pricingItem.scenario === PricingScenarioMap.UNAVAILABLE,
    origin,
    breakdownKey: eventUuid,
  };

  if (pricingItem.scenario === PricingScenarioMap.UNAVAILABLE) {
    return (
      <ItemPricing
        label={PRESCRIPTION_PRICING_LABELS.YOUR_COPAY}
        price={PRICE_PENDING}
        helperText={PAYMENT_BREAKDOWN_HELPER_TEXT.UNAVAILABLE}
        eventParams={eventParams}
      />
    );
  }

  const priceOption = selectedPriceOption(pricingItem.prices, selectedPaymentType);

  if (!priceOption?.payment_breakdown) return null;
  return (
    <ItemPricingDetailed
      paymentBreakdown={priceOption.payment_breakdown}
      estimated={pricingItem.estimated}
      eventParams={eventParams}
    />
  );
};
