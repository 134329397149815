// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { ButtonSelector, Column, Link, Text } from '@alto/pocky';
import React, { useState } from 'react';
import { type AnalyticsProps } from '~shared/features/courier-tips/AnalyticsProps';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import {
  COURIER_TIP_COPY,
  TIP_AMOUNT_OPTIONS,
  TIP_AMOUNT_OPTION_VALUES,
} from '~shared/features/courier-tips/constants';
import { formatDollars } from '~shared/helpers/currency';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import CustomTipInput from './CustomTipInput';

type Props = {
  readonly alignCenter?: boolean;
  readonly editTipAmount: (tipAmount: number) => void;
  readonly initialTipAmount: number;
  readonly verticalSpacing: string;
  readonly analyticsProps: AnalyticsProps;
};

export default function CourierTipInput({
  alignCenter = false,
  editTipAmount,
  initialTipAmount,
  verticalSpacing,
  analyticsProps,
}: Props) {
  const dispatch = useDispatchShared();
  const isCustomAmountSelected = !TIP_AMOUNT_OPTION_VALUES.includes(initialTipAmount);
  const [isCustomAmountInputShowing, setIsCustomAmountInputShowing] = useState(isCustomAmountSelected);

  // @ts-expect-error TS(7006): Parameter 'tipAmount' implicitly has an 'any' type.
  const onEditTipAmount = (tipAmount) => {
    dispatch(
      sendTipAnalyticsEvent({
        eventKey: EVENTS.COURIER_TIPS__AMOUNT_SELECTED,
        analyticsProps,
        additionalProps: { tip_value: formatDollars(tipAmount, false) },
      }),
    );
    editTipAmount(tipAmount);
  };

  // @ts-expect-error TS(7006): Parameter 'tipAmount' implicitly has an 'any' type.
  const hideCustomAmountAndSetSelectedTip = (tipAmount) => {
    setIsCustomAmountInputShowing(false);
    onEditTipAmount(tipAmount);
  };

  const onClickCustomAmount = () => {
    dispatch(sendTipAnalyticsEvent({ eventKey: EVENTS.COURIER_TIPS__CUSTOM_AMOUNT_TAPPED, analyticsProps }));
    setIsCustomAmountInputShowing(true);
  };

  let enterCustomAmount;

  if (isCustomAmountInputShowing) {
    enterCustomAmount = <Text bold>{COURIER_TIP_COPY.CUSTOM_AMOUNT_LINK}</Text>;
  } else {
    enterCustomAmount = <Link onClick={onClickCustomAmount}>{COURIER_TIP_COPY.CUSTOM_AMOUNT_LINK}</Link>;
  }

  return (
    <Column spacing={verticalSpacing}>
      <ButtonSelector
        options={TIP_AMOUNT_OPTIONS}
        onChange={hideCustomAmountAndSetSelectedTip}
        value={isCustomAmountInputShowing ? null : initialTipAmount}
      />
      {enterCustomAmount}
      {isCustomAmountInputShowing ? (
        <CustomTipInput
          alignCenter={alignCenter}
          editTipAmount={onEditTipAmount}
          initialTipAmount={initialTipAmount}
        />
      ) : null}
    </Column>
  );
}
