// @owners { team: patients-team }
import { Body, Column, H3, LgSpacing, ListBase, ListButton, MdSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Modal, ModalContent } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { openModal } from '~shared/actions/modal';
import { useDispatchShared } from '~shared/store';

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

export type MedicationBackorderedModalProps = {
  readonly onClose: () => void;
  readonly prescriptionID: number;
  readonly medicationName: string;
};

export const MedicationBackorderedModal = ({
  onClose,
  prescriptionID,
  medicationName,
}: MedicationBackorderedModalProps) => {
  const dispatch = useDispatchShared();
  const title = `${medicationName || 'Medication'} is backordered`;

  return (
    <StyledModal
      onClose={onClose}
      showing
      title={title}
    >
      <ModalContent>
        <Body>Currently out of stock with no arrival date. We'll notify you when it is available to schedule.</Body>
        <LgSpacing />
        <Column flexGrow={1}>
          <ListBase
            items={[
              {
                key: 'base item title',
                component: <H3>Additional Options</H3>,
              },
              {
                key: 'alternative',
                component: (
                  <ListButton
                    label="Get an alternative medication"
                    onPress={() => dispatch(openModal('MEDICATION_TRANSFER_MODAL', { prescriptionID, medicationName }))}
                    rightIcon="chevronright"
                  />
                ),
              },
              {
                key: 'transfer',
                component: (
                  <ListButton
                    label="Use a different pharmacy"
                    onPress={() =>
                      dispatch(openModal('MEDICATION_ALTERNATIVE_MODAL', { prescriptionID, medicationName }))
                    }
                    rightIcon="chevronright"
                  />
                ),
              },
            ]}
            separator={<MdSpacing />}
          />
        </Column>
      </ModalContent>
    </StyledModal>
  );
};
