// @owners { team: patients-team }
import { AltoSpinningLoader } from '@alto/design-system';
import { MedSyncStatusMap } from '@alto/scriptdash/alto/patients/types/v1/med_sync_status';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
// eslint-disable-next-line import/no-deprecated
import { useFetchMedSyncStatus } from '~shared/queries/useFetchMedSyncStatus';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { PageBackground } from '~web/components/Page';

export enum MED_SYNC_SELF_SERVICE_ROUTES {
  INFO = '/bundle-delivery/info',
  SELECT_MEDICATIONS = '/bundle-delivery/select-medications',
  REQUEST_RECEIVED = '/bundle-delivery/request-received',
  REVIEW = '/bundle-delivery/review',
  LOADING = '/bundle-delivery/creating-plan',
  CONFIRMED = '/bundle-delivery/confirmed',
}

export const MedSyncSelfServiceRoutingContainer = () => {
  const dispatch = useDispatchShared();
  const currentUserID = useSelectorShared(getCurrentUserID);
  // eslint-disable-next-line import/no-deprecated
  const { data, isError, isLoading } = useFetchMedSyncStatus(currentUserID);
  const medSyncStatus = data?.data?.status;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    switch (medSyncStatus) {
      case MedSyncStatusMap.ENROLLED:
        dispatch(push('/home'));
        break;
      case MedSyncStatusMap.INTERESTED:
        dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.SELECT_MEDICATIONS));
        break;
      case MedSyncStatusMap.CONFIRMED_MED_LIST:
        dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.REQUEST_RECEIVED));

        break;
      default:
        dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.INFO));
    }
  }, [dispatch, isLoading, currentUserID, data, medSyncStatus]);

  useEffect(() => {
    if (isError) {
      dispatch(push('/home'));
    }
  }, [dispatch, isError]);

  return (
    <PageBackground>
      <AltoSpinningLoader />
    </PageBackground>
  );
};
