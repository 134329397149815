import { ActionSheetContext, XxsSpacing } from '@alto/design-system';
import { InsuranceDeletionActionSheet } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, FlexRow, Link, spacing } from '@alto/pocky';
import React, { useContext } from 'react';
import { selectInsurance } from '~shared/actions/insurances';
import { openModal } from '~shared/actions/modal';
import { type InsuranceSource } from '~shared/constants';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { useDispatchShared } from '~shared/store';
import { type Insurance as InsuranceType } from '~shared/types';
import InsuranceCard from '~web/features/onboarding/components/redesign/InsuranceCard';

type Props = {
  readonly hideActions?: boolean;
  readonly insurance: InsuranceType;
  readonly source: InsuranceSource;
};

export const Insurance = ({ hideActions = false, insurance, source }: Props) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const handleEditInsurance = () => {
    dispatch(selectInsurance(insurance));
    dispatch(openModal('INSURANCE_MODAL'));
  };

  const handleDeleteInsurance = () => {
    dispatch(selectInsurance(insurance));
    setActiveActionSheet(
      <InsuranceDeletionActionSheet
        insurance={insurance}
        source={source}
      />,
    );
  };

  const insuranceStillProcessing = getIsPhotoUpload(insurance);

  return (
    <FlexRow spacing={spacing.xxsSpacing}>
      <InsuranceCard insurance={insurance} />
      <XxsSpacing key="edit insurance spacing" />
      {!hideActions && (
        <FlexColumn
          verticallyAlignContent
          rightAlignContent
        >
          {!insuranceStillProcessing && (
            <>
              <Link
                small
                onClick={handleEditInsurance}
              >
                Edit
              </Link>
              <span>&nbsp;|&nbsp;</span>
            </>
          )}
          <>
            <Link
              small
              onClick={handleDeleteInsurance}
            >
              Delete
            </Link>
          </>
        </FlexColumn>
      )}
    </FlexRow>
  );
};
