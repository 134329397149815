import {
  OrderBundlingEndpoint,
  type OrderBundlingEndpointOrderBundlingOptionsRequest,
} from '@alto/scriptdash/alto/patient_app/scheduling/v1/order_bundling_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';

const OrderBundlingApi = OrderBundlingEndpoint(apiEndpointHandler);

export const orderBundling = createQueryKeys('orderBundling', {
  options: (params: OrderBundlingEndpointOrderBundlingOptionsRequest) => ({
    queryKey: [params],
    queryFn: () => OrderBundlingApi.orderBundlingOptions(params),
  }),
});
