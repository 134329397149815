import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components/native';
import { Text, type TextProps } from './Text';

export type H3Props = Pick<TextProps, 'color' | 'center' | 'numberOfLines' | 'children' | 'fontFamily'>;

const StyledH3 = styled(Text)<H3Props>`
  font-size: ${TYPOGRAPHY.TEXT.BODY.MD.px};
  line-height: ${TYPOGRAPHY.TEXT.BODY.MD.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD.px};
`;

export const H3 = ({
  center,
  numberOfLines,
  color = COLORS.TEXT_COLORS.PRIMARY,
  children,
  fontFamily = 'semibold',
}: H3Props) => {
  return (
    <StyledH3
      role="heading"
      aria-level={3}
      numberOfLines={numberOfLines}
      center={center}
      color={color}
      fontFamily={fontFamily}
    >
      {children}
    </StyledH3>
  );
};
