import { COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, H1, Modal, ModalContent, ModalFooter, Text, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { CART_UPDATED_LOCATION, cartUpdatedCopyMapping } from '~shared/features/my-meds/constants';
import { ButtonWrapper } from '~web/components/ButtonWrapper';

export type Props = {
  readonly onClose: () => void;
  readonly location: string;
  readonly navigateToMedList: () => void;
};

function getTextBody(path: string) {
  if (path === '/checkout') {
    return cartUpdatedCopyMapping[CART_UPDATED_LOCATION.CHECKOUT];
  }

  return cartUpdatedCopyMapping[CART_UPDATED_LOCATION.NON_SCHEDULING];
}

const TitleText = styled(H1)<{ isSMScreenOrBigger: boolean }>`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  margin-top: ${spacing.xlSpacing};
  ${({ isSMScreenOrBigger }) =>
    !isSMScreenOrBigger &&
    `
      margin-top: ${SPACING.STATIC.MD.rem};
      font-size: 20px;
    `}
`;

const UpdatedCartModal = ({ onClose, location, navigateToMedList }: Props) => {
  const modalText = getTextBody(location);
  const { isSMScreenOrBigger } = useScreenSize();

  const closeModal = location === '/checkout' ? navigateToMedList : onClose;

  return (
    <Modal
      showDismiss={false}
      dismissOnOutsideClick={false}
      onClose={onClose}
    >
      <ModalContent>
        <Column spacing={SPACING.STATIC.MD.rem}>
          <TitleText isSMScreenOrBigger={isSMScreenOrBigger}>{modalText.title}</TitleText>
          <Text>{modalText.body}</Text>
        </Column>
      </ModalContent>
      <ModalFooter>
        <ButtonWrapper horizontallyAlignContent>
          <Button onClick={closeModal}>{modalText.buttonText}</Button>
        </ButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default UpdatedCartModal;
