// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Column, H3, Text, palette, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import {
  BE_SURE_TAGLINE,
  CETROTIDE,
  CETROTIDE_DISCLAIMER,
  CLINIC_DISCLAIMER,
  WASH_HANDS_TAGLINE,
} from '~shared/features/instructional-videos/constants';
import { type Video } from '~shared/features/instructional-videos/types';

const VideoTitle = styled(H3)`
  color: ${palette.altoColorsP2};
`;

const VideoPointersList = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
`;

const ItalicText = styled(Text)`
  font-style: italic;
`;

type Props = {
  readonly video: Video | null | undefined;
};

const DescriptionPane = ({ video }: Props) => {
  if (!video) return null;
  return (
    <Column spacing={spacing.smSpacing}>
      <VideoTitle>{video.title}</VideoTitle>
      <Text>{BE_SURE_TAGLINE}</Text>
      <VideoPointersList>
        {video.prepItems.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </VideoPointersList>
      {video === CETROTIDE && <ItalicText>{CETROTIDE_DISCLAIMER}</ItalicText>}
      <Text>{WASH_HANDS_TAGLINE}</Text>
      <Text>
        <Text bold>Note:</Text>
        {CLINIC_DISCLAIMER[0]}
      </Text>
      <Text>{CLINIC_DISCLAIMER[1]}</Text>
    </Column>
  );
};

export default DescriptionPane;
