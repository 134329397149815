// @owners { team: patients-team }
import { Description, InlineAlert, Link, MdPadding } from '@alto/design-system';
import React from 'react';

type LockedCaseInlineAlertProps = {
  readonly supportCaseLocked: boolean;
  readonly handleNewRequestPress: () => void;
};

/**
 * Inline alert component that will show with the current support case is locked ie read only state, patients cannot
 * add new messages to the thread when the case is locked but they can read the thread
 * @returns LockedCaseInlineAlert UI
 */
export const LockedCaseInlineAlert = ({ supportCaseLocked, handleNewRequestPress }: LockedCaseInlineAlertProps) => {
  if (!supportCaseLocked) {
    return null;
  }

  return (
    <MdPadding>
      <InlineAlert>
        <Description>
          Request closed after 7 days of no activity. Have a question?{' '}
          <Link
            underline
            onPress={handleNewRequestPress}
            textSize="mini"
          >
            Start a new request
          </Link>
        </Description>
      </InlineAlert>
    </MdPadding>
  );
};
