// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  AltoIcon,
  AltoSpinningLoader,
  Description,
  InlineAlert,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  ListDescription,
  Row,
  Toast,
  ToastContext,
} from '@alto/design-system';
import { ShipmentFeeTypeMap } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import { type FulfillmentWindowOption } from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import { isToday, parseISO } from 'date-fns';
import React, { useCallback, useContext, useEffect } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getOrderAddressId } from '~shared/features/checkout/selectors/getOrder';
import getSelectedPaymentTypes from '~shared/features/checkout/selectors/getSelectedPaymentTypes';
import { formatDollars } from '~shared/helpers/currency';
import { formatDateWithoutYear } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { useQueryFulfillmentWindowOptions } from '../../../..';
import { getSelectedPriceTypesForPrescriptions } from '../../helpers/getSelectedPriceTypesForPrescriptions';
import { FeeRadioRightContent } from '../select-date-card/FeeRadioRightContent';

type SelectDeliveryWindowActionSheetProps = {
  deliveryDate: string;
  selectedWindowOption?: FulfillmentWindowOption;
  handleDeliveryWindowSelection?: (deliveryWindow: FulfillmentWindowOption) => void; // pick from a specified date
  handleCustomDateDeliveryWindowSelection?: (deliveryDate: string, deliveryWindow: FulfillmentWindowOption) => void; // pick a custom date
};

type FeeEligibility = {
  asap_eligible: boolean;
  asap_eta?: string | null;
  same_day_eligible: boolean;
};

export const SelectDeliveryWindowActionSheet = ({
  deliveryDate,
  selectedWindowOption,
  handleDeliveryWindowSelection,
  handleCustomDateDeliveryWindowSelection,
}: SelectDeliveryWindowActionSheetProps) => {
  const { trackPageView } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const addressID = useSelectorShared(getOrderAddressId);
  const { prescriptionIDs: cartPrescriptionIDs } = usePrescriptionsInCart();
  const cartSelectedPaymentTypes = useSelectorShared(getSelectedPaymentTypes);
  const selectedPrices = getSelectedPriceTypesForPrescriptions({
    prescriptionIDs: cartPrescriptionIDs,
    cartSelectedPaymentTypes,
  });
  const editShipmentId = useSelectorShared(getEditShipmentID);
  const {
    fulfillmentWindowOptions,
    isPending: isDeliveryWindowLoading,
    isSuccess: isDeliveryWindowSuccess,
  } = useQueryFulfillmentWindowOptions({
    addressID,
    cartPrescriptionIDs,
    date: deliveryDate,
    selectedPrices,
    shipmentID: editShipmentId,
  });

  useEffect(() => {
    if (!isDeliveryWindowSuccess) return;

    const feeEligibility: FeeEligibility = {
      asap_eligible: false,
      asap_eta: undefined,
      same_day_eligible: false,
    };

    fulfillmentWindowOptions.forEach((option) => {
      if (option.available_fee?.fee_type === 'asap_delivery') {
        feeEligibility.asap_eligible = true;
        feeEligibility.asap_eta = option.estimated_eta;
      }
      if (option.available_fee?.fee_type === 'same_day_delivery') {
        feeEligibility.same_day_eligible = true;
      }
    });

    trackPageView({
      event: EVENTS.CHECKOUT_VIEWED_WITH_FULFILLMENT_WINDOW_OPTIONS,
      params: {
        origin: editShipmentId ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
        shipment_id: editShipmentId,
        ...feeEligibility,
      },
    });
  }, [editShipmentId, fulfillmentWindowOptions, isDeliveryWindowSuccess, trackPageView]);

  const handleWindowPress = useCallback(
    (value: string) => {
      const deliveryWindow = fulfillmentWindowOptions.find((window) => window.description === value);
      if (!deliveryWindow) {
        addToast(<Toast variant="error">Could not set delivery window</Toast>);
        return;
      }
      handleDeliveryWindowSelection?.(deliveryWindow);
      handleCustomDateDeliveryWindowSelection?.(deliveryDate, deliveryWindow);
    },
    [
      addToast,
      deliveryDate,
      fulfillmentWindowOptions,
      handleCustomDateDeliveryWindowSelection,
      handleDeliveryWindowSelection,
    ],
  );

  return (
    <ActionSheetV2
      title="Select delivery time"
      description={
        !isDeliveryWindowLoading
          ? `Delivery ${isToday(parseISO(deliveryDate)) ? 'today' : formatDateWithoutYear(deliveryDate)} via courier`
          : undefined
      }
      analyticsName="checkout select delivery window"
    >
      {isDeliveryWindowLoading && (
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <Row
            flexGrow={1}
            center
          >
            <AltoSpinningLoader />
          </Row>
        </LgPadding>
      )}
      {!isDeliveryWindowLoading && fulfillmentWindowOptions.length === 0 && (
        <LgPadding>
          <InlineAlert type="error">
            <Description>No windows available. Please select another date.</Description>
          </InlineAlert>
        </LgPadding>
      )}
      {!isDeliveryWindowLoading && fulfillmentWindowOptions.length > 0 && (
        <InputRadioGroup
          onValueChange={handleWindowPress}
          value={selectedWindowOption?.date === deliveryDate ? selectedWindowOption?.description : undefined}
        >
          {fulfillmentWindowOptions.map(({ description, label, available_fee }, index) => {
            const isAsapFee = available_fee?.fee_type === ShipmentFeeTypeMap.ASAP_DELIVERY;
            return (
              <React.Fragment key={description}>
                <InputRadio
                  testID={`e2e-delivery-window-option-${index}`}
                  radioPlacement="left"
                  key={label}
                  label={label}
                  value={description}
                  descriptions={[<ListDescription key={description}>{description}</ListDescription>]}
                  RightContent={
                    <FeeRadioRightContent
                      feeAmountLabel={available_fee?.amount ? formatDollars(available_fee.amount) : 'Free'}
                      feeOriginalAmountLabel={
                        available_fee?.original_amount ? formatDollars(available_fee.original_amount) : 'Free'
                      }
                    />
                  }
                  TitlePressableIcon={isAsapFee ? <AltoIcon name="lightning-duo" /> : undefined}
                  titlePressableIconPlacement={isAsapFee ? 'inline' : undefined}
                />
              </React.Fragment>
            );
          })}
        </InputRadioGroup>
      )}
    </ActionSheetV2>
  );
};
