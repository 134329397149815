// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import {
  Card,
  Column,
  ContentLoader,
  Description,
  ExpandableImage,
  H1,
  Illustration,
  InlineAlert,
  LgPadding,
  LgSpacing,
  MdSpacing,
  Row,
  SecondaryPage,
  TagText,
  XsSpacing,
  XxsSpacing,
  useScreenSize,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import medImagePlaceholder from '~shared/assets/images/med_image_placeholder.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { CartLauncherButton } from '../../cart';
import { PageErrorFallback } from '../../error-boundary';
import { useMedDetailsMedication } from '../queries/useMedDetails';
import { MedicationDescriptions } from './MedicationDescriptions';
import { OtherPrescriptions } from './OtherPrescriptions';
import { PrescriptionCard } from './prescription-card';

const SmExpandableImageWrapper = styled(View)`
  width: ${SIZES.ILLUSTRATION.SM.px};
`;

const MdImageSizedOffset = styled(View)`
  width: ${SIZES.ILLUSTRATION.MD.px};
`;

export const MedDetails = ({
  origin,
  primaryPrescriptionId,
  useAddToCartInterstitialSheets,
}: {
  readonly origin: string;
  readonly primaryPrescriptionId: number;
  readonly useAddToCartInterstitialSheets: any;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { trackPageView } = useAnalytics();
  const { goBack, setOptions } = useNavigation<'RouteMedDetails'>();
  const { isFetching, medication } = useMedDetailsMedication({ primaryPrescriptionId });
  const hasMedication = medication !== undefined && medication !== null;
  const { isMDScreenOrBigger } = useScreenSize();
  const { cartSize } = useCartData();

  // setOptions is here to override the header options in meds.tsx - we don't show a header on this page
  useEffect(() => {
    setOptions({ headerShown: false });
  }, [setOptions]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.MED_DETAILS__VIEWED,
      params: {
        origin,
        medicationName: medication?.title,
        medicationStatus: medication?.primary_prescription?.status?.title,
        hasOtherPrescriptions: medication?.has_other_prescriptions,
      },
    });
  });

  if (isFetching && !hasMedication) {
    return (
      <SecondaryPage
        HeaderContent={
          <LgPadding
            topPadding={SPACING.STATIC.XS}
            bottomPadding={SPACING.STATIC.MD}
            leftPadding={isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
            rightPadding={isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
          >
            <Row
              flexShrink={1}
              flexGrow={1}
            >
              <H1>Medication details</H1>
            </Row>
          </LgPadding>
        }
        headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
        dismissIcon="chevronleft"
        onDismiss={goBack}
      >
        <LgSpacing />
        <Card>
          <LgPadding>
            <Column>
              <ContentLoader accessibilityLabel="loading medication details" />
            </Column>
          </LgPadding>
        </Card>
      </SecondaryPage>
    );
  }

  if (!hasMedication) {
    return (
      <SecondaryPage
        headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
        dismissIcon="chevronleft"
        onDismiss={goBack}
      >
        <PageErrorFallback />
      </SecondaryPage>
    );
  }

  const { title, image_url, patient_name, stock_status, stock_details, primary_prescription, has_other_prescriptions } =
    medication;
  return (
    <SecondaryPage
      dismissIcon="chevronleft"
      onDismiss={goBack}
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      buttons={cartSize > 0 ? [<CartLauncherButton key="med-details-cart-launcher-button" />] : []}
      HeaderContent={
        <LgPadding
          topPadding={SPACING.STATIC.XS}
          bottomPadding={SPACING.STATIC.MD}
          leftPadding={isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
          rightPadding={isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
        >
          <Column>
            {patient_name ? <Description color={COLORS.TEXT_COLORS.GREY}>{patient_name}</Description> : null}
            <XsSpacing />
            <Row
              wrap={false}
              top
            >
              <Row
                flexShrink={1}
                flexGrow={1}
              >
                <H1>{title}</H1>
              </Row>
              <MdSpacing />
              {image_url ? (
                <SmExpandableImageWrapper>
                  <ExpandableImage
                    accessibilityLabel={IMG_ALT_TEXTS.medication}
                    imageUrl={image_url}
                    height={SIZES.ILLUSTRATION.SM.value}
                  />
                </SmExpandableImageWrapper>
              ) : (
                <Illustration
                  size="sm"
                  borderRadius={BORDERS.RADIUS.MD.value}
                  source={medImagePlaceholder}
                />
              )}
            </Row>
            {stock_status ? (
              <>
                <XsSpacing />
                <InlineAlert
                  type="error"
                  borderless
                >
                  <Row>
                    <TagText color={COLORS.TEXT_COLORS.PRIMARY}>{stock_status}</TagText>
                  </Row>
                  {stock_details ? (
                    <>
                      <XxsSpacing />
                      <Description color={COLORS.TEXT_COLORS.BLACK}>{stock_details}</Description>
                    </>
                  ) : null}
                </InlineAlert>
              </>
            ) : null}
            <XsSpacing />
            <Row wrap={false}>
              <MedicationDescriptions
                medication={medication}
                providerName={primary_prescription.prescriber}
              />
              <MdSpacing />
              <MdImageSizedOffset />
            </Row>
          </Column>
        </LgPadding>
      }
    >
      <PrescriptionCard
        isPrimary
        prescription={primary_prescription}
        useAddToCartInterstitialSheets={useAddToCartInterstitialSheets}
        medicationName={title}
        primaryPrescriptionId={primaryPrescriptionId}
      />
      {has_other_prescriptions ? (
        <>
          <MdSpacing />
          <OtherPrescriptions
            primaryPrescriptionId={primaryPrescriptionId}
            useAddToCartInterstitialSheets={useAddToCartInterstitialSheets}
          />
        </>
      ) : null}
    </SecondaryPage>
  );
};
