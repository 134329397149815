// @owners { team: patients-team }
import { Button } from '@alto/design-system';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';

type DownloadReceiptButtonProps = {
  readonly handleDownloadReceipt: () => void | Promise<void>;
  readonly shipmentID: number;
};

export const DownloadReceiptButton = ({ handleDownloadReceipt, shipmentID }: DownloadReceiptButtonProps) => {
  const fetchOrderPricingLoading = useSelectorShared((state) => state.ui.loading.fetchOrderPricingLoading);
  const fetchFsaReceiptLoading = useSelectorShared((state) => state.ui.loading.fetchFsaReceiptLoading);
  const { trackEvent } = useAnalytics();

  const onPress = () => {
    handleDownloadReceipt();
    trackEvent({
      event: EVENTS.DELIVERY_DETAILS_DOWNLOAD_BUTTON_CLICKED,
      additionalFields: { shipmentId: shipmentID },
    });
  };

  return (
    <Button
      accessibilityLabel="Download Receipt Button"
      label="Download Receipt"
      loading={fetchOrderPricingLoading || fetchFsaReceiptLoading}
      onPress={onPress}
      small
      testID="download-receipt-button"
      type="secondary"
      width="inline"
    >
      Download receipt
    </Button>
  );
};
