// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  FlexColumn,
  FlexRow,
  InputBlockText,
  Link,
  Paragraph,
  spacing,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { PRIVACY_POLICY_URL } from '~shared/constants';
import getURLParam from '~shared/helpers/getURLParam';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

type Values = {
  zip: string;
};

export type Props = {
  readonly disabled?: boolean;
  readonly onSubmit: (values: Values) => void;
};

const validateForm = ({ zip }: Values) => {
  const zipErrors = validatePresentWithMessage('Zip Code')(zip);

  if (zipErrors) {
    return {
      zip: zipErrors,
    };
  }

  return {};
};

const ServiceAreaForm = ({ disabled, onSubmit }: Props) => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    const origin = getURLParam('origin', window.location.href);
    trackPageView({
      event: EVENTS.ZIP_VERIFICATION_VIEWED,
      params: { flow: 'sign up', origin },
    });
  }, [trackPageView]);

  return (
    <Formik
      initialValues={{
        zip: '',
      }}
      onSubmit={onSubmit}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form>
        <FlexRow spacing={spacing.mdSpacing}>
          <Paragraph>Let's check if we serve your area. What's your zip code?</Paragraph>
          <Field name="zip">
            {({ field, meta }: FieldProps<string>) => (
              <InputBlockText
                label="Zip code"
                type="text"
                placeholder="Zip code"
                meta={meta}
                pattern="[0-9]*"
                inputMode="numeric"
                {...field}
              />
            )}
          </Field>
          <FlexColumn horizontallyAlignContent>
            <Link
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </FlexColumn>
          {/* @ts-expect-error Property 'onClick' is missing in type */}
          <Button
            full
            type="submit"
            kind="primary"
            disabled={disabled}
          >
            Submit
          </Button>
        </FlexRow>
      </Form>
    </Formik>
  );
};

export default ServiceAreaForm;
