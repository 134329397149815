import { COLORS } from '@alto/design-library-tokens';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Pressable } from '../../../buttons';
import { AltoIcon } from '../../../icon';
import { ListItem, type ListItemProps } from '../../../lists';
import { type Tag } from '../../../tags';
import { RadioGroupProvider } from './RadioGroupProvider';

export type InputRadioProps = Pick<
  ListItemProps,
  'LeftContent' | 'descriptions' | 'byline' | 'tagPlacement' | 'TitlePressableIcon' | 'titlePressableIconPlacement'
> & {
  /**
   * Primary label for the radio input.
   */
  readonly label: string;
  /**
   * The value of the radio input.
   */
  readonly value: string;
  /**
   * A boolean to set a specific radio input to disabled.
   */
  readonly disabled?: boolean;
  /**
   * Do not use this to handle form state, only use it for side effects.
   */
  readonly onCheck?: () => void;
  /**
   * Tag that appears below the primary label.
   */
  readonly tag?: React.ReactElement<typeof Tag>;
  /**
   * Test ID used for testing purposes.
   */
  readonly testID?: string;
  /**
   * Determines which side the radio indicator is rendered on, right (default) or left.
   */
  readonly radioPlacement?: 'right' | 'left';
  /**
   * Content on the right side of the component that can be shown when the radioPosition is set to "left"
   */
  readonly RightContent?: ListItemProps['RightContent'];
};

export const InputRadio = ({
  label,
  value,
  disabled,
  tag,
  tagPlacement,
  descriptions,
  byline,
  radioPlacement = 'right',
  LeftContent,
  RightContent,
  onCheck,
  TitlePressableIcon,
  titlePressableIconPlacement,
  testID,
}: InputRadioProps) => {
  // Web-only state to render effects on hover
  const [isHovering, setIsHovering] = useState(false);
  const { checkedValue, onPressRadio, groupIsDisabled } = useContext(RadioGroupProvider);
  const handleRadioPress = useCallback(() => {
    onPressRadio(value);
    onCheck?.();
  }, [onPressRadio, onCheck, value]);

  const isChecked = value === checkedValue;
  const isDisabled = !!disabled || !!groupIsDisabled;

  const handleHoverIn = () => {
    setIsHovering(true);
  };

  const handleHoverOut = () => {
    setIsHovering(false);
  };

  const radioIndicator = useMemo(
    () => (
      <AltoIcon
        name={isChecked ? 'radio-duo' : 'radio'}
        type={isDisabled ? 'disabled' : undefined}
      />
    ),
    [isChecked, isDisabled],
  );

  const shouldShowBackground = isChecked || isHovering;
  return (
    <Pressable
      onPress={handleRadioPress}
      accessibilityRole="radio"
      accessibilityState={{ checked: isChecked, disabled: isDisabled }}
      accessibilityChecked={isChecked}
      accessibilityLabel={label}
      backgroundColor={shouldShowBackground ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER : 'transparent'}
      disabled={isDisabled}
      // Web-only props to render effects on hover
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      testID={testID}
    >
      <ListItem
        title={label}
        RightContent={radioPlacement === 'right' ? radioIndicator : RightContent}
        disabled={isDisabled}
        tags={tag}
        tagPlacement={tagPlacement}
        descriptions={descriptions}
        byline={byline}
        LeftContent={radioPlacement === 'left' ? radioIndicator : LeftContent}
        backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
        TitlePressableIcon={TitlePressableIcon}
        titlePressableIconPlacement={titlePressableIconPlacement}
      />
    </Pressable>
  );
};
