// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Body, Column, Row, XsSpacing } from '@alto/design-system';
import React from 'react';
import { Image, View } from 'react-native';
import styled from 'styled-components/native';
import checkIcon from '~shared/assets/images/check_icon.png';

type AutoRefillStepProps = {
  readonly text?: string;
  readonly children?: React.ReactNode;
};

const ImageContainer = styled(Column).attrs({
  centerVertically: true,
})`
  max-height: ${SPACING.STATIC.LG.px};
`;

export const AutoRefillStep = ({ text, children }: AutoRefillStepProps) => (
  <Row left>
    <ImageContainer>
      <Image
        source={checkIcon}
        accessible
        accessibilityLabel="Checkmark"
      />
    </ImageContainer>
    <XsSpacing />
    <Row
      flexGrow={1}
      flexShrink={1}
      flexBasis="0%"
    >
      {!!text && <Body>{text}</Body>}
    </Row>
    <View>{children}</View>
  </Row>
);
