// @owners { team: patients-team }
import { InputSelect } from '@alto/design-system';
import React from 'react';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { GENDERS } from '~shared/constants';
import { getPossessiveName } from '~shared/helpers/string';

type SexInputProps = {
  defaultValue?: string;
  familyMemberName?: string;
  onChange: (value: string) => void;
  error?: string;
};

const genders = TypescriptUtils.objectKeys(GENDERS).map((v) => ({
  label: GENDERS[v],
  value: v,
}));

export const SexInputSelect = ({ defaultValue, familyMemberName, onChange, error }: SexInputProps) => {
  const caption =
    familyMemberName !== undefined ? 'This should match their insurance' : 'This should match your insurance';

  return (
    <InputSelect
      defaultValue={defaultValue}
      caption={caption}
      description={caption}
      label={familyMemberName !== undefined ? `${getPossessiveName(familyMemberName) || 'Family member’s'} sex` : 'Sex'}
      modalTitle="Select sex"
      onValueChange={onChange}
      options={genders}
      placeholder="Select sex"
      required
      error={error}
    />
  );
};
