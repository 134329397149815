import { useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

export const useOnboardingConfirmationViewed = (isProgynyRxView: boolean, origin?: string) => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__CONFIRMATION_VIEWED,
      params: {
        origin,
        is_progyny_rx_view: isProgynyRxView,
      },
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
