// @owners { team: patients-team }
import {
  ConfigsEndpoint,
  type ConfigsEndpointFetchCreateAccountRequest,
  type ConfigsEndpointFetchCreateAccountResponse,
  type ConfigsEndpointFetchInsuranceRequest,
  type ConfigsEndpointFetchInsuranceResponse,
  type ConfigsEndpointFetchLandingPageRequest,
  type ConfigsEndpointFetchLandingPageResponse,
  type ConfigsEndpointFetchMedConfirmationRequest,
  type ConfigsEndpointFetchMedConfirmationResponse,
  type ConfigsEndpointFetchMedicalInfoRequest,
  type ConfigsEndpointFetchMedicalInfoResponse,
  type ConfigsEndpointFetchOnboardSuccessRequest,
  type ConfigsEndpointFetchOnboardSuccessResponse,
  type ConfigsEndpointFetchPhiAuthorizationRequest,
  type ConfigsEndpointFetchPhiAuthorizationResponse,
  type ConfigsEndpointFetchTransferRequest,
  type ConfigsEndpointFetchTransferResponse,
} from '@alto/scriptdash/alto/patient_app/onboarding/v1/configs_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';

const onboardingConfigEndpoint = ConfigsEndpoint(apiEndpointHandler);

export const onboardingConfigs = createQueryKeys('onboardingConfigs', {
  fetchLandingPage: ({ params }: { params: ConfigsEndpointFetchLandingPageRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchLandingPageResponse> => {
      const response = await onboardingConfigEndpoint.fetchLandingPage(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchMedConfirmation: ({ params }: { params: ConfigsEndpointFetchMedConfirmationRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchMedConfirmationResponse> => {
      const response = await onboardingConfigEndpoint.fetchMedConfirmation(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchInsurance: ({ params }: { params: ConfigsEndpointFetchInsuranceRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchInsuranceResponse> => {
      const response = await onboardingConfigEndpoint.fetchInsurance(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchTransfer: ({ params }: { params: ConfigsEndpointFetchTransferRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchTransferResponse> => {
      const response = await onboardingConfigEndpoint.fetchTransfer(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchMedicalInfo: ({ params }: { params: ConfigsEndpointFetchMedicalInfoRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchMedicalInfoResponse> => {
      const response = await onboardingConfigEndpoint.fetchMedicalInfo(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchPhiAuthorization: ({ params }: { params: ConfigsEndpointFetchPhiAuthorizationRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchPhiAuthorizationResponse> => {
      const response = await onboardingConfigEndpoint.fetchPhiAuthorization(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchCreateAccount: ({ params }: { params: ConfigsEndpointFetchCreateAccountRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchCreateAccountResponse> => {
      const response = await onboardingConfigEndpoint.fetchCreateAccount(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),

  fetchOnboardSuccess: ({ params }: { params: ConfigsEndpointFetchOnboardSuccessRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<ConfigsEndpointFetchOnboardSuccessResponse> => {
      const response = await onboardingConfigEndpoint.fetchOnboardSuccess(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
});
