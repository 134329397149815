import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import styled from 'styled-components/native';
import { Text } from './Text';

export const TopBarText = styled(Text).attrs({
  color: COLORS.TEXT_COLORS.PRIMARY,
})`
  font-family: ${TYPOGRAPHY.FONT.HEADER.SEMIBOLD};
  font-size: ${TYPOGRAPHY.TEXT.HEADER.XS.px};
  line-height: ${TYPOGRAPHY.TEXT.HEADER.XS.value * TYPOGRAPHY.LINE_HEIGHT.MD}px;
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.SM.px};
`;
