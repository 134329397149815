// @owners { team: patients-team }
import React from 'react';
import InfoRow from '~web/components/InfoRow';

type Props = {
  readonly medicationName: string;
  readonly courierAgeRestrictionEnabled: boolean;
};

export const PhotoIDRequiredTag = ({ medicationName, courierAgeRestrictionEnabled }: Props) => {
  if (courierAgeRestrictionEnabled) {
    return (
      <InfoRow
        icon="idcard-small"
        infoText={`${medicationName} is a controlled substance. Someone 18+ must be present to show a photo id and sign for this order.`}
      >
        18+ photo ID & signature required
      </InfoRow>
    );
  }

  return (
    <InfoRow
      icon="signature-small"
      infoText={`${medicationName} is a controlled substance and requires a signature upon delivery.`}
    >
      Signature required upon delivery
    </InfoRow>
  );
};
