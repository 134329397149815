// @owners { team: patients-team }

export const RATING_PROMPT_ACTION = 'RATING_PROMPT_ACTION';

type RatingPromptAction = {
  type: typeof RATING_PROMPT_ACTION;
  payload: {
    screen: string;
    action: string;
  };
};
export function ratingPromptAction(screen: string, action: string) {
  return {
    type: RATING_PROMPT_ACTION,
    payload: {
      screen,
      action,
    },
  };
}

export type FeedbackAction = RatingPromptAction;
