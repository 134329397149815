// @owners { team: patients-team }
import { Button } from '@alto/design-system';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

export type SnoozeButtonProps = {
  readonly handleOpenSnooze?: () => void; //because no web functionality
  readonly shipmentID: number;
};

export const SnoozeButton = ({ handleOpenSnooze, shipmentID }: SnoozeButtonProps) => {
  const { trackEvent } = useAnalytics();

  const onPress = () => {
    handleOpenSnooze?.();
    trackEvent({ event: EVENTS.DELIVERY_DETAILS_SNOOZE_BUTTON_CLICKED, additionalFields: { shipmentId: shipmentID } });
  };

  return (
    <Button
      accessibilityLabel="Snooze Order Button"
      onPress={onPress}
      small
      type="secondary"
      testID="snooze-order-button"
      width="inline"
    >
      Snooze
    </Button>
  );
};
