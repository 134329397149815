// @owners { team: patients-team }
import { SIZES, SPACING } from '@alto/design-library-tokens';
import { LgSpacing, MdSpacing, XlSpacing } from '@alto/design-system';
import { AddOnsContext } from '@alto/features';
import React, { useMemo } from 'react';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { type Shipment } from '~shared/types';
import { FullPage } from '~web/features/essentials/components';
import { DesktopProducts } from '~web/features/essentials/pages/OrderSuccess/DesktopProducts';
import { MobileProducts } from '~web/features/essentials/pages/OrderSuccess/MobileProducts';
import { OrderCard } from '~web/features/essentials/pages/OrderSuccess/OrderCard';
import { Image, Text, View } from '~web/features/essentials/primitives';
import { useDeprecatedMediaQuery, useDeprecatedWindowResize } from '~web/hooks';

const orderCompleteImage = 'https://assets.prod.alto.com/otc_categories/box.svg';

type Props = {
  shipment: Shipment;
};

export const OrderSuccessWithProducts = ({ shipment }: Props) => {
  const addOnsContext = useMemo(
    () => ({ shipmentID: shipment?.id, origin: ORIGINS.ORDER_SUCCESS }) as const,
    [shipment?.id],
  );
  const isMobile = !useDeprecatedMediaQuery('SM');
  const isLargeScreen = useDeprecatedMediaQuery('LG');
  const screenPartitions = isMobile ? '100%' : '50%';
  const windowSize = useDeprecatedWindowResize();
  const defaultViewSidePadding = isMobile ? SPACING.STATIC.LG.px : SPACING.STATIC.XXL.px;
  const sidePadding = isLargeScreen
    ? `${(windowSize.width - parseFloat(SIZES.PAGE.WEB_NAVBAR_WIDTH.LG)) / 2}px`
    : defaultViewSidePadding;

  return (
    <FullPage>
      <AddOnsContext.Provider value={addOnsContext}>
        <View
          padding={`${SPACING.STATIC.XXXL.px} ${defaultViewSidePadding} ${SPACING.STATIC.XXXL.px} ${sidePadding}`}
          flexBasis={screenPartitions}
          flexDirection="column"
          backgroundColor="TERTIARY_LIGHTEST"
        >
          <View
            alignItems={isMobile ? 'center' : 'flex-start'}
            flexDirection="column"
          >
            {isMobile ? (
              <View justifyContent="center">
                <Image
                  src={orderCompleteImage}
                  alt="order complete"
                  width={SIZES.ILLUSTRATION.XL.px}
                  height={SIZES.ILLUSTRATION.XL.px}
                />
              </View>
            ) : null}
            <LgSpacing />
            <Text
              title="Order scheduled!"
              variant="h1"
              align="center"
            />
            <XlSpacing />
          </View>
          <OrderCard
            shipment={shipment}
            showTitleIllustration={true}
          />
          {isMobile ? (
            <>
              <MdSpacing />
              {shipment ? <MobileProducts /> : null}
            </>
          ) : null}
        </View>
        {!isMobile && (
          <View
            flexDirection="column"
            padding={`${SPACING.STATIC.XXXL.px} ${sidePadding} ${SPACING.STATIC.XXXL.px} ${SPACING.STATIC.XXL.px}`}
            flexBasis={screenPartitions}
            overflow="hidden"
          >
            <DesktopProducts />
          </View>
        )}
      </AddOnsContext.Provider>
    </FullPage>
  );
};
