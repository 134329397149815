// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { InfoBox, Text } from '@alto/pocky';
import React from 'react';

const CookieError = () => (
  <InfoBox
    danger
    title="Uh oh!"
  >
    <Text>Please enable cookies and refresh page to continue.</Text>
  </InfoBox>
);

export default CookieError;
