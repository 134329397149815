import { v4 as uuid } from 'uuid';
import { EVENTS_FLUSHED_FAILED, EVENTS_FLUSHED_SUCCEEDED, EVENTS_FLUSH_STARTED, EVENTS_GENERATED } from './actions';
import { type Event } from './types';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateAnalytics = {
  readonly events: (Event & {
    id: string;
  })[];
  readonly flushing: boolean;
  readonly lastFlushAttemptedAt: number;
};

const initialState: StateAnalytics = {
  events: [],
  flushing: false,
  lastFlushAttemptedAt: Date.now(),
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
function analytics(state: StateAnalytics = initialState, action): StateAnalytics {
  switch (action.type) {
    case EVENTS_GENERATED:
      return {
        ...state,
        events: [
          ...state.events,
          // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
          ...action.payload.map((e) => ({
            id: uuid(),
            ...e,
          })),
        ],
      };

    case EVENTS_FLUSHED_SUCCEEDED: {
      // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
      const flushedEventIDs = action.payload.events.map((e) => e.id);

      return {
        ...state,
        // only remove events we know we've flushed in case new events have
        // been generated since the flush began.
        events: state.events.filter((e) => !flushedEventIDs.includes(e.id)),
        flushing: false,
      };
    }

    case EVENTS_FLUSHED_FAILED:
      return { ...state, flushing: false };

    case EVENTS_FLUSH_STARTED:
      return { ...state, flushing: true, lastFlushAttemptedAt: Date.now() };

    default:
      return state;
  }
}

export default withRehydration(analytics, initialState);
