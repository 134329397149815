// @owners { team: patients-team }
import {
  type CreateShipmentTipRequest as Request,
  type CreateShipmentTipResponse as Response,
} from '@alto/scriptdash/alto/patients/v3/courier_tips/wunderbar/v1/shipment_tips_endpoint';
import { type Dispatch } from 'redux';
import api from '~shared/features/courier-tips/api';
import { type APIError } from '~shared/types/APIError';

export const CREATE_SHIPMENT_TIP_LOADING = 'CREATE_SHIPMENT_TIP_LOADING';
export const CREATE_SHIPMENT_TIP_SUCCEEDED = 'CREATE_SHIPMENT_TIP_SUCCEEDED';
export const CREATE_SHIPMENT_TIP_FAILED = 'CREATE_SHIPMENT_TIP_FAILED';

type ResponseData = Response['data'];
type ParsedResponse = {
  data: ResponseData;
  error: APIError;
};

export type CreateShipmentTipLoadingAction = {
  type: typeof CREATE_SHIPMENT_TIP_LOADING;
};
export function createShipmentTipLoading(): CreateShipmentTipLoadingAction {
  return {
    type: CREATE_SHIPMENT_TIP_LOADING,
  };
}

export type CreateShipmentTipSucceededAction = {
  type: typeof CREATE_SHIPMENT_TIP_SUCCEEDED;
  payload: ResponseData;
};
export function createShipmentTipSucceeded(payload: ResponseData): CreateShipmentTipSucceededAction {
  return {
    type: CREATE_SHIPMENT_TIP_SUCCEEDED,
    payload,
  };
}

export type CreateShipmentTipFailedAction = {
  type: typeof CREATE_SHIPMENT_TIP_FAILED;
  payload: APIError;
};
export function createShipmentTipFailed(payload: APIError): CreateShipmentTipFailedAction {
  return {
    type: CREATE_SHIPMENT_TIP_FAILED,
    payload,
  };
}

export type CreateShipmentTipActions =
  | CreateShipmentTipLoadingAction
  | CreateShipmentTipSucceededAction
  | CreateShipmentTipFailedAction;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function createShipmentTip(params: Request) {
  return async (dispatch: Dispatch<CreateShipmentTipActions>): Promise<ResponseData | APIError> => {
    dispatch(createShipmentTipLoading());

    try {
      // @ts-expect-error TS(2741): Property 'error' is missing in type 'CreateShipmentTipResponse' but required in type 'ParsedResponse... (Delete me to see the full error)
      const { data, error }: ParsedResponse = await api.createShipmentTip(params);

      // NOTE: Failed API responses are not rejected, so we have to throw here
      if (error) {
        throw error;
      }

      dispatch(createShipmentTipSucceeded(data));
      return data;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to parameter of type 'APIError'.
      dispatch(createShipmentTipFailed(error));
      throw error;
    }
  };
}
