// @owners { team: patients-team }
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { getDeliveryMethod } from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { useGetDeliveryMethodsByShipmentIDs } from '../../delivery-methods';

type Props = {
  shipmentID: number;
  readonly status?: APIOrderStatus;
};

export const DeliveryMethodRow = ({ shipmentID, status }: Props) => {
  const { deliveryMethodsByShipmentID, deliveryMethodsLoading } = useGetDeliveryMethodsByShipmentIDs([shipmentID]);
  const { text, iconName } = getDeliveryMethod(deliveryMethodsByShipmentID[shipmentID], true, status);

  return (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name={iconName}
          type="grey"
          testID={`delivery-method-icon-${iconName}`}
        />
      }
      text={deliveryMethodsLoading ? '...' : text}
    />
  );
};
