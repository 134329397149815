import { type Coupon } from '@alto/scriptdash/alto/billing/types/v1/coupon';
import { createSelector } from 'reselect';
import { getPrescriptionPricing } from './getPricing';
import { type ReduxStateShared } from '~shared/types';

type PrescriptionIDParam = {
  prescriptionID: number | null;
};

const getPrescriptionID = (_state: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

export const getAvailableManualCoupon: (
  state: ReduxStateShared,
  prescriptionIDProp: PrescriptionIDParam,
) => Coupon | null | undefined = createSelector(
  getPrescriptionPricing,
  getPrescriptionID,
  (pricingObject, prescriptionID) => {
    return prescriptionID ? pricingObject[prescriptionID]?.available_manual_coupon : null;
  },
);
