import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { type StationType, type Status } from '../../types';
import { ActiveIndicator } from './ActiveIndicator';
import { CompletedIndicator } from './CompletedIndicator';
import { FailedIndicator } from './FailedIndicator';
import { SmallIndicator } from './SmallIndicator';

export const Indicator = ({ type, status }: { readonly type: StationType; readonly status: Status }) => {
  switch (type) {
    case 'active':
      if (status === 'completed') {
        return <CompletedIndicator />;
      }
      if (status === 'failed') {
        return <FailedIndicator />;
      }
      return <ActiveIndicator />;
    case 'completed':
      return <CompletedIndicator />;
    case 'failed':
      return <SmallIndicator color={COLORS.PALETTE.DANGER.DEFAULT} />;
    case 'inactive':
    default:
      return <SmallIndicator color={COLORS.PALETTE.GREYSCALE.DEFAULT} />;
  }
};
