import { type BACKGROUND_COLORS_VALUES, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import styled from 'styled-components/native';
import { Border } from '../../../borders';
import { Pressable } from '../../../buttons';
import { MdPadding } from '../../../containers';

type Props = {
  readonly children?: React.ReactNode;
  readonly hideTopBorder?: boolean;
  readonly hideBottomBorder?: boolean;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly pressedBackgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly onPress?: () => void;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
  readonly opacity?: boolean;
  readonly fullBleed?: boolean;
};

type ListItemWrapperProps = Pick<Props, 'backgroundColor' | 'fullBleed'>;

const ListItemWrapper = styled(MdPadding).attrs(({ fullBleed }: ListItemWrapperProps) => ({
  leftPadding: fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.LG,
  rightPadding: fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.LG,
  topPadding: fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.MD,
  bottomPadding: fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.MD,
}))<ListItemWrapperProps>`
  background-color: ${(props) => `${props.backgroundColor}`};
  flex-shrink: 1;
`;

export const ListItemBase = ({
  hideTopBorder,
  hideBottomBorder,
  children,
  backgroundColor,
  onPress,
  testID,
  pressedBackgroundColor,
  opacity = true,
  fullBleed,
}: Props) => {
  if (onPress) {
    return (
      <Border
        radius="NONE"
        hideTop={hideTopBorder}
        hideBottom={hideBottomBorder}
        hideLeft
        hideRight
      >
        <Pressable
          onPress={onPress}
          backgroundColor={backgroundColor}
          pressedBackgroundColor={pressedBackgroundColor}
          testID={testID}
          opacity={opacity}
        >
          {fullBleed ? (
            children
          ) : (
            <MdPadding
              leftPadding={SPACING.STATIC.LG}
              rightPadding={SPACING.STATIC.LG}
            >
              {children}
            </MdPadding>
          )}
        </Pressable>
      </Border>
    );
  }

  return (
    <Border
      radius="NONE"
      hideTop={hideTopBorder}
      hideBottom={hideBottomBorder}
      hideLeft
      hideRight
    >
      <ListItemWrapper
        backgroundColor={backgroundColor}
        fullBleed={fullBleed}
      >
        {children}
      </ListItemWrapper>
    </Border>
  );
};
