// @owners { team: patients-team }
import { VERIFICATION_COMMS_CHANNEL, type VerificationCommsChannel } from '~shared/actions/verifications';

export const VERIFICATION_TITLE = 'Verify Account';
export const RESEND_VERIFICATION_TITLE = 'Resend Code';

export const VERIFICATION_SINGLE_OPTION_BODY = (channel: VerificationCommsChannel, contact: string): string => {
  switch (channel) {
    case VERIFICATION_COMMS_CHANNEL.email:
      return `For your security, we’ll send a verification code by email to ${contact}.`;

    case VERIFICATION_COMMS_CHANNEL.sms:
      return `For your security, we’ll send a verification code by text/SMS to the phone number ending in ${contact}.`;

    case VERIFICATION_COMMS_CHANNEL.call:
      return `For your security, we’ll send a verification code by automated voice call to the phone number ending in ${contact}.`;

    default:
      return '';
  }
};

export const VERIFICATION_MULTIPLE_OPTIONS_BODY =
  'For your security, we’ll send a verification code to confirm your account. Please select a verification method:';

export const VERIFICATION_OPTIONS_TEXT = (channel: VerificationCommsChannel, contact: string): string => {
  switch (channel) {
    case VERIFICATION_COMMS_CHANNEL.email:
      return `Email to ${contact}`;

    case VERIFICATION_COMMS_CHANNEL.sms:
      return `Text/SMS to the phone number ending in ${contact}`;

    case VERIFICATION_COMMS_CHANNEL.call:
      return `Automated voice call to the phone number ending in ${contact}`;

    default:
      return '';
  }
};

export const VERIFICATION_CTA_TEXT = 'Send Code';
export const VERIFICATION_CTA_LOADING_TEXT = 'Sending Code...';

export const VERIFICATION_SENT_BODY = (
  channel: VerificationCommsChannel | null | undefined,
  contact: string,
): string => {
  switch (channel) {
    case VERIFICATION_COMMS_CHANNEL.email:
      return `For your security, we’ve sent a verification code to ${contact}. Please enter the 6 digit code below.`;

    case VERIFICATION_COMMS_CHANNEL.sms:
      return `For your security, we’ve sent a verification code by text/SMS to the phone number ending in ${contact}. Please enter the 6 digit code below.`;

    case VERIFICATION_COMMS_CHANNEL.call:
      return `For your security, we’ve sent a verification code by automated voice call to the phone number ending in ${contact}. Please enter the 6 digit code below.`;

    default:
      return '';
  }
};
