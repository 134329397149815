// @owners { team: patients-team }
import React, { PureComponent } from 'react';
import { push } from 'react-router-redux';
import { logout } from '~shared/actions/auth';
import { setSelectedVideo } from '~shared/actions/instructionalVideos';
import { type ModalType, openModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers } from '~shared/actions/users';
import { getFamilyMembers } from '~shared/features/family-accounts/selectors/getFamilyMembers';
import { getIsVideosEnabled } from '~shared/features/instructional-videos/selectors/getIsVideosEnabled';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared, type User } from '~shared/types';
import Profile from './Profile';
import { configureStore } from '~web/configureStore';

type Props = {
  readonly fetchUsers: () => void;
  readonly isFamilyMember: boolean;
  readonly logout: () => void;
  readonly navigate: (route: string) => void;
  readonly navigateAndGenerateAnalyticsEvent: (route: string, event: Event) => void;
  readonly openModal: (name: ModalType) => void;
  readonly users: User[];
  readonly showFertVideosLink: boolean;
};

class ProfileContainer extends PureComponent<Props> {
  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  render() {
    const {
      isFamilyMember,
      logout,
      openModal,
      navigate,
      navigateAndGenerateAnalyticsEvent,
      users,
      showFertVideosLink,
    } = this.props;

    return (
      <Profile
        isFamilyMember={isFamilyMember}
        logout={logout}
        openModal={openModal}
        navigate={navigate}
        navigateAndGenerateAnalyticsEvent={navigateAndGenerateAnalyticsEvent}
        users={users}
        showFertVideosLink={showFertVideosLink}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStateShared) => {
  const users = getFamilyMembers(state);
  const user = getCurrentUser(state);
  const showFertVideosLink = getIsVideosEnabled(state);
  return {
    users,
    showFertVideosLink,
    isFamilyMember: user?.is_family_member ?? false,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    fetchUsers: () => {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchUsers());
    },
    logout: () => {
      dispatch(sendAnalyticsEvent(createEvent(EVENTS.LOGOUT_CLICKED)));
      // eslint-disable-next-line promise/catch-or-return
      dispatch(logout()).then((success) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          dispatch(push('/'));
        }
      });
      configureStore().persistor.purge();
    },
    // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
    navigate: (url) => {
      dispatch(push(url));
    },
    // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
    navigateAndGenerateAnalyticsEvent: (url, event) => {
      // selectedVideo needs to be cleared here to avoid pre-setting the value of the search bar
      if (url === '/me/fertility-videos') {
        dispatch(setSelectedVideo(null));
      }

      dispatch(sendAnalyticsEvent(event));
      dispatch(
        push({
          pathname: url,
          state: {
            origin: ORIGIN_NAMES.PROFILE,
          },
        }),
      );
    },
    openModal: (modalType: ModalType) => {
      dispatch(openModal(modalType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
