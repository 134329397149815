import { browserHistory } from 'react-router';
import { type RouteParams } from './RouteParams';

/**
 * @deprecated We should never be using this on web.
 */
export function setTopLevelNavigator() {
  return;
}

export function getCurrentRouteName(): keyof RouteParams | undefined {
  return browserHistory.getCurrentLocation()?.pathname as keyof RouteParams | undefined;
}

/**
 * @deprecated We should never be using this on web.
 */
export function getCurrentRouteNames(): string[] {
  return [];
}

export function goBack() {
  return;
}

export function navigate(location: Record<string, unknown>) {
  return location;
}
