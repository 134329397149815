import { COLORS, SIZES, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { Card, LgPadding, useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { H3 as H3Pocky, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import expeditedDelivery from '~web/images/deliveryNoBackground.png';
import pillBottle from '~web/images/pillBottleBackground.png';
import lowPrice from '~web/images/priceInvestigate@3x.png';
import chatPharmacist from '~web/images/secureMessagingNoBackground.png';

const H3 = styled(H3Pocky)`
  margin-bottom: ${SPACING.STATIC.XS.px};
`;
const Div = styled.div<{ isMDScreenOrBigger: boolean }>`
  display: none;
  z-index: 0;

  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `display: initial;`}
`;

const CustomCard = styledNative(Card)`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;
const CardTextContainer = styled.div`
  margin-left: ${SPACING.STATIC.MD.px};
  * {
    color: ${COLORS.TEXT_COLORS.PRIMARY};
  }
  h3 {
    font-size: ${TYPOGRAPHY.TEXT.HEADER.SM.px};
  }
`;
const FeaturesContainer = styled.div`
  display: flex;
`;
const Feature = styled.div`
  padding: ${SPACING.STATIC.MD.px};
  display: flex;
  align-items: center;
  flex-basis: 33.33%;
  position: relative;
  &:not(:first-child) {
    &::before {
      position: absolute;
      content: '';
      border-left: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
      height: ${SIZES.ILLUSTRATION.SM.px};
      left: 0;
      top: calc(50% - ${SIZES.ILLUSTRATION.SM.value / 2}px);
    }
  }
`;
const FeatureText = styled(Text)`
  color: ${COLORS.PALETTE.PRIMARY.DEFAULT};
`;
const Illustration = styled.img<{ width?: string }>`
  width: ${({ width }) => width || SIZES.ILLUSTRATION.MD.px};
  margin-right: ${SPACING.STATIC.XS.px};
`;

export default function NoNewUpdates() {
  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <Div isMDScreenOrBigger={isMDScreenOrBigger}>
      <CustomCard>
        <LgPadding>
          <Illustration
            src={pillBottle}
            width="120px"
          />
          <CardTextContainer>
            <H3>No new updates</H3>
            <Text>Any new scheduled deliveries, new medications, and refill requests will show up here.</Text>
          </CardTextContainer>
        </LgPadding>
      </CustomCard>
      <FeaturesContainer>
        <Feature>
          <Illustration src={lowPrice} />
          <FeatureText>Low prices</FeatureText>
        </Feature>
        <Feature>
          <Illustration src={expeditedDelivery} />
          <FeatureText>Delivery to your door</FeatureText>
        </Feature>
        <Feature>
          <Illustration src={chatPharmacist} />
          <FeatureText>Chat with a pharmacist</FeatureText>
        </Feature>
      </FeaturesContainer>
    </Div>
  );
}
