// @owners { team: patients-team }
import { connect } from '~shared/store';
import OnboardingModal from './OnboardingModal';

// @ts-expect-error TS(7031): Binding element 'currentStep' implicitly has an 'any' type.
const mapStateToProps = ({ modal: { currentStep, show, modalType } }) => {
  return {
    currentStep,
    show,
    modalType,
  };
};

export default connect(mapStateToProps)(OnboardingModal);
