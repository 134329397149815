// @owners { team: patients-team }
import { type ActionSetSelectedVideo, SET_SELECTED_VIDEO } from '~shared/actions/instructionalVideos';
import { type Video } from '~shared/features/instructional-videos/types';

// Selected video controls two features:
// 1. The content of the PrepInfoModal
// 2. The pre-set value of the search term when on viewing the Instructional Videos page.
// Make sure to clear this value if you are navigating to the Instructional Videos page and don't want the search bar pre-set.
export type InstructionalVideosState = {
  selectedVideo: Video | null | undefined;
};

type Action = ActionSetSelectedVideo;

const initialState: InstructionalVideosState = {
  selectedVideo: null,
};

export default function instructionalVideos(
  state: InstructionalVideosState = initialState,
  action: Action,
): InstructionalVideosState {
  switch (action.type) {
    case SET_SELECTED_VIDEO:
      return { ...state, selectedVideo: action.payload };

    default:
      return state;
  }
}
