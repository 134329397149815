// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { type DeliveryWithPrescription } from '~shared/features/delivery-info/types';
import { getPrescriptionsHash } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { type ShipmentIDParamLong } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

type DeliveriesByShipment = Record<number, DeliveryWithPrescription[]>;

export const getDeliveriesByShipment = createSelector(
  [getDeliveries, getPrescriptionsHash],
  (deliveries, prescriptionHash) => {
    const deliveriesByShipment: DeliveriesByShipment = {};
    deliveries.forEach((d) => {
      if (!d.shipment_id) {
        return;
      }

      const prescription = prescriptionHash[d.prescription_id];

      deliveriesByShipment[d.shipment_id] = deliveriesByShipment[d.shipment_id] || [];

      if (prescription) {
        deliveriesByShipment[d.shipment_id].push({ ...d, prescription });
      }
    });

    return deliveriesByShipment;
  },
);

const getShipmentID = (_: ReduxStateShared, props: ShipmentIDParamLong) => props.shipmentID;

const getDeliveriesForShipmentID = createSelector(
  [getDeliveriesByShipment, getShipmentID],
  (deliveries, shipmentID) => (shipmentID && deliveries[shipmentID]) || [],
);

export default getDeliveriesForShipmentID;
