// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  Description,
  InlineAlert,
  LgPadding,
  SmSpacing,
  Toast,
  ToastContext,
  XxsSpacing,
} from '@alto/design-system';
import React, { useContext, useEffect, useState } from 'react';
import { updateAutoRefill } from '~shared/actions/prescriptions';
import { openCart } from '~shared/actions/ui/cart';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';
import { getIsCartShowing } from '~shared/selectors/ui/cart';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { AutoRefillInfoButton, AutoRefillRadioOptions } from '../../../../index';
import { useAddToCart } from '../../../cart/hooks/useAddToCart';
import { useUpdateCartItem } from '../../../cart/hooks/useUpdateCartItem';
import { useInvalidateMedDetails } from '../../../med-details/queries/useMedDetails';
import { ORDER_TYPE_CONTEXT, type OrderTypeContext } from '../../constants';

type OrderTypeActionSheetProps = {
  readonly handleBack?: () => void;
  readonly prescriptionID: number;
  // true: if auto refill should be initially selected,
  // false: if one-time fill should be initially selected,
  // undefined / null: if neither should be selected.
  readonly initialAutoRefillEnabledValue: boolean | undefined | null;
  readonly orderTypeContext: OrderTypeContext;
};

export const OrderTypeActionSheet = ({
  handleBack,
  prescriptionID,
  orderTypeContext,
  initialAutoRefillEnabledValue,
}: OrderTypeActionSheetProps) => {
  const { invalidatePrescriptionsQuery, invalidateMedicationQuery } = useInvalidateMedDetails();
  const dispatch = useDispatchShared();
  const [autoRefillEnabled, setAutoRefillEnabled] = useState(initialAutoRefillEnabledValue);
  const { addToast } = useContext(ToastContext);
  const { handleAddToCart, isPending: addToCartLoading } = useAddToCart();
  const { handleUpdateCartItem, isPending: updateCartItemLoading } = useUpdateCartItem();
  const { goToPreviousActionSheet } = useContext(ActionSheetContext);
  const { updateAutoRefillLoading } = useSelectorShared(getLoading);
  const isCartShowing = useSelectorShared(getIsCartShowing);
  const { trackPageView, trackEvent } = useAnalytics();
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const [radioOptionsError, setRadioOptionsError] = useState(false);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ORDER_TYPE_SCREEN_VIEWED,
      params: {
        context: orderTypeContext,
      },
      additionalFields: {
        prescriptionId: prescriptionID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!prescription) {
    return null;
  }
  const handleGoToPreviousActionSheet = () => {
    goToPreviousActionSheet();
    handleBack?.();
  };

  const updateServerAutoRefillStatus = (newAutoRefillStatus: boolean) => {
    return dispatch(updateAutoRefill(prescription.id, newAutoRefillStatus));
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleOrderTypeSave = async () => {
    if (autoRefillEnabled === undefined || autoRefillEnabled === null) {
      setRadioOptionsError(true);
      return;
    }

    let success = false;
    if (orderTypeContext === ORDER_TYPE_CONTEXT.CART) {
      const res = await handleUpdateCartItem({
        resource_id: prescription.id,
        resource_type: 'Prescription',
        auto_refill_enabled: autoRefillEnabled,
      });
      success = res.success;
      if (!success) {
        addToast(<Toast variant="error">There was an error updating the order type. Please try again later.</Toast>);
      }
    } else if (orderTypeContext === ORDER_TYPE_CONTEXT.ADD_TO_CART) {
      const res = await handleAddToCart({
        resource_id: prescription.id,
        resource_type: 'Prescription',
        auto_refill_enabled: autoRefillEnabled,
      });
      success = res.success;
      if (!success) {
        addToast(<Toast variant="error">There was an error adding to your cart. Please try again later.</Toast>);
      } else {
        if (!isCartShowing) {
          dispatch(openCart());
        }
      }
    } else if (orderTypeContext === ORDER_TYPE_CONTEXT.POST_CHECKOUT) {
      success = await updateServerAutoRefillStatus(!!autoRefillEnabled);
      invalidatePrescriptionsQuery(prescriptionID);
      invalidateMedicationQuery(prescriptionID);

      if (!success) {
        addToast(<Toast variant="error">There was an error updating the order type. Please try again later.</Toast>);
      }
    }

    if (success) {
      trackEvent({
        event: EVENTS.AUTO_REFILL_UI_STATUS_CHANGED,
        params: { origin: getOrigin(), auto_refill_status: autoRefillEnabled ? 'auto refill' : 'one-time fill' },
        additionalFields: { prescriptionId: prescriptionID },
      });
      goToPreviousActionSheet();
    }
  };

  return (
    <>
      <ActionSheetV2
        onBack={handleBack}
        title={`Select order type for ${prescription.medication_name}`}
        buttons={[
          <Button
            testID="order-type-primary-button"
            key="order-type-primary-button"
            label={orderTypeContext === ORDER_TYPE_CONTEXT.ADD_TO_CART ? 'Add to cart' : 'Save'}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={handleOrderTypeSave}
            loading={addToCartLoading || updateCartItemLoading}
          />,
          <Button
            key="order-type-cancel-button"
            type="tertiary"
            label="Cancel"
            onPress={handleGoToPreviousActionSheet}
            disabled={updateAutoRefillLoading}
            loading={updateAutoRefillLoading || addToCartLoading}
          />,
        ]}
        analyticsName="auto refill order type"
      >
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.MD}
        >
          <Description testID="order-type-actionsheet-description">This med is eligible for auto refill!</Description>
          <XxsSpacing />
          <AutoRefillInfoButton origin={`${orderTypeContext.toLowerCase()} order type`} />
          {radioOptionsError ? (
            <>
              <SmSpacing />
              <InlineAlert type="error">
                <Description>Please select an order type</Description>
              </InlineAlert>
            </>
          ) : null}
        </LgPadding>
        <AutoRefillRadioOptions
          handleOnChange={(value) => {
            setAutoRefillEnabled(value);
            setRadioOptionsError(false);
          }}
          autoRefillEnabled={autoRefillEnabled}
          prescriptionID={prescription.id}
        />
      </ActionSheetV2>
    </>
  );
};
