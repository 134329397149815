// @owners { team: patients-team }
import { Link } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-deprecated
import fetchQuantityOptions from '~shared/actions/quantityOptions';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared } from '~shared/store';

type Props = {
  readonly prescriptionID: number;
  readonly origin: (typeof ORIGIN_NAMES)[keyof typeof ORIGIN_NAMES];
};

export const QuantityChangeLink = ({ prescriptionID, origin }: Props) => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { navigate } = useNavigation();

  const onPress = useCallback(() => {
    trackEvent({
      event: EVENTS.QUANTITY_CHANGE_INITIATED,
      params: { origin },
      additionalFields: { prescriptionId: prescriptionID },
    });
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchQuantityOptions(prescriptionID));
    navigate('RouteChangeQuantity', { prescriptionID });
  }, [dispatch, navigate, origin, prescriptionID, trackEvent]);

  return (
    <Link
      textSize="mini"
      fontFamily="regular"
      onPress={onPress}
    >
      Change
    </Link>
  );
};
