// @owners { team: patients-team }
import { Button } from '@alto/design-system';
import React, { useCallback } from 'react';
import { push } from 'react-router-redux';
import { openCart } from '~shared/actions/ui/cart';
import { useCartEssentials } from '~shared/features/essentials';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const EditCartButton = () => {
  const dispatch = useDispatchShared();
  const hideEditCart = useSelectorShared(getIsEditingExistingOrder);
  const { hasEssentials, hasNonEssentials } = useCartEssentials();
  const shouldOpenStore = hasEssentials && !hasNonEssentials;

  const handleEditCart = useCallback(() => {
    if (shouldOpenStore) {
      dispatch(push('/store'));
      return;
    }
    dispatch(push({ pathname: '/prescriptions' }));
    dispatch(openCart());
  }, [dispatch, shouldOpenStore]);

  if (hideEditCart) return null;
  return (
    <Button
      type="secondary"
      onPress={handleEditCart}
    >
      Edit cart
    </Button>
  );
};
