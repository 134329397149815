import { useEffect, useState } from 'react';
import { Keyboard, type KeyboardEvent } from 'react-native';

/**
 * Returns the on-screen height of the keyboard.
 *
 * Generally, it is preferred to use `<KeyboardAvoidingView />` or `<KeyboardAvoidingScrollView />`
 * to consider the presence of the keyboard for displayed content, but this hook can be utilized
 * when use of those components is not possible or for other scenarios that are concerned with
 * keyboard height.
 */
export const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  useEffect(() => {
    const keyboardShown = (event: KeyboardEvent) => {
      setKeyboardHeight(event.endCoordinates.height);
    };
    const keyboardHidden = () => {
      setKeyboardHeight(0);
    };

    const willShowListener = Keyboard.addListener('keyboardWillShow', keyboardShown);
    const willHideListener = Keyboard.addListener('keyboardWillHide', keyboardHidden);

    return () => {
      willShowListener?.remove();
      willHideListener?.remove();
    };
  }, []);

  return keyboardHeight;
};
