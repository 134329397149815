export const ReactNativeLocalize = {
  getLocales: () => [
    {
      languageCode: '',
      scriptCode: undefined,
      countryCode: '',
      languageTag: '',
      isRTL: false,
    },
  ],
  getCountry: () => '',
  getTimeZone: () => '',
};
