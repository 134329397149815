import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Text, spacing } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { useOrderSuccessViewed } from '~shared/lib/analytics/src/hooks/useOrderSuccessViewed';
import HeaderImage, { LARGE_SIZE } from '~web/components/HeaderImage';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import { OnboardingScreenButtonWrapper } from '~web/features/onboarding/components/redesign/OnboardingScreenButtonWrapper';
import delivery from '~web/images/delivery@2x.png';

const AdjustedPageBackground = styled(PageBackground)<{ white?: boolean; isLGScreenOrBigger: boolean }>`
  padding-top: 80px;
  padding-bottom: ${spacing.xlSpacing};
  ${({ isLGScreenOrBigger }) => !isLGScreenOrBigger && `padding-top: 80px; padding-bottom: ${spacing.xlSpacing};`}
`;

type Props = {
  readonly navigateToNextRoute: () => void;
};

const OrderSuccess = ({ navigateToNextRoute }: Props) => {
  useOrderSuccessViewed();
  const { isLGScreenOrBigger } = useScreenSize();
  return (
    <AdjustedPageBackground
      isLGScreenOrBigger={isLGScreenOrBigger}
      white
    >
      <SingleColumnContentWrapper>
        <div>
          <HeaderImage
            src={delivery}
            alt={IMG_ALT_TEXTS.delivery}
            title="Order scheduled!"
            imgSize={LARGE_SIZE}
          />
          <Text center>Your delivery has been scheduled. We’ll be sure to remind you when it’s on the way.</Text>
        </div>
        <OnboardingScreenButtonWrapper horizontallyAlignContent>
          <Button
            kind="primary"
            onClick={navigateToNextRoute}
          >
            View Delivery
          </Button>
        </OnboardingScreenButtonWrapper>
      </SingleColumnContentWrapper>
    </AdjustedPageBackground>
  );
};

export default OrderSuccess;
