// @owners { team: patients-team }
import {
  type UpdateShipmentTipRequest as Request,
  type UpdateShipmentTipResponse as Response,
} from '@alto/scriptdash/alto/patients/v3/courier_tips/wunderbar/v1/shipment_tips_endpoint';
import api from '~shared/features/courier-tips/api';
import { type ReduxDispatch } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const UPDATE_SHIPMENT_TIP_LOADING = 'UPDATE_SHIPMENT_TIP_LOADING';
export const UPDATE_SHIPMENT_TIP_SUCCEEDED = 'UPDATE_SHIPMENT_TIP_SUCCEEDED';
export const UPDATE_SHIPMENT_TIP_FAILED = 'UPDATE_SHIPMENT_TIP_FAILED';

type ResponseData = Response['data'];
type ParsedResponse = {
  data: ResponseData;
  error: APIError;
};

export type UpdateShipmentTipLoadingAction = {
  type: typeof UPDATE_SHIPMENT_TIP_LOADING;
};
export function updateShipmentTipLoading(): UpdateShipmentTipLoadingAction {
  return {
    type: UPDATE_SHIPMENT_TIP_LOADING,
  };
}

export type UpdateShipmentTipSucceededAction = {
  type: typeof UPDATE_SHIPMENT_TIP_SUCCEEDED;
  payload: ResponseData;
};
export function updateShipmentTipSucceeded(payload: ResponseData): UpdateShipmentTipSucceededAction {
  return {
    type: UPDATE_SHIPMENT_TIP_SUCCEEDED,
    payload,
  };
}

export type UpdateShipmentTipFailedAction = {
  type: typeof UPDATE_SHIPMENT_TIP_FAILED;
  payload: APIError;
};
export function updateShipmentTipFailed(payload: APIError): UpdateShipmentTipFailedAction {
  return {
    type: UPDATE_SHIPMENT_TIP_FAILED,
    payload,
  };
}

export type UpdateShipmentTipActions =
  | UpdateShipmentTipLoadingAction
  | UpdateShipmentTipSucceededAction
  | UpdateShipmentTipFailedAction;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function updateShipmentTip(params: Request) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(updateShipmentTipLoading());

    try {
      // @ts-expect-error TS(2741): Property 'error' is missing in type 'UpdateShipmentTipResponse' but required in type 'ParsedResponse... (Delete me to see the full error)
      const { data, error }: ParsedResponse = await api.updateShipmentTip(params);

      // NOTE: Failed API responses are not rejected, so we have to throw here
      if (error) {
        throw error;
      }

      dispatch(updateShipmentTipSucceeded(data));
      return data;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to parameter of type 'APIError'.
      dispatch(updateShipmentTipFailed(error));
      throw error;
    }
  };
}
