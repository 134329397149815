export const unauthenticatedRoutes = [
  '/',
  '/login',
  '/signup',
  '/forgot_password',
  '/reset_password',
  '/delivery_confirmed',
  '/contact_requested',
  '/waitlist_confirmed',
  '/communications/email/not_me',
  '/communications/phone/not_me',
  '/communications/verify_identity/email',
  '/communications/verify_identity/phone',
  '/communications/verify_identity/email/success',
  '/communications/verify_identity/email/fail',
  '/communications/verify_identity/phone/success',
  '/communications/verify_identity/phone/fail',
  '/patient-onboarding',
];

export const unauthenticatedApis = ['start', 'onboard', 'status', 'survey', 'subscriptions', 'rate-shipment'];
