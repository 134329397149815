// @owners { team: patients-team }
import { Body, LgSpacing, LoadingEmptyState, MdSpacing, XlSpacing, XxlPadding } from '@alto/design-system';
import { type SupportCase } from '@alto/scriptdash/alto/customer_support/types/v1/support_case';
import React, { useCallback, useEffect } from 'react';
import { FlatList, type ListRenderItemInfo } from 'react-native';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getUnreadSupportCaseCount, useGetReadOnlyWundercoms, useGetSupportCases } from '../../queries/queries';
import { MessageHistory } from './MessageHistory';
import { SupportCaseCard } from './SupportCaseCard';

export const Inbox = () => {
  const { supportCases, fetchNextPage, hasNextPage, isPending } = useGetSupportCases();
  const { trackEvent } = useAnalytics();
  const {
    data: readOnlyWundercoms,
    fetchStatus,
    unreadMessageCount,
    isPending: isWundercomsPending,
  } = useGetReadOnlyWundercoms();
  const unreadSupportCaseCount = getUnreadSupportCaseCount(supportCases);

  const handleFetchMore = useCallback(async () => {
    if (hasNextPage && !isPending) {
      trackEvent({
        event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
        params: {
          name: ASSISTANT_VIEWED_EVENTS.FETCH_MORE_SUPPORT_CASES,
          unreadMessageCount,
          unreadSupportCaseCount,
        },
      });
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isPending, trackEvent, unreadMessageCount, unreadSupportCaseCount]);

  const renderSupportCase = useCallback((item: ListRenderItemInfo<SupportCase>) => {
    return (
      <React.Fragment key={`support-case-${item.item.id}}`}>
        <SupportCaseCard supportCase={item.item} />
        <MdSpacing />
      </React.Fragment>
    );
  }, []);

  useEffect(() => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: {
        name: ASSISTANT_VIEWED_EVENTS.SUPPORT_CASE_INBOX,
        unreadMessageCount,
        unreadSupportCaseCount,
      },
    });
  }, [trackEvent, unreadMessageCount, unreadSupportCaseCount]);

  if (isPending || (isWundercomsPending && fetchStatus !== 'idle')) {
    return (
      <>
        <LgSpacing />
        <XxlPadding>
          <LoadingEmptyState />
        </XxlPadding>
      </>
    );
  }

  if (readOnlyWundercoms.length === 0 && supportCases.length === 0) {
    return (
      <>
        <LgSpacing />
        <XxlPadding>
          <Body center>All messages you have with Alto show up here</Body>
        </XxlPadding>
      </>
    );
  }

  return (
    <FlatList
      nestedScrollEnabled
      data={supportCases}
      renderItem={renderSupportCase}
      scrollEnabled={false}
      keyExtractor={(item) => item.id.toString()}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onEndReached={handleFetchMore}
      onEndReachedThreshold={0.1}
      ListHeaderComponent={
        unreadMessageCount > 0 ? (
          <>
            <MessageHistory />
            <MdSpacing />
          </>
        ) : null
      }
      ListFooterComponent={
        <>
          {unreadMessageCount === 0 && readOnlyWundercoms.length > 0 ? <MessageHistory /> : null}
          <XlSpacing />
        </>
      }
    />
  );
};
