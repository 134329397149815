// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Button,
  Description,
  InlineAlert,
  Link,
  LoadingEmptyState,
  MdPadding,
  SecondaryPage,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_DELIVERY_ACTIONS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useOrderPricing } from '~shared/queries';
import { useSelectorShared } from '~shared/store';
import { PricingBreakdownCard, useNavigateToAssistantLanding, useShipment } from '../../../../../index';

const PriceDetailsCard = ({ navigateToMessageForm }: { readonly navigateToMessageForm: () => void }) => {
  const { shipmentID } = useShipment();
  const { isError, isPending, orderPricing } = useOrderPricing({ shipmentID });
  const prescriptions = useSelectorShared((state) => getPrescriptionsForShipment(state, { shipmentID }));

  if (isPending) return <LoadingEmptyState />;
  if (isError) {
    return (
      <InlineAlert type="error">
        <MdPadding>
          <Description>Looks like we're having some technical difficulty.</Description>
          <Link
            onPress={navigateToMessageForm}
            textSize="mini"
          >
            Send us a message
          </Link>
        </MdPadding>
      </InlineAlert>
    );
  }

  return (
    <PricingBreakdownCard
      prescriptions={prescriptions}
      orderPricing={orderPricing}
      origin="UpcomingDeliveryPriceDetails"
    />
  );
};

export const PriceDetails = () => {
  const { navigate, goBack } = useNavigation();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();
  const { restoreStack } = useContext(ActionSheetContext);

  const onDismiss = () => {
    navigateToAssistantLanding();
  };

  const navigateToMessageForm = () => {
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_DELIVERY_ACTION_MENU,
      category: `${ASSISTANT_CATEGORIES.DELIVERIES}.${ASSISTANT_DELIVERY_ACTIONS.PRICING_QUESTION}`,
      messagePrompt: MESSAGE_PROMPTS.HELP_WITH_PRICING_PAYMENTS,
      subject: ASSISTANT_MESSAGE_SUBJECTS.PRICING_PAYMENT,
    });
  };

  return (
    <SecondaryPage
      NavBarCenterContent="Price details"
      onDismiss={() => {
        goBack();
        if (getPlatformOS() !== 'web') {
          restoreStack<ActionSheetProviderStackCacheKey>('assistant-price-details');
        }
      }}
      dismissIcon={'chevronleft'}
      HeaderContent={
        <MdPadding>
          <Description center>Here's the price details of each item in your upcoming delivery</Description>
        </MdPadding>
      }
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      buttons={[
        <Button
          label="This answered my question"
          key="This answered my question"
          accessibilityLabel="This answered my question"
          onPress={onDismiss}
        />,
        <Button
          label="I still need help"
          key="I still need help"
          accessibilityLabel="I still need help"
          type="tertiary"
          onPress={navigateToMessageForm}
        />,
      ]}
    >
      <PriceDetailsCard navigateToMessageForm={navigateToMessageForm} />
    </SecondaryPage>
  );
};
