// @owners { team: patients-team }
import { Card, LgPadding } from '@alto/design-system';
import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { type OrderPricing } from '~shared/features/pricing/types';
import { type OrderPricingQueryData } from '~shared/queries/useOrderPricing';
import { type Prescription } from '~shared/types';
import { useTrackPriceBreakdownViewed } from '../hooks/useTrackPriceBreakdownViewed';
import { PaymentBreakdown } from './PaymentBreakdown';

type Props = {
  readonly origin: string;
  readonly prescriptions: Prescription[];
  readonly orderPricing?: OrderPricing | OrderPricingQueryData;
};

export const PricingBreakdownCard = ({ origin, orderPricing, prescriptions }: Props) => {
  // key to include in analytics event to enable querying 'item viewed' events for given 'payment breakdown viewed' event
  const eventUuid = useMemo(uuid, [prescriptions, orderPricing]);

  useTrackPriceBreakdownViewed({
    lineItems: orderPricing?.line_items,
    numberOfRxs: prescriptions.length,
    origin,
    breakdownKey: eventUuid,
  });

  return (
    <Card>
      <LgPadding>
        <PaymentBreakdown
          orderPricing={orderPricing}
          prescriptions={prescriptions}
          origin={origin}
          eventUuid={eventUuid}
        />
      </LgPadding>
    </Card>
  );
};
