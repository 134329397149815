import { type ErrorObject } from '@alto/core/types/v1/error_object';
import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';

export type ApplyManualCouponError = {
  error: ErrorObject;
  prescriptionID: number;
  insuranceID: number;
};

export type ErrorsByPrescriptionID = Record<number, ApplyManualCouponError>; // [Prescription.id]: ApplyManualCouponError

export const getErrorsByPrescription = (state: ReduxStateShared): ErrorsByPrescriptionID =>
  // @ts-expect-error TS(2339): Property 'ui' does not exist on type 'State'.
  state.ui.errors.applyManualCouponErrorByID;

// When we submit + bill a coupon via the ApplyCouponFormModal, we attempt to bill all other prescriptions that
// the coupon is applicable to. This operation could fail for any prescription, and we need to know which prescription fails
// so that we can raise a support request for the appropriate delivery
export const getErrorForPrescriptionID: (
  prescriptionID: number,
) => (arg0: ReduxStateShared) => ApplyManualCouponError | null = (prescriptionID) =>
  createSelector([getErrorsByPrescription], (errorsByPrescription: ErrorsByPrescriptionID) => {
    return errorsByPrescription ? errorsByPrescription[prescriptionID] || null : null;
  });
