// @owners { team: patients-team }

import {
  SET_PROMO_DISMISSED,
  SET_PROMO_VIEWED,
  type SetPromoDismissed,
  type SetPromoViewed,
} from '~shared/actions/ui/promos';
import { withRehydration } from '~shared/reducers/withRehydration';

export type StateUiPromos = {
  viewedPromos: Partial<
    Record<
      number,
      {
        currentImpressionCount: number;
      }
    >
  >;
};

export const initialState: StateUiPromos = {
  viewedPromos: {},
};

type Action = SetPromoViewed | SetPromoDismissed;

const promos = (state: StateUiPromos = initialState, action: Action): StateUiPromos => {
  const { type } = action;

  switch (type) {
    case SET_PROMO_VIEWED: {
      const {
        payload: { promoID },
      } = action;
      const curCount = state.viewedPromos[promoID]?.currentImpressionCount ?? 0;
      return {
        ...state,
        viewedPromos: {
          ...state.viewedPromos,
          [promoID]: { currentImpressionCount: curCount + 1 },
        },
      };
    }

    case SET_PROMO_DISMISSED: {
      const {
        payload: { promoID, maxImpressionCount },
      } = action;
      return {
        ...state,
        viewedPromos: {
          ...state.viewedPromos,
          [promoID]: { currentImpressionCount: maxImpressionCount },
        },
      };
    }

    default:
      return state;
  }
};

export default withRehydration(promos, initialState);
