// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { AltoIcon, InputRadio, Tag } from '@alto/design-system';
import {
  type FulfillmentOption,
  type FulfillmentWindowOption,
} from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import React from 'react';
import { FeeRadioRightContent } from '../select-date-card/FeeRadioRightContent';
import { OptionDescription } from './OptionDescription';
import { getDateKey, getFeeLabels } from './utils';

type Props = {
  fulfillmentOption: FulfillmentOption;
  disableSelector: boolean;
  loading: boolean;
  selectedWindowOption?: FulfillmentWindowOption;
  deliveryMethod?: DeliveryMethod;
  checkedValue?: string | null; // used to show fee labels with selected fulfillment window option
};

export const FulfillmentOptionRadioOption = ({
  fulfillmentOption,
  disableSelector,
  loading,
  selectedWindowOption,
  deliveryMethod,
  checkedValue,
}: Props) => {
  const { date, label, tag, pickup_data } = fulfillmentOption;
  const isPickup = !!pickup_data;
  const dateKey = getDateKey({ date, isPickup, tag });
  let testID = dateKey;

  if (label.includes('Today') && !isPickup) {
    testID = 'same-day-delivery';
  } else if (label.includes('Tomorrow') && !isPickup) {
    testID = 'next-day-delivery';
  } else if (isPickup) {
    testID = 'self-scheduled-pickup';
  }

  const isDateOptionSelected = checkedValue === dateKey;
  const { feeAmountLabel, feeOriginalAmountLabel } = getFeeLabels({
    fulfillmentOption,
    selectedWindowOption,
    isDateOptionSelected,
  });

  return (
    <React.Fragment>
      <InputRadio
        disabled={disableSelector}
        radioPlacement="left"
        label={label}
        value={dateKey}
        testID={testID}
        descriptions={
          <OptionDescription
            deliveryMethod={deliveryMethod}
            selectedWindowOption={selectedWindowOption}
            fulfillmentOption={fulfillmentOption}
          />
        }
        tag={
          tag === 'BUNDLED' ? (
            <Tag
              icon={<AltoIcon name="box-small" />}
              type="success"
              key="bundled"
              label="Add to upcoming delivery"
            />
          ) : undefined
        }
        RightContent={
          <FeeRadioRightContent
            feeAmountLabel={feeAmountLabel}
            feeOriginalAmountLabel={feeOriginalAmountLabel}
            loading={loading}
          />
        }
      />
    </React.Fragment>
  );
};
