import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Pressable, Tag } from '@alto/design-system';
import { BackorderedTag, useBackorderedMedication } from '@alto/features';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type SourcePrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/source_prescription';
import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import React from 'react';
import {
  type ConstantStatusTypes,
  getStationTitleAndDescription,
} from '~shared/features/prescription-tracker/constants';
import { getIsCurrentUserProgyny } from '~shared/features/users/selectors/getIsProgynyUser';
import { useSelectorShared } from '~shared/store';
import AddToCart from '~web/features/homescreen/components/AddToCartContainer';

type Props = {
  readonly prescription: SourcePrescription | Prescription;
  readonly fullWidthButton?: boolean;
  readonly openPrescriptionTrackerModal?: () => void;
};

export const MedicationCTA = ({ prescription, fullWidthButton, openPrescriptionTrackerModal }: Props) => {
  const { status } = prescription;
  const isProgyny = useSelectorShared(getIsCurrentUserProgyny);

  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'SourcePrescription | Prescription'.
  const prescriptionID = prescription.id;
  const isBackorderdMedication = useBackorderedMedication(prescriptionID);

  if (isBackorderdMedication) {
    return (
      <BackorderedTag
        origin="Home"
        prescriptionId={prescriptionID}
      />
    );
  }

  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'SourcePrescription | Prescription'.
  if (typeof prescription.id === 'number') {
    // If the prescription id is present, we know this prescription is not of type SourcePrescription,
    // and can refine its type to be Prescription
    const typed_prescription = prescription as Prescription;

    if (status === PrescriptionStatusMap.READY || status === PrescriptionStatusMap.MULTIPLE_PRICES) {
      return (
        <AddToCart
          prescription={typed_prescription}
          fullWidthButton={fullWidthButton}
        />
      );
    }

    if (status === PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE) {
      return (
        <AddToCart
          prescription={typed_prescription}
          fullWidthButton={fullWidthButton}
          isProgynyOrderEarlyEligible
        />
      );
    }

    if (status === PrescriptionStatusMap.BACKORDERED || status === PrescriptionStatusMap.OBSOLETED) {
      return null;
    }
  }

  const otherStatuses = status as ConstantStatusTypes;
  const title = getStationTitleAndDescription[otherStatuses]?.title?.('present', isProgyny);

  if (openPrescriptionTrackerModal) {
    return (
      <Pressable
        backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
        onPress={openPrescriptionTrackerModal}
      >
        <Tag
          icon={<AltoIcon name="chevrondown-small" />}
          label={title}
          right
        />
      </Pressable>
    );
  }

  return (
    <Tag
      icon={<AltoIcon name="chevrondown-small" />}
      label={title}
      right
    />
  );
};
