// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import {
  FETCH_PENDING_RATE_SHIPMENT_SUCCEEDED,
  RATE_SHIPMENT_DISMISSED,
  type fetchPendingRateShipmentSucceeded,
  type rateShipmentDismissed,
} from '~shared/actions/rateShipment';

export type RateShipmentState = {
  lastDismissedRatingUuid: string | null | undefined;
  pendingRating: Record<string, any> | null | undefined;
};

const initialState: RateShipmentState = {
  lastDismissedRatingUuid: null,
  pendingRating: null,
};

type Actions = ReturnType<typeof fetchPendingRateShipmentSucceeded> | ReturnType<typeof rateShipmentDismissed>;

const rateShipment = (state: RateShipmentState = initialState, action: Actions): RateShipmentState => {
  switch (action.type) {
    case FETCH_PENDING_RATE_SHIPMENT_SUCCEEDED:
      return { ...state, pendingRating: action.payload };

    case RATE_SHIPMENT_DISMISSED:
      return { ...state, lastDismissedRatingUuid: state.pendingRating?.uuid };

    default:
      return state;
  }
};

export default withRehydration(rateShipment, initialState);
