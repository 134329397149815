import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { type ImageSourcePropType, View } from 'react-native';
import styled from 'styled-components/native';
import { Column, LgPadding, Row } from '../../containers';
import { Illustration } from '../../images';
import { type ListItemRightContentProps } from '../../lists/src/ListItem/ListUtils';
import { XsSpacing, XxsSpacing } from '../../separators';
import { type Tag } from '../../tags';
import { H2, Text } from '../../typography';

const ColumnWithLgRadius = styled(Column)`
  border-top-left-radius: ${BORDERS.RADIUS.LG.px};
  border-top-right-radius: ${BORDERS.RADIUS.LG.px};
`;

const IllustrationAnchor = styled(View)`
  position: absolute;
  top: -${SIZES.ILLUSTRATION.LG.value / 2.5}px;
  right: ${SPACING.STATIC.SM.px};
`;

const IllustrationOffset = styled(Column)<{ illustrationOffset: boolean }>`
  margin-right: ${({ illustrationOffset }) =>
    illustrationOffset ? `${SIZES.ILLUSTRATION.LG.value + SPACING.STATIC.XXS.value}px` : SPACING.STATIC.NONE.value};
`;

export type CardHeaderProps = {
  readonly title: string;
  readonly description?: string;
  readonly RightContent?: ListItemRightContentProps;
  readonly illustrationSrc?: ImageSourcePropType;
  readonly illustrationAccessibilityLabel?: string | null | undefined;
  readonly tag?: React.ReactElement<typeof Tag>;
  readonly wrapHeader?: boolean;
};

export const CardHeader = ({
  title,
  RightContent,
  illustrationSrc,
  illustrationAccessibilityLabel,
  tag,
  description,
  wrapHeader,
}: CardHeaderProps) => {
  const showIllustration = !!illustrationSrc && !!illustrationAccessibilityLabel;
  return (
    <ColumnWithLgRadius>
      <IllustrationOffset illustrationOffset={!tag && showIllustration}>
        <LgPadding
          bottomPadding={SPACING.STATIC.MD}
          rightPadding={showIllustration ? SPACING.STATIC.SM : SPACING.STATIC.LG}
        >
          <Row
            flexShrink={1}
            centerVertically
            spaceBetween
            wrap={wrapHeader}
          >
            <Column flexShrink={1}>
              {!!tag && (
                <IllustrationOffset illustrationOffset={showIllustration}>
                  {tag}
                  <XsSpacing />
                </IllustrationOffset>
              )}
              <H2 color={COLORS.TEXT_COLORS.PRIMARY}>{title}</H2>
              {!!description && (
                <>
                  <XxsSpacing />
                  <Text
                    textSize="mini"
                    color={COLORS.TEXT_COLORS.GREY}
                  >
                    {description}
                  </Text>
                </>
              )}
            </Column>
            <Row centerVertically>
              {RightContent ? (
                <Row flexShrink={0}>
                  <XsSpacing />
                  <Column
                    right
                    centerVertically
                  >
                    {RightContent}
                  </Column>
                </Row>
              ) : null}
            </Row>
          </Row>
        </LgPadding>
      </IllustrationOffset>
      {showIllustration ? (
        <IllustrationAnchor>
          <Illustration
            size="lg"
            source={illustrationSrc}
            accessibilityLabel={illustrationAccessibilityLabel}
          />
        </IllustrationAnchor>
      ) : null}
    </ColumnWithLgRadius>
  );
};
