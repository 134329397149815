// @owners { team: patients-team }
import React, { useEffect } from 'react';
import { areCookiesDisabled } from '~shared/helpers/getDeviceID';
import { useSelectorShared } from '~shared/store';
import OnboardingContactVerification from './OnboardingContactVerificationContainer';
import { OnboardingGeoUncovered } from './OnboardingGeoUncovered';
import ServiceAreaForm from './ServiceAreaFormContainer';
import SignupForm from './SignupFormContainer';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';
import CookieError from '~web/features/auth/components/CookieError';

type Props = {
  readonly authorized: boolean;
  readonly fetchServiceAvailability: (zip: string) => void;
  readonly redirectToHome: () => void;
  readonly serviceAvailabilityChecked: boolean;
  readonly serviceAvailable: boolean;
  readonly verificationRequired: boolean;
  readonly zip: string;
};

const SignupPage = ({
  authorized = false,
  fetchServiceAvailability,
  serviceAvailabilityChecked,
  serviceAvailable,
  verificationRequired,
  redirectToHome,
  zip = '',
}: Props) => {
  const { isFertDeliverySignup } = useSelectorShared((state) => state.uiOnboarding);
  useEffect(() => {
    if (zip) {
      fetchServiceAvailability(zip);
    }
  }, [fetchServiceAvailability, zip]);

  useEffect(() => {
    if (authorized && !verificationRequired) {
      redirectToHome();
    }
  }, [authorized, verificationRequired, redirectToHome]);

  const cookiesDisabled = areCookiesDisabled();

  let body;
  let title;

  if (serviceAvailabilityChecked || authorized) {
    if (isFertDeliverySignup || serviceAvailable || authorized) {
      if (verificationRequired) {
        title = 'Verify Account';
        body = <OnboardingContactVerification />;
      } else {
        // Available for user to sign up
        title = 'Create Account';
        body = <SignupForm navigate />;
      }
    } else {
      // Not available in the user's area
      body = <OnboardingGeoUncovered zip={zip} />;
    }
  } else {
    // User hasn't entered their zip yet
    title = 'Sign Up';
    body = <ServiceAreaForm disabled={cookiesDisabled} />;
  }

  return (
    <PageBackground>
      <SingleColumnContentWrapper>
        {cookiesDisabled ? <CookieError /> : null}
        <UnauthorizedFormWrapper header={title}>{body}</UnauthorizedFormWrapper>
      </SingleColumnContentWrapper>
    </PageBackground>
  );
};

export default SignupPage;
