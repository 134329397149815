// @owners { team: patients-team }
import { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { type Prescription } from '~shared/types';

type GetEssentialItemProps = {
  productID: number | undefined;
  prescriptions: Prescription[];
};

const getEssentialItem = ({ productID, prescriptions }: GetEssentialItemProps) => {
  return prescriptions.find(
    (prescription) => prescription.is_add_on_otc && prescription.product_id === productID && prescription,
  );
};

type Props = {
  productID: number | undefined;
};

export const useCartEssentialPrescriptionByProductID = ({ productID }: Props) => {
  const { prescriptions } = usePrescriptionsInCart();

  return useMemo(
    () => ({
      essential: getEssentialItem({ prescriptions, productID }),
    }),
    [prescriptions, productID],
  );
};
