import React from 'react';
import { Row } from '../../../containers';
import { Body, type BodyProps } from '../../../typography';

type Props = Pick<BodyProps, 'color' | 'fontFamily'> & {
  /*
    children is the Body typography copy next to the bullet point
    this can be a combination of strings and Body components when bolding is needed for
    words or phrases
   */
  readonly children: string | React.ReactNode;
};

export const BulletedListItem = ({ fontFamily, color, children }: Props) => {
  return (
    <Row
      accessibilityRole="text"
      centerVertically
    >
      <Body
        fontFamily={fontFamily}
        color={color}
      >
        &bull;&ensp;{children}
      </Body>
    </Row>
  );
};
