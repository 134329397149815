// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, Description, Divider, LgPadding, useScreenSize } from '@alto/design-system';
import { type OmittedPrescription } from '@alto/scriptdash/alto/med_sync/types/patients/v1/omitted_prescription';
import { type ProposedShipment } from '@alto/scriptdash/alto/med_sync/types/patients/v1/proposed_shipment';
import React from 'react';
import styled from 'styled-components';
import { MedSyncSelfServiceFirstSyncedShipment } from '~web/features/med-sync-self-service/components/FirstSyncedShipment';
import { MedSyncSelfServiceOmittedPrescriptions } from '~web/features/med-sync-self-service/components/OmittedPrescriptions';
import fulfillmentBoxWithMeds from '~web/images/fulfillmentBoxWithMeds@3x.png';

const ContentContainer = styled.div<{ isMDScreenOrBigger: boolean }>`
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `padding: 0 ${SPACING.STATIC.LG.px}};`}
`;

type Props = {
  readonly proposedShipment: ProposedShipment;
  readonly omittedPrescriptions: OmittedPrescription[];
};

export const MedSyncSelfServicePlanSummaryCard = ({ proposedShipment, omittedPrescriptions }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  return (
    <Card
      title="Your first bundled delivery"
      illustrationSrc={fulfillmentBoxWithMeds}
      illustrationAccessibilityLabel="Medications in a bag with Alto logo"
    >
      <ContentContainer isMDScreenOrBigger={isMDScreenOrBigger}>
        <MedSyncSelfServiceFirstSyncedShipment proposedShipment={proposedShipment} />
      </ContentContainer>
      {omittedPrescriptions.length > 0 && (
        <>
          <Divider />
          <ContentContainer isMDScreenOrBigger={isMDScreenOrBigger}>
            <MedSyncSelfServiceOmittedPrescriptions omittedPrescriptions={omittedPrescriptions} />
          </ContentContainer>
        </>
      )}
      <Divider />
      <ContentContainer isMDScreenOrBigger={isMDScreenOrBigger}>
        <LgPadding topPadding={SPACING.STATIC.MD}>
          <Description>We will confirm final cost with you before your first delivery arrives.</Description>
        </LgPadding>
      </ContentContainer>
    </Card>
  );
};
