// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoSpinningLoader,
  Button,
  ButtonGroup,
  Card,
  Column,
  Description,
  InputCheckbox,
  InputCheckboxGroup,
  LgPadding,
  SecondaryPage,
  Toast,
  ToastContext,
  isSMScreenOrBigger,
} from '@alto/design-system';
import { MedicationImage } from '@alto/features';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { goBack, push } from 'react-router-redux';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
// eslint-disable-next-line import/no-deprecated
import { useCreateMedSyncPlan } from '~shared/queries/useCreateMedSyncPlan';
// eslint-disable-next-line import/no-deprecated
import { useFetchMedSyncPreliminaryPrescriptions } from '~shared/queries/useFetchMedSyncPreliminaryPrescriptions';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { MedSyncSelfServiceProgressHeader } from './components/ProgressHeader';
import { MED_SYNC_SELF_SERVICE_ROUTES } from '~web/features/med-sync-self-service/RoutingContainer';

export const MedSyncSelfServiceSelectMedicationsPage = () => {
  const dispatch = useDispatchShared();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const [toastText, setToastText] = useState<string | null>(null);
  const [selectedMedications, setSelectedMedications] = useState<Record<string, boolean>>({});
  const { addToast } = React.useContext(ToastContext);
  const smScreenOrBigger = isSMScreenOrBigger();

  useEffect(() => {
    if (toastText) {
      addToast(
        <Toast
          variant="error"
          onHide={() => {
            setToastText(null);
          }}
        >
          {toastText}
        </Toast>,
      );
    }
  }, [addToast, toastText]);

  const {
    data,
    isError: isFetchPrescriptionsError,
    isLoading: isFetchPrescriptionsLoading,
    // eslint-disable-next-line import/no-deprecated
  } = useFetchMedSyncPreliminaryPrescriptions(currentUserID);
  const {
    mutateAsync: createMedSyncPlan,
    isPending: isCreateMedSyncPlanLoading,
    isError: isCreateMedSyncPlanError,
    isSuccess: isCreateMedSyncPlanSuccess,
    // eslint-disable-next-line import/no-deprecated
  } = useCreateMedSyncPlan();

  const prescriptions = data?.data;

  useEffect(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_SELECT_MEDICATIONS_VIEWED, {})));
  }, [dispatch]);

  useEffect(() => {
    if (isFetchPrescriptionsError) {
      setToastText('Something went wrong');
    } else {
      setToastText(null);
    }
  }, [isFetchPrescriptionsError]);

  useEffect(() => {
    if (isCreateMedSyncPlanError) {
      setToastText('Something went wrong');
    }
  }, [isCreateMedSyncPlanError]);

  useEffect(() => {
    if (isCreateMedSyncPlanSuccess) {
      dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.REQUEST_RECEIVED));
    }
  }, [dispatch, isCreateMedSyncPlanSuccess]);

  const onDismiss = useCallback(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_SELECT_MEDICATIONS_DISMISSED)));
    dispatch(push('/home'));
  }, [dispatch]);

  // if none of the prescriptions have been requested, let's precheck all of the checkboxes
  // if at least one prescription has been requested, only precheck the requested prescriptions
  const prevRequestedPrescriptionIDs = useMemo(
    () => (prescriptions || []).filter((prescription) => prescription.requested).map((prescription) => prescription.id),
    [prescriptions],
  );
  const precheckAllBoxes = !prevRequestedPrescriptionIDs.length;
  const checkboxMapping: Record<string, boolean> = useMemo(
    () =>
      (prescriptions || []).reduce(
        (prescriptionsMap, { id, requested }) => ({ ...prescriptionsMap, [id]: requested || precheckAllBoxes }),
        {},
      ),
    [prescriptions, precheckAllBoxes],
  );

  const onSubmit = useCallback(() => {
    const selectedPrescriptionIDs = Object.entries(selectedMedications).reduce<number[]>(
      (selectedIDs, [prescriptionID, isSelected]) => {
        if (isSelected) {
          selectedIDs.push(parseInt(prescriptionID, 10));
        }

        return selectedIDs;
      },
      [],
    );

    if (selectedPrescriptionIDs?.length < 2) {
      setToastText('Please select at least two medications.');
    } else {
      dispatch(sendAnalyticsEvent(createEvent(EVENTS.MED_SYNC_SELECT_MEDICATIONS_REQUEST_BUNDLE_CLICKED)));
      createMedSyncPlan({ prescription_ids: selectedPrescriptionIDs });
    }
  }, [createMedSyncPlan, dispatch, selectedMedications]);

  return (
    <SecondaryPage
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST}
      onDismiss={() => {
        dispatch(goBack());
      }}
      dismissIcon="chevronleft"
      HeaderContent={
        <MedSyncSelfServiceProgressHeader
          currentStep={2}
          title="Which medications would you like to bundle?"
        />
      }
    >
      <Card>
        <LgPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          {isFetchPrescriptionsLoading ? (
            <AltoSpinningLoader />
          ) : (
            <>
              <InputCheckboxGroup
                onValueChange={setSelectedMedications}
                initialState={checkboxMapping}
              >
                {(prescriptions || []).map(({ id, medication_name, product_image_url }) => (
                  <InputCheckbox
                    key={`${medication_name}-${id}`}
                    label={medication_name}
                    name={id?.toString()}
                    LeftContent={<MedicationImage imageUrl={product_image_url} />}
                  />
                ))}
              </InputCheckboxGroup>

              <Column center>
                <LgPadding topPadding={SPACING.STATIC.XS}>
                  <Description center>
                    Don’t see a medication? Some medications are ineligible due to certain limitations.
                  </Description>
                </LgPadding>
              </Column>

              {/*
              Keep the buttons in the submitting state after success such that
              there is no flash of pressable buttons before being redirected
            */}
              <LgPadding
                topPadding={SPACING.STATIC.NONE}
                bottomPadding={SPACING.STATIC.NONE}
              >
                <ButtonGroup
                  buttons={[
                    <Button
                      key="cancel"
                      type="tertiary"
                      label="Cancel"
                      onPress={onDismiss}
                      disabled={isCreateMedSyncPlanLoading || isCreateMedSyncPlanSuccess}
                    />,
                    <Button
                      key="continue"
                      label="Continue"
                      onPress={onSubmit}
                      loading={isCreateMedSyncPlanLoading || isCreateMedSyncPlanSuccess}
                      loadingLabel="Bundling..."
                    />,
                  ]}
                  rowReversed={!smScreenOrBigger}
                />
              </LgPadding>
            </>
          )}
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};
