// FIXME: this endpoint _should_ be generated from protos, but we're having trouble generating the correct route.
import { type ErrorObject } from '@alto/core/types/v1/error_object';
import { type PaginationRequestParams } from '@alto/core/types/v1/pagination_request_params';
import { type PaginationResponseMetadata } from '@alto/core/types/v1/pagination_response_metadata';
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';

export type WundercomMessagesEndpointFetchAllRequest = {
  support_case_id: number;
  pagination: PaginationRequestParams | null | undefined;
}

const WundercomMessagesEndpointFetchAllRequestHttpQueryParams = (request: WundercomMessagesEndpointFetchAllRequest) => {
  return {
    support_case_id: request.support_case_id,
    page_size: request.pagination?.page_size,
    page_token: request.pagination?.page_token,
  };
};

export type WundercomMessagesEndpointFetchAllResponseMetadata = {
  pagination: PaginationResponseMetadata | null | undefined;
}

export type WundercomMessagesEndpointFetchAllResponse = {
  errors: ErrorObject[] | null | undefined;
  data: WundercomMessage[] | null | undefined;
  metadata: WundercomMessagesEndpointFetchAllResponseMetadata | null | undefined;
}

export const WundercomMessagesEndpoint = <Options extends Record<string, unknown>>(
  makeRequest: (method: string, endpoint: string, params: Record<string, unknown>, options?: Options) => Promise<any>,
) => {
  return {
    // Given a support case ID return a list of wundercom messages
    fetchAll(
      params: WundercomMessagesEndpointFetchAllRequest,
      options?: Options,
    ): Promise<WundercomMessagesEndpointFetchAllResponse> {
      return makeRequest(
        'GET',
        `/v1/support_cases/${params.support_case_id}/wundercom_messages`,
        WundercomMessagesEndpointFetchAllRequestHttpQueryParams(params),
        options,
      );
    },
  };
};
