export const WARNING = {
  DARKEST: '#7D4F00',
  DARKER: '#A36700',
  DARK: '#CC8100',
  DEFAULT: '#FFA000',
  LIGHT: '#FEB874',
  LIGHTER: '#FFECB8',
  LIGHTEST: '#FFFAE9',
  TRANSPARENT: 'rgba(255, 248, 225, 0.5)',
} as const;
