// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import { type ActionAuthSignupVerificationRequired, SIGNUP_VERIFICATION_REQUIRED } from '~shared/actions/auth';
import {
  type ActionClearOnboardingState,
  type ActionSeenInsuranceLoading,
  type ActionSetEditingInsuranceID,
  type ActionSetInitialOnboardState,
  type ActionSetInsuranceID,
  type ActionSetSuccessMessageType,
  type ActionSetTempPassword,
  CLEAR_ONBOARDING_STATE,
  SEEN_INSURANCE_LOADING,
  SET_EDITING_INSURANCE_ID,
  SET_INITIAL_ONBOARD_STATE,
  SET_INSURANCE_ID,
  SET_SUCCESS_MESSAGE_TYPE,
  SET_TEMP_PASSWORD,
} from '~shared/actions/onboarding';
import {
  type ActionClearOnboardingVerificationRequired,
  type ActionCompleteOnboarding,
  type ActionSetOnboardingVerificationChannel,
  type ActionSetOnboardingVerificationRequired,
  type ActionSetOnboardingVerificationUser,
  CLEAR_ONBOARDING_VERIFICATION_REQUIRED,
  SET_ONBOARDING_VERIFICATION_CHANNEL,
  SET_ONBOARDING_VERIFICATION_REQUIRED,
  SET_ONBOARDING_VERIFICATION_USER,
  type VerificationCommsChannel,
} from '~shared/actions/verifications';
import { COMPLETE_ONBOARDING } from '~shared/constants';

export type Verification = {
  channel?: VerificationCommsChannel | null;
  required?: boolean;
  user?: Record<string, any> | null | undefined;
  uuid?: string | null | undefined;
  verification_methods?: VerificationCommsChannel[];
};

export type OnboardingState = {
  tempPassword?: string;
  insuranceID?: number;
  editingInsuranceID?: number | null;
  initialOnboardState?: string;
  insuranceLoadingSeen?: boolean;
  successMessageType?: string | null | undefined;
  verification?: Verification | null | undefined;
  onboardedInThisSession?: boolean;
};

const initialState = {};

type AuthActions = ActionAuthSignupVerificationRequired;

type VerificationActions =
  | ActionClearOnboardingVerificationRequired
  | ActionSetOnboardingVerificationChannel
  | ActionSetOnboardingVerificationRequired
  | ActionSetOnboardingVerificationUser;

type OnboardingAction =
  | ActionCompleteOnboarding
  | ActionSetTempPassword
  | ActionSetInsuranceID
  | ActionSetEditingInsuranceID
  | ActionSetInitialOnboardState
  | ActionSeenInsuranceLoading
  | ActionClearOnboardingState
  | ActionSetSuccessMessageType
  | AuthActions
  | VerificationActions;

const onboarding = (state: OnboardingState = initialState, action: OnboardingAction): OnboardingState => {
  switch (action.type) {
    case SET_TEMP_PASSWORD:
      return { ...state, tempPassword: action.payload };

    case SET_INSURANCE_ID:
      return { ...state, insuranceID: action.payload };

    case SET_EDITING_INSURANCE_ID:
      return { ...state, editingInsuranceID: action.payload };

    case SET_INITIAL_ONBOARD_STATE:
      return { ...state, initialOnboardState: action.payload };

    case SEEN_INSURANCE_LOADING:
      return { ...state, insuranceLoadingSeen: true };

    case CLEAR_ONBOARDING_STATE:
      return { ...initialState };

    case SET_SUCCESS_MESSAGE_TYPE:
      return { ...state, successMessageType: action.payload };

    case SIGNUP_VERIFICATION_REQUIRED:
      return { ...state, verification: { ...state.verification, ...action.payload } };

    case SET_ONBOARDING_VERIFICATION_USER:
      return { ...state, verification: { ...state.verification, user: action.payload } };

    case SET_ONBOARDING_VERIFICATION_REQUIRED:
      return { ...state, verification: { ...state.verification, required: true } };

    case CLEAR_ONBOARDING_VERIFICATION_REQUIRED:
      return { ...state, verification: { ...state.verification, required: false } };

    case SET_ONBOARDING_VERIFICATION_CHANNEL:
      return { ...state, verification: { ...state.verification, channel: action.payload } };

    case COMPLETE_ONBOARDING:
      return { ...state, onboardedInThisSession: true };

    default:
      return state;
  }
};

export default withRehydration(onboarding, initialState);
