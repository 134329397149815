import { TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components/native';
import { Row } from '../../../containers';
import { AltoSpinningLoader } from '../../../loaders';

/*
 Used to prevent input size changing when going between loading and not loading states
 min-height value matches line-height value of Body.
 Included -1.3 because even though the line-height of Body is set to 24px it is always only 22.7px tall
 */
const MatchBodyLineHeightWrapper = styled(Row)`
  min-height: ${TYPOGRAPHY.TEXT.BODY.MD.value * TYPOGRAPHY.LINE_HEIGHT.LG - 1.3}px;
`;

export const InputLoader = () => (
  <MatchBodyLineHeightWrapper center>
    <AltoSpinningLoader small />
  </MatchBodyLineHeightWrapper>
);
