import { BORDERS, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { Body, Column, LgSpacing, XsSpacing } from '@alto/design-system';
import React, { type ReactNode, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import styledNative, { css } from 'styled-components/native';

const slideUp = css`
  from {
    bottom: -500px;
  }
  to {
    bottom: 0;
  }
`;

const DrawerOverlay = styled.div<{ opacity: number }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  touch-action: none;
  z-index: 1000;
  background-color: rgba(25, 32, 38, ${({ opacity }) => opacity});
`;

const DismissZone = styled.div`
  flex-grow: 1;
`;

// eslint-disable-next-line @alto/no-media-queries
const StyledDrawer = styled.div<{ drawerBottom: number; center: boolean }>`
  width: 100%;
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  position: fixed;
  bottom: ${({ drawerBottom }) => drawerBottom}px;
  border-radius: ${BORDERS.RADIUS.XL.px} ${BORDERS.RADIUS.XL.px} 0 0;
  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 700ms;
    animation-name: ${slideUp};
    animation-fill-mode: backwards;
  }
`;

const Title = styledNative(Body)`
  font-size: 24px;
  line-height: 32px;
  font-family: ${TYPOGRAPHY.FONT.HEADER.SEMIBOLD};
  color: ${COLORS.TEXT_COLORS.PRIMARY};
`;

const Bar = styled.div`
  height: 4px;
  width: 100px;
  margin: auto;
  background-color: ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  border-radius: ${BORDERS.RADIUS.SM.px};
`;

const Content = styledNative(Column)`
  width: 100%;
  padding: ${SPACING.STATIC.MD.px};
`;

type Props = {
  readonly title?: string;
  readonly dismissable?: boolean;
  readonly onClose: () => void;
  readonly children: ReactNode | ReactNode[];
};

export const Drawer = ({ title, dismissable, onClose, children }: Props) => {
  const [deltaY, setDeltaY] = useState(0);
  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.deltaY > 0) setDeltaY(eventData.deltaY / 3); // scale down by 3
    },
    onSwipedDown: onClose,
  });

  const drawerProps = dismissable ? { ...handlers } : {};

  return (
    <DrawerOverlay opacity={(75 - deltaY) / 100}>
      <DismissZone
        onClick={onClose}
        data-testId="dismiss-zone"
      />
      <StyledDrawer
        {...drawerProps}
        drawerBottom={-deltaY}
        center
      >
        <Content>
          <XsSpacing />
          <Bar />
          <LgSpacing />
          {title ? <Title center>{title}</Title> : null}
          {children}
        </Content>
      </StyledDrawer>
    </DrawerOverlay>
  );
};
