// @owners { team: patients-team }
import { Description, InlineAlert } from '@alto/design-system';
import React from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { ContactUs } from '../../../alto-assistant';

type SplitPaymentCardProps = {
  readonly hasFailedSplitPayments: boolean;
};

export const SplitPaymentCard = ({ hasFailedSplitPayments }: SplitPaymentCardProps) => {
  if (!hasFailedSplitPayments) {
    return null;
  }

  return (
    <InlineAlert>
      <Description fontFamily="semibold">You are using two payment methods.</Description>
      <Description>
        Update your payment information using the Alto mobile app, or you can{' '}
        <ContactUs
          origin={CONTACT_US_ORIGIN.DeliveryDetails}
          type="link"
          method="message"
          textSize="mini"
        />
        or{' '}
        <ContactUs
          origin={CONTACT_US_ORIGIN.DeliveryDetails}
          type="link"
          method="phone"
          textSize="mini"
        />
      </Description>
    </InlineAlert>
  );
};
