// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';
import { type ProductIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getProductID = (_: ReduxStateShared, { productID }: ProductIDParam) => productID;

export const getOtcPrescriptionInShipmentByProductID = createSelector(
  [getPrescriptionsForShipment, getProductID],
  (prescriptionsInShipment, productID) => {
    return prescriptionsInShipment.find(
      (prescription) =>
        prescription.is_add_on_otc &&
        prescription.product_id === productID &&
        !prescription.is_upcoming_delivery_paid_by_insurance,
    );
  },
);
