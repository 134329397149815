// @owners { team: patients-team }
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { type DeliveryWithPrescription } from '~shared/features/delivery-info/types';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import getIsMedSyncUser from '~shared/features/users/selectors/getIsMedSyncUser';
import { useSelectorShared } from '~shared/store';
import { ORDER_TYPE_CONTEXT, type OrderTypeContext } from '../../auto-refill';
import { useShipmentID } from '../../order-details';

export const useCanRemoveMedication = ({ orderTypeContext }: { orderTypeContext?: OrderTypeContext }) => {
  const { shipmentID } = useShipmentID();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID }));
  const isMedSyncUser = useSelectorShared((state) => getIsMedSyncUser(state, { userID: shipment?.user_id }));
  const delivery: DeliveryWithPrescription | undefined = useSelectorShared(
    (state) => getDeliveriesForShipmentID(state, { shipmentID })[0],
  );
  const isSmartPackDelivery = delivery?.is_smart_pack ?? false;

  if (orderTypeContext === ORDER_TYPE_CONTEXT.POST_CHECKOUT) {
    return {
      canRemoveMedication: !isMedSyncUser && !isSmartPackDelivery && !!shipment && isShipmentEditable(shipment),
    };
  }

  return { canRemoveMedication: orderTypeContext === ORDER_TYPE_CONTEXT.ADD_TO_CART };
};
