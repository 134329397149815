// @owners { team: patients-team }
import { Description, MdSpacing, Row, Switch, XsSpacing } from '@alto/design-system';
import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { toggleSplitPayment } from '~shared/actions/cart';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useDispatchShared } from '~shared/store';
import { type PaymentMethod } from '~shared/types';
import { SplitPaymentAutoRefillMessage } from './SplitPaymentAutoRefillMessage';

type Props = {
  readonly isSplitPaymentEnabled: boolean;
  readonly paymentMethod: PaymentMethod | null | undefined;
};

export const CheckoutSplitPaymentSelector = ({ isSplitPaymentEnabled, paymentMethod }: Props) => {
  const dispatch = useDispatchShared();
  const { cartItems } = useCartData();
  const cartHasAutoRefillPrescriptions = useMemo(() => {
    return cartItems.some((item) => item.auto_refill_enabled);
  }, [cartItems]);

  return (
    <>
      <XsSpacing />
      <Row
        spaceBetween={Platform.OS !== 'web'}
        centerVertically
      >
        <Description>Split medication payment</Description>
        {Platform.OS === 'web' ? <MdSpacing /> : null}
        <Switch
          value={isSplitPaymentEnabled}
          onValueChange={() => dispatch(toggleSplitPayment())}
        />
      </Row>
      {isSplitPaymentEnabled && cartHasAutoRefillPrescriptions ? (
        <SplitPaymentAutoRefillMessage paymentMethod={paymentMethod} />
      ) : null}
    </>
  );
};
