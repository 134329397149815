// @owners { team: patients-team }
import { useNavigation } from '@alto/navigation';
import { useCallback } from 'react';
import { type InsuranceSource } from '~shared/constants';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { useGetInsurancesByUserID } from '~shared/queries/query-keys/insurances';
import { useSelectorShared } from '~shared/store';

export const useCancelAddingBenefit = ({ source }: { source: InsuranceSource }) => {
  const { goBack, navigate } = useNavigation();
  const userID = useSelectorShared(getSelectedUserID);
  const { hasInsurances } = useGetInsurancesByUserID({ userID });

  const handleGoBack = useCallback(() => {
    if (source === 'add family member' && userID) {
      if (hasInsurances) {
        // route back to insurances listed with option to add more benefits
        navigate('RouteFamilyMemberCompleteProfile', { userID });
      } else {
        // route back to chose how to add benefits, cash pay, or remind later
        navigate('RouteFamilyMemberBenefits', { userID });
      }
    } else {
      goBack();
    }
  }, [source, userID, hasInsurances, navigate, goBack]);

  return { handleGoBack };
};
