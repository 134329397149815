// @owners { team: patients-team }
import {
  ActionSheetContext,
  Button,
  Column,
  Description,
  H3,
  InputText,
  LgSpacing,
  XsSpacing,
  XxsPadding,
  XxsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import debounce from 'lodash/debounce';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { setDraftMessage } from '~shared/actions/altoAssistant';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { MESSAGE_RESPONSE_TIME } from '~shared/features/messages/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getDraftMessage } from '~shared/selectors/alto-assistant/getDraftMessage';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useGetSupportCase, useGetWundercomMessages } from '../../queries/queries';
import { useSendMessage } from '../../queries/useSendMessage';
import { type FaqActionSheet, getFaqsForMessageBody } from '../faq/helpers';

type ReplyFormContentProps = {
  readonly supportCaseID: number;
};

/**
 * Reply form content for support cases (threaded conversations). Displays messages for the given conversation
 * and allows a patient to reply using quick reply buttons or a custom message. Also, displays
 * buttons to show FAQ content when a patient has matching keywords in the message for topics like
 * receipts or sharps container disposal.
 * @param supportCaseID - number the ID of the support case
 * @param patientID - number the ID of the patient
 * @returns ReplyFormContent UI
 */
export const ReplyFormContent = ({ supportCaseID }: ReplyFormContentProps) => {
  const { goBack, navigate } = useNavigation();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const draftMessage = useSelectorShared(getDraftMessage);
  const { refetchMessages } = useGetWundercomMessages({ supportCaseID });
  const { refetchSupportCase } = useGetSupportCase(supportCaseID, false);
  const { sendMessageMutate } = useSendMessage();
  const message = useRef<string>(draftMessage);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [faqs, setFaqs] = useState<ReturnType<typeof getFaqsForMessageBody>>([]);
  const viewedLearnMoreButtonsRef = useRef<Record<string, boolean>>({});

  useEffect(() => {
    if (!supportCaseID) return;
    // NOTE: save the current draft before unmounting the component
    return () => {
      dispatch(setDraftMessage(message.current));
    };
  }, [supportCaseID, dispatch]);

  const handleSubmit = async () => {
    const body = message.current.trim();
    if (!body.length) {
      setHasError(true);
      return;
    }
    setIsSubmitting(true);
    await sendMessageMutate({
      body,
      supportCaseID,
    });
    dispatch(setDraftMessage(''));
    message.current = '';
    refetchSupportCase();
    refetchMessages();
    setIsSubmitting(false);
    goBack();
  };

  const handlePressFaqButton = ({ component: ActionSheet, label }: { component: FaqActionSheet; label: string }) => {
    setActiveActionSheet(React.cloneElement(<ActionSheet />, { navigate }));
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: { name: ASSISTANT_PRESSED_EVENTS.LEARN_MORE_FAQ_BUTTON, label },
    });
  };

  const trackLearnMoreButtonViewed = (label: string, matchedKeyword: string) => {
    // track views for learn more buttons per "session" when a patient gets to message form
    // do not track multiple views if button is re-rendered as message composes longer message
    if (viewedLearnMoreButtonsRef.current[label]) {
      return;
    }
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: { name: ASSISTANT_PRESSED_EVENTS.LEARN_MORE_FAQ_BUTTON, label, matchedKeyword },
    });
    viewedLearnMoreButtonsRef.current[label] = true;
  };

  // show buttons to surface FAQs when certain keywords are in the message body
  const getLearnMoreButtons = useCallback((body = '') => {
    const trimmedBody = body.trim();
    if (!trimmedBody) {
      setFaqs([]);
      return;
    }

    setFaqs(getFaqsForMessageBody(trimmedBody));
  }, []);

  const getLearnMoreButtonsDebounced = debounce(getLearnMoreButtons, 300);

  const handleTextChange = useCallback(
    (typedMessage: string) => {
      if (hasError && typedMessage.length > 1) {
        setHasError(false);
      }
      message.current = typedMessage;
      getLearnMoreButtonsDebounced(typedMessage);
    },
    [getLearnMoreButtonsDebounced, hasError],
  );

  return (
    <>
      <Column top>
        <InputText
          defaultValue={message.current}
          accessibilityLabel="Your message"
          autoCorrect
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          error={hasError ? 'Message cannot be blank' : undefined}
          label="Message"
          numberOfLines={6}
          onChangeText={handleTextChange}
          placeholder={isSubmitting ? 'Sending...' : 'Enter your reply'}
          returnKeyType="default"
        />
        {faqs.length > 0 && (
          <>
            <XsSpacing />
            <H3>Learn more</H3>
            <XxsSpacing />
            {faqs.map(({ label, matchedKeyword, component }) => {
              trackLearnMoreButtonViewed(label, matchedKeyword);
              return (
                <XxsPadding key={label}>
                  <Button
                    small
                    width="inline"
                    type="secondary"
                    label={label}
                    accessibilityLabel={label}
                    onPress={() => {
                      handlePressFaqButton({ component, label });
                    }}
                  />
                </XxsPadding>
              );
            })}
          </>
        )}
        <LgSpacing />
      </Column>
      <Description center>We typically respond within {MESSAGE_RESPONSE_TIME} during business hours.</Description>
      <LgSpacing />
      <Button
        width="full"
        label="Reply"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={handleSubmit}
        loading={isSubmitting}
        disabled={isSubmitting}
      />
    </>
  );
};
