import { responseRedirect } from './responseRedirect';
import { Sentry } from '~shared/sentry';

type RequestPayload = {
  response: Response;
  url: string;
  method: string;
  body?: string;
  headers?: Record<string, string>;
};

export const handleBadRequest = ({ response, url, method, body, headers }: RequestPayload) => {
  if (response.status === 404) {
    Sentry.captureMessage(`404 received for url: ${url}`, {
      contexts: { body: { body }, url: { url }, method: { method }, response: { response }, headers: { headers } },
    });
  }

  responseRedirect(response);
};
