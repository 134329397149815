// @owners { team: patients-team }
import { ActionSheetContext, Button, Row } from '@alto/design-system';
import { RemoveMedicationActionSheet } from '@alto/features';
import React, { useCallback, useContext } from 'react';
import styledNative from 'styled-components/native';
import { selectPrescription } from '~shared/actions/prescriptions';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import getIsMedSyncUser from '~shared/features/users/selectors/getIsMedSyncUser';
import { useDispatchShared, useSelectorShared } from '~shared/store';

type Props = {
  readonly userID?: number;
  readonly shipmentID: number;
  readonly shipmentIsEditable: boolean;
  readonly prescriptionID: number;
  readonly deliveryID?: number;
};

const StyledButton = styledNative(Button)`
  padding: 0px;
  min-height: unset;
  font-size: 0.875rem;
  &:hover&:not(:disabled) {
    background-color: transparent;
  }
`;

export const RemoveItemButton = ({ deliveryID, prescriptionID, shipmentID, shipmentIsEditable, userID }: Props) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const isMedSyncUser = useSelectorShared((state) => getIsMedSyncUser(state, { userID }));
  const deliveries = useSelectorShared((state) =>
    getDeliveriesForShipmentID(state, {
      shipmentID,
    }),
  );
  const isSmartPackDelivery = deliveries[0]?.is_smart_pack ?? false;
  const canRemoveMedication = !isMedSyncUser && !isSmartPackDelivery && shipmentIsEditable;

  const handleRemoveMedication = useCallback(() => {
    dispatch(selectPrescription(prescriptionID));
    setActiveActionSheet(
      <RemoveMedicationActionSheet
        deliveryID={deliveryID}
        shipmentID={shipmentID}
      />,
    );
  }, [dispatch, prescriptionID, setActiveActionSheet, deliveryID, shipmentID]);

  if (!canRemoveMedication) {
    return null;
  }

  return (
    <Row
      centerVertically
      right
    >
      <StyledButton
        width="inline"
        type="tertiary"
        small
        noPadding
        iconRightName="trash-small"
        onPress={handleRemoveMedication}
        accessibilityLabel="Click to remove item"
      >
        Remove item
      </StyledButton>
    </Row>
  );
};
