// @owners { team: patients-team }
import { differenceInHours, differenceInMinutes, isAfter, isToday, isValid, parse } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';
import { type DeliveryWindow } from '~shared/types';

export const getTimeToCutoff = (windows: DeliveryWindow[]) => {
  const now = new Date();

  // find the latest cutoff time for available delivery windows today
  const cutoffTimes = windows
    .map(({ cutoff_time }) => parse(cutoff_time, DATE_FORMATS.DELIVERY_WINDOW_CUTOFF, 0))
    .filter(
      (parsedCutoffTime) =>
        isValid(parsedCutoffTime) && isToday(parsedCutoffTime) && isAfter(parsedCutoffTime, new Date()),
    );
  const lastCutoff = cutoffTimes[cutoffTimes.length - 1];
  if (!lastCutoff) {
    return { hours: 0, minutes: 0 };
  }
  const hours = differenceInHours(lastCutoff, now);
  const minutes = differenceInMinutes(lastCutoff, now);
  return { hours, minutes };
};

export const formatTimeToCutoff = ({ hours, minutes }: { hours: number; minutes: number }) => {
  if (hours < 0) {
    return '';
  }
  if (minutes < 0) {
    return '';
  }
  if (minutes === 0 && hours === 0) {
    return '';
  }

  let formattedMins = '';
  const minsRemaining = minutes - hours * 60;
  if (minsRemaining > 0) {
    formattedMins = minsRemaining === 1 ? '1min' : `${minsRemaining}mins`;
  }

  if (hours > 0) {
    let formattedTime = `within ${hours}${hours === 1 ? 'hr' : 'hrs'}`;
    if (formattedMins) {
      formattedTime += ` and ${formattedMins}`;
    }
    return formattedTime;
  }
  return `within ${formattedMins}`;
};
