// @owners { team: patients-team }
type QueryParams = {
  addressID?: number;
  facilityID?: number;
  prescriptionIDs: number[];
  shipmentID?: number | null;
  flag?: boolean;
};

export const nextAvailableDatesQueryKeys = {
  // invalidate all queries by using `queryClient.invalidateQueries({ queryKey: nextAvailableDatesQueryKeys.all })`
  all: [{ $scope: 'next-available-dates' }] as const,

  // use this to invalidate queries for NADs for prescriptions
  prescription: () => [{ ...nextAvailableDatesQueryKeys.all[0], nadType: 'prescription' }] as const,

  // use this to invalidate queries for NADs for orders
  order: () => [{ ...nextAvailableDatesQueryKeys.all[0], nadType: 'order' }] as const,

  // unbundled next available dates - get NADs for each prescription separately
  forPrescriptions: ({ addressID, facilityID, prescriptionIDs }: QueryParams) =>
    [
      {
        ...nextAvailableDatesQueryKeys.prescription()[0],
        addressID,
        facilityID,
        prescriptionIDs,
      },
    ] as const,

  // bundled next available dates for an order - get the NAD for all prescriptions in an order as a group
  forOrder: ({ addressID, facilityID, prescriptionIDs, shipmentID }: QueryParams) =>
    [
      {
        ...nextAvailableDatesQueryKeys.order()[0],
        addressID,
        facilityID,
        prescriptionIDs,
        shipmentID,
      },
    ] as const,
};
