import { createSelector } from 'reselect';
import { type OrderPricing } from '~shared/features/pricing/types';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { type ReduxStateShared } from '~shared/types';

export default (state: ReduxStateShared) => state.pricing;
export const getPrescriptionPricing = (state: ReduxStateShared) => state.pricing.prescriptionPricing;
export const getPrescriptionPricingLastAttemptedAt = (state: ReduxStateShared) => state.pricing.lastAttemptedAt;
export const getPrescriptionPricingLastFetchedAt = (state: ReduxStateShared) => state.pricing.lastFetchedAt;
export const getPrescriptionPricingAttemptCount = (state: ReduxStateShared) => state.pricing.fetchAttemptCount;
export const getCartPricing = (state: ReduxStateShared) => state.pricing.orderPricing.cartPricing;
export const getEditPricing = (state: ReduxStateShared) => state.pricing.orderPricing.editPricing;
export const getShipmentPricing = (state: ReduxStateShared) => state.pricing.orderPricing.shipmentPricing;
export const getOrderPricingLastAttemptedAt = (state: ReduxStateShared) => state.pricing.orderPricing.lastAttemptedAt;
export const getOrderPricingLastFetchedAt = (state: ReduxStateShared) => state.pricing.orderPricing.lastFetchedAt;
export const getOrderPricingAttemptCount = (state: ReduxStateShared) => state.pricing.orderPricing.fetchAttemptCount;
export const getFetchOrderPricingLoading = (state: ReduxStateShared) => state.ui.loading.fetchOrderPricingLoading;
export const getAutobillPrescriptionLoadingByID = (state: ReduxStateShared) =>
  state.ui.loading.autobillPrescriptionLoadingByID;

export const getOrderPricing = createSelector(
  [getIsEditingExistingOrder, getCartPricing, getEditPricing],
  (isEditing: boolean, cartPricing: OrderPricing, editPricing: OrderPricing): OrderPricing => {
    return isEditing ? editPricing : cartPricing;
  },
);

export type OrderPricingWithUpdatedAt = OrderPricing & { updatedAt: number };

export const getOrderPricingWithUpdatedAt = createSelector(
  [getOrderPricing],
  (orderPricing): OrderPricingWithUpdatedAt => {
    const updatedAt = Date.now();
    return { ...orderPricing, updatedAt };
  },
);
