// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { FlatList, type ListRenderItem, View, useWindowDimensions } from 'react-native';
import styled from 'styled-components/native';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { useAddOnsContext } from '../../checkout/components/essentials/AddOnsContext';
import { EssentialItem } from './EssentialItem';

const StyledEssential = styled(View)<{
  first: boolean;
  last: boolean;
  width: string;
  highlighted: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  margin-left: ${({ first, highlighted }) => (first && !highlighted ? SPACING.STATIC.MD.px : 0)};
  margin-right: ${({ last, highlighted }) => (last || highlighted ? SPACING.STATIC.MD.px : 0)};
  background-color: ${({ highlighted }) =>
    highlighted ? COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST : COLORS.BACKGROUND_COLORS.WHITE};
  border-radius: ${({ highlighted }) => (highlighted ? BORDERS.RADIUS.LG.px : 0)};
`;

type Props = {
  readonly addOnOtcs: AddOnOtc[];
  readonly source: string; // for analytics: in what context this carousel is being rendered
  readonly category?: string | undefined; // for analytics: the name of the category being rendered in the carousel, if present
  // We want ~3.5 items to show in each carousel (~2.5 if larger font) so that it indicates scrollability.
  readonly spacing?: number; // the amount of horizontal padding included outside of the carousel. i.e {screen width} - {spacing} = {carousel width}
  readonly visibleOtcs?: number; // the number of OTCs we'd like to be visible in the carousel without any scrolling.
  readonly largeFontVisibleOtcs?: number; // the number of OTCs we'd like to be visibile in the carousel without any scrolling, if user has large font.
  readonly isStoreItem?: boolean;
  readonly onCloseActionSheet?: () => void;
  readonly highlighted?: boolean;
};

const computeOtcWidth = (
  windowWidth: number,
  windowFontScale: number,
  largeFontVisibleOtcs: number,
  visibleOtcs: number,
  spacing: number,
) => `${(1 / (windowFontScale > 1.4 ? largeFontVisibleOtcs : visibleOtcs)) * (windowWidth - spacing)}px`;

export const AddOnsCarousel = memo(function AddOnsCarouselMemo({
  addOnOtcs,
  source,
  category,
  spacing = 0,
  visibleOtcs = 3.5,
  largeFontVisibleOtcs = 2.5,
  isStoreItem,
  onCloseActionSheet,
  highlighted,
}: Props) {
  const { shipmentID, origin } = useAddOnsContext();
  const { trackEvent } = useAnalytics();
  const hasDraggedScrollView = useRef(false);
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));
  const { width: windowWidth, fontScale: windowFontScale } = useWindowDimensions();
  const width = useMemo(
    () => computeOtcWidth(windowWidth, windowFontScale, largeFontVisibleOtcs, visibleOtcs, spacing),
    [windowWidth, windowFontScale, largeFontVisibleOtcs, visibleOtcs, spacing],
  );

  const handleBeginDrag = useCallback(() => {
    if (hasDraggedScrollView.current) return;
    trackEvent({
      event: EVENTS.ESSENTIALS__CAROUSEL_SCROLLED,
      params: {
        origin,
        source,
        shipment_id: shipmentID,
        category,
      },
    });
    hasDraggedScrollView.current = true;
  }, [trackEvent, category, origin, shipmentID, source]);

  const renderItem = useCallback<ListRenderItem<AddOnOtc>>(
    ({ item, index }) => (
      <StyledEssential
        width={width}
        first={index === 0}
        last={index === addOnOtcs.length - 1}
        highlighted={highlighted}
      >
        <EssentialItem
          isStoreItem={isStoreItem}
          addOnOtc={item}
          analyticsProps={{
            slot: index + 1,
            sku: item.sku,
            sku_type: item.sku_type,
            otc_name: item.name,
            source,
            category,
            query_string: null,
            shipment_id: shipmentID,
            facility_id: address?.dispensing_facility_id,
            origin,
          }}
          onCloseActionSheet={onCloseActionSheet}
        />
      </StyledEssential>
    ),
    [
      addOnOtcs.length,
      address?.dispensing_facility_id,
      category,
      origin,
      width,
      shipmentID,
      source,
      isStoreItem,
      onCloseActionSheet,
      highlighted,
    ],
  );

  const keyExtractor = useCallback((item: AddOnOtc) => String(item.product_id), []);

  return (
    <FlatList
      keyExtractor={keyExtractor}
      horizontal
      data={addOnOtcs}
      renderItem={renderItem}
      onScrollBeginDrag={handleBeginDrag}
      // 2.5 - 3.5 items are visible at a time. The following props are used to optimize performance.
      maxToRenderPerBatch={5}
      initialNumToRender={5}
      windowSize={12}
    />
  );
});
