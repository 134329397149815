// @owners { team: patients-team }
import { BORDERS, SIZES } from '@alto/design-library-tokens';
import { ListButton, Row } from '@alto/design-system';
import React, { useRef } from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';

const HiddenFileInputWrapper = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  whitespace: nowrap;
  wordwrap: normal;
`;

const BottomAttachedImage = styled.img`
  height: auto;
  max-height: ${SIZES.ILLUSTRATION.XXL.px};
  width: 100%;
  object-fit: contain;
  border-top-left-radius: ${BORDERS.RADIUS.LG.px};
  border-top-right-radius: ${BORDERS.RADIUS.LG.px};
`;

const TopAttachedListButton = styledNative(ListButton)`
  border-top-left-radius: ${BORDERS.RADIUS.NONE.px};
  border-top-right-radius: ${BORDERS.RADIUS.NONE.px};
  width: 100%;
`;

const FullWidthListButton = styledNative(ListButton)`
  width: 100%;
`;

export type ImageUploadListButtonProps = {
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly name: string;
  readonly onUpload: (file: File) => void;
  readonly onDelete: () => void;
  readonly source: string;
  readonly children: string | string[];
};

export const ImageUploadListButton = ({
  disabled,
  loading,
  name,
  onUpload,
  onDelete,
  source,
  children,
}: ImageUploadListButtonProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    fileInput?.current?.click();
  };

  const handleDeleteClick = () => {
    onDelete();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      onUpload(event.target.files[0]);
    }
  };

  return (
    <Row>
      {source ? (
        <>
          <Row
            flexGrow={1}
            center
          >
            <BottomAttachedImage
              src={source}
              alt={name}
            />
          </Row>
          <TopAttachedListButton
            label={children}
            onPress={handleDeleteClick}
            rightIcon="trash"
            disabled={disabled || loading}
            loading={loading}
            loadingLabel="Uploading..."
          />
        </>
      ) : (
        <>
          <FullWidthListButton
            onPress={handleUploadClick}
            label={children}
            rightIcon="camera"
            disabled={disabled || loading}
            loading={loading}
            loadingLabel="Uploading..."
          />
          <HiddenFileInputWrapper
            data-testid={name}
            id={name}
            name={name}
            onChange={handleFileChange}
            ref={fileInput}
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            multiple={false}
          />
        </>
      )}
    </Row>
  );
};
