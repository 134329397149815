// @owners { team: patients-team }
import { getUpcomingDeliveriesByPrescriptionID } from '~shared/features/delivery/selectors/getUpcomingDeliveriesForPrescription';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { useSelectorShared } from '~shared/store';

export type AvailabilitySortData = {
  upcomingDeliveryDate: string | null;
  earliestAvailableDate: string | null;
};

export const useAvailabilityDataByPrescriptionID = () => {
  const upcomingDeliveriesByPrescriptionID = useSelectorShared((state) => getUpcomingDeliveriesByPrescriptionID(state));
  const prescriptions = useSelectorShared((state) => getPrescriptions(state));
  const { nextAvailableDatesByPrescriptionID } = useNextAvailableDatesForPrescriptions();

  return prescriptions.reduce<Record<number, AvailabilitySortData>>((map, prescription) => {
    let upcomingDeliveryDate = null;
    if (upcomingDeliveriesByPrescriptionID[prescription.id]?.length) {
      upcomingDeliveryDate = upcomingDeliveriesByPrescriptionID[prescription.id][0].date || null;
    }

    map[prescription.id] = {
      upcomingDeliveryDate,
      earliestAvailableDate: nextAvailableDatesByPrescriptionID[prescription.id]?.earliest?.date || null,
    };
    return map;
  }, {});
};
