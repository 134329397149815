import { getPlatformOS } from './getPlatformOS';
import { isMobileWeb } from './helper';

const platform = getPlatformOS();
const getPlatform = () => {
  if (platform === 'web') {
    return isMobileWeb() ? 'patient-mobile-web' : 'patient-desktop-web';
  }
  if (platform === 'ios') {
    return 'patient-rn-ios';
  }
  if (platform === 'android') {
    return 'patient-rn-android';
  }
  return 'patient-rn-unknown';
};

export default getPlatform();
