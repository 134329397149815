// @owners { team: patients-team }
import { ActionSheetContext, Description, InlineAlert, Link, XsSpacing, XxsSpacing } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { PartialFillActionSheet } from '../../med-sync';

type Props = {
  readonly partialFillDeliveryIDs: number[];
  readonly shipmentID: number;
};

export const PartialFillAlert = ({ partialFillDeliveryIDs, shipmentID }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackPageView } = useAnalytics();
  const userID = useSelectorShared(getCurrentUserID);

  useEffect(() => {
    trackPageView({
      event: EVENTS.DELIVERY_DETIALS_SHORT_FILL_INFO_VIEWED,
      params: {
        patient_id: userID,
        delivery_ids: partialFillDeliveryIDs,
        shipment_id: shipmentID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePress = () => {
    setActiveActionSheet(<PartialFillActionSheet />);
  };

  const title =
    partialFillDeliveryIDs.length > 1 ? 'Delivery includes partial fills' : 'Delivery includes a partial fill';

  return (
    <InlineAlert>
      <Description fontFamily="semibold">{title}</Description>
      <XxsSpacing />
      <Description>
        A partial medication fill is an adjusted quantity to last you until your bundled auto refill delivery arrives.
      </Description>
      <XsSpacing />
      <Link
        onPress={handlePress}
        textSize="mini"
      >
        Why did I receive this?
      </Link>
    </InlineAlert>
  );
};
