import { type BACKGROUND_COLORS_VALUES, COLORS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { forwardRef } from 'react';
import { useScreenSize } from '../../../utils';
import { type ButtonGroupProps, PressableAltoIcon } from '../../buttons';
import { MdPadding } from '../../containers';
import { type NavBarLogo } from '../../images';
import { XlSpacing } from '../../separators';
import { BasePage, type BasePageProps, type PageRef } from './BasePage';
import { NavBar } from './NavBar';
import { PageHeaderSection } from './PageHeaderSection';
import { PageOffsetContentSection } from './PageOffsetContentSection';

export type Props = Omit<BasePageProps, 'NavBar' & 'backgroundColor'> & {
  readonly onDismiss?: () => void;
  readonly dismissIcon?: 'close' | 'chevronleft';
  /**
   * @deprecated Use NavBarCenterContent?: string | React.ReactElement<typeof NavBarLogo> moving forward
   */
  readonly navBarTitle?: string;
  /** Content rendered in the Center of the NavBar, can be string or NavBarLogo */
  readonly NavBarCenterContent?: string | React.ReactElement<typeof NavBarLogo>;
  readonly NavBarRightPressable?: React.ReactElement<typeof PressableAltoIcon>;
  readonly HeaderContent?: React.ReactNode;
  readonly headerBackgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly inlineButtons?: ButtonGroupProps['inline'];
  readonly justifyButtons?: ButtonGroupProps['justifyButtons'];
  readonly children: React.ReactNode;
  /** If true, no padding will be applied to the content. */
  readonly noContentPadding?: boolean;
};

export const SecondaryPage = forwardRef<PageRef, Props>(
  (
    {
      children,
      headerBackgroundColor = COLORS.BACKGROUND_COLORS.TERTIARY_LIGHTEST,
      // Should this be called small/mobileDismiss?
      onDismiss,
      dismissIcon = 'close',
      navBarTitle,
      NavBarCenterContent,
      NavBarRightPressable,
      HeaderContent,
      buttons,
      footerPlacementContext,
      Footnote,
      inlineButtons = false,
      justifyButtons,
      noContentPadding,
      scrollEnabled = true,
      withoutWebFramingElementHeights,
      keyboardAvoidingEnabled = true,
    },
    ref,
  ) => {
    const { isMDScreenOrBigger } = useScreenSize();
    const hasNavBar = !!NavBarCenterContent || !!navBarTitle || !!onDismiss;
    return (
      <BasePage
        ref={ref}
        scrollEnabled={scrollEnabled}
        keyboardAvoidingEnabled={keyboardAvoidingEnabled}
        scrollViewBounces={false}
        buttons={buttons}
        footerPlacementContext={footerPlacementContext}
        Footnote={Footnote}
        justifyButtons={justifyButtons}
        inlineButtons={inlineButtons}
        headerBackgroundColor={headerBackgroundColor}
        withoutWebFramingElementHeights={withoutWebFramingElementHeights}
        NavBar={
          hasNavBar ? (
            <NavBar
              CenterContent={NavBarCenterContent}
              title={navBarTitle}
              backgroundColor={headerBackgroundColor}
              LeftPressableAltoIcon={
                onDismiss ? (
                  <PressableAltoIcon
                    name={dismissIcon}
                    onPress={onDismiss}
                    accessibilityLabel={dismissIcon === 'chevronleft' ? 'Press to go back' : 'Press to close'}
                  />
                ) : undefined
              }
              RightPressable={NavBarRightPressable}
            />
          ) : undefined
        }
      >
        <PageHeaderSection backgroundColor={headerBackgroundColor}>
          {HeaderContent || <XlSpacing />}
          <XlSpacing />
        </PageHeaderSection>
        <PageOffsetContentSection>
          <MdPadding
            flexGrow={1}
            topPadding={SPACING.STATIC.NONE}
            leftPadding={noContentPadding || isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.MD}
            rightPadding={noContentPadding || isMDScreenOrBigger ? SPACING.STATIC.NONE : SPACING.STATIC.MD}
            bottomPadding={noContentPadding ? SPACING.STATIC.NONE : SPACING.STATIC.MD}
          >
            {children}
          </MdPadding>
        </PageOffsetContentSection>
      </BasePage>
    );
  },
);

SecondaryPage.displayName = 'SecondaryPage';
