// eslint-disable-next-line @alto/no-pocky-import
import { Text } from '@alto/pocky';
import { type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { useEffect, useState } from 'react';
import { getSavingsAppliedAmountForPrescription } from '~shared/features/pricing/selectors/getSavingsAppliedAmountForPrescription';
import { formatDollars } from '~shared/helpers/currency';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly prescriptionID: number;
  readonly priceType: PriceType | null | undefined;
};

export default function SavingsAppliedTag({ prescriptionID, priceType }: Props) {
  const [savingsApplied, setSavingsApplied] = useState<number>(0);
  const savingsAppliedAmount = useSelectorShared((state) =>
    getSavingsAppliedAmountForPrescription(state, prescriptionID, priceType),
  );

  useEffect(() => {
    if (savingsAppliedAmount) {
      setSavingsApplied(savingsAppliedAmount);
    }
  }, [savingsAppliedAmount]);

  if (savingsApplied === 0) {
    return null;
  }

  return (
    <>
      <Text
        bold
        small
        success
      >
        Savings Applied
      </Text>
      <Text
        bold
        small
        success
      >
        -{formatDollars(savingsApplied)}
      </Text>
    </>
  );
}
