// @owners { team: patients-team }
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Linking, Platform } from 'react-native';
import { type Dispatch } from 'redux';
import { purgeStoredState } from 'redux-persist';
import { EventTypes } from 'redux-segment';
import { onAuthSuccessful } from './onAuthSuccessful';
import { type VerificationCommsChannel } from './verifications';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers } from '~shared/actions/users';
import { BIOMETRIC_SESSION_EXPIRY, COMPLETE_ONBOARDING, USER_AUTHORIZED_KEY } from '~shared/constants';
import { destroy, get, post } from '~shared/helpers/apiHelper';
import getDeviceID from '~shared/helpers/getDeviceID';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { replaceApostrophes } from '~shared/helpers/string';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { configureBiometricSession } from '~shared/lib/biometrics';
import { CookieManager } from '~shared/lib/cookies';
import { PushNotificationIOS } from '~shared/lib/push-notifications';
import { getShouldUpgradeSessionForUserID } from '~shared/selectors/biometrics/getBiometricsForCurrentUser';
import { Sentry } from '~shared/sentry';
import { type ReduxDispatchShared, type ReduxGetStateShared, type User, type UserCreateRequest } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const CLEAR_REGISTRATION_NOTIFICATIONS = 'CLEAR_REGISTRATION_NOTIFICATIONS';

export type ClearRegistrationNotificationsType = {
  type: typeof CLEAR_REGISTRATION_NOTIFICATIONS;
};

export function clearRegistrationNotifications(): ClearRegistrationNotificationsType {
  return {
    type: CLEAR_REGISTRATION_NOTIFICATIONS,
  };
}

export type CompleteOnBoarding = {
  type: typeof COMPLETE_ONBOARDING;
  meta: {
    analytics: {
      eventType: EventTypes.track;
    };
  };
};

export function completeOnboarding(): CompleteOnBoarding {
  return {
    type: COMPLETE_ONBOARDING,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export const SET_POST_LOGIN_ROUTE = 'SET_POST_LOGIN_ROUTE';
export type SetPostLoginRoute = {
  type: typeof SET_POST_LOGIN_ROUTE;
  payload: string;
};
export const setPostLoginRoute = (route: string): SetPostLoginRoute => {
  return {
    type: SET_POST_LOGIN_ROUTE,
    payload: route,
  };
};

export const LOGIN_LOADING = 'LOGIN_LOADING';
export type LoginLoading = {
  type: typeof LOGIN_LOADING;
};
export const loginLoading = (): LoginLoading => {
  return { type: LOGIN_LOADING };
};

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export type LoginSucceeded = {
  type: typeof LOGIN_SUCCEEDED;
  payload: User;
};
export const loginSucceeded = (user: User): LoginSucceeded => {
  return {
    type: LOGIN_SUCCEEDED,
    payload: user,
  };
};

export const LOGIN_FAILED = 'LOGIN_FAILED';
export type LoginFailed = {
  type: typeof LOGIN_FAILED;
  error: true;
  payload: APIError;
};
export const loginFailed = (error: APIError): LoginFailed => {
  return {
    type: LOGIN_FAILED,
    error: true,
    payload: error,
  };
};

export const STORE_DEEPLINK_PATH = 'STORE_DEEPLINK_PATH';
export type StoreDeepLinkPath = {
  type: typeof STORE_DEEPLINK_PATH;
  payload: string;
};
export const storeDeepLinkPath = (path: string): StoreDeepLinkPath => {
  return {
    type: STORE_DEEPLINK_PATH,
    payload: path,
  };
};

export const CLEAR_DEEP_LINK_PATH = 'CLEAR_DEEP_LINK_PATH';
export type ClearDeepLinkPath = {
  type: typeof CLEAR_DEEP_LINK_PATH;
};
export const clearDeepLinkPath = (): ClearDeepLinkPath => {
  return {
    type: CLEAR_DEEP_LINK_PATH,
  };
};

export const LOGIN_OPTIONS_V1 = { version: 'v1' };
/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
const login = (auth0IdToken: string) => {
  return async (dispatch: ReduxDispatchShared) => {
    dispatch(loginLoading());
    const params = { id_token: auth0IdToken };
    const json = await post('/auth/auth0/login', params, LOGIN_OPTIONS_V1);

    if (json.error) {
      dispatch(loginFailed(json.error));
    } else {
      dispatch(loginSucceeded(json));
      Sentry.setUser({ id: json.id });
      await dispatch(postAuthSuccessForNative());
    }
    return json;
  };
};

export const loginWeb = (auth0IdToken: string) => {
  return async (dispatch: ReduxDispatchShared) => {
    const json = await dispatch(login(auth0IdToken));
    if (json.error) return false;
    Sentry.setUser({ id: json.id });
    dispatch(onAuthSuccessful());
    return true;
  };
};

export const loginNative = (auth0IdToken: string) => {
  return async (dispatch: ReduxDispatchShared, getState: ReduxGetStateShared) => {
    const json = await dispatch(login(auth0IdToken));
    if (json.error) return false;
    const state = getState();
    const userID = json.id;
    const shouldUpgradeBiometricSession = getShouldUpgradeSessionForUserID(state, { userID });
    if (shouldUpgradeBiometricSession) {
      configureBiometricSession(userID);
    }

    // if user clicked a deeplink before they were logged in, the path was stored in the state and we will navigate to it now
    const deepLinkPath = state.auth.deepLinkPath;
    Linking.emit('url', { url: deepLinkPath });
    dispatch(clearDeepLinkPath());
    return true;
  };
};

// native and web define different action functions for this, this is just here to share the action type
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export type LogoutSucceeded = {
  type: typeof LOGOUT_SUCCEEDED;
  meta?: {
    analytics: Record<string, any>;
  };
};

export function logoutSucceeded(): LogoutSucceeded {
  return {
    type: LOGOUT_SUCCEEDED,
    meta: {
      analytics: {
        eventType: EventTypes.reset,
      },
    },
  };
}

export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export type ActionAuthLogoutFailed = {
  type: typeof LOGOUT_FAILED;
  payload: APIError;
  error: true;
};

// only used in web
export function logoutFailed(error: APIError): ActionAuthLogoutFailed {
  return {
    type: LOGOUT_FAILED,
    payload: error,
    error: true,
  };
}

export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export type ActionLogoutLoading = {
  type: typeof LOGOUT_LOADING;
};

const logoutLoading = (): ActionLogoutLoading => ({
  type: LOGOUT_LOADING,
});

export function logout() {
  return async (dispatch: ReduxDispatchShared) => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.LOGOUT_STARTED)));
    dispatch(logoutLoading());
    // first we need to log the user out of the server
    await destroy('/users/logout');
    // then we need to clear the user's data from the device
    try {
      await Promise.all([
        AsyncStorage.multiRemove([USER_AUTHORIZED_KEY, BIOMETRIC_SESSION_EXPIRY]),
        CookieManager.clearAll(),
        purgeStoredState({
          // @ts-expect-error TS(2322): Type 'AsyncStorageStatic' is not assignable to type 'AsyncStorage | WebStorage | LocalForageStorage ... (Delete me to see the full error)
          storage: AsyncStorage,
        }),
      ]);
      Sentry.setUser({ id: undefined });
      if (getPlatformOS() === 'ios') {
        PushNotificationIOS.setApplicationIconBadgeNumber(0);
      }
      return dispatch(logoutSucceeded());
    } catch (e) {
      return dispatch(logoutFailed(e as APIError));
    }
  };
}

export const RESETTING_PASSWORD = 'RESETTING_PASSWORD';
export type ActionAuthResettingPassword = {
  type: typeof RESETTING_PASSWORD;
};

export function resettingPassword(): ActionAuthResettingPassword {
  return {
    type: RESETTING_PASSWORD,
  };
}

export function resetPassword(password: string, password_confirmation: string, uuid: string) {
  return (dispatch: ReduxDispatchShared) => {
    dispatch(resettingPassword());
    return post('/users/reset_password', {
      user: {
        password,
        password_confirmation,
        uuid,
      },
    }).then((json) => {
      if (json.error) {
        dispatch(resettingPasswordFailed(json.error));
        return false;
      }

      dispatch(loginSucceeded(json));
      dispatch(resettingPasswordSucceeded(json));
      return true;
    });
  };
}

export function resettingPasswordSucceeded(user: User): ResettingPasswordSucceeded {
  return {
    type: RESETTING_PASSWORD_SUCCEEDED,
    payload: user,
    meta: {
      analytics: [
        {
          eventType: EventTypes.identify,
          eventPayload: { userId: user.id },
        },
        {
          eventType: EventTypes.track,
          eventPayload: {
            event: RESETTING_PASSWORD_SUCCEEDED,
            properties: { userId: user.id },
          },
        },
      ],
    },
  };
}

// this action is only used in web web but we need this type in shared so that the shared user reducer can listen to it
export const RESETTING_PASSWORD_SUCCEEDED = 'RESETTING_PASSWORD_SUCCEEDED';
export type ResettingPasswordSucceeded = {
  type: typeof RESETTING_PASSWORD_SUCCEEDED;
  payload: User;
  meta?: Record<string, any>;
};

export const RESETTING_PASSWORD_FAILED = 'RESETTING_PASSWORD_FAILED';
export type ActionAuthResettingPasswordFailed = {
  type: typeof RESETTING_PASSWORD_FAILED;
  payload: APIError;
  error: true;
};

export function resettingPasswordFailed(error: APIError): ActionAuthResettingPasswordFailed {
  return {
    type: RESETTING_PASSWORD_FAILED,
    payload: error,
    error: true,
    // @ts-expect-error TS(2322): Type '{ type: "RESETTING_PASSWORD_FAILED"; payload: APIError; error: true; meta: { analytics: { even... (Delete me to see the full error)
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export type ActionClearAuthError = {
  type: typeof CLEAR_AUTH_ERROR;
};

export function clearAuthError(): ActionClearAuthError {
  return {
    type: CLEAR_AUTH_ERROR,
  };
}

export const FORGOTTEN_PASSWORD_LOADING = 'FORGOTTEN_PASSWORD_LOADING';
export type ActionForgottenPasswordLoading = {
  type: typeof FORGOTTEN_PASSWORD_LOADING;
};

export function requestingForgottenPassword(): ActionForgottenPasswordLoading {
  return {
    type: FORGOTTEN_PASSWORD_LOADING,
  };
}

export const FORGOTTEN_PASSWORD_SUCCEEDED = 'FORGOTTEN_PASSWORD_SUCCEEDED';
export type ActionForgottenPasswordSucceeded = {
  type: typeof FORGOTTEN_PASSWORD_SUCCEEDED;
};

export function requestForgottenPasswordSucceeded(): ActionForgottenPasswordSucceeded {
  return {
    type: FORGOTTEN_PASSWORD_SUCCEEDED,
  };
}

export const FORGOTTEN_PASSWORD_FAILED = 'FORGOTTEN_PASSWORD_FAILED';
export type ActionForgottenPasswordFailed = {
  type: typeof FORGOTTEN_PASSWORD_FAILED;
  payload: APIError;
  error: true;
};

export function requestForgottenPasswordFailed(error: APIError): ActionForgottenPasswordFailed {
  return {
    type: FORGOTTEN_PASSWORD_FAILED,
    payload: error,
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function forgotPassword(email: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestingForgottenPassword());
    return post('/users/forgot_password', {
      email,
    }).then((json) => {
      if (json.error) {
        dispatch(requestForgottenPasswordFailed(json.error));
        return false;
      }

      dispatch(requestForgottenPasswordSucceeded());
      return true;
    });
  };
}

export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export type ActionAuthSigningUp = {
  type: typeof SIGNUP_LOADING;
};

export function signingUp(): ActionAuthSigningUp {
  return {
    type: SIGNUP_LOADING,
  };
}

export const SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED';
export type ActionAuthSignupSucceeded = {
  type: typeof SIGNUP_SUCCEEDED;
  payload: User;
  meta: {
    analytics: Record<string, unknown>[];
  };
};

export function signupSucceeded(user: User): ActionAuthSignupSucceeded {
  return {
    type: SIGNUP_SUCCEEDED,
    payload: user,
    meta: {
      analytics: [
        {
          eventType: EventTypes.identify,
          eventPayload: { userId: user.id },
        },
        {
          eventType: EventTypes.track,
          eventPayload: {
            event: SIGNUP_SUCCEEDED,
            properties: { userId: user.id, resource_type: 'User', resource_id: user.id },
          },
        },
      ],
    },
  };
}

export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export type ActionAuthSignupFailed = {
  type: typeof SIGNUP_FAILED;
  payload: APIError;
  error: true;
};

export function signupFailed(error: APIError): ActionAuthSignupFailed {
  return {
    type: SIGNUP_FAILED,
    payload: error,
    error: true,
  };
}

export const SIGNUP_VERIFICATION_REQUIRED = 'SIGNUP_VERIFICATION_REQUIRED';
export type ActionAuthSignupVerificationRequired = {
  type: typeof SIGNUP_VERIFICATION_REQUIRED;
  payload?: VerificationParams;
};

type VerificationParams = {
  verification_methods: VerificationCommsChannel[];
  uuid: string;
};

export function signupVerificationRequired(verification?: VerificationParams): ActionAuthSignupVerificationRequired {
  return {
    type: SIGNUP_VERIFICATION_REQUIRED,
    payload: verification,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function signUp(user: UserCreateRequest, promos: Record<string, any>, isFertDeliverySignup?: boolean) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signingUp());
    const anonymousID = getDeviceID();

    const options = {
      version: 'v3',
    };

    const json = await post(
      '/users/sign_up',
      {
        user,
        anonymousID,
        ...promos,
        fert_mail_sign_up: isFertDeliverySignup || false,
      },
      options,
    );

    if (json.error) {
      const verificationRequired = json.error.details.statusCode === 409;

      if (verificationRequired) {
        dispatch(signupVerificationRequired(json.error.details.verification));
      }

      dispatch(signupFailed(json.error));
      return {
        success: false,
        verificationRequired,
      };
    }

    dispatch(signupSucceeded(json.user));
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(postAuthSuccessForNative());
    return {
      success: true,
    };
  };
}

export const VALIDATING_USER_LOADING = 'VALIDATING_USER_LOADING';

export type ActionAuthValidatingUserLoading = {
  type: typeof VALIDATING_USER_LOADING;
};

export function validatingUser(): ActionAuthValidatingUserLoading {
  return {
    type: VALIDATING_USER_LOADING,
  };
}

export const VALIDATING_USER_SUCCEEDED = 'VALIDATING_USER_SUCCEEDED';
export type ActionValidatingUserSucceeded = {
  type: 'VALIDATING_USER_SUCCEEDED';
  payload: User;
};

export function validatingUserSucceeded(user: User): ActionValidatingUserSucceeded {
  return {
    type: VALIDATING_USER_SUCCEEDED,
    payload: user,
  };
}

export const VALIDATING_USER_FAILED = 'VALIDATING_USER_FAILED';
export type ActionAuthValidatingUserFailed = {
  type: typeof VALIDATING_USER_FAILED;
  payload: APIError;
  error: true;
};

export function validatingUserFailed(error: APIError): ActionAuthValidatingUserFailed {
  return {
    type: VALIDATING_USER_FAILED,
    payload: error,
    error: true,
  };
}

export type ValidateUserPayload = {
  last_name: string;
  date_of_birth: string;
  token: string;
  terms?: boolean;
};
/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function validateUser({ last_name, date_of_birth, token: temp_password, terms }: ValidateUserPayload) {
  return (dispatch: Dispatch<any>) => {
    dispatch(validatingUser());

    return post('/users/validate', {
      last_name: replaceApostrophes(last_name),
      date_of_birth,
      temp_password,
      terms,
    }).then((json) => {
      if (json.error) {
        dispatch(validatingUserFailed(json.error));
        return false;
      }

      dispatch(validatingUserSucceeded(json));
      return true;
    });
  };
}

export const CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR = 'CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR';
export type ActionClearFetchServiceAvailabilityError = {
  type: typeof CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR;
};
export function clearFetchServiceAvailabilityError(): ActionClearFetchServiceAvailabilityError {
  return {
    type: CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR,
  };
}

export const FETCH_SERVICE_AVAILABILITY_LOADING = 'FETCH_SERVICE_AVAILABILITY_LOADING';
export type ActionFetchServiceAvailabilityLoading = {
  type: typeof FETCH_SERVICE_AVAILABILITY_LOADING;
};
export function fetchServiceAvailabilityLoading(): ActionFetchServiceAvailabilityLoading {
  return {
    type: FETCH_SERVICE_AVAILABILITY_LOADING,
  };
}

export const FETCH_SERVICE_AVAILABILITY_SUCCEEDED = 'FETCH_SERVICE_AVAILABILITY_SUCCEEDED';
export type ActionFetchServiceAvailabilitySucceeded = {
  type: typeof FETCH_SERVICE_AVAILABILITY_SUCCEEDED;
  payload: {
    available: boolean;
  };
};

export function fetchServiceAvailabilitySucceeded(
  availability: ActionFetchServiceAvailabilitySucceeded['payload'],
): ActionFetchServiceAvailabilitySucceeded {
  return {
    type: FETCH_SERVICE_AVAILABILITY_SUCCEEDED,
    payload: availability,
  };
}

export const FETCH_SERVICE_AVAILABILITY_FAILED = 'FETCH_SERVICE_AVAILABILITY_FAILED';
export type ActionFetchServiceAvailabilityFailed = {
  type: typeof FETCH_SERVICE_AVAILABILITY_FAILED;
  error: true;
  payload: APIError;
};

export function fetchServiceAvailabilityFailed(error: APIError): ActionFetchServiceAvailabilityFailed {
  return {
    type: FETCH_SERVICE_AVAILABILITY_FAILED,
    error: true,
    payload: error,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchServiceAvailability(zip: string | null = null, state: string | null = null) {
  return (dispatch: ReduxDispatchShared) => {
    let service_area_params = {};

    if (state) {
      service_area_params = {
        state,
      };
    } else {
      service_area_params = {
        zip,
      };
    }

    dispatch(fetchServiceAvailabilityLoading());
    return get('/delivery_zips/service_area_check', service_area_params)
      .then((json) => {
        if (json.error) {
          dispatch(fetchServiceAvailabilityFailed(json.error));
          return json as APIError;
        }

        dispatch(fetchServiceAvailabilitySucceeded(json));
        return json as { available: boolean };
      })
      .catch((error: unknown) => {
        dispatch(fetchServiceAvailabilityFailed(error as APIError));
        // eslint-disable-next-line promise/no-return-wrap
        return Promise.reject(error as APIError);
      });
  };
}

export const FETCH_SUDO_LOADING = 'FETCH_SUDO_LOADING';

export function fetchingSudo() {
  return {
    type: FETCH_SUDO_LOADING,
  };
}

export const FETCH_SUDO_FAILED = 'FETCH_SUDO_FAILED';

export function fetchSudoFailed(error: Error) {
  return {
    type: FETCH_SUDO_FAILED,
    payload: error,
  };
}

export const FETCH_SUDO_SUCCEEDED = 'FETCH_SUDO_SUCCEEDED';

export function fetchSudoSucceded(sudo: boolean) {
  return {
    type: FETCH_SUDO_SUCCEEDED,
    sudo,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchSudo() {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchingSudo());
    return get('/users/sudo', undefined, {
      version: 'v3',
    }).then((json) => {
      if (json.error) {
        dispatch(fetchSudoFailed(json.error));
        return json;
      }

      dispatch(fetchSudoSucceded(json.sudo));
      return json;
    });
  };
}

export const SET_SUDO_LOADING = 'SET_SUDO_LOADING';

export function settingSudo() {
  return {
    type: SET_SUDO_LOADING,
  };
}

export const SET_SUDO_FAILED = 'SET_SUDO_FAILED';

export function setSudoFailed(error: Error) {
  return {
    type: SET_SUDO_FAILED,
    payload: error,
  };
}

export const SET_SUDO_SUCCEEDED = 'SET_SUDO_SUCCEEDED';

export function setSudoSucceded() {
  return {
    type: SET_SUDO_SUCCEEDED,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function setSudo(password: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(settingSudo());
    return post(
      '/users/sudo',
      {
        password,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(setSudoFailed(json.error));
        return json.error;
      }

      dispatch(setSudoSucceded());
      return {
        sudo: true,
      };
    });
  };
}

export const postAuthSuccessForNative = (loggedInAt?: string) => {
  return async (dispatch: Dispatch<any>) => {
    // Used for native only. Defining here because of shared login actions for both native and web.
    if (Platform.OS !== 'web') {
      // we need to get the user's info now that we've logged in so that we can properly report analytics.
      await Promise.all([
        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        await AsyncStorage.setItem(USER_AUTHORIZED_KEY, loggedInAt || new Date().toISOString()),
        // eslint-disable-next-line import/no-deprecated, @typescript-eslint/await-thenable
        await dispatch(fetchUsers()),
      ]);
    }
  };
};

export const UPDATE_TEMP_SIGNUP_USER = 'UPDATE_TEMP_SIGNUP_USER';
export type ActionUpdateTempSignupUser = {
  type: typeof UPDATE_TEMP_SIGNUP_USER;
  payload: UserCreateRequest;
};

export function updateTempSignupUser(user: UserCreateRequest): ActionUpdateTempSignupUser {
  return {
    type: UPDATE_TEMP_SIGNUP_USER,
    payload: user,
  };
}
