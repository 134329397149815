import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { Constants } from '../../../utils';

type Props = {
  readonly children: React.ReactNode;
  readonly duration?: number;
};

type XProps = Props & {
  readonly xPosition1?: number;
  readonly xPosition2?: number;
};

type YProps = Props & {
  readonly yPosition1?: number;
  readonly yPosition2?: number;
};

export const MoveX = ({ children, duration = 150, xPosition1 = 0, xPosition2 = 0 }: XProps) => {
  const translateX = useRef(new Animated.Value(xPosition1)).current;

  useEffect(() => {
    Animated.timing(translateX, {
      toValue: xPosition2,
      duration,
      useNativeDriver: Constants.useNativeDriver,
    }).start();
  }, [translateX, xPosition2, duration]);

  return <Animated.View style={{ transform: [{ translateX }] }}>{children}</Animated.View>;
};

export const MoveY = ({ children, duration = 150, yPosition1 = 0, yPosition2 = 0 }: YProps) => {
  const translateY = useRef(new Animated.Value(yPosition1)).current;

  useEffect(() => {
    Animated.timing(translateY, {
      toValue: yPosition2,
      duration,
      useNativeDriver: Constants.useNativeDriver,
    }).start();
  }, [translateY, yPosition2, duration]);

  return <Animated.View style={{ transform: [{ translateY }] }}>{children}</Animated.View>;
};
