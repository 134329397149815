// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModal } from '@alto/pocky';
import React, { useCallback } from 'react';
import { closeModal } from '~shared/actions/modal';
import { clearUserError } from '~shared/actions/users';
import getModal from '~shared/selectors/modal/getModal';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { ContactInfoModalContent } from './ContactInfoModalContent';
import ReauthRequired from '~web/features/auth/components/ReauthRequired';

export const ContactInfoModal = () => {
  const modal = useSelectorShared(getModal);
  const dispatch = useDispatchShared();

  const onClose = useCallback(() => {
    dispatch(closeModal('CONTACT_INFO_MODAL'));
    dispatch(clearUserError());
  }, [dispatch]);

  if (modal.show && modal.modalType === 'CONTACT_INFO_MODAL') {
    return (
      <DeprecatedModal
        showing
        onClose={onClose}
        scrollable
        title="Edit Phone Number & Login"
      >
        <ReauthRequired>
          <ContactInfoModalContent
            onClose={onClose}
            {...modal}
          />
        </ReauthRequired>
      </DeprecatedModal>
    );
  }

  return null;
};
