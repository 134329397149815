// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components';
import { Text } from '~web/features/essentials/primitives/Text';
import { View } from '~web/features/essentials/primitives/View';

type Props = {
  readonly text: string;
};

const StyledView = styled(View)`
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER};
  min-width: 24px;
  border-radius: 12px;
  padding: 0 ${SPACING.STATIC.XS.px};
`;

export const ButtonSideText = ({ text }: Props) => {
  return (
    <StyledView>
      <Text
        title={text}
        color="PRIMARY"
      />
    </StyledView>
  );
};
