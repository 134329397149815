import { closeBanner } from '~shared/actions/banners';
import { openModal } from '~shared/actions/modal';
import { selectUser } from '~shared/actions/ui/users';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { Banner } from './Banner';
import getIsOnboardingRoute, {
  getIsOrganicOnboardingLandingPage,
} from '~web/features/onboarding/selectors/getIsOnboardingRoute';

const mapStateToProps = (state: ReduxStateShared) => {
  const { authorized } = state.auth;
  const { showBanner, metadata, bannerType } = state.banners;
  return {
    authorized,
    showBanner,
    metadata,
    bannerType,
    onboarding: state.auth?.onboarding || getIsOnboardingRoute(state) || getIsOrganicOnboardingLandingPage(state),
  };
};

const mapDispatchToProps = {
  closeBanner,
  selectUser,
  openModal,
};

// @ts-expect-error TS(7006): Parameter 'stateProps' implicitly has an 'any' type.
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { metadata } = stateProps;
  const { selectUser, openModal, ...restDispatchProps } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    ...restDispatchProps,
    buttonHandlers: {
      ADD_INSURANCE: () => {
        const { userID } = metadata || {};
        selectUser(userID);
        openModal('INSURANCE_MODAL');
      },
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Banner);
