// @owners { team: patients-team }
// question text
export const LINE_SEPARATED_HEADER = ['Your delivery arrived!', 'Rate your delivery'];
export const FREEFORM_LABEL = 'Tell us more';
export const FREEFORM_LABEL_OPTIONAL = `${FREEFORM_LABEL} (optional)`;
export const FREEFORM_SUBLABEL_OPTIONAL = `Optional`;

// question objects
export const RATING_QUESTION = {
  question: LINE_SEPARATED_HEADER.join(' '),
  required: true,
  type: 'fivestar',
};
export const FREEFORM_QUESTION = {
  question: FREEFORM_LABEL,
  required: false,
  type: 'text',
};

export const NO_RATING_ERROR_MESSAGE = 'Please rate your delivery experience';

export function createSurveyResponseDataPayload(rating: number, freeformReason: string) {
  const data: Record<string, any>[] = [
    { ...RATING_QUESTION, response: rating },
    { ...FREEFORM_QUESTION, response: freeformReason },
  ];
  return data;
}
