// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components';

const StyledGridProductList = styled.ul`
  list-style: none;
  display: grid;
  margin: 0;
  padding: ${SPACING.STATIC.MD.px} ${SPACING.STATIC.LG.px};
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: ${SPACING.STATIC.MD.px};
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

export const GridProductList = ({ children }: { readonly children: React.ReactNode }) => {
  return <StyledGridProductList>{children}</StyledGridProductList>;
};
