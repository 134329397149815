// @owners { team: patients-team }
import { Description, XxsSpacing } from '@alto/design-system';
import React from 'react';
import { PricingInfoRow } from '../../pricing-info/components/PricingInfoRow';
import { useTrackPaymentBreakdownItemViewed } from '../hooks/useTrackPaymentBreakdownItemViewed';

type Props = {
  readonly label: string;
  readonly price: string;
  readonly helperText: string;
  readonly eventParams: Record<string, any>;
};

export const ItemPricing = ({ label, price, helperText, eventParams }: Props) => {
  useTrackPaymentBreakdownItemViewed({ eventParams });

  return (
    <>
      <PricingInfoRow
        label={label}
        price={price}
      />
      <XxsSpacing />
      <Description>{helperText}</Description>
    </>
  );
};
