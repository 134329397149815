// @owners { team: patients-team }
import { XxsSpacing } from '@alto/design-system';
import { type PaymentBreakdown } from '@alto/scriptdash/alto/pricing/types/v3';
import React from 'react';
import { PAYMENT_BREAKDOWN_LABELS } from '~shared/features/pricing/constants';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { PricingInfoRow } from '../../pricing-info/components/PricingInfoRow';
import { useTrackPaymentBreakdownItemViewed } from '../hooks/useTrackPaymentBreakdownItemViewed';
import { EstimatedSavingsAttributions } from './EstimatedSavingsAttributions';
import { SavingsAttributions } from './SavingsAttributions';

const totalLabel = (estimated: boolean, insurancePaid: number) => {
  if (estimated) {
    return insurancePaid > 0 ? PAYMENT_BREAKDOWN_LABELS.PREV_TOTAL_WITH_INSURANCE : PAYMENT_BREAKDOWN_LABELS.PREV_TOTAL;
  }
  return insurancePaid > 0 ? PAYMENT_BREAKDOWN_LABELS.TOTAL_WITH_INSURANCE : PAYMENT_BREAKDOWN_LABELS.TOTAL;
};

type Props = {
  readonly paymentBreakdown: PaymentBreakdown;
  readonly estimated: boolean;
  readonly eventParams: Record<string, any>;
};

export const ItemPricingDetailed = ({ paymentBreakdown, estimated, eventParams }: Props) => {
  useTrackPaymentBreakdownItemViewed({ paymentBreakdown, eventParams });

  return (
    <>
      {paymentBreakdown.cash_price > 0 && (
        <PricingInfoRow
          label={estimated ? PAYMENT_BREAKDOWN_LABELS.PREV_ITEM_PRICE : PAYMENT_BREAKDOWN_LABELS.ITEM_PRICE}
          price={paymentBreakdown.cash_price}
        />
      )}
      {estimated ? (
        <EstimatedSavingsAttributions paymentBreakdown={paymentBreakdown} />
      ) : (
        <SavingsAttributions paymentBreakdown={paymentBreakdown} />
      )}
      {paymentBreakdown.cash_price > 0 && <XxsSpacing />}
      <PricingInfoRow
        label={totalLabel(estimated, paymentBreakdown.insurance_payers_paid)}
        price={formatDollarsWithPlaceholder(paymentBreakdown.total)}
      />
    </>
  );
};
