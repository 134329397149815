import { apiError, apiSent, apiSuccess } from './actions';
import { type ApiResponseRow, type DataSource, type DataSourceResponse } from './types';

// length of ', and n more'
const LENGTH_OF_X_MORE_CLAUSE = 12;

export const summarizeEntries = (entries: ApiResponseRow<any>[], maxCharacterLimit = 32): string => {
  let extraEntries = 0;

  const summary = entries.reduce((ongoingSummary: string, entry: ApiResponseRow<any>): string => {
    if (!ongoingSummary) {
      return entry.label;
    }

    const newSummary = `${ongoingSummary}, ${entry.label}`;

    if (newSummary.length > maxCharacterLimit - LENGTH_OF_X_MORE_CLAUSE) {
      extraEntries += 1;
      return ongoingSummary;
    }

    return newSummary;
  }, '');

  if (extraEntries > 0) {
    return `${summary}, and ${extraEntries} more`;
  }

  return summary;
};

// Text String Helpers
export const escapeRegExp = (textToEscape: string): string => {
  return textToEscape.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export function ellipsize(string: string | null | undefined, maxCharacters = 25): string {
  let ellipsized = string || '';

  if (ellipsized.length > maxCharacters) {
    ellipsized = `${ellipsized.slice(0, maxCharacters - 3)}...`;
  }

  return ellipsized;
}

export const getOriginalCase = (
  fullText: string | null | undefined,
  maybeWrongCasedStringToMatch: string | null | undefined,
): string | null | undefined => {
  if (!fullText || !maybeWrongCasedStringToMatch) {
    return null;
  }

  const matchingRegExp = new RegExp(escapeRegExp(maybeWrongCasedStringToMatch), 'i');
  const match = fullText.match(matchingRegExp);

  if (!match || match.length === 0) {
    return null;
  }

  return match[0];
};

export const callApi = (
  debouncedText: string,
  dataSource: DataSource<any>,
  isInputFocused: boolean,
  dispatch: React.Dispatch<any>,
  shouldAllowManualInput?: boolean,
) => {
  // the input is not focused when running tests, do not return early in tests
  if (!isInputFocused && !process?.env?.JEST_WORKER_ID) {
    return () => undefined;
  }

  if (!debouncedText && !isInputFocused) {
    return () => undefined;
  }

  let hasCancelled = false;
  dispatch(apiSent());
  dataSource(debouncedText)
    .then((response: DataSourceResponse<any>) => {
      // eslint-disable-next-line promise/always-return
      if (hasCancelled) {
        return;
      }

      dispatch(apiSuccess(response, shouldAllowManualInput));
    })
    .catch((error: unknown) => {
      dispatch(apiError(shouldAllowManualInput));
      console.warn(error);
    });

  return () => {
    hasCancelled = true;
  };
};

export const generateInitialValue = (text: string): ApiResponseRow<string> => ({
  title: text,
  value: text,
  label: text,
  key: text,
  isFromAutocomplete: false,
});
