import Channel from './Channel';
import type { Callback, Payload, Unsub } from '~shared/types/websocket';
/*
 * ChannelManager provides a subscription interface for our WebSocket connection,
 * creating new channels for each unique channel name with a subscription, and routing
 * messages to the correct channels as they come in.
 */

export default class ChannelManager {
  channels: Channel[];

  constructor() {
    this.channels = [];
  }

  publish(channelName: string, message: Payload) {
    const channel = this.findChannel(channelName);

    if (channel) {
      channel.publish(message);
    }
  }

  subscribe(channelName: string, subscriber: Callback): Unsub {
    let channel = this.findChannel(channelName);

    if (!channel) {
      channel = this.createChannel(channelName);
    }

    return channel.subscribe(subscriber);
  }

  findChannel(channelName: string): Channel | null | undefined {
    return this.channels.find((c) => c.name === channelName);
  }

  createChannel(channelName: string): Channel {
    const channel = new Channel(channelName);
    this.channels.push(channel);
    return channel;
  }

  onConnect() {
    this.channels.forEach((c) => {
      c.onConnect();
    });
  }

  onDisconnect() {
    this.channels.forEach((c) => {
      c.onDisconnect();
    });
  }

  disconnectedChannels(): Channel[] {
    return this.channels.filter((c) => !c.connected);
  }
}
