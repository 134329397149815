// @owners { team: patients-team }
import { useEffect, useState } from 'react';

// use CSS media-queries for resizing, don't re-render within javascript
export const useDeprecatedWindowResize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const onResize = (event: Event) => {
    const target = event.target as Window;
    setSize({ width: target.innerWidth, height: target.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return size;
};
