// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Link, SimpleContainer, Text } from '@alto/pocky';
import React from 'react';
import { CollapsibleSection } from './CollapsibleSection';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import { phoneSupport } from '~web/constants';

const FAQs = () => (
  <>
    <CollapsibleSection
      title="What are your hours of operation? Where do you deliver?"
      content={
        <div>
          <Text>
            Alto’s hours of operation vary by location. View the full details for each pharmacy at{' '}
            <Link
              href="https://alto.com/locations"
              target="_blank"
              rel="noopener noreferrer"
            >
              alto.com/locations
            </Link>
            .
          </Text>
          <br />
          <Text>
            Have a question? Our team is available Monday - Friday 6 am - 9 pm PT and Saturday - Sunday 7 am - 6 pm PT
            at <Link href={`tel:${phoneSupport}`}>{phoneSupport}</Link> or by secure message in this app.
          </Text>
        </div>
      }
    />
    <CollapsibleSection
      title="My doctor sent you a prescription, and I just created an account, but I don’t see the prescription info in this app. What’s the deal?"
      content={
        <Text>
          An Alto account is automatically created for you when your doctor sends us your first prescription. If you
          created your own account prior to when the doctor sent your prescription, you may have a duplicate account in
          our system. Please give us a call at <Link href={`tel:${phoneSupport}`}>{phoneSupport}</Link>.
        </Text>
      }
    />
    <CollapsibleSection
      title="How will I know when to expect my delivery?"
      content={
        <Text>
          We’ll text or call you when your prescription is ready for delivery; just select the delivery date and window
          that works best for you. Your medications will be hand-delivered by a member of our local courier team, and
          we’ll keep you updated throughout the delivery process via text.
        </Text>
      }
    />
    <CollapsibleSection
      title="I scheduled my delivery but I don’t know what my copay is. When will I know how much it costs?"
      content={
        <Text>
          That means we’re still working to get you the best price on your medications. Once we’ve processed your
          insurance and applied all available coupons, we’ll reach out to confirm your copay before the delivery leaves
          our pharmacy.
        </Text>
      }
    />
    <CollapsibleSection
      title="Can I use my FSA or HSA with Alto?"
      content={
        <Text>
          Yes, you can use FSA and HSA funds at Alto. You can manage your payment options by going to the "Payment
          Methods" section of your Account settings in this app.
        </Text>
      }
    />
    <CollapsibleSection
      title="Why do you need my photo ID?"
      content={
        <Text>
          Per state and federal regulations, we are required to verify your identity when filling prescriptions for
          controlled substances.
        </Text>
      }
    />
    <CollapsibleSection
      title="Is the app secure?"
      content={
        <Text>
          Yes, we take security very seriously at Alto. We encrypt our user data and are fully HIPAA-compliant to ensure
          your privacy.
        </Text>
      }
    />
  </>
);

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'FAQs',
  },
];

export const ProfileSupport = () => {
  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexRow>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <PageHeader>FAQs</PageHeader>
        </FullFlexRow>
        <FullFlexRow>
          <SimpleContainer>
            <FAQs />
          </SimpleContainer>
        </FullFlexRow>
      </ContentWrapper>
    </PageBackground>
  );
};
