import React from 'react';
import { type ToastElement } from './Toast';

/**
 * External API for Toast components
 */
export type ToastContextInterface = {
  addToast: (toast: ToastElement) => void;
  clearToasts: () => void;
};

export const ToastContext = React.createContext<ToastContextInterface>({
  addToast: () => null,
  clearToasts: () => null,
});
