// @owners { team: patients-team }
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';

type WebsocketMessagePayload = {
  event_type: string;
  result: WundercomMessage;
};

export type SupportCaseChatbot = {
  id: number | null | undefined;
  locked_at: string | null | undefined;
  chatbot_session_status: string | null | undefined;
};

type WebsocketSupportCasePayload = {
  event_type: string;
  result: SupportCaseChatbot;
};

export const transformWebsocketMessagePayload = (payload: WebsocketMessagePayload) => {
  return {
    id: payload.result.id,
    user_id: payload.result.user_id,
    admin_user_id: payload.result.admin_user_id,
    body: payload.result.body,
    read_at: payload.result.read_at,
    created_at: payload.result.created_at,
    updated_at: payload.result.updated_at,
    support_case_id: payload.result.support_case_id,
    admin_user_name: payload.result.admin_user_name,
    suggested_responses: payload.result.suggested_responses,
    is_support_chatbot: payload.result.is_support_chatbot,
    classified_intent: payload.result.classified_intent,
    title: payload.result.title,
  };
};

export const transformWebsocketSupportCasePayload = (payload: WebsocketSupportCasePayload) => {
  return {
    id: payload.result.id,
    locked_at: payload.result.locked_at,
    chatbot_session_status: payload.result.chatbot_session_status,
  };
};
