// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import { EventTypes } from 'redux-segment';
import { removeUnschedulablePrescriptionsFromCart } from './cart';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type ApiOptions } from '~shared/helpers/api';
import { destroy, get, post, put } from '~shared/helpers/apiHelper';
import { type ReduxDispatch, type ReduxGetStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { type Prescription, type RenewalRequestTimelineItem, type Transfer } from '~shared/types/clients';

const V3_API_VERSION = 'v3';
const v3Options: ApiOptions = {
  version: V3_API_VERSION,
};

const FETCH_ALL_FRESH_THRESHOLD = 1000 * 60 * 60; // 60 minutes

export const FETCH_ACTIVE_PRESCRIPTIONS_LOADING = 'FETCH_ACTIVE_PRESCRIPTIONS_LOADING';

export function fetchingActivePrescriptions() {
  return {
    type: FETCH_ACTIVE_PRESCRIPTIONS_LOADING,
  } as const;
}

export const FETCH_ACTIVE_PRESCRIPTIONS_SUCCEEDED = 'FETCH_ACTIVE_PRESCRIPTIONS_SUCCEEDED';

export function fetchActivePrescriptionsSucceeded(prescriptions: Prescription[]) {
  return {
    type: FETCH_ACTIVE_PRESCRIPTIONS_SUCCEEDED,
    payload: prescriptions,
  } as const;
}

export const FETCH_PRESCRIPTIONS_LOADING = 'FETCH_PRESCRIPTIONS_LOADING';

export function fetchingPrescriptions() {
  return {
    type: FETCH_PRESCRIPTIONS_LOADING,
  } as const;
}

export const FETCH_PRESCRIPTIONS_SUCCEEDED = 'FETCH_PRESCRIPTIONS_SUCCEEDED';

export function fetchPrescriptionsSucceeded(prescriptions: Prescription[]) {
  return {
    type: FETCH_PRESCRIPTIONS_SUCCEEDED,
    payload: prescriptions,
  } as const;
}

export const FETCH_PRESCRIPTIONS_FAILED = 'FETCH_PRESCRIPTIONS_FAILED';

export function fetchPrescriptionsFailed(error: APIError) {
  return {
    type: FETCH_PRESCRIPTIONS_FAILED,
    payload: error,
    error: true,
  } as const;
}

export const CLEAR_PRESCRIPTIONS_ERROR = 'CLEAR_PRESCRIPTIONS_ERROR';

export function clearPrescriptionsError() {
  return {
    type: CLEAR_PRESCRIPTIONS_ERROR,
  } as const;
}

export const UPDATE_PRESCRIPTION_LOADING = 'UPDATE_PRESCRIPTION_LOADING';

export function updatingPrescription() {
  return {
    type: UPDATE_PRESCRIPTION_LOADING,
  } as const;
}

export const UPDATE_PRESCRIPTION_SUCCEEDED = 'UPDATE_PRESCRIPTION_SUCCEEDED';

export function updatePrescriptionSucceeded(prescription: Prescription) {
  return {
    type: UPDATE_PRESCRIPTION_SUCCEEDED,
    payload: prescription,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: UPDATE_PRESCRIPTION_SUCCEEDED,
          properties: {
            resource_type: 'Prescription',
          },
        },
      },
    },
  } as const;
}

export const UPDATE_PRESCRIPTION_FAILED = 'UPDATE_PRESCRIPTION_FAILED';

export function updatePrescriptionFailed(error: APIError | null) {
  return {
    type: UPDATE_PRESCRIPTION_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

export const UNARCHIVE_PRESCRIPTION_LOADING = 'UNARCHIVE_PRESCRIPTION_LOADING';

export function unarchivingPrescription() {
  return {
    type: UNARCHIVE_PRESCRIPTION_LOADING,
  } as const;
}

export const UNARCHIVE_PRESCRIPTION_SUCCEEDED = 'UNARCHIVE_PRESCRIPTION_SUCCEEDED';

export function unarchivePrescriptionSucceeded(prescription: Prescription) {
  return {
    type: UNARCHIVE_PRESCRIPTION_SUCCEEDED,
    payload: prescription,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: UNARCHIVE_PRESCRIPTION_SUCCEEDED,
          properties: {
            resource_type: 'Prescription',
          },
        },
      },
    },
  } as const;
}

export const UNARCHIVE_PRESCRIPTION_FAILED = 'UNARCHIVE_PRESCRIPTION_FAILED';

export function unarchivePrescriptionFailed(error: APIError) {
  return {
    type: UNARCHIVE_PRESCRIPTION_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

export const ARCHIVE_PRESCRIPTION_LOADING = 'ARCHIVE_PRESCRIPTION_LOADING';

export function archivingPrescription() {
  return {
    type: ARCHIVE_PRESCRIPTION_LOADING,
  };
}

export const ARCHIVE_PRESCRIPTION_SUCCEEDED = 'ARCHIVE_PRESCRIPTION_SUCCEEDED';

export function archivePrescriptionSucceeded(prescription: Prescription) {
  return {
    type: ARCHIVE_PRESCRIPTION_SUCCEEDED,
    payload: prescription,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: ARCHIVE_PRESCRIPTION_SUCCEEDED,
          properties: {
            resource_type: 'Prescription',
          },
        },
      },
    },
  } as const;
}

export const ARCHIVE_PRESCRIPTION_FAILED = 'ARCHIVE_PRESCRIPTION_FAILED';

export function archivePrescriptionFailed(error: APIError) {
  return {
    type: ARCHIVE_PRESCRIPTION_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

export const FETCH_TRANSFERS_LOADING = 'FETCH_TRANSFERS_LOADING';

export function fetchingTransfers() {
  return {
    type: FETCH_TRANSFERS_LOADING,
  } as const;
}

export const FETCH_TRANSFERS_SUCCEEDED = 'FETCH_TRANSFERS_SUCCEEDED';

export function fetchTransfersSucceeded(transfers: Transfer[]) {
  return {
    type: FETCH_TRANSFERS_SUCCEEDED,
    payload: transfers,
  } as const;
}

export const FETCH_TRANSFERS_FAILED = 'FETCH_TRANSFERS_FAILED';

export function fetchTransfersFailed(error: APIError) {
  return {
    type: FETCH_TRANSFERS_FAILED,
    payload: error,
    error: true,
  } as const;
}

export const DELETE_TRANSFER_LOADING = 'DELETE_TRANSFER_LOADING';

export function deletingTransfer() {
  return {
    type: DELETE_TRANSFER_LOADING,
  } as const;
}

export const DELETE_TRANSFER_SUCCEEDED = 'DELETE_TRANSFER_SUCCEEDED';

export function deleteTransferSucceeded(transfer: Transfer) {
  return {
    type: DELETE_TRANSFER_SUCCEEDED,
    payload: transfer,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: DELETE_TRANSFER_SUCCEEDED,
          properties: {
            resource_type: 'Transfer',
            resource_id: transfer.id,
          },
        },
      },
    },
  } as const;
}

export const DELETE_TRANSFER_FAILED = 'DELETE_TRANSFER_FAILED';

export function deleteTransferFailed(error: APIError) {
  return {
    type: DELETE_TRANSFER_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

export const CHANGE_PRESCRIPTION_FILTER = 'CHANGE_PRESCRIPTION_FILTER';

export function changePrescriptionFilter(filter: string) {
  return {
    type: CHANGE_PRESCRIPTION_FILTER,
    payload: filter,
  } as const;
}

export const CHANGE_PRESCRIPTION_FILTER_STRING = 'CHANGE_PRESCRIPTION_FILTER_STRING';

export function changePrescriptionFilterString(filter: string) {
  return {
    type: CHANGE_PRESCRIPTION_FILTER_STRING,
    payload: filter,
  } as const;
}

export const SELECT_PRESCRIPTION = 'SELECT_PRESCRIPTION';

export function selectPrescription(rxID: number) {
  return {
    type: SELECT_PRESCRIPTION,
    payload: rxID,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: SELECT_PRESCRIPTION,
          properties: {
            resource_type: 'Prescription',
            resource_id: rxID,
          },
        },
      },
    },
  } as const;
}

export const DESELECT_PRESCRIPTION = 'DESELECT_PRESCRIPTION';

export function deselectPrescription() {
  return {
    type: DESELECT_PRESCRIPTION,
  } as const;
}

export const REQUEST_RENEWAL_LOADING = 'REQUEST_RENEWAL_LOADING';

export function requestingRenewal() {
  return {
    type: REQUEST_RENEWAL_LOADING,
  } as const;
}

export const REQUEST_RENEWAL_SUCCEEDED = 'REQUEST_RENEWAL_SUCCEEDED';

export function requestRenewalSucceeded(prescription: Prescription) {
  return {
    type: REQUEST_RENEWAL_SUCCEEDED,
    payload: prescription,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: REQUEST_RENEWAL_SUCCEEDED,
          properties: {
            resource_type: 'Prescription',
            resource_id: prescription.id,
          },
        },
      },
    },
  } as const;
}

export const REQUEST_RENEWAL_FAILED = 'REQUEST_RENEWAL_FAILED';

export function requestRenewalFailed(error: APIError) {
  return {
    type: REQUEST_RENEWAL_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

export const FETCH_RENEWAL_REQUEST_TIMELINE_LOADING = 'FETCH_RENEWAL_REQUEST_TIMELINE_LOADING';

export function fetchingRenewalRequestTimeline() {
  return {
    type: FETCH_RENEWAL_REQUEST_TIMELINE_LOADING,
  } as const;
}

export const FETCH_RENEWAL_REQUEST_TIMELINE_SUCCEEDED = 'FETCH_RENEWAL_REQUEST_TIMELINE_SUCCEEDED';

export function fetchRenewalRequestTimelineSucceeded(timeline: RenewalRequestTimelineItem[], prescriptionID: number) {
  return {
    type: FETCH_RENEWAL_REQUEST_TIMELINE_SUCCEEDED,
    payload: {
      prescriptionID,
      timeline,
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: FETCH_RENEWAL_REQUEST_TIMELINE_SUCCEEDED,
          properties: {
            resource_type: 'Prescription',
            resource_id: prescriptionID,
          },
        },
      },
    },
  } as const;
}

export const FETCH_RENEWAL_REQUEST_TIMELINE_FAILED = 'FETCH_RENEWAL_REQUEST_TIMELINE_FAILED';

export function fetchRenewalRequestTimelineFailed(error: APIError) {
  return {
    type: FETCH_RENEWAL_REQUEST_TIMELINE_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

export const CANCEL_RENEWAL_LOADING = 'CANCEL_RENEWAL_LOADING';

export function cancelingRenewal() {
  return {
    type: CANCEL_RENEWAL_LOADING,
  } as const;
}

export const CANCEL_RENEWAL_SUCCEEDED = 'CANCEL_RENEWAL_SUCCEEDED';

export function cancelRenewalSucceeded(prescription: Prescription) {
  return {
    type: CANCEL_RENEWAL_SUCCEEDED,
    payload: prescription,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: CANCEL_RENEWAL_SUCCEEDED,
          properties: {
            resource_type: 'Prescription',
            resource_id: prescription.id,
          },
        },
      },
    },
  } as const;
}

export const CANCEL_RENEWAL_FAILED = 'CANCEL_RENEWAL_FAILED';

export function cancelRenewalFailed(error: APIError) {
  return {
    type: CANCEL_RENEWAL_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  } as const;
}

/**
 * Fetches only the active prescriptions for the user.
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
function fetchActivePrescriptions() {
  return async (dispatch: ReduxDispatch) => {
    const options = {
      version: V3_API_VERSION,
    };
    const params = {
      batchley: true,
      active: true,
    };
    return get('/prescriptions', params, options).then((json) => {
      if (json.error) {
        dispatch(fetchPrescriptionsFailed(json.error));
        return false;
      }

      dispatch(fetchActivePrescriptionsSucceeded(json));
      dispatch(removeUnschedulablePrescriptionsFromCart());
      return json;
    });
  };
}

/**
 * Fetches all prescriptions for the user. This is a very expensive call, because some users have hundreds of prescriptions.
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
function fetchAllPrescriptions() {
  return async (dispatch: ReduxDispatch) => {
    const options = {
      version: V3_API_VERSION,
    };
    const params = {
      batchley: true,
    };
    return get('/prescriptions', params, options).then((json) => {
      if (json.error) {
        dispatch(fetchPrescriptionsFailed(json.error));
        return false;
      }

      dispatch(fetchPrescriptionsSucceeded(json));
      dispatch(removeUnschedulablePrescriptionsFromCart());
      return json;
    });
  };
}

/**
 * The fetchPrescriptions call will either fetch just active prescriptions or all prescriptions if it's been an hour
 * since the last fetch all prescriptions.
 *
 * We've strategically done this to avoid the performance hit of fetching all prescriptions every time we call fetchPrescriptions,
 * because in most cases, we don't need to do this.
 *
 * Ideally, the default fetchPrescriptions call does not do this fetch split based off of time. We should be explicit about which
 * prescriptions we care about and make targeted calls to fetch only those prescriptions.
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc*
 */
export function fetchPrescriptions(fetchAllOverride?: boolean) {
  return async (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const { fetchPrescriptionsLoading, fetchActivePrescriptionsLoading } = getLoading(state);
    const shouldFetchAll =
      Date.now() - (state.prescriptions?.lastFetchAllRequestSucceededAt || 0) > FETCH_ALL_FRESH_THRESHOLD;

    if (fetchPrescriptionsLoading || fetchActivePrescriptionsLoading) {
      return Promise.resolve();
    }

    if (fetchAllOverride || shouldFetchAll) {
      dispatch(fetchingPrescriptions());
      return dispatch(fetchAllPrescriptions());
    }
    dispatch(fetchingActivePrescriptions());
    return dispatch(fetchActivePrescriptions());
  };
}

export function fetchPrescriptionFromID(prescriptionID: number) {
  return async (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    let prescription = getPrescriptionByID(state, prescriptionID);

    // If we can't find the prescription, refetch prescription data and try again
    if (!prescription) {
      const prescriptions = await dispatch(fetchPrescriptions(true));
      prescription = (prescriptions || [])?.find((prescription: Prescription) => prescription.id === prescriptionID);

      if (!prescription) {
        return Promise.reject(new Error('Unable to fetch prescription'));
      }
    }

    return prescription;
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function updatePrescription(prescription: Partial<Prescription>) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(updatingPrescription());
    const options = v3Options;
    return put(`/prescriptions/${prescription.id}`, prescription, options).then((json) => {
      if (json.error) {
        dispatch(updatePrescriptionFailed(json.error));
        return false;
      }

      dispatch(updatePrescriptionSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchTransfers() {
  return async (dispatch: ReduxDispatch) => {
    dispatch(fetchingTransfers());
    return get('/transfers', {}, v3Options).then((json) => {
      if (json.error) {
        dispatch(fetchTransfersFailed(json.error));
        return false;
      }

      dispatch(fetchTransfersSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function deleteTransfer(transfer: { id: number }) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(deletingTransfer());
    return destroy(`/transfers/${transfer.id}`, v3Options).then((json) => {
      if (json.error) {
        dispatch(deleteTransferFailed(json.error));
        return false;
      }

      dispatch(deleteTransferSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function unarchivePrescription(prescription: Prescription) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(unarchivingPrescription());
    const options = v3Options;
    return put(`/prescriptions/${prescription.id}/unarchive`, {}, options).then((json) => {
      if (json.error) {
        dispatch(unarchivePrescriptionFailed(json.error));
        return false;
      }

      dispatch(unarchivePrescriptionSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function archivePrescription(prescription: Prescription) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(archivingPrescription());
    const options = v3Options;
    return put(`/prescriptions/${prescription.id}/archive`, {}, options).then((json) => {
      if (json.error) {
        dispatch(archivePrescriptionFailed(json.error));
        return false;
      }

      dispatch(archivePrescriptionSucceeded(json));
      return true;
    });
  };
}

export function requestRenewal(prescription_id: number) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(requestingRenewal());

    return post(`/prescriptions/${prescription_id}/request_renewal`).then((json) => {
      if (json.error) {
        dispatch(requestRenewalFailed(json.error));
        return false;
      }

      dispatch(requestRenewalSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchRenewalRequestTimeline(prescription: Prescription) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(fetchingRenewalRequestTimeline());

    return get(`/prescriptions/${prescription.id}/renewal_request_timeline`).then((json) => {
      if (json.error) {
        dispatch(fetchRenewalRequestTimelineFailed(json.error));
        return false;
      }

      dispatch(fetchRenewalRequestTimelineSucceeded(json, prescription.id));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function cancelRenewal(prescription: Prescription) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(cancelingRenewal());

    return put(`/prescriptions/${prescription.id}/cancel_renewal`).then((json) => {
      if (json.error) {
        dispatch(cancelRenewalFailed(json.error));
        return false;
      }

      dispatch(cancelRenewalSucceeded(json));
      return true;
    });
  };
}

export const AUTOBILL_PRESCRIPTION_LOADING = 'AUTOBILL_PRESCRIPTION_LOADING';

export function autobillingPrescription(id: number) {
  return {
    type: AUTOBILL_PRESCRIPTION_LOADING,
    id,
  } as const;
}

export const AUTOBILL_PRESCRIPTION_SUCCEEDED = 'AUTOBILL_PRESCRIPTION_SUCCEEDED';

// @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
export function autobillPrescriptionSucceeded(data, id) {
  return {
    type: AUTOBILL_PRESCRIPTION_SUCCEEDED,
    payload: data,
    id,
  } as const;
}

export const AUTOBILL_PRESCRIPTION_FAILED = 'AUTOBILL_PRESCRIPTION_FAILED';

export function autobillPrescriptionFailed(error: APIError, id: number) {
  return {
    type: AUTOBILL_PRESCRIPTION_FAILED,
    payload: error,
    error: true,
    id,
  } as const;
}

export const CLEAR_AUTOBILL_PRESCRIPTION_ERROR = 'CLEAR_AUTOBILL_PRESCRIPTION_ERROR';
export type ActionClearAutobillPrescriptionError = {
  type: typeof CLEAR_AUTOBILL_PRESCRIPTION_ERROR;
};

export function clearAutobillPrescriptionError(): ActionClearAutobillPrescriptionError {
  return {
    type: CLEAR_AUTOBILL_PRESCRIPTION_ERROR,
  } as const;
}

export type BillingOverridePayload = {
  reason: string;
  data?: VacationSupplyOverrideData;
};

type VacationSupplyOverrideData = {
  travel_destination: string;
  travel_start_date: string;
  travel_end_date: string;
};
export type autoBillPrescriptionResult = {
  success: boolean;
  autobillSuccess: boolean;
};
/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function autobillPrescription(id: number, billing_overrides?: BillingOverridePayload) {
  return async (dispatch: ReduxDispatch): Promise<autoBillPrescriptionResult> => {
    dispatch(autobillingPrescription(id));
    const data = {
      prescription_id: id,
      billing_overrides,
    };

    return post('/prescriptions/autobill', data, v3Options).then((json) => {
      if (json.error) {
        dispatch(autobillPrescriptionFailed(json.error, id));
        return { success: false, autobillSuccess: false };
      }

      dispatch(autobillPrescriptionSucceeded(json, id));
      return { success: true, autobillSuccess: json.autobill_success };
    });
  };
}

export const UPDATE_AUTO_REFILL_LOADING = 'UPDATE_AUTO_REFILL_LOADING';

type UpdateAutoRefillLoadingAction = {
  type: typeof UPDATE_AUTO_REFILL_LOADING;
};

export function updatingAutoRefill(): UpdateAutoRefillLoadingAction {
  return {
    type: UPDATE_AUTO_REFILL_LOADING,
  };
}

export const UPDATE_AUTO_REFILL_SUCCEEDED = 'UPDATE_AUTO_REFILL_SUCCEEDED';

type UpdateAutoRefillSucceededAction = {
  type: typeof UPDATE_AUTO_REFILL_SUCCEEDED;
};

export function updateAutoRefillSucceeded(): UpdateAutoRefillSucceededAction {
  return {
    type: UPDATE_AUTO_REFILL_SUCCEEDED,
  };
}

export const UPDATE_AUTO_REFILL_FAILED = 'UPDATE_AUTO_REFILL_FAILED';

type UpdateAutoRefillFailedAction = {
  type: typeof UPDATE_AUTO_REFILL_FAILED;
  error: true;
  payload: APIError | null;
};

export function updateAutoRefillFailed(error: APIError | null): UpdateAutoRefillFailedAction {
  return {
    type: UPDATE_AUTO_REFILL_FAILED,
    error: true,
    payload: error,
  };
}

export const CLEAR_UPDATE_AUTO_REFILL_ERROR = 'CLEAR_UPDATE_AUTO_REFILL_ERROR';
export type ActionClearUpdateAutoRefillError = {
  type: typeof CLEAR_UPDATE_AUTO_REFILL_ERROR;
};

export function clearUpdateAutoRefillError(): ActionClearUpdateAutoRefillError {
  return {
    type: CLEAR_UPDATE_AUTO_REFILL_ERROR,
  };
}

export const updateAutoRefill = (prescriptionID: number, newAutoRefillStatus: boolean) => {
  return async (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    dispatch(updatingAutoRefill());

    return dispatch(
      updatePrescription({
        auto_refill: newAutoRefillStatus,
        id: prescriptionID,
      }),
    ).then((wasSuccessful: boolean) => {
      if (!wasSuccessful) {
        const state = getState();
        const error = getErrors(state).updatePrescriptionError;
        dispatch(updateAutoRefillFailed(error));
        return false;
      }

      dispatch(updateAutoRefillSucceeded());
      return true;
    });
  };
};
