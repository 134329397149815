import { createSelector } from 'reselect';
import { getDeliveriesSortedByDateDesc } from './getDefaultAddress';
import { getOrder } from './getOrder';
import { getWindows } from '~shared/features/checkout/selectors/getCart';
import { type LightDeliveryWindow, type Window } from '~shared/features/checkout/types';
import { isCourier__DEPRECATED } from '~shared/helpers/order';
import { type LightDelivery as Delivery } from '~shared/types/clients';

const getAvailableWindow = (window: LightDeliveryWindow, windows: Window[]) => {
  if (!window) {
    return undefined;
  }

  const windowIsAvailable = windows.some(
    (w) => w.deliver_before === window.deliver_before && w.deliver_after === window.deliver_after,
  );

  if (windowIsAvailable) {
    return window;
  }

  return undefined;
};

const getMostRecentlyUsedWindowForAddress = (sortedDeliveries: Delivery[], addressID?: number) => {
  for (const delivery of sortedDeliveries) {
    if (delivery.address_id === addressID && delivery.deliver_before && delivery.deliver_after) {
      const window: LightDeliveryWindow = {
        deliver_before: delivery.deliver_before,
        deliver_after: delivery.deliver_after,
      };

      return window;
    }
  }

  return undefined;
};

const getWindowFromMostRecentDelivery = (sortedDeliveries: Delivery[]) => {
  if (!sortedDeliveries.length) {
    return undefined;
  }

  const mostRecentDelivery = sortedDeliveries[0];
  const { deliver_before, deliver_after } = mostRecentDelivery;
  if (deliver_before && deliver_after) {
    const window: LightDeliveryWindow = {
      deliver_before,
      deliver_after,
    };
    return window;
  }

  return undefined;
};

/*
 returns a default delivery window in this priority order:
   1. the most recently used window for the current delivery address if it's a valid window
   2. the window from the most recent delivery if it's a valid window
   3. the first available window for this date
   4. undefined, if there are no known delivery windows
*/
const getDefaultDeliveryWindow = createSelector(
  [getDeliveriesSortedByDateDesc, getOrder, getWindows],
  (deliveries, order, windows) => {
    if (!windows.length) {
      return undefined;
    }

    const firstWindow: LightDeliveryWindow = {
      deliver_before: windows[0].deliver_before,
      deliver_after: windows[0].deliver_after,
    };

    const courierDeliveries = deliveries.filter(
      (delivery) => delivery.delivery_method && isCourier__DEPRECATED(delivery.delivery_method),
    );

    // Calculate the most recently used window for the current delivery address if it's a valid window
    const recentWindowForAddress = getMostRecentlyUsedWindowForAddress(courierDeliveries, order.address_id);
    const availableWindowForAddress = recentWindowForAddress
      ? getAvailableWindow(recentWindowForAddress, windows)
      : undefined;

    // Calculate the window from the most recent delivery if it's a valid window
    const recentWindowFromDelivery = getWindowFromMostRecentDelivery(courierDeliveries);
    const availableWindowFromDelivery = recentWindowFromDelivery
      ? getAvailableWindow(recentWindowFromDelivery, windows)
      : undefined;

    return availableWindowForAddress || availableWindowFromDelivery || firstWindow;
  },
);

export default getDefaultDeliveryWindow;
