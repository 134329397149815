// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type UnconfirmedShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/unconfirmed_shipment';
import { type UpcomingShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import { type Shipment as OrderSuccessShipment } from '@alto/scriptdash/alto/patient_app/order_success/types/v1/shipment';
import { useIsAutoRefillOnlyShipment } from '~shared/features/auto-refill/hooks/useIsAutoRefillOnlyShipment';
import { numberOfDaysUntil } from '~shared/helpers/date';
import { isCourier } from '~shared/helpers/order';

/**
 * @param shipment - shipment (either UpcomingShipment or OrderSuccessShipment)
 * @returns boolean - is the shipment eligible for mail auto refill
 */

type Shipment = UpcomingShipment | OrderSuccessShipment | UnconfirmedShipment | null;

type DeliveryMethodType = DeliveryMethod | null | undefined;

export const useEligibleForMailAutoRefill = ({
  shipment,
  deliveryMethod,
}: {
  shipment: Shipment;
  deliveryMethod: DeliveryMethodType;
}) => {
  const DAYS_BEFORE_SHIPMENT = 5;
  const isCourierMethod = isCourier(deliveryMethod?.mode);
  const isAutoRefill = useIsAutoRefillOnlyShipment({ shipmentID: shipment?.id });
  const enoughTimeBeforeShipment = shipment?.date ? numberOfDaysUntil(shipment?.date) > DAYS_BEFORE_SHIPMENT : false;

  return isCourierMethod && isAutoRefill && enoughTimeBeforeShipment;
};
