// @owners { team: patients-team }
import { AltoIcon } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, Text, spacing } from '@alto/pocky';
import React, { PureComponent } from 'react';

type Props = {
  readonly text: string;
  readonly small?: boolean;
  readonly light?: boolean;
};

export default class UserTag extends PureComponent<Props> {
  static defaultProps = {
    small: false,
  };

  render() {
    const { text, small, light } = this.props;

    return (
      <FlexColumn
        verticallyAlignContent
        spacing={spacing.xsSpacing}
      >
        <AltoIcon
          name="user-small"
          type="grey"
        />
        <Text
          small={small}
          light={light}
        >
          {text}
        </Text>
      </FlexColumn>
    );
  }
}
