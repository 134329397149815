// @owners { team: patients-team }

export type CancelDeliveriesFormFields = {
  cancelation_reason?: string;
  cancelation_info?: string;
};

export const CANCEL_DELIVERIES_FORM_ERROR_MESSAGES = {
  cancelation_reason: 'Please indicate a cancelation reason.',
  cancelation_info: 'Please provide some information behind your cancelation.',
};
