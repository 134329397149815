import { ActionSheetContext } from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { GetItSoonerActionSheet } from '~web/features/my-meds/components/modals/GetItSoonerActionSheet';
import { MultipleRxNextAvailableDateActionSheet } from '~web/features/my-meds/components/modals/MultipleRxNextAvailableDate.action-sheet';

export const useGetItSoonerFlow = () => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { prescriptions } = usePrescriptionsInCart();
  const openGetItSoonerFlow = useCallback(() => {
    if (prescriptions.length === 1) {
      setActiveActionSheet(
        <GetItSoonerActionSheet
          prescription={prescriptions[0]}
          onClose={() => undefined}
          origin="date picker"
        />,
      );
    } else {
      setActiveActionSheet(<MultipleRxNextAvailableDateActionSheet />);
    }
  }, [prescriptions, setActiveActionSheet]);

  return { openGetItSoonerFlow };
};
