// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Description,
  H2,
  LgPadding,
  ListDescription,
  ListItem,
  Pressable,
  Toast,
  ToastContext,
} from '@alto/design-system';
import React, { useContext } from 'react';
import { Linking } from 'react-native';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getRemoveBlockingMedMessagingForPrescription__DEPRECATED } from '~shared/features/next-available-date/selectors/getNextAvailableDateMessaging';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getPrescriptionUserIsFertility } from '~shared/features/prescriptions/selectors/getPrescriptionUser';
import { isC2 } from '~shared/helpers/helper';
import { useSelectorShared } from '~shared/store';
import { ContactUs, type OrderTypeContext, useCanRemoveMedication, useRemoveFromCart } from '../../../../index';

type Props = {
  readonly prescriptionID: number;
  readonly handleModalClose: () => void;
  readonly orderTypeContext?: OrderTypeContext;
};

export const WantItSoonerPanel = ({ prescriptionID, handleModalClose, orderTypeContext }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { addToast } = useContext(ToastContext);
  const { cartItems } = useCartData();
  const { handleRemoveFromCart } = useRemoveFromCart();

  const prescriptionItemsCount = cartItems.filter((item) => item.cart_item_type === 'PRESCRIPTION').length;

  // Fertility users should be nudged to message in. Contact us component should be embedded instead.
  const isFertilityUser = useSelectorShared((state) => getPrescriptionUserIsFertility(state, prescriptionID));
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const removeBlockingMedMessage = useSelectorShared((state) =>
    getRemoveBlockingMedMessagingForPrescription__DEPRECATED(state, prescriptionID),
  );
  const isControlledMedication = prescription ? isC2(prescription) : false;
  const callProviderTitle = isControlledMedication ? 'Call your provider' : 'Or call your provider';

  const { canRemoveMedication } = useCanRemoveMedication({ orderTypeContext });
  const showRemoveMedicationOption = canRemoveMedication && prescriptionItemsCount > 1 && !prescription?.is_add_on_otc;

  const handleCallProviderPress = () => {
    const phoneNumber = prescription?.provider_phone?.replace(/\D/g, '');
    if (phoneNumber) Linking.openURL(`tel://${phoneNumber}`);
  };

  const handleRemoveMedicationPress = () => {
    const itemKey = prescriptionToItemKey(prescriptionID);
    handleRemoveFromCart({ item: itemKey, properties: { source: 'cart_summary' } });
    closeActionSheet();
    addToast(<Toast>{`${prescription?.medication_name ?? 'Medication'} removed from cart`}</Toast>);
  };

  return (
    <>
      <LgPadding bottomPadding={SPACING.STATIC.XS}>
        <H2>Get it sooner</H2>
      </LgPadding>
      {!isFertilityUser && !isControlledMedication ? (
        <ListItem
          title="Contact another pharmacy"
          LeftContent={<AltoIcon name="phone-duo" />}
          descriptions={
            <ListDescription>
              {`Transfer ${prescription?.medication_name ?? 'this medication'} to another pharmacy`}
            </ListDescription>
          }
        />
      ) : null}
      {!isFertilityUser ? (
        <Pressable
          disabled={!prescription?.provider_phone}
          onPress={handleCallProviderPress}
          accessibilityLabel="Press to call your provider"
        >
          <ListItem
            title={callProviderTitle}
            LeftContent={<AltoIcon name="doctor-duo" />}
            RightContent={prescription?.provider_phone ? <AltoIcon name="chevronright-small" /> : undefined}
            descriptions={<ListDescription>Ask them to send a new prescription to another pharmacy.</ListDescription>}
          />
        </Pressable>
      ) : null}
      {showRemoveMedicationOption ? (
        <Pressable
          onPress={handleRemoveMedicationPress}
          accessibilityLabel={`Press to remove ${prescription?.medication_name ?? 'this medication'}`}
        >
          <ListItem
            title="Want the rest of your order sooner?"
            LeftContent={<AltoIcon name="trash-duo" />}
            RightContent={<AltoIcon name="chevronright-small" />}
            descriptions={<ListDescription>{removeBlockingMedMessage}</ListDescription>}
          />
        </Pressable>
      ) : null}
      {isFertilityUser ? (
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <Description>
            If you need this prescription urgently, please{' '}
            <ContactUs
              textSize="mini"
              type="link"
              method="message"
              origin={CONTACT_US_ORIGIN.NextAvailableDate}
              onPressCallback={handleModalClose}
            />{' '}
            and we'll see how we can speed things up.
          </Description>
        </LgPadding>
      ) : null}
    </>
  );
};
