// @owners { team: patients-team }
import { ListBase } from '@alto/design-system';
import React from 'react';
import { kebabcase } from '~shared/helpers/string';
import { MenuOption, type MenuOptionProps } from './MenuOption';

type MenuOptionsProps = {
  readonly options: MenuOptionProps[];
};

export const MenuOptions = ({ options }: MenuOptionsProps) => {
  const items = options.map((props) => {
    return {
      // eslint-disable-next-line react/prop-types
      key: kebabcase(props.text),
      component: <MenuOption {...props} />,
    };
  });
  return <ListBase items={items} />;
};
