// @owners { team: patients-team }
import {
  type Attributes,
  type FeatureDefinition,
  GrowthBook,
  GrowthBookProvider,
  useFeatureValue as useFeatureValueGrowthBook,
} from '@growthbook/growthbook-react';
import React, { type PropsWithChildren, type ReactElement, useEffect } from 'react';
import { type AppFeatures } from './app-features';

let growthBookInstance: null | GrowthBook = null;
const isTest = !!process.env.JEST_WORKER_ID;
const initError = 'Experimentation.init has not been called yet';

type Interface = {
  /**
   * returns a provider that wraps the app and loads the feature flags. this is needed to use the Growthbook hooks
   * @example
   * const App = () => (
   *   <Experimentation.Provider>
   *     <App />
   *   </Experimentation.Provider>
   * )
   */
  Provider: ({ children }: PropsWithChildren) => ReactElement;
  /**
   * returns the list of features and their values
   * @example
   * const features = Experimentation.getFeatures()
   */
  getFeatures: () => Record<keyof AppFeatures, FeatureDefinition>;
  /**
   * initializes the experimentation library
   * @param clientKey the client key for the experimentation library
   * @param onTrackExperiment callback that is called when an experiment is tracked
   * @example
   * Experimentation.init('123', (experimentKey, resultKey) => {});
   */
  init: (clientKey: string, onTrackExperiment: (experimentKey: string, resultKey: string) => void) => GrowthBook;
  /**
   * sets the attributes for the experimentation library
   * @param attributes the attributes to set
   * @example
   * Experimentation.setAttributes({ userId: '123' });
   */
  setAttributes: (attributes: Attributes) => void;
  /**
   * Gets the feature flag value of the feature key.
   * @param featureFlag the feature flag key
   * @param defaultValue the default value to return if Growthbook is not ready (optional)
   * @example
   * // Growthbook ready (server data)
   * const { value, isLoading } = Experimentation.useFeatureFlag('flagName', 2); // { value: 99, isLoading: false }
   * // Growthbook not ready (client default value)
   * const { value, isLoading } = Experimentation.useFeatureFlag('flagName', 2); // { value: 2, isLoading: true }
   */
  useFeatureFlag: <Key extends keyof AppFeatures>(
    featureFlag: Key,
    defaultValue?: AppFeatures[Key],
  ) => { isLoading: boolean; value: AppFeatures[Key] };
};

export const Experimentation: Interface = {
  init: (clientKey: string, onTrackExperiment: (experimentKey: string, resultKey: string) => void) => {
    growthBookInstance = new GrowthBook<AppFeatures>({
      apiHost: 'https://growthbook-api.prod.alto.com',
      clientKey,
      enableDevMode: __DEV__,
      subscribeToChanges: true,
      trackingCallback: (experiment, result) => {
        onTrackExperiment(experiment.key, result.key);
      },
    });
    return growthBookInstance;
  },
  setAttributes: (attributes: Attributes) => {
    if (!growthBookInstance) throw new Error(initError);
    growthBookInstance.setAttributes(attributes);
  },
  getFeatures: () => {
    if (!growthBookInstance) throw new Error(initError);
    return growthBookInstance.getFeatures();
  },
  Provider: ({ children }: PropsWithChildren) => {
    if (!growthBookInstance) throw new Error(initError);

    useEffect(() => {
      if (!growthBookInstance) throw new Error(initError);
      void growthBookInstance.loadFeatures();
    }, []);

    return <GrowthBookProvider growthbook={growthBookInstance}>{children}</GrowthBookProvider>;
  },
  useFeatureFlag: <Key extends keyof AppFeatures>(featureFlag: Key, defaultValue?: AppFeatures[Key]) => {
    if (!growthBookInstance && !isTest) throw new Error(initError);
    // https://github.com/growthbook/growthbook/pull/1730
    // @ts-expect-error Type 'Record<string, unknown>' is not assignable to type 'JSONValue'.
    const value = (useFeatureValueGrowthBook(featureFlag, defaultValue) as AppFeatures[Key]) ?? false;
    return { value, isLoading: !growthBookInstance?.ready };
  },
};
