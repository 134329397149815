// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Description, XxsSpacing } from '@alto/design-system';
import React from 'react';

type Props = {
  readonly text: string;
};

export const StripeInputLabel = ({ text }: Props) => (
  <Description
    fontFamily="semibold"
    color={COLORS.TEXT_COLORS.PRIMARY}
  >
    {text}
    <XxsSpacing />
    <Description color={COLORS.TEXT_COLORS.DANGER}>*</Description>
  </Description>
);
