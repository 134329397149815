// @owners { team: patients-team }
import { type MedSyncStatus, MedSyncStatusMap } from '@alto/scriptdash/alto/patients/types/v1/med_sync_status';
import React from 'react';
import { push } from 'react-router-redux';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { type ANALYTICS_EVENT, EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';
import { MedSyncUpsellCard } from '~web/features/homescreen/sections/MedSyncUpsellCard';
import { MED_SYNC_SELF_SERVICE_ROUTES } from '~web/features/med-sync-self-service/RoutingContainer';

type Props = {
  readonly status?: MedSyncStatus | null;
};

export const MedSyncUpsellSection = ({ status }: Props) => {
  const dispatch = useDispatchShared();

  const navigateWithAnalytics = (route: MED_SYNC_SELF_SERVICE_ROUTES, eventName: ANALYTICS_EVENT) => {
    dispatch(sendAnalyticsEvent(createEvent(eventName, { med_sync_status: status })));
    dispatch(push(route));
  };

  switch (status) {
    case MedSyncStatusMap.ELIGIBLE:
    case MedSyncStatusMap.UNRESPONSIVE:
      return (
        <MedSyncUpsellCard
          title="Simplify your deliveries"
          description="Bundle all of your medications in a single, recurring delivery, for free."
          buttonLabel="Bundle medications"
          onClick={() => {
            navigateWithAnalytics(MED_SYNC_SELF_SERVICE_ROUTES.INFO, EVENTS.MED_SYNC_HOMESCREEN_ELIGIBLE_CARD_CLICKED);
          }}
        />
      );
    case MedSyncStatusMap.INTERESTED:
      return (
        <MedSyncUpsellCard
          title="Finish bundling your medications"
          description="Confirm which medications you'd like in a single, recurring delivery."
          buttonLabel="Select medications"
          onClick={() => {
            navigateWithAnalytics(
              MED_SYNC_SELF_SERVICE_ROUTES.SELECT_MEDICATIONS,
              EVENTS.MED_SYNC_HOMESCREEN_INTERESTED_CARD_CLICKED,
            );
          }}
        />
      );
    case MedSyncStatusMap.ENROLLED:
    default:
      return null;
  }
};
