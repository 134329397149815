// @owners { team: patients-team }
import { SIZES, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Button,
  Description,
  H1,
  LgSpacing,
  MdSpacing,
  XlSpacing,
  useScreenSize,
} from '@alto/design-system';
import { AddressFormActionSheet } from '@alto/features';
import React, { useContext, useEffect } from 'react';
import { MISSING_ADDRESS } from '~shared/features/essentials/add_ons_consts';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type Shipment } from '~shared/types';
import { StoreHeaderWrapper } from './StoreHeaderWrapper';
import { useSidePadding } from './useSidePadding';
import { FullPage } from '~web/features/essentials/components';
import { Image, View } from '~web/features/essentials/primitives';

type Props = {
  readonly shipment: Shipment | undefined;
};

export const MissingAddress = ({ shipment }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackPageView } = useAnalytics();
  const { title, description, image, buttonText } = MISSING_ADDRESS;
  const { isSMScreen } = useScreenSize();
  const isMobile = isSMScreen;
  const sidePadding = useSidePadding();
  const padding = isMobile ? SPACING.STATIC.XL.px : `0 ${sidePadding} 0 ${SPACING.STATIC.XXL.px}`;
  const imageSize = isMobile ? SIZES.ILLUSTRATION.LG.px : SIZES.ILLUSTRATION.XL.px;

  useEffect(() => {
    trackPageView({ event: EVENTS.ESSENTIALS__STORE_NO_ADDRESS_VIEWED });
  }, [trackPageView]);

  const handleAddressPress = () => {
    setActiveActionSheet(<AddressFormActionSheet />);
  };

  return (
    <>
      <FullPage>
        <StoreHeaderWrapper shipment={shipment}>
          <XlSpacing />
        </StoreHeaderWrapper>
        <View
          flexDirection="column"
          flexShrink={1}
          flexBasis="65%"
          backgroundColor="WHITE"
          padding={padding}
          justifyContent={isMobile ? 'flex-start' : 'center'}
        >
          <View justifyContent="center">
            <Image
              src={image}
              alt="icon of address location"
              width={imageSize}
              height={imageSize}
            />
          </View>
          <LgSpacing />
          <H1 center>{title}</H1>
          <MdSpacing />
          <Description center>{description}</Description>
          <XlSpacing />
          <View justifyContent="center">
            <Button
              label={buttonText}
              onPress={handleAddressPress}
            />
          </View>
        </View>
      </FullPage>
    </>
  );
};
