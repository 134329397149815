export const VIEW_NOTICE = 'VIEW_NOTICE';

export type ActionViewNotice = {
  type: typeof VIEW_NOTICE;
  payload: string;
};

export function viewNotice(notice: string): ActionViewNotice {
  return {
    type: VIEW_NOTICE,
    payload: notice,
  };
}

export const SET_BADGE_COUNTS = 'SET_BADGE_COUNTS';
export type BadgeCounts = {
  messages?: number; // count of new wundercom messages not associated with a support case
  patientTasks?: number;
};
export type ActionSetBadgeCounts = {
  type: typeof SET_BADGE_COUNTS;
  payload: BadgeCounts;
};

export function setBadgeCounts(badgeCounts: BadgeCounts): ActionSetBadgeCounts {
  return {
    type: SET_BADGE_COUNTS,
    payload: badgeCounts,
  };
}
