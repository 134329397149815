import {
  type ActionClearSelectedPaymentTypes,
  type ActionSelectPriceOption,
  CLEAR_SELECTED_PAYMENT_TYPES,
  SELECT_PRICE_OPTION,
} from '~shared/actions/ui/priceOptions';
import { withRehydration } from '~shared/reducers/withRehydration';
import { type PricingResultPaymentType } from '~shared/types';

export const initialState = {
  selectedPaymentTypes: {},
};

export type SelectedPaymentTypes = Record<number, PricingResultPaymentType>;

export type StateUiPriceOptions = {
  selectedPaymentTypes: SelectedPaymentTypes;
};

type Action = ActionSelectPriceOption | ActionClearSelectedPaymentTypes;

const priceOptions = (state: StateUiPriceOptions = initialState, action: Action): StateUiPriceOptions => {
  const { type } = action;

  switch (type) {
    case SELECT_PRICE_OPTION: {
      const { payload } = action;
      const { paymentType, prescriptionID } = payload;
      const selectedPaymentTypes = { ...state.selectedPaymentTypes };
      selectedPaymentTypes[prescriptionID] = paymentType;
      return { ...state, selectedPaymentTypes };
    }

    case CLEAR_SELECTED_PAYMENT_TYPES: {
      return { ...state, selectedPaymentTypes: {} };
    }

    default:
      return state;
  }
};

export default withRehydration(priceOptions, initialState);
