import { COLORS } from '@alto/design-library-tokens';
import {
  AltoIcon,
  Body,
  Column,
  FlatCard,
  H2,
  Illustration,
  LgPadding,
  Pressable,
  Row,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import React from 'react';

type Props = {
  readonly description?: string;
  readonly label: string;
  readonly illustrationAlt: string;
  readonly illustration: any;
  readonly onClick: () => void;
};

export const MedicationsSectionNavigator = ({ description, label, illustration, onClick, illustrationAlt }: Props) => {
  return (
    <Pressable
      accessibilityLabel={`navigate to ${label}`}
      backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
      onPress={onClick}
      pressedBackgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST}
    >
      <FlatCard>
        <LgPadding>
          <Row
            spaceBetween
            centerVertically
            wrap={false}
          >
            <Illustration
              size="sm"
              source={illustration}
              accessibilityLabel={illustrationAlt}
            />
            <SmSpacing />
            <Column
              flexGrow={1}
              flexShrink={1}
            >
              <H2>{label}</H2>
              <XsSpacing />
              {!!description && <Body color={COLORS.TEXT_COLORS.GREY}>{description}</Body>}
            </Column>
            <AltoIcon
              name="chevronright"
              accessibilityLabel="Right arrow"
            />
          </Row>
        </LgPadding>
      </FlatCard>
    </Pressable>
  );
};
