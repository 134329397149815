import { type AltoIconName, SIZES, type TEXT_COLORS_VALUES } from '@alto/design-library-tokens';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import styled from 'styled-components/native';
import { Row } from '../../../containers';
import { AltoIcon, getIconTypeFromColor } from '../../../icon';
import { SmSpacing, XsSpacing } from '../../../separators';
import { Description, Text } from '../../../typography';
import { type ButtonWidth } from './Button';
import { type ButtonSideCopy } from './ButtonSideCopy';

const SideContentPlaceholder = styled(View)<{ small?: boolean; width?: ButtonWidth }>`
  width: ${({ small, width }) => {
    if (small && width === 'inline') return 0;
    return SIZES.ICON.MD.px;
  }};
`;

type SideContentProps = {
  readonly side: 'left' | 'right';
  readonly iconName?: AltoIconName;
  readonly SideComponent?: React.ReactElement<typeof ButtonSideCopy | typeof AltoIcon>;
} & Pick<ButtonLabelProps, 'labelColor' | 'small' | 'width' | 'spaceBetween'>;

const SideContentWrapper = ({
  side,
  iconName,
  SideComponent,
  labelColor,
  small,
  width,
  spaceBetween,
}: SideContentProps) => {
  if (!iconName && !SideComponent) {
    return (
      <SideContentPlaceholder
        small={small}
        width={width}
      />
    );
  }

  return (
    <>
      {!spaceBetween && side === 'left' && <XsSpacing />}
      {iconName ? (
        <AltoIcon
          name={iconName}
          type={getIconTypeFromColor(labelColor)}
        />
      ) : (
        SideComponent
      )}
      {!spaceBetween && side === 'right' && <XsSpacing />}
    </>
  );
};

export type ButtonLabelProps = {
  readonly LeftContent?: React.ReactElement<typeof ButtonSideCopy | typeof AltoIcon>;
  readonly RightContent?: React.ReactElement<typeof ButtonSideCopy | typeof AltoIcon>;
  readonly iconLeftName?: AltoIconName;
  readonly iconRightName?: AltoIconName;
  readonly label: string;
  readonly labelColor: TEXT_COLORS_VALUES;
  readonly small?: boolean;
  readonly loading?: boolean;
  readonly loadingLabel?: string;
  readonly spaceBetween?: boolean;
  readonly width?: ButtonWidth;
};

/**
 * The button's text that can include icons on either the left and/or right
 * @param {ButtonLabelProps} props component props
 * @returns ButtonLabel
 */
export const ButtonLabel = ({
  LeftContent,
  RightContent,
  iconLeftName,
  iconRightName,
  label,
  labelColor,
  small,
  loading,
  loadingLabel,
  spaceBetween,
  width,
}: ButtonLabelProps) => {
  const labelText = loading ? loadingLabel : label;
  const Label = small ? (
    <Description
      color={labelColor}
      fontFamily="semibold"
    >
      {labelText}
    </Description>
  ) : (
    <Text
      tight
      fontFamily="semibold"
      color={labelColor}
    >
      {labelText}
    </Text>
  );

  return (
    <Row
      spaceBetween
      centerVertically
    >
      {loading ? (
        <Row
          flexGrow={1}
          center
        >
          <ActivityIndicator color={labelColor} />
          {!!labelText && (
            <>
              <SmSpacing />
              {Label}
            </>
          )}
        </Row>
      ) : (
        <>
          <SideContentWrapper
            side="left"
            small={small}
            width={width}
            iconName={iconLeftName}
            SideComponent={LeftContent}
            labelColor={labelColor}
            spaceBetween={spaceBetween}
          />
          {Label}
          <SideContentWrapper
            side="right"
            small={small}
            width={width}
            iconName={iconRightName}
            SideComponent={RightContent}
            labelColor={labelColor}
            spaceBetween={spaceBetween}
          />
        </>
      )}
    </Row>
  );
};
