// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Column, H1, Illustration, MdPadding, MdSpacing, SecondaryPage, XsSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useEffect } from 'react';
import { setAddFamilyMemberRelationship } from '~shared/actions/ui/addFamilyMember';
import AddFamilyMemberImage from '~shared/assets/images/add_family_member.png';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { getPossessiveName } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { FamilyMemberAddedCard } from './FamilyMemberAddedCard';

const isWeb = getPlatformOS() === 'web';

const formatDateOfBirth = (dob: string) => {
  const [year, month, day] = dob.split('-');
  return [month, day, year].join('/');
};

export const FamilyMemberAdded = () => {
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const { getParam, navigate } = useNavigation<'RouteFamilyMemberAdded'>();
  const hasCompletedOnboarding = getParam('hasCompletedOnboarding', false);
  const selectedUser = useSelectorShared(getSelectedUser);
  const firstName = selectedUser?.preferred_first_name ?? selectedUser?.first_name ?? 'Family member';
  const lastName = selectedUser?.last_name ?? '';
  const dateOfBirth = selectedUser?.date_of_birth ? formatDateOfBirth(selectedUser.date_of_birth) : '';

  useEffect(() => {
    trackPageView({ event: EVENTS.FAMILY_ACCOUNTS_FAMILY_MEMBER_ADDED_VIEWED });
  }, [trackPageView]);

  const handleClose = () => {
    dispatch(setAddFamilyMemberRelationship(''));
    navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
  };

  return (
    <SecondaryPage
      dismissIcon="close"
      onDismiss={handleClose}
      headerBackgroundColor={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      HeaderContent={
        <MdPadding topPadding={SPACING.STATIC.NONE}>
          <Column center>
            <Illustration
              source={AddFamilyMemberImage}
              accessibilityLabel="family holding hands"
              size={isWeb ? 'xl' : 'lg'}
            />
            <MdSpacing />
            <H1 center>Family member added!</H1>
            <XsSpacing />
            <Body center>
              {hasCompletedOnboarding
                ? `We found ${getPossessiveName(firstName)} Alto account and added it to your family.`
                : `${getPossessiveName(firstName)} account has been created and added to your family.`}
            </Body>
            <MdSpacing />
          </Column>
        </MdPadding>
      }
    >
      <FamilyMemberAddedCard
        firstName={firstName}
        lastName={lastName}
        dateOfBirth={dateOfBirth}
      />
    </SecondaryPage>
  );
};
