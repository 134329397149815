// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Body, Row, XsSpacing } from '@alto/design-system';
import React from 'react';

export default function TipQualifiedExpenseWarning() {
  return (
    <Row wrap={false}>
      <AltoIcon
        name="info"
        type="success"
      />
      <XsSpacing />
      <Body color={COLORS.TEXT_COLORS.SUCCESS}>Tips are not qualified expenses for FSA or HSA cards.</Body>
    </Row>
  );
}
