// @owners { team: patients-team }
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getItemsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import { getOrder, getOrderAddressId } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex, getOrdersByIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { getIsUnmodifiedProgynyWizardFlow } from '~shared/selectors/checkout/progyny';
import { useSelectorShared } from '~shared/store';
import { getOrderParams } from '../helpers/getOrderParams';

export const useGetOrderParams = () => {
  const addressID = useSelectorShared(getOrderAddressId);
  const editOrderShipmentID = useSelectorShared(getEditShipmentID);
  const items = useSelectorShared(getItemsInOrder__DEPRECATED);
  const scheduledWithProgynyWizard = useSelectorShared(getIsUnmodifiedProgynyWizardFlow);
  const ordersByIndex = useSelectorShared(getOrdersByIndex);
  const { compounds } = usePrescriptionsInCart();
  const compoundIDs = new Set(compounds.map((rx) => rx.id));

  // when handleSaveOrder runs, the current order is stored in redux
  // the redux value may not be up to date when this function runs
  // so ensure the last order is in ordersByIndex before creating order params
  const order = useSelectorShared(getOrder);
  const orderIndex = useSelectorShared(getOrderIndex);
  ordersByIndex[orderIndex] = order;

  const { cartItemsByDeliveryMethod, deliveryMethods } = useCartDeliveryMethods();

  const createOrderParams = () => {
    return getOrderParams({
      addressID,
      compoundIDs, // need in the case of split shipments mail/mail where both orders shipped fedex_priority_overnight
      ordersByIndex,
      cartItemsByDeliveryMethod,
      deliveryMethods,
      editOrderShipmentID,
      items,
      scheduledWithProgynyWizard,
    });
  };

  return { createOrderParams };
};
