import { createSelector } from 'reselect';
import { PRICING_SCENARIOS } from '~shared/features/pricing/constants';
import { getOrderPricing, getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

const getAllowPriceOptionChange = createSelector(
  [getPrescriptionPricing, getOrderPricing, getPrescriptionID],
  (prescriptionPricing, orderPricing, prescriptionID) => {
    return (
      prescriptionPricing[prescriptionID]?.scenario === PRICING_SCENARIOS.CASH_LESS_THAN_INSURANCE ||
      orderPricing.item_pricing[prescriptionID]?.scenario === PRICING_SCENARIOS.CASH_LESS_THAN_INSURANCE
    );
  },
);

export default getAllowPriceOptionChange;
