// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Link, Row, Text } from '@alto/pocky';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, Main, PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import rx from '~web/images/prescription@2x.png';
import transfer from '~web/images/transfer@2x.png';

type Props = {
  readonly startTransfer: () => void;
  readonly openPharmacyInfoModal: () => void;
  readonly fetchFacilities: () => void;
}; // FullFlexRow but without the child selector margins

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: ${SPACING.STATIC.LG.rem};
  margin-right: 0;
  width: 100%;
`;

const BodyCopy = styled(Text)<{ isSMScreenOrBigger: boolean }>`
  margin-bottom: ${SPACING.STATIC.XS.rem};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-bottom: ${SPACING.STATIC.LG.rem};`}
`;

const Card = styled(Row).attrs({
  verticallyAlignContent: true,
  evenlySpaceContent: true,
  spacing: SPACING.STATIC.MD.rem,
})<{ isSMScreenOrBigger: boolean }>`
  max-width: 25em;
  width: 100%;
  padding: ${SPACING.STATIC.MD.rem};
  border: ${BORDERS.SIZE}px solid ${COLORS.BORDER_COLORS.PRIMARY};
  border-radius: 4px;
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  box-shadow: 0 1px 5px rgba(25, 32, 38, 0.1);
  cursor: pointer;
  margin-top: ${SPACING.STATIC.MD.rem};

  /*
   * removes height: 100% set in Row with verticallyAlignContent
   * this allows them to be equal heights regardless of copy
   */
  height: initial;

  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-top: 0;`}
`;

const CardCopyContainer = styled(Column).attrs({
  leftAlignContent: true,
  evenlySpaceContent: true,
  parentHeight: true,
})``;

const CardImage = styled.img`
  height: 60px;
  width: 60px;
`;

// to handle spacing when wrapped
const Spacer = styled.div`
  height: ${SPACING.STATIC.MD.rem};
  width: ${SPACING.STATIC.MD.rem};
`;
const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Send prescriptions to Alto',
  },
];

const SendPrescriptionOptions = ({ startTransfer, fetchFacilities, openPharmacyInfoModal }: Props) => {
  useEffect(() => {
    fetchFacilities();
  }, [fetchFacilities]);

  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexRow>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <PageHeader>Send Prescriptions</PageHeader>
        </FullFlexRow>
        <Main>
          <MainContent>
            <BodyCopy
              isSMScreenOrBigger={isSMScreenOrBigger}
              bold
            >
              We make it easy and quick to bring your prescriptions to Alto.
            </BodyCopy>
            <Row wrap>
              <Card
                isSMScreenOrBigger={isSMScreenOrBigger}
                onClick={startTransfer}
              >
                <CardCopyContainer>
                  <Text>Let Alto get my prescriptions from another pharmacy.</Text>
                  <Link>Get Started</Link>
                </CardCopyContainer>
                <CardImage
                  src={transfer}
                  alt="Illustration of paper airplane representing doctor prescription"
                />
              </Card>
              <Spacer />
              <Card
                isSMScreenOrBigger={isSMScreenOrBigger}
                onClick={openPharmacyInfoModal}
              >
                <CardCopyContainer>
                  <Text>Show Alto's information to my doctor.</Text>
                  <Link>View Pharmacy Info</Link>
                </CardCopyContainer>
                <CardImage
                  src={rx}
                  alt="Illustration of paper prescription"
                />
              </Card>
            </Row>
          </MainContent>
        </Main>
      </ContentWrapper>
    </PageBackground>
  );
};

export default SendPrescriptionOptions;
