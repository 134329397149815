// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Modal, ModalContent, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import HeaderImage, { MODAL_SIZE } from '~web/components/HeaderImage';
import doublePillBottle from '~web/images/double_pill_bottle@2x.png';

export const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

type Props = {
  readonly onClose: () => void;
  readonly formattedProviderName: string;
  readonly originalMedName: string;
};

export const AlternativeMedicationModal = ({ onClose, formattedProviderName, originalMedName }: Props) => {
  const formattedOriginalMedName = originalMedName ? (
    <>
      {' '}
      for{' '}
      <Text
        inline
        bold
      >
        {originalMedName}
      </Text>{' '}
    </>
  ) : (
    ' '
  );

  return (
    <StyledModal
      dismissOnOutsideClick
      showDismiss
      onClose={onClose}
    >
      <ModalContent textAlignment="left">
        <Column spacing={SPACING.STATIC.MD.rem}>
          <HeaderImage
            src={doublePillBottle}
            alt={IMG_ALT_TEXTS.doublePillBottle}
            title="Alternative medication"
            imgSize={MODAL_SIZE}
          />
          <Text>
            Your original prescription{formattedOriginalMedName}was not covered by your insurance. Your provider
            {formattedProviderName}has chosen an alternative medication to reduce your costs.
          </Text>
          <Text>
            This alternative is in the same therapeutic class, which means it has similar chemical characteristics and
            ways of working and targets the same condition.
          </Text>
        </Column>
      </ModalContent>
    </StyledModal>
  );
};
