// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, palette } from '@alto/pocky';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

export type Props = {
  readonly totalSteps: number;
  readonly currentIndex: number;
};

const Step = styled.div<{ highlighted: boolean }>`
  background-color: ${(props) => (props.highlighted ? palette.teal1 : palette.grey2)};
  flex-grow: 1;
  height: 4px;
  margin: 2px;
`;

export default class ProgressIndicator extends PureComponent<Props> {
  render() {
    const { totalSteps, currentIndex } = this.props;

    const steps = Array.from(
      {
        length: totalSteps,
      },
      (_, k) => k + 1,
    ).map((s, i) => {
      return (
        <Step
          key={`progress-indicator-step-${s}`}
          highlighted={i <= currentIndex}
        />
      );
    });

    return <FlexColumn>{steps}</FlexColumn>;
  }
}
