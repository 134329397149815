import { type FetchOrderPricingRequest } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { type Dispatch } from 'redux';
import { getFetchPricingOrderParam } from '~shared/features/pricing/selectors/getFetchPricingOrderParam';
import { type OrderPricing, type Pricing } from '~shared/features/pricing/types';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { get, post } from '~shared/helpers/apiHelper';
import { type ReduxGetStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const API_VERSION_V4 = 'v4';
const API_VERSION_V6 = 'v6';

export const FETCH_PRESCRIPTION_PRICING_LOADING = 'FETCH_PRESCRIPTION_PRICING_LOADING';
export type ActionFetchingPrescriptionPricing = {
  type: typeof FETCH_PRESCRIPTION_PRICING_LOADING;
};
export function fetchingPrescriptionPricing(): ActionFetchingPrescriptionPricing {
  return {
    type: FETCH_PRESCRIPTION_PRICING_LOADING,
  };
}

export const FETCH_PRESCRIPTION_PRICING_SUCCEEDED = 'FETCH_PRESCRIPTION_PRICING_SUCCEEDED';
type FetchPrescriptionPricingSucceededPayload = { item_pricing: Pricing[] };
export type ActionFetchPrescriptionPricingSucceeded = {
  type: typeof FETCH_PRESCRIPTION_PRICING_SUCCEEDED;
  payload: FetchPrescriptionPricingSucceededPayload;
};

export function fetchPrescriptionPricingSucceeded(
  prescriptions: FetchPrescriptionPricingSucceededPayload,
): ActionFetchPrescriptionPricingSucceeded {
  return {
    type: FETCH_PRESCRIPTION_PRICING_SUCCEEDED,
    payload: prescriptions,
  };
}

export const FETCH_PRESCRIPTION_PRICING_FAILED = 'FETCH_PRESCRIPTION_PRICING_FAILED';
export type ActionFetchPrescriptionPricingFailed = {
  type: typeof FETCH_PRESCRIPTION_PRICING_FAILED;
  payload: APIError;
  error: true;
};
export function fetchPrescriptionPricingFailed(error: APIError): ActionFetchPrescriptionPricingFailed {
  return {
    type: FETCH_PRESCRIPTION_PRICING_FAILED,
    payload: error,
    error: true,
  };
}

export const FETCH_ORDER_PRICING_LOADING = 'FETCH_ORDER_PRICING_LOADING';
type ActionFetchingOrderPricing = {
  type: typeof FETCH_ORDER_PRICING_LOADING;
};
export function fetchingOrderPricing(): ActionFetchingOrderPricing {
  return {
    type: FETCH_ORDER_PRICING_LOADING,
  };
}

export type OrderPricingAPIResponse = Omit<OrderPricing, 'item_pricing' | 'shipment_id'> & { item_pricing: Pricing[] };

export const FETCH_ORDER_PRICING_SUCCEEDED = 'FETCH_ORDER_PRICING_SUCCEEDED';
type FetchOrderPricingSucceededPayload = OrderPricingAPIResponse & {
  isShipmentPricing: boolean;
  isEditPricing: boolean;
};
export type ActionFetchOrderPricingSucceeded = {
  type: typeof FETCH_ORDER_PRICING_SUCCEEDED;
  payload: FetchOrderPricingSucceededPayload;
};
export function fetchOrderPricingSucceeded(
  orderPricing: FetchOrderPricingSucceededPayload,
): ActionFetchOrderPricingSucceeded {
  return {
    type: FETCH_ORDER_PRICING_SUCCEEDED,
    payload: orderPricing,
  };
}

export const FETCH_ORDER_PRICING_FAILED = 'FETCH_ORDER_PRICING_FAILED';
export type ActionFetchOrderPricingFailed = {
  type: typeof FETCH_ORDER_PRICING_FAILED;
  payload: APIError;
  error: true;
};
export function fetchOrderPricingFailed(error: APIError): ActionFetchOrderPricingFailed {
  return {
    type: FETCH_ORDER_PRICING_FAILED,
    payload: error,
    error: true,
  };
}

type PrescriptionActions =
  | ActionFetchingPrescriptionPricing
  | ActionFetchPrescriptionPricingFailed
  | ActionFetchPrescriptionPricingSucceeded;

type OrderActions = ActionFetchingOrderPricing | ActionFetchOrderPricingFailed | ActionFetchOrderPricingSucceeded;

export type PricingActions = PrescriptionActions | OrderActions;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchPrescriptionPricing() {
  return (dispatch: Dispatch<PrescriptionActions>) => {
    dispatch(fetchingPrescriptionPricing());
    return get('/pricing/prescriptions', {}, { version: API_VERSION_V4 }).then((json) => {
      if (json.error) {
        dispatch(fetchPrescriptionPricingFailed(json.error));
        return false;
      }

      dispatch(fetchPrescriptionPricingSucceeded(json));
      return true;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const fetchOrderPricing = (bundlingOptionShipmentID?: number) => {
  return (dispatch: Dispatch<OrderActions>, getState: ReduxGetStateShared) => {
    dispatch(fetchingOrderPricing());
    const state = getState();
    const isEditingOrder = getIsEditingExistingOrder(state);
    const params: FetchOrderPricingRequest = {
      order: getFetchPricingOrderParam(state),
      bundling_option_shipment_id: bundlingOptionShipmentID,
      shipment_id: undefined,
    };

    return post('/pricing/order', params, { version: API_VERSION_V6 }).then((json) => {
      if (json.error) {
        dispatch(fetchOrderPricingFailed(json.error));
        return false;
      }

      dispatch(fetchOrderPricingSucceeded({ ...json, isShipmentPricing: false, isEditPricing: isEditingOrder }));
      return true;
    });
  };
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchShipmentPricing(shipmentID: number) {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch(fetchingOrderPricing());
    return post(
      '/pricing/order',
      {
        shipment_id: shipmentID,
      },
      { version: API_VERSION_V6 },
    ).then((json) => {
      if (json.error) {
        dispatch(fetchOrderPricingFailed(json.error));
        return false;
      }

      dispatch(
        fetchOrderPricingSucceeded({
          ...json,
          isShipmentPricing: true,
          isEditPricing: false,
        }),
      );
      return true;
    });
  };
}
