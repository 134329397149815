// @owners { team: patients-team }
import { useEffect, useRef } from 'react';

/**
 * The `usePrevious` hook is a useful tool for tracking the previous value of a variable in a functional component.
 * This can be particularly handy in scenarios where it is necessary to compare the current value with the previous one, such as triggering actions or rendering based on changes.
 * @see https://usehooks.com/useprevious
 * @param {any} value - The value to track
 * @return {any} The previous value
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
