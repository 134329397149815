// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES } from '@alto/design-library-tokens';
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { formatWindowTime } from '~shared/helpers/date';

type Props = {
  readonly startTime?: string | null;
  readonly endTime?: string | null;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  /**
   * deprecated; from original on demand delivery pilot
   */
  readonly isOnDemandDelivery?: boolean;
  readonly isAsapDelivery?: boolean;
};

export const TimeWindowRow = ({ startTime, endTime, backgroundColor, isOnDemandDelivery, isAsapDelivery }: Props) => {
  // if both times are not given and not ordered on demand, return nothing
  if (!isOnDemandDelivery && !(startTime && endTime)) {
    return null;
  }

  let datetime = '';

  if (isAsapDelivery && endTime) {
    datetime += `By ${formatWindowTime({ windowTime: endTime, meridiem: 'a' })} (estimated)`;
  } else if (isOnDemandDelivery) {
    datetime += 'ASAP';
  } else if (!!startTime && !!endTime) {
    datetime += `${formatWindowTime({ windowTime: startTime, meridiem: 'a' })} - ${formatWindowTime({ windowTime: endTime, meridiem: 'a' })}`;
  }

  return (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name="clock"
          type="grey"
        />
      }
      text={datetime}
      backgroundColor={backgroundColor}
    />
  );
};
