// @owners { team: patients-team }
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getNextDeliveryByPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { partitionMedicationsByActiveState } from '~shared/features/my-meds/selectors/partitionMedicationsByActiveState';
import { formatRelativeDateWithDow } from '~shared/helpers/date';
import hasAutoRefills from '~shared/helpers/hasAutoRefills';

export const getMedsWithAutoRefill = createSelector(
  [partitionMedicationsByActiveState],
  ({ active, needsAttention }) => {
    const medications = [...active, ...needsAttention];
    return medications.filter((medication) => hasAutoRefills(medication));
  },
);

export const getFormattedDeliveryDateForAutoRefillPrescriptions = createSelector(
  [getMedsWithAutoRefill, getNextDeliveryByPrescriptionID],
  (medsWithAutoRefill, nextDeliveriesByPrescriptionID) => {
    const deliveryDatesByPrescriptionID: Record<number, string> = {};

    medsWithAutoRefill.forEach(({ prescriptions }) => {
      prescriptions.forEach(({ id }) => {
        const delivery = nextDeliveriesByPrescriptionID[id];
        let date = get(delivery, 'date');

        if (date) {
          date = formatRelativeDateWithDow(date);
        }

        deliveryDatesByPrescriptionID[id] = date || 'TBD';
      });
    });

    return deliveryDatesByPrescriptionID;
  },
);
