import React from 'react';
import { Toast, type ToastProps } from '../alerts';
import { Anchor } from '../containers';

/**
 * Toasts cannot appear above action sheets due to react-native-modal use of React Portals
 * Use this component to show a toast inside of an ActionSheet.
 */
export const ActionSheetToast = ({
  actionLabel,
  actionOnPress,
  children,
  delay,
  dismissible,
  duration,
  fullBleed,
  onHide,
  onPress,
  testID,
  variant = 'success',
}: ToastProps) => {
  return (
    <Anchor
      top={undefined}
      pointerEvents="box-none"
      center
    >
      <Toast
        actionLabel={actionLabel}
        actionOnPress={actionOnPress}
        delay={delay}
        duration={duration}
        fullBleed={fullBleed}
        onHide={onHide}
        onPress={onPress}
        dismissible={dismissible}
        testID={testID}
        variant={variant}
      >
        {children}
      </Toast>
    </Anchor>
  );
};
