// @owners { team: patients-team }
import { BREAKPOINTS } from '@alto/design-library-tokens';
import { useEffect, useState } from 'react';

// use CSS media-queries for resizing, don't re-render within javascript
export const useDeprecatedMediaQuery = (breakpoint: keyof typeof BREAKPOINTS) => {
  const query = `(min-width: ${BREAKPOINTS[breakpoint]}px)`;
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [matches, query]);

  return matches;
};
