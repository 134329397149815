// @owners { team: patients-team }
import { type FetchShipmentTipsForUserResponse as Response } from '@alto/scriptdash/alto/patients/v3/courier_tips/wunderbar/v1/shipment_tips_endpoint';
import { type Dispatch } from 'redux';
import api from '~shared/features/courier-tips/api';
import { type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const FETCH_SHIPMENT_TIPS_LOADING = 'FETCH_SHIPMENT_TIPS_LOADING';
export const FETCH_SHIPMENT_TIPS_SUCCEEDED = 'FETCH_SHIPMENT_TIPS_SUCCEEDED';
export const FETCH_SHIPMENT_TIPS_FAILED = 'FETCH_SHIPMENT_TIPS_FAILED';

type ResponseData = Response['data'];
type ParsedResponse = {
  data: ResponseData;
  error: APIError;
};

export type FetchShipmentTipsLoadingAction = {
  type: typeof FETCH_SHIPMENT_TIPS_LOADING;
};
export function fetchShipmentTipsLoading(): FetchShipmentTipsLoadingAction {
  return {
    type: FETCH_SHIPMENT_TIPS_LOADING,
  };
}

export type FetchShipmentTipsSucceededAction = {
  type: typeof FETCH_SHIPMENT_TIPS_SUCCEEDED;
  payload: ResponseData;
};
export function fetchShipmentTipsSucceeded(payload: ResponseData): FetchShipmentTipsSucceededAction {
  return {
    type: FETCH_SHIPMENT_TIPS_SUCCEEDED,
    payload,
  };
}

export type FetchShipmentTipsFailedAction = {
  type: typeof FETCH_SHIPMENT_TIPS_FAILED;
  payload: APIError;
};
export function fetchShipmentTipsFailed(payload: APIError): FetchShipmentTipsFailedAction {
  return {
    type: FETCH_SHIPMENT_TIPS_FAILED,
    payload,
  };
}

export type FetchShipmentTipsActions =
  | FetchShipmentTipsLoadingAction
  | FetchShipmentTipsSucceededAction
  | FetchShipmentTipsFailedAction;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchShipmentTipsForUser(user_id: User['id']) {
  return async (dispatch: Dispatch<FetchShipmentTipsActions>): Promise<ResponseData | APIError> => {
    dispatch(fetchShipmentTipsLoading());

    try {
      // @ts-expect-error TS(2741): Property 'error' is missing in type 'FetchShipmentTipsForUserResponse' but required in type 'ParsedR... (Delete me to see the full error)
      const { data, error }: ParsedResponse = await api.fetchShipmentTipsForUser({
        user_id,
      });

      // NOTE: Failed API responses are not rejected, so we have to throw here
      if (error) {
        throw error;
      }

      dispatch(fetchShipmentTipsSucceeded(data));
      return data;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to parameter of type 'APIError'.
      dispatch(fetchShipmentTipsFailed(error));
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'ShipmentTip[] | APIError'.
      return error;
    }
  };
}
