import { COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { type GestureResponderEvent, Pressable, View } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';
import { useScreenSize } from '../../../utils';
import { Row } from '../../containers';
import { ListItem } from '../../lists';
import { type ListItemProps } from '../../lists/src/ListItem/ListUtils';
import { MdSpacing } from '../../separators';
import { Indicator } from './Indicator';
import { Rail } from './Rail';
import { getCssFromOrientation } from './helpers';
import { type VerticalEnabled } from './types';

const ResponsiveSpacing = styled(View)<{ isMDScreenOrBigger: boolean; verticalEnabled: VerticalEnabled }>`
  ${({ verticalEnabled, isMDScreenOrBigger }) => css`
    ${getCssFromOrientation({
      verticalEnabled,
      isMDScreenOrBigger,
      horizontalCss: css`
        height: ${SPACING.STATIC.XS.px};
        width: ${SPACING.STATIC.XS.px};
      `,
      verticalCss: css`
        height: ${SPACING.STATIC.LG.px};
        width: ${SPACING.STATIC.LG.px};
      `,
    })}
  `}
`;

const horizontalStationWrapperCss = css`
  flex-direction: column;
`;

const verticalStationWrapperCss = css`
  flex-direction: row;
  align-items: flex-start;
  padding-right: ${SPACING.STATIC.XXS.px};
`;

const Content = styled(View)<{ isMDScreenOrBigger: boolean; verticalEnabled: VerticalEnabled }>`
  flex-shrink: 1;
  ${({ verticalEnabled, isMDScreenOrBigger }) =>
    getCssFromOrientation({
      verticalEnabled,
      isMDScreenOrBigger,
      horizontalCss: horizontalStationWrapperCss,
      verticalCss: verticalStationWrapperCss,
    })}
`;

const Station = styled(Pressable)<{
  isMDScreenOrBigger: boolean;
  verticalEnabled: VerticalEnabled;
  numStations: number;
}>`
  z-index: 0;
  ${({ verticalEnabled, numStations, isMDScreenOrBigger }) => css`
    ${getCssFromOrientation({
      verticalEnabled,
      isMDScreenOrBigger,
      horizontalCss: css`
        flex-direction: row;
        flex-basis: ${100 / numStations}%;
      `,
    })}
  `}
`;

type Props = {
  readonly id: string;
  readonly title: string;
  readonly withSmallTitle?: boolean;
  readonly onPress?: (e: GestureResponderEvent) => void;
} & Pick<ListItemProps, 'descriptions' | 'BottomButton'>;

export const SubwayStation = ({ id, title, withSmallTitle, descriptions, onPress, BottomButton }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  const { isFirst, isFinal, verticalEnabled, numStations, type, status, extendRails } = useTheme();

  const extendFirstRail = extendRails === 'first' || extendRails === 'both';
  const extendLastRail = extendRails === 'last' || extendRails === 'both';

  return (
    <Station
      onPress={onPress}
      key={id}
      verticalEnabled={verticalEnabled}
      numStations={numStations}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      {isFirst && extendFirstRail ? <MdSpacing /> : null}
      {!isFirst || extendFirstRail ? (
        <Rail
          type={type}
          status={status}
          verticalEnabled={verticalEnabled}
          position="before"
        />
      ) : null}
      <Content
        verticalEnabled={verticalEnabled}
        isMDScreenOrBigger={isMDScreenOrBigger}
      >
        <Indicator
          type={type}
          status={status}
        />
        <MdSpacing />
        <Row flexShrink={1}>
          <ListItem
            title={title}
            titleSize={withSmallTitle ? 'small' : 'default'}
            descriptions={descriptions}
            fullBleed
            backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
            BottomButton={BottomButton}
          />
        </Row>
      </Content>
      {!isFinal || extendLastRail ? (
        <Rail
          type={type}
          status={status}
          verticalEnabled={verticalEnabled}
          position="after"
        />
      ) : null}
      {/* Spacing between each station; needs to be in here for bottom Rail to connect to next Station */}
      <ResponsiveSpacing
        verticalEnabled={verticalEnabled}
        isMDScreenOrBigger={isMDScreenOrBigger}
      />
    </Station>
  );
};
