// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Card,
  Description,
  LgPadding,
  ListDescription,
  ListItem,
  MdSpacing,
  Row,
  XxsSpacing,
} from '@alto/design-system';
import React, { memo, useContext, useEffect } from 'react';
import { Platform } from 'react-native';
import { type AnalyticsProps, SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import { COURIER_NEW_TIP_COPY } from '~shared/features/courier-tips/constants';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { CourierTipInput } from './CourierTipInput';
import { TipInfoActionSheet } from './TipInfoActionSheet';

type Props = {
  readonly analyticsProps: AnalyticsProps;
  readonly handleEditTipAmount: (tipAmount: number) => void;
  readonly tipAmount: number;
};

const TipDescription = () => {
  return Platform.OS === 'web' ? (
    <Row>
      <Description>{COURIER_NEW_TIP_COPY.CARD_BODY}</Description>
      <XxsSpacing />
      <AltoIcon
        type="secondary"
        name="info-small"
      />
    </Row>
  ) : (
    <Description>{COURIER_NEW_TIP_COPY.CARD_BODY}</Description>
  );
};

export const CourierTipCard = memo(function CourierTipCardMemo({
  analyticsProps,
  handleEditTipAmount,
  tipAmount,
}: Props) {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);

  const onOpenActionSheet = () => {
    setActiveActionSheet(<TipInfoActionSheet onCloseModal={closeActionSheet} />);
  };

  useEffect(() => {
    const eventKey =
      analyticsProps.source === SOURCES.EDIT_DELIVERY
        ? EVENTS.COURIER_TIPS__EDIT_DELIVERY_TIP_VIEWED
        : EVENTS.COURIER_TIPS__PRE_DELIVERY_TIP_VIEWED;
    dispatch(sendTipAnalyticsEvent({ eventKey, analyticsProps, additionalProps: { tip_amount: tipAmount } }));
  }, [analyticsProps, dispatch, tipAmount]);

  return (
    <Card title="Courier tip">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <ListItem
          descriptions={
            <ListDescription>
              <TipDescription />
            </ListDescription>
          }
          fullBleed
          RightContent={
            Platform.OS === 'web' ? undefined : (
              <AltoIcon
                type="secondary"
                name="info-small"
              />
            )
          }
          onPress={onOpenActionSheet}
        />
        <MdSpacing />
        <CourierTipInput
          analyticsProps={analyticsProps}
          handleEditTipAmount={handleEditTipAmount}
          tipAmount={tipAmount}
        />
      </LgPadding>
    </Card>
  );
});
