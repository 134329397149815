// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Column, H1, MdSpacing, SecondaryPage, SmSpacing, XlPadding } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { FamilyMemberRequestCard } from './FamilyMemberRequestCard';

export const FamilyMemberRequest = () => {
  const { trackPageView } = useAnalytics();
  const { getParam, navigate } = useNavigation<'RouteFamilyMemberRequest'>();
  const dateOfBirth = getParam('dateOfBirth', '');
  const firstName = getParam('firstName', '');
  const lastName = getParam('lastName', '');
  const relationship = getParam('relationship', '');
  const supportCaseID = Number(getParam('supportCaseID', undefined));

  useEffect(() => {
    trackPageView({
      event: EVENTS.FAMILY_ACCOUNTS_ADD_FAMILY_MEMBER_REQUEST_SENT_VIEWED,
    });
  });

  const handleClose = () => {
    navigate('RouteAppTabNavigator', { screen: 'RouteTabAccount' });
  };

  return (
    <SecondaryPage
      dismissIcon="close"
      onDismiss={handleClose}
      headerBackgroundColor={COLORS.PALETTE.GREYSCALE.LIGHTEST}
      HeaderContent={
        <XlPadding topPadding={SPACING.STATIC.NONE}>
          <Column center>
            <MdSpacing />
            <H1 center>Request sent</H1>
            <SmSpacing />
            <Body center>Our care team will follow up to assist you (we aim to respond within two hours).</Body>
          </Column>
        </XlPadding>
      }
    >
      <FamilyMemberRequestCard
        firstName={firstName}
        lastName={lastName}
        dateOfBirth={dateOfBirth}
        relationship={relationship}
        supportCaseID={supportCaseID}
      />
    </SecondaryPage>
  );
};
