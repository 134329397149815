// @owners { team: patients-team }
import { AltoIcon, ExternalLink } from '@alto/design-system';
import React from 'react';
import { ALTO_FAQS_URL } from '~shared/constants';

export const FAQs = () => {
  return (
    <ExternalLink href={ALTO_FAQS_URL}>
      <AltoIcon
        name="questionmark"
        accessibilityLabel="faqs"
      />
    </ExternalLink>
  );
};
