// @owners { team: patients-team }
import { Description, InlineAlert, MdSpacing, XsSpacing } from '@alto/design-system';
import React from 'react';
import { type PaymentMethod } from '~shared/types';

type Props = {
  readonly paymentMethod: PaymentMethod | null | undefined;
};

export const SplitPaymentAutoRefillMessage = ({ paymentMethod }: Props) => {
  const { details } = paymentMethod || {};
  const { card_last_4 = '', card_type = '' } = details || {};
  const cardText = card_type ? `${card_type} ${card_last_4}` : card_last_4;

  return (
    <>
      <MdSpacing />
      <InlineAlert>
        <Description fontFamily="semibold">This order contains an auto-refill</Description>
        <XsSpacing />
        <Description>
          {`Auto-refills will use ${cardText} for future orders. We’ll notify you before the next delivery to confirm details and price, where you can choose to split the payment again if you wish.`}
        </Description>
      </InlineAlert>
    </>
  );
};
