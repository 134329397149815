import {
  APP_GOOGLE_PLACES_API_KEY,
  APP_PLAID_PUBLIC_KEY,
  APP_SEGMENT_WRITE_KEY,
  APP_SEGMENT_WRITE_KEY_ANDROID,
  APP_SEGMENT_WRITE_KEY_IOS,
  APP_STRIPE_PUBLISHABLE_KEY,
  GROWTHBOOK_API_KEY_PROD,
  GROWTHBOOK_API_KEY_SANDBOX,
  GROWTHBOOK_API_KEY_STG,
  SANDBOX_NAME,
  APP_ID as _APP_ID,
  AUTH0_CLIENT_ID as _AUTH0_CLIENT_ID,
  AUTH0_CLIENT_ID_DEV as _AUTH0_CLIENT_ID_DEV,
  AUTH0_CLIENT_ID_STG as _AUTH0_CLIENT_ID_STG,
  BACKEND_ENVIRONMENT as _BACKEND_ENVIRONMENT,
  NATIVE_URL_PREFIX as _NATIVE_URL_PREFIX,
  NODE_ENV as _NODE_ENV,
  WEB_VERSION as _WEB_VERSION,
  // eslint-disable-next-line import/no-unresolved
} from '@env';
import { getAuth0ClientID, getAuth0Domain } from './config/getAuth0Config';
import { getBaseURL } from './config/getBaseUrls';
import { getGrowthbookApiKey } from './config/getExperimentationConfig';

const { API_URL: _API_URL, API_WEBSOCKET_URL: _API_WEBSOCKET_URL } = getBaseURL({
  backendEnv: _BACKEND_ENVIRONMENT,
  sandboxName: SANDBOX_NAME,
});

export const API_WEBSOCKET_URL = _API_WEBSOCKET_URL;

export const BACKEND_ENVIRONMENT = _BACKEND_ENVIRONMENT;
export const GOOGLE_PLACES_API_KEY = APP_GOOGLE_PLACES_API_KEY;
export const PLAID_ENV = BACKEND_ENVIRONMENT === 'production' ? 'production' : 'sandbox';
export const PLAID_PUBLIC_KEY = APP_PLAID_PUBLIC_KEY;
export const SEGMENT_WRITE_KEY = APP_SEGMENT_WRITE_KEY;
export const SEGMENT_WRITE_KEY_IOS = APP_SEGMENT_WRITE_KEY_IOS;
export const SEGMENT_WRITE_KEY_ANDROID = APP_SEGMENT_WRITE_KEY_ANDROID;
export const STRIPE_PUBLISHABLE_KEY = APP_STRIPE_PUBLISHABLE_KEY;
export const AUTH0_DOMAIN = getAuth0Domain(BACKEND_ENVIRONMENT);
const auth0IDs = { dev: _AUTH0_CLIENT_ID_DEV, stg: _AUTH0_CLIENT_ID_STG, prod: _AUTH0_CLIENT_ID };
export const AUTH0_CLIENT_ID = getAuth0ClientID(BACKEND_ENVIRONMENT, auth0IDs);
export const GROWTHBOOK_API_KEY = getGrowthbookApiKey({
  env: BACKEND_ENVIRONMENT,
  production: GROWTHBOOK_API_KEY_PROD,
  sandbox: GROWTHBOOK_API_KEY_SANDBOX,
  staging: GROWTHBOOK_API_KEY_STG,
});
export const API_URL = _API_URL;
export const NATIVE_URL_PREFIX = _NATIVE_URL_PREFIX;
export const NODE_ENV = _NODE_ENV;
export const WEB_VERSION = _WEB_VERSION;
export const APP_ID = _APP_ID || 'com.scriptdash.altopharmacy';
