// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Card, LgPadding, MdSpacing } from '@alto/design-system';
import React, { useEffect } from 'react';
import { setWasNeedlePromptShown } from '~shared/actions/cart';
import NeedleMeds from '~shared/assets/images/needle_meds.png';
import { CONTACT_US_ORIGIN, IMG_ALT_TEXTS } from '~shared/constants';
import { formatList } from '~shared/helpers/list';
import { useDispatchShared } from '~shared/store';
import { ContactUs } from '../../alto-assistant';

type Props = {
  readonly medication_names: string[];
};

export const MissingNeedlesReminder = ({ medication_names }: Props) => {
  const dispatch = useDispatchShared();
  useEffect(() => {
    dispatch(setWasNeedlePromptShown(true));
  }, [dispatch]);

  return (
    <Card
      title="Missing needles?"
      illustrationSrc={NeedleMeds}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.needleMeds}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body color={COLORS.TEXT_COLORS.PRIMARY}>Your {formatList(medication_names)} will not come with needles.</Body>
        <MdSpacing />
        <Body color={COLORS.TEXT_COLORS.PRIMARY}>
          If you need needles or are out of refills, you can request more from an existing needle prescription on your
          profile, or{' '}
          <ContactUs
            type="link"
            method="message"
            origin={CONTACT_US_ORIGIN.MissingNeedlesReminder}
          />{' '}
          for assistance.
        </Body>
      </LgPadding>
    </Card>
  );
};
