// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import { post } from '~shared/helpers/apiHelper';
import { fetch } from '~shared/helpers/fetch';
import { type ReduxDispatch, type S3Source } from '~shared/types';

export const FETCH_S3_PRESIGNED_POST_LOADING = 'FETCH_S3_PRESIGNED_POST_LOADING';

export function fetchingS3PresignedPost() {
  return {
    type: FETCH_S3_PRESIGNED_POST_LOADING,
  };
}

export const FETCH_S3_PRESIGNED_POST_SUCCEEDED = 'FETCH_S3_PRESIGNED_POST_SUCCEEDED';

// @ts-expect-error TS(7006): Parameter 'presignedPost' implicitly has an 'any' type.
export function fetchS3PresignedPostSucceeded(presignedPost) {
  return {
    type: FETCH_S3_PRESIGNED_POST_SUCCEEDED,
    payload: presignedPost,
  };
}

export const FETCH_S3_PRESIGNED_POST_FAILED = 'FETCH_S3_PRESIGNED_POST_FAILED';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export function fetchS3PresignedPostFailed(error) {
  return {
    type: FETCH_S3_PRESIGNED_POST_FAILED,
    payload: error,
    error: true,
  };
}

export const UPLOAD_IMAGE_TO_S3_LOADING = 'UPLOAD_IMAGE_TO_S3_LOADING';

export function uploadingImageToS3() {
  return {
    type: UPLOAD_IMAGE_TO_S3_LOADING,
  };
}

export const UPLOAD_IMAGE_TO_S3_SUCCEEDED = 'UPLOAD_IMAGE_TO_S3_SUCCEEDED';

export function uploadImageToS3Succeeded(url: string) {
  return {
    type: UPLOAD_IMAGE_TO_S3_SUCCEEDED,
    payload: url,
  };
}

export const UPLOAD_IMAGE_TO_S3_FAILED = 'UPLOAD_IMAGE_TO_S3_FAILED';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
export function uploadImageToS3Failed(error) {
  return {
    type: UPLOAD_IMAGE_TO_S3_FAILED,
    payload: error,
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
function fetchS3PresignedPost(bucket: string, filename: string) {
  return (dispatch: ReduxDispatch): Promise<false | { url: string; formData: any }> => {
    dispatch(fetchingS3PresignedPost());
    const params = {
      bucket,
      filename,
    };
    return post('/s3_url', params).then((json) => {
      if (json.error) {
        dispatch(fetchS3PresignedPostFailed(json.error));
        return false;
      }

      // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
      dispatch(fetchS3PresignedPostSucceeded());
      const formData = JSON.parse(json.form_data);
      const url = json.s3_url;
      return {
        url,
        formData,
      };
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function uploadImageToS3(image: S3Source | File, bucket: string) {
  return (dispatch: ReduxDispatch): Promise<false | string> => {
    dispatch(uploadingImageToS3());

    return dispatch(fetchS3PresignedPost(bucket, image.name)).then((s3PresignedPost) => {
      if (s3PresignedPost) {
        const { url, formData } = s3PresignedPost;
        const form = new FormData();

        for (const k in formData) {
          form.append(k, formData[k]);
        }

        // @ts-expect-error TS2554: Expected 2 arguments, but got 3.
        form.append('file', image, image.name);

        return (
          fetch(url, {
            method: 'POST',
            body: form,
          })
            // eslint-disable-next-line promise/no-nesting
            .then((response: { ok: boolean; statusText: string }) => {
              if (response.ok) {
                dispatch(uploadImageToS3Succeeded(formData.key));
                return formData.key;
              }

              const error = {
                details: {
                  message: response.statusText || 'Something went wrong! Please try again.',
                },
              };
              dispatch(uploadImageToS3Failed(error));
              return false;
            })
            // eslint-disable-next-line promise/no-nesting
            .catch(() => {
              const error = {
                details: {
                  message: 'Something went wrong! Please try again.',
                },
              };
              dispatch(uploadImageToS3Failed(error));
              return false;
            })
        );
      }

      return false;
    });
  };
}
