import { Experimentation } from '@alto/experimentation';
// eslint-disable-next-line @alto/no-pocky-import
import { Loader } from '@alto/pocky';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { loginSucceeded, logoutSucceeded } from '~shared/actions/auth';
import { onAuthSuccessful } from '~shared/actions/onAuthSuccessful';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers } from '~shared/actions/users';
import { BACKEND_ENVIRONMENT, GROWTHBOOK_API_KEY, WEB_VERSION } from '~shared/config';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { getAnalyticalProperties } from '~shared/lib/experiments/src/getAnalyticalProperties';
import { Sentry } from '~shared/sentry';
import { configureStore } from './configureStore';
import PatientDashboardApp from './features/app/components/PatientDashboardApp';
import './main.scss';

// extend console logs to also print to cypress in integration env
if (BACKEND_ENVIRONMENT === 'integration') {
  // eslint-disable-next-line no-console
  const originalConsoleLog = console.log;
  const originalConsoleWarn = console.warn;
  const originalConsoleError = console.error;

  // @ts-expect-error TS(7006): Parameter 'level' implicitly has an 'any' type.
  const logToCypress = (level, args) => {
    // @ts-expect-error TS(2304): we're intentionally excluding Cypress types since they conflict with Jest. Can leave this for now.
    Cypress?.log({
      name: 'consoleRedirect',
      displayName: level,
      message: args,
      consoleProps: () => ({
        args,
      }),
    });
  };

  // eslint-disable-next-line no-console
  console.log = (...args) => {
    logToCypress('console.log', args);
    originalConsoleLog.apply(console, args);
  };

  console.warn = (...args) => {
    logToCypress('console.warn', args);
    originalConsoleWarn.apply(console, args);
  };

  console.error = (...args) => {
    logToCypress('console.error', args);
    originalConsoleError.apply(console, args);
  };
}

Sentry.init({
  dsn: 'https://c1be7b1e99af4b8194beb242c2cc32e2@o4505590845734912.ingest.sentry.io/4506079326568450',
  release: WEB_VERSION,
  environment: BACKEND_ENVIRONMENT,
  // add trace headers to localhost, alto API requests, and other requests on this domain, but not to auth0's login.*.alto endpoint
  tracePropagationTargets: ['localhost', /patients\.(prod|stg|dev).alto\./, /^\//],
  integrations: [
    new Sentry.BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          // remove numbers from pathnames so we can group them more intelligently
          // eg /begin/asdfgh and /begin/123456 will both be grouped as /begin/:temp-password
          name: window.location.pathname
            .replace(/\/begin\/[^/]+$/, '/begin/:temp-password')
            .replace(/\/start\/[^/]+$/, '/start/:temp-password')
            .replace(/\/status\/[^/]+$/, '/status/:temp-password')
            .replace(/\/onboard\/verify\/[^/]+$/, '/onboard/verify/:temp-password')
            .replace(/\/orders\/\d+/, '/orders/:id')
            .replace(/\/checkout\/success\/\d+/, '/checkout/success/:id')
            .replace(/\/rate-shipment\/[^/]+$/, '/rate-shipment/:hash')
            .replace(/\/subscriptions\/[^/]+$/, '/subscriptions/:hash'),
        };
      },
    }),
    new Sentry.BrowserProfilingIntegration(),
  ],
  // trace 100% of user traffic
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  normalizeDepth: 10, // cut off annotated redux state at 10 levels deep
});

const { store } = configureStore();

const NO_ENDLESS_LOADING_SCREEN = 1000 * 60;
// @ts-expect-error TS(7034): Variable 'loaderTimeout' implicitly has type 'any' in some locations where its type cannot be determ... (Delete me to see the full error)
let loaderTimeout;

function renderApp(Component = PatientDashboardApp, skipInitialDataFetch = false) {
  // @ts-expect-error TS(7005): Variable 'loaderTimeout' implicitly has an 'any' type.
  if (loaderTimeout) clearTimeout(loaderTimeout);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!);
  root.render(
    <Component
      store={store}
      skipInitialDataFetch={skipInitialDataFetch}
    />,
  );
}

if (module.hot) {
  module.hot.accept('./features/app/components/PatientDashboardApp', () => {
    renderApp(PatientDashboardApp);
  });
}

function renderLoader() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!);
  loaderTimeout = setTimeout(() => {
    root.render(<Loader text="Whoops! It looks like something went wrong. Refresh the page and try again?" />);
    throw new Error(`App load timeout: ${NO_ENDLESS_LOADING_SCREEN}ms`);
  }, NO_ENDLESS_LOADING_SCREEN);

  root.render(<Loader text="Loading…" />);
}

// TODO: this really should just be built into components instead of rebuilding
// parts of redux
const state = store.getState();

Experimentation.init(GROWTHBOOK_API_KEY, (experimentKey: string, resultKey: string) =>
  store.dispatch(
    // @ts-expect-error Argument of type '(dispatch: Dispatch<AnyAction>) => void' is not assignable to parameter of type 'AnyAction'.
    sendAnalyticsEvent(
      createEvent(EVENTS.EXPERIMENT_FEATURE_DECISION, {
        source: 'growthbook',
        experiment_key: experimentKey,
        variation_key: resultKey,
        ...getAnalyticalProperties(store.getState()),
      }),
    ),
  ),
);

// if receive unauthorized request logout and dont call load

const { locationBeforeTransitions } = state.routing;
const isKnownUnauthorized =
  (locationBeforeTransitions && locationBeforeTransitions.query.authorized === '0') ||
  /[&?]authorized=0/.test(window.location.search);

// We don't want to authenticate when on the landing or csat pages
const webLandingPage =
  window?.location?.pathname?.startsWith('/start/') || window?.location?.pathname?.startsWith('/begin/');
const csatPage = window?.location?.pathname?.startsWith('/csat/');

// if we can go a short amount of time without showing a loader, do that. This
// prevents a flash of the loader when we don't need it.
const initialLoaderDelay = 500;
const showLoaderTimeout = setTimeout(() => {
  renderLoader();
}, initialLoaderDelay);

if (isKnownUnauthorized) {
  clearTimeout(showLoaderTimeout);
  store.dispatch(logoutSucceeded());
  renderApp();
} else if (webLandingPage || csatPage) {
  clearTimeout(showLoaderTimeout);

  // Render the app, but don't fetch initial data.
  renderApp(PatientDashboardApp, true);
} else {
  // fetchUsers and the result is used to determine whether you are actually
  // logged in. Ideally we would use the persisted storage, but we need to wait
  // until the rehydration happens. We should upgrade to redux-persist v5, which
  // supports this out of the box.
  // @ts-expect-error TS(2345): Argument of type '(dispatch: any) => Promise<boolean>' is not assignable to parameter of type 'Actio... (Delete me to see the full error)
  // eslint-disable-next-line import/no-deprecated, promise/catch-or-return
  store.dispatch(fetchUsers()).then((success: boolean) => {
    clearTimeout(showLoaderTimeout);

    // eslint-disable-next-line promise/always-return
    if (success) {
      const newState = store.getState();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const user = getCurrentUser(newState)!;

      store.dispatch(loginSucceeded(user));
      // @ts-expect-error TS(2345): Argument of type '(dispatch: Dispatch<any>, getState: ReduxGetState) => void' is not assignable to p... (Delete me to see the full error)
      store.dispatch(onAuthSuccessful());
    } else {
      store.dispatch(logoutSucceeded());
    }

    renderApp();
  });
}
