import { AltoIcon, Column } from '@alto/design-system';
import React from 'react';
import styled, { css } from 'styled-components/native';

const spin = css`
  0% {
    transform: rotate(0deg);
  }
  43% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  93% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinningAnimation = styled(Column)`
  animation-name: ${spin};
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.6, 0.01, 0.64, 1.6);
`;

const SpinningHourglass = () => (
  <SpinningAnimation centerVertically>
    <AltoIcon
      name="hourglass"
      type="grey"
    />
  </SpinningAnimation>
);

export default SpinningHourglass;
