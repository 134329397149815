import { BORDERS, COLORS, SHADOWS } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Column,
  Description,
  LgPadding,
  MdSpacing,
  Toast,
  ToastContext,
  XsSpacing,
  XxsSpacing,
} from '@alto/design-system';
import React, { useCallback } from 'react';
import { ActivityIndicator, View } from 'react-native';
import styled from 'styled-components/native';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { REFILL_CROSS_SELL_COLORS } from '~shared/features/checkout/constants';
import { getLastFilledText, getPrescriptionInfoText, prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getFamilyMembers } from '~shared/features/family-accounts/selectors/getFamilyMembers';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { getDisplayName } from '~shared/helpers/helper';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription, type User } from '~shared/types';
import { useAddToCart } from '../../cart/hooks/useAddToCart';
import { useRemoveFromCart } from '../../cart/hooks/useRemoveFromCart';

const Container = styled(View)<{ backgroundColor: string }>`
  flex: 1;
  border-radius: ${BORDERS.RADIUS.LG.px};
  background: ${(props) => props.backgroundColor};
  border: ${BORDERS.SIZE}px solid ${COLORS.BORDER_COLORS.PRIMARY};
  ${SHADOWS.TOP_LIGHT};
`;

const BottomContainer = styled(View)`
  flex-shrink: 1;
  flex-grow: 1;
  background: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-radius: ${BORDERS.RADIUS.LG.px};
`;

const getFamilyMemberName = (familyMembers: User[], userID?: number) => {
  const familyMember = familyMembers?.find((familyMember) => familyMember.id === userID);
  return getDisplayName(familyMember);
};

type Props = {
  readonly addNumItemsAddedFromCrossSell: () => void;
  readonly prescription: Prescription;
  readonly prescriptionIndex: number;
  readonly MedImage: React.ReactNode;
};

export const PrescriptionRefillCard = ({
  addNumItemsAddedFromCrossSell,
  prescription,
  prescriptionIndex,
  MedImage,
}: Props) => {
  const familyMembers = useSelectorShared(getFamilyMembers);
  const familyMemberName = getFamilyMemberName(familyMembers, prescription.user_id);
  const backgroundColor = REFILL_CROSS_SELL_COLORS[prescriptionIndex % REFILL_CROSS_SELL_COLORS.length];
  const prescriptionPricing = useSelectorShared(getPrescriptionPricing);
  const price = formatDollarsWithPlaceholder(
    prescriptionPricing[prescription.id]?.prices[0]?.price,
    PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE,
  );
  const loading = useSelectorShared((state) => state.ui.loading.fetchPrescriptionPricingLoading);
  const dispatch = useDispatchShared();
  const { addToast } = React.useContext(ToastContext);
  const { handleAddToCart } = useAddToCart();
  const { handleRemoveFromCart } = useRemoveFromCart();
  const { isPrescriptionInCart } = usePrescriptionsInCart();

  const onAddToCartSuccess = useCallback(() => {
    addNumItemsAddedFromCrossSell();
    dispatch(
      sendAnalyticsEvent(
        createEvent(EVENTS.REFILL_CROSS_SELL_ITEM_ADDED, {
          prescription,
          index: prescriptionIndex,
        }),
      ),
    );
  }, [addNumItemsAddedFromCrossSell, dispatch, prescription, prescriptionIndex]);

  const handleOnAdd = async () => {
    const result = await handleAddToCart({
      resource_id: prescription.id,
      resource_type: 'Prescription',
    });
    if (result.success) {
      onAddToCartSuccess();
    } else {
      addToast(<Toast variant="error">There was an error adding to your cart. Please try again later.</Toast>);
    }
  };

  const handleRemove = useCallback(() => {
    handleRemoveFromCart({ item: prescriptionToItemKey(prescription.id) });
  }, [prescription.id, handleRemoveFromCart]);

  return (
    <Container backgroundColor={backgroundColor}>
      <LgPadding>
        <Column>
          <XsSpacing />
          {MedImage}
          <MdSpacing />
          <Body fontFamily="semibold">{prescription.medication_name}</Body>
          <XxsSpacing />
          <Description>{getPrescriptionInfoText(prescription, familyMemberName)}</Description>
          {prescription.last_delivered_at ? (
            <>
              <MdSpacing />
              <Description>{getLastFilledText(prescription)}</Description>
            </>
          ) : null}
        </Column>
      </LgPadding>
      <BottomContainer>
        <LgPadding>
          {price ? (
            <>
              {loading ? (
                <ActivityIndicator />
              ) : (
                <Description
                  center
                  fontFamily="semibold"
                >{`${PRESCRIPTION_PRICING_LABELS.YOUR_COPAY}: ${price}`}</Description>
              )}
              <XsSpacing />
            </>
          ) : null}
          {isPrescriptionInCart(prescription.id) ? (
            <Button
              type="secondary"
              label="Remove from Cart"
              onPress={handleRemove}
              accessibilityLabel="Remove from cart"
            />
          ) : (
            <Button
              label="Add to cart"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={handleOnAdd}
              accessibilityLabel="Add to cart"
            />
          )}
          <XsSpacing />
        </LgPadding>
      </BottomContainer>
    </Container>
  );
};
