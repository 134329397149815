// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Description, H2, MdSpacing, SmSpacing, Subway, SubwayStation, useScreenSize } from '@alto/design-system';
import { type Plan } from '@alto/scriptdash/alto/med_sync/types/patients/v1/plan';
import { type ProposedShipment } from '@alto/scriptdash/alto/med_sync/types/patients/v1/proposed_shipment';
import React from 'react';
import styled from 'styled-components';
import { MedSyncSelfServiceShipmentSubwayStation } from '~web/features/med-sync-self-service/components/ShipmentSubwayStation';

const ContentContainer = styled.div<{ isMDScreenOrBigger: boolean }>`
  padding: ${SPACING.STATIC.LG.px} ${SPACING.STATIC.XS.px};
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `padding: ${SPACING.STATIC.LG.px} ${SPACING.STATIC.XXL.px};`}
`;

type Props = {
  readonly proposedShipments: ProposedShipment[];
  readonly finalShipmentDaysSupply: Plan['days_supply'];
};

export const MedSyncSelfServicePlanDetails = ({ proposedShipments, finalShipmentDaysSupply }: Props) => {
  const partialFillShipments = proposedShipments.slice(0, -1);
  const lastBundledShipment = proposedShipments[proposedShipments.length - 1];
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <ContentContainer isMDScreenOrBigger={isMDScreenOrBigger}>
      <H2>Partial Fills</H2>
      <SmSpacing />
      <Description>
        To hold you over until your first bundled delivery arrives, we'll deliver partial fills of your current
        medications.
      </Description>
      <SmSpacing />
      <Subway
        verticalEnabled="always"
        extendRails="both"
      >
        {partialFillShipments.map((partialFillShipment) => (
          <MedSyncSelfServiceShipmentSubwayStation
            proposedShipment={partialFillShipment}
            key={partialFillShipment.date}
          />
        ))}
      </Subway>
      <MdSpacing />
      <H2>Bundled Delivery</H2>
      <SmSpacing />
      <Description>Your medications will arrive in a single bundled delivery each month.</Description>
      <SmSpacing />
      <Subway
        verticalEnabled="always"
        extendRails="first"
      >
        <MedSyncSelfServiceShipmentSubwayStation proposedShipment={lastBundledShipment} />
        <SubwayStation
          id="bundled-days-supply"
          title={`Repeats every ${finalShipmentDaysSupply} days`}
        />
      </Subway>
    </ContentContainer>
  );
};
