// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import {
  FETCH_NEXT_AVAILABLE_DATES_FAILED,
  FETCH_NEXT_AVAILABLE_DATES_LOADING,
  FETCH_NEXT_AVAILABLE_DATES_SUCCEEDED,
  type NextAvailableDateActions,
} from '~shared/actions/nextAvailableDates';
import { getNextAvailableDateID } from '~shared/features/next-available-date/helpers/getNextAvailableDateID';
import { type NextAvailableDate } from '~shared/types/clients';

export type NextAvailableDateMapping = Record<string, NextAvailableDate>;

export type NextAvailableDateState = {
  requestAttemptCount: number;
  lastRequestAttemptedAt: number;
  dates: NextAvailableDateMapping;
};

const initialState: NextAvailableDateState = {
  requestAttemptCount: 0,
  lastRequestAttemptedAt: 0,
  dates: {},
};

const nextAvailableDates = (
  state: NextAvailableDateState = initialState,
  action: NextAvailableDateActions,
): NextAvailableDateState => {
  switch (action.type) {
    case FETCH_NEXT_AVAILABLE_DATES_LOADING: {
      return { ...state, requestAttemptCount: state.requestAttemptCount + 1 };
    }

    case FETCH_NEXT_AVAILABLE_DATES_SUCCEEDED: {
      const newDates = action.payload?.reduce?.<NextAvailableDateMapping>((dates, nextAvailableDateResponse) => {
        const {
          address,
          facility_id: facilityID,
          prescription_ids: prescriptionIDs,
          computed_at: computedAt,
          next_available_date: earliest,
          last_available_date: lastAvailableDate,
          excluded_dates: excluded,
          next_auto_refill_date: nextAutoRefill,
          delivery_method: deliveryMethod,
        } = nextAvailableDateResponse;

        const generatedID = getNextAvailableDateID({
          addressID: address.id,
          facilityID,
          prescriptionIDs,
        });

        return {
          ...dates,
          [generatedID]: {
            earliest,
            lastAvailableDate,
            excluded,
            computedAt,
            nextAutoRefill,
            deliveryMethod,
          },
        };
      }, {});

      return {
        ...state,
        lastRequestAttemptedAt: Date.now(),
        requestAttemptCount: 0,
        dates: {
          ...state.dates,
          ...newDates,
        },
      };
    }

    case FETCH_NEXT_AVAILABLE_DATES_FAILED:
      return { ...state, lastRequestAttemptedAt: Date.now() };

    default:
      return state;
  }
};

export default withRehydration(nextAvailableDates, initialState);
