// @owners { team: patients-team }
import { Button, Card, LgPadding, SmSpacing, Toast, ToastContext, XlSpacing } from '@alto/design-system';
import { BenefitPhotoUpload, useCancelAddingBenefit } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-deprecated
import { clearInsuranceError, createPhotoInsurance } from '~shared/actions/insurances';
import { updateCashPay } from '~shared/actions/users';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { queries } from '~shared/queries/query-keys';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { InsurancePhotoUploadButtons } from '~web/features/onboarding/components/redesign/InsurancePhotoUploadButtons';

export const InsurancePhotoFormPage = () => {
  const queryClient = useQueryClient();
  const { navigate } = useNavigation();
  const routeState = useSelectorShared(getRouteState);
  const source = routeState.source ?? 'profile';
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const loading = useSelectorShared((state) => state.ui.loading.createPhotoInsuranceLoading);
  const createPhotoInsuranceError = useSelectorShared((state) => state.ui.errors.createPhotoInsuranceError);
  const insuranceID = useSelectorShared(getEditingOnboardingInsuranceID);
  const insurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const viewOnly = getIsPhotoUpload(insurance);
  const dispatch = useDispatchShared();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const userID = selectedUserID || currentUserID;
  const { handleGoBack } = useCancelAddingBenefit({ source });

  const { setValue, handleSubmit } = useForm({
    defaultValues: {
      image_url: [] as File[],
      image_url_2: [] as File[],
    },
  });

  const handleSetCashPay = async () => {
    if (!userID) return;

    const { success } = await dispatch(updateCashPay(userID, false));
    if (success) {
      trackEvent({
        event: EVENTS.CASH_PAY_ONLY_SET,
        params: {
          source,
          'new value': false,
        },
      });
    }
  };

  const handleSaveInsurancePhotos = () => {
    handleSubmit(async ({ image_url, image_url_2 }) => {
      const frontImage = image_url[0];
      const backImage = image_url_2[0];

      if (!frontImage) {
        addToast(<Toast variant="error">Please add a photo for the front of your benefits card.</Toast>);
        return;
      }
      if (!backImage) {
        addToast(<Toast variant="error">Please add a photo for the back of your benefits card.</Toast>);
      }

      dispatch(clearInsuranceError());
      // @ts-expect-error using createPhotoInsurance wrong here but it's out-of-scope of this PR to fix it
      // eslint-disable-next-line import/no-deprecated
      const insurance = await dispatch(createPhotoInsurance([frontImage, backImage], userID));

      if (typeof insurance !== 'boolean') {
        trackEvent({
          event: EVENTS.INSURANCE_ADDED,
          params: {
            type: 'photo',
            source,
            entity_name: insurance.insurance_plan_name ?? '',
          },
        });
        queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
        handleSetCashPay();
        if (!userID) return;
        navigate('RouteFamilyMemberCompleteProfile', { userID });
      } else {
        addToast(
          <Toast variant="error">{createPhotoInsuranceError?.message ?? 'Unable to save benefits card photos'}</Toast>,
        );
      }
    })();
  };

  return (
    <BenefitPhotoUpload onDismiss={handleGoBack}>
      <Card>
        <LgPadding>
          <InsurancePhotoUploadButtons
            loading={loading}
            onFormChange={setValue}
            initialFrontImageUrl={insurance?.image_url ?? ''}
            initialBackImageUrl={insurance?.image_url_2 ?? ''}
            disabled={viewOnly}
          />
          <XlSpacing />
          <Button
            width="full"
            loading={loading}
            onPress={handleSaveInsurancePhotos}
            label="Save benefit"
          />
          <SmSpacing />
          <Button
            width="full"
            onPress={handleGoBack}
            label="Cancel"
            type="tertiary"
          />
        </LgPadding>
      </Card>
    </BenefitPhotoUpload>
  );
};
