// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, DeprecatedModal, DeprecatedModalBody, DeprecatedModalFooter, Text } from '@alto/pocky';
import React, { PureComponent } from 'react';
import { type ModalType } from '~shared/actions/modal';
import { IMG_ALT_TEXTS } from '~shared/constants';
import Image from '~web/components/Image';
import prescriptions from '~web/images/prescriptions@2x.png';

export type Props = {
  readonly show: boolean;
  readonly modalType: ModalType;
  readonly next: () => void;
};

export default class OnboardingResume extends PureComponent<Props> {
  render() {
    const { modalType, show } = this.props;

    const showing = show && modalType === 'ONBOARDING_RESUME_MODAL';

    return showing ? (
      <DeprecatedModal
        showing={showing}
        title="Welcome Back!"
        dismissable={false}
        verticallyAlignContent={false}
        onClose={() => undefined}
      >
        <DeprecatedModalBody>
          <Text>Let’s finish filling out your medical info so we can get your medications delivered ASAP.</Text>
          <Image
            center
            src={prescriptions}
            alt={IMG_ALT_TEXTS.prescriptions}
          />
        </DeprecatedModalBody>
        <DeprecatedModalFooter>
          <Button
            kind="primary"
            type="submit"
            onClick={this.props.next}
          >
            Okay!
          </Button>
        </DeprecatedModalFooter>
      </DeprecatedModal>
    ) : null;
  }
}
