import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding } from '@alto/design-system';
import React, { useContext } from 'react';

type Props = {
  readonly analyticsName?: string;
  readonly description: string;
  readonly onConfirm: () => Promise<void>;
};

export const ConfirmDeleteActionSheet = ({ analyticsName, description, onConfirm }: Props) => {
  const { goToPreviousActionSheet } = useContext(ActionSheetContext);

  return (
    <ActionSheetV2
      title="Confirm deletion"
      analyticsName={analyticsName || 'confirm delete'}
      buttons={[
        <Button
          key="delete-button"
          variant="danger"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={onConfirm}
          label="Delete"
        />,
        <Button
          key="cancel-button"
          onPress={goToPreviousActionSheet}
          type="tertiary"
          label="Cancel"
        />,
      ]}
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Body>Are you sure you want to delete {description}?</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
