// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Button, Column, LgPadding, ListDescription, SmSpacing, Subway, SubwayStation } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React, { useEffect } from 'react';
import { Linking } from 'react-native';
import { type StatusMap } from '~shared/features/shipments/constants';
import { useShipmentStatusMap } from '~shared/features/shipments/hooks/useShipmentStatusMap';
import { isMail, isPickup } from '~shared/helpers/order';
import { type TrackProps, useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type DeliveryStatus, type ShipmentStatus } from '~shared/types';
import { useGetDeliveryMethodsByShipmentIDs } from '../../delivery-methods';

export const subwayDescription = ({
  isPastStop,
  isFutureStop,
  isMailDelivery,
  isPickupDelivery,
  shipmentStatus,
  shipmentStatusMap,
}: {
  isPastStop: boolean;
  isFutureStop: boolean;
  isMailDelivery: boolean;
  isPickupDelivery: boolean;
  shipmentStatus: ShipmentStatus;
  shipmentStatusMap: StatusMap;
}) => {
  if (isPastStop || shipmentStatus === 'delivered') {
    return undefined;
  } else if (isMailDelivery && isFutureStop) {
    return shipmentStatusMap[shipmentStatus]?.mailInfoTextFuture;
  } else if (isMailDelivery) {
    return shipmentStatusMap[shipmentStatus]?.mailInfoText;
  } else if (isPickupDelivery && isFutureStop) {
    return shipmentStatusMap[shipmentStatus]?.pickupInfoTextFuture;
  } else if (isPickupDelivery) {
    return shipmentStatusMap[shipmentStatus]?.pickupInfoText;
  } else if (isFutureStop) {
    return shipmentStatusMap[shipmentStatus]?.infoTextFuture;
  } else {
    return shipmentStatusMap[shipmentStatus]?.infoText;
  }
};

export const subwayTitle = ({
  isMailDelivery,
  isPickupDelivery,
  shipmentStatus,
  shipmentStatusMap,
}: {
  isMailDelivery: boolean;
  isPickupDelivery: boolean;
  shipmentStatus: ShipmentStatus;
  shipmentStatusMap: StatusMap;
}) => {
  if (isMailDelivery) {
    return shipmentStatusMap[shipmentStatus]?.mailStatusString || '';
  } else if (isPickupDelivery) {
    return shipmentStatusMap[shipmentStatus]?.pickupStatusString || '';
  } else {
    return shipmentStatusMap[shipmentStatus]?.statusString || '';
  }
};

const getDeliveryStatusIndex = (
  shipmentStatusMap: StatusMap,
  isPickupDelivery: boolean,
  status: DeliveryStatus | ShipmentStatus,
) => {
  if (isPickupDelivery) {
    return shipmentStatusMap[status].pickupIndex || shipmentStatusMap[status].index;
  } else {
    return shipmentStatusMap[status].index;
  }
};

/**
 * Helper for getting the correct Title, Description (present or future tense), and possibly a
 * clickable button if there is a tracking link included on the shipment and it's 'shipped'
 */
export const getSubwayContent = ({
  isMailDelivery,
  isPickupDelivery,
  shipmentStatus,
  deliveryStatusIndex,
  currentIndex,
  shipmentTrackingLink,
  trackEvent,
  shipmentId,
  shipmentStatusMap,
}: {
  isMailDelivery: boolean;
  isPickupDelivery: boolean;
  shipmentStatus: ShipmentStatus;
  deliveryStatusIndex: number;
  currentIndex: number;
  shipmentTrackingLink: string | undefined;
  trackEvent: ({ event, params, additionalFields }: TrackProps) => void;
  shipmentId: number | undefined;
  shipmentStatusMap: StatusMap;
}) => {
  const isPastStop = currentIndex < deliveryStatusIndex;
  const isFutureStop = currentIndex > deliveryStatusIndex;

  let trackingButton = null;
  const title = subwayTitle({ isMailDelivery, isPickupDelivery, shipmentStatus, shipmentStatusMap });
  const description = subwayDescription({
    isPastStop,
    isFutureStop,
    isMailDelivery,
    isPickupDelivery,
    shipmentStatus,
    shipmentStatusMap,
  });

  // special case to show the tracking link if the delivery is on the way and there's a tracking link
  if (currentIndex === 2 && deliveryStatusIndex === 2 && shipmentStatus === 'shipped' && shipmentTrackingLink) {
    const onPressTrackingButton = () => {
      trackEvent({
        event: EVENTS.TRACK_DELIVERY_BUTTON_PRESSED,
        additionalFields: {
          shipmentId,
        },
      });
      Linking.openURL(shipmentTrackingLink);
    };

    trackingButton = (
      <Column>
        <SmSpacing />
        <Button
          small
          onPress={onPressTrackingButton}
        >
          Track your order
        </Button>
      </Column>
    );
  }

  return { title, description, trackingButton };
};

export type OrderStatusTrackerProps = {
  readonly shipmentId: number | undefined;
  readonly deliveryStatus: DeliveryStatus | ShipmentStatus;
  readonly showTracking: boolean;
  readonly shipmentTrackingLink?: string | undefined;
};

/**
 * Component for rendering the subway stations for a delivery. The number and titles of the subways depend on this
 * being a mail v courier v pick up order. deliveryStatus determines the active subway stop
 * @param OrderStatusTrackerProps props component props
 * @returns OrderStatusTracker UI
 */
export const OrderStatusTracker = ({
  shipmentId,
  deliveryStatus,
  showTracking,
  shipmentTrackingLink,
}: OrderStatusTrackerProps) => {
  const { trackEvent } = useAnalytics();
  const shipmentIDs = shipmentId ? [shipmentId] : [];
  const { deliveryMethodsByShipmentID, deliveryMethodsLoading } = useGetDeliveryMethodsByShipmentIDs(shipmentIDs);
  const deliveryMethod = shipmentId ? deliveryMethodsByShipmentID[shipmentId] : undefined;
  const deliveryMethodName = deliveryMethod?.name;

  useEffect(() => {
    if (deliveryMethodsLoading) return;

    trackEvent({
      event: EVENTS.DELIVERY_STATUS_TRACKER_VIEWED,
      params: {
        deliveryMethod: deliveryMethodName,
        deliveryStatus,
        showTracking,
        shipmentTrackingLink,
      },
      additionalFields: {
        shipmentId,
      },
    });
  }, [
    deliveryMethodName,
    deliveryMethodsLoading,
    deliveryStatus,
    shipmentId,
    shipmentTrackingLink,
    showTracking,
    trackEvent,
  ]);
  const shipmentStatusMap = useShipmentStatusMap();

  const terminalStatus = deliveryStatus === 'failed' ? 'failed' : 'delivered';
  const deliveryMethodMode = deliveryMethod?.mode;
  const isPickupDelivery = isPickup(deliveryMethodMode);
  const isMailDelivery = isMail(deliveryMethodMode);
  const { value: pickupReadyAtPackage } = Experimentation.useFeatureFlag('pickup_ready_at_package');

  let stations: ShipmentStatus[];
  if (isPickupDelivery && pickupReadyAtPackage) {
    stations = ['upcoming', 'processing', 'awaiting_packing', terminalStatus];
  } else {
    stations = ['upcoming', 'packaged', 'shipped', terminalStatus];
  }
  const deliveryStatusIndex = getDeliveryStatusIndex(shipmentStatusMap, isPickupDelivery, deliveryStatus);
  const activeStation = stations[deliveryStatusIndex];

  return (
    <LgPadding
      bottomPadding={SPACING.STATIC.NONE}
      leftPadding={SPACING.STATIC.NONE}
    >
      <Subway
        activeStation={activeStation}
        status={deliveryStatus === 'delivered' ? 'completed' : undefined}
      >
        {stations.map((shipmentStatus, index) => {
          const { title, description, trackingButton } = getSubwayContent({
            isPickupDelivery,
            isMailDelivery,
            shipmentStatus,
            deliveryStatusIndex,
            currentIndex: index,
            shipmentTrackingLink,
            trackEvent,
            shipmentId,
            shipmentStatusMap,
          });
          const descriptions = description
            ? [
                <ListDescription key={`${shipmentStatus}-info`}>
                  {trackingButton && showTracking && shipmentTrackingLink ? trackingButton : description}
                </ListDescription>,
              ]
            : [];
          return (
            <SubwayStation
              key={shipmentStatus}
              id={shipmentStatus}
              title={title}
              descriptions={descriptions}
            />
          );
        })}
      </Subway>
    </LgPadding>
  );
};
