// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  LgPadding,
  ListDescription,
  SecondaryPage,
  Subway,
  SubwayStation,
} from '@alto/design-system';
import { useQueryLandingPageConfigs } from '@alto/features';
import React from 'react';
import multipleRx from '~shared/assets/images/multipleRx@1x.png';
import { FAQs } from '~web/features/onboarding/components/redesign/FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';

type Props = {
  readonly handleGetStarted: () => void;
  readonly providerName: string | null | undefined;
  readonly loading: boolean;
  readonly tempPassword: string;
};

export const WebLandingPage = ({ handleGetStarted, providerName, loading, tempPassword }: Props) => {
  const { isFetching, welcomeBody, welcomeHeader } = useQueryLandingPageConfigs({ tempPassword });
  const subtitle = welcomeBody ?? 'Create an account to get started.';
  const title = welcomeHeader ?? 'Welcome to Alto';

  if (isFetching) {
    return null;
  }

  return (
    <SecondaryPage
      HeaderContent={
        <OnboardingHeader
          title={title}
          subtitle={<Body center>{subtitle}</Body>}
        />
      }
      NavBarCenterContent={<OnboardingNavBarLogo />}
      NavBarRightPressable={<FAQs />}
      buttons={[
        <Button
          label="Get started"
          key="get-started"
          loading={loading}
          onPress={handleGetStarted}
        />,
      ]}
      footerPlacementContext="webScreenWithoutPolicyFooter"
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      withoutWebFramingElementHeights
    >
      <Card
        title={`We've received your prescription from ${providerName || 'your doctor'}`}
        illustrationSrc={multipleRx}
        illustrationAccessibilityLabel="Prescription"
      >
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <Subway
            verticalEnabled="always"
            activeStation="2"
          >
            <SubwayStation
              id="1"
              title="We're working on your prescription"
            />
            <SubwayStation
              id="2"
              title="Confirm your health information"
              descriptions={[<ListDescription key="info-description">Review insurance and pricing</ListDescription>]}
            />
            <SubwayStation
              id="3"
              title="Choose your delivery window"
            />
            <SubwayStation
              id="4"
              title="Your prescription arrives at your door!"
            />
          </Subway>
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};
