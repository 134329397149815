// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDeliveriesForMedication } from './getDeliveriesForMedication';
import { BILLING_ISSUES } from '~shared/constants';

export const getIsProcessingInsuranceForMedication = createSelector(
  [getDeliveriesForMedication],
  (deliveriesForMedication) => {
    const hasUpcomingDeliveries = deliveriesForMedication.some(({ status }) => status === 'upcoming');

    if (hasUpcomingDeliveries) {
      return false;
    }

    return deliveriesForMedication.some(({ comms_state }) => comms_state === BILLING_ISSUES.process_insurance_choice);
  },
);
