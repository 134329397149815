// @owners { team: patients-team }
import {
  type StartVerificationParams,
  setOnboardingVerificationChannel,
  setOnboardingVerificationRequired,
  // eslint-disable-next-line import/no-deprecated
  startVerification,
} from '~shared/actions/verifications';
import {
  type Option,
  getOnboardingVerificationOptions,
  getOnboardingVerificationUuid,
} from '~shared/features/onboarding/selectors/getOnboardingVerification';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { connect } from '~shared/store';
import { type APIError, type ReduxStateShared } from '~shared/types';
import VerificationModal from './VerificationModal';
import { type Values } from './VerificationOptionsModal';

type OwnProps = {
  onClose: () => void;
  resend?: boolean;
};

type StateProps = {
  error: APIError | null | undefined;
  loading: boolean;
  options: Option[];
  uuid: string | null | undefined;
};

type DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  startVerification: (params: StartVerificationParams) => any;
  setOnboardingVerificationRequired: typeof setOnboardingVerificationRequired;
  setOnboardingVerificationChannel: typeof setOnboardingVerificationChannel;
};

const mapStateToProps = (state: ReduxStateShared): StateProps => {
  const loading = getLoading(state);
  const errors = getErrors(state);

  const uuid = getOnboardingVerificationUuid(state);
  const options = getOnboardingVerificationOptions(state);
  return {
    error: errors.startVerificationError,
    loading: loading.startVerificationLoading,
    options,
    uuid,
  };
};

const mapDispatchToProps: DispatchProps = {
  // eslint-disable-next-line import/no-deprecated
  startVerification,
  setOnboardingVerificationRequired,
  setOnboardingVerificationChannel,
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
  const { uuid } = stateProps;
  const { startVerification, setOnboardingVerificationRequired, setOnboardingVerificationChannel } = dispatchProps;
  const { onClose } = ownProps;
  return {
    ...stateProps,
    ...ownProps,
    onSubmit: (values: Values) => {
      // eslint-disable-next-line promise/catch-or-return
      startVerification({
        uuid,
        reason: 'duplicate_account',
        comms_channel: values.channel,
      }).then((success: boolean) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          onClose();
          setOnboardingVerificationRequired();
          setOnboardingVerificationChannel(values.channel);
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VerificationModal);
