import { COLORS } from '../colors';

const baseIcons = {
  alert: true,
  bank: true,
  bell: true,
  bookmark: true,
  box: true,
  briefcasemedical: true,
  cake: true,
  calendar: true,
  calendarcancel: true,
  calendarcheck: true,
  camera: true,
  cancel: true,
  capsule: true,
  car: true,
  cart: true,
  chart: true,
  checkbox: true,
  checkcircle: true,
  checklist: true,
  checkmark: true,
  chevroncircledown: true,
  chevroncircleup: true,
  chevrondown: true,
  chevronup: true,
  chevronleft: true,
  chevronright: true,
  clock: true,
  close: true,
  closecircle: true,
  copy: true,
  creditcard: true,
  doctor: true,
  doctorplus: true,
  download: true,
  edit: true,
  ellipsish: true,
  ellipsisv: true,
  externallink: true,
  eyeclosed: true,
  eyeopen: true,
  faceid: true,
  filter: true,
  fingerprint: true,
  gear: true,
  handbox: true,
  home: true,
  homelock: true,
  hourglass: true,
  idcard: true,
  info: true,
  injectorpen: true,
  link: true,
  loader: true,
  mail: true,
  mapmarker: true,
  message: true,
  messagequestion: true,
  minus: true,
  paperairplane: true,
  patch: true,
  people: true,
  percent: true,
  phone: true,
  pill: true,
  pills: true,
  pillbottle: true,
  play: true,
  plus: true,
  pluscircle: true,
  pricetag: true,
  questionmark: true,
  radio: true,
  receipt: true,
  rxchart: true,
  search: true,
  settings: true,
  shield: true,
  shieldslash: true,
  signature: true,
  signpaper: true,
  snowflake: true,
  star: true,
  store: true,
  lightning: true,
  suppository: true,
  swap: true,
  sync: true,
  syringe: true,
  testtube: true,
  topical: true,
  towelette: true,
  trash: true,
  truck: true,
  user: true,
  usercircle: true,
  userplus: true,
} as const;

export const iconDefaultVariant = {
  main: COLORS.TEXT_COLORS.PRIMARY,
  duo: COLORS.PALETTE.SECONDARY.LIGHT,
};

export const iconColorVariants = {
  primary: {
    ...iconDefaultVariant,
  },
  secondary: {
    main: COLORS.TEXT_COLORS.SECONDARY,
    duo: COLORS.PALETTE.SECONDARY.LIGHT,
  },
  grey: {
    main: COLORS.TEXT_COLORS.GREY,
    duo: COLORS.PALETTE.GREYSCALE.DEFAULT,
  },
  disabled: {
    main: COLORS.PALETTE.GREYSCALE.DARKER,
    duo: COLORS.PALETTE.GREYSCALE.DEFAULT,
  },
  success: {
    main: COLORS.TEXT_COLORS.SUCCESS,
    duo: COLORS.PALETTE.SUCCESS.LIGHT,
  },
  danger: {
    main: COLORS.TEXT_COLORS.DANGER,
    duo: COLORS.PALETTE.DANGER.LIGHTER,
  },
  white: {
    main: COLORS.TEXT_COLORS.WHITE,
    duo: COLORS.PALETTE.GREYSCALE.DARKER,
  },
} as const;

export const iconColorVariantList = Object.keys(iconColorVariants) as (keyof typeof iconColorVariants)[];
export const variantPostfixes = ['small', 'duo'] as const;

export type ColorVariant = keyof typeof iconColorVariants;

export type BaseIconName = keyof typeof baseIcons;
export type DuoIconName = `${BaseIconName}-duo`;
export type SmallIconName = `${BaseIconName}-small`;

export type BaseIconNameWithColors = BaseIconName | `${BaseIconName}-${ColorVariant}`;
export type DuoIconNameWithColors = DuoIconName | `${DuoIconName}-${ColorVariant}`;
export type SmallIconNameWithColors = SmallIconName | `${SmallIconName}-${ColorVariant}`;

export type AltoIconNameWithColors = BaseIconNameWithColors | DuoIconNameWithColors | SmallIconNameWithColors;

// use this type in all external design systems when typing their icon wrapper name props. There should be a separate
// prop that takes ColorVariant, and the icon wrapper should build the name it needs from there.
export type AltoIconName = BaseIconName | DuoIconName | SmallIconName;

export type AltoIconUrl = `https://assets.prod.alto.com/icons/${AltoIconNameWithColors}.svg`;

export const generateIconUrl = (name: AltoIconNameWithColors): AltoIconUrl =>
  `https://assets.prod.alto.com/icons/${name}.svg`;

// loops through all variant types to create a list of all valid icon names
const allIconNames: AltoIconNameWithColors[] = [];
(Object.keys(baseIcons) as BaseIconName[]).forEach((baseIconName) => {
  allIconNames.push(baseIconName);
  variantPostfixes.forEach((variantPostfix) => {
    allIconNames.push(`${baseIconName}-${variantPostfix}`);
    iconColorVariantList.forEach((iconColorVariant) => {
      allIconNames.push(`${baseIconName}-${iconColorVariant}`);
      allIconNames.push(`${baseIconName}-${variantPostfix}-${iconColorVariant}`);
    });
  });
});

export const altoIconToUrlMap = allIconNames.reduce<Record<string, unknown>>((reduced, iconName) => {
  reduced[iconName] = generateIconUrl(iconName);
  return reduced;
}, {}) as unknown as Record<AltoIconNameWithColors, AltoIconUrl>;
