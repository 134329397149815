// eslint-disable-next-line @alto/no-pocky-import
import { Link, Text } from '@alto/pocky';
import React from 'react';
import { COMMS_CHANNEL, PHONE } from '~shared/constants';
import { stripNonDigits } from '~shared/helpers/string';
import BackLink from '~web/components/BackLink';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';

type Props = {
  readonly commsChannel: string;
  readonly result: boolean | null | undefined;
};

function pageText(commsChannel: string, result: boolean | null | undefined) {
  if (result) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
    return <Text>{COMMS_CHANNEL[commsChannel]} successfully removed.</Text>;
  }

  return (
    <Text>
      Please try again or contact support at <Link href={`tel:${stripNonDigits(PHONE)}`}>{PHONE}</Link> if the problem
      persists
    </Text>
  );
}

const NotMe = ({ commsChannel, result }: Props) => {
  return (
    <PageBackground>
      <SingleColumnContentWrapper>
        <UnauthorizedFormWrapper header="">
          <BackLink
            href="https://alto.com"
            text="Return to alto.com"
          />
          {pageText(commsChannel, result)}
        </UnauthorizedFormWrapper>
      </SingleColumnContentWrapper>
    </PageBackground>
  );
};

export default NotMe;
