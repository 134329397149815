// eslint-disable-next-line @alto/no-pocky-import
import { spacing } from '@alto/pocky';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Img = styled.img<{ sidebar?: boolean; center?: boolean }>`
  width: ${({ sidebar }) => (sidebar ? '80px' : '120px')};
  height: ${({ sidebar }) => (sidebar ? '80px' : '120px')};
  margin: 0 auto ${({ sidebar }) => (sidebar ? 0 : spacing.smSpacing)};
  ${({ center }) =>
    center &&
    `
    display: flex;
    text-align: center;
  `}
`;

type Props = {
  readonly src: string;
  readonly alt: string;
  readonly sidebar?: boolean;
  readonly center?: boolean;
};

export default class Image extends PureComponent<Props> {
  render() {
    const { alt, center, src, sidebar } = this.props;
    return (
      <Img
        center={center}
        src={src}
        alt={alt || ''}
        sidebar={sidebar}
      />
    );
  }
}
