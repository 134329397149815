import {
  type FetchOrderPricingResponse,
  type OrderPricingParams,
  PricingEndpoint,
} from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';
import { itemPricingMap } from '~shared/reducers/pricing';

const PricingApi = PricingEndpoint(apiEndpointHandler);

const getTotalPrice = (data: FetchOrderPricingResponse) => {
  const lineItemTotal = data.line_items?.find(({ key }) => key === 'total');
  return lineItemTotal?.value;
};

export const pricing = createQueryKeys('pricing', {
  order: ({
    shipmentID,
    order,
    bundlingOptionShipmentID,
  }: {
    shipmentID?: number;
    order?: OrderPricingParams;
    bundlingOptionShipmentID?: number;
  }) => ({
    queryKey: [{ shipmentID, order, bundlingOptionShipmentID }],
    queryFn: async () => {
      const response = await PricingApi.fetchOrderPricing(
        { order, shipment_id: shipmentID, bundling_option_shipment_id: bundlingOptionShipmentID },
        { version: 'v6' },
      );

      if (response.errors?.length) {
        throw new Error(response.errors[0].title || 'Error fetching shipment pricing');
      }

      return {
        item_pricing: itemPricingMap(response.item_pricing),
        line_items: response.line_items,
        metadata: response.metadata,
        totalPrice: getTotalPrice(response),
      };
    },
  }),
  prescriptionById: ({ prescriptionId }: { prescriptionId: number }) => ({
    queryKey: [{ prescriptionId }],
    queryFn: async () => {
      const response = await PricingApi.fetchPrescriptionPricingById({ id: prescriptionId }, { version: 'v4' });

      return {
        item_pricing: response.item_pricing,
        errors: response.errors,
      };
    },
  }),
});
