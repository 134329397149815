export const OPEN_BANNER = 'OPEN_BANNER';

export function openBanner() {
  return {
    type: OPEN_BANNER,
  } as const;
}

export const CLOSE_BANNER = 'CLOSE_BANNER';

export function closeBanner() {
  return {
    type: CLOSE_BANNER,
  } as const;
}

export const CHANGE_BANNER = 'CHANGE_BANNER';

export type ChangeBannerArg = {
  bannerType:
    | 'ADD_INSURANCE'
    | 'PRIOR_AUTH_PENDING'
    | 'PRIOR_AUTH_APPROVED'
    | 'PRIOR_AUTH_NO_RESPONSE'
    | 'PRIOR_AUTH_DENIED'
    | 'PROGYNY_APP_UPSELL';
  metadata?: {
    medication_name?: string | null;
  };
};

export function changeBanner(banner: ChangeBannerArg) {
  return {
    type: CHANGE_BANNER,
    payload: banner,
  } as const;
}
