// @owners { team: patients-team }
import { Pressable } from '@alto/design-system';
import React from 'react';
import { Linking } from 'react-native';
import { ANDROID_APP_STORE_LINK } from '~shared/constants';
import { GooglePlayImage } from './GooglePlayImage';

export const AndroidAppDownloadImageLink = () => {
  return (
    <Pressable
      onPress={() => {
        Linking.openURL(ANDROID_APP_STORE_LINK);
      }}
    >
      <GooglePlayImage />
    </Pressable>
  );
};
