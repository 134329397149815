import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, MdSpacing, Tag, useScreenSize } from '@alto/design-system';
import { CalendarRow, CreditCardRow, MedRow } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, InfoBox, Link as KKLink, Paragraph } from '@alto/pocky';
import {
  type PaymentMethod,
  type ShipmentInfo,
} from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/update_payment_method_patient_task';
import React from 'react';
import { Link as RRLink } from 'react-router';
import styledNative from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { SECTION_LABELS } from '~shared/features/homescreen/constants';
import { formatWindowTime } from '~shared/helpers/date';
import { formatCreditCards } from '~shared/helpers/payment';
import { phoneSupport } from '~web/constants';
import illustration from '~web/images/creditCard.png';

const CustomCard = styledNative(Card)<{ isSMScreenOrBigger: boolean; children: React.ReactNode }>`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `margin: 0 0 ${SPACING.STATIC.XXL.px};`}
  z-index: 0;
`;

type Props = {
  readonly paymentMethods: PaymentMethod[];
  readonly shipment: ShipmentInfo;
  readonly onViewDelivery: (id: number) => void;
  readonly onUpdateNow: (id: number) => void;
};

const tag = (
  <Tag
    label="Action Required"
    type="warning"
  />
);

export default function UpdatePaymentMethod({ shipment, onViewDelivery, paymentMethods, onUpdateNow }: Props) {
  const { date, start_time, end_time, cutoff_time } = shipment;
  const splitPayments = shipment.split_payment;
  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <CustomCard
      tag={tag}
      description={`Update by ${cutoff_time ? formatWindowTime({ windowTime: cutoff_time, meridiem: 'aaa' }) : null}, for scheduled delivery`}
      title={SECTION_LABELS.UPDATE_PAYMENT_METHOD}
      illustrationSrc={illustration}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.creditCard}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <CalendarRow
          date={date}
          startTime={start_time}
          endTime={end_time}
        />
        <MedRow medicationNames={shipment.medication_names} />
        <CreditCardRow copy={formatCreditCards(paymentMethods)} />
        <MdSpacing />
        {splitPayments ? (
          <InfoBox title="You are using two payment methods">
            <Paragraph>
              Update your payment information using the Alto mobile app, or you can{' '}
              <RRLink to="/messages">
                <KKLink>message us</KKLink>
              </RRLink>{' '}
              or call us at <KKLink href={`tel:${phoneSupport}`}>{phoneSupport}</KKLink>.
            </Paragraph>
          </InfoBox>
        ) : null}
        {!splitPayments && (
          <Button
            kind="primary"
            onClick={() => {
              onUpdateNow(shipment.id);
            }}
          >
            Update now
          </Button>
        )}
        <MdSpacing />
        <Button
          kind={splitPayments ? 'secondary' : 'tertiary'}
          onClick={() => {
            onViewDelivery(shipment.id);
          }}
        >
          View delivery
        </Button>
      </LgPadding>
    </CustomCard>
  );
}
