import {
  type AltoIconName,
  type BACKGROUND_COLORS_VALUES,
  COLORS,
  type ColorVariant,
  SPACING,
} from '@alto/design-library-tokens';
import React from 'react';
import { Column, LgPadding, Row } from '../../containers';
import { AltoIcon, IconPlaceholder } from '../../icon';
import { getTextColorFromType } from '../../icon/src/getTextColorFromType';
import { type Illustration } from '../../images';
import { MdSpacing, XsSpacing } from '../../separators';
import { Body, type Link } from '../../typography';

type Props = {
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly fullBleed?: boolean;
  /**
   * Used to render content on the left side of the copy that is either a sm (small) Illustration or an AltoIcon.
   * Be sure to match the type of AltoIcon to the type prop passed to PresentationListItem.
   */
  readonly LeftContent?: React.ReactElement<typeof AltoIcon> | React.ReactElement<typeof Illustration>;
  /**
   * @deprecated Use `LeftContent`, which takes an AltoIcon or Illustration
   */
  readonly iconName?: AltoIconName;
  readonly RightContent?: React.ReactElement<typeof Link>;
  readonly text: React.ReactNode;
  /*
   * Used to match the type of AltoIcon so text color and Icon color match
   */
  readonly type?: ColorVariant;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
};

export const PresentationListItem = ({
  backgroundColor = COLORS.BACKGROUND_COLORS.TRANSPARENT,
  fullBleed = false,
  LeftContent,
  iconName,
  RightContent,
  text,
  type = 'grey',
  testID,
}: Props) => {
  const hasSmallIcon = iconName?.endsWith('-small');
  return (
    <LgPadding
      testID={testID}
      backgroundColor={backgroundColor}
      bottomPadding={SPACING.STATIC.XXS}
      leftPadding={fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
      rightPadding={fullBleed ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
      topPadding={SPACING.STATIC.XXS}
    >
      <Row spaceBetween>
        <Row
          flexShrink={1}
          flexGrow={1}
          top
          wrap={false}
          centerVertically={hasSmallIcon ?? undefined}
        >
          {iconName && !LeftContent ? (
            <AltoIcon
              name={iconName}
              type={type}
              allowFontScaling
            />
          ) : null}
          {LeftContent ? LeftContent : null}
          {!iconName && !LeftContent ? <IconPlaceholder allowFontScaling /> : null}
          <MdSpacing />
          <Column
            flexShrink={1}
            flexGrow={1}
            centerVertically
          >
            <Body color={getTextColorFromType(type)}>{text}</Body>
          </Column>
        </Row>
        {!!RightContent && (
          <Row top>
            <XsSpacing />
            <Column
              right
              centerVertically
            >
              {RightContent}
            </Column>
          </Row>
        )}
      </Row>
    </LgPadding>
  );
};
