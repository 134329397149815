// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoSpinningLoader, LandingPage } from '@alto/design-system';
import React, { useEffect } from 'react';
import { push, replace } from 'react-router-redux';
import { getURLParams } from '~shared/helpers/helper';
// eslint-disable-next-line import/no-deprecated
import { useCreateMedSyncPlan } from '~shared/queries/useCreateMedSyncPlan';
import { useDispatchShared } from '~shared/store';
import { MED_SYNC_SELF_SERVICE_ROUTES } from '~web/features/med-sync-self-service/RoutingContainer';
import box from '~web/images/box@3x.png';

export const MedSyncSelfServiceCreatingPlanPage = () => {
  const dispatch = useDispatchShared();
  const {
    mutateAsync: createMedSyncPlan,
    data: response,
    isError: isCreateMedSyncPlanError,
    isSuccess: isCreateMedSyncPlanSuccess,
    // eslint-disable-next-line import/no-deprecated
  } = useCreateMedSyncPlan();

  useEffect(() => {
    if (isCreateMedSyncPlanError) {
      dispatch(replace(MED_SYNC_SELF_SERVICE_ROUTES.REQUEST_RECEIVED));
    }
  }, [dispatch, isCreateMedSyncPlanError]);

  useEffect(() => {
    if (isCreateMedSyncPlanSuccess && response) {
      if (response.error) {
        // this is where we are handling the case where we were not able to successfully auto-enroll the patient in
        // medsync - the request succeeded, but the API returned 'errors' because the auto-enroll failed, so we want
        // to navigate to the bail out screen to inform the user that Ops will be following up.
        dispatch(replace(MED_SYNC_SELF_SERVICE_ROUTES.REQUEST_RECEIVED));
      } else if (response?.data?.id) {
        // use replace so that if the user hits the back button on the review page, they go back to the
        // SelectMedicationsPage
        dispatch(replace(MED_SYNC_SELF_SERVICE_ROUTES.REVIEW));
      }
    }
  }, [response, dispatch, isCreateMedSyncPlanSuccess]);

  useEffect(() => {
    const params = getURLParams();
    const selectedPrescriptionIDs = params.selectedPrescriptionIDs?.split(',').map(Number);
    if (!selectedPrescriptionIDs?.length) {
      dispatch(push(MED_SYNC_SELF_SERVICE_ROUTES.SELECT_MEDICATIONS));
      return;
    }

    createMedSyncPlan({ prescription_ids: selectedPrescriptionIDs });
  }, [createMedSyncPlan, dispatch]);

  return (
    <LandingPage
      title="Creating a plan to bundle your medications..."
      illustrationSrc={box}
      backgroundColor={COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST}
    >
      <AltoSpinningLoader />
    </LandingPage>
  );
};
