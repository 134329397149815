// @owners { team: patients-team }
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';
export const MESSAGE_SEND_PENDING = 'MESSAGE_SEND_PENDING';

// These tags are added to a wundercom message and may trigger extra functionality
// ex: the `medication_question` tag will skip creating a Message Action for Care ops
//     and instead create a Message Action for a pharmacist to answer
export const MESSAGE_TAGS = {
  COPAY_CHECK: 'copay_check',
  MEDICATION_QUESTION: 'medication_question',
} as const;

export type MessageTag = (typeof MESSAGE_TAGS)[keyof typeof MESSAGE_TAGS];

export const MESSAGE_FORM_TYPES = {
  MESSAGE_CHAT: 'message_chat',
  MESSAGE_WITH_SUBJECT: 'message_with_subject',
  SUPPORT_CASE_REPLY: 'support_case_reply',
} as const;
export type MessageFormType = (typeof MESSAGE_FORM_TYPES)[keyof typeof MESSAGE_FORM_TYPES];

export const MESSAGE_RESPONSE_TIME = '2-4 hours';
export const BUSINESS_HOURS = `Monday - Friday: 6am - 9pm PT\nSaturday & Sunday: 7am - 6pm PT`;
export const MESSAGE_SUCCESS_TOASTS = {
  DEFAULT: 'Your message was sent to Alto',
  FOR_PHARMACIST: 'Message sent. We will look into this and get back to you as soon as possible.',
  NEW_SUPPORT_CASE: 'Message sent. We will look into this and get back to you as soon as possible.',
  SUPPORT_CASE_REPLY: 'Message sent. We will look into this and get back to you as soon as possible.',
  SUPPORT_CASE_QUICK_REPLY: 'Message sent.',
} as const;

export const MESSAGE_ERROR_TOASTS = {
  DEFAULT: 'There has been an issue submitting your message. Please try again.',
  NEW_SUPPORT_CASE: 'We are sorry. Your request hit a snag. Try sending your request again.',
};
export type WundercomMessageMap = Record<number, WundercomMessage>;
