// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import { type FeedbackAction, RATING_PROMPT_ACTION } from '~shared/actions/feedback';

type RatingPrompt = {
  time: number;
  action: string;
  screen: string;
};

export type FeedbackState = {
  ratingPrompts?: RatingPrompt[];
};

const initialState: FeedbackState = {
  ratingPrompts: [],
};

const feedback = (state: FeedbackState = initialState, action: FeedbackAction): FeedbackState => {
  const { type } = action;

  switch (type) {
    case RATING_PROMPT_ACTION: {
      const previousRatingPrompts = state.ratingPrompts || [];
      return {
        ...state,
        ratingPrompts: [
          {
            time: Date.now(),
            screen: action.payload.screen,
            action: action.payload.action,
          },
          ...previousRatingPrompts,
        ],
      };
    }
    default:
      return state;
  }
};

export default withRehydration(feedback, initialState);
