// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, H1, palette } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';

const Container = styled(FlexColumn)<{ height?: string; verticallyAlignContent?: boolean }>`
  justify-content: flex-start;
  height: ${({ height }) => height};
  outline: 0;
`;

const Header = styled(H1)<{ underline?: boolean }>`
  color: ${palette.navy};
  ${({ underline }) =>
    underline &&
    `
    border-bottom: 2px solid ${palette.citrus};
  `}
`;

type Props = {
  readonly children: string;
  readonly height?: string;
  readonly underline?: boolean;
  readonly announceToScreenReader?: boolean;
};

export default class PageHeader extends React.PureComponent<Props> {
  static defaultProps = {
    announceToScreenReader: false,
    height: '100%',
    underline: true,
  };

  render() {
    const { children, height, underline, announceToScreenReader } = this.props;

    return (
      <Container
        verticallyAlignContent
        height={height}
      >
        <Header
          underline={underline}
          announceToScreenReader={announceToScreenReader}
        >
          {children}
        </Header>
      </Container>
    );
  }
}
