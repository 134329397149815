import { BORDERS, COLORS, SHADOWS, SIZES, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { useScreenSize } from '../../../utils';
import { BottomAnchor, MdPadding, Row } from '../../containers';
import { Description } from '../../typography';
import { ButtonGroup, type ButtonGroupProps } from './ButtonGroup';

type BottomAnchorWithShadow = {
  showTopShadow: AnchoredButtonGroupProps['showTopShadow'];
  hideTopBorder: AnchoredButtonGroupProps['hideTopBorder'];
};

type ContainerProps = {
  isMDScreenOrBigger: boolean;
};

const BottomAnchorWithShadow = styled(BottomAnchor)<BottomAnchorWithShadow>`
  ${({ showTopShadow }) => (showTopShadow ? SHADOWS.TOP : '')}
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-top-width: ${BORDERS.SIZE}px;
  border-top-color: ${({ hideTopBorder }) => (hideTopBorder ? 'transparent' : COLORS.BORDER_COLORS.LIGHT)};
`;

const Container = styled(View)<ContainerProps>`
  width: ${SIZES.PAGE.CONTENT_WIDTH.SM};

  ${({ isMDScreenOrBigger }) =>
    isMDScreenOrBigger &&
    `
        align-self: center;
        width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
        max-width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
  `}
`;

export type AnchoredButtonGroupProps = {
  readonly buttons: ButtonGroupProps['buttons'];
  readonly hideTopBorder?: boolean;
  readonly showTopShadow?: boolean;
  readonly Footnote?: React.ReactElement<typeof Description> | string;
  readonly inline?: ButtonGroupProps['inline'];
  readonly justifyButtons?: ButtonGroupProps['justifyButtons'];
};

export const AnchoredButtonGroup = ({
  buttons,
  hideTopBorder,
  inline = false,
  showTopShadow,
  Footnote,
  justifyButtons,
}: AnchoredButtonGroupProps) => {
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <BottomAnchorWithShadow
      hideTopBorder={hideTopBorder}
      showTopShadow={showTopShadow}
    >
      <Container isMDScreenOrBigger={isMDScreenOrBigger}>
        {Footnote ? (
          <MdPadding
            topPadding={SPACING.STATIC.XS}
            bottomPadding={SPACING.STATIC.NONE}
          >
            <Row center>
              <Description center>{Footnote}</Description>
            </Row>
          </MdPadding>
        ) : null}
        <MdPadding topPadding={Footnote ? SPACING.STATIC.XS : SPACING.STATIC.MD}>
          <ButtonGroup
            rowReversed
            buttons={buttons}
            inline={inline}
            justifyButtons={justifyButtons}
          />
        </MdPadding>
      </Container>
    </BottomAnchorWithShadow>
  );
};
