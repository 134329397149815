// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import {
  ARCHIVE_PRESCRIPTION_SUCCEEDED,
  CANCEL_RENEWAL_SUCCEEDED,
  CHANGE_PRESCRIPTION_FILTER,
  CHANGE_PRESCRIPTION_FILTER_STRING,
  DELETE_TRANSFER_SUCCEEDED,
  DESELECT_PRESCRIPTION,
  FETCH_ACTIVE_PRESCRIPTIONS_LOADING,
  FETCH_ACTIVE_PRESCRIPTIONS_SUCCEEDED,
  FETCH_PRESCRIPTIONS_LOADING,
  FETCH_PRESCRIPTIONS_SUCCEEDED,
  FETCH_RENEWAL_REQUEST_TIMELINE_SUCCEEDED,
  FETCH_TRANSFERS_LOADING,
  FETCH_TRANSFERS_SUCCEEDED,
  REQUEST_RENEWAL_SUCCEEDED,
  SELECT_PRESCRIPTION,
  UNARCHIVE_PRESCRIPTION_SUCCEEDED,
  UPDATE_PRESCRIPTION_SUCCEEDED,
  type archivePrescriptionSucceeded,
  type cancelRenewalSucceeded,
  type changePrescriptionFilter,
  type changePrescriptionFilterString,
  type deleteTransferSucceeded,
  type deselectPrescription,
  type fetchActivePrescriptionsSucceeded,
  type fetchPrescriptionsSucceeded,
  type fetchRenewalRequestTimelineSucceeded,
  type fetchTransfersSucceeded,
  type fetchingActivePrescriptions,
  type fetchingPrescriptions,
  type fetchingTransfers,
  type requestRenewalSucceeded,
  type selectPrescription,
  type unarchivePrescriptionSucceeded,
  type updatePrescriptionSucceeded,
} from '~shared/actions/prescriptions';
import { immutableBulkUpsert, immutableUpsert } from '~shared/helpers/immutableUpsert';
import { type Prescription, type RenewalRequestTimelineItem, type Transfer } from '~shared/types';

export type RenewalRequestTimelines = Record<number, RenewalRequestTimelineItem[]>;

export type PrescriptionState = {
  filter: string;
  searchString: string;
  renewalRequestTimelines: RenewalRequestTimelines;
  transfers: Transfer[];
  prescriptions: Prescription[];
  lastRequestAttemptedAt: number;
  requestAttemptCount: number;
  lastFetchAllRequestSucceededAt: number;
  lastRequestSucceededAt: number;
  lastTransfersRequestAttemptedAt: number;
  transfersRequestAttemptCount: number;
  lastTransfersRequestSucceededAt: number;
  selectedPrescriptionID?: number;
};

export const initialState: PrescriptionState = {
  filter: 'active',
  searchString: '',
  prescriptions: [] as Prescription[],
  renewalRequestTimelines: {},
  selectedPrescriptionID: undefined,
  transfers: [] as Transfer[],
  lastRequestAttemptedAt: 0,
  requestAttemptCount: 0,
  lastFetchAllRequestSucceededAt: 0,
  lastRequestSucceededAt: 0,
  lastTransfersRequestAttemptedAt: 0,
  transfersRequestAttemptCount: 0,
  lastTransfersRequestSucceededAt: 0,
};

export type PrescriptionActions =
  | ReturnType<typeof fetchPrescriptionsSucceeded>
  | ReturnType<typeof fetchActivePrescriptionsSucceeded>
  | ReturnType<typeof fetchingPrescriptions>
  | ReturnType<typeof fetchingActivePrescriptions>
  | ReturnType<typeof archivePrescriptionSucceeded>
  | ReturnType<typeof unarchivePrescriptionSucceeded>
  | ReturnType<typeof fetchingTransfers>
  | ReturnType<typeof fetchTransfersSucceeded>
  | ReturnType<typeof deleteTransferSucceeded>
  | ReturnType<typeof changePrescriptionFilter>
  | ReturnType<typeof changePrescriptionFilterString>
  | ReturnType<typeof selectPrescription>
  | ReturnType<typeof deselectPrescription>
  | ReturnType<typeof requestRenewalSucceeded>
  | ReturnType<typeof cancelRenewalSucceeded>
  | ReturnType<typeof fetchRenewalRequestTimelineSucceeded>
  | ReturnType<typeof updatePrescriptionSucceeded>;

const prescriptions = (state = initialState, action: PrescriptionActions): PrescriptionState => {
  switch (action.type) {
    case FETCH_PRESCRIPTIONS_SUCCEEDED:
      return {
        ...state,
        prescriptions: action.payload,
        lastRequestSucceededAt: Date.now(),
        lastFetchAllRequestSucceededAt: Date.now(),
        requestAttemptCount: 0,
      };

    case FETCH_ACTIVE_PRESCRIPTIONS_SUCCEEDED:
      return {
        ...state,
        prescriptions: immutableBulkUpsert({
          state: state.prescriptions,
          payload: action.payload,
        }),
        lastRequestSucceededAt: Date.now(),
        requestAttemptCount: 0,
      };

    case FETCH_ACTIVE_PRESCRIPTIONS_LOADING:
    case FETCH_PRESCRIPTIONS_LOADING:
      return { ...state, lastRequestAttemptedAt: Date.now(), requestAttemptCount: state.requestAttemptCount + 1 };

    case UPDATE_PRESCRIPTION_SUCCEEDED:
      return {
        ...state,
        prescriptions: immutableUpsert({
          state: state.prescriptions,
          payload: action.payload,
        }),
      };

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case ARCHIVE_PRESCRIPTION_SUCCEEDED:
      return {
        ...state,
        prescriptions: immutableUpsert({
          state: state.prescriptions,
          payload: action.payload,
        }),
      };

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case UNARCHIVE_PRESCRIPTION_SUCCEEDED:
      return {
        ...state,
        prescriptions: immutableUpsert({
          state: state.prescriptions,
          payload: action.payload,
        }),
      };

    case FETCH_TRANSFERS_LOADING:
      return {
        ...state,
        lastTransfersRequestAttemptedAt: Date.now(),
        transfersRequestAttemptCount: state.transfersRequestAttemptCount + 1,
      };

    case FETCH_TRANSFERS_SUCCEEDED:
      return {
        ...state,
        transfers: action.payload,
        lastTransfersRequestSucceededAt: Date.now(),
        transfersRequestAttemptCount: 0,
      };

    case DELETE_TRANSFER_SUCCEEDED:
      return {
        ...state,
        transfers: state.transfers.filter((transfer) => {
          return transfer.id !== action.payload.id;
        }),
      };

    case CHANGE_PRESCRIPTION_FILTER:
      return { ...state, filter: action.payload };

    case CHANGE_PRESCRIPTION_FILTER_STRING:
      return { ...state, searchString: action.payload.toLowerCase() };

    case SELECT_PRESCRIPTION:
      return { ...state, selectedPrescriptionID: action.payload };

    case DESELECT_PRESCRIPTION:
      return { ...state, selectedPrescriptionID: undefined };

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case REQUEST_RENEWAL_SUCCEEDED: {
      return {
        ...state,
        prescriptions: immutableUpsert({
          state: state.prescriptions,
          payload: action.payload,
        }),
      };
    }

    case FETCH_RENEWAL_REQUEST_TIMELINE_SUCCEEDED: {
      const { prescriptionID, timeline } = action.payload;
      return { ...state, renewalRequestTimelines: { ...state.renewalRequestTimelines, [prescriptionID]: timeline } };
    }

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case CANCEL_RENEWAL_SUCCEEDED: {
      return {
        ...state,
        prescriptions: immutableUpsert({
          state: state.prescriptions,
          payload: action.payload,
        }),
      };
    }

    default:
      return state;
  }
};

export default withRehydration(prescriptions, initialState);
