// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, SmSpacing } from '@alto/design-system';
import { type PickupData } from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import React from 'react';
import { HolidayClosureAlert } from '../../../alto-assistant';
import { formatStoreTime } from './utils';

type Props = {
  pickupData: PickupData;
};

export const PharmacyHoursActionSheet = ({ pickupData }: Props) => {
  const {
    facility_name,
    facility_weekday_store_open,
    facility_weekday_store_close,
    facility_weekend_store_open,
    facility_weekend_store_close,
  } = pickupData;

  return (
    <ActionSheetV2
      title="Pharmacy hours"
      description={`Alto Pharmacy ${facility_name}`}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <HolidayClosureAlert />
        <Body>{`Monday - Friday: ${formatStoreTime(facility_weekday_store_open)} - ${formatStoreTime(facility_weekday_store_close)}`}</Body>
        <SmSpacing />
        <Body>{`Saturday - Sunday: ${formatStoreTime(facility_weekend_store_open)} - ${formatStoreTime(facility_weekend_store_close)}`}</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
