// @owners { team: patients-team }
import { isBefore, isValid, parseISO } from 'date-fns';
import { createSelector } from 'reselect';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { type User } from '~shared/types';

// Don't force users who signed up before we started collecting health info and
// communication preferences during onboarding to fill these out.
const launchDate = new Date(2018, 6, 2); // July 2, 2018

const hasCompletedOnboarding = ({ allergies, created_at, medical_conditions, preferred_communication }: User) => {
  const parsedCreatedAt = parseISO(created_at ?? '');

  if (isValid(parsedCreatedAt) && isBefore(parsedCreatedAt, launchDate)) {
    return true;
  }

  return [allergies, medical_conditions, preferred_communication].every(Boolean);
};

export const getIsOnboardingComplete = createSelector([getCurrentUser], (user) => {
  if (!user) {
    return true;
  }

  return hasCompletedOnboarding(user);
});
