// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES } from '@alto/design-library-tokens';
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';

type CreditCardProps = {
  readonly copy: string;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
};

export const CreditCardRow = ({ copy, backgroundColor }: CreditCardProps) => (
  <PresentationListItem
    backgroundColor={backgroundColor}
    testID="credit-card-row"
    LeftContent=<AltoIcon
      name="creditcard"
      type="grey"
    />
    text={copy}
    type="grey"
  />
);
