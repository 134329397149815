// @owners { team: patients-team }
import { ActionSheetContext } from '@alto/design-system';
import { AddOnsContext } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useCallback, useContext, useMemo } from 'react';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { useSelectorShared } from '~shared/store';
import { type Shipment } from '~shared/types';
import { ProductItemUpdateActionSheet } from '~web/features/essentials/components/ProductItemUpdateActionSheet';

type Props = {
  essential: AddOnOtc | null | undefined;
  shipment?: Shipment;
};

export const useEditEssential = ({ essential, shipment }: Props) => {
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID: shipment?.id }));
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const origin = shipment?.id ? ORIGINS.EDIT_DELIVERY_DETAILS : ORIGINS.CART;
  const addOnsContext = useMemo(
    () =>
      ({
        shipmentID: shipment?.id,
        origin,
      }) as const,
    [origin, shipment?.id],
  );

  const onChangeEssential = useCallback(() => {
    if (!essential) {
      return;
    }

    const analyticsProps: AnalyticsProps = {
      slot: 0,
      sku: essential.sku,
      shipment_id: shipment?.id,
      sku_type: essential.sku_type,
      otc_name: essential.name,
      category: null,
      query_string: null,
      facility_id: address?.dispensing_facility_id,
      origin,
    };

    setActiveActionSheet(
      <AddOnsContext.Provider value={addOnsContext}>
        <ProductItemUpdateActionSheet
          essential={essential}
          analyticsProps={analyticsProps}
        />
      </AddOnsContext.Provider>,
    );
  }, [essential, shipment?.id, address?.dispensing_facility_id, origin, setActiveActionSheet, addOnsContext]);

  return {
    onChangeEssential,
  };
};
