export const BREAKPOINTS = {
  XS: 375,
  SM: 600,
  MD: 905,
  LG: 1240,
  XL: 1400,
} as const;

export const DEVICES = {
  XS: `(min-width: ${BREAKPOINTS.XS}px)`,
  SM: `(min-width: ${BREAKPOINTS.SM}px)`,
  MD: `(min-width: ${BREAKPOINTS.MD}px)`,
  LG: `(min-width: ${BREAKPOINTS.LG}px)`,
  XL: `(min-width: ${BREAKPOINTS.XL}px)`,
} as const;
