import { isSameDay, parseISO } from 'date-fns';
import { useCrossSellPrescriptions } from '~shared/features/checkout/hooks/useCrossSellPrescriptions';
import { getEnterCartTypeHash } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { REFILL_CROSS_SELL_NOTICE } from '~shared/features/notices/selectors/getNotices';
import { getViewedNotices } from '~shared/features/notices/selectors/getViewedNotices';
import { useSelectorShared } from '~shared/store';

export const useShouldShowCrossSellPage = () => {
  const viewedNotices = useSelectorShared(getViewedNotices);
  const { crossSellPrescriptions } = useCrossSellPrescriptions();
  const enterCartTypeHash = useSelectorShared(getEnterCartTypeHash);
  const crossSellViewedTimestamp = viewedNotices[REFILL_CROSS_SELL_NOTICE] || undefined;
  const crossSellSeenToday = !!crossSellViewedTimestamp && isSameDay(parseISO(crossSellViewedTimestamp), new Date());
  const needlePromptShown = enterCartTypeHash.needle_prompt || enterCartTypeHash.needle_prompt_no_needles;
  const show = !!crossSellPrescriptions.length && !crossSellSeenToday && !needlePromptShown;

  return { show };
};
