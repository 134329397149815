import { COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Link, Row, Text, spacing } from '@alto/pocky';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { ANDROID_APP_STORE_LINK, IMG_ALT_TEXTS, IOS_APP_STORE_LINK } from '~shared/constants';
import { isAndroidMobileWeb, isIOSMobileWeb } from '~shared/helpers/helper';
import notification from '~web/images/notification@3x.png';

const Image = styled.img`
  height: ${SIZES.ILLUSTRATION.SM.px};
  width: ${SIZES.ILLUSTRATION.SM.px};
`;

type BackgroundColor = 'success' | 'error' | 'warning' | 'pink';
function backgroundColor(type: BackgroundColor) {
  switch (type) {
    case 'success':
      return COLORS.PALETTE.SUCCESS.LIGHTER;

    case 'error':
      return COLORS.PALETTE.DANGER.LIGHTEST;

    case 'warning':
      return COLORS.PALETTE.WARNING.LIGHTER;

    case 'pink':
      return COLORS.PALETTE.PINK.LIGHTER;

    default:
      return COLORS.PALETTE.PRIMARY.LIGHTER;
  }
}

const StyledBanner = styled.div<{ type: BackgroundColor }>`
  background-color: ${({ type }) => backgroundColor(type)};
  width: 100%;
  padding: ${SPACING.STATIC.MD.px};
  display: flex;
  justify-content: center;
  align-items: center;
  > ${Row} {
    width: 1140px;
  }
`;

const BannerText = styled(Text)`
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type BannerType =
  | 'ADD_INSURANCE'
  | 'PRIOR_AUTH_PENDING'
  | 'PRIOR_AUTH_APPROVED'
  | 'PRIOR_AUTH_DENIED'
  | 'PRIOR_AUTH_NO_RESPONSE'
  | 'PROGYNY_APP_UPSELL';

function getBannerProps(
  bannerType: BannerType,
  metadata: Record<string, unknown> | null | undefined,
  buttonHandlers: Record<string, unknown>,
) {
  switch (bannerType) {
    case 'ADD_INSURANCE':
      return {
        buttonHandler: buttonHandlers.ADD_INSURANCE,
        type: 'warning',
        buttonText: '+ Add Insurance',
        content: (
          <BannerText
            light
            small
          >
            Speed up your checkout process by adding your insurance now!
          </BannerText>
        ),
      };

    case 'PRIOR_AUTH_PENDING': {
      const { medication_name } = metadata || {};
      return {
        type: 'warning',
        content: (
          <BannerText
            light
            small
          >
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            {`Your order for ${medication_name} has been unscheduled until a Prior Authorization is complete. To learn more, view the order.`}
          </BannerText>
        ),
      };
    }

    case 'PRIOR_AUTH_APPROVED': {
      const { medication_name } = metadata || {};
      return {
        type: 'success',
        content: (
          <Row verticallyAlignContent>
            <BannerText
              light
              small
            >
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`Your Prior Authorization for ${medication_name} has been approved and is ready to be scheduled!`}
            </BannerText>
          </Row>
        ),
      };
    }

    case 'PRIOR_AUTH_DENIED': {
      const { medication_name } = metadata || {};
      return {
        type: 'error',
        content: (
          <Row verticallyAlignContent>
            <BannerText
              light
              small
            >
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`Unfortunately, your Prior Authorization for ${medication_name} has been denied. You must pay out-of-pocket to receive this medication.`}
            </BannerText>
          </Row>
        ),
      };
    }

    case 'PRIOR_AUTH_NO_RESPONSE': {
      const { medication_name } = metadata || {};
      return {
        type: 'error',
        content: (
          <Row verticallyAlignContent>
            <BannerText
              light
              small
            >
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`Your Prior Authorization for ${medication_name} has been closed. You must pay out-of-pocket to receive this medication.`}
            </BannerText>
          </Row>
        ),
      };
    }

    case 'PROGYNY_APP_UPSELL': {
      let href = 'https://alto.com/blog/post/introducing-alto-pharmacy-on-ios-and-android';

      if (isIOSMobileWeb()) {
        href = IOS_APP_STORE_LINK;
      } else if (isAndroidMobileWeb()) {
        href = ANDROID_APP_STORE_LINK;
      }

      return {
        type: 'pink',
        content: (
          <Row
            verticallyAlignContent
            spacing={SPACING.STATIC.XS.px}
          >
            <Image
              src={notification}
              alt={IMG_ALT_TEXTS.notification}
            />
            <BannerText>
              One or more of your medications must be ordered using the Alto app. <Link href={href}>Download now!</Link>
            </BannerText>
          </Row>
        ),
        dismissable: false,
      };
    }

    default:
      return {};
  }
}

type Props = {
  readonly authorized: boolean;
  readonly bannerType: BannerType;
  readonly buttonHandlers: Record<string, unknown>;
  readonly closeBanner: () => void;
  readonly metadata: Record<string, unknown> | null | undefined;
  readonly onboarding?: boolean;
  readonly showBanner: boolean;
};

export const Banner = ({
  authorized,
  bannerType,
  buttonHandlers,
  closeBanner,
  metadata,
  onboarding,
  showBanner,
}: Props) => {
  const {
    buttonHandler = null,
    buttonText = null,
    content,
    dismissable = true,
    type,
  } = getBannerProps(bannerType, metadata, buttonHandlers);

  let body = <div />;

  if (showBanner && authorized && !onboarding) {
    body = (
      <StyledBanner type={type as BackgroundColor}>
        <Row
          horizontallyAlignContent
          verticallyAlignContent
          spacing={spacing.smSpacing}
        >
          {content}
          <Row
            verticallyAlignContent
            spacing={spacing.smSpacing}
          >
            {buttonHandler ? (
              <Button
                kind="secondary"
                // @ts-expect-error: Property 'onClick' has incorrect type
                onClick={buttonHandler}
              >
                {buttonText}
              </Button>
            ) : null}
            {dismissable ? (
              <Link onClick={closeBanner}>
                <span>&times;</span>
              </Link>
            ) : null}
          </Row>
        </Row>
      </StyledBanner>
    );
  }

  return (
    <CSSTransitionGroup
      transitionAppear
      transitionName="banner"
      transitionAppearTimeout={200}
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
    >
      {body}
    </CSSTransitionGroup>
  );
};
