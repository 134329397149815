// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Border, MdPadding, XxsSpacing } from '@alto/design-system';
import React from 'react';
import { StripeInputLabel } from './StripeInputLabel';

type Props = {
  readonly children: React.ReactNode;
  readonly text: string;
};

export const StripeInputText = ({ children, text }: Props) => (
  <>
    <StripeInputLabel text={text} />
    <XxsSpacing />
    <Border
      radius="LG"
      color={COLORS.BORDER_COLORS.LIGHT}
    >
      <MdPadding
        backgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST}
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        {children}
      </MdPadding>
    </Border>
  </>
);
