import { ActionSheetContext, Button, Column, MdSpacing, Row, XsSpacing } from '@alto/design-system';
import { ConfirmCashPayActionSheet } from '@alto/features';
import React, { useContext } from 'react';
import { type InsuranceSource } from '~shared/constants';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getUserCashPayOnly } from '~shared/features/users/selectors/getUserCashPayOnly';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useSelectorShared } from '~shared/store';
import { type Insurance as InsuranceType } from '~shared/types/clients';
import { Insurance } from './Insurance';

type Props = {
  readonly hasActiveInsurances: boolean;
  readonly insurances: InsuranceType[];
  readonly onAddInsurance?: () => void;
  readonly source: InsuranceSource;
};
export const InsuranceList = ({ hasActiveInsurances, insurances, onAddInsurance, source }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const currentUserID = useSelectorShared(getCurrentUserID);
  const userID = selectedUserID || currentUserID;
  const isCashPayOnly = useSelectorShared((state) => getUserCashPayOnly(state, { userID }));

  const handleSelectCashPay = () => {
    setActiveActionSheet(
      <ConfirmCashPayActionSheet
        source="profile"
        hasAllInactiveInsurances={!hasActiveInsurances && insurances.length > 0}
      />,
    );
  };

  return (
    <>
      <Column gap="LG">
        {insurances.map((insurance) => (
          <Insurance
            key={insurance.id}
            insurance={insurance}
            source={source}
          />
        ))}
      </Column>
      {onAddInsurance ? (
        <>
          <MdSpacing />
          <Row right>
            {!hasActiveInsurances && !isCashPayOnly ? (
              <>
                <Button
                  label="I don't have any benefits"
                  onPress={handleSelectCashPay}
                  type="secondary"
                />
                <XsSpacing />
              </>
            ) : null}
            <Button
              label="Add benefits"
              onPress={onAddInsurance}
            />
          </Row>
        </>
      ) : null}
    </>
  );
};
