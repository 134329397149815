import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type StatusMap } from '~shared/features/shipments/constants';
import { isCourier, isMail, isPickup } from '~shared/helpers/order';
import { type ShipmentStatus } from '~shared/types';

export const getWhatToExpectCopy = (
  method: DeliveryMethod | undefined | null,
  shipmentStatusMap: StatusMap,
  shipmentStatus?: ShipmentStatus,
) => {
  let copy = '';

  if (isPickup(method?.mode)) {
    copy =
      shipmentStatus !== 'shipped'
        ? shipmentStatusMap[shipmentStatus || 'processing']?.pickupInfoTextFuture || ''
        : shipmentStatusMap[shipmentStatus]?.pickupInfoText || '';
  }

  if (isMail(method?.mode)) {
    copy = shipmentStatus !== 'shipped' ? 'We’ll send a tracking link via email when your order has been shipped.' : '';
  }

  if (isCourier(method?.mode)) {
    copy =
      'You’ll get updates via text when your courier is on the way, including tracking information and an updated arrival time.';
  }

  return copy;
};
