// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  Column,
  DeprecatedModalFooter,
  InputBlockText,
  InputRow,
  LoadingButton,
  Text,
  createValidator,
  spacing,
  validatePresent,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { type VerificationCommsChannel } from '~shared/actions/verifications';
import { VERIFICATION_SENT_BODY } from '~shared/features/onboarding/constants';
import { getErrorMessage } from '~shared/helpers/helper';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { type APIError } from '~shared/types';

const Footer = styled(DeprecatedModalFooter)`
  border: none;
  padding: 0;
`;

const validate = createValidator({
  code: [validatePresent],
});

export type Values = {
  code: string;
};

export type Props = {
  readonly channel: VerificationCommsChannel | null | undefined;
  readonly contact: string;
  readonly error: APIError | null | undefined;
  readonly loading: boolean;
  readonly onSubmit: (arg0: Values) => void;
  readonly onResendCode: () => void;
  readonly back: () => void;
};

export default function OnboardingContactVerification({
  back,
  channel,
  contact,
  error,
  loading,
  onSubmit,
  onResendCode,
}: Props) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        code: '',
      }}
      validate={validate}
    >
      <Form noValidate>
        <Column spacing={spacing.smSpacing}>
          <Text>{VERIFICATION_SENT_BODY(channel, contact)}</Text>
          <InputRow>
            <Field name="code">
              {({ field, meta }: FieldProps<Values['code']>) => (
                <InputBlockText
                  required
                  label="Verification Code"
                  id="code"
                  placeholder="XXXXXX"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
          {!!error && <Text danger>{getErrorMessage(error)}</Text>}
          <Footer>
            <Button
              kind="tertiary"
              onClick={back}
            >
              Back
            </Button>
            <Button
              kind="secondary"
              onClick={onResendCode}
            >
              Resend Code
            </Button>
            <LoadingButton
              loading={loading}
              loadingText="Verifying..."
              type="submit"
            >
              Verify
            </LoadingButton>
          </Footer>
        </Column>
      </Form>
    </Formik>
  );
}
