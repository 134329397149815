import { useEffect } from 'react';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';

export const useDatePickerViewedEvent = () => {
  const { trackPageView } = useAnalytics();
  const { prescriptions } = usePrescriptionsInCart();
  const { nextAvailableDatesByPrescriptionID } = useNextAvailableDatesForPrescriptions();

  const isEditOrder = useSelectorShared(getIsEditingExistingOrder);
  const { earliestAvailableDate } = useCartNextAvailableDate();
  // Build a unique list of earliest available dates based on items in the order
  const earliestDates = prescriptions.reduce<string[]>((dates, { id }) => {
    const date = nextAvailableDatesByPrescriptionID[id]?.earliest.date;
    if (date && !dates.includes(date)) {
      dates.push(date);
    }
    return dates;
  }, []);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ORDER_DATE_PICKER_VIEWED,
      params: {
        shipmentNextAvailableDate: earliestAvailableDate,
        numPrescriptions: prescriptions.length,
        numUniqueNextAvailableDates: earliestDates.length,
        origin: isEditOrder ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
      },
    });
  }, [earliestAvailableDate, earliestDates.length, isEditOrder, prescriptions.length, trackPageView]);
};
