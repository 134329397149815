const WEB_NAVBAR_WIDTH = {
  SM: '100%',
  MD: '100%',
  LG: '1170px',
};

const WEB_NAVBAR_HEIGHT = {
  SM: '56px',
  MD: '56px',
  LG: '72px',
};

const WEB_FOOTER_HEIGHT = {
  SM: '261px',
  MD: '285px',
  LG: '141px',
};

const CONTENT_WIDTH = {
  SM: '100%',
  MD: '100%',
  LG: '600px',
};

export const PAGE = {
  WEB_NAVBAR_WIDTH,
  WEB_NAVBAR_HEIGHT,
  WEB_FOOTER_HEIGHT,
  CONTENT_WIDTH,
};
