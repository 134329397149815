// @owners { team: patients-team }
import { ANIMATION, COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  AltoSpinningLoader,
  Body,
  Column,
  FlatCard,
  LgPadding,
  ListBase,
  ListDescription,
  ListItem,
  type ListItemProps,
  MdSpacing,
  Toast,
  ToastContext,
} from '@alto/design-system';
import { useAddToCart } from '@alto/features';
import { GetItSoonerOptionTypeMap } from '@alto/scriptdash/alto/patient_app/availability_options/types/v1/get_it_sooner';
import {
  AvailabilityOptionsEndpoint,
  type AvailabilityOptionsEndpointFetchAllResponse,
} from '@alto/scriptdash/alto/patient_app/availability_options/v2/availability_options_endpoint';
import { PriceOverrideReasonMap } from '@alto/scriptdash/alto/pricing/types/v3/price_override_reason';
import { differenceInDays, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import styledNative from 'styled-components/native';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
// eslint-disable-next-line import/no-deprecated
import { autobillPrescription } from '~shared/actions/prescriptions';
import { closeCart, openCart } from '~shared/actions/ui/cart';
import { usePrescriptionNextAvailableDateMessaging } from '~shared/features/cart/hooks/usePrescriptionNextAvailableDateMessaging';
import { reasonIsRefillTooSoon } from '~shared/features/delivery/helpers';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getNextAvailableDateAddressID } from '~shared/features/next-available-date/selectors/getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from '~shared/features/next-available-date/selectors/getNextAvailableDateFacilityId';
import { getOrderPricingLastFetchedAt } from '~shared/features/pricing/selectors/getPricing';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { apiEndpointHandler } from '~shared/helpers/api';
import { formatDollars } from '~shared/helpers/currency';
import { formatNumberOfHoursAgo, formatRelativeDate } from '~shared/helpers/date';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
// eslint-disable-next-line import/no-deprecated
import { fetchLatestVacationSupply } from '~shared/queries/useFetchLatestVacationSupply';
import { useQuery } from '~shared/react-query';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { VacationSupplyActionSheet } from '~web/features/my-meds/components/modals/VacationSupply/VacationSupply.action-sheet';
import { VacationSupplyFailureActionSheet } from '~web/features/my-meds/components/modals/VacationSupply/VacationSupplyFailure.action-sheet';

const availabilityOptionsEndpoint = AvailabilityOptionsEndpoint(apiEndpointHandler);

const ContentContainer = styledNative(Column)`
  position: relative;
`;

type ContentLoaderProps = {
  visible: boolean;
};

const ContentLoader = styledNative(Column)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  transition: opacity ${ANIMATION.SPEEDS.DEFAULT};

  ${({ visible }: ContentLoaderProps) => `
    opacity: ${visible ? '1' : '0'};
    pointer-events: ${visible ? 'auto' : 'none'};
  `}
`;

type GetItSoonerOptionType = 'pay_cash' | 'run_insurance' | 'vacation_override' | 'lost_medication_override';

type GetItSoonerActionSheetProps = {
  readonly onClose: () => void;
  readonly prescription: Prescription;
  readonly origin: string;
};

export const GetItSoonerActionSheet = ({ onClose, prescription, origin }: GetItSoonerActionSheetProps) => {
  const { setActiveActionSheet, closeActionSheet } = React.useContext(ActionSheetContext);
  const { addToast } = React.useContext(ToastContext);
  const { nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID: prescription.id });
  const isRefillTooSoon = reasonIsRefillTooSoon(nextAvailableDate?.earliest.reason || '');
  const { message: nextAvailableDateMessage } = usePrescriptionNextAvailableDateMessaging({
    prescriptionID: prescription.id,
    excludeDateFromMessage: true,
  });

  const dispatch = useDispatchShared();
  const addressID = useSelectorShared(getNextAvailableDateAddressID);
  const facilityID = useSelectorShared(getNextAvailableDateFacilityID);

  const availabilityOptionsParams = {
    prescription_id: prescription.id,
    address_id: Number(addressID),
    facility_id: facilityID,
  };
  const { data, isLoading } = useQuery<AvailabilityOptionsEndpointFetchAllResponse>({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['prescription-availability-options', availabilityOptionsParams],
    queryFn: () => availabilityOptionsEndpoint.fetchAll(availabilityOptionsParams),
  });

  const { handleAddToCart, isPending: isAddToCartLoading } = useAddToCart();

  const formatRelativeOptions = {
    includeRelative: false,
    makeLowerCase: true,
    includeDayOfWeek: false,
  };
  let actionSheetTitle = nextAvailableDate?.earliest.date
    ? `Delivery as early as ${formatRelativeDate(nextAvailableDate.earliest.date, formatRelativeOptions)}`
    : '';
  const cashPayOption = data?.data?.options.one_time_cash_pay;
  if (cashPayOption) {
    actionSheetTitle = `Delivery as early as ${formatRelativeDate(
      cashPayOption.available_date.date ?? '',
      formatRelativeOptions,
    )}`;
  }

  const daysSinceLastDelivery = prescription.last_delivered_at
    ? differenceInDays(new Date(), parseISO(prescription.last_delivered_at))
    : 0;
  const infoBoxPrimaryText = `Insurance covered this ${
    daysSinceLastDelivery > 0 ? `${daysSinceLastDelivery} days ago` : 'recently'
  }`;

  const infoBoxDescription = prescription.last_delivered_at
    ? `This was last filled on ${formatRelativeDate(prescription.last_delivered_at, {
        includeRelative: true,
        makeLowerCase: true,
      })}`
    : '';

  const PREFILLED_MESSAGES = {
    vacation_override: `I'm going to be traveling and need a vacation supply of my ${prescription.medication_name}.%0A%0A**FILL IN YOUR TRAVEL INFO BELOW**%0A%0ATravel dates:%0A%0ATravel destination:%0A%0ANeed medication before:`,
    lost_medication_override: `I lost my ${prescription.medication_name} and need to get more.%0A%0A**ADD ADDITIONAL INFO BELOW**%0A%0A`,
  };

  // Track when the patient chose to pay with cash.
  // This date will be compared to when the pricing was fetched to determine when to close the action sheet.
  const [optedIntoCashPayAt, setOptedIntoCashPayAt] = React.useState<number | undefined>(undefined);
  const [showLoadingState, setShowLoadingState] = useState(false);

  const pricingLastFetchedAt = useSelectorShared(getOrderPricingLastFetchedAt);
  const isEditingExistingOrder = useSelectorShared(getIsEditingExistingOrder);

  // Compare the time patient opted into cash price with when order pricing is updated.
  // This determines when to close the action sheet flow
  React.useEffect(() => {
    if (optedIntoCashPayAt && pricingLastFetchedAt && pricingLastFetchedAt > optedIntoCashPayAt) {
      setOptedIntoCashPayAt(undefined);
      closeActionSheet();
    }
  }, [optedIntoCashPayAt, pricingLastFetchedAt, closeActionSheet]);

  useEffect(() => {
    if (!isLoading) {
      const cashPayOption = data?.data?.options.one_time_cash_pay;
      dispatch(
        sendAnalyticsEvent(
          createEvent(
            EVENTS.GET_IT_SOONER_ACTION_SHEET_VIEWED,
            {
              payCashOptionAvailable: !!cashPayOption,
              vacationOverrideOptionAvailable: true,
              lostMedicationOverrideOptionAvailable: true,
              origin,
            },
            { prescriptionId: prescription.id },
          ),
        ),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    setShowLoadingState(isAddToCartLoading);
  }, [isAddToCartLoading]);

  const sendAnalyticsOnOptionSelect = (option: GetItSoonerOptionType) => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(EVENTS.GET_IT_SOONER_ACTION_SHEET_OPTION_SELECTED, { option }, { prescriptionId: prescription.id }),
      ),
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleOptionSelect = async (option: GetItSoonerOptionType) => {
    const showVacationSupplySelfService = data?.data?.options.vacation_supply?.self_service_allowed;

    switch (option) {
      case 'pay_cash':
        sendAnalyticsOnOptionSelect(option);
        if (isEditingExistingOrder) {
          addToast(
            <Toast variant="error">Unable to switch to cash price because the order is already scheduled.</Toast>,
          );
        } else {
          const result = await handleAddToCart({
            resource_id: prescription.id,
            resource_type: 'Prescription',
            resetOrderDate: true,
            billing_overrides: {
              reason: PriceOverrideReasonMap.ONE_TIME_CASH_PAY,
            },
          });
          if (result.success) {
            dispatch(openCart());
            // Keep track of when user opted in to cash price
            setOptedIntoCashPayAt(new Date().valueOf());
          } else {
            addToast(<Toast variant="error">There was an error adding to your cart. Please try again later.</Toast>);
          }
          onClose();
        }
        break;
      case 'run_insurance': {
        sendAnalyticsOnOptionSelect(option);
        setShowLoadingState(true);
        // eslint-disable-next-line import/no-deprecated
        const autobillResult = await dispatch(autobillPrescription(prescription.id));
        setShowLoadingState(false);

        if (autobillResult.autobillSuccess) {
          addToast(<Toast variant="success">We’ve run this medication through your insurance on file</Toast>);
        } else {
          addToast(<Toast variant="error">There was an error running your insurance. Please try again later.</Toast>);
        }

        await Promise.all([
          // eslint-disable-next-line import/no-deprecated
          dispatch(fetchNextAvailableDates({ unbundledPrescriptionIDs: [prescription.id] })),
          // eslint-disable-next-line import/no-deprecated
          dispatch(fetchDeliveries()),
        ]);

        onClose();
        break;
      }
      case 'vacation_override':
        sendAnalyticsOnOptionSelect(option);
        if (showVacationSupplySelfService) {
          setShowLoadingState(true);
          // eslint-disable-next-line import/no-deprecated
          const vacationSupplyRequest = await fetchLatestVacationSupply({
            prescription_id: prescription.id,
            status: 'failed',
          });
          setShowLoadingState(false);

          if (vacationSupplyRequest?.data) {
            setActiveActionSheet(
              <VacationSupplyFailureActionSheet
                prescription={prescription}
                vacationSupply={vacationSupplyRequest.data}
              />,
            );
          } else {
            setActiveActionSheet(<VacationSupplyActionSheet prescription={prescription} />);
          }
        } else {
          dispatch(push(`/messages?message=${PREFILLED_MESSAGES[option]}`));
          dispatch(closeCart());
          onClose();
          closeActionSheet();
        }
        break;
      case 'lost_medication_override':
        sendAnalyticsOnOptionSelect(option);
        dispatch(push(`/messages?message=${PREFILLED_MESSAGES[option]}`));
        dispatch(closeCart());
        onClose();
        closeActionSheet();
        break;
      default:
        break;
    }
  };

  const buildOptions = (): (ListItemProps & { key: string })[] => {
    const getItSoonerOptions = data?.data?.options;
    const cashPayOption = getItSoonerOptions?.one_time_cash_pay;
    if (!getItSoonerOptions) {
      return [];
    }

    return Object.keys(getItSoonerOptions).map((option) => {
      if (option === GetItSoonerOptionTypeMap.ONE_TIME_CASH_PAY && cashPayOption) {
        const { price } = cashPayOption.price_option;
        const cashText = 'Pay cash price';
        return {
          key: 'pay-cash-price',
          descriptions: [
            <ListDescription key="date without insurance">
              <>
                Get it{' '}
                {formatRelativeDate(cashPayOption.available_date.date ?? '', {
                  includeRelative: false,
                  makeLowerCase: true,
                })}{' '}
                without insurance
              </>
            </ListDescription>,
          ],
          title: price ? `${cashText}: ${formatDollars(price)}` : cashText,
          LeftContent: <AltoIcon name="pricetag-duo" />,
          RightContent: <AltoIcon name="chevronright" />,
          onPress: () => handleOptionSelect('pay_cash'),
        };
      }

      if (option === GetItSoonerOptionTypeMap.RUN_INSURANCE) {
        const descriptions = [
          <ListDescription key="run insurance again">
            If you’ve resolved any open issues, we can run your insurance again
          </ListDescription>,
        ];
        const lastRunAt = getItSoonerOptions?.run_insurance?.last_billed_at;
        if (lastRunAt) {
          descriptions.push(
            <ListDescription key="last-run-at">{`Last attempt: ${formatNumberOfHoursAgo(lastRunAt)}`}</ListDescription>,
          );
        }
        return {
          key: 'run-insurance',
          descriptions,
          title: 'Run your insurance',
          LeftContent: <AltoIcon name="shieldslash-duo" />,
          RightContent: <AltoIcon name="chevronright" />,
          onPress: () => handleOptionSelect('run_insurance'),
        };
      }

      if (option === GetItSoonerOptionTypeMap.VACATION_SUPPLY) {
        return {
          key: 'request-vacation-supply',
          descriptions: [<ListDescription key="available 6 months">Available every 6 months</ListDescription>],
          title: 'Request vacation supply',
          iconName: 'alert-duo' as const,
          LeftContent: <AltoIcon name="paperairplane-duo" />,
          RightContent: <AltoIcon name="chevronright" />,
          onPress: () => handleOptionSelect('vacation_override'),
        };
      }

      return {
        key: 'report-lost-medication',
        descriptions: [<ListDescription key="medication override">Get medication override</ListDescription>],
        title: 'Report lost medication',
        LeftContent: <AltoIcon name="alert-duo" />,
        RightContent: <AltoIcon name="chevronright" />,
        onPress: () => handleOptionSelect('lost_medication_override'),
      };
    });
  };

  const getItems = () => {
    const options = buildOptions();
    return options.map(({ key, ...option }) => ({
      key,
      component: <ListItem {...option} />,
    }));
  };

  return (
    <ActionSheetV2
      title={actionSheetTitle}
      analyticsName="get it sooner"
    >
      {!isRefillTooSoon && (
        <LgPadding>
          <Body>{nextAvailableDateMessage}</Body>
        </LgPadding>
      )}
      {isRefillTooSoon ? (
        <ContentContainer>
          <LgPadding
            topPadding={SPACING.STATIC.NONE}
            bottomPadding={SPACING.STATIC.NONE}
          >
            <FlatCard>
              <LgPadding>
                <Body
                  fontFamily="semibold"
                  color={COLORS.TEXT_COLORS.PRIMARY}
                >
                  {infoBoxPrimaryText}
                </Body>
                {!!infoBoxDescription && (
                  <>
                    <MdSpacing />
                    <Body color={COLORS.TEXT_COLORS.GREY}>{infoBoxDescription}</Body>
                  </>
                )}
              </LgPadding>
            </FlatCard>
          </LgPadding>
          {!isLoading && <ListBase items={getItems()} />}
          {/* Loader to prevent clicks */}
          <ContentLoader
            visible={!!optedIntoCashPayAt || showLoadingState}
            centerVertically
            centerHorizontally
          >
            <AltoSpinningLoader />
          </ContentLoader>
        </ContentContainer>
      ) : null}
    </ActionSheetV2>
  );
};
