import React, { PureComponent } from 'react';
import { push } from 'react-router-redux';
import { initializeExistingOrder, setOrderAtIndex, updateOrder } from '~shared/actions/cart';
import { openModal } from '~shared/actions/modal';
import { type BadgeCounts, setBadgeCounts } from '~shared/actions/notices';
import { getShipmentWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { type Event } from '~shared/lib/analytics/src/types';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxGetStateShared, type ReduxStateShared } from '~shared/types';
import { Homescreen } from './Homescreen';
import { type Props as PrescriptionTrackerProps } from './prescription-tracker/PrescriptionTrackerModal';

export type Props = {
  readonly currentUserID: number | null;
  readonly navigateToMedList: () => void;
  readonly onViewDelivery: (id: number) => void;
  readonly navigateToSendPrescriptionOptions: () => void;
  readonly openPharmacyInfoModal: () => void;
  readonly openPrescriptionTrackerModal: (data: PrescriptionTrackerProps) => void;
  readonly sendAnalyticsEvent: (event: Event) => void;
  readonly setBadgeCounts: (badgeCounts: BadgeCounts) => void;
  readonly editShipment: (shipmentID: number) => void;
};

class HomescreenContainer extends PureComponent<Props> {
  render() {
    const {
      currentUserID,
      onViewDelivery,
      navigateToSendPrescriptionOptions,
      openPharmacyInfoModal,
      openPrescriptionTrackerModal,
      navigateToMedList,
      sendAnalyticsEvent,
      setBadgeCounts,
      editShipment,
    } = this.props;
    return (
      <Homescreen
        onViewDelivery={onViewDelivery}
        navigateToSendPrescriptionOptions={navigateToSendPrescriptionOptions}
        openPharmacyInfoModal={openPharmacyInfoModal}
        openPrescriptionTrackerModal={openPrescriptionTrackerModal}
        navigateToMedList={navigateToMedList}
        currentUserID={currentUserID}
        sendAnalyticsEvent={sendAnalyticsEvent}
        setBadgeCounts={setBadgeCounts}
        editShipment={editShipment}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStateShared) => {
  const currentUserID = getCurrentUserID(state);

  return {
    currentUserID,
  };
};

function navigateToEditOrderDetails(shipmentID: number) {
  return (dispatch: ReduxDispatchShared, getState: ReduxGetStateShared) => {
    const existingOrder = getShipmentWithItemsByID(getState(), {
      id: shipmentID,
    });
    // @ts-expect-error Type 'ShipmentWithItems' is not assignable to type 'Order'
    dispatch(setOrderAtIndex({ order: existingOrder, orderIndex: 0 }));
    dispatch(
      initializeExistingOrder({
        // @ts-expect-error TS(2322): Type 'ShipmentWithItems' is not assignable to type 'Order'.
        order: existingOrder,
        shipmentID,
      }),
    );
    dispatch(
      updateOrder({
        origin: 'Existing Order',
      }),
    );
    dispatch(push('/checkout'));
  };
}

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    navigateToMedList() {
      dispatch(push({ pathname: '/prescriptions', state: { origin: ORIGIN_NAMES.HOMESCREEN } }));
    },

    navigateToSendPrescriptionOptions() {
      dispatch(push('/me/send-prescription-options'));
    },

    onViewDelivery(shipmentID: number) {
      dispatch(push(`/orders/${shipmentID}`));
    },

    openPharmacyInfoModal: () => {
      dispatch(openModal('PHARMACY_INFO_MODAL'));
    },
    openPrescriptionTrackerModal: (modalProps: PrescriptionTrackerProps) => {
      dispatch(openModal('PRESCRIPTION_TRACKER_MODAL', modalProps));
    },
    sendAnalyticsEvent: (event: Event) => {
      dispatch(sendAnalyticsEvent(event));
    },
    setBadgeCounts: (badgeCounts: BadgeCounts) => dispatch(setBadgeCounts(badgeCounts)),
    editShipment: (shipmentID: number) => {
      dispatch(navigateToEditOrderDetails(shipmentID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomescreenContainer);
