// @owners { team: patients-team }
export const isAccountRoute = (routeName: string) => {
  return routeName === '/me' || routeName.includes('/me/');
};

export const isMedsRoute = (routeName: string) => {
  return routeName === '/prescriptions' || routeName.includes('/prescriptions/');
};

export const isOrdersRoute = (routeName: string) => {
  return routeName === '/orders' || routeName.includes('/orders/');
};

export const isSupportRoute = (routeName: string) => {
  return ['/help', '/help/message', '/messages'].includes(routeName);
};

export const isNotificationsRoute = (routeName: string) => {
  return routeName === '/notifications';
};

export const isHomeRoute = (routeName: string) => {
  return routeName === '/home';
};

const FAMILY_MEMBER_ROUTE_SUFFIXES_WITHOUT_NAV_BAR_ICONS = [
  'medical-info',
  'add-benefits',
  'complete-profile',
  'add-benefit',
  'benefits',
  'benefits/photos',
];

export const isAddFamilyMemberRoute = (routeName: string, relationship: string) => {
  if (!relationship) return false;

  return FAMILY_MEMBER_ROUTE_SUFFIXES_WITHOUT_NAV_BAR_ICONS.some((path) => routeName.endsWith(path));
};

const ROUTE_PREFIXES_WITHOUT_FOOTER = ['/onboard', '/help', '/patient-onboarding', '/me/family_member'];

export const isRouteWithoutFooter = (routeName: string) => {
  return ROUTE_PREFIXES_WITHOUT_FOOTER.some((route) => routeName.startsWith(route));
};
