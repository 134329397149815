// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import {
  CANCEL_DELIVERIES_SUCCEEDED,
  CLEAR_DELIVERY_NOTIFICATIONS,
  type DeliveryActions,
  FETCH_ACTIVE_DELIVERIES_LOADING,
  FETCH_ACTIVE_DELIVERIES_SUCCEEDED,
  FETCH_DELIVERIES_FAILED,
  FETCH_DELIVERIES_LOADING,
  FETCH_DELIVERIES_SUCCEEDED,
} from '~shared/actions/deliveries';
import { immutableBulkUpsert } from '~shared/helpers/immutableUpsert';
import { type LightDelivery } from '~shared/types';

export type DeliveryState = {
  deliveries: LightDelivery[];
  lastRequestAttemptedAt: number;
  requestAttemptCount: number;
  lastFetchAllRequestSucceededAt: number;
  lastRequestSucceededAt: number;
  selectedOrderID: number | string | null;
};

const initialState: DeliveryState = {
  deliveries: [],
  lastRequestAttemptedAt: 0,
  requestAttemptCount: 0,
  lastFetchAllRequestSucceededAt: 0,
  lastRequestSucceededAt: 0,
  selectedOrderID: null,
};

const deliveries = (state = initialState, action: DeliveryActions): DeliveryState => {
  switch (action.type) {
    case FETCH_DELIVERIES_SUCCEEDED:
      return {
        ...state,
        deliveries: action.payload,
        lastRequestSucceededAt: Date.now(),
        lastFetchAllRequestSucceededAt: Date.now(),
        requestAttemptCount: 0,
      };

    case FETCH_ACTIVE_DELIVERIES_SUCCEEDED:
      return {
        ...state,
        deliveries: immutableBulkUpsert({
          state: state.deliveries,
          payload: action.payload,
        }).filter((d) => d.status !== 'canceled'),
        lastRequestSucceededAt: Date.now(),
        requestAttemptCount: 0,
      };

    case CANCEL_DELIVERIES_SUCCEEDED:
      return {
        ...state,
        deliveries: state.deliveries.filter((delivery) => {
          return action.payload.findIndex((deleted) => deleted.id === delivery.id) === -1;
        }),
      };

    case FETCH_DELIVERIES_FAILED:
      return { ...state };

    case FETCH_DELIVERIES_LOADING:
    case FETCH_ACTIVE_DELIVERIES_LOADING:
      return { ...state, lastRequestAttemptedAt: Date.now(), requestAttemptCount: state.requestAttemptCount + 1 };

    case CLEAR_DELIVERY_NOTIFICATIONS:
      return { ...state };

    default:
      return state;
  }
};

export default withRehydration(deliveries, initialState);
