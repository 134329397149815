import { type BACKGROUND_COLORS_VALUES, BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { type ImageSourcePropType, View } from 'react-native';
import styled from 'styled-components/native';
import { type Avatar } from '../../avatars';
import { Pressable } from '../../buttons';
import { Row } from '../../containers';
import { Illustration } from '../../images';
import { LgSpacing, XlSpacing, XsSpacing } from '../../separators';
import { Body, H1 } from '../../typography';
import { BasePage, type BasePageProps } from './BasePage';
import { NavBar, type NavBarProps } from './NavBar';

type Props = {
  readonly title: string;
  readonly subtitle?: string | React.ReactElement<typeof Body>;
  readonly headerBgColor: BACKGROUND_COLORS_VALUES;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly imageSrc?: ImageSourcePropType;
  readonly imageAccessibilityLabel?: string;
  readonly statusBarHeight?: number;
  readonly children: React.ReactNode;
  readonly onPress?: () => void;
  readonly xlIllustration?: boolean;
  readonly avatar?: React.ReactElement<typeof Avatar>;
  readonly RightHeaderPressable?: NavBarProps['RightPressable'];
} & Pick<BasePageProps, 'buttons' | 'Footnote'>;

type HeaderContainerProps = {
  statusBarHeight: Props['statusBarHeight'];
  headerBgColor: Props['headerBgColor'];
};

const HeaderContainer = styled(View)<HeaderContainerProps>`
  padding: ${({ statusBarHeight }) => SPACING.STATIC.XXL.value + (statusBarHeight || 0)}px ${SPACING.STATIC.LG.px}
    ${SPACING.STATIC.XL.px} ${SPACING.STATIC.LG.px};
  background-color: ${({ headerBgColor }) => headerBgColor};
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  border-bottom-width: ${BORDERS.SIZE}px;
  border-bottom-color: ${COLORS.BORDER_COLORS.LIGHT};
`;

const ContentContainer = styled(View)`
  z-index: 1;
  margin-top: -${SPACING.STATIC.XL.px};
`;

const defaultOnPress = () => null;
export const HeroPage = ({
  title,
  subtitle,
  headerBgColor,
  backgroundColor,
  imageSrc,
  imageAccessibilityLabel = '',
  statusBarHeight = 0,
  children,
  onPress = defaultOnPress,
  xlIllustration = false,
  avatar,
  buttons,
  Footnote,
  RightHeaderPressable,
}: Props) => {
  return (
    <BasePage
      buttons={buttons}
      Footnote={Footnote}
      transparentNavBar
      backgroundColor={backgroundColor}
      NavBar={RightHeaderPressable ? <NavBar RightPressable={RightHeaderPressable} /> : undefined}
    >
      <HeaderContainer
        statusBarHeight={statusBarHeight}
        headerBgColor={headerBgColor}
      >
        <XsSpacing />
        <Row
          centerVertically
          centerHorizontally
        >
          <Pressable
            accessibilityLabel={imageAccessibilityLabel}
            backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
            onPress={onPress}
            opacity={!onPress}
          >
            {avatar ||
              (imageSrc && (
                <Illustration
                  source={imageSrc}
                  accessibilityLabel={imageAccessibilityLabel}
                  size={xlIllustration ? 'xl' : 'lg'}
                />
              ))}
          </Pressable>
        </Row>
        <LgSpacing />
        <Row center>
          <H1 center>{title}</H1>
        </Row>
        {subtitle ? (
          <>
            <XsSpacing />
            <Body center>{subtitle}</Body>
          </>
        ) : null}
        <XlSpacing />
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </BasePage>
  );
};
