export const toOxfordCommaList = (items: string[]) => {
  if (items.length === 0) {
    return '';
  }

  if (items.length === 1) {
    return items[0];
  }

  if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  }

  return [...items.slice(0, items.length - 1), `and ${items[items.length - 1]}`].join(', ');
};

export const toNumbersOnly = (value: string | undefined) => {
  return value ? value.replace(/[^\d]/g, '') : '';
};

export const toFormattedPhoneNumber = (value: string | undefined, isMaestro?: boolean): string => {
  if (!value) {
    return '';
  }

  if (isMaestro) {
    return value;
  }

  const phoneNumber = toNumbersOnly(value);
  const phoneNumberLength = phoneNumber.length;
  const first3 = phoneNumber.slice(0, 3);
  const next3 = phoneNumber.slice(3, 6);
  const last4 = phoneNumber.slice(6, 10);

  if (phoneNumberLength <= 2) {
    return `(${first3}`;
  }

  if (phoneNumberLength === 3) {
    return `(${first3})`;
  }

  if (phoneNumberLength <= 5) {
    return `(${first3}) ${next3}`;
  }

  return `(${first3}) ${next3}-${last4}`;
};

type CharacterAndIndex = {
  character: string;
  index: number;
};

export const findFirstDifferentCharacter = (
  stringAfterDelete: string,
  stringBeforeDelete: string,
): CharacterAndIndex => {
  const stringBeforeDeleteArray = stringBeforeDelete.split('');
  let diff = '';
  let diffIndex = 0;
  for (let index = 0; index < stringBeforeDeleteArray.length; index++) {
    const character = stringBeforeDeleteArray[index];
    if (character !== stringAfterDelete.charAt(index)) {
      diff = character;
      diffIndex = index;
      break;
    }
  }
  return {
    character: diff,
    index: diffIndex,
  };
};
