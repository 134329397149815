import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Card, H2, LgPadding, MdSpacing, useScreenSize } from '@alto/design-system';
import { type Prescription as HomescreenPrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { SECTION_LABELS } from '~shared/features/homescreen/constants';
import DueForRefillMedicationInfo from './DueForRefillMedicationInfo';

const CustomCardFrame = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const CustomLgPadding = styledNative(LgPadding)`
  height: 100%;
`;

const CustomCard = styledNative(Card)<{ isSMScreenOrBigger: boolean }>`
  margin: 0 ${SPACING.STATIC.XXS.px};
  z-index: 0;
  height: unset;
  min-height: 205px;
  max-width: 160px;
  > * {
    max-width: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  ${({ isSMScreenOrBigger }) =>
    isSMScreenOrBigger &&
    `
    height: 100%;
    min-height: 212px;
    max-width: initial;
    &:first-child {
      margin-left: initial;
    }
  `}
`;

const MobileCustomCardRow = styled.div<{ isSMScreenOrBigger: boolean }>`
  display: flex;
  overflow-x: auto;
  max-width: calc(100vw - ${SPACING.STATIC.MD.px} * 2);
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `display: none;`}
`;

const DesktopCustomCardRow = styled.div<{ isSMScreenOrBigger: boolean }>`
  margin: 0 -${SPACING.STATIC.XXS.px} -${SPACING.STATIC.XS.px};
  display: none;
  flex-wrap: wrap;
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `display: flex;`}
`;

const StyledCol = styled.div<{ isMDScreenOrBigger: boolean }>`
  padding-bottom: ${SPACING.STATIC.XS.px};
  max-width: 33.33%;
  width: 33.33%;
  flex-grow: 1;
  min-width: 280px;
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `width: 50%; max-width: 50%;`}
`;

const CardContent = styled.div`
  height: 100%;
`;

type Props = {
  readonly prescriptions: HomescreenPrescription[];
  readonly isTopSection: boolean;
};

export default function DueForRefillPrescriptions({ prescriptions, isTopSection }: Props) {
  const { isSMScreenOrBigger, isMDScreenOrBigger } = useScreenSize();

  return (
    <CustomCardFrame>
      {!isTopSection && (
        <>
          <H2 color={COLORS.TEXT_COLORS.PRIMARY}>{SECTION_LABELS.DUE_FOR_REFILL}</H2>
          <MdSpacing />
        </>
      )}
      <MobileCustomCardRow isSMScreenOrBigger={isSMScreenOrBigger}>
        {prescriptions.map((prescription) => (
          <CustomCard
            key={prescription.id}
            isSMScreenOrBigger={isSMScreenOrBigger}
          >
            <CustomLgPadding>
              <CardContent>
                <DueForRefillMedicationInfo prescription={prescription} />
              </CardContent>
            </CustomLgPadding>
          </CustomCard>
        ))}
      </MobileCustomCardRow>
      <DesktopCustomCardRow isSMScreenOrBigger={isSMScreenOrBigger}>
        {prescriptions.map((prescription) => (
          <StyledCol
            isMDScreenOrBigger={isMDScreenOrBigger}
            key={prescription.id}
          >
            <CustomCard isSMScreenOrBigger={isSMScreenOrBigger}>
              <CustomLgPadding>
                <CardContent>
                  <DueForRefillMedicationInfo prescription={prescription} />
                </CardContent>
              </CustomLgPadding>
            </CustomCard>
          </StyledCol>
        ))}
      </DesktopCustomCardRow>
    </CustomCardFrame>
  );
}
