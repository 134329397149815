// @owners { team: patients-team }
import {
  ONBOARD_STATE_TO_ROUTE,
  type Route,
  currentStep,
  nextOnboardingRoute,
  previousOnboardingRoute,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { ROUTE_TO_URL, URL_TO_ROUTE } from '~shared/features/onboarding/helpers/routeToPath';
import { type ReduxStateShared } from '~shared/types';

export function nextUrl(state: ReduxStateShared, currentRoute: Route, desiredRoute?: Route): string {
  const nextRoute = nextOnboardingRoute(state, currentRoute, desiredRoute);

  // TODO: handle when nextRoute === currentRoute
  return ROUTE_TO_URL[nextRoute];
}

export function previousUrl(state: ReduxStateShared, currentRoute: Route): string | null | undefined {
  const previousRoute = previousOnboardingRoute(state, currentRoute);

  // TODO: handle when previousRoute === currentRoute
  if (previousRoute) {
    return ROUTE_TO_URL[previousRoute];
  }

  return null;
}

export function currentUrlStep(url: string) {
  return currentStep(URL_TO_ROUTE[url]);
}

export function urlFromOnboardState(onboardState: string | null | undefined): string | null | undefined {
  if (onboardState) {
    const route = ONBOARD_STATE_TO_ROUTE[onboardState];

    if (route) {
      return ROUTE_TO_URL[route] || null;
    }
  }

  return null;
}
