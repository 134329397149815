import { SIZES } from '@alto/design-library-tokens';
import React from 'react';
import { Image, type ImageErrorEventData, type ImageSourcePropType, type NativeSyntheticEvent } from 'react-native';
import styled from 'styled-components/native';

type IllustrationKeys = keyof typeof SIZES.ILLUSTRATION;

type SizeProps = {
  readonly height?: IllustrationKeys;
  readonly width?: IllustrationKeys;
};

export type NavBarLogoProps = SizeProps & {
  readonly source: ImageSourcePropType | string;
  readonly accessibilityLabel?: string;
  readonly onError?: (error: NativeSyntheticEvent<ImageErrorEventData>) => void;
  readonly testID?: string;
};

const Logo = styled(Image)<{ imageHeight: IllustrationKeys; imageWidth: IllustrationKeys }>`
  ${({ imageHeight, imageWidth }) => {
    const h = SIZES.ILLUSTRATION[imageHeight].px;
    const w = SIZES.ILLUSTRATION[imageWidth].px;
    return `
      width: ${w};
      height: ${h};
    `;
  }}
`;

export const NavBarLogo = ({
  height = 'SM',
  width = 'XL',
  source,
  accessibilityLabel,
  onError,
  testID,
}: NavBarLogoProps) => {
  const src: ImageSourcePropType = typeof source === 'string' ? { uri: source } : source;
  return (
    <Logo
      imageHeight={height}
      imageWidth={width}
      accessibilityLabel={accessibilityLabel}
      source={src}
      onError={onError}
      testID={testID}
    />
  );
};
