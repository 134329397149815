import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { Text, type TextProps } from './Text';

type LinkProps = {
  readonly onPress?: () => void;
  readonly underline?: boolean;
} & TextProps;

export const Link = styled(Text).attrs(({ color = COLORS.TEXT_COLORS.SECONDARY_DARK, fontFamily }: LinkProps) => ({
  color,
  fontFamily: fontFamily ?? 'semibold',
}))<LinkProps>`
  ${({ color, underline }) => underline && `text-decoration-color: ${color ?? COLORS.TEXT_COLORS.SECONDARY};`}
  ${({ underline }) => underline && `text-decoration-line: underline`}
`;

type ExternalLinkProps = LinkProps & {
  readonly href: string;
};

export const ExternalLink = ({ onPress, href, ...rest }: ExternalLinkProps) => {
  const handleOnPress = () => {
    Linking.openURL(href);
    onPress?.();
  };

  return (
    <Link
      onPress={handleOnPress}
      {...rest}
    />
  );
};
