// @owners { team: patients-team }
import { createSelector } from 'reselect';
import compareFamilyMembers from '~shared/features/family-accounts/helpers/compareFamilyMembers';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getUsers } from '~shared/features/users/selectors/getUsers';

// We assume all users in state are members within the family the current user
// can manage.
export const getFamilyMembers = createSelector(getUsers, getCurrentUser, (users, currentUser) => {
  return users.sort((user1, user2) => compareFamilyMembers(user1, user2, currentUser));
});
