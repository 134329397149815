// eslint-disable-next-line @alto/no-pocky-import
import { SimpleContainer, SimpleContainerHeader, SimpleContainerSection, spacing } from '@alto/pocky';
import React, { type ReactElement } from 'react';
import styled from 'styled-components';

const StyledSimpleContainerSection = styled(SimpleContainerSection)<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ center }) =>
    center &&
    `
    justify-content: center;
    text-align: center;
  `}
  > *:not(:last-child) {
    margin-bottom: ${spacing.mdSpacing};
  }
`;

type Props = {
  readonly header?: React.ReactNode | string;
  readonly children: ReactElement | ReactElement[];
  readonly center?: boolean;
};

export default class EmptyStateBox extends React.PureComponent<Props> {
  static defaultProps = {
    center: false,
  };

  render() {
    const { header, children, center } = this.props;
    return (
      <SimpleContainer>
        {header ? <SimpleContainerHeader>{header}</SimpleContainerHeader> : null}
        <StyledSimpleContainerSection center={center}>{children}</StyledSimpleContainerSection>
      </SimpleContainer>
    );
  }
}
