// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Link, Row, SimpleContainer, SimpleContainerHeader, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { CONTACT_SUPPORT_HEADER, SEND_MESSAGE_FORK } from '~shared/features/profile/constants';
import BusinessHours from './BusinessHours';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, Main, PageBackground, Sidebar } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import pillBottle from '~web/images/pillBottle@3x.png';

export type Props = {
  readonly openMessages: () => void;
};

// TODO: consolidate page layout styles with SendPrescriptionOptions
// FullFlexRow but without the child selector margins
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0;
  width: 100%;
`;

const BodyCopy = styled(Text)<{ isSMScreenOrBigger: boolean }>`
  margin-bottom: ${SPACING.STATIC.XS.rem};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-bottom: ${SPACING.STATIC.LG.rem};`}
`;

const Card = styled(Row).attrs({
  verticallyAlignContent: true,
  evenlySpaceContent: true,
  spacing: SPACING.STATIC.MD.rem,
})`
  max-width: 25em;
  width: 100%;
  padding: ${SPACING.STATIC.MD.rem};
  border: ${BORDERS.SIZE}px solid ${COLORS.BORDER_COLORS.PRIMARY};
  border-radius: 4px;
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  box-shadow: 0 1px 5px rgba(25, 32, 38, 0.1);
  cursor: pointer;
  margin-top: ${SPACING.STATIC.MD.rem};

  /*
   * removes height: 100% set in Row with verticallyAlignContent
   * this allows them to be equal heights regardless of copy
   */
  height: initial;
`;

// Overriding spacing functionality on Row because when wrapping we need vertical spacing rather than horizontal
// a better way to do this might be to switch to Column on those breakpoints
const StyledRow = styled(Row)<{ isSMScreenOrBigger: boolean }>`
  > *:not(:last-child) {
    ${({ isSMScreenOrBigger, spacing }) =>
      !isSMScreenOrBigger &&
      `
      margin-right: 0;
      margin-top: ${spacing || 0};
    `}
  }
`;

const CardCopyContainer: typeof Column = styled(Column).attrs({
  leftAlignContent: true,
  evenlySpaceContent: true,
  parentHeight: true,
})``;

const CardImage = styled.img`
  height: 60px;
  width: 60px;
`;

const StyledSidebar = styled(Sidebar)`
  max-width: 25em;
`;

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Contact support',
  },
];

const ProfileContactSupport = ({ openMessages }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();
  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexRow>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <PageHeader>Contact support</PageHeader>
        </FullFlexRow>
        <Main>
          <MainContent>
            <BodyCopy
              isSMScreenOrBigger={isSMScreenOrBigger}
              bold
            >
              {CONTACT_SUPPORT_HEADER.TITLE}
            </BodyCopy>
            <StyledRow
              spacing={SPACING.STATIC.MD.rem}
              wrap
              isSMScreenOrBigger={isSMScreenOrBigger}
            >
              <Card onClick={openMessages}>
                <CardCopyContainer>
                  <Text>{SEND_MESSAGE_FORK.BODY}</Text>
                  <Link>{SEND_MESSAGE_FORK.CTA_TEXT_WEB}</Link>
                </CardCopyContainer>
                <CardImage
                  src={pillBottle}
                  alt={IMG_ALT_TEXTS.pillBottle}
                />
              </Card>
            </StyledRow>
          </MainContent>
        </Main>
        <StyledSidebar>
          <SimpleContainer>
            <SimpleContainerHeader>Business Hours</SimpleContainerHeader>
            <BusinessHours />
          </SimpleContainer>
        </StyledSidebar>
      </ContentWrapper>
    </PageBackground>
  );
};

export default ProfileContactSupport;
