// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  FlexRow,
  InfoBox,
  InputBlockRadio,
  InputRow,
  Paragraph,
  RadioOption,
  Text,
  createValidator,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik, type FormikProps } from 'formik';
import React from 'react';
import { NO_KNOWN_ALLERGIES, NO_KNOWN_MEDICAL_CONDITIONS } from '~shared/constants';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import AllergiesField from '~web/features/profile/components/AllergiesField';
import MedicalConditionsField from '~web/features/profile/components/MedicalConditionsField';

type Values = {
  allergies: string;
  medical_conditions: string;
  gender?: string;
};

const validate = createValidator<Values>({
  allergies: validatePresentWithMessage('Allergies'),
  medical_conditions: validatePresentWithMessage('Medical conditions'),
  gender: validatePresentWithMessage('Gender'),
});

type Props = {
  readonly onSubmit: (values: Values) => void;
  readonly user: User | undefined;
  readonly userError: APIError | null;
};

const OnboardingHealthProfileForm = ({ userError, user, onSubmit }: Props) => (
  <Formik
    validate={validate} // This prevents validation error form being shown after clicking textarea for first time
    validateOnBlur={false}
    initialValues={{
      allergies: user?.allergies || NO_KNOWN_ALLERGIES,
      medical_conditions: user?.medical_conditions || NO_KNOWN_MEDICAL_CONDITIONS,
      gender: user?.gender,
    }}
    onSubmit={onSubmit}
  >
    {/* @ts-expect-error formik is having issues with newest react */}
    {(formikProps: FormikProps<Values>) => (
      <Form
        {...formikProps}
        noValidate
      >
        <DeprecatedModalBody>
          <InputRow>
            <Text>Our pharmacists review every order, and use this info to ensure your well-being.</Text>
          </InputRow>
          {userError ? (
            <InfoBox warning>
              <Paragraph>{userError.message}</Paragraph>
            </InfoBox>
          ) : null}
          <InputRow>
            <FlexRow growToParentWidth>
              <AllergiesField />
            </FlexRow>
          </InputRow>
          <InputRow>
            <FlexRow growToParentWidth>
              <MedicalConditionsField />
            </FlexRow>
          </InputRow>
          <InputRow>
            <Field name="gender">
              {({ field, meta }: FieldProps<string>) => (
                <InputBlockRadio
                  required
                  label="Sex"
                  info="This should match your insurance."
                  input={field}
                  {...mapFormikFieldToInputProps(field, meta)}
                >
                  <RadioOption value="F">Female</RadioOption>
                  <RadioOption value="M">Male</RadioOption>
                  <RadioOption value="U">Other</RadioOption>
                </InputBlockRadio>
              )}
            </Field>
          </InputRow>
        </DeprecatedModalBody>
        <DeprecatedModalFooter>
          {/* @ts-expect-error Property 'onClick' is missing in type */}
          <Button type="submit">Next</Button>
        </DeprecatedModalFooter>
      </Form>
    )}
  </Formik>
);

export default OnboardingHealthProfileForm;
