import { SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Card,
  Description,
  InlineAlert,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  Separator,
} from '@alto/design-system';
import React from 'react';
import { updateOrder } from '~shared/actions/cart';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCheckoutSharpsContainerErrors } from '~shared/features/checkout/hooks/useCheckoutSharpsContainerErrors';
import { getShowingValidationErrors } from '~shared/features/checkout/selectors/getCart';
import { getOrderIncludeSharpsContainer } from '~shared/features/checkout/selectors/getOrder';
import { formatList } from '~shared/helpers/list';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const SharpsContainerCard = () => {
  const dispatch = useDispatchShared();
  const { injectables } = usePrescriptionsInCart();
  const order_includes_sharps_container = useSelectorShared(getOrderIncludeSharpsContainer);
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const { sharpsContainerValidationErrors } = useCheckoutSharpsContainerErrors();
  const hasValidationErrors = showingValidationErrors && sharpsContainerValidationErrors.length > 0;

  const onChange = (include: boolean) => {
    if (order_includes_sharps_container !== include) {
      dispatch(
        updateOrder({
          include_sharps_container: include,
        }),
      );
    }
  };
  const onPressOption = (value: string) => {
    const include = value === 'yes';
    onChange(include);
  };

  let defaultValue = '';
  if (order_includes_sharps_container !== undefined) {
    defaultValue = order_includes_sharps_container ? 'yes' : 'no';
  }

  const injectableNames = formatList(injectables.map((injectable) => injectable.name));
  const verb = injectables.length > 1 ? 'are' : 'is';
  const description = `${injectableNames} ${verb} administered by injection. Would you like a free sharps container with your order to safely dispose of needles?`;

  return (
    <Card
      overflow="hidden"
      title="Add free sharps container?"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.SM}
      >
        <Body>{description}</Body>
      </LgPadding>
      <InputRadioGroup
        onValueChange={onPressOption}
        defaultValue={defaultValue}
      >
        <Separator transparent={!order_includes_sharps_container} />
        <InputRadio
          testID="sharps-container-yes"
          label="Yes"
          value="yes"
        />
        <Separator />
        <InputRadio
          testID="sharps-container-no"
          label="No"
          value="no"
        />
      </InputRadioGroup>
      {hasValidationErrors ? (
        <LgPadding topPadding={SPACING.STATIC.XS}>
          <InlineAlert type="error">
            <Description>{sharpsContainerValidationErrors[0].message}</Description>
          </InlineAlert>
        </LgPadding>
      ) : null}
    </Card>
  );
};
