// @owners { team: patients-team }
import { type MinimalShipmentFee } from '@alto/scriptdash/alto/scheduling/types/v1/minimal_shipment_fee';
import { createSelector } from 'reselect';
import { getOrderPricing } from '~shared/features/pricing/selectors/getPricing';

export const getAppliedShipmentFeesForOrder = createSelector([getOrderPricing], (orderPricing) => {
  const { line_items } = orderPricing;
  const appliedShipmentFees: MinimalShipmentFee[] = [];

  for (const lineItem of line_items) {
    const { key, value } = lineItem;

    if (key === 'essentials_only_fee') {
      appliedShipmentFees.push({
        fee_type: 'essentials_only_delivery',
        amount: value?.toString() || '',
      });
    } else if (key === 'same_day_delivery_fee') {
      appliedShipmentFees.push({
        fee_type: 'same_day_delivery',
        amount: value?.toString() || '',
      });
    } else if (key === 'next_day_delivery_fee') {
      appliedShipmentFees.push({
        fee_type: 'next_day_delivery',
        amount: value?.toString() || '',
      });
    } else if (key === 'standard_delivery_fee') {
      appliedShipmentFees.push({
        fee_type: 'standard_delivery',
        amount: value?.toString() || '',
      });
    } else if (key === 'on_demand_fee') {
      appliedShipmentFees.push({
        fee_type: 'on_demand_delivery',
        amount: value?.toString() || '',
      });
    } else if (key === 'asap_delivery_fee') {
      appliedShipmentFees.push({
        fee_type: 'asap_delivery',
        amount: value?.toString() || '',
      });
    }
  }

  return appliedShipmentFees;
});
