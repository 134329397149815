// @owners { team: patients-team }
import { type SortType } from '@alto/scriptdash/alto/medications/patients/v1/types';
import { type FilterType } from '@alto/scriptdash/alto/medications/patients/v2/list_endpoint';
import {
  type resetFilters,
  type setFamilyMemberFilters,
  type setMedListFilters,
  type setSortType,
} from '~shared/actions/ui/medList';
import { withRehydration } from '~shared/reducers/withRehydration';

export type CheckboxStateByFilterType = Record<FilterType, boolean>;

export type StateMedList = {
  familyMemberFilters: Record<string, boolean>;
  filters: CheckboxStateByFilterType;
  sortType: SortType;
};

export const initialState: StateMedList = {
  familyMemberFilters: {
    ALL: true,
  },
  filters: {
    ALL: true,
    ACTIVE: true,
    INACTIVE: true,
  },
  sortType: 'PRESCRIBED_DATE',
};

type Action =
  | ReturnType<typeof resetFilters>
  | ReturnType<typeof setSortType>
  | ReturnType<typeof setMedListFilters>
  | ReturnType<typeof setFamilyMemberFilters>;

/**
 * This holds the state for the medication list page.
 * It's used to store:
 * - the sort type
 * - applied prescription status filters
 * - applied family member filters
 */
const medList = (state: StateMedList = initialState, action: Action): StateMedList => {
  switch (action.type) {
    case 'SET_SORT_TYPE':
      return { ...state, sortType: action.payload.sortType };

    case 'SET_MED_LIST_FILTERS':
      return { ...state, filters: action.payload.filters };

    case 'SET_FAMILY_MEMBER_FILTERS':
      return { ...state, familyMemberFilters: action.payload.familyMemberFilters };

    case 'RESET_FILTERS':
      return { ...initialState, sortType: state.sortType };

    default:
      return state;
  }
};

export default withRehydration(medList, initialState);
