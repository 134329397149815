// @owners { team: patients-team }

import { type ApiResponseRow } from '@alto/design-system/src/components/inputs/src/InputAutocomplete/types';
import { type GooglePlacesValue } from './googlePlacesDataSource';
import GooglePlacesService from './googlePlacesService';
import { type GooglePlacesAddressComponent } from './types';

const googlePlacesService = new GooglePlacesService();

/**
 * Takes an api response row of google places value created from the autocomplete api request
 * and calls the google places detail api to get more information about the place (i.e. zip code)
 * @param prediction_address
 * @returns address with full details
 */
export const transformToFullAddress = async (
  prediction_address: ApiResponseRow<GooglePlacesValue>,
): Promise<GooglePlacesValue> => {
  const fullAddress = await googlePlacesService.placeDetails(prediction_address.key);
  return transformPlaceResultToGooglePlacesValue(fullAddress ?? {});
};

const transformPlaceResultToGooglePlacesValue = (details: google.maps.places.PlaceResult): GooglePlacesValue => {
  const addressParts: Record<string, GooglePlacesAddressComponent> = (details.address_components ?? []).reduce(
    (parts, component) => {
      if (!component.types[0]) return parts;

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
      parts[component.types[0]] = component;
      return parts;
    },
    {},
  );

  const streetAddress = `${addressParts.street_number?.short_name || ''} ${
    addressParts.route?.short_name || ''
  }`.trim();

  return {
    name: details.name,
    phone: details.formatted_phone_number,
    street_address: streetAddress,
    city: addressParts.locality?.long_name || addressParts.sublocality_level_1?.long_name,
    neighborhood: addressParts.neighborhood?.long_name,
    state: addressParts.administrative_area_level_1?.short_name,
    zip: addressParts.postal_code?.short_name,
  };
};
