import { View } from 'react-native';
import styled from 'styled-components/native';
import { type IconSize, calculateIconPxSize } from './utils';

export type Props = {
  allowFontScaling?: boolean;
  size?: IconSize;
};

// Wraps the icon with height and width so that content doesn't jump around as the icon loads
export const INTERNAL_IconWrapper = styled(View)<Props>`
  ${({ allowFontScaling = true, size = 'regular' }) => {
    const s = calculateIconPxSize(size, allowFontScaling);

    return `
      width: ${s}px;
      height: ${s}px;
    `;
  }}
`;
