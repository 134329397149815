// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Column, Row, XsSpacing } from '@alto/design-system';
import React from 'react';
import { PAYMENT_METHOD_TYPES, type PaymentMethodType } from '~shared/constants';
import {
  checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder,
  checkIfPaymentMethodFailedInitialCheckoutOrAfterSaveOrderAttempt,
  findCorrespondingShipmentPaymentMethodFromPaymentMethod,
} from '~shared/features/payment-methods/helpers/checkIfPaymentMethodFailed';
import { getDidAttemptOrderUpdate, getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useSelectorShared } from '~shared/store';
import { type PaymentMethod, type ShipmentPaymentMethod } from '~shared/types';
import { PaymentAmount } from './PaymentAmount';
import { PaymentMethodSelector } from './PaymentMethodSelector';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly isSecondaryPaymentMethod?: boolean;
  readonly paymentMethod?: PaymentMethod | null;
  readonly showSplitPayment: boolean;
  readonly splitPaymentTotalCost: number | null;
  readonly hasNoCopay: boolean;
  readonly hasSplitContexts: boolean;
  readonly shipmentPaymentMethods?: ShipmentPaymentMethod[];
  readonly isSplitPaymentEnabled: boolean;
  readonly disabled?: boolean;
};

export const PaymentMethodRow = ({
  handleNativePay,
  paymentMethod,
  isSecondaryPaymentMethod = false,
  showSplitPayment,
  splitPaymentTotalCost,
  hasNoCopay,
  hasSplitContexts,
  shipmentPaymentMethods,
  isSplitPaymentEnabled,
  disabled,
}: Props) => {
  const saveOrderError = useSelectorShared((state) => state.ui.errors.saveOrderError);
  const didAttemptOrderUpdate = useSelectorShared(getDidAttemptOrderUpdate);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const canEditSplitPayments = showSplitPayment && !!splitPaymentTotalCost;
  const shouldDisplaySplitPayment = canEditSplitPayments && !hasSplitContexts && !hasNoCopay;
  const shipmentPaymentMethod = findCorrespondingShipmentPaymentMethodFromPaymentMethod(
    shipmentPaymentMethods,
    paymentMethod,
  );
  const didPaymentFailBeforeSaveOrderAttemptOnExistingOrder = isEditingOrder && !didAttemptOrderUpdate;
  const hasInvalidPaymentMethodError = didPaymentFailBeforeSaveOrderAttemptOnExistingOrder
    ? checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder(shipmentPaymentMethod)
    : checkIfPaymentMethodFailedInitialCheckoutOrAfterSaveOrderAttempt(paymentMethod, saveOrderError);

  return (
    <>
      <Row flexGrow={1}>
        <Column
          flexGrow={1}
          flexShrink={1}
        >
          <PaymentMethodSelector
            disabled={disabled}
            handleNativePay={handleNativePay}
            isSecondaryPaymentMethod={isSecondaryPaymentMethod}
            paymentMethodType={isSecondaryPaymentMethod ? PAYMENT_METHOD_TYPES.SECONDARY : PAYMENT_METHOD_TYPES.PRIMARY}
          />
        </Column>
        {shouldDisplaySplitPayment && isSplitPaymentEnabled ? (
          <>
            <XsSpacing />
            <PaymentAmount isSecondaryField={isSecondaryPaymentMethod} />
          </>
        ) : null}
      </Row>
      {hasInvalidPaymentMethodError ? (
        <>
          <XsSpacing />
          <Body color={COLORS.TEXT_COLORS.DANGER}>Payment failed</Body>
        </>
      ) : null}
    </>
  );
};
