import { type AltoIconName, BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { ActivityIndicator, type StyleProp, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { Pressable } from '../../buttons';
import { MdPadding, Row } from '../../containers';
import { AltoIcon } from '../../icon';
import { getIconTypeFromColor } from '../../icon/src/utils';
import { MdSpacing } from '../../separators';
import { Body } from '../../typography';

export type ListButtonProps = {
  readonly remove?: boolean;
  readonly rightIcon?: AltoIconName;
  readonly label: string | string[];
  readonly onPress: () => void;
  /**
   * Use for testing-library identification purposes, only if you can't rely on user-readable text or a11y labels.
   */
  readonly testID?: string;
  readonly LeftContent?: React.ReactElement<typeof AltoIcon>;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly loadingLabel?: string;
  readonly style?: StyleProp<ViewStyle>;
};

const StyledPressable = styled(Pressable)`
  display: flex;
`;

const getTextColor = ({ disabled, remove }: { disabled?: boolean; remove?: boolean }) => {
  if (disabled) {
    return COLORS.TEXT_COLORS.DISABLED;
  }
  if (remove) {
    return COLORS.TEXT_COLORS.DANGER;
  }
  return COLORS.TEXT_COLORS.SECONDARY_DARK;
};

const getLeftIconName = ({ rightIcon, remove }: { rightIcon?: AltoIconName; remove?: boolean }) => {
  if (rightIcon) {
    return rightIcon;
  }
  return remove ? 'trash' : 'plus';
};

export const ListButton = ({
  LeftContent,
  label,
  onPress,
  remove = false,
  rightIcon,
  testID = 'listButton',
  disabled,
  loading,
  loadingLabel,
  style,
}: ListButtonProps) => {
  const color = remove ? COLORS.PALETTE.DANGER : COLORS.PALETTE.PRIMARY;
  const textColor = getTextColor({ disabled, remove });
  const labelText = loading ? loadingLabel : label;
  const rightIconName = getLeftIconName({ rightIcon, remove });

  return (
    <StyledPressable
      backgroundColor={color.LIGHTEST}
      pressedBackgroundColor={color.LIGHT}
      hoverBackgroundColor={color.LIGHTER}
      borderRadius={BORDERS.RADIUS.LG}
      onPress={onPress}
      testID={testID}
      opacity={false}
      disabled={disabled}
      style={style}
    >
      <MdPadding
        leftPadding={SPACING.STATIC.LG}
        rightPadding={SPACING.STATIC.LG}
      >
        <Row
          wrap={false}
          spaceBetween
          centerVertically
        >
          <Row
            flexShrink={1}
            center
          >
            {!!LeftContent && (
              <>
                {LeftContent}
                <MdSpacing />
              </>
            )}
            <Body
              fontFamily="semibold"
              color={textColor}
              numberOfLines={2}
            >
              {labelText}
            </Body>
          </Row>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <AltoIcon
              name={rightIconName}
              type={getIconTypeFromColor(textColor)}
            />
          )}
        </Row>
      </MdPadding>
    </StyledPressable>
  );
};
