// @owners { team: patients-team }
import { BREAKPOINTS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { PageBackground } from '~web/components/Page';

// Needs Page.tsx migration first
// eslint-disable-next-line @alto/no-media-queries
const StyledOnboardingPageBackground = styled(PageBackground).attrs({
  color: '#FFFAF5',
  // TODO: move consts
  fullWidth: true,
})<{
  showProgyny: boolean;
}>`
  ${({ showProgyny }) => {
    return showProgyny ? `background-color: ${COLORS.BACKGROUND_COLORS.PINK_LIGHTEST};` : '';
  }}
  min-height: calc(100vh - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG});
  @media (max-width: ${BREAKPOINTS.LG}px) {
    padding: ${SPACING.STATIC.XXL.px} ${SPACING.STATIC.LG.px};
    min-height: calc(100vh - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG});
  }
  @media (max-width: ${BREAKPOINTS.SM}px) {
    display: flex;
    flex-direction: column;
    padding: ${SPACING.STATIC.XXL.px} 0 0 0;
    min-height: calc(100vh - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM});
  }
`;

const OnboardingPageBackground = ({
  children,
  showProgyny,
}: {
  readonly children: React.ReactNode;
  readonly showProgyny: boolean;
}) => {
  return <StyledOnboardingPageBackground showProgyny={showProgyny}>{children}</StyledOnboardingPageBackground>;
};

const mapStateToProps = (state: ReduxStateShared) => ({
  showProgyny: getShowProgynySelfServiceForUser(state),
});

export default connect(mapStateToProps)(OnboardingPageBackground);
