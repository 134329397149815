// @owners { team: patients-team }
import { type Dispatch } from 'redux';
import { openModal } from './modal';
import { type Video } from '~shared/features/instructional-videos/types';

export const SET_SELECTED_VIDEO = 'SET_SELECTED_VIDEO';

export type ActionSetSelectedVideo = {
  type: typeof SET_SELECTED_VIDEO;
  payload: Video;
};

export const setSelectedVideo = (video: Video | null | undefined): ActionSetSelectedVideo => ({
  type: SET_SELECTED_VIDEO,
  // @ts-expect-error TS(2322): Type 'Video | null | undefined' is not assignable to type 'Video'.
  payload: video,
});

export const openPrepInfoModal: (arg0: Video) => (arg0: Dispatch<any>) => void = (video: Video) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setSelectedVideo(video));
    dispatch(openModal('VIDEO_PREP_INFO_MODAL'));
  };
};
