// @owners { team: patients-team }
import { MdSpacing, Row } from '@alto/design-system';
import React, { useMemo } from 'react';
import { type AssistantTabName } from '~shared/features/alto-assistant/constants';
import { getSupportCasesWithUnreadMessages } from '~shared/features/messages/selectors/getMessages';
import { useSelectorShared } from '~shared/store';
import { useGetReadOnlyWundercoms, useGetSupportCases } from '../../../../index';
import { Tab } from './Tab';

type Props = {
  readonly activeTab: AssistantTabName;
  readonly onSetActiveTab: (tabName: AssistantTabName) => void;
};

export const Tabs = ({ activeTab, onSetActiveTab }: Props) => {
  const { supportCases } = useGetSupportCases();
  const { unreadMessageCount } = useGetReadOnlyWundercoms();
  const supportCasesWithUnreadMessages = useSelectorShared(getSupportCasesWithUnreadMessages);
  const hasUnreadMessages = supportCasesWithUnreadMessages.size > 0 || unreadMessageCount > 0;

  const hasUnreadSupportCase = useMemo(() => {
    return supportCases.some(
      ({ most_recent_message }) => most_recent_message?.admin_user_id && !most_recent_message.read_at,
    );
  }, [supportCases]);

  const handleNavigateToMessages = () => {
    onSetActiveTab('messages');
  };

  const handleNavigateToAssistant = () => {
    onSetActiveTab('assistant');
  };

  return (
    <Row>
      <Tab
        name="Get help"
        isActive={activeTab === 'assistant'}
        onPress={handleNavigateToAssistant}
      />
      <MdSpacing />
      <Tab
        isActive={activeTab === 'messages'}
        name="Messages"
        onPress={handleNavigateToMessages}
        showDotIndicator={hasUnreadMessages || hasUnreadSupportCase}
      />
    </Row>
  );
};
