// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_DELIVERY_ACTIONS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';

type Props = {
  readonly shipmentID: number;
};

export const WizardRemoveMedicationActionSheet = ({ shipmentID }: Props) => {
  const { navigate } = useNavigation();
  const { saveStack, closeActionSheet } = useContext(ActionSheetContext);

  return (
    <ActionSheetV2
      title="Remove medication"
      buttons={[
        <Button
          label="Go to delivery details"
          key="Go to delivery details"
          onPress={() => {
            closeActionSheet();
            navigate('RouteOrderDetails', { shipmentID });
          }}
        />,
        <Button
          label="I still have a question"
          key="I still have a question"
          type="secondary"
          onPress={() => {
            saveStack<ActionSheetProviderStackCacheKey>('assistant');
            closeActionSheet();
            navigate('RouteAssistantMessage', {
              messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
              origin: ASSISTANT_CHAT_ORIGINS.REMOVE_MEDICATION,
              category: `${ASSISTANT_CATEGORIES.DELIVERIES}.${ASSISTANT_DELIVERY_ACTIONS.REMOVE_MEDICATION}`,
              subject: ASSISTANT_MESSAGE_SUBJECTS.MEDICATION,
            });
          }}
        />,
      ]}
      analyticsName="assistant remove medication"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          To remove a medication, go to the delivery details page. Press the trash icon to the right of a medication to
          remove it from the order.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
