// @owners { team: patients-team }
import { type AppPromo } from '@alto/scriptdash/alto/patients/engagement/types/v1/app_promo';
import { type StateUiPromos } from '~shared/reducers/ui/promos';
import { type ReduxStateShared } from '~shared/store';

export const getEligiblePromos = (state: ReduxStateShared, promos: AppPromo[]) => {
  return getEligibleViewedPromos(state.persistedUI.promos, promos);
};

export const getEligibleViewedPromos = (promoState: StateUiPromos, promos: AppPromo[]) => {
  const { viewedPromos } = promoState;

  // Filter promos based on their stored view count and if it's less than the max impression count.
  return promos.filter((promo) => {
    const viewedPromoState = viewedPromos[promo.id];
    const maxImpressionCount = promo.max_impression_count || 5;

    return !viewedPromoState || viewedPromoState.currentImpressionCount < maxImpressionCount;
  });
};
