// @owners { team: patients-team }
import getLoading from '~shared/features/ui/selectors/getLoading';
import { isNative } from '~shared/helpers/isNative';
import { type ReduxStateShared } from '~shared/types';

/*
The default export checks for all auth properties necessary to proceed with API calls.
For web, this is simply state.auth.authorized
For native, we require a session that is both logged in and has had biometrics authenticated.
 */

// DISCLAIMER: USING THIS UNION STATE IS NOT A GOOD PRACTICE PLEASE RETHINK APPROACH OR REACH OUT TO THE APP PLATFORM TEAM FOR ASSISTANCE
export default (state: ReduxStateShared): boolean => {
  const { logoutLoading } = getLoading(state);

  if (isNative) {
    return !!state.auth.loggedInAt && state.auth.biometricsAuthenticated && !logoutLoading;
  }

  return state.auth.authorized;
};
/*
Returns true if the user is logged in with a valid email/password session.
Key callouts for native:
- Logged in status determines if the user will be routed to AuthStack of AppStack.
- Logged in status does not check for a valid biometric session. To check for a valid biometric session,
use the default export
 */

// DISCLAIMER: USING THIS UNION STATE IS NOT A GOOD PRACTICE PLEASE RETHINK APPROACH OR REACH OUT TO THE APP PLATFORM TEAM FOR ASSISTANCE
export const getIsLoggedIn = (state: ReduxStateShared): boolean => {
  const { logoutLoading } = getLoading(state);

  if (isNative) {
    return !!state.auth.loggedInAt && !logoutLoading;
  }

  return state.auth.authorized;
};
