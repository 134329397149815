import { ALERT_COLORS } from './alertColors';
import { BACKGROUND_COLORS } from './backgroundColors';
import { BORDER_COLORS } from './borderColors';
import { PALETTE } from './palette/index';
import { TEXT_COLORS } from './textColors';

export const COLORS = {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  PALETTE,
  TEXT_COLORS,
  ALERT_COLORS,
} as const;

export type COLORS_VALUES = (typeof COLORS)[keyof typeof COLORS];
export type TEXT_COLORS_VALUES = (typeof TEXT_COLORS)[keyof typeof TEXT_COLORS];
export type BORDER_COLORS_VALUES = (typeof BORDER_COLORS)[keyof typeof BORDER_COLORS];
export type BACKGROUND_COLORS_VALUES = (typeof BACKGROUND_COLORS)[keyof typeof BACKGROUND_COLORS];
