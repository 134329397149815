// @owners { team: patients-team }
import { Button, InputText } from '@alto/design-system';
import React, { useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { forgotPassword } from '~shared/actions/auth';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import { validateEmail } from '~shared/validations';
import { StyledParagraph } from '~web/components/UnauthorizedFormWrapper';

export const ForgotPasswordForm = () => {
  const [emailValidationError, setEmailValidationError] = useState<string | undefined>(undefined);
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();

  const [email, setEmail] = useState('');

  const requestPasswordResetSuccess = useSelectorShared(
    (state: ReduxStateShared) => state.auth.requestPasswordResetSuccess,
  );
  const error = useSelectorShared((state) => state.ui.errors.forgottenPasswordError);
  const forgottenPasswordError = getErrorMessage(error);
  const forgottenPasswordLoading = useSelectorShared((state) => state.ui.loading.forgottenPasswordLoading);

  if (requestPasswordResetSuccess) {
    return <StyledParagraph>Success! Password reset instructions are on their way!</StyledParagraph>;
  }

  const handleForgotPassword = () => {
    const invalidEmailMessage = validateEmail(email);
    if (invalidEmailMessage) {
      setEmailValidationError(invalidEmailMessage);
      return;
    } else if (emailValidationError) {
      setEmailValidationError(undefined);
    }

    // eslint-disable-next-line import/no-deprecated
    dispatch(forgotPassword(email));
    trackEvent({
      event: EVENTS.RESET_PASSWORD_CLICKED,
      params: {
        email,
      },
    });
  };

  const captionString = forgottenPasswordError || emailValidationError;

  return (
    <>
      <StyledParagraph>Enter your email below and we’ll email you a password reset link.</StyledParagraph>
      <InputText
        label="Email"
        placeholder="Email"
        onChange={(event: any) => {
          setEmail(event.currentTarget.value);
        }}
        caption={' '}
        error={captionString || undefined}
      />
      <Button
        disabled={forgottenPasswordLoading}
        onPress={handleForgotPassword}
        loading={forgottenPasswordLoading}
      >
        Send Me The Link
      </Button>
    </>
  );
};
