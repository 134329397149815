// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, Button, H1, LgPadding, LgSpacing, Row, SizedImage } from '@alto/design-system';
import React, { useMemo } from 'react';
import carrot_logo from '~shared/assets/images/carrot.jpg';
import { capitalizeString } from '~shared/helpers/string';

const PARTNER_NAME_TO_SUPPORT_EMAIL: Record<string, string> = {
  Carrot: 'support@get-carrot.com',
};

type Props = {
  readonly partnerName: string | undefined;
  readonly confirmedCoverage: boolean;
  readonly handleClose: () => void;
  readonly handleContinue: () => void;
  readonly handleAddAnotherBenefit?: (() => void) | undefined;
};

export const InsuranceWarningActionSheet = ({
  partnerName = 'carrot',
  confirmedCoverage,
  handleClose,
  handleContinue,
  handleAddAnotherBenefit,
}: Props) => {
  const titleizedPartnerName = useMemo(() => capitalizeString(partnerName), [partnerName]);
  const partnerEmail = useMemo(() => PARTNER_NAME_TO_SUPPORT_EMAIL[titleizedPartnerName], [titleizedPartnerName]);
  const buttons = useMemo(() => {
    const buttonList = [
      <Button
        label="Continue"
        key="continue"
        onPress={handleContinue}
      />,
    ];

    if (handleAddAnotherBenefit) {
      buttonList.push(
        <Button
          label="Add another benefit"
          key="add-another-benefit"
          type="tertiary"
          onPress={handleAddAnotherBenefit}
        />,
      );
    }

    return buttonList;
  }, [handleContinue, handleAddAnotherBenefit]);

  return (
    <ActionSheetV2
      handleClose={handleClose}
      analyticsName="insurance warning"
      buttons={buttons}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Row center>
          <SizedImage
            source={carrot_logo}
            pixelSize={100}
            maxHeight={45}
            resizeMode="contain"
          />
        </Row>
        <LgSpacing />
        {confirmedCoverage ? (
          <H1 center>Your {titleizedPartnerName} benefit will be automatically applied at checkout!</H1>
        ) : (
          <H1 center>Sorry, we couldn’t locate your {titleizedPartnerName} membership.</H1>
        )}
        <LgSpacing />
        {confirmedCoverage ? (
          <Body center>
            Thank you for choosing {titleizedPartnerName} Rx! We’ve verified your {titleizedPartnerName} membership. You
            will see your discounted {titleizedPartnerName} pricing applied to your covered medications at checkout.
          </Body>
        ) : (
          <Body center>
            It looks like you might be a {titleizedPartnerName} member, but we could not verify your membership. Please
            contact your {titleizedPartnerName} team {partnerEmail ? `at ${partnerEmail}` : ''} to verify your
            membership.
          </Body>
        )}
      </LgPadding>
    </ActionSheetV2>
  );
};
