// @owners { team: patients-team }
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import { createSelector } from 'reselect';
import { findShipmentTipForShipment } from './helpers';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { type ShipmentIDParamLong } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getShipmentID = (_: ReduxStateShared, props: ShipmentIDParamLong) => props?.shipmentID;

export const getShipmentTips: (state: ReduxStateShared) => ShipmentTip[] = (state) => state.shipmentTips.shipmentTips;

export const getShipmentTipForShipmentID = createSelector(
  [getShipmentID, getEditShipmentID, getShipmentTips],
  (shipmentID, editShipmentID, shipmentTips): ShipmentTip | null | undefined => {
    if (shipmentID) return findShipmentTipForShipment(shipmentTips, shipmentID);
    if (editShipmentID) return findShipmentTipForShipment(shipmentTips, editShipmentID);
    return null;
  },
);
