// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  Column,
  H1,
  Illustration,
  LgPadding,
  LgSpacing,
  MdPadding,
  MdSpacing,
} from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { Linking } from 'react-native';
import { useAnalytics } from '~shared/hooks';

type Props = {
  id: number;
  title: string;
  content: string;
  imageURL?: string | null;
  routeURL?: string | null;
  ctaText?: string | null;
};

export const AnnouncementActionSheet = ({ id, title, content, imageURL, routeURL, ctaText }: Props) => {
  const { trackEvent } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);

  const handlePress = useCallback(() => {
    if (routeURL) {
      Linking.openURL(routeURL);
    } else {
      closeActionSheet();
    }

    trackEvent({
      event: 'Announcement CTA Clicked',
      params: {
        announcementID: id,
        url: routeURL,
        title,
      },
    });
  }, [closeActionSheet, id, routeURL, title, trackEvent]);

  const handleClose = useCallback(() => {
    trackEvent({
      event: 'Announcement Dismissed',
      params: {
        announcementID: id,
        announcementTitle: title,
      },
    });
  }, [id, title, trackEvent]);

  return (
    <ActionSheetV2
      handleClose={handleClose}
      analyticsName="app announcement"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Column centerHorizontally>
          {imageURL ? (
            <Illustration
              source={imageURL}
              size="xxl"
              accessibilityLabel="Announcement Action Sheet Image"
            />
          ) : null}
          <LgSpacing />
          <H1 center>{title}</H1>
          <MdSpacing />
          <Body center>{content}</Body>
          <MdSpacing />
          <MdPadding
            leftPadding={SPACING.STATIC.LG}
            rightPadding={SPACING.STATIC.LG}
          >
            <Button
              type="primary"
              testID="announcement-action-sheet-cta"
              onPress={handlePress}
              label={ctaText || 'Ok'}
            />
          </MdPadding>
        </Column>
      </LgPadding>
    </ActionSheetV2>
  );
};
