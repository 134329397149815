import { type AvailableShipmentFee } from '@alto/scriptdash/alto/orders/types/v1/available_shipment_fee';
import {
  ATTEMPTED_ORDER_UPDATE,
  type ActionAttemptedOrderUpdate,
  type ActionExitEditingOrder,
  type ActionInitExistingOrder,
  type ActionToggleSplitPayment,
  type ActionTurnOnSplitPayment,
  EXIT_EDITING_ORDER,
  INIT_EXISTING_ORDER,
  TOGGLE_SPLIT_PAYMENT,
  TURN_ON_SPLIT_PAYMENT,
} from '~shared/actions/cart';
import { type closeCart, type openCart } from '~shared/actions/ui/cart';

type Action =
  | ActionExitEditingOrder
  | ActionInitExistingOrder
  | ActionAttemptedOrderUpdate
  | ActionToggleSplitPayment
  | ActionTurnOnSplitPayment
  | ReturnType<typeof openCart>
  | ReturnType<typeof closeCart>
  | { type: 'SCHEDULEABLE_SAME_DAY_FEE'; payload: AvailableShipmentFee | null | undefined };

export type StateUiCart = {
  isEditingOrder: boolean;
  isSplitPaymentEnabled: boolean;
  didAttemptOrderUpdate: boolean | null;
  scheduleableSameDayFee: AvailableShipmentFee | null | undefined;
  isShowing: boolean;
};

export const initialState: StateUiCart = {
  isEditingOrder: false,
  isSplitPaymentEnabled: false,
  didAttemptOrderUpdate: false,
  scheduleableSameDayFee: null,
  isShowing: false,
} as const;

const uiCart = (state: StateUiCart = initialState, action: Action): StateUiCart => {
  const { type } = action;

  switch (type) {
    case INIT_EXISTING_ORDER:
      return { ...state, isEditingOrder: true, didAttemptOrderUpdate: false };

    case EXIT_EDITING_ORDER:
      return { ...state, isEditingOrder: false, isSplitPaymentEnabled: false, didAttemptOrderUpdate: null };

    case ATTEMPTED_ORDER_UPDATE:
      return { ...state, didAttemptOrderUpdate: true };

    case TURN_ON_SPLIT_PAYMENT:
      return { ...state, isSplitPaymentEnabled: true };

    case TOGGLE_SPLIT_PAYMENT:
      return { ...state, isSplitPaymentEnabled: !state.isSplitPaymentEnabled };

    case 'SCHEDULEABLE_SAME_DAY_FEE': {
      return { ...state, scheduleableSameDayFee: action.payload };
    }

    case 'CLOSE_CART':
      return { ...state, isShowing: false };

    case 'OPEN_CART':
      return { ...state, isShowing: true };

    default:
      return state;
  }
};

export default uiCart;
