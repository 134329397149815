// @owners { team: patients-team }

export const COACHMARK_NOTIFICATION_CENTER = 'COACHMARK_NOTIFICATION_CENTER';

export type CoachmarkKey = typeof COACHMARK_NOTIFICATION_CENTER;
export const SET_COACHMARK_VIEWED = 'SET_COACHMARK_VIEWED';

export type MarkCoachmarkViewed = {
  type: typeof SET_COACHMARK_VIEWED;
  payload: CoachmarkKey;
};

export function setCoachmarkViewed(coachmarkKey: CoachmarkKey) {
  return {
    type: SET_COACHMARK_VIEWED,
    payload: coachmarkKey,
  };
}
