// @owners { team: patients-team }
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { staleTimes } from './constants';
import { queries } from './query-keys';
import { getLastFetchAllRequestSucceededAt } from '~shared/features/prescriptions/selectors/getPrescriptions';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const FETCH_ALL_FRESH_THRESHOLD = 1000 * 60 * 60; // 60 minutes

export const usePrescriptions = () => {
  const dispatch = useDispatchShared();
  const lastFetchAllRequestSucceededAt = useSelectorShared(getLastFetchAllRequestSucceededAt);
  const shouldFetchAllPrescriptions = Date.now() - (lastFetchAllRequestSucceededAt || 0) > FETCH_ALL_FRESH_THRESHOLD;
  const { data, isError, isFetching, isPending, isSuccess, refetch } = useQuery({
    ...queries.prescriptions.prescriptions({ dispatch, shouldFetchAll: shouldFetchAllPrescriptions }),
    staleTime: staleTimes.fiveMinutes,
  });

  return {
    isError,
    isLoadingPrescriptions: isFetching || isPending,
    isSuccess,
    prescriptions: data,
    refetchPrescriptions: refetch,
  };
};

export const useInvalidatePrescriptions = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const lastFetchAllRequestSucceededAt = useSelectorShared(getLastFetchAllRequestSucceededAt);
  const shouldFetchAllPrescriptions = Date.now() - (lastFetchAllRequestSucceededAt || 0) > FETCH_ALL_FRESH_THRESHOLD;
  const { refetch } = useQuery({
    ...queries.prescriptions.prescriptions({ dispatch, shouldFetchAll: shouldFetchAllPrescriptions }),
    enabled: false,
    staleTime: staleTimes.fiveMinutes,
  });
  const invalidatePrescriptionsQuery = useCallback(() => {
    queryClient.cancelQueries({
      queryKey: queries.prescriptions.prescriptions({ dispatch, shouldFetchAll: shouldFetchAllPrescriptions }).queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: queries.prescriptions.prescriptions({ dispatch, shouldFetchAll: shouldFetchAllPrescriptions }).queryKey,
    });

    /**
     * !⚠️ This is a temporary use of the imperative `refetch` in order to "invalidate" or refresh the query.
     * After we fully migrate off the selectors, then we can remove the `refetch` usage and use use `invalidateQueries`.
     */
    refetch();
  }, [dispatch, queryClient, refetch, shouldFetchAllPrescriptions]);

  return { invalidatePrescriptionsQuery };
};
