import { BORDERS, COLORS, SHADOWS } from '@alto/design-library-tokens';
import React from 'react';
import { type ImageSourcePropType, type StyleProp, View, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { type StandaloneCheckbox, type Switch } from '../../buttons';
import { Column } from '../../containers';
import { type AltoIcon } from '../../icon';
import { type Tag } from '../../tags';
import { type Link, type Text } from '../../typography';
import { CardHeader } from './CardHeader';

export type CardRightContentProps = React.ReactElement<
  typeof StandaloneCheckbox | typeof Switch | typeof Text | typeof Link | typeof AltoIcon
>;

export type CardProps = {
  readonly children: React.ReactNode;
  readonly flexGrow?: number;
  readonly title?: string;
  readonly overflow?: 'hidden' | 'visible' | 'scroll';
  readonly description?: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly RightContent?: CardRightContentProps;
  readonly illustrationSrc?: ImageSourcePropType;
  readonly illustrationAccessibilityLabel?: string | null | undefined;
  readonly tag?: React.ReactElement<typeof Tag>;
  readonly wrapHeader?: boolean;
};

const StyledCard = styled(View)<{ flexGrow: CardProps['flexGrow']; overflow?: string }>`
  ${({ flexGrow }) => (flexGrow ? `flex-grow: ${flexGrow};` : ``)}
  flex-shrink: 1;
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : ``)}
  background-color: ${COLORS.PALETTE.GREYSCALE.WHITE};
  border: ${COLORS.BORDER_COLORS.LIGHT} 1px;
  border-radius: ${BORDERS.RADIUS.LG.px};
  ${SHADOWS.BOTTOM_LIGHT}
`;

export const Card = ({
  children,
  flexGrow,
  title,
  description,
  illustrationSrc,
  illustrationAccessibilityLabel,
  RightContent,
  tag,
  style,
  overflow,
  wrapHeader,
}: CardProps) => {
  return (
    <StyledCard
      style={style}
      flexGrow={flexGrow}
      overflow={overflow}
    >
      {!!title && (
        <CardHeader
          title={title}
          RightContent={RightContent}
          illustrationSrc={illustrationSrc}
          illustrationAccessibilityLabel={illustrationAccessibilityLabel}
          tag={tag}
          description={description}
          wrapHeader={wrapHeader}
        />
      )}
      <Column flexGrow={1}>{children}</Column>
    </StyledCard>
  );
};
