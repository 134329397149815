// Eventually, we can delete this file once web is migrated away from Formik.
// eslint-disable-next-line @alto/prefer-react-hook-form
import { type FieldInputProps, type FieldMetaProps } from 'formik';
import { type ChangeEventHandler } from 'react';

export type Field = {
  error: string;
  invalid: boolean;
  onChange: ChangeEventHandler;
  touched: boolean;
  value: string;
};

export default function mapFieldToInputProps(field: Field) {
  return {
    meta: {
      error: field.error,
      touched: field.touched,
      invalid: field.invalid,
    },
    ...field,
  };
}

export function mapFormikFieldToInputProps(field: FieldInputProps<any>, meta: FieldMetaProps<any>) {
  return {
    meta: { ...meta, invalid: !!meta.error },
    ...field,
  };
}
