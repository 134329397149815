import { KeyDateTypeMap } from '@alto/operations_api/v1/types';
import { isMonday, isSameDay, isSunday, isWeekend, parseISO } from 'date-fns';
import { type ExcludedDate } from '~shared/types';

const EXCLUSION_DATE_TYPES: Record<string, boolean> = {
  [KeyDateTypeMap.HOLIDAY]: true,
  [KeyDateTypeMap.EMERGENCY_CLOSURE]: true,
  [KeyDateTypeMap.EMERGENCY_CLOSURE_PATIENT_ONLY]: true,
};

export function shouldExcludeOrder(excludedDate: ExcludedDate, date: Date) {
  const { date: dateString, filter } = excludedDate;
  const filterDate = dateString ? parseISO(dateString) : undefined;

  if (EXCLUSION_DATE_TYPES[filter] && filterDate) {
    return isSameDay(filterDate, date);
  }

  switch (filter) {
    case 'weekend':
      return isWeekend(date);

    case 'sunday':
      return isSunday(date);

    case 'monday':
      return isMonday(date);

    default:
      return false;
  }
}
