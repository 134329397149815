// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Toast, ToastContext } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModal,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  InputBlockRadio,
  InputBlockText,
  InputRow,
  InputUploadImage,
  RadioOption as PockyRadioOption,
  Tooltip,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import {
  Field,
  type FieldInputProps,
  type FieldMetaProps,
  type FieldProps,
  Form,
  Formik,
  type FormikProps,
} from 'formik';
import { omit } from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { closeModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { uploadImageToS3 } from '~shared/actions/upload';
// eslint-disable-next-line import/no-deprecated
import { clearUserError, updateUser } from '~shared/actions/users';
import { DEFAULT_ERROR_ALERT_MESSAGE, DEFAULT_ERROR_ALERT_TITLE, GENDERS, PRONOUNS } from '~shared/constants';
import getSelectedUser from '~shared/features/users/selectors/getSelectedUser';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Gender } from '~shared/types';

const FlexInputBlockRadio = styled(InputBlockRadio)`
  > :not(:first-child) {
    display: flex;
  }
`;

const RadioOption = styled(PockyRadioOption)`
  margin-right: ${SPACING.STATIC.LG.px};
  margin-bottom: 0;
  display: flex;
`;

type Values = {
  date_of_birth: string;
  first_name: string;
  gender: Gender;
  last_name: string;
  photo_id_url: File[] | string | null;
  preferred_first_name: string;
  preferred_pronoun: string;
};

const getRadioOptions = (collection: typeof GENDERS | typeof PRONOUNS) => {
  return Object.entries(collection).map(([key, value]) => {
    return (
      <RadioOption
        name={value}
        value={key}
        key={key}
      >
        {value}
      </RadioOption>
    );
  });
};

const CHANGE_NAME_OR_DOB_COPY =
  'To ensure your privacy, please contact us if you wish to change your name or date of birth';
const CHANGE_PREFERRED_NAME_COPY =
  'If you go by a name other than your legal name, please share it with us so we can address you correctly';

export const PersonalInfoModal = () => {
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const user = useSelectorShared(getSelectedUser);
  const modalType = useSelectorShared(getModalType);
  const show = useSelectorShared(getIsModalShowing);

  const onChangePhotoInput = (field: FieldInputProps<any>, form: FormikProps<any>) => {
    return (files: File[] | null | undefined) => {
      form.setFieldValue(field.name, files);
    };
  };

  const handleCloseModal = () => {
    dispatch(clearUserError());
    dispatch(closeModal('PERSONAL_INFO_MODAL'));
  };

  const updateUserPersonalInfo = async (data: Omit<Values, 'photo_id_url'> & { photo_id_url?: string }) => {
    if (!user?.id) {
      addToast(
        <Toast variant="error">
          {DEFAULT_ERROR_ALERT_TITLE}. {DEFAULT_ERROR_ALERT_MESSAGE}
        </Toast>,
      );
      return;
    }

    const params = omit(data, ['first_name', 'last_name', 'date_of_birth']);
    const { success } = await dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateUser({
        id: user.id,
        ...params,
      }),
    );

    if (success) {
      addToast(<Toast>Saved personal information!</Toast>);
      handleCloseModal();
    }
  };

  const handleSubmit = async (data: Values) => {
    // if the data.photo_id_url is not of type string and does not match the user's photo_id_url
    // then we're uploading a new photo
    if (data.photo_id_url && user?.photo_id_url !== data.photo_id_url) {
      // eslint-disable-next-line import/no-deprecated
      const url = await dispatch(uploadImageToS3(data.photo_id_url[0] as File, 'users'));
      if (url) {
        updateUserPersonalInfo({
          ...data,
          photo_id_url: url,
        });
      }
    } else {
      // remove the photo_id_url since it has not changed
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { photo_id_url, ...dataWithoutPhotoUrl } = data;
      updateUserPersonalInfo(dataWithoutPhotoUrl);
    }
  };

  const shown = show && modalType === 'PERSONAL_INFO_MODAL';
  if (!shown) return null;

  return (
    <DeprecatedModal
      showing={shown}
      onClose={handleCloseModal}
      title="Edit Personal Info"
      scrollable
    >
      <Formik
        initialValues={{
          date_of_birth: user?.date_of_birth ?? '',
          first_name: user?.first_name ?? '',
          // @ts-expect-error gender must be selected by patient
          gender: user?.gender ?? '',
          last_name: user?.last_name ?? '',
          photo_id_url: user?.photo_id_url ?? null,
          preferred_first_name: user?.preferred_first_name ?? '',
          preferred_pronoun: user?.preferred_pronoun ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <DeprecatedModalBody>
            <Tooltip
              position="top"
              content={CHANGE_NAME_OR_DOB_COPY}
            >
              <InputRow>
                <Field name="first_name">
                  {({ field, meta }: FieldProps<string, Values>) => {
                    return (
                      <InputBlockText
                        label="First Name"
                        type="text"
                        placeholder="First"
                        meta={meta}
                        {...field}
                        disabled
                      />
                    );
                  }}
                </Field>
              </InputRow>
            </Tooltip>
            <Tooltip
              position="top"
              content={CHANGE_NAME_OR_DOB_COPY}
            >
              <InputRow>
                <Field name="last_name">
                  {({ field, meta }: FieldProps<string, Values>) => {
                    return (
                      <InputBlockText
                        label="Last Name"
                        type="text"
                        placeholder="Last"
                        meta={meta}
                        {...field}
                        disabled
                      />
                    );
                  }}
                </Field>
              </InputRow>
            </Tooltip>
            <Tooltip
              position="top"
              content={CHANGE_PREFERRED_NAME_COPY}
            >
              <InputRow>
                <Field name="preferred_first_name">
                  {({ field, meta }: FieldProps<string, Values>) => {
                    return (
                      <InputBlockText
                        label="Preferred name"
                        type="text"
                        placeholder="Preferred name"
                        meta={meta}
                        {...field}
                      />
                    );
                  }}
                </Field>
              </InputRow>
            </Tooltip>
            <Tooltip
              position="top"
              content={CHANGE_NAME_OR_DOB_COPY}
            >
              <InputRow>
                <Field name="date_of_birth">
                  {({ field, meta }: FieldProps<string, Values>) => {
                    return (
                      <InputBlockText
                        label="Date of birth"
                        type="text"
                        placeholder="MM/DD/YYYY"
                        meta={meta}
                        {...field}
                        disabled
                      />
                    );
                  }}
                </Field>
              </InputRow>
            </Tooltip>
            <InputRow>
              <Field name="gender">
                {({ field, meta }: FieldProps<string, Values>) => {
                  return (
                    <FlexInputBlockRadio
                      required
                      label="Choose sex"
                      input={field}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      {getRadioOptions(GENDERS)}
                    </FlexInputBlockRadio>
                  );
                }}
              </Field>
            </InputRow>
            <InputRow>
              <Field name="preferred_pronoun">
                {({ field, meta }: { field: FieldInputProps<Values>; meta: FieldMetaProps<Values> }) => {
                  return (
                    <FlexInputBlockRadio
                      required
                      label="Choose pronoun"
                      // @ts-expect-error:  Type 'Values' is not assignable to type 'string | number'
                      input={field}
                      {...mapFormikFieldToInputProps(field, meta)}
                    >
                      {getRadioOptions(PRONOUNS)}
                    </FlexInputBlockRadio>
                  );
                }}
              </Field>
            </InputRow>
            <Field name="photo_id_url">
              {({ field, meta, form }: FieldProps<Values['photo_id_url']>) => (
                <InputUploadImage
                  label="Upload photo ID"
                  id="photo-id-upload"
                  uploadText="+ Add photo ID"
                  meta={meta}
                  value={field.value}
                  onChange={onChangePhotoInput(field, form)}
                  required
                />
              )}
            </Field>
          </DeprecatedModalBody>
          <DeprecatedModalFooter>
            <Button
              kind="tertiary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            {/* @ts-expect-error: Property 'onClick' is missing in type */}
            <Button type="submit">Save</Button>
          </DeprecatedModalFooter>
        </Form>
      </Formik>
    </DeprecatedModal>
  );
};
