import { createSelector } from 'reselect';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { type MedicationParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getMedication = (_: ReduxStateShared, props: MedicationParam) => props.medication;

const getMedicationHasPriceOptions = createSelector(
  [getPrescriptionPricing, getMedication],
  (prescriptionPricing, medication) =>
    medication?.prescriptions?.some?.((rx) => prescriptionPricing[rx.id]?.prices?.length > 1) ?? false,
);

export default getMedicationHasPriceOptions;
