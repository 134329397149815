import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { type ImageSourcePropType, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Column, Row } from '../../containers';
import { AltoIcon } from '../../icon';
import { Illustration } from '../../images/src/Illustration';
import { MdSpacing, XsSpacing, XxsSpacing } from '../../separators';
import { type Tag } from '../../tags';
import { Text } from '../../typography/src/Text';

const IllustrationWrapper = styled(Column)`
  align-self: flex-start;
`;

type Props = {
  readonly onPress: () => void;
  readonly copy?: string;
  readonly illustrationSource: ImageSourcePropType;
  readonly illustrationAccessibilityLabel?: string;
  readonly title: string;
  readonly tag?: React.ReactElement<typeof Tag>;
  readonly testID?: string;
};

export const NavigationRow = ({
  onPress,
  copy,
  illustrationSource,
  illustrationAccessibilityLabel,
  title,
  tag,
  testID,
}: Props) => {
  return (
    <Pressable
      onPress={onPress}
      testID={testID}
    >
      <Row spaceBetween>
        <Row
          centerVertically
          flexShrink={1}
        >
          <IllustrationWrapper>
            <Illustration
              size="sm"
              source={illustrationSource}
              accessibilityLabel={illustrationAccessibilityLabel}
            />
          </IllustrationWrapper>
          <MdSpacing />
          <Column flexShrink={1}>
            <Text
              fontFamily="semibold"
              color={COLORS.TEXT_COLORS.PRIMARY}
            >
              {title}
            </Text>
            {!!copy && (
              <>
                <XxsSpacing />
                <Text
                  tight
                  color={COLORS.TEXT_COLORS.GREY}
                  textSize="mini"
                >
                  {copy}
                </Text>
              </>
            )}
            {tag ? (
              <>
                {copy ? <XsSpacing /> : <XxsSpacing />}
                {tag}
              </>
            ) : null}
          </Column>
        </Row>
        <Row centerVertically>
          <AltoIcon
            name="chevronright-small"
            type="grey"
          />
        </Row>
      </Row>
    </Pressable>
  );
};
