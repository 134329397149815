// IE's fetch implementation is broken.
// https://github.com/github/fetch/issues/391
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8546263/
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/?page=1&q=fetch
// So, rather than assuming `window.fetch` works, we'll always replace it with our own ponyfill
import fetchPonyfill from 'fetch-ponyfill';
import { isNative } from './isNative';

// on ReactNative, we want to trust the global fetch (especially since the
// fetchPonyfill doesn't work)
const originalFetch = isNative ? global.fetch : fetchPonyfill().fetch;

// @ts-expect-error TS(7006): Parameter 'd1' implicitly has an 'any' type.
const combinePartialBatchleyData = (d1, d2) => {
  if (Array.isArray(d1)) {
    return d1.concat(d2);
  }

  return Object.assign(d1, d2);
};

// @ts-expect-error TS(7006): Parameter 'response' implicitly has an 'any' type.
const responseWithJsonRestored = (response, data) => {
  response.json = () =>
    new Promise((resolve) => {
      resolve(data);
    });

  return response;
};

export const batchleyAwareFetch: typeof fetch = async (...args: any) => {
  const firstResponse = await originalFetch.apply(this, args);
  const data = await firstResponse.json();

  // if it's not batchley-enabled || we already have all records, immediately return
  if (!data.batchley_more_data) {
    return responseWithJsonRestored(firstResponse, data);
  }

  // call all paginated URLs returned by the first call to get the rest of the data
  // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
  const responses = await Promise.all(data.batchley_more_data.map((url) => originalFetch(url, args[1])));

  // extract json contents from the responses, or return early with an error response
  // if one of the responses errored out.
  const responseBodyPromises = [];

  for (const response of responses) {
    if (!response.ok) {
      return response;
    }

    responseBodyPromises.push(response.json());
  }

  const allResponses = await Promise.all(responseBodyPromises);

  // merge all retrieved data back into the original data response,
  // to be returned as if it was all one web request
  allResponses.forEach((response) => {
    // Check if this endpoint follows the jsonapi convention of having a top-level data attribute
    if (data?.batchley_partial_data?.data) {
      data.batchley_partial_data.data = combinePartialBatchleyData(data.batchley_partial_data.data, response.data);
    } else {
      data.batchley_partial_data = combinePartialBatchleyData(data.batchley_partial_data, response);
    }
  });

  return responseWithJsonRestored(firstResponse, data.batchley_partial_data);
};
