// @owners { team: patients-team }
import { AltoIcon, Row, SmSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Link } from '@alto/pocky';
import React from 'react';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
// eslint-disable-next-line import/no-deprecated
import { useAddOnsFetchByProductIDs } from '~shared/queries/useAddOnsFetchByProductIDs';
import { type Shipment } from '~shared/types';
import ClickableAltoIcon from '~web/components/ClickableAltoIcon';
import { useEditEssential } from '~web/features/essentials/hooks';

type Props = {
  readonly productID: number;
  readonly shipment: Shipment;
};

const EditOtcRow = ({ productID, shipment }: Props) => {
  // eslint-disable-next-line import/no-deprecated
  const { data: response } = useAddOnsFetchByProductIDs([productID], shipment.address_id);
  const essential = response?.data[0];
  const { onChangeEssential } = useEditEssential({ essential, shipment });

  if (!isShipmentEditable(shipment)) return null;

  return (
    <Row centerVertically>
      <Link onClick={onChangeEssential}>Edit item</Link>
      <SmSpacing />
      <ClickableAltoIcon
        onClick={onChangeEssential}
        AltoIcon={
          <AltoIcon
            name="edit-small"
            accessibilityLabel="Edit"
            type="secondary"
          />
        }
      />
    </Row>
  );
};

export default EditOtcRow;
