// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoIcon,
  Body,
  Card,
  Column,
  H1,
  ListItem,
  MdSpacing,
  SecondaryPage,
  SmPadding,
  XlPadding,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useGetInsurancesByUserID } from '~shared/queries/query-keys/insurances';
import { useSelectorShared } from '~shared/store';

const isWeb = getPlatformOS() === 'web';

export const AddInsurancePage = () => {
  const { getParam, goBack, navigate } = useNavigation<'RouteInsuranceAdd'>();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const userID = selectedUserID || currentUserID;
  const { hasInsurances } = useGetInsurancesByUserID({ userID });
  const source = getParam('source', 'profile');

  const handleTakePhotoPress = () => {
    if (!userID) return;
    navigate('RouteInsurancePhotoForm', { source, userID });
  };

  const handleManualEntryPress = () => {
    if (!userID) return;
    navigate('RouteInsuranceForm', { source, userID });
  };

  return (
    <SecondaryPage
      dismissIcon="chevronleft"
      onDismiss={goBack}
      HeaderContent={
        <XlPadding
          leftPadding={SPACING.STATIC.XXL}
          rightPadding={SPACING.STATIC.XXL}
        >
          <Column>
            <H1 center>{hasInsurances ? 'Add another benefit' : 'Add a benefit'}</H1>
            <MdSpacing />
            <Body center>Add all insurance and prescription savings cards so we can get you the best pricing.</Body>
          </Column>
        </XlPadding>
      }
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      footerPlacementContext={isWeb ? 'webScreenWithoutPolicyFooter' : undefined}
      withoutWebFramingElementHeights={isWeb}
    >
      <Card>
        <SmPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <ListItem
            title="Take a photo"
            testID="benefit-photo-upload"
            LeftContent={
              <AltoIcon
                name="camera-duo"
                accessibilityLabel="Press to upload a photo"
              />
            }
            RightContent={
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
            }
            onPress={handleTakePhotoPress}
          />
        </SmPadding>
      </Card>
      <MdSpacing />
      <Card>
        <SmPadding
          leftPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
        >
          <ListItem
            title="Enter it manually"
            testID="benefit-manual-input"
            LeftContent={
              <AltoIcon
                name="signpaper-duo"
                accessibilityLabel="Press to enter benefit manually"
              />
            }
            RightContent={
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
            }
            onPress={handleManualEntryPress}
          />
        </SmPadding>
      </Card>
    </SecondaryPage>
  );
};
