import { useCallback, useRef } from 'react';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { type ANALYTICS_EVENT } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type AdditionalFields, type Event } from '~shared/lib/analytics/src/types';
import { useDispatchShared } from '~shared/store';

export type TrackProps = {
  event: ANALYTICS_EVENT;
  params?: Record<string, unknown>;
  additionalFields?: AdditionalFields | null | undefined;
  enabled?: boolean;
};

export const useAnalytics = () => {
  const dispatch = useDispatchShared();
  const pageLoaded = useRef(false);

  const trackEvent = useCallback(
    ({ event, params, additionalFields }: TrackProps) => {
      const createdEvent = createEvent(event, params, additionalFields);
      dispatch(sendAnalyticsEvent(setDefaultOrigin(createdEvent)));
    },
    [dispatch],
  );

  const trackPageView = useCallback(
    ({ event, params, additionalFields, enabled = true }: TrackProps) => {
      if (pageLoaded.current || !enabled) return;
      pageLoaded.current = true;
      trackEvent({ event, params, additionalFields });
    },
    [trackEvent],
  );

  return { trackEvent, trackPageView };
};

/**
 * Set a sensible origin prop if one is not passed in to the analytics event
 * @param event
 */
export const setDefaultOrigin = (event: Event) => {
  event.properties = {
    origin: getOrigin(),
    ...event.properties,
  };

  if (
    event.properties.origin === undefined ||
    event.properties.origin === null ||
    event.properties.origin === 'unknown'
  ) {
    // Don't send vague origin.
    // Note: We explicitly check `undefined` because `event.properties.origin` can itself have the value undefined
    delete event.properties.origin;
  }

  return event;
};
