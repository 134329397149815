import { type LightDoctor } from '~shared/types';

type GetDoctorNameOptions = {
  isCapitalized: boolean;
};

export function getDoctorName(doctor?: LightDoctor, options?: GetDoctorNameOptions): string {
  if (!doctor || !(doctor.first_name || doctor.last_name)) {
    if (options?.isCapitalized) {
      return 'Your doctor';
    }

    return 'your doctor';
  }

  let doctorName = 'Dr.';
  if (doctor.first_name) doctorName = `${doctorName} ${doctor.first_name}`;
  if (doctor.last_name) doctorName = `${doctorName} ${doctor.last_name}`;
  return doctorName;
}
