import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Column, type ColumnProps } from './Flex';

const StyledAnchor = styled(Column)<{ absolute: boolean; top: boolean | undefined; offset: number }>`
  z-index: 1;
  width: 100%;
  ${({ absolute }) => absolute && 'position: absolute;'}
  ${({ top, offset }) => (top ? `top: ${offset}px;` : `bottom: ${offset}px;`)}
`;

export type AnchorProps = {
  readonly top?: boolean;
  readonly absolute?: boolean;
  readonly offset?: number;
} & ColumnProps;

export const Anchor = ({ top, absolute = true, children, offset = 0, ...props }: AnchorProps) => {
  return (
    <StyledAnchor
      {...props}
      top={top}
      absolute={absolute}
      offset={offset}
    >
      {children}
    </StyledAnchor>
  );
};

// similar to Anchor but it centers properly when in a pad view
export const BottomAnchor = styled(View)`
  z-index: 1;
  width: 100%;
  margin-top: auto;
`;
