// @owners { team: patients-team }
import { format, isAfter, isBefore, isSameDay, isValid, parse, startOfDay } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';

export type VacationSupplyFormValues = {
  travelDestination: string;
  startDate: string;
  returnDate: string;
};

export const initialFormValues: VacationSupplyFormValues = {
  travelDestination: '',
  startDate: '',
  returnDate: '',
};

export const validateStartDate = (startDate: string, earliestAvailableDate: string) => {
  const startOfToday = startOfDay(new Date());
  const parsedStartDate = parse(startDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const parsedEarliestAvailableDate = parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const formattedEarliestAvailableDate = format(parsedEarliestAvailableDate, DATE_FORMATS.DATE_INPUT_FORMAT).replace(
    / \/ /g,
    '/',
  );

  if (startDate === '' || !isValid(parsedStartDate)) {
    return 'Please enter a start date in the MM/DD/YYYY format';
  }

  if (isBefore(parsedStartDate, startOfToday) || isAfter(parsedStartDate, parsedEarliestAvailableDate)) {
    return `Start date must be between today and ${formattedEarliestAvailableDate}`;
  }

  return true;
};

export const validateReturnDate = (returnDate: string, earliestAvailableDate: string) => {
  const parsedReturnDate = parse(returnDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, new Date());
  const parsedEarliestAvailableDate = parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const formattedEarliestAvailableDate = format(parsedEarliestAvailableDate, DATE_FORMATS.DATE_INPUT_FORMAT).replace(
    / \/ /g,
    '/',
  );

  if (returnDate === '' || !isValid(parsedReturnDate)) {
    return 'Please enter a return date in the MM/DD/YYYY format';
  }

  if (
    isBefore(parsedReturnDate, parsedEarliestAvailableDate) ||
    isSameDay(parsedReturnDate, parsedEarliestAvailableDate)
  ) {
    return `Return date must be after ${formattedEarliestAvailableDate}`;
  }

  return true;
};

/**
 * @deprecated migrate to VacationOverrideForm which uses react-hook-form
 */
export const validateVacationForm = (earliestAvailableDate: string, values: VacationSupplyFormValues) => {
  const errors: {
    startDate?: string;
    returnDate?: string;
    travelDestination?: string;
  } = {};

  const { travelDestination } = values;

  if (!travelDestination) {
    errors.travelDestination = 'Travel destination is required to submit';
  }

  const startOfToday = startOfDay(new Date());
  const startDate = parse(values.startDate, DATE_FORMATS.DATE_INPUT_FORMAT, 0);
  const returnDate = parse(values.returnDate, DATE_FORMATS.DATE_INPUT_FORMAT, 0);
  const parsedEarliestAvailableDate = parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const formattedEarliestAvailableDate = format(parsedEarliestAvailableDate, DATE_FORMATS.DATE_INPUT_FORMAT).replace(
    / \/ /g,
    '/',
  );

  // Start date error validation
  if (values.startDate === '' || !isValid(startDate)) {
    errors.startDate = 'Please enter a valid date in the MM/DD/YYYY format';
  } else if (isBefore(startDate, startOfToday) || isAfter(startDate, parsedEarliestAvailableDate)) {
    errors.startDate = `Start date must be between today and ${formattedEarliestAvailableDate}`;
  }

  // Return date error validation
  if (values.returnDate === '' || !isValid(returnDate)) {
    errors.returnDate = 'Please enter a valid date in the MM/DD/YYYY format';
  } else if (isBefore(returnDate, parsedEarliestAvailableDate) || isSameDay(returnDate, parsedEarliestAvailableDate)) {
    errors.returnDate = `Return date must be after ${formattedEarliestAvailableDate}`;
  }

  return errors;
};
