// @owners { team: patients-team }
import { SIZES, SPACING } from '@alto/design-library-tokens';
import { LgSpacing, MdSpacing, XsSpacing } from '@alto/design-system';
import { useAddOnsContext } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { useDispatchShared } from '~shared/store';
import { FSABadge, ProductFooter } from '~web/features/essentials/components';
import { StoreProductTag } from '~web/features/essentials/components/StoreProductTag';
import { Image, StyledHoverable, StyledTouchable, Text, View } from '~web/features/essentials/primitives';

const StyledProductItem = styled.li<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${SPACING.STATIC.LG.px};
  ${StyledHoverable}
  ${({ disabled }) => (disabled ? '' : StyledTouchable)}
`;

const StyledImage = styled(View)`
  ${StyledTouchable}
`;

type ProductItemProps = {
  readonly product: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
};

const placeholderImage = 'https://assets.prod.alto.com/otc_categories/essentials_logo.svg';

export const ProductItem = ({ product, analyticsProps }: ProductItemProps) => {
  const { shipmentID, origin, activeProduct } = useAddOnsContext();
  const disabled = !!activeProduct;
  const dispatch = useDispatchShared();

  const handlePress = () => {
    if (disabled) return;
    let search = `?origin=${origin}`;
    if (shipmentID) {
      search += `&order=${shipmentID}`;
    }
    dispatch(
      push({
        pathname: `/store/${product.product_id}`,
        search,
        state: { analyticsProps },
      }),
    );
  };

  return (
    <StyledProductItem
      onClick={handlePress}
      disabled={disabled}
    >
      <StyledImage
        position="sticky"
        justifyContent="center"
      >
        <Image
          src={product.image_urls[0] || placeholderImage}
          width={SIZES.ILLUSTRATION.XL.px}
          height={SIZES.ILLUSTRATION.XL.px}
          alt={product.name}
        />
        <StoreProductTag
          product={product}
          shipmentID={shipmentID}
          hidePlusIcon
        />
      </StyledImage>
      <FSABadge addOnOtc={product} />
      <Text
        title={product.name}
        variant="body-semibold"
        color="PRIMARY"
        numberOfLines={2}
      />
      <View flexGrow={1} />
      <XsSpacing />
      <Text
        title={product.quantity_description || ''}
        variant="body"
        color="DISABLED"
      />
      <MdSpacing />
      <LgSpacing />
      <ProductFooter
        product={product}
        analyticsProps={analyticsProps}
        showPrice={false}
        isGridItem
      />
    </StyledProductItem>
  );
};
