// @owners { team: patients-team }
import { Description, ListDescription } from '@alto/design-system';
import React from 'react';
import { formatWindowTime } from '~shared/helpers/date';

export const PickupTimeDescription = ({ time }: { time: string | undefined | null }) => {
  if (!time) {
    return null;
  }

  const timeParsed = formatWindowTime({ windowTime: time, meridiem: 'aaa', forceReturnMinutes: true, useUTC: false });
  return (
    <ListDescription key={`pickup-time-description-${timeParsed}`}>
      <Description>Ready by {timeParsed} (estimated)</Description>
    </ListDescription>
  );
};
