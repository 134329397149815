import { type Insurance } from '~shared/types';

/**
 * Returns true if the given insurance item has been saved and is awaiting transcription
 */
const getIsPhotoUpload = (insurance: Insurance | Partial<Insurance> | null | undefined): boolean => {
  if (!insurance) return false;

  return !!insurance.id && !insurance.bin && typeof insurance.image_url === 'string' && insurance.image_url.length > 0;
};

export default getIsPhotoUpload;
