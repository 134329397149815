import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { logout } from '~shared/actions/auth';
import getCookie from '~shared/helpers/getCookie';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { configureStore } from '~web/configureStore';

// for top calculation, 1.2em appears to be the height of our text, can't find it explicitly defined anywhere
const ImposterWrapper = styled.div`
  border: 3px solid ${COLORS.PALETTE.DANGER.DEFAULT};
  border-top: 0;
  padding-bottom: 212px;
  .banner {
    margin: 0 3px;
    top: calc(#{90px} + 3px);
    width: calc(100% - 3px * 2);
  }
  .patient-nav {
    margin: 0 3px;
    top: calc(1.2em + 4px * 2 + 3px);
  }
`;

// for z-index, keep it above the DashboardNavbar and Banner components
const Banner = styled.span`
  background-color: ${COLORS.PALETTE.DANGER.DEFAULT};
  color: ${COLORS.PALETTE.GREYSCALE.WHITE};
  display: flex;
  justify-content: space-between;
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  padding: 4px 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1500;
`;
const Logout = styled.a`
  color: ${COLORS.PALETTE.GREYSCALE.WHITE};
  margin-right: 30px;
`;

type Props = {
  readonly children?: React.ReactNode;
};

export const ImposterBanner = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const adminCookie = getCookie('admin');
  const auth = useSelectorShared((state) => state.auth);
  const user = auth.user;

  if (!adminCookie || !user) {
    return <div>{children}</div>;
  }

  const fullName =
    user.full_name || [user?.first_name, user?.middle_name, user?.last_name].filter(Boolean).join(' ').trim();
  const handleLogout = () => {
    dispatch(logout())
      .then((success) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          dispatch(push('/'));
        } else {
          alert('unable to log out');
        }
      })
      .catch((err: unknown) => {
        alert(err);
      });
    configureStore().persistor.purge();
    queryClient.clear();
  };

  return (
    <ImposterWrapper>
      <Banner>
        <span />
        <span className="text">
          EXERCISE CAUTION: Logged in as&nbsp;
          <strong>{fullName}</strong>
        </span>
        <Logout onClick={handleLogout}>Log out</Logout>
      </Banner>
      {children}
    </ImposterWrapper>
  );
};
