import pluralize from 'pluralize';

type FormattedMedications = {
  medication: string;
  moreMedications: string;
  allMedications: string;
};

export function formatMedications(medication_names: string[]): FormattedMedications {
  const otherMedicationCount = medication_names.length - 1;
  const addOn =
    medication_names.length > 1
      ? ` + ${otherMedicationCount} more ${pluralize('medication', otherMedicationCount)}`
      : ``;

  return {
    medication: medication_names[0],
    moreMedications: addOn,
    allMedications: medication_names[0] + addOn,
  };
}
