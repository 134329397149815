// @owners { team: patients-team }
import { type AltoIconName, COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, MdSpacing, Row, useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, ParentWidthColumn, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-radius: 0;
  padding: ${SPACING.STATIC.MD.rem};
`;

const Label = styled(Text)`
  margin-left: ${SPACING.STATIC.MD.rem};
`;

const Description = styled(Text)<{ isSMScreenOrBigger: boolean }>`
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `display: none;`}
`;

type Props = {
  readonly description?: string;
  readonly icon: AltoIconName;
  readonly label: string;
  readonly onClick: () => void;
};

export const SectionNavigator = ({ description = '', icon, label, onClick }: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();

  return (
    <StyledButton
      full
      kind="tertiary"
      onClick={onClick}
    >
      <ParentWidthColumn evenlySpaceContent>
        <Row centerVertically>
          <AltoIcon
            name={icon}
            type="grey"
          />
          <Label inline>{label}</Label>
        </Row>
        <Row centerVertically>
          {!!description && (
            <Description
              inline
              small
              light
              isSMScreenOrBigger={isSMScreenOrBigger}
            >
              {description}
            </Description>
          )}
          <MdSpacing />
          <AltoIcon
            name="chevronright-small"
            type="grey"
          />
        </Row>
      </ParentWidthColumn>
    </StyledButton>
  );
};
