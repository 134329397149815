// @owners { team: patients-team }

export const SOURCES = Object.freeze({
  PRE_DELIVERY: 'pre-delivery' as const,
  POST_DELIVERY: 'post-delivery' as const,
  EDIT_DELIVERY: 'edit-delivery' as const,
});

type SourceKeys = keyof typeof SOURCES;
type SourceValues = (typeof SOURCES)[SourceKeys];

export type AnalyticsProps = {
  shipment_id?: string | number | null;
  prescription_ids?: (string | number)[];
  source: SourceValues;
  facility_id?: number;
  tip_value?: string;
};
