// @owners { team: patients-team }
import { TypescriptUtils } from '~shared/TypescriptUtils';
import {
  CREATE_ACCOUNT,
  HOME,
  INSURANCE_FOUND,
  INSURANCE_INPUT,
  INSURANCE_INPUT_MANUAL,
  INSURANCE_INPUT_PHOTO,
  MEDICAL_INFO,
  MED_CONFIRMATION,
  ONBOARD_SUCCESS,
  PHI_AUTHORIZATION,
  RX_TRACKER,
  type Route,
  TARGETED_DIAGNOSES,
  TRANSFER,
  VERIFY,
  WEB_LANDING_PAGE,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';

// @ts-expect-error TS(2741): Property 'WEB_LANDING_PAGE' is missing in type '{ TRACKER: string; VERIFY: string; MED_CONFIRMATION:... (Delete me to see the full error)
export const ROUTE_TO_URL: Record<Route, string> = {
  [RX_TRACKER]: '/status',
  [VERIFY]: '/onboard/verify/:tempPassword',
  [MED_CONFIRMATION]: '/onboard/med-confirmation',
  [TARGETED_DIAGNOSES]: '/onboard/targeted-diagnoses',
  [INSURANCE_FOUND]: '/onboard/insurance',
  [INSURANCE_INPUT]: '/onboard/insurance-input',
  [INSURANCE_INPUT_MANUAL]: '/onboard/insurance-input-manual',
  [INSURANCE_INPUT_PHOTO]: '/onboard/insurance-input-photo',
  [MEDICAL_INFO]: '/onboard/medical-info',
  [TRANSFER]: '/onboard/transfer',
  [PHI_AUTHORIZATION]: '/onboard/authorize-phi',
  [CREATE_ACCOUNT]: '/onboard/create-account',
  [ONBOARD_SUCCESS]: '/onboard/success',
  [HOME]: '/home',
};

export const URL_TO_ROUTE = TypescriptUtils.objectKeys(ROUTE_TO_URL).reduce<Record<string, Route>>((obj, key) => {
  const value = ROUTE_TO_URL[key];
  obj[value] = key;
  return obj;
}, {});

export function routeFromPath(path: string): Route {
  const verifyUrl = ROUTE_TO_URL[VERIFY];
  const verify = verifyUrl.substring(0, verifyUrl.indexOf(':'));
  const prescriptionTrackerVerify = '/status/';

  if (path.startsWith(verify) || path.startsWith(prescriptionTrackerVerify)) {
    return VERIFY;
  }

  if (path.startsWith('/begin/') || path.startsWith('/start/')) {
    return WEB_LANDING_PAGE;
  }

  return URL_TO_ROUTE[path];
}
