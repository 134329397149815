import React, { useCallback, useEffect, useState } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchIdentityVerificationNeeded, verifyCommsAndUser } from '~shared/actions/verifications';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { formatDateStringForAPI } from '~shared/helpers/date';
import { getErrorMessage, getURLParams } from '~shared/helpers/helper';
import { replaceApostrophes } from '~shared/helpers/string';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import IdentityVerificationPage from './IdentityVerificationPage';

type StateProps = {
  readonly submitLoading: boolean;
  readonly identityVerificationNeededLoading: boolean;
  readonly identityVerificationNeededError: string | null | undefined;
};

type DispatchProps = {
  readonly redirectToVerificationPage: (arg0: string, arg1: string) => void;
  readonly identityVerificationNeeded: (arg0: string, arg1: string) => void;
  readonly handleSubmit: (arg0: Record<string, unknown>) => void;
};

type OwnProps = {
  readonly params: {
    commsChannel: string;
  };
};

type Props = OwnProps & StateProps & DispatchProps;

const IdentityVerificationContainer = ({
  submitLoading,
  identityVerificationNeededLoading,
  identityVerificationNeededError,
  redirectToVerificationPage,
  handleSubmit,
  identityVerificationNeeded,
  params,
}: Props) => {
  const { commsChannel } = params;
  const { token } = getURLParams();

  const [isIdentityVerificationNeeded, setIsIdentityVerificationNeeded] = useState<boolean | null | undefined>(null);
  const onIdentityVerificationNeeded = useCallback(() => {
    // @ts-expect-error TS(7006): Parameter 'identity_verification_needed' implicitly has an 'any' type.
    // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression, promise/always-return
    identityVerificationNeeded(token, commsChannel).then((identity_verification_needed) => {
      setIsIdentityVerificationNeeded(identity_verification_needed);
    });
  }, [token, commsChannel, identityVerificationNeeded]);

  useEffect(() => {
    onIdentityVerificationNeeded();
  }, [onIdentityVerificationNeeded]);

  if (
    !identityVerificationNeededLoading &&
    !identityVerificationNeededError &&
    isIdentityVerificationNeeded === false
  ) {
    redirectToVerificationPage(token, commsChannel);
  }

  return (
    <IdentityVerificationPage
      handleSubmit={({ last_name, dob }) => {
        handleSubmit({
          last_name,
          dob,
          token,
          comms_channel: commsChannel,
        });
      }}
      submitLoading={submitLoading}
      identityVerificationNeededLoading={identityVerificationNeededLoading}
      identityVerificationNeededError={identityVerificationNeededError}
    />
  );
};

const mapStateToProps = (state: ReduxStateShared) => {
  const loading = getLoading(state);
  const error = getErrors(state);

  return {
    submitLoading: loading.verifyIdentityAndCommsLoading,
    identityVerificationNeededLoading: loading.identityVerificationNeededLoading,
    identityVerificationNeededError: getErrorMessage(error.identityVerificationNeededError),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    // @ts-expect-error TS(7006): Parameter 'token' implicitly has an 'any' type.
    redirectToVerificationPage: (token, comms_channel) => {
      dispatch(push(`/communications/verify/${comms_channel}/${token}`));
    },
    // @ts-expect-error TS(7006): Parameter 'token' implicitly has an 'any' type.
    identityVerificationNeeded: (token, comms_channel) =>
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchIdentityVerificationNeeded(token, comms_channel)),
    // @ts-expect-error TS(7006): Parameter 'values' implicitly has an 'any' type.
    handleSubmit: (values) => {
      const { dob, last_name, token, comms_channel } = values;
      const date_of_birth = formatDateStringForAPI(dob);
      const formattedLastName = replaceApostrophes(last_name);
      // eslint-disable-next-line import/no-deprecated, promise/catch-or-return
      dispatch(verifyCommsAndUser(formattedLastName, date_of_birth, token, comms_channel)).then((success) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          dispatch(push(`/communications/verify_identity/${comms_channel}/success?token=${token}`));
        } else {
          dispatch(push(`/communications/verify_identity/${comms_channel}/fail?token=${token}`));
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentityVerificationContainer);
