// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { MdSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, Row, Text, palette } from '@alto/pocky';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import { formatDollars } from '~shared/helpers/currency';

const Amount = styled.div<{ color: string }>`
  flex: 1;
  text-align: right;
  color: ${(props) => props.color};
`;

const UpdateButton = styled(Button)`
  padding: 0px;
  min-height: unset;
  &:hover&:not(:disabled) {
    background-color: transparent;
  }
`;

const noop = () => undefined;

type Props = {
  readonly amount?: number | null | undefined;
  readonly active?: boolean;
  readonly textColor?: string;
  readonly icon: ReactNode;
  readonly paymentMethodName: string;
  readonly failedPayment?: boolean;
  readonly splitPayment?: boolean;
  readonly shipmentIsEditable?: boolean;
  readonly editShipment?: () => void;
  readonly hasFailedSplitPayments?: boolean;
};

export default function StyledPaymentMethodRow({
  amount,
  icon,
  paymentMethodName,
  active,
  textColor = palette.altoColorsB1,
  failedPayment,
  splitPayment,
  shipmentIsEditable,
  editShipment = noop,
  hasFailedSplitPayments,
}: Props) {
  const formattedAmount = amount ? formatDollars(amount) : '';
  const backgroundColor = failedPayment ? COLORS.BACKGROUND_COLORS.DANGER_LIGHTEST : undefined;

  return (
    <Row
      verticallyAlignContent
      evenlySpaceContent
      backgroundColor={backgroundColor}
      padding={`${SPACING.STATIC.XS.px} ${SPACING.STATIC.LG.px}`}
      margin={`${SPACING.STATIC.NONE.px} -${SPACING.STATIC.LG.px}`}
    >
      <Column>
        <Row verticallyAlignContent>
          {icon}
          <MdSpacing />
          <Text
            active={active}
            inline
          >
            {paymentMethodName}
          </Text>
        </Row>
      </Column>
      <Column>
        {shipmentIsEditable && failedPayment && !splitPayment ? (
          <Row>
            <UpdateButton
              kind="tertiary"
              small
              onClick={editShipment}
            >
              Update payment
            </UpdateButton>
          </Row>
        ) : null}
        {!failedPayment && !hasFailedSplitPayments && <Amount color={textColor}>{formattedAmount}</Amount>}
      </Column>
    </Row>
  );
}
