// @owners { team: patients-team }
import { AltoIcon, Description, ListDescription, Row, XxsSpacing } from '@alto/design-system';
import { type FulfillmentWindowOption } from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import React from 'react';

// if clicking the fulfillment option leads to the select delivery window action sheet, areWindowChangeable is true
export const WindowDescription = ({
  window,
  areWindowsChangeable = false,
}: {
  window?: FulfillmentWindowOption | null;
  areWindowsChangeable?: boolean;
}) => {
  if (!window) {
    return (
      <ListDescription fullWidth>
        <Row>
          <Description>Select delivery time</Description>
          <AltoIcon
            name="chevronright-small"
            type={'grey'}
          />
        </Row>
      </ListDescription>
    );
  }

  return (
    <>
      <ListDescription>{`${window.label}, ${window.description.toLowerCase()}`}</ListDescription>
      <XxsSpacing />
      {areWindowsChangeable && (
        <ListDescription>
          <Row>
            <Description>Change delivery time</Description>
            <AltoIcon
              name="chevronright-small"
              type={'grey'}
            />
          </Row>
        </ListDescription>
      )}
    </>
  );
};
