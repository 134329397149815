// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  AltoSpinningLoader,
  Description,
  FlatCard,
  LgPadding,
  MdPadding,
  Pressable,
  Row,
  Separator,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { type OrderBundlingOrigin } from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/order_bundling_origin';
import React, { useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing } from '~shared/actions/pricing';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { getPrescriptionsByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { PricingBreakdownActionSheet } from '../../pricing-breakdown';
import { useOrderBundlingOptions } from '../hooks/useOrderBundlingOptions';

type OrderBundlingPaymentSummaryProps = {
  readonly origin: OrderBundlingOrigin;
  readonly showPaymentBreakdown: boolean;
};

export const OrderBundlingPaymentSummary = ({ origin, showPaymentBreakdown }: OrderBundlingPaymentSummaryProps) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const address = useSelectorShared(getDefaultAddress);
  const { isPending, earliestUpcomingShipment, cartShipment, bundledPrescriptionIDs } = useOrderBundlingOptions({
    origin,
    address_id: address?.id,
  });
  const bundledPrescriptions = useSelectorShared((state) => getPrescriptionsByIDs(state, bundledPrescriptionIDs || []));

  if (isPending || !cartShipment || !earliestUpcomingShipment) {
    return (
      <FlatCard>
        <LgPadding>
          <Row center>
            <AltoSpinningLoader />
          </Row>
        </LgPadding>
      </FlatCard>
    );
  }

  const newPrescriptionsCount = cartShipment.prescriptions.length ?? 1;
  const newMedicationsPriceLabel = `New ${newPrescriptionsCount > 1 ? 'medications' : 'medication'}`;

  const originalPrice = earliestUpcomingShipment.price ?? undefined;
  const isOriginalPriceNumber = typeof originalPrice === 'number';

  const newPrice = cartShipment.price ?? undefined;
  const isNewPriceNumber = typeof newPrice === 'number';

  const formattedOriginalPrice = isOriginalPriceNumber
    ? `$${originalPrice.toFixed(2)}`
    : PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE;
  const formattedNewPrice = isNewPriceNumber
    ? `$${newPrice.toFixed(2)}`
    : PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE;

  let formattedPrice;
  if (isOriginalPriceNumber && isNewPriceNumber) {
    formattedPrice = `$${(originalPrice + newPrice).toFixed(2)}`;
  } else {
    formattedPrice = PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE;
  }

  const handleOnPress = async () => {
    // eslint-disable-next-line import/no-deprecated
    const response = await dispatch(fetchOrderPricing(earliestUpcomingShipment.id));
    if (response) {
      setActiveActionSheet(
        <PricingBreakdownActionSheet
          origin={origin}
          prescriptions={bundledPrescriptions}
        />,
      );
    } else {
      addToast(
        <Toast
          dismissible
          duration={5000}
          variant="error"
        >
          There was an error loading the item breakdown. Please try again.
        </Toast>,
      );
    }
  };

  return (
    <FlatCard>
      <MdPadding
        topPadding={SPACING.STATIC.SM}
        bottomPadding={showPaymentBreakdown ? SPACING.STATIC.XXS : SPACING.STATIC.SM}
      >
        <Row spaceBetween>
          <Description>Original order</Description>
          <Description>{formattedOriginalPrice}</Description>
        </Row>
        <XsSpacing />
        <Row spaceBetween>
          <Description>{newMedicationsPriceLabel}</Description>
          <Description>{formattedNewPrice}</Description>
        </Row>
        <XsSpacing />
        <Row spaceBetween>
          <Description
            color={COLORS.TEXT_COLORS.PRIMARY}
            fontFamily="semibold"
          >
            Updated total
          </Description>
          <Description
            color={COLORS.TEXT_COLORS.PRIMARY}
            fontFamily="semibold"
          >
            {formattedPrice}
          </Description>
        </Row>
      </MdPadding>
      {showPaymentBreakdown ? (
        <>
          <Separator />
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Pressable onPress={handleOnPress}>
            <MdPadding
              topPadding={SPACING.STATIC.SM}
              bottomPadding={SPACING.STATIC.SM}
            >
              <Row
                centerVertically
                spaceBetween
              >
                <Description
                  color={COLORS.TEXT_COLORS.SECONDARY_DARK}
                  fontFamily="semibold"
                >
                  Full pricing details
                </Description>
                <AltoIcon
                  type="secondary"
                  name="chevronright-small"
                />
              </Row>
            </MdPadding>
          </Pressable>
        </>
      ) : null}
    </FlatCard>
  );
};
