// @owners { team: patients-team }
import { type Dispatch } from 'redux';
import { get } from '~shared/helpers/apiHelper';

export const FETCH_PENDING_RATE_SHIPMENT_LOADING = 'FETCH_PENDING_RATE_SHIPMENT_LOADING';

export const fetchPendingRateShipmentLoading = () => {
  return {
    type: FETCH_PENDING_RATE_SHIPMENT_LOADING,
  } as const;
};

export const FETCH_PENDING_RATE_SHIPMENT_FAILED = 'FETCH_PENDING_RATE_SHIPMENT_FAILED';

export const fetchPendingRateShipmentFailed = (error: Error) => {
  return {
    type: FETCH_PENDING_RATE_SHIPMENT_FAILED,
    payload: error,
  } as const;
};

export const FETCH_PENDING_RATE_SHIPMENT_SUCCEEDED = 'FETCH_PENDING_RATE_SHIPMENT_SUCCEEDED';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const fetchPendingRateShipmentSucceeded = (pendingRateShipment: any | null | undefined) => {
  return {
    type: FETCH_PENDING_RATE_SHIPMENT_SUCCEEDED,
    payload: pendingRateShipment,
  } as const;
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchPendingRateShipment() {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchPendingRateShipmentLoading());
    return get('/survey_responses/check_pending', {
      survey_type: 'rate_shipment',
    }).then((json) => {
      if (json.error) {
        dispatch(fetchPendingRateShipmentFailed(json.error));
        return false;
      }

      const pendingRateShipment = json?.pending_survey_response;
      dispatch(fetchPendingRateShipmentSucceeded(pendingRateShipment));
      return true;
    });
  };
}

export const RATE_SHIPMENT_DISMISSED = 'RATE_SHIPMENT_DISMISSED';
export function rateShipmentDismissed() {
  return {
    type: RATE_SHIPMENT_DISMISSED,
  } as const;
}
