// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { useEffect } from 'react';
import { getAnalyticalProperties } from '~shared/lib/experiments/src/getAnalyticalProperties';
import { useSelectorShared } from '~shared/store';

export const ExperimentationListener = () => {
  const attributes = useSelectorShared(getAnalyticalProperties);

  useEffect(() => {
    Experimentation.setAttributes(attributes);
  }, [attributes]);

  return null;
};
