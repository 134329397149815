import {
  type PrescriptionPricing,
  type PriceOption,
  type PriceType,
} from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createSelector } from 'reselect';
import { getCartPricing, getPrescriptionPricing } from './getPricing';
import getCartSelectedPaymentTypes from '~shared/features/checkout/selectors/getSelectedPaymentTypes';
import { PRICING_INFO } from '~shared/features/my-meds/constants';
import {
  PRESCRIPTION_PRICING_LABELS,
  PRICE_OPTION_LABELS,
  PRICING_SCENARIOS,
} from '~shared/features/pricing/constants';
import { selectedPriceOption } from '~shared/features/pricing/helpers';
import { type OrderPricing, type Pricing } from '~shared/features/pricing/types';
import { formatDollars } from '~shared/helpers/currency';
import { type ReduxStateShared } from '~shared/types';

// TODO(pricing refactor): figure out way to remove this, and not return this object
// a few components use this object, so first pass is to translate new
// api pricing into old PriceInfo object, then refactor components
// that use this object to a simpler object or to not need it at all
export type PriceInfo = {
  paymentLabel: string | null | undefined;
  paymentType: PriceType | null | undefined;
  priceLabelInfo: string | null | undefined;
  priceLabel:
    | (typeof PRESCRIPTION_PRICING_LABELS)[keyof typeof PRESCRIPTION_PRICING_LABELS]

    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | ''
    | null
    | undefined;
  price: string | null | undefined;
  totalReason:
    | (typeof PRESCRIPTION_PRICING_LABELS)[keyof typeof PRESCRIPTION_PRICING_LABELS]

    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | ''
    | null
    | undefined;
};

export type ItemPriceTotal = {
  reason: string;
  value: number | null | undefined;
  type?: PriceType | null | undefined;
};

// replacement for paymentSummary.js/deliveryTotal
export const getItemPricingTotal = (
  pricing: Pricing | PrescriptionPricing,
  selectedPaymentType?: PriceType | null,
): ItemPriceTotal => {
  let reason: (typeof PRESCRIPTION_PRICING_LABELS)[keyof typeof PRESCRIPTION_PRICING_LABELS] =
    PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE;
  const priceOption: PriceOption | null | undefined = selectedPriceOption(pricing?.prices || [], selectedPaymentType);

  if (!priceOption) {
    return {
      reason,
      value: 0,
    };
  }

  // slightly different than the below pricingInfoForPrescription...
  // TODO(pricing refactor): try to consolidate and only use one?
  const { price, type } = priceOption;
  const { estimated, scenario } = pricing || {};

  if (scenario === PRICING_SCENARIOS.UNAVAILABLE) {
    reason = PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE;
  } else if (scenario === PRICING_SCENARIOS.PROGYNY) {
    reason = PRESCRIPTION_PRICING_LABELS.PROGYNY;
  } else if (!estimated && type === 'without_insurance') {
    reason = PRESCRIPTION_PRICING_LABELS.YOUR_COPAY;
  } else if (!estimated && type === 'with_insurance') {
    reason = PRESCRIPTION_PRICING_LABELS.YOUR_COPAY;
  } else if (estimated && type === 'with_insurance') {
    reason = PRESCRIPTION_PRICING_LABELS.LAST_COPAY;
  } else if (estimated && type === 'without_insurance') {
    reason = PRESCRIPTION_PRICING_LABELS.ESTIMATED_PRICE;
  } else if (scenario === PRICING_SCENARIOS.VOUCHER_PAY) {
    reason = PRESCRIPTION_PRICING_LABELS.COVERED_BY_CLINIC;
  }

  return {
    reason,
    value: price,
    type,
  };
};

// replacement for getPriceInfoForPrescription
export const pricingInfoForPrescription = (pricingForPrescription: Pricing): PriceInfo[] => {
  const { prices = [], estimated, scenario } = pricingForPrescription || {};

  return prices.map((priceOption: PriceOption) => {
    const { price, type } = priceOption;
    const pricingInfo: PriceInfo = {
      paymentLabel: '',
      // how to handle payment label??
      paymentType: type,
      priceLabelInfo: '',
      priceLabel: '',
      price: '',
      totalReason: '',
    };

    if (scenario === PRICING_SCENARIOS.UNAVAILABLE) {
      pricingInfo.priceLabel = PRESCRIPTION_PRICING_LABELS.PRICE_UNAVAILABLE;
      pricingInfo.priceLabelInfo = PRICING_INFO.price_unavailable;
    } else {
      pricingInfo.price = formatDollars(price || 0);
    }

    if (scenario === PRICING_SCENARIOS.PROGYNY) {
      pricingInfo.priceLabel = PRESCRIPTION_PRICING_LABELS.PROGYNY;
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      pricingInfo.price += '*';
    } else if (!estimated && type === 'without_insurance') {
      pricingInfo.priceLabel = PRESCRIPTION_PRICING_LABELS.YOUR_COPAY;
    } else if (!estimated && type === 'with_insurance') {
      pricingInfo.priceLabel = PRESCRIPTION_PRICING_LABELS.YOUR_COPAY; // TODO(pricing refactor): how are we getting handling this info now?
      // pricingInfo.paymentLabel = getPaymentLabel(delivery, pricingResult);
    } else if (estimated && type === 'with_insurance') {
      pricingInfo.priceLabel = PRESCRIPTION_PRICING_LABELS.LAST_COPAY;
      pricingInfo.priceLabelInfo = PRICING_INFO.wont_be_charged_until; // TODO(pricing refactor): how are we getting handling this info now?
      // pricingInfo.paymentLabel = getPaymentLabel(delivery);
    } else if (estimated && type === 'without_insurance') {
      pricingInfo.priceLabel = PRESCRIPTION_PRICING_LABELS.ESTIMATED_PRICE;
      pricingInfo.priceLabelInfo = `${PRICING_INFO.cannot_bill_automatically(1)} ${PRICING_INFO.price_estimated(1)}`;
    }

    // TODO(pricing refactor): not sure how to map these properly or how to remove them if possible
    pricingInfo.totalReason = pricingInfo.priceLabel;

    return pricingInfo;
  });
};

const getPrescriptionID = (_: ReduxStateShared, prescriptionID: number) => prescriptionID;

export const getPricingInfoForPrescription = createSelector(
  [getPrescriptionPricing, getPrescriptionID],
  (prescriptionPricing, prescriptionID) => {
    return prescriptionPricing?.[prescriptionID] ? pricingInfoForPrescription(prescriptionPricing[prescriptionID]) : [];
  },
);

export const getPricingInfoOptionsForPrescription = createSelector(
  [getPricingInfoForPrescription],
  (priceInfos: PriceInfo[]) => {
    if (priceInfos.length > 1) {
      return priceInfos.map((priceInfo) => ({
        ...priceInfo,
        priceLabel: priceInfo.paymentType ? PRICE_OPTION_LABELS[priceInfo.paymentType] : priceInfo.priceLabel,
      }));
    }

    return priceInfos;
  },
);

export const getCartPricingInfoForPrescription = createSelector(
  [getCartPricing, getPrescriptionID],
  (cartPricing: OrderPricing, prescriptionID: number) => {
    return cartPricing.item_pricing[prescriptionID]
      ? pricingInfoForPrescription(cartPricing.item_pricing[prescriptionID])
      : [];
  },
);

export const getCartSelectedPricingInfoForPrescription = createSelector(
  [getCartSelectedPaymentTypes, getCartPricing, getPrescriptionID],
  (cartSelectedPaymentTypes, cartPricing, prescriptionID) => {
    const selectedPaymentType = cartSelectedPaymentTypes?.[prescriptionID] || null;
    const prices = cartPricing.item_pricing[prescriptionID]
      ? pricingInfoForPrescription(cartPricing.item_pricing[prescriptionID])
      : [];
    if (prices.length === 2 && selectedPaymentType) {
      return prices.find((p) => p.paymentType === selectedPaymentType);
    } else if (prices.length > 0) {
      return prices[0];
    }
    return undefined;
  },
);
