import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, Button, Column, SmSpacing } from '@alto/design-system';
import {
  BackorderedTag,
  ManualCouponInfoBox,
  ORDER_TYPE_CONTEXT,
  OrderTypeSelectionSection,
  useBackorderedMedication,
  useFetchAncillariesByPrescriptionId,
  useQueryEssentials,
  useRemoveFromCart,
} from '@alto/features';
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
// eslint-disable-next-line @alto/no-pocky-import
import { Link, LoaderImage, Row, Text, palette, spacing, spinnerStyles } from '@alto/pocky';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { type PriceOption, type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { type ModalType } from '~shared/actions/modal';
import { getCashLessThanInsuranceSavings, prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { useCourierAgeRestriction } from '~shared/features/checkout/hooks/useCourierAgeRestriction';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getMedicationForPrescription } from '~shared/features/my-meds/selectors/getMedicationsFromPrescriptions';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { type PriceInfo } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { formatQuantity, isControl } from '~shared/helpers/helper';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import SavingsAppliedTag from './SavingsAppliedTag';
import ClickableAltoIcon from '~web/components/ClickableAltoIcon';
import { useEditEssential } from '~web/features/essentials/hooks';
import UserTag from '~web/features/family-accounts/components/UserTagContainer';
import { AncillaryItemsTag } from '~web/features/my-meds/components/AncillaryItemsTag';
import { NextAvailableDateInfoRow } from '~web/features/my-meds/components/NextAvailableDateInfoRow';
import { PhotoIDRequiredTag } from '~web/features/my-meds/components/PhotoIDRequiredTag';
import RefrigerationRequiredTag from '~web/features/my-meds/components/RefrigerationRequiredTag';
import { Ancillaries } from '~web/features/my-meds/components/med-list/ancillaries/Ancillaries';
import { ChangeQuantityLink } from '~web/features/quantity-change/components/ChangeQuantityLink';
import { ChangeQuantityModal } from '~web/features/quantity-change/components/ChangeQuantityModal';

const Container = styled.div`
  padding: ${spacing.mdSpacing};
`;

const Name = styled(Text)`
  flex: 1;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RemoveLink = styled(Link)`
  font-size: 0.875rem;
`;

const StyledColumn = styled(Column)<{ centerVertically: boolean }>``;

const PriceRowContainer = styled.div`
  padding-top: ${spacing.xsSpacing};
  padding-bottom: ${spacing.xsSpacing};
`;

const PriceText = styled(Text)<{
  color?: (typeof COLORS.TEXT_COLORS)[keyof typeof COLORS.TEXT_COLORS];
  small?: boolean;
}>`
  color: ${({ color }) => color || palette.black1};
  font-weight: 500;
`;

const SectionSeparator = styled.div`
  margin: ${SPACING.STATIC.MD.px} 0;
  border-top: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  border-bottom: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
`;

const ChangePriceButton = styled(Button)`
  padding: 0px;
  min-height: unset;
  font-size: 0.875rem;

  &:hover &:not(:disabled) {
    background-color: transparent;
  }
`;

type PricingSectionProps = {
  readonly cartItem: CartItem;
  readonly priceLoading: boolean;
  readonly pricingInfoForPrescription:
    | PriceInfo
    | {
        price?: string | null | undefined;
        priceLabel?: string | null | undefined;
        paymentType?: PriceType | null | undefined;
      };

  readonly allowPriceOptionChange: boolean;
  readonly openModal: (modalType: ModalType, props?: Record<string, unknown>) => void;
  readonly priceOptions: PriceOption[];
};

const PricingSection = (props: PricingSectionProps) => {
  const { cartItem, priceLoading, pricingInfoForPrescription, allowPriceOptionChange, openModal, priceOptions } = props;
  const { price, priceLabel, paymentType } = pricingInfoForPrescription || {};
  const cashSavingsText = getCashLessThanInsuranceSavings(priceOptions);

  return priceLoading ? (
    <Row evenlySpaceContent>
      <LoaderImage style={spinnerStyles} />
    </Row>
  ) : (
    <PriceRowContainer>
      <Row evenlySpaceContent>
        <PriceText small>{priceLabel}</PriceText>
        <PriceText small>{price || '-'}</PriceText>
      </Row>
      {cashSavingsText && paymentType === 'without_insurance' ? (
        <Row>
          <PriceText
            small
            color={COLORS.TEXT_COLORS.SUCCESS}
          >
            {cashSavingsText}
          </PriceText>
        </Row>
      ) : null}
      <Row evenlySpaceContent>
        <SavingsAppliedTag
          prescriptionID={cartItem.resource_id}
          priceType={paymentType}
        />
      </Row>
      {allowPriceOptionChange ? (
        <Row
          spacing={SPACING.STATIC.XS.px}
          verticallyAlignContent
        >
          <ChangePriceButton
            type="tertiary"
            small
            onPress={() => {
              openModal('SELECT_PRICE_OPTION_MODAL', { prescriptionID: cartItem.resource_id });
            }}
          >
            Change price option
          </ChangePriceButton>
          <ClickableAltoIcon
            onClick={() => {
              openModal('SELECT_PRICE_OPTION_MODAL', { prescriptionID: cartItem.resource_id });
            }}
            AltoIcon={
              <AltoIcon
                name="edit-small"
                accessibilityLabel="Edit"
                type="secondary"
              />
            }
          />
        </Row>
      ) : null}
    </PriceRowContainer>
  );
};

type PrescriptionItemProps = {
  readonly cartItem: CartItem;
  readonly priceLoading: boolean;
  readonly pricingInfoForPrescription:
    | PriceInfo
    | {
        price?: string | null | undefined;
        priceLabel?: string | null | undefined;
        paymentType?: PriceType | null | undefined;
      };

  readonly allowPriceOptionChange: boolean;
  readonly openModal: (modalType: ModalType, props?: Record<string, unknown>) => void;
  readonly showChangeQuantityLink: boolean;
  readonly priceOptions: PriceOption[];
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PrescriptionItem = (props: PrescriptionItemProps) => {
  const [showChangeQuantityModal, setShowChangeQuantityModal] = useState(false);
  const { cartItem, showChangeQuantityLink } = props;
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, cartItem.resource_id));
  const medication = useSelectorShared((state) => getMedicationForPrescription(state, prescription));
  const hasControl = prescription && isControl(prescription);
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID: null }));
  const { essentialsHash } = useQueryEssentials({ addressID: address?.id });
  const { ancillaries } = useFetchAncillariesByPrescriptionId({
    prescriptionId: prescription?.id,
    hasAncillaryItems: prescription?.has_ancillary_items,
  });
  let essential: AddOnOtc | undefined;
  if (prescription?.is_add_on_otc && prescription?.product_id) {
    essential = essentialsHash[prescription.product_id];
  }
  const { onChangeEssential } = useEditEssential({ essential });
  const { handleRemoveFromCart } = useRemoveFromCart();
  const isBackorderdMedication = useBackorderedMedication(prescription?.id);
  const hideNextAvailableDate = isBackorderdMedication;

  const courierAgeRestrictionEnabled = useCourierAgeRestriction(address?.dispensing_facility_id);

  const handleRemove = useCallback(() => {
    if (!prescription) return;
    handleRemoveFromCart({ item: prescriptionToItemKey(prescription.id) });
  }, [handleRemoveFromCart, prescription]);

  if (!prescription || !medication) {
    return null;
  }
  const { dosageForm, medicationName } = medication;

  return (
    <Container>
      <Row evenlySpaceContent>
        <Name>{cartItem.name}</Name>
        <RemoveLink onClick={handleRemove}>Remove</RemoveLink>
      </Row>
      <Text small>{dosageForm}</Text>
      <Text small>Qty: {formatQuantity(prescription)}</Text>
      {showChangeQuantityLink ? (
        <ChangeQuantityLink
          prescriptionID={prescription.id}
          origin={ORIGIN_NAMES.CART}
          showQtyModal={() => {
            setShowChangeQuantityModal(true);
          }}
        />
      ) : null}
      <UserTag
        userIDs={[prescription.user_id]}
        small
      />
      <StyledColumn centerVertically>
        {essential ? (
          <Row verticallyAlignContent>
            <RemoveLink onClick={onChangeEssential}>Edit item</RemoveLink>
            <SmSpacing />
            <ClickableAltoIcon
              onClick={onChangeEssential}
              AltoIcon={
                <AltoIcon
                  name="edit-small"
                  accessibilityLabel="Edit"
                  type="secondary"
                />
              }
            />
          </Row>
        ) : null}
      </StyledColumn>
      {hideNextAvailableDate ? null : (
        <NextAvailableDateInfoRow
          prescription={prescription}
          orderTypeContext={ORDER_TYPE_CONTEXT.CART}
        />
      )}
      {prescription.has_ancillary_items ? <AncillaryItemsTag medicationName={medicationName} /> : null}
      {prescription.is_refrigerated ? <RefrigerationRequiredTag medicationName={medicationName} /> : null}
      {hasControl ? (
        <PhotoIDRequiredTag
          medicationName={medicationName}
          courierAgeRestrictionEnabled={courierAgeRestrictionEnabled}
        />
      ) : null}
      {prescription.auto_refill_eligible ? (
        <OrderTypeSelectionSection
          prescriptionID={prescription.id}
          orderTypeContext={ORDER_TYPE_CONTEXT.CART}
          initialAutoRefillEnabledValue={cartItem.auto_refill_enabled}
        />
      ) : null}
      {prescription.has_ancillary_items && ancillaries.length > 0 ? (
        <SectionSeparator>
          <Ancillaries
            collapsible
            ancillaries={ancillaries}
          />
        </SectionSeparator>
      ) : null}
      <PricingSection {...props} />
      {isBackorderdMedication ? (
        <BackorderedTag
          origin="Cart"
          prescriptionId={prescription.id}
        />
      ) : null}
      {prescription.user_id && prescription.id ? (
        <ManualCouponInfoBox
          prescriptionID={prescription.id}
          prescriptionUserID={prescription.user_id}
        />
      ) : null}
      {showChangeQuantityModal ? (
        <ChangeQuantityModal
          onClose={() => {
            setShowChangeQuantityModal(false);
          }}
          prescriptionID={medication.representativePrescription?.id}
        />
      ) : null}
    </Container>
  );
};
