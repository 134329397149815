// @owners { team: patients-team }
import { ActionSheetContext, Link as DSLink, MdSpacing } from '@alto/design-system';
import { TransferLearnMoreActionSheet } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, LoadingButton, spacing } from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik, type FormikProps } from 'formik';
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { ALL_ACTIVE_RXS } from '~shared/features/transfers/constants';
import { type ApiResponseRow, type Pharmacy } from '~shared/features/transfers/types';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { type AsyncValue, formatAsyncSelectInitialValue } from '~shared/helpers/formatAsyncSelectValues';
import { useSelectorShared } from '~shared/store';
import { type User } from '~shared/types';
import { createValidator, validatePresentIf, validatePresentWithMessage } from '~shared/validations';
// eslint-disable-next-line import/no-deprecated
import { TransferFormFields as FormikTransferFormFields } from './TransferFormFields';
import { type ButtonWrapper } from '~web/components/ButtonWrapper';

export type GooglePlacesResultValue = {
  city: string;
  name: string;
  phone: string;
  state: string;
  street_address: string;
  zip: string;
};

export type TransferFormFields = {
  transfer_all: boolean;
  medications: AsyncValue | AsyncValue[] | null;
  pharmacy: GooglePlacesResultValue | ApiResponseRow<string>[];
  selected_user_id: number | null | undefined;
  hasFamily: boolean;
};

export type ButtonType = {
  text?: string;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  onClick: (arg0: any | null | undefined) => void;
};

export type Props = {
  readonly isLoading: boolean;
  readonly submitButtonProps: ButtonType;
  readonly secondaryButtonProps?: ButtonType;
  readonly hasFamily: boolean;
  readonly users: User[];
  readonly ButtonWrapperComponent?: typeof ButtonWrapper;
  readonly isPatientsOtherScriptsOn?: boolean | null;
  readonly showLearnMoreLink?: boolean;
};

const formatPharmacy = (place: ApiResponseRow<Pharmacy>[]): string => {
  const pharmacy = place[0]?.value;

  if (!pharmacy) return '';

  if (pharmacy.name && pharmacy.street_address && pharmacy.city && pharmacy.state) {
    return `${pharmacy.name}, ${pharmacy.street_address}, ${pharmacy.city}, ${pharmacy.state}`;
  }

  return '';
};

const validate = createValidator<TransferFormFields>({
  pharmacy: (value) => validatePresentWithMessage('Pharmacy')(formatPharmacy(value)),
  selected_user_id: (value, values) => (values.hasFamily ? validatePresentWithMessage('Name')(value) : null),
  medications: validatePresentIf((_, values) => !values.transfer_all, 'Medication names'),
});

const formatPayload = (values: TransferFormFields) => {
  const { selected_user_id, medications, pharmacy, transfer_all } = values;

  let formattedMedications;
  if (Array.isArray(medications)) {
    formattedMedications = medications?.map((m) => ({
      name: m.value,
      // @ts-expect-error is_new may not be real
      autocomplete: !m.__isNew__,
    }));
  }

  return {
    user_id: selected_user_id,
    medications: transfer_all ? ALL_ACTIVE_RXS : JSON.stringify(formattedMedications),
    pharmacy: Array.isArray(pharmacy) ? pharmacy?.[0].value : pharmacy,
  };
};

/**
 * @deprecated do not use this, use TransferForm from \@alto/features instead
 */
const TransferForm = forwardRef(function RefTransferForm(
  {
    isLoading,
    isPatientsOtherScriptsOn,
    submitButtonProps,
    secondaryButtonProps,
    hasFamily,
    users,
    ButtonWrapperComponent,
    showLearnMoreLink = true,
  }: Props,
  ref,
) {
  const [isDirty, setIsDirty] = useState(false);
  const formikRef = useRef(null);
  const { text: submitButtonText, onClick: onSubmit } = submitButtonProps || {};
  const { text: secondaryButtonText, onClick: onSecondaryButtonClick } = secondaryButtonProps || {
    onClick: () => undefined,
  };
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const userID = useSelectorShared(getCurrentUserID);

  const handleFormikSubmit = () => {
    // @ts-expect-error TS(2339): Property 'handleSubmit' does not exist on type 'never'.
    if (formikRef.current) formikRef.current.handleSubmit();
  };

  useImperativeHandle(
    ref,
    () => ({
      handleFormikSubmit,
    }),
    [],
  );

  const handleClick = () => {
    setActiveActionSheet(<TransferLearnMoreActionSheet />);
  };

  const initialValues: TransferFormFields = {
    selected_user_id: userID,
    medications: isPatientsOtherScriptsOn ? formatAsyncSelectInitialValue(users[0].other_scripts_list) : [],
    transfer_all: false,
    hasFamily,
    pharmacy: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnBlur={isDirty}
      validateOnChange={isDirty}
      onSubmit={(values) => {
        onSubmit(formatPayload(values));
      }}
      innerRef={formikRef}
    >
      {(formikProps: FormikProps<any>) => {
        const { handleSubmit, submitCount } = formikProps;
        const hasSubmittedOnce = submitCount > 0;

        return (
          <>
            {showLearnMoreLink ? (
              <>
                <DSLink onPress={handleClick}>Learn more about how transfers work</DSLink>
                <MdSpacing />
              </>
            ) : null}
            <FormikTransferFormFields formikProps={formikProps} />
            {!!ButtonWrapperComponent && (
              <>
                <MdSpacing />
                <ButtonWrapperComponent
                  horizontallyAlignContent
                  spacing={spacing.xsSpacing}
                >
                  <Button
                    disabled={isLoading}
                    kind="secondary"
                    onClick={onSecondaryButtonClick}
                  >
                    {secondaryButtonText}
                  </Button>
                  <LoadingButton
                    loading={(hasSubmittedOnce && isLoading) ?? false}
                    onClick={() => {
                      setIsDirty(true);
                      handleSubmit();
                    }}
                  >
                    {submitButtonText}
                  </LoadingButton>
                </ButtonWrapperComponent>
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
});

export default TransferForm;
