// @owners { team: patients-team }
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import { memoize } from 'lodash';
import { transformCartPriceType } from '~shared/features/cart/selectors/getReduxCart';
import { type PrescriptionHashWithUpdatedAt } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { type OrderPricingWithUpdatedAt } from '~shared/features/pricing/selectors/getPricing';
import {
  type ItemPriceTotal,
  getItemPricingTotal,
} from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { type Prescription } from '~shared/types/clients';

type CartDetails = {
  cartItems: CartItem[];
  prescriptionsHash: PrescriptionHashWithUpdatedAt;
  orderPricing: OrderPricingWithUpdatedAt;
  cartUpdatedAt: number;
};

/**
 * This function is used to sync redux data with react-query cart data.
 * lodash.memoize is the caching layer between redux and react-query.
 * All the expensive data transformations are memoized here.
 * The memoization cache is cleared whenever items in the cart are updated.
 */
export const getCartDetails = memoize(
  ({ cartItems, prescriptionsHash, orderPricing }: CartDetails) => {
    const cartPrescriptionIds: number[] = [];
    const cartPrescriptionIdHash: Record<number, Prescription> = {};
    const cartPricingTotals: Record<number, ItemPriceTotal> = {};
    const cartCompounds: Prescription[] = [];
    const cartInjectableCompounds: CartItem[] = [];
    const cartInjectable: CartItem[] = [];
    const cartPrescriptions: Prescription[] = [];

    cartItems.forEach((cartItem) => {
      if (cartItem.resource_type !== 'Prescription') return;
      const prescription = prescriptionsHash.prescriptions[cartItem.resource_id];
      if (!prescription) return;
      cartPrescriptions.push(prescription);
      cartPrescriptionIds.push(prescription.id);
      cartPrescriptionIdHash[prescription.id] = prescription;
      const pricing = orderPricing.item_pricing[prescription.id];
      if (pricing) {
        const paymentTotal = getItemPricingTotal(pricing, transformCartPriceType(cartItem.selected_price_type));
        cartPricingTotals[prescription.id] = paymentTotal;
      }
      if (prescription.injectable) {
        cartInjectable.push(cartItem);
      }
      if (prescription.is_compound) {
        cartCompounds.push(prescription);
      }
      if (prescription.injectable && prescription.is_compound) {
        cartInjectableCompounds.push(cartItem);
      }
    });

    return {
      cartPrescriptions,
      cartPrescriptionIds,
      cartCompounds,
      cartPrescriptionIdHash,
      cartInjectableCompounds,
      cartInjectable,
      cartPricingTotals,
    };
  },
  ({ cartUpdatedAt, orderPricing, prescriptionsHash }) =>
    `${cartUpdatedAt}-${orderPricing.updatedAt}-${prescriptionsHash.updatedAt}`,
);
