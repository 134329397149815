import { createSelector } from 'reselect';
import { getCurrentUserID, getUsers } from './getUsers';
import { type ReduxStateShared, type User } from '~shared/types';

export const getCurrentUser: (state: ReduxStateShared) => User | undefined = createSelector(
  getUsers,
  getCurrentUserID,
  (users: User[], userID: number | null | undefined): User | undefined => {
    return (users || []).find((u) => u.id === userID);
  },
);

export const getCurrentUserUuid: (state: ReduxStateShared) => string | undefined = createSelector(
  [getCurrentUser],
  (user: User | null | undefined) => user?.uuid,
);

export const getCurrentUserEmail: (state: ReduxStateShared) => string | null | undefined = createSelector(
  [getCurrentUser],
  (user: User | null | undefined) => user?.email,
);

export const getCurrentUserIsEmployee: (state: ReduxStateShared) => boolean = createSelector(
  [getCurrentUser],
  (user: User | null | undefined) => !!user?.email?.endsWith('@alto.com'),
);

export const getCurrentUserFirstName: (state: ReduxStateShared) => string | null | undefined = createSelector(
  [getCurrentUser],
  (user: User | null | undefined) => user?.first_name,
);
