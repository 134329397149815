// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  Column,
  Description,
  H3,
  InlineAlert,
  LgPadding,
  ListDescription,
  Row,
  SmPadding,
  SmSpacing,
  Subway,
  SubwayStation,
} from '@alto/design-system';
import { type PrescriptionStatus } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import {
  TimelineEndpoint,
  type TimelineEndpointFetchByPrescriptionResponse,
} from '@alto/scriptdash/alto/prescriptions/patient_app/v3/timeline_endpoint';
import {
  type PrescriptionTimelineStatus,
  StatusTypeMap,
} from '@alto/scriptdash/alto/prescriptions/types/v3/prescription_timeline_status';
import React, { useEffect } from 'react';
import { apiEndpointHandler } from '~shared/helpers/api';
import { formatDollars } from '~shared/helpers/currency';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useQuery } from '~shared/react-query';
import { type APIError } from '~shared/types';

const prescriptionTimelineEndpoint = TimelineEndpoint(apiEndpointHandler);

type Props = {
  readonly prescriptionId: number | undefined;
  readonly estimatedPrice?: number | null | undefined;
  readonly origin: 'NewPrescriptions' | 'MedListActive' | 'MedDetails';
  readonly prescriptionStatus: PrescriptionStatus | undefined;
  readonly prescriptionName: string;
};

export const PrescriptionStatusTracker = ({
  prescriptionId,
  estimatedPrice,
  origin,
  prescriptionStatus,
  prescriptionName,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const params = { prescription_id: prescriptionId };
  const { data, isLoading, error } = useQuery<TimelineEndpointFetchByPrescriptionResponse, APIError>({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @alto/prefer-query-key-factory
    queryKey: ['prescription-timeline', params],
    queryFn: () => prescriptionTimelineEndpoint.fetchByPrescription(params),
    enabled: prescriptionId !== null,
  });

  useEffect(() => {
    trackEvent({
      event: EVENTS.PRESCRIPTION_TRACKER_MODAL_VIEWED,
      params: {
        prescriptionStatus,
        origin,
        estimatedPrice,
        useBackendDrivenRxTracker: true,
      },
      additionalFields: { prescriptionId },
    });
  }, [trackEvent, prescriptionStatus, origin, prescriptionId, estimatedPrice]);

  const subwayStations = data?.data?.statuses;
  const activeStation = data?.data?.active_status;

  const getStationDescription = (station: PrescriptionTimelineStatus) => {
    if (station.status_type !== StatusTypeMap.ACTIVE) {
      return [];
    }

    const stationDescriptions = station.descriptions.map((description) => (
      <ListDescription key={description}>{description}</ListDescription>
    ));

    // Special case for estimated price
    if (estimatedPrice !== null && estimatedPrice !== undefined) {
      stationDescriptions.unshift(
        <ListDescription key="Estimated price">{`Estimated price ${formatDollars(estimatedPrice)}`}</ListDescription>,
      );
    }

    return stationDescriptions;
  };

  const loadingContent = (
    <Row centerVertically>
      <H3>Getting prescription status...</H3>
      <SmSpacing />
      <ListDescription loading />
    </Row>
  );

  const errorContent = (
    <InlineAlert type="warning">
      <H3>There was an error</H3>
      <Description>Please try again. If this problem continues, please send us a message.</Description>
    </InlineAlert>
  );

  const loadedContent = (
    <Column
      left
      flexShrink={1}
      flexGrow={1}
    >
      <SmPadding
        leftPadding={SPACING.STATIC.NONE}
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Subway
          activeStation={activeStation}
          verticalEnabled="always"
        >
          {subwayStations?.map((station) => (
            <SubwayStation
              key={station.title}
              id={station.title}
              title={station.title}
              descriptions={getStationDescription(station)}
            />
          ))}
        </Subway>
      </SmPadding>
    </Column>
  );

  return (
    <ActionSheetV2
      title={prescriptionName}
      analyticsName="prescription status tracker"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {isLoading ? loadingContent : null}
        {error ? errorContent : null}
        {!isLoading && !error && loadedContent}
      </LgPadding>
    </ActionSheetV2>
  );
};
