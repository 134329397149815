// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, LgPadding } from '@alto/design-system';
import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { getOrderPricing, getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { useTrackPriceBreakdownViewed } from '../hooks/useTrackPriceBreakdownViewed';
import { PaymentBreakdown, type PricingBreakdownPrescription } from './PaymentBreakdown';

type Props = {
  readonly origin: string;
  readonly prescriptions: Prescription[] | PricingBreakdownPrescription[];
  readonly forSinglePrescription?: boolean;
  readonly forShipment?: boolean;
};

export const PricingBreakdownActionSheet = ({
  forSinglePrescription = false,
  origin,
  prescriptions,
  forShipment = false,
}: Props) => {
  const orderPricing = useSelectorShared(getOrderPricing);
  const shipmentPricing = useSelectorShared(getShipmentPricing);
  const pricing = forShipment ? shipmentPricing : orderPricing;
  // key to include in analytics event to enable querying 'item viewed' events for given 'payment breakdown viewed' event
  const eventUuid = useMemo(uuid, [prescriptions]);

  useTrackPriceBreakdownViewed({
    lineItems: pricing?.line_items,
    numberOfRxs: prescriptions.length,
    origin,
    breakdownKey: eventUuid,
  });

  return (
    <ActionSheetV2 analyticsName="pricing breakdown">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <PaymentBreakdown
          orderPricing={pricing}
          prescriptions={prescriptions}
          forSinglePrescription={forSinglePrescription}
          origin={origin}
          eventUuid={eventUuid}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
