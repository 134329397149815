// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getBiometrics } from './getBiometrics';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

export type CurrentUserBiometricState = {
  available: boolean;
  supportedType: string | undefined;
  devicePrompted: boolean;
  enabled: boolean;
  prompted: boolean;
};

export const getBiometricsForCurrentUser: (state: ReduxStateShared) => CurrentUserBiometricState = createSelector(
  [getBiometrics, getCurrentUserID],
  ({ available, supportedType, devicePrompted, users }, currentUserID) => {
    const currentUser = users.length && users.find((user) => user.userID === currentUserID);
    return {
      available,
      supportedType,
      devicePrompted,
      enabled: currentUser ? currentUser.enabled : false,
      prompted: currentUser ? currentUser.prompted : false,
    };
  },
);

const getUserID: (state: ReduxStateShared, arg1: UserIDParam) => number | null | undefined = (_, props) => props.userID;

export const getShouldUpgradeSessionForUserID: (state: ReduxStateShared, arg1: UserIDParam) => boolean = createSelector(
  [getBiometrics, getUserID],
  ({ users, available }, userID) => {
    const user = users.length && users.find((user) => user.userID === userID);

    if (!user) {
      return false;
    }

    return available && user.enabled;
  },
);
