import { SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { Platform, type StyleProp, View, type ViewProps, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';

type FlexProps = {
  /**
   * Gap defines the spacing between the children of the flex container.
   * The value is applied to the main axis, and can only be the value of our spacing tokens.
   * https://reactnative.dev/docs/layout-props#gap
   */
  readonly gap?: keyof typeof SPACING.STATIC;
  readonly flexGrow?: number;
  readonly flexShrink?: number;
  readonly flexBasis?: string;
  readonly wrap?: boolean;
  readonly wrapReverse?: boolean;
  readonly center?: boolean;
  readonly centerVertically?: boolean;
  readonly top?: boolean;
  readonly bottom?: boolean;
  readonly spaceBetween?: boolean;
  readonly spaceAround?: boolean;
  readonly spaceEvenly?: boolean;
  readonly left?: boolean;
  readonly right?: boolean;
  readonly centerHorizontally?: boolean;
  readonly stretchItems?: boolean;
  readonly baseline?: boolean;
  readonly testID?: string;
  readonly children: React.ReactNode;
  readonly style?: StyleProp<ViewStyle>;
  backgroundColor?: string;
} & ViewProps;

export type ColumnProps = FlexProps & {
  // align-content
  readonly columnStart?: boolean;
  readonly columnEnd?: boolean;
  readonly columnCenter?: boolean;
  readonly columnStretch?: boolean;
  readonly columnSpaceBetween?: boolean;
  readonly columnSpaceAround?: boolean;
  readonly backgroundColor?: string;
};

export type RowProps = FlexProps & {
  // align-content
  readonly rowStart?: boolean;
  readonly rowEnd?: boolean;
  readonly rowCenter?: boolean;
  readonly rowStretch?: boolean;
  readonly rowSpaceBetween?: boolean;
  readonly rowSpaceAround?: boolean;
  readonly backgroundColor?: string;
};

type FlexWrap = 'wrap' | 'wrap-reverse' | 'nowrap';

const getFlexWrap = (wrap: boolean | undefined, wrapReverse: boolean | undefined): FlexWrap => {
  if (wrapReverse) {
    return 'wrap-reverse';
  }

  if (wrap) {
    return 'wrap';
  }

  return 'nowrap';
};

type JustifyContent = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | '';

const getJustifyContent = (
  center: boolean | undefined,
  centerInFlexDirection: boolean | undefined,
  justifyFlexStart: boolean | undefined,
  justifyFlexEnd: boolean | undefined,
  spaceBetween: boolean | undefined,
  spaceAround: boolean | undefined,
  spaceEvenly: boolean | undefined,
): JustifyContent => {
  if (center || centerInFlexDirection) {
    return 'center';
  }

  if (justifyFlexStart) {
    return 'flex-start';
  }

  if (justifyFlexEnd) {
    return 'flex-end';
  }

  if (spaceBetween) {
    return 'space-between';
  }

  if (spaceAround) {
    return 'space-around';
  }

  if (spaceEvenly) {
    return 'space-evenly';
  }

  return '';
};

type AlignItems = 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline' | '';

const getAlignItems = (
  center: boolean | undefined,
  centerInFlexDirection: boolean | undefined,
  alignFlexStart: boolean | undefined,
  alignFlexEnd: boolean | undefined,
  stretchItems: boolean | undefined,
  baseline: boolean | undefined,
): AlignItems => {
  if (center || centerInFlexDirection) {
    return 'center';
  }

  if (alignFlexStart) {
    return 'flex-start';
  }

  if (alignFlexEnd) {
    return 'flex-end';
  }

  if (stretchItems) {
    return 'stretch';
  }

  if (baseline) {
    return 'baseline';
  }

  return '';
};

type AlignContent =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | '';

const getAlignContent = (
  center: boolean | undefined,
  start: boolean | undefined,
  end: boolean | undefined,
  spaceBetween: boolean | undefined,
  spaceAround: boolean | undefined,
  stretch: boolean | undefined,
): AlignContent => {
  if (center) {
    return 'center';
  }

  if (start) {
    return 'flex-start';
  }

  if (end) {
    return 'flex-end';
  }

  if (spaceBetween) {
    return 'space-between';
  }

  if (spaceAround) {
    return 'space-around';
  }

  if (stretch) {
    return 'stretch';
  }

  if (Platform.OS === 'web') {
    return 'stretch';
  }

  return '';
};

type StyledFlexProps = {
  flexDirection: 'row' | 'column';
  flexWrap: FlexWrap;
  flexJustifyContent: JustifyContent;
  flexAlignContent: AlignContent;
  flexAlignItems: AlignItems;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  backgroundColor?: string;
  gap?: keyof typeof SPACING.STATIC;
} & ViewProps;

const StyledFlex = styled(View)<StyledFlexProps>`
  ${({ gap }) => (gap ? `gap: ${SPACING.STATIC[gap].px};` : '')}
  ${({ flexGrow }) => (flexGrow ? `flex-grow: ${flexGrow};` : '')}
  ${({ flexShrink }) => (flexShrink ? `flex-shrink: ${flexShrink};` : '')}
  ${({ flexBasis }) => (flexBasis ? `flex-basis: ${flexBasis};` : '')}
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : '')}
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: ${flexWrap};` : '')}
  ${({ flexJustifyContent }) => (flexJustifyContent ? `justify-content: ${flexJustifyContent};` : '')}
  ${({ flexAlignContent }) => (flexAlignContent ? `align-content: ${flexAlignContent};` : '')}
  ${({ flexAlignItems }) => (flexAlignItems ? `align-items: ${flexAlignItems};` : '')}
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
`;

export const Row = ({
  gap,
  flexGrow,
  flexShrink = Platform.OS === 'web' ? 1 : 0,
  flexBasis,
  wrap = Platform.OS === 'web',
  wrapReverse,
  center,
  centerVertically,
  top,
  bottom,
  spaceBetween,
  spaceAround,
  spaceEvenly,
  left,
  right,
  baseline,
  centerHorizontally,
  stretchItems,
  rowStart,
  rowEnd,
  rowCenter,
  rowStretch,
  rowSpaceBetween,
  rowSpaceAround,
  backgroundColor,
  testID,
  children,
  style,
  ...props
}: RowProps) => {
  const flexWrap = getFlexWrap(wrap, wrapReverse);
  const flexJustifyContent = getJustifyContent(
    center,
    centerHorizontally,
    left,
    right,
    spaceBetween,
    spaceAround,
    spaceEvenly,
  );
  const flexAlignItems = getAlignItems(center, centerVertically, top, bottom, stretchItems, baseline);
  const flexAlignContent = getAlignContent(rowCenter, rowStart, rowEnd, rowSpaceBetween, rowSpaceAround, rowStretch);

  return (
    <StyledFlex
      gap={gap}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      flexBasis={flexBasis}
      flexDirection="row"
      flexWrap={flexWrap}
      flexJustifyContent={flexJustifyContent}
      flexAlignItems={flexAlignItems}
      flexAlignContent={flexAlignContent}
      backgroundColor={backgroundColor}
      testID={testID}
      style={style}
      {...props}
    >
      {children}
    </StyledFlex>
  );
};

export const Column = ({
  gap,
  flexGrow,
  flexShrink = Platform.OS === 'web' ? 1 : 0,
  flexBasis,
  wrap = Platform.OS === 'web',
  wrapReverse,
  center,
  centerVertically,
  top,
  bottom,
  spaceBetween,
  spaceAround,
  spaceEvenly,
  left,
  right,
  baseline,
  centerHorizontally,
  stretchItems,
  columnStart,
  columnEnd,
  columnCenter,
  columnStretch,
  columnSpaceBetween,
  columnSpaceAround,
  backgroundColor,
  testID,
  children,
  style,
  ...props
}: ColumnProps) => {
  const flexWrap = getFlexWrap(wrap, wrapReverse);
  const flexJustifyContent = getJustifyContent(
    center,
    centerVertically,
    top,
    bottom,
    spaceBetween,
    spaceAround,
    spaceEvenly,
  );
  const flexAlignItems = getAlignItems(center, centerHorizontally, left, right, stretchItems, baseline);
  const flexAlignContent = getAlignContent(
    columnCenter,
    columnStart,
    columnEnd,
    columnSpaceBetween,
    columnSpaceAround,
    columnStretch,
  );

  return (
    <StyledFlex
      gap={gap}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      flexBasis={flexBasis}
      flexDirection="column"
      flexWrap={flexWrap}
      flexJustifyContent={flexJustifyContent}
      flexAlignItems={flexAlignItems}
      flexAlignContent={flexAlignContent}
      backgroundColor={backgroundColor}
      testID={testID}
      style={style}
      {...props}
    >
      {children}
    </StyledFlex>
  );
};
