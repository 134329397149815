import * as React from 'react';
import { Row } from '../../../containers';
import { XsSpacing } from '../../../separators';
import { type ListItemProps } from './ListUtils';

export const ListItemTags = ({ tags }: Required<Pick<ListItemProps, 'tags'>>) => {
  const isTagsAnArray = Array.isArray(tags);
  if (!isTagsAnArray) {
    return tags;
  }
  return (
    <Row>
      {tags.map((tag, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <XsSpacing />}
          {tag}
        </React.Fragment>
      ))}
    </Row>
  );
};
