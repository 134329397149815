// @owners { team: patients-team }
import { KeyDateTypeMap } from '@alto/operations_api/v1/types';
import { DateRestrictionReasonMap } from '@alto/scriptdash/alto/scheduling/types/v1/date_restriction_reason';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { formatRelativeDate } from '~shared/helpers/date';
import { capitalizeFirstLetter } from '~shared/helpers/string';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';

type GetMessagingParams = {
  earliestAvailableDate: string | undefined;
  earliestAvailableDateReason: string | undefined;
  excludeDateFromMessage: boolean;
  inCart: boolean;
  prescription?: Prescription;
};

const getNextAvailableDateMessaging = ({
  earliestAvailableDate,
  earliestAvailableDateReason,
  excludeDateFromMessage,
  inCart,
  prescription,
}: GetMessagingParams) => {
  if (!earliestAvailableDate) {
    if (earliestAvailableDateReason === DateRestrictionReasonMap.OUT_OF_ZONE) {
      return {
        message:
          'The selected address is outside of our service area and not eligible for delivery. Please select a different address.',
      };
    }

    return { message: '' };
  }

  const medicationName = prescription?.medication_name || 'this medication';

  switch (earliestAvailableDateReason) {
    case DateRestrictionReasonMap.COMPOUND:
      return {
        message: `${capitalizeFirstLetter(
          medicationName,
        )} is a compound, which means it’s mixed specifically for you according to your prescription and takes an extra few days to arrive.`,
      };

    case DateRestrictionReasonMap.DELIVERED_RECENTLY:
    case DateRestrictionReasonMap.REFILL_TOO_SOON:
      return {
        message: `You had ${medicationName} filled ${
          prescription?.last_delivered_at ? formatRelativeDate(prescription.last_delivered_at) : 'recently'
        }. Due to insurance restrictions on how often a prescription can be filled, the earliest we can deliver ${medicationName} is ${formatRelativeDate(
          earliestAvailableDate,
          { includeRelative: true },
        )}.`,
      };

    case DateRestrictionReasonMap.DELIVERY_UPCOMING:
      return {
        message: `You already have ${medicationName} scheduled. Due to insurance restrictions on how often a prescription can be filled, the earliest we can deliver ${medicationName} again is ${formatRelativeDate(
          earliestAvailableDate,
          { includeRelative: true },
        )}.`,
      };

    case DateRestrictionReasonMap.OUT_OF_STOCK:
      if (excludeDateFromMessage) {
        return {
          message: `This is the earliest we can deliver your order because we are temporarily out of ${medicationName}.`,
        };
      } else {
        return {
          message: `The earliest we can deliver your order is ${formatRelativeDate(earliestAvailableDate, {
            includeRelative: true,
          })} because we are temporarily out of ${medicationName}.`,
        };
      }

    case DateRestrictionReasonMap.MAIL_DELIVERY_METHOD:
      return {
        message: `The selected address is outside of our courier delivery zone, so your order will be sent by mail arriving ${formatRelativeDate(
          earliestAvailableDate,
          { includeRelative: true },
        )}.`,
      };
    case DateRestrictionReasonMap.AVAILABLE:
      return {
        message: inCart
          ? `${capitalizeFirstLetter(medicationName)} is available for delivery today!`
          : `Add ${medicationName} to your cart to schedule it for delivery!`,
      };
    case DateRestrictionReasonMap.HOLIDAY:
      return {
        message: `The pharmacy is temporarily closed for a holiday but you can still add ${medicationName} to your cart and schedule it for delivery starting ${formatRelativeDate(
          earliestAvailableDate,
          { includeRelative: true },
        )}.`,
      };

    case DateRestrictionReasonMap.EMERGENCY_CLOSURE:
    case KeyDateTypeMap.EMERGENCY_CLOSURE_PATIENT_ONLY:
      return {
        message: `The pharmacy is temporarily closed but you can still add ${medicationName} to your cart and schedule it for delivery starting ${formatRelativeDate(
          earliestAvailableDate,
          { includeRelative: true },
        )}.`,
      };

    default:
      return {
        message: `${capitalizeFirstLetter(medicationName)} is next available for delivery ${formatRelativeDate(
          earliestAvailableDate,
          { includeRelative: true },
        )}.`,
      };
  }
};

type NextAvailableDateMessagingParams = {
  prescriptionID?: number;
  excludeDateFromMessage?: boolean;
};

export const usePrescriptionNextAvailableDateMessaging = ({
  prescriptionID,
  excludeDateFromMessage = false,
}: NextAvailableDateMessagingParams) => {
  const { nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID });
  const earliestAvailableDate = nextAvailableDate?.earliest.date || '';
  const earliestAvailableDateReason = nextAvailableDate?.earliest.reason || '';
  const { isPrescriptionInCart } = usePrescriptionsInCart();
  const inCart = !!prescriptionID && isPrescriptionInCart(prescriptionID);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));

  return getNextAvailableDateMessaging({
    earliestAvailableDate,
    earliestAvailableDateReason,
    excludeDateFromMessage,
    inCart,
    prescription,
  });
};

export const useQueryNextAvailableDateMessaging = ({
  prescriptionID,
  excludeDateFromMessage = false,
}: NextAvailableDateMessagingParams) => {
  const { isLoading, nextAvailableDate } = useNextAvailableDatesForPrescriptions({ prescriptionID });
  const earliest = nextAvailableDate?.earliest;
  const { isPrescriptionInCart } = usePrescriptionsInCart();
  const inCart = !!prescriptionID && isPrescriptionInCart(prescriptionID);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));

  if (isLoading) return { message: '' };

  return getNextAvailableDateMessaging({
    earliestAvailableDate: earliest?.date,
    earliestAvailableDateReason: earliest?.reason,
    excludeDateFromMessage,
    inCart,
    prescription,
  });
};
