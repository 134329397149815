// @owners { team: patients-team }
import { SECTION_LABELS } from './constants';
import { type ShipmentStatus } from '~shared/types';

export const getUpcomingOrderLabel = ({ isPickup, status }: { isPickup?: boolean; status?: ShipmentStatus }) => {
  if (!isPickup) {
    return SECTION_LABELS.UPCOMING_DELIVERY;
  }

  if (status === 'shipped') {
    return SECTION_LABELS.AWAITING_PICKUP;
  }

  return SECTION_LABELS.UPCOMING_PICKUP;
};

export const getDeliveredOrderLabel = ({ isPickup }: { isPickup?: boolean }) =>
  isPickup ? SECTION_LABELS.COMPLETED_PICKUP : SECTION_LABELS.COMPLETED_DELIVERY;
