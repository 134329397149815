export function validateGreaterThan<T>(
  min: number,
  message?: (value: T, min: number) => string,
): (value: T) => string | null | undefined {
  return (value: T): string | null | undefined => {
    if (!value) {
      return undefined;
    }

    if ((Array.isArray(value) || typeof value === 'string') && value.length > min) {
      return undefined;
    }

    return message ? message(value, min) : `Must be greater than ${min}`;
  };
}
