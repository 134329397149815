// @owners { team: patients-team }
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchServiceAvailability, updateTempSignupUser } from '~shared/actions/auth';
import { connect } from '~shared/store';
import ServiceAreaForm from './ServiceAreaForm';

// @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => {
  return {
    // @ts-expect-error TS(7006): Parameter 'values' implicitly has an 'any' type.
    onSubmit: (values) => {
      const url = new URL(window.location.href);

      // Preserve the existing query params
      const params = new URLSearchParams(url.search);
      params.set('zip', values.zip);
      const destination = `${url.pathname}?${params.toString()}`;

      const referrerID = params.get('referred_by_id');

      if (referrerID) {
        dispatch(
          updateTempSignupUser({
            referred_by_id: Number(referrerID),
          }),
        );
      }

      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchServiceAvailability(values.zip));
      dispatch(push(destination));
    },
  };
};

export default connect(null, mapDispatchToProps)(ServiceAreaForm);
