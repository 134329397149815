import { getPlatformOS } from '~shared/helpers/getPlatformOS';

// staging on web is actually a sandbox, sandbox name is not available so check current url
const isWebStaging = (backendEnv: string) => {
  return getPlatformOS() === 'web' && backendEnv === 'sandbox' && window.location?.origin.includes('app.stg.alto.com');
};

export const getAuth0Domain = (backendEnv: string) => {
  if (isWebStaging(backendEnv)) {
    return 'login.stg.alto.com';
  }
  switch (backendEnv) {
    case 'staging':
      return 'login.stg.alto.com';
    case 'production':
      return 'login.prod.alto.com';
    default:
      return 'login.dev.alto.com';
  }
};

export const getAuth0ClientID = (backendEnv: string, ids: Record<string, string>) => {
  if (isWebStaging(backendEnv)) {
    return ids.stg;
  }
  switch (backendEnv) {
    case 'staging':
      return ids.stg;
    case 'production':
      return ids.prod;
    default:
      return ids.dev;
  }
};
