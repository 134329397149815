export const BIOMETRIC_SESSION_EXPIRY = '@user:biometricSessionExpiry';
export const APPSTATE_FOREGROUND = 'active';
export const APPSTATE_TRANSITIONING = 'inactive';
export const CAMERA_PERMISSIONS_ERROR = 'Camera permissions not granted';

export const PRESCRIPTION_STATES = {
  ACTIVE_RENEWED: 'active renewed',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DISCONTINUED: 'discontinued',
  EXPIRED: 'expired',
  NO_REFILLS: 'no refills',
  RENEWED: 'renewed',
  TOO_EARLY: 'too early',
  TRANSFERRED: 'transferred',
} as const;

export const GENDERS = {
  F: 'Female',
  M: 'Male',
  U: 'Other',
} as const;

export const PRONOUNS: Record<string, string> = {
  he: 'He/Him/His',
  she: 'She/Her/Hers',
  they: 'They/Them/Theirs',
} as const;

export const NO_KNOWN_ALLERGIES = 'No Known Allergies';
export const NO_KNOWN_MEDICAL_CONDITIONS = 'No Known Medical Conditions';
export const HAS_OTHER_SCRIPTS = 'Yes';
export const DOES_NOT_HAVE_OTHER_SCRIPTS = 'No';

export const CANCELATION_REASONS = {
  prefer_local_pharmacy: 'I prefer another pharmacy',
  alto_happiness: "I'm not happy with Alto's services",
  too_expensive: "It's too expensive",
  other: 'Other, please specify',
} as const;

export const APP_DOWNLOAD_UPSELL_MODAL = {
  has_replied: 'hasRepliedToGetAppModal',
  has_selected_app: 'app',
  has_selected_browser: 'browser',
} as const;

export const FIRST_FILL_REASONS = {
  condition_improved: 'My condition has improved on its own',
  side_effects_worried: "I'm worried about side effects",
  different_medication: "I'm trying a different medication",
} as const;

export const REFILL_REASONS = {
  side_effects_experienced: 'I was experiencing side effects',
  not_working: "This medication wasn't working",
  feel_better: 'I feel better',
  refill_not_ready: "I'm not ready for a refill yet",
} as const;

export type CancelationReason =
  | keyof typeof CANCELATION_REASONS
  | keyof typeof FIRST_FILL_REASONS
  | keyof typeof REFILL_REASONS
  | 'remove_medication';

export const SAFE_PLACE_OPTIONS = [
  {
    value: 'true',
    label: 'Yes, there is a safe place',
    descriptions: ['I accept liability for lost or stolen packages'],
  },
  {
    value: 'false',
    label: 'No, do not leave packages',
    descriptions: ['There is no safe place to leave packages'],
  },
];

export const SAFE_PLACES = new Set([
  'Front door',
  'Back door',
  'Side porch',
  'Garage',
  'Building reception',
  'Mailroom or property staff',
  'No preference',
]);
export const SAFE_PLACE_INSTRUCTIONS_OPTIONS = Array.from(SAFE_PLACES).map((option) => ({
  value: option,
  label: option,
}));

export const SAFE_PLACE_INSTRUCTIONS_PLACEHOLDER =
  'A safe location where your medication can be placed (e.g. behind the blue planter).';

export const DELIVERY_INSTRUCTIONS_PLACEHOLDER =
  'Please include instructions for the driver, ' +
  'like gate code or distinct identifiers to help locate your address.';

export const RELATIONSHIP_CODES = {
  SELF: '1',
  SPOUSE: '2',
  CHILD: '3',
  OTHER: '4',
  PARTNER: '8',
  NOT_APPLICABLE: '0',
} as const;

export const RELATIONSHIP_OPTIONS = [
  {
    value: RELATIONSHIP_CODES.SELF,
    label: 'Self',
    personCode: '01',
  },
  {
    value: RELATIONSHIP_CODES.SPOUSE,
    label: 'Spouse',
    personCode: '02',
  },
  {
    value: RELATIONSHIP_CODES.CHILD,
    label: 'Child',
    personCode: '03',
  },
  {
    value: RELATIONSHIP_CODES.OTHER,
    label: 'Other',
    personCode: null,
  },
  {
    value: RELATIONSHIP_CODES.PARTNER,
    label: 'Partner',
    personCode: '02',
  },
  {
    value: RELATIONSHIP_CODES.NOT_APPLICABLE,
    label: 'N/A',
    personCode: null,
  },
];

export const ADDRESS_KIND_OPTIONS = [
  {
    label: 'Single Family Home',
    value: 'Single Family Home',
  },
  {
    label: 'Multi Family Home',
    value: 'Multi Family Home',
  },
  {
    label: 'Townhouse',
    value: 'Townhouse',
  },
  {
    label: 'Apartment Complex',
    value: 'Apartment Complex',
  },
  {
    label: 'Apartment Building',
    value: 'Apartment Building',
  },
  {
    label: 'Office Building',
    value: 'Office Building',
  },
  {
    label: 'Store Front',
    value: 'Store Front',
  },
  {
    label: 'Hospital',
    value: 'Hospital',
  },
  {
    label: 'Clinic',
    value: 'Clinic',
  },
  {
    label: 'Assisted Living Facility',
    value: 'Assisted Living Facility',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const ADDRESS_FORM_FIELDS = {
  id: 'id',
  street_address_1: 'street_address_1',
  street_address_2: 'street_address_2',
  primary: 'primary',
  safe_place: 'safe_place',
  safe_place_instructions: 'safe_place_instructions',
  kind: 'kind',
  delivery_instructions: 'delivery_instructions',
  city: 'city',
  state: 'state',
  zip: 'zip',
} as const;

export const FEEDBACK_CATEGORIES = {
  scheduling: 'Scheduling or editing a delivery',
  refill: 'Requesting a refill authorization',
  deliveries: 'Viewing the status of a delivery',
  messaging: 'Sending a message',
  profile: 'Updating my personal information',
  insurance: 'Adding or editing my insurance',
  address: 'Adding or editing an address',
  payment: 'Adding or editing payment methods',
  other: 'Other',
} as const;

export const CONTACT_US_ORIGIN = {
  AutoRefillInfoActionSheet: 'AutoRefillInfoActionSheet',
  ChangeQuantityList: 'ChangeQuantityList',
  CheckoutDateSelector: 'CheckoutDateSelector',
  CheckoutPaymentSelector: 'CheckoutPaymentSelector',
  ConfirmRenewalRequestModal: 'ConfirmRenewalRequestModal',
  ContactSupportNeedHelpWithRxCard: 'ContactSupportNeedHelpWithRxCard',
  ContactUsActionSheet: 'ContactUsActionSheet',
  DatePickerActionSheet: 'DatePickerActionSheet',
  DatePickerContainer: 'DatePickerContainer',
  DeliveryDetails: 'DeliveryDetails',
  DeliveryDetailsPickup: 'DeliveryDetails_Pickup',
  DosageChangeContactUsActionSheet: 'DosageChangeContactUsActionSheet',
  ExcessSupplyContactUsActionSheet: 'ExcessSupplyContactUsActionSheet',
  LeftOverMedsContactProgynyActionSheet: 'LeftoverMedsContactProgynyActionSheet',
  FamilyMemberStaticContactScreen: 'FamilyMemberContact',
  FertilityQuantity: 'FertilityQuantity',
  Help: 'Help',
  MaximizeBenefitsActionSheet: 'MaximizeBenefitsActionSheet',
  MedicationDeliveryStatusPanel: 'MedicationDeliveryStatusPanel',
  MessageModal: 'MessageModal',
  MessagingActionSheet: 'MessagingActionSheet',
  MissingNeedlesReminder: 'MissingNeedlesReminder',
  NextAvailableDate: 'NextAvailableDate',
  NextAvailableDateModal: 'NextAvailableDateModal',
  NotCoveredByInsuranceMessages: 'NotCoveredByInsuranceMessages',
  OnboardingPersonal: 'OnboardingPersonal',
  OrderSuccess: 'OrderSuccess',
  ProfileSupport: 'ProfileSupport',
  PharmacyInfo: 'PharmacyInfo',
  QuantitiesReviewAlert: 'QuantitiesReviewAlert',
  SupportCase: 'SupportCase',
  TransferOutFaq: 'TransferOutFaq',
} as const;

export const CALL_BUTTON_ORIGIN = {
  Help: 'Help',
  IdentityVerification: 'IdentityVerification',
  LoginHelpers: 'LoginHelpers',
  MessageModal: 'MessageModal',
  NotMe: 'NotMe',
  OnboardingGeoVerify: 'OnboardingGeoVerify',
  Profile: 'Profile',
  VerificationBlocked: 'VerificationBlocked',
} as const;

export const EXPENSIVE_THRESHOLD_500 = 500;
export const EXPENSIVE_THRESHOLD_3000 = 3000;

export const PHONE = '1 (800) 874-5881';
export const PROGYNY_PHONE = '1 (833) 682-8243';
export const FAX = '1 (415) 484-7058';

export const EMAIL = 'care@alto.com';

export const ALTO_HOME_URL = 'https://alto.com';
export const PRIVACY_POLICY_URL = 'https://alto.com/legal/privacy';
export const PRIVACY_PRACTICES_URL = 'https://alto.com/legal/npp';
export const TERMS_OF_SERVICE_URL = 'https://alto.com/legal/terms-and-conditions';
export const ALTO_LOCATIONS_URL = 'https://www.alto.com/locations';
export const ALTO_FAQS_URL = 'https://www.alto.com/faqs';

export const ALTO_PATIENT_NOTICE_URL = 'https://www.alto.com/legal/patient-notices';

export const INIT = '__INIT__';

export const BILLING_ISSUES = {
  refill_too_soon: 'billing_issue_refill_too_soon',
  needs_insurance: 'billing_issue_needs_insurance',
  process_insurance_choice: 'billing_issue_process_insurance_choice',
} as const;

export const BILLING_STATUS = {
  REFILL_TOO_SOON: 'waiting_next_billable_date',
};

export const DEFAULT_ERROR_ALERT_TITLE = 'Something went wrong';
export const DEFAULT_ERROR_ALERT_MESSAGE = 'Please try again or message support if the problem persists.';
export const LOGIN_FAILURE_ERROR_MESSAGE =
  "Please try again. If you've forgotten your password, try resetting " +
  `your password through the 'Forgot my password' link below. Or contact Alto support at: ${PHONE}.`;
export const ACCOUNT_LOCKED_LOGIN_ERROR_MESSAGE =
  'This account has been locked because there have been too many failed login attempts. ' +
  `Please wait 1 hour and try again, or contact Alto support at: ${PHONE}.`;
export const VERIFY_FAILURE_ERROR_MESSAGE =
  'Unable to verify your info. Please try again or call us at 1 (800) 874-5881 if you need help.';
export const NETWORK_FAILURE_ERROR_MESSAGE = 'Unable to connect. Please check your internet connection and retry.';
export const UNAUTHORIZED_ERROR_MESSAGE = 'Username or password is incorrect. Please try again.';
export const UNDER_AGE_EIGHTEEN_ERROR_MESSAGE = `If you are under 18 years old, please have your parent or guardian call us at ${PHONE}.`;

export const IOS_APP_STORE_LINK = 'https://apps.apple.com/us/app/alto-pharmacy/id1251765858';
export const IOS_APP_STORE_LINK_ITMS = 'itms-apps://itunes.apple.com/us/app/apple-store/id1251765858?mt=8';
export const ANDROID_APP_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.scriptdash.altopharmacy';
export const ANDROID_APP_STORE_LINK_MARKET = 'market://details?id=com.scriptdash.altopharmacy';

export const CHECKOUT_ROUTE = 'checkout';

export const PREP_MEDICATION_GCNS = new Set(['57883', '76097', '76101', '76102', '75812']);

// alt texts for images in web/src/images and native/assets/images, keyed by file name
export const IMG_ALT_TEXTS = {
  altoLogo: 'Alto logo',
  alertIssue: 'Illustration of a warning sign with an exclamation mark',
  autoRefill: 'Illustration of a pill bottle with a repeat icon',
  bikeCourier: 'Illustration of a courier biking with a package',
  box: 'Illustration of an opened box',
  calendar: 'Illustration of a calendar',
  cashPrice: 'Illustration of an open wallet with cash',
  checklist: 'Illustration of a paper checklist',
  clock: 'Illustration of a clock',
  creditCard: 'Illustration of a credit card',
  delivery: 'Illustration of hands holding a wrapped box',
  doublePillBottle: 'Illustration of two pill bottles',
  enterInsuranceManually: 'Illustration of form',
  gift: 'Illustration of a wrapped box',
  greenCheckmark: 'Illustration of green checkmark',
  handheldMessaging: 'Illustration of a hand holding a smartphone that displays a text conversation',
  helpBubble: 'Illustration of chat bubble with question mark',
  helpUsImprove: 'Illustration of chat bubble with three dots',
  insuranceCard: 'Illustration of health insurance card',
  insuranceEnterManual: 'Illustration of hand pointing up',
  insuranceNone: 'Illustration of circle with a slash through it',
  insuranceUpload: 'Illustration of upload icon',
  medication: 'Image of the medication',
  needleMeds: 'Illustration of a medication bottle and needle',
  notification: 'Illustration of phone notification from Alto',
  deliveredDelivery: 'Image of the delivered delivery',
  orderBox: 'Illustration of an order in an open box',
  pharmacist: 'Photographic headshot of Jenny, pharmacist at Alto',
  pillBottle: 'Illustration of a pill bottle with the lid off and pills falling in from the top',
  pillBottleViewfinder: 'Illustration of a pill bottle seen through a camera viewfinder',
  prescription: 'Illustration of a prescription',
  prescriptions: 'Illustration of inhaler, cream, and pill bottle',
  priceInvestigate: 'Illustration of a magnifying glass over a dollar sign on paper',
  progynyAltoLogo: 'Progyny and Alto logo',
  receipts: 'Illustration of paper receipts',
  scheduleDelivery: 'Illustration of a calendar',
  secureMessaging: 'Illustration of a text conversation on a smartphone',
  serviceFee: 'Illustration of a person with package in hands',
  shield: 'Icon of a shield and lock',
  stethoscope: 'Illustration of a stethoscope.',
  essentialIcon: 'Illustration of a pill bottle and medications',
  telephone: 'Illustration of a telephone',
  thumbsUp: 'Illustration of a thumbs up',
  transferMeds: 'Illustration of medications',
  transferPrescription: 'Illustration of a prescription with a transfer symbol',
  topSellers: 'Illustration of top selling over the counter products',
  verifiedCheckmark: 'Illustration of a checkmark',
  wallet: 'Illustration of an open wallet with cash',
} as const;

export const phoneTypes = ['Mobile', 'Landline', 'VoIP'];

export const QUANTITY_UNIT_MAP = {
  EA: '',
  ML: 'mL',
  GM: 'g',
} as const;

export const COMMS_CHANNEL = {
  phone: 'Phone number',
  email: 'Email address',
} as const;

export const ORGANIC_ONBOARDING_STEPS = {
  HEALTH_PROFILE: 0,
  INSURANCE_FORM: 1,
  COMMUNICATION_PREFERENCES: 2,
} as const;

// These skus will receive free same-day delivery when they are dispensed from the NY facility.
export const NY_WAIVED_FEE_SKUS = [
  '00069108530',
  '00069034530',
  '00006505506',
  '00069110120',
  '00310744202',
  '00006505506',
];

// These skus (Furoscix + Irbsela) will receive free same-day delivery
export const FUROSCIX_WAIVED_FEE_SKUS = ['71767010099', '71767010001'];
export const IBSRELA_WAIVED_FEE_SKU = '73154005060';

export const SAME_DAY_DELIVERY_FEE_PILOT_FACILITY_IDS = {
  DALLAS: 10,
  NEW_YORK: 9,
  ORANGE_COUNTY: 1021,
} as const;

export const REHYDRATE = 'persist/REHYDRATE';

export const CLEAR_UPDATE_FERTILITY_CYCLE_ERROR = 'CLEAR_UPDATE_FERTILITY_CYCLE_ERROR';

export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING';

export const subscriptionsConstants = {
  general_updates: {
    category: 'General Alto updates and announcements',
    description: "Information about Alto's services, products and new offerings",
  },
  wellness_newsletters: {
    category: "Alto's health and wellness newsletter",
    description: 'Biweekly tips and health news from our pharmacists',
  },
  research_opportunities: {
    category: 'Research opportunities',
    description: 'Invitations to participate in paid user research studies',
  },
  nps_surveys: {
    category: 'Customer satisfaction surveys',
    description: "Periodic check-ins on how we're doing at delivering your medications",
  },
};

export type PaymentMethodType = 'PRE_TIP' | 'POST_TIP' | 'PRIMARY' | 'SECONDARY';

export const PAYMENT_METHOD_TYPES: Record<string, PaymentMethodType> = Object.freeze({
  PRE_TIP: 'PRE_TIP',
  POST_TIP: 'POST_TIP',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
});

export type InsuranceSource = 'med details' | 'onboarding' | 'profile' | 'add family member';

export const USER_AUTHORIZED_KEY = '@user:authorized';

export const PROGYNY_BILLING_DISCLAIMER = {
  TITLE: '* Progyny Billing',
  BODY: 'You will receive an invoice from Progyny for your medications covered by Progyny Rx that will have the appropriate member responsibility applied.',
};
