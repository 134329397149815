// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  InfoBox,
  InfoText,
  InputBlockRadio,
  InputCheckbox,
  InputRow,
  Label,
  LoadingButton,
  Paragraph,
  RadioOption,
  Text,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik, type FormikProps } from 'formik';
import React, { PureComponent } from 'react';
import {
  type CommunicationPreferencesFormFields,
  PREFERRED_COMMUNICATION_OPTIONS,
  SECONDARY_COMMUNICATION_PREFERENCE_OPTIONS,
  SECURE_MESSAGE,
  TEXT,
  validate,
} from '~shared/helpers/communicationPreferences';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { type PhiAuthInfo, type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import Required from '~web/components/legacy/text/Required';

type ContainerProps = {
  readonly userError: APIError | null | undefined;
  readonly updateUserLoading?: boolean;
  readonly goBack: () => void;
  readonly onSubmit: (arg0: CommunicationPreferencesFormFields) => void;
  readonly user: User | null | undefined;
  readonly user_phi_data: PhiAuthInfo | null | undefined;
};

export type CommunicationPreferencesFormOwnProps = {
  readonly isOnboarding?: boolean;
};

type Props = ContainerProps & CommunicationPreferencesFormOwnProps;

export const showNotificationType = (preferredCommunication: string) => preferredCommunication === SECURE_MESSAGE;

class CommunicationPreferencesForm extends PureComponent<Props> {
  static defaultProps = {
    isOnboarding: false,
    updateUserLoading: false,
    userError: null,
  };

  renderRadioOptions(
    option: {
      label: string;
      value: string;
    },
    index: number,
    key: string,
  ): React.ReactNode {
    const { value } = option;
    let { label } = option;

    if (index === 0) {
      label = `${option.label} (recommended)`;
    }

    return (
      <RadioOption
        name={label}
        value={value}
        key={`${key}-${value}`}
      >
        {label}
      </RadioOption>
    );
  }

  renderCheckbox(fieldName: string, label: string): React.ReactNode {
    return (
      <Field name={fieldName}>
        {({ field, meta }: FieldProps<string>) => {
          return (
            <InputCheckbox
              input={field}
              {...mapFormikFieldToInputProps(field, meta)}
            >
              {label}
            </InputCheckbox>
          );
        }}
      </Field>
    );
  }

  render() {
    const { goBack, isOnboarding, userError, updateUserLoading, onSubmit, user, user_phi_data } = this.props;

    const cancelText = isOnboarding ? 'Back' : 'Cancel';
    const submitText = isOnboarding ? 'Complete Profile' : 'Update';
    const mainText = isOnboarding
      ? 'We’ll send you medication and delivery updates with secure in-app messages.'
      : 'How would you like Alto to contact you about your prescriptions?';
    const initialValues: CommunicationPreferencesFormFields = {
      preferred_communication: isOnboarding ? SECURE_MESSAGE : user?.preferred_communication || SECURE_MESSAGE,
      secondary_communication_preference: user?.secondary_communication_preference || TEXT,
      phi_auth_data: {
        sms_authorized: isOnboarding ? true : user_phi_data?.sms_authorized,
        email_authorized: isOnboarding ? true : user_phi_data?.email_authorized,
        push_authorized: isOnboarding ? true : user_phi_data?.push_authorized,
      },
    };

    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ values }: FormikProps<CommunicationPreferencesFormFields>) => {
          return (
            <Form noValidate>
              <DeprecatedModalBody>
                <InputRow>
                  <Text bold={isOnboarding}>{mainText}</Text>
                </InputRow>
                {userError ? (
                  <InfoBox warning>
                    <Paragraph>{userError.message}</Paragraph>
                  </InfoBox>
                ) : null}
                {!isOnboarding ? (
                  <>
                    <Label>
                      Preferred Communication
                      <Required>*</Required>
                    </Label>
                    <InputRow>
                      <Field name="preferred_communication">
                        {({ field, meta }: FieldProps<string>) => {
                          return (
                            <InputBlockRadio
                              required
                              id="preferred_communication"
                              input={field}
                              {...mapFormikFieldToInputProps(field, meta)}
                            >
                              {PREFERRED_COMMUNICATION_OPTIONS.map((option, i) => {
                                return this.renderRadioOptions(option, i, 'communicationPreference');
                              })}
                            </InputBlockRadio>
                          );
                        }}
                      </Field>
                    </InputRow>
                  </>
                ) : null}
                {values.preferred_communication && showNotificationType(values.preferred_communication) ? (
                  <>
                    <Label>
                      Notification Type
                      <Required>*</Required>
                    </Label>
                    <br />
                    <InfoText>We’ll also notify you with a push notification and either text or email.</InfoText>
                    <InputRow>
                      <Field name="secondary_communication_preference">
                        {({ field, meta }: FieldProps<string>) => (
                          <InputBlockRadio
                            required
                            input={field}
                            {...mapFormikFieldToInputProps(field, meta)}
                          >
                            {SECONDARY_COMMUNICATION_PREFERENCE_OPTIONS.map((option, i) => {
                              return this.renderRadioOptions(option, i, 'secondaryCommunicationPreference');
                            })}
                          </InputBlockRadio>
                        )}
                      </Field>
                    </InputRow>
                  </>
                ) : null}
                <Label>Can we include personal health info in messages we send you?</Label>
                <br />
                <InfoText>
                  This allows our team to provide more detail when we communicate with you about your medication or
                  orders.
                </InfoText>
                <br />
                <InputRow>{this.renderCheckbox('phi_auth_data.sms_authorized', 'Include in texts/SMS')}</InputRow>
                <InputRow>
                  {this.renderCheckbox('phi_auth_data.push_authorized', 'Include in push notifications')}
                </InputRow>
                <InputRow>{this.renderCheckbox('phi_auth_data.email_authorized', 'Include in emails')}</InputRow>
              </DeprecatedModalBody>
              <DeprecatedModalFooter>
                <Button
                  kind="tertiary"
                  onClick={goBack}
                >
                  {cancelText}
                </Button>
                <LoadingButton
                  kind="primary"
                  type="submit"
                  loading={updateUserLoading}
                >
                  {submitText}
                </LoadingButton>
              </DeprecatedModalFooter>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default CommunicationPreferencesForm;
