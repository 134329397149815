// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  FlexRow,
  InputBlockPassword,
  InputRow,
  LoadingButton,
  Paragraph,
  Text,
  createValidator,
  spacing,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { getErrorMessage } from '~shared/helpers/helper';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { type APIError } from '~shared/types/APIError';

type Values = {
  password: string;
};
type Props = {
  readonly onSubmit: (v: Values) => Promise<void>;
  readonly onCancel: () => void;
  readonly error: APIError | null | undefined;
};

export default function ReauthModal({ onSubmit, onCancel, error }: Props) {
  const validate = createValidator({
    password: validatePresentWithMessage('Password'),
  });

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <DeprecatedModalBody>
            <FlexRow spacing={spacing.smSpacing}>
              <Paragraph>To edit this information, please enter your password.</Paragraph>

              <Field
                type="password"
                name="password"
              >
                {/* @ts-expect-error TS(7031): Binding element 'field' implicitly has an 'any' type. */}
                {({ field, meta }) => (
                  <InputRow>
                    <InputBlockPassword
                      label="Password"
                      placeholder="Enter password"
                      id={field.name}
                      {...mapFormikFieldToInputProps(field, meta)}
                    />
                  </InputRow>
                )}
              </Field>
              {error ? (
                <Text
                  danger
                  small
                >
                  {getErrorMessage(error)}
                </Text>
              ) : null}
            </FlexRow>
          </DeprecatedModalBody>
          <DeprecatedModalFooter>
            <Button
              kind="tertiary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              kind="primary"
              type="submit"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </DeprecatedModalFooter>
        </Form>
      )}
    </Formik>
  );
}
