// @owners { team: patients-team }
import { Body, InputText, LgSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { type FormikErrors, type FormikHandlers } from 'formik';
import React from 'react';
import { type VacationSupplyFormValues } from '~shared/features/vacation-supply/helpers';
import { DATE_FORMATS, formatDateInput } from '~shared/helpers/date';

type VacationSupplyFormProps = {
  readonly description?: string;
  readonly handleChange: FormikHandlers['handleChange'];
  readonly values: VacationSupplyFormValues;
  readonly errors: FormikErrors<VacationSupplyFormValues>;
};

export const VacationSupplyForm = ({ description, values, errors, handleChange }: VacationSupplyFormProps) => {
  return (
    <form method="get">
      <InputText
        autoComplete="off"
        name="travelDestination"
        label="Travel destination"
        id="travelDestination"
        required
        value={values.travelDestination}
        onChange={handleChange}
        error={errors.travelDestination}
        placeholder="City, Country"
        leftIconProps={{ name: 'mapmarker-small' }}
      />
      <LgSpacing />
      <InputText
        autoComplete="off"
        name="startDate"
        label="Start date"
        id="startDate"
        keyboardType="number-pad"
        required
        value={values?.startDate}
        onChange={(event: any) => {
          event.target.value = formatDateInput(event.target.value);
          handleChange(event);
        }}
        error={errors.startDate}
        placeholder={DATE_FORMATS.DATE_INPUT_PLACEHOLDER}
      />
      <LgSpacing />
      <InputText
        autoComplete="off"
        name="returnDate"
        label="Return date"
        id="returnDate"
        keyboardType="number-pad"
        required
        value={values?.returnDate}
        onChange={(event: any) => {
          event.target.value = formatDateInput(event.target.value);
          handleChange(event);
        }}
        error={errors.returnDate}
        placeholder={DATE_FORMATS.DATE_INPUT_PLACEHOLDER}
      />
      {description ? (
        <>
          <LgSpacing />
          <Body>{description}</Body>
        </>
      ) : null}
    </form>
  );
};
