// @owners { team: patients-team }
import {
  Button,
  Column,
  Description,
  H2,
  H3,
  LgSpacing,
  MdSpacing,
  SmSpacing,
  Toast,
  ToastContext,
  XxxlPadding,
} from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { InputBlockTextArea, Modal, ModalContent, ModalFooter } from '@alto/pocky';
import React, { useEffect, useMemo } from 'react';
import { useEssentialsSearch } from '~shared/features/essentials';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { Image } from '~web/features/essentials/primitives';

type Props = {
  readonly queryString?: string;
  readonly origin?: string;
  readonly facilityId?: number;
  readonly shipmentId?: number | null;
};

export const NoSearchResults = ({ queryString, origin, facilityId, shipmentId }: Props) => {
  const { addToast } = React.useContext(ToastContext);
  const { description, title } = useEssentialsSearch({});
  const [showModal, setShowModal] = React.useState(false);
  const [feedback, setFeedback] = React.useState('');
  const { trackEvent, trackPageView } = useAnalytics();
  const params = useMemo(
    () => ({
      query_string: queryString,
      shipment_id: shipmentId,
      facility_id: facilityId,
      origin,
      feedback,
    }),
    [facilityId, feedback, origin, queryString, shipmentId],
  );

  const handleSuggestItemsPress = () => {
    trackEvent({ event: EVENTS.ESSENTIALS__SEARCH_FEEDBACK_FORM_VIEWED, params });
    setShowModal(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleFeedbackSubmit = () => {
    setShowModal(false);
    trackEvent({ event: EVENTS.ESSENTIALS__SEARCH_FEEDBACK_FORM_SUBMITTED, params });
    addToast(<Toast>Thank you for suggesting items!</Toast>);
  };

  useEffect(() => {
    trackPageView({ event: EVENTS.ESSENTIALS__EMPTY_SEARCH_RESULTS_VIEWED, params });
  }, [params, trackPageView]);

  return (
    <XxxlPadding>
      <Column center>
        <Image
          src="https://assets.prod.alto.com/otc_categories/alert.png"
          alt="error icon"
          height="100px"
          width="100px"
        />
        <LgSpacing />
        <H2>{title}</H2>
        <SmSpacing />
        <Description center>{description}</Description>
        <LgSpacing />
        <Button
          type="secondary"
          onPress={handleSuggestItemsPress}
        >
          Suggest items
        </Button>
        {showModal ? (
          <Modal
            onClose={handleModalClose}
            showDismiss
          >
            <ModalContent textAlignment="left">
              <MdSpacing />
              <H3>What item(s) would you like to see in the Essentials store (products, brands, etc.)?</H3>
              <MdSpacing />
              <InputBlockTextArea
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={handleInputChange}
                placeholder="Type feedback here"
              />
            </ModalContent>
            <ModalFooter>
              <Button
                onPress={handleFeedbackSubmit}
                disabled={!feedback.length}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </Column>
    </XxxlPadding>
  );
};
