// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
import { closeModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { clearUserError, updateUser } from '~shared/actions/users';
import { DEFAULT_ERROR_ALERT_MESSAGE, DEFAULT_ERROR_ALERT_TITLE, HAS_OTHER_SCRIPTS } from '~shared/constants';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getSelectedUser } from '~shared/features/users/selectors/getSelectedUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { formatAsyncSelectSubmitValue } from '~shared/helpers/formatAsyncSelectValues';
import { BUCKET, markExperimentExposure } from '~shared/lib/analytics/src/actions';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import MedicalInfoModal, { type Props } from './MedicalInfoModal';

const mapStateToProps = (state: ReduxStateShared) => {
  const { modal } = state;
  const errors = getErrors(state);
  const loading = getLoading(state);
  const userError = errors.fetchUsersError || errors.updateUserError;

  const user = getSelectedUser(state);
  const currentUserID = getCurrentUserID(state);
  const firstName = currentUserID !== user?.id ? (user?.preferred_first_name ?? user?.first_name ?? '') : '';

  return {
    ...modal,
    userError,
    loading: loading.updateUserLoading,
    user,
    firstName, // first name of family member
  };
};

const mapDispatchToProps = {
  clearUserError,
  closeModal,
  // eslint-disable-next-line import/no-deprecated
  updateUser,
  markExperimentExposure,
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
): Props => {
  const { user } = stateProps;
  const {
    clearUserError: clearError,
    closeModal: close,
    updateUser: update,
    markExperimentExposure,
    ...restDispatchProps
  } = dispatchProps;
  return {
    ...stateProps,
    ...restDispatchProps,
    onClose: () => {
      clearError();

      close('MEDICAL_INFO_MODAL');
    },
    // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
    onSubmit: (data) => {
      if (!user) {
        toaster.toast({
          kind: 'danger',
          title: DEFAULT_ERROR_ALERT_TITLE,
          description: DEFAULT_ERROR_ALERT_MESSAGE,
        });

        return;
      }

      const params = {
        id: user.id,
        ...data,
      };
      params.other_scripts_list =
        data.onboarding_other_scripts !== HAS_OTHER_SCRIPTS
          ? ''
          : formatAsyncSelectSubmitValue(data.other_scripts_list);
      // @ts-expect-error TS(7031): Binding element 'success' implicitly has an 'any' type.
      // eslint-disable-next-line promise/catch-or-return
      update(params).then(({ success }) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          clearError();

          close('MEDICAL_INFO_MODAL');
        }
      });
    },
    markPatientsOtherScriptsExposure: () => {
      markExperimentExposure({
        experimentName: 'patients-other-scripts',
        origin: ORIGIN_NAMES.PROFILE,
        bucket: BUCKET.TREATMENT,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MedicalInfoModal);
