import { format, isToday, isYesterday } from 'date-fns';
import { toNumbersOnly } from './strings';
import { type DateData } from '.';

export const getRecentRelativeOrDate = (timestamp: number | Date): string => {
  if (isToday(timestamp)) {
    return 'Today';
  }

  if (isYesterday(timestamp)) {
    return 'Yesterday';
  }

  return format(timestamp, 'PPP');
};

// Ideally this would sync up with the user's time settings on their phone.
// There is currently no well-supported way to get that preference.
export const getTimeOfDay = (timestamp: number | Date): string => format(timestamp, 'p');

export const toFormattedDate = (value: string | undefined) => {
  if (!value) {
    return '';
  }

  const date = toNumbersOnly(value);
  const dateLength = date.length;
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  const year = date.slice(4, 8);

  if (dateLength === 1) {
    return month;
  }
  if (dateLength <= 3) {
    return `${month}/${day}`;
  }
  return `${month}/${day}/${year}`;
};

export const toFormattedISODate = (value: string | undefined) => {
  if (!value) {
    return '';
  }

  const date = toNumbersOnly(value);
  const dateLength = date.length;
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  const year = date.slice(4, 8);

  if (dateLength <= 2) {
    return month;
  }
  if (dateLength <= 4) {
    return `${month}-${day}`;
  }
  return `${year}-${month}-${day}`;
};

const stringToDateObject = (value: string): { year: number; month: number; day: number } => {
  const date = toNumbersOnly(value);

  if (date.length !== 8) {
    return {
      year: NaN,
      month: NaN,
      day: NaN,
    };
  }

  const year = Number(date.slice(0, 4));
  // Date is zero indexed in the month parameter
  const month = Number(date.slice(4, 6)) - 1;
  const day = Number(date.slice(6, 8));

  return {
    year,
    month,
    day,
  };
};

export const getDateData = (date: Date) => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  day: date.getDate(),
  timestamp: date.valueOf(),
  dateString: format(date, 'yyyy-MM-dd'),
});

/*
 * Receives a value in the form of "yyyy-MM-dd" and returns a date object ready to be used inside InputDatePicker or Calendar
 */
export const toDateObject = (value: string): Date => {
  const { year, month, day } = stringToDateObject(value);

  return new Date(year, month, day);
};

/*
 * Receives a value in the form of "yyyy-MM-dd" and returns a date object that is used internally in InputDatePicker or Calendar
 */
export const toDateData = (value: string): DateData => {
  const { year, month, day } = stringToDateObject(value);
  const dateObject = new Date(year, month, day);

  return {
    year,
    month,
    day,
    timestamp: dateObject.getTime(),
    dateString: dateObject.toDateString(),
  };
};

/*
 * Receives a DateData object and returns a string in format "yyyy-MM-dd"
 */
export const fromDateData = (dateData: DateData): string => {
  // Convert month and date to two-digit format
  const monthStr = dateData.month < 10 ? `0${dateData.month}` : `${dateData.month}`;
  const dayStr = dateData.day < 10 ? `0${dateData.day}` : `${dateData.day}`;

  // Concatenate year, month, and date with dashes
  return `${dateData.year}-${monthStr}-${dayStr}`;
};

// Selects the initialSelectedDate in the InputDatePicker and DatePickerActionSheet
export const getInitialSelectedDate = (initialSelectedDate?: string, selectedDate?: DateData): DateData => {
  if (initialSelectedDate) {
    return toDateData(initialSelectedDate);
  }

  if (selectedDate?.timestamp) {
    return selectedDate;
  }

  const today = new Date();

  return {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    timestamp: today.getTime(),
    dateString: today.toDateString(),
  };
};
