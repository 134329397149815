// @owners { team: patients-team }
import { MdSpacing, Separator } from '@alto/design-system';
import { type LineItem } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React from 'react';
import { parseLineItem } from '~shared/helpers/pricingBreakdown';
import { PricingInfoRow } from '../../pricing-info/components/PricingInfoRow';

type Props = {
  readonly lineItem: LineItem;
  readonly noSeparator?: boolean;
};

export const PricingBreakdownOrderLineItem = ({ lineItem, noSeparator }: Props) => {
  const { label, originalPrice, shownPrice, savings } = parseLineItem(lineItem);
  return (
    <>
      <MdSpacing />
      <PricingInfoRow
        label={label}
        originalPrice={originalPrice}
        savings={savings}
        price={shownPrice}
      />
      <MdSpacing />
      {noSeparator ? null : <Separator />}
    </>
  );
};
