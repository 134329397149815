// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React from 'react';

export const PriceIncreaseActionSheet = () => {
  return (
    <ActionSheetV2
      title="Why did my price change?"
      analyticsName="price change info"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Body>
          A new year often means changes in insurance coverage, which can result in changes to your medication price.
          This may be due to an out-of-pocket reset or other coverage change.
          {'\n\n'}
          For support regarding a change in price, contact your insurance provider directly as they will be best
          equipped to provide an explanation about your specific benefits.
          {'\n'}
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
