// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { queries } from '~shared/queries/query-keys';
import { useQuery } from '~shared/react-query';
import { useSelectorShared } from '~shared/store';
import { onboardingBackgroundColor } from '../helpers/onboardingBackgroundColor';

export const useQueryCreateAccountConfigs = () => {
  const { isLoading, value: enabled } = Experimentation.useFeatureFlag('onboarding_platformization');
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const { isFetching, isError, isSuccess, data } = useQuery({
    ...queries.onboardingConfigs.fetchCreateAccount({ params: {} }),
    enabled,
  });

  const partnershipName = data?.data?.partnership_name;
  const headerBackgroundColor = onboardingBackgroundColor(partnershipName, showProgyny);

  return {
    isFetching: isLoading || isFetching,
    isError,
    isSuccess,
    headerBackgroundColor,
    headerLogoUrl: data?.data?.header_logo_url,
  };
};
