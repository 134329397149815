// @owners { team: patients-team }
import { ActionSheetContext, FilterTag } from '@alto/design-system';
import React, { useContext } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { getAppliedMedListFiltersContent } from '~shared/selectors/ui/medList';
import { useSelectorShared } from '~shared/store';
import { FilterActionSheet } from './FilterActionSheet';

export const FilterTypeTag = () => {
  const { trackEvent } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const openActionSheet = () => {
    trackEvent({
      event: EVENTS.MED_LIST__FILTER_MEDICATIONS_VIEWED,
      params: {
        origin: ORIGIN_NAMES.MED_LIST,
      },
    });
    setActiveActionSheet(<FilterActionSheet />);
  };
  const appliedFiltersContent = useSelectorShared(getAppliedMedListFiltersContent);

  return <FilterTag onPress={openActionSheet}>{appliedFiltersContent}</FilterTag>;
};
