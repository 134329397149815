// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  H2,
  LgPadding,
  LgSpacing,
  XsSpacing,
} from '@alto/design-system';
import React, { useContext } from 'react';
import insuranceCard from '~shared/assets/images/insurance_card.png';
import { getDoctorFromMostRecentPrescriptions } from '~shared/features/prescriptions/selectors/getDoctorFromMostRecentPrescriptions';
import { getDoctorName } from '~shared/helpers/doctorName';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useSelectorShared } from '~shared/store';

const isWeb = getPlatformOS() === 'web';

export const CardFinderActionSheet = () => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const doctor = useSelectorShared((state) => getDoctorFromMostRecentPrescriptions(state));

  const handleClose = () => {
    closeActionSheet();
  };

  const buttons = isWeb
    ? [
        <Button
          key="back-to-insurance"
          label="Back to insurance"
          onPress={handleClose}
        />,
      ]
    : undefined;

  return (
    <ActionSheetV2
      title="How it works"
      handleClose={isWeb ? handleClose : undefined}
      illustrationAccessibilityLabel="Insurance card"
      illustrationSrc={insuranceCard}
      buttons={buttons}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          The information provided by {doctor ? getDoctorName(doctor) : 'your doctor'} helped us locate your insurance
          information.
        </Body>
        <LgSpacing />
        <Body>
          This information is stored securely and never shared with anyone else. Your privacy is our top priority.
        </Body>
        <LgSpacing />
        <H2>Can I change my insurance later?</H2>
        <XsSpacing />
        <Body>Absolutely! You can update your insurance information at any time in the &quot;Account&quot; tab.</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
