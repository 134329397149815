import { COLORS, SPACING } from '@alto/design-library-tokens';
import React, { useState } from 'react';
import { Image, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Border } from '../../borders';
import { Row } from '../../containers';
import { AltoIcon } from '../../icon';
import { FullScreenImageViewer } from './FullScreenImageViewer';

export type ExpandableImageProps = {
  readonly accessibilityLabel: string;
  readonly imageUrl: string;
  readonly height?: number;
  readonly onExpand?: () => void;
  readonly withThumbnailOverlay?: boolean;
};

const FullWidthImage = styled(Image)<{ height: number | string }>`
  width: 100%;
  ${({ height }) => `
    height: ${typeof height === 'string' ? height : `${height}px`};
  `}
`;

const ThumbnailImageOverlay = styled(Row)<any>`
  position: absolute;
  background-color: rgba(25, 32, 38, 0.5);
  width: 100%;
  height: 100%;
`;

const IconAnchor = styled(Row)`
  position: absolute;
  bottom: ${SPACING.STATIC.SM.px};
  right: ${SPACING.STATIC.SM.px};
`;

const noop = () => undefined;

export const ExpandableImage = ({
  accessibilityLabel,
  imageUrl,
  height = 96,
  onExpand = noop,
  withThumbnailOverlay,
}: ExpandableImageProps) => {
  const [fullScreenExpanded, setFullScreenExpanded] = useState(false);

  const onImageClick = () => {
    setFullScreenExpanded(true);
    onExpand();
  };

  const onClose = () => {
    setFullScreenExpanded(false);
  };

  return (
    <>
      <FullScreenImageViewer
        imageUrl={imageUrl}
        accessibilityLabel={accessibilityLabel}
        isVisible={fullScreenExpanded}
        onClose={onClose}
      />
      <Border
        radius="LG"
        color={COLORS.BACKGROUND_COLORS.TRANSPARENT}
      >
        <Pressable onPress={onImageClick}>
          <Row>
            <FullWidthImage
              height={height}
              resizeMethod="resize"
              resizeMode="cover"
              source={{
                uri: imageUrl,
              }}
              accessibilityLabel={accessibilityLabel}
              testID="image"
            />
            {withThumbnailOverlay ? <ThumbnailImageOverlay /> : null}
            <IconAnchor>
              <AltoIcon
                name="search"
                type="white"
              />
            </IconAnchor>
          </Row>
        </Pressable>
      </Border>
    </>
  );
};
