import { createSelector } from 'reselect';
import { getAddresses, getAllAddresses } from './getAddresses';
import { type AddressIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

export const getAddressID = (_: ReduxStateShared, props: AddressIDParam) => props.addressID;

export const getAddress = createSelector([getAddresses, getAddressID], (addresses, addressID) =>
  addresses.find((address) => address.id === addressID),
);

export const getAddressFromAllAddresses = createSelector([getAllAddresses, getAddressID], (addresses, addressID) => {
  return addresses.find((address) => address.id === addressID);
});
