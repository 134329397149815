// @owners { team: patients-team }
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';

export const DESTINATION_NAMES = Object.freeze({
  FAQ: 'faq',
  FERTILITY_VIDEOS: 'fertility-videos',
  CONTACT_SUPPORT: 'contact_support',
});

export const generateSupportViewedEvent = (origin: string, destination: string): Event =>
  createEvent(EVENTS.SUPPORT_VIEWED, {
    origin,
    destination,
  });
