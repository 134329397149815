import { createSelector } from 'reselect';
import getErrors from '~shared/features/ui/selectors/getErrors';
import { type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const getUserError: (state: ReduxStateShared) => APIError | null = createSelector(
  getErrors,
  (errors) => errors.fetchUsersError || errors.updateUserError,
);

export default getUserError;
