// @owners { team: patients-team }
import { Platform } from 'react-native';
import { createSelector } from 'reselect';
import { getCurrentUserEmail, getCurrentUserIsEmployee } from '~shared/features/users/selectors/getCurrentUser';
import { getCurrentUserLabels } from '~shared/features/users/selectors/getCurrentUserLabels';
import { getHasFamily } from '~shared/features/users/selectors/getHasFamily';
import { getIsCurrentUserFertility } from '~shared/features/users/selectors/getIsFertilityUser';
import { getIsCurrentUserProgyny } from '~shared/features/users/selectors/getIsProgynyUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import getCookie from '~shared/helpers/getCookie';
import getDeviceID from '~shared/helpers/getDeviceID';
import platform from '~shared/helpers/getPlatform';
import version from '~shared/helpers/getVersion';

const unAuthenticatedUserId = 0;
const isWeb = Platform.OS === 'web';
const appVersion = isWeb ? '' : version;

export const getAnalyticalProperties = createSelector(
  [
    getCurrentUserIsEmployee,
    getHasFamily,
    getIsCurrentUserFertility,
    getIsCurrentUserProgyny,
    getCurrentUserID,
    getCurrentUserLabels,
    getCurrentUserEmail,
  ],
  (isEmployee, isFamilyAccount, isFert, isProgyny, userId, labels, email) => {
    const deviceId = getDeviceID();
    const adminLoggedInAsPatient = !!getCookie('admin');
    return {
      // Growthbook Attributes
      // * Must * match attributes here https://growthbook.prod.alto.com/attributes
      // For name conventions, see https://www.notion.so/alto/Experimentation-Knowledge-Center-04d2ba2dcc0748fa8bfaca85a3585e49?pvs=4#6f8747a6261e48a7afbc89227d8adcd8
      PATIENT_APP_VERSION: appVersion,
      PATIENT_DEVICE_ID: deviceId,
      PATIENT_ID: userId ?? unAuthenticatedUserId,
      PATIENT_IS_EMPLOYEE: isEmployee,
      PATIENT_IS_FAMILY_ACCOUNT: isFamilyAccount,
      PATIENT_IS_FERTILITY: isFert,
      PATIENT_IS_PROGYNY: isProgyny,
      PATIENT_LABELS: labels,
      PATIENT_PLATFORM: platform,
      PATIENT_EMAIL: __DEV__ ? email : undefined, // don't include email in prod since this is PII and sent in analytics
      PATIENT_APP_ACCESS_BY_ADMIN: adminLoggedInAsPatient,
    };
  },
);
