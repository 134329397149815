// @owners { team: patients-team }
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/no-deprecated
import { updateOnboardingUser } from '~shared/actions/onboarding';
// eslint-disable-next-line import/no-deprecated
import { fetchMedicationNames } from '~shared/actions/products';
import { getFamilyMembers } from '~shared/features/family-accounts/selectors/getFamilyMembers';
import { ONBOARD_STATE_TRANSFER, TRANSFER } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { getHasFamily } from '~shared/features/users/selectors/getHasFamily';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import OnboardingTransfer, { type Props as OnboardingTransferProps } from './OnboardingTransfer';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

type Props = OnboardingTransferProps & {
  readonly nextRoute: string;
  readonly updateOnboardingUser: (arg0: any) => Promise<any>;
  readonly push: (url: string) => void;
};

const OnboardingTransferContainer = ({ nextRoute, updateOnboardingUser, push, ...otherProps }: Props) => {
  useEffect(() => {
    updateOnboardingUser({
      onboard_state: ONBOARD_STATE_TRANSFER,
    });
  }, [updateOnboardingUser]);

  const onContinue = () => {
    push(nextRoute);
  };

  return (
    <OnboardingTransfer
      {...otherProps}
      onContinue={onContinue}
    />
  );
};

const mapStateToProps = (state: ReduxStateShared) => {
  const hasFamily = getHasFamily(state);
  const users = getFamilyMembers(state);

  return {
    nextRoute: nextUrl(state, TRANSFER),
    hasFamily,
    users,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => ({
  ...bindActionCreators(
    {
      // eslint-disable-next-line import/no-deprecated
      fetchMedicationNames,
    },
    dispatch,
  ),
  // @ts-expect-error TS(7006): Parameter 'payload' implicitly has an 'any' type.
  // eslint-disable-next-line import/no-deprecated
  updateOnboardingUser: (payload) => dispatch(updateOnboardingUser(payload)),
  // @ts-expect-error TS(7006): Parameter 'nextRoute' implicitly has an 'any' type.
  push: (nextRoute) => dispatch(push(nextRoute)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTransferContainer);
