import { type AltoIconName } from '@alto/design-library-tokens';
import React, { type MutableRefObject, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Platform, type TextInput } from 'react-native';
import { useScreenSize } from '../../../../utils';
import { ActionSheetContext } from '../../../action-sheet';
import { InputText, type InputTextProps } from '../InputText/InputText';
import { InputAutocompleteActionSheet } from './InputAutocompleteActionSheet';
import { InputAutocompleteDropdown } from './InputAutocompleteDropdown';
import { summarizeEntries } from './helpers';
import { type ApiResponseRow, type DataSource } from './types';

export type SelectionTypes = 'multi' | 'single';

type Props = Pick<InputTextProps, 'placeholder' | 'accessibilityLabel'> & {
  readonly dataSource: DataSource<any>;
  readonly error?: string | null | undefined | undefined[];
  readonly leftIcon?: AltoIconName;
  readonly selectionType?: SelectionTypes;
  readonly onSelectCallback: (arg: ApiResponseRow<any>[]) => void;
  readonly initialValues?: ApiResponseRow<any>[];
  readonly shouldAllowManualInput?: boolean;
  readonly label: string;
  readonly title?: string;
  readonly required?: boolean;
  readonly onRef?: MutableRefObject<TextInput | null>;
  readonly hasDefaultOptions?: boolean;
  /**
   * Used to determine if the action sheet closes when a selection is made, default to true. One example is the
   * KaiserActionSheet, which should not close after a selection is made searching pharmacies.
   */
  readonly onSelectAutoCloseActionSheet?: boolean;
};

export const InputAutocomplete = ({
  dataSource,
  error,
  placeholder,
  leftIcon,
  selectionType = 'single',
  onSelectCallback,
  label,
  title,
  initialValues,
  accessibilityLabel = '',
  shouldAllowManualInput,
  required,
  onRef,
  hasDefaultOptions,
  onSelectAutoCloseActionSheet,
}: Props) => {
  const [text, setText] = useState(initialValues ? summarizeEntries(initialValues) : '');
  const [selectedValues, setSelectedValues] = useState<ApiResponseRow<any>[]>(initialValues || []);
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const inputTextRef = useRef<TextInput>(null);

  // Logic for desktop web
  const { isSMScreenOrBigger } = useScreenSize();
  const desktopWeb = Platform.OS === 'web' && isSMScreenOrBigger;

  const refs = useMemo(() => {
    if (onRef) {
      return [onRef, inputTextRef];
    }

    return inputTextRef;
  }, [onRef, inputTextRef]);

  const onSelect = useCallback(
    (userSelectedValues: ApiResponseRow<any>[]) => {
      onSelectCallback(userSelectedValues);
      if (selectionType === 'multi' && !desktopWeb) {
        setSelectedValues(userSelectedValues);
      }
    },
    [onSelectCallback, selectionType, desktopWeb],
  );

  const openActionSheet = () => {
    inputTextRef.current?.blur();
    setActiveActionSheet(
      <InputAutocompleteActionSheet
        dataSource={dataSource}
        placeholderText={placeholder}
        leftIcon={leftIcon}
        selectionType={selectionType}
        onSelectCallback={onSelect}
        initialValues={selectedValues || initialValues}
        accessibilityLabel={accessibilityLabel}
        shouldAllowManualInput={shouldAllowManualInput}
        setParentText={setText}
        label={title ? label : undefined}
        title={title ? title : label}
        hasDefaultOptions={hasDefaultOptions}
        onSelectAutoCloseActionSheet={onSelectAutoCloseActionSheet}
      />,
      { shouldRetainPreviousActionSheet: true },
    );
  };

  const shouldShowError = !!error;

  // For most components, we either have a web implementation or a native implementation.
  // For InputAutocomplete specifically, our mobile web implementation should be the same as native,
  // but our desktop web implementation is completely different.
  if (desktopWeb) {
    return (
      <InputAutocompleteDropdown
        initialValues={initialValues}
        label={label}
        error={shouldShowError ? String(error) : undefined}
        shouldAllowManualInput={shouldAllowManualInput}
        dataSource={dataSource}
        leftIcon={leftIcon}
        accessibilityLabel={accessibilityLabel}
        placeholder={placeholder}
        selectionType={selectionType}
        hasDefaultOptions={hasDefaultOptions}
        onSelectCallback={onSelect}
        required={required}
      />
    );
  }

  return (
    <InputText
      value={text}
      placeholder={placeholder}
      accessibilityLabel={accessibilityLabel}
      leftIconProps={{ name: leftIcon }}
      label={label}
      error={shouldShowError ? String(error) : undefined}
      onFocus={openActionSheet}
      onRef={refs}
      required={required}
    />
  );
};
