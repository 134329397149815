// @owners { team: patients-team }
export const UPDATE_ADD_TO_SHIPMENT_ERROR = 'UPDATE_ADD_TO_SHIPMENT_ERROR';

type AddToShipmentErrorParams = {
  error: string | null | undefined;
  quantityAdded: number | null | undefined;
};
export type UpdateAddToShipmentErrorAction = {
  type: typeof UPDATE_ADD_TO_SHIPMENT_ERROR;
  payload: AddToShipmentErrorParams;
  error: boolean;
};
export function updateAddToShipmentError(payload: AddToShipmentErrorParams): UpdateAddToShipmentErrorAction {
  return {
    type: UPDATE_ADD_TO_SHIPMENT_ERROR,
    payload,
    error: true,
  };
}
