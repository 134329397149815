import { API_URL } from '~shared/config';

export const getApiUrl = (...path: string[]) => {
  // Trim forward slash characters from the beginning and end of the path parts
  return [API_URL, ...path]
    .reduce<string[]>((parts, part) => {
      if (part?.trim()) {
        parts.push(part.replace(/(^\/+|\/+$)/g, ''));
      }
      return parts;
    }, [])
    .join('/');
};
