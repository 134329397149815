import { COLORS, type ColorVariant } from '@alto/design-library-tokens';
import type React from 'react';
import { type Button, type ButtonType, type ButtonVariant } from './src/button/Button';

type UtilProps = {
  type?: ButtonType;
  variant?: ButtonVariant;
  disabled?: boolean;
};
export type ButtonsGroupProps = React.ReactElement<typeof Button>[];

export const getBackgroundColor = ({ type, variant, disabled }: UtilProps) => {
  if (disabled) {
    return COLORS.BACKGROUND_COLORS.GREY_LIGHTER;
  }

  if (variant === 'danger' && type === 'primary') {
    return COLORS.PALETTE.DANGER.DEFAULT;
  }

  if (type === 'secondary') {
    return COLORS.BACKGROUND_COLORS.WHITE;
  }

  if (type === 'tertiary') {
    return COLORS.BACKGROUND_COLORS.TRANSPARENT;
  }

  return COLORS.BACKGROUND_COLORS.PRIMARY;
};

export const getBorderColor = ({ type, variant, disabled }: UtilProps) => {
  if (disabled) {
    return COLORS.PALETTE.GREYSCALE.LIGHTER;
  }

  if (variant === 'danger' && type !== 'tertiary') {
    return COLORS.BORDER_COLORS.DANGER;
  }

  if (type === 'secondary') {
    return COLORS.BORDER_COLORS.SECONDARY;
  }

  if (type === 'tertiary') {
    return COLORS.PALETTE.GREYSCALE.TRANSPARENT;
  }

  return COLORS.BORDER_COLORS.PRIMARY;
};

export const getLabelColor = ({ type, variant, disabled }: UtilProps) => {
  const isNotPrimary = type === 'secondary' || type === 'tertiary';

  if (disabled) {
    return COLORS.TEXT_COLORS.DISABLED;
  }

  if (variant === 'danger' && isNotPrimary) {
    return COLORS.TEXT_COLORS.DANGER;
  }

  if (isNotPrimary) {
    return COLORS.TEXT_COLORS.SECONDARY;
  }

  return COLORS.TEXT_COLORS.WHITE;
};

export const getAltoIconColor = ({ type, disabled }: UtilProps): ColorVariant => {
  if (disabled) {
    return 'disabled';
  }

  if (type === 'secondary') {
    return 'secondary';
  }

  return 'white';
};
