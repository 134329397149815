// @owners { team: patients-team }
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchServiceAvailability } from '~shared/actions/auth';
import { getOnboardingVerificationRequired } from '~shared/features/onboarding/selectors/getOnboardingVerification';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import SignupPage from './SignupPage';

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state: ReduxStateShared, ownProps) => {
  const verificationRequired = getOnboardingVerificationRequired(state);
  const { authorized, serviceAvailabilityChecked, serviceAvailable } = state.auth;

  return {
    authorized,
    serviceAvailabilityChecked,
    serviceAvailable,
    verificationRequired,
    zip: ownProps.location.query.zip,
  };
};

// @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => {
  return {
    // @ts-expect-error TS(7006): Parameter 'zip' implicitly has an 'any' type.
    fetchServiceAvailability: (zip) => {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchServiceAvailability(zip));
    },
    redirectToHome: () => {
      dispatch(push('/home'));
    },
  };
};

// @ts-expect-error TS(2345): Argument of type '({ authorized, fetchServiceAvailability, serviceAvailabilityChecked, serviceAvaila... (Delete me to see the full error)
export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
