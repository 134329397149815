import { SPACING } from '@alto/design-library-tokens';
import React from 'react';
import { PressableAltoIcon } from '../../../buttons';
import { XxsPadding } from '../../../containers';
import { XsSpacing } from '../../../separators';
import { Tag } from '../../../tags';
import { type ApiResponseRow } from '../InputAutocomplete/types';

type Props = {
  readonly selectedValues: ApiResponseRow<string>[];
  readonly onRemoveValue?: (selectedValue: ApiResponseRow<string>) => () => void;
};

export const InputTextSelectedTags = ({ selectedValues, onRemoveValue }: Props) => {
  const handlePress = (selectedValue: ApiResponseRow<string>) => () => {
    if (onRemoveValue) {
      onRemoveValue(selectedValue)();
    }
  };

  return (
    <>
      {selectedValues.map((selectedValue) => (
        <React.Fragment key={selectedValue.label}>
          <XxsPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <Tag
              label={selectedValue.label}
              type="counter"
              right
              icon={
                <PressableAltoIcon
                  type="white"
                  accessibilityLabel={`remove ${selectedValue.label} from input`}
                  onPress={handlePress(selectedValue)}
                  name="closecircle-small"
                />
              }
            />
          </XxsPadding>
          <XsSpacing />
        </React.Fragment>
      ))}
    </>
  );
};
