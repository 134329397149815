import React from 'react';
import { getURLParams } from '~shared/helpers/helper';
import CommsVerificationStatus from './CommsVerificationStatus';

type Props = {
  readonly params: {
    commsChannel: string;
    verificationStatus: string;
  };
};

const UnauthCommsVerificationStatusContainer = ({ params }: Props) => {
  const { commsChannel, verificationStatus } = params;
  const { token } = getURLParams();

  return (
    <CommsVerificationStatus
      isVerified={verificationStatus === 'success'}
      loading={false}
      commsChannel={commsChannel}
      authorized={false}
      token={token}
    />
  );
};

export default UnauthCommsVerificationStatusContainer;
