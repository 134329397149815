// @owners { team: patients-team }
import {
  CheckoutEndpoint,
  type CheckoutEndpointFetchFulfillmentOptionsRequest,
  type CheckoutEndpointFetchFulfillmentWindowOptionsRequest,
} from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import { type PrescriptionSelectedPriceType } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_selected_price_type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMemo } from 'react';
import { apiEndpointHandler } from '~shared/helpers/api';
import { formatWindowTimeLong } from '~shared/helpers/date';
import { useQuery } from '~shared/react-query';
import { formatDeliveryWindow } from '../helpers/formatDeliveryWindow';

const checkoutEndpoint = CheckoutEndpoint(apiEndpointHandler);

export const checkout = createQueryKeys('checkout', {
  fulfillmentOptions: (params: CheckoutEndpointFetchFulfillmentOptionsRequest) => ({
    queryKey: ['fulfillmentOptions', params],
    queryFn: () => checkoutEndpoint.fetchFulfillmentOptions(params),
  }),
  fulfillmentWindowOptions: (params: CheckoutEndpointFetchFulfillmentWindowOptionsRequest) => ({
    queryKey: ['fulfillmentWindowOptions', params],
    queryFn: () => checkoutEndpoint.fetchFulfillmentWindowOptions(params),
  }),
});

export const useQueryFulfillmentOptions = ({
  addressID,
  cartPrescriptionIDs,
  selectedPrices,
  shipmentID,
}: {
  addressID?: number;
  cartPrescriptionIDs: number[];
  selectedPrices?: PrescriptionSelectedPriceType[] | null | undefined;
  shipmentID?: number | null | undefined;
}) => {
  const { isSuccess, isPending, isError, error, data } = useQuery({
    ...checkout.fulfillmentOptions({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      address_id: addressID!,
      cart_prescription_ids: cartPrescriptionIDs,
      selected_prices: selectedPrices,
      shipment_id: shipmentID,
    }),
    enabled: !!addressID,
  });

  const courierDateFulfillmentOptions = useMemo(() => {
    return (data?.data?.courier_date_fulfillment_options || []).map((fulfillmentOption) => {
      return formatDeliveryWindow(fulfillmentOption);
    });
  }, [data?.data?.courier_date_fulfillment_options]);

  const courierAnyDateFulfillmentOption = useMemo(() => {
    return data?.data?.courier_any_date_fulfillment_option
      ? formatDeliveryWindow(data?.data?.courier_any_date_fulfillment_option)
      : null;
  }, [data?.data?.courier_any_date_fulfillment_option]);

  const pickupFulfillmentOptions = useMemo(() => {
    return (data?.data?.pickup_fulfillment_options || []).map((fulfillmentOption) => {
      return formatDeliveryWindow(fulfillmentOption);
    });
  }, [data?.data?.pickup_fulfillment_options]);

  return {
    isSuccess,
    isPending,
    isError,
    error,
    courierDateFulfillmentOptions,
    courierAnyDateFulfillmentOption,
    pickupFulfillmentOptions,
  };
};

export const useQueryFulfillmentWindowOptions = ({
  addressID,
  cartPrescriptionIDs,
  date,
  selectedPrices,
  shipmentID,
}: {
  addressID?: number;
  cartPrescriptionIDs: number[];
  date: string;
  selectedPrices?: PrescriptionSelectedPriceType[] | null | undefined;
  shipmentID?: number | null | undefined;
}) => {
  const { isSuccess, isPending, isError, error, data } = useQuery({
    ...checkout.fulfillmentWindowOptions({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      address_id: addressID!,
      cart_prescription_ids: cartPrescriptionIDs,
      date,
      selected_prices: selectedPrices,
      shipment_id: shipmentID,
    }),
    enabled: !!addressID,
  });

  const fulfillmentWindowOptions = useMemo(() => {
    return (data?.data?.fulfillment_window_options || []).map((fulfillmentWindowOption) => {
      if (fulfillmentWindowOption.delivery_window) {
        const {
          delivery_window: { start_time, end_time, cutoff_time },
          ...rest
        } = fulfillmentWindowOption;
        return {
          ...rest,
          delivery_window: {
            start_time: formatWindowTimeLong(start_time, 'a'),
            end_time: formatWindowTimeLong(end_time, 'a'),
            cutoff_time: cutoff_time ? formatWindowTimeLong(cutoff_time, 'a') : null,
          },
        };
      }
      return fulfillmentWindowOption;
    });
  }, [data?.data?.fulfillment_window_options]);

  return {
    isSuccess,
    isPending,
    isError,
    error,
    fulfillmentWindowOptions,
  };
};
