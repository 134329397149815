// @owners { team: patients-team }
export const AUTO_REFILL_INFO_ACTION_SHEET_DEPRECATED = {
  title: 'How auto refill works',
  subtitle:
    'Auto refill makes your life easier by automatically sending your medication when you need it—one less thing for you to remember!',
  step1: 'We’ll notify you before each delivery to confirm details and price',
  step2: 'Easily adjust the delivery date and time to fit your needs',
  step3: 'When you run out of refills, we’ll automatically contact your doctor for more',
  step4: 'Turn auto refill off at any time',
  bodyNextDate: (date: string) => `Your estimated next refill delivery date is ${date}.`,
  button: 'Got It',
} as const;

export const AUTO_REFILL_INFO_ACTION_SHEET = [
  {
    key: 'step1',
    icon: 'checkcircle-grey',
    subtitle: 'No surprises',
    text: 'We’ll notify you before each delivery to confirm details and price',
  },
  {
    key: 'step2',
    icon: 'calendar-grey',
    subtitle: 'You’re in control',
    text: 'Adjust the delivery dates and times to fit your needs, or turn auto refill off at any time',
  },
  {
    key: 'step3',
    icon: 'rxchart-grey',
    subtitle: 'Automatic renewals',
    text: 'When you run out of refills, we’ll automatically request a new prescription from your provider',
  },
  {
    key: 'step4',
    icon: 'sync-grey',
    subtitle: 'Simplified deliveries',
    text: 'If you have multiple medications on auto refill, we’ll sync them into bundled recurring deliveries when possible',
  },
];

export const AUTO_REFILL_OFF_ACTION_SHEET = {
  title: 'Turn auto refill off',
  body: 'By turning auto refill off for this medication, Alto will no longer automatically send future deliveries. You can turn auto refill back on again at any time.',
  button: 'Turn auto refill off',
} as const;

export const AUTO_REFILL_ACTION_CARD = {
  buttonText: 'View my Meds',
  body: 'You can enroll eligible medications in auto refill during checkout.',
} as const;

export const AUTO_REFILL_EMPTY_LIST = {
  body: "Looks like you don't have any medications enrolled in auto refill yet.",
} as const;

export const AUTO_REFILL_EMPTY_STATE_WITH_ELIGIBLE_MEDS = {
  title: 'No medications enrolled',
  body: 'Make life easier — we’ll automatically send your medications a few days before you run out.',
  buttonText: 'How does auto refill work?',
} as const;

export const AUTO_REFILL_EMPTY_STATE = {
  title: 'No eligible medications',
  body: 'None of your current medications are eligible for auto refill.',
  buttonText: 'How does auto refill work?',
} as const;

export const AUTO_REFILL_MANAGEMENT_ACTION_SHEET = {
  title: 'Manage your auto refill medications in one place!',
  step1: 'View medications eligible for auto refill',
  step2: 'Enroll medications in auto refill',
  step3: 'View your upcoming auto refill deliveries',
} as const;
