// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  AltoSpinningLoader,
  Column,
  Description,
  ExternalLink,
  FlatCard,
  InlineAlert,
  LgPadding,
  ListDescription,
  Row,
  Subway,
  SubwayStation,
} from '@alto/design-system';
import React, { useEffect } from 'react';
import styled from 'styled-components/native';
// eslint-disable-next-line import/no-deprecated
import { fetchRenewalRequestTimeline } from '~shared/actions/prescriptions';
import autoRefillImage from '~shared/assets/images/auto_refill.png';
import { useRenewalStatusTimeline } from '~shared/features/my-meds/hooks/useRenewalStatusTimeline';
import { stripNonDigits } from '~shared/helpers/string';
import { useDispatchShared } from '~shared/store';
import { type Prescription } from '~shared/types';

const ProviderPhoneNumber = styled(ExternalLink)`
  font-size: 14px;
  font-weight: normal;
`;

type Props = {
  readonly prescription: Prescription;
};

export const RenewalRequestStatusActionSheet = ({ prescription }: Props) => {
  const dispatch = useDispatchShared();

  useEffect(() => {
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchRenewalRequestTimeline(prescription));
  }, [dispatch, prescription]);

  const { error, loading, activeStation, stations } = useRenewalStatusTimeline(prescription);

  return (
    <ActionSheetV2
      title="Request in progress"
      illustrationSrc={autoRefillImage}
      analyticsName="renewal request in progress"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {!loading && !error && (
          <Column>
            <Subway
              activeStation={activeStation}
              verticalEnabled="always"
            >
              {Object.values(stations).map(({ id, title, description }) => (
                <SubwayStation
                  key={id}
                  id={id}
                  title={title}
                  descriptions={[<ListDescription key={description}>{description}</ListDescription>]}
                />
              ))}
            </Subway>
            {prescription.provider_phone ? (
              <FlatCard>
                <LgPadding>
                  <Description color={COLORS.TEXT_COLORS.BLACK}>
                    <Description
                      fontFamily="semibold"
                      color={COLORS.TEXT_COLORS.BLACK}
                    >
                      If this is urgent,{' '}
                    </Description>
                    call your provider at{' '}
                    <ProviderPhoneNumber href={`tel://${stripNonDigits(prescription.provider_phone)}`}>
                      {prescription.provider_phone}
                    </ProviderPhoneNumber>{' '}
                    to accelerate your request.{'\n'}
                  </Description>
                  <Description color={COLORS.TEXT_COLORS.BLACK}>
                    <Description
                      fontFamily="semibold"
                      color={COLORS.TEXT_COLORS.BLACK}
                    >
                      If you have changed providers,{' '}
                    </Description>
                    they need to send us a new prescription.
                  </Description>
                </LgPadding>
              </FlatCard>
            ) : null}
          </Column>
        )}

        {error ? (
          <InlineAlert type="error">
            <Description
              fontFamily="bold"
              color={COLORS.TEXT_COLORS.BLACK}
            >
              {error}
            </Description>
          </InlineAlert>
        ) : null}

        {loading ? (
          <Row center>
            <AltoSpinningLoader />
          </Row>
        ) : null}
      </LgPadding>
    </ActionSheetV2>
  );
};
