// @owners { team: patients-team }
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import { getVideosFromNdc } from '~shared/features/instructional-videos/constants';
import { type Video } from '~shared/features/instructional-videos/types';
import { getPrescriptions } from '~shared/features/prescriptions/selectors/getPrescriptions';

export const getForYouVideos = createSelector([getPrescriptions], (prescriptions) => {
  const videos = prescriptions.reduce<Video[]>((videos, { ndc }) => {
    if (ndc) {
      videos.push(...getVideosFromNdc(ndc));
    }

    return videos;
  }, []);

  return uniq(videos);
});
