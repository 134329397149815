// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  ContentLoader,
  H2,
  H3,
  Illustration,
  LgPadding,
  LgSpacing,
  MdSpacing,
  Row,
} from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useContext, useEffect, useMemo } from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type OriginValues, SOURCES } from '~shared/features/essentials/add_ons_consts';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { AddOnsContext } from '../../checkout/components/essentials/AddOnsContext';
import { useQueryEssentialsParams } from '../../essentials/hooks/useQueryEssentialsParams';
import { useQueryRelatedEssentials } from '../../essentials/queries/useQueryRelatedEssentials';
import { AddOnsCarousel } from './AddOnsCarousel';

type Props = {
  readonly essential: AddOnOtc;
  readonly shipmentID?: number | null | undefined;
  readonly origin?: OriginValues;
};

export const RelatedEssentialsActionSheet = ({ shipmentID, essential, origin }: Props) => {
  const { trackPageView } = useAnalytics();
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));
  const { closeActionSheet } = useContext(ActionSheetContext);
  const queryParams = useQueryEssentialsParams();
  const { isPending, relatedEssentials } = useQueryRelatedEssentials({
    ...queryParams,
    sku: essential.sku,
    skuType: essential.sku_type,
  });
  const addOnsContext = useMemo(
    () =>
      ({
        origin,
        shipmentID,
      }) as const,
    [origin, shipmentID],
  );

  useEffect(() => {
    trackPageView({
      event: EVENTS.ESSENTIALS__RELATED_ESSENTIALS_VIEWED,
      params: {
        facility_id: address?.dispensing_facility_id,
        shipment_id: shipmentID,
        source: SOURCES.RELATED_OTCS_INTERSTITIAL,
        origin,
      },
    });
  }, [address?.dispensing_facility_id, origin, shipmentID, trackPageView]);

  return (
    <AddOnsContext.Provider value={addOnsContext}>
      <ActionSheetV2 analyticsName="related essentials">
        <LgPadding
          topPadding={SPACING.STATIC.MD}
          bottomPadding={SPACING.STATIC.MD}
          backgroundColor={COLORS.BACKGROUND_COLORS.SUCCESS_LIGHTEST}
        >
          <Row
            spaceBetween
            centerVertically
          >
            <Row centerVertically>
              <Illustration
                size="sm"
                source={{ uri: essential.image_urls[0] }}
                accessibilityLabel={IMG_ALT_TEXTS.medication}
                testID="added-to-cart-image"
              />
              <MdSpacing />
              <H2>Added to {shipmentID ? 'order' : 'cart'}</H2>
            </Row>
            <AltoIcon name="checkmark" />
          </Row>
        </LgPadding>
        <LgPadding
          topPadding={SPACING.STATIC.MD}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <H3>{`Frequently bought with ${essential.name}`}</H3>
        </LgPadding>
        {isPending || !relatedEssentials ? (
          <LgPadding topPadding={SPACING.STATIC.MD}>
            <ContentLoader accessibilityLabel="loading essentials" />
          </LgPadding>
        ) : (
          <AddOnsCarousel
            addOnOtcs={relatedEssentials}
            source={SOURCES.RELATED_OTCS_INTERSTITIAL}
            onCloseActionSheet={closeActionSheet}
            visibleOtcs={2.75}
          />
        )}
        <LgSpacing />
      </ActionSheetV2>
    </AddOnsContext.Provider>
  );
};
