import { AVATAR } from './avatars';
import { ICON } from './icons';
import { ILLUSTRATION, IMAGE } from './images';
import { PAGE } from './pages';

export const SIZES = {
  AVATAR,
  ICON,
  IMAGE,
  ILLUSTRATION,
  PAGE,
} as const;

export type SIZES_AVATAR_SIZES = (typeof AVATAR)[keyof typeof AVATAR];
export type SIZES_ICON_SIZES = (typeof ICON)[keyof typeof ICON];
export type SIZES_IMAGE_SIZES = (typeof IMAGE)[keyof typeof IMAGE];
export type SIZES_ILLUSTRATION_SIZES = (typeof ILLUSTRATION)[keyof typeof ILLUSTRATION];
export type SIZES_ILLUSTRATION_SIZE_NAMES = keyof typeof ILLUSTRATION;
export type SIZES_PAGE_SIZES = (typeof PAGE)[keyof typeof PAGE];
