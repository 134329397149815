// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Button,
  type ButtonType,
  H3,
  LgPadding,
  ListBase,
  ListButton,
  SmSpacing,
  XsPadding,
  XsSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useNavigation } from '@alto/navigation';
import { type UpcomingShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/order_success/types/v1/prescription';
import { type Shipment as OrderSuccessShipment } from '@alto/scriptdash/alto/patient_app/order_success/types/v1/shipment';
import { partition } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { getShipmentWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { isExpensive } from '~shared/helpers/helper';
import { isPickup } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { useFetchAncillariesByPrescriptionIds } from '../../ancillaries';
import { useGetDeliveryMethodsByShipmentIDs } from '../../delivery-methods';
import { RenewalRequestButton } from '../../renewal';
import { AncillaryRow } from './AncillaryRow';
import { CalendarRow } from './CalendarRow';
import { FulfillmentMethodRow } from './FulfillmentMethodRow';
import { HomeToSignRow } from './HomeToSignRow';
import { LocationRow } from './LocationRow';
import { MedRow } from './MedRow';
import { PriceRow } from './PriceRow';
import { WhatToExpectOnDeliveryDayRow } from './WhatToExpectOnDeliveryDayRow';

type Props = {
  readonly shipment: UpcomingShipment | OrderSuccessShipment;
  readonly shipmentLabel: string; // non-empty shipmentLabel indicates split shipments
  readonly navigateDeliveryDetails?: (shipmentID: number, previousRoute?: string) => void;
  readonly showFulfillmentMethod?: boolean;
  readonly bundledPrescriptionIDs?: number[];
  readonly isEligibleForMailAutoRefill?: boolean;
};

function isOrderSuccessShipment(shipment: UpcomingShipment | OrderSuccessShipment): shipment is OrderSuccessShipment {
  return (shipment as OrderSuccessShipment).prescriptions !== undefined;
}

function getLabel(shipment: UpcomingShipment | OrderSuccessShipment): string {
  if (shipment.editable) {
    return 'View or edit order';
  } else {
    return 'View order';
  }
}

export const ShipmentInfo = ({
  shipment,
  shipmentLabel,
  navigateDeliveryDetails,
  showFulfillmentMethod = true,
  bundledPrescriptionIDs,
  isEligibleForMailAutoRefill = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const { navigate } = useNavigation();
  const [hasRequestedRefill, setHasRequestedRefill] = useState(false);
  const { address, date, end_time, price, start_time, is_asap_delivery } = shipment;
  const shipmentWithItems = useSelectorShared((state) => getShipmentWithItemsByID(state, { id: shipment.id }));
  const { home_to_sign_confirmation, items: shipmentItems = [], status } = shipmentWithItems || {}; // fallback when no shipment
  const safe_place = useSelectorShared((state) => getShipmentAddress(state, { shipmentID: shipment.id })?.safe_place);
  const { ancillaries, isPending } = useFetchAncillariesByPrescriptionIds({
    prescriptionIds: shipmentItems.map((item) => item.prescription.id),
  });

  const { deliveryMethodsByShipmentID, deliveryMethodsLoading } = useGetDeliveryMethodsByShipmentIDs([shipment.id]);
  const deliveryMethod = deliveryMethodsByShipmentID[shipment.id];

  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');
  const hasExpensiveItems = isExpensive(
    shipmentItems.map((item) => item.prescription),
    useNewThreshold,
  );
  const hasControls = shipmentItems.some((item) => item.isSignatureRequired);

  // props for buttons
  const onPress = () => {
    if (navigateDeliveryDetails) {
      navigateDeliveryDetails(shipment.id); // handles showing survey after checkout
    } else {
      navigate('RouteOrderDetails', { shipmentID: shipment.id });
    }
  };

  // partition prescriptions into bundled and non-renewable prescriptions
  // medication_names is a list of medication names that are not bundled
  const partitionBundledPrescriptions = (bundledIDs: number[], nonRenewalRxs: Prescription[], medNames: string[]) => {
    let bundledPrescriptions = [] as Prescription[];
    let nonRenewablePrescriptions = [] as Prescription[];
    let nonBundledMedicationNames = [...medNames];

    if (bundledIDs && bundledIDs.length > 0) {
      [bundledPrescriptions, nonRenewablePrescriptions] = partition(nonRenewalRxs, (p) => bundledIDs.includes(p.id));
      nonBundledMedicationNames = nonBundledMedicationNames.filter(
        (name) => !bundledPrescriptions.map((p) => p.name).includes(name),
      );
    }

    return {
      bundledPrescriptions,
      nonRenewablePrescriptions,
      nonBundledMedicationNames,
    };
  };

  let isHomescreen = true;
  let showRequestRefills = false;
  let nonRenewablePrescriptions = [] as Prescription[];
  let renewablePrescriptions = [] as Prescription[];
  let bundledPrescriptions = [] as Prescription[];
  let { medication_names } = shipment;

  // On the Order Success page only, partition prescriptions if they are on the last refill and can be renewed
  // Show "Request refills" button for renewable prescriptions
  if (isOrderSuccessShipment(shipment)) {
    [renewablePrescriptions, nonRenewablePrescriptions] = partition(shipment.prescriptions, (p) => p.is_renewable);
    isHomescreen = false; // if post checkout (order success screen), list each medication on new line
    showRequestRefills = renewablePrescriptions.length > 0;
    if (bundledPrescriptionIDs) {
      const partitionResult = partitionBundledPrescriptions(
        bundledPrescriptionIDs,
        nonRenewablePrescriptions,
        medication_names,
      );
      nonRenewablePrescriptions = partitionResult.nonRenewablePrescriptions;
      bundledPrescriptions = partitionResult.bundledPrescriptions;
      medication_names = partitionResult.nonBundledMedicationNames;
    }
  }

  let buttonType: ButtonType = 'primary';

  if (showRequestRefills) {
    buttonType = 'tertiary';
  }
  if (hasRequestedRefill || !showRequestRefills) {
    buttonType = 'secondary';
  }

  const onCompleteRequestRefill = useCallback(() => {
    setHasRequestedRefill(true);
  }, []);

  const rows = [
    {
      key: 'label',
      component: (
        <XsPadding leftPadding={SPACING.STATIC.LG}>
          <H3>{shipmentLabel}</H3>
          <XsSpacing />
          {showFulfillmentMethod ? (
            <WhatToExpectOnDeliveryDayRow
              method={deliveryMethod}
              shipmentStatus={status}
              isLoading={deliveryMethodsLoading}
            />
          ) : null}
        </XsPadding>
      ),
      hide: !shipmentLabel,
    },
    {
      key: 'calendar',
      component: (
        <CalendarRow
          date={date}
          method={deliveryMethod}
          status={status}
          origin={isOrderSuccessShipment(shipment) ? 'order_success' : null}
          startTime={start_time}
          endTime={end_time}
          isAsapDelivery={is_asap_delivery ?? false}
          isEligibleForMailAutoRefill={isEligibleForMailAutoRefill}
        />
      ),
    },
    {
      key: 'location',
      component: (
        <LocationRow
          address={address}
          isPickup={isPickup(deliveryMethod?.mode)}
          showMap={status !== 'delivered'}
        />
      ),
      hide: !address,
    },
    {
      key: 'fulfillment-method',
      component: (
        <FulfillmentMethodRow
          method={deliveryMethod}
          status={status}
        />
      ),
      hide: !showFulfillmentMethod || isEligibleForMailAutoRefill,
    },
    {
      key: 'ancillaries',
      component: (
        <AncillaryRow
          isLoading={isPending}
          show={ancillaries.length > 0}
        />
      ),
      hide: ancillaries.length === 0 || isPending,
    },
    {
      key: 'medications',
      component: (
        <MedRow
          medicationNames={showRequestRefills ? nonRenewablePrescriptions.map((p) => p.name) : medication_names}
          showAllMeds={!isHomescreen}
          newMedicationNames={bundledPrescriptions.map((p) => p.name)}
        />
      ),
    },
    {
      key: 'refillable-medication',
      component: (
        <MedRow
          medicationNames={renewablePrescriptions.map((p) => p.name)}
          isLastRefill
          showAllMeds
        />
      ),
      hide: !showRequestRefills,
    },
    {
      key: 'signature',
      component: (
        <HomeToSignRow
          hasControlledOrExpensiveMedication={hasControls || hasExpensiveItems}
          homeToSignConfirmation={home_to_sign_confirmation}
          safePlace={!!safe_place}
        />
      ),
      hide: home_to_sign_confirmation === 'unnecessary',
    },
    {
      key: 'price',
      component: <PriceRow price={price} />,
    },
    {
      key: 'request-refill',
      component: (
        <>
          <SmSpacing />
          <LgPadding
            topPadding={SPACING.STATIC.NONE}
            bottomPadding={SPACING.STATIC.NONE}
          >
            <RenewalRequestButton
              prescriptionIDs={renewablePrescriptions.map((p) => p.id)}
              label="Request refills"
              secondary
              onComplete={onCompleteRequestRefill}
            />
          </LgPadding>
        </>
      ),
      hide: !showRequestRefills,
    },
    {
      key: 'view-order',
      component: (
        <>
          <SmSpacing />
          <LgPadding
            leftPadding={Platform.OS === 'web' ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
            rightPadding={Platform.OS === 'web' ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
            topPadding={SPACING.STATIC.NONE}
            bottomPadding={SPACING.STATIC.NONE}
          >
            {isHomescreen ? (
              <ListButton
                label={getLabel(shipment)}
                rightIcon={shipment.editable ? 'edit' : 'chevronright-small'}
                onPress={onPress}
              />
            ) : (
              <Button
                type={buttonType}
                label={getLabel(shipment)}
                onPress={() => {
                  if (navigateDeliveryDetails) {
                    navigateDeliveryDetails(shipment.id); // handles showing survey after checkout
                  } else {
                    navigate('RouteOrderDetails', { shipmentID: shipment.id });
                  }
                }}
              />
            )}
          </LgPadding>
        </>
      ),
    },
  ];

  const items = rows.filter((row) => !row.hide);

  return <ListBase items={items} />;
};
