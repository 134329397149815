// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import {
  getAddOnOtcsInShipment,
  getHasNonEssentialsInShipment,
} from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { type ReduxStateShared } from '~shared/types';

const getEssentialsTotalPriceInShipment = createSelector(
  [getShipmentPricing, getAddOnOtcsInShipment],
  (shipmentPricing, essentials) => {
    return essentials.reduce((sum, item) => {
      const price = item.prescription?.id ? shipmentPricing.item_pricing[item.prescription.id]?.prices[0]?.price : 0;
      return sum + (price || 0);
    }, 0);
  },
);

type UpdatedBelowMinProps = {
  initialQuantity: number;
  updatedQuantity: number;
  essentialPrice: number;
  minimumThreshold: number;
};
const getInitialQuantity = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.initialQuantity;
const getUpdatedQuantity = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.updatedQuantity;
const getEssentialPrice = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.essentialPrice;
const getMinimumThreshold = (_: ReduxStateShared, props: UpdatedBelowMinProps) => props.minimumThreshold;
export const getUpdatedEssentialQuantityIsBelowMinimum = createSelector(
  [
    getEssentialsTotalPriceInShipment,
    getHasNonEssentialsInShipment,
    getInitialQuantity,
    getUpdatedQuantity,
    getEssentialPrice,
    getMinimumThreshold,
  ],
  (
    essentialsTotalPrice,
    hasNonEssentialsInOrder,
    initialQuantity,
    updatedQuantity,
    essentialPrice,
    minimumThreshold,
  ): boolean => {
    if (hasNonEssentialsInOrder) {
      return false;
    }
    const priceDiff = (initialQuantity - updatedQuantity) * essentialPrice;
    return essentialsTotalPrice - priceDiff < minimumThreshold;
  },
);
export const getUpdatedEssentialQuantityIsBelowFreeDeliveryMinimum = createSelector(
  [
    getEssentialsTotalPriceInShipment,
    getHasNonEssentialsInShipment,
    getInitialQuantity,
    getUpdatedQuantity,
    getEssentialPrice,
    getMinimumThreshold,
  ],
  (
    essentialsTotalPrice,
    hasNonEssentialsInOrder,
    initialQuantity,
    updatedQuantity,
    essentialPrice,
    minimumThreshold,
  ): boolean => {
    if (hasNonEssentialsInOrder) {
      return false;
    }
    const priceDiff = (initialQuantity - updatedQuantity) * essentialPrice;
    return essentialsTotalPrice - priceDiff < minimumThreshold;
  },
);
