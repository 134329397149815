import { SIZES } from '@alto/design-library-tokens';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { AltoIcon, type AltoIconProps } from '../../../icon';
import { AltoSpinner } from './AltoSpinner';

// React native defaults to align-items: stretch which causes our
// Spin to stretch as big as the parent, causing a weird spin animation
// This keeps the spin and icon only the size of the icon
const NonStretchyContainer = styled(View)<{ small?: boolean }>`
  ${({ small }) => `
  height: ${small ? SIZES.ICON.MD.px : SIZES.ICON.LG.px};
  width: ${small ? SIZES.ICON.MD.px : SIZES.ICON.LG.px};
  `}
`;

type Props = Pick<AltoIconProps, 'type'> & { readonly small?: boolean; readonly testID?: string };

export const AltoSpinningLoader = ({ testID, type = 'grey', small = true }: Props) => (
  <NonStretchyContainer small={small}>
    <AltoSpinner>
      <AltoIcon
        type={type}
        name={small ? 'loader-small' : 'loader'}
        allowFontScaling={false}
        testID={testID}
      />
    </AltoSpinner>
  </NonStretchyContainer>
);
