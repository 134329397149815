import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Column, Description, ListButton, MdSpacing, XxlSpacing } from '@alto/design-system';
import { useEssentialsStoreCard } from '@alto/features';
import React, { useCallback } from 'react';
import { push } from 'react-router-redux';
import styledNative from 'styled-components/native';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';

const Container = styledNative(Column)`
  padding-right: ${SPACING.STATIC.LG.px};
`;

type Props = {
  readonly navigateToMedList: () => void;
  readonly navigateToSendPrescriptionOptions: () => void;
  readonly openPharmacyInfoModal: () => void;
};

export const QuickLinks = ({ navigateToMedList, navigateToSendPrescriptionOptions, openPharmacyInfoModal }: Props) => {
  const { showEssentialListItem, upSellCardTitle } = useEssentialsStoreCard();
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();

  const navigateToEssentialsStore = useCallback(() => {
    trackEvent({ event: EVENTS.ESSENTIALS__SHOP_ESSENTIALS_CARD_TAPPED });
    dispatch(push('/store'));
  }, [dispatch, trackEvent]);

  return (
    <Container>
      <XxlSpacing />
      <Description
        fontFamily="semibold"
        color={COLORS.TEXT_COLORS.PRIMARY}
      >
        Quick Links
      </Description>

      {showEssentialListItem ? (
        <>
          <MdSpacing />
          <ListButton
            label={upSellCardTitle}
            onPress={navigateToEssentialsStore}
            rightIcon="chevronright"
          />
        </>
      ) : null}

      <MdSpacing />

      <ListButton
        label="Transfer to Alto"
        onPress={navigateToSendPrescriptionOptions}
        rightIcon="chevronright"
      />

      <MdSpacing />

      <ListButton
        label="See Alto's pharmacy info"
        onPress={openPharmacyInfoModal}
        rightIcon="chevronright"
      />

      <MdSpacing />

      <ListButton
        label="View All Medications"
        onPress={navigateToMedList}
        rightIcon="chevronright"
      />
    </Container>
  );
};
