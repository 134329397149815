import { SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexRow, H3, InfoText, InputCheckbox, LoaderImage, spinnerStyles } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { subscriptionsConstants } from '~shared/constants';
import { type Subscription } from '~shared/types/subscription';
import PrefsConfirmationPage from './PrefsConfirmationPage';
import { CTAButton, LargeText, SmallText, palette } from '~web/features/onboarding/components/styles';
import logoAlto from '~web/images/altoLogo@2x.png';

type Props = {
  readonly email: string;
  readonly errorMessage: any;
  readonly isUpdated: boolean;
  readonly isChanged: boolean;
  readonly subscriptions: Subscription[];
  readonly subscriptionsLoading: boolean;
  readonly handleUpdate: (unSubscribeAll?: boolean) => void;
  readonly setSubscriptions: (subscription: string) => void;
};

const ColoredFlexRow = styled(FlexRow)`
  height: 100vh;
  min-height: 900px;
  background-color: white;
`;

const LogoContainer = styled.a`
  width: 106px;
`;

const AltoLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledInputCheckbox = styled(InputCheckbox)<{ isSMScreenOrBigger: boolean }>`
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 576px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 340px;`}
`;

const StyledH3 = styled(H3)<{ isSMScreenOrBigger: boolean }>`
  color: #4c4c4c;
  font-size: 16px;
  font-weight: bold;
  line-height: 1rem;
  font-family: '${TYPOGRAPHY.FONT.BODY.SEMIBOLD}', sans-serif;
  margin-bottom: -4px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `font-size: 14px; font-weight: bold;`}
`;

const CheckboxTextContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  margin-left: 8px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-left: 4px;`}
`;

const StyledUnsubAllButton = styled.a<{ isSMScreenOrBigger: boolean }>`
  font-family: '${TYPOGRAPHY.FONT.HEADER.REGULAR}', serif;
  color: ${palette.brandNavy};
  margin: ${SPACING.STATIC.LG.rem};
  &:hover {
    text-decoration: none;
  }
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-top: ${SPACING.STATIC.MD.rem}`}
`;

const InfoTextFont = styled(InfoText)`
  font-family: '${TYPOGRAPHY.FONT.BODY.SEMIBOLD}', sans-serif;
  font-size: 12px;
`;

const StyledInfoText = styled(InfoTextFont)<{ isSMScreenOrBigger: boolean }>`
  width: 490px;
  text-align: center;
  margin: ${SPACING.STATIC.XL.rem};
  font-size: 12px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 350px; margin: ${SPACING.STATIC.MD.rem}`}
`;

const MessageContainer = styled(FlexRow)<{ isSMScreenOrBigger: boolean }>`
  width: 630px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `width: 280px; margin-top: ${SPACING.STATIC.XL.rem}`}
`;

const StyledFlexRow = styled(FlexRow)<{ isSMScreenOrBigger: boolean }>`
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `padding: ${SPACING.STATIC.MD.rem}`}
`;

const StyledErrorMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: ${SPACING.STATIC.XL.rem};
`;

const SubscriptionsPage = ({
  email,
  errorMessage,
  isUpdated,
  isChanged,
  subscriptions,
  setSubscriptions,
  subscriptionsLoading,
  handleUpdate,
}: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();
  const renderAltoHeader = () => (
    <FlexRow
      horizontallyAlignContent
      padding={SPACING.STATIC.MD.rem}
    >
      <LogoContainer
        href="https://alto.com"
        aria-label="Alto Logo"
      >
        <AltoLogo src={logoAlto} />
      </LogoContainer>
    </FlexRow>
  );

  const renderMessage = () => (
    <MessageContainer
      horizontallyAlignContent
      spacing={SPACING.STATIC.LG.rem}
      margin="4.5rem 0 0 0"
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <LargeText isSMScreenOrBigger={isSMScreenOrBigger}>We'd like to stay in touch!</LargeText>
      <SmallText isSMScreenOrBigger={isSMScreenOrBigger}>
        Hi <strong>{email}</strong>, uncheck an option to remove yourself from a specific mailing list.
      </SmallText>
    </MessageContainer>
  );

  const renderSelections = () => (
    <StyledFlexRow
      spacing={SPACING.STATIC.XS.rem}
      padding={SPACING.STATIC.XL.rem}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      {subscriptions.map(({ comms_type, subscribed }) => (
        <StyledInputCheckbox
          key={comms_type}
          input={{
            // @ts-expect-error Type 'boolean' is not assignable to type 'string | number | readonly string[] | undefined'.ts(2769)
            value: subscribed,
            onChange: () => {
              setSubscriptions(comms_type);
            },
          }}
          isSMScreenOrBigger={isSMScreenOrBigger}
        >
          <CheckboxTextContainer isSMScreenOrBigger={isSMScreenOrBigger}>
            {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error) */}
            <StyledH3>{subscriptionsConstants[comms_type].category}</StyledH3>
            {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error) */}
            <InfoTextFont>{subscriptionsConstants[comms_type].description}</InfoTextFont>
          </CheckboxTextContainer>
        </StyledInputCheckbox>
      ))}
    </StyledFlexRow>
  );

  const renderUpdateCTA = () => (
    <CTAButton
      disabled={!isChanged}
      buttonText="Update"
      color={palette.citrus}
      hoverColor={palette.darkCitrus}
      onClick={() => {
        handleUpdate();
      }}
      withChevron={false}
    />
  );

  const renderUnsubAll = () => (
    <StyledUnsubAllButton
      isSMScreenOrBigger={isSMScreenOrBigger}
      onClick={() => {
        handleUpdate(true);
      }}
    >
      Or unsubscribe all
    </StyledUnsubAllButton>
  );

  const renderFooter = () => (
    <StyledInfoText isSMScreenOrBigger={isSMScreenOrBigger}>
      <strong>Please note:</strong> you may still receive account-related emails from Alto related to the management and
      delivery of your medications.
    </StyledInfoText>
  );

  const renderContent = () =>
    subscriptionsLoading ? (
      <LoaderImage
        data-testid="loader"
        style={spinnerStyles}
      />
    ) : (
      <>
        {renderMessage()}
        {renderSelections()}
        {renderUpdateCTA()}
        {renderUnsubAll()}
        {renderFooter()}
      </>
    );

  const renderConfirmationOrContent = () => (isUpdated ? <PrefsConfirmationPage /> : renderContent());

  const renderErrorMessage = () => <StyledErrorMessage>{errorMessage}</StyledErrorMessage>;

  return (
    <ColoredFlexRow horizontallyAlignContent>
      {renderAltoHeader()}
      {errorMessage ? renderErrorMessage() : renderConfirmationOrContent()}
    </ColoredFlexRow>
  );
};

export default SubscriptionsPage;
