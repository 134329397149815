import { COLORS } from '@alto/design-library-tokens';
import { View } from 'react-native';
import styled from 'styled-components/native';

type ColorProps = {
  dark?: boolean;
  transparent?: boolean;
};

function getSeparatorColor(props: ColorProps): string {
  if (props.transparent) {
    return 'transparent';
  }

  if (props.dark) {
    return COLORS.PALETTE.GREYSCALE.DARK;
  }

  return COLORS.PALETTE.GREYSCALE.DEFAULT;
}

export const Separator = styled(View)<ColorProps>`
  background-color: ${(props) => getSeparatorColor(props)};
  height: 1px;
`;

export const Divider = styled(View)<ColorProps>`
  width: 100%;
  height: 1px;
  background-color: ${(props) => getSeparatorColor(props)};
`;
