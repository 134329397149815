// @owners { team: patients-team }
import { getDisplayName } from '~shared/helpers/helper';
import { type User } from '~shared/types'; // A compare function used with .sort that results in an order of the current user
// first followed by the other users in alphabetical order of the display name.

export default function compareFamilyMembers(
  user1: User | null | undefined,
  user2: User | null | undefined,
  currentUser: User | null | undefined,
): number {
  if (user1 && currentUser && user1.id === currentUser.id) return -1;
  if (user2 && currentUser && user2.id === currentUser.id) return 1;

  const name1 = getDisplayName(user1);
  const name2 = getDisplayName(user2);
  return name1.localeCompare(name2);
}
