// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getUsers } from './getUsers';
import { type UserIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getUserID = (_: ReduxStateShared, props: UserIDParam) => props.userID;

const getIsMedSyncUser = createSelector([getUserID, getUsers], (id, users) => {
  const user = users.find((u) => u.id === id);
  return !!user?.is_med_sync;
});

export default getIsMedSyncUser;
