// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type ReduxStateShared } from '~shared/types';

const getFetchQuantityOptionsLoading: (arg0: ReduxStateShared) => boolean = createSelector(
  getLoading,
  (loading) => loading?.fetchQuantityOptionsLoading,
);

export default getFetchQuantityOptionsLoading;
