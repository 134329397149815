// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getBiometrics } from './getBiometrics';
import { isBrandSupported } from '~shared/actions/biometrics';
import { type ReduxStateShared } from '~shared/types';

export const getShouldPromptBiometricsSheet: (arg0: ReduxStateShared) => boolean = createSelector(
  [getBiometrics],
  ({ available, devicePrompted }) => available && !devicePrompted && isBrandSupported(),
);
