// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getDeliveries } from './getDeliveries';
import { makeCachedSortByISODateString } from '~shared/helpers/sort';
import { type PrescriptionIDParamNested } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';
import { type LightDelivery as Delivery } from '~shared/types/clients';

export type DeliveriesByPrescription = Record<string, Delivery[]>;

export const getUpcomingDeliveriesByPrescriptionID = createSelector(getDeliveries, (deliveries) => {
  const sortByDate = makeCachedSortByISODateString();
  const upcomingDeliveries = deliveries
    .filter(({ status }) => status === 'upcoming')
    .sort((a, b) => sortByDate(a.date, b.date));

  const upcomingDeliveriesByPrescription: DeliveriesByPrescription = {};

  upcomingDeliveries.forEach((delivery) => {
    const prescriptionID = delivery.prescription_id;

    if (upcomingDeliveriesByPrescription[prescriptionID]) {
      upcomingDeliveriesByPrescription[prescriptionID].push(delivery);
    } else {
      upcomingDeliveriesByPrescription[prescriptionID] = [delivery];
    }
  });

  return upcomingDeliveriesByPrescription;
});

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParamNested) => props.prescription.id;

export const getUpcomingDeliveriesForPrescription = createSelector(
  getUpcomingDeliveriesByPrescriptionID,
  getPrescriptionID,
  (upcomingDeliveriesByPrescription, prescriptionID) => {
    return upcomingDeliveriesByPrescription[prescriptionID] || [];
  },
);

export const getNextUpcomingDeliveryForPrescription = createSelector(
  getUpcomingDeliveriesForPrescription,
  (upcomingDeliveriesForPrescription) => upcomingDeliveriesForPrescription[0] as Delivery | undefined,
);

export default getUpcomingDeliveriesForPrescription;
