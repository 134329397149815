// @owners { team: patients-team }
import { createSelector } from 'reselect';
import getErrors from '~shared/features/ui/selectors/getErrors';
import { type ReduxStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export const RESTRICTED_PARTNER_ERRORS = {
  ALREADY_CONFIRMED: 'Billing::Insurances::Errors::AlreadyConfirmed',
  NOT_YET_CONFIRMED: 'Billing::Insurances::Errors::NotYetConfirmed',
};

const getInsuranceError: (state: ReduxStateShared) => APIError | null = createSelector(
  getErrors,
  (errors) => errors.createInsuranceError || errors.updateInsuranceError || null,
);

export const isRestrictedPartnerError = (error: APIError | null) => {
  if (!error) return false;

  const code = error?.details?.code || '';
  return Object.values(RESTRICTED_PARTNER_ERRORS).includes(code);
};

export const getRestrictedPartnerError: (state: ReduxStateShared) => APIError | null = createSelector(
  [getInsuranceError],
  (insuranceError) => (isRestrictedPartnerError(insuranceError) ? insuranceError : null),
);

export const getNotRestrictedPartnerError: (state: ReduxStateShared) => APIError | null = createSelector(
  [getInsuranceError],
  (insuranceError) => (isRestrictedPartnerError(insuranceError) ? null : insuranceError),
);

export default getInsuranceError;
