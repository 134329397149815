import useVisibilitySensor from '@rooks/use-visibility-sensor';
import { type ElementRef, useEffect, useRef } from 'react';

export const useTrackNodeSeen = ({ onSeen }: { onSeen: () => void }): ElementRef<any> => {
  const rootNode = useRef(null);
  const seen = useRef(false);
  const { isVisible } = useVisibilitySensor(rootNode, {
    resizeCheck: true,
    scrollThrottle: 250,
    resizeThrottle: 250,
  });

  useEffect(() => {
    if (isVisible && !seen.current) {
      onSeen();
      seen.current = true;
    }
  }, [isVisible, onSeen]);

  return rootNode;
};
