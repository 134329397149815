// @owners { team: patients-team }
import { withRehydration } from './withRehydration';
import { type CSATActions, UPDATE_CSAT_SUBMITTED_DATE, UPDATE_CSAT_VIEWED_DATE } from '~shared/actions/csat';

export type CSATState = {
  lastViewedDate: Date;
  lastSubmittedDate: Date;
};

const initialState: CSATState = {
  lastViewedDate: new Date(0),
  lastSubmittedDate: new Date(0),
};

const csat = (state = initialState, action: CSATActions): CSATState => {
  switch (action.type) {
    case UPDATE_CSAT_VIEWED_DATE:
      return { ...state, lastViewedDate: new Date() };

    case UPDATE_CSAT_SUBMITTED_DATE:
      return { ...state, lastSubmittedDate: new Date() };

    default:
      return state;
  }
};

export default withRehydration(csat, initialState);
