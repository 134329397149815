// @owners { team: patients-team }
import {
  type ActionSetAddFamilyMemberRelationship,
  SET_ADD_FAMILY_MEMBER_RELATIONSHIP,
} from '~shared/actions/ui/addFamilyMember';

type Action = ActionSetAddFamilyMemberRelationship;

export type StateAddFamilyMember = {
  relationship: string;
};

const initialState: StateAddFamilyMember = {
  relationship: '',
};

const addFamilyMember = (state: StateAddFamilyMember = initialState, action: Action) => {
  switch (action.type) {
    case SET_ADD_FAMILY_MEMBER_RELATIONSHIP:
      return {
        ...state,
        relationship: action.payload,
      };

    default:
      return state;
  }
};

export default addFamilyMember;
