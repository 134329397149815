// @owners { team: patients-team }
import { AltoIcon, LgSpacing, Tag, XsSpacing } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';

type Props = {
  readonly addOnOtc: AddOnOtc;
};

export const FSABadge = ({ addOnOtc }: Props) => {
  if (addOnOtc.fsa_hsa_eligible) {
    return (
      <>
        <Tag
          label="FSA"
          icon={<AltoIcon name="checkcircle-small" />}
          type="recommended"
        />
        <XsSpacing />
      </>
    );
  }

  return (
    <>
      <LgSpacing />
      <XsSpacing />
    </>
  );
};
