// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ButtonSelector, Description, MdSpacing, Pressable } from '@alto/design-system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { type TextInput } from 'react-native';
import { getHasNoCopay } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { type AnalyticsProps } from '~shared/features/courier-tips/AnalyticsProps';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import {
  COURIER_NEW_TIP_COPY,
  TIP_AMOUNT_OPTIONS,
  TIP_AMOUNT_OPTION_VALUES,
} from '~shared/features/courier-tips/constants';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { formatDollars } from '~shared/helpers/currency';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { CustomAmountInput } from './CustomAmountInput';

type Props = {
  readonly analyticsProps: AnalyticsProps;
  readonly amountCentered?: boolean;
  readonly handleEditTipAmount: (tipAmount: number) => void;
  readonly tipAmount: number;
  readonly disabled?: boolean;
};

export const CourierTipInput = ({
  analyticsProps,
  amountCentered,
  handleEditTipAmount,
  tipAmount,
  disabled,
}: Props) => {
  const inputRef = useRef<TextInput | null>(null);
  const dispatch = useDispatchShared();
  const hasInitialCustomAmount = !TIP_AMOUNT_OPTION_VALUES.includes(tipAmount);
  const [isCustomAmountFocused, setIsCustomAmountFocused] = useState(false);
  const [isCustomAmountShown, setIsCustomAmountShown] = useState(hasInitialCustomAmount);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const hasNoCopay = useSelectorShared(getHasNoCopay);
  const { trackEvent } = useAnalytics();

  const showCustomAmountInput = useCallback(() => {
    if (!isCustomAmountShown) {
      dispatch(sendTipAnalyticsEvent({ eventKey: EVENTS.COURIER_TIPS__CUSTOM_AMOUNT_TAPPED, analyticsProps }));
      handleEditTipAmount(0);
    }

    setIsCustomAmountFocused(true);
    setIsCustomAmountShown(true);

    // Focus custom input on press
    inputRef.current?.focus();
  }, [isCustomAmountShown, setIsCustomAmountFocused, setIsCustomAmountShown, handleEditTipAmount, inputRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideCustomAmountInputAndSetSelectedTip = useCallback(
    (value: number) => {
      setIsCustomAmountFocused(false);
      setIsCustomAmountShown(false);
      handleEditTipAmount(value);

      trackEvent({
        event: EVENTS.PAYMENT_METHOD_RENDERED,
        params: {
          origin: isEditingOrder ? 'edit delivery' : 'checkout',
          has_medication_payment: !hasNoCopay,
        },
      });
    },
    [hasNoCopay, isEditingOrder, setIsCustomAmountFocused, setIsCustomAmountShown, handleEditTipAmount, trackEvent],
  );

  const setCustomTipAmount = useCallback(
    (value: number) => {
      handleEditTipAmount(value);

      trackEvent({
        event: EVENTS.PAYMENT_METHOD_RENDERED,
        params: {
          origin: isEditingOrder ? 'edit delivery' : 'checkout',
          has_medication_payment: !hasNoCopay,
        },
      });
    },
    [handleEditTipAmount, hasNoCopay, isEditingOrder, trackEvent],
  );

  // Focus custom input after input is mounted
  useEffect(() => {
    if (isCustomAmountFocused) {
      inputRef.current?.focus();
    }
  }, [isCustomAmountFocused]);

  // Persist tip amount in the store
  useEffect(() => {
    dispatch(
      sendTipAnalyticsEvent({
        eventKey: EVENTS.COURIER_TIPS__AMOUNT_SELECTED,
        analyticsProps,
        additionalProps: { tip_value: formatDollars(tipAmount, false) },
      }),
    );
    handleEditTipAmount(Number(tipAmount) || 0);
  }, [analyticsProps, dispatch, handleEditTipAmount, tipAmount]);

  return (
    <>
      <ButtonSelector
        // @ts-expect-error issues with strings and numbers
        onChange={hideCustomAmountInputAndSetSelectedTip}
        options={TIP_AMOUNT_OPTIONS}
        value={isCustomAmountShown ? undefined : tipAmount}
        disabled={disabled}
        testID="courier-tip-selector"
      />
      <MdSpacing />
      <Pressable
        disabled={disabled}
        accessibilityLabel="Tap to enter a custom tip amount"
        onPress={showCustomAmountInput}
      >
        <Description
          color={disabled ? COLORS.TEXT_COLORS.GREY : COLORS.TEXT_COLORS.SECONDARY}
          fontFamily="semibold"
        >
          {COURIER_NEW_TIP_COPY.CUSTOM_AMOUNT_LINK}
        </Description>
      </Pressable>
      {isCustomAmountShown ? (
        <CustomAmountInput
          centered={amountCentered}
          editTipAmount={setCustomTipAmount}
          initialTipAmount={hasInitialCustomAmount ? tipAmount : 0}
          inputRef={inputRef}
        />
      ) : null}
    </>
  );
};
