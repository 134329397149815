import { EventTypes } from 'redux-segment';
import { updateOrder } from '~shared/actions/cart';
import { PAYMENT_METHOD_TYPES, type PaymentMethodType } from '~shared/constants';
import { destroy, get, post } from '~shared/helpers/apiHelper';
import { type PaymentMethod, type ReduxDispatch, type ReduxStateShared, type ReduxThunkAction } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

type Token = {
  id: string;
  card: {
    id: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    brand: string;
    native_pay_type?: string | null;
  };
};

export type CardData = {
  id?: number | null;
  token: Token;
  paymentMethodType?: PaymentMethodType;
};

export type PlaidData = {
  publicToken: string;
  accountId: string;
  institutionName: string;
  paymentMethodType?: PaymentMethodType;
};

type StripeData = {
  card_id: string;
  card_expiration_month: number;
  card_expiration_year: number;
  card_last_4: string;
  card_type: string;
  token: string;
  native_pay_type?: string | null;
};

const generateStripeData = (token: Token): StripeData => ({
  card_expiration_month: token.card.exp_month,
  card_expiration_year: token.card.exp_year,
  card_last_4: token.card.last4,
  card_type: token.card.brand,
  card_id: token.card.id,
  token: token.id,
  native_pay_type: token.card.native_pay_type,
});

/**
 * Fetch payment methods action
 */
export const FETCH_PAYMENT_METHODS_LOADING = 'FETCH_PAYMENT_METHODS_LOADING';

type ActionFetchPaymentMethodsLoading = {
  type: typeof FETCH_PAYMENT_METHODS_LOADING;
};

export const fetchingPaymentMethods = (): ActionFetchPaymentMethodsLoading => ({
  type: FETCH_PAYMENT_METHODS_LOADING,
});

/**
 * Fetch payment methods success action
 */
export const FETCH_PAYMENT_METHODS_SUCCEEDED = 'FETCH_PAYMENT_METHODS_SUCCEEDED';

type ActionFetchPaymentMethodsSucceeded = {
  type: typeof FETCH_PAYMENT_METHODS_SUCCEEDED;
  payload: PaymentMethod[];
};

export const fetchPaymentMethodsSucceeded = (paymentMethods: PaymentMethod[]): ActionFetchPaymentMethodsSucceeded => ({
  type: FETCH_PAYMENT_METHODS_SUCCEEDED,
  payload: paymentMethods,
});

/**
 * Fetch payment methods failed action
 */
export const FETCH_PAYMENT_METHODS_FAILED = 'FETCH_PAYMENT_METHODS_FAILED';

type ActionFetchPaymentMethodsFailed = {
  type: typeof FETCH_PAYMENT_METHODS_FAILED;
  payload: APIError;
  error: true;
};

export const fetchPaymentMethodsFailed = (error: APIError): ActionFetchPaymentMethodsFailed => ({
  type: FETCH_PAYMENT_METHODS_FAILED,
  payload: error,
  error: true,
});

/**
 * Create payment method loading action
 */
export const CREATE_PAYMENT_METHOD_LOADING = 'CREATE_PAYMENT_METHOD_LOADING';

type ActionCreatePaymentMethodLoading = {
  type: typeof CREATE_PAYMENT_METHOD_LOADING;
};

export const creatingPaymentMethod = (): ActionCreatePaymentMethodLoading => ({
  type: CREATE_PAYMENT_METHOD_LOADING,
});

type MetaAnalyticsTrack = {
  meta: {
    analytics: {
      eventType: 'track';
    };
  };
};

/**
 * Create payment method succeeded action
 */
export const CREATE_PAYMENT_METHOD_SUCCEEDED = 'CREATE_PAYMENT_METHOD_SUCCEEDED';

export type ActionCreatePaymentMethodSucceeded = {
  type: typeof CREATE_PAYMENT_METHOD_SUCCEEDED;
  payload: PaymentMethod;
} & MetaAnalyticsTrack;

export const createPaymentMethodSucceeded = (paymentMethod: PaymentMethod): ActionCreatePaymentMethodSucceeded => ({
  type: CREATE_PAYMENT_METHOD_SUCCEEDED,
  payload: paymentMethod,
  meta: {
    analytics: {
      eventType: EventTypes.track,
    },
  },
});

/**
 * Create payment method failed action
 */
export const CREATE_PAYMENT_METHOD_FAILED = 'CREATE_PAYMENT_METHOD_FAILED';

export type ActionCreatePaymentMethodFailed = MetaAnalyticsTrack & {
  type: typeof CREATE_PAYMENT_METHOD_FAILED;
  payload: APIError | string | undefined;
  error: true;
};

export const createPaymentMethodFailed = (error: APIError | string | undefined): ActionCreatePaymentMethodFailed => ({
  type: CREATE_PAYMENT_METHOD_FAILED,
  payload: error,
  error: true,
  meta: {
    analytics: {
      eventType: EventTypes.track,
    },
  },
});

/**
 * Delete payment method loading action
 */
export const DELETE_PAYMENT_METHOD_LOADING = 'DELETE_PAYMENT_METHOD_LOADING';

export type ActionDeletePaymentMethodLoading = {
  type: typeof DELETE_PAYMENT_METHOD_LOADING;
};

export const deletingPaymentMethod = (): ActionDeletePaymentMethodLoading => ({
  type: DELETE_PAYMENT_METHOD_LOADING,
});

/**
 * Delete payment method succeeded action
 */
export const DELETE_PAYMENT_METHOD_SUCCEEDED = 'DELETE_PAYMENT_METHOD_SUCCEEDED';

export type ActionDeletePaymentMethodSucceeded = {
  type: typeof DELETE_PAYMENT_METHOD_SUCCEEDED;
  payload: PaymentMethod;
} & MetaAnalyticsTrack;

export const deletePaymentMethodSucceeded = (paymentMethod: PaymentMethod): ActionDeletePaymentMethodSucceeded => ({
  type: DELETE_PAYMENT_METHOD_SUCCEEDED,
  payload: paymentMethod,
  meta: {
    analytics: {
      eventType: EventTypes.track,
    },
  },
});

/**
 * Delete payment method failed action
 */
export const DELETE_PAYMENT_METHOD_FAILED = 'DELETE_PAYMENT_METHOD_FAILED';

export type ActionDeletePaymentMethodFailed = {
  type: typeof DELETE_PAYMENT_METHOD_FAILED;
  payload: APIError;
  error: true;
} & MetaAnalyticsTrack;

export const deletePaymentMethodFailed = (error: APIError): ActionDeletePaymentMethodFailed => ({
  type: DELETE_PAYMENT_METHOD_FAILED,
  payload: error,
  error: true,
  meta: {
    analytics: {
      eventType: EventTypes.track,
    },
  },
});

/**
 * Clear payment method error action
 */
export const CLEAR_PAYMENT_METHOD_ERROR = 'CLEAR_PAYMENT_METHOD_ERROR';

export type ActionClearPaymentMethodError = {
  type: typeof CLEAR_PAYMENT_METHOD_ERROR;
};

export const clearPaymentMethodError = (): ActionClearPaymentMethodError => ({
  type: CLEAR_PAYMENT_METHOD_ERROR,
});

type FetchPaymentMethodsActions =
  | ActionFetchPaymentMethodsLoading
  | ActionFetchPaymentMethodsFailed
  | ActionFetchPaymentMethodsSucceeded;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const fetchPaymentMethods: () => ReduxThunkAction<
  Promise<boolean>,
  ReduxStateShared,
  FetchPaymentMethodsActions
> = () => async (dispatch) => {
  dispatch(fetchingPaymentMethods());

  return get('/payment_methods').then((json) => {
    if (json.error) {
      dispatch(fetchPaymentMethodsFailed(json.error));
      return false;
    }

    dispatch(fetchPaymentMethodsSucceeded(json));
    return true;
  });
};

type CreatePaymentMethodActions =
  | ActionCreatePaymentMethodLoading
  | ActionCreatePaymentMethodFailed
  | ActionCreatePaymentMethodSucceeded;

type PlaidInfo = {
  public_token: string;
  account_id: string;
  institution_name: string;
};

export type CreatePaymentMethodRequest = {
  type?: string;
  plaid_data?: PlaidInfo;
  stripe_data?: Record<string, any>;
};

export const createPaymentMethod: (
  paymentMethod: CreatePaymentMethodRequest,
  paymentMethodType?: PaymentMethodType,
) => ReduxThunkAction<Promise<boolean>, ReduxStateShared, CreatePaymentMethodActions> =
  (paymentMethod, paymentMethodType) => async (dispatch) => {
    dispatch(creatingPaymentMethod());

    return post('/payment_methods', paymentMethod).then((json) => {
      if (json.error) {
        dispatch(createPaymentMethodFailed(json.error));
        return false;
      }

      dispatch(createPaymentMethodSucceeded(json));

      if (paymentMethodType === PAYMENT_METHOD_TYPES.PRIMARY) {
        dispatch(
          updateOrder({
            payment_method_id: json.id,
          }),
        );
      }

      if (paymentMethodType === PAYMENT_METHOD_TYPES.SECONDARY) {
        dispatch(
          updateOrder({
            second_payment_method_id: json.id,
          }),
        );
      }

      if (paymentMethodType === PAYMENT_METHOD_TYPES.PRE_TIP || paymentMethodType === PAYMENT_METHOD_TYPES.POST_TIP) {
        dispatch(
          updateOrder({
            tip_payment_method_id: json.id,
          }),
        );
      }
      return true;
    });
  };

type DeletePaymentMethodActions =
  | ActionDeletePaymentMethodLoading
  | ActionDeletePaymentMethodFailed
  | ActionDeletePaymentMethodSucceeded;

export const deletePaymentMethod: (
  paymentMethod: PaymentMethod,
) => ReduxThunkAction<Promise<boolean>, ReduxStateShared, DeletePaymentMethodActions> =
  (paymentMethod) => async (dispatch) => {
    dispatch(deletingPaymentMethod());

    return destroy(`/payment_methods/${paymentMethod.id}`).then((json) => {
      if (json.error) {
        dispatch(deletePaymentMethodFailed(json.error));
        return false;
      }

      dispatch(deletePaymentMethodSucceeded(json));
      return true;
    });
  };

export const createCreditCardPaymentMethod = ({ token, paymentMethodType }: CardData) => {
  return (dispatch: ReduxDispatch) => {
    const stripe_data = generateStripeData(token);

    return dispatch(
      createPaymentMethod(
        {
          type: 'credit',
          stripe_data,
        },
        paymentMethodType,
      ),
    );
  };
};

export const createBankAccountPaymentMethod = ({
  publicToken,
  accountId,
  institutionName,
  paymentMethodType,
}: PlaidData) => {
  return (dispatch: ReduxDispatch) => {
    return dispatch(
      createPaymentMethod(
        {
          type: 'ach',
          plaid_data: {
            public_token: publicToken,
            account_id: accountId,
            institution_name: institutionName,
          },
        },
        paymentMethodType,
      ),
    );
  };
};
