import { COLORS } from '@alto/design-library-tokens';
import React from 'react';
import { type StationType, type Status, type VerticalEnabled } from '../../types';
import { AfterRail } from './AfterRail';
import { BeforeRail } from './BeforeRail';
import { type RailColors } from './types';

type Props = {
  readonly position: 'before' | 'after';
  readonly type: StationType;
  readonly status: Status;
  readonly verticalEnabled: VerticalEnabled;
};

export const Rail = ({ position, type, status, verticalEnabled }: Props) => {
  let color: RailColors = COLORS.BORDER_COLORS.LIGHT;
  if (type === 'completed') {
    color = COLORS.BORDER_COLORS.SUCCESS;
  }
  if (type === 'active' && position === 'before') {
    color = status === 'failed' ? COLORS.BORDER_COLORS.DANGER : COLORS.BORDER_COLORS.SUCCESS;
  }
  if (type === 'failed') {
    color = COLORS.BORDER_COLORS.DANGER;
  }

  switch (position) {
    case 'before':
      return (
        <BeforeRail
          color={color}
          verticalEnabled={verticalEnabled}
        />
      );
    case 'after':
      return (
        <AfterRail
          color={color}
          verticalEnabled={verticalEnabled}
        />
      );
    default:
      return null;
  }
};
