import { withRehydration } from './withRehydration';
import { FETCH_FACILITIES_SUCCEEDED, type FacilitiesActions } from '~shared/actions/facilities';
import { type Facility } from '~shared/types/facility';

export type FacilitiesState = {
  facilities: Facility[];
};

const initialState: FacilitiesState = {
  facilities: [],
};

const facilities = (state: FacilitiesState = initialState, action: FacilitiesActions): FacilitiesState => {
  switch (action.type) {
    case FETCH_FACILITIES_SUCCEEDED:
      return { ...state, facilities: action.payload };

    default:
      return state;
  }
};

export default withRehydration(facilities, initialState);
