import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import React from 'react';
import { type TextProps as NativeTextProps, Platform, Text as RNText, type TextStyle } from 'react-native';
import styled from 'styled-components/native';

export type FontFamily = 'regular' | 'semibold' | 'bold' | 'italic' | 'semiboldItalic' | 'boldItalic';

export type TextTransform = 'capitalize' | 'uppercase';

export type TextSize = 'mini' | 'large' | 'detail' | 'default';

export type TextProps = NativeTextProps & {
  readonly textTransform?: TextTransform;
  readonly textSize?: TextSize;
  readonly fontFamily?: FontFamily;
  readonly textDecorationLine?: TextStyle['textDecorationLine'];
  readonly center?: boolean;
  readonly color?: string;
  readonly tight?: boolean;
  readonly selectable?: boolean;
  readonly flexShrink?: number;
  readonly focusable?: boolean;
  readonly onPress?: () => void;
  testID?: string;
  /**
   * Sets flex-shrink to 1.
   */
  readonly contain?: boolean;
  readonly ref?: React.ForwardedRef<RNText>;
};

// when using multiple text components together in a row
// they cause each other to go off-screen.
// applying flex-shrink prevents this.
const StyledText = styled(RNText)<TextProps>`
  color: ${({ color }) => color || COLORS.TEXT_COLORS.BLACK};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  ${({ center }) => {
    if (Platform.OS === 'web' && center) {
      return `display: block;`;
    }
    return ``;
  }}
  font-family: ${({ fontFamily }) => {
    switch (fontFamily) {
      case 'regular':
        return TYPOGRAPHY.FONT.BODY.REGULAR;
      case 'semibold':
        return TYPOGRAPHY.FONT.BODY.SEMIBOLD;
      case 'bold':
        return TYPOGRAPHY.FONT.BODY.BOLD;
      case 'italic':
        return TYPOGRAPHY.FONT.BODY.REGULAR_ITALIC;
      case 'semiboldItalic':
        return TYPOGRAPHY.FONT.BODY.SEMIBOLD_ITALIC;
      case 'boldItalic':
        return TYPOGRAPHY.FONT.BODY.BOLD_ITALIC;
      default:
        return TYPOGRAPHY.FONT.BODY.REGULAR;
    }
  }};
  font-size: ${({ textSize }) => {
    switch (textSize) {
      case 'mini':
        return TYPOGRAPHY.TEXT.BODY.SM.px;
      case 'large':
        return TYPOGRAPHY.TEXT.BODY.LG.px;
      case 'detail':
        return TYPOGRAPHY.TEXT.DETAIL.LG.px;
      default:
        return TYPOGRAPHY.TEXT.BODY.MD.px;
    }
  }};
  font-style: ${({ fontFamily }) =>
    fontFamily === 'boldItalic' || fontFamily === 'italic' || fontFamily === 'semiboldItalic' ? `italic` : `normal`};
  line-height: ${({ tight, textSize }) => {
    if (tight || textSize === 'mini' || textSize === 'detail') {
      return TYPOGRAPHY.TEXT.BODY.SM.value * TYPOGRAPHY.LINE_HEIGHT.MD;
    }
    return TYPOGRAPHY.TEXT.BODY.MD.value * TYPOGRAPHY.LINE_HEIGHT.LG;
  }}px;
  letter-spacing: ${TYPOGRAPHY.LETTER_SPACING.MD.px};
  ${({ textTransform }) => textTransform === 'uppercase' && 'text-transform: uppercase;'}
  ${({ textTransform }) => textTransform === 'capitalize' && 'text-transform: capitalize;'}
  ${({ contain }) => contain && 'flex-shrink: 1;'}
  ${({ flexShrink }) => (flexShrink ? `flex-shrink: ${flexShrink};` : '')}
  ${({ textDecorationLine }) => textDecorationLine && `text-decoration-line: ${textDecorationLine};`}
`;

// eslint-disable-next-line react/display-name
export const Text = React.forwardRef<RNText, TextProps>((props: TextProps, forwardedRef) => {
  const textProps = { selectable: true, ...props };
  return (
    <StyledText
      {...textProps}
      focusable={Platform.OS === 'web' ? textProps.focusable : undefined}
      ref={forwardedRef}
      accessibilityRole="text"
    />
  );
});
