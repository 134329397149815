// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  Body,
  Column,
  Description,
  LgPadding,
  LgSpacing,
  Row,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import React from 'react';
import styled from 'styled-components/native';
import { MedSyncCalendarImage } from './MedSyncCalendarImage';
import { IMAGE_DIMENSIONS } from './constants';

const ImageContainer = styled(Column)`
  width: 100%;
  max-width: ${IMAGE_DIMENSIONS.width}px;
`;

export const PartialFillActionSheet = () => {
  return (
    <ActionSheetV2
      analyticsName="med sync partial fill"
      title="Partial medication fills"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>To reduce your delivery frequency, we bundle your auto refill medications when possible.</Body>
        <SmSpacing />
        <Body>
          You might receive partial fills for some medications while we sync your deliveries, but you’ll never run out
          and you’ll only pay for what you receive until the full bundle arrives.
        </Body>
        <LgSpacing />
        <Row centerHorizontally>
          <ImageContainer left>
            <MedSyncCalendarImage />
            <XsSpacing />
            <Description fontFamily="italic">Example delivery schedule</Description>
          </ImageContainer>
        </Row>
      </LgPadding>
    </ActionSheetV2>
  );
};
