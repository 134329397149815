import { BORDERS, COLORS, SIZES } from '@alto/design-library-tokens';
import { AltoIcon, Border, Column, Illustration } from '@alto/design-system';
import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';

const PlaceholderContainer = styled(Column)`
  width: ${SIZES.ILLUSTRATION.SM.px};
  height: ${SIZES.ILLUSTRATION.SM.px};
`;

type Props = {
  readonly imageUrl: string | undefined | null;
};

export const MedicationImage = ({ imageUrl }: Props) =>
  imageUrl ? (
    <Illustration
      size="sm"
      source={
        Platform.OS === 'android' || Platform.OS === 'ios'
          ? {
              uri: imageUrl,
            }
          : imageUrl
      }
      borderRadius={BORDERS.RADIUS.MD.value}
      accessibilityLabel={IMG_ALT_TEXTS.medication}
    />
  ) : (
    <PlaceholderContainer>
      <Border
        radius="MD"
        hideTop
        hideBottom
        hideLeft
        hideRight
      >
        <PlaceholderContainer
          center
          backgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER}
        >
          <AltoIcon
            name="pills-duo"
            accessibilityLabel="pills"
          />
        </PlaceholderContainer>
      </Border>
    </PlaceholderContainer>
  );
