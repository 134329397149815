// @owners { team: patients-team }
import { type ReduxStateShared } from '~shared/types';

export const getDeliveries = (state: ReduxStateShared) => state.deliveries.deliveries;

export const getLastRequestAttemptedAt = (state: ReduxStateShared) => state.deliveries.lastRequestAttemptedAt;

export const getRequestAttemptCount = (state: ReduxStateShared) => state.deliveries.requestAttemptCount;

export const getLastRequestSucceededAt = (state: ReduxStateShared) => state.deliveries.lastRequestSucceededAt;
