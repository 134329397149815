// @owners { team: patients-team }
import { useEffect } from 'react';
import { updateSupportCaseWithUnreadMessages } from '~shared/actions/messages';
import { transformWebsocketMessagePayload } from '~shared/features/messages/helpers/transformWebsocketMessagePayload';
import { getSupportCasesWithUnreadMessages } from '~shared/features/messages/selectors/getMessages';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import socket from '~shared/websocket/socket';

// tracks any new wundercom messages coming in from websocket to show unread messages status indicator
export const useWundercomUnreadMessages = () => {
  const dispatch = useDispatchShared();
  const supportCasesWithUnreadMessages = useSelectorShared(getSupportCasesWithUnreadMessages);
  const user = useSelectorShared(getCurrentUser);
  const email = user?.email || '';

  useEffect(() => {
    const wundercomChannelName = `users:${email}:wundercom`;

    const unsub = socket.bind(wundercomChannelName, (payload) => {
      if (payload.event_type === 'create') {
        const wundercomMessage = transformWebsocketMessagePayload(payload);
        if (
          wundercomMessage.admin_user_id &&
          !wundercomMessage.read_at &&
          typeof wundercomMessage.support_case_id === 'number'
        ) {
          const updatedSet = new Set(supportCasesWithUnreadMessages);

          updatedSet.add(wundercomMessage.support_case_id);
          dispatch(updateSupportCaseWithUnreadMessages(updatedSet));
        }
      }
    });

    return () => {
      unsub();
    };
  }, [dispatch, email, supportCasesWithUnreadMessages]);
};
