export const CHECKOUT_NOTICE = 'CheckoutNotice';
export const FAMILY_ACCOUNT_BETA_NOTICE = 'FamilyAccountBetaNotice';
export const AUTO_REFILL_NOTICE = 'AutoRefillNotice';
export const REMINDERS_NOTICE = 'RemindersNotice';
export const AUTO_REFILL_NUDGE_CARD_NOTICE = 'AutoRefillNudgeCardNotice';
export const REFILL_CROSS_SELL_NOTICE = 'AutoRefillCrossSellNotice';
export const POST_DELIVERY_MODAL_NOTICE = 'PostDeliveryModalNotice';
export const AUTO_REFILL_MANAGEMENT_NOTICE = 'AutoRefillManagementNotice';

export type NoticeNames =
  | typeof CHECKOUT_NOTICE
  | typeof FAMILY_ACCOUNT_BETA_NOTICE
  | typeof AUTO_REFILL_NOTICE
  | typeof REMINDERS_NOTICE
  | typeof AUTO_REFILL_NUDGE_CARD_NOTICE
  | typeof REFILL_CROSS_SELL_NOTICE
  | typeof POST_DELIVERY_MODAL_NOTICE
  | typeof AUTO_REFILL_MANAGEMENT_NOTICE;

export type ViewedNotices = Record<NoticeNames, string | null | undefined>;
