// @owners { team: patients-team }
import { type SortType } from '@alto/scriptdash/alto/medications/patients/v1/types';
import { type FilterType, ListEndpoint } from '@alto/scriptdash/alto/medications/patients/v2/list_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { apiEndpointHandler } from '~shared/helpers/api';
import {
  getAppliedMedListFilters,
  getIncludedFamilyMemberPatientIDs,
  getMedListFilters,
} from '~shared/selectors/ui/medList';
import { useSelectorShared } from '~shared/store';

const medListEndpoint = ListEndpoint(apiEndpointHandler);

type MedListParams = {
  patientIDs: number[] | null;
  sortType: SortType;
  included_filters: FilterType[];
};

export const medications = createQueryKeys('medications', {
  list: ({ patientIDs, sortType, included_filters }: MedListParams) => {
    return {
      queryKey: [{ patientIDs, sortType, included_filters }],
      queryFn: async ({ pageParam }: QueryFunctionContext<QueryKey, string>) => {
        if (!patientIDs) {
          return { medications: [], nextPageToken: undefined, totalCount: 0 };
        }

        const response = await medListEndpoint.fetchAll(
          {
            included_filters,
            included_patient_ids: patientIDs,
            pagination: { page_size: 10, page_token: pageParam },
            sort_type: sortType,
          },
          { splitArrayParams: true },
        );

        return {
          medications: response.data,
          nextPageToken: response.metadata?.pagination?.next_page_token,
          totalCount: response.metadata?.pagination?.total_count,
        };
      },
    };
  },
});

const useBuildListFetchParams = (): MedListParams => {
  const patientIDs = useSelectorShared(getIncludedFamilyMemberPatientIDs);
  const sortType = useSelectorShared((state) => state.persistedUI.medList.sortType);

  const appliedFilters = useSelectorShared(getAppliedMedListFilters);

  return {
    patientIDs,
    sortType,
    included_filters: appliedFilters,
  };
};

export const useMedList = () => {
  const listFetchParams = useBuildListFetchParams();
  const { patientIDs } = listFetchParams;
  const filters = useSelectorShared(getMedListFilters);
  const areFiltersApplied = !filters.ALL;

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isPending, refetch } = useInfiniteQuery<{
    medications: [];
    nextPageToken: string;
  }>({
    ...medications.list(listFetchParams),
    initialPageParam: '0',
    getNextPageParam: (lastPage) => {
      if (!lastPage.nextPageToken) return undefined;
      return lastPage.nextPageToken;
    },
    enabled: patientIDs?.length ? patientIDs.length > 0 : false,
    select: ({ pageParams, pages }) => {
      // ⚡ hijack `pages` to flatten the array of medications
      return { pageParams, pages: pages.flatMap(({ medications }) => medications) };
    },
  });

  return {
    areFiltersApplied,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isPendingMedications: isPending,
    medications: data?.pages,
    refetch,
  };
};

export const useInvalidateMedList = () => {
  const { patientIDs, sortType, included_filters } = useBuildListFetchParams();
  const queryClient = useQueryClient();
  const invalidateListQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: medications.list({ patientIDs, sortType, included_filters }).queryKey,
    });
  }, [patientIDs, queryClient, sortType, included_filters]);
  return {
    invalidateListQuery,
  };
};
