// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  InputBlockSelect,
  InputBlockText,
  InputRow,
  LoadingButton,
  Text,
  createValidator,
  validatePassword,
  validatePhone,
  validatePresent,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { closeModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { clearUserError, updateUser } from '~shared/actions/users';
import { phoneTypes } from '~shared/constants';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getErrorMessage } from '~shared/helpers/helper';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import getUserError from '~shared/selectors/ui/errors/getUserError';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type User } from '~shared/types/clients';
import { validateEmail } from '~shared/validations';

const PHONE_TYPE_OPTIONS = phoneTypes.map((type) => ({
  label: type,
  value: type.toLowerCase(),
}));

type Values = {
  phone: string;
  phone_type: string;
  secondary_contact_phone: string;
  secondary_phone_type: string;
  email: string;
  password: string;
  password_confirmation: string;
};

const validator = createValidator({
  email: [validatePresent, validateEmail],
  phone: [validatePresent, validatePhone],
});

function validate({ phone, email, password, password_confirmation }: Values) {
  const errors: Partial<Record<keyof Values, string>> = validator({
    phone,
    email,
  });

  if (validatePassword(password)) {
    errors.password = validatePassword(password);
  }

  if (password && password !== password_confirmation) {
    errors.password_confirmation = 'Passwords must match.';
  }

  return errors;
}

function getInitialValues(user: User | undefined) {
  const { phone, phone_type, secondary_contact_phone, secondary_phone_type, email } = user || {};

  return {
    phone: phone || '',
    phone_type: phone_type || '',
    secondary_contact_phone: secondary_contact_phone || '',
    secondary_phone_type: secondary_phone_type || '',
    email: email || '',
    password: '',
    password_confirmation: '',
  };
}

type Props = {
  readonly onClose: () => void;
};

const getFormattedValues = (values: Values) => {
  if (values.phone) {
    values.phone = values.phone.replace(/\D/g, '');
  }

  if (values.secondary_contact_phone) {
    values.secondary_contact_phone = values.secondary_contact_phone.replace(/\D/g, '');
  }

  const formattedValues: Record<string, string> = {};
  for (const [key, value] of Object.entries(values)) {
    if (value !== '') {
      formattedValues[key as keyof Values] = value;
    }
  }
  return formattedValues;
};

export const ContactInfoModalContent = ({ onClose }: Props) => {
  const dispatch = useDispatchShared();
  const user = useSelectorShared(getCurrentUser);
  const error = useSelectorShared(getUserError);
  const loading = useSelectorShared((state) => state.ui.loading.updateUserLoading);

  const onSubmit = useCallback(
    (values: Values) => {
      const formattedValues = getFormattedValues(values);
      // eslint-disable-next-line import/no-deprecated, promise/catch-or-return
      dispatch(updateUser(formattedValues)).then((res: { success: boolean }) => {
        // eslint-disable-next-line promise/always-return
        if (res.success) {
          dispatch(clearUserError());
          dispatch(closeModal('CONTACT_INFO_MODAL'));
        }
      });
    },
    [dispatch],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      validate={validate}
      initialValues={getInitialValues(user)}
    >
      <Form noValidate>
        <DeprecatedModalBody>
          <InputRow>
            <Field name="phone">
              {({ field, meta }: FieldProps<Values['phone']>) => (
                <InputBlockText
                  required
                  label="Phone number"
                  id="phone"
                  type="tel"
                  placeholder="(555) 555-5555"
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
            <Field name="phone_type">
              {({ field, meta, form: { setFieldValue } }: FieldProps<Values['phone_type']>) => (
                <InputBlockSelect
                  label="Phone type"
                  id="phone_type"
                  options={PHONE_TYPE_OPTIONS}
                  input={{
                    value: field.value,
                    onChange: (value) => {
                      setFieldValue(field.name, value);
                    },
                  }}
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
          <InputRow>
            <Field name="secondary_contact_phone">
              {({ field, meta }: FieldProps<Values['secondary_contact_phone']>) => (
                <InputBlockText
                  label="Alternate phone number"
                  id="secondary_contact_phone"
                  type="tel"
                  placeholder="(555) 555-5555"
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
            <Field name="secondary_phone_type">
              {({ field, meta, form: { setFieldValue } }: FieldProps<Values['secondary_phone_type']>) => (
                <InputBlockSelect
                  label="Alternate phone type"
                  id="secondary_phone_type"
                  options={PHONE_TYPE_OPTIONS}
                  input={{
                    value: field.value,
                    onChange: (value) => {
                      setFieldValue(field.name, value);
                    },
                  }}
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
          <InputRow>
            <Field name="email">
              {({ field, meta }: FieldProps<Values['email']>) => (
                <InputBlockText
                  required
                  label="Email"
                  id="email"
                  type="email"
                  placeholder="username@email.com"
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
          <InputRow>
            <Field name="password">
              {({ field, meta }: FieldProps<Values['password']>) => (
                <InputBlockText
                  label="New password"
                  id="password"
                  type="password"
                  placeholder="•••••••••••••"
                  autoComplete="new-password"
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
          <InputRow>
            <Field name="password_confirmation">
              {({ field, meta }: FieldProps<Values['password_confirmation']>) => (
                <InputBlockText
                  label="Confirm new password"
                  id="password_confirmation"
                  type="password"
                  placeholder="•••••••••••••"
                  autoComplete="new-password"
                  autoCapitalize="none"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
          {!!error && <Text danger>{getErrorMessage(error)}</Text>}
        </DeprecatedModalBody>
        <DeprecatedModalFooter>
          <Button
            kind="tertiary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            kind="primary"
            type="submit"
            loading={loading}
          >
            Save
          </LoadingButton>
        </DeprecatedModalFooter>
      </Form>
    </Formik>
  );
};
