// @owners { team: patients-team }
import { type AltoIconName, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  AltoIcon,
  Body,
  LgPadding,
  ListBase,
  PresentationListItem,
  SmSpacing,
} from '@alto/design-system';
import React from 'react';
import autoRefill from '~shared/assets/images/auto_refill.png';
import { AUTO_REFILL_INFO_ACTION_SHEET } from '~shared/features/auto-refill/constants';
export const AutoRefillInfoActionSheet = () => {
  const autoRefillSteps = AUTO_REFILL_INFO_ACTION_SHEET.map((step) => {
    return {
      key: step.key,
      component: (
        <PresentationListItem
          LeftContent={<AltoIcon name={step.icon as AltoIconName} />}
          text={
            <>
              <Body fontFamily="semibold">{step.subtitle}: </Body>
              <Body>{step.text}</Body>
            </>
          }
          fullBleed
        />
      ),
    };
  });

  return (
    <ActionSheetV2
      title="How auto refill works"
      illustrationSrc={autoRefill}
      analyticsName="auto refill info"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          Auto refill makes your life easier by automatically sending your medication when you need it—one less thing
          for you to remember!
        </Body>
        <SmSpacing />
        <ListBase items={autoRefillSteps} />
      </LgPadding>
    </ActionSheetV2>
  );
};
