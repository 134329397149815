// @owners { team: patients-team }
import { BORDERS, SIZES } from '@alto/design-library-tokens';
import { Column, Row, SizedImage, XsSpacing, useScreenSize } from '@alto/design-system';
import React from 'react';

export const InsuranceImages = ({
  imageFrontUrl,
  imageBackUrl,
}: {
  readonly imageFrontUrl: string | undefined;
  readonly imageBackUrl: string | undefined;
}) => {
  const { isSMScreen } = useScreenSize();

  if (isSMScreen) {
    return (
      <Column testID="insurance-images">
        {imageFrontUrl ? (
          <SizedImage
            borderRadius={BORDERS.RADIUS.MD.value}
            pixelSize={SIZES.ILLUSTRATION.XXL.value}
            maxHeight={SIZES.ILLUSTRATION.XL.value}
            resizeMode="cover"
            source={{ uri: imageFrontUrl }}
          />
        ) : null}
        <XsSpacing />
        {imageBackUrl ? (
          <SizedImage
            borderRadius={BORDERS.RADIUS.MD.value}
            pixelSize={SIZES.ILLUSTRATION.XXL.value}
            maxHeight={SIZES.ILLUSTRATION.XL.value}
            resizeMode="cover"
            source={{ uri: imageBackUrl }}
          />
        ) : null}
      </Column>
    );
  }

  return (
    <Row
      testID="insurance-images"
      spaceEvenly
    >
      {imageFrontUrl ? (
        <SizedImage
          borderRadius={BORDERS.RADIUS.MD.value}
          pixelSize={SIZES.ILLUSTRATION.XXL.value}
          maxHeight={SIZES.ILLUSTRATION.XL.value}
          resizeMode="cover"
          source={{ uri: imageFrontUrl }}
        />
      ) : null}
      {imageBackUrl ? (
        <SizedImage
          borderRadius={BORDERS.RADIUS.MD.value}
          pixelSize={SIZES.ILLUSTRATION.XXL.value}
          maxHeight={SIZES.ILLUSTRATION.XL.value}
          resizeMode="cover"
          source={{ uri: imageBackUrl }}
        />
      ) : null}
    </Row>
  );
};
