// @owners { team: patients-team }
export const ASSISTANT_CHAT_ORIGINS = {
  ADD_FAMILY_MEMBER_ACTION_SHEET: 'add_family_member_action_sheet',
  ALTO_ASSISTANT_LANDING_PAGE: 'alto_assistant_landing_page',
  ALTO_ASSISTANT_CHOOSE_CATEGORY_MENU: 'alto_assistant_choose_category_menu',
  ALTO_ASSISTANT_PAYMENTS_MENU: 'alto_assistant_payments_menu',
  ALTO_ASSISTANT_CHOOSE_DELIVERY_MENU: 'alto_assistant_choose_delivery_menu',
  ALTO_ASSISTANT_EDIT_DELIVERY_ACTION_MENU: 'alto_assistant_edit_delivery_action_menu',
  ALTO_ASSISTANT_CHOOSE_DELIVERY_ACTION_MENU: 'alto_assistant_choose_delivery_action_menu', // Upcoming deliveries action menu
  ALTO_ASSISTANT_PAST_DELIVERY_ACTION_MENU: 'alto_assistant_past_delivery_action_menu', // Past deliveries action menu
  ALTO_ASSISTANT_PAST_DELIVERY_REPORT_ISSUE_ACTION_MENU: 'alto_assistant_past_delivery_report_issue_action_menu',
  ALTO_ASSISTANT_MED_SELECTION: 'alto_assistant_med_selection',
  ALTO_ASSISTANT_ORDER_IN_PROGRESS: 'alto_assistant_order_in_progress',
  ALTO_ASSISTANT_WHAT_MEDICATION_QUESTION: 'alto_assistant_what_medication_question',
  ALTO_UPDATES: 'alto_updates',
  ACCOUNT: 'alto_assistant_account_action_menu',
  CHECKOUT: 'checkout',
  CLOSED_SUPPORT_CASE: 'closed_support_case',
  EDIT_ORDER_SCREEN_MORE_HELP_NEEDED: 'edit_order_screen_more_help_needed',
  FAQ: 'single_faq',
  LOST_MEDICATION_OVERRIDE: 'lost_medication_override',
  NEXT_AVAILABLE_DATE_ACTION_SHEET: 'next_available_date_action_sheet',
  MED_DETAILS: 'med_details',
  MED_LIST: 'med_list',
  MEDICATIONS: 'alto_assistant_medications_action_menu',
  OPTIONS_TO_GET_IT_SOONER_ACTION_SHEET: 'options_to_get_it_sooner_action_sheet',
  ORDERING: 'alto_assistant_ordering_action_menu',
  PRESCRIPTION_STATUS_PAGE: 'prescription_status_page',
  PRICING_PAYMENTS: 'alto_assistant_pricing_payments_action_menu',
  REMOVE_MEDICATION: 'remove_medication',
  SUPPORT_PAGE: 'support_page', // NOTE: v2 of alto assistant landing page
  SUPPORT_CASE_PAGE: 'support_case_page', // NOTE: page to view support case and messages
  SUPPORT_CASE_INBOX: 'support_case_inbox', // NOTE: "Messages" tab for support case patients
  TRANSFER: 'alto_assistant_transfer_action_menu',
  TRANSFER_FORM: 'transfer_form',
  VACATION_OVERRIDE: 'vacation_override',
} as const;
export type AssistantChatOrigin = (typeof ASSISTANT_CHAT_ORIGINS)[keyof typeof ASSISTANT_CHAT_ORIGINS] | 'unknown';

export const ASSISTANT_PAGE_ORIGINS = {
  NAVIGATION_TAB: 'navigation_tab',
  ACCOUNT_TAB: 'account_tab',
  MESSAGES_TAB: 'messages_tab',
  DEEP_LINK: 'deep_link',
  EDIT_ORDER_SCREEN: 'edit_order_screen',
  ACCOUNT_PAGE: 'account_page',
  HOMESCREEN_ICON: 'homescreen_icon',
} as const;
export type AssistantPageOrigin = (typeof ASSISTANT_PAGE_ORIGINS)[keyof typeof ASSISTANT_PAGE_ORIGINS];

export const ASSISTANT_ACCOUNT_ACTIONS = {
  ADD_FAMILY_MEMBER: 'add_family_member',
  CONTACT_INFO: 'contact_info',
  DOWNLOAD_RECEIPTS: 'download_receipts',
  INSURANCE: 'insurance',
  PAYMENT_METHODS: 'payment_methods',
  SOMETHING_ELSE: 'something_else',
} as const;
export type AssistantAccountAction = (typeof ASSISTANT_ACCOUNT_ACTIONS)[keyof typeof ASSISTANT_ACCOUNT_ACTIONS];

export const ASSISTANT_DELIVERY_ACTIONS = {
  ADD_MEDICATION: 'add_medication',
  DELIVERY_DATE_OR_TIME: 'delivery_date_or_time',
  PAYMENT: 'payment',
  DELIVERY_ADDRESS: 'delivery_address',
  DELIVERY_INSTRUCTIONS: 'delivery_instructions',
  CANCEL_ORDER: 'cancel_order',
  EDIT_DELIVERY: 'edit_delivery',
  NEVER_ARRIVED: 'never_arrived',
  MISSING_ITEMS: 'missing_items',
  INCORRECT_ITEMS: 'incorrect_items',
  DAMAGED_ITEMS: 'damaged_items',
  MEDICATION_CONSULTATION: 'medication_consultation',
  PRICING_QUESTION: 'pricing_question',
  REMOVE_MEDICATION: 'remove_medication',
  REPORT_ISSUE: 'report_issue',
  REQUEST_RECEIPT: 'request_receipt',
  SOMETHING_ELSE: 'something_else',
  TRACK_MY_DELIVERY: 'track_my_delivery',
  UPCOMING_DELIVERY: 'upcoming_delivery',
  WRONG_ADDRESS: 'wrong_address',
} as const;
export type AssistantDeliveryAction = (typeof ASSISTANT_DELIVERY_ACTIONS)[keyof typeof ASSISTANT_DELIVERY_ACTIONS];

export type AssistantPastDeliveryAction = 'report_issue' | 'pricing_question' | 'request_receipt' | 'something_else';
export type AssistantPastDeliveryIssue =
  | 'never_arrived'
  | 'missing_items'
  | 'incorrect_items'
  | 'damaged_items'
  | 'something_else';
export type AssistantEditDeliveryAction =
  | 'delivery_date_or_time'
  | 'payment'
  | 'delivery_address'
  | 'delivery_instructions'
  | 'add_medication'
  | 'remove_medication'
  | 'something_else';
export type AssistantUpcomingDeliveryAction =
  | 'track_my_delivery'
  | 'cancel_order'
  | 'edit_delivery'
  | 'pricing_question'
  | 'something_else';

export const ASSISTANT_ORDERING_ACTIONS = {
  NEED_TO_ORDER: 'need_to_order',
  UNABLE_TO_ORDER: 'unable_to_order',
  DIFFERENT_AMOUNT: 'different_amount',
  DATE_UNAVAILABLE: 'date_unavailable',
  SOMETHING_ELSE: 'something_else',
} as const;
export type AssistantOrderingAction = (typeof ASSISTANT_ORDERING_ACTIONS)[keyof typeof ASSISTANT_ORDERING_ACTIONS];

export const ASSISTANT_PAYMENTS_ACTIONS = {
  DOWNLOAD_RECEIPTS: 'download_receipts',
  GET_A_PRICE_QUOTE: 'get_a_price_quote',
  SOMETHING_ELSE: 'something_else',
  UNAVAILABLE_OR_PENDING: 'price_unavailable_or_pending',
  FSA_HSA_FUNDS: 'fsa_hsa_funds',
  INVESTIGATION_REQUEST: 'investigation_request',
} as const;
export type AssistantPaymentsAction = (typeof ASSISTANT_PAYMENTS_ACTIONS)[keyof typeof ASSISTANT_PAYMENTS_ACTIONS];

export const ASSISTANT_TRANSFER_ACTIONS = {
  PRICE_QUOTE: 'price_quote',
  TRANSFER_IN: 'transfer_in',
  TRANSFER_OUT: 'transfer_out',
  SOMETHING_ELSE: 'something_else',
} as const;
export type AssistantTransferAction = (typeof ASSISTANT_TRANSFER_ACTIONS)[keyof typeof ASSISTANT_TRANSFER_ACTIONS];

export const ASSISTANT_MEDICATIONS_ACTIONS = {
  MEDICATION_QUESTION: 'medication_question',
  STATUS_OF_NEW_PRESCRIPTION: 'status_of_new_prescription',
  PRICING: 'medication_pricing',
  SOMETHING_ELSE: 'something_else',
} as const;
export type AssistantMedicationsAction =
  (typeof ASSISTANT_MEDICATIONS_ACTIONS)[keyof typeof ASSISTANT_MEDICATIONS_ACTIONS];

export const PRICING_PAYMENTS_CATEGORY = 'pricing_payments';
export const ORDER_CATEGORY = 'ordering';
export const ACCOUNT_CATEGORY = 'account';

export const MESSAGE_PROMPTS = {
  ADD_FAMILY_MEMBER: 'Please include full name and date of birth for each family member.',
  ADD_MEDICATION: 'Let us know which medication(s) you need help ordering',
  DELIVERY_DAMAGED_ITEMS: 'Tell us more about the damaged item(s) you received',
  DELIVERY_INCORRECT_ITEMS: 'Tell us more about the incorrect item(s) you received',
  DELIVERY_MISSING_ITEMS: 'Tell us more about the item(s) you were expecting to receive',
  DELIVERY_NEVER_ARRIVED: 'Tell us more about your missing package',
  DELIVERY_WRONG_ADDRESS: 'Tell us more about your misdelivered package',
  ENCOUNTERED_APP_ERROR: 'I encountered an app error',
  HELP_WITH_ACCOUNT: 'How can we help you with your account?',
  HELP_WITH_MEDICATION: 'How can we help you with your medication',
  HELP_WITH_ORDER: 'How can we help you with your order?',
  HELP_WITH_PRICING_PAYMENTS: 'How can we help you with pricing & payments?',
  HELP_WITH_TRANSFER: 'How can we help you with a transfer?',
  LET_US_HELP: "Let us know how we can help and we'll assist you with your question.",
  ORDER_IN_PROGRESS: 'This order is already in progress, but we will try all we can to assist you ASAP.',
  PHARMACIST_SLA: 'Pharmacists will respond in 1 - 2 business day',
  PRICE_QUOTE: 'How can we help with your price quote?',
} as const;
export type AssistantMessagePrompt = (typeof MESSAGE_PROMPTS)[keyof typeof MESSAGE_PROMPTS];

export const LANDING_PAGE_TITLES = {
  ASSURE_NOTIFICATIONS_WHEN_READY: 'We’ll notify you when it’s ready',
};

export const ASSISTANT_WE_ARE_STILL_WORKING_ON_THINGS_ACTIONS = {
  SEND_MESSAGE: 'send_a_message',
  NOTIFY_ME: 'notify_me',
};

export const ASSISTANT_SHORTCUTS = {
  DOWNLOAD_RECEIPTS: 'download_receipts',
  TRANSFER_IN: 'transfer_in',
  EDIT_UPCOMING_DELIVERY: 'edit_upcoming_delivery',
  EDIT_INSURANCE: 'edit_insurance',
  FAQ: 'faq',
  PRICE_INVESTIGATION: 'price_investigation',
  SHARPS_DISPOSAL_CONTAINER: 'sharps_disposal_container',
} as const;
export type AssistantShortcut = (typeof ASSISTANT_SHORTCUTS)[keyof typeof ASSISTANT_SHORTCUTS];

export const ASSISTANT_MESSAGE_SUBJECTS = {
  ADD_FAMILY_MEMBER: 'Request to add family member(s)',
  ADD_MEDICATION: 'Add medication to my upcoming order',
  ACCOUNT: 'Help with my account',
  CANCEL_DELIVERY: 'I need to cancel an order',
  DATE_UNAVAILABLE: 'My preferred date is unavailable',
  DELIVERY: 'Help with an order',
  DIFFERENT_AMOUNT: 'I need a different amount',
  ENCOUNTERED_APP_ERROR: 'I encountered an app error',
  GET_IT_SOONER: 'Request to get medication sooner',
  GET_A_PRICE_QUOTE: 'Price quote request',
  HOLIDAY_CLOSURE: 'Upcoming Holiday Closure',
  PRICE_INVESTIGATION_REQUEST: 'Price investigation request',
  PRICE_UNAVAILABLE_OR_PENDING: 'Help with unavailable or pending price',
  PRICE_FSA_HSA_FUNDS: 'Help with FSA/HSA funds',
  MED_SELECTION_MISSING: 'Help with a medication',
  MED_SELECTION_MULTIPLE: 'Help with multiple medications',
  MEDICATION: 'Help with medication',
  ORDER_IN_PROGRESS: 'Help with a processing prescription',
  ORDERING: 'Help with ordering medication',
  PAST_DELIVERY: 'Help with a previous order',
  PHARMACIST_QUESTION: 'Medication question',
  PRICING_PAYMENT: 'Help with pricing or a payment',
  RECEIPTS: 'I need a receipt',
  SUPPORT_CASE: 'Help with missing support case',
  TRACK_DELIVERY: 'Help with tracking an upcoming delivery',
  TRANSFER: 'Help with a transfer',
  UNABLE_TO_ORDER: "I'm unable to order medication",
  UPCOMING_DELIVERY: 'Help with an upcoming order',
  SOMETHING_ELSE: 'Help',
} as const;
export type AssistantMessageSubject = (typeof ASSISTANT_MESSAGE_SUBJECTS)[keyof typeof ASSISTANT_MESSAGE_SUBJECTS];

export const DEFAULT_MESSAGE_PLACEHOLDER = 'Enter your detailed request';

export const ASSISTANT_CATEGORIES = {
  ACCOUNT: 'account',
  DELIVERIES: 'deliveries',
  ORDERING: 'ordering',
  MEDICATION_SHORTAGE: 'medication_shortage',
  MEDICATIONS: 'medications',
  PRICING_PAYMENTS: 'pricing_payments',
  REPLY_MESSAGE_HISTORY: 'reply_message_history', // >0 unread messages
  SOMETHING_ELSE: 'something_else',
  STOCK_CHECK: 'stock_check',
  MEDICAL_QUESTION: 'medical_question',
  TRANSFER: 'transfer',
  VIEW_MESSAGE_HISTORY: 'view_message_history', // 0 unread messages
  VACATION_OVERRIDE: 'vacation_override',
  LOST_MEDICATION_OVERRIDE: 'lost_medication_override',
  HOLIDAY_CLOSURE: 'holiday_closure',
} as const;

type AccountCategory = `${typeof ASSISTANT_CATEGORIES.ACCOUNT}.${AssistantAccountAction}`;
type MedicationCategory = `${typeof ASSISTANT_CATEGORIES.MEDICATIONS}.${AssistantMedicationsAction}`;
type DeliveryCategory = `${typeof ASSISTANT_CATEGORIES.DELIVERIES}.${AssistantDeliveryAction}`;
type OrderingCategory = `${typeof ASSISTANT_CATEGORIES.ORDERING}.${AssistantOrderingAction}`;
type PricingPaymentsCategory = `${typeof ASSISTANT_CATEGORIES.PRICING_PAYMENTS}.${AssistantPaymentsAction}`;
type TransferCategory = `${typeof ASSISTANT_CATEGORIES.TRANSFER}.${AssistantTransferAction}`;
type VacationOverrideCategory = `${typeof ASSISTANT_CATEGORIES.VACATION_OVERRIDE}.${AssistantTransferAction}`;
type LostMedicationCategory = `${typeof ASSISTANT_CATEGORIES.LOST_MEDICATION_OVERRIDE}.${AssistantTransferAction}`;

export type AssistantCategory =
  | (typeof ASSISTANT_CATEGORIES)[keyof typeof ASSISTANT_CATEGORIES]
  | AccountCategory
  | MedicationCategory
  | DeliveryCategory
  | OrderingCategory
  | PricingPaymentsCategory
  | TransferCategory
  | VacationOverrideCategory
  | LostMedicationCategory
  | 'unknown';

export type ActionSheetProviderStackCacheKey =
  | 'assistant-add-medication'
  | 'assistant-cancel-order'
  | 'assistant-deliveries'
  | 'assistant-medication-consultation'
  | 'assistant-medications'
  | 'assistant-ordering'
  | 'assistant-preferred-date-unavailable'
  | 'assistant-price-details'
  | 'assistant';

export type SelfServiceEvent =
  | AssistantAccountAction
  | AssistantDeliveryAction
  | AssistantOrderingAction
  | AssistantPaymentsAction
  | AssistantTransferAction;

// NOTE: add self service events here
// an array is used because a submission form might map to multiple alto assistant leaf nodes
export const SELF_SERVICE_MAP = {
  EDIT_ADDRESS: [ASSISTANT_DELIVERY_ACTIONS.DELIVERY_ADDRESS, ASSISTANT_DELIVERY_ACTIONS.DELIVERY_INSTRUCTIONS],
};
export const ASSISTANT_ACTION_SHEETS = {
  PAST_DELIVERY: 'past_delivery',
  PAST_DELIVERY_ADDRESS_CONFIRMATION: 'past_delivery_address_confirmation',
  PAST_DELIVERY_SAFE_PLACE_CONFIRMATION: 'past_delivery_safe_place_confirmation',
} as const;
export type AssistantActionSheet = (typeof ASSISTANT_ACTION_SHEETS)[keyof typeof ASSISTANT_ACTION_SHEETS];

export const ASSISTANT_BUTTONS = {
  ADDRESS_CONFIRMATION_CORRECT: 'address_confirmation_correct',
  ADDRESS_CONFIRMATION_INCORRECT: 'address_confirmation_incorrect',
  MESSAGE_CHAT_NEW_ISSUE: 'message_chat_new_issue',
  MESSAGE_CHAT_REPLY: 'message_chat_reply',
  SAFE_PLACE_CONFIRMATION_FOUND: 'safe_place_confirmation_found',
  SAFE_PLACE_CONFIRMATION_NOT_FOUND: 'safe_place_confirmation_not_found',
} as const;
export type AssistantButton = (typeof ASSISTANT_BUTTONS)[keyof typeof ASSISTANT_BUTTONS];

export const HOLIDAY_CLOSURE_INFO: FAQ_TYPE[] = [
  {
    title: 'When is Alto closed?',
    answer: [
      'We will be closed during the following holidays: Labor Day, Memorial Day, Independence Day (July 4th), Thanksgiving, Christmas (December 25th), and New Year’s Day (January 1st).',
      'Deliveries, pickups, pharmacies and customer support will not be available on these days.',
    ],
  },
  {
    title: 'What happens to deliveries and support around holidays?',
    answer: [
      'Around national holidays, you may experience reduced service and availability. The scheduling options on our mobile and web apps will reflect the next available delivery date based on any reduced hours or closures.',
      'Our support team will be available regular hours on the days surrounding a holiday closure with the exception of Christmas Eve (December 24th) and New Year’s Eve (December 31st). Support hours will end at 6pm PT on these days.',
    ],
  },
  {
    title: 'When should I order my medications?',
    answer: [
      'To avoid delays or complications, we recommend scheduling deliveries for medications in advance and for a day that is within regular business hours.',
      'Keep in mind that refrigerated medications that require mail order (FedEx) require 2 consecutive days for delivery so that your medications are not left out of the refrigerator for too long!',
    ],
  },
  {
    title: 'What should I do if I’m traveling over the holidays? Can Alto deliver to my destination?',
    answer: [
      'If you’re traveling within the United States, Alto may be able to deliver to your destination. You can check by adding your destination address to your account. If you’re interested in having Alto deliver to your home address before you travel, be sure to schedule ASAP so that we can help troubleshoot any insurance restrictions.',
      'If getting your medication from Alto is not an option, have your preferred local pharmacy contact us to request a transfer of your prescription. We can transfer your prescription back once you return!',
    ],
  },
];

export type HolidayClosureInfoType = (typeof HOLIDAY_CLOSURE_INFO)[keyof typeof HOLIDAY_CLOSURE_INFO];

export type FAQ_TYPE = {
  title: string;
  answer: string[];
};

export type AssistantTabName = 'assistant' | 'messages';
