import { COLORS, type TEXT_COLORS_VALUES } from '@alto/design-library-tokens';
import { AltoIcon, Description, LgSpacing, Link, Row, XsSpacing, getIconTypeFromColor } from '@alto/design-system';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { isTotal } from '~shared/helpers/pricingBreakdown';

type Props = {
  readonly label: string;
  readonly priceTextColor: TEXT_COLORS_VALUES;
  readonly onPressMoreInfo?: () => void;
  readonly onPressChange?: () => void;
  readonly semibold?: boolean;
};

export const PricingInfoLabel = ({ semibold, priceTextColor, onPressChange, onPressMoreInfo, label }: Props) => {
  const isTotalPrice = isTotal(label);
  const labelText = (
    <Description
      color={isTotalPrice ? COLORS.TEXT_COLORS.PRIMARY : COLORS.TEXT_COLORS.GREY}
      fontFamily={semibold ? 'semibold' : undefined}
    >
      {label}
    </Description>
  );

  if (onPressMoreInfo) {
    return (
      <TouchableOpacity onPress={onPressMoreInfo}>
        <Row centerVertically>
          <Description
            color={priceTextColor}
            fontFamily="semibold"
          >
            {label}
          </Description>
          <XsSpacing />
          <AltoIcon
            testID="info-icon"
            name="info-small"
            type={getIconTypeFromColor(priceTextColor)}
          />
        </Row>
      </TouchableOpacity>
    );
  }
  if (onPressChange) {
    return (
      <Row centerVertically>
        {labelText}
        <LgSpacing />
        <Link
          textSize="mini"
          onPress={onPressChange}
        >
          Change
        </Link>
      </Row>
    );
  }

  return labelText;
};
