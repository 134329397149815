// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModal } from '@alto/pocky';
import React, { PureComponent, useEffect } from 'react';
import { type ModalType } from '~shared/actions/modal';
import { ORGANIC_ONBOARDING_STEPS } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useCommunicationPreferencesViewed } from '~shared/lib/analytics/src/hooks/useCommunicationPreferencesViewed';
import OnboardingHealthProfileForm from './OnboardingHealthProfileFormContainer';
import { OnboardingInsuranceForm } from './OnboardingInsuranceForm';
import ProgressIndicator from '~web/components/legacy/ProgressIndicator';
import CommunicationPreferencesForm from '~web/features/profile/components/CommunicationPreferencesFormContainer';

export type Props = {
  readonly modalType: ModalType;
  readonly show: boolean;
  readonly currentStep: number;
};

type ModalContent = {
  header: string;
  body: any;
};

type OnboardingModalWrapperProps = {
  readonly children: React.ReactNode;
  readonly currentStep: number;
};

const CommunicationPreferencesFormWrapper = ({ children }: { readonly children: React.ReactNode }) => {
  useCommunicationPreferencesViewed();
  return <>{children}</>;
};

const stepContent = (index: number): ModalContent => {
  switch (index) {
    case ORGANIC_ONBOARDING_STEPS.HEALTH_PROFILE:
      return {
        header: 'Medical Info',
        body: <OnboardingHealthProfileForm />,
      };

    case ORGANIC_ONBOARDING_STEPS.INSURANCE_FORM:
      return {
        header: 'Benefits',
        body: <OnboardingInsuranceForm />,
      };

    case ORGANIC_ONBOARDING_STEPS.COMMUNICATION_PREFERENCES:
      return {
        header: 'Communication Preferences',
        body: (
          <CommunicationPreferencesFormWrapper>
            <CommunicationPreferencesForm isOnboarding />
          </CommunicationPreferencesFormWrapper>
        ),
      };

    default:
      // TODO: fill with real content
      return {
        header: '-',
        body: <span />,
      };
  }
};

const OnboardingModalWrapper = ({ children, currentStep }: OnboardingModalWrapperProps) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (currentStep === ORGANIC_ONBOARDING_STEPS.HEALTH_PROFILE) {
      trackEvent({
        event: EVENTS.ONBOARDING__HEALTH_PROFILE_VIEWED,
        params: { origin: 'ONBOARDING_MODAL' },
      });
    }

    if (currentStep === ORGANIC_ONBOARDING_STEPS.INSURANCE_FORM) {
      trackEvent({
        event: EVENTS.ONBOARDING__INSURANCE_STEP_VIEWED,
        params: { origin: 'ONBOARDING_MODAL' },
      });
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return <>{children}</>;
};

class OnboardingModal extends PureComponent<Props> {
  render() {
    const { currentStep, modalType, show } = this.props;
    const shown = show && modalType === 'ONBOARDING_MODAL';

    const { header, body } = stepContent(currentStep);

    return shown ? (
      <OnboardingModalWrapper currentStep={currentStep}>
        <DeprecatedModal
          dismissable={false}
          title={header}
          showing={shown}
          verticallyAlignContent={false}
          scrollable
          onClose={() => undefined}
        >
          <ProgressIndicator
            totalSteps={4}
            currentIndex={currentStep}
          />
          {body}
        </DeprecatedModal>
      </OnboardingModalWrapper>
    ) : null;
  }
}

export default OnboardingModal;
