// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~shared/queries/query-keys';

export const useQuerySuccessConfigs = () => {
  const { isLoading, value: enabled } = Experimentation.useFeatureFlag('onboarding_platformization');

  const { isFetching, isError, isSuccess, data } = useQuery({
    ...queries.onboardingConfigs.fetchOnboardSuccess({ params: {} }),
    enabled,
  });

  const partnershipName = data?.data?.partnership_name;

  return {
    closingImageUrl: data?.data?.closing_image_url,
    closingText: data?.data?.closing_text,
    isFetching: isLoading || isFetching,
    isError,
    isSuccess,
    partnershipName,
  };
};
