// @owners { team: patients-team }
import { completeOnboarding } from '~shared/actions/auth';
import { closeModal, previousModalStep } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { updateUser } from '~shared/actions/users';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getUserPhiAuth } from '~shared/features/users/selectors/getUserPhiAuth';
import { type CommunicationPreferencesFormFields } from '~shared/helpers/communicationPreferences';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import getUserError from '~shared/selectors/ui/errors/getUserError';
import getUpdateUserLoading from '~shared/selectors/ui/loading/getUpdateUserLoading';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import CommunicationPreferencesForm, {
  type CommunicationPreferencesFormOwnProps,
  showNotificationType,
} from './CommunicationPreferencesForm';

const mapStateToProps = (state: ReduxStateShared) => {
  return {
    userError: getUserError(state),
    updateUserLoading: getUpdateUserLoading(state),
    user: getCurrentUser(state),
    user_phi_data: getUserPhiAuth(state),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared, ownProps: CommunicationPreferencesFormOwnProps) => {
  return {
    goBack: () => {
      if (ownProps.isOnboarding) {
        dispatch(previousModalStep('ONBOARDING_MODAL'));
      } else {
        dispatch(closeModal('COMMUNICATION_PREFERENCES_MODAL'));
      }
    },
    onSubmit: (data: CommunicationPreferencesFormFields) => {
      const { preferred_communication, secondary_communication_preference, phi_auth_data } = data;

      // filter out hidden fields
      const user: CommunicationPreferencesFormFields = {
        preferred_communication,
      };

      if (preferred_communication && showNotificationType(preferred_communication)) {
        user.secondary_communication_preference = secondary_communication_preference;
      }

      // eslint-disable-next-line import/no-deprecated, promise/catch-or-return
      dispatch(updateUser({ ...user, phi_auth_data: JSON.stringify(phi_auth_data) })).then(({ success }) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          if (ownProps.isOnboarding) {
            dispatch(closeModal('ONBOARDING_MODAL'));
            dispatch(completeOnboarding());
            dispatch(sendAnalyticsEvent(createEvent(EVENTS.ONBOARDING__COMPLETED)));
          } else {
            dispatch(closeModal('COMMUNICATION_PREFERENCES_MODAL'));
          }
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferencesForm);
