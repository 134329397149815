// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Body, Button, Card, LgPadding, MdSpacing, Row } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import fulfillmentBoxWithMeds from '~web/images/fulfillmentBoxWithMeds@3x.png';

const CustomCard = styled(Card)`
  margin: 0 0 0;
  z-index: 0;
  position: relative;
`;

type Props = {
  readonly title: string;
  readonly description: string;
  readonly children?: React.ReactNode;
  readonly buttonLabel: string;
  readonly onClick: () => void;
};

export const MedSyncUpsellCard = ({ title, description, children, buttonLabel, onClick }: Props) => (
  <CustomCard
    title={title}
    illustrationSrc={fulfillmentBoxWithMeds}
    illustrationAccessibilityLabel={IMG_ALT_TEXTS.altoLogo}
  >
    <LgPadding topPadding={SPACING.STATIC.NONE}>
      <Body>{description}</Body>
      {children}
      <MdSpacing />
      <Row center>
        <Button
          label={buttonLabel}
          onPress={onClick}
        />
      </Row>
    </LgPadding>
  </CustomCard>
);
