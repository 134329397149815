// @owners { team: patients-team }
import { useNavigation } from '@alto/navigation';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';

export const useNavigateToMedDetails = () => {
  const { navigate } = useNavigation();

  return (prescriptionID: number, origin: string) => {
    // If no id (such as in SourcePrescription) navigate to overall med list page, otherwise, navigate to specific
    // prescription in med list based on rx number.
    if (!prescriptionID) {
      navigate('RouteAppTabNavigator', { screen: 'RouteTabMyMeds' });
    } else {
      navigate('RouteMedDetails', {
        primaryPrescriptionId: prescriptionID,
        origin: origin || ORIGIN_NAMES.HOMESCREEN,
      });
    }
  };
};
