// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  AltoIcon,
  Card,
  LgPadding,
  MdSpacing,
  PresentationListItem,
  Switch,
  Tag,
  Toast,
  ToastContext,
  useScreenSize,
} from '@alto/design-system';
import { CalendarRow, FulfillmentMethodRow, LocationRow, MedRow, PriceRow } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, LoadingButton, Text } from '@alto/pocky';
import { ConfirmationTypeMap } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/confirmation_type';
import { type UnconfirmedShipment as Shipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/unconfirmed_shipment';
import { type ConfirmShipmentRequest } from '@alto/scriptdash/alto/patient_app/homescreen/v1/confirm_shipment_endpoint';
import React, { type RefObject, useState } from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { SECTION_LABELS, UNCONFIRMED_SHIPMENT_LABELS } from '~shared/features/homescreen/constants';
import { useEligibleForMailAutoRefill } from '~shared/features/shipments/hooks/useEligibleForMailAutoRefill';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { formatWindowTime } from '~shared/helpers/date';
import { getIllustration } from '~shared/helpers/order';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
// eslint-disable-next-line import/no-deprecated
import { useConfirmShipment } from '~shared/queries/useConfirmShipment';
import { useSelectorShared } from '~shared/store';
import { useTrackNodeSeen } from '~web/hooks';

const CustomCard = styledNative(Card)<{
  isSMScreenOrBigger?: boolean;
  isMDScreenOrBigger?: boolean;
  children: React.ReactNode;
}>`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  ${(props) => props.isMDScreenOrBigger && `margin: 0 0 ${SPACING.STATIC.XXL.px};`}
  z-index: 0;
`;

const CardContent = styled.div`
  margin-bottom: ${SPACING.STATIC.XXS.px};
`;

const SignatureContainer = styled.div`
  border-top: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  border-bottom: 1px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  padding: ${SPACING.STATIC.MD.px} 0;
  margin: ${SPACING.STATIC.MD.px} 0;
`;

const SpacedText = styled(Text)`
  margin-top: ${SPACING.STATIC.MD.px};
`;

type Props = {
  readonly shipment: Shipment;
  readonly sendAnalyticsEvent: (event: Event) => void;
  readonly refetchHomescreen: () => void;
  readonly onViewDelivery: (id: number) => void;
};

export default function UnconfirmedShipment({
  shipment,
  sendAnalyticsEvent,
  refetchHomescreen,
  onViewDelivery,
}: Props) {
  const {
    address,
    confirmation_types,
    cutoff_time,
    date,
    delivery_method,
    end_time,
    home_to_sign_required,
    id,
    medication_names,
    price,
    start_time,
    waivable,
  } = shipment;
  const { addToast } = React.useContext(ToastContext);
  const fullShipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: shipment?.id }));
  const isEligibleForMailAutoRefill = useEligibleForMailAutoRefill({ shipment, deliveryMethod: delivery_method });
  const illustrationArgs = { method: delivery_method, status: fullShipment?.status };
  const refNode = useTrackNodeSeen({
    onSeen: () => {
      sendAnalyticsEvent(createEvent(EVENTS.SHIPMENT_CONFIRMATION_VIEWED, {}, { shipmentId: id }));
    },
  });
  const [waived, setWaived] = useState(shipment.waived);
  const tag = (
    <Tag
      label="Action Required"
      type="warning"
    />
  );
  const COLOR = COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST;
  const highlightCalendar = confirmation_types.find((type) => type === ConfirmationTypeMap.DATE_AND_TIME);
  const highlightAddress = confirmation_types.find((type) => type === ConfirmationTypeMap.ADDRESS);
  const highlightPrice = confirmation_types.find((type) => type === ConfirmationTypeMap.PATIENT_PAY);
  const highlightSignature = confirmation_types.find((type) => type === ConfirmationTypeMap.HOME_TO_SIGN);
  const { isSMScreenOrBigger } = useScreenSize();

  const onError = () => {
    addToast(
      <Toast variant="error">{`Something went wrong. ${UNCONFIRMED_SHIPMENT_LABELS.generic_confirmation_error}`}</Toast>,
    );
  };

  const onSuccess = (data: { success?: boolean }) => {
    if (data?.success) {
      addToast(<Toast>Order confirmed!</Toast>);
      refetchHomescreen();
    } else {
      onError();
    }
  };

  // eslint-disable-next-line import/no-deprecated
  const { mutate, isPending } = useConfirmShipment();

  const viewDetails = () => {
    onViewDelivery(id);
    sendAnalyticsEvent(createEvent(EVENTS.SHIPMENT_CONFIRMATION_EDIT_CLICKED, {}, { shipmentId: id }));
  };

  const confirmShipment = () => {
    const params: ConfirmShipmentRequest = {
      id,
      date,
      start_time,
      end_time,
      address,
      // @ts-expect-error TS(2322): Type 'number | null | undefined' is not assignable to type 'number'.
      price,
      medication_names,
      home_to_sign_required,
      waivable,
      waived,
    };
    mutate(params, {
      onSuccess,
      onError,
    });

    sendAnalyticsEvent(createEvent(EVENTS.SHIPMENT_CONFIRMATION_CONFIRMED_CLICKED, {}, { shipmentId: id }));
  };

  return (
    <CustomCard
      title={SECTION_LABELS.UNCONFIRMED_SHIPMENT}
      illustrationSrc={getIllustration(illustrationArgs)}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.bikeCourier}
      tag={tag}
      isSMScreenOrBigger={isSMScreenOrBigger}
      description={
        cutoff_time
          ? `Confirm by ${formatWindowTime({ windowTime: cutoff_time || '', meridiem: 'aaa' })}, for scheduled order`
          : undefined
      }
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <CardContent ref={refNode as RefObject<HTMLDivElement>}>
          <CalendarRow
            backgroundColor={highlightCalendar ? COLOR : undefined}
            date={date}
            startTime={start_time}
            endTime={end_time}
            method={shipment.delivery_method}
            isEligibleForMailAutoRefill={isEligibleForMailAutoRefill}
          />
          <LocationRow
            backgroundColor={highlightAddress ? COLOR : undefined}
            address={address}
          />
          <FulfillmentMethodRow
            method={delivery_method}
            showDefault={false}
          />
          <MedRow medicationNames={medication_names} />
          <PriceRow
            backgroundColor={highlightPrice ? COLOR : undefined}
            price={price}
          />
          {home_to_sign_required ? (
            <>
              <PresentationListItem
                backgroundColor={highlightSignature ? COLOR : undefined}
                LeftContent={<AltoIcon name="signature" />}
                text="Signature required"
                fullBleed
              />
              {waivable ? (
                <SignatureContainer>
                  <Switch
                    accessibilityLabel="switch"
                    label="Waive signature"
                    value={waived}
                    onValueChange={(val) => {
                      setWaived(val);
                    }}
                  />
                  {waived ? (
                    <SpacedText
                      light
                      small
                    >
                      {UNCONFIRMED_SHIPMENT_LABELS.waive_signature_requirement}
                    </SpacedText>
                  ) : null}
                </SignatureContainer>
              ) : null}
            </>
          ) : null}
        </CardContent>

        <MdSpacing />

        <LoadingButton
          loading={isPending}
          loadingText="Confirming order..."
          kind="primary"
          full
          onClick={confirmShipment}
        >
          Confirm now
        </LoadingButton>

        <MdSpacing />

        <Button
          disabled={isPending}
          kind="tertiary"
          full
          onClick={viewDetails}
        >
          View or edit order
        </Button>
      </LgPadding>
    </CustomCard>
  );
}
