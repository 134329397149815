import { withRehydration } from './withRehydration';
import {
  CREATE_PAYMENT_METHOD_FAILED,
  CREATE_PAYMENT_METHOD_LOADING,
  CREATE_PAYMENT_METHOD_SUCCEEDED,
  DELETE_PAYMENT_METHOD_SUCCEEDED,
  FETCH_PAYMENT_METHODS_LOADING,
  FETCH_PAYMENT_METHODS_SUCCEEDED,
  type createPaymentMethodFailed,
  type createPaymentMethodSucceeded,
  type creatingPaymentMethod,
  type deletePaymentMethodSucceeded,
  type fetchPaymentMethodsFailed,
  type fetchPaymentMethodsSucceeded,
  type fetchingPaymentMethods,
} from '~shared/actions/paymentMethods';
import { CREATE_PLAID_LINK_TOKEN_SUCCEEDED, type createPlaidLinkTokenSucceeded } from '~shared/actions/plaid';
import { type PaymentMethod, type PlaidLinkToken } from '~shared/types';

export type PaymentMethodState = {
  paymentMethods: PaymentMethod[];
  createPaymentMethodSuccess: boolean | null;
  lastRequestAttemptedAt: number;
  requestAttemptCount: number;
  lastRequestSucceededAt: number;
  plaidLinkToken: PlaidLinkToken;
};

const initialState: PaymentMethodState = {
  paymentMethods: [],
  createPaymentMethodSuccess: null,
  lastRequestAttemptedAt: 0,
  requestAttemptCount: 0,
  lastRequestSucceededAt: 0,
  plaidLinkToken: {
    token: null,
  },
};

type Actions =
  | ReturnType<typeof fetchPaymentMethodsSucceeded>
  | ReturnType<typeof fetchPaymentMethodsFailed>
  | ReturnType<typeof fetchingPaymentMethods>
  | ReturnType<typeof deletePaymentMethodSucceeded>
  | ReturnType<typeof createPlaidLinkTokenSucceeded>
  | ReturnType<typeof creatingPaymentMethod>
  | ReturnType<typeof createPaymentMethodFailed>
  | ReturnType<typeof createPaymentMethodSucceeded>;

const paymentMethods = (state: PaymentMethodState = initialState, action: Actions): PaymentMethodState => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS_SUCCEEDED:
      return { ...state, paymentMethods: action.payload, lastRequestSucceededAt: Date.now(), requestAttemptCount: 0 };

    case FETCH_PAYMENT_METHODS_LOADING:
      return { ...state, lastRequestAttemptedAt: Date.now(), requestAttemptCount: state.requestAttemptCount + 1 };

    case CREATE_PAYMENT_METHOD_LOADING:
      return { ...state, createPaymentMethodSuccess: null };

    case CREATE_PAYMENT_METHOD_SUCCEEDED:
      return { ...state, createPaymentMethodSuccess: true, paymentMethods: [...state.paymentMethods, action.payload] };

    case CREATE_PAYMENT_METHOD_FAILED:
      return { ...state, createPaymentMethodSuccess: false };

    case DELETE_PAYMENT_METHOD_SUCCEEDED:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter((paymentMethod) => {
          return paymentMethod.id !== action.payload.id;
        }),
      };

    case CREATE_PLAID_LINK_TOKEN_SUCCEEDED:
      return { ...state, plaidLinkToken: action.payload };

    default:
      return state;
  }
};

export const getPaymentBreakdowns = (paymentMethods: PaymentMethod[]) => {
  return {
    creditCardPaymentMethods: paymentMethods.filter((pm) => pm.type === 'StripeData' && !pm.details?.native_pay_type),
    achPaymentMethods: paymentMethods.filter((pm) => pm.type === 'PlaidData'),
    nativePaymentMethods: paymentMethods.filter((pm) => pm.type === 'StripeData' && !!pm.details?.native_pay_type),
  };
};

export default withRehydration(paymentMethods, initialState);
