// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Card,
  H1,
  H2,
  LgPadding,
  ListButton,
  MdPadding,
  MdSpacing,
  SecondaryPage,
  Separator,
  SmSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useContext, useEffect } from 'react';
import { ASSISTANT_PRESSED_EVENTS, ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { sendAssistantPressedEvent, sendAssistantViewedEvent } from '~shared/features/alto-assistant/analytics/helpers';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import {
  getPastShipments,
  getUpcomingShipments,
  isRecentPastShipment,
} from '~shared/selectors/alto-assistant/getRecentShipments';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { PastDeliveryRow, UpcomingDeliveryCard } from '../../../../index';

export const DeliverySelection = () => {
  const { goBack, navigate } = useNavigation();

  const upcomingShipments = useSelectorShared(getUpcomingShipments);
  const pastShipments = useSelectorShared(getPastShipments).slice(0, 10).filter(isRecentPastShipment);

  const dispatch = useDispatchShared();
  const { restoreStack } = useContext(ActionSheetContext);

  useEffect(() => {
    const upcomingCount = upcomingShipments.length;
    const editableUpcomingCount = upcomingShipments.filter((shipment) => isShipmentEditable(shipment)).length;
    const uneditableUpcomingCount = upcomingCount - editableUpcomingCount;
    const pastCount = pastShipments.length;

    dispatch(
      sendAssistantViewedEvent(ASSISTANT_VIEWED_EVENTS.DELIVERY_PICKER, {
        upcoming_count: upcomingCount,
        editable_upcoming_count: editableUpcomingCount,
        uneditable_upcoming_count: uneditableUpcomingCount,
        past_count: pastCount,
      }),
    );
  }, [dispatch, upcomingShipments, pastShipments.length]);

  const handleNoDeliverySelected = useCallback(() => {
    dispatch(sendAssistantPressedEvent(ASSISTANT_PRESSED_EVENTS.NO_DELIVERY_CHOSEN));
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_DELIVERY_MENU,
      category: 'deliveries.not_shown',
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      subject: ASSISTANT_MESSAGE_SUBJECTS.UPCOMING_DELIVERY,
    });
  }, [dispatch, navigate]);

  const handleDismiss = useCallback(() => {
    restoreStack<ActionSheetProviderStackCacheKey>('assistant-deliveries');
    goBack();
  }, [goBack, restoreStack]);

  let title: string;
  const hasShipments = upcomingShipments.length || pastShipments.length;
  if (hasShipments) {
    title = 'Which order do you need help with?';
  } else {
    title = 'You have no upcoming or past orders';
  }

  return (
    <SecondaryPage
      transparentNavBar
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      HeaderContent={
        <MdPadding>
          <H1>{title}</H1>
          <MdSpacing />
        </MdPadding>
      }
      dismissIcon="chevronleft"
      onDismiss={handleDismiss}
    >
      {upcomingShipments.map((shipment) => (
        <React.Fragment key={`upcoming-${shipment.id}`}>
          <UpcomingDeliveryCard shipment={shipment} />
          <MdSpacing />
        </React.Fragment>
      ))}
      {pastShipments.length > 0 && (
        <>
          <Card>
            <LgPadding bottomPadding={SPACING.STATIC.NONE}>
              <H2>Past orders</H2>
            </LgPadding>
            {pastShipments.map((shipment, index) => (
              <React.Fragment key={`past-${shipment.id}`}>
                {index !== 0 && <Separator />}
                <PastDeliveryRow shipment={shipment} />
              </React.Fragment>
            ))}
            <SmSpacing />
          </Card>
          <MdSpacing />
        </>
      )}

      {!hasShipments ? (
        <Card>
          <LgPadding>
            <H2>How can we help?</H2>
            <MdSpacing />
            <ListButton
              rightIcon="chevronright-small"
              label="Send a message"
              onPress={handleNoDeliverySelected}
            />
          </LgPadding>
        </Card>
      ) : (
        <ListButton
          rightIcon="chevronright-small"
          label="None of these"
          onPress={handleNoDeliverySelected}
        />
      )}
    </SecondaryPage>
  );
};
