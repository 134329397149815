// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Card,
  H1,
  ListDescription,
  ListItem,
  MdPadding,
  SecondaryPage,
  Separator,
  Tag,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { ASSISTANT_PRESSED_EVENTS, ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_PAGE_ORIGINS,
  type AssistantTabName,
} from '~shared/features/alto-assistant/constants';
import { MESSAGE_RESPONSE_TIME } from '~shared/features/messages/constants';
import { useAnalytics } from '~shared/hooks';
import { useWundercomUnreadMessages } from '~shared/hooks/alto-assistant/useWundercomUnreadMessages';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useGetReadOnlyWundercoms } from '../../queries/queries';
import { ContactUsActionSheet } from '../contact-us/ContactUsActionSheet';
import { FAQ_KEYS } from '../faq/Constants';
import { Inbox } from '../support-cases/Inbox';
import { HelpTopicsActionSheet } from '../wizard/HelpTopicsActionSheet';
import { HolidayClosureAlert } from './HolidayClosureAlert';
import { PriceIncreaseAlert } from './PriceIncreaseAlert';
import { Shortcuts } from './Shortcuts';
import { Tabs } from './Tabs';
import helpBubble from './help_bubble_transparent.png';

const isWeb = Platform.OS === 'web';

export const SupportPage = () => {
  const { getParam, navigate } = useNavigation<'RouteTabMessages'>();
  const { trackEvent, trackPageView } = useAnalytics();
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  useWundercomUnreadMessages();
  const { unreadMessageCount } = useGetReadOnlyWundercoms();
  const activeTabParam = getParam('activeTab', undefined);
  const originParam = getParam('origin', undefined);
  const [activeTab, setActiveTab] = useState<AssistantTabName>(activeTabParam || 'assistant');

  useEffect(() => {
    const sendOrigin = async () => {
      const initialURL = await Linking.getInitialURL();
      let origin: string | null = null;

      if (initialURL) {
        // If user came from a deep link
        origin = ASSISTANT_PAGE_ORIGINS.DEEP_LINK;
      } else if (originParam) {
        // If user was redirected with navigate
        origin = originParam;
      }

      trackPageView({
        event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
        params: {
          name: ASSISTANT_VIEWED_EVENTS.ASSISTANT_LANDING_PAGE,
          origin,
          unread_message_count: unreadMessageCount,
        },
      });
    };

    sendOrigin();
  }, [originParam, trackPageView, unreadMessageCount]);

  const holidayClosureOnPress = () => {
    navigate('RouteFaq', {
      faqKey: FAQ_KEYS.HOLIDAY_CLOSURE,
      hideBottomButtons: true,
    });
  };

  const handleContactInfoPress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.SHOW_CONTACT_INFORMATION,
      },
    });
    setActiveActionSheet(<ContactUsActionSheet />);
  };

  const hideSendUsFeedback = true;

  return (
    <SecondaryPage
      keyboardAvoidingEnabled={false}
      scrollEnabled
      footerPlacementContext="edgelessScreenWithTabBar"
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      HeaderContent={
        <MdPadding topPadding={isWeb ? SPACING.STATIC.XXXL : SPACING.STATIC.XS}>
          <H1>Support</H1>
          <HolidayClosureAlert onPress={holidayClosureOnPress} />
          <PriceIncreaseAlert />
          <XsSpacing />
          <Tabs
            activeTab={activeTab}
            onSetActiveTab={setActiveTab}
          />
        </MdPadding>
      }
    >
      {activeTab === 'assistant' ? (
        <>
          <Shortcuts />
          <XlSpacing />
          <Card
            title={'Get in touch'}
            illustrationSrc={helpBubble}
            illustrationAccessibilityLabel={'Illustration of a help bubble'}
            description={'Select method below to get started'}
          >
            <ListItem
              testID="message-care-team"
              title={'Message care team'}
              descriptions={[
                <ListDescription key={'message-care-team'}>
                  {`We typically respond within ${MESSAGE_RESPONSE_TIME} during business hours.`}
                </ListDescription>,
              ]}
              onPress={() => {
                setActiveActionSheet(<HelpTopicsActionSheet />);
              }}
              tagPlacement={'title'}
              tags={
                <Tag
                  label={'Recommended'}
                  type={'recommended'}
                />
              }
              RightContent={
                <AltoIcon
                  name={'chevronright-small'}
                  accessibilityLabel={'Press to open help categories action sheet'}
                />
              }
            />
            <Separator />
            <ListItem
              title={'Contact info'}
              onPress={handleContactInfoPress}
              RightContent={
                <AltoIcon
                  name={'chevronright-small'}
                  accessibilityLabel={"Press to view Alto's contact info"}
                />
              }
            />
            <Separator />
            {hideSendUsFeedback ? null : (
              <ListItem
                title={'Send us feedback'}
                onPress={() => {
                  trackEvent({
                    event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
                    params: {
                      name: ASSISTANT_PRESSED_EVENTS.SUPPORT_CASE_NEW_REQUEST,
                      origin: ASSISTANT_CHAT_ORIGINS.SUPPORT_PAGE,
                    },
                  });
                  navigate('RouteAssistantMessage', {
                    origin: ASSISTANT_CHAT_ORIGINS.SUPPORT_PAGE,
                  });
                }}
                RightContent={
                  <AltoIcon
                    name={'chevronright-small'}
                    accessibilityLabel={'Press to send feedback to Alto'}
                  />
                }
              />
            )}
          </Card>
        </>
      ) : null}
      {activeTab === 'messages' ? <Inbox /> : null}
    </SecondaryPage>
  );
};
