// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, MdSpacing, XsSpacing } from '@alto/design-system';
import React from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useProduct } from '~shared/queries';

function parseMarkdownToTitleCopyMapArray(markdown: string | undefined) {
  if (markdown === undefined) {
    return [];
  }
  // Regular expression to match the titles and their corresponding copies
  const pattern = /\*\*(.*?)\*\*\s*([\s\S]*?)(?=\*\*|$)/g;

  let matches;
  const result = [];

  // Find all matches and push them to the result array
  while ((matches = pattern.exec(markdown)) !== null) {
    const title = matches[1].trim();
    const copy = matches[2].trim();
    result.push({ title, copy });
  }

  return result;
}

export const MedicationMonographActionSheet = ({
  medicationName,
  productId,
}: {
  readonly medicationName: string | undefined;
  readonly productId: number | null;
}) => {
  const { trackEvent } = useAnalytics();
  const { product } = useProduct({ productID: productId });
  const { monograph } = product || {};

  const titleCopyMapArray = parseMarkdownToTitleCopyMapArray(monograph);

  trackEvent({
    event: EVENTS.MED_DETAILS__LEARN_MORE_VIEWED,
    params: { medication_name: medicationName, productId },
  });

  return (
    <ActionSheetV2 title={medicationName || 'Medication Information'}>
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        {titleCopyMapArray.map(({ title, copy }) => (
          <React.Fragment key={`${productId}-${title}`}>
            <Body fontFamily="semibold">{title}</Body>
            <XsSpacing />
            <Body>{copy}</Body>
            <MdSpacing />
          </React.Fragment>
        ))}
      </LgPadding>
    </ActionSheetV2>
  );
};
