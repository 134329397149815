// @owners { team: patients-team }
import { Column, InputText } from '@alto/design-system';
import React, { useCallback, useState } from 'react';
import { updateOrder } from '~shared/actions/cart';
import { SPLIT_AMOUNTS_NOT_MATCHED } from '~shared/features/checkout/constants';
import { getCheckoutPaymentAmountErrors } from '~shared/features/checkout/helpers';
import {
  getEditPaymentAmount,
  getEditSecondPaymentAmount,
  getPaymentAmount,
  getSecondPaymentAmount,
  getShowingValidationErrors,
} from '~shared/features/checkout/selectors/getCart';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getIsPaymentMethodRequired } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { getItemsSubtotal } from '~shared/features/pricing/selectors/getOrderTotal';
import { getIsEditingExistingOrder, getIsSplitPaymentEnabled } from '~shared/features/ui/selectors/getCart';
import { formatDollarsWithPlaceholder, roundToTwo } from '~shared/helpers/currency';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const CUSTOM_AMOUNT_PLACEHOLDER = '0.00';
export const MAX_PAYMENT_AMOUNT = 999999.99;
export const MAX_PAYMENT_AMOUNT_LENGTH = MAX_PAYMENT_AMOUNT.toFixed(2).toString().length;

type Props = {
  readonly isSecondaryField?: boolean;
};

export const PaymentAmount = ({ isSecondaryField = false }: Props) => {
  const dispatch = useDispatchShared();
  const isPaymentMethodRequired = useSelectorShared(getIsPaymentMethodRequired);
  const splitPaymentTotalCost = useSelectorShared(getItemsSubtotal);
  const order = useSelectorShared(getOrder);
  const isSplitPaymentEnabled = useSelectorShared(getIsSplitPaymentEnabled);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const editPaymentAmount = useSelectorShared(getEditPaymentAmount);
  const nonEditPaymentAmount = useSelectorShared(getPaymentAmount);
  const paymentAmount = isEditingOrder ? editPaymentAmount : nonEditPaymentAmount;
  const secondEditPaymentAmount = useSelectorShared(getEditSecondPaymentAmount);
  const secondNonEditPaymentAmount = useSelectorShared(getSecondPaymentAmount);
  const secondPaymentAmount = isEditingOrder ? secondEditPaymentAmount : secondNonEditPaymentAmount;
  const { paymentAmountValidationErrors } = getCheckoutPaymentAmountErrors({
    order,
    isSplitPaymentEnabled,
    splitPaymentsTotal: splitPaymentTotalCost,
    isPaymentMethodRequired,
  });
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const [paymentAmountFocused, setPaymentAmountFocused] = useState(false);
  const [secondPaymentAmountFocused, setSecondPaymentAmountFocused] = useState(false);
  const errorMessage = showingValidationErrors
    ? paymentAmountValidationErrors.find(
        (error) => error.isSecondaryField === isSecondaryField && error.key === SPLIT_AMOUNTS_NOT_MATCHED,
      )?.message
    : undefined;
  const inputFocused = isSecondaryField ? secondPaymentAmountFocused : paymentAmountFocused;
  const amountValue = isSecondaryField ? secondPaymentAmount : paymentAmount;
  const formattedValue = !inputFocused && amountValue ? formatDollarsWithPlaceholder(amountValue) : null;

  const handleAmountInputFocus = (isSecondaryField: boolean) => {
    if (isSecondaryField) {
      setSecondPaymentAmountFocused(true);
    } else {
      setPaymentAmountFocused(true);
    }
  };

  const processSplitPaymentAmountUpdate = useCallback(
    (amount = '', isSecondaryPaymentMethod = false) => {
      if (!splitPaymentTotalCost) return;
      const amountKeyToModify = isSecondaryPaymentMethod ? 'second_payment_method_amount' : 'payment_method_amount';
      const amountKeyRemaining = isSecondaryPaymentMethod ? 'payment_method_amount' : 'second_payment_method_amount';
      const convertedAmount = Number(amount.replace(/\$/g, '')) || 0;
      const normalizedAmount = Math.min(Math.max(convertedAmount, 0), splitPaymentTotalCost);
      const newAmounts = {};
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type '"second_payment_method_amount" | "p... (Delete me to see the full error)
      newAmounts[amountKeyToModify] = roundToTwo(normalizedAmount);
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type '"second_payment_method_amount" | "p... (Delete me to see the full error)
      newAmounts[amountKeyRemaining] = roundToTwo(splitPaymentTotalCost - normalizedAmount);
      dispatch(updateOrder(newAmounts));
    },
    [dispatch, splitPaymentTotalCost],
  );

  return (
    <Column
      flexShrink={1}
      flexBasis="33%"
    >
      <InputText
        required
        accessibilityLabel="Input a custom amount in USD"
        autoCapitalize="none"
        autoCorrect={false}
        onFocus={() => {
          handleAmountInputFocus(isSecondaryField);
        }}
        onChangeText={(amount) => {
          processSplitPaymentAmountUpdate(amount, isSecondaryField);
        }}
        onBlur={() => {
          setPaymentAmountFocused(false);
          setSecondPaymentAmountFocused(false);
        }}
        keyboardType="decimal-pad"
        maxLength={MAX_PAYMENT_AMOUNT_LENGTH}
        placeholder={CUSTOM_AMOUNT_PLACEHOLDER}
        returnKeyType="done"
        textContentType="none"
        value={formattedValue ?? undefined}
        defaultValue={isSecondaryField ? undefined : (formatDollarsWithPlaceholder(splitPaymentTotalCost) ?? undefined)}
        blurOnSubmit
        error={errorMessage}
      />
    </Column>
  );
};
