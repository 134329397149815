// @owners { team: patients-team }
import { type AltoIconNameWithColors, SIZES, altoIconToUrlMap } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components';
import { Image } from '~web/features/essentials/primitives/Image';
import { StyledTouchable } from '~web/features/essentials/primitives/Styled';

type Props = {
  readonly name: AltoIconNameWithColors;
  readonly size?: keyof typeof SIZES.ICON;
  readonly label: string;
  readonly spinning?: boolean;
  readonly interactive: boolean;
};

const StyledSpinner = styled.div<{ spinning?: boolean; interactive: boolean }>`
  display: flex;
  ${({ spinning }) => spinning && 'animation: spin 1.5s linear infinite;'}
  ${({ interactive }) => interactive && StyledTouchable}
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Icon = ({ name, spinning, interactive, size = 'SM', label }: Props) => {
  return (
    <StyledSpinner
      spinning={spinning}
      interactive={interactive}
    >
      <Image
        src={altoIconToUrlMap[name]}
        aria-hidden={interactive ? 'false' : 'true'}
        alt={label}
        width={SIZES.ICON[size].px}
        height={SIZES.ICON[size].px}
      />
    </StyledSpinner>
  );
};
