// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, SmSpacing } from '@alto/design-system';
import React from 'react';

export const NewPrescriptionStatusActionSheet = () => {
  return (
    <ActionSheetV2
      title="New prescription status"
      analyticsName="assistant new prescription status"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          We typically begin processing prescriptions within 30 minutes of receiving them, at which point they will be
          visible in your app from the Home and Meds tabs.
        </Body>
        <SmSpacing />
        <Body>
          You can track the status by clicking the tag below the medication name. Prescriptions are typically processed
          and ready to schedule within 3 hours of receipt, but may be delayed at times due to surges in new prescription
          volume. Rest assured, our team is working diligently to get your prescription ready as quickly as possible.
        </Body>
        <SmSpacing />
        <Body>
          We ask that you please refrain from contacting us to inquire about the status of your prescription, unless it
          is needed urgently.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
