import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import { Constants } from '../../../utils';

type Props = {
  readonly children: React.ReactNode;
};

export const Spin = ({ children }: Props) => {
  const rotation = useRef(new Animated.Value(0)).current;

  const rotate = rotation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });
  useEffect(() => {
    Animated.loop(
      Animated.timing(rotation, {
        toValue: 1,
        duration: 750,
        easing: Easing.linear,
        useNativeDriver: Constants.useNativeDriver,
      }),
    ).start();
  }, [rotation]);

  return (
    <Animated.View
      style={{
        transform: [
          {
            rotate,
          },
        ],
      }}
    >
      {children}
    </Animated.View>
  );
};
