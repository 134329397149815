// Format a list of strings showing a max of 2 elements:
// John
// John and Mary
// John, Mary, and 1 other
// John, Mary, and 2 others
export const formatListMaxTwo = (names: string[]): string | null | undefined => {
  if (names.length < 1) {
    return null;
  }

  let result = names[0];

  if (names.length === 2) {
    result += ` and ${names[1]}`;
  } else if (names.length > 2) {
    result += `, ${names[1]}, and ${names.length - 2} other`;
  }

  if (names.length > 3) {
    result += 's';
  }

  return result;
};

// Returns a comma separated list of items with appropriate 'and'.
export function formatList(items: string[]): string {
  const dupedItems = [...items];

  if (dupedItems.length === 0) {
    return '';
  }

  if (dupedItems.length === 1) {
    return dupedItems[0];
  }

  if (dupedItems.length === 2) {
    return dupedItems.join(' and ');
  }

  return [dupedItems.slice(0, -1).join(', '), dupedItems.slice(-1)[0]].join(dupedItems.length < 2 ? '' : ', and ');
}
