// @owners { team: supply-chain }
import { Experimentation } from '@alto/experimentation';

export type CourierAgeRestriction = {
  enabledFacilityIds: number[];
};

export const defaultCourierAgeRestriction: CourierAgeRestriction = {
  enabledFacilityIds: [],
};

export const useCourierAgeRestriction = (facilityId: number | undefined): boolean => {
  const { value } = Experimentation.useFeatureFlag('courier_age_restriction_clients', defaultCourierAgeRestriction);

  if (!facilityId) return false;
  return (value as CourierAgeRestriction).enabledFacilityIds.includes(facilityId);
};
