import {
  API_ERROR,
  API_SENT,
  API_SUCCESS,
  type Actions,
  CLEAR_RESULTS,
  DECREMENT_HIGHLIGHTED_RESULT_INDEX,
  INCREMENT_HIGHLIGHTED_RESULT_INDEX,
  INPUT_BLURRED,
  INPUT_FOCUSED,
  REMOVE_SELECTED_ITEM,
  SELECT_ITEM,
  UPDATE_TEXT,
} from './actions';
import { escapeRegExp, summarizeEntries } from './helpers';
import { type ApiResponseRow } from './types';

export type State = {
  text: string;
  resultsToDisplay: ApiResponseRow<any>[] | null | undefined;
  /**
   * This is only used for desktop web keyboard navigation.
   */
  highlightedResultIndex?: number;
  selectedValues: ApiResponseRow<any>[];
  selectedValueKeys: Record<string, boolean>;
  isInputFocused: boolean;
  isLoading: boolean;
};

// @ts-expect-error TS(7006): Parameter 'responseData' implicitly has an 'any' type.
const exactTextIsNotInResults = (responseData, text) => {
  const exactCaseInsensitiveMatcher = new RegExp(`^${escapeRegExp(text)}$`, 'i');

  // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
  return !responseData.find((row) => row.title.match(exactCaseInsensitiveMatcher));
};

const generateDefaultValue = (text: string): ApiResponseRow<string> => ({
  title: text,
  value: text,
  label: text,
  key: text,
  isFromAutocomplete: false,
});

// eslint-disable-next-line sonarjs/cognitive-complexity
export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case UPDATE_TEXT:
      return { ...state, text: action.payload };

    case CLEAR_RESULTS:
      return { ...state, resultsToDisplay: null };

    case INPUT_FOCUSED:
      return { ...state, isInputFocused: true };

    case INPUT_BLURRED: {
      return { ...state, isInputFocused: false };
    }

    case API_SENT:
      return { ...state, isLoading: true };

    case API_ERROR: {
      const { shouldAllowManualInput } = action.payload;

      // allows user to finish form if API is down, if manual entry is allowed
      const resultsToDisplay = shouldAllowManualInput ? [generateDefaultValue(state.text)] : null;

      return { ...state, isLoading: false, resultsToDisplay };
    }

    case API_SUCCESS: {
      const { response, shouldAllowManualInput } = action.payload;
      const responseData = response.map((row) => ({ ...row, isFromAutocomplete: true }));

      if (shouldAllowManualInput && state.text.length > 0 && exactTextIsNotInResults(responseData, state.text)) {
        responseData.push(generateDefaultValue(state.text));
      }

      return { ...state, isLoading: false, resultsToDisplay: responseData };
    }

    case REMOVE_SELECTED_ITEM: {
      const { rowToRemove, selectionType } = action.payload;

      const newSelectedValues = state.selectedValues.filter((row) => row.key !== rowToRemove.key);
      const newSelectedValueKeys = newSelectedValues.reduce((acc, row) => ({ ...acc, [row.key]: true }), {});
      const newText = selectionType === 'multi' ? '' : summarizeEntries(newSelectedValues);
      return { ...state, selectedValues: newSelectedValues, text: newText, selectedValueKeys: newSelectedValueKeys };
    }

    case SELECT_ITEM: {
      const { selectionType, selectedItem } = action.payload;
      const newSelectedValues = selectionType === 'multi' ? [...state.selectedValues, selectedItem] : [selectedItem];
      const newSelectedValueKeys = newSelectedValues.reduce((acc, row) => ({ ...acc, [row.key]: true }), {});
      const newText = selectionType === 'multi' ? '' : summarizeEntries(newSelectedValues);
      return {
        ...state,
        text: newText,
        resultsToDisplay: null,
        selectedValues: newSelectedValues,
        selectedValueKeys: newSelectedValueKeys,
        // Reset highlighted result index to 0 when a selection is made.
        highlightedResultIndex: state.highlightedResultIndex === undefined ? undefined : 0,
      };
    }

    case INCREMENT_HIGHLIGHTED_RESULT_INDEX: {
      // Only increment result index if there are results to display and we have a numeric highlightedResultIndex to work with.
      if (state.resultsToDisplay?.length && state.highlightedResultIndex !== undefined) {
        const maxHighlightedIndex = state.resultsToDisplay.length - 1;
        return {
          ...state,
          highlightedResultIndex:
            state.highlightedResultIndex === maxHighlightedIndex
              ? maxHighlightedIndex
              : state.highlightedResultIndex + 1,
        };
      }
      return {
        ...state,
      };
    }

    case DECREMENT_HIGHLIGHTED_RESULT_INDEX: {
      // Only decrement result index if there are results to display  and we have a numeric highlightedResultIndex to work with.
      if (state.resultsToDisplay?.length && state.highlightedResultIndex !== undefined) {
        const minHighlightedIndex = 0;
        return {
          ...state,
          highlightedResultIndex:
            state.highlightedResultIndex === minHighlightedIndex
              ? minHighlightedIndex
              : state.highlightedResultIndex - 1,
        };
      }
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
