import { createSelector } from 'reselect';
import { getUsers } from './getUsers';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { type ReduxStateShared, type User } from '~shared/types';

export const getSelectedUser: (arg0: ReduxStateShared) => User | null | undefined = createSelector(
  getUsers,
  getSelectedUserID,
  (users: User[], userID: number | null | undefined): User | null | undefined => {
    return (users || []).find((u) => u.id === userID);
  },
);

export default getSelectedUser;
