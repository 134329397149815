import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';

const getSubscriptionsState = (state: ReduxStateShared) => state.subscriptions;

export const getEmail = createSelector(getSubscriptionsState, (subscriptionsState) => subscriptionsState.email);

export const getSubscriptions = createSelector(
  getSubscriptionsState,
  (subscriptionsState) => subscriptionsState.subscriptions,
);
