// @owners { team: patients-team }
import { nextModalStep } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { clearUserError, updateUser } from '~shared/actions/users';
import getErrors from '~shared/features/ui/selectors/getErrors';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import OnboardingHealthProfileForm from './OnboardingHealthProfileForm';

const mapStateToProps = (state: ReduxStateShared) => {
  const errors = getErrors(state);
  const userError = errors.fetchUsersError || errors.updateUserError;
  const user = getCurrentUser(state);
  return {
    userError,
    user,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    // @ts-expect-error TS(7006): Parameter 'data' implicitly has an 'any' type.
    onSubmit: (data) => {
      // eslint-disable-next-line import/no-deprecated, promise/catch-or-return
      dispatch(updateUser(data)).then(({ success }) => {
        // eslint-disable-next-line promise/always-return
        if (success) {
          dispatch(clearUserError());
          dispatch(sendAnalyticsEvent(createEvent(EVENTS.ONBOARDING__HEALTH_PROFILE_COMPLETED)));
          dispatch(nextModalStep('ONBOARDING_MODAL'));
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingHealthProfileForm);
