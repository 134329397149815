import { type Dispatch } from 'redux';
import { type NewUserPayload } from './uiOnboarding';
import { type COMPLETE_ONBOARDING } from '~shared/constants';
import { get, post } from '~shared/helpers/apiHelper';
import { type APIError } from '~shared/types/APIError';

export const VERIFICATION_COMMS_CHANNEL = Object.freeze({
  email: 'email',
  sms: 'sms',
  call: 'call',
});
export type VerificationCommsChannel = keyof typeof VERIFICATION_COMMS_CHANNEL;
export type VerificationReason = 'duplicate_account';

const IDENTITY_VERIFICATION_NEEDED_LOADING = 'IDENTITY_VERIFICATION_NEEDED_LOADING';

function identityVerificationNeededLoading() {
  return {
    type: IDENTITY_VERIFICATION_NEEDED_LOADING,
  };
}

const IDENTITY_VERIFICATION_NEEDED_SUCCEEDED = 'IDENTITY_VERIFICATION_NEEDED_SUCCEEDED';

function identityVerificationNeededSucceeded() {
  return {
    type: IDENTITY_VERIFICATION_NEEDED_SUCCEEDED,
  };
}

const IDENTITY_VERIFICATION_NEEDED_FAILED = 'IDENTITY_VERIFICATION_NEEDED_FAILED';

function identityVerificationNeededFailed(error: Error) {
  return {
    type: IDENTITY_VERIFICATION_NEEDED_FAILED,
    payload: error,
    error: true,
  };
}

const VERIFY_IDENTITY_AND_COMMS_LOADING = 'VERIFY_IDENTITY_AND_COMMS_LOADING';

function verifyingIdentityAndComms() {
  return {
    type: VERIFY_IDENTITY_AND_COMMS_LOADING,
  };
}

const VERIFY_IDENTITY_AND_COMMS_SUCCEEDED = 'VERIFY_IDENTITY_AND_COMMS_SUCCEEDED';

function verifyIdentityAndCommsSucceeded() {
  return {
    type: VERIFY_IDENTITY_AND_COMMS_SUCCEEDED,
  };
}

const VERIFY_IDENTITY_AND_COMMS_FAILED = 'VERIFY_IDENTITY_AND_COMMS_FAILED';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
function verifyIdentityAndCommsFailed(error) {
  return {
    type: VERIFY_IDENTITY_AND_COMMS_FAILED,
    payload: error,
    error: true,
  };
}

const VERIFY_COMMS_LOADING = 'VERIFY_COMMS_LOADING';

function verifyingComms() {
  return {
    type: VERIFY_COMMS_LOADING,
  };
}

const VERIFY_COMMS_SUCCEEDED = 'VERIFY_COMMS_SUCCEEDED';

function verifyCommsSucceeded() {
  return {
    type: VERIFY_COMMS_SUCCEEDED,
  };
}

const VERIFY_COMMS_FAILED = 'VERIFY_COMMS_FAILED';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
function verifyCommsFailed(error) {
  return {
    type: VERIFY_COMMS_FAILED,
    payload: error,
    error: true,
  };
}

const REQUEST_VERIFICATION_LOADING = 'REQUEST_VERIFICATION_LOADING';

function requestingVerification() {
  return {
    type: REQUEST_VERIFICATION_LOADING,
  };
}

const REQUEST_VERIFICATION_SUCCEEDED = 'REQUEST_VERIFICATION_SUCCEEDED';

function requestVerificationSucceeded() {
  return {
    type: REQUEST_VERIFICATION_SUCCEEDED,
  };
}

const REQUEST_VERIFICATION_FAILED = 'REQUEST_VERIFICATION_FAILED';

// @ts-expect-error TS(7006): Parameter 'error' implicitly has an 'any' type.
function requestVerificationFailed(error) {
  return {
    type: REQUEST_VERIFICATION_FAILED,
    payload: error,
    error: true,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchIdentityVerificationNeeded(token: string, comms_channel: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(identityVerificationNeededLoading());

    return get(
      '/communications/verifications/identity_verification_needed',
      {
        token,
        comms_channel,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(identityVerificationNeededFailed(json.error));
        return null;
      }

      dispatch(identityVerificationNeededSucceeded());
      return json.identity_verification_needed;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function verifyCommsAndUser(
  last_name: string,
  dob: string | null | undefined,
  token: string,
  comms_channel: string,
) {
  return (dispatch: Dispatch<any>) => {
    dispatch(verifyingIdentityAndComms());

    return post(
      '/communications/verifications/verify_comms_channel_and_user',
      {
        last_name,
        dob,
        token,
        comms_channel,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(verifyIdentityAndCommsFailed(json.error));
        return false;
      }

      dispatch(verifyIdentityAndCommsSucceeded());

      // Note that this deviates a bit from the usual pattern,
      // where we are returning false if the API call fails (above),
      // and json.verified below (which returns true/false)
      // is both for indicating API success and verification success.
      return json.verified;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function verifyEmail(confirmation_token: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(verifyingComms());
    return post(
      '/communications/email_verifications/verify',
      {
        confirmation_token,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(verifyCommsFailed(json.error));
        return false;
      }

      dispatch(verifyCommsSucceeded());
      return json.verified;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function verifyPhone(token: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(verifyingComms());
    return post(
      '/communications/phone_verifications/verify',
      {
        token,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(verifyCommsFailed(json.error));
        return false;
      }

      dispatch(verifyCommsSucceeded());
      return json.verified;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function requestVerification(token: string, commsChannel: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestingVerification());
    return post(
      '/communications/verifications/request_verification',
      {
        token,
        comms_channel: commsChannel,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(requestVerificationFailed(json.error));
        return json;
      }

      dispatch(requestVerificationSucceeded());
      return json;
    });
  };
}

const MARKING_WRONG_USER_LOADING = 'MARKING_WRONG_USER_LOADING';

function markingWrongUser() {
  return {
    type: MARKING_WRONG_USER_LOADING,
  };
}

const MARKING_WRONG_USER_SUCCEEDED = 'MARKING_WRONG_USER_SUCCEEDED';

function markingWrongUserSucceeded() {
  return {
    type: MARKING_WRONG_USER_SUCCEEDED,
  };
}

const MARKING_WRONG_USER_FAILED = 'MARKING_WRONG_USER_FAILED';

function markingWrongUserFailed(error: Error) {
  return {
    type: MARKING_WRONG_USER_FAILED,
    payload: error,
    error: true,
  };
}

export function markWrongUser(token: string, commsChannel: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(markingWrongUser());

    if (!token) {
      dispatch(markingWrongUserFailed(new Error('No token provided')));
      return Promise.resolve(false);
    }
    if (!commsChannel) {
      dispatch(markingWrongUserFailed(new Error('No comms channel provided')));
      return Promise.resolve(false);
    }

    return post(
      `/communications/${commsChannel}_verifications/not_me`,
      {
        token,
      },
      {
        version: 'v3',
      },
    ).then((json) => {
      if (json.error) {
        dispatch(markingWrongUserFailed(json.error));
        return false;
      }

      dispatch(markingWrongUserSucceeded());
      return true;
    });
  };
}

export type ActionCompleteOnboarding = {
  type: typeof COMPLETE_ONBOARDING;
};

export const SET_ONBOARDING_VERIFICATION_CHANNEL = 'SET_ONBOARDING_VERIFICATION_CHANNEL';
export type ActionSetOnboardingVerificationChannel = {
  type: typeof SET_ONBOARDING_VERIFICATION_CHANNEL;
  payload: VerificationCommsChannel | null | undefined;
};
export function setOnboardingVerificationChannel(channel: VerificationCommsChannel | null | undefined) {
  return {
    type: SET_ONBOARDING_VERIFICATION_CHANNEL,
    payload: channel,
  };
}

export const SET_ONBOARDING_VERIFICATION_REQUIRED = 'SET_ONBOARDING_VERIFICATION_REQUIRED';
export type ActionSetOnboardingVerificationRequired = {
  type: typeof SET_ONBOARDING_VERIFICATION_REQUIRED;
};
export function setOnboardingVerificationRequired() {
  return {
    type: SET_ONBOARDING_VERIFICATION_REQUIRED,
  };
}

export const SET_ONBOARDING_VERIFICATION_USER = 'SET_ONBOARDING_VERIFICATION_USER';
export type ActionSetOnboardingVerificationUser = {
  type: typeof SET_ONBOARDING_VERIFICATION_USER;
  payload: NewUserPayload;
};
export function setOnboardingVerificationUser(user: NewUserPayload) {
  return {
    type: SET_ONBOARDING_VERIFICATION_USER,
    payload: user,
  };
}

export const CLEAR_ONBOARDING_VERIFICATION_REQUIRED = 'CLEAR_ONBOARDING_VERIFICATION_REQUIRED';
export type ActionClearOnboardingVerificationRequired = {
  type: typeof CLEAR_ONBOARDING_VERIFICATION_REQUIRED;
};
export function clearOnboardingVerificationRequired() {
  return {
    type: CLEAR_ONBOARDING_VERIFICATION_REQUIRED,
  };
}

export const START_VERIFICATION_LOADING = 'START_VERIFICATION_LOADING';

function startVerificationLoading() {
  return {
    type: START_VERIFICATION_LOADING,
  };
}

export const START_VERIFICATION_SUCCEEDED = 'START_VERIFICATION_SUCCEEDED';

function startVerificationSucceeded() {
  return {
    type: START_VERIFICATION_SUCCEEDED,
  };
}

export const START_VERIFICATION_FAILED = 'START_VERIFICATION_FAILED';

function startVerificationFailed(error: APIError) {
  return {
    type: START_VERIFICATION_FAILED,
    payload: error,
    error: true,
  };
}

export type StartVerificationParams = {
  comms_channel: VerificationCommsChannel | null | undefined;
  uuid: string | null | undefined;
  reason: VerificationReason;
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function startVerification(params: StartVerificationParams) {
  return (dispatch: Dispatch<any>) => {
    dispatch(startVerificationLoading());
    return post('/communications/verifications/start_verification', params, {
      version: 'v3',
    }).then((json) => {
      if (json.error) {
        dispatch(startVerificationFailed(json.error));
        return false;
      }

      dispatch(startVerificationSucceeded());
      return true;
    });
  };
}

export const COMPLETE_VERIFICATION_LOADING = 'COMPLETE_VERIFICATION_LOADING';

function completeVerificationLoading() {
  return {
    type: COMPLETE_VERIFICATION_LOADING,
  };
}

export const COMPLETE_VERIFICATION_SUCCEEDED = 'COMPLETE_VERIFICATION_SUCCEEDED';

function completeVerificationSucceeded() {
  return {
    type: COMPLETE_VERIFICATION_SUCCEEDED,
  };
}

export const COMPLETE_VERIFICATION_FAILED = 'COMPLETE_VERIFICATION_FAILED';

function completeVerificationFailed(error: APIError) {
  return {
    type: COMPLETE_VERIFICATION_FAILED,
    payload: error,
    error: true,
  };
}

export type CompleteVerificationParams = {
  comms_channel: VerificationCommsChannel | null | undefined;
  code: string;
  uuid: string | null | undefined;
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function completeVerification(params: CompleteVerificationParams) {
  return (dispatch: Dispatch<any>) => {
    dispatch(completeVerificationLoading());
    return post('/communications/verifications/complete_verification', params, {
      version: 'v3',
    }).then((json) => {
      if (json.error) {
        dispatch(completeVerificationFailed(json.error));
        return {
          success: false,
        };
      }

      const { phone_verification_id, email_verification_id } = json;
      dispatch(completeVerificationSucceeded());
      return {
        success: true,
        result: {
          phone_verification_id,
          email_verification_id,
        },
      };
    });
  };
}
