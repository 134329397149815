// @owners { team: patients-team }
import { Button, ButtonSideCopy, Toast, ToastContext } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useNavigateToCart } from '../hooks/useNavigateToCart';

export const CartLauncherButton = () => {
  const { addToast } = useContext(ToastContext);
  const { cartSize } = useCartData();
  const { navigateToCart, loading, error } = useNavigateToCart();

  useEffect(() => {
    if (error) {
      addToast(
        <Toast variant="error">
          Something went wrong. Please try again or message support if the problem persists.
        </Toast>,
      );
    }
  }, [addToast, error]);

  return cartSize === 0 ? null : (
    <Button
      spaceBetween
      loading={loading}
      disabled={loading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onPress={navigateToCart}
      LeftContent={<ButtonSideCopy>{cartSize.toString()}</ButtonSideCopy>}
    >
      View cart
    </Button>
  );
};
