export const LG = {
  value: 0.3,
  px: '0.3px',
} as const;

export const MD = {
  value: 0,
  px: '0px',
} as const;

export const SM = {
  value: -0.3,
  px: '-0.3px',
} as const;

export const LETTER_SPACING = {
  LG,
  MD,
  SM,
} as const;
