// eslint-disable-next-line @alto/no-pocky-import
import {
  InputBlockSelect,
  InputBlockText,
  InputRow,
  createValidator,
  validatePresentIf,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps } from 'formik';
import React, { PureComponent } from 'react';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { RELATIONSHIP_CODES, RELATIONSHIP_OPTIONS } from '~shared/constants';
import { type Values } from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { type CreateManualInsurancePayload } from '~shared/features/insurances/types';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';

type Props = {
  readonly values: Values;
};

export const validate = createValidator<Values>({
  relationship_code: validatePresentWithMessage('Relationship'),
  first_name: validatePresentIf((_, values) => {
    return values.relationship_code !== RELATIONSHIP_CODES.SELF;
  }, 'First Name'),
  last_name: validatePresentIf((_, values) => {
    return values.relationship_code !== RELATIONSHIP_CODES.SELF;
  }, 'Last Name'),
  member_id: validatePresentWithMessage('Member ID'),
  bin: validatePresentWithMessage('BIN'),
});

// redux-form fields change to empty strings when they are focused.
// do not want to send an empty string when the value should be null.
// for example, a nickname input left blank should be a null value in the database.
export const removeEmptyValues = (insurance: CreateManualInsurancePayload): CreateManualInsurancePayload => {
  TypescriptUtils.objectKeys(insurance).forEach((key) => {
    const value = insurance[key];
    if (value === '') {
      insurance[key] = undefined;
    }
  });

  return insurance;
};

export default class InsuranceFormElements extends PureComponent<Props> {
  render() {
    const { values } = this.props;

    return (
      <>
        <InputRow>
          <Field name="member_id">
            {({ field, meta }: FieldProps<Values['member_id']>) => (
              <InputBlockText
                id="member_id"
                label="Member ID"
                placeholder="00000000"
                info="Can also be ID Number or Enrollee ID"
                autoCapitalize="none"
                required
                {...mapFormikFieldToInputProps(field, meta)}
              />
            )}
          </Field>
          <Field name="bin">
            {({ field, meta }: FieldProps<Values['bin']>) => (
              <InputBlockText
                id="bin"
                label="Rx BIN"
                placeholder="000000"
                info="6 Digit Number"
                autoCapitalize="none"
                required
                {...mapFormikFieldToInputProps(field, meta)}
              />
            )}
          </Field>
        </InputRow>
        <InputRow>
          <Field name="group">
            {({ field, meta }: FieldProps<Values['group']>) => (
              <InputBlockText
                id="group"
                label="Rx Group"
                placeholder="00000000"
                info="Can also be RxGrp"
                autoCapitalize="none"
                {...mapFormikFieldToInputProps(field, meta)}
              />
            )}
          </Field>
          <Field name="pcn">
            {({ field, meta }: FieldProps<Values['pcn']>) => (
              <InputBlockText
                id="pcn"
                label="Rx PCN"
                placeholder="0000000"
                autoCapitalize="none"
                {...mapFormikFieldToInputProps(field, meta)}
              />
            )}
          </Field>
        </InputRow>
        <InputRow>
          <Field name="relationship_code">
            {({ field, meta, form: { setFieldValue } }: FieldProps<Values['relationship_code']>) => (
              <InputBlockSelect
                id="relationship_code"
                input={{
                  value: field.value,
                  onChange: (value) => {
                    setFieldValue(field.name, value);
                  },
                }}
                options={RELATIONSHIP_OPTIONS}
                label="Relationship"
                info="Your relationship to the primary benefit holder"
                reactSelectConfig={{
                  placeholder: 'Self, Spouse, Child, etc.',
                }}
                required
                {...mapFormikFieldToInputProps(field, meta)}
              />
            )}
          </Field>
        </InputRow>
        {values.relationship_code !== '' && values.relationship_code !== '1' && (
          <InputRow>
            <Field name="first_name">
              {({ field, meta }: FieldProps<Values['first_name']>) => (
                <InputBlockText
                  label="First name"
                  placeholder="Enter first name"
                  autoCapitalize="none"
                  required={values.relationship_code !== '1'}
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
            <Field name="last_name">
              {({ field, meta }: FieldProps<Values['last_name']>) => (
                <InputBlockText
                  required={values.relationship_code !== '1'}
                  label="Last name"
                  placeholder="Enter last name"
                  {...mapFormikFieldToInputProps(field, meta)}
                />
              )}
            </Field>
          </InputRow>
        )}
        <InputRow>
          <Field name="nickname">
            {({ field, meta }: FieldProps<Values['nickname']>) => (
              <InputBlockText
                label="Nickname"
                placeholder="My Rx Card"
                autoCapitalize="none"
                {...mapFormikFieldToInputProps(field, meta)}
              />
            )}
          </Field>
        </InputRow>
      </>
    );
  }
}
