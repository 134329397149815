// @owners { team: patients-team }
import { ActionSheetContext, ListDescription } from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { AutoRefillInfoActionSheet } from './AutoRefillInfoActionSheet';

type AutoRefillInfoButtonProps = {
  readonly origin: string;
};

export const AutoRefillInfoButton = ({ origin }: AutoRefillInfoButtonProps) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();

  const handleIconPress = useCallback(() => {
    setActiveActionSheet(<AutoRefillInfoActionSheet />);

    if (origin.length) {
      trackEvent({ event: EVENTS.AUTO_REFILL_INFO_VIEWED, params: { origin } });
    }
  }, [origin, setActiveActionSheet, trackEvent]);

  return (
    <ListDescription
      type="secondary"
      iconName="info-small"
      onPress={handleIconPress}
    >
      How does auto refill work?
    </ListDescription>
  );
};
