// @owners { team: patients-team }
import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-deprecated
import { getAutoRefills__DEPRECATED } from './getCart';
import { type PrescriptionIDParam } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getPrescriptionID = (_: ReduxStateShared, props: PrescriptionIDParam) => props.prescriptionID;

const getCartAutoRefillForPrescription = createSelector(
  // eslint-disable-next-line import/no-deprecated
  [getAutoRefills__DEPRECATED, getPrescriptionID],
  (autoRefills, prescriptionID) => {
    return autoRefills[prescriptionID];
  },
);

export default getCartAutoRefillForPrescription;
