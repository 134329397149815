// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import { XsSpacing } from '@alto/design-system';
import { useAddOnsContext } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { Carousel, StoreProductTag } from '~web/features/essentials/components';
import { EssentialTag } from '~web/features/essentials/components/EssentialsTag';
import { Image, StyledTouchable, Text, View } from '~web/features/essentials/primitives';

const StyledProductItem = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  border-radius: ${BORDERS.RADIUS.LG.px};
  padding: ${SPACING.STATIC.SM.px};
  &:hover {
    background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER};
  }
  ${StyledTouchable}
`;

const StyledImage = styled(View)`
  ${StyledTouchable}
`;

type ProductCarouselProps = {
  readonly numOfItems: number;
  readonly numOfColumns: number;
  readonly products: AddOnOtc[];
  readonly categoryName: string;
};

const ProductCarouselItem = ({
  product,
  numOfColumns,
  analyticsProps,
}: {
  readonly product: AddOnOtc;
  readonly numOfColumns: number;
  readonly analyticsProps: AnalyticsProps;
}) => {
  const dispatch = useDispatchShared();
  const { shipmentID, origin } = useAddOnsContext();
  const onPress = (id: number) => () =>
    dispatch(
      push({
        pathname: `/store/${id}`,
        search: `?order=${shipmentID}&origin=${origin}`,
        state: { analyticsProps },
      }),
    );
  return (
    <Carousel.Item
      numOfColumns={numOfColumns}
      key={product.name}
    >
      <StyledProductItem onClick={onPress(product.product_id)}>
        <StyledImage
          position="sticky"
          justifyContent="center"
        >
          <Image
            src={product.image_urls[0]}
            width={SIZES.ILLUSTRATION.LG.px}
            height={SIZES.ILLUSTRATION.LG.px}
            alt={`icon for ${product.name}`}
          />
          <StoreProductTag
            product={product}
            shipmentID={shipmentID}
          />
        </StyledImage>
        <EssentialTag addOnOtc={product} />
        <Text
          title={product.quantity_description || ''}
          color="DISABLED"
        />
        <XsSpacing />
        <Text
          title={product.name}
          numberOfLines={3}
          color="PRIMARY"
          variant="body-semibold"
        />
        <View flexGrow={1} />
        <XsSpacing />
        <Text
          title={`$${Number(product.price).toFixed(2)}`}
          color="PRIMARY"
        />
      </StyledProductItem>
    </Carousel.Item>
  );
};

export const ProductCarousel = ({ numOfItems, numOfColumns, products, categoryName }: ProductCarouselProps) => {
  const { shipmentID, origin } = useAddOnsContext();
  const address = useSelectorShared((state) => getShipmentAddress(state, { shipmentID }));
  return (
    <Carousel.Container
      numOfItems={numOfItems}
      numOfColumns={numOfColumns}
    >
      {products.map((product, i) => (
        <ProductCarouselItem
          key={product.product_id}
          product={product}
          numOfColumns={numOfColumns}
          analyticsProps={{
            slot: i + 1,
            sku: product.sku,
            sku_type: product.sku_type,
            otc_name: product.name,
            category: categoryName,
            query_string: undefined,
            shipment_id: shipmentID,
            facility_id: address?.dispensing_facility_id,
            origin,
          }}
        />
      ))}
    </Carousel.Container>
  );
};
