// @owners { team: patients-team }
import { Description, InlineAlert, Link, MdPadding, XxsSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';

export const FSAIndividualReceiptAlert = () => {
  const { navigate } = useNavigation();

  const goToOrders = () => {
    navigate('RouteAppTabNavigator', { screen: 'RouteTabOrders' });
  };
  return (
    <MdPadding>
      <InlineAlert>
        <Description fontFamily="semibold">Looking for individual receipts?</Description>
        <XxsSpacing />
        <Description>
          Receipts for individual orders are available on the order details page once the order has been completed.
        </Description>
        <XxsSpacing />
        <Link
          onPress={goToOrders}
          textSize="mini"
        >
          View orders
        </Link>
      </InlineAlert>
    </MdPadding>
  );
};
