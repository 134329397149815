// @owners { team: patients-team }
import { InputAutocomplete } from '@alto/design-system';
import { type ApiResponseRow } from '@alto/design-system/src/components/inputs/src/InputAutocomplete/types';
import React from 'react';
import { allergensAutocompleteDataSource } from '~shared/data-sources/allergensAutocompleteDataSource';
import { getPossessiveName } from '~shared/helpers/string';

type Props = {
  readonly familyMemberName?: string;
  readonly error?: string;
  readonly hasSubmitted: boolean;
  readonly initialValues: ApiResponseRow<string>[];
  readonly isTouched: boolean;
  readonly onChange: (allergies: string) => void;
};

export const AllergenSearchField = ({
  error,
  familyMemberName,
  hasSubmitted,
  initialValues,
  isTouched,
  onChange,
}: Props) => {
  const label = familyMemberName ? `${getPossessiveName(familyMemberName)} allergies` : 'Allergies';

  return (
    <InputAutocomplete
      label={label}
      leftIcon="search-small"
      accessibilityLabel="Select or search for all allergies that apply"
      shouldAllowManualInput
      selectionType="multi"
      dataSource={allergensAutocompleteDataSource}
      initialValues={initialValues}
      onSelectCallback={(data: ApiResponseRow<string>[]) => {
        const allergens = data.map((allergen) => allergen.key).join(',');
        onChange(allergens);
      }}
      placeholder="Select or search for all allergies that apply"
      error={isTouched || hasSubmitted ? error : null}
      hasDefaultOptions
      required
    />
  );
};
