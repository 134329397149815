import * as React from 'react';
import { Column } from './Flex';

type Props = {
  readonly children: React.ReactNode;
};

export const CenterView = ({ children }: Props) => {
  return (
    <Column
      center
      flexGrow={1}
    >
      {children}
    </Column>
  );
};
