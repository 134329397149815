// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  AltoSpinningLoader,
  Description,
  FlatCard,
  MdPadding,
  Pressable,
  Row,
  Separator,
  XsSpacing,
} from '@alto/design-system';
import React, { useContext } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { type OrderPricing } from '~shared/features/pricing/types';
import { getPrescriptionsForShipment } from '~shared/features/shipments/selectors/getPrescriptionsForShipment';
import { useSelectorShared } from '~shared/store';
import { type PaymentMethod, type Shipment } from '~shared/types';
import { PricingBreakdownActionSheet } from '../../pricing-breakdown';
import { PricingInfoLineItem } from '../../pricing-info';
import { PaymentMethodInfo } from './PaymentMethodInfo';

export type Props = {
  readonly origin: 'Cart' | 'Checkout' | 'DeliveryDetails';
  readonly pricing: OrderPricing;
  readonly openEditTip?: () => void;
  readonly shipmentPaymentMethods?: PaymentMethodWithAmount[] | null;
  readonly showZeroTipLineItem?: boolean;
  readonly showEditTipOption?: boolean;
  readonly showEstimatedTotal?: boolean;
  readonly tipPaymentMethod?: PaymentMethod | null | undefined;
  readonly shipmentID?: number | null;
  readonly shipment?: Shipment;
};

export const PaymentSummary = ({
  pricing,
  openEditTip,
  shipmentPaymentMethods = null,
  showZeroTipLineItem = false,
  showEditTipOption = false,
  showEstimatedTotal,
  tipPaymentMethod,
  origin,
  shipmentID = null,
  shipment,
}: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const fetchOrderPricingLoading = useSelectorShared((state) => state.ui.loading.fetchOrderPricingLoading);
  const prescriptionsForShipment = useSelectorShared((state) => getPrescriptionsForShipment(state, { shipmentID }));
  const { prescriptions: prescriptionsInCart } = usePrescriptionsInCart();
  const prescriptions = shipmentID ? prescriptionsForShipment : prescriptionsInCart;
  const itemCount = prescriptions.length;

  const { line_items } = pricing;
  const getTipAmount = (): number => {
    const tipLineItems = line_items.filter(({ key }) => key === 'tip_amount');
    return tipLineItems[0]?.value || 0;
  };
  const tipAmount = getTipAmount();

  const handleOnPress = () => {
    setActiveActionSheet(
      <PricingBreakdownActionSheet
        origin={origin}
        prescriptions={prescriptions}
        forShipment={!!shipmentID}
      />,
    );
  };

  const showSeparator = !!shipmentPaymentMethods;
  const displayPayAtPickup = shipment && shipment.is_pickup && shipment.pay_at_pickup;

  return (
    <FlatCard>
      <MdPadding
        topPadding={SPACING.STATIC.SM}
        bottomPadding={showSeparator ? SPACING.STATIC.XXS : SPACING.STATIC.SM}
      >
        {fetchOrderPricingLoading ? (
          <Row center>
            <AltoSpinningLoader />
          </Row>
        ) : (
          line_items.map(({ key: itemType, label, original_value: originalValue, value, info_key }) => {
            if (itemType === 'tip_amount' && !showZeroTipLineItem && !tipAmount) return null;
            const hasPaymentMethod = !!shipmentPaymentMethods && shipmentPaymentMethods.length > 0;
            const isTotalLineItem = itemType === 'total';

            return (
              <React.Fragment key={itemType}>
                <PricingInfoLineItem
                  infoKey={info_key}
                  itemType={itemType}
                  itemValue={value}
                  label={label}
                  openEditTip={openEditTip}
                  originalValue={originalValue}
                  showEditTipOption={showEditTipOption}
                  showEstimatedTotal={showEstimatedTotal}
                  totalItemCount={itemCount}
                  origin={origin}
                />
                {isTotalLineItem && !hasPaymentMethod ? null : <XsSpacing />}
              </React.Fragment>
            );
          })
        )}
      </MdPadding>
      {showSeparator ? <Separator /> : null}
      {displayPayAtPickup ? (
        <MdPadding
          topPadding={SPACING.STATIC.SM}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <Row centerVertically>
            <Description color={COLORS.TEXT_COLORS.GREY}>Paying at pickup</Description>
          </Row>
        </MdPadding>
      ) : (
        !!shipmentPaymentMethods && (
          <MdPadding
            topPadding={SPACING.STATIC.SM}
            bottomPadding={SPACING.STATIC.NONE}
          >
            <PaymentMethodInfo
              shipmentPaymentMethods={shipmentPaymentMethods}
              showTipPaymentMethod={!!tipAmount}
              tipPaymentMethod={tipPaymentMethod}
            />
          </MdPadding>
        )
      )}
      {fetchOrderPricingLoading ? null : (
        <Pressable onPress={handleOnPress}>
          <MdPadding
            topPadding={SPACING.STATIC.SM}
            bottomPadding={SPACING.STATIC.SM}
          >
            <Row
              centerVertically
              spaceBetween
            >
              <Description
                color={COLORS.TEXT_COLORS.SECONDARY_DARK}
                fontFamily="semibold"
              >
                Full pricing details
              </Description>
              <AltoIcon
                type="secondary"
                name="chevronright-small"
              />
            </Row>
          </MdPadding>
        </Pressable>
      )}
    </FlatCard>
  );
};
