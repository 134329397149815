// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, InputBlockTextArea, Text, spacing } from '@alto/pocky';
import React, { type ElementRef } from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { FREEFORM_LABEL_OPTIONAL, LINE_SEPARATED_HEADER } from '~shared/features/rate-shipment/helpers';
import Stars from './Stars';
import HeaderImage, { LARGE_SIZE } from '~web/components/HeaderImage';
import handAndBox from '~web/images/delivery@1x.png';

type Props = {
  readonly rating: number;
  readonly onRatingChange: (rating: number) => void;
  readonly freeformReason: string;
  readonly onFreeformReasonChange: (freeformReason: string) => void;
  readonly errorMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  readonly firstFocusableElementRef: ElementRef<any> | null | undefined;
  readonly isModal: boolean | null | undefined;
};

const HeaderImageWrapper = styled.div<{ isModal: boolean | null | undefined; isSMScreenOrBigger: boolean }>`
  ${({ isModal }) => `
    padding-top: ${isModal ? SPACING.STATIC.XL.rem : '0'};
  `};

  ${({ isSMScreenOrBigger, isModal }) =>
    !isSMScreenOrBigger &&
    `
      & img {
        display: ${isModal ? 'none' : 'unset'};
      }
  `}
`;

const ErrorText = styled(Text)<{ horizontallyAlignContent: boolean }>`
  /* reduce the margin provided by the previous element in the container */
  margin-top: -${SPACING.STATIC.LG.rem};
  /* override the containers margin bottom style */
  margin-bottom: ${SPACING.STATIC.MD.rem} !important;
`;

const FullWidthColumn = styled(Column)`
  width: 100%;
`;

const RateShipment = ({
  rating,
  onRatingChange,
  freeformReason,
  onFreeformReasonChange,
  errorMessage,
  firstFocusableElementRef,
  isModal,
}: Props) => {
  const onFreeformInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onFreeformReasonChange(value);
  };
  const { isSMScreenOrBigger } = useScreenSize();
  const header = (
    <>
      {LINE_SEPARATED_HEADER[0]}
      <br />
      {LINE_SEPARATED_HEADER[1]}
    </>
  );

  return (
    <Column
      spacing={spacing.xlSpacing}
      horizontallyAlignContent
    >
      <HeaderImageWrapper
        isSMScreenOrBigger={isSMScreenOrBigger}
        isModal={isModal}
      >
        <HeaderImage
          src={handAndBox}
          alt={IMG_ALT_TEXTS.delivery}
          title={header}
          imgSize={LARGE_SIZE}
        />
      </HeaderImageWrapper>
      <Stars
        rating={rating}
        setRating={onRatingChange}
        isModal={isModal}
        firstFocusableElementRef={firstFocusableElementRef}
      />
      {errorMessage ? (
        <ErrorText
          danger
          horizontallyAlignContent
        >
          {errorMessage}
        </ErrorText>
      ) : null}
      <FullWidthColumn>
        <InputBlockTextArea
          label={FREEFORM_LABEL_OPTIONAL}
          placeholder="Enter your comments"
          meta={{
            error: false,
            touched: !!freeformReason,
            invalid: false,
          }}
          input={{
            value: freeformReason,
            onChange: onFreeformInputChange,
          }}
        />
      </FullWidthColumn>
    </Column>
  );
};

export default RateShipment;
