// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { queries } from '~shared/queries/query-keys';
import { useQuery } from '~shared/react-query';
import { useSelectorShared } from '~shared/store';

export const useQueryFetchAnnouncement = () => {
  const { isLoading, value: enabled } = Experimentation.useFeatureFlag('app_education');
  const patientID = useSelectorShared(getCurrentUserID);
  const { isFetching, isSuccess, data, isError } = useQuery({
    ...queries.announcements.fetchAnnouncement({ params: { patient_id: patientID || 0 } }),
    enabled,
  });

  return {
    isFetching: isLoading || isFetching,
    isSuccess,
    isError,
    announcement: data?.data,
  };
};
