import { SHADOWS } from '@alto/design-library-tokens';
import React from 'react';
import styled from 'styled-components/native';
import { Column } from './Flex';

const { TOP_LIGHT, TOP, TOP_DARK, BOTTOM_LIGHT, BOTTOM, BOTTOM_DARK } = SHADOWS;

type Props = {
  readonly children: React.ReactNode;
  readonly topLight?: boolean;
  readonly top?: boolean;
  readonly topDark?: boolean;
  readonly bottomLight?: boolean;
  readonly bottom?: boolean;
  readonly bottomDark?: boolean;
  readonly flexGrow?: number;
  readonly flexShrink?: number;
}; // Box-shadows don't work on android
// elevation style is used for Android and is
// not included here because it has rendering issues

const Shadow = styled(Column)<{ shadow?: (typeof SHADOWS)[keyof typeof SHADOWS] }>`
  ${({ shadow }) => shadow}
`;

export const DropShadow = ({
  children,
  topLight,
  top,
  topDark,
  bottomLight,
  bottom,
  bottomDark,
  flexGrow,
  flexShrink,
}: Props) => {
  let shadow;

  if (topLight) {
    shadow = TOP_LIGHT;
  } else if (top) {
    shadow = TOP;
  } else if (topDark) {
    shadow = TOP_DARK;
  } else if (bottomLight) {
    shadow = BOTTOM_LIGHT;
  } else if (bottom) {
    shadow = BOTTOM;
  } else if (bottomDark) {
    shadow = BOTTOM_DARK;
  }

  return (
    <Shadow
      shadow={shadow}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
    >
      {children}
    </Shadow>
  );
};
