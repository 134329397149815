// @owners { team: patients-team }
import { SIZES, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, spacing } from '@alto/pocky';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NO_RATING_ERROR_MESSAGE } from '~shared/features/rate-shipment/helpers';
import RateShipment from './RateShipment';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import { FOOTER_HEIGHT, PageBackground, SingleColumnContentWrapper } from '~web/components/Page';

type Props = {
  readonly trackRateShipmentPageViewed: () => void;
  readonly sendRatingClickedAnalyticsEvent: (rating: number) => void;
  readonly onSubmit: (rating: number, freeformReason: string) => void;
  readonly onDismiss: () => void;
};

const EXTRA_SPACE_UNDER_NAVBAR = '8px';

// FIXME: figure out a better way to do this
const PageBackgroundWrapper = styled(PageBackground)<{ isMDScreenOrBigger: boolean }>`
  min-height: calc(100vh - ${FOOTER_HEIGHT.DESKTOP} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG} - ${EXTRA_SPACE_UNDER_NAVBAR});
  margin-top: ${EXTRA_SPACE_UNDER_NAVBAR};

  ${({ isMDScreenOrBigger }) =>
    !isMDScreenOrBigger &&
    `min-height: calc(100vh - ${FOOTER_HEIGHT.TABLET} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM} - ${EXTRA_SPACE_UNDER_NAVBAR});`}
`;

const RateShipmentContentWrapper = styled(SingleColumnContentWrapper)<{ isSMScreenOrBigger: boolean }>`
  // fixes display bug when unauthed at 1200px screensize
  min-width: 490px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `min-width: unset;`}
  > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

// add some space between rate shipment and buttons.
// already 1.5rem margin from SingleColumnContentWrapper
const Spacer = styled.div<{ isSMScreenOrBigger: boolean }>`
  height: ${spacing.xlSpacing};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `height: ${SPACING.STATIC.LG.rem};;`}
`;

const RateShipmentPage = ({
  trackRateShipmentPageViewed,
  sendRatingClickedAnalyticsEvent,
  onSubmit,
  onDismiss,
}: Props) => {
  const [rating, setRating] = useState(0);
  const [freeformReason, setFreeformReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { isSMScreenOrBigger, isMDScreenOrBigger } = useScreenSize();

  useEffect(() => {
    trackRateShipmentPageViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRatingChangeWithAnalytics = (rating: number) => {
    sendRatingClickedAnalyticsEvent(rating);
    setErrorMessage('');
    setRating(rating);
  };

  const onSubmitClick = () => {
    if (rating === 0) {
      setErrorMessage(NO_RATING_ERROR_MESSAGE);
    } else {
      onSubmit(rating, freeformReason);
    }
  };

  return (
    <PageBackgroundWrapper
      isMDScreenOrBigger={isMDScreenOrBigger}
      white
    >
      <RateShipmentContentWrapper isSMScreenOrBigger={isSMScreenOrBigger}>
        {/* @ts-expect-error TS(2741): Property 'firstFocusableElementRef' is missing in type '{ rating: number; onRatingChange: (rating: n... (Delete me to see the full error) */}
        <RateShipment
          rating={rating}
          onRatingChange={onRatingChangeWithAnalytics}
          freeformReason={freeformReason}
          onFreeformReasonChange={setFreeformReason}
          errorMessage={errorMessage}
          isModal={false}
        />
        <Spacer isSMScreenOrBigger={isSMScreenOrBigger} />
        <ButtonWrapper
          spacing={spacing.xsSpacing}
          horizontallyAlignContent
        >
          <Button
            kind="secondary"
            onClick={onDismiss}
          >
            Dismiss
          </Button>
          <Button onClick={onSubmitClick}>Submit</Button>
        </ButtonWrapper>
      </RateShipmentContentWrapper>
    </PageBackgroundWrapper>
  );
};

export default RateShipmentPage;
