// @owners { team: patients-team }
import { SmSpacing } from '@alto/design-system';
import { useAddOnsContext, useQueryFeaturedOtcs } from '@alto/features';
import React from 'react';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { StandaloneEssentialsCarousel } from './StandaloneEssentialsCarousel';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';
import { View } from '~web/features/essentials/primitives';
import { Text } from '~web/features/essentials/primitives/Text';

export const ShipmentSingleCarousel = () => {
  const { shipmentID, origin } = useAddOnsContext();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID }));
  const { error, products } = useQueryFeaturedOtcs({
    addressID: shipment?.address_id,
    date: shipment?.scheduled_date,
  });

  useTrackViewedEvent(EVENTS.ESSENTIALS__SINGLE_CAROUSEL_VIEWED, shipmentID, origin);

  if (error || !products) {
    return null;
  }

  return (
    <View
      flexDirection="column"
      width="100%"
    >
      <Text
        title="Need anything else?"
        variant="h3"
      />
      <SmSpacing />
      <Text
        title="Add some essentials to this order"
        variant="body"
      />
      <SmSpacing />
      <StandaloneEssentialsCarousel
        shipmentID={shipmentID}
        origin={ORIGINS.EDIT_DELIVERY_DETAILS}
      />
    </View>
  );
};
